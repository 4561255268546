import { Component, Input, OnInit, ChangeDetectorRef, Output, EventEmitter, OnChanges } from "@angular/core";
import { ControlContainer, NgForm } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { FormMode } from "app/common/enums";

import { Geofence } from "app/models/geofence.model";
import { AuthenticationService } from "app/services/authentication/authentication.service";

@Component({
  selector: "fh-geofence-details",
  templateUrl: "geofenceDetails.component.html",
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class GeofenceDetailsComponent implements OnInit, OnChanges {
  @Input() geofence: Geofence;
  @Input() loading = false;
  @Output() onAccountChanged = new EventEmitter();
  @Output() onColorChanged = new EventEmitter();
  @Input() formMode: any = FormMode.read;

  color = "#0033FF";
  loadingAccounts = false;
  // geofenceTypes = [{ name: this.translateService.instant('general.default'), value: 1 }, { name: this.translateService.instant('general.noTheftArea'), value: 16 }]
  selectedTypes = [];
  selectedResellerId;
  permissions: {};

  constructor(
    private cd: ChangeDetectorRef,
    private translateService: TranslateService,
    private authenticationService: AuthenticationService
  ) {
    this.permissions = this.authenticationService.permissions;
  }

  resellerChanged(resellerId) {
    this.geofence.accountId = null;

    this.selectedResellerId = resellerId;
  }

  accountChanged(accountId) {
    console.log("AccountId set to " + accountId);
    this.onAccountChanged.emit(this.geofence.accountId);
    this.geofence.userId = undefined;
    this.cd.markForCheck();
  }

  typesChanged() {
    this.geofence.type = this.selectedTypes.reduce((previous, current) => (previous += current), 0);
  }

  ngOnInit(): void {
    this.loadingAccounts = true;

    if (this.formMode === 2) {
      this.selectedTypes = [1];
      this.typesChanged();
    } else {
      this.setSelectedType();
    }
  }

  ngOnChanges() {
    this.setSelectedType();
  }

  setSelectedType() {
    if (this.geofence) {
      this.selectedTypes = this.convertSelectedTypes(this.geofence.type);
    }
  }

  colorChanged(value) {
    this.onColorChanged.emit(value);
  }

  convertSelectedTypes(value): number[] {
    const result = [];
    const maxAmountType = 16;
    for (let i = 0; i < maxAmountType; i++) {
      const flag = 2 ** i;
      if ((value & flag) === flag) {
        result.push(flag);
      }
    }
    return result;
  }

  updateRadius(e) {
    document.dispatchEvent(new CustomEvent("resizegeofence", { detail: e.target.value }));
  }
}
