<div class="ibox-content slick" style="position: relative">
    <ngx-loading [show]="loading"></ngx-loading>
    <table style="width: 100%" class="table table-fixed nowrap dtr-inline no-footer">
        <tbody *ngIf="!sensor">
            <tr>
                <td colspan="4">
                    {{ "general.noSensor" | translate }}
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="sensor">
            <tr>
                <td class="row_opening">
                    {{ "general.name" | translate }}
                    <span class="error" *ngIf="name.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input
                        type="text"
                        [readonly]="formMode == 1"
                        name="name"
                        #name="ngModel"
                        required
                        placeholder="{{ 'placeholder.name' | translate }}"
                        class="form-control table-inline"
                        [(ngModel)]="sensor.name"
                    />
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ "general.devEUI" | translate }}
                    <span class="error" *ngIf="devEUI.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input
                        type="text"
                        [readonly]="formMode == 1"
                        name="devEUI"
                        #devEUI="ngModel"
                        required
                        placeholder="{{ 'placeholder.devEUI' | translate }}"
                        class="form-control table-inline"
                        [(ngModel)]="sensor.devEUI"
                    />
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ "general.minTemp" | translate }}
                    <span class="error" *ngIf="minTemp.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input
                        type="text"
                        [readonly]="formMode == 1"
                        name="minTemp"
                        #minTemp="ngModel"
                        placeholder="{{ 'placeholder.minTemp' | translate }}"
                        class="form-control table-inline"
                        [(ngModel)]="sensor.lowerTemperatureThreshold"
                    />
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ "general.maxTemp" | translate }}
                    <span class="error" *ngIf="maxTemp.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input
                        type="text"
                        [readonly]="formMode == 1"
                        name="maxTemp"
                        #maxTemp="ngModel"
                        placeholder="{{ 'placeholder.maxTemp' | translate }}"
                        class="form-control table-inline"
                        [(ngModel)]="sensor.upperTemperatureThreshold"
                    />
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ "general.minHumidity" | translate }}
                    <span class="error" *ngIf="minHumidity.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input
                        type="text"
                        [readonly]="formMode == 1"
                        name="minHumidity"
                        #minHumidity="ngModel"
                        placeholder="{{ 'placeholder.minHumidity' | translate }}"
                        class="form-control table-inline"
                        [(ngModel)]="sensor.lowerHumidityThreshold"
                    />
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ "general.maxHumidity" | translate }}
                    <span class="error" *ngIf="maxHumidity.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input
                        type="text"
                        [readonly]="formMode == 1"
                        name="maxHumidity"
                        #maxHumidity="ngModel"
                        placeholder="{{ 'placeholder.maxHumidity' | translate }}"
                        class="form-control table-inline"
                        [(ngModel)]="sensor.upperHumidityThreshold"
                    />
                </td>
            </tr>
        </tbody>
    </table>
</div>
