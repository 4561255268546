import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { TranslateService } from "@ngx-translate/core";
import { AuthenticationService } from "app/services/authentication/authentication.service";

// Moment
import Moment from "moment-timezone";

import { FormMode } from "app/common/enums";

import { AccountService } from "app/services/account/account.service";
import { AccountInventory } from "app/models/account.model";
import { WorkingHoursSelectorComponent } from "app/views/shared/usercontrols/workingHours.component";
import { SensorTemplateService } from "app/services/sensorTemplate/sensorTemplate.service";
import { SensorTemplate } from "app/models/sensorTemplate.model";
window["moment"] = Moment;

@Component({
  selector: "fh-sensor-templates-add",
  templateUrl: "addSensorTemplate.template.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SensorTemplateAddViewComponent implements OnInit, AfterViewInit {
  @ViewChild(WorkingHoursSelectorComponent, { static: false }) workingHoursVar: WorkingHoursSelectorComponent;

  sub;

  loading = false;
  saving = false;

  error: any;
  success: any;

  formMode: FormMode = FormMode.add;

  sensorTemplate: SensorTemplate;
  account: AccountInventory;

  constructor(
    private cd: ChangeDetectorRef,
    private sensorTemplateService: SensorTemplateService,
    private accountService: AccountService,
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    private authenticationService: AuthenticationService
  ) {
    this.account = null;

    this.sensorTemplate = new SensorTemplate();
    this.cd.markForCheck();
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.workingHoursVar?.createSchedule();
    this.cd.markForCheck();
  }

  onInsert() {
    this.saving = true;
    console.log("insert");
    this.sensorTemplateService.saveSensorTemplate(this.sensorTemplate).subscribe(
      (result) => {
        this.error = null;
        this.success = {
          statusText: "Success",
          success: "Trigger schedule is successfully added.",
        };

        setTimeout(() => {
          this.router.navigate(["/Devices/SensorTemplateDetails/Index/" + result.id]);
        }, 2000);
      },
      (error) => {
        this.saving = false;
        this.success = null;
        this.error = error;
      }
    );
  }
}
