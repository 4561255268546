import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceAnnouncement } from 'app/models/serviceAnnouncement.model';


import { throwError } from 'rxjs/internal/observable/throwError';
import { Observable, catchError, map } from 'rxjs';
import { AuthenticationService } from '../authentication/authentication.service';
import { LoggingService } from '../logging/logging.service';


@Injectable()
export class ServiceAnnouncementService {

    token: string;
    timestamp;
    url = '';

    constructor(private http: HttpClient, private loggingService: LoggingService, private authenticationService: AuthenticationService) {
        this.url = this.authenticationService.getWebserviceURL('serviceAnnouncement');
    }

    getPagingUrl() {
        return this.url + 'Paging';
    }

    getServerAnnouncements(accountId?: number): Observable<ServiceAnnouncement[]> {
        return this.http.get(this.url + (accountId ? `?accountId=${accountId}` : ''), { headers: this.authenticationService.headers })
            .pipe(map(data => {
                const parsedResponse = this.parseResponse(data);
                return parsedResponse;
            }), catchError(this.handleError));
    }

    getServiceAnnouncementById(id: string): Observable<ServiceAnnouncement> {
        return this.http.get(this.url + id, { headers: this.authenticationService.headers })
            .pipe(map(data => {
                const parsedResponse = this.parseReponseDetails(data);
                return parsedResponse;
            }), catchError(this.handleError));
    }

    saveServiceAnnouncement(serviceAnnouncement: ServiceAnnouncement): Observable<any> {
        console.log('save serviceAnnouncement');
        return this.http.post(this.url, serviceAnnouncement, { headers: this.authenticationService.headers })
            .pipe(catchError(this.handleError));
    }

    updateServiceAnnouncement(serviceAnnouncement: ServiceAnnouncement): Observable<any> {
        console.log('save serviceAnnouncement');
        return this.http.put(this.url + serviceAnnouncement.id, serviceAnnouncement, { headers: this.authenticationService.headers })
            .pipe(catchError(this.handleError));
    }

    deleteServiceAnnouncement(serviceAnnouncement: ServiceAnnouncement): Observable<any> {
        console.log('save serviceAnnouncement');
        return this.http.delete(this.url + serviceAnnouncement.id, { headers: this.authenticationService.headers })
            .pipe(catchError(this.handleError));
    }

    parseResponse(json: any): ServiceAnnouncement[] {

        this.loggingService.log(this.constructor.name, 'Retrieved ' + json.length + ' ServiceAnnouncements.');

        const ident = 1;
        const assets: ServiceAnnouncement[] = [];

        json.forEach(item => {

            const asset = this.parseReponseDetails(item);
            assets.push(asset);
        });

        this.timestamp = new Date().getTime();
        return assets;
    }

    parseReponseDetails(item) {
        //   this.loggingService.log(this.constructor().name + " - New", item);
        const serviceAnnouncement = new ServiceAnnouncement();
        serviceAnnouncement.id = item.id;
        serviceAnnouncement.resellerId = item.resellerId;
        serviceAnnouncement.message = item.message;
        serviceAnnouncement.details = item.details;

        serviceAnnouncement.validFrom = item.validFrom ? new Date(item.validFrom) : null;
        serviceAnnouncement.validTill = item.validTill ? new Date(item.validTill) : null;

        serviceAnnouncement.severity = item.severity;
        serviceAnnouncement.cultures = item.cultures;

        return serviceAnnouncement;
    }

    private handleError(error: Response) {
        return throwError(error);
    }
}
