<div class="ibox" style="position: relative">
    <ngx-loading [show]="loading"></ngx-loading>
    <div class="ibox-title">
        <h5><i class="fas fa-fw fa-address-card"></i> {{ "general.userContacts" | translate }}</h5>
        <span class="float-right">
            <div class="filter-form d-none d-md-block">
                <input
                    name="filter"
                    type="text"
                    class="form-control table-inline top-filter d-none d-md-block"
                    (ngModelChange)="p.setCurrent(0)"
                    [(ngModel)]="filter"
                />
            </div>
        </span>
        <!-- <span class="float-right">
            <i class="fa fa-fw fa-cog pointer" [routerLink]="['/Notifications/UserContacts/Overview']"></i>
        </span> -->
    </div>
    <div class="ibox-content slick" style="position: relative; overflow-y: auto">
        <table style="width: 100%" class="table nowrap dtr-inline no-footer">
            <tbody *ngIf="userContacts.length == 0">
                <tr>
                    <td colspan="2">
                        {{ "general.noUserContacts" | translate }}
                    </td>
                </tr>
            </tbody>
            <thead *ngIf="userContacts.length > 0">
                <tr>
                    <td style="width: 25px">{{ "general.email" | translate }}</td>
                    <td style="width: 25px">{{ "general.sms" | translate }}</td>
                    <td style="width: auto">{{ "general.name" | translate }}</td>
                    <td style="width: auto">{{ "general.email" | translate }}</td>
                    <td style="width: auto">{{ "general.phone" | translate }}</td>
                </tr>
            </thead>
            <tbody *ngIf="userContacts.length > 0">
                <tr
                    *ngFor="
                        let userContact of userContacts
                            | stringFilter : filter
                            | paginate : { itemsPerPage: 10, currentPage: currentPage }
                    "
                >
                    <td style="width: 25px" *ngIf="!isDisplayOnly">
                        <div class="fa_checkbox">
                            <input
                                [name]="'idEmail_' + userContact.id"
                                [id]="'idEmail_' + userContact.id"
                                type="checkbox"
                                [disabled]="formMode == 1"
                                [(ngModel)]="userContact.isEmailEnabled"
                            />
                            <label [for]="'idEmail_' + userContact.id"></label>
                        </div>
                    </td>
                    <td style="width: 25px" *ngIf="!isDisplayOnly">
                        <div class="fa_checkbox">
                            <input
                                [name]="'idSms_' + userContact.id"
                                [id]="'idSms_' + userContact.id"
                                type="checkbox"
                                [disabled]="formMode == 1"
                                [(ngModel)]="userContact.isSmsEnabled"
                            />
                            <label [for]="'idSms_' + userContact.id"></label>
                        </div>
                    </td>
                    <td style="width: auto" class="row_content">
                        <a
                            class="secondary link_bolder"
                            href="/#/Notifications/UserContactDetails/Index/{{ userContact.id }}"
                        >
                            {{ userContact.firstName }} {{ userContact.lastName }}</a
                        >
                    </td>
                    <td style="width: auto" class="row_content">
                        {{ userContact.email }}
                    </td>
                    <td style="width: auto" class="row_content">
                        {{ userContact.msisdn }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="ibox-footer noselect dataTables_wrapper">
        <pagination-template #p="paginationApi" (pageChange)="currentPage = $event">
            <div class="dataTables_paginate paging_simple_numbers" id="dataTable_paginate">
                <ul class="pagination">
                    <li class="paginate_button page-item previous" [class.disabled]="p.isFirstPage()">
                        <a class="page-link" (click)="p.previous()" *ngIf="!p.isFirstPage()">{{
                            "grid.previous" | translate
                        }}</a>
                        <a *ngIf="p.isFirstPage()">{{ "grid.previous" | translate }}</a>
                    </li>

                    <li
                        class="paginate_button page-item"
                        *ngFor="let page of p.pages"
                        [class.current]="p.getCurrent() === page.value"
                    >
                        <a class="page-link" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
                            <span>{{ page.label }}</span>
                        </a>
                        <a class="page-link" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() === page.value">
                            <span style="font-weight: bold">{{ page.label }}</span>
                        </a>
                    </li>

                    <li class="paginate_button page-item next" [class.disabled]="p.isLastPage()">
                        <a class="page-link" (click)="p.next()" *ngIf="!p.isLastPage()">
                            {{ "grid.next" | translate }}
                        </a>
                        <a class="page-link" *ngIf="p.isLastPage()"> {{ "grid.next" | translate }} </a>
                    </li>
                </ul>
            </div>
        </pagination-template>
        <div style="clear: both"></div>
    </div>
</div>
