import { KeyValue } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from "@angular/core";
import { colorArray, colorArray2, roundSeconds } from "app/common/globals";

import { StorageType } from "app/common/enums";
import { StorageHelper } from "app/common/storagehelper";

import * as Moment from "moment";

@Component({
  selector: "fh-overview-geofence-history",
  templateUrl: "overviewGeofenceHistory.template.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FleetOverviewGeofenceHistoryComponent implements OnInit, OnChanges {
  @Input() geofence: KeyValue<any, any>;

  currentDate: number;
  colorArray = colorArray2;

  constructor(private storageHelper: StorageHelper) {
    this.storageHelper.loadStoreState(StorageType.LocalStorage, "settings_", "brightColors").subscribe((result) => {
      if (JSON.parse(result) === true) {
        this.colorArray = colorArray;
      }
    });
  }

  ngOnChanges(): void {}

  ngOnInit(): void {}

  renderEpisodes(episodes: any[]) {
    this.currentDate = null;

    return episodes;
  }

  isNewDate(episode) {
    const checkDate = Moment(episode.dateTime).date();
    if (checkDate === this.currentDate) {
      return false;
    } else {
      this.currentDate = checkDate;
      return true;
    }
  }

  roundSeconds(seconds) {
    return roundSeconds(seconds);
  }
}
