import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef } from "@angular/core";
import { ControlContainer, NgForm } from "@angular/forms";
import { FormMode, UserContactType } from "app/common/enums";
import { UserContact } from "app/models/userContact.model";
import { AccountService } from "app/services/account/account.service";
import { AuthenticationService } from "app/services/authentication/authentication.service";
import { UserService } from "app/services/users/user.service";

@Component({
  selector: "fh-user-contact-details",
  templateUrl: "userContactDetails.component.html",
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class UserContactDetailsComponent implements OnInit {
  @Input() userContact: UserContact;
  @Input() accounts = [];
  @Input() formMode: any = FormMode.read;
  @Output() onAccountChanged = new EventEmitter();

  loadingAccounts = false;
  loadingUsers = false;
  permissions: {};

  selectedResellerId;
  userContactTypes = [];
  users = [];

  constructor(
    private cd: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private accountService: AccountService,
    private userService: UserService
  ) {
    this.userContactTypes = Object.keys(UserContactType)
      .filter((k) => typeof UserContactType[k] === "string")
      .map((n) => ({ value: +n, name: UserContactType[n] }))
      .sort((a, b) => a.name.localeCompare(b.name));

    this.permissions = this.authenticationService.permissions;
  }

  resellerChanged(resellerId) {
    this.userContact.accountId = null;
    this.userContact.userId = null;
    this.selectedResellerId = resellerId;
  }

  accountChanged(accountId) {
    this.onAccountChanged.emit(this.userContact.accountId);
    this.userContact.userId = null;

    this.userService.getUsersByAccountId(this.userContact.accountId).subscribe((result) => {
      this.users = result;

      const userId = this.authenticationService.getUserId();

      if (result.map((x) => x.id.toString()).indexOf(userId.toString()) !== -1) {
        this.userContact.userId = +this.authenticationService.getUserId();
      }

      this.loadingUsers = false;
      this.cd.markForCheck();
    });
  }

  ngOnInit(): void {
    this.loadingAccounts = true;
    this.selectedResellerId = +this.authenticationService.getResellerId();
    if (this.userContact) {
      this.userContact.accountId = +this.authenticationService.getAccountId();
      this.onAccountChanged.emit(this.userContact?.accountId);
    }
  }
}
