import { HttpClient } from "@angular/common/http";
import { Component, OnInit, ChangeDetectorRef, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Device, DeviceSettingChange, DeviceSettingChangeResult } from "app/models/device.model";
import { AuditLog } from "../../models/auditlog.model";
import { AuditLogService } from "../../services/auditlog/auditlog.service";
import { DeviceService } from "../../services/device/device.service";
import { TranslateService } from "@ngx-translate/core";
import { DeviceSettingChangeService } from "app/services/device/deviceSettingChange.service";
import { ConfirmationModalComponent } from "../shared/usercontrols/confirmationModal.component";
import { DeviceSettingExchangeStatus } from "app/common/enums";

@Component({
  selector: "fh-device-changes",
  templateUrl: "changes.template.html",
})
export class DeviceChangesViewComponent implements OnInit {
  @ViewChild("deleteModal") deleteModal: ConfirmationModalComponent;
  @ViewChild("cancelModal") cancelModal: ConfirmationModalComponent;

  deviceLog: AuditLog[];
  sub;
  device: Device;
  deviceId;
  deviceSettingChange: DeviceSettingChange;
  loading = false;
  loadingSettingChanges = false;
  error: any;
  success: any;
  settingChanges = [];
  currentPage;
  filter;
  selectedChange;

  permissionName = "Devices_Edit";

  constructor(
    private cd: ChangeDetectorRef,
    private http: HttpClient,
    private auditLogService: AuditLogService,
    private deviceService: DeviceService,
    private deviceSettingChangeService: DeviceSettingChangeService,
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService
  ) {
    this.device = null;
    this.deviceLog = [];
  }

  ngOnInit() {
    this.loading = true;
    this.loadingSettingChanges = true;

    this.device = new Device();
    this.device.id = "";

    this.sub = this.route.params.subscribe({
      next: (params) => {
        const id = params["id"];

        this.deviceId = id;
        this.deviceService.getDeviceById(id).subscribe((device) => {
          this.device = device;

          if (this.device == null) {
            this.router.navigate(["/Devices/Overview"]);
          }

          this.loading = false;
        });

        this.deviceSettingChange = new DeviceSettingChange();
        this.deviceSettingChange.deviceId = Number(id);

        this.reloadChanges();
      },
      error: (error) => {
        this.error = error;
        this.error.statusText = "Error fetching device";

        this.cd.markForCheck();

        setTimeout(() => {
          this.router.navigate(["/Devices/Overview"]);
        }, 3000);
      },
    });
  }

  resendChanges(id) {
    this.loadingSettingChanges = true;

    this.deviceSettingChangeService.resendDeviceSettingChanges(id).subscribe({
      next: (result) => {
        this.error = null;
        this.success = {
          statusText: "Success",
          success: "Setting change is successfully resend.",
        };

        this.reloadChanges();
      },
      error: (error) => {
        this.success = null;
        this.error = error;
        this.loadingSettingChanges = false;
        this.cd.markForCheck();
      },
    });
  }

  showCancel(id) {
    this.cancelModal.showModal(id);
  }

  cancelChanges(id) {
    this.cancelModal.hideModal();
    this.loadingSettingChanges = true;

    this.deviceSettingChangeService.cancelDeviceSettingChanges(id).subscribe({
      next: (result) => {
        this.error = null;
        this.success = {
          statusText: "Success",
          success: "Setting change is successfully canceled.",
        };

        this.reloadChanges();
      },
      error: (error) => {
        this.success = null;
        this.error = error;
        this.loadingSettingChanges = false;
        this.cd.markForCheck();
      },
    });
  }

  showDelete(id) {
    this.deleteModal.showModal(id);
  }

  deleteChanges(id) {
    this.deleteModal.hideModal();
    this.loadingSettingChanges = true;

    this.deviceSettingChangeService.deleteDeviceSettingChanges(id).subscribe({
      next: (result) => {
        this.error = null;
        this.success = {
          statusText: "Success",
          success: "Setting change is successfully deleted.",
        };

        this.reloadChanges();
      },
      error: (error) => {
        this.success = null;
        this.error = error;
        this.loadingSettingChanges = false;
        this.cd.markForCheck();
      },
    });
  }

  addNewChange() {
    this.loadingSettingChanges = true;

    console.log(this.deviceSettingChange);
    this.deviceSettingChangeService.sendDeviceSettingChanges(this.deviceSettingChange).subscribe({
      next: (result) => {
        this.error = null;
        this.success = {
          statusText: "Success",
          success: result.message,
        };

        this.reloadChanges();
      },
      error: (error) => {
        this.success = null;
        this.error = error;
        this.loadingSettingChanges = false;
        this.cd.markForCheck();
      },
    });
  }

  refresh() {
    this.loadingSettingChanges = true;
    this.reloadChanges();
  }

  reloadChanges() {
    this.sub = this.route.params.subscribe((params) => {
      const id = params["id"];

      this.deviceSettingChangeService.getDeviceSettingChangesByDeviceId(id).subscribe((result) => {
        this.settingChanges = result;
        this.loadingSettingChanges = false;
        this.cd.markForCheck();
      });
    });
  }

  isStatusScheduled(setting: DeviceSettingChangeResult): boolean {
    if (setting.status === DeviceSettingExchangeStatus.Scheduled) {
      return true;
    } else {
      return false;
    }
  }
}
