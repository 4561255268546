<div class="vertical-center" (document:mousemove)="onMouseMove($event)">
    <div class="page-content-inner login-background">
        <div class="container loginscreen" style="display: table-cell; vertical-align: middle; height: 100% !important">
            <div class="login-box well single-page-block-inner blur-placeholder effect-3d-element">
                <div class="text-center" style="min-height: 145px">
                    <div class="alert alert-danger" *ngIf="error">
                        <strong>{{error}}</strong>
                    </div>

                    <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '14px' }"></ngx-loading>
                    <form class="" #f="ngForm" action="#">
                        <div class="site_login_logo"></div>
                        <h1 style="font-size: 40px; padding-bottom: 20px; display: none" class="text-center">
                            {{'general.title' | translate }}
                        </h1>
                        <div
                            class="form-group col-sm-12"
                            [ngClass]="{ 'has-error': TheUserName.invalid && (TheUserName.dirty || TheUserName.touched) }"
                        >
                            <input
                                name="username"
                                value=""
                                id="username-email"
                                placeholder='{{"login.emailoruser" | translate }}'
                                [(ngModel)]="UserName"
                                autocomplete="on"
                                pattern="[^ @]*@[^ @]*"
                                type="text"
                                class="form-control"
                                required
                                #TheUserName="ngModel"
                            />
                        </div>
                        <div
                            class="error col-sm-12"
                            *ngIf="TheUserName.errors && (TheUserName.dirty || TheUserName.touched)"
                        >
                            <p *ngIf="TheUserName.errors.required">{{'login.emailrequired' | translate }}</p>
                            <p *ngIf="TheUserName.errors.pattern">{{'login.emailpattern' | translate }}</p>
                        </div>
                        <div
                            class="form-group col-sm-12"
                            [ngClass]="{ 'has-error': ThePassword.invalid && (ThePassword.dirty || ThePassword.touched) }"
                        >
                            <input
                                name="password"
                                id="password"
                                value=""
                                placeholder='{{"login.password" | translate }}'
                                type="password"
                                [(ngModel)]="Password"
                                minlength="4"
                                class="form-control"
                                required
                                #ThePassword="ngModel"
                            />
                        </div>
                        <div
                            class="error col-sm-12"
                            *ngIf="ThePassword.errors && (ThePassword.dirty || ThePassword.touched)"
                        >
                            <p *ngIf="ThePassword.errors.required">{{'login.passwordrequired' | translate }}</p>
                            <p *ngIf="ThePassword.errors.minlength">{{'login.passwordminlength' | translate }}</p>
                        </div>

                        <!-- <div class='col-sm-12'>
                                <label class="form-check-label">
                                    <input type="checkbox" class="form-check-input">
                                    {{'login.rememberme' | translate }}
                                </label>
                            </div> -->
                        <!-- <div class='col-sm-3 '>
                                <button class="btn btn-primary btn-block" [routerLink]="['/Register']" [disabled]="true">
                                    <i class="fa fa-fw fa-user"></i> {{'login.register' | translate }}</button>
                            </div> -->
                        <div style="clear: both"></div>
                        <div class="col-sm-12">
                            <button
                                class="btn btn-primary btn-block"
                                type="submit"
                                (click)="clicked($event)"
                                [disabled]="(ThePassword.touched && !Password) || (TheUserName.touched && !UserName)"
                                (keyup.enter)="clicked($event)"
                            >
                                <!--  [disabled]="!f.valid" -->
                                <i class="fa fa-fw fa-unlock"></i> {{'login.signin' | translate }}
                            </button>
                        </div>
                    </form>

                    <div class="col-sm-12">
                        <p></p>
                    </div>

                    <div class="col-sm-12">
                        <span
                            class="form-check-label secondary"
                            style="font-weight: bold"
                            role="button"
                            [routerLink]="['/ForgotPassword']"
                        >
                            {{'login.forgotPassword' | translate }}?
                        </span>
                        <p>&nbsp;</p>
                    </div>

                    <footer class="col-sm-12">
                        <div class="copy-text col-sm-12">
                            {{'login.footer' | translate }}
                            <a href="https://360locate.com" target="_blank">360locate</a> - {{appVersion}}
                        </div>
                    </footer>
                </div>
            </div>
        </div>
    </div>
</div>
