import { Component, OnInit } from "@angular/core";
import { NotificationsService } from "app/modules/notification";
import { AssetService } from "app/services/asset/asset.service";
import { DeviceService } from "app/services/device/device.service";
import { DriverService } from "app/services/driver/driver.service";

@Component({
  selector: "fh-general-sttings",
  templateUrl: "general.template.html",
})
export class SettingsGeneralComponent implements OnInit {
  loading: boolean;

  constructor(
    private deviceService: DeviceService,
    private driverService: DriverService,
    private assetService: AssetService,
    protected notificationsService: NotificationsService
  ) {}

  ngOnInit(): void {}

  ResetDeviceCache() {
    this.loading = true;
    this.deviceService.resetDeviceCache().subscribe((result) => {
      this.loading = false;
      this.notificationsService.addInfo("Update", "Device cache has been cleared");
      console.log(result);
    });
  }

  ResetAssetCache() {
    this.loading = true;
    this.assetService.resetAssetCache().subscribe((result) => {
      this.loading = false;
      this.notificationsService.addInfo("Update", "Asset cache has been cleared");
      console.log(result);
    });
  }

  ResetDriverCache() {
    this.loading = true;
    this.driverService.resetCache().subscribe((result) => {
      this.loading = false;
      this.notificationsService.addInfo("Update", "Driver cache has been cleared");
      console.log(result);
    });
  }
}
