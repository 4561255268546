import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { environment } from "app/../environments/environment";

@Component({
  selector: "fh-blank",
  templateUrl: "blankLayout.template.html",
})
export class BlankLayoutComponent {
  constructor(private titleService: Title) {
    this.titleService.setTitle(environment.ApplicationName);
  }
}
