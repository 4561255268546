import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { appDate, appVersion } from "../../app.version";
import { AuthenticationService } from "../../services/authentication/authentication.service";
import { UserService } from "../../services/users/user.service";

@Component({
  templateUrl: "./register.template.html",
  styleUrls: ["./login.template.css"],
})
export class RegisterComponent {
  model: any = {};
  loading = false;

  appDate: string;
  appVersion: string;
  UserName = "";
  Password = "";
  error: string;
  data: string;
  returnUrl;
  subscription;
  param = null;
  errorLoggedIn = false;

  constructor(
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private userService: UserService
  ) {
    this.subscription = route.queryParams.subscribe((queryParam: any) => (this.param = queryParam["prev"]));
    this.appVersion = appVersion;
    this.appDate = appDate;
  }

  onMouseMove(event) {
    const e = event;
    // Mouse Move 3d Effect

    const perX = e.clientX / $(window).width() - 0.5;
    const perY = e.clientY / $(window).height() - 0.5;
    // TweenMax.to('.effect-3d-element', 0.4, { rotationY: 5 * perX, rotationX: 5 * perY, transformPerspective: 1000, transformOrigin: 'center' })
  }

  register() {
    this.loading = true;

    // setTimeout(function () {
    //   this.userService.createUser(this.model).subscribe({
    //     next: (data) => {
    //       this.router.navigate(["/Login"]);
    //     },
    //     error: (error) => {
    //       this.loading = false;
    //     },
    //   });
    // }, 2000);
  }
}
