import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Observable, catchError, map, throwError } from "rxjs";
import { AuthenticationService } from "../authentication/authentication.service";
import { HierarchyGroup } from "app/models/hierarchyGroup.model";

@Injectable()
export class HierarchieService {
  base_url = "";
  url = "";

  constructor(private http: HttpClient, private authenticationService: AuthenticationService) {
    this.base_url = this.authenticationService.getWebserviceURL("");
    this.url = this.authenticationService.getWebserviceURL("hierarchy");
  }

  getPagingUrl() {
    return this.url + `Paging`;
  }

  getHierarchyGroups(): Observable<any> {
    return this.http.get(this.url, { headers: this.authenticationService.headers }).pipe(
      map((data: any) => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  getNestedHierarchyGroups(): Observable<any> {
    return this.http.get(this.url + "Nested", { headers: this.authenticationService.headers }).pipe(
      map((data: any) => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  getHierarchyGroupsByAccountId(hierachyGroupId: any): Observable<any> {
    return this.http.get(this.url + "Account/" + hierachyGroupId, { headers: this.authenticationService.headers }).pipe(
      map((data: any) => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  getHierarchyGroupById(hierachyGroupId: any): Observable<any> {
    return this.http.get(this.url + hierachyGroupId, { headers: this.authenticationService.headers }).pipe(
      map((data: any) => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  deleteHierarchyGroupBy(HierarchyGroup: HierarchyGroup) {
    console.log(this.url + HierarchyGroup.groupId);
    return this.http
      .delete(this.url + HierarchyGroup.groupId, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  saveHierarchyGroupBy(HierarchyGroup: HierarchyGroup): Observable<any> {
    return this.http
      .post(this.url, HierarchyGroup, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  updateHierarchyGroup(HierarchyGroup: HierarchyGroup) {
    return this.http
      .put(this.url + HierarchyGroup.groupId, HierarchyGroup, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  private handleError(error: Response) {
    return throwError(() => error);
  }
}
