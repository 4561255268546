import { HttpClient } from "@angular/common/http";
import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Device } from "app/models/device.model";
import { AuditLog } from "../../models/auditlog.model";
import { DeviceService } from "../../services/device/device.service";
import { TranslateService } from "@ngx-translate/core";
import { BsDaterangepickerConfig } from "ngx-bootstrap/datepicker";
import { getDefaultDpConfig } from "app/common/gridhelper";
import { AuthenticationService } from "app/services/authentication/authentication.service";
import { NotificationLogService } from "app/services/notificationlog/notificationlog.service";

// Moment timezone
import Moment from "moment-timezone";
window["moment"] = Moment;

@Component({
  selector: "fh-device-notifications",
  templateUrl: "notifications.template.html",
})
export class DeviceNotificationsViewComponent implements OnInit {
  deviceLog: AuditLog[];
  sub;
  device: Device;
  deviceId;

  loading = false;
  loadingNotifications = false;
  loadingTriggers = false;

  error: any;
  success: any;
  notifications = [];
  triggers = [];
  selectedNotification;

  currentPage;
  filter;

  // Daterange
  public dpConfig: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();
  daterangepickerModel: Date[];
  permissions: {};
  timezoneIana: string;

  constructor(
    private authenticationService: AuthenticationService,
    private cd: ChangeDetectorRef,
    private http: HttpClient,
    private notificationLogService: NotificationLogService,
    private deviceService: DeviceService,
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService
  ) {
    this.permissions = this.authenticationService.permissions;

    this.device = null;
    this.deviceLog = [];

    this.timezoneIana = this.authenticationService.getTimeZoneIana();

    this.daterangepickerModel = [
      Moment().tz(this.timezoneIana).subtract(1, "month").startOf("day").toDate(),
      Moment().tz(this.timezoneIana).endOf("day").toDate(),
    ];

    this.dpConfig = getDefaultDpConfig(this.authenticationService);
  }

  dateChanged(event) {
    this.loadingNotifications = true;
    this.error = null;

    if (event !== null) {
      this.daterangepickerModel = event;
    }

    this.notificationLogService
      .getNoticationLogByDeviceId(
        this.device.id,
        Moment(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf("day"),
        Moment(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf("day")
      )
      .subscribe({
        next: (result) => {
          this.notifications = result;
          this.loadingNotifications = false;
          this.cd.markForCheck();
        },
        error: (error) => {
          this.error = error;
          this.loadingNotifications = false;
          this.cd.markForCheck();
        },
      });
  }

  ngOnInit() {
    this.loading = true;
    this.loadingNotifications = true;
    this.device = new Device();
    this.device.id = "";

    this.sub = this.route.params.subscribe({
      next: (params) => {
        const id = params["id"];

        this.deviceId = id;
        this.deviceService.getDeviceById(id).subscribe((device) => {
          this.device = device;

          if (this.device == null) {
            this.router.navigate(["/Devices/Overview"]);
          }

          this.loading = false;

          this.dateChanged(null);
        });
      },
      error: (error) => {
        this.error = error;
        this.error.statusText = "Error fetching device";

        setTimeout(() => {
          this.router.navigate(["/Devices/Overview"]);
        }, 3000);
      },
    });
  }
}
