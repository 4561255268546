export class UserContact {
  accountId: any;
  id: any;
  firstName: any;
  lastName: any;
  companyName: any;
  webserviceToken: any;
  email: any;
  emailLoginName: any;
  userId: any;
  userContactType = 0;
  msisdn: any;

  isSmsEnabled: any;
  isEmailEnabled: any;
  isPushEnabled: any;

  resellerDescription: any;
  resellerId: any;
}
