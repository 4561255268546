import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormMode } from "app/common/enums";
import { EntityType } from "app/common/enums";
import { AuthenticationService } from "app/services/authentication/authentication.service";
import { AppUser } from "app/models/user.model";
import { FhChartService } from "app/services/charts/charts.service";
import { CarSharingService } from "app/services/carsharing/carsharing.service";

// Moment timezone
import Moment from "moment-timezone";
import { CarSharingGarage } from "app/models/carsharingGarage.model";

window["moment"] = Moment;

@Component({
  providers: [FhChartService],
  selector: "fh-fh-garage-details",
  templateUrl: "garageDetails.template.html",
})
export class CarSharingGaragesDetailsViewComponent implements OnInit {
  sub: any;
  garage: CarSharingGarage;
  device: any;
  tagType = EntityType.Garage.valueOf();
  formMode = FormMode.read;
  formModeWasl = FormMode.read;

  loading = false;
  saving = false;

  filter;

  error: any;
  success: any;

  permissions: {};

  permissionName = "Modules_HasPrivateFleet";

  waslSuccess: { statusText: string; success: any };
  waslError: any;

  activeDevices = 0;
  activeDrivers = 0;
  activeGeofences = 0;

  geofences = [];
  loadingGeofences = false;
  loadingUsers = false;
  loadingKpi = false;
  users: AppUser[];

  loadingDeviceCount = false;
  chartDeviceCount: any;

  // Pagination
  totalItems = 0;
  currentPage = 1;
  smallnumPages = 0;
  deviceCountData = [];
  distanceChartData = [];
  loadingCount = false;

  constructor(
    private router: Router,
    private cd: ChangeDetectorRef,
    private authentication: AuthenticationService,
    private carsharingService: CarSharingService,
    private route: ActivatedRoute
  ) {
    this.permissions = this.authentication.permissions;
  }

  ngOnInit() {
    this.loading = true;
    this.sub = this.route.params.subscribe((params) => {
      const id = params["id"];

      this.carsharingService.getGarageById(id).subscribe({
        next: (garage) => {
          this.garage = garage;
          this.loading = false;
        },
        error: (error) => {
          this.error = error;
          this.error.statusText = "Error fetching garage";

          this.cd.markForCheck();

          setTimeout(() => {
            this.router.navigate(["/CarSharing/Garages"]);
          }, 3000);
        },
      });
    });
  }

  // Form

  onDelete() {
    this.loading = true;

    this.carsharingService.deleteGarage(this.garage).subscribe({
      next: (_) => {
        this.error = null;
        this.success = {
          statusText: "Success",
          success: "Garage is successfully deleted.",
        };

        setTimeout(() => {
          this.router.navigate(["/CarSharing/Garages"]);
        }, 3000);
      },
      error: (error) => {
        this.success = null;
        this.error = error;
        this.loading = false;
        this.cd.markForCheck();
      },
    });
  }

  onSave() {
    this.saving = true;

    this.carsharingService.updateGarage(this.garage).subscribe({
      next: (_) => {
        this.error = null;
        this.success = {
          statusText: "Success",
          success: "Garage is successfully updated.",
        };

        this.saving = false;
        this.cd.markForCheck();

        this.setFormMode(FormMode.read);
      },
      error: (error) => {
        this.saving = false;
        this.success = null;
        this.error = error;
        this.cd.markForCheck();
      },
    });
  }

  setFormMode(mode) {
    this.formMode = mode;

    if (this.formMode === FormMode.read) {
      this.loading = true;
      this.getGarageInfo();
    }
  }

  setFormModeWasl(mode) {
    this.formModeWasl = mode;

    if (this.formModeWasl === FormMode.read) {
      this.getGarageInfo();
    }
  }

  getGarageInfo() {
    this.carsharingService.getGarageById(this.garage.id).subscribe((garage) => {
      this.garage = garage;
      this.loading = false;
    });
  }
}
