import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "environments/environment";
// Moment timezone
import Moment from "moment-timezone";
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { AuthenticateResult } from "../../models/authenticateresult.model";
import { AuthenticationService } from "../../services/authentication/authentication.service";

window["moment"] = Moment;

@Component({
  templateUrl: "./login.template.html",
  styleUrls: ["./login.template.css"],
})
export class LoginComponent {
  appVersion: string;
  UserName = "";
  Password = "";
  error: string;
  data: string;
  returnUrl;
  subscription;
  param = null;
  redirect = "";
  loading = false;
  errorLoggedIn = false;
  errorExtended: string;
  ssoEnabled = false;
  loginAsRegularUser = false;

  constructor(
    private localeService: BsLocaleService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    this.ssoEnabled = environment.SSOEnabled;

    this.subscription = this.route.queryParams.subscribe((queryParam: any) => {
      this.param = queryParam["prev"];
      this.redirect = queryParam["redirect"];
    });

    this.appVersion = environment.version;
  }

  onMouseMove(event) {
    const e = event;
    // Mouse Move 3d Effect

    const perX = e.clientX / $(window).width() - 0.5;
    const perY = e.clientY / $(window).height() - 0.5;
    // TweenMax.to('.effect-3d-element', 0.4, { rotationY: 5 * perX, rotationX: 5 * perY, transformPerspective: 1000, transformOrigin: 'center' })
  }

  clicked(event) {
    console.log("Clicked login -> Authenticating");
    this.loading = true;

    if (this.UserName.length === 0 || this.Password.length === 0) {
      const email = this.translate.instant("login.emailoruser");
      const pass = this.translate.instant("login.password");

      this.error = email + " and " + pass + " is required";
      this.loading = false;
    } else {
      this.authenticationService.performLogin(this.UserName, this.Password, this.loginAsRegularUser, null).subscribe({
        next: (result) => {
          this.loading = false;
          if (result !== null) {
            this.loginCompleted(result);
          } else {
            this.error = this.translate.instant("login.warningincorrect");
          }
        },
        error: (error) => {
          this.errorLoggedIn = true;

          if (error.status === 401) {
            this.error = this.translate.instant("login.warningincorrect");
          } else if (error.status === 409) {
            this.error = this.translate.instant("login.warningpermission");
          } else {
            this.error = this.translate.instant("login.warningapi");
          }

          this.errorExtended = error.message + " : " + error.statusText;
          this.loading = false;
        },
      });
    }
  }

  private loginCompleted(authenticateResult: AuthenticateResult) {
    this.loading = true;

    if (this.translate.currentLang !== authenticateResult.user.culture) {
      console.log("Use user language " + authenticateResult.user.culture);
      this.translate.use(authenticateResult.user.culture.substring(0, 2));
      Moment.locale(authenticateResult.user.culture.substring(0, 2));
      this.localeService.use(authenticateResult.user.culture.substring(0, 2));
    }

    let urlBlock = decodeURI(this.redirect).split("?");
    let paramsMap = urlBlock[1]?.split("&").reduce((p, c) => {
      let components = c.split("=");
      p[components[0]] = components[1];
      return p;
    }, new Map<string, string>());

    if (this.redirect && urlBlock[0] != "/Login") {
      this.router.navigate([urlBlock[0]], { queryParams: paramsMap });
    } else {
      this.router.navigate(["/"]);
    }
  }
}
