import { Component, OnInit, ChangeDetectorRef, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormMode } from "app/common/enums";
import { Trigger } from "app/models/trigger.model";
import { EntityType } from "app/common/enums";
import { AuthenticationService } from "app/services/authentication/authentication.service";

import * as Highcharts from "highcharts";

// Moment timezone
import Moment from "moment-timezone";
import { TriggerService } from "app/services/triggers/triggers.service";
import { FhChartService } from "app/services/charts/charts.service";
import { TriggerRuleDetailsComponent } from "../../shared/usercontrols/triggerRuleDetails.component";

@Component({
  providers: [FhChartService],
  selector: "fh-fh-trigger-dashboard",
  templateUrl: "triggerDashboard.template.html",
})
export class TriggerDashboardViewComponent implements OnInit {
  Highcharts: typeof Highcharts = Highcharts;

  @ViewChild("ruleConfiguration") ruleConfiguration: TriggerRuleDetailsComponent;
  sub: any;
  trigger = new Trigger();
  device: any;
  tagType = EntityType.Trigger.valueOf();
  formMode = FormMode.read;

  loading = false;
  saving = false;

  success: any;
  error: any;
  warning: any;

  permissions: {};

  loadingCount = false;
  loadingAssets = false;
  loadingEpisodeCount = false;

  currentPage;
  filter;
  assets = [];
  triggerCount = [];

  permissionName = "Triggers_View";

  chartCount;
  timeZoneIana: string;
  daterangepickerModel: any[];
  timezoneIana: string;

  constructor(
    private router: Router,
    private cd: ChangeDetectorRef,
    private chartService: FhChartService,
    private authentication: AuthenticationService,
    private triggerService: TriggerService,
    private route: ActivatedRoute
  ) {
    this.permissions = this.authentication.permissions;

    this.timeZoneIana = this.authentication.getTimeZoneIana();

    this.daterangepickerModel = [
      Moment().subtract(1, "months").startOf("week").toDate(),
      Moment().add(0, "days").endOf("day").toDate(),
    ];

    this.timezoneIana = this.authentication.getTimeZoneIana();
  }

  testMessage() {
    this.loading = true;
    this.triggerService.testTrigger(this.trigger).subscribe({
      next: (trigger) => {
        this.warning = null;
        this.success = {
          statusText: "Success",
          success: "Trigger has been fired!",
        };
        this.loading = false;
        this.cd.markForCheck();
      },
      error: (error) => {
        this.loading = false;
        this.error = error;
        this.error.statusText = "Error testing trigger";

        this.cd.markForCheck();
      },
    });
  }

  ngOnInit() {
    this.loading = true;
    this.sub = this.route.params.subscribe((params) => {
      const id = params["id"];

      this.getTriggerInfo(id);
    });
  }

  getTriggerInfo(id = null) {
    if (this.loading !== true) {
      this.loading = true;
    }

    // Get from current triggert
    if (id == null) {
      id = this.trigger.id;
    }

    this.triggerService.getTriggerById(id).subscribe({
      next: (trigger) => {
        this.trigger = trigger;
        this.loading = false;

        this.cd.markForCheck();
      },
      error: (error) => {
        this.error = error;
        this.error.statusText = "Error fetching trigger";

        this.cd.markForCheck();

        setTimeout(() => {
          this.router.navigate(["/Notifications/Triggers/Overview"]);
        }, 3000);
      },
    });

    this.loadingAssets = true;
    this.triggerService.getTriggerAssetsById(id).subscribe({
      next: (assets) => {
        this.assets = assets;

        this.loadingAssets = false;
        this.cd.markForCheck();
      },
      error: (error) => {
        this.error = error;
        this.error.statusText = "Error fetching trigger assets";
        this.cd.markForCheck();
      },
    });

    this.loadingEpisodeCount = true;
    this.triggerService
      .getTriggerCountById(
        id,
        Moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf("day"),
        Moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf("day")
      )
      .subscribe({
        next: (count) => {
          this.triggerCount = count;
          this.loadingEpisodeCount = false;

          this.generateCount(count);
          this.cd.markForCheck();
        },
        error: (error) => {
          this.error = error;
          this.error.statusText = "Error fetching trigger count";
          this.cd.markForCheck();
        },
      });
  }

  // Generate chart locationcount
  generateCount(source) {
    const theEpisodesData = [];
    const theAppNoticationsData = [];
    const theFMNoticationsData = [];
    const theEmailData = [];
    const theSmsData = [];
    const theWebhookEndpointData = [];

    source.forEach((item) => {
      theEpisodesData.push({
        x: Moment.utc(item.summaryDate).toDate().getTime(),
        y: Math.round((item.episodeCount + Number.EPSILON) * 100) / 100,
      });
      theAppNoticationsData.push({
        x: Moment.utc(item.summaryDate).toDate().getTime(),
        y: Math.round((item.appNotificationCount + Number.EPSILON) * 100) / 100,
      });
      theFMNoticationsData.push({
        x: Moment.utc(item.summaryDate).toDate().getTime(),
        y: Math.round((item.fmNotificationCount + Number.EPSILON) * 100) / 100,
      });
      theSmsData.push({
        x: Moment.utc(item.summaryDate).toDate().getTime(),
        y: Math.round((item.smsNotificationCount + Number.EPSILON) * 100) / 100,
      });
      theEmailData.push({
        x: Moment.utc(item.summaryDate).toDate().getTime(),
        y: Math.round((item.emailNotificationCount + Number.EPSILON) * 100) / 100,
      });
      theWebhookEndpointData.push({
        x: Moment.utc(item.summaryDate).toDate().getTime(),
        y: Math.round((item.webhookEndpointNotificationCount + Number.EPSILON) * 100) / 100,
      });
    });

    const theCategories = [];

    const theChartData = [
      {
        name: "Episodes",
        data: theEpisodesData,
        type: "area",
        dashStyle: "dash",
        fillOpacity: 0.5,
        opacity: 1,
        marker: {
          enabled: false,
          lineWidth: 1,
          symbol: "square",
        },
      },
      {
        name: "App Notifications",
        data: theAppNoticationsData,
        type: "area",
        dashStyle: "dash",
        fillOpacity: 0.5,
        opacity: 1,
        marker: {
          enabled: false,
          lineWidth: 1,
          symbol: "square",
        },
      },
      {
        name: "FM Notifications",
        data: theFMNoticationsData,
        type: "area",
        dashStyle: "dash",
        fillOpacity: 0.5,
        opacity: 1,
        marker: {
          enabled: false,
          lineWidth: 1,
          symbol: "square",
        },
      },
      {
        name: "Email",
        data: theEmailData,
        type: "area",
        dashStyle: "dash",
        fillOpacity: 0.5,
        opacity: 1,
        marker: {
          enabled: false,
          lineWidth: 1,
          symbol: "square",
        },
      },
      {
        name: "SMS",
        data: theSmsData,
        type: "area",
        dashStyle: "dash",
        fillOpacity: 0.5,
        opacity: 1,
        marker: {
          enabled: false,
          lineWidth: 1,
          symbol: "square",
        },
      },
      {
        name: "Webhooks",
        data: theWebhookEndpointData,
        type: "area",
        dashStyle: "dash",
        fillOpacity: 0.5,
        opacity: 1,
        marker: {
          enabled: false,
          lineWidth: 1,
          symbol: "square",
        },
      },
    ];

    this.chartCount = this.chartService.generateColumnChartDates(
      theChartData,
      {},
      theCategories,
      undefined,
      "normal",
      true
    );

    this.cd.markForCheck();
  }
}
