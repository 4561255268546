import { Component, Input, OnInit, OnChanges } from "@angular/core";
import { ControlContainer, NgForm, FormGroup } from "@angular/forms";
import { FormMode } from "app/common/enums";
import { CustomCommand } from "app/models/customCommand.model";
import { AuthenticationService } from "app/services/authentication/authentication.service";
import { Device } from "app/models/device.model";
import { CustomCommandImplementation } from "app/models/customCommand.model";
import { DeviceType } from "app/models/devicetype.model";
import { ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core";
import { CustomCommandService } from "app/services/customcommand/customcommand.service";
import { Router } from "@angular/router";
import { DeviceTypeService } from "app/services/devicetypes/devicetypes.service";

@Component({
  selector: "fh-custom-command-details",
  templateUrl: "customCommandDetails.component.html",
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomCommandDetailsComponent implements OnInit, OnChanges {
  @Input() customCommand: CustomCommand;
  @Input() loading = false;
  @Input() form;
  @Input() invalidSettings: boolean = false;

  @Input() formMode: any = FormMode.read;

  FormMode = FormMode;

  permissions = {};

  device: Device = new Device();
  commands: CustomCommandImplementation[] = [];

  sub;
  saving = false;

  error: any;
  success: any;

  formGroup: FormGroup;

  duplicateInputs: { [id: number]: number } = {};

  loadingDeviceTypes: boolean;
  deviceTypes: DeviceType[] = [];
  userId: string;

  constructor(
    private authenticationService: AuthenticationService,
    private cd: ChangeDetectorRef,
    private customCommandService: CustomCommandService,
    private router: Router,
    private deviceTypeService: DeviceTypeService
  ) {
    this.permissions = this.authenticationService.permissions;
    this.userId = this.authenticationService.getUserId();

    this.deviceTypeService.getDeviceTypes().subscribe((result) => {
      this.deviceTypes = result;
      this.cd.markForCheck();
    });
  }

  ngOnInit(): void {
    if (this.formMode == FormMode.add) {
      this.customCommand = new CustomCommand();
      this.addCustomCommandImplementation();
    }
  }

  ngAfterViewInit(): void {
    this.formGroup = this.form.form;
  }

  ngOnChanges(): void {}

  resellerChanged(event) {
    this.customCommand.accountId = null;
  }

  accountChanged(event) {}

  addCustomCommandImplementation() {
    const newCommand = new CustomCommandImplementation();
    this.commands.push(newCommand);
  }

  getCustomCommand(customCommandId) {
    this.loading = true;
    this.cd.markForCheck();

    this.commands = [];

    this.customCommandService.getCommandById(customCommandId).subscribe((customCommand) => {
      this.customCommand = customCommand;

      this.customCommand.implementations.forEach((implementation) => {
        const newCommand = new CustomCommandImplementation();
        newCommand.deviceTypeId = implementation.deviceTypeId;
        newCommand.value = implementation.value;
        this.commands.push(newCommand);
      });

      this.checkDuplicate();

      this.loading = false;
      this.cd.markForCheck();
    });
  }

  deleteCommand(command: CustomCommandImplementation) {
    const index: number = this.commands.indexOf(command);
    if (index !== -1) {
      this.commands.splice(index, 1);
    }

    this.cd.markForCheck();
  }

  setFormMode(mode) {
    this.formMode = mode;
    if (this.formMode === FormMode.read) {
      this.getCustomCommand(this.customCommand.id);
    }
  }

  checkDuplicate() {
    this.duplicateInputs = {};
    let nonDuplicateList = [];

    let formFields = Object.keys(this.formGroup?.value);

    formFields.forEach((field) => {
      if (field.startsWith("deviceType")) {
        nonDuplicateList.push(field);
      }
    });

    for (const key of nonDuplicateList) {
      if (this.formGroup.value[key] !== undefined) {
        this.duplicateInputs[this.formGroup.value[key]] = (this.duplicateInputs[this.formGroup.value[key]] ?? -1) + 1;
        delete this.duplicateInputs[0];
      }
    }

    this.invalidSettings = !Object.values(this.duplicateInputs).every((x) => x === 0);

    this.cd.markForCheck();
  }

  onInsert() {
    this.saving = true;

    //Fill in correct data before it is saved
    this.customCommand.implementations = this.commands;

    this.customCommandService.createCustomCommand(this.customCommand).subscribe(
      (result) => {
        this.error = null;
        this.success = {
          statusText: "Success",
          success: "Custom command is successfully added",
        };

        this.cd.markForCheck();

        setTimeout(() => {
          this.router.navigate(["/Devices/CustomCommands/Overview/"]);
        }, 3000);
      },
      (error) => {
        this.saving = false;
        this.success = null;
        this.error = error;

        this.cd.markForCheck();
      }
    );
  }

  onSave() {
    this.saving = true;

    //Fill in correct data before it is saved
    this.customCommand.implementations = this.commands;

    this.customCommandService.updateCustomCommand(this.customCommand.id, this.customCommand).subscribe(
      (result) => {
        this.error = null;
        this.success = {
          statusText: "Success",
          success: "Custom command is successfully updated",
        };

        this.setFormMode(FormMode.read);

        this.cd.markForCheck();
      },
      (error) => {
        this.saving = false;
        this.success = null;
        this.error = error;

        this.cd.markForCheck();
      }
    );
  }

  onDelete() {
    this.loading = true;

    this.customCommandService.deleteCustomCommand(this.customCommand.id).subscribe(
      (result) => {
        this.error = null;
        this.success = {
          statusText: "Success",
          success: "Custom command is successfully deleted.",
        };

        this.cd.markForCheck();

        setTimeout(() => {
          this.router.navigate(["/Devices/CustomCommands/Overview"]);
        }, 3000);
      },
      (error) => {
        this.success = null;
        this.error = error;
        this.loading = false;
        this.cd.markForCheck();
      }
    );
  }
}
