import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { CopyInputBoxComponent } from "./copyInputBox.component";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  imports: [CommonModule, FormsModule, TooltipModule.forRoot(), TranslateModule],
  declarations: [CopyInputBoxComponent],
  exports: [CopyInputBoxComponent, TranslateModule],
})
export class CopyInputBoxModule {}
