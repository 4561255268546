<div class="col-lg-12">
    <div class="horizontalMenuWrapper">
        <ul>
            <li [ngClass]="{active: activeRoute('GeofenceDetails/Index')}">
                <a [routerLink]="['/GeofenceDetails/Index/', geofenceId]">
                    <i class="fa fa-fw fa-draw-polygon"></i>
                    <div>{{ 'menu.dashboard' | translate }}</div>
                </a>
            </li>
            <li
                *ngIf="permissions['FleetManagement_GeofenceStates']"
                [ngClass]="{active: activeRoute('GeofenceDetails/GeofenceStates')}"
            >
                <a [routerLink]="['/GeofenceDetails/GeofenceStates/', geofenceId]">
                    <i class="fa fa-fw fa-hexagon"></i>
                    <div>{{ 'general.geofenceStates' | translate }}</div>
                </a>
            </li>
            <li
                *ngIf="permissions['Platform_IsReseller']"
                [ngClass]="{active: activeRoute('GeofenceDetails/GeofenceEvents')}"
            >
                <a [routerLink]="['/GeofenceDetails/GeofenceEvents/', geofenceId]">
                    <i class="fa fa-fw fa-hexagon"></i>
                    <div>{{ 'general.geofenceEvents' | translate }}</div>
                </a>
            </li>
            <li
                *ngIf="permissions['Platform_IsReseller']"
                [ngClass]="{active: activeRoute('GeofenceDetails/GeofenceEpisodes')}"
            >
                <a [routerLink]="['/GeofenceDetails/GeofenceEpisodes/', geofenceId]">
                    <i class="fa fa-fw fa-hexagon"></i>
                    <div>{{ 'general.geofenceEpisodes' | translate }}</div>
                </a>
            </li>
            <li *ngIf="permissions['FleetManagement_Log']" [ngClass]="{active: activeRoute('UserDetails/Log')}">
                <a [routerLink]="['/GeofenceDetails/Log/', geofenceId]">
                    <i class="fas fa-fw fa-file-alt"></i>
                    <div>{{ 'menu.log' | translate }}</div>
                </a>
            </li>
        </ul>
    </div>
    <div class="horizontalMenuWrapper" style="float: right">
        <ul>
            <ng-content></ng-content>
        </ul>
    </div>
</div>
<div style="clear: both"></div>
