<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/"> <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Warehouses/Overview">{{'general.warehouses' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/WarehouseDetails/Index/{{warehouse?.id}}">{{warehouse?.name}}</a>
                </li>
                <li class="breadcrumb-item active">{{'menu.externalApi' | translate }}</li>
            </ol>
        </nav>

        <span class="fa-filter-container noselect">
            <a
                style="margin-left: 2px"
                class="btn btn-info margin-fix d-none d-md-inline-flex"
                title="Help"
                href="https://help.360locate.com/knowledge-base/fleet-management/warehouse-monitoring/"
                target="_blank"
                ><i class="fa fa-fa fa-book" style="margin-right: 6px"></i>
                <span class="d-none d-md-inline-flex">{{'general.needHelp' | translate }}</span></a
            >
        </span>
    </div>
    <fh-warehouse-details-navigation [warehouseId]="warehouseId"></fh-warehouse-details-navigation>
</div>

<div class="wrapper wrapper-content">
    <ng-container *ngIf="account?.properties?.wasl?.referenceKey; else accountSetup">
        <div class="row" data-equalizer="">
            <div class="col-lg-12">
                <fh-error [error]="error" [warning]="warning" [success]="success" [autoRemove]="false"></fh-error>
            </div>
        </div>
        <div class="row" data-equalizer="">
            <div class="col-lg-6">
                <div class="ibox float-e-margins">
                    <div class="ibox-title">
                        <h5><i class="fa fa-link"></i> {{'general.warehouse' | translate }}</h5>
                    </div>
                    <div class="ibox-content slick" style="position: relative">
                        <ngx-loading [show]="loading || loadingZones"></ngx-loading>
                        <table style="width: 100%" class="table nowrap dtr-inline no-footer">
                            <tbody>
                                <tr>
                                    <td class="row_opening">{{ 'general.name' | translate}}</td>
                                    <td class="row_content">
                                        <input
                                            type="text"
                                            [readonly]="true"
                                            name="ac_name"
                                            class="form-control table-inline"
                                            [(ngModel)]="warehouse.name"
                                            required
                                            #name="ngModel"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td class="row_opening">{{ 'general.identifierWarehouse' | translate}}</td>
                                    <td class="row_content">{{warehouse?.properties?.wasl?.referenceKey}}</td>
                                </tr>
                                <tr>
                                    <td class="row_opening">{{ 'general.identifierAccount' | translate}}</td>
                                    <td class="row_content">{{account?.properties?.wasl?.referenceKey}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="ibox-footer">
                        <button
                            class="btn btn-primary"
                            [disabled]="!account?.properties?.wasl?.referenceKey || waslForm.invalid"
                            (click)="register(warehouse)"
                        >
                            <i class="fa fa-fw fa-edit"></i> {{'general.register' | translate }}
                        </button>

                        <button
                            class="btn btn-danger"
                            *ngIf="warehouse?.properties?.wasl?.referenceKey"
                            (click)="unRegister(warehouse)"
                        >
                            <i class="fa fa-fw fa-times"></i> {{'general.unRegister' | translate }}
                        </button>

                        <button
                            class="btn btn-primary"
                            [disabled]="!account?.properties?.wasl?.referenceKey"
                            (click)="inquire('SFDA')"
                        >
                            <i class="fa fa-fw fa-broadcast-tower"></i> {{'general.inquire' | translate }}
                        </button>
                    </div>
                </div>

                <div
                    class="ibox"
                    style="position: relative"
                    *ngFor="let zone of zones; let i = index"
                    [ngClass]="{ ibox_disabled: !(warehouse?.properties?.wasl?.referenceKey) }"
                >
                    <div class="ibox-title">
                        <h5><i class="fa fa-border-none"></i> {{zone.name}}</h5>
                    </div>
                    <div class="ibox-content slick" style="position: relative">
                        <ngx-loading [show]="loading || loadingZones"></ngx-loading>

                        <table style="width: 100%" class="table nowrap dtr-inline no-footer">
                            <tbody>
                                <tr>
                                    <td class="row_opening">{{ 'general.name' | translate}}</td>
                                    <td class="row_content">{{zone.name}}</td>
                                </tr>
                                <tr>
                                    <td class="row_opening">{{ 'general.identifierZone' | translate}}</td>
                                    <td class="row_content">{{zone.identifier ?? '-'}}</td>
                                </tr>
                                <tr>
                                    <td class="row_opening">{{ 'general.sfdaStoringCategory' | translate}}</td>
                                    <td class="row_content">{{zone.type}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="ibox-footer">
                        <button
                            class="btn btn-primary"
                            [disabled]="waslForm.invalid || !warehouseAzure?.referenceId"
                            (click)="registerInventory(zone)"
                        >
                            <i class="fa fa-fw fa-edit"></i> {{'general.register' | translate }}
                        </button>

                        <button class="btn btn-danger" *ngIf="zone.identifier" (click)="unRegisterInventory(zone)">
                            <i class="fa fa-fw fa-times"></i> {{'general.unRegister' | translate }}
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <form #waslForm="ngForm">
                    <div *ngIf="!loading && waslForm.invalid" class="alert alert-warning shadow">
                        <p *ngIf="waslForm.invalid">{{'general.waslFormInvalid' | translate }}</p>
                    </div>
                    <div class="ibox float-e-margins">
                        <div class="ibox-title">
                            <h5><i class="fa fa-link"></i> {{'general.properties' | translate }}</h5>
                        </div>
                        <div class="ibox-content slick" style="position: relative">
                            <table style="width: 100%" class="table nowrap dtr-inline no-footer">
                                <tbody>
                                    <tr>
                                        <td class="row_opening">
                                            {{ 'general.name' | translate}}
                                            <span class="error" *ngIf="name.invalid && formMode != 1">*</span>
                                        </td>
                                        <td class="row_content">
                                            <input
                                                type="text"
                                                [readonly]="formMode == 1"
                                                name="name"
                                                class="form-control table-inline"
                                                [(ngModel)]="warehouse.name"
                                                required
                                                #name="ngModel"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="row_opening">
                                            {{ 'general.phone' | translate}}
                                            <span class="error" *ngIf="phone.invalid && formMode != 1">*</span>
                                        </td>
                                        <td class="row_content">
                                            <input
                                                type="text"
                                                [readonly]="formMode == 1"
                                                name="phone"
                                                class="form-control table-inline"
                                                [(ngModel)]="warehouse.properties.wasl.phone"
                                                required
                                                #phone="ngModel"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="row_opening">
                                            {{ 'general.mobileNumber' | translate}}
                                            <span class="error" *ngIf="managerMobile.invalid && formMode != 1">*</span>
                                        </td>
                                        <td class="row_content">
                                            <input
                                                type="text"
                                                [readonly]="formMode == 1"
                                                name="managerMobile"
                                                class="form-control table-inline"
                                                [(ngModel)]="warehouse.properties.wasl.managerMobile"
                                                required
                                                #managerMobile="ngModel"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="row_opening">
                                            {{ 'general.landAreaInSquareMeter' | translate}}
                                            <span class="error" *ngIf="landAreaInSquareMeter.invalid && formMode != 1"
                                                >*</span
                                            >
                                        </td>
                                        <td class="row_content">
                                            <input
                                                type="text"
                                                [readonly]="formMode == 1"
                                                name="landAreaInSquareMeter"
                                                class="form-control table-inline"
                                                [(ngModel)]="warehouse.properties.wasl.landAreaInSquareMeter"
                                                required
                                                #landAreaInSquareMeter="ngModel"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="row_opening">
                                            {{ 'general.licenseNumber' | translate}}
                                            <span class="error" *ngIf="licenseNumber.invalid && formMode != 1">*</span>
                                        </td>
                                        <td class="row_content">
                                            <input
                                                type="text"
                                                [readonly]="formMode == 1"
                                                name="licenseNumber"
                                                class="form-control table-inline"
                                                [(ngModel)]="warehouse.properties.wasl.licenseNumber"
                                                required
                                                #licenseNumber="ngModel"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="row_opening">
                                            {{ 'general.licenseIssueDate' | translate}}
                                            <span class="error" *ngIf="licenseIssueDate.invalid && formMode != 1"
                                                >*</span
                                            >
                                        </td>
                                        <td class="row_content">
                                            <input
                                                type="text"
                                                [readonly]="formMode == 1"
                                                name="licenseIssueDate"
                                                class="form-control table-inline"
                                                [(ngModel)]="warehouse.properties.wasl.licenseIssueDate"
                                                required
                                                #licenseIssueDate="ngModel"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="row_opening">
                                            {{ 'general.licenseExpiryDate' | translate}}
                                            <span class="error" *ngIf="licenseExpiryDate.invalid && formMode != 1"
                                                >*</span
                                            >
                                        </td>
                                        <td class="row_content">
                                            <input
                                                type="text"
                                                [readonly]="formMode == 1"
                                                name="licenseExpiryDate"
                                                class="form-control table-inline"
                                                [(ngModel)]="warehouse.properties.wasl.licenseExpiryDate"
                                                required
                                                #licenseExpiryDate="ngModel"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="row_opening">
                                            {{ 'general.latitude' | translate}}
                                            <span class="error" *ngIf="latitude.invalid && formMode != 1">*</span>
                                        </td>
                                        <td class="row_content">
                                            <input
                                                type="text"
                                                [readonly]="true"
                                                name="latitude"
                                                class="form-control table-inline"
                                                [(ngModel)]="warehouse.latitude"
                                                required
                                                #latitude="ngModel"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="row_opening">
                                            {{ 'general.longitude' | translate}}
                                            <span class="error" *ngIf="longitude.invalid && formMode != 1">*</span>
                                        </td>
                                        <td class="row_content">
                                            <input
                                                type="text"
                                                [readonly]="true"
                                                name="longitude"
                                                class="form-control table-inline"
                                                [(ngModel)]="warehouse.longitude"
                                                required
                                                #longitude="ngModel"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="row_opening">
                                            {{ 'general.email' | translate}}
                                            <span class="error" *ngIf="email.invalid && formMode != 1">*</span>
                                        </td>
                                        <td class="row_content">
                                            <input
                                                type="text"
                                                [readonly]="formMode == 1"
                                                name="email"
                                                class="form-control table-inline"
                                                [(ngModel)]="warehouse.properties.wasl.email"
                                                required
                                                #email="ngModel"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="row_opening">
                                            {{ 'general.city' | translate}}
                                            <span class="error" *ngIf="city.invalid && formMode != 1">*</span>
                                        </td>
                                        <td class="row_content">
                                            <input
                                                type="text"
                                                [readonly]="formMode == 1"
                                                name="city"
                                                class="form-control table-inline"
                                                [(ngModel)]="warehouse.properties.wasl.city"
                                                required
                                                #city="ngModel"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="row_opening">
                                            {{ 'general.address' | translate}}
                                            <span class="error" *ngIf="address.invalid && formMode != 1">*</span>
                                        </td>
                                        <td class="row_content">
                                            <input
                                                type="text"
                                                [readonly]="true"
                                                name="address"
                                                class="form-control table-inline"
                                                [(ngModel)]="warehouse.address"
                                                required
                                                #address="ngModel"
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <ngx-loading [show]="loading"></ngx-loading>
                        <fh-save-footer
                            [valid]="!waslForm.invalid"
                            [allowSave]="false"
                            (setFormMode)="setFormMode($event)"
                            [formMode]="formMode"
                        ></fh-save-footer>
                    </div>
                </form>

                <div class="ibox">
                    <fh-leaflet-map
                        [editableGeofence]="geofence"
                        (mapReady)="onMapReady()"
                        [height]="250"
                        [allowEdit]="allowEdit"
                        [showSearch]="true"
                        [allowBookmarks]="false"
                    >
                        <div header class="ibox-title">
                            <span class="pull-left">
                                <h5><i class="fa fa-fw fa-map-marked-alt"></i> {{'general.map' | translate }}</h5>
                            </span>
                        </div>
                    </fh-leaflet-map>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-template #accountSetup>
        <div class="row">
            <div class="col-lg-12">
                <div class="alert alert-warning shadow">
                    <p>{{'general.waslAccountInvalid' | translate }}</p>
                    <br />
                    <button
                        class="btn btn-primary hand"
                        [routerLink]="'/#/AccountDetails/External/' + warehouse?.accountId"
                    >
                        <i class="fas fa-fw fa-plus"></i>
                        {{'enums.batchAction.15' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </ng-template>
</div>
