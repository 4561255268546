import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { throwError } from "rxjs/internal/observable/throwError";
import { Observable, catchError, map, of } from "rxjs";
import { DeviceType, Supplier } from "../../models/devicetype.model";
import { AuthenticationService } from "../authentication/authentication.service";
import { TranslatorService } from "../common/translator.service";
import { LoggingService } from "../logging/logging.service";

@Injectable()
export class DeviceTypeService {
  timestamp;
  url = "";
  public deviceTypes: DeviceType[] = [];
  permissions: {};

  constructor(
    private http: HttpClient,
    private translatorService: TranslatorService,
    private loggingService: LoggingService,
    private authenticationService: AuthenticationService
  ) {
    this.url = authenticationService.getWebserviceURL("devicetype");

    this.permissions = authenticationService.permissions;
  }

  getPagingUrl() {
    return this.url + "Paging";
  }

  getDeviceTypes(setDefinate = true, includeThumbnails = true, excludeUnused = true): Observable<DeviceType[]> {
    if (setDefinate && this.deviceTypes.length !== 0) {
      console.log("Getting devicetypes from cache.");
      return of(this.deviceTypes);
    }

    console.log("getting devicetypes from service");
    return this.http
      .get(this.url + "?includeThumbnails=" + includeThumbnails + "&excludeUnused=" + excludeUnused, {
        headers: this.authenticationService.headers,
      })
      .pipe(
        map((data) => {
          const parsedResponse = this.parseResponse(data);
          if (setDefinate) {
            this.deviceTypes = parsedResponse;
          }
          return parsedResponse;
        }),
        catchError(this.handleError)
      );
  }

  getDeviceTypeById(id: string): Observable<DeviceType> {
    console.log("getting devicetypes from service");
    return this.http.get(this.url + id, { headers: this.authenticationService.headers }).pipe(
      map((data) => {
        const parsedResponse = this.parseResponseData(data);
        return parsedResponse;
      }),
      catchError(this.handleError)
    );
  }

  getDevicesAdded(id: string): Observable<any> {
    console.log("getting devicetypes from service");
    return this.http.get(this.url + id + "/added", { headers: this.authenticationService.headers }).pipe(
      map((data: any) => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  getDeviceTypeDashboard(id: string): Observable<any> {
    console.log("getting dashboard from service per devicetype");
    return this.http.get(this.url + id + "/dashboard/", { headers: this.authenticationService.headers }).pipe(
      map((data: any) => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  getDeviceTypeIssueTypes(id: string): Observable<any> {
    console.log("getting dashboard from service per devicetype");
    return this.http.get(this.url + id + "/issuetypes/", { headers: this.authenticationService.headers }).pipe(
      map((data: any) => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  getIssuesPerIssueType(id: string, start, end): Observable<any> {
    console.log("getting dashboard from service per issuetype");
    return this.http
      .get(this.url + id + "/IssuesPerIssueType?start=" + Math.round(start / 1000) + "&end=" + Math.round(end / 1000), {
        headers: this.authenticationService.headers,
      })
      .pipe(
        map((data: any) => {
          return Object.keys(data).map((k) => [this.translatorService.translate(k, "enums.issuetypes"), data[k]]);
        }),
        catchError(this.handleError)
      );
  }

  getDeviceTypesWithCount(setDefinate = true): Observable<DeviceType[]> {
    console.log("getting devicetypes from service");
    return this.http.get(this.url, { headers: this.authenticationService.headers }).pipe(
      map((data) => {
        const parsedResponse = this.parseResponse(data);
        return parsedResponse;
      }),
      catchError(this.handleError)
    );
  }

  getDeviceTypesChart(start, end): Observable<any> {
    console.log("getting devicetypes from service");
    return this.http
      .get(this.url + "chart/?start=" + Math.round(start / 1000) + "&end=" + Math.round(end / 1000), {
        headers: this.authenticationService.headers,
      })
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  private handleError(error: Response) {
    return throwError(error);
  }

  parseResponse(json: any): DeviceType[] {
    const deviceTypes: DeviceType[] = [];

    json.forEach((item) => {
      const deviceType = this.parseResponseData(item);

      deviceTypes.push(deviceType);
    });

    this.timestamp = new Date().getTime();
    return deviceTypes;
  }

  parseResponseData(item) {
    const deviceType = new DeviceType();
    deviceType.id = item.id;
    deviceType.modelName = item.name;
    deviceType.modelNumber = item.number;

    if (this.permissions["Platform_IsReseller"]) {
      deviceType.description = item.description;

      const supplier = new Supplier();
      supplier.name = item.supplier.name;

      deviceType.supplier = supplier;
    } else {
      deviceType.description = "-";
    }

    deviceType.latestVersion = item.latestVersion;
    deviceType.port = item.port;
    deviceType.deviceCount = item.deviceCount;
    deviceType.deviceCountActive = item.deviceCountActive;

    return deviceType;
  }
}
