import { HttpClient } from "@angular/common/http";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DeviceType } from "app/models/devicetype.model";

import { AuthenticationService } from "app/services/authentication/authentication.service";
import { DeviceTypeService } from "app/services/devicetypes/devicetypes.service";

import { Subscription } from "rxjs";
import { FhChartService } from "app/services/charts/charts.service";

@Component({
  providers: [FhChartService],
  selector: "fh-fh-device-type-details",
  templateUrl: "deviceTypeDetails.template.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceTypeDetailsViewComponent implements OnInit {
  devices: any;
  markers: any;

  loadingKpis: boolean;

  deviceCount;

  sub: Subscription;
  loading = false;
  loadingMapData = false;
  deviceType: DeviceType;
  loadingDeviceCount: boolean;
  deviceCountData = [];

  permissionName = "Entities_DeviceTypes";

  constructor(
    private authenticationService: AuthenticationService,
    private cd: ChangeDetectorRef,
    private http: HttpClient,
    private deviceTypeService: DeviceTypeService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.deviceType = null;
  }

  setDeviceTypeDetails(deviceType: DeviceType): any {
    if (deviceType == null) {
      this.router.navigate(["/DeviceTypes/Overview"]);
    } else {
      this.deviceType = deviceType;
    }
  }

  orderBy(array: any[], prop: string) {
    return array.sort((a, b) => (a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1));
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe((params) => {
      const id = params["id"];

      this.loading = true;
      this.loadingDeviceCount = true;

      const deviceTypeFromService = this.deviceTypeService.getDeviceTypeById(id).subscribe((deviceType) => {
        this.loading = false;
        this.setDeviceTypeDetails(deviceType);
        this.cd.markForCheck();
      });

      this.deviceTypeService.getDevicesAdded(id).subscribe((devices) => {
        this.loadingDeviceCount = false;
        this.deviceCountData = devices;
        this.cd.markForCheck();
      });

      // this.fillDevices(id);
    });
  }
}
