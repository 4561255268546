import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable, catchError, map, of } from "rxjs";
import { AuthenticationService } from "../authentication/authentication.service";
import { LoggingService } from "../logging/logging.service";
import { throwError } from "rxjs/internal/observable/throwError";
import { Country } from "app/models/country.model";

@Injectable()
export class CountriesService {
  url = "";
  countries: Country[] = [];

  constructor(
    private http: HttpClient,
    private loggingService: LoggingService,
    private authenticationService: AuthenticationService
  ) {
    this.url = this.authenticationService.getWebserviceURL("country");
  }

  getCountries(setDefinate = true): Observable<Country[]> {
    if (setDefinate && this.countries.length !== 0) {
      console.log("Returning countries from cache.");
      return of(this.countries);
    }

    return this.http.get(this.url, { headers: this.authenticationService.headers }).pipe(
      map((data) => {
        this.countries = this.parseResponse(data);
        return this.countries;
      }),
      catchError(this.handleError)
    );
  }

  private handleError(error: Response) {
    return throwError(error);
  }

  parseResponse(json: any): Country[] {
    this.loggingService.log(this.constructor.name, "Retrieved " + json.length + " Countries.");

    const ident = 1;
    const countries: Country[] = [];

    json.forEach((item) => {
      const country = new Country();
      country.id = item.id;
      country.name = item.name;
      countries.push(country);
    });

    return countries;
  }
}
