<ng-select
    [loading]="loadingTriggerSchedules"
    ngDefaultControl
    [searchable]="true"
    [clearable]="clearable"
    style="height: unset"
    [readonly]="readonly"
    [items]="triggerSchedules"
    bindLabel="triggerScheduleName"
    bindValue="id"
    [virtualScroll]="false"
    placeholder="{{ placeholder }} ({{ triggerSchedules.length }})"
    #input
    [multiple]="multiple"
    [(ngModel)]="value"
    class="form-control"
    [ngClass]="multiple !== true ? 'table-inline' : ''"
    (ngModelChange)="triggerScheduleChanged()"
>
    <ng-template ng-label-tmp let-triggerSchedules="item" let-clear="clear">
        <span><i class="fas fa-fw fa-clock"></i> {{ triggerSchedules.triggerScheduleName }}</span>
        <span class="ng-value-icon right" (click)="clear(triggerSchedules)" aria-hidden="true">×</span>
    </ng-template>
    <ng-template ng-option-tmp let-triggerSchedules="item" let-search="searchTerm">
        {{ triggerSchedules.triggerScheduleName }}
    </ng-template>
    <ng-template ng-footer-tmp>
        <button
            class="btn btn-primary hand"
            style="width: 100%"
            [routerLink]="['/Notifications/TriggerScheduleDetails/Add', {selectedAccountId}]"
        >
            <i class="fas fa-fw fa-plus"></i>
            {{ "general.createNew" | translate }}
        </button>
    </ng-template>
</ng-select>
