<div class="row wrapper border-bottom white-bg page-heading">
    <div style="padding: 3px 2px 0px 2px" class="flex-container">
        <div class="col-lg-3 col-sm-12" [ngClass]="{'col-lg-6': !permissions['Entities_DeviceTypes']}">
            <div class="form-group input-group" style="margin-bottom: 2px">
                <span class="input-group-prepend">
                    <i class="fa fa-fw fa-search"></i>
                </span>
                <input
                    style="width: 100%"
                    type="text"
                    min="0"
                    class="form-control"
                    placeholder="{{ 'general.searchOnDeviceOrImei' | translate}}"
                    (input)="filterMarkers(true)"
                    [(ngModel)]="search"
                />
            </div>
        </div>
        <div class="col-sm-3 d-none d-sm-block">
            <div class="form-group" style="margin-bottom: 6px">
                <fh-asset-group-input
                    name="assetGroups"
                    #assetGroupVar="ngModel"
                    required
                    [selectedAccountId]="selectedAccountId"
                    [tableInline]="false"
                    (onChanged)="filterMarkers(true)"
                    [multiple]="false"
                    [autoSelect]="false"
                    placeholder="{{ (selectedAccountId? 'general.chooseAssetGroups' : 'general.firstChooseAccount') | translate}}"
                    [(ngModel)]="selectedAssetGroups"
                >
                </fh-asset-group-input>
            </div>
        </div>
        <div class="col-sm-3 d-none d-sm-block">
            <div class="form-group input-group" style="margin-bottom: 2px">
                <span class="input-group-prepend">
                    <i class="fa fa-fw fa-map-marker-alt"></i>
                </span>
                <ng-select
                    class="form-control"
                    name="displayEvents"
                    (change)="filterMarkers(true)"
                    placeholder="{{ 'general.deviceState' | translate }}"
                    [(ngModel)]="status"
                    style="width: 100%"
                >
                    <ng-option [value]="0">
                        <i class="fa fa-fw fa-map-marker-alt eye-active black"></i>
                        {{ 'general.deviceState_0' | translate}} ({{getDeviceCount(0)}})
                    </ng-option>
                    <ng-option [value]="1">
                        <i class="fa fa-fw fa-map-marker-alt eye-active green"></i>
                        {{ 'general.deviceState_1' | translate}} ({{getDeviceCount(1)}})
                    </ng-option>
                    <ng-option [value]="2">
                        <i class="fa fa-fw fa-map-marker-alt eye-active red"></i>
                        {{ 'general.deviceState_2' | translate}} ({{getDeviceCount(2)}})
                    </ng-option>
                    <ng-option [value]="3">
                        <i class="fa fa-fw fa-map-marker-alt eye-active orange"></i>
                        {{ 'general.deviceState_3' | translate}} ({{getDeviceCount(3)}})
                    </ng-option>
                    <ng-option [value]="4">
                        <i class="fa fa-fw fa-map-marker-alt eye-active blue"></i>
                        {{ 'general.deviceState_4' | translate}} ({{getDeviceCount(4)}})
                    </ng-option>
                    <ng-option [value]="5">
                        <i class="fa fa-fw fa-map-marker-alt eye-active black"></i>
                        {{ 'general.deviceState_5' | translate}} ({{getDeviceCount(5)}})
                    </ng-option>
                    <ng-option [value]="6">
                        <i class="fa fa-fw fa-location-crosshairs-slash eye-active violet"></i>
                        {{ 'general.deviceState_6' | translate}} ({{getDeviceCount(6)}})
                    </ng-option>
                </ng-select>
            </div>
        </div>

        <div class="col-sm-3 d-none d-sm-block" *ngIf="permissions['Entities_DeviceTypes']">
            <div class="form-group input-group" style="margin-bottom: 2px">
                <span class="input-group-prepend">
                    <i class="fa fa-fw fa-router"></i>
                </span>
                <ng-select
                    class="form-control"
                    name="displayEvents"
                    (change)="filterMarkers(true)"
                    placeholder="{{ 'general.chooseDeviceType' | translate }}  ({{ deviceTypeOptions.length }})"
                    [loading]="loadingDeviceTypes"
                    [(ngModel)]="deviceType"
                    style="width: 100%"
                >
                    <ng-option *ngFor="let deviceType of deviceTypeOptions" [value]="deviceType.id">
                        {{ deviceType.value }}</ng-option
                    >
                </ng-select>
            </div>
        </div>
        <div style="clear: both"></div>
    </div>
    <div class="col-lg-12" style="position: absolute; top: 200px; z-index: 2000; width: 50%">
        <fh-error [error]="error"></fh-error>
    </div>
</div>

<div id="mapFullContainerWithFilter" class="shadow-inner">
    <ngx-loading [show]="loading"></ngx-loading>

    <div *ngIf="isSpinning" style="position: absolute; z-index: 1400; bottom: 15px; left: 15px">
        <i
            tooltip="{{'general.updated' | translate }}"
            style="margin-left: 3px"
            class="fa fa-fw fa-spinner fa-spin eye-active green"
        ></i>
    </div>

    <div
        id="map"
        style="height: 100%; width: 100%; border-radius: 5px"
        leaflet
        [leafletOptions]="options"
        (leafletMapReady)="onMapReady($event)"
    ></div>

    <div [@slideInOut]="menuState" class="slideMenu sidebar-bg">
        <div class="closeButton" (click)="toggleMenu()">
            <i class="fa fa-fw fa-times"></i>
        </div>
        <div (click)="toggleMenu()" class="slideMenuControl"></div>
        <fh-map-sidebar
            [selectedDevice]="selectedDevice"
            [loadingSidebar]="loadingSidebar"
            [selectedDeviceExtended]="selectedDeviceExtended"
            [selectedDeviceUtilization]="selectedDeviceUtilization"
        ></fh-map-sidebar>
    </div>
</div>
