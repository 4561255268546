import { Component, ChangeDetectorRef } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

import { forkJoin } from "rxjs";
import { getGridButtons, getGridLanguages, createdCellCheckbox } from "app/common/gridhelper";
import { GridBase360Directive } from "app/common/360Grid.base";
import { AuthenticationService } from "app/services/authentication/authentication.service";
import { CustomCommandService } from "app/services/customcommand/customcommand.service";
import { StorageHelper } from "app/common/storagehelper";
import { ColorService } from "app/services/common/color.service";

import Moment from "moment";

@Component({
  selector: "fh-custom-command",
  templateUrl: "customCommands.template.html",
})
export class CustomCommandViewComponent extends GridBase360Directive {
  showActiveOnly = true;

  loading = false;
  languageLoaded: boolean;
  permissions: {};

  constructorName = "CustomCommandViewComponent";
  timezoneIana: string;

  permissionName = "Platform_IsFleetManager";

  success;
  error;

  constructor(
    private translateService: TranslateService,
    private cd: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    protected storageHelper: StorageHelper,
    private colorService: ColorService,
    private customCommandService: CustomCommandService
  ) {
    super(storageHelper);

    this.timezoneIana = this.authenticationService.getTimeZoneIana();

    // Get all the date for dropdown boxes
    forkJoin({ dateTranslation: this.translateService.get("general.date") }).subscribe({
      next: ({ dateTranslation }) => {
        this.languageLoaded = true;
        this.loading = false;

        this.initGrid();
      },
      error: (err) => console.error(err),
    });
  }

  // Fire custom filters to update grid and call server again
  fireFilter(event): void {
    if (event != null) {
      console.log("Fire update");
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        if (this.showActiveOnly === true) {
          dtInstance.column("isActive:name").search("true");
        } else {
          dtInstance.column("isActive:name").search("@ignore");
        }

        // Fire the request
        dtInstance.draw();
      });
    }
  }

  ngOnInit() {
    this.permissions = this.authenticationService.permissions;

    this.translateService.get("general.date").subscribe((value) => {
      this.languageLoaded = true;
      this.initGrid();
    });
  }

  // Check custom filters from grid save
  // checkFilters() {

  //   this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
  //     this.showActiveOnly = dtInstance.column('isActive:name').search() !== '@ignore';
  //   });

  //   this.fireFilter(1);
  // }

  initGrid(): void {
    console.log("Init grid");

    $.fn["dataTable"].ext.search.pop();

    this.loading = true;

    this.columns = [
      {
        name: "id",
        data: "id",
        orderable: false,
        title: '<div class="hideDropdown"></div>',
        width: "20",
        render: (data, type, row) =>
          `<a class='btn btn-primary btn-grid' title='${this.translateService.instant(
            "general.details"
          )}' href='/#/Devices/CustomCommands/Details/Index/${data}'><span class="d-none d-md-inline-flex" style="padding-left: 7px">${this.translateService.instant(
            "general.details"
          )} </span><i class='fas fa-fw fa-angle-right'></i></a>`,
      },
      {
        name: "id_export",
        data: "id",
        className: "noVis",
        title: this.translateService.instant("general.id"),
        visible: false,
      },
      {
        name: "name",
        data: "name",
        title: this.translateService.instant("general.name"),
      },
      {
        name: "companyName",
        data: "companyName",
        defaultContent: "-",
        title: this.translateService.instant("general.companyName"),
      },
      {
        name: "resellerDescription",
        data: "resellerDescription",
        defaultContent: "-",
        title: this.translateService.instant("general.resellerDescription"),
      },
      {
        name: "createdTimestamp",
        data: "timestamp",
        defaultContent: "-",
        title: this.translateService.instant("general.createdDate"),
        render: (data, type, row) => {
          if (type && type === "display") {
            const date = Moment.utc(data)["tz"](this.timezoneIana);
            return data ? `<span title=" ${date.toLocaleString()}">${date.format("lll")}</span>` : "";
          } else {
            return data;
          }
        },
      },
      {
        name: "isResellerOnly",
        data: "isResellerOnly",
        title: this.translateService.instant("general.resellerOnly"),
        type: "checkBox",
        defaultContent: "",
        visible: true,
        createdCell: createdCellCheckbox,
      },
      {
        name: "emailLoginName",
        data: "emailLoginName",
        defaultContent: "-",
        title: this.translateService.instant("general.emailLoginName"),
        visible: false,
      },
    ];

    this.dtOptions = {
      buttons: getGridButtons(
        this.commonExportOptions,
        "customcommand_overview",
        this.translateService.instant("menu.messagesoverview"),
        this.colorService
      ),
      pagingType: "simple_numbers",
      serverSide: false,
      processing: false,
      scrollX: true,
      colReorder: { fixedColumnsLeft: 1 },
      order: [[2, "asc"]],
      stateSave: false,
      ajax: (dataTablesParameters: any, callback) => {
        $(".dataTables_info").html(this.translateService.instant("grid.loadingData"));
        this.loading = true;
        this.customCommandService.getCommands().subscribe({
          next: (resp) => {
            callback({
              recordsTotal: resp.length,
              recordsFiltered: resp.length,
              data: resp,
            });
          },
          error: (error) => {
            this.success = null;
            this.error = error;
            this.loading = false;
          },
        });
      },
      initComplete: (settings, json) => {
        this.loading = false;

        console.log("init complete");
        this.checkFilters();
        this.drawFilterRow();
        this.loading = false;
      },
      colVis: {
        restore: this.translateService.instant("general.restore"),
        showAll: this.translateService.instant("general.showAll"),
        showNone: this.translateService.instant("general.hideAll"),
      },
      columns: this.columns,
      pageLength: 17,
      lengthMenu: [
        [10, 17, 25, 50, 200, -1],
        [10, 17, 25, 50, 200, this.translateService.instant("general.all")],
      ],
      language: getGridLanguages(this.translateService),
      rowCallback: (row, data) => {},
    };
  }
}
