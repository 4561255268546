<div class="cockpitMenuDetails white-bg fontColor" style="position: relative">
    <ngx-loading [show]="loadingSidebar"></ngx-loading>
    <img style="position: absolute; right: 20px; top: 0px" [src]="getIcon(selectedDevice?.asset?.icon)" />

    <table style="width: 100%" class="table table-fixed nowrap dtr-inline no-footer">
        <tbody>
            <tr>
                <td class="row_opening">
                    {{ "general.name" | translate }}
                </td>
                <td class="row_content" colspan="2">
                    <a class="secondary link_bolder" href="/#/DeviceDetails/Index/{{ selectedDevice?.id }}">
                        {{ selectedDevice?.asset?.name }}</a
                    >
                </td>
            </tr>
            <tr *ngIf="permissions['Accounts_View']">
                <td class="row_opening">
                    {{ "general.customer" | translate }}
                </td>
                <td class="row_content" colspan="2">
                    <a class="secondary link_bolder" href="/#/AccountDetails/Index/{{ selectedDevice?.accountId }}">{{
                        selectedDevice?.companyName
                    }}</a>
                </td>
            </tr>

            <tr *ngIf="driver">
                <td class="row_opening">
                    {{ "general.driver" | translate }}
                </td>
                <td class="row_content" title="{{ driver.name }}" colspan="2">
                    {{ driver.name }} - {{ driver.phone }}
                </td>
            </tr>

            <tr>
                <td class="row_opening">
                    {{ "general.lastCommunication" | translate }}
                </td>
                <td class="row_content" title="{{ deviceState?.communicationState?.updateTimestamp }}" colspan="2">
                    {{ deviceState?.communicationState?.updateTimestamp | amTimeAgo }}
                </td>
            </tr>
            <tr *ngIf="deviceState?.currentAddress">
                <td class="row_opening">
                    {{ "general.near" | translate }}
                </td>
                <td
                    class="row_content"
                    colspan="2"
                    title="{{ deviceState?.currentAddress?.address }}, {{ deviceState?.currentAddress?.city }}, {{
                        deviceState?.currentAddress?.country
                    }}"
                >
                    {{ deviceState?.currentAddress?.city }}
                    {{ deviceState?.currentAddress?.address ? ", " + deviceState?.currentAddress?.address : "" }}
                    {{ deviceState?.currentAddress?.country ? ", " + deviceState?.currentAddress?.country : "" }}
                </td>
            </tr>
            <tr *ngIf="selectedDevice?.asset?.currentAddress">
                <td class="row_opening">
                    {{ "general.near" | translate }}
                </td>
                <td
                    class="row_content"
                    colspan="2"
                    title="{{ selectedDevice?.asset?.currentAddress?.address }}, {{
                        selectedDevice?.asset?.currentAddress?.city
                    }}, {{ selectedDevice?.asset?.currentAddress?.country }}"
                >
                    {{ selectedDevice?.asset?.currentAddress?.city }}
                    {{
                        selectedDevice?.asset?.currentAddress?.address
                            ? ", " + selectedDevice?.asset?.currentAddress?.address
                            : ""
                    }}
                    {{
                        selectedDevice?.asset?.currentAddress?.country
                            ? ", " + selectedDevice?.asset?.currentAddress?.country
                            : ""
                    }}
                </td>
            </tr>
            <tr>
                <td colspan="3" style="margin: 0; padding: 0">
                    <highcharts-chart
                        [runOutsideAngular]="true"
                        style="display: block; width: 100%; height: 120px"
                        [Highcharts]="Highcharts"
                        [options]="chartOptions"
                    ></highcharts-chart>
                </td>
            </tr>
        </tbody>
    </table>

    <nav>
        <li class="fleet-overview-sidebar-tabs" style="margin-top: 15px; position: relative">
            <button
                class="fleet-overview-sidebar-tab"
                (click)="changeTab('Tasks')"
                title="Tasks"
                [class.active]="tab === 'Tasks'"
            >
                <i class="fa fa-fw fa-bolt"></i> {{ "general.tasks" | translate }}
            </button>
            <button
                class="fleet-overview-sidebar-tab"
                (click)="changeTab('Chat')"
                title="Chat"
                [class.active]="tab === 'Chat'"
            >
                <i class="fa fa-fw fa-comments"></i> {{ "general.chat" | translate }}
            </button>
            <button
                class="fleet-overview-sidebar-tab"
                (click)="changeTab('History')"
                title="History"
                [class.active]="tab === 'History'"
            >
                <i class="fa fa-fw fa-history"></i> {{ "general.history" | translate }}
            </button>
        </li>
    </nav>

    <div class="fleet-overview-sidebar-details" *ngIf="tab === 'Tasks'">
        <table style="width: 100%; margin-bottom: 3px" class="table table-fixed nowrap dtr-inline no-footer">
            <thead>
                <tr>
                    <td class="sidebar-header" style="width: 120px">&nbsp;</td>
                    <td class="sidebar-header">
                        {{ "general.task" | translate }}
                    </td>
                    <td class="sidebar-header">{{ "general.status" | translate }}</td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>2024-01-01 08:12</td>
                    <td>Pickup truck</td>
                    <td>Done</td>
                </tr>
                <tr>
                    <td>2024-01-01 11:12</td>
                    <td>Pickup cargo</td>
                    <td>Done</td>
                </tr>
                <tr>
                    <td>2024-01-01 12:11</td>
                    <td>Deliver cargo</td>
                    <td>Active</td>
                </tr>
                <tr>
                    <td>2024-01-01 15:34</td>
                    <td>Wash vehicle</td>
                    <td>Planned</td>
                </tr>
                <tr>
                    <td>2024-01-01 16:32</td>
                    <td>Deliver truck</td>
                    <td>Planned</td>
                </tr>
            </tbody>
        </table>
    </div>

    <!-- Chat -->
    <div class="fleet-overview-sidebar-details" *ngIf="tab === 'Chat'">
        <table style="width: 100%; margin-bottom: 3px" class="table table-fixed nowrap dtr-inline no-footer">
            <tbody>
                <tr>
                    <td class="row_opening sidebar-header" colspan="3" style="border-top-left-radius: 0">
                        <i class="fa fa-fas fa-comments"></i> {{ "general.chat" | translate }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <!-- History -->
    <div class="fleet-overview-sidebar-details" *ngIf="tab === 'History'">
        <table style="width: 100%; margin-bottom: 3px" class="table table-fixed nowrap dtr-inline no-footer">
            <tbody>
                <tr>
                    <td class="row_opening sidebar-header" colspan="3" style="border-top-left-radius: 0">
                        <i class="fa fa-fas fa-history"></i> {{ "general.history" | translate }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
