<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/"> <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Devices/Overview">{{'general.devices' | translate }}</a>
                </li>
                <li class="breadcrumb-item active">
                    <a href="/#/Devices/External">{{'general.external' | translate }}</a>
                </li>
            </ol>
        </nav>

        <fh-filter-toggler
            style="margin-left: auto"
            [constructorName]="constructorName"
            (filterChanged)="showFilter = $event"
            (presetsChanged)="showPresets = $event"
        >
            <a
                style="margin-left: 2px"
                class="btn btn-info margin-fix d-none d-md-inline-flex"
                title="Help"
                href="https://help.360locate.com/knowledge-base/fleet-management/devices/"
                target="_blank"
                ><i class="fa fa-fa fa-book" style="margin-right: 6px"></i>
                <span class="d-none d-md-inline-flex">{{'general.needHelp' | translate }}</span></a
            >
        </fh-filter-toggler>
    </div>
    <fh-device-navigation></fh-device-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">
        <div class="row">
            <div class="col-lg-12">
                <fh-error [error]="error" [success]="success"></fh-error>

                <!-- Custom filters -->
                <div class="ibox" [hidden]="!showFilter">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-filter"></i> {{'general.filter' | translate }}</h5>

                        <span class="float-right">
                            <div class="hand btn btn-info filter-margin-fix" (click)="flipExtendedDeviceFilter();">
                                <i
                                    class="fa-duotone fa-fw fa-lg"
                                    [ngClass]="showExtendedDeviceFilter ? 'fa-toggle-on' : 'fa-toggle-off'"
                                ></i>
                                <span class="d-none d-md-inline-flex">
                                    {{'general.showExtendedDeviceFilter' | translate }}</span
                                >
                            </div>

                            <div class="hand btn btn-info filter-margin-fix" (click)="clearAllFilters();">
                                <i class="fa fa-fw fa-times"></i>
                                <span class="d-none d-md-inline-flex"> {{'general.clearFilters' | translate }}</span>
                            </div>
                        </span>
                    </div>
                    <div class="ibox-content slick_toppadding" style="position: relative">
                        <ngx-loading [show]="loading"></ngx-loading>
                        <div id="dynamicFilters" class="flex-container"></div>
                        <div style="clear: both"></div>
                    </div>
                    <div
                        *ngIf="showExtendedDeviceFilter"
                        class="ibox-content slick_toppadding"
                        style="position: relative"
                    >
                        <ngx-loading [show]="loading"></ngx-loading>

                        <div id="extendedFilters">
                            <div id="imeiSelector" style="width: 50%; float: left" title="">
                                <div class="col-lg-12">
                                    <textarea
                                        name="textBox"
                                        id="textBox"
                                        style="width: 100%; height: 120px; resize: none"
                                        (ngModelChange)="onImeiChanged($event)"
                                        placeholder="{{ 'placeholder.imeiSearch'| translate }}"
                                        value=""
                                        [(ngModel)]="imeiText"
                                        class="form-control noresize stickypad"
                                    ></textarea>
                                </div>
                            </div>
                            <div id="accountSelector" style="width: 50%; float: left" title="">
                                <div class="col-lg-12">
                                    <div class="form-group" style="margin-bottom: 6px">
                                        <fh-reseller-input
                                            name="resellerSelector"
                                            (onChanged)="resellerChanged($event)"
                                            [tableInline]="false"
                                            [autoSelect]="false"
                                            placeholder="{{ 'general.chooseReseller' | translate}}"
                                            [(ngModel)]="selectedResellerId"
                                        >
                                        </fh-reseller-input>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="form-group" style="margin-bottom: 6px">
                                        <fh-account-input
                                            name="accountSelector"
                                            (onChanged)="accountChanged($event)"
                                            [activeOnly]="true"
                                            [selectedResellerId]="selectedResellerId"
                                            [autoSelect]="false"
                                            [tableInline]="false"
                                            placeholder="{{ (selectedResellerId ? 'general.chooseAccount' : 'general.firstChooseReseller') | translate}}"
                                            [(ngModel)]="selectedAccountId"
                                        >
                                        </fh-account-input>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="form-group" style="margin-bottom: 6px">
                                        <fh-asset-group-input
                                            name="assetGroupsSelector"
                                            [selectedAccountId]="selectedAccountId"
                                            [tableInline]="false"
                                            (onChanged)="assetGroupsChanged()"
                                            [autoSelect]="false"
                                            placeholder="{{ (selectedAccountId? 'general.chooseAssetGroups' : 'general.firstChooseAccount') | translate}}"
                                            [(ngModel)]="selectedAssetGroups"
                                        >
                                        </fh-asset-group-input>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="clear: both"></div>
                    </div>
                </div>

                <!-- End default dynamic filters -->

                <!-- Create the grid -->
                <div class="ibox">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-link"></i> {{'general.deviceExternal' | translate }}</h5>
                        <iboxseach
                            [searchTerm]="searchTerm"
                            (seachChanged)="seachChanged($event)"
                            [constructorName]="
                            constructorName"
                            (filterChanged)="showFilter = $event"
                            (presetLoaded)="onPresetLoaded($event)"
                            [showColumns]=" true"
                            (columnsChanged)="drawFilterRow()"
                        >
                            <fh-batch-processing
                                *ngIf="enableSelecting"
                                [entityTypes]="entityTypes"
                                (onClose)="onClose($event)"
                            >
                            </fh-batch-processing>
                        </iboxseach>
                    </div>
                    <div class="ibox-content slick" style="position: relative" *ngIf="languageLoaded">
                        <table
                            id="dataTable"
                            style="width: 100%; min-height: 50px"
                            fh-datatable
                            [dtTrigger]="dtTrigger"
                            [dtOptions]="dtOptions"
                            class="table nowrap margin-bottom-0 dataTable dtr-inline"
                        ></table>
                    </div>
                </div>
                <!-- End create the grid -->
            </div>
        </div>
    </div>
</fh-permission-handler>
