export class GeofenceGroupModel {
  filterClose = false;
  open = false;

  constructor(public id: string, public name: string, public items: any[], public order?: string) {}

  get _order() {
    return this.order ?? this.name;
  }
}
