import { Component, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Device } from "app/models/device.model";
import { DeviceService } from "../../services/device/device.service";
import { TranslateService } from "@ngx-translate/core";
import { BsDaterangepickerConfig } from "ngx-bootstrap/datepicker";
import { getDefaultDpConfig, getGridButtons, getGridLanguages, createdCellCheckbox } from "app/common/gridhelper";

import { AuthenticationService } from "app/services/authentication/authentication.service";
import { TripService } from "app/services/trip/trip.service";
import * as L from "leaflet";
import { DeviceEpisode, StorageType } from "app/common/enums";
import { GridBase360Directive } from "app/common/360Grid.base";

// Moment
import Moment from "moment-timezone";
import { LeafletMapComponent } from "../shared/usercontrols/leafletMap.component";
import { AccountService } from "app/services/account/account.service";
import { drawGeofence } from "app/common/leafletGlobals";
import { StorageHelper } from "app/common/storagehelper";
import { ColorService } from "app/services/common/color.service";

window["moment"] = Moment;

@Component({
  selector: "fh-device-geofence-events",
  templateUrl: "geofenceEvents.template.html",
})
export class DeviceGeofenceEventsViewComponent extends GridBase360Directive implements OnInit, OnDestroy {
  @ViewChild(LeafletMapComponent, { static: false }) leafletMapComponent: LeafletMapComponent;

  sub;
  device: Device;
  deviceId;

  loading: boolean = false;

  error: any;
  success: any;

  timezoneIana: string;
  excludingColumns: string[] = ["stateChangeDateTime", "episodeStart", "episodeEnd"];
  limit: number = 1000;
  mapHeight: number = 250;
  token: string;

  languageLoaded: boolean = false;
  geofenceStatesLoaded: boolean = false;

  // Datepicker
  public dpConfig: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();
  to: any;
  from: any;
  daterangepickerModel: any[];
  permissions: {};
  maxDate: Date = new Date();

  randomKey: number;

  geofences = [];
  loadingGeofences: boolean = false;

  showMapOnSide: boolean = false;
  storageType: StorageType = StorageType.LocalStorage;

  theGeofence;
  permissionName: string = "FleetManagement_GeofenceStates";
  constructorName: string = "DeviceGeofenceStatesViewComponent";

  constructor(
    private accountService: AccountService,
    private deviceService: DeviceService,
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    private authenticationService: AuthenticationService,
    private tripService: TripService,
    protected storageHelper: StorageHelper,
    private colorService: ColorService
  ) {
    super(storageHelper);

    this.device = null;

    this.permissions = this.authenticationService.permissions;

    this.timezoneIana = this.authenticationService.getTimeZoneIana();
    this.token = this.authenticationService.getAuthToken();

    this.randomKey = Math.floor(Math.random() * (999999 - 100000)) + 100000;

    this.daterangepickerModel = [
      Moment().tz(this.timezoneIana).subtract(1, "weeks").startOf("day").toDate(),
      Moment().tz(this.timezoneIana).endOf("day").toDate(),
    ];

    this.storageHelper.loadStoreState(this.storageType, "settings_", "showMapOnSide").subscribe((result) => {
      this.showMapOnSide = JSON.parse(result) === true;

      if (this.showMapOnSide) {
        this.mapHeight = 330;
      }
    });

    this.dpConfig = getDefaultDpConfig(authenticationService);
  }

  ngOnInit() {
    this.loading = true;
    this.device = new Device();
    this.device.id = "";

    this.sub = this.route.params.subscribe((params) => {
      const id = params["id"];

      this.deviceId = id;
      this.deviceService.getDeviceById(id).subscribe((device) => {
        this.device = device;

        if (this.device == null) {
          this.router.navigate(["/Devices/Overview"]);
        }

        this.getGeofences();
        this.loading = false;

        this.translateService.get("general.date").subscribe((value) => {
          this.initGrid();
          this.languageLoaded = true;
        });
      });
    });
  }

  getGeofences() {
    if (this.geofences.length === 0 && this.device.accountId != null && this.device.accountId > 0) {
      this.loadingGeofences = true;
      this.accountService.getGeofencesByAccount(this.device.accountId).subscribe((geofences) => {
        this.geofences = geofences;
        this.loadingGeofences = false;
      });
    }
  }

  dateChanged(event) {
    console.log("Changed date");
    if (event !== null) {
      this.geofenceStatesLoaded = true;

      this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.ajax
          .url(
            this.tripService.getGeofenceEventsUrl(
              this.device.id,
              null,
              Moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf("day"),
              Moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf("day"),
              this.randomKey
            )
          )
          .load(() => (this.geofenceStatesLoaded = false));
      });
    }
  }

  onMapReady(map) {
    setTimeout(() => {
      this.leafletMapComponent.invalidateSize();
    }, 10);
  }

  initGrid(): void {
    const deviceEpisodeTypes = [];
    Object.values(DeviceEpisode)
      .filter((key) => isNaN(Number(DeviceEpisode[key])))
      .forEach((item, index) => {
        deviceEpisodeTypes.push({ id: item.toString(), value: DeviceEpisode[item].toString() });
      });

    this.columns = [
      {
        name: "id_export",
        data: "id",
        className: "noVis",
        title: this.translateService.instant("general.id"),
        visible: false,
      },
      {
        name: "stateChangeDateTime",
        data: "stateChangeDateTime",
        title: this.translateService.instant("general.timestamp"),
        type: "date",
        width: 130,
        render: (data, type, row) => {
          const date = Moment.utc(data)["tz"](this.timezoneIana);
          return data
            ? '<span title=" ' + date.toLocaleString() + '">' + date.format("YYYY-MM-DD HH:mm:ss") + "</span>"
            : "";
        },
      },
      {
        name: "hasLeft",
        data: "hasLeft",
        width: 70,
        type: "checkBox",
        createdCell: createdCellCheckbox,
        title: this.translateService.instant("general.hasLeft"),
        render: (data, type, row) => {
          return data ?? "0";
        },
      },
      {
        name: "hasEntered",
        data: "hasEntered",
        width: 70,
        type: "checkBox",
        createdCell: createdCellCheckbox,
        title: this.translateService.instant("general.hasEntered"),
        render: (data, type, row) => {
          return data ?? "0";
        },
      },
      {
        name: "hasPassedThrough",
        data: "hasPassedThrough",
        width: 70,
        type: "checkBox",
        createdCell: createdCellCheckbox,
        title: this.translateService.instant("general.hasPassedThrough"),
        render: (data, type, row) => {
          return data ?? "0";
        },
      },
      {
        name: "geoFenceLabel",
        data: "geoFenceLabel",
        title: this.translateService.instant("general.geofence"),
        render: (data, type, row) => {
          return `<a class="secondary link_bolder" href="/#/GeofenceDetails/Index/${row.geoFenceId}">${data}</a>`;
        },
      },
      {
        name: "assetName",
        data: "assetName",
        visible: false,
        title: this.translateService.instant("general.assetName"),
        render: (data, type, row) => {
          return `<a class="secondary link_bolder" href="/#/DeviceDetails/Index/${row.deviceId}">${data}</a>`;
        },
      },
    ];

    this.dtOptions = {
      buttons: getGridButtons(
        this.commonExportOptions,
        "geofenceStates_overview",
        this.translateService.instant("menu.deviceoverview"),
        this.colorService
      ),
      pagingType: "simple_numbers",
      serverSide: true,
      processing: true,
      // scrollY: 500,
      // scroller: {
      //     loadingIndicator: true
      // },
      searchDelay: 2000,
      deferRender: true,
      scrollX: true,
      colReorder: {},
      deferLoading: 0,
      stateSave: true,
      stateSaveCallback: (settings, data) => {
        this.saveState(this.constructorName, data);
      },
      stateLoadCallback: (_, callback) => {
        (async () => {
          try {
            const columnSettings = await this.loadState(this.constructorName);
            this.searchTerm = columnSettings && columnSettings.search && columnSettings.search.search;
            return columnSettings;
          } catch (e) {
            this.error = {};
            this.error.error = e;
            this.error.statusText = "Error fetching column settings";

            return null;
          }
        })().then((result) => {
          callback(result);
        });
      },
      order: [[0, "desc"]],
      ajax: {
        beforeSend: () => {
          this.drawFilterRow();

          $(".dataTables_info").html(this.translateService.instant("grid.loadingData"));
        },
        url: this.tripService.getGeofenceEventsUrl(
          this.device.id,
          null,
          Moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf("day"),
          Moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf("day"),
          this.randomKey
        ),
        data: (d) => {
          return d;
        },
        dataSrc: (json) => {
          return json.data;
        },
        type: "POST",
        headers: {
          Authorization: "Bearer " + this.token,
        },
      },
      initComplete: (settings, json) => {
        this.loading = false;
        this.geofenceStatesLoaded = false;

        console.log("init complete");
        this.checkFilters();
        this.drawFilterRow();
        this.loading = false;
      },
      colVis: {
        restore: this.translateService.instant("general.restore"),
        showAll: this.translateService.instant("general.showAll"),
        showNone: this.translateService.instant("general.hideAll"),
      },
      columns: this.columns,
      pageLength: 17,
      lengthMenu: [
        [10, 17, 25, 50, -1],
        [10, 17, 25, 50, this.translateService.instant("general.all")],
      ],
      language: getGridLanguages(this.translateService),
      rowCallback: (row, data) => {
        this.setMapInteraction(this, row, data);

        if (data.geoFenceId != null) {
          $(row).addClass("hand");
        }
      },
    };
  }

  setMapInteraction(table, row, data) {
    $(row).on("click", () => {
      this.showGeofenceOnMap(data.geoFenceId);
    });
  }

  showGeofenceOnMap(geoFenceId) {
    if (!geoFenceId) {
      return;
    }

    const geofenceData = this.geofences.find((x) => x.id === geoFenceId);

    if (this.theGeofence) {
      this.leafletMapComponent.map.removeLayer(this.theGeofence);
    }

    drawGeofence(L, geofenceData, this.leafletMapComponent.map, geofenceData.color);
    this.theGeofence = geofenceData.layerRef;

    const bounds = this.theGeofence.getBounds();

    if (bounds.isValid()) {
      this.leafletMapComponent?.map?.flyToBounds(bounds, { padding: [15, 15], animate: true, duration: 0.5 });
    }
  }
}
