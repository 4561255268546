import { animate, style, transition, trigger } from "@angular/animations";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit } from "@angular/core";
import { ControlContainer, NgForm } from "@angular/forms";
import { FormMode } from "app/common/enums";
import { Schedule } from "app/models/commutingSchedule.model";
import { AuthenticationService } from "app/services/authentication/authentication.service";

@Component({
  selector: "fh-working-hours-selector",
  templateUrl: "workingHours.template.html",
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger("enterAnimation", [
      transition(":enter", [
        // css styles at start of transition
        style({ opacity: 0 }),

        // animation and styles at end of transition
        animate("0.5s", style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class WorkingHoursSelectorComponent implements OnChanges, OnInit {
  @Input() schedule = [];
  @Input() formMode: any = FormMode.read;

  permissions: {};
  timePickerOptions = [];

  options = {};

  hours: string[];
  noneSelected: boolean;

  constructor(private authentication: AuthenticationService, private cd: ChangeDetectorRef) {
    this.permissions = this.authentication.permissions;

    this.hours = [];
    for (let i = 0; i <= 24; i++) {
      this.hours.push(i.toString().length > 1 ? i.toString() : "0" + i);
    }
  }

  ngOnInit(): void {}

  ngOnChanges(): void {
    if (this.schedule.length > 0) {
      this.validateSchedule();
    } else {
      console.log("Schedule updated");
    }

    if (this.formMode === FormMode.read) {
      this.options = {
        disabled: true,
        showTicks: false,
        draggableRange: true,
        floor: 0,
        ceil: 24,
        step: 0.5,
        translate: (value: number): string => {
          let time = value >= 10 ? Math.floor(value) : "0" + Math.floor(value);
          time += value % 1 === 0 ? ":00" : ":30";
          return time.toString();
        },
      };
    } else {
      this.options = {
        disabled: false,
        showTicks: false,
        draggableRange: true,
        floor: 0,
        ceil: 24,
        step: 0.5,
        translate: (value: number): string => {
          let time = value >= 10 ? Math.floor(value) : "0" + Math.floor(value);
          time += value % 1 === 0 ? ":00" : ":30";
          return time.toString();
        },
      };
    }

    this.cd.markForCheck();
  }

  checkSchedule(): void {
    this.noneSelected = true;
    this.schedule.forEach((day) => {
      this.noneSelected = day.commuting ? false : this.noneSelected;
    });
  }

  createSchedule() {
    this.schedule = [];
    this.schedule.push(new Schedule(0, false, "00:00", "24:00"));
    this.schedule.push(new Schedule(1, true, "08:00", "18:00"));
    this.schedule.push(new Schedule(2, true, "08:00", "18:00"));
    this.schedule.push(new Schedule(3, true, "08:00", "18:00"));
    this.schedule.push(new Schedule(4, true, "08:00", "18:00"));
    this.schedule.push(new Schedule(5, true, "08:00", "18:00"));
    this.schedule.push(new Schedule(6, false, "00:00", "24:00"));

    this.validateSchedule();

    setTimeout(() => {
      this.cd.markForCheck();
    }, 1);

    return this.schedule;
  }

  validateSchedule() {
    this.schedule.forEach((day) => {
      this.setTimePicker(day);
    });

    // const sunday = this.schedule.find((x: Schedule) => x.day === 0);
    // this.schedule.splice(this.schedule.indexOf(sunday), 1);
    // this.schedule.push(sunday);
  }

  sliderValueChange(event: any, type: string, schedule: Schedule): void {
    let changeValue = event.toString().length > 1 ? "" : "0";

    if (event % 1 === 0) {
      changeValue += Math.floor(event) + ":00";
    } else {
      changeValue += Math.floor(event) + ":30";
    }

    if (type === "start") {
      schedule.startTime = changeValue;
    } else {
      schedule.endTime = changeValue;
    }

    this.setTimePicker(schedule);
  }

  setTimePicker(set: Schedule): void {
    const startTime = set.startTime.split(":");
    // if (set.startTime === '00:00') {
    //     console.log('Setting startTime');
    //     set.startTime = '00:01';
    // }
    // if (set.endTime === '00:00') {
    //     console.log('Setting endtime');
    //     set.endTime = '23:59';
    // }

    const endTime = set.endTime.split(":");

    const timePicker = {
      start: {
        hours: startTime[0],
        minutes: startTime[1],
      },
      end: {
        hours: endTime[0],
        minutes: endTime[1],
      },
      time: {
        start: parseInt(startTime[0], 10) + (startTime[1] === "00" ? 0 : 0.5),
        end: parseInt(endTime[0], 10) + (endTime[1] === "00" ? 0 : 0.5),
      },
    };

    if (!this.timePickerOptions[set.day]) {
      this.timePickerOptions.push(timePicker);
    } else {
      this.timePickerOptions[set.day] = timePicker;
    }
  }

  setTime(i: number): void {
    if (
      parseInt(this.timePickerOptions[i].start.hours, 10) > parseInt(this.timePickerOptions[i].end.hours, 10) ||
      (parseInt(this.timePickerOptions[i].start.hours, 10) === parseInt(this.timePickerOptions[i].end.hours, 10) &&
        parseInt(this.timePickerOptions[i].start.minutes, 10) >= parseInt(this.timePickerOptions[i].end.minutes, 10))
    ) {
      if (
        this.timePickerOptions[i].start.hours === "24" ||
        (this.timePickerOptions[i].start.hours === "23" && this.timePickerOptions[i].start.minutes === "30")
      ) {
        if (parseInt(this.timePickerOptions[i].end.hours, 10) > 0) {
          const hourBefore = (parseInt(this.timePickerOptions[i].end.hours, 10) - 1).toString();
          this.timePickerOptions[i].start.hours = hourBefore.length > 1 ? hourBefore : "0" + hourBefore;
          this.timePickerOptions[i].start.minutes = this.timePickerOptions[i].end.minutes;
        } else {
          this.timePickerOptions[i].start.hours = "00";
          this.timePickerOptions[i].start.minutes = "00";
          this.timePickerOptions[i].end.hours = "01";
          this.timePickerOptions[i].end.minutes = "00";
        }
      } else {
        const hourAfter = (parseInt(this.timePickerOptions[i].start.hours, 10) + 1).toString();
        this.timePickerOptions[i].end.hours = hourAfter.length > 1 ? hourAfter : "0" + hourAfter;
        this.timePickerOptions[i].end.minutes = this.timePickerOptions[i].start.minutes;
      }
    }
    this.schedule[i].startTime = this.timePickerOptions[i].start.hours + ":" + this.timePickerOptions[i].start.minutes;
    this.schedule[i].endTime = this.timePickerOptions[i].end.hours + ":" + this.timePickerOptions[i].end.minutes;

    this.schedule.forEach((schedule) => {
      this.setTimePicker(schedule);
    });
  }

  setTimes(set: any): { start: number; end: number } {
    let start_time = set.start * 60;
    let end_time = set.end * 60;
    if (set.start % 1 !== 0) {
      start_time = Math.floor(set.start) * 60 + 30;
    }
    if (set.end % 1 !== 0) {
      end_time = Math.floor(set.end) * 60 + 30;
    }
    return {
      start: start_time,
      end: end_time,
    };
  }
}
