import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgOptionHighlightModule } from "@ng-select/ng-option-highlight";
import { TranslateModule } from "@ngx-translate/core";
import { ngxLoadingAnimationTypes } from "../loading/ngx-loading-config";
import { NgxLoadingModule } from "../loading/public_api";
import { DragDropDirective } from "./drag-drop.directive";
import { UploaderComponent } from "./uploader.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    NgOptionHighlightModule,
    TranslateModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.rectangleBounce,
      backdropBackgroundColour: "rgba(255,255,255,0.7)",
      backdropBorderRadius: "4px",
      primaryColour: "#DC8139",
      secondaryColour: "#DC8139",
      tertiaryColour: "#DC8139",
    }),
  ],
  declarations: [UploaderComponent, DragDropDirective],
  exports: [UploaderComponent, TranslateModule],
})
export class UploaderModule {}
