import { Component, EventEmitter, Output, ChangeDetectionStrategy, ChangeDetectorRef, Input } from "@angular/core";

@Component({
  selector: "fh-batch-simple",
  templateUrl: "batchSimple.template.html",
  changeDetection: ChangeDetectionStrategy.Default,
})
export class BatchSimpleComponent {
  @Input() gridSelection = [];
  @Output() onFinish = new EventEmitter();
  logItem: string;

  constructor(private cd: ChangeDetectorRef) {
    console.log("open log adding");
  }

  accept() {
    this.onFinish.emit({ status: "success", object: {} });
  }

  decline() {
    this.onFinish.emit({ status: "hide", object: {} });
  }
}
