import { Component, OnInit } from "@angular/core";

@Component({
  selector: "fh-theme-sttings",
  templateUrl: "theme.template.html",
})
export class ThemeSettingsComponent implements OnInit {
  color1;
  color2;
  chColor1;
  chColor2;
  chColor3;
  textColor;

  constructor() {}

  ngOnInit(): void {
    // https://material.io/color/#!/?view.left=0&view.right=0&primary.color=E6EE9C&secondary.color=FAFAFA

    this.color1 = "#385D91";
    this.color2 = "#CB711D";
    this.chColor1 = "#CB711D";
    this.chColor2 = "#C9A484";
    this.chColor3 = "#e6cbb4";
    this.textColor = "#3D3E3F";
  }
}
