import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { throwError } from "rxjs/internal/observable/throwError";
import { Observable, catchError, map } from "rxjs";
import { AuthenticationService } from "../authentication/authentication.service";
import { TranslatorService } from "../common/translator.service";
import { LoggingService } from "../logging/logging.service";

@Injectable()
export class InfoService {
  token: string;
  timestamp;
  url = "";
  base_url = "";

  constructor(
    private translatorService: TranslatorService,
    private http: HttpClient,
    private loggingService: LoggingService,
    private authenticationService: AuthenticationService
  ) {
    this.url = authenticationService.getWebserviceURL("info");
    this.base_url = this.authenticationService.getWebserviceURL("");
    this.token = authenticationService.getAuthToken();
  }

  getEndPoint() {
    return this.authenticationService.getWebserviceURL("");
  }

  getInfo(): Observable<any> {
    this.token = this.authenticationService.getAuthToken();
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + this.token,
    });

    console.log("Fetch info");
    return this.http.get(this.url, { headers: headers }).pipe(
      map((data) => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  getHealth(): Observable<any> {
    this.token = this.authenticationService.getAuthToken();
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + this.token,
    });

    console.log("Fetch info");
    return this.http.get(this.base_url + "health", { headers: headers }).pipe(
      map((data) => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  private handleError(error: Response) {
    return throwError(error);
  }
}
