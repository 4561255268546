import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { WizardComponent } from "app/modules/wizard/wizard";
import { AccountService } from "app/services/account/account.service";
import { ReportService } from "app/services/reports/report.service";
import { ResellerService } from "app/services/resellers/resellers.service";
import { ReportSubscription } from "app/models/reporting.model";
import { TranslateService } from "@ngx-translate/core";
import { FormMode } from "app/common/enums";

// Moment
import Moment from "moment-timezone";
import { ReportWizardComponent } from "../shared/usercontrols/reportWizard.component";

window["moment"] = Moment;

@Component({
  selector: "fh-reporting-create",
  templateUrl: "reportingCreate.template.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportingCreateViewComponent implements OnInit {
  @ViewChild(WizardComponent, { static: true }) wizard: WizardComponent;
  @ViewChild(ReportWizardComponent, { static: true }) reportWizard: ReportWizardComponent;

  processing = false;

  formMode = FormMode.add;

  success;
  error;

  // Resellers
  selectedReseller;
  loadingResellers = false;
  selectedResellerId;

  // Accounts
  selectedAccount;

  selectedAssetGroups;
  selectedDriverGroups;
  selectedGeofenceGroups;
  selectedProjects;

  // Report
  reportShown = false;
  reportData = {
    request: null,
    kpiList: [],
    charts: [],
    data: [],
  };

  reportSubscription: ReportSubscription;

  constructor(
    private resellerService: ResellerService,
    private reportService: ReportService,
    private accountService: AccountService,
    private translate: TranslateService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.reportSubscription = new ReportSubscription();
  }

  resellerChanged = (resellerId) => {
    this.reportSubscription.accountId = null;

    this.selectedResellerId = resellerId;
    this.selectedReseller = this.resellerService.resellers.find((x) => x.id === resellerId);
  };

  accountChanged = (accountId) => {
    this.reportSubscription.accountId = accountId;
    this.selectedAccount = this.accountService.accounts.find((x) => x.id === this.reportSubscription.accountId);

    this.selectedAssetGroups = [];
    this.selectedDriverGroups = [];
    this.selectedGeofenceGroups = [];
    this.selectedProjects = [];
  };

  onCheckOutFinish() {
    this.processing = true;

    this.reportService.saveReportSubscription(this.reportSubscription).subscribe({
      next: (result) => {
        this.processing = false;
        this.cd.markForCheck();

        this.reportShown = true;
        this.reportData = result;

        this.success = {
          statusText: "Success",
          success: this.translate.instant(result.message),
        };

        this.resetWizard();
      },
      error: (error) => {
        this.error = error;
        this.processing = false;
        this.cd.markForCheck();
      },
    });
  }

  resetWizard() {
    const oldReport = { ...this.reportSubscription };

    this.reportShown = false;
    this.reportSubscription = new ReportSubscription();

    this.reportSubscription.selectedReportCategory = oldReport.selectedReportCategory;
    this.reportSubscription.selectedReportType = oldReport.selectedReportType;
    this.reportSubscription.accountId = oldReport.accountId;
    this.reportSubscription.name = oldReport.name;

    this.reportSubscription.parameterConfiguration = oldReport.parameterConfiguration;
    this.reportSubscription.template = oldReport.template;

    // this.reportWizard.reportSubscription.selectedAssetGroups = oldReport.selectedAssetGroups;
    // this.reportWizard.reportSubscription.selectedAssets = oldReport.selectedAssets;
    // this.reportWizard.reportSubscription.selectedDriverGroups = oldReport.selectedDriverGroups;
    // this.reportWizard.reportSubscription.selectedDrivers = oldReport.selectedDrivers;
    // this.reportWizard.reportSubscription.selectedGeofenceGroups = oldReport.selectedGeofenceGroups;
    // this.reportWizard.reportSubscription.selectedGeofences = oldReport.selectedGeofences;
    // this.reportWizard.reportSubscription.selectedProjects = oldReport.selectedProjects;

    this.wizard.reset();
  }
}
