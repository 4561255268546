<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/"> <i class="fas fa-fw fa-home"></i> {{ "general.home" | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/System/">{{ "general.system" | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/System/Tags">{{ "general.tags" | translate }}</a>
                </li>
            </ol>
        </nav>
    </div>
    <fh-system-navigation></fh-system-navigation>
</div>

<div class="wrapper wrapper-content">
    <div class="row">
        <div class="col-lg-12">
            <div class="ibox">
                <div class="ibox-title">
                    <h5><i class="fas fa-fw fa-tags"></i> {{ "search.tags" | translate }}</h5>
                </div>
                <div class="ibox-content" style="position: relative">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <table style="width: 100%" class="table nowrap dtr-inline no-footer">
                        <thead>
                            <tr>
                                <th>
                                    {{ "general.tag" | translate }}
                                </th>
                                <th>
                                    {{ "general.name" | translate }}
                                </th>
                                <th>
                                    {{ "general.type" | translate }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let tag of tags; index as i" (click)="goTo(tag)">
                                <td title="{{ tag.objectId }}">
                                    <a>{{ tag.name }}</a>
                                </td>
                                <td>
                                    <a *ngIf="tag.objectName"
                                        ><b>{{ tag.objectName }}</b></a
                                    >
                                    <a *ngIf="!tag.objectName"
                                        ><b>{{ "general.undefined" | translate }}</b></a
                                    >
                                </td>
                                <td>
                                    <a>{{ "enums.entityTypes." + tag.objectType | translate }}</a>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div style="clear: both"></div>
                </div>
            </div>
        </div>
    </div>
</div>
