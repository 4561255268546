import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef } from "@angular/core";
import { ControlContainer, NgForm } from "@angular/forms";
import { FormMode } from "app/common/enums";
import { Driver } from "app/models/driver.model";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import { AuthenticationService } from "app/services/authentication/authentication.service";

@Component({
  selector: "fh-driver-details",
  templateUrl: "driverDetails.component.html",
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class DriverDetailsComponent implements OnInit {
  @Input() driver: Driver;
  @Input() loading = false;
  @Input() formMode: any = FormMode.read;
  @Output() onAccountChanged = new EventEmitter();

  // Datepicker
  public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();

  accountPlatforms = [];

  selectedAccountId;
  selectedResellerId;
  permissions: {};

  constructor(private cd: ChangeDetectorRef, private authenticationService: AuthenticationService) {
    this.permissions = this.authenticationService.permissions;
  }

  ngOnInit(): void {
    // Datepicker
    this.dpConfig.containerClass = "theme-default"; // or whatever color
    this.dpConfig.dateInputFormat = "ll";
    this.dpConfig.isAnimated = true;
    this.dpConfig.withTimepicker = false;
  }

  resellerChanged(resellerId) {
    this.driver.accountId = null;

    this.selectedAccountId = null;
    this.selectedResellerId = resellerId;
  }

  accountChanged(accountId) {
    this.onAccountChanged.emit(this.driver.accountId);
  }
}
