<div class="ibox-content slick" style="position: relative">
    <ngx-loading [show]="loadingUserRoles"></ngx-loading>
    <table style="width: 100%" class="table table-fixed nowrap dtr-inline no-footer">
        <tbody *ngIf="!user">
            <tr>
                <td colspan="4">
                    {{ "general.noUser" | translate }}
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="user">
            <ng-container *ngIf="formMode == 2">
                <tr>
                    <td class="row_opening">
                        {{ "general.password" | translate }}
                        <span class="error" *ngIf="password.invalid">*</span>
                    </td>
                    <td class="row_content">
                        <fh-password-input
                            name="password"
                            autocomplete="off"
                            [(ngModel)]="user.password"
                            required
                            minlength="4"
                            pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@!%?#.&])[\S]{8,}$"
                            placeholder="{{ 'placeholder.newPassword' | translate }}"
                            #password="ngModel"
                            behaviour="click"
                        >
                        </fh-password-input>
                    </td>
                </tr>
                <tr>
                    <td class="row_opening">
                        {{ "general.repeatPassword" | translate }}
                        <span class="error" *ngIf="password2.invalid">*</span>
                    </td>
                    <td class="row_content">
                        <input
                            type="password"
                            placeholder="{{ 'placeholder.repeatPassword' | translate }}"
                            name="password2"
                            class="form-control table-inline"
                            autocomplete="off"
                            [(ngModel)]="passwordConfirmation"
                            required
                            minlength="4"
                            #password2="ngModel"
                        />
                    </td>
                </tr>
                <tr
                    class="alert alert-danger"
                    *ngIf="
                        (password.value?.length > 0 &&
                            password2.value?.length > 0 &&
                            password.value !== password2.value) ||
                        (password?.invalid && (password?.dirty || password?.touched))
                    "
                >
                    <td colspan="2">
                        <div *ngIf="password.value !== password2.value">* Passwords do not match</div>
                        <div *ngIf="password?.errors?.minlength">* Password must be at least 4 characters long.</div>
                        <div *ngIf="password.errors?.pattern">
                            * Requirements are not ok<br />
                            (Eight characters, Uppercase letter, Lowercase letter, Special char, Number)
                        </div>
                        <div *ngIf="password?.errors?.required">* Password is required</div>
                    </td>
                </tr>
            </ng-container>
            <tr>
                <td class="row_opening">
                    {{ "general.whitelabel" | translate }}
                    <span class="error" *ngIf="whitelabel.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content" style="overflow: visible">
                    <ng-select
                        placeholder="{{ 'general.chooseUserContactGroup' | translate }}"
                        name="whitelabel"
                        [disabled]="!permissions['Platform_IsReseller'] || formMode == 1"
                        [clearable]="false"
                        [(ngModel)]="user.userType"
                        class="form-control table-inline"
                        #whitelabel="ngModel"
                        required
                        style="width: 100%"
                    >
                        <ng-option *ngFor="let userType of userTypes" [value]="userType.value">
                            {{ userType.name }}
                        </ng-option>
                    </ng-select>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ "dates.timezone" | translate }}
                    <span class="error" *ngIf="timezoneVar.invalid">*</span>
                </td>
                <td class="row_content" style="overflow: visible">
                    <fh-timezone-picker
                        name="timeZoneSelector"
                        [disabled]="formMode == 1"
                        [timezone]="user.timezoneIana"
                        required
                        #timezoneVar="ngModel"
                        [(ngModel)]="user.timezoneIana"
                    >
                    </fh-timezone-picker>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ "general.assetDisplayName" | translate }}
                    <span class="error" *ngIf="assetDisplayName.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content" style="overflow: visible">
                    <ng-select
                        placeholder="{{ 'general.chooseUserContactGroup' | translate }}"
                        name="assetDisplayName"
                        [disabled]="formMode == 1"
                        [(ngModel)]="user.assetDisplayName"
                        [clearable]="false"
                        required
                        class="form-control table-inline"
                        #assetDisplayName="ngModel"
                        style="width: 100%"
                    >
                        <ng-option *ngFor="let displayName of displayNameOptions" [value]="displayName.value">
                            {{ displayName.name }}
                        </ng-option>
                    </ng-select>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ "general.distanceUnit" | translate }}
                    <span class="error" *ngIf="distanceUnit.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content" style="overflow: visible">
                    <ng-select
                        placeholder="{{ 'general.chooseUserContactGroup' | translate }}"
                        name="distanceUnit"
                        [disabled]="formMode == 1"
                        [(ngModel)]="user.distanceUnit"
                        required
                        [clearable]="false"
                        class="form-control table-inline"
                        #distanceUnit="ngModel"
                        style="width: 100%"
                    >
                        <ng-option *ngFor="let unit of distanceUnits" [value]="unit.value">
                            {{ unit.name }}
                        </ng-option>
                    </ng-select>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ "general.volumeUnit" | translate }}
                    <span class="error" *ngIf="volumeUnit.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content" style="overflow: visible">
                    <ng-select
                        placeholder="{{ 'general.chooseUserContactGroup' | translate }}"
                        name="volumeUnit"
                        [disabled]="formMode == 1"
                        [(ngModel)]="user.volumeUnit"
                        required
                        [clearable]="false"
                        class="form-control table-inline"
                        #volumeUnit="ngModel"
                        style="width: 100%"
                    >
                        <ng-option *ngFor="let unit of volumeUnits" [value]="unit.value">
                            {{ unit.name }}
                        </ng-option>
                    </ng-select>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ "general.userRole" | translate }}
                    <span class="error" *ngIf="userRole.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content" style="overflow: visible">
                    <ng-select
                        [loading]="loadingUserRoles"
                        name="userRoleId"
                        ngDefaultControl
                        [searchable]="false"
                        [clearable]="false"
                        placeholder="{{ 'general.chooseUserRole' | translate }}"
                        required
                        [(ngModel)]="user.userRoleId"
                        #userRole="ngModel"
                        [disabled]="formMode == 1"
                        class="form-control table-inline"
                        style="width: 100%"
                        (ngModelChange)="userRoleChanged()"
                    >
                        <ng-option *ngFor="let userRole of userRoles | orderBy : 'name' : false" [value]="userRole.id">
                            {{ userRole.name }}</ng-option
                        >
                    </ng-select>
                </td>
            </tr>
        </tbody>
    </table>
</div>
