import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { DistanceUnitService } from "app/common/distanceunit.service";
import { VehicleType } from "app/common/enums";
import { DriverService } from "app/services/driver/driver.service";

@Component({
  selector: "fh-account-driverscore-settings",
  templateUrl: "driverscoresettings.template.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountDriverScoreSettingsViewComponent implements OnInit {
  error;
  success;
  warning;

  accountId;
  selectedResellerId;
  selectedVehicleType = "0";
  calculationBasedOn = "1";

  vehicleTypes = [];

  options = [];

  loading = false;
  sliderOptionsScore: {};
  sliderOptionsMg: {};
  timePickerOptions = [];
  sliderOptionsSpeed: {};
  sliderOptionsRpm: {};
  timerid: any;

  constructor(
    private cd: ChangeDetectorRef,
    private driverService: DriverService,
    private distance: DistanceUnitService,
    private translateService: TranslateService
  ) {}

  save() {
    this.loading = true;

    this.driverService.saveEcoDrivingSettings(this.accountId, null, this.calculationBasedOn, this.options).subscribe({
      next: (result) => {
        this.success = {
          statusText: "Success",
        };

        this.loading = false;
        this.cd.markForCheck();
      },
      error: (error) => {
        this.error = error;
        this.loading = false;
        this.cd.markForCheck();
      },
    });
  }

  changeValue(index, values, event, columnName) {
    if (index > -1) {
      values[index][columnName] = event;
      this.cd.markForCheck();
    }
  }

  changeHighValue(index, values, event, columnName) {
    if (this.timerid !== null) {
      clearTimeout(this.timerid);
    }
    this.timerid = setTimeout(() => {
      if (values.length > index + 1) {
        values[index + 1][columnName] = event;
        this.cd.markForCheck();
      }
    }, 10);
  }

  getValue(values, index, columnName) {
    return values[index][columnName];
  }

  getNextValue(values, index, columnName, columnType) {
    if (values.length > index + 1) {
      return values[index + 1][columnName];
    } else {
      if (columnType === "speeding") {
        return 100;
      }

      if (columnType === "rpm") {
        return 10000;
      }

      return 1000;
    }
  }

  addItem(name, values) {
    values.push({ ...values[values.length - 1] });
  }

  removeItem(index, values) {
    values.splice(index, 1);
  }

  ngOnInit(): void {
    this.vehicleTypes = Object.keys(VehicleType)
      .filter((k) => typeof VehicleType[k] === "string")
      .map((n) => ({ id: n.toString(), name: VehicleType[n] }));

    this.sliderOptionsScore = {
      noSwitching: true,
      floor: 0,
      ceil: 100,
      showSelectionBar: true,
    };

    this.sliderOptionsSpeed = {
      noSwitching: true,
      floor: 0,
      ceil: 50,
      step: 5,
      showSelectionBar: true,
      selectionBarGradient: {
        from: "yellow",
        to: "#FF0000",
      },
      translate: (value: number): string => {
        return "+ " + value + "  " + this.translateService.instant(this.distance.getDistanceUnitPerHour());
      },
    };

    this.sliderOptionsRpm = {
      noSwitching: true,
      floor: 500,
      ceil: 10000,
      step: 250,
      showSelectionBar: true,
      selectionBarGradient: {
        from: "yellow",
        to: "#FF0000",
      },
      translate: (value: number): string => {
        return value + " rpm";
      },
    };

    this.sliderOptionsMg = {
      noSwitching: true,
      showTicksValues: false,
      floor: 150,
      ceil: 750,
      step: 25,
      showSelectionBar: true,
      selectionBarGradient: {
        from: "yellow",
        to: "#FF0000",
      },
      translate: (value: number): string => {
        return value + " mg";
      },
    };
  }

  resellerChanged(event) {
    this.accountId = null;
    this.accountChanged(null);
  }

  accountChanged(event) {
    // check if setting is availible. when not set defaults

    if (!this.accountId) {
      this.options = [];
      return;
    }

    this.loading = true;
    this.driverService.getEcoDrivingSettings(this.accountId, null).subscribe({
      next: (result) => {
        if (result) {
          this.calculationBasedOn = result.calculationBasedOn.toString();
          this.options = result.settings;
          this.selectedVehicleType = "0";
        } else {
          this.setDefault();
        }
        this.loading = false;
        this.cd.markForCheck();
      },
      error: (error) => {
        this.error = error;
        this.loading = false;
        this.cd.markForCheck();
      },
    });
  }

  setDefault() {
    this.success = {
      statusText: "Notification",
      success: "No saved driverscore settings found on this account. Filled with default.",
    };

    // Set default values
    this.vehicleTypes.forEach((vehicleType, index) => {
      this.options[vehicleType.id.toString()] = {
        vehicleTypeId: vehicleType.id.toString(),
        enableSpeeding: true,
        speedingBaseScore: 10,
        speedingValues: [
          {
            value: 1,
            score: 1,
          },
          {
            value: 10,
            score: 5,
          },
          {
            value: 30,
            score: 20,
          },
        ],
        enableIdling: true,
        idlingScore: 5,
        enableSeatbelt: true,
        seatbeltScore: 20,
        enableRpm: true,
        rpmBaseScore: 5,
        rpmValues: [
          {
            value: 4500,
            score: 1,
          },
          {
            value: 5500,
            score: 5,
          },
          {
            value: 7000,
            score: 20,
          },
        ],
        enableAcceleration: true,
        accelerationBaseScore: 10,
        accelerationValues: [
          {
            value: 250,
            score: 5,
          },
          {
            value: 310,
            score: 10,
          },
          {
            value: 350,
            score: 30,
          },
        ],
        enableBraking: true,
        brakingBaseScore: 15,
        brakingValues: [
          {
            value: 300,
            score: 10,
          },
          {
            value: 350,
            score: 30,
          },
          {
            value: 410,
            score: 50,
          },
        ],
        enableCornering: true,
        corneringBaseScore: 20,
        corneringValues: [
          {
            value: 300,
            score: 10,
          },
          {
            value: 350,
            score: 30,
          },
          {
            value: 410,
            score: 50,
          },
        ],
      };
    });
  }
}
