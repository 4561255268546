<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/"><i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item active">{{'general.shareTokens' | translate}}</li>
            </ol>
        </nav>
    </div>
</div>

<div class="wrapper wrapper-content">
    <div class="row">
        <div class="col-lg-12">
            <fh-error [success]="success" [error]="error"></fh-error>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <div class="ibox">
                <div class="ibox-title">
                    <h5><i class="fa fa-fw fa-share-nodes"></i> {{'general.shareTokens' | translate }}</h5>
                </div>
                <div class="ibox-content slick" style="position: relative; overflow-y: auto">
                    <ngx-loading [show]="loading"></ngx-loading>

                    <table style="width: 100%" class="table nowrap dtr-inline no-footer">
                        <thead>
                            <tr>
                                <th>{{'general.name' | translate }}</th>
                                <th>{{'general.token' | translate }}</th>
                                <th style="text-align: right">{{'general.timestamp' | translate }}</th>
                                <th style="text-align: right">{{'general.expiryTimestamp' | translate }}</th>
                                <th style="text-align: right">{{'general.lastVisited' | translate }}</th>
                                <th style="text-align: right">{{'general.visitCount' | translate }}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngIf="shareTokens?.length == 0">
                                <td colspan="4">{{'general.noDataFound' | translate }}</td>
                            </tr>
                            <tr *ngFor="let shareToken of shareTokens; let i = index">
                                <td>
                                    <a
                                        class="secondary link_bolder"
                                        href="{{baseUrl}}/#/Sharing/{{shareToken.token}}"
                                        target="_blank"
                                    >
                                        {{ shareToken.name || (('enums.shareTokenTypes.' + shareToken.type) | translate)
                                        }}</a
                                    >
                                </td>

                                <td>
                                    <fh-copyinput [textMode]="true">
                                        {{ baseUrl }}/#/Sharing/{{ shareToken.token }}
                                    </fh-copyinput>
                                </td>
                                <td style="text-align: right">{{ shareToken.timestamp | amDateFormat: 'LLL'}}</td>
                                <td style="text-align: right">{{ shareToken.expiryTimestamp | amTimeAgo}}</td>
                                <td style="text-align: right">{{ shareToken.lastVisited | amDateFormat: 'LLL'}}</td>
                                <td style="text-align: right">{{ shareToken.visitCount }}</td>
                                <td>
                                    <a
                                        class="btn btn-danger btn-grid"
                                        (click)="deleteShareToken(shareToken.id)"
                                        title="{{'general.delete' | translate }}"
                                    >
                                        <i class="fas fa-fw fa-trash-alt"></i>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
