<ng-select
    [loading]="loadingResellers"
    ngDefaultControl
    [searchable]="true"
    [disabled]="resellers.length == 0 || disable"
    [readonly]="readonly"
    [clearable]="clearable"
    placeholder="{{ placeholder }} ({{ resellers.length }})"
    #input
    [(ngModel)]="value"
    class="form-control"
    [ngClass]="tableInline === true ? 'table-inline' : ''"
    (ngModelChange)="resellerChanged()"
>
    <ng-option *ngFor="let reseller of resellers | orderBy : 'resellerDescription' : false" [value]="reseller.id">
        <i
            class="fas fa-fw fa-city"
            title="Accounts : {{ reseller.clientCount }} / Devices: {{ reseller.deviceCount }}"
        ></i>
        {{ reseller.resellerDescription }}
    </ng-option>
</ng-select>
<div style="clear: both"></div>
