import { ChangeDetectorRef, Component, Input, OnChanges, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { EntityType, StorageType } from "app/common/enums";
import { StorageHelper } from "app/common/storagehelper";
import { TagService } from "app/services/tag/tag.service";
import { TagInputModule } from "ngx-chips";
import { Observable, map } from "rxjs";

@Component({
  selector: "fh-tagging",
  templateUrl: "tagging.template.html",
})
export class TaggingComponent implements OnInit, OnChanges {
  @Input() objectId: string;
  @Input() objectType: EntityType;

  items = [];
  isMessage: boolean;
  messageStatus: string;
  updateMessage: any;
  loading = false;
  storageType = StorageType.LocalStorage;
  hideTags = false;

  constructor(
    private tagService: TagService,
    private router: Router,
    private translateServe: TranslateService,
    private cd: ChangeDetectorRef,
    private storageHelper: StorageHelper
  ) {
    this.loading = false;

    this.storageHelper.loadStoreState(this.storageType, "settings_", "hideTags").subscribe((result) => {
      this.hideTags = JSON.parse(result) === true;
    });
  }

  public requestAutocompleteItems = (text: string): Observable<string[]> => {
    return this.tagService.getTagsWithUsage().pipe(map((tags) => tags.map((tag) => tag.name)));
  };

  ngOnInit() {
    TagInputModule.withDefaults({
      tagInput: {
        placeholder: this.translateServe.instant("general.tagEnterNew"),
        // add here other default values for tag-input
      },
      dropdown: {
        displayBy: "my-display-value",
        // add here other default values for tag-input-dropdown
      },
    });

    this.items = [];
  }

  ngOnChanges() {
    this.loading = true;
    this.tagService.getTagsForObject(this.objectId, this.objectType).subscribe((result) => {
      this.items = result.map((x) => x.name);
      this.loading = false;
      this.cd.markForCheck();
    });
  }

  onItemAdded(event) {
    console.log("Item added: " + event.display);
    this.loading = true;
    this.tagService.saveTag(event.display, this.objectId, this.objectType).subscribe(
      (result) => {
        if (result === true) {
          this.isMessage = true;
          this.messageStatus = "alert-success";
          this.updateMessage = this.translateServe.instant("general.tagSaved");
          this.loading = false;
          this.cd.markForCheck();
        } else {
          this.isMessage = true;
          this.messageStatus = "alert-warning";
          this.updateMessage = this.translateServe.instant("general.tagNotSaved");
          this.loading = false;
          this.cd.markForCheck();
        }
      },
      (error) => {
        this.updateMessage = this.translateServe.instant("general.tagNotSaved");
        this.loading = false;
        this.cd.markForCheck();
      }
    );

    // return Observable.of(true);
  }

  onItemRemoved(event) {
    console.log("Item deleting: " + event);
    this.loading = true;
    if (event.display) {
      // when just added
      event = event.display;
    }
    this.tagService.removeTag(event, this.objectId, this.objectType).subscribe(
      (result) => {
        if (result === true) {
          this.isMessage = true;
          this.messageStatus = "alert-success";
          this.updateMessage = this.translateServe.instant("general.tagRemoved");
          this.loading = false;
          this.cd.markForCheck();
        } else {
          this.isMessage = true;
          this.messageStatus = "alert-warning";
          this.updateMessage = this.translateServe.instant("general.tagNotRemoved");
          this.loading = false;
          this.cd.markForCheck();
        }
      },
      (error) => {
        this.updateMessage = this.translateServe.instant("general.tagNotRemoved");
        this.loading = false;
        this.cd.markForCheck();
      }
    );

    // return Observable.of(true);
  }

  onSelected(event) {
    if (event.display) {
      // when just added
      this.router.navigate(["/Tags/" + event.display]);
    } else {
      this.router.navigate(["/Tags/" + event]);
    }
  }

  onTagEdited(event) {
    console.log("Item edited: " + event);
  }

  onInputFocused(event) {
    console.log("Input focussed: " + event);
  }
}
