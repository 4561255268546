<ng-select
    [loading]="loadingDriverGroups"
    ngDefaultControl
    [searchable]="true"
    [clearable]="clearable"
    [disabled]="driverGroups.length == 0"
    [readonly]="readonly"
    placeholder="{{placeholder}} ({{driverGroups.length}})"
    #input
    [(ngModel)]="value"
    class="form-control table-inline"
    (ngModelChange)="driverGroupChanged()"
>
    <ng-option *ngFor="let driverGroup of driverGroups | orderBy : 'name':false" [value]="driverGroup.id">
        <i class="fas fa-fw fa-layer-group"></i> {{ driverGroup.name }}</ng-option
    >
</ng-select>
