import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ControlContainer, NgForm } from "@angular/forms";
import { FormMode } from "app/common/enums";
import { Reseller } from "app/models/reseller.model";
import { ResellerService } from "app/services/resellers/resellers.service";

@Component({
  selector: "fh-reseller-details",
  templateUrl: "resellerDetails.component.html",
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class ResellerDetailsComponent implements OnInit {
  @Input() reseller: Reseller;
  @Input() loading = false;
  @Input() formMode: any = FormMode.read;
  @Output() onResellerChanged = new EventEmitter();
  loadingResellers = false;

  resellers = [];

  constructor(private cd: ChangeDetectorRef, private resellerService: ResellerService) {}

  ngOnInit(): void {}

  resellerChanged(resellerId) {
    this.onResellerChanged.emit(this.reseller.parentResellerId);
  }
}
