<!-- Archive -->
<fh-batch-archive-assets
    *ngIf="selectedAction && selectedAction.batchActionType == 1"
    [gridSelection]="gridSelection"
    (onFinish)="process($event)"
>
</fh-batch-archive-assets>

<!-- Un-archive -->
<fh-batch-unarchive-assets
    *ngIf="selectedAction && selectedAction.batchActionType == 2"
    [gridSelection]="gridSelection"
    (onFinish)="process($event)"
>
</fh-batch-unarchive-assets>

<!-- Add logitem -->
<fh-batch-add-log
    *ngIf="selectedAction && selectedAction.batchActionType == 3 || selectedAction?.batchActionType == 25"
    [gridSelection]="gridSelection"
    (onFinish)="process($event)"
>
</fh-batch-add-log>

<!-- get locations-->
<fh-batch-get-location
    *ngIf="selectedAction && selectedAction.batchActionType == 4"
    [gridSelection]="gridSelection"
    (onFinish)="process($event)"
>
</fh-batch-get-location>

<!-- Add to group -->
<fh-batch-group-add
    *ngIf="selectedAction && selectedAction.batchActionType === 5"
    [gridSelection]="gridSelection"
    [batchEntityType]="entityTypes"
    (onFinish)="process($event)"
>
</fh-batch-group-add>

<!--Add to client -->
<fh-batch-activate-device
    *ngIf="selectedAction && selectedAction.batchActionType == 6"
    [gridSelection]="gridSelection"
    (onFinish)="process($event)"
>
</fh-batch-activate-device>

<!-- Update fields -->
<fh-batch-update-fields
    [entityTypes]="entityTypes"
    [gridSelection]="gridSelection"
    *ngIf="selectedAction && selectedAction.batchActionType == 7"
    (onFinish)="process($event)"
>
</fh-batch-update-fields>

<!-- Update devicesettings -->
<fh-batch-device-settings
    [gridSelection]="gridSelection"
    *ngIf="selectedAction && selectedAction.batchActionType == 8"
    (onFinish)="process($event)"
>
</fh-batch-device-settings>

<!-- delete -->
<fh-batch-delete
    *ngIf="selectedAction && selectedAction.batchActionType == 9"
    [gridSelection]="gridSelection"
    (onFinish)="process($event)"
>
</fh-batch-delete>

<!-- Apply devicesettingchanges -->
<fh-batch-devicesettingchanges
    [gridSelection]="gridSelection"
    *ngIf="selectedAction && selectedAction.batchActionType == 10"
    (onFinish)="process($event)"
>
</fh-batch-devicesettingchanges>

<!-- Change Reseller -->
<fh-batch-change-inventory
    [gridSelection]="gridSelection"
    *ngIf="selectedAction?.batchActionType == 11"
    (onFinish)="process($event)"
>
</fh-batch-change-inventory>

<!-- Move to client -->
<fh-batch-move-to-client
    *ngIf="selectedAction && selectedAction.batchActionType == 12"
    [gridSelection]="gridSelection"
    (onFinish)="process($event)"
>
</fh-batch-move-to-client>

<!-- Replace device -->
<fh-batch-replace-device
    *ngIf="selectedAction && selectedAction.batchActionType == 13"
    [gridSelection]="gridSelection"
    [resellerId]="batchProcessing ? 0 : entity.resellerId"
    (onFinish)="process($event)"
>
</fh-batch-replace-device>

<!-- Deactivate device -->
<fh-batch-remove-from-account
    *ngIf="selectedAction && selectedAction.batchActionType == 14"
    [gridSelection]="gridSelection"
    (onFinish)="process($event)"
>
</fh-batch-remove-from-account>

<!-- Register WASL -->
<fh-batch-register-wasl
    *ngIf="selectedAction && selectedAction.batchActionType == 15"
    [gridSelection]="gridSelection"
    (onFinish)="process($event)"
>
</fh-batch-register-wasl>

<!-- UnRegister WASL -->
<fh-batch-unregister-wasl
    *ngIf="selectedAction && selectedAction.batchActionType == 16"
    [gridSelection]="gridSelection"
    (onFinish)="process($event)"
>
</fh-batch-unregister-wasl>

<!-- Delete device history -->
<fh-batch-delete-history
    *ngIf="selectedAction && selectedAction.batchActionType == 17"
    [gridSelection]="gridSelection"
    (onFinish)="process($event)"
>
</fh-batch-delete-history>

<!-- Reset device -->
<fh-batch-reset-device
    *ngIf="selectedAction && selectedAction.batchActionType == 18"
    [gridSelection]="gridSelection"
    (onFinish)="process($event)"
>
</fh-batch-reset-device>

<!-- Virtual sensors -->
<fh-batch-virtualsensors-device
    *ngIf="selectedAction && selectedAction.batchActionType == 21"
    [gridSelection]="gridSelection"
    (onFinish)="process($event)"
>
</fh-batch-virtualsensors-device>

<!-- Create trip report -->
<fh-batch-instant-report
    *ngIf="selectedAction && selectedAction.batchActionType == 22"
    [gridSelection]="gridSelection"
    (onFinish)="process($event)"
>
</fh-batch-instant-report>

<!-- Calibrate odo -->
<fh-batch-odo
    *ngIf="selectedAction && selectedAction.batchActionType == 23"
    [gridSelection]="gridSelection"
    (onFinish)="process($event)"
>
</fh-batch-odo>

<!-- Rerun trips -->
<fh-batch-rerun-trips
    *ngIf="selectedAction && selectedAction.batchActionType == 24"
    [gridSelection]="gridSelection"
    (onFinish)="process($event)"
>
</fh-batch-rerun-trips>

<!-- Basic trigger-->
<fh-batch-basictrigger
    *ngIf="selectedAction && selectedAction.batchActionType == 26"
    [gridSelection]="gridSelection"
    [accountId]="batchProcessing ? 0 : [entity.accountId]"
    (onFinish)="process($event)"
>
</fh-batch-basictrigger>

<!-- Reset geofence -->
<fh-batch-simple
    *ngIf="selectedAction && selectedAction.batchActionType == 27"
    [gridSelection]="gridSelection"
    (onFinish)="process($event)"
>
</fh-batch-simple>

<!-- Set up custom commands -->
<fh-batch-custom-commands
    *ngIf="selectedAction && selectedAction.batchActionType == 28"
    [gridSelection]="gridSelection"
    (onFinish)="process($event)"
>
</fh-batch-custom-commands>
