import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import Moment from "moment";

declare var L;

@Injectable()
export class MapService {
  public leafletMapComponent;

  public static get LEAFLET_MAPTYPE(): string {
    return "leaflet_maptype";
  }

  constructor(public translateService: TranslateService) {}

  public getLeafletMapType(): string {
    return localStorage.getItem(MapService.LEAFLET_MAPTYPE);
  }

  public setLeafletMapType(mapType: string | undefined): void {
    if (!mapType) {
      return;
    }
    localStorage.setItem(MapService.LEAFLET_MAPTYPE, mapType);
  }

  setPruneCluster(leafletView) {
    const that = this;

    leafletView.BuildLeafletClusterIcon = function (cluster) {
      const e = new L.Icon.MarkerCluster();
      e.stats = cluster.stats;
      e.population = cluster.population;
      return e;
    };

    const colors = ["#676767", "#008E43", "#971C24", "#EE9234", "#206EB4", "#000000", "#7D177B"],
      pi2 = Math.PI * 2;

    L.Icon.MarkerCluster = L.Icon.extend({
      options: {
        iconSize: new L.Point(44, 44),
        className: "prunecluster leaflet-markercluster-icon",
      },
      createIcon: function () {
        // based on L.Icon.Canvas from shramov/leaflet-plugins (BSD licence)
        const e = document.createElement("canvas");
        this._setIconStyles(e, "icon");
        const s = this.options.iconSize;
        e.width = s.x;
        e.height = s.y;
        // this.draw(e.getContext('2d'), s.x, s.y);
        this.draw(e.getContext("2d"), e.width, e.height);
        return e;
      },
      createShadow: function () {
        return null;
      },
      draw: function (canvas, width, height) {
        const lol = 0;

        let start = 0;
        for (let i = 0, l = colors.length; i < l; ++i) {
          const size = this.stats[i] / this.population;

          if (size > 0) {
            canvas.beginPath();
            canvas.moveTo(22, 22);
            canvas.fillStyle = colors[i];
            let from = start + 0.14,
              to = start + size * pi2;

            if (to < from) {
              from = start;
            }
            canvas.arc(22, 22, 22, from, to);

            start = start + size * pi2;
            canvas.lineTo(22, 22);
            canvas.fill();
            canvas.closePath();
          }
        }

        canvas.beginPath();
        canvas.fillStyle = "white";
        canvas.arc(22, 22, 18, 0, Math.PI * 2);
        canvas.fill();
        canvas.closePath();

        canvas.fillStyle = "#555";
        canvas.textAlign = "center";
        canvas.textBaseline = "middle";
        canvas.font = "bold 12px sans-serif";

        canvas.fillText(this.population, 22, 22, 40);
      },
    });

    leafletView.PrepareLeafletMarker = function (theMarker, data, category) {
      this.addLabel(theMarker);

      this.createPopup(theMarker, data, category);
    };

    return true;
  }

  addLabel(theMarker, data, permanent = true, skipIncludingGroupColors = false) {
    if (!data) {
      return;
    }

    let label = "";

    // label = '<table><tr><td colspan="2">' + data.title + '</td></tr>';

    // if (data.deviceState?.currentPosition?.speed > 0) {
    //     label += '<tr><td>' + data.deviceState?.currentPosition?.speed + 'km/h </td></tr>';
    // }

    // if (data.deviceState?.temperature1?.temperatureInCelcius > 0) {
    //     label += '<tr><td><i class="fa fa-fw fa-temperature-three-quarters"></i>' + roundAsNumber(data.deviceState?.temperature1?.temperatureInCelcius, 1) + ' °C</td></tr>';
    // }

    // if (data.deviceState?.weight1?.weightInKg > 0) {
    //     label += '<tr><td><i class="fa fa-fw fa-weight"></i>' + roundAsNumber(data.deviceState?.weight1?.weightInKg, 0) + ' kg</td></tr>';
    // }

    // label += '</table>';

    if (!skipIncludingGroupColors) {
      if (data.assetGroups?.length > 0) {
        data.assetGroups.forEach((assetGroup) => {
          if (assetGroup.color) {
            label += ` <i class="fa fa-fw fa-layer-group pointer" title="AssetGroup: ${assetGroup.name}" style="color: ${assetGroup.color}"></i>`;
          }
        });
      }
    }

    label += " " + data.title;

    const direction = "bottom";
    const offset = L.point(0, 20);

    // theMarker.data.label
    theMarker["bindTooltip"](label, { permanent: permanent, direction: direction, opacity: 0.8, offset: offset });
  }

  createPopup(theMarker, data, category) {
    // parse data to icon
    theMarker.setIcon(this.createIcon());

    var label = data.assetDisplayLabel;
    if (data.summary && data.summary.trim() != "" && data.summary.trim().length > 1) {
      label += " - " + data.summary;
    }

    // prettier-ignore
    const markerPopup = `
      <div style="width:100%">
        <div style="width:300px; overflow: auto;" class="leaflet-mappopup">
          <div class="header">${label}</div>
          <div class="content">${this.translateService.instant("general.imei")}</div>
          <div class="content">${data.imei}</div>
          
          ${
            data.deviceState !== undefined
              ? `
            <div class="content">
              ${this.translateService.instant("general.deviceState_" + data.deviceState)}
            </div>
            <div class="content" title="${data.stateChangedTimestamp?.toLocaleString()}">
              ${Moment(data.stateChangedTimestamp).fromNow()}
            </div>

            <div class="content">${this.translateService.instant("general.lastCommunication")}</div>
            <div class="content" title="${data.lastCommunication?.toLocaleString()}">
              ${Moment(data.lastCommunication).fromNow()}
            </div>
              
              ${
                data.deviceState === 1
                  ? `
                <div class="content">${this.translateService.instant("general.speed")}</div>
                <div class="content" title="${data.speed?.toLocaleString()}">
                  ${data.speed} ${this.translateService.instant("general.kmh")}
                </div>
              `
                  : ""
              }
            `
              : ""
          }
          
          <div class="content" style="width:100%">
            <a href="/#/DeviceDetails/Index/${data.deviceId}">
              ${this.translateService.instant("general.details")}
            </a> / 
            <a href="/#/DeviceDetails/Trips/${data.deviceId}">
              ${this.translateService.instant("general.trips")}
            </a>
          </div>
        </div>
      </div>
      `;

    if (theMarker.getPopup()) {
      theMarker.setPopupContent(markerPopup);
    } else {
      theMarker.bindPopup(markerPopup, {
        closeButton: false,
      });
    }
  }

  createIcon() {
    return L.icon({
      iconSize: [25, 41],
      iconAnchor: [13, 41],
      iconUrl: "assets/marker-icon.png",
      shadowUrl: "assets/marker-shadow.png",
    });
  }

  GetContextmenuItems(context) {
    const contextMenu: any = [
      {
        text: this.translateService.instant("general.getNearbyPois"),
        callback: context.nearestPois,
      },
      {
        text: this.translateService.instant("general.getNearbyAssets"),
        callback: context.nearestAssets,
      },
      {
        text: this.translateService.instant("general.centerMap"),
        callback: context.centerMap,
      },
    ];

    contextMenu.push({
      text: this.translateService.instant("general.saveLocation"),
      callback: context.showCoordinates,
    });

    contextMenu.push(
      "-",
      {
        text: this.translateService.instant("general.zoomIn"),
        icon: "assets/images/leaflet/zoom-in.png",
        callback: context.zoomIn,
      },
      {
        text: this.translateService.instant("general.zoomOut"),
        icon: "assets/images/leaflet/zoom-out.png",
        callback: context.zoomOut,
      }
    );

    return contextMenu;
  }
}
