import { animate, style, transition, trigger } from "@angular/animations";
import { HttpClient } from "@angular/common/http";
import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Device } from "app/models/device.model";

import { DeviceService } from "../../services/device/device.service";
import { AuthenticationService } from "app/services/authentication/authentication.service";

import { LeafletMapComponent } from "../shared/usercontrols/leafletMap.component";

import { getIconPath } from "app/common/globals";

import "leaflet-polylinedecorator";

import "../../../../vendor/leaflet-movingmarker/leaflet-movingmarker.js";
import "../../../../vendor/leaflet-numbermarker/leaflet-numbermarker.js";
import "../../../../vendor/leaflet-routing/leaflet-routing-machine.min.js";
import "../../../../vendor/leaflet-geocoder/Control.Geocoder.js";
declare var L;

// Moment
import Moment from "moment-timezone";
import { StorageHelper } from "app/common/storagehelper";
window["moment"] = Moment;

@Component({
  selector: "fh-device-routes",
  templateUrl: "routes.template.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger("enterAnimation", [
      transition(":enter", [
        // css styles at start of transition
        style({ opacity: 0 }),

        // animation and styles at end of transition
        animate("0.5s", style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class DeviceRoutesViewComponent implements OnInit {
  @ViewChild(LeafletMapComponent, { static: false }) leafletMapComponent: LeafletMapComponent;

  constructorName = "DeviceRouteViewComponent";
  permissions: {};
  device: any;
  loading: boolean;
  deviceId: any;
  sub: any;
  movingMarkerIcon: any;

  error: any;
  success: any;
  warning: any;

  loadingGeofences;
  geofences = [];
  mapHeight = 600;

  method = "mapbox/driving";

  permissionName = "FleetManagement_Trips";
  routing: any;

  constructor(
    private cd: ChangeDetectorRef,
    private http: HttpClient,
    private deviceService: DeviceService,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private storageHelper: StorageHelper
  ) {
    this.permissions = this.authenticationService.permissions;

    this.device = null;
  }

  ngOnInit() {
    this.device = new Device();
    this.device.id = "";
    this.loading = true;
    this.cd.markForCheck();

    this.sub = this.route.params.subscribe(
      (params) => {
        const id = params["id"];

        this.deviceId = id;
        this.deviceService.getDeviceById(id).subscribe((device) => {
          this.device = device;

          const iconPath = getIconPath(this.device.asset?.icon)[1];

          this.movingMarkerIcon = L.icon({
            iconUrl: iconPath,
            // className: 'markerPlayTrip',
            iconAnchor: [16, 16],
          });

          if (this.device == null) {
            this.router.navigate(["/Devices/Overview"]);
          }

          this.loading = false;
          this.cd.markForCheck();
        });
      },
      (error) => {
        this.error = error;
        this.error.statusText = "Error fetching device";

        setTimeout(() => {
          this.router.navigate(["/Devices/Overview"]);
        }, 3000);
      }
    );
  }

  onMapReady(map) {
    setTimeout(() => {
      this.leafletMapComponent.invalidateSize();
    }, 10);

    setTimeout(() => {
      this.createRouting(this.method);

      // var routingControlContainer = routing.getContainer();
      // var controlContainerParent = routingControlContainer.parentNode;
      // controlContainerParent.removeChild(routingControlContainer);
      // var itineraryDiv = document.getElementById('path-results');
      // itineraryDiv.appendChild(routingControlContainer);
    }, 100);
  }

  createRouting(method) {
    const options = { profile: method }; // driving, walking, cycling
    const mapboxRouting = L.Routing.mapbox(
      "pk.eyJ1Ijoid2Rla29ydGUiLCJhIjoiY2szenY0NHpmMHprMzNtcWM3cnlmaDhsZCJ9.UvME9fYRq6oqgnEIyCzYNw",
      options
    );

    if (this.routing) {
      this.leafletMapComponent.map.removeControl(this.routing);
    }

    const startIcon = L["StatusMarker"].icon({
      iconSize: [20, 20],
      iconAnchor: [10, 10],
      shadowSize: [0, 0],
      shadowAnchor: [0, 0],
      icon: "fa-car",
      prefix: "fa",
      iconColor: "black",
      className: "m360_12",
    });

    const myViaIcon = L["StatusMarker"].icon({
      iconSize: [20, 20],
      iconAnchor: [10, 10],
      shadowSize: [0, 0],
      shadowAnchor: [0, 0],
      icon: "fa-rss",
      prefix: "fa",
      iconColor: "green",
      className: "m360_12",
    });

    const finishIcon = L.icon({
      iconUrl: "assets/images/icons/end.png",
      className: "markerEnd",
    });

    this.routing = L.Routing.control({
      addWaypoints: true,
      waypointMode: "snap",
      routeWhileDragging: false,
      show: true,
      fitSelectedRoutes: true,
      plan: false,
      draggableWaypoints: true,
      collapsible: true,
      lineOptions: {
        styles: [
          { color: "black", opacity: 0.15, weight: 8 },
          { color: "white", opacity: 0.8, weight: 6 },
          { color: "red", opacity: 0.5, weight: 2 },
        ],
      },
      waypoints: [
        L.Routing.waypoint(L.latLng(51.8558, 4.4761), "Rhoon"),
        L.Routing.waypoint(L.latLng(51.788, 4.7187), "Dordrecht"),
      ],
      geocoder: L.Control.Geocoder.nominatim(),
      language: "en",
      reverseWaypoints: true,
      showAlternatives: true,
      altLineOptions: {
        styles: [
          { color: "black", opacity: 0.15, weight: 8 },
          { color: "white", opacity: 0.8, weight: 6 },
          { color: "blue", opacity: 0.5, weight: 2 },
        ],
      },
      router: mapboxRouting,
      // createMarker: function (i: number, waypoint: any, n: number) {
      //     const marker = L.marker(waypoint.latLng, {
      //         draggable: true,
      //         bounceOnAdd: false,
      //         icon: L.icon({
      //             iconUrl: './assets/global/img/mapmarker-red.png',
      //             iconSize: [38, 95],
      //             iconAnchor: [22, 94],
      //             popupAnchor: [-3, -76],
      //             shadowUrl: './assets/global/img/marker-shadow.png',
      //             shadowSize: [68, 95],
      //             shadowAnchor: [22, 94]
      //         })
      //     });
      //     return marker;
      // }
    });

    this.routing.route({ geometryOnly: true, simplifyGeometry: false });

    this.routing.addTo(this.leafletMapComponent.map);
  }

  onMapResized() {
    setTimeout(() => {
      this.leafletMapComponent.centerMap();
    }, 200);
  }
}
