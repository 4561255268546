import { Component, Input, OnChanges } from "@angular/core";
import { Device } from "app/models/device.model";
import { DeviceService } from "app/services/device/device.service";

@Component({
  selector: "fh-device-details-nearby",
  templateUrl: "devicesNearby.template.html",
})
export class DevicesNearbyComponent implements OnChanges {
  loading: boolean;
  nearbyDevices: Device[];
  @Input() device: Device;

  constructor(private deviceService: DeviceService) {
    this.loading = false;
    this.nearbyDevices = [];
  }

  ngOnChanges() {
    console.log("Set loading");
    this.loading = true;
    setTimeout(() => {
      this.getDevicesNearby();
    }, 1400);
  }

  getSortedKeys(obj) {
    const keys = [];
    for (const key in obj) {
      keys.push(key);
    }
    return keys.sort((a, b) => {
      return obj[a] - obj[b];
    });
  }

  contains(a, obj) {
    for (let i = 0, len = a.length; i < len; i++) {
      if (a[i] == obj) {
        return true;
      }
    }
    return false;
  }

  getDevicesNearby() {
    function toRad(Value) {
      /** Converts numeric degrees to radians */
      return (Value * Math.PI) / 180;
    }

    this.deviceService.getDevices().subscribe((devices) => {
      if (this.device && this.device.lastPosition) {
        const lat1 = this.device.lastPosition.latitude;
        const lon1 = this.device.lastPosition.longitude;
        const distance = [];

        $.each(devices, (idx, device) => {
          if (device.lastPosition && device.lastPosition.latitude) {
            const lat2 = device.lastPosition.latitude;
            const lon2 = device.lastPosition.longitude;

            const R = 3958.7558657440545; // Radius of earth in Miles
            const dLat = toRad(lat2 - lat1);
            const dLon = toRad(lon2 - lon1);
            const a =
              Math.sin(dLat / 2) * Math.sin(dLat / 2) +
              Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
            const d = R * c;
            distance[device.id] = d;
            device.distance = Math.round(d * 100) / 100;
          }
        });

        const sortedDistance = this.getSortedKeys(distance);

        const searchAmount = 7;
        const selectedAssetIds = sortedDistance.slice(1, searchAmount);

        this.nearbyDevices = devices.filter((asset) => this.contains(selectedAssetIds, asset.id));
        console.log("Set stop loading");
        this.loading = false;
      } else {
        this.loading = false;
      }
    });
  }
}
