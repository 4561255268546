import {
  Component,
  AfterViewInit,
  Input,
  ElementRef,
  ViewChild,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from "@angular/core";

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { DriverGroupsService } from "app/services/driver/driverGroups.service";

const noop = () => {};

@Component({
  selector: "fh-drivergroupparent-selector",
  templateUrl: "./driverGroupParentSelector.template.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: DriverGroupParentSelectorComponent }],
})
export class DriverGroupParentSelectorComponent implements OnChanges, OnInit, AfterViewInit, ControlValueAccessor {
  @Input() placeholder: string;
  @Input() clearable = true;
  @Input() readonly = false;

  @Input() driverGroupId;

  @Input() selectedGroupType;

  @Output() onChanged = new EventEmitter();

  driverGroupData = [];

  driverGroups = [];
  loadingDriverGroups = true;

  @ViewChild("input") el: ElementRef;

  private _value: string;
  private _onChange: (_: any) => void = noop;

  get value(): any {
    return this._value;
  }

  set value(v: any) {
    if (v !== this._value) {
      this._value = v;
      this._onChange(v);
      this.cd.markForCheck();
    }
  }

  constructor(private driverGroupService: DriverGroupsService, private cd: ChangeDetectorRef) {}

  driverGroupChanged() {
    this.onChanged.emit(this.value);
    this.loadingDriverGroups = false;
    this.cd.markForCheck();
  }

  ngOnChanges(changes) {
    if (changes["selectedGroupType"] !== null && this.driverGroupData.length > 0) {
      this.loadingDriverGroups = true;
      this._value = undefined;

      setTimeout(() => {
        this.filterDriverGroups();
      }, 0);
    }
  }

  filterDriverGroups() {
    let groupTypeLevel;

    switch (this.selectedGroupType) {
      case 19:
        groupTypeLevel = 18;
        break;
      case 18:
        groupTypeLevel = 17;
        break;
      default:
        break;
    }

    if (!groupTypeLevel) {
      this.driverGroups = [];
    } else {
      this.driverGroups = this.driverGroupData.filter((x) => +x.groupType === groupTypeLevel);
    }

    this.loadingDriverGroups = false;
    this.cd.markForCheck();
  }

  loadDriverGroups() {
    this.loadingDriverGroups = true;
    this.cd.markForCheck();

    this.driverGroupService.getDriverGroups().subscribe((result) => {
      this.driverGroupData = result.filter((x) => !(+x.groupType === 1 || x.id === this.driverGroupId));
      this.filterDriverGroups();
    });
  }

  ngOnInit() {
    this.loadDriverGroups();
  }

  ngAfterViewInit() {
    const __this = this;
  }

  writeValue(value: any) {
    this._value = value;
    this.cd.markForCheck();
  }

  registerOnChange(fn: (value: any) => void) {
    this._onChange = fn;
    this.cd.markForCheck();
  }

  registerOnTouched(fn: any) {
    this.cd.markForCheck();
  }
}
