import { Component, OnInit, ChangeDetectorRef, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { EntityType, FormMode, StorageType } from "app/common/enums";
import { Fuel } from "app/models/fuel.model";
import { AuthenticationService } from "app/services/authentication/authentication.service";
import { WaslService } from "app/services/wasl/wasl.service";
// Moment timezone
import Moment from "moment-timezone";
import { FuelService } from "app/services/fuel/fuel.service";
import { AppUser } from "app/models/user.model";
import { FhChartService } from "app/services/charts/charts.service";
import { LeafletMapComponent } from "../shared/usercontrols/leafletMap.component";
import { DeviceService } from "app/services/device/device.service";
import { AccountService } from "app/services/account/account.service";
import { getIconPath } from "app/common/globals";
import * as L from "leaflet";
import { StorageHelper } from "app/common/storagehelper";

window["moment"] = Moment;

import * as Highcharts from "highcharts";
import { TripService } from "app/services/trip/trip.service";
@Component({
  providers: [FhChartService],
  selector: "fh-fh-fuel-details",
  templateUrl: "fuelDetails.template.html",
})
export class FuelDetailsViewComponent implements OnInit {
  Highcharts: typeof Highcharts = Highcharts;

  @ViewChild(LeafletMapComponent, { static: false }) leafletMapComponent: LeafletMapComponent;

  sub: any;
  fuelEvent = new Fuel();
  device: any;
  tagType = EntityType.Fuel.valueOf();
  formMode = FormMode.read;
  formModeWasl = FormMode.read;

  loading = false;
  saving = false;
  loadingFuelWasl = false;
  loadingFuel = false;
  allowDelete = false;
  filter;

  error: any;
  success: any;

  permissions: {};
  waslSuccess: { statusText: string; success: any };
  waslError: any;

  permissionName = "FuelEvents_View";

  activeDevices = 0;
  activeDrivers = 0;
  activeGeofences = 0;

  mapHeight = 250;

  geofences = [];
  loadingGeofences = false;
  loadingUsers = false;
  loadingKpi = false;
  users: AppUser[];

  loadingDeviceCount = false;
  chartDeviceCount: any;

  showFullScreen = false;

  // Pagination
  totalItems = 0;
  currentPage = 1;
  smallnumPages = 0;
  deviceCountData = [];
  distanceChartData = [];
  loadingCount = false;
  loadingChildren = false;
  children = [];
  marker: any;
  fuelEvents = [];
  tripLocations = [];
  trips: {};

  timezoneIana: string;
  chartFuel: any;
  locationData: any[] = [];

  storageType = StorageType.LocalStorage;
  showMapOnSide = false;

  constructor(
    private router: Router,
    private accountService: AccountService,
    private tripService: TripService,
    private authenticationService: AuthenticationService,
    private waslService: WaslService,
    private deviceService: DeviceService,
    private chartService: FhChartService,
    private cd: ChangeDetectorRef,
    private authentication: AuthenticationService,
    private fuelService: FuelService,
    private route: ActivatedRoute,
    private storageHelper: StorageHelper
  ) {
    this.permissions = this.authentication.permissions;
    this.timezoneIana = this.authenticationService.getTimeZoneIana();

    this.storageHelper.loadStoreState(this.storageType, "settings_", "showMapOnSide").subscribe((result) => {
      this.showMapOnSide = JSON.parse(result) === true;

      if (this.showMapOnSide) {
        this.mapHeight = 330;
      }
      this.cd.markForCheck();
    });
  }

  ngOnInit() {
    this.loading = true;
    this.loadingChildren = true;

    this.sub = this.route.params.subscribe((params) => {
      const id = params["id"];

      this.fuelService.getFuelEventById(id).subscribe({
        next: (fuel) => {
          this.fuelEvent = fuel;
          this.loading = false;

          this.deviceService.getDeviceById(this.fuelEvent.deviceId).subscribe((device) => {
            this.device = device;

            this.showOnMap(fuel);

            this.fetchLocations();
          });
        },
        error: (error) => {
          this.error = error;
          this.error.statusText = "Error fetching fuel";

          this.cd.markForCheck();

          setTimeout(() => {
            this.router.navigate(["/Fuel/Overview"]);
          }, 3000);
        },
      });
    });
  }

  onMapReady(map) {
    setTimeout(() => {
      this.leafletMapComponent.invalidateSize();
    }, 10);
  }

  // Form
  onDelete() {
    this.loading = true;

    this.fuelService.deleteFuelEvent(this.fuelEvent.id).subscribe({
      next: (result) => {
        this.error = null;
        this.success = {
          statusText: "Success",
          success: "Fuel is successfully deleted.",
        };

        setTimeout(() => {
          this.router.navigate(["/Fuel/Overview"]);
        }, 3000);
      },
      error: (error) => {
        this.success = null;
        this.error = error;
        this.loading = false;
        this.cd.markForCheck();
      },
    });
  }

  onSave() {
    this.saving = true;

    this.fuelService.updateFuelEvent(this.fuelEvent).subscribe({
      next: (result) => {
        this.saving = false;

        this.error = null;
        this.success = {
          statusText: "Success",
          success: "Fuel is successfully updated.",
        };

        this.getFuelInfo();
      },
      error: (error) => {
        this.saving = false;
        this.success = null;
        this.error = error;
        this.cd.markForCheck();
      },
    });
  }

  setFormMode(mode) {
    this.formMode = mode;

    if (this.formMode === FormMode.read) {
      this.loading = true;
      this.getFuelInfo();
    }
  }

  getFuelInfo() {
    if (this.loading !== true && this.loadingFuelWasl !== true) {
      this.loading = true;
    }

    this.loadingGeofences = true;

    this.fuelService.getFuelEventById(this.fuelEvent.id).subscribe((fuelEvent) => {
      this.fuelEvent = fuelEvent;
      this.loading = false;
      this.saving = false;
      this.loadingFuelWasl = false;

      this.showOnMap(fuelEvent);

      this.fetchLocations();
    });
  }

  showOnMap(fuel) {
    if (this.marker) {
      this.leafletMapComponent.markerLayer.removeLayer(this.marker);
    }

    this.locationData = [{ icon: this.device.asset?.icon, latitude: fuel.latitude, longitude: fuel.longitude }];

    this.loadingGeofences = true;
    this.accountService.getGeofencesByAccount(this.fuelEvent.accountId).subscribe((geofences) => {
      this.loadingGeofences = false;
      this.geofences = geofences;
      this.cd.markForCheck();
    });
  }

  // Chart
  fetchLocations() {
    this.loadingFuel = true;

    const hoursBeforeAndAfter = 6;

    this.tripService
      .getMessagesForDevice(
        this.fuelEvent.deviceId,
        Moment.utc(this.fuelEvent.eventTimeStamp).add(-1 * hoursBeforeAndAfter, "hours"),
        Moment.utc(this.fuelEvent.eventTimeStamp).add(hoursBeforeAndAfter, "hours"),
        "",
        2
      )
      .subscribe({
        next: (locations) => {
          const locations2 = locations
            .filter((x) => x.latitude !== "0" && x.latitude !== 0)
            .sort((a, b) => (a.timestamp < b.timestamp ? -1 : 1));

          this.parseLocations(locations2);
          this.loadingFuel = false;
        },
        error: (error) => {
          this.error = error;
          this.loadingFuel = false;
        },
      });
  }

  parseLocations(locations) {
    this.fuelEvents = locations;
    this.loadingFuel = false;

    this.tripLocations = locations.filter((x) => x.latitude !== 0);

    // Fix timezone
    this.tripLocations.forEach((location) => {
      location.timestamp = Moment.utc(location.timestamp)["tz"](this.timezoneIana);
    });

    this.drawChart(this.trips);
  }

  drawChart(trips) {
    const iconPath = getIconPath(this.device.asset?.icon)[1];

    const theIcon = L.icon({
      iconUrl: iconPath,
      // className: 'markerPlayTrip',
      iconAnchor: [16, 16],
    });

    // The speed gauge
    // data, km, fuel percentage, deviation, symbol

    const theChartDataDistance = [];
    const theChartDataLevel = [];
    const theChartFueling = [];
    const theChartFuelingEvents = [];
    const theChartDataIgnition = [];
    const theChartDataSpeed = [];
    const theChartDataRpm = [];

    const cachedDistanceOffset = this.tripLocations
      .filter((x) => x.odoValue > 0)
      .sort((a, b) => (a.timestamp < b.timestamp ? -1 : 1))[0]?.odoValue;

    $.each(this.tripLocations, (index, value) => {
      const dateTime = Moment.utc(value.timestamp)["tz"](this.timezoneIana).unix() * 1000;
      const coordinate = [value.latitude, value.longitude];

      if (value.latitude !== "0" && value.latitude !== 0 && value.odoValue > 0) {
        const distance = (value.odoValue - cachedDistanceOffset) / 1000;
        theChartDataDistance.push({ x: dateTime, y: distance, latlon: coordinate });
      }

      if (value.fuelLevel && value.fuelLevel !== 0) {
        theChartDataLevel.push({ x: dateTime, y: value.fuelLevel, latlon: coordinate });
      }

      theChartDataSpeed.push({ x: dateTime, y: value.speedInKph > 0 ? 1 : 0, latlon: coordinate });
      theChartDataIgnition.push({ x: dateTime, y: value.ignition ? 1 : 0, latlon: coordinate });

      theChartDataRpm.push({ x: dateTime, y: value.rpm, latlon: coordinate });
    });

    theChartFuelingEvents.push({
      x: this.fuelEvent.eventTimeStamp,
      y: this.fuelEvent.endFuelEventFuelLevelInLiters,
      text: "x",
      title: this.fuelEvent.refuelVsTheft == 1 ? "Theft" : "Refuel",
      latlon: [this.fuelEvent.latitude, this.fuelEvent.longitude],
    });

    theChartFueling.push({
      x: this.fuelEvent.eventTimeStamp,
      y: this.fuelEvent.endFuelEventFuelLevelInLiters,
      latlon: [this.fuelEvent.latitude, this.fuelEvent.longitude],
    });

    let theData = [];

    theData = [
      {
        name: "Distance",
        type: "area",
        //  threshold: null,
        // step: 'right',
        dashStyle: "dash",
        fillOpacity: 0.1,
        opacity: 0.3,
        color: "#ccc",
        zIndex: 5,
        yAxis: 0,
        data: theChartDataDistance,
      },
      {
        name: "Events",
        type: "flags",
        color: "#5AB867",
        marker: {
          enabled: true,
          lineWidth: 4,
          symbol: "square",
        },
        zIndex: 5,
        yAxis: 1,
        data: theChartFuelingEvents,
      },
      {
        name: "Speed",
        type: "spline",
        color: "#39DD93",
        visible: false,
        yAxis: 3,
        step: "left",
        zIndex: 3,
        marker: {
          enabled: false,
          lineWidth: 2,
          radius: 5,
          symbol: "square",
        },
        data: theChartDataSpeed,
      },
      {
        name: "Ignition",
        type: "line",
        color: "#00E0C6",
        visible: false,
        yAxis: 4,
        step: "left",
        zIndex: 4,
        marker: {
          enabled: false,
          lineWidth: 2,
          radius: 5,
          symbol: "square",
        },
        data: theChartDataIgnition,
      },
      {
        name: "Fuel level",
        type: "spline",
        color: "#7589FF",
        dashStyle: "ShortDot",
        fillOpacity: 0.2,
        visible: true,
        step: "left",
        marker: {
          enabled: true,
          lineWidth: 0.5,
        },
        yAxis: 1,
        zIndex: 3,
        data: theChartDataLevel,
      },
      {
        name: "RPM",
        type: "spline",
        dashStyle: "ShortDashDot",
        color: "#ff00f0",
        visible: false,
        step: "left",
        marker: {
          enabled: false,
          lineWidth: 2,
          symbol: "square",
        },
        yAxis: 6,
        zIndex: 3,
        data: theChartDataRpm,
      },
    ];

    // Plotlines
    const filteredEvents = theChartFueling;

    const plotLines = [];
    const plotBands = [];

    plotLines.push({
      color: "#b81313",
      dashStyle: "dash",
      width: 2,
      value: Moment.utc(this.fuelEvent.eventTimeStamp).unix() * 1000,
    });

    filteredEvents.forEach((event) => {
      if (event[0] !== Moment.utc(this.fuelEvent.eventTimeStamp).unix() * 1000) {
        plotLines.push({
          color: "#ddd",
          dashStyle: "dash",
          width: 1,
          opacity: 0.5,
          value: event[0],
        });
      }
    });

    const map = this.leafletMapComponent.map; // this.leafletMapComponent.map

    this.chartFuel = this.chartService.generateFuelChart(theData, plotLines, plotBands, map, theIcon);
  }

  flipFullscreen() {
    this.showFullScreen = !this.showFullScreen;
    this.cd.markForCheck();

    setTimeout(() => {
      const map = this.leafletMapComponent.invalidateSize();

      if (this.chartFuel && this.chartFuel.ref) {
        console.log("reflow");
        this.chartFuel.ref.update(
          {
            /* options object */
          },
          true
        );
        this.chartFuel.ref.reflow();
      }
    }, 100);
  }

  fuelValidation(status: boolean | null) {
    this.fuelEvent.isValid = status;

    this.saving = true;

    this.fuelService.updateFuelEvent(this.fuelEvent).subscribe({
      next: (result) => {
        this.saving = false;
        this.error = null;
        this.success = {
          statusText: "Success",
          success: "Fuel is successfully updated.",
        };

        this.getFuelInfo();
      },
      error: (error) => {
        this.success = null;
        this.error = error;
        this.saving = false;
        this.getFuelInfo();
      },
    });
  }

  updateSuccess(result: string) {
    this.success = result;
  }

  updateError(result) {
    this.error = result;
    this.getFuelInfo();
  }
}
