import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormMode } from "app/common/enums";
import { AuthenticationService } from "app/services/authentication/authentication.service";
import { AppUser } from "../../models/user.model";
import { UserService } from "../../services/users/user.service";
import { AccountService } from "app/services/account/account.service";
import { sha256 } from "js-sha256";
import { TranslateService } from "@ngx-translate/core";
import { AssetGroupsService } from "app/services/asset/assetGroups.service";
import { generatePassword } from "app/common/globals";

@Component({
  selector: "fh-user-add",
  templateUrl: "userAdd.template.html",
})
export class UserAddViewComponent implements OnInit {
  accountPlatforms: any;
  languages: { name: string; path: any }[] = [];
  loading: boolean;
  user: AppUser = new AppUser();
  password2;
  formMode = FormMode.add;
  permissions = {};
  error;
  success;
  accountId: string;
  accounts: any[];

  selectedAssetGroups = [];

  permissionName = "Users_Add";

  constructor(
    private translateServe: TranslateService,
    private authenticationService: AuthenticationService,
    private accountService: AccountService,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private assetGroupService: AssetGroupsService
  ) {
    this.permissions = this.authenticationService.permissions;

    // Set new lang to current
    this.user.language = this.authenticationService.getServerCulture();
    this.user.userType = +this.authenticationService.getUserType();

    this.user.password = generatePassword(8);
  }

  ngOnInit() {}

  accountChanged() {
    this.selectedAssetGroups = [];
  }

  resellerChanged() {
    this.selectedAssetGroups = [];
  }

  onInsert() {
    this.loading = true;

    const userObject = { ...this.user };
    userObject.timezoneIana = this.authenticationService.getTimeZoneIana();
    userObject.password = sha256(this.user.password);
    userObject["selectedAssetGroups"] = this.selectedAssetGroups;

    this.userService.saveUser(userObject).subscribe({
      next: (result) => {
        this.loading = false;

        this.success = {
          statusText: "Success",
          success: this.translateServe.instant("general.saveSuccessRedirect", { entity: "user" }),
        };

        setTimeout(() => {
          this.router.navigate(["/UserDetails/Index/" + result.id]);
        }, 2000);

        this.loading = false;
      },
      error: (error) => {
        this.error = error;
        this.loading = false;
      },
    });
  }
}
