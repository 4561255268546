import { Component, OnInit, ChangeDetectorRef, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormMode } from "app/common/enums";
import { Trailer } from "app/models/trailer.model";
import { EntityType } from "app/common/enums";
import { AuthenticationService } from "app/services/authentication/authentication.service";

// Moment timezone

import { TrailerService } from "../../services/trailer/trailer.service";
import { LocationService } from "app/services/locations/locations.service";
import { ConfirmationModalComponent } from "../shared/usercontrols/confirmationModal.component";

@Component({
  selector: "fh-fh-trailer-details",
  templateUrl: "trailerDetails.template.html",
})
export class TrailerDetailsViewComponent implements OnInit {
  @ViewChild("confirmModal", { static: false }) confirmModal: ConfirmationModalComponent;
  sub: any;
  trailer: Trailer = new Trailer();
  device: any;
  tagType = EntityType.Trailer.valueOf();
  formMode = FormMode.read;

  loading = false;
  saving = false;
  error: any;
  success: any;
  warning: any;

  selectedAction;

  permissions: {};

  loadingLocation = false;
  loadingAssignments = false;
  allowDelete = true;
  geofences = [];

  trailerAssignments = [];

  permissionName = "Trailers_View";
  trailerId: any;

  constructor(
    private router: Router,
    private cd: ChangeDetectorRef,
    private locationService: LocationService,
    private authentication: AuthenticationService,
    private trailerService: TrailerService,
    private route: ActivatedRoute
  ) {
    this.permissions = this.authentication.permissions;
  }

  ngOnInit() {
    this.loading = true;
    this.loadingAssignments = true;

    this.sub = this.route.params.subscribe((params) => {
      const id = params["id"];

      this.trailerId = id;
      this.trailerService.getTrailerById(id).subscribe({
        next: (trailer) => {
          this.trailer = trailer;
          this.loading = false;
        },
        error: (error) => {
          this.error = error;
          this.error.statusText = "Error fetching trailer";

          this.cd.markForCheck();

          setTimeout(() => {
            this.router.navigate(["/Trailers/Overview"]);
          }, 3000);
        },
      });

      this.trailerService.getTrailerAssignmentsById(id).subscribe((res) => {
        this.trailerAssignments = res.sort((a, b) => (a.dateStart.unix() > b.dateStart.unix() ? -1 : 1));
        this.loadingAssignments = false;
      });
    });
  }

  showModal(selectedAction) {
    this.selectedAction = selectedAction;
    this.confirmModal.showModal("");
  }

  hideModal() {
    this.confirmModal.hideModal();
  }

  finishTask(task) {
    if (task?.completed) {
      this.error = null;

      if (task.result?.isSuccess) {
        this.warning = null;
        this.success = {
          statusText: "Success",
          success: task.result?.message,
        };
      } else {
        this.success = null;
        this.warning = {
          statusText: "Warning",
          warning: task.result?.message,
        };
      }

      this.getTrailerInfo();
      this.cd.markForCheck();
    } else {
      this.error = task?.error;
    }
  }

  // Form
  onDelete() {
    this.loading = true;

    this.trailerService.deleteTrailer(this.trailer).subscribe({
      next: (result) => {
        this.error = null;
        this.success = {
          statusText: "Success",
          success: "Trailer is successfully deleted.",
        };

        setTimeout(() => {
          this.router.navigate(["/Trailers/Overview"]);
        }, 3000);
      },
      error: (error) => {
        this.success = null;
        this.error = error;
        this.loading = false;
        this.cd.markForCheck();
      },
    });
  }

  onSave() {
    this.saving = true;

    this.trailerService.updateTrailer(this.trailer).subscribe({
      next: (result) => {
        this.error = null;
        this.success = {
          statusText: "Success",
          success: "Trailer is successfully updated.",
        };

        this.saving = false;
        this.cd.markForCheck();

        this.setFormMode(FormMode.read);
      },
      error: (error) => {
        this.saving = false;
        this.success = null;
        this.error = error;
        this.cd.markForCheck();
      },
    });
  }

  setFormMode(mode) {
    this.formMode = mode;

    if (this.formMode === FormMode.read) {
      this.loading = true;
      this.getTrailerInfo();
    }
  }

  getTrailerInfo() {
    if (this.loading !== true) {
      this.loading = true;
    }

    this.trailerService.getTrailerById(this.trailer.id).subscribe((trailer) => {
      this.trailer = trailer;
      this.loading = false;
    });
  }
}
