import { KeyValue } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { DistanceUnitService } from "app/common/distanceunit.service";
import { DeviceAuxiliary, DeviceOutput, EntityType, StorageType } from "app/common/enums";
import { colorArray, colorArray2, getIconPath, roundAsNumber, roundSeconds } from "app/common/globals";
import { StorageHelper } from "app/common/storagehelper";
import { DeviceSettingChange } from "app/models/device.model";
import { Trip } from "app/models/trip.model";
import { AuthenticationService } from "app/services/authentication/authentication.service";
import { DeviceSettingChangeService } from "app/services/device/deviceSettingChange.service";
import { DriverService } from "app/services/driver/driver.service";
import { FleetOverviewStoreService } from "app/services/fleetoverview/fleetoverview-store.service";
import { TripService } from "app/services/trip/trip.service";
import { colorMapper, getEngineColor, getFuelColor } from "app/common/leafletGlobals";
import * as Highcharts from "highcharts";
// Moment timezone
import Moment from "moment-timezone";
import { SensorTemplateService } from "app/services/sensorTemplate/sensorTemplate.service";
import { FhChartService } from "app/services/charts/charts.service";
window["moment"] = Moment;

@Component({
  selector: "fh-cockpit-sidebar",
  templateUrl: "cockpitSidebar.component.html",
  providers: [FhChartService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CockpitSidebarComponent implements OnInit, OnChanges {
  Highcharts: typeof Highcharts = Highcharts;

  @Input() selectedDevice;
  @Input() selectedDeviceExtended;
  @Input() selectedDeviceUtilization;
  @Input() loadingSidebar;

  tab: string = "Tasks";

  deviceState;
  showUnMapped = false;
  colorMapper = colorMapper;
  getFuelColor = getFuelColor;
  getEngineColor = getEngineColor;
  showUtilization;
  constructorName = "FleetOverviewSidebarComponent";
  permissions: {};

  tagType = EntityType.Device.valueOf();
  compareDate = new Date(new Date().setDate(new Date().getDate() - 3));
  hasDriverTagsEnabled = true;
  immobilizationStatus = 1;
  thePort;
  immobilizationType;
  theSettingId;
  activeDsc: any;
  loading = false;
  showPosition = true;
  translatedKmh: any = "km/h";
  translatedKm: any = "km";

  driversMap;
  assetWithTrips: { id: any; name: any; iconId: any; loading: boolean; trips: Trip[] };
  daterangepickerModel = [];
  timezoneIana: string;
  selectedAsset: KeyValue<any, any>;
  loadingTrips: boolean;
  cachedTrips = new Map<number, L.FeatureGroup<any>>();
  colorArray = colorArray2;

  driver;
  sensorTemplates: any[];
  loadingSensorTemplates: boolean;
  hasCanbus: boolean;

  chartOptions: any;

  constructor(
    private authenticationService: AuthenticationService,
    private distance: DistanceUnitService,
    private cd: ChangeDetectorRef,
    private translateService: TranslateService,
    private storageHelper: StorageHelper,
    private chartService: FhChartService,
    private fleetOverviewStoreService: FleetOverviewStoreService
  ) {
    this.permissions = this.authenticationService.permissions;
    this.driversMap = fleetOverviewStoreService.driversMap;

    this.timezoneIana = this.authenticationService.getTimeZoneIana();

    this.fleetOverviewStoreService.deviceState$.subscribe((states) => {
      this.parseChanges();
    });

    this.storageHelper.loadStoreState(StorageType.LocalStorage, "settings_", "brightColors").subscribe((result) => {
      if (JSON.parse(result) === true) {
        this.colorArray = colorArray;
      }
    });

    const theData = {
      type: "xrange",
      name: "Activity",
      data: [
        {
          x: Date.UTC(2024, 11, 5, 8, 0),
          x2: Date.UTC(2024, 11, 5, 10, 0),
          y: 0,
          color: "#76c7c0",
          name: "Driving",
        },
        {
          x: Date.UTC(2024, 11, 5, 10, 30),
          x2: Date.UTC(2024, 11, 5, 10, 45),
          y: 0,
          color: "#76c7c0",
          name: "Driving",
        },
        {
          x: Date.UTC(2024, 11, 5, 10, 45),
          x2: Date.UTC(2024, 11, 5, 10, 55),
          y: 0,
          color: "#dba100",
          name: "Idling",
        },
        {
          x: Date.UTC(2024, 11, 5, 10, 55),
          x2: Date.UTC(2024, 11, 5, 14, 30),
          y: 0,
          color: "#76c7c0",
          name: "Driving",
          //partialFill: 0.5,
        },
      ],
      tooltip: {
      },
      dataLabels: {
        enabled: false,
        formatter: function () {
          return this.point.name;
        },
        format: "{point.name}",
      },
    };

    this.chartOptions = this.chartService.generateXtrangeChart(theData);
  }

  ngOnChanges() {
    this.parseChanges();
  }

  parseChanges() {
    this.deviceState = this.selectedDevice?.theMarker?.data?.deviceState;

    if (this.selectedDeviceExtended != null) {
      if (this.selectedDevice?.settings?.canBusParameters?.length > 0) {
        this.hasCanbus = true;
      }

      if (this.selectedDevice) {
        this.selectedDevice.geofences = [];
        var geofences = this.deviceState?.insideGeofences;
        if (geofences) {
          Object.keys(geofences).forEach((geofenceId) => {
            const geofenceName = this.fleetOverviewStoreService.geofencesMap.get(+geofenceId);
            if (geofenceName) {
              this.selectedDevice?.geofences.push({
                id: +geofenceId,
                name: geofenceName,
                entered: geofences[geofenceId],
              });
            }
          });
        }
      }

      // Checking for scanned Driver
      let driverBinding = this.fleetOverviewStoreService.driverBindings.get(this.deviceState?.id);
      if (driverBinding) {
        let driver = driverBinding();
        this.driver = driver;
      }
    }
  }

  ngOnInit(): void {
    this.translateService.get("general.date").subscribe((data) => {
      this.translatedKm = this.translateService.instant(this.distance.getDistanceUnit());
      this.translatedKmh = this.translateService.instant(this.distance.getDistanceUnitPerHour());
      this.cd.markForCheck();
    });
  }

  changeTab(clickedTab: string) {
    this.tab = clickedTab;
  }

  getIcon(iconId) {
    return getIconPath(iconId)[2];
  }

  actualRound(value, decimals) {
    return roundAsNumber(value, decimals);
  }

  actualRoundSeconds(value) {
    return roundSeconds(value, true);
  }
}
