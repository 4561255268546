<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/"> <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li
                    class="breadcrumb-item"
                    *ngIf="permissions['Accounts_View'] && device?.asset && device?.asset.accountId"
                >
                    <a href="/#/AccountDetails/Index/{{device?.asset.accountId}}">{{device?.asset?.companyName}}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Devices/Overview?companyName={{device?.companyName}}"
                        >{{'general.devices' | translate }}</a
                    >
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/DeviceDetails/Index/{{device?.id}}"
                        >{{device?.asset?.name ? device?.asset?.name : device?.name}}</a
                    >
                </li>
                <li class="breadcrumb-item active">{{'general.rawData' | translate }}</li>
            </ol>
        </nav>

        <span style="margin-left: auto" class="fa-filter-container noselect">
            <a
                style="margin-left: 2px"
                class="btn btn-info margin-fix d-none d-md-inline-flex"
                title="Help"
                href="https://help.360locate.com/knowledge-base/fleet-management/raw-data/"
                target="_blank"
                ><i class="fa fa-fa fa-book" style="margin-right: 6px"></i>
                <span class="d-none d-md-inline-flex">{{'general.needHelp' | translate }}</span></a
            >
        </span>
    </div>
    <fh-device-details-navigation [deviceId]="deviceId" [device]="device"></fh-device-details-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">
        <div class="row" data-equalizer="">
            <div class="col-lg-12">
                <fh-error [error]="error" [warning]="warning" [success]="success"></fh-error>
            </div>
        </div>

        <div class="row" data-equalizer="">
            <div class="col-lg-9 col-md-8">
                <div class="ibox">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fa fa-database"></i> {{'general.rawData' | translate }}</h5>
                    </div>
                    <div class="ibox-content" style="position: relative">
                        <ng-select
                            [loading]="loading"
                            placeholder="{{ 'general.chooseFile' | translate}}"
                            name="files"
                            [(ngModel)]="selectedFile"
                            [disabled]="files.length == 0"
                            [clearable]="false"
                            class="form-control"
                            (change)="fileSelected($event)"
                            style="width: 100%"
                        >
                            <ng-option *ngFor="let file of files" [value]="file"> {{ file }} </ng-option>
                        </ng-select>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-4">
                <div class="ibox">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-bolt"></i> {{'general.actions' | translate }}</h5>
                    </div>
                    <div class="ibox-footer noselect">
                        <!-- <div class="col-lg-6">
                            <button class="btn btn-primary " style="width:100%; margin-bottom:6px"
                                [disabled]="fileDetails.length == 0" (click)="exportRaw()"><i
                                    class="fas fa-fw fa-file"></i>
                                {{'general.exportRaw' | translate }}</button>
                        </div>
                        <div class="col-lg-6">
                           
                        </div>
                        <div style="clear:both"></div> -->
                        <button
                            class="btn btn-primary"
                            style="width: 100%; margin-bottom: 6px"
                            [disabled]="fileDetails.length == 0"
                            (click)="export()"
                        >
                            <i class="fas fa-fw fa-file"></i> {{'general.export' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" data-equalizer="">
            <div class="col-lg-9 col-md-8">
                <div class="ibox">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fa fa-database"></i> {{'general.rawData' | translate }}</h5>

                        <span class="float-right">
                            <div class="filter-form d-none d-md-block">
                                <input
                                    type="text"
                                    class="form-control table-inline top-filter d-none d-md-block"
                                    (ngModelChange)="p.setCurrent(0)"
                                    [(ngModel)]="filter"
                                />
                            </div>
                        </span>
                    </div>
                    <div class="ibox-content slick" style="position: relative; overflow-y: auto">
                        <ngx-loading [show]="loadingDetails"></ngx-loading>
                        <table style="width: 100%" class="table nowrap dtr-inline no-footer">
                            <thead>
                                <tr>
                                    <th>{{ 'general.timestamp' | translate}}</th>
                                    <th>{{ 'general.isIncoming' | translate}}</th>
                                    <th>{{ 'general.logRecordType' | translate}}</th>
                                    <!-- <th>
                                        {{ 'general.asciiData' | translate}}
                                    </th> -->
                                    <th>{{ 'general.hexData' | translate}}</th>
                                    <th>{{ 'general.deviceAddress' | translate}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="let row of fileDetails | orderBy : 'timestamp':true | stringFilter: filter | paginate: { itemsPerPage: 10, currentPage: currentPage }"
                                >
                                    <td>{{ row.timestamp | amDateFormat: 'YYYY-MM-DD HH:mm:ss' }}</td>
                                    <td>{{ row.isIncoming }}</td>
                                    <td>{{ row.logRecordType }}</td>
                                    <!-- <td class="row_content" style="max-width: 300px;"> {{ row.asciiData }}</td> -->
                                    <td class="row_content" style="max-width: 300px">{{ row.hexData }}</td>
                                    <td>{{ row.deviceAddress }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="ibox-footer noselect dataTables_wrapper">
                        <pagination-template #p="paginationApi" (pageChange)="currentPage = $event">
                            <div class="dataTables_paginate paging_simple_numbers" id="dataTable_paginate">
                                <ul class="pagination">
                                    <li class="paginate_button page-item previous" [class.disabled]="p.isFirstPage()">
                                        <a class="page-link" (click)="p.previous()" *ngIf="!p.isFirstPage()"
                                            >{{ 'grid.previous' | translate }}</a
                                        >
                                        <a *ngIf="p.isFirstPage()">{{ 'grid.previous' | translate }}</a>
                                    </li>

                                    <li
                                        class="paginate_button page-item"
                                        *ngFor="let page of p.pages"
                                        [class.current]="p.getCurrent() === page.value"
                                    >
                                        <a
                                            class="page-link"
                                            (click)="p.setCurrent(page.value)"
                                            *ngIf="p.getCurrent() !== page.value"
                                        >
                                            <span>{{ page.label }}</span>
                                        </a>
                                        <a
                                            class="page-link"
                                            (click)="p.setCurrent(page.value)"
                                            *ngIf="p.getCurrent() === page.value"
                                        >
                                            <span style="font-weight: bold">{{ page.label }}</span>
                                        </a>
                                    </li>

                                    <li class="paginate_button page-item next" [class.disabled]="p.isLastPage()">
                                        <a class="page-link" (click)="p.next()" *ngIf="!p.isLastPage()">
                                            {{ 'grid.next' | translate }}
                                        </a>
                                        <a class="page-link" *ngIf="p.isLastPage()"> {{ 'grid.next' | translate }} </a>
                                    </li>
                                </ul>
                            </div>
                        </pagination-template>
                        <div style="clear: both"></div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-4">
                <div class="ibox">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-random"></i> {{'general.mapping' | translate }}</h5>
                    </div>
                    <div class="ibox-content slick" style="position: relative; overflow-y: auto">
                        <ngx-loading [show]="loadingRoutings"></ngx-loading>
                        <table
                            *ngIf="mapping && mapping.length == 1"
                            style="width: 100%"
                            class="table nowrap dtr-inline no-footer"
                        >
                            <thead>
                                <tr>
                                    <th>{{'general.destinationCluster' | translate }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let routing of mapping[0].routings">
                                    <td>{{ ('enums.clusters.' + routing.destinationCluster) | translate }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="ibox-footer noselect">
                        <button
                            *ngIf="!loadingRoutings"
                            class="btn btn-primary"
                            (click)="showMapping()"
                            style="width: 100%"
                        >
                            <i class="fas fa-fw fa-random"></i> {{'general.editMapping' | translate }}
                        </button>
                    </div>
                </div>

                <div class="ibox" *ngIf="permissions['Platform_IsReseller']">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-person-running"></i> {{'general.rerunner' | translate }}</h5>
                    </div>
                    <div class="ibox-content slick" style="position: relative; overflow-y: auto">
                        <table class="table nowrap dtr-inline no-footer" *ngIf="device">
                            <tbody>
                                <tr>
                                    <td>{{ 'general.from' | translate}}</td>
                                    <td class="small_padding">
                                        <input
                                            class="form-control table-inline"
                                            [(ngModel)]="rerunDate"
                                            name="dateEnd"
                                            #dateEnd="ngModel"
                                            required
                                            [minDate]="minDate"
                                            [maxDate]="maxDate"
                                            autocomplete="off"
                                            [bsConfig]="dpConfig"
                                            bsDatepicker
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                        <div class="fa_checkbox noselect" style="white-space: break-spaces">
                                            <input
                                                [checked]="false"
                                                id="rerunCheck"
                                                type="checkbox"
                                                name="rerunCheck"
                                                [(ngModel)]="rerunChecked"
                                            />
                                            <label for="rerunCheck"> {{'general.rerunWarning' | translate }}</label>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="ibox-footer noselect">
                        <button
                            class="btn btn-primary"
                            (click)="rerun(true)"
                            [disabled]="!rerunDate || !rerunChecked"
                            style="width: 48%"
                        >
                            <i class="fas fa-fw fa-person-running"></i> {{'general.rerun' | translate }} (beta)
                        </button>
                        <button
                            class="btn btn-primary"
                            (click)="rerun(false)"
                            [disabled]="!rerunDate || !rerunChecked"
                            style="width: 48%"
                        >
                            <i class="fas fa-fw fa-person-running"></i> {{'general.rerun' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</fh-permission-handler>

<div
    [config]="{ show: false, backdrop: true, ignoreBackdropClick: true }"
    (onHidden)="onHidden()"
    bsModal
    #autoShownModal="bs-modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
>
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title pull-left"><i class="fas fa-random"></i> {{'general.mapping' | translate }}</h5>
                <button type="button" class="close float-right" aria-label="Close" (click)="hideModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <table style="width: 100%; margin-bottom: 0" class="table nowrap dtr-inline no-footer">
                    <thead>
                        <tr>
                            <th style="width: 50px"></th>
                            <th>{{'general.cluster' | translate }}</th>
                            <th>{{'general.destinationCluster' | translate }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let cluster of clusters">
                            <td style="width: 50px">
                                <div class="fa_checkbox">
                                    <input
                                        [checked]="false"
                                        type="checkbox"
                                        [(ngModel)]="cluster.isChecked"
                                        [id]="'id_' + cluster.value"
                                        type="checkbox"
                                    />
                                    <label [for]="'id_' + cluster.value"></label>
                                </div>
                            </td>
                            <td>{{ cluster.value }}</td>
                            <td>{{ ('enums.clusters.' + cluster.value) | translate }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="modal-footer">
                <button
                    type="button"
                    style="min-width: 120px"
                    class="btn btn-primary"
                    (click)="updateMapping()"
                    style="margin-bottom: 6px"
                >
                    <i class="fas fa-fw fa-save"></i> {{'general.save' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
