import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { AuthenticationService } from "app/services/authentication/authentication.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { HandleTaskProcessingComponent } from "./handleTaskProcessing.component";

@Component({
  selector: "fh-overview-action",
  templateUrl: "overviewTaskProcessing.template.html",
  providers: [HandleTaskProcessingComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FleetOverviewActionProcessingComponent implements OnChanges {
  @ViewChild("handleAction", { static: false }) handleAction: HandleTaskProcessingComponent;

  @Input() entity;
  @Input() entityTypes;

  permissions: {};

  showActions = false;
  selectedAction;
  batchActions = [];

  modalRef?: BsModalRef;

  constructor(
    private bsModalService: BsModalService,
    private authenticationService: AuthenticationService,
    private translate: TranslateService,
    private cd: ChangeDetectorRef,
    private toastr: ToastrService,
    private action: HandleTaskProcessingComponent
  ) {
    this.permissions = this.authenticationService.permissions;
  }

  ngOnChanges(): void {
    if (!this.entityTypes && !this.entity) {
      return;
    }

    if (this.entity?.id) {
      //Setting a temporary reference to the user control because viewchild isn't loaded yet
      if (!this.handleAction) {
        this.handleAction = this.action;
      }

      this.batchActions = this.handleAction.getBatchActionList(this.entityTypes, this.entity);
      this.cd.markForCheck();
    }
  }

  finish() {
    this.handleAction.process({});
  }

  process(event) {
    if (event != undefined) {
      this.handleAction.processAction(event).subscribe(
        (result) => {
          if (result?.message?.startsWith("general")) {
            result.message = this.translate.instant(result.message);
          }

          if (result?.isSuccess) {
            this.toastr.success(result?.message, this.translate.instant("general.success"), {
              progressBar: true,
            });
          } else {
            this.toastr.warning(result?.message, this.translate.instant("general.warning"), {
              progressBar: true,
            });
          }

          this.cd.markForCheck();
        },
        (error) => {
          this.toastr.error(
            this.translate.instant("general.somethingWentWrong"),
            this.translate.instant("general.error"),
            {
              progressBar: true,
            }
          );

          this.cd.markForCheck();
        }
      );
    }

    this.modalRef.hide();
  }

  public showModal(selectedAction, template: TemplateRef<void>) {
    this.selectedAction = selectedAction;
    this.modalRef = this.bsModalService.show(template);
  }
}
