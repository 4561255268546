<form #groupForm="ngForm" style="width: 100%; border-top: 0px">
    <table class="table nowrap dtr-inline no-footer">
        <thead>
            <tr>
                <th class="small_padding">{{ 'general.imei' | translate}}</th>
                <th class="small_padding">{{ 'general.assetName' | translate}}</th>
                <th class="small_padding">{{ 'general.assignmentStart' | translate}} *</th>
                <th class="small_padding">{{ 'general.assignmentEnd' | translate}} *</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class="small_padding">
                    <input readonly class="form-control table-inline" [value]="device?.name" />
                </td>
                <td class="small_padding">
                    <input readonly class="form-control table-inline" [value]="asset?.name" />
                </td>
                <td class="small_padding">
                    <input
                        [ngStyle]="{'border': dateStart.errors ? '1px solid red' : ''}"
                        class="form-control table-inline"
                        [(ngModel)]="startDate"
                        name="dateStart"
                        #dateStart="ngModel"
                        required
                        autocomplete="off"
                        [bsConfig]="dpConfig"
                        bsDatepicker
                    />
                </td>
                <td class="small_padding">
                    <input
                        [ngStyle]="{'border': dateEnd.errors ? '1px solid red' : ''}"
                        class="form-control table-inline"
                        [(ngModel)]="archiveDate"
                        [disabled]="!asset?.assignmentEnd"
                        name="dateEnd"
                        #dateEnd="ngModel"
                        required
                        autocomplete="off"
                        [bsConfig]="dpConfig"
                        bsDatepicker
                    />
                </td>
            </tr>
        </tbody>
    </table>
</form>
