import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { FormMode } from "app/common/enums";
import { Reseller } from "app/models/reseller.model";
import { AuthenticationService } from "../../services/authentication/authentication.service";
import { ResellerService } from "app/services/resellers/resellers.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "fh-reseller-add",
  templateUrl: "add.template.html",
})
export class ResellersAddViewComponent {
  loading: boolean = false;
  reseller: Reseller = new Reseller();

  formMode: FormMode = FormMode.add;
  error: any;
  permissions: {};
  success: { statusText: string; success: string };

  permissionName: string = "Resellers_Add";

  constructor(
    private translate: TranslateService,
    private resellerService: ResellerService,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {
    this.permissions = this.authenticationService.permissions;
  }

  onInsert() {
    this.loading = true;

    this.resellerService.saveReseller(this.reseller).subscribe({
      next: (result) => {
        this.success = {
          statusText: "Success",
          success: this.translate.instant("general.saveSuccessRedirect", { entity: "reseller" }),
        };

        setTimeout(() => {
          this.router.navigate(["/ResellerDetails/Index/" + result.id]);
        }, 2000);

        this.loading = false;
      },
      error: (error) => {
        this.error = error;
        this.loading = false;
      },
    });
  }
}
