<div class="slideMenuDetails white-bg fontColor" style="position: relative">
    <ngx-loading [show]="loadingSidebar"></ngx-loading>
    <img style="position: absolute; right: 20px; top: 0px" [src]="getIcon(selectedDevice?.asset?.icon)" />

    <table style="width: 100%" class="table table-fixed nowrap dtr-inline no-footer">
        <tbody>
            <tr>
                <td class="row_opening">
                    {{ "general.name" | translate }}
                </td>
                <td class="row_content" colspan="2">
                    <a class="secondary link_bolder" href="/#/DeviceDetails/Index/{{ selectedDevice?.id }}">
                        {{ selectedDevice?.asset?.name }}</a
                    >
                </td>
            </tr>
            <tr *ngIf="permissions['Entities_DeviceTypes']">
                <td class="row_opening">
                    {{ "general.modelId" | translate }}
                </td>
                <td class="row_content" colspan="2">
                    <a
                        class="secondary link_bolder"
                        href="/#/Devices/DeviceTypeDetails/Index/{{ selectedDeviceExtended?.deviceTypeId }}"
                    >
                        {{ selectedDeviceExtended?.deviceTypeNameFull }}
                    </a>
                </td>
            </tr>
            <tr *ngIf="permissions['Accounts_View']">
                <td class="row_opening">
                    {{ "general.customer" | translate }}
                </td>
                <td class="row_content" colspan="2">
                    <a class="secondary link_bolder" href="/#/AccountDetails/Index/{{ selectedDevice?.accountId }}">{{
                        selectedDevice?.companyName
                    }}</a>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ "general.lastCommunication" | translate }}
                </td>
                <td class="row_content" title="{{ deviceState?.communicationState?.updateTimestamp }}" colspan="2">
                    {{ deviceState?.communicationState?.updateTimestamp | amTimeAgo }}
                </td>
            </tr>
            <tr *ngIf="deviceState?.currentAddress">
                <td class="row_opening">
                    {{ "general.near" | translate }}
                </td>
                <td
                    class="row_content"
                    colspan="2"
                    title="{{ deviceState?.currentAddress?.address }}, {{ deviceState?.currentAddress?.city }}, {{
                        deviceState?.currentAddress?.country
                    }}"
                >
                    {{ deviceState?.currentAddress?.city }}
                    {{ deviceState?.currentAddress?.address ? ", " + deviceState.currentAddress?.address : "" }}
                    {{ deviceState?.currentAddress?.country ? ", " + deviceState?.currentAddress?.country : "" }}
                </td>
            </tr>
            <tr *ngIf="selectedDevice?.asset?.currentAddress">
                <td class="row_opening">
                    {{ "general.near" | translate }}
                </td>
                <td
                    class="row_content"
                    colspan="2"
                    title="{{ selectedDevice?.asset?.currentAddress?.address }}, {{
                        selectedDevice?.asset?.currentAddress?.city
                    }}, {{ selectedDevice?.asset?.currentAddress?.country }}"
                >
                    {{ selectedDevice?.asset?.currentAddress?.city }}
                    {{
                        selectedDevice?.asset?.currentAddress?.address
                            ? ", " + selectedDevice?.asset?.currentAddress?.address
                            : ""
                    }}
                    {{
                        selectedDevice?.asset?.currentAddress?.country
                            ? ", " + selectedDevice?.asset?.currentAddress?.country
                            : ""
                    }}
                </td>
            </tr>
        </tbody>
    </table>

    <nav>
        <span
            class="fleet-overview-sidebar-action"
            style="float: right; display: block; position: relative; right: 0; top: 0"
            *ngIf="permissions['FleetManagement_Actions']"
        >
            <fh-overview-action [entity]="selectedDeviceExtended" [entityTypes]="tagType"></fh-overview-action>
        </span>
        <li class="fleet-overview-sidebar-tabs" style="margin-top: 15px; position: relative">
            <button
                class="fleet-overview-sidebar-tab"
                (click)="changeTab('DeviceState')"
                title="Asset details"
                [class.active]="tab === 'DeviceState'"
            >
                <i class="fa fa-fw fa-satellite"></i>
                <span *ngIf="tab === 'DeviceState'">{{ "general.state" | translate }}</span>
            </button>
            <button
                class="fleet-overview-sidebar-tab"
                (click)="changeTab('Details')"
                title="Asset details"
                [class.active]="tab === 'Details'"
            >
                <i class="fa fa-fw fa-microchip"></i>
                <span *ngIf="tab === 'Details'">{{ "general.details" | translate }}</span>
            </button>
            <button
                class="fleet-overview-sidebar-tab"
                (click)="changeTab('Utilization')"
                title="Utilization"
                [class.active]="tab === 'Utilization'"
            >
                <i class="fa fa-fw fa-engine"></i>
                <span *ngIf="tab === 'Utilization'">{{ "general.utilization" | translate }}</span>
            </button>
            <button
                class="fleet-overview-sidebar-tab"
                (click)="changeTab('Trips')"
                title="Trips"
                [class.active]="tab === 'Trips'"
            >
                <i class="fa fa-fw fa-route"></i>
                <span *ngIf="tab === 'Trips'">{{ "general.trips" | translate }}</span>
            </button>
        </li>
    </nav>

    <div class="fleet-overview-sidebar-details" *ngIf="tab === 'Details'">
        <table style="width: 100%; margin-bottom: 3px" class="table table-fixed nowrap dtr-inline no-footer">
            <tbody>
                <tr>
                    <td class="row_opening sidebar-header" colspan="3" style="border-top-left-radius: 0">
                        <i class="fa fa-fas fa-car"></i> {{ "general.asset" | translate }}
                    </td>
                </tr>
                <tr *ngIf="selectedDevice?.asset?.brand">
                    <td class="row_opening">
                        {{ "general.brand" | translate }}
                    </td>
                    <td class="row_content" colspan="2">
                        {{ selectedDevice?.asset?.brand }}
                    </td>
                </tr>
                <tr *ngIf="selectedDevice?.asset?.model">
                    <td class="row_opening">
                        {{ "general.model" | translate }}
                    </td>
                    <td class="row_content" colspan="2">
                        {{ selectedDevice?.asset?.model }}
                    </td>
                </tr>
                <tr *ngIf="selectedDevice?.asset?.plateNumber">
                    <td class="row_opening">
                        {{ "general.plate" | translate }}
                    </td>
                    <td class="row_content" colspan="2">
                        {{ selectedDevice?.asset?.plateNumber }}
                    </td>
                </tr>
                <tr *ngIf="selectedDevice?.asset?.year">
                    <td class="row_opening">
                        {{ "general.year" | translate }}
                    </td>
                    <td class="row_content" colspan="2">
                        {{ selectedDevice?.asset?.year }}
                    </td>
                </tr>
                <tr *ngIf="selectedDevice?.asset?.color">
                    <td class="row_opening">
                        {{ "general.color" | translate }}
                    </td>
                    <td class="row_content" colspan="2">
                        {{ selectedDevice?.asset?.color }}
                    </td>
                </tr>
                <tr *ngIf="permissions['Entities_DeviceTypes']">
                    <td class="row_opening">
                        {{ "general.unitId" | translate }}
                    </td>
                    <td class="row_content" colspan="2">
                        {{ selectedDevice?.unitId }}
                    </td>
                </tr>
                <tr *ngIf="selectedDeviceExtended?.asset?.vin">
                    <td class="row_opening">
                        {{ "general.vin" | translate }}
                    </td>
                    <td class="row_content" colspan="2">
                        {{ selectedDeviceExtended?.asset?.vin }}
                    </td>
                </tr>
                <tr *ngIf="selectedDeviceExtended?.asset?.comment">
                    <td class="row_opening">
                        {{ "general.comment" | translate }}
                    </td>
                    <td class="row_content" colspan="2">
                        {{ selectedDeviceExtended?.asset?.comment }}
                    </td>
                </tr>
                <tr *ngIf="selectedDeviceExtended?.asset?.properties?.custom.length > 0">
                    <td class="row_opening sidebar-header" colspan="3">
                        <i class="fa fa-fw fa-draw-polygon"></i> {{ "general.customFields" | translate }}
                    </td>
                </tr>
                <tr *ngFor="let custom of selectedDeviceExtended?.asset?.properties?.custom">
                    <td class="row_content">{{ custom.key }}</td>
                    <td class="row_content" colspan="2">{{ custom.value }}</td>
                </tr>
                <tr *ngIf="permissions['AssetGroups_View']">
                    <td class="row_opening sidebar-header" colspan="3">
                        <i class="fa fa-fw fa-layer-group"></i> {{ "general.assetGroups" | translate }}
                    </td>
                </tr>
                <tr *ngIf="permissions['AssetGroups_View']">
                    <td class="row_content" colspan="3" style="padding: 4px; white-space: normal">
                        <span *ngFor="let group of selectedDeviceExtended?.asset?.assetGroups">
                            <a
                                class="btn btn-info btn-grid"
                                href="/#/AssetGroupDetails/Index/{{ group?.id }}"
                                style="text-wrap: nowrap; margin: 2px"
                            >
                                {{ group?.name }}</a
                            >
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <!-- Utilization -->
    <div class="fleet-overview-sidebar-details flex-container" *ngIf="tab === 'Utilization'">
        <header class="sidebar-header">
            <i class="fa fa-fw fa-engine"></i> {{ "general.utilization" | translate }}
        </header>
        <div class="col-4" style="padding: 0">
            <div class="kpi-item-small">
                <h3 class="margin-bottom font-bold">
                    {{
                        selectedDeviceUtilization?.segmentsDistance
                            ? selectedDeviceUtilization?.segmentsDistance.toLocaleString()
                            : "-"
                    }}
                    {{ translatedKm }}
                </h3>
                <div>{{ "general.distance" | translate }}</div>
            </div>
        </div>
        <div class="col-4" style="padding: 0">
            <div class="kpi-item-small">
                <h3 class="margin-bottom font-bold">
                    {{
                        selectedDeviceUtilization?.tripDurationInSeconds
                            ? actualRoundSeconds(selectedDeviceUtilization?.tripDurationInSeconds)
                            : "-"
                    }}
                </h3>
                <div>{{ "general.duration" | translate }}</div>
            </div>
        </div>
        <div class="col-4" style="padding: 0">
            <div class="kpi-item-small">
                <h3 class="margin-bottom font-bold">
                    {{
                        selectedDeviceUtilization?.idlingDurationInSeconds
                            ? actualRoundSeconds(selectedDeviceUtilization?.idlingDurationInSeconds)
                            : "-"
                    }}
                </h3>
                <div>{{ "general.idling" | translate }}</div>
            </div>
        </div>
        <div class="col-4" style="padding: 0">
            <div class="kpi-item-small">
                <h3 class="margin-bottom font-bold">
                    <a
                        class="secondary link_bolder"
                        (click)="$event.stopPropagation()"
                        href="/#/DeviceDetails/Trips/{{ selectedDevice?.id }}"
                    >
                        {{ selectedDeviceUtilization?.tripCount ? selectedDeviceUtilization?.tripCount : "-" }}</a
                    >
                </h3>
                <div>{{ "general.trips" | translate }}</div>
            </div>
        </div>

        <div class="col-4" style="padding: 0">
            <div class="kpi-item-small">
                <h3 class="margin-bottom font-bold">
                    {{ selectedDeviceUtilization?.accelCount ? selectedDeviceUtilization?.accelCount : "-" }}
                </h3>
                <div>{{ "general.acceleration" | translate }}</div>
            </div>
        </div>
        <div class="col-4" style="padding: 0">
            <div class="kpi-item-small">
                <h3 class="margin-bottom font-bold">
                    {{ selectedDeviceUtilization?.decelCount ? selectedDeviceUtilization?.decelCount : "-" }}
                </h3>
                <div>{{ "general.braking" | translate }}</div>
            </div>
        </div>

        <div class="col-4" style="padding: 0">
            <div class="kpi-item-small">
                <h3 class="margin-bottom font-bold">
                    {{
                        selectedDeviceUtilization?.roadSpeedingCount
                            ? selectedDeviceUtilization?.roadSpeedingCount
                            : "-"
                    }}
                </h3>
                <div>{{ "general.speeding" | translate }}</div>
            </div>
        </div>
        <div class="col-4" style="padding: 0">
            <div class="kpi-item-small">
                <h3 class="margin-bottom font-bold">
                    {{ selectedDeviceUtilization?.maxSpeed ? selectedDeviceUtilization?.maxSpeed : "-" }}
                </h3>
                <div>{{ "general.maxSpeed" | translate }}</div>
            </div>
        </div>
    </div>

    <!-- Device State -->
    <div class="fleet-overview-sidebar-details" *ngIf="tab === 'DeviceState'">
        <table style="width: 100%; margin-bottom: 3px" class="table table-fixed nowrap dtr-inline no-footer">
            <tbody>
                <tr *ngIf="driver && permissions['Drivers_View']">
                    <td class="row_opening sidebar-header" colspan="3">
                        <i class="fa fa-fw fa-id-card-alt"></i> {{ "general.driver" | translate }}
                    </td>
                </tr>
                <tr *ngIf="driver && driver?.name && permissions['Drivers_View']">
                    <td class="row_opening">
                        {{ "general.name" | translate }}
                    </td>
                    <td class="row_content" colspan="2">
                        <a class="secondary link_bolder" href="/#/DriverDetails/Index/{{ driver?.id }}">
                            {{ driver?.name }}</a
                        >
                    </td>
                </tr>
                <tr *ngIf="driver && driver?.phone && permissions['Drivers_View']">
                    <td class="row_opening">
                        {{ "general.phone" | translate }}
                    </td>
                    <td class="row_content" colspan="2">
                        {{ driver?.phone }}
                    </td>
                </tr>
                <tr *ngIf="deviceState?.insideGeofences && selectedDevice?.geofences?.length > 0">
                    <td class="row_opening sidebar-header" colspan="3">
                        <i class="fa fa-fw fa-draw-polygon"></i> {{ "general.geofences" | translate }}
                    </td>
                </tr>
                <tr *ngIf="deviceState?.insideGeofences && selectedDevice?.geofences?.length > 0">
                    <td class="row_content" colspan="3" style="padding: 4px; white-space: normal">
                        <span *ngFor="let geofence of selectedDevice?.geofences">
                            <a
                                class="btn btn-info btn-grid"
                                title="{{ 'general.entered' | translate }} : {{ geofence.entered | amTimeAgo }}"
                                href="/#/GeofenceDetails/Index/{{ geofence?.id }}"
                                style="text-wrap: nowrap; margin: 2px"
                            >
                                {{ geofence?.name }}</a
                            >
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>

        <div class="flex-container">
            <header class="sidebar-header">
                <i class="fa fa-fas fa-satellite"></i> {{ "general.deviceState" | translate }}
            </header>
            <div class="col-4" style="padding: 0">
                <div
                    title="DeviceState: '{{
                        'general.deviceState_' + deviceState?.calculatedDeviceState?.deviceState | translate
                    }}' since {{ deviceState?.calculatedDeviceState?.stateChangedTimestamp }}"
                    class="kpi-item-small border-active"
                    [ngClass]="deviceState?.markerColor"
                >
                    <h3 class="margin-bottom font-bold">
                        <i
                            class="full-icon fas fa-fw fa-map-marker-alt eye-active"
                            [ngClass]="deviceState?.markerColor"
                        ></i>
                        {{ "general.deviceState_" + deviceState?.calculatedDeviceState?.deviceState | translate }}
                    </h3>
                    <div title="{{ deviceState?.calculatedDeviceState?.stateChangedTimestamp }}">
                        {{ "general.stateSince" | translate }}
                        {{ deviceState?.calculatedDeviceState?.stateChangedTimestamp | amTimeAgo : true }}
                    </div>
                </div>
            </div>
            <div class="col-4" style="padding: 0">
                <div
                    title="Speed: '{{ deviceState?.currentPosition?.speed }}' on {{
                        deviceState?.currentPosition?.updateTimestamp
                    }}"
                    class="kpi-item-small"
                >
                    <h3 class="margin-bottom font-bold">
                        {{ deviceState?.currentPosition?.speed ? deviceState?.currentPosition?.speed : "-" }}
                        {{ translatedKmh }}
                    </h3>
                    <div>{{ "general.speed" | translate }}</div>
                </div>
            </div>
            <div class="col-4" style="padding: 0">
                <div
                    title="Heading: '{{ deviceState?.currentPosition?.heading }}' on {{
                        deviceState?.currentPosition?.updateTimestamp
                    }}"
                    class="kpi-item-small"
                >
                    <h3 class="margin-bottom font-bold">
                        <i
                            *ngIf="deviceState"
                            [ngClass]="deviceState?.markerColor"
                            [ngStyle]="{
                                transform: 'rotate(' + deviceState?.currentPosition?.heading + 'deg)',
                                '-webkit-transform': 'rotate(' + deviceState?.currentPosition?.heading + 'deg)',
                                '-ms-transform': 'rotate(' + deviceState?.currentPosition?.heading + 'deg)'
                            }"
                            class="full-icon fas fa-arrow-up eye-active"
                        ></i>

                        {{ deviceState?.currentPosition?.heading ? deviceState?.currentPosition?.heading : "-" }} °
                    </h3>
                    <div>{{ "general.heading" | translate }}</div>
                </div>
            </div>
            <div class="col-4" style="padding: 0" *ngIf="deviceState?.externalBattery?.batteryLevelInVoltage">
                <div [title]="deviceState?.externalBattery?.updateTimestamp" class="kpi-item-small">
                    <h3 class="margin-bottom font-bold">
                        <i class="full-icon fas fa-fw fa-car-battery"></i>
                        {{
                            deviceState?.externalBattery?.batteryLevelInVoltage
                                ? actualRound(deviceState?.externalBattery?.batteryLevelInVoltage, 2).toLocaleString()
                                : "-"
                        }}
                        V
                    </h3>
                    <div>{{ "general.battery" | translate }}</div>
                </div>
            </div>
            <div class="col-4" style="padding: 0" *ngIf="deviceState?.engineCoolantTemperature?.temperatureInCelcius">
                <div [title]="deviceState?.engineCoolantTemperature?.updateTimestamp" class="kpi-item-small">
                    <h3 class="margin-bottom font-bold">
                        <i class="full-icon fas fa-fw fa-thermometer-three-quarters"></i>
                        {{
                            deviceState?.engineCoolantTemperature?.temperatureInCelcius
                                ? actualRound(deviceState?.engineCoolantTemperature?.temperatureInCelcius, 2)
                                : "-"
                        }}
                        °C
                    </h3>
                    <div>{{ "general.engineCoolantTemperature" | translate }}</div>
                </div>
            </div>
            <div class="col-4" style="padding: 0" *ngIf="deviceState?.temperature1?.temperatureInCelcius">
                <div [title]="deviceState?.temperature1?.updateTimestamp" class="kpi-item-small">
                    <h3 class="margin-bottom font-bold">
                        <i class="full-icon fas fa-fw fa-thermometer-three-quarters"></i>
                        {{
                            deviceState?.temperature1?.temperatureInCelcius
                                ? actualRound(deviceState?.temperature1?.temperatureInCelcius, 2)
                                : "-"
                        }}
                        °C
                    </h3>
                    <div>{{ "general.temperature" | translate }}</div>
                </div>
            </div>
            <div class="col-4" style="padding: 0" *ngIf="deviceState?.temperature2?.temperatureInCelcius">
                <div [title]="deviceState?.temperature2?.updateTimestamp" class="kpi-item-small">
                    <h3 class="margin-bottom font-bold">
                        <i class="full-icon fas fa-fw fa-thermometer-three-quarters"></i>
                        {{
                            deviceState?.temperature2?.temperatureInCelcius
                                ? actualRound(deviceState?.temperature2?.temperatureInCelcius, 2)
                                : "-"
                        }}
                        °C
                    </h3>
                    <div>{{ "general.temperature" | translate }}</div>
                </div>
            </div>
            <div class="col-4" style="padding: 0" *ngIf="deviceState?.rpmStatus?.rpm">
                <div [title]="deviceState?.rpmStatus?.updateTimestamp" class="kpi-item-small">
                    <h3 class="margin-bottom font-bold">
                        <i class="full-icon fas fa-fw fa-engine"></i>
                        {{ deviceState?.rpmStatus?.rpm ? actualRound(deviceState?.rpmStatus?.rpm, 0) : "-" }}
                    </h3>
                    <div>{{ "general.rpm" | translate }}</div>
                </div>
            </div>
            <div class="col-4" style="padding: 0" *ngIf="deviceState?.fuel?.fuelLevelInPercentage">
                <div [title]="deviceState?.fuel?.updateTimestamp" class="kpi-item-small">
                    <h3 class="margin-bottom font-bold">
                        <i class="full-icon fas fa-fw fa-gas-pump"></i>
                        {{
                            deviceState?.fuel?.fuelLevelInPercentage
                                ? actualRound(deviceState?.fuel?.fuelLevelInPercentage, 0)
                                : "-"
                        }}%
                    </h3>
                    <div>{{ "general.fuel" | translate }}</div>
                </div>
            </div>
            <div class="col-4" style="padding: 0" *ngIf="deviceState?.humidity1?.humidityInPercent">
                <div [title]="deviceState?.humidity1?.updateTimestamp" class="kpi-item-small">
                    <h3 class="margin-bottom font-bold">
                        <i class="full-icon fas fa-fw fa-humidity"></i>
                        {{
                            deviceState?.humidity1?.humidityInPercent
                                ? actualRound(deviceState?.humidity1?.humidityInPercent, 0)
                                : "-"
                        }}%
                    </h3>
                    <div>{{ "general.humidity" | translate }}</div>
                </div>
            </div>
            <div class="col-4" style="padding: 0" *ngIf="deviceState?.totalAxleWeight">
                <div [title]="deviceState?.totalAxleWeight?.updateTimestamp" class="kpi-item-small">
                    <h3 class="margin-bottom font-bold">
                        <i class="full-icon fas fa-fw fa-weight"></i>
                        {{
                            deviceState?.totalAxleWeight?.totalWeightInKg
                                ? actualRound(deviceState?.totalAxleWeight?.totalWeightInKg, 0).toLocaleString()
                                : "-"
                        }}
                        kg
                    </h3>
                    <div>{{ "general.weight" | translate }}</div>
                </div>
            </div>
            <div class="col-4" style="padding: 0">
                <div [title]="deviceState?.odometer?.updateTimestamp" class="kpi-item-small">
                    <h3 class="margin-bottom font-bold">
                        {{
                            deviceState?.odometer?.gpsOdometer !== null
                                ? actualRound(
                                      (selectedDeviceExtended?.asset?.calibrationOdoOffsetInKm ?? 0) +
                                          deviceState?.odometer?.gpsOdometer / 1000,
                                      0
                                  ).toLocaleString()
                                : "-"
                        }}
                        {{ translatedKm }}
                    </h3>
                    <div>{{ "general.odo" | translate }}</div>
                </div>
            </div>
            <div class="col-4" style="padding: 0">
                <div [title]="deviceState?.currentPosition?.updateTimestamp" class="kpi-item-small">
                    <h3 [ngSwitch]="true" class="margin-bottom font-bold">
                        <ng-container
                            *ngSwitchCase="
                                deviceState?.hasGpsFix == false &&
                                deviceState?.hasCellFix == true &&
                                deviceState?.cellPosition != null &&
                                !(deviceState?.cellPosition.latitude === 0 && deviceState?.cellPosition.longitude === 0)
                            "
                        >
                            <i
                                title="Cell Position"
                                class="full-icon fas fa-fw fa-location-crosshairs-slash"
                                [ngStyle]="{ color: 'rgba(151,28,36,0.9)' }"
                            ></i>
                            <a
                                target="_blank"
                                rel="nofollow"
                                href="https://www.google.com/maps/place/{{ deviceState?.cellPosition?.latitude }}+{{
                                    deviceState?.cellPosition?.longitude
                                }}/@{{ deviceState?.cellPosition?.latitude }},{{
                                    deviceState?.cellPosition?.longitude
                                }},15z"
                            >
                                {{
                                    deviceState?.cellPosition?.latitude.toLocaleString("en-US", {
                                        minimumFractionDigits: 2
                                    }) +
                                        "," +
                                        deviceState?.cellPosition?.longitude.toLocaleString("en-US", {
                                            minimumFractionDigits: 2
                                        })
                                }}
                            </a>
                        </ng-container>
                        <ng-container
                            *ngSwitchCase="deviceState?.hasGpsFix == true && deviceState?.currentPosition != null"
                        >
                            <i
                                title="GPS Position"
                                class="full-icon fas fa-fw fa-location-crosshairs"
                                [ngStyle]="{ color: 'rgba(0,142,67,0.9)' }"
                            ></i>
                            <a
                                target="_blank"
                                rel="nofollow"
                                href="https://www.google.com/maps/place/{{ deviceState?.currentPosition?.latitude }}+{{
                                    deviceState?.currentPosition?.longitude
                                }}/@{{ deviceState?.currentPosition?.latitude }},{{
                                    deviceState?.currentPosition?.longitude
                                }},15z"
                            >
                                {{
                                    deviceState?.currentPosition?.latitude.toLocaleString("en-US", {
                                        minimumFractionDigits: 2
                                    }) +
                                        ", " +
                                        deviceState?.currentPosition?.longitude.toLocaleString("en-US", {
                                            minimumFractionDigits: 2
                                        })
                                }}
                            </a>
                        </ng-container>
                        <ng-container
                            *ngSwitchCase="deviceState?.hasGpsFix == false && deviceState?.currentPosition != null"
                        >
                            <i
                                title="Outdated position"
                                class="full-icon fas fa-fw fa-location-crosshairs-slash"
                                [ngStyle]="{ color: 'rgba(151,28,36,0.9)' }"
                            ></i>
                            <a
                                target="_blank"
                                rel="nofollow"
                                href="https://www.google.com/maps/place/{{ deviceState?.currentPosition?.latitude }}+{{
                                    deviceState?.currentPosition?.longitude
                                }}/@{{ deviceState?.currentPosition?.latitude }},{{
                                    deviceState?.currentPosition?.longitude
                                }},15z"
                            >
                                {{
                                    deviceState?.currentPosition?.latitude.toLocaleString("en-US", {
                                        minimumFractionDigits: 2
                                    }) +
                                        "," +
                                        deviceState?.currentPosition?.longitude.toLocaleString("en-US", {
                                            minimumFractionDigits: 2
                                        })
                                }}
                            </a>
                        </ng-container>
                        <ng-container *ngSwitchDefault> &ndash; </ng-container>
                    </h3>
                    <div>
                        {{ "general.location" | translate }}
                        <i
                            *ngIf="
                                deviceState?.currentPosition != null &&
                                deviceState?.currentPosition?.updateTimestamp <
                                    deviceState.communicationState?.updateTimestamp
                            "
                            class="fa fa-fw history-icon hand fa-history"
                            title="{{ deviceState?.currentPosition?.updateTimestamp }}"
                        ></i>
                    </div>
                </div>
            </div>
            <div *ngIf="deviceState?.currentPosition?.satellites" class="col-6 col-lg-4" style="padding: 0">
                <div [title]="deviceState?.currentPosition?.updateTimestamp" class="kpi-item-small">
                    <h3 class="margin-bottom font-bold">
                        {{
                            deviceState?.currentPosition?.satellites
                                ? deviceState?.currentPosition?.satellites.toLocaleString()
                                : "-"
                        }}
                    </h3>
                    <div>{{ "general.satellites" | translate }}</div>
                </div>
            </div>
            <div *ngIf="deviceState?.currentPosition?.hdop" class="col-6 col-lg-4" style="padding: 0">
                <div [title]="deviceState?.currentPosition?.updateTimestamp" class="kpi-item-small">
                    <h3 class="margin-bottom font-bold">
                        {{
                            deviceState?.currentPosition?.hdop
                                ? deviceState?.currentPosition?.hdop.toLocaleString()
                                : "-"
                        }}
                    </h3>
                    <div>{{ "general.hdop" | translate }}</div>
                </div>
            </div>
            <div *ngIf="deviceState?.currentPosition?.pdop" class="col-6 col-lg-4" style="padding: 0">
                <div [title]="deviceState?.currentPosition?.updateTimestamp" class="kpi-item-small">
                    <h3 class="margin-bottom font-bold">
                        {{
                            deviceState?.currentPosition?.pdop
                                ? deviceState?.currentPosition?.pdop.toLocaleString()
                                : "-"
                        }}
                    </h3>
                    <div>{{ "general.pdop" | translate }}</div>
                </div>
            </div>
            <div class="col-4" style="padding: 0" *ngIf="deviceState?.currentPosition?.radiusInMeters">
                <div [title]="deviceState?.currentPosition?.updateTimestamp" class="kpi-item-small">
                    <h3 class="margin-bottom font-bold">
                        {{
                            deviceState?.currentPosition?.radiusInMeters
                                ? deviceState?.currentPosition?.radiusInMeters
                                : "-"
                        }}
                        m
                    </h3>
                    <div>{{ "general.radiusInMeters" | translate }}</div>
                </div>
            </div>
            <!-- IO States -->
            <header class="sidebar-header">
                <i class="fa fa-fas fa-random"></i> {{ "general.ioStates" | translate }}
            </header>
            <div
                class="col-4"
                style="padding: 0"
                *ngIf="
                    (selectedDeviceExtended?.settings?.inputPorts[0] &&
                        selectedDeviceExtended?.settings?.inputPorts[0].byte != null &&
                        selectedDeviceExtended?.settings?.inputPorts[0].byte != 0) ||
                    showUnMapped
                "
            >
                <div [title]="deviceState?.input1?.updateTimestamp" class="kpi-item-small">
                    <h3 class="margin-bottom font-bold">
                        <i
                            class="full-icon fas fa-fw fa-arrow-alt-circle-up"
                            [ngStyle]="{
                                color:
                                    deviceState?.input1 == null
                                        ? '#ccc'
                                        : deviceState?.input1?.state
                                        ? 'rgba(0,142,67,0.9)'
                                        : 'rgba(151,28,36,0.9)'
                            }"
                        ></i>
                        {{
                            selectedDeviceExtended?.settings?.inputPorts &&
                                "enums.locationEventType." + selectedDeviceExtended?.settings?.inputPorts[0]?.byte
                                | translate
                        }}
                    </h3>
                    <div>
                        {{ "general.inputFormatted" | translate : { value: "1" } }}
                    </div>
                </div>
            </div>
            <div
                class="col-4"
                style="padding: 0"
                *ngIf="
                    (selectedDeviceExtended?.settings?.inputPorts[1] &&
                        selectedDeviceExtended?.settings?.inputPorts[1].byte != null &&
                        selectedDeviceExtended?.settings?.inputPorts[1].byte != 0) ||
                    showUnMapped
                "
            >
                <div [title]="deviceState?.input2?.updateTimestamp" class="kpi-item-small">
                    <h3 class="margin-bottom font-bold">
                        <i
                            class="full-icon fas fa-fw fa-arrow-alt-circle-up"
                            [ngStyle]="{
                                color:
                                    deviceState?.input2 == null
                                        ? '#ccc'
                                        : deviceState?.input2?.state
                                        ? 'rgba(0,142,67,0.9)'
                                        : 'rgba(151,28,36,0.9)'
                            }"
                        ></i>
                        {{
                            selectedDeviceExtended?.settings?.inputPorts &&
                                "enums.locationEventType." + selectedDeviceExtended?.settings?.inputPorts[1]?.byte
                                | translate
                        }}
                    </h3>
                    <div>
                        {{ "general.inputFormatted" | translate : { value: "2" } }}
                    </div>
                </div>
            </div>
            <div
                class="col-4"
                style="padding: 0"
                *ngIf="
                    (selectedDeviceExtended?.settings?.inputPorts[2] &&
                        selectedDeviceExtended?.settings?.inputPorts[2].byte != null &&
                        selectedDeviceExtended?.settings?.inputPorts[2].byte != 0) ||
                    showUnMapped
                "
            >
                <div [title]="deviceState?.input3?.updateTimestamp" class="kpi-item-small">
                    <h3 class="margin-bottom font-bold">
                        <i
                            class="full-icon fas fa-fw fa-arrow-alt-circle-up"
                            [ngStyle]="{
                                color:
                                    deviceState?.input3 == null
                                        ? '#ccc'
                                        : deviceState?.input3?.state
                                        ? 'rgba(0,142,67,0.9)'
                                        : 'rgba(151,28,36,0.9)'
                            }"
                        ></i>
                        {{
                            selectedDeviceExtended?.settings?.inputPorts &&
                                "enums.locationEventType." + selectedDeviceExtended?.settings?.inputPorts[2]?.byte
                                | translate
                        }}
                    </h3>
                    <div>
                        {{ "general.inputFormatted" | translate : { value: "3" } }}
                    </div>
                </div>
            </div>
            <div
                class="col-4"
                style="padding: 0"
                *ngIf="
                    (selectedDeviceExtended?.settings?.inputPorts[3] &&
                        selectedDeviceExtended?.settings?.inputPorts[3].byte != null &&
                        selectedDeviceExtended?.settings?.inputPorts[3].byte != 0) ||
                    showUnMapped
                "
            >
                <div [title]="deviceState?.input4?.updateTimestamp" class="kpi-item-small">
                    <h3 class="margin-bottom font-bold">
                        <i
                            class="full-icon fas fa-fw fa-arrow-alt-circle-up"
                            [ngStyle]="{
                                color:
                                    deviceState?.input4 == null
                                        ? '#ccc'
                                        : deviceState?.input4?.state
                                        ? 'rgba(0,142,67,0.9)'
                                        : 'rgba(151,28,36,0.9)'
                            }"
                        ></i>
                        {{
                            selectedDeviceExtended?.settings?.inputPorts &&
                                "enums.locationEventType." + selectedDeviceExtended?.settings?.inputPorts[3]?.byte
                                | translate
                        }}
                    </h3>
                    <div>
                        {{ "general.inputFormatted" | translate : { value: "4" } }}
                    </div>
                </div>
            </div>
            <div
                class="col-4"
                style="padding: 0"
                *ngIf="
                    (selectedDeviceExtended?.settings?.inputPorts[4] &&
                        selectedDeviceExtended?.settings?.inputPorts[4].byte != null &&
                        selectedDeviceExtended?.settings?.inputPorts[4].byte != 0) ||
                    showUnMapped
                "
            >
                <div [title]="deviceState?.input5?.updateTimestamp" class="kpi-item-small">
                    <h3 class="margin-bottom font-bold">
                        <i
                            class="full-icon fas fa-fw fa-arrow-alt-circle-up"
                            [ngStyle]="{
                                color:
                                    deviceState?.input5 == null
                                        ? '#ccc'
                                        : deviceState?.input5?.state
                                        ? 'rgba(0,142,67,0.9)'
                                        : 'rgba(151,28,36,0.9)'
                            }"
                        ></i>
                        {{
                            selectedDeviceExtended?.settings?.inputPorts &&
                                "enums.locationEventType." + selectedDeviceExtended?.settings?.inputPorts[4]?.byte
                                | translate
                        }}
                    </h3>
                    <div>
                        {{ "general.inputFormatted" | translate : { value: "5" } }}
                    </div>
                </div>
            </div>
            <div
                class="col-4"
                style="padding: 0"
                *ngIf="
                    (selectedDeviceExtended?.settings?.inputPorts[5] &&
                        selectedDeviceExtended?.settings?.inputPorts[5].byte != null &&
                        selectedDeviceExtended?.settings?.inputPorts[5].byte != 0) ||
                    showUnMapped
                "
            >
                <div [title]="deviceState?.input6?.updateTimestamp" class="kpi-item-small">
                    <h3 class="margin-bottom font-bold">
                        <i
                            class="full-icon fas fa-fw fa-arrow-alt-circle-up"
                            [ngStyle]="{
                                color:
                                    deviceState?.input6 == null
                                        ? '#ccc'
                                        : deviceState?.input6?.state
                                        ? 'rgba(0,142,67,0.9)'
                                        : 'rgba(151,28,36,0.9)'
                            }"
                        ></i>
                        {{
                            selectedDeviceExtended?.settings?.inputPorts &&
                                "enums.locationEventType." + selectedDeviceExtended?.settings?.inputPorts[5]?.byte
                                | translate
                        }}
                    </h3>
                    <div>
                        {{ "general.inputFormatted" | translate : { value: "6" } }}
                    </div>
                </div>
            </div>
            <div
                class="col-4"
                style="padding: 0"
                *ngIf="
                    (selectedDeviceExtended?.settings?.outputPorts[0] &&
                        selectedDeviceExtended?.settings?.outputPorts[0].byte != null &&
                        selectedDeviceExtended?.settings?.outputPorts[0].byte != 0) ||
                    showUnMapped
                "
            >
                <div [title]="deviceState?.output1?.updateTimestamp" class="kpi-item-small">
                    <h3 class="margin-bottom font-bold">
                        <i
                            class="full-icon fas fa-fw fa-arrow-alt-circle-down"
                            [ngStyle]="{
                                color:
                                    deviceState?.output1 == null
                                        ? '#ccc'
                                        : deviceState?.output1?.state
                                        ? 'rgba(0,142,67,0.9)'
                                        : 'rgba(151,28,36,0.9)'
                            }"
                        ></i>
                        {{
                            selectedDeviceExtended?.settings?.outputPorts &&
                                "enums.deviceOutput." + selectedDeviceExtended?.settings?.outputPorts[0]?.byte
                                | translate
                        }}
                    </h3>
                    <div>
                        {{ "general.outputFormatted" | translate : { value: "1" } }}
                    </div>
                </div>
            </div>
            <div
                class="col-4"
                style="padding: 0"
                *ngIf="
                    (selectedDeviceExtended?.settings?.outputPorts[1] &&
                        selectedDeviceExtended?.settings?.outputPorts[1].byte != null &&
                        selectedDeviceExtended?.settings?.outputPorts[1].byte != 0) ||
                    showUnMapped
                "
            >
                <div [title]="deviceState?.output2?.updateTimestamp" class="kpi-item-small">
                    <h3 class="margin-bottom font-bold">
                        <i
                            class="full-icon fas fa-fw fa-arrow-alt-circle-down"
                            [ngStyle]="{
                                color:
                                    deviceState?.output2 == null
                                        ? '#ccc'
                                        : deviceState?.output2?.state
                                        ? 'rgba(0,142,67,0.9)'
                                        : 'rgba(151,28,36,0.9)'
                            }"
                        ></i>
                        {{
                            selectedDeviceExtended?.settings?.outputPorts &&
                                "enums.deviceOutput." + selectedDeviceExtended?.settings?.outputPorts[1]?.byte
                                | translate
                        }}
                    </h3>
                    <div>
                        {{ "general.outputFormatted" | translate : { value: "2" } }}
                    </div>
                </div>
            </div>
            <div
                class="col-4"
                style="padding: 0"
                *ngIf="
                    (selectedDeviceExtended?.settings?.outputPorts[2] &&
                        selectedDeviceExtended?.settings?.outputPorts[2].byte != null &&
                        selectedDeviceExtended?.settings?.outputPorts[2].byte != 0) ||
                    showUnMapped
                "
            >
                <div [title]="deviceState?.output3?.updateTimestamp" class="kpi-item-small">
                    <h3 class="margin-bottom font-bold">
                        <i
                            class="full-icon fas fa-fw fa-arrow-alt-circle-down"
                            [ngStyle]="{
                                color:
                                    deviceState?.output3 == null
                                        ? '#ccc'
                                        : deviceState?.output3?.state
                                        ? 'rgba(0,142,67,0.9)'
                                        : 'rgba(151,28,36,0.9)'
                            }"
                        ></i>
                        {{
                            selectedDeviceExtended?.settings?.outputPorts &&
                                "enums.deviceOutput." + selectedDeviceExtended?.settings?.outputPorts[2]?.byte
                                | translate
                        }}
                    </h3>
                    <div>
                        {{ "general.outputFormatted" | translate : { value: "3" } }}
                    </div>
                </div>
            </div>
            <div
                class="col-4"
                style="padding: 0"
                *ngIf="
                    (selectedDeviceExtended?.settings?.outputPorts[3] &&
                        selectedDeviceExtended?.settings?.outputPorts[3].byte != null &&
                        selectedDeviceExtended?.settings?.outputPorts[3].byte != 0) ||
                    showUnMapped
                "
            >
                <div [title]="deviceState?.output4?.updateTimestamp" class="kpi-item-small">
                    <h3 class="margin-bottom font-bold">
                        <i
                            class="full-icon fas fa-fw fa-arrow-alt-circle-down"
                            [ngStyle]="{
                                color:
                                    deviceState?.output4 == null
                                        ? '#ccc'
                                        : deviceState?.output4?.state
                                        ? 'rgba(0,142,67,0.9)'
                                        : 'rgba(151,28,36,0.9)'
                            }"
                        ></i>
                        {{
                            selectedDeviceExtended?.settings?.outputPorts &&
                                "enums.deviceOutput." + selectedDeviceExtended?.settings?.outputPorts[3]?.byte
                                | translate
                        }}
                    </h3>
                    <div>
                        {{ "general.outputFormatted" | translate : { value: "4" } }}
                    </div>
                </div>
            </div>
            <div
                class="col-4"
                style="padding: 0"
                *ngIf="
                    (selectedDeviceExtended?.settings?.analogPorts[0] &&
                        selectedDeviceExtended?.settings?.analogPorts[0].byte != null &&
                        selectedDeviceExtended?.settings?.analogPorts[0].byte != 0) ||
                    showUnMapped
                "
            >
                <div [title]="deviceState?.analog1?.updateTimestamp" class="kpi-item-small">
                    <h3 class="margin-bottom font-bold">
                        <i class="full-icon fas fa-fw fa-bolt"></i>
                        {{ deviceState?.analog1 ? deviceState?.analog1?.value?.toLocaleString() : "-" }} V
                    </h3>
                    <div>
                        {{
                            selectedDeviceExtended?.settings?.analogPorts &&
                                "enums.analogFunctions." + selectedDeviceExtended?.settings?.analogPorts[0]?.byte
                                | translate
                        }}
                        (Analog 1)
                    </div>
                </div>
            </div>
            <div
                class="col-4"
                style="padding: 0"
                *ngIf="
                    (selectedDeviceExtended?.settings?.analogPorts[1] &&
                        selectedDeviceExtended?.settings?.analogPorts[1].byte != null &&
                        selectedDeviceExtended?.settings?.analogPorts[1].byte != 0) ||
                    showUnMapped
                "
            >
                <div [title]="deviceState?.analog2?.updateTimestamp" class="kpi-item-small">
                    <h3 class="margin-bottom font-bold">
                        <i class="full-icon fas fa-fw fa-bolt"></i>
                        {{ deviceState?.analog2 ? deviceState?.analog2?.value?.toLocaleString() : "-" }} V
                    </h3>
                    <div>
                        {{
                            selectedDeviceExtended?.settings?.analogPorts &&
                                "enums.analogFunctions." + selectedDeviceExtended?.settings?.analogPorts[1]?.byte
                                | translate
                        }}
                        (Analog 2)
                    </div>
                </div>
            </div>
            <div *ngIf="immobilizationType && permissions['FleetManagement_ImmobilizeVehicle']" class="col-4">
                <ngx-loading [show]="loading"></ngx-loading>
                <div class="kpi-item-small">
                    <button
                        class="btn btn-primary"
                        style="width: 100%"
                        *ngIf="immobilizationStatus == 1"
                        (click)="immobilise()"
                    >
                        <i class="fas fa-fw fa-check"></i> {{ "general.immobilise" | translate }}
                    </button>

                    <button
                        class="btn btn-danger"
                        style="width: 100%"
                        *ngIf="immobilizationStatus > 2"
                        (click)="abort()"
                    >
                        <i class="fas fa-fw fa-times"></i> {{ "general.abort" | translate }}
                    </button>

                    <button
                        class="btn btn-primary"
                        style="width: 100%"
                        *ngIf="immobilizationStatus == 2"
                        (click)="unimmobilise()"
                    >
                        <i class="fas fa-fw fa-check"></i> {{ "general.unimmobilise" | translate }}
                    </button>
                </div>
            </div>
            <div style="clear: both"></div>
        </div>
    </div>
    <div class="fleet-overview-sidebar-details fleet-overview-header" *ngIf="tab === 'Trips'">
        <ngx-loading [show]="loadingTrips"></ngx-loading>
        <fh-overview-trip-history
            [asset]="selectedAsset"
            [openTrip]="openTrip.bind(this)"
            [openStop]="openStop.bind(this)"
            [changeTripVisibility]="changeTripVisibility.bind(this)"
            [displayLocation]="displayLocation.bind(this)"
            [playTrip]="playTrip.bind(this)"
            [driversMap]="driversMap"
        >
        </fh-overview-trip-history>
    </div>
</div>
