import { ChangeDetectorRef, Component, OnInit, ChangeDetectionStrategy } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

import { DeviceTypeService } from "app/services/devicetypes/devicetypes.service";

import { AuthenticationService } from "../../services/authentication/authentication.service";
import { DeviceService } from "../../services/device/device.service";
import { FhChartService } from "app/services/charts/charts.service";
import { getDefaultDpConfig } from "app/common/gridhelper";

import { BsDaterangepickerConfig } from "ngx-bootstrap/datepicker";

import { DeviceUtilization } from "app/models/device.model";
import { getIconPath, roundAsNumber } from "app/common/globals";

// Moment
import Moment from "moment-timezone";
import { AccountService } from "app/services/account/account.service";

import * as Highcharts from "highcharts";

window["moment"] = Moment;

@Component({
  providers: [FhChartService],
  selector: "fh-devices-utilization",
  templateUrl: "utilization.template.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DevicesUtilizationViewComponent implements OnInit {
  Highcharts: typeof Highcharts = Highcharts;

  token: string;
  countrySelect = false;

  excludingColumns = [
    "timeStamp",
    "assetDateStart",
    "assetDateEnd",
    "lastCommunication",
    "input1",
    "input2",
    "input3",
    "input4",
    "input5",
    "input6",
    "output1",
    "output2",
  ];
  loading = false;

  permissionName = "Devices_View";
  constructorName = "DevicesSensorsViewComponent";

  selectedResellerId;
  selectedAccountId;
  selectedAssetGroups;

  error: any;
  success: any;
  timezoneIana: string;
  languageLoaded: boolean;

  deviceSource: DeviceUtilization[] = [];
  devices: DeviceUtilization[] = [];

  currentPage;
  filter;
  itemsPerPage = 10;
  orderBy = "score";
  status;
  orderByReversed = false;
  chart;
  kpis;

  // Daterange
  public dpConfig: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();
  daterangepickerModel: Date[];

  constructor(
    private translateService: TranslateService,
    private accountService: AccountService,
    private chartService: FhChartService,
    private cd: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private deviceService: DeviceService,
    private deviceTypeService: DeviceTypeService
  ) {
    const that = this;

    this.token = this.authenticationService.getAuthToken();
    this.timezoneIana = this.authenticationService.getTimeZoneIana();

    this.daterangepickerModel = [
      Moment().tz(this.timezoneIana).subtract(14, "days").startOf("day").toDate(),
      Moment().tz(this.timezoneIana).subtract(0, "days").startOf("day").toDate(),
    ];

    this.dpConfig = getDefaultDpConfig(authenticationService);
  }

  actualRound(value, decimals) {
    return roundAsNumber(value, decimals);
  }

  resellerChanged(event) {
    this.deviceSource = [];
    this.devices = [];
    this.selectedAssetGroups = [];
    this.kpis = null;

    this.selectedAccountId = null;
  }

  accountChanged(event) {
    this.deviceSource = [];
    this.devices = [];
    this.selectedAssetGroups = [];
    this.kpis = null;

    this.getData(this.selectedAccountId);
  }

  dateChanged(event) {
    this.deviceSource = [];
    this.devices = [];
    this.kpis = null;

    this.getData(this.selectedAccountId);
  }

  ngOnInit() {}

  getIcon(iconId) {
    return getIconPath(iconId)[2];
  }

  filterDevices() {
    this.devices = this.deviceSource;

    // Filter groups
    if (this.selectedAssetGroups && this.selectedAssetGroups.length > 0) {
      this.devices = this.devices.filter((x) => x.assetGroups.some((ag) => this.selectedAssetGroups.includes(+ag.id)));
    }

    // Calculate totals
    this.kpis = {
      assetCount: this.devices.length,
      assetCountWithTrips: this.devices.filter((x) => x.tripCount > 0).length,
      tripCount: this.devices.reduce((partial_sum, d) => partial_sum + d.tripCount, 0),
      totalDistance: this.devices.reduce((partial_sum, d) => partial_sum + d.segmentsDistance, 0),
      segmentsDistancePrivate: this.devices.reduce((partial_sum, d) => partial_sum + d.segmentsDistancePrivate, 0),
      totalDuration: this.devices.reduce((partial_sum, d) => partial_sum + d.tripDurationInSeconds, 0),
      totalIdling: this.devices.reduce((partial_sum, d) => partial_sum + d.idlingDurationInSeconds, 0),
      efficiency: this.devices.reduce((partial_sum, d) => partial_sum + d.utilization, 0),
    };

    this.devices = this.devices.sort((a, b) => (a.utilization < b.utilization ? 1 : -1));

    this.chart = this.generateChart(this.devices);

    this.cd.markForCheck();
  }

  getData(accountId) {
    this.loading = true;

    this.deviceSource = [];
    this.devices = [];
    this.kpis = null;

    if (accountId == null) {
      this.loading = false;
      return;
    }

    this.accountService
      .getAccountUtilization(
        accountId,
        Moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf("day"),
        Moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf("day")
      )
      .subscribe(
        (result) => {
          this.deviceSource = result;
          this.filterDevices();
          this.loading = false;
          this.cd.markForCheck();
        },
        (err) => {
          this.error = err;
          this.languageLoaded = true;
          this.loading = false;
          this.cd.markForCheck();
        }
      );
  }

  generateChart(data) {
    const theData = [];
    const theContent = [];
    const categories = [];

    data.forEach((device) => {
      categories.push(device.assetName);
      theContent.push(device.efficiency * 100);
    });

    theData.push({
      data: theContent,
      type: "column",
      name: "Driverscore",
    });

    return this.chartService.generateUtilizationScoreChart(theData, categories, data.ChartType);
  }
}
