import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuditLog } from "../../models/auditlog.model";

import { UserService } from "app/services/users/user.service";
import { AppUser } from "app/models/user.model";
import { BsDaterangepickerConfig } from "ngx-bootstrap/datepicker";
import { getDefaultDpConfig } from "app/common/gridhelper";
import { AuthenticationService } from "app/services/authentication/authentication.service";

// Moment
import Moment from "moment-timezone";
window["moment"] = Moment;

@Component({
  selector: "fh-user-notifications",
  templateUrl: "notifications.template.html",
})
export class UserNotificationsViewComponent implements OnInit {
  deviceLog: AuditLog[];
  sub;

  loading = false;
  loadingNotifications = false;

  error: any;
  success: any;
  notifications = [];
  selectedNotification;

  currentPage;
  filter;
  user: AppUser;

  // Daterange
  public dpConfig: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();
  daterangepickerModel: Date[];
  userId: any;
  timezoneIana: string;

  constructor(
    private authenticationService: AuthenticationService,
    private cd: ChangeDetectorRef,
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.user = null;
    this.deviceLog = [];

    this.timezoneIana = this.authenticationService.getTimeZoneIana();

    this.daterangepickerModel = [
      Moment().tz(this.timezoneIana).subtract(1, "week").startOf("day").toDate(),
      Moment().tz(this.timezoneIana).add(0, "days").toDate(),
    ];

    this.dpConfig = getDefaultDpConfig(this.authenticationService);
  }

  dateChanged(event) {
    this.loadingNotifications = true;

    if (event !== null) {
      this.daterangepickerModel = event;
    }

    this.userService
      .getNotifications(
        this.user.id,
        Moment(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf("day"),
        Moment(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf("day")
      )
      .subscribe({
        next: (result) => {
          this.notifications = result;
          this.loadingNotifications = false;
          this.cd.markForCheck();
        },
        error: (error) => {
          this.error = error;
          this.loadingNotifications = false;
          this.cd.markForCheck();
        },
      });
  }

  ngOnInit() {
    this.loading = true;
    this.loadingNotifications = true;
    this.user = new AppUser();
    this.user.id = "";

    this.sub = this.route.params.subscribe((params) => {
      const id = params["id"];

      this.userId = id;
      this.userService.getUserById(id).subscribe((user) => {
        this.user = user;

        if (this.user == null) {
          this.router.navigate(["/Users/Overview"]);
        }

        this.loading = false;

        this.dateChanged(null);
      });
    });
  }
}
