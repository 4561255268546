<ng-select
    [loading]="loadingWebhookEndpoints"
    ngDefaultControl
    [searchable]="true"
    [clearable]="clearable"
    style="height: unset"
    [disabled]="webhookEndpoints.length == 0"
    [readonly]="readonly"
    [items]="webhookEndpoints"
    bindLabel="name"
    bindValue="id"
    [virtualScroll]="false"
    placeholder="{{ placeholder }} ({{ webhookEndpoints.length }})"
    #input
    [multiple]="multiple"
    [(ngModel)]="value"
    class="form-control"
    [ngClass]="multiple !== true ? 'table-inline' : ''"
    (ngModelChange)="webhookEndpointChanged()"
>
    <ng-template ng-label-tmp let-webhookEndpoint="item" let-clear="clear">
        <span><i class="fas fa-fw fa-network-wired"></i> {{ webhookEndpoint.name }}</span>
        <span class="ng-value-icon right" (click)="clear(webhookEndpoints)" aria-hidden="true">×</span>
    </ng-template>
    <ng-template ng-option-tmp let-webhookEndpoint="item" let-search="searchTerm">
        {{ webhookEndpoint.name }}
    </ng-template>
    <ng-template ng-footer-tmp>
        <button class="btn btn-primary hand" style="width: 100%" [routerLink]="'/System/WebhookEndpointDetails/Add'">
            <i class="fas fa-fw fa-plus"></i>
            {{ "general.createNew" | translate }}
        </button>
    </ng-template>
</ng-select>
<div style="clear: both"></div>
