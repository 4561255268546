<span *ngIf="formMode == 1"
    ><img [src]="iconPathLarge" style="margin-top: -20px; height: 54px; display: block; position: absolute"
/></span>
<span *ngIf="formMode != 1">
    <ng-select
        class="form-control table-inline"
        name="category"
        [(ngModel)]="iconCategory"
        [clearable]="false"
        style="width: 25%; float: left"
        placeholder="{{ 'general.selector' | translate }}"
        (change)="catChanged($event)"
    >
        <ng-option *ngFor="let cat of categories; let i = index" [value]="cat.Category">
            {{ cat.Value }}
        </ng-option>
    </ng-select>

    <ng-select
        class="form-control table-inline"
        name="selection"
        [(ngModel)]="iconSelection"
        [clearable]="false"
        placeholder="{{ 'general.selector' | translate }}"
        style="width: 25%; float: left; padding-left: 5px"
        (change)="itemSelected($event)"
    >
        <ng-option *ngFor="let icon of iconValues; let i = index" [value]="icon.Category">
            {{ icon.Value }}
        </ng-option>
    </ng-select>

    <ng-select
        class="form-control table-inline"
        name="color"
        [(ngModel)]="iconColor"
        [clearable]="false"
        placeholder="{{ 'general.selector' | translate }}"
        style="width: 25%; float: left; padding-left: 5px"
        (change)="itemSelected($event)"
    >
        <ng-option *ngFor="let color of colors; let i = index" [value]="color.value">
            {{ color.name }}
        </ng-option>
    </ng-select>

    <span style="padding-left: 6px"
        ><img [src]="iconPathLarge" style="margin-top: -10px; float: left; padding-left: 5px"
    /></span>
</span>
