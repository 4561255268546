import { LocationStrategy } from "@angular/common";
import { Component } from "@angular/core";
import { AuthenticationService } from "app/services/authentication/authentication.service";
import { ShareTokenService } from "app/services/sharetoken/shareToken.service";

@Component({
  selector: "fh-settings-share-tokens",
  templateUrl: "shareTokens.template.html",
})
export class ShareTokensComponent {
  accountId;
  shareTokens = [];

  success;
  error;

  loading = false;
  baseUrl;

  constructor(
    private locationStrategy: LocationStrategy,
    protected authenticationService: AuthenticationService,
    private shareTokenService: ShareTokenService
  ) {
    this.accountId = this.authenticationService.getAccountId();
    this.baseUrl = location.origin + this.locationStrategy.getBaseHref();
  }

  ngOnInit() {
    this.loading = true;

    this.shareTokenService.getShareTokens().subscribe({
      next: (result) => {
        this.loading = false;
        this.shareTokens = result;
      },
      error: (error) => {
        this.loading = false;
        this.error = error;
        console.log(error);
      },
    });
  }

  deleteShareToken(id) {
    this.loading = true;

    const index = this.shareTokens.findIndex((x) => x.id == id);
    this.shareTokens.splice(index, 1);

    this.shareTokenService.deleteShareToken(id).subscribe((result) => {
      this.success = "Deleted sharetoken";
      this.loading = false;
    });
  }
}
