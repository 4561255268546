import { ChangeDetectorRef, Component, HostListener, OnDestroy, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { getGridButtons, getGridLanguages, getDefaultDpConfig } from "app/common/gridhelper";
import { AuthenticationService } from "../../services/authentication/authentication.service";
import { GridBase360Directive } from "app/common/360Grid.base";
import { SignalrService } from "app/services/signalr/signalr.service";

import { ReportService } from "app/services/reports/report.service";
import { BsDaterangepickerConfig } from "ngx-bootstrap/datepicker";
import { roundAsNumber } from "app/common/globals";
import { ConfirmationModalComponent } from "../shared/usercontrols/confirmationModal.component";

import { StorageHelper } from "app/common/storagehelper";
import { forkJoin } from "rxjs/internal/observable/forkJoin";

// Moment
import Moment from "moment-timezone";
import { ColorService } from "app/services/common/color.service";

window["moment"] = Moment;

@Component({
  selector: "fh-reporting-templates",
  templateUrl: "templates.template.html",
})
export class ReportingTemplatesViewComponent extends GridBase360Directive implements OnDestroy {
  @ViewChild("deleteModal") deleteModal: ConfirmationModalComponent;

  loading: boolean = false;
  saving: boolean = false;
  languageLoaded: boolean;
  permissions: {};

  availableReports = {};

  excludingColumns: string[] = ["timestamp"];

  success;
  error;

  randomKey;

  constructorName: string = "ReportingViewComponent";
  permissionName: string = "FleetManagement_Reporting";
  timezoneIana: string;

  // Daterange
  public dpConfig: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();
  daterangepickerModel: Date[];

  constructor(
    public signalrService: SignalrService,
    private translateService: TranslateService,
    private cd: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private reportService: ReportService,
    protected storageHelper: StorageHelper,
    private colorService: ColorService
  ) {
    super(storageHelper);

    this.loading = true;

    this.randomKey = Math.floor(Math.random() * (999999 - 100000)) + 100000;

    this.permissions = this.authenticationService.permissions;
    this.timezoneIana = this.authenticationService.getTimeZoneIana();

    this.daterangepickerModel = [
      Moment().tz(this.timezoneIana).subtract(1, "months").startOf("day").toDate(),
      Moment().tz(this.timezoneIana).endOf("day").toDate(),
    ];

    this.dpConfig = getDefaultDpConfig(authenticationService);

    // Get all the date for dropdown boxes
    forkJoin([this.reportService.getReportColumnsByReportType(), this.translateService.get("general.date")]).subscribe({
      next: ([columns, _]) => {
        this.languageLoaded = true;
        this.loading = false;

        for (const item in columns) {
          if (item === null) {
            continue;
          }

          this.availableReports[columns[item].reportType] = true;
        }

        delete this.availableReports["0"];

        this.cd.markForCheck();

        this.initGrid();
      },
      error: (err) => {
        this.error = err;
        this.languageLoaded = true;
        this.loading = false;
        this.cd.markForCheck();
      },
    });
  }

  dateChanged(event) {
    if (event !== null) {
      this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.ajax
          .url(
            this.reportService.getSubscriptionPagingUrl(
              Moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf("day"),
              Moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf("day"),
              this.randomKey
            )
          )
          .load();
      });
    }
  }

  actualRound(value, decimals) {
    return roundAsNumber(value, decimals);
  }

  finishReportTemplate(id) {
    this.deleteModal.hideModal();

    $.fn["dataTable"].ext.search.pop();

    forkJoin([this.reportService.deleteReportTemplate(id), this.datatableElement.dtInstance]).subscribe({
      next: ([_, dtInstance]) => {
        dtInstance.ajax.reload();
        this.saving = false;
        this.cd.markForCheck();
      },
    });
  }

  @HostListener("document:deleteTemplate", ["$event.detail"])
  deleteReportTemplate(el) {
    if (this.saving) {
      return;
    }

    this.saving = true;
    this.deleteModal.showModal(el);
  }

  initGrid(): void {
    $.fn["dataTable"].ext.search.pop();

    this.loading = true;

    const reportSubscriptionTypeOptions = [];
    for (let i = 1; i < 3; i++) {
      reportSubscriptionTypeOptions.push({
        id: i,
        value: this.translateService.instant("enums.reportSubscriptionType." + i),
      });
    }

    const reportTypeOptions = [];
    for (const i of Object.keys(this.availableReports)) {
      reportTypeOptions.push({
        id: this.translateService.instant("enums.reportType." + i),
        value: this.translateService.instant("enums.reportType." + i),
      });
    }

    this.columns = [
      {
        name: "name",
        data: "name",
        defaultContent: "",
        title: this.translateService.instant("general.name"),
        render: (data, type, row) => {
          if (data) {
            if (data.startsWith("lt.reporting")) {
              return this.translateService?.instant(data);
            }
            return data;
          }
          return "-";
        },
      },
      {
        name: "reportType",
        data: "reportType",
        title: this.translateService.instant("general.selectedReportType"),
        type: "select",
        options: reportTypeOptions.sort((a, b) => a.value.localeCompare(b.value)),
        render: (data, type, row) => {
          return this.translateService.instant("enums.reportType." + data);
        },
      },
      {
        name: "createdDate",
        data: "createdDate",
        type: "date",
        title: this.translateService.instant("general.createdDate"),
        render: (data, type, row) => {
          const date = Moment.utc(data)["tz"](this.timezoneIana);
          if (type && type === "display") {
            return data ? '<span title=" ' + date.toLocaleString() + '">' + date.format("lll") + "</span>" : "";
          }

          return date.toLocaleString();
        },
      },
    ];

    if (this.permissions["Platform_IsReseller"]) {
      this.columns.push({
        name: "targetResellerId",
        data: "targetResellerId",
        defaultContent: "-",
        visable: false,
        title: this.translateService.instant("general.targetResellerId"),
        render: (data, type, row) => {
          return data;
        },
      });

      this.columns.push({
        name: "targetAccountId",
        data: "targetAccountId",
        defaultContent: "-",
        visable: false,
        title: this.translateService.instant("general.targetAccountId"),
        render: (data, type, row) => {
          return data;
        },
      });

      this.columns.push({
        name: "targetUserId",
        data: "targetUserId",
        defaultContent: "-",
        visable: false,
        title: this.translateService.instant("general.targetUserId"),
        render: (data, type, row) => {
          return data;
        },
      });
    }

    if (this.permissions["FleetManagementReporting_Delete"]) {
      this.columns.push({
        name: "id",
        data: "id",
        className: "noVis",
        orderable: false,
        title: '<div class="hideDropdown"></div>',
        width: "20",
        render: (data, type, row) => {
          if (row["isGeneral"] == false) {
            return (
              "<div class='btn btn-danger btn-grid' title='" +
              this.translateService.instant("general.delete") +
              '\'onclick=\'document.dispatchEvent(new CustomEvent("deleteTemplate", { detail: "' +
              data +
              "\", bubbles: true }));'" +
              "><i class='fas fa-fw fa-trash-alt'></i></div>"
            );
          } else {
            return "";
          }
        },
      });
    }

    this.dtOptions = {
      buttons: getGridButtons(
        this.commonExportOptions,
        "templates_overview",
        this.translateService.instant("menu.messagesoverview"),
        this.colorService
      ),
      pagingType: "simple_numbers",
      serverSide: false,
      processing: false,
      scrollX: true,
      colReorder: { fixedColumnsLeft: 1 },
      order: [[0, "desc"]],
      stateSave: false,
      ajax: (dataTablesParameters: any, callback) => {
        $(".dataTables_info").html(this.translateService.instant("grid.loadingData"));
        this.loading = true;
        this.reportService.getReportTemplates().subscribe({
          next: (resp) => {
            this.loading = false;
            console.log(resp);
            const reportTemplates = resp; //.filter(({ isDefault }) => isDefault == false);
            callback({
              recordsTotal: reportTemplates.length,
              recordsFiltered: reportTemplates.length,
              data: reportTemplates,
            });
          },
          error: (error) => {
            this.success = null;
            this.error = error;
            this.loading = false;
          },
        });
      },
      initComplete: (settings, json) => {
        this.loading = false;

        console.log("init complete");
        this.checkFilters();
        this.drawFilterRow();
        this.loading = false;
      },
      colVis: {
        restore: this.translateService.instant("general.restore"),
        showAll: this.translateService.instant("general.showAll"),
        showNone: this.translateService.instant("general.hideAll"),
        exclude: ["id", "id_export"],
      },
      columns: this.columns,
      pageLength: 17,
      lengthMenu: [
        [10, 17, 25, 50, -1],
        [10, 17, 25, 50, this.translateService.instant("general.all")],
      ],
      language: getGridLanguages(this.translateService),
    };
  }
}
