<div class="col-lg-12">
    <div class="horizontalMenuWrapper">
        <ul>
            <li [ngClass]="{active:  activeRoute('Notifications/Overview')}">
                <a [routerLink]="['/Notifications/Overview']" aria-expanded="true" aria-disabled="true">
                    <i class="fas fa-fw fa-bell"></i>
                    <div>{{ 'general.inbox' | translate }}</div>
                </a>
            </li>
            <li [ngClass]="{active:  activeRoute('Notifications/Live')}">
                <a [routerLink]="['/Notifications/Live']" aria-expanded="true" aria-disabled="true">
                    <i class="fas fa-fw fa-bell"></i>
                    <div>{{ 'general.live' | translate }}</div>
                </a>
            </li>
            <li
                *ngIf="!hideTriggers && permissions['Triggers_View']"
                [ngClass]="{active:  activeRoute('Notifications/Triggers') || isTriggerDetails()}"
            >
                <a [routerLink]="['/Notifications/Triggers/Overview/']" aria-expanded="true" aria-disabled="true">
                    <i class="fas fa-fw fa-bell-on"></i>
                    <div>{{ 'general.triggers' | translate }}</div>
                </a>
            </li>
            <li
                *ngIf="permissions['Platform_IsFleetManager']"
                [ngClass]="{active: activeRoute('Notifications/TriggerSchedule')}"
            >
                <a [routerLink]="['/Notifications/TriggerSchedules/Overview']">
                    <i class="fas fa-fw fa-calendar"></i>
                    <div>{{ 'general.triggerSchedules' | translate }}</div>
                </a>
            </li>
            <li *ngIf="permissions['Platform_IsReseller']" [ngClass]="{active: activeRoute('Notifications/Log')}">
                <a [routerLink]="['/Notifications/Log/Overview/']" aria-expanded="true" aria-disabled="true">
                    <i class="fas fa-fw fa-envelope-open-text"></i>
                    <div>{{ 'general.notificationLog' | translate }}</div>
                </a>
            </li>
            <li
                *ngIf="permissions['UserContacts_View'] || permissions['UserContactGroups_View']"
                [ngClass]="{active: activeRoute('Notifications/UserContacts') || isUserContacts()}"
            >
                <a [routerLink]="['/Notifications/UserContacts/']">
                    <i class="fas fa-fw fa-address-card"></i>
                    <div>{{ 'menu.userContacts' | translate }}</div>
                </a>
            </li>
            <li
                *ngIf="permissions['FleetManagement_NotificationSettings']"
                [ngClass]="{active: activeRoute('Notifications/Settings')}"
            >
                <a [routerLink]="['/Notifications/Settings/']">
                    <i class="fas fa-fw fa-cog"></i>
                    <div>{{ 'menu.settings' | translate }}</div>
                </a>
            </li>
        </ul>
    </div>
    <div class="horizontalMenuWrapper" style="float: right">
        <ul>
            <ng-content></ng-content>
        </ul>
    </div>
</div>

<div class="col-lg-12" style="padding: 5px" *ngIf="isUserContacts()">
    <div class="horizontalMenuSubWrapper">
        <ul>
            <li
                *ngIf="permissions['UserContacts_View']"
                [ngClass]="{active: activeRoute('Notifications/UserContacts') || activeRoute('Notifications/UserContactDetails')}"
            >
                <a [routerLink]="['/Notifications/UserContacts/Overview']">
                    <i class="fas fa-fw fa-address-card"></i>
                    {{ 'menu.userContacts' | translate }}
                </a>
            </li>
            <li
                *ngIf="permissions['UserContactGroups_View']"
                [ngClass]="{active: activeRoute('Notifications/UserContactGroup')}"
            >
                <a [routerLink]="['/Notifications/UserContactGroups/Overview']">
                    <i class="fas fa-fw fa-address-book"></i>
                    {{ 'menu.userContactGroups' | translate }}
                </a>
            </li>
        </ul>
    </div>
</div>

<div class="col-lg-12" style="padding: 5px" *ngIf="isTriggerDetails()">
    <div class="horizontalMenuSubWrapper">
        <ul>
            <li [ngClass]="{active: activeRoute('TriggerDetails/Index')}">
                <a [routerLink]="['/Notifications/TriggerDetails/Index/', activeId]">
                    <i class="fas fa-fw fa-bell-on"></i>
                    {{ 'menu.dashboard' | translate }}
                </a>
            </li>
            <li [ngClass]="{active: activeRoute('TriggerDetails/Details')}">
                <a [routerLink]="['/Notifications/TriggerDetails/Details/', activeId]">
                    <i class="fas fa-fw fa-th-list"></i>
                    {{ 'menu.details' | translate }}
                </a>
            </li>
            <li
                *ngIf="permissions['FleetManagement_Notifications']"
                [ngClass]="{active: activeRoute('TriggerDetails/Notifications')}"
            >
                <a [routerLink]="['/Notifications/TriggerDetails/Notifications/', activeId]">
                    <i class="fas fa-fw fa-envelope-open-text"></i>
                    {{ 'general.notifications' | translate }}
                </a>
            </li>
            <li
                *ngIf="permissions['FleetManagement_Episodes']"
                [ngClass]="{active: activeRoute('TriggerDetails/Episodes')}"
            >
                <a [routerLink]="['/Notifications/TriggerDetails/Episodes/', activeId]">
                    <i class="fas fa-fw fa-traffic-light-stop"></i>
                    {{ 'general.episodes' | translate }}
                </a>
            </li>
            <li *ngIf="permissions['FleetManagement_Log']" [ngClass]="{active: activeRoute('TriggerDetails/Log')}">
                <a [routerLink]="['/Notifications/TriggerDetails/Log/', activeId]">
                    <i class="fas fa-fw fa-file-alt"></i>
                    {{ 'menu.log' | translate }}
                </a>
            </li>
        </ul>
    </div>
</div>
<div style="clear: both"></div>
