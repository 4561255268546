import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { ActionType, EntityType, TrackerTypeSource } from "app/common/enums";
import { WizardComponent } from "app/modules/wizard/wizard";
import { AccountService } from "app/services/account/account.service";
import { ExportService } from "app/services/export/export.service";

import * as XLSX from "xlsx-js-style";

type AOA = any[][];
interface UploadColumn {
  isEnabled?: boolean;
  name: string;
  isRequired: boolean;
  mapping?: number;
}

// Moment
import Moment from "moment-timezone";
import { Upload } from "app/models/upload.model";
import { ResellerService } from "app/services/resellers/resellers.service";
import { DeviceType } from "app/models/devicetype.model";
import { DeviceTypeService } from "app/services/devicetypes/devicetypes.service";

window["moment"] = Moment;

@Component({
  selector: "fh-import",
  templateUrl: "import.template.html",
  styleUrls: ["import.template.css"],
})
export class ImportViewComponent implements OnInit {
  @ViewChild(WizardComponent, { static: false }) wizard: WizardComponent;

  entityType = EntityType;
  actionType = ActionType;

  step = 1;
  processing = false;

  success;
  error;
  warning;
  errorItems;

  // Resellers
  selectedReseller;
  loadingResellers = false;
  resellers = [];
  selectedResellerId;

  // Accounts
  selectedAccountId;
  selectedAccount;
  useAccount = true;
  loadingAccounts = false;
  accounts = [];

  // Actions
  selectedActionId;

  // Entities
  selectedEntityId;
  clearItemsOnImport = false;

  insertCount = 0;
  updateCount = 0;

  // Trackers
  loadingDeviceTypes = false;
  deviceTypes: DeviceType[] = [];
  selectedTrackerTypeSource = TrackerTypeSource.Default;
  selectedTrackerTypeId = null;
  applyToAll = false;

  // Uploader
  fileName;
  errorList = [];
  processingUpload = false;
  uploadData: Upload[] = [];
  columns: UploadColumn[] = [];

  showUploader = true;
  showConfirm = false;

  uploading = false;
  uploaded = false;

  useButton = false;

  uploadForm;

  data: AOA = [[]];
  headers = [];

  identifierName = "id";

  constructor(
    private accountService: AccountService,
    private resellerService: ResellerService,
    private exportService: ExportService,
    private deviceTypeService: DeviceTypeService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.getDeviceTypes();
  }

  changeEnabled(column) {
    if (column.mapping) {
      column.isEnabled = true;
    } else {
      column.isEnabled = false;
    }
  }

  entityTypeChanged() {
    if (this.selectedEntityId === EntityType.Device) {
      this.useAccount = false;
      this.checkAccountSwitch();
    }
  }

  checkAccountSwitch() {
    if (!this.useAccount) {
      this.selectedAccountId = null;
      this.selectedAccount = null;
    }
  }

  trackerTypeSourceChanged(selectedTrackerTypeSource) {
    this.selectedTrackerTypeSource = selectedTrackerTypeSource;

    if (selectedTrackerTypeSource !== 2) {
      this.selectedTrackerTypeId = null;
    }
  }

  resellerChanged(resellerId) {
    this.accounts = [];
    this.selectedAccountId = null;

    this.selectedResellerId = resellerId;
    this.selectedReseller = this.resellerService.resellers.find((x) => x.id === resellerId);
  }

  accountChanged(accountId) {
    this.selectedAccountId = accountId;
    this.selectedAccount = this.accountService.accounts.find((x) => x.id === this.selectedAccountId);
  }

  onCheckOutFinish() {
    this.processing = true;

    // todo: Do the actual import
    this.exportService
      .postImport(this.selectedAccountId, this.selectedResellerId, this.selectedEntityId, {
        contents: this.uploadData,
        columns: this.columns,
      })
      .subscribe(
        (result) => {
          this.processing = false;

          this.error = null;
          this.warning = null;
          this.success = null;
          this.errorItems = null;

          if (result.isSuccess) {
            if (result.errorCount > 0) {
              this.warning = {
                statusText: "Warning",
                warning: "Data has some errors. " + result.message,
              };

              this.errorItems = {
                statusText: "Failed items",
                success: "These items had errors. " + result.message,
                json: result.errorItems,
              };

              setTimeout(() => {
                this.resetWizard();
              }, 3000);
            } else {
              this.success = {
                statusText: "Success",
                success: "Data has been succesfully imported. " + result.message,
              };

              setTimeout(() => {
                this.resetWizard();
              }, 3000);
            }
          } else {
            this.error = {
              statusText: "Error",
              error: "Data import has failed please fix the issue and try again. " + result.message,
            };
            this.errorItems = {
              statusText: "Failed items",
              success: "These items had errors. " + result.message,
              json: result.errorItems,
            };
          }
        },
        (error) => {
          this.error = error;
          this.processing = false;
        }
      );
  }

  resetWizard() {
    this.selectedAccountId = null;
    this.selectedActionId = null;
    this.selectedEntityId = null;
    this.selectedResellerId = null;
    this.selectedTrackerTypeSource = TrackerTypeSource.Default;
    this.useAccount = true;

    this.wizard.reset();
  }

  getConfirm() {
    this.processing = true;

    // todo:  Upload data and check the amount of entities updated and inserted

    this.processing = false;
  }

  // Uploader
  onFileChange(event: any) {
    this.uploading = true;
    /* wire up file reader */
    // const target: DataTransfer = <DataTransfer>(evt.target);
    if (event.target.files.length !== 1) {
      this.uploading = false;
      this.cd.markForCheck();
      throw new Error("Cannot use multiple files");
    }

    // Define reader
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      this.error = null;

      try {
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: "binary", cellText: true, cellDates: true });

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        /* save data */
        this.data = <AOA>XLSX.utils.sheet_to_json(ws, { header: 1, raw: false, dateNF: "yyyy-mm-dd" });
        this.headers = [];

        if (this.data.length <= 1) {
          this.error = {
            statusText: "Error",
            error: "No data found or error parsing",
          };
        } else {
          this.data[0].forEach((value, index) => {
            this.headers.push({ id: index + 1, name: value });
          });
          this.tryMapping();
        }

        this.uploading = false;
        this.cd.markForCheck();
      } catch (error) {
        this.error = error;
        this.uploading = false;
        this.cd.markForCheck();
        console.log(error);
      }
    };

    // Read
    reader.readAsBinaryString(event.target.files[0]);
  }

  prepareUploader() {
    console.log("Preparing uploader");
    // Always reset upload
    this.headers = [];
    this.data = [];
    this.selectedTrackerTypeSource = TrackerTypeSource.Default;

    if (this.selectedEntityId === EntityType.Device) {
      this.identifierName = "id";
      this.columns = [
        { name: "id", isRequired: false },
        { name: "unitId", isRequired: false },
        { name: "deviceStatus", isRequired: false },
        { name: "deviceInventoryStatus", isRequired: false },
        { name: "deviceType", isRequired: false },
        { name: "simActivationStatus", isRequired: false },
        { name: "simIdentifier", isRequired: false },
        { name: "imsi", isRequired: false },
        { name: "msisdn", isRequired: false },
      ];
    }

    if (this.selectedEntityId === EntityType.Sims) {
      this.identifierName = "unitId";
      this.columns = [
        { name: "unitId", isRequired: true },
        { name: "simActivationStatus", isRequired: false },
        { name: "simIdentifier", isRequired: false },
        { name: "imsi", isRequired: false },
        { name: "msisdn", isRequired: false },
      ];
    }

    if (this.selectedEntityId === EntityType.Asset) {
      this.identifierName = "id";
      this.columns = [
        { name: "id", isRequired: true },
        { name: "name", isRequired: false },
        { name: "deviceId", isRequired: false },
        { name: "brand", isRequired: false },
        { name: "code", isRequired: false },
        { name: "color", isRequired: false },
        { name: "comment", isRequired: false },
        { name: "assignmentStart", isRequired: false },
        { name: "assignmentEnd", isRequired: false },
        { name: "model", isRequired: false },
        { name: "plateNumber", isRequired: false },
        { name: "vin", isRequired: false },
        { name: "year", isRequired: false },
      ];
    }

    if (this.selectedEntityId === EntityType.Driver) {
      this.identifierName = "id";
      this.columns = [
        { name: "id", isRequired: true },
        { name: "firstName", isRequired: true },
        { name: "lastName", isRequired: true },
        { name: "dateOfBirth", isRequired: false },
        { name: "email", isRequired: false },
        { name: "mobilePhone", isRequired: false },
        { name: "officePhone", isRequired: false },
        { name: "homePhone", isRequired: false },
        { name: "driverLicense", isRequired: false },
        { name: "driverLicenceExpirationDate", isRequired: false },
        { name: "identificationCardNumber", isRequired: false },
        { name: "identificationCardExpirationDate", isRequired: false },
        { name: "qualification", isRequired: false },
        { name: "comment", isRequired: false },
        { name: "erpCode", isRequired: false },
        { name: "tag", isRequired: false },
      ];
    }

    if (this.selectedEntityId === EntityType.Geofence) {
      this.identifierName = "id";
      this.columns = [
        { name: "id", isRequired: true },
        { name: "name", isRequired: true },
        { name: "color", isRequired: true },
        { name: "geoJson", isRequired: true },
        { name: "isArchived", isRequired: false },
        { name: "radius", isRequired: false },
        { name: "userId", isRequired: false },
        { name: "referenceId", isRequired: false },
      ];
    }

    if (this.selectedEntityId === EntityType.Trailer) {
      this.identifierName = "id";
      this.columns = [
        { name: "id", isRequired: true },
        { name: "name", isRequired: true },
        { name: "erpCode", isRequired: false },
        { name: "identificationTag", isRequired: false },
      ];
    }

    if (this.selectedEntityId === EntityType.Fuel) {
      this.identifierName = "id";
      this.columns = [
        { name: "assetId", isRequired: true },
        { name: "eventTimeStamp", isRequired: true },
        { name: "fuelingActivityInLiters", isRequired: true },
      ];
    }

    if (this.selectedEntityId === EntityType.Maintenance) {
      this.identifierName = "id";
      this.columns = [
        { name: "assetId", isRequired: true },
        { name: "maintenanceTypeId", isRequired: true },
        { name: "occurrenceDate", isRequired: true },
        { name: "description", isRequired: true },
        { name: "cost", isRequired: true },
        { name: "reference", isRequired: false },
        { name: "supplierName", isRequired: false },
        { name: "dateCalibration", isRequired: false },
        { name: "realAssetOdo", isRequired: false },
        { name: "localPurchaseOrderNumber", isRequired: false },
        { name: "materialReceiptInspectionVoucherNumber", isRequired: false },
        { name: "quantity", isRequired: false },
        { name: "unitPrice", isRequired: false },
        { name: "discount", isRequired: false },
        { name: "remarks", isRequired: false },
        { name: "partNumber", isRequired: false },
        { name: "controlNumber", isRequired: false },
      ];
    }

    if (this.selectedEntityId === EntityType.AssetCalibration) {
      this.identifierName = "id";
      this.columns = [
        { name: "assetId", isRequired: true },
        { name: "dateCalibration", isRequired: true },
        { name: "realAssetOdo", isRequired: true },
      ];
    }

    this.cd.markForCheck();
  }

  tryMapping() {
    const that = this;
    // Try to automap columns
    this.columns.forEach((column) => {
      const index = this.headers.map((x) => x.name.toLowerCase()).indexOf(column.name.toLowerCase());
      if (index !== -1) {
        column.mapping = index + 1;
        column.isEnabled = true;
      } else {
        column.isEnabled = false;
      }
    });

    const deviceTypeColumn = this.columns.find((x) => x.name.toLowerCase() === "devicetype");

    if (deviceTypeColumn && deviceTypeColumn.mapping) {
      this.selectedTrackerTypeSource = TrackerTypeSource.Column;
    }

    this.cd.markForCheck();
  }

  parseData(): void {
    this.processingUpload = true;

    const returnVar = [];
    this.data.forEach((row, index) => {
      if (index > 0) {
        const rowItem = {};
        this.columns.forEach((column) => {
          if (column.mapping && column.mapping !== -2) {
            rowItem[column.name] = row[column.mapping - 1];
          }
        });
        returnVar.push(rowItem);
      }
    });

    // Mapping of the upload entity
    this.uploadData = [];
    this.errorList = [];

    returnVar.forEach((item) => {
      const dataItem = new Upload();

      Object.keys(item).forEach((key) => {
        dataItem[key] = item[key];
      });

      // dataItem[this.identifierName] = Number(item[this.identifierName]);

      if (!dataItem[this.identifierName]) {
        dataItem[this.identifierName] = -1;
        dataItem.isNewEntity = true;
      }

      if (isNaN(dataItem[this.identifierName])) {
        this.errorList.push(`Error converting '${item[this.identifierName]}' to number`);
        dataItem.hasError = true;
      }

      if (this.selectedEntityId === EntityType.Driver) {
        if (dataItem["firstName"] == null) {
          this.errorList.push(`First name is mandatory`);
          dataItem.hasError = true;
        }

        if (dataItem["lastName"] == null) {
          this.errorList.push(`Last name is mandatory`);
          dataItem.hasError = true;
        }
      }

      // If prefilled tracker type is selected, apply either to all devices or only to devices for which no type is filled in in sheet
      if (this.selectedTrackerTypeSource === TrackerTypeSource.Default && this.selectedTrackerTypeId !== null) {
        dataItem["deviceType"] = this.selectedTrackerTypeId;
      }

      if (this.selectedEntityId === EntityType.Device && dataItem.isNewEntity) {
        if (dataItem["deviceType"] == null) {
          this.errorList.push("Tracker type is mandatory");
          dataItem.hasError = true;
        }
        if (dataItem["unitId"] == null) {
          this.errorList.push("UnitId is mandatory");
          dataItem.hasError = true;
        }
      }

      // dataItem['accountId'] = Number(item.accountId);
      // if (isNaN(dataItem['accountId'])) {
      //   this.errorList.push(`Error converting '${item.accountId}' to number`);
      //   dataItem.hasError = true;
      // }

      this.uploadData.push(dataItem);
    });

    // We can save here right away or list a confirmbox
    // Show errors and confirm
    this.showConfirm = true;
    this.showUploader = false;

    this.insertCount = this.uploadData.filter((x) => x.hasError === false && x.isNewEntity === true).length;
    this.updateCount = this.uploadData.filter((x) => x.hasError === false && x.isNewEntity === false).length;

    this.processingUpload = false;
    this.cd.markForCheck();
  }

  getDeviceTypes() {
    this.loadingDeviceTypes = true;
    this.deviceTypeService.getDeviceTypes().subscribe((deviceTypes) => {
      this.loadingDeviceTypes = false;
      this.deviceTypes = deviceTypes
        .sort((a, b) => a.modelName.localeCompare(b.modelName))
        .filter((d) => d.modelName !== "");

      this.deviceTypes.forEach((deviceType) => {
        deviceType.id = +deviceType.id;
      });

      this.cd.markForCheck();
    });
  }
}
