import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { appDate, appVersion } from "../../app.version";
import { AuthenticationService } from "../../services/authentication/authentication.service";
import { UserService } from "../../services/users/user.service";

@Component({
  templateUrl: "./register.template.html",
  styleUrls: ["./login.template.css"],
})
export class RegisterComponent {
  model: any = {};
  loading = false;

  appDate: string;
  appVersion: string;
  UserName = "";
  Password = "";
  error: string;
  data: string;
  returnUrl;
  subscription;
  param = null;
  errorLoggedIn = false;

  constructor(
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private userService: UserService
  ) {
    this.subscription = route.queryParams.subscribe((queryParam: any) => (this.param = queryParam["prev"]));
    this.appVersion = appVersion;
    this.appDate = appDate;
  }

  onMouseMove(event) {
    const e = event;
    // Mouse Move 3d Effect

    const perX = e.clientX / $(window).width() - 0.5;
    const perY = e.clientY / $(window).height() - 0.5;
    // TweenMax.to('.effect-3d-element', 0.4, { rotationY: 5 * perX, rotationX: 5 * perY, transformPerspective: 1000, transformOrigin: 'center' })
  }

  register() {
    this.loading = true;
    const that = this;

    // setTimeout(function () {
    //     that.userService.createUser(that.model)
    //         .subscribe(
    //             data => {
    //                 that.router.navigate(['/Login']);
    //             },
    //             error => {
    //                 that.loading = false;
    //             });
    // }, 2000);
  }
}
