<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/"> <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/System/">{{'general.system' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/System/Overview">{{'general.system' | translate }}</a>
                </li>
                <li class="breadcrumb-item active">
                    <a href="/#/System/ErrorLogs/Overview">{{'general.errorLogs' | translate }}</a>
                </li>
                <li class="breadcrumb-item active">{{'general.dashboard' | translate }}</li>
            </ol>
        </nav>
    </div>
    <fh-system-navigation></fh-system-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">
        <div class="row" data-equalizer="" *ngIf="error">
            <div class="col-lg-12">
                <fh-error [error]="error"></fh-error>
            </div>
        </div>
        <div class="row" data-equalizer="">
            <div class="col-lg-12">
                <div class="ibox" style="position: relative">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <div class="ibox-title">
                        <h5><i class="fa fa-bug"></i> {{'general.errorLog' | translate }}</h5>
                    </div>
                    <div class="ibox-content slick" style="position: relative">
                        <table style="width: 100%" class="table table-fixed nowrap dtr-inline no-footer">
                            <tbody *ngIf="!errorLog">
                                <tr>
                                    <td colspan="4">{{ 'general.noErrorLog' | translate}}</td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="errorLog">
                                <tr>
                                    <td class="row_opening">{{ 'general.date' | translate}}</td>
                                    <td class="row_content" title="{{ errorLog.createdDate?.toLocaleString() }}">
                                        {{ errorLog.createdDate | amDateFormat: 'YYYY-MM-DD HH:mm:ss '}}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="row_opening">{{ 'general.user' | translate}}</td>
                                    <td class="row_content">{{ errorLog.username }}</td>
                                </tr>
                                <tr>
                                    <td class="row_opening">{{ 'general.description' | translate}}</td>
                                    <td class="row_content">{{ errorLog.description }}</td>
                                </tr>
                                <tr>
                                    <td class="row_opening">{{ 'general.message' | translate}}</td>
                                    <td class="row_content">{{ errorLog.message }}</td>
                                </tr>
                                <tr>
                                    <td class="row_opening">{{ 'general.location' | translate}}</td>
                                    <td class="row_content">{{ errorLog.location }}</td>
                                </tr>
                                <tr>
                                    <td class="row_opening">{{ 'general.stack' | translate}}</td>
                                    <td class="row_content" style="white-space: normal">{{ errorLog.stack }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</fh-permission-handler>
