import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { getGridButtons, getGridLanguages, createdCellCheckbox } from "app/common/gridhelper";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { forkJoin } from "rxjs";
import { GridBase360Directive } from "app/common/360Grid.base";
import { UserRoleService } from "app/services/users/userRole.service";
import { ColorService } from "app/services/common/color.service";

// Moment timezone
import Moment from "moment-timezone";
import { StorageHelper } from "app/common/storagehelper";

window["moment"] = Moment;

@Component({
  selector: "fh-user-roles",
  templateUrl: "userRoles.template.html",
})
export class UserRolesViewComponent extends GridBase360Directive implements OnInit, OnDestroy {
  token: string;

  showActiveOnly = true;

  loading = false;
  languageLoaded: boolean;
  permissions: {};

  permissionName = "UserRoles_View";
  constructorName = "UserRolesViewComponent";
  timezoneIana: string;

  error;

  constructor(
    private translateService: TranslateService,
    private cd: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private userRoleService: UserRoleService,
    protected storageHelper: StorageHelper,
    private colorService: ColorService
  ) {
    super(storageHelper);

    this.token = this.authenticationService.getAuthToken();
    this.timezoneIana = this.authenticationService.getTimeZoneIana();

    // Get all the date for dropdown boxes
    forkJoin(this.translateService.get("general.date")).subscribe(
      (data) => {
        this.languageLoaded = true;
        this.loading = false;

        this.initGrid();
      },
      (err) => console.error(err)
    );
  }

  // Fire custom filters to update grid and call server again
  fireFilter(event): void {
    if (event != null) {
      console.log("Fire update");
      this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
        if (this.showActiveOnly === true) {
          dtInstance.column("isActive:name").search("true");
        } else {
          dtInstance.column("isActive:name").search("@ignore");
        }

        // Fire the request
        dtInstance.draw();
      });
    }
  }

  ngOnInit() {
    this.permissions = this.authenticationService.permissions;

    this.translateService.get("general.date").subscribe((value) => {
      this.languageLoaded = true;
      this.initGrid();
    });
  }

  // Check custom filters from grid save
  checkFilters() {
    this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
      this.showActiveOnly = dtInstance.column("isActive:name").search() !== "@ignore";
    });

    this.fireFilter(1);
  }

  initGrid(): void {
    this.permissions = this.authenticationService.permissions;

    const that = this;

    $.fn["dataTable"].ext.search.pop();

    this.loading = true;

    this.columns = [
      {
        name: "id",
        data: "id",
        className: "noVis",
        orderable: false,
        title: '<div class="hideDropdown"></div>',
        width: "20",
        render: function (data, type, row) {
          return `
            <a class='btn btn-primary btn-grid' title='${that.translateService.instant(
              "general.details"
            )}' href='/#/Users/UserRoleDetails/Index/${data}'>
              <span class="d-none d-md-inline-flex" style="padding-left: 7px">${that.translateService.instant(
                "general.details"
              )}</span>
              <i class='fas fa-fw fa-angle-right'></i>
            </a>`;
        },
      },
      {
        name: "id_export",
        data: "id",
        className: "noVis",
        title: this.translateService.instant("general.id"),
        visible: false,
      },
      {
        name: "name",
        data: "name",
        title: this.translateService.instant("general.name"),
      },
      {
        name: "companyName",
        data: "companyName",
        defaultContent: "-",
        title: this.translateService.instant("general.companyName"),
      },
      {
        name: "userCount",
        data: "userCount",
        defaultContent: 0,
        title: this.translateService.instant("general.userCount"),
      },
      {
        name: "accountId",
        data: "accountId",
        defaultContent: "-",
        title: this.translateService.instant("general.accountId"),
        visible: false,
      },
      {
        name: "resellerDescription",
        data: "resellerDescription",
        defaultContent: "-",
        title: this.translateService.instant("general.resellerDescription"),
      },
    ];

    if (this.permissions["Platform_IsAdministrator"]) {
      this.columns.push({
        name: "isAdministrator",
        data: "isAdministrator",
        width: "40",
        createdCell: createdCellCheckbox,
        title: this.translateService.instant("general.isAdministrator"),
      });
    }

    if (this.permissions["Platform_IsReseller"]) {
      this.columns.push({
        name: "isReseller",
        data: "isReseller",
        width: "40",
        createdCell: createdCellCheckbox,
        title: this.translateService.instant("general.isReseller"),
      });
      this.columns.push({
        name: "isFleetManager",
        data: "isFleetManager",
        width: "40",
        createdCell: createdCellCheckbox,
        title: this.translateService.instant("general.isFleetManager"),
      });
      this.columns.push({
        name: "isInstaller",
        data: "isInstaller",
        width: "40",
        createdCell: createdCellCheckbox,
        title: this.translateService.instant("general.isInstaller"),
      });
    }

    this.columns.push(
      {
        name: "hasFleetManagement",
        data: "hasFleetManagement",
        width: "40",
        visible: false,
        createdCell: createdCellCheckbox,
        title: this.translateService.instant("general.hasFleetManagement"),
      },
      {
        name: "hasFleetOverview",
        data: "hasFleetOverview",
        width: "40",
        visible: false,
        createdCell: createdCellCheckbox,
        title: this.translateService.instant("general.hasFleetOverview"),
      },
      {
        name: "hasSetup",
        data: "hasSetup",
        width: "40",
        visible: false,
        createdCell: createdCellCheckbox,
        title: this.translateService.instant("general.hasSetup"),
      },
      {
        name: "hasReporting",
        data: "hasReporting",
        width: "40",
        visible: false,
        createdCell: createdCellCheckbox,
        title: this.translateService.instant("general.hasReporting"),
      },
      {
        name: "hasMobileApp",
        data: "hasMobileApp",
        width: "40",
        visible: false,
        createdCell: createdCellCheckbox,
        title: this.translateService.instant("general.hasMobileApp"),
      },
      {
        name: "hasApi360",
        data: "hasApi360",
        width: "40",
        visible: false,
        createdCell: createdCellCheckbox,
        title: this.translateService.instant("general.hasApi360"),
      },
      {
        title: "Entities.Accounts",
        width: "40",
        defaultContent: "",
        sortable: false,
        visible: false,
        createdCell: createdCellCheckbox,
        render: function (data, type, row) {
          const permissions = JSON.parse(row.permissionsJson);
          return permissions?.Entities?.includes("Accounts");
        },
      },
      {
        title: "Entities.Assets",
        width: "40",
        defaultContent: "",
        sortable: false,
        visible: false,
        createdCell: createdCellCheckbox,
        render: function (data, type, row) {
          const permissions = JSON.parse(row.permissionsJson);
          return permissions?.Entities?.includes("Assets");
        },
      },
      {
        title: "Entities.AssetGroups",
        width: "40",
        defaultContent: "",
        sortable: false,
        visible: false,
        createdCell: createdCellCheckbox,
        render: function (data, type, row) {
          const permissions = JSON.parse(row.permissionsJson);
          return permissions?.Entities?.includes("AssetGroups");
        },
      },
      {
        title: "Entities.AssetTypes",
        width: "40",
        defaultContent: "",
        sortable: false,
        visible: false,
        createdCell: createdCellCheckbox,
        render: function (data, type, row) {
          const permissions = JSON.parse(row.permissionsJson);
          return permissions?.Entities?.includes("AssetTypes");
        },
      },
      {
        title: "Entities.Devices",
        width: "40",
        defaultContent: "",
        sortable: false,
        visible: false,
        createdCell: createdCellCheckbox,
        render: function (data, type, row) {
          const permissions = JSON.parse(row.permissionsJson);
          return permissions?.Entities?.includes("Devices");
        },
      },
      {
        title: "Entities.DeviceTypes",
        width: "40",
        defaultContent: "",
        sortable: false,
        visible: false,
        createdCell: createdCellCheckbox,
        render: function (data, type, row) {
          const permissions = JSON.parse(row.permissionsJson);
          return permissions?.Entities?.includes("DeviceTypes");
        },
      },
      {
        title: "Entities.Drivers",
        width: "40",
        defaultContent: "",
        sortable: false,
        visible: false,
        createdCell: createdCellCheckbox,
        render: function (data, type, row) {
          const permissions = JSON.parse(row.permissionsJson);
          return permissions?.Entities?.includes("Drivers");
        },
      },
      {
        title: "Entities.DriverGroups",
        width: "40",
        defaultContent: "",
        sortable: false,
        visible: false,
        createdCell: createdCellCheckbox,
        render: function (data, type, row) {
          const permissions = JSON.parse(row.permissionsJson);
          return permissions?.Entities?.includes("DriverGroups");
        },
      },
      {
        title: "Entities.Geofences",
        width: "40",
        defaultContent: "",
        sortable: false,
        visible: false,
        createdCell: createdCellCheckbox,
        render: function (data, type, row) {
          const permissions = JSON.parse(row.permissionsJson);
          return permissions?.Entities?.includes("Geofences");
        },
      },
      {
        title: "Entities.GeofenceGroups",
        width: "40",
        defaultContent: "",
        sortable: false,
        visible: false,
        createdCell: createdCellCheckbox,
        render: function (data, type, row) {
          const permissions = JSON.parse(row.permissionsJson);
          return permissions?.Entities?.includes("GeofenceGroups");
        },
      },
      {
        title: "Entities.Users",
        width: "40",
        defaultContent: "",
        sortable: false,
        visible: false,
        createdCell: createdCellCheckbox,
        render: function (data, type, row) {
          const permissions = JSON.parse(row.permissionsJson);
          return permissions?.Entities?.includes("Users");
        },
      },
      {
        title: "Entities.UserRoles",
        width: "40",
        defaultContent: "",
        sortable: false,
        visible: false,
        createdCell: createdCellCheckbox,
        render: function (data, type, row) {
          const permissions = JSON.parse(row.permissionsJson);
          return permissions?.Entities?.includes("UserRoles");
        },
      },
      {
        title: "Entities.UserContacts",
        width: "40",
        defaultContent: "",
        sortable: false,
        visible: false,
        createdCell: createdCellCheckbox,
        render: function (data, type, row) {
          const permissions = JSON.parse(row.permissionsJson);
          return permissions?.Entities?.includes("UserContacts");
        },
      },
      {
        title: "Entities.UserContactGroups",
        width: "40",
        defaultContent: "",
        sortable: false,
        visible: false,
        createdCell: createdCellCheckbox,
        render: function (data, type, row) {
          const permissions = JSON.parse(row.permissionsJson);
          return permissions?.Entities?.includes("UserContactGroups");
        },
      },
      {
        title: "Entities.Resellers",
        width: "40",
        defaultContent: "",
        sortable: false,
        visible: false,
        createdCell: createdCellCheckbox,
        render: function (data, type, row) {
          const permissions = JSON.parse(row.permissionsJson);
          return permissions?.Entities?.includes("Resellers");
        },
      },
      {
        title: "Entities.Trailers",
        width: "40",
        defaultContent: "",
        sortable: false,
        visible: false,
        createdCell: createdCellCheckbox,
        render: function (data, type, row) {
          const permissions = JSON.parse(row.permissionsJson);
          return permissions?.Entities?.includes("Trailers");
        },
      },
      {
        title: "Entities.Projects",
        width: "40",
        defaultContent: "",
        sortable: false,
        visible: false,
        createdCell: createdCellCheckbox,
        render: function (data, type, row) {
          const permissions = JSON.parse(row.permissionsJson);
          return permissions?.Entities?.includes("Projects");
        },
      },
      {
        title: "Entities.Triggers",
        width: "40",
        defaultContent: "",
        sortable: false,
        visible: false,
        createdCell: createdCellCheckbox,
        render: function (data, type, row) {
          const permissions = JSON.parse(row.permissionsJson);
          return permissions?.Entities?.includes("Triggers");
        },
      },
      {
        title: "Entities.FuelEvents",
        width: "40",
        defaultContent: "",
        sortable: false,
        visible: false,
        createdCell: createdCellCheckbox,
        render: function (data, type, row) {
          const permissions = JSON.parse(row.permissionsJson);
          return permissions?.Entities?.includes("FuelEvents");
        },
      },
      {
        title: "Entities.Maintenances",
        width: "40",
        defaultContent: "",
        sortable: false,
        visible: false,
        createdCell: createdCellCheckbox,
        render: function (data, type, row) {
          const permissions = JSON.parse(row.permissionsJson);
          return permissions?.Entities?.includes("Maintenances");
        },
      },
      {
        title: "Entities.MaintenanceTypes",
        width: "40",
        defaultContent: "",
        sortable: false,
        visible: false,
        createdCell: createdCellCheckbox,
        render: function (data, type, row) {
          const permissions = JSON.parse(row.permissionsJson);
          return permissions?.Entities?.includes("MaintenanceTypes");
        },
      },
      {
        title: "Entities.Warehouses",
        width: "40",
        defaultContent: "",
        sortable: false,
        visible: false,
        createdCell: createdCellCheckbox,
        render: function (data, type, row) {
          const permissions = JSON.parse(row.permissionsJson);
          return permissions?.Entities?.includes("Warehouses");
        },
      },
      {
        title: "Entities.Wasl",
        width: "40",
        defaultContent: "",
        sortable: false,
        visible: false,
        createdCell: createdCellCheckbox,
        render: function (data, type, row) {
          const permissions = JSON.parse(row.permissionsJson);
          return permissions?.Entities?.includes("Wasl");
        },
      },
      {
        title: "FleetManagement.Dashboard",
        width: "40",
        defaultContent: "",
        sortable: false,
        visible: false,
        createdCell: createdCellCheckbox,
        render: function (data, type, row) {
          const permissions = JSON.parse(row.permissionsJson);
          return permissions?.FleetManagement?.includes("Dashboard");
        },
      },
      {
        title: "FleetManagement.Actions",
        width: "40",
        defaultContent: "",
        sortable: false,
        visible: false,
        createdCell: createdCellCheckbox,
        render: function (data, type, row) {
          const permissions = JSON.parse(row.permissionsJson);
          return permissions?.FleetManagement?.includes("Actions");
        },
      },
      {
        title: "FleetManagement.Actions",
        width: "40",
        defaultContent: "",
        sortable: false,
        visible: false,
        createdCell: createdCellCheckbox,
        render: function (data, type, row) {
          const permissions = JSON.parse(row.permissionsJson);
          return permissions?.FleetManagement?.includes("Actions");
        },
      },
      {
        title: "FleetManagement.Issues",
        width: "40",
        defaultContent: "",
        sortable: false,
        visible: false,
        createdCell: createdCellCheckbox,
        render: function (data, type, row) {
          const permissions = JSON.parse(row.permissionsJson);
          return permissions?.FleetManagement?.includes("Issues");
        },
      },
      {
        title: "FleetManagement.External",
        width: "40",
        defaultContent: "",
        sortable: false,
        visible: false,
        createdCell: createdCellCheckbox,
        render: function (data, type, row) {
          const permissions = JSON.parse(row.permissionsJson);
          return permissions?.FleetManagement?.includes("External");
        },
      },
      {
        title: "FleetManagement.Map",
        width: "40",
        defaultContent: "",
        sortable: false,
        visible: false,
        createdCell: createdCellCheckbox,
        render: function (data, type, row) {
          const permissions = JSON.parse(row.permissionsJson);
          return permissions?.FleetManagement?.includes("Map");
        },
      },
      {
        title: "FleetManagement.DeviceState",
        width: "40",
        defaultContent: "",
        sortable: false,
        visible: false,
        createdCell: createdCellCheckbox,
        render: function (data, type, row) {
          const permissions = JSON.parse(row.permissionsJson);
          return permissions?.FleetManagement?.includes("DeviceState");
        },
      },
      {
        title: "FleetManagement.DeviceCards",
        width: "40",
        defaultContent: "",
        sortable: false,
        visible: false,
        createdCell: createdCellCheckbox,
        render: function (data, type, row) {
          const permissions = JSON.parse(row.permissionsJson);
          return permissions?.FleetManagement?.includes("DeviceCards");
        },
      },
      {
        title: "FleetManagement.DeviceUtilization",
        width: "40",
        defaultContent: "",
        sortable: false,
        visible: false,
        createdCell: createdCellCheckbox,
        render: function (data, type, row) {
          const permissions = JSON.parse(row.permissionsJson);
          return permissions?.FleetManagement?.includes("DeviceUtilization");
        },
      },
      {
        title: "FleetManagement.Sensors",
        width: "40",
        defaultContent: "",
        sortable: false,
        visible: false,
        createdCell: createdCellCheckbox,
        render: function (data, type, row) {
          const permissions = JSON.parse(row.permissionsJson);
          return permissions?.FleetManagement?.includes("Sensors");
        },
      },
      {
        title: "FleetManagement.RawData",
        width: "40",
        defaultContent: "",
        sortable: false,
        visible: false,
        createdCell: createdCellCheckbox,
        render: function (data, type, row) {
          const permissions = JSON.parse(row.permissionsJson);
          return permissions?.FleetManagement?.includes("RawData");
        },
      },
      {
        title: "FleetManagement.Actions",
        width: "40",
        defaultContent: "",
        sortable: false,
        visible: false,
        createdCell: createdCellCheckbox,
        render: function (data, type, row) {
          const permissions = JSON.parse(row.permissionsJson);
          return permissions?.FleetManagement?.includes("Actions");
        },
      },
      {
        title: "FleetManagement.CargoAndRoutes",
        width: "40",
        defaultContent: "",
        sortable: false,
        visible: false,
        createdCell: createdCellCheckbox,
        render: function (data, type, row) {
          const permissions = JSON.parse(row.permissionsJson);
          return permissions?.FleetManagement?.includes("CargoAndRoutes");
        },
      },
      {
        title: "FleetManagement.DriverCards",
        width: "40",
        defaultContent: "",
        sortable: false,
        visible: false,
        createdCell: createdCellCheckbox,
        render: function (data, type, row) {
          const permissions = JSON.parse(row.permissionsJson);
          return permissions?.FleetManagement?.includes("DriverCards");
        },
      },
      {
        title: "FleetManagement.DriverScore",
        width: "40",
        defaultContent: "",
        sortable: false,
        visible: false,
        createdCell: createdCellCheckbox,
        render: function (data, type, row) {
          const permissions = JSON.parse(row.permissionsJson);
          return permissions?.FleetManagement?.includes("DriverScore");
        },
      },
      {
        title: "FleetManagement.Equipment",
        width: "40",
        defaultContent: "",
        sortable: false,
        visible: false,
        createdCell: createdCellCheckbox,
        render: function (data, type, row) {
          const permissions = JSON.parse(row.permissionsJson);
          return permissions?.FleetManagement?.includes("Equipment");
        },
      },
      {
        title: "FleetManagement.DeviceSettingsView",
        width: "40",
        defaultContent: "",
        sortable: false,
        visible: false,
        createdCell: createdCellCheckbox,
        render: function (data, type, row) {
          const permissions = JSON.parse(row.permissionsJson);
          return permissions?.FleetManagement?.includes("DeviceSettingsView");
        },
      },
      {
        title: "FleetManagement.DeviceSettingsEdit",
        width: "40",
        defaultContent: "",
        sortable: false,
        visible: false,
        createdCell: createdCellCheckbox,
        render: function (data, type, row) {
          const permissions = JSON.parse(row.permissionsJson);
          return permissions?.FleetManagement?.includes("DeviceSettingsEdit");
        },
      },
      {
        title: "FleetManagement.OdoView",
        width: "40",
        defaultContent: "",
        sortable: false,
        visible: false,
        createdCell: createdCellCheckbox,
        render: function (data, type, row) {
          const permissions = JSON.parse(row.permissionsJson);
          return permissions?.FleetManagement?.includes("OdoView");
        },
      },
      {
        title: "FleetManagement.OdoEdit",
        width: "40",
        defaultContent: "",
        sortable: false,
        visible: false,
        createdCell: createdCellCheckbox,
        render: function (data, type, row) {
          const permissions = JSON.parse(row.permissionsJson);
          return permissions?.FleetManagement?.includes("OdoEdit");
        },
      },
      {
        title: "FleetManagement.EngineHourView",
        width: "40",
        defaultContent: "",
        sortable: false,
        visible: false,
        createdCell: createdCellCheckbox,
        render: function (data, type, row) {
          const permissions = JSON.parse(row.permissionsJson);
          return permissions?.FleetManagement?.includes("EngineHourView");
        },
      },
      {
        title: "FleetManagement.EngineHourEdit",
        width: "40",
        defaultContent: "",
        sortable: false,
        visible: false,
        createdCell: createdCellCheckbox,
        render: function (data, type, row) {
          const permissions = JSON.parse(row.permissionsJson);
          return permissions?.FleetManagement?.includes("EngineHourEdit");
        },
      },
      {
        title: "FleetManagement.WorkingHours",
        width: "40",
        defaultContent: "",
        sortable: false,
        visible: false,
        createdCell: createdCellCheckbox,
        render: function (data, type, row) {
          const permissions = JSON.parse(row.permissionsJson);
          return permissions?.FleetManagement?.includes("WorkingHours");
        },
      },
      {
        title: "FleetManagement.Warehouses",
        width: "40",
        defaultContent: "",
        sortable: false,
        visible: false,
        createdCell: createdCellCheckbox,
        render: function (data, type, row) {
          const permissions = JSON.parse(row.permissionsJson);
          return permissions?.FleetManagement?.includes("Warehouses");
        },
      },
      {
        title: "FleetManagement.Audit",
        width: "40",
        defaultContent: "",
        sortable: false,
        visible: false,
        createdCell: createdCellCheckbox,
        render: function (data, type, row) {
          const permissions = JSON.parse(row.permissionsJson);
          return permissions?.FleetManagement?.includes("Audit");
        },
      },
      {
        title: "FleetManagement.Jobs",
        width: "40",
        defaultContent: "",
        sortable: false,
        visible: false,
        createdCell: createdCellCheckbox,
        render: function (data, type, row) {
          const permissions = JSON.parse(row.permissionsJson);
          return permissions?.FleetManagement?.includes("Jobs");
        },
      },
      {
        title: "FleetManagement.Tags",
        width: "40",
        defaultContent: "",
        sortable: false,
        visible: false,
        createdCell: createdCellCheckbox,
        render: function (data, type, row) {
          const permissions = JSON.parse(row.permissionsJson);
          return permissions?.FleetManagement?.includes("Tags");
        },
      },
      {
        title: "FleetManagement.Trips",
        width: "40",
        defaultContent: "",
        sortable: false,
        visible: false,
        createdCell: createdCellCheckbox,
        render: function (data, type, row) {
          const permissions = JSON.parse(row.permissionsJson);
          return permissions?.FleetManagement?.includes("Trips");
        },
      },
      {
        title: "FleetManagement.Messages",
        width: "40",
        defaultContent: "",
        sortable: false,
        visible: false,
        createdCell: createdCellCheckbox,
        render: function (data, type, row) {
          const permissions = JSON.parse(row.permissionsJson);
          return permissions?.FleetManagement?.includes("Messages");
        },
      },
      {
        title: "FleetManagement.Notifications",
        width: "40",
        defaultContent: "",
        sortable: false,
        visible: false,
        createdCell: createdCellCheckbox,
        render: function (data, type, row) {
          const permissions = JSON.parse(row.permissionsJson);
          return permissions?.FleetManagement?.includes("Notifications");
        },
      },
      {
        title: "FleetManagement.Episodes",
        width: "40",
        defaultContent: "",
        sortable: false,
        visible: false,
        createdCell: createdCellCheckbox,
        render: function (data, type, row) {
          const permissions = JSON.parse(row.permissionsJson);
          return permissions?.FleetManagement?.includes("Episodes");
        },
      },
      {
        title: "FleetManagement.GeofenceStates",
        width: "40",
        defaultContent: "",
        sortable: false,
        visible: false,
        createdCell: createdCellCheckbox,
        render: function (data, type, row) {
          const permissions = JSON.parse(row.permissionsJson);
          return permissions?.FleetManagement?.includes("GeofenceStates");
        },
      },
      {
        title: "FleetManagement.ImmobilizeVehicle",
        width: "40",
        defaultContent: "",
        sortable: false,
        visible: false,
        createdCell: createdCellCheckbox,
        render: function (data, type, row) {
          const permissions = JSON.parse(row.permissionsJson);
          return permissions?.FleetManagement?.includes("ImmobilizeVehicle");
        },
      },
      {
        title: "FleetManagement.Reporting",
        width: "40",
        defaultContent: "",
        sortable: false,
        visible: false,
        createdCell: createdCellCheckbox,
        render: function (data, type, row) {
          const permissions = JSON.parse(row.permissionsJson);
          return permissions?.FleetManagement?.includes("Reporting");
        },
      },
      {
        title: "FleetManagement.ExportImport",
        width: "40",
        defaultContent: "",
        sortable: false,
        visible: false,
        createdCell: createdCellCheckbox,
        render: function (data, type, row) {
          const permissions = JSON.parse(row.permissionsJson);
          return permissions?.FleetManagement?.includes("ExportImport");
        },
      },
      {
        title: "FleetManagement.Log",
        width: "40",
        defaultContent: "",
        sortable: false,
        visible: false,
        createdCell: createdCellCheckbox,
        render: function (data, type, row) {
          const permissions = JSON.parse(row.permissionsJson);
          return permissions?.FleetManagement?.includes("Log");
        },
      }
    );

    this.dtOptions = {
      buttons: getGridButtons(
        this.commonExportOptions,
        "assetTypes_overview",
        this.translateService.instant("menu.deviceoverview"),
        this.colorService
      ),
      pagingType: "simple_numbers",
      serverSide: true,
      processing: true,
      searchDelay: 500,
      deferRender: true,
      scrollX: true,
      colReorder: { fixedColumnsLeft: 1 },
      deferLoading: 0,
      stateSave: true,
      stateSaveCallback: function (settings, data) {
        that.saveState(that.constructorName, data);
      },
      stateLoadCallback: function (_, callback) {
        (async () => {
          try {
            const columnSettings = await that.loadState(that.constructorName);
            that.searchTerm = columnSettings && columnSettings.search && columnSettings.search.search;
            return columnSettings;
          } catch (e) {
            that.error = {};
            that.error.error = e;
            that.error.statusText = "Error fetching column settings";

            return null;
          }
        })().then((result) => {
          callback(result);
        });
      },
      order: [[2, "asc"]],
      ajax: {
        beforeSend: () => {
          that.drawFilterRow();

          $(".dataTables_info").html(this.translateService.instant("grid.loadingData"));
        },
        url: that.userRoleService.getPagingUrl(),
        type: "POST",
        headers: {
          Authorization: "Bearer " + that.token,
        },
      },
      initComplete: function (settings, json) {
        console.log("init complete");
        that.checkFilters();
        that.drawFilterRow();
        that.loading = false;
      },
      colVis: {
        restore: this.translateService.instant("general.restore"),
        showAll: this.translateService.instant("general.showAll"),
        showNone: this.translateService.instant("general.hideAll"),
        exclude: ["id", "id_export"],
      },
      columns: this.columns,
      pageLength: 17,
      lengthMenu: [
        [10, 17, 25, 50, -1],
        [10, 17, 25, 50, this.translateService.instant("general.all")],
      ],
      language: getGridLanguages(this.translateService),
    };
  }
}
