<div *ngIf="permissions['FleetManagement_Actions']">
    <ngx-loading [show]="saving"></ngx-loading>
    <!-- Custom content -->
    <ng-content> </ng-content>

    <div dropdown style="position: relative" [isDisabled]="gridSelection.length == 0">
        <button
            id="button-basic"
            dropdownToggle
            type="button"
            class="btn dropdown-toggle"
            (click)="setGridSelection()"
            [ngClass]="gridSelection.length > 0 ? 'btn-primary' : 'btn-info'"
            aria-controls="dropdown-basic"
            style="min-width: 100px; margin: 0px"
        >
            <i class="fa fa-fw fa-bolt"></i> ({{ gridSelection.length }}) <span class="caret"></span>
        </button>
        <ul
            id="dropdown-basic"
            *dropdownMenu
            class="dropdown-menu dropdown-menu-fix d dropdown-menu-right"
            role="menu"
            aria-labelledby="button-basic"
            style="min-width: 200px"
        >
            <li
                [ngClass]="action.disabled ? 'disabled' : ''"
                role="menuitem"
                *ngFor="let action of batchActions | orderBy : 'name' : false"
            >
                <a
                    class="dropdown-item"
                    href="javascript:void(0)"
                    (click)="!action.disabled ? showModal(action) : null"
                >
                    <i
                        class="fas fa-fw"
                        [ngClass]="action.icon ? action.icon : 'fa-angle-right'"
                        style="padding-right: 5px"
                    ></i>
                    {{ "enums.batchAction." + action.batchActionType | translate }}
                </a>
            </li>
        </ul>
    </div>
</div>

<div style="display: none" id="selectorHolder">
    <i
        id="grid_select_or"
        class="far fa-fw fa-lg"
        (click)="handleSelection()"
        [ngClass]="{
            'fa-square': gridSelection.length == 0,
            'fa-check-square': gridSelection.length == tableIdsSelection.length && gridSelection.length > 0,
            'fa-minus-square': gridSelection.length != tableIdsSelection.length && gridSelection.length > 0
        }"
        style="cursor: pointer"
    ></i>
</div>

<div
    (onHidden)="hideModal()"
    bsModal
    [config]="{ backdrop: true, ignoreBackdropClick: true }"
    #batchModal="bs-modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
>
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title pull-left">
                    <i class="fa fa-fw fa-bolt"></i>
                    {{ "general.bulkAction" | translate }}:
                    {{ "enums.batchAction." + selectedAction?.batchActionType | translate }}
                </h5>
                <button type="button" class="close float-right" aria-label="Close" (click)="hideModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" *ngIf="selectedAction == null">
                {{ "general.defaultBatchDescription" | translate }}
            </div>
            <div class="popup-content" #contentWrapper>
                <fh-handle-tasks
                    #handleActions
                    [selectedAction]="selectedAction"
                    [batchProcessing]="true"
                    [entityTypes]="entityTypes"
                    (onFinish)="process($event)"
                ></fh-handle-tasks>
            </div>
        </div>
    </div>
</div>
