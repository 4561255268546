<div class="col-lg-12">
    <div class="horizontalMenuWrapper">
        <ul>
            <li [ngClass]="{active: activeRoute('Home/Default')}">
                <a [routerLink]="['/Home/Default']">
                    <i class="fas fa-fw fa-home"></i>
                    <div>{{ 'general.home' | translate }}</div>
                </a>
            </li>
            <!-- <li [ngClass]="{active: activeRoute('Home/History')}">
                <a [routerLink]="['/Home/History']">
                    <i class="fas fa-fw fa-history"></i>
                    <div>{{ 'general.history' | translate }}</div>
                </a>
            </li> -->
            <li [ngClass]="{'active': activeRoute('Home/Live')}">
                <a [routerLink]="['/Home/Live/']">
                    <i class="fas fa-fw fa-heartbeat"></i>
                    <div>{{ 'general.live' | translate }}</div>
                </a>
            </li>
        </ul>
    </div>
    <div class="horizontalMenuWrapper" style="float: right">
        <ul>
            <ng-content></ng-content>
        </ul>
    </div>
</div>
<div style="clear: both"></div>
