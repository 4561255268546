import {
  Component,
  EventEmitter,
  Output,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnInit,
  Input,
  OnChanges,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { DeviceService } from "app/services/device/device.service";
import { Device, DeviceSettingChange } from "app/models/device.model";

@Component({
  selector: "fh-batch-devicesettingchanges",
  templateUrl: "applyDeviceSettingChanges.template.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BatchDeviceSettingChangesComponent implements OnInit, OnChanges {
  @Input() gridSelection = [];
  @Output() onFinish = new EventEmitter();

  selectedDevice = new Device();
  deviceSettingChange = new DeviceSettingChange();
  form = new FormGroup({});
  step: number;
  logItem;

  deviceImeiDict = {};

  groupForm = new FormGroup({
    customCommandId: new FormControl(null, Validators.required),
    wakeUpDevice: new FormControl(false, Validators.required),
  });

  constructor(private cd: ChangeDetectorRef, private deviceService: DeviceService) {
    this.step = 1;
  }

  ngOnInit(): void {
    this.deviceService.getDeviceData(this.gridSelection).subscribe((devices) => {
      for (const device of devices) {
        if (device === null) {
          continue;
        }
        this.deviceImeiDict[device.id] = device.unitId;
      }
    });

    this.deviceService.getDeviceById(this.gridSelection[0]).subscribe((device) => {
      this.selectedDevice = device;
      this.cd.markForCheck();
    });

    this.deviceSettingChange.deviceId = Number(this.gridSelection[0]);
  }

  ngOnChanges(): void {
    this.cd.markForCheck();
  }

  initFinish() {
    console.log(1);
  }

  onCheckOutFinish() {
    this.onFinish.emit({
      status: "success",
      object: {
        settingId: this.deviceSettingChange.settingId,
        settingValue: this.deviceSettingChange.settingValue,
        wakeUpDevice: this.deviceSettingChange.wakeUpDevice,
      },
      log: this.logItem,
    });

    this.step = 1;
  }
}
