import { KeyValue } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { DistanceUnitService } from "app/common/distanceunit.service";
import { DeviceAuxiliary, DeviceOutput, EntityType } from "app/common/enums";
import { colorArray2, getIconPath, roundAsNumber, roundSeconds } from "app/common/globals";
import { StorageHelper } from "app/common/storagehelper";
import { DeviceSettingChange } from "app/models/device.model";
import { Trip } from "app/models/trip.model";
import { AuthenticationService } from "app/services/authentication/authentication.service";
import { DeviceSettingChangeService } from "app/services/device/deviceSettingChange.service";
import { DriverService } from "app/services/driver/driver.service";
import { FleetOverviewStoreService } from "app/services/fleetoverview/fleetoverview-store.service";
import { TripService } from "app/services/trip/trip.service";

// Moment timezone
import Moment from "moment-timezone";
window["moment"] = Moment;

@Component({
  selector: "fh-map-sidebar",
  templateUrl: "sidebar.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FleetOverviewSidebarComponent implements OnInit, OnChanges {
  @Input() selectedDevice;
  @Input() selectedDeviceExtended;
  @Input() selectedDeviceUtilization;
  @Input() loadingSidebar;

  tab: string = "DeviceState";

  deviceState;
  showUnMapped = false;

  showUtilization;
  constructorName = "FleetOverviewSidebarComponent";
  permissions: {};

  tagType = EntityType.Device.valueOf();

  hasDriverTagsEnabled = true;
  immobilizationStatus = 1;
  thePort;
  immobilizationType;
  theSettingId;
  activeDsc: any;
  loading = false;

  translatedKmh: any = "km/h";
  translatedKm: any = "km";

  driversMap;
  assetWithTrips: { id: any; name: any; iconId: any; loading: boolean; trips: Trip[] };
  daterangepickerModel = [];
  timezoneIana: string;
  selectedAsset: KeyValue<any, any>;
  loadingTrips: boolean;
  cachedTrips = new Map<number, L.FeatureGroup<any>>();
  colorArray = colorArray2;

  driver;

  constructor(
    private authenticationService: AuthenticationService,
    private driverService: DriverService,
    private distance: DistanceUnitService,
    private cd: ChangeDetectorRef,
    private dsc: DeviceSettingChangeService,
    private translateService: TranslateService,
    private storageHelper: StorageHelper,
    private tripService: TripService,
    private fleetOverviewStoreService: FleetOverviewStoreService
  ) {
    this.permissions = authenticationService.permissions;
    this.driversMap = fleetOverviewStoreService.driversMap;

    this.timezoneIana = authenticationService.getTimeZoneIana();

    this.daterangepickerModel = [
      Moment().tz(this.timezoneIana).subtract(14, "days").startOf("day").toDate(),
      Moment().tz(this.timezoneIana).subtract(0, "days").startOf("day").toDate(),
    ];

    this.fleetOverviewStoreService.deviceState$.subscribe((states) => {
      this.parseChanges();
    });
  }

  ngOnChanges() {
    this.parseChanges();
  }

  parseChanges() {
    const that = this;
    this.deviceState = this.selectedDevice?.theMarker?.data?.deviceState;

    if (this.selectedDeviceExtended != null) {
      this.getCurrentStatus();
      this.getDeviceDriverSettings();

      if (this.selectedDevice) {
        this.selectedDevice.geofences = [];
        var geofences = this.deviceState?.insideGeofences;
        if (geofences) {
          Object.keys(geofences).forEach((geofenceId) => {
            const geofenceName = this.fleetOverviewStoreService.geofencesMap.get(+geofenceId);
            if (geofenceName) {
              this.selectedDevice?.geofences.push({
                id: +geofenceId,
                name: geofenceName,
                entered: geofences[geofenceId],
              });
            }
          });
        }
      }

      // Checking for scanned Driver
      let driverBinding = that.fleetOverviewStoreService.driverBindings.get(this.deviceState?.id);
      if (driverBinding) {
        let driver = driverBinding();
        this.driver = driver;
      }
    }

    if (this.tab === "Trips") {
      this.getTrips();
    }
  }

  ngOnInit(): void {
    this.translateService.get("general.date").subscribe((data) => {
      this.translatedKm = this.translateService.instant(this.distance.getDistanceUnit());
      this.translatedKmh = this.translateService.instant(this.distance.getDistanceUnitPerHour());
      this.cd.markForCheck();
    });
  }

  changeTab(clickedTab: string) {
    this.tab = clickedTab;

    if (clickedTab === "Trips") {
      this.getTrips();
    }
  }

  getIcon(iconId) {
    return getIconPath(iconId)[2];
  }

  actualRound(value, decimals) {
    return roundAsNumber(value, decimals);
  }

  actualRoundSeconds(value) {
    return roundSeconds(value, true);
  }

  // Immobilisation

  // Driver tags
  getDeviceDriverSettings() {
    const aux = this.selectedDeviceExtended.settings.deviceAuxiliary;

    if (aux.filter((x) => (BigInt(x.id) & BigInt(DeviceAuxiliary.DriverFlags)) > 0).length > 0) {
      this.hasDriverTagsEnabled = true;
    }
  }

  getCurrentStatus() {
    this.thePort = null;
    this.immobilizationType = null;
    this.theSettingId = null;

    const outputPorts = this.selectedDeviceExtended?.settings?.outputPorts;

    if (outputPorts.filter((x) => x.byte === DeviceOutput["OnCommand Starter Interrupt"].toString()).length > 0) {
      this.immobilizationType = "OnCommandStarterInterrupt";
      this.theSettingId = 38;

      this.thePort = outputPorts.filter((x) => x.byte === DeviceOutput["OnCommand Starter Interrupt"].toString())[0].id;
    }

    if (outputPorts.filter((x) => x.byte === DeviceOutput["Automatic Starter Interrupt"].toString()).length > 0) {
      this.immobilizationType = "AutomaticCommandStarterInterrupt";
      this.theSettingId = 39;

      this.thePort = outputPorts.filter((x) => x.byte === DeviceOutput["Automatic Starter Interrupt"].toString())[0].id;
    }

    if (this.thePort) {
      // Get the current status
      this.dsc.getDeviceSettingChangesByDeviceId(this.selectedDeviceExtended.id).subscribe((result) => {
        const currentStatus = result
          .filter((x) => x.settingId === this.theSettingId && x.status === 2)
          .sort((a, b) => (a.timestamp.unix() > b.timestamp.unix() ? -1 : 1));
        const activeDsc = result
          .filter(
            (x) =>
              x.settingId === this.theSettingId && x.status !== 2 && x.status !== 3 && x.status !== 5 && x.status !== 6
          )
          .sort((a, b) => (a.timestamp.unix() > b.timestamp.unix() ? -1 : 1));

        if (currentStatus.length > 0) {
          if (currentStatus[0].value.toString() === "1") {
            this.immobilizationStatus = 2;
          } else {
            this.immobilizationStatus = 1;
          }
        }

        if (activeDsc.length > 0) {
          const currentDsc = activeDsc[0];
          this.activeDsc = currentDsc;

          // scheduled
          if (currentDsc.value.toString() === "1") {
            this.immobilizationStatus = 3;
          } else {
            this.immobilizationStatus = 4;
          }
        }
        // ImmobilizationStatus
        this.loading = false;
        this.cd.markForCheck();
      });
    }
  }

  refresh() {
    this.loading = true;
    this.cd.markForCheck();

    this.getCurrentStatus();
  }

  abort() {
    this.loading = true;
    this.cd.markForCheck();

    this.dsc.deleteDeviceSettingChanges(this.activeDsc.id).subscribe(
      (result) => {
        this.immobilizationStatus = 1;

        this.loading = false;
        this.cd.markForCheck();

        setTimeout(() => {
          this.refresh();
        }, 3000);
      },
      (error) => {
        this.loading = false;
        this.cd.markForCheck();
      }
    );
  }

  immobilise() {
    this.loading = true;
    this.cd.markForCheck();

    const change = new DeviceSettingChange();
    change.deviceId = +this.selectedDeviceExtended.id;
    change.settingId = this.theSettingId;
    change.settingValue = "1";

    this.dsc.sendDeviceSettingChanges(change).subscribe(
      (result) => {
        this.loading = false;
        this.cd.markForCheck();

        setTimeout(() => {
          this.refresh();
        }, 3000);
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  unimmobilise() {
    this.loading = false;

    const change = new DeviceSettingChange();
    change.deviceId = +this.selectedDeviceExtended.id;
    change.settingId = this.theSettingId;
    change.settingValue = "0";

    this.dsc.sendDeviceSettingChanges(change).subscribe(
      (result) => {
        this.loading = false;

        setTimeout(() => {
          this.refresh();
        }, 3000);
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  finishTask(event) {
    console.log(event);
  }

  getTrips() {
    console.log("Getting trips");

    if (!this.selectedDevice.id) {
      return;
    }

    this.loadingTrips = true;
    this.tripService
      .getTripsWithGapEpisodes(
        this.selectedDevice.id,
        null,
        Moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf("day"),
        Moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf("day")
      )
      .subscribe(
        (trips) => {
          (<any>trips).sort((a, b) => new Date(b.beginDateTime).getTime() - new Date(a.beginDateTime).getTime());

          this.selectedAsset = {
            key: this.selectedDevice.id,
            value: {
              id: this.selectedDevice.id,
              name: this.selectedDevice.name,
              iconId: this.selectedDevice.iconId,
              loading: false,
              trips,
            },
          };
          this.loadingTrips = false;

          this.cd.detectChanges();
        },
        (error) => {
          this.loadingTrips = false;
          // this.error = error;
          this.cd.detectChanges();
        }
      );
  }

  playTrip(trip) {
    this.fleetOverviewStoreService.playTrip.next([trip.assetId, trip.locations]);
  }

  openTrip(trip, ident) {
    if (trip.tripMethod === 5) {
      return;
    }

    trip.isOpen = !trip.isOpen;

    if (trip.locations !== undefined) {
      trip.loading = false;

      this.cd.detectChanges();
      return;
    }

    trip.loading = true;
    this.cd.detectChanges();

    this.tripService.getTripDetails(trip.id).subscribe(
      (result) => {
        const locations = result.messages.sort((a, b) => b.timestamp - a.timestamp);
        trip.locations = locations;

        const isOngoing = trip.tripType === 3 || trip.tripType === 4;

        const featureGroup = this.cachedTrips.get(trip.id);

        if (featureGroup !== undefined) {
          this.fleetOverviewStoreService.removeTrip.next([featureGroup]);
        }

        trip.concatenatedEvents = result.concatenatedEvents;

        const icon = null;

        const tripFeatureGroup = this.tripService.drawTrip(result, ident, this.colorArray, isOngoing, icon);

        this.cachedTrips.set(trip.id, tripFeatureGroup);

        trip.plotted = true;
        trip.loading = false;

        this.cd.detectChanges();

        this.fleetOverviewStoreService.selectedTrip.next([tripFeatureGroup]);
      },
      (_) => {
        trip.loading = false;
        this.cd.detectChanges();
      }
    );
  }

  changeTripVisibility(trip, ident, plotted) {
    if (!("plotted" in trip)) {
      this.openTrip(trip, ident);
      return;
    }

    if (trip.plotted === plotted) {
      return;
    }

    if (plotted === undefined) {
      trip.plotted = !trip.plotted;
    } else {
      trip.plotted = plotted;
    }

    const featureGroup = this.cachedTrips.get(trip.id);

    if (featureGroup === undefined) {
      return;
    }

    if (trip.plotted === false) {
      this.fleetOverviewStoreService.removeTrip.next([featureGroup]);
    } else {
      this.fleetOverviewStoreService.selectedTrip.next([featureGroup]);
    }

    this.cd.detectChanges();
  }

  openStop(trip, ident, entity) {
    if (trip.tripMethod === 5) {
      return;
    }

    trip.isOpen = !trip.isOpen;

    if (trip.episodes !== undefined) {
      trip.loading = false;

      this.cd.detectChanges();
      return;
    }

    trip.loading = true;
    this.cd.detectChanges();

    let episodesMethod = this.tripService.getEpisodes(entity.value.id, trip.beginDateTime, trip.endDateTime);
    if (entity.key.startsWith(EntityType[EntityType.Driver])) {
      episodesMethod = this.driverService.getEpisodes(
        entity.value.id,
        trip.beginDateTime,
        trip.endDateTime,
        null,
        null
      );
    }

    episodesMethod.subscribe(
      (result) => {
        trip.episodes = result;
        trip.loading = false;
        this.cd.detectChanges();
      },
      (_) => {
        trip.loading = false;
        this.cd.detectChanges();
      }
    );
  }

  displayLocation(asset, location, geofence, episode) {
    // this.fleetOverviewNavigationComponent.displayLocation(asset, location, geofence, episode);
  }
}
