import {
  Component,
  AfterViewInit,
  Input,
  ElementRef,
  ViewChild,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from "@angular/core";

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { DriverService } from "app/services/driver/driver.service";

const noop = () => {};

@Component({
  selector: "fh-driver-input",
  templateUrl: "./driverSelector.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: DriverInputComponent }],
})
export class DriverInputComponent implements OnChanges, OnInit, AfterViewInit, ControlValueAccessor {
  @Input() placeholder: string;
  @Input() clearable = true;
  @Input() selectedAccountId;
  @Input() autoSelect = true;
  @Input() readonly = false;

  @Output() onChanged = new EventEmitter();

  drivers = [];

  loadingDrivers = false;

  @ViewChild("input") el: ElementRef;

  private _value: string;
  private _onChange: (_: any) => void = noop;

  get value(): any {
    return this._value;
  }

  set value(v: any) {
    if (v !== this._value) {
      this._value = v;
      this._onChange(v);
      this.cd.markForCheck();
    }
  }

  constructor(private driverService: DriverService, private cd: ChangeDetectorRef) {}

  driversChanged() {
    this.onChanged.emit(this.value);
    this.loadingDrivers = false;
    this.cd.markForCheck();
  }

  ngOnChanges() {
    setTimeout(() => {
      // Get accounts again
      this.loadDrivers();
    }, 0);
  }

  loadDrivers() {
    this.drivers = [];
    this.cd.markForCheck();

    if (this.selectedAccountId) {
      this.loadingDrivers = true;
      this.cd.markForCheck();

      if (this.selectedAccountId && this.selectedAccountId !== 0) {
        this.driverService.getDriversByAccount(this.selectedAccountId).subscribe((result) => {
          result = result.sort((a, b) => (a.name < b.name ? -1 : 1));

          this.drivers = result;

          // Autoselect
          if (!this.readonly && this.drivers.length === 1 && this.autoSelect) {
            console.log("Setting default to " + this.drivers[0].id);
            this.value = this.drivers[0].id;
            this.cd.markForCheck();
          }

          this.loadingDrivers = false;
          this.cd.markForCheck();
        });
      } else {
        this.loadingDrivers = false;
        this.cd.markForCheck();
      }
    }
  }

  ngOnInit() {}

  ngAfterViewInit() {
    const __this = this;
  }

  writeValue(value: any) {
    this._value = value;
    this.cd.markForCheck();
  }

  registerOnChange(fn: (value: any) => void) {
    this._onChange = fn;
    this.cd.markForCheck();
  }

  registerOnTouched(fn: any) {
    this.cd.markForCheck();
  }
}
