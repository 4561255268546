<ng-select
    [loading]="loadingHierarchyGroups"
    ngDefaultControl
    [searchable]="true"
    [clearable]="clearable"
    [disabled]="hierarchyGroups.length == 0"
    [readonly]="readonly"
    placeholder="{{placeholder}} ({{hierarchyGroups.length}})"
    #input
    [(ngModel)]="value"
    class="form-control table-inline"
    (ngModelChange)="hierarchyGroupChanged()"
>
    <ng-option *ngFor="let group of hierarchyGroups | orderBy : 'name':false" [value]="group.groupId">
        <i class="fas fa-fw fa-layer-group"></i> {{ group.groupName }}</ng-option
    >
</ng-select>
