import { ChangeDetectorRef, Component, Input } from "@angular/core";
import { ControlContainer, NgForm } from "@angular/forms";
import { FormMode } from "app/common/enums";
import { UserContact } from "app/models/userContact.model";

@Component({
  selector: "fh-user-contact-selector",
  templateUrl: "userContactSelector.component.html",
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class UserContactSelectorComponent {
  @Input() loading = false;
  @Input() formMode: any = FormMode.read;
  @Input() userContacts: UserContact[];

  isDisplayOnly = false;

  // Pagination
  filter;
  currentPage;

  constructor(private cd: ChangeDetectorRef) {}
}
