<ng-select
    [loading]="loadingProjects"
    ngDefaultControl
    [searchable]="true"
    [clearable]="clearable"
    style="height: unset"
    [disabled]="projects.length == 0"
    [readonly]="readonly"
    [compareWith]="compareFn"
    placeholder="{{ placeholder }} ({{ projects.length }})"
    #input
    [multiple]="multiple"
    [(ngModel)]="value"
    class="form-control"
    [ngClass]="tableInline ? 'table-inline' : ''"
    (ngModelChange)="projectChanged()"
>
    <ng-option *ngFor="let project of projects | orderBy : 'name' : false" [value]="project.id">
        <i class="fas fa-fw fa-gavel"></i> {{ project.name }} ({{ project.itemCount }})</ng-option
    >
</ng-select>
<div style="clear: both"></div>
