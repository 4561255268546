import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UserRole } from "app/models/userRole.model";
import { Observable, catchError, map, of, throwError } from "rxjs";
import { AuthenticationService } from "../authentication/authentication.service";
import { LoggingService } from "../logging/logging.service";

@Injectable()
export class UserRoleService {
  token: string;
  url = "";
  userRoles: UserRole[] = [];

  constructor(
    private http: HttpClient,
    private loggingService: LoggingService,
    private authenticationService: AuthenticationService
  ) {
    this.url = this.authenticationService.getWebserviceURL("userRole");
  }

  getPagingUrl() {
    return this.url + "Paging";
  }

  getUserRoleById(id: any): Observable<UserRole> {
    return this.http.get(this.url + id, { headers: this.authenticationService.headers }).pipe(
      map((data) => {
        return this.parseReponseDetails(data);
      }),
      catchError(this.handleError)
    );
  }

  getUserRoles(accountId?: number, setDefinate = true): Observable<UserRole[]> {
    if (setDefinate && this.userRoles.length !== 0) {
      console.log("Returning userRoles from cache.");
      return of(this.userRoles);
    }

    return this.http
      .get(this.url + (accountId ? `?accountId=${accountId}` : ""), { headers: this.authenticationService.headers })
      .pipe(
        map((data) => {
          const parsedResponse = this.parseResponse(data);
          this.userRoles = parsedResponse;
          return parsedResponse;
        }),
        catchError(this.handleError)
      );
  }

  saveUserRole(userRole: UserRole): Observable<any> {
    console.log("save userRole");
    return this.http
      .post(this.url, userRole, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  updateUserRole(userRole: UserRole): Observable<any> {
    console.log("update userRole");
    return this.http
      .put(this.url + userRole.id, userRole, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  deleteUserRole(userRole: UserRole): Observable<any> {
    console.log("delete userRole");
    return this.http
      .delete(this.url + userRole.id, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  parseResponse(json: any): UserRole[] {
    this.loggingService.log(this.constructor.name, "Retrieved " + json?.length + " UserRoles.");

    const ident = 1;
    const assets: UserRole[] = [];

    json.forEach((element) => {
      const asset = this.parseReponseDetails(element);
      assets.push(asset);
    });

    return assets;
  }

  parseReponseDetails(item) {
    const userRole = new UserRole();
    userRole.id = item.id;
    userRole.name = item.name;
    userRole.resellerId = item.resellerId;
    userRole.accountId = item.accountId;
    userRole.companyName = item.companyName;
    userRole.resellerDescription = item.resellerDescription;
    userRole.permissions = item.permissions;
    userRole.reportPermissions = item.reportPermissions;

    return userRole;
  }

  private handleError(error: Response) {
    return throwError(() => error);
  }
}
