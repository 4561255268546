<div class="col-lg-12">
    <div class="horizontalMenuWrapper">
        <ul>
            <li [ngClass]="{'active': activeRoute('/Reporting/Dashboard')}">
                <a href="/#/Reporting/Dashboard"
                    ><i class="fas fa-fw fa-user-chart"></i>
                    <div>{{ 'menu.dashboard' |translate }}</div>
                </a>
            </li>
            <li [ngClass]="{'active': activeRoute('/Reporting/Executions') || activeRoute('/ReportDetails')}">
                <a href="/#/Reporting/Executions"
                    ><i class="fas fa-fw fa-file-chart-pie"></i>
                    <div>{{ 'menu.executions' |translate }}</div>
                </a>
            </li>
            <li
                [ngClass]="{'active': activeRoute('/Reporting/Subscriptions') || activeRoute('/ReportSubscriptionDetails') || isSubscriptionDetails()}"
            >
                <a href="/#/Reporting/Subscriptions"
                    ><i class="fas fa-fw fa-clock"></i>
                    <div>{{ 'general.subscriptions' |translate }}</div>
                </a>
            </li>
            <li [ngClass]="{'active': activeRoute('/Reporting/Templates')}">
                <a href="/#/Reporting/Templates"
                    ><i class="fas fa-fw fa-gopuram"></i>
                    <div>{{ 'general.templates' |translate }}</div>
                </a>
            </li>
        </ul>
    </div>
    <div class="horizontalMenuWrapper" style="float: right">
        <ul>
            <ng-content></ng-content>
        </ul>
    </div>
    <div style="clear: both"></div>
</div>
<div class="col-lg-12">
    <div class="horizontalMenuSubWrapper" style="padding: 5px" *ngIf="isSubscriptionDetails()">
        <ul>
            <li [ngClass]="{active: activeRoute('ReportSubscriptionDetails/Index')}">
                <a [routerLink]="['/ReportSubscriptionDetails/Index/', activeId]">
                    <i class="fas fa-fw fa-clock"></i>
                    {{ 'menu.dashboard' | translate }}
                </a>
            </li>
            <li
                *ngIf="permissions['FleetManagement_Log']"
                [ngClass]="{active: activeRoute('ReportSubscriptionDetails/Log')}"
            >
                <a [routerLink]="['/ReportSubscriptionDetails/Log/', activeId]">
                    <i class="fas fa-fw fa-file-alt"></i>
                    {{ 'menu.log' | translate }}
                </a>
            </li>
        </ul>
    </div>
    <div style="clear: both"></div>
</div>
