import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormMode } from "app/common/enums";

import { AuthenticationService } from "app/services/authentication/authentication.service";

// Moment timezone
import Moment from "moment-timezone";

import { AppUser } from "app/models/user.model";

import { ClientAnnouncementService } from "app/services/announcements/clientAnnouncements";
import { ClientAnnouncement } from "app/models/clientAnnouncement.model";

window["moment"] = Moment;

@Component({
  selector: "fh-fh-service-announcement-details",
  templateUrl: "clientAnnouncementDetails.template.html",
})
export class ClientAnnouncementsDetailsViewComponent implements OnInit {
  sub: any;
  clientAnnouncement: ClientAnnouncement;
  device: any;
  formMode = FormMode.read;

  loading = false;
  saving = false;

  filter;

  error: any;
  success: any;

  permissions: {};

  users: AppUser[];

  loadingDeviceCount = false;
  chartDeviceCount: any;

  // Pagination
  totalItems = 0;
  currentPage = 1;
  smallnumPages = 0;
  deviceCountData = [];
  distanceChartData = [];
  loadingCount = false;

  permissionName = "UserContacts_View";

  constructor(
    private router: Router,
    private cd: ChangeDetectorRef,
    private authentication: AuthenticationService,
    private clientAnnouncementsService: ClientAnnouncementService,
    private route: ActivatedRoute
  ) {
    this.permissions = this.authentication.permissions;
  }

  ngOnInit() {
    this.loading = true;
    this.sub = this.route.params.subscribe((params) => {
      const id = params["id"];

      this.getClientAnnouncementInfo(id);
    });
  }

  // Form

  onDelete() {
    this.loading = true;

    this.clientAnnouncementsService.deleteClientAnnouncement(this.clientAnnouncement).subscribe({
      next: (result) => {
        this.error = null;
        this.success = {
          statusText: "Success",
          success: "Service announcement is successfully deleted.",
        };

        setTimeout(() => {
          this.router.navigate(["/Settings/ClientAnnouncements/Overview"]);
        }, 3000);
      },
      error: (error) => {
        this.success = null;
        this.error = error;
        this.loading = false;
        this.cd.markForCheck();
      },
    });
  }

  onSave() {
    this.saving = true;

    this.clientAnnouncementsService.updateClientAnnouncement(this.clientAnnouncement).subscribe({
      next: (result) => {
        this.error = null;
        this.success = {
          statusText: "Success",
          success: "Service announcement is successfully updated.",
        };

        this.saving = false;
        this.cd.markForCheck();

        this.setFormMode(FormMode.read);
      },
      error: (error) => {
        this.saving = false;
        this.success = null;
        this.error = error;
        this.cd.markForCheck();
      },
    });
  }

  setFormMode(mode) {
    this.formMode = mode;

    if (this.formMode === FormMode.read) {
      this.loading = true;
      this.getClientAnnouncementInfo();
    }
  }

  getClientAnnouncementInfo(id = 0) {
    this.loading = true;
    this.cd.markForCheck();

    if (id === 0) {
      id = this.clientAnnouncement.id;
    }

    this.clientAnnouncementsService.getClientAnnouncementById(id.toString()).subscribe({
      next: (userContact) => {
        this.clientAnnouncement = userContact;

        this.loading = false;
        this.cd.markForCheck();
      },
      error: (error) => {
        this.success = null;
        this.error = error;
        this.error.statusText = "Error fetching Service announcement";
        this.cd.markForCheck();

        setTimeout(() => {
          this.router.navigate(["/Settings/ClientAnnouncements/Overview"]);
        }, 3000);
      },
    });
  }
}
