import { Component, OnInit } from "@angular/core";
import { NotificationsService } from "app/modules/notification";
import { AuthenticationService } from "app/services/authentication/authentication.service";
import { UserService } from "app/services/users/user.service";

@Component({
  selector: "fh-tokens-settings",
  templateUrl: "tokens.template.html",
})
export class TokenComponent implements OnInit {
  loading: boolean;
  loadingRefreshTokens: boolean;

  tokens = [];
  refreshTokens = [];

  currentPage = 0;
  userId;

  error;
  success;
  filter = "";
  refreshFilter = "";

  tokenName = "";

  constructor(
    private userService: UserService,
    protected notificationsService: NotificationsService,
    private authenticationService: AuthenticationService
  ) {
    this.userId = this.authenticationService.getUserId();
  }

  ngOnInit(): void {
    this.getAuthenticationTokens();
    this.getRefreshTokens();
  }

  getAuthenticationTokens() {
    this.loading = true;

    this.userService.getAuthTokens(this.userId).subscribe(
      (tokens) => {
        this.tokens = tokens;
        this.loading = false;
      },
      (error) => {
        this.success = null;
        this.error = error;
        this.loading = false;
      }
    );
  }

  getRefreshTokens() {
    this.loadingRefreshTokens = true;

    this.userService.getRefreshTokens(this.userId).subscribe(
      (refreshTokens) => {
        this.refreshTokens = refreshTokens;
        this.loadingRefreshTokens = false;
      },
      (error) => {
        this.success = null;
        this.error = error;
        this.loadingRefreshTokens = false;
      }
    );
  }

  deleteToken(id) {
    this.loading = true;

    this.userService.deleteAuthToken(this.userId, id).subscribe(
      (deleteToken) => {
        this.error = null;
        this.success = {
          statusText: "Success",
          success: "Token is successfully deleted.",
        };

        this.getAuthenticationTokens();
      },
      (error) => {
        this.success = null;
        this.error = error;
        this.loading = false;
      }
    );
  }

  addToken() {
    this.loading = true;

    this.userService.createAuthToken(this.userId).subscribe(
      (createToken) => {
        this.error = null;
        this.success = {
          statusText: "Success",
          success: "Token is successfully added.",
        };

        this.getAuthenticationTokens();
      },
      (error) => {
        this.success = null;
        this.error = error;
        this.loading = false;
      }
    );
  }

  deleteRefreshToken(id) {
    this.loadingRefreshTokens = true;

    this.userService.deleteRefreshToken(this.userId, id).subscribe(
      (deleteToken) => {
        this.error = null;
        this.success = {
          statusText: "Success",
          success: "Token is successfully deleted.",
        };

        this.getRefreshTokens();
      },
      (error) => {
        this.success = null;
        this.error = error;
        this.loadingRefreshTokens = false;
      }
    );
  }

  addRefreshToken() {
    this.loadingRefreshTokens = true;

    this.userService.createRefreshToken(this.userId, this.tokenName).subscribe(
      (createToken) => {
        this.error = null;
        this.success = {
          statusText: "Success",
          success: "Token is successfully added.",
        };

        this.getRefreshTokens();
      },
      (error) => {
        this.success = null;
        this.error = error;
        this.loadingRefreshTokens = false;
      }
    );
  }
}
