/*
 * LocatePage js helpers:
 *
 * correctHeight() - fix the height of main wrapper
 * detectBody() - detect windows size
 *
 */

declare var jQuery: any;

export function correctHeight() {
  const pageWrapper = jQuery("#page-wrapper");
  const navbarHeight = jQuery("nav.navbar-default").height();
  const wrapperHeight = pageWrapper.height();

  if (navbarHeight > wrapperHeight) {
    pageWrapper.css("min-height", navbarHeight + "px");
  }

  if (navbarHeight <= wrapperHeight) {
    if (navbarHeight < jQuery(window).height()) {
      pageWrapper.css("min-height", jQuery(window).height() + "px");
    } else {
      pageWrapper.css("min-height", navbarHeight + "px");
    }
  }

  if (jQuery("body").hasClass("fixed-nav")) {
    if (navbarHeight > wrapperHeight) {
      pageWrapper.css("min-height", navbarHeight + "px");
    } else {
      pageWrapper.css("min-height", jQuery(window).height() - 60 + "px");
    }
  }
}

export function detectBody() {
  if (jQuery(document).width() < 769) {
    jQuery("body").addClass("body-small");
  } else {
    jQuery("body").removeClass("body-small");
  }
}
