import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "fh-warehouse-navigation",
  templateUrl: "warehouseNavigation.template.html",
})
export class WarehouseNavigationComponent implements OnInit {
  sub: any;

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit() {}

  activeRoute(routename: string): boolean {
    const active = this.router.url.indexOf(routename) !== -1;
    return active;
  }
}
