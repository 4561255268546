import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewEncapsulation } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

import { AuthenticationService } from "../../services/authentication/authentication.service";
import { SignalrService } from "app/services/signalr/signalr.service";

import { ReportService } from "app/services/reports/report.service";

import { StorageHelper } from "app/common/storagehelper";

import { DashboardService } from "app/services/dashboard/dashboard.service";
import { slideInOut } from "app/common/animations";
import { FhChartService } from "app/services/charts/charts.service";
import Highcharts from "highcharts";

// Moment
import Moment from "moment-timezone";
import {
  CalendarView,
  CalendarEvent,
  DateFormatterParams,
  DAYS_OF_WEEK,
  CalendarDateFormatter,
  CalendarEventTimesChangedEvent,
} from "angular-calendar";
import { Subject } from "rxjs";
import { formatDate } from "@angular/common";
import { CustomDateFormatter } from "./custom-date-formatter.provider";
import { isSameDay, isSameMonth } from "date-fns";

window["moment"] = Moment;

@Component({
  selector: "fh-reporting-dashboard",
  templateUrl: "dashboard.template.html",
  providers: [
    FhChartService,
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [slideInOut],
})
export class ReportingDashboardComponent {
  Highcharts: typeof Highcharts = Highcharts;

  token: string;

  loading = false;
  languageLoaded: boolean;
  permissions: {};

  availableReports = {};

  excludingColumns = ["timestamp"];

  success;
  error;

  randomKey;

  constructorName = "ReportingViewComponent";
  permissionName = "FleetManagement_Reporting";
  timezoneIana: string;

  loadingReportUsage = false;
  chartReportUsage;

  loadingExpensiveReports = false;
  expensiveReports = [];

  loadingRecentReports = false;
  recentReports = [];

  loadingScheduledReports = false;
  scheduledReports = [];

  // Pagination
  currentPage = 1;
  filter;

  constructor(
    private dashboardService: DashboardService,
    private chartService: FhChartService,
    public signalrService: SignalrService,
    private translateService: TranslateService,
    private cd: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private reportService: ReportService,
    protected storageHelper: StorageHelper
  ) {
    const that = this;
    this.loading = true;

    this.permissions = this.authenticationService.permissions;
    this.token = this.authenticationService.getAuthToken();
    this.timezoneIana = this.authenticationService.getTimeZoneIana();

    this.loadingReportUsage = true;
    this.dashboardService.getReportCount().subscribe(
      (result) => {
        this.loadingReportUsage = false;
        this.generateReportCount(result);
        this.cd.markForCheck();
      },
      (error) => {
        this.loadingReportUsage = false;
        this.error = error;
        this.cd.markForCheck();
      }
    );

    this.loadingExpensiveReports = true;
    this.reportService.getExpensiveReports().subscribe(
      (result) => {
        this.loadingExpensiveReports = false;

        this.expensiveReports = result;

        this.cd.markForCheck();
      },
      (error) => {
        this.loadingExpensiveReports = false;
        this.error = error;
        this.cd.markForCheck();
      }
    );

    this.loadingRecentReports = true;
    this.reportService.getRecentReports().subscribe(
      (result) => {
        this.loadingRecentReports = false;

        result.forEach((report) => {
          report.displayName =
            report?.name != ""
              ? report?.name
              : report?.template?.name
              ? report?.template?.name
              : this.translateService.instant("enums.reportType." + report?.selectedReportType);
        });

        this.recentReports = result;

        this.cd.markForCheck();
      },
      (error) => {
        this.loadingRecentReports = false;
        this.error = error;
        this.cd.markForCheck();
      }
    );

    this.loadingScheduledReports = true;
    this.reportService.getScheduledReports().subscribe(
      (result) => {
        this.loadingScheduledReports = false;

        this.scheduledReports = result;
        this.generateCalendar(this.scheduledReports);

        this.cd.markForCheck();
      },
      (error) => {
        this.loadingScheduledReports = false;
        this.error = error;
        this.cd.markForCheck();
      }
    );
  }

  // Generate chart report
  generateReportCount(source) {
    const theReportCountData = [];
    const theSSRSReportCountData = [];

    source.forEach((item) => {
      theReportCountData.push({
        x: Moment.utc(item.summaryDate).toDate().getTime(),
        y: Math.round((item.reportCount + Number.EPSILON) * 100) / 100,
      });
      theSSRSReportCountData.push({
        x: Moment.utc(item.summaryDate).toDate().getTime(),
        y: Math.round((item.ssrsReportCount + Number.EPSILON) * 100) / 100,
      });
    });

    const theCategories = [];

    const theChartData = [
      {
        name: this.translateService.instant("general.reports"),
        data: theReportCountData,
        type: "areaspline",
        dashStyle: "dash",
        fillOpacity: 0.5,
        opacity: 1,
        marker: {
          enabled: false,
          lineWidth: 1,
          symbol: "square",
        },
      },
      {
        name: this.translateService.instant("general.ssrsReports"),
        data: theSSRSReportCountData,
        type: "areaspline",
        dashStyle: "dash",
        fillOpacity: 0.5,
        opacity: 1,
        marker: {
          enabled: false,
          lineWidth: 1,
          symbol: "square",
        },
      },
    ];

    this.chartReportUsage = this.chartService.generateColumnChartDates(
      theChartData,
      {},
      theCategories,
      undefined,
      "normal",
      true
    );

    this.cd.markForCheck();
  }

  // Calendar
  viewDate: Date = new Date();
  events: CalendarEvent[] = [];
  daysInWeek = 7;
  refresh = new Subject<void>();
  locale: string = "en";
  weekStartsOn: number = DAYS_OF_WEEK.MONDAY;
  weekendDays: number[] = [DAYS_OF_WEEK.FRIDAY, DAYS_OF_WEEK.SATURDAY];
  activeDayIsOpen = false;
  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;
  calenderEditMode = false;
  eventClicked({ event }: { event: CalendarEvent }): void {
    console.log("Event clicked", event);
  }

  generateCalendar(events) {
    events.forEach((event) => {
      this.events.push({
        title: event.companyName + ": " + this.translateService.instant("enums.reportType." + event.selectedReportType),
        start: new Date(event.nextOccurrence),
      });
    });
    this.cd.markForCheck();
    this.refresh.next();
  }

  public dayViewHour({ date, locale }: DateFormatterParams): string {
    return formatDate(date, "HH:mm", locale);
  }

  public weekViewHour({ date, locale }: DateFormatterParams): string {
    return this.dayViewHour({ date, locale });
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  handleEvent(action: string, event: CalendarEvent): void {}

  eventTimesChanged({ event, newStart, newEnd }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent("Dropped or resized", event);
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if ((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) || events.length === 0) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }
}
