export class UserRole {
  public id: number;
  public resellerId: any;
  public resellerDescription: any;
  public accountId: any;
  public companyName: any;
  public name: string;
  public permissions: any;
  public reportPermissions: any;
}
