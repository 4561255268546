import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";

import { CronEditorComponent } from "./cron-editor.component";
import { TimePickerComponent } from "./time-picker/time-picker.component";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  declarations: [CronEditorComponent, TimePickerComponent],
  imports: [TranslateModule, CommonModule, FormsModule],
  exports: [TranslateModule, CronEditorComponent],
})
export class CronEditorModule {}
