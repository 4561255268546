import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
} from "@angular/core";
import { CallbackPipe } from "app/common/callback.pipe";
import { AuthenticationService } from "app/services/authentication/authentication.service";

@Component({
  selector: "fh-notifications-list",
  providers: [CallbackPipe],
  templateUrl: "notifications.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsListViewComponent implements OnInit, OnChanges {
  @Input() notifications = [];
  @Input() loading = false;
  @Output() onDateChanged = new EventEmitter();
  @Input() daterangepickerModel;
  @Input() dpConfig;

  selectedNotification;
  // Pagination
  filter;
  currentPage;
  maxDate = new Date();

  notificationMethods = [];
  notificationTypes = [];

  selectType = undefined;
  selectMethod = undefined;

  permissions: {};

  constructor(private cd: ChangeDetectorRef, private authenticationService: AuthenticationService) {
    this.permissions = this.authenticationService.permissions;
  }

  ngOnChanges() {
    this.notificationMethods = [...new Set(this.notifications.map((item) => item.notificationMethod))];
    this.notificationTypes = [...new Set(this.notifications.map((item) => item.notificationType))];
  }

  ngOnInit(): void {}

  dateChanged(event) {
    this.onDateChanged.emit(this.daterangepickerModel);
  }

  checkJson(value) {
    if (typeof value === "object") {
      return true;
    } else {
      return false;
    }
  }
}
