import {
  Component,
  AfterViewInit,
  Input,
  ElementRef,
  ViewChild,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from "@angular/core";

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { GeofenceGroupsService } from "app/services/geofence/geofenceGroups.service";

const noop = () => {};

@Component({
  selector: "fh-geofence-group-item-input",
  templateUrl: "./geofenceGroupItemSelector.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: GeofenceGroupItemInputComponent }],
})
export class GeofenceGroupItemInputComponent implements OnChanges, OnInit, AfterViewInit, ControlValueAccessor {
  @Input() placeholder: string;
  @Input() clearable = true;
  @Input() selectedAccountId;
  @Input() readonly = false;
  @Input() multiple = true;
  @Input() tableInline;
  @Input() includeEmptyGroups = true;
  @Input() setCurrentGeofences;

  @Output() onChanged = new EventEmitter();

  geofenceGroups = [];

  loadingGeofenceGroups = false;

  @ViewChild("input") el: ElementRef;

  private _value: string;
  private _onChange: (_: any) => void = noop;

  get value(): any {
    return this._value;
  }

  set value(v: any) {
    if (v !== this._value) {
      this._value = v;
      this._onChange(v);
      this.cd.markForCheck();
    }
  }

  constructor(private geofenceGroupService: GeofenceGroupsService, private cd: ChangeDetectorRef) {}

  groupValueFn = (object: { [key: string]: any }, children: any[]) => ({
    displayName: object.value.displayName,
    id: [{ geofenceGroupId: object.value.id }],
    length: children.length,
  });

  // Override search function to be able to search on groups
  searchFn(searchTerm: string, item: any) {
    const columns = ["geofenceGroupName", "geofenceName"];
    let isSearchMatch = false;

    columns.forEach((column) => {
      if (item[column]?.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) {
        isSearchMatch = true;
      }
    });

    return isSearchMatch;
  }

  public compareFn(a, b): boolean {
    if (a.displayName) {
      if (a.id[0].geofenceGroupId === b) {
        return true;
      }
    }
    return a.id === b || (b !== null && a.id?.length > 0 && b === "geofenceGroupId_" + a.id[0].geofenceGroupId);
  }

  geofenceGroupChanged() {
    this.onChanged.emit(this.value);
    this.cd.markForCheck();
  }

  ngOnChanges() {
    setTimeout(() => {
      // Get accounts again
      this.loadGeofenceGroups();
      this.cd.markForCheck();
    }, 0);
  }

  compareFunction = (item, selected) => {
    if (selected.gender && item.gender) {
      return item.gender === selected.gender;
    }
    if (item.id && selected.id) {
      return item.id === selected.id;
    }
    return false;
  };

  showAll(event, geofenceGroup) {
    geofenceGroup.showAll = !geofenceGroup.showAll;
    event.stopPropagation();
  }

  loadGeofenceGroups() {
    this.geofenceGroups = [];
    this.cd.markForCheck();

    if (this.selectedAccountId) {
      this.loadingGeofenceGroups = true;
      this.cd.markForCheck();

      if (this.selectedAccountId && this.selectedAccountId !== 0) {
        this.geofenceGroupService.getGeofenceGroups(this.selectedAccountId, true).subscribe((result) => {
          result = result.sort((a, b) => (a.name < b.name ? -1 : 1));

          this.geofenceGroups = result;

          // Removing empty groups
          if (!this.includeEmptyGroups) {
            this.geofenceGroups = this.geofenceGroups.filter((x) => x.geofenceGroupItems?.length > 0);
          }

          this.geofenceGroups.sort((a, b) => (a.displayName < b.displayName ? -1 : 1));
          this.geofenceGroups.forEach((group) => {
            group.geofenceGroupItems.sort((a, b) => (a.geofenceName < b.geofenceName ? -1 : 1));
          });

          this.loadingGeofenceGroups = false;
          this.cd.markForCheck();
        });
      } else {
        this.loadingGeofenceGroups = false;
        this.cd.markForCheck();
      }
    }
  }

  ngOnInit() {}

  ngAfterViewInit() {
    const __this = this;
  }

  writeValue(value: any) {
    this._value = value;
    this.cd.markForCheck();
  }

  registerOnChange(fn: (value: any) => void) {
    this._onChange = fn;
    this.cd.markForCheck();
  }

  registerOnTouched(fn: any) {
    this.cd.markForCheck();
  }
}
