import { ChangeDetectorRef, Component, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { FormMode, TriggerResultName } from "app/common/enums";
import { AuthenticationService } from "app/services/authentication/authentication.service";
import { TriggerService } from "app/services/triggers/triggers.service";
import { Trigger, TriggerRule } from "app/models/trigger.model";
import { TranslateService } from "@ngx-translate/core";
import { TriggerRuleDetailsComponent } from "app/views/shared/usercontrols/triggerRuleDetails.component";
import { TriggerRulesViewComponent } from "app/views/shared/usercontrols/triggerRules.component";

@Component({
  selector: "fh-trigger-add",
  templateUrl: "add.template.html",
})
export class TriggerAddViewComponent {
  @ViewChild("ruleConfiguration") ruleConfiguration: TriggerRuleDetailsComponent;
  @ViewChild("triggerRulesView") triggerRulesView: TriggerRulesViewComponent;

  loading: boolean = false;
  trigger: Trigger = new Trigger();
  formMode: FormMode = FormMode.add;
  error: any;
  permissions: {};

  triggerScheduleName: any;

  loadingUserContactGroups: boolean = false;
  loadingAssetGroups: boolean = false;

  success: { statusText: string; success: string };

  permissionName: string = "Triggers_Add";

  adding: boolean = false;

  constructor(
    private translateServe: TranslateService,
    private triggerService: TriggerService,
    private cd: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {
    this.permissions = this.authenticationService.permissions;
    this.trigger.episodeType = TriggerResultName.Other;
  }

  add() {
    this.adding = true;
    this.cd.markForCheck();
  }

  onInsert() {
    this.loading = true;

    // When avg weight change add weight > 0
    if (this.trigger.triggerInputSource === 3) {
      // Check for deviation rule
      if (this.trigger.rules.findIndex((x) => x.ruleType === 13) < 0) {
        const deviationRule = new TriggerRule();
        deviationRule.ruleType = 13;
        deviationRule.ruleProperty = 3;
        deviationRule.thresholdCompare = 1;
        deviationRule.threshold = 5;

        this.trigger.rules.push(deviationRule);
      }

      // Check for kg rule
      if (this.trigger.rules.findIndex((x) => x.ruleType === 10) < 0) {
        const weightRule = new TriggerRule();
        weightRule.ruleType = 10;
        weightRule.thresholdCompare = 1;
        weightRule.threshold = 0;

        this.trigger.rules.push(weightRule);
      }
    }

    this.triggerService.saveTrigger(this.trigger).subscribe({
      next: (result) => {
        this.success = {
          statusText: "Success",
          success: this.translateServe.instant("general.saveSuccessRedirect", { entity: "trigger" }),
        };

        setTimeout(() => {
          this.router.navigate(["/Notifications/TriggerDetails/Index/" + result.id]);
        }, 2000);

        this.loading = false;
      },
      error: (error) => {
        this.error = error;
        this.loading = false;
      },
    });
  }

  accountChanged(accountId) {
    this.trigger.userContactGroups = [];
    this.trigger.assetGroups = [];

    if (accountId != null) {
      this.ruleConfiguration.changeAccount(accountId);
    }

    this.cd.markForCheck();
  }

  AssetGroupsChanged(event) {}

  sourceChanged(event) {
    console.log(event);
    this.ruleConfiguration.updateInputSource();
  }

  onRuleAdded(rule) {
    if (rule) {
      this.trigger.rules.push(rule);
    }

    this.adding = false;
    this.triggerRulesView.refresh();
    this.cd.markForCheck();
  }

  removeRule(rule) {
    const index: number = this.trigger.rules.indexOf(rule);
    if (index !== -1) {
      this.trigger.rules.splice(index, 1);
    }
  }
}
