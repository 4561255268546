import { Component, EventEmitter, Output, ChangeDetectionStrategy, ChangeDetectorRef, Input } from "@angular/core";
import { FormGroup, FormControl, Validators, FormArray } from "@angular/forms";
import { DeviceService } from "app/services/device/device.service";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";

@Component({
  selector: "fh-batch-unarchive-assets",
  templateUrl: "unArchiveAssets.template.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BatchUnArchiveAssetsComponent {
  step: number;
  @Output() onFinish = new EventEmitter();
  @Input() gridSelection = [];
  disabled = true;
  loadingDeviceDetails = false;
  logItem;

  assetForm = new FormGroup({
    checkbox: new FormControl("", Validators.required),
  });

  deviceForm: FormArray;

  public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();

  get formControls() {
    return this.deviceForm.controls as unknown as FormGroup[];
  }

  constructor(private cd: ChangeDetectorRef, private deviceService: DeviceService) {
    this.dpConfig.containerClass = "theme-default"; // or whatever color
    this.dpConfig.dateInputFormat = "lll";
    this.dpConfig.isAnimated = true;
    this.step = 1;
  }

  initFinish() {
    // Fetch all data and make sure it can be saved
    console.log(1);
  }

  onCheckOutFinish() {
    this.onFinish.emit({ status: "success", object: this.deviceForm.value, log: this.logItem });
    this.step = 1;
  }

  loadDevices() {
    if (this.deviceForm) {
      return;
    }

    const formGroups = [];
    this.loadingDeviceDetails = true;

    this.deviceService.getDeviceData(this.gridSelection).subscribe((devices) => {
      this.loadingDeviceDetails = false;
      this.cd.markForCheck();

      devices = devices.filter((x) => x.asset?.id !== undefined && new Date(x.asset?.assetDateEnd) < new Date());

      for (const device of devices) {
        formGroups.push(
          new FormGroup({
            imei: new FormControl(device.unitId, Validators.required),
            deviceId: new FormControl(device.id, Validators.required),
            assetId: new FormControl(device.asset?.id, Validators.required),
            name: new FormControl(device.assetName),
            code: new FormControl(device.assetCode),
            dateEnd: new FormControl(),
          })
        );
      }

      this.deviceForm = new FormArray(formGroups);
    });
  }

  initForm() {
    this.step = 1;
    console.log("init tab");
  }
}
