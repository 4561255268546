import { HttpClient } from "@angular/common/http";
import { Component, OnInit, ChangeDetectorRef, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Device, DeviceCalibration } from "app/models/device.model";
import { AuditLog } from "../../models/auditlog.model";
import { DeviceService } from "../../services/device/device.service";
import { TranslateService } from "@ngx-translate/core";
import { FormMode } from "app/common/enums";
import { FhChartService } from "app/services/charts/charts.service";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import { LocationService } from "app/services/locations/locations.service";
import { AssetService } from "app/services/asset/asset.service";
import { ConfirmationModalComponent } from "../shared/usercontrols/confirmationModal.component";

// Moment timezone
import Moment from "moment-timezone";
import { Asset } from "app/models/asset.model";
import { AuthenticationService } from "app/services/authentication/authentication.service";

import * as Highcharts from "highcharts";

window["moment"] = Moment;

@Component({
  selector: "fh-device-odo",
  providers: [FhChartService],
  templateUrl: "odo.template.html",
})
export class DeviceOdoViewComponent implements OnInit {
  Highcharts: typeof Highcharts = Highcharts;

  @ViewChild("deleteModal", { static: false }) deleteModal: ConfirmationModalComponent;

  deviceLog: AuditLog[];
  sub;
  device: Device;
  deviceId;

  loading = false;
  saving = false;
  error: any;
  success: any;
  warning: any;

  formMode = FormMode.add;

  loadingHistory = false;
  loadingCacher = false;
  loadingAssets: boolean;
  assets: Asset[] = [];

  chartOdo;
  calibrations: DeviceCalibration[] = [];

  calibratedOdo = 0;
  assetCalibratedOdo = 0;
  calibrationDate = new Date();

  deviceOdoDistanceOffset = 0;
  deviceOdo = 0;
  incrementalOdoDistanceOffset = 0;

  isCalibrationOutdated = false;

  filter;
  currentPage;

  selectedAssetId: any;
  selectedAsset: Asset;

  // Datepicker
  public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
  lastCalibration: any;

  permissionName = "FleetManagement_OdoView";
  permissions: {};

  constructor(
    private cd: ChangeDetectorRef,
    private locationService: LocationService,
    private chartService: FhChartService,
    private authenticationService: AuthenticationService,
    private http: HttpClient,
    private assetService: AssetService,
    private deviceService: DeviceService,
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService
  ) {
    this.device = null;
    this.deviceLog = [];
    this.permissions = this.authenticationService.permissions;

    // Datepicker
    this.dpConfig.containerClass = "theme-default"; // or whatever color
    this.dpConfig.dateInputFormat = "lll";
    this.dpConfig.isAnimated = true;
    this.dpConfig.withTimepicker = true;
    this.dpConfig.keepDatepickerOpened = true;
  }

  ngOnInit() {
    this.loading = true;
    this.loadingHistory = true;
    this.device = new Device();
    this.device.id = "";

    this.sub = this.route.params.subscribe({
      next: (params) => {
        const id = params["id"];

        this.loading = true;
        this.loadingHistory = true;

        this.deviceId = id;
        this.deviceService.getDeviceById(id).subscribe((device) => {
          this.device = device;

          // Fill asset dropdown
          this.loadingAssets = true;

          this.deviceService.getAssets(this.device.id).subscribe((assets) => {
            this.assets = assets;

            this.selectedAssetId = this.device.asset?.id;
            this.assetChanged(this.selectedAssetId);

            this.loadingAssets = false;
            this.cd.markForCheck();
          });

          if (this.device == null) {
            this.router.navigate(["/Devices/Overview"]);
          }

          this.loading = false;
        });
      },
      error: (error) => {
        this.error = error;
        this.error.statusText = "Error fetching device";

        setTimeout(() => {
          this.router.navigate(["/Devices/Overview"]);
        }, 3000);
      },
    });
  }

  assetChanged(assetId) {
    this.selectedAsset = this.assets.find((x) => x.id === assetId);
    if (this.selectedAsset) {
      this.calibratedOdo = Math.round(this.selectedAsset.calibratedOdo / 1000);
      this.lastCalibration = this.selectedAsset.lastCalibration;
      this.selectedAssetId = assetId;
      this.getOdoDetails();
    } else {
      this.loadingAssets = false;
      this.loadingHistory = false;
      this.cd.markForCheck();
    }
  }

  refresh() {
    this.getOdoDetails();
  }

  round(value) {
    return Math.round(value);
  }

  getOdoDetails() {
    this.getCalibrations(this.selectedAssetId);
  }

  getCalibrations(assetId) {
    this.loadingHistory = true;
    this.cd.markForCheck();

    // Get calibrations
    this.assetService.getCalibrationsByAssetId(assetId).subscribe((calibrations) => {
      this.calibrations = calibrations;
      this.cd.markForCheck();

      if (this.calibrations && this.calibrations.length > 0) {
        this.calibrations = this.calibrations.sort((a, b) => (a.dateCalibration > b.dateCalibration ? -1 : 1));

        // Get the latest offset
        this.deviceOdoDistanceOffset = Math.round(this.calibrations[0].deviceOdoDistanceOffset / 1000) ?? 0;
        this.incrementalOdoDistanceOffset = Math.round(this.calibrations[0].incrementalOdoDistanceOffset / 1000) ?? 0;
        this.isCalibrationOutdated =
          this.calibrations[0].dateCalibration < Moment().subtract(3, "months").startOf("day").toDate();
        this.cd.markForCheck();
      } else {
        this.deviceOdoDistanceOffset = 0;
        this.isCalibrationOutdated = true;
        this.cd.markForCheck();
      }

      // Get latest location
      this.loadingCacher = true;
      this.locationService.getStates([+this.device.id], null, null, 0).subscribe((result) => {
        if (result?.deviceStates?.length > 0) {
          // Get most current location/trip odo

          const state = result.deviceStates[0];

          if (this.device?.settings?.canBusParameters?.filter((x) => x.id.toString() == "4").length > 0) {
            this.warning = "Canbus odometer found for this device. Please do not calibrate.";
            this.loadingCacher = false;
            this.cd.markForCheck();
            return;
          }

          if (!state.odometer || state.odometer?.gpsOdometer < 1) {
            this.warning =
              "No current device odo found for device. This will offset the odocalibration. Please do not calibrate.";
            this.loadingCacher = false;
            this.cd.markForCheck();
            return;
          }

          this.deviceOdo = Math.round(state.odometer?.gpsOdometer / 1000);
          this.calibratedOdo = Math.round(this.deviceOdoDistanceOffset + state.odometer?.gpsOdometer / 1000);
          this.assetCalibratedOdo = this.calibratedOdo;
          this.loadingCacher = false;
          this.cd.markForCheck();
        } else {
          this.warning =
            "No current location found for device. This will offset the odocalibration. Please do not calibrate.";
          this.loadingCacher = false;
          this.cd.markForCheck();
        }
      });
      this.drawChart(calibrations);

      this.loadingHistory = false;
      this.cd.markForCheck();
    });
  }

  drawChart(calibrations) {
    const theDataDeviceOdo = [];
    const theDataRealOdo = [];

    this.loading = false;

    calibrations.forEach((calibration) => {
      theDataDeviceOdo.push({
        x: new Date(calibration.dateCalibration).getTime(),
        y: Math.round(calibration.deviceOdoDistance / 1000),
        calibrationId: calibration.id,
      });
      theDataRealOdo.push({
        x: new Date(calibration.dateCalibration).getTime(),
        y: Math.round(calibration.realOdoDistance / 1000),
        calibrationId: calibration.id,
      });
    });

    const theDataEvents = [];

    if (this.permissions["Platform_IsReseller"]) {
      theDataEvents.push({
        type: "line",
        name: this.translateService.instant("general.deviceOdoDistance"),
        data: theDataDeviceOdo,
        lineWidth: 3,
        marker: {
          radius: 4,
        },
      });
    }

    theDataEvents.push({
      type: "line",
      name: this.translateService.instant("general.realOdoDistance"),
      data: theDataRealOdo,
      lineWidth: 3,
      marker: {
        radius: 4,
      },
    });

    this.chartOdo = this.chartService.generateOdoChart(theDataEvents);
    this.cd.markForCheck();
  }

  setFormMode(mode) {
    this.formMode = mode;
  }

  showDelete(calibrationId) {
    this.deleteModal.showModal(calibrationId);
  }

  deleteCalibration(calibrationId) {
    this.deleteModal.hideModal();

    this.assetService.deleteCalibration(this.device.asset?.id, calibrationId).subscribe({
      next: (result) => {
        this.saving = false;

        this.error = null;
        this.success = {
          statusText: "Success",
          success: "Odo calibration is successfully deleted. Applying the change will take a few minutes.",
        };

        setTimeout(() => {
          this.refresh();
        }, 2000);

        this.cd.markForCheck();

        this.setFormMode(FormMode.add);
      },
      error: (error) => {
        this.saving = false;
        this.success = null;
        this.error = error;
        this.cd.markForCheck();
      },
    });
  }

  onSave() {
    this.saving = true;

    // Set ticmepicker values to date
    const calibration = {
      assetId: this.selectedAssetId,
      realAssetOdo: this.calibratedOdo,
      dateCalibration: this.calibrationDate,
    };

    this.assetService.postCalibration(this.device.asset?.id, calibration).subscribe({
      next: (result) => {
        this.saving = false;

        this.error = null;
        this.success = {
          statusText: "Success",
          success: "Odo calibration is successfully updated. Applying the change will take a few minutes.",
        };

        setTimeout(() => {
          this.refresh();
        }, 2000);

        this.cd.markForCheck();

        this.setFormMode(FormMode.add);
      },
      error: (error) => {
        this.saving = false;
        this.success = null;
        this.error = error;
        this.cd.markForCheck();
      },
    });
  }
}
