import { Device } from "./device.model";

export class AuditLog {
  public id: number;
  public user: string;
  public device: Device;
  public timestamp: any;
  public description: string;
  public entityType: any;
  public delta: any;
  public deltaItems: { Differences: { [id: string]: string }[] } | any;
  public createdDate: any;
  public logType: any;
  public userId: any;
  public accountId: any;
  public companyName: any;
  public entityId: any;
  public link: any;
}
