<div class="ibox-content slick" style="position: relative">
    <ngx-loading [show]="loading"></ngx-loading>
    <table style="width: 100%" class="table table-fixed nowrap dtr-inline no-footer">
        <tbody *ngIf="!zone">
            <tr>
                <td colspan="4">
                    {{ "general.noZone" | translate }}
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="zone">
            <tr>
                <td class="row_opening">
                    {{ "general.name" | translate }}
                    <span class="error" *ngIf="name.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input
                        type="text"
                        [readonly]="formMode == 1"
                        name="name"
                        #name="ngModel"
                        required
                        placeholder="{{ 'placeholder.name' | translate }}"
                        class="form-control table-inline"
                        [(ngModel)]="zone.name"
                    />
                </td>
            </tr>
            <!-- <tr>
                <td class="row_opening">
                    {{ 'general.partitionKey' | translate}}
                    <span class="error" *ngIf="partitionKey.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="text" [readonly]="formMode == 1" name="partitionKey" class="form-control table-inline"
                        placeholder="{{ 'placeholder.partitionKey' | translate}}" [(ngModel)]="zone.partitionKey"
                        #partitionKey="ngModel">
                </td>
            </tr> -->
            <!-- <tr>
                <td class="row_opening">
                    {{ 'general.rowKey' | translate}}
                    <span class="error" *ngIf="rowKey.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="text" [readonly]="formMode == 1" name="rowKey" class="form-control table-inline"
                        placeholder="{{ 'placeholder.rowKey' | translate}}" [(ngModel)]="zone.rowKey" #rowKey="ngModel">
                </td>
            </tr> -->
            <tr>
                <td class="row_opening">
                    {{ "general.sfdaStoringCategory" | translate }}
                    <span class="error" *ngIf="sfdaCompanyActivity.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content" style="overflow: visible">
                    <ng-select
                        class="form-control table-inline"
                        [readonly]="formMode == 1"
                        name="companyActivity"
                        required
                        #sfdaCompanyActivity="ngModel"
                        [(ngModel)]="zone.type"
                        style="width: 100%"
                    >
                        <ng-option value="SCD1"> {{ "enums.sfdaStoringCategory.SCD1" | translate }} </ng-option>
                        <ng-option value="SCD2"> {{ "enums.sfdaStoringCategory.SCD2" | translate }} </ng-option>
                        <ng-option value="SCD3"> {{ "enums.sfdaStoringCategory.SCD3" | translate }} </ng-option>
                        <ng-option value="SCC1"> {{ "enums.sfdaStoringCategory.SCC1" | translate }} </ng-option>
                        <ng-option value="SCM1"> {{ "enums.sfdaStoringCategory.SCM1" | translate }} </ng-option>
                        <ng-option value="SCM2"> {{ "enums.sfdaStoringCategory.SCM2" | translate }} </ng-option>
                        <ng-option value="SCM3"> {{ "enums.sfdaStoringCategory.SCM3" | translate }} </ng-option>
                        <ng-option value="SCM4"> {{ "enums.sfdaStoringCategory.SCM4" | translate }} </ng-option>
                        <ng-option value="SCM5"> {{ "enums.sfdaStoringCategory.SCM5" | translate }} </ng-option>
                        <ng-option value="SCF1"> {{ "enums.sfdaStoringCategory.SCF1" | translate }} </ng-option>
                        <ng-option value="SCF2"> {{ "enums.sfdaStoringCategory.SCF2" | translate }} </ng-option>
                        <ng-option value="SCF3"> {{ "enums.sfdaStoringCategory.SCF3" | translate }} </ng-option>
                        <ng-option value="SCF4"> {{ "enums.sfdaStoringCategory.SCF4" | translate }} </ng-option>
                        <ng-option value="SCA1"> {{ "enums.sfdaStoringCategory.SCA1" | translate }} </ng-option>
                        <ng-option value="SCP1"> {{ "enums.sfdaStoringCategory.SCP1" | translate }} </ng-option>
                    </ng-select>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ "general.waslKey" | translate }}
                    <span class="error" *ngIf="identifier.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input
                        type="text"
                        [readonly]="true"
                        name="identifier"
                        class="form-control table-inline"
                        placeholder="{{ 'placeholder.identifier' | translate }}"
                        [(ngModel)]="zone.identifier"
                        #identifier="ngModel"
                    />
                </td>
            </tr>
        </tbody>
    </table>
</div>
