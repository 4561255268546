import { WebHookHeader } from "./trigger.model";

export class WebhookEndpoint {
  public id: string;
  public name: string;
  public headers: WebHookHeader[] = [];
  public method = 2;
  public contentType = 1;
  public url: string;
  public timstamp: string;
  public companyName: string;
  public accountId: any;
  public resellerId: any;
  public resellerDescription: any;
  public isEnabled = true;
}
