import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Device, DeviceSettingChange } from "app/models/device.model";
import { AuditLog } from "../../models/auditlog.model";
import { DeviceService } from "../../services/device/device.service";
import { TranslateService } from "@ngx-translate/core";
import { DeviceAuxiliary, DeviceOutput, FormMode, ImmobilizationStatus } from "app/common/enums";
import { DeviceSettingChangeService } from "app/services/device/deviceSettingChange.service";
import { ConfirmationModalComponent } from "../shared/usercontrols/confirmationModal.component";
import { AuthenticationService } from "app/services/authentication/authentication.service";

@Component({
  selector: "fh-device-immobilization",
  templateUrl: "immobilization.template.html",
})
export class DeviceImmobilizationViewComponent implements OnInit {
  @ViewChild("pasteModal") pasteModal: ConfirmationModalComponent;
  hasDriverTagsEnabled = false;
  loadingDriverTagAssignments = false;
  pasteText;
  newDriverTag;
  warningTags;
  errorTags;
  successTags;
  loadingDriverSettings = false;
  driverTagAssignments = [];
  formMode = FormMode.read;

  deviceLog: AuditLog[];
  sub;
  device: Device;
  deviceId;

  loading = false;

  success: any;
  error: any;

  errorStarter: any;
  warningStarter: any;

  thePort: any;
  immobilizationType: any;
  theSettingId: number;

  immobilizationStatus = ImmobilizationStatus.NotImmobilised;
  activeDsc: any;

  permissionName = "FleetManagement_ImmobilizeVehicle";
  permissions: {};

  wakeUpDevice = false;

  constructor(
    private authenticationService: AuthenticationService,
    private cd: ChangeDetectorRef,
    private dsc: DeviceSettingChangeService,
    private deviceService: DeviceService,
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService
  ) {
    this.permissions = authenticationService.permissions;

    this.device = null;
    this.deviceLog = [];
  }

  ngOnInit() {
    this.loading = true;
    this.loadingDriverSettings = true;

    this.device = new Device();
    this.device.id = "";

    this.sub = this.route.params.subscribe(
      (params) => {
        const id = params["id"];

        this.deviceId = id;
        this.deviceService.getDeviceById(id).subscribe((device) => {
          this.device = device;

          this.getDeviceDriverSettings();

          this.getCurrentStatus();

          if (this.device == null) {
            this.router.navigate(["/Devices/Overview"]);
          }

          this.getDriverTagInfo(id);

          this.loading = false;
          this.cd.markForCheck();
        });
      },
      (error) => {
        this.error = error;
        this.error.statusText = "Error fetching device";

        setTimeout(() => {
          this.router.navigate(["/Devices/Overview"]);
        }, 3000);
      }
    );
  }

  refresh() {
    this.loading = true;
    this.cd.markForCheck();

    this.deviceService.getDeviceById(this.device.id).subscribe((device) => {
      this.device = device;
      this.loading = false;
      this.getCurrentStatus();
      this.cd.markForCheck();
    });
  }

  abort() {
    this.loading = true;
    this.cd.markForCheck();

    this.dsc.deleteDeviceSettingChanges(this.activeDsc.id).subscribe(
      (result) => {
        this.immobilizationStatus = 1;

        this.errorStarter = null;
        this.success = {
          statusText: "Success",
          success: "Device immobilization action was cancelled",
        };

        this.loading = false;
        this.cd.markForCheck();

        setTimeout(() => {
          this.refresh();
        }, 3000);
      },
      (error) => {
        this.errorStarter = error;
        this.loading = false;
      }
    );
  }

  immobilise() {
    this.loading = true;
    this.cd.markForCheck();

    const change = new DeviceSettingChange();
    change.deviceId = +this.device.id;
    change.settingId = this.theSettingId;
    change.settingValue = "1";
    change.wakeUpDevice = this.wakeUpDevice;

    this.dsc.sendDeviceSettingChanges(change).subscribe(
      (result) => {
        this.errorStarter = null;
        this.success = {
          statusText: "Success",
          success: "Device immobilization was requested",
        };

        this.loading = false;
        this.cd.markForCheck();

        setTimeout(() => {
          this.refresh();
        }, 3000);
      },
      (error) => {
        this.errorStarter = error;
        this.loading = false;
      }
    );
  }

  unimmobilise() {
    this.loading = false;

    const change = new DeviceSettingChange();
    change.deviceId = +this.device.id;
    change.settingId = this.theSettingId;
    change.settingValue = "0";

    this.dsc.sendDeviceSettingChanges(change).subscribe(
      (result) => {
        this.loading = false;
        this.errorStarter = null;
        this.success = {
          statusText: "Success",
          success: "Device de-immobilization was requested",
        };

        setTimeout(() => {
          this.refresh();
        }, 3000);
      },
      (error) => {
        this.errorStarter = error;
        this.loading = false;
      }
    );
  }

  getCurrentStatus() {
    this.thePort = null;
    this.immobilizationType = null;
    this.theSettingId = null;

    const outputPorts = this.device.settings.outputPorts;

    if (outputPorts.filter((x) => x.byte === DeviceOutput["OnCommand Starter Interrupt"].toString()).length > 0) {
      this.immobilizationType = "OnCommandStarterInterrupt";
      this.theSettingId = 38;

      this.thePort = outputPorts.filter((x) => x.byte === DeviceOutput["OnCommand Starter Interrupt"].toString())[0].id;
    }

    if (outputPorts.filter((x) => x.byte === DeviceOutput["Automatic Starter Interrupt"].toString()).length > 0) {
      this.immobilizationType = "AutomaticCommandStarterInterrupt";
      this.theSettingId = 39;

      this.thePort = outputPorts.filter((x) => x.byte === DeviceOutput["Automatic Starter Interrupt"].toString())[0].id;
    }

    if (this.immobilizationType === null) {
      this.warningStarter = {
        statusText: "Warning",
        warning: "There is no starter interrupt configured on this device. Immobilization can not be configured!",
      };
    }

    // Get the current status
    this.dsc.getDeviceSettingChangesByDeviceId(this.device.id).subscribe((result) => {
      const currentStatus = result
        .filter((x) => x.settingId === this.theSettingId && x.status === 2)
        .sort((a, b) => (a.timestamp.unix() > b.timestamp.unix() ? -1 : 1));
      const activeDsc = result
        .filter(
          (x) =>
            x.settingId === this.theSettingId && x.status !== 2 && x.status !== 3 && x.status !== 5 && x.status !== 6
        )
        .sort((a, b) => (a.timestamp.unix() > b.timestamp.unix() ? -1 : 1));

      if (currentStatus.length > 0) {
        if (currentStatus[0].value.toString() === "1") {
          this.immobilizationStatus = 2;
        } else {
          this.immobilizationStatus = 1;
        }
      }

      if (activeDsc.length > 0) {
        const currentDsc = activeDsc[0];
        this.activeDsc = currentDsc;

        // scheduled
        if (currentDsc.value.toString() === "1") {
          this.immobilizationStatus = 3;
        } else {
          this.immobilizationStatus = 4;
        }
      }
      // ImmobilizationStatus
    });
  }

  // Driver tags
  getDeviceDriverSettings() {
    this.loadingDriverSettings = false;

    const aux = this.device.settings.deviceAuxiliary;

    if (aux.filter((x) => (BigInt(x.id) & BigInt(DeviceAuxiliary.DriverFlags)) > 0).length > 0) {
      this.hasDriverTagsEnabled = true;
    } else {
      this.warningTags = {
        statusText: "Warning",
        warning: "There is no driver scanner configured on this device. Driver tags can not be configured!",
      };
    }
  }

  getDriverTagInfo(id = this.device.id) {
    this.loadingDriverTagAssignments = true;

    this.deviceService.getDriverTagAssignmentsById(id).subscribe(
      (driverTagAssignments) => {
        this.driverTagAssignments = [];

        driverTagAssignments.forEach((assignment) => {
          // Check if we need to split
          if (assignment.active === false && assignment.driverTag.indexOf(",") > -1) {
            const tags = assignment.driverTag.split(",");
            tags.forEach((tag) => {
              if (tag !== "" && tag.length > 4) {
                const tagAssignment = { ...assignment };
                tagAssignment.driverTag = tag;
                this.driverTagAssignments.push(tagAssignment);
              }
            });
          } else {
            this.driverTagAssignments.push(assignment);
          }
        });

        this.loadingDriverTagAssignments = false;
      },
      (error) => {
        this.loadingDriverTagAssignments = false;
        this.errorTags = error;
      }
    );
  }

  removeTag(driverTagAssignment) {
    const index: number = this.driverTagAssignments.indexOf(driverTagAssignment);
    if (index !== -1) {
      this.driverTagAssignments.splice(index, 1);
    }
  }

  add() {
    this.driverTagAssignments.push({ driverTag: this.newDriverTag, dateStart: Date(), dateEnd: null });

    this.newDriverTag = "";
  }

  massApply() {}

  paste(event) {
    this.pasteModal.hideModal();

    const keyList = [];

    if (this.pasteText && this.pasteText.length > 10) {
      if (this.pasteText?.indexOf(";") > -1) {
        const tempList = this.pasteText?.split(";");
        tempList.forEach((item) => {
          item = item.trim();
          if (item.length > 5) {
            keyList.push(item);
          }
        });
      } else {
        const tempList = this.pasteText?.split(/\r?\n/);
        tempList.forEach((item) => {
          item = item.trim();
          if (item.length > 5) {
            keyList.push(item);
          }
        });
      }
    }

    this.driverTagAssignments = [];
    keyList.forEach((key) => {
      this.driverTagAssignments.push({ driverTag: key, dateStart: Date(), dateEnd: null });
    });

    this.setFormMode(FormMode.edit);
  }

  onSave() {
    this.loadingDriverTagAssignments = true;

    this.deviceService.saveDriverTagAssignmentsById(this.device.id, this.driverTagAssignments).subscribe(
      (result) => {
        this.errorTags = null;
        this.successTags = null;

        if (result.isSuccess) {
          this.successTags = {
            statusText: "Success",
            success: result.message,
          };
        } else {
          this.errorTags = {
            statusText: "Error",
            error: result.message,
          };
        }

        this.loadingDriverTagAssignments = false;

        this.setFormMode(FormMode.read);
      },
      (error) => {
        this.loadingDriverTagAssignments = false;
        this.successTags = null;
        this.errorTags = error;
        this.errorTags = {
          statusText: "Success",
        };
      }
    );
  }

  setFormMode(mode) {
    this.formMode = mode;

    if (this.formMode === FormMode.read) {
      this.getDeviceDriverSettings();
      this.getDriverTagInfo();
    }
  }
}
