export enum SettingsLevel {
  General = 0,
  Account = 1,
  Platform = 2,
  Customer = 3,
  DeviceType = 4,
  Device = 5,
  None = 6,
}

export enum SidebarOptions {
  City = 0,
  Brand = 1,
  Model = 2,
  Driver = 3,
  PlateNumber = 4,
  ShowTimeStamp = 5,
  ShowLastStateChange = 6,
  GroupByState = 7,
  Geofence = 8,
}

export enum VehicleType {
  Unknown = 0,
  CarVan = 1,
  LightTruck = 2,
  HeavyTruck = 3,
  Bus = 4,
  Motorcycle = 5,
  Equipment = 6,
  Asset = 7,
  Pet = 8,
}

export enum DurationGroupingType {
  Day = 1,
  Week = 2,
  Month = 3,
}

export enum DriverScoreType {
  Drivers = 1,
  Assets = 2,
}

export enum languages {
  "en-US" = "./assets/i18n/en.json",
  "en-GB" = "./assets/i18n/en.json",
  "nl-NL" = "./assets/i18n/nl.json",
  "ar-AE" = "./assets/i18n/ar.json",
  "ar-EG" = "./assets/i18n/ar.json",
  "de-DE" = "./assets/i18n/de.json",
  "fr-FR" = "./assets/i18n/fr.json",
  "es-ES" = "./assets/i18n/es.json",
  "pt-PT" = "./assets/i18n/pt.json",
  "ru-RU" = "./assets/i18n/ru.json",
  "zh-CN" = "./assets/i18n/zh.json",
}

export enum sources {
  Messages = 1,
  TripsAndStops = 2,
}

export enum userTypes {
  // l360 = 'Default (360)',
  // ls = 'Blue (LS)',
  // blue = 'Blue',
  // ginger = 'Ginger',
  // lightblue = 'Light blue',
  // modern = 'Modern',
  // wasl = 'WASL',
  "Etmam Logistics" = 50,
  "Location Solutions Europe" = 49,
  "Arabtec" = 43,
  "Demo Account" = 9,
  "Dubai Police" = 46,
  "Emirates Transport" = 36,
  "Infocast" = 37,
  "360 Locate" = 19,
  "360 Locate USA" = 34,
  "Fleetloqater" = 41,
  "Location Solutions KSA" = 18,
  "Location Solutions UAE" = 15,
  "RTA Dubai" = 42,
  "SBL" = 48,
  "Telemagics" = 33,
  "CARlectra" = 51,
  "Trips in the Cloud" = 47,
  "LinqHaul" = 52,
  "TraceKing" = 53,
  "Environment" = 54,
  "Lulu" = 55,
  "KuijpersGroep" = 56,
  "AlFuttaim" = 57,
  "Location Solutions Dark Mode" = 58,
  "Asyad" = 59,
  "Dubai Municipality" = 60,
  "Dollar" = 61,
  "Location Solutions OMAN" = 62,
  "Emdad" = 63,
  "Together International" = 64,
}

export enum AssetDisplayName {
  "Asset Code" = 1,
  "Client Handle: Asset Code" = 4,
  "Plate Number" = 2,
  "Client Handle: Plate Number" = 5,
  "Device Name" = 3,
  "Client Handle: Device Name" = 6,
}

export enum DistanceUnit {
  "Km" = 0,
  "Miles" = 1,
}

export enum VolumeUnit {
  "Liter" = 0,
  "Imperial Gallon" = 1,
  "US Liquid Gallon" = 2,
}

export enum SortingOptionFleetOverview {
  "Device Name" = 0,
  "Asset Code" = 1,
  "Plate Number" = 2,
  "Display Name" = 3,
  "Location Updated" = 4,
  "State Updated" = 5,
  "Driver Name" = 6,
}

export enum PermissionsBitmask {
  HasUserAdministration = 2,
  HasAccountAdministration = 4, //
  IsAdministrator = 8,
  HasDrivers = 16,
  HasScheduleView = 64, //
  HasReporting = 128,
  HasCustomersViewOwn = 256,
  HasCustomersViewAll = 512,
  HasPlatforms = 1024,
  HasScheduleProcessOwn = 2048,
  HasScheduleProcessAll = 4096, //
  HasWorkflow = 8192,
  HasDeviceTypes = 16384,
  HasJobs = 32768,
  HasDevices = 65536,
  HasIssues = 131072,
  HasAssets = 262144,
  HasWasl = 524288,
}

export enum Cluster {
  Staging = 99,
  Clust02 = 2,
  Clust03 = 3,
  Clust04 = 4,
  Clust05 = 5,
  Clust06 = 6,
  Clust07 = 7,
}

export enum UserContactType {
  NormalContact = 0,
  WS_AndroidPush = 2,
  WS_iPhonePush = 3,
  WS_Firebase = 4,
  WS_OracleOtm = 9,
  WS_Naqaba = 10,
  WS_Maxoptra = 11,
  WS_ServantMrAppliance = 12,
  WS_ServantMollyMaid = 13,
  WS_2Gis = 14,
  WS_ReadyMixTracking = 15,
  WS_Api360 = 16,
  WS_Inrix = 17,
  WS_Aldrees = 18,
  WS_Wasl = 19,
  WS_BeMobile = 20,
  WS_ServantJanPro = 21,
  WS_TomTom = 22,
  WS_JeddahMunicipality = 23,
  WS_Here = 24,
}

export enum IssueTypeEnums {
  Unknown = 0,
  TooManyLocations = 1,
  FarTooManyMessages = 2,
  NoCommunication = 3,
  TooManyPowerlossEvents = 4,
  NoIgnitionSignal = 5,
  LooseConnectionOrIgnitionWire = 6,
  BadGpsReception = 7,
  TooFewLocations = 9,
  TooMuchDistance = 13,
  TooMuchDistancePerMessage = 14,
  SensorStuck = 15,
  SensorOutOfBounds = 16,
  SensorNoData = 17,
  FuelPercentageSensorStuck = 18,
  FuelPercentageSensorOutOfBounds = 19,
  FuelPercentageSensorNoData = 20,
  TemperatureSensorStuck = 21,
  TemperatureOutOfBounds = 22,
  TemperatureNoData = 23,
  OdoSensorStuck = 24,
  OdoOutOfBounds = 25,
  OdoNoData = 26,
  HighDataConsumptionMinimumMotion = 27,
  BatteryPowerTooLow = 28,
  OutlierTime = 29,
  OutlierDistance = 30,
  NoCommunicationPowerLost = 31,
  BackupBatteryTooLow = 32,
  MissingDriver = 33,
  GpsDrift = 34,
  DelayedMessages = 35,
  GpsStuck = 36,
  FuelLevelSensorStuck = 37,
  FuelLevelSensorOutOfBounds = 38,
  FuelLevelSensorNoData = 39,
}

export enum ImmobilizationStatus {
  Unknown = 0,
  NotImmobilised = 1,
  Immobilised = 2,
  ImmobiliationPending = 3,
  DeimmobilizationPending = 4,
}

export enum Uom {
  None = 0,
  Messages = 1,
  Meters = 2,
  Count = 3,
  FuelPercentage = 4,
  TemperatureInCelcius = 5,
  Minutes = 6,
  Voltage = 7,
  Seconds = 8,
  FuelLevel = 9,
}

export enum DistanceUnits {
  Kilometers = 0,
  Miles = 1,
}

export enum VolumeUnits {
  Liters = 0,
  Imperial_Gallon = 1,
  US_Liquid_Gallon = 2,
}

export enum MapType {
  Wiki = 1,
  OpenStreetMapsVector = 2,
  OpenStreetMapsVectorBright = 3,
  OpenStreetMap = 4,
  OpenStreetMapLocationSolutions = 5,
  OpenStreetMapCarto = 6,
  GoogleMaps = 7,
  GoogleMapsHybrid = 19,
  GoogleMapsSatellite = 8,
  BingMaps = 9,
  BingMapsSatellite = 10,
  Esri = 11,
  CartoDB = 12,
  MapboxVector = 13,
  MapboxStreets = 14,
  MapboxOutdoors = 15,
  MapboxSatellite = 16,
  MapboxSatelliteStreets = 17,
  ArcGIS = 18,
}

export enum FormMode {
  read = 1,
  add = 2,
  edit = 3,
}

export enum ScheduleActions {
  "Unknown" = 0,
  "Fix wiring" = 1,
  "Check device" = 2,
  "Replace device" = 3,
  "Update device firmware" = 4,
  "New roadtest" = 5,
}

export enum Severity {
  Unknown = 0,
  low = 1,
  Medium = 2,
  High = 3,
  Top = 4,
  Critical = 5,
}

export enum AccountType {
  Unknown = 0,
  Trial = 1,
  Normal = 2,
}

export enum NotificationCode {
  None = 0,
  ApiError = 1,
}

export enum Form {
  Unknown = 0,
  Point = 1, // is how we define a Circle
  MultiPoint = 2,
  Polygon = 3,
  LineString = 4,
  MultiLineString = 5,
}

export enum ActionType {
  "Import" = 1,
  "Export" = 2,
}

export enum ExportFileType {
  "CSV" = 0,
  "XLSX" = 1,
}

export enum ReportCategory {
  "FleetBased" = 1,
  "TripBased" = 2,
  "DriverBased" = 3,
  "SensorBased" = 4,
  "MaintenanceBased" = 5,
  "FuelBased" = 6,
  "GeofenceBased" = 7,
  "ResellerBased" = 8,
  "Behaviour" = 9,
}

export enum StorageType {
  "Unknown" = 0,
  "LocalStorage" = 1,
  "SessionStorage" = 2,
  "Cookie" = 3,
  "Database" = 4,
  "DatabasePrefetch" = 5,
}

export enum EntityType {
  "Unknown" = 0,
  "Device" = 1,
  "Asset" = 2,
  "Account" = 3,
  "Driver" = 4,
  "Geofence" = 5,
  "Maintenance" = 6,
  "MaintenanceType" = 7,
  "Fuel" = 8,
  "User" = 9,
  "AssetType" = 10,
  "Reseller" = 11,
  "UserContact" = 12,
  "UserContactGroup" = 13,
  "DeviceSettingChanges" = 14,
  "Issue" = 15,
  "DataTrigger" = 16,
  "AssetGroup" = 17,
  "DriverGroup" = 18,
  "GeofenceGroup" = 19,
  "DeviceExternal" = 20,
  "Trigger" = 21,
  "UserRole" = 22,
  "Trailer" = 23,
  "WorkingHours" = 24,
  "Warehouse" = 25,
  "WarehouseZone" = 26,
  "Project" = 27,
  "Sims" = 28,
  "TriggerSchedule" = 29,
  "ServiceAnnouncement" = 30,
  "ClientAnnouncement" = 31,
  "SensorTemplate" = 32,
  "Trip" = 33,
  "AssetDriver" = 34,
  "AssetCalibration" = 35,
  "WebhookEndpoint" = 36,
  "ReportSubscription" = 37,
  "ReportTemplate" = 38,
  "Garage" = 39,
  "Episode" = 40,
  "ReportExecution" = 41,
  "ShareToken" = 42,
  "CustomCommand" = 43,
  "HierarchyGroup" = 44,
}

export enum UserModalAction {
  "Unknown" = 0,
  "UpdatePassword" = 1,
  "NewUserWelcome" = 2,
}

export enum BatchAction {
  "Unknown" = 0,
  "Archive" = 1,
  "Unarchive" = 2,
  "AddLogitem" = 3,
  "RequestLatestLocation" = 4,
  "AddToGroup" = 5,
  "ActivateDevice" = 6,
  "UpdateFields" = 7,
  "DeviceSettings" = 8,
  "Delete" = 9,
  "DeviceSettingChanges" = 10,
  "ChangeReseller" = 11,
  "MoveToAccount" = 12,
  "ReplaceDevice" = 13,
  "RemoveFromAccount" = 14,
  "WaslRegister" = 15,
  "WaslUnregister" = 16,
  "DeleteHistory" = 17,
  "ResetDevice" = 18,
  "DeviceSensors" = 19,
  "VirtualSensors" = 21,
  "CreateInstantReport" = 22,
  "CalibrateOdo" = 23,
  "RerunTrips" = 24,
  "RefreshDeviceStateFromDatabase" = 25,
  "CreateBasicTrigger" = 26,
  "ResetState" = 27,
  "SendCustomCommand" = 28,
}

export enum BasicTriggerCase {
  "ExternalPowerLost" = 1,
  "FixedSpeeding" = 2,
  "Geofence" = 3,
  "UseOutsideSchedule" = 4,
  "UseOutsideGeofences" = 5,
}

export enum InstantReportSelection {
  "Trips" = 1,
  "TripsWithDetails" = 2,
  "Utilization" = 7,
  "GeofenceEpisodes" = 9,
}

export enum BatchStatus {
  "Unknown" = 0,
  "Pending" = 1,
  "InProgress" = 2,
  "Success" = 3,
  "Error" = 4,
  "Warning" = 5,
}

export enum BatchItemStatus {
  "Unknown" = 0,
  "Pending" = 1,
  "Success" = 2,
  "Error" = 3,
}

export enum DeviceOutput {
  "Undefined" = null,
  "None" = 0,
  "OnCommand Starter Interrupt" = 1,
  "Door Lock" = 2,
  "Door Control" = 3,
  "Automatic Starter Interrupt" = 4,
  "Door Unlock" = 5,
  "Sound Buzzer Violations" = 6,
  "Red Led" = 7,
  "Green Led" = 8,
  "Sound Buzzer Driver Tags" = 9,
}

export enum DeviceAuxiliary {
  None = 0,
  RfidReaderInverted = 1,
  iButtonReader = 2,
  OneWireTemperatureSensor1 = 4,
  OneWireTemperatureSensor2 = 8,
  CanBusObdII = 16,
  CanBusJ1939 = 32,
  CanBusJ1708 = 64,
  RfidReader = 128,
  GarminFleetManagementInterface = 256,
  Squarell = 512,
  VepamonFuelSensor = 1024,
  VssSignal = 2048,
  RfidReaderControlledStarting = 4096,
  RfidReaderControlledDoor = 8192,
  iButtonControlledStarting = 16384,
  HurricaneFuelSensorAnalogue = 32768,
  RpmByPulseCounter = 65536,
  PZSwitchV1 = 131072,
  PZSwitchV2 = 262144,
  PZSwitchV5 = 524288,
  FreeValue = 1048576,
  TechnotonSerialFuelLevelSensor = 2097152,
  RcsFuelSensor = 4194304,
  BuzzerDisabledByAnyiButton = 8388608,
  OneWireRfid = 16777216,
  BuzzerEnabledByViolation = 33554432,
  TechnotonAnalogFuelLevelSensor = 67108864,
  BuzzerDisabledByWhitelistediButton = 134217728,
  BuzzerDisabledByAnyRfidTag = 268435456,
  BuzzerDisabledByWhitelistedRfidTag = 536870912,
  RfidReaderDecimal = 1073741824,
  RcsFuelSensor_RS485_1 = 2147483648,
  RcsFuelSensor_RS485_2 = 4294967296,
  VepamonFuelSensorFlags = VepamonFuelSensor,
  RcsFuelSensorFlags = RcsFuelSensor | RcsFuelSensor_RS485_1 | RcsFuelSensor_RS485_2,
  CanbusFuelSensorFlags = CanBusObdII | CanBusJ1939 | CanBusJ1708,
  AnalogFuelSensorFlags = TechnotonAnalogFuelLevelSensor | HurricaneFuelSensorAnalogue,
  FuelSensorFlags = VepamonFuelSensorFlags |
    RcsFuelSensorFlags |
    CanbusFuelSensorFlags |
    AnalogFuelSensorFlags |
    TechnotonSerialFuelLevelSensor,
  DriverFlags = iButtonReader |
    OneWireRfid |
    iButtonControlledStarting |
    RfidReaderControlledStarting |
    RfidReaderControlledDoor |
    BuzzerDisabledByWhitelistediButton |
    BuzzerDisabledByAnyRfidTag |
    BuzzerDisabledByWhitelistedRfidTag |
    RfidReaderDecimal,
  BLE_Escort_Motion1 = 34359738368,
  BLE_Escort_Motion_2 = 68719476736,
  BLE_Escort_Motion_3 = 137438953472,
  BLE_Escort_Motion_4 = 274877906944,
  TemperatureSensor1 = 549755813888,
  TemperatureSensor2 = 1099511627776,
  TemperatureSensor3 = 2199023255552,
  TemperatureSensor4 = 4398046511104,
  HumiditySensor1 = 8796093022208,
  HumiditySensor2 = 17592186044416,
  RotationSensor1 = 35184372088832,
  RotationSensor2 = 70368744177664,
  RotationSensor3 = 140737488355328,
  RotationSensor4 = 281474976710656,
}

export enum DeviceEpisode {
  "Unknown" = 0,
  "Rpm High" = 1,
  "Rpm Medium" = 2,
  "Rpm Low" = 3,
  "Speed High" = 4,
  "Speed Medium" = 5,
  "Speed Low" = 6,
  "Torque High" = 7,
  "Torque Medium" = 8,
  "Torque Low" = 9,
  "Accel High" = 10,
  "Accel Medium" = 11,
  "Accel Low" = 12,
  "Decel High" = 13,
  "Dece lMedium" = 14,
  "Decel Low" = 15,
  "Coasting" = 16,
  "Cruising" = 17,
  "Driving" = 18,
  "Idling" = 19,
  "Harsh Braking" = 20,
  "Number Of Stops" = 21,
  "Retarder" = 22,
  "Engine Seconds" = 23,
  "Accel" = 24,
  "Decel" = 25,
  "Cornering" = 26,
  "Fuel" = 27,
  "Server Side Speed Over 80" = 30,
  "Server Side Speed Over 90" = 31,
  "Server Side Speed Over 100" = 32,
  "Server Side Speed Over 110" = 33,
  "Server Side Speed Over 120" = 35,
  "Server Side Speed Over 130" = 35,
  "Server Side Speed Over 105" = 36,
  "Server Side Speed Over 140" = 37,
  "RoadSpeedViolated" = 40,
  "NoGoViolation" = 99,
  "GeoFenceState" = 100,
  "BanTimeViolation" = 101,
  "Driving Hours Daily Violation Band1" = 102,
  "Driving Hours Daily Violation Band2" = 103,
  "Driving Hours Daily Violation Band3" = 104,
  "Driving Hours Running ViolationBand1" = 105,
  "Driving Hours Running ViolationBand2" = 106,
  "Driving Hours Running ViolationBand3" = 107,
  "Inside Geofence Time Threshold Violation" = 110,
  "Outside Geofence Time Threshold Violation" = 111,
  "Outside All Geofences TimeThresholdViolation" = 112,
  "Outside All Geofences EventDoorSensorOnViolation" = 113,
  "Outside All Geofences EventDoorSensorOffViolation" = 114,
  "Outside All Geofences EventSecondaryDoorSensorOnViolation" = 115,
  "Outside All Geofences EventSecondaryDoorSensorOffViolation" = 116,
  "Outside All Geofences EventIgnitionOnViolation" = 120,
  "Outside All Geofences EventIgnitionOffViolation" = 121,
  "Outside All Geofences EventIdlingStartViolation" = 122,
  "Outside All Geofences EventIdlingEndViolation" = 123,
  "Outside All Geofences EventPowerOnViolation" = 124,
  "Outside All Geofences EventPowerOffViolation" = 125,
  "Idling Started" = 130,
  "Idling Stopped" = 131,
  "External Power Off" = 150,
  "External Power On" = 151,
  "Alert" = 152,
  "Battery" = 153,
  "PTO" = 154,
  "Rfid" = 155,
  "Unknown Rfid" = 156,
  "Door Sensor" = 157,
  "Secondary Door Sensor" = 158,
  "Lower Boundary For IoEpisodes" = 199,
  "Ignition Active" = 200,
  "Ignition Inactive" = 201,
  "External Power Active" = 202,
  "External Power Inactive" = 203,
  "Input1 Active" = 211,
  "Input2 Active" = 212,
  "Input3 Active" = 213,
  "Input4 Active" = 214,
  "Input5 Active" = 215,
  "Input6 Active" = 216,
  "Input1 Inactive" = 221,
  "Input2 Inactive" = 222,
  "Input3 Inactive" = 223,
  "Input4 Inactive" = 224,
  "Input5 Inactive" = 225,
  "Input6 Inactive" = 226,
  "Output1 Active" = 231,
  "Output2 Active" = 232,
  "Output3 Active" = 233,
  "Output4 Active" = 234,
  "Output5 Active" = 235,
  "Output6 Active" = 236,
  "Output1 Inactive" = 241,
  "Output2 Inactive" = 242,
  "Output3 Inactive" = 243,
  "Output4 Inactive" = 244,
  "Output5 Inactive" = 245,
  "Output6 Inactive" = 246,
  "Upper Boundary For Io Episodes" = 299,
  "Too Many Episodes" = 300,
  "Buffered Communication" = 301,
  "No Communication" = 302,
  "Fuel Is Missing" = 303,
  "NoGpsAtStartOfTrip" = 304,
}

export enum IconColors {
  "Black" = 0,
  "Blue" = 1,
  "Green" = 2,
  "Grey" = 3,
  "Red" = 4,
  "Yellow" = 5,
}

export enum LocationEventType {
  "Unknown" = 0,
  "Simple TLM" = 1,
  "Speeding" = 2,
  "Battery" = 4,
  "Alert" = 8,
  "Client Side GeoFence" = 16,
  "Taxi" = 18,
  "Shutdown" = 32,
  "Movement Start" = 64,
  "Movement Stop" = 128,
  "RPM Warning" = 256,
  "Movement Motion" = 512,
  "Secondary Door Sensor" = 1024,
  "Call Triggered" = 2048,
  "Startup" = 4096,
  "Speeding Stopped" = 8192,
  "Secondary Pto" = 16384,
  "Ignition On" = 32768,
  "Ignition Off" = 65536,
  "Speeding Started" = 131072,
  "iWire Temperature 1 Out Of Bounds" = 262144,
  "iWire Temperature 2 Out Of Bounds" = 524288,
  "Heartbeat" = 1048576,
  "Temperature Out Of Bounds" = 2097152,
  "Heading Change" = 4194304,
  "Wake Up From Sleep" = 8388608,
  "PTO" = 16777216,
  "External Power Off" = 33554432,
  "Harsh Accelleration" = 67108864,
  "Harsh Braking" = 134217728,
  "Analog 1" = 268435456,
  "Analog 2" = 536870912,
  "Rfid" = 1073741824,
  "Driver SeatBelt Warning" = 2147483648,
  "Idle" = 4294967296,
  "Unknown Rfid" = 8589934592,
  "Door Lock Manipulation" = 17179869184,
  "Pulse Counter" = 34359738368,
  "Speeding Level2" = 68719476736,
  "Four Wheel Drive" = 137438953472,
  "Passenger Seatbelt Warning" = 274877906944,
  "PZ Private" = 549755813888,
  "PZ Business" = 1099511627776,
  "External Power On" = 2199023255552,
  "Door Sensor" = 4398046511104,
  "PendingD TC" = 8796093022208,
  "DTC" = 17592186044416,
  "Delayed Gps" = 35184372088832,
  "Towing Alert" = 70368744177664,
  "Harsh Cornering" = 140737488355328,
  "iWire Temperature 1 Inside Bounds" = 281474976710656,
  "iWire Temperature 2 Inside Bounds" = 562949953421312,
  "GPS Antenna Violation" = 1125899906842624,
  "BlackListed DriverTag" = 2251799813685248,
  "Fuel Only" = 4503599627370496,
  "Accident" = 9007199254740992,
  "Cement Offloading" = 18014398509481984,
  "AC" = 36028797018963968,
  "BLESensor1Working" = 72057594037927936,
  "BLESensor2Working" = 144115188075855872,
  "BLESensor3Working" = 288230376151711744,
  "BLESensor4Working" = 576460752303423488,
  "BLESensor1Door" = "576460752303423489",
  "BLESensor2Door" = "576460752303423490",
  "BLESensor3Door" = "576460752303423491",
  "BLESensor4Door" = "576460752303423492",
}

export const EventTypesInput = [
  [LocationEventType["Alert"], true],
  [LocationEventType["Taxi"], true],
  [LocationEventType["Secondary Door Sensor"], true],
  [LocationEventType["Secondary Pto"], true],
  [LocationEventType["Ignition On"], true],
  [LocationEventType["PTO"], true],
  [LocationEventType["Door Lock Manipulation"], true],
  [LocationEventType["Pulse Counter"], true],
  [LocationEventType["Four Wheel Drive"], true],
  [LocationEventType["Driver SeatBelt Warning"], true],
  [LocationEventType["Passenger Seatbelt Warning"], true],
  [LocationEventType["PZ Private"], true],
  [LocationEventType["PZ Business"], true],
  [LocationEventType["External Power On"], true],
  [LocationEventType["Door Sensor"], true],
  [LocationEventType["Cement Offloading"], true],
  [LocationEventType["AC"], true],
  [LocationEventType["BLESensor1Working"], true],
  [LocationEventType["BLESensor2Working"], true],
  [LocationEventType["BLESensor3Working"], true],
  [LocationEventType["BLESensor4Working"], true],
  [LocationEventType["BLESensor1Door"], true],
  [LocationEventType["BLESensor2Door"], true],
  [LocationEventType["BLESensor3Door"], true],
  [LocationEventType["BLESensor4Door"], true],
];

export enum LocationTypes {
  Unknown = 0,
  CellSite = 1,
  GPS = 2,
  Address = 4,
  GPSByCellSiteEstimation = 8,
  GPSByLacSiteEstimation = 16,
  City = 32,
  PostCode = 64,
  State = 128,
  CountryByReverseGeoCode = 256,
  CountryByMCC = 512,
  ManualGpsFix = 1024,
  Wifi = 2048,
  GPSByWifiEstimation = 4096,
  GPSByGoogleCellDb = 8192,
  GPSByOpenCellDb = 16384,
  NoGeocodeResults = 32768,
  Locality = 65536,
  AdministrativeAreaLevel1 = 131072,
  AdministrativeAreaLevel2 = 262144,
  SubLocalityLevel1 = 2 * 262144,
  StreamLine = 4 * 262144,

  LocationWithLatLon = GPS |
    GPSByCellSiteEstimation |
    GPSByLacSiteEstimation |
    GPSByWifiEstimation |
    GPSByGoogleCellDb |
    GPSByOpenCellDb,
}

export enum TriggerResultName {
  Speeding = 1,
  Idling = 2,
  Geofence = 3,
  External = 4,
  IO = 5,
  Other = 6,
  Seatbelt = 7,
  RPM = 8,
  CrossOver = 9,
  Work = 10,
  Temperature = 11,
  Acceleration = 12,
  Braking = 13,
  Cornering = 14,
}

export enum TriggerRules {
  DeviceOutsideSpecs = 1,
  DeviceState = 2,
  Geofences = 3,
  InputOutputPorts = 4,
  IOLogical = 5,
  Events = 6,
  Schedule = 7,
  // Speed = 8,
  // Temperature = 9,
  // Weight = 10,
  // Humidity = 11,
  // ExternalBatteryVoltage = 12,
  AvgDeviationDetection = 13,
  // Rpm = 14,
  VirtualSensor = 15,
  RoadSpeed = 16,
  DeviceSensor = 17,
  NoData = 18,
}

export enum TriggerDeviceSensors {
  Speed = 1,
  Temperature = 2,
  Weight = 3,
  Humidity = 4,
  ExternalBatteryVoltage = 5,
  Rpm = 6,
  Accel = 7,
  Decel = 8,
  Cornering = 9,
  EngineCoolantTemperature = 10,
  Analog1 = 11,
  Analog2 = 12,
  Analog3 = 13,
  Analog4 = 14,
  BatteryLevel = 15,
}

export enum DomainEventInput {
  Unknown = 0,
  DeviceCreated = 1,
  DeviceUpdate = 2,
  DeviceDeleted = 3,
  AssetCreated = 4,
  AssetUpdate = 5,
  AssetDeleted = 6,
  DeviceProvisioned = 7,
  DeviceActivated = 8,
}

export enum DeviceStateProperties {
  Unknown = 0,
  Ignition = 1,
  ExternalPower = 2,
  Idling = 3,
  Communicating = 4,
  GpsFixed = 5,
}

export enum virtualSensorOutputType {
  None = 0,
  Working = 1,
  CrossOver = 2,
  Idling = 3,
  RotatingLeft = 4,
  RotatingRight = 5,
  Open = 6,
  Closed = 7,
  Disconnected = 8,
}

export enum Conditions {
  False = "false",
  True = "true",
}

export enum InsideOutsideSettings {
  Inside = 1,
  Outside = 2,
}

export enum TriggerName {
  Unknown = 0, // The value for error conditions
  Speed = 1, // A Speed Trigger, with LowerBound and UpperBound values
  Battery = 2, // A Battery Low Event from the device
  ShutDown = 3, // A Shutdown event, Only on LcMobile
  Alert = 4, // Alert
  EngineRunning = 5, // Any message where Ignition is On (including Ignition Off)
  StartUp = 6,
  Ignition = 7, // Ignition turned on or off
  LocationReceived = 8, // Trigger fires for EVERY location
  Temperature = 11, // Check for temperature events, serverside
  RPM = 13, // Device Side RPM Violation. See note #1)
  Idling = 14, // Server Side check for idling devices
  ExternalPower = 16, // External power has been lost
  HarshAccelleration = 17, // Device Side Acceleration threshold violation. See note #1)
  HarshBraking = 18, // See note #1)
  TowingAlertStart = 19, // Device Side detection of Speed whenever Ignition is off
  iWireTemperature1 = 21, // Uses TemperatureRangeId as single value
  iWireTemperature2 = 22, // Uses TemperatureRangeId as single value
  HarshCornering = 30, // See note #1)
  PTO = 34,
  DoorSensor = 35,
  Rfid = 36,
  SettingChange = 37, // A specific Setting Change occurred.
  MotionSensor = 38, // Device Side Motion Sensor fired
  ServerSideBatteryLow = 39,
  SecondaryPto = 40,
  SecondaryDoorSensor = 41,
  DoorLockManipulation = 42,
  UnknownRfid = 43,
  PZPrivate = 44,
  PZBusiness = 45,
  DriverMaintenanceAlert = 46, // Uses the MaintenanceThreshold percentage as single value, driver is determined when event happens, if no driver is found the ucg of this data trigger is used
  CalibrationAlert = 47,
  TripDistanceAlert = 48,
  NoGpsAtStartOfTrip = 49,
  Audit = 50,
  Accident = 51,
  DTC = 52,
  CustomEvent = 53,
  Input = 54, // SingleValue = LocationEventType, LowerBound = 0 if corresponding io = 0 starts event, LowerBound = 1 if corresponding io = 1 starts event
  RoadSpeed = 55,
  GpsLost = 56,
  StateMachine = 57,
}

export enum TriggerInputSource {
  CommunicationStateUpdated = 0,
  Timer = 1,
  Crud = 2,
  TotalWeightEvent = 3,
  TemperatureEvent = 4,
  HumidityEvent = 5,
  GeofenceEvent = 6,
}

export enum DeviationType {
  Absolute = 0,
  Percentage = 1,
}

export enum DeviceSettingIds {
  Unknown = -1,
  HomeInterval = 0, // Home / Ignition On
  RoamingInterval = 1, // Home / Ignition Off
  MotionInterval = 2, // Roaming / Ignition On
  NoGpsInterval = 3, // Roaming / Ignition Off
  PositionExchange = 4, // Whether or not messages should be exchanged. Used by Mobile
  AutomaticStartup = 5,
  UdpSendingAttempts = 6, // How many times to try to send an UDP message, before resorting to heavier methods (such as TCP or SMS). Used by Mobile
  CriticalBatteryLevel = 7, // The lowerbound on the battery level before sending a notification message to the server. Used by Mobile
  LocationBufferSize = 8, // The size of the location buffer on the device, before transmitting. Used by Mobile
  LocationBufferInterval = 9, // The interval on which to store locations in the location buffer. Used by Mobile
  MaximumNumberSms = 10, // The maximum number of SMS''s the device may utilize. Used by Mobile
  SpeedLimit = 11, // The lowerbound on the speed limit, before sending a notifier to the server. Used by Mobile
  NotMovingMargin = 12, // How many meters of margin is used to determine movement. Used by Mobile
  NotMovingSkip = 13, // How many times to skip sending a message due to not moving. Used by Mobile
  GeoFenceInfo = 14, // Accounting information for setting Client Side Geofences. Used by Enfora
  HideSettings = 15, // Is the menu enabled on the device. Used by Mobile.
  SettingsPassword = 16, // The on device menu password. Used by Mobile.
  // IO1 = 17,
  // IO2 = 18,
  // IO3 = 19,
  OperatingMode = 20, // The operating mode for mobiles. 0 = Interval, 1 = Automatic
  Manual = 21, // Used to send a custom config to the devce (for example custom AT commands)
  ReportingTimeStart = 22, // The time of day the reporting should start (int value 0 - 2359)
  ReportingTimeStop = 23, // The time of day the reporting should stop (int value 0 - 2359)
  Analog1 = 24,
  Analog2 = 25,
  // RMacUpdatingWhenLocationIsDone = 26,
  EnforaVersionUpgrade = 27,
  Temperature1OutOfBounds = 28, // First use: Atrack 1-wire temperature sensor #1
  Temperature2OutOfBounds = 29, // First use: Atrack 1-wire temperature sensor #2

  // These should be the new Interval Setting Id's
  HomeMotionInterval = 30,
  HomeNoMotionInterval = 31,
  RoamingMotionInterval = 32,
  RoamingNoMotionInterval = 33,

  HarshAcceleration = 34,
  HarshDeceleration = 35,
  HarshCornering = 36,
  RfidListChange = 37,
  OnCommandStarterInterrupt = 38,
  AutomaticStarterInterrupt = 39,
  GarminMessage = 40,
  GarminStop = 41,
  EnforaAddConnectionMessageQueueCheck = 42,
  DoorOpen = 43,
  DoorClose = 44,
  EnforaRemoveCgattRenewalFromModemStartup = 45,
  EnforaConvertRoamingToHomeTimerInCaseOfWeirdRegistrationStatus = 46,
  RestrictRoamingToOperatorList = 47,
  EnableGps = 48,
  EnforaAddDutchVoiceCallCallback = 49,
  EnforaNetmonPlus = 50,
  EnforaReplaceNetmonPlusWithATNetmon = 51,
  EnforaNetmonPlusDecreaseThresholdTo2 = 52,
  Reset = 53,
  EnforaMaintainIOStateDuringResetFor2338 = 54,
  iButtonWhiteListChange = 55,
  DeleteGeoFenceInfo = 56,
  EnableiButtonWhiteOTA = 57,

  // JK: This one seems to be overloaded.
  // MD and Ruptela upload their configurations into the DeviceConfigurationInAtCommands-field
  // while Diwei and Meitrack upload their version details.
  // Preferably use GetVersionDetails to accomplish retrieving the version details.
  GetConfiguration = 58,
  AddRules = 59,
  RemoveRules = 60,
  FOTA = 61,
  ShowFileList = 62,
  UploadFileToFtp = 63,
  FtpUploadStatus = 64,
  Buzzer = 65,
  AddLoqaterLeds = 66,
  DriverTagBlackListChange = 67,
  AddHeadingChangeEvent = 68,
  iButtonWhiteListClear = 69,
  RemoveDpsCommunication = 70,
  AddDpsCommunication = 71,
  AddLoqaterLedsNoBeep = 72,
  RemoveLoqaterLeds = 73,
  RemoveLoqaterLedsNoBeep = 74,
  RemoveHeadingChangeEvent = 75,
  RetrieveDevicePassword = 76, // The Diwei devices have a backdoor for retrieving the device password. Insanity
  SetDevicePassword = 77,
  DioneCommand = 78,
  HeartBeatInMinutes = 79,
  HeadingChangeThresholdInDegrees = 80,
  SleepMode = 81,
  RawAtCommand = 82, // Used to send a raw AT commands to the device, so better make sure you know what you are doing.
  ActivateMetaDevice = 83,
  RollOver = 84, // Enabling/Disabling rollover detection
  ConfigureRfidReader = 85,
  ConfigureRfidControlledStarting = 86,
  ConfigureIButtonReader = 87,
  ConfigureIButtonControlledStarting = 88,
  ConfigureTemperatureSettings = 89,
  ConfigureDigitalInput = 90,
  ConfigureApn = 91,
  ConfigureConnectionSettings = 92,
  ConfigureAutomaticControlledStarting = 93,
  PairWiMeta = 94,
  SetConfiguration = 95,
  ConfigureBuzzerDisabledByAnyiButton = 96,
  ConfigureOneWireRfid = 97,
  ConfigureIgnitionDetection = 98,
  UnpairWiMeta = 99,
  ConfigureTripDebounceDuration = 100,
  ConfigureDriverBehavior = 101,
  ConfigureBuzzerDisabledByWhitelistediButton = 102,
  ConfigureCommunicationProtocol = 103,
  ConfigureRs485Sensor = 104,
  GetVersionDetails = 105,
  getImeiccid = 106,
}

export enum DeviceSettingExchangeStatus {
  Scheduled = 0, // The setting is scheduled to be transmitted to the device
  Pending = 1, // The device and the server are handshaking
  Accepted = 2, // The device accepted the settings - used only in the backup table
  Rejected = 3, // The device rejected the settings - used only in the backup table
  OverruledByServer = 4, // The setting was set on the server and the device at the same time.
  Immediate = 5, // This setting was sent without the Parser, using Text Messages
  RetryCountExceeded = 6, // This setting was sent several times to the device but the device never replied
  Canceled = 7, // This setting was cancelled by a UI action.
}

export enum AnalogFunctions {
  None = 0,
  // Digital = 1,
  LC_ACC_11 = 3,
  BATTERY = 7,
  TechnotonAnalogFuelSensor = 8,
  CapacitorFuelSensor = 9,
  GNOM_DDE = 10,
  GNOM_TP = 11,
  Eurosens_Difference_01 = 12,
  Eurosens_Difference_02 = 13,
  Veinasa_HRTM030 = 14,
  Veinasa_HTW_IC00X_H = 15,
  Veinasa_HTW_IC00X_T = 16,
  AnalogToDigitalInput1 = 17,
  AnalogToDigitalInput2 = 18,
  AnalogToDigitalInput3 = 19,
  AnalogToDigitalInput4 = 20,
  AnalogToDigitalInput5 = 21,
  AnalogToDigitalInput6 = 22,

  Voss_Strain_Weight = 25,

  WeightSensorFlags = Veinasa_HRTM030 |
    Veinasa_HTW_IC00X_H |
    Eurosens_Difference_01 |
    Eurosens_Difference_02 |
    Voss_Strain_Weight,

  FuelSensorFlags = TechnotonAnalogFuelSensor | CapacitorFuelSensor | Eurosens_Difference_01 | Eurosens_Difference_02,
}

export enum ThirdPartyProviders {
  WASL = 1,
  SFDA = 2,
  TOW = 3,
}

export enum DeviceTypes {
  MigratedMobile = 0, // TODO: rename to unknown
  MyLocation = 1,
  EnforaMiniMt = 2,
  Ocellus = 3,
  EnforaSpiderMt = 4,
  TrimbleTM3000 = 5,
  WebTech5000 = 6,
  WebTech7000 = 7,
  GL100 = 8,
  OcellusT100 = 9,
  Tk102 = 10,
  OBD_410 = 11,
  WondeProud = 12,
  AdcoDcs = 13,
  OrionChoco = 14,
  OrionSpeedTrack = 15,
  OrionEasyTrack = 16,
  SymbianOld = 17,
  ATrackAT1 = 18,
  ATrackAT3 = 19,
  ATrackAT5 = 20,
  EnforaSpiderWithRmac = 21,
  EnforaSpider2500 = 22,
  EnforaSpider3000 = 23,
  TT8850 = 24,
  EnforaSpiderMtWithAccel = 25,
  EnforaSpiderMt2238 = 26,
  GL200 = 27,
  CalAmpLmu1100 = 28,
  ATrackAT1EPro = 29,
  EnforaSpiderMt2356 = 30,
  Blackberry = 31,
  BrickHouseBB = 32,
  EnforaSpider4000 = 33,
  ATrackAT5Y = 34,
  MobileDevicesC4Evo = 35,
  MobileDevicesC4Max = 36,
  LoqaterV1 = 37,
  LoqaterV2 = 38, // Actually MD C4D
  LoqaterV5 = 39,
  EnforaSpiderMt1200 = 40,
  MobileDevicesC4Obd2 = 41,
  EnforaSpiderAT3000 = 42,
  WindowsMobileProf = 43,
  WindowsMobileStandard = 44,
  Android = 45,
  JavaME = 46,
  iPhone = 47,
  TK116 = 48,
  TKOBD = 49,
  // the PA webservice does not deal nicely with gaps in enums that it uses. Can be replaced when we enter a new device in the system
  FillerDeviceForPaWsdl = 50,
  Symbian = 51,
  EnforaSpider4100 = 52,
  MirageFt100 = 53,
  MirageFt1000 = 54,
  RuptelaFmEco4 = 55,
  RuptelaFmPro4 = 56,
  TK119 = 57,
  MetaT30 = 58,
  // Same as D40, but without any activity on the can wires
  MobileDevicesC4MaxLite = 59,
  ATrackAL1 = 60,
  ATrackAX9 = 61,
  CVNaviSVT80 = 62,
  TeltonikaFMMxx = 63,
  MeitrackT366 = 64,
  TeltonikaFMAxx = 65,
  ConcoxJV200 = 66,
  BCEFMS500Light = 67,
  Wialon = 68,
  BCEFamily = 69,
  BCEFMS500LightPlus = 70,
  Flespi = 71,
  TeltonikaFMBxx = 72,
  ATrackAX7 = 73,
  BCEFMS500ONE = 74,
  TeltonikaFMB001 = 75,
  TeltonikaFMB010 = 76,
  TeltonikaFMT100 = 77,
  TeltonikaFMB630 = 78,
  TeltonikaFMB640 = 79,
  ATrackAX11 = 80,
  TeltonikaFMB920 = 81,
  TeltonikaFMM640 = 82,
  TeltonikaFMB020 = 83,
  RuptelaTrace5 = 84,
  RuptelaHCV5 = 85,
  ConcoxJMVL03 = 86,
  RuptelaLCV5 = 87,
  XirgoXG3780 = 88,
  ThermoKing = 89,
  Galileosky7X = 90,
  TpFlyTP1SF = 91,
  StreamLine = 92,
  TeltonikaFMC130 = 93,
  RuptelaHCV5Light = 94,
  Dahua = 95,
}

export enum NotificationType {
  DeprecatedValue1 = 0,
  DeprecatedValue2 = 1,
  MobileClientDownload = 2,
  // GeneralTextMessage, // obsolete
  ConfigTextMessage = 4,
  TwitterStatusUpdate = 5,
  NewPaymentUserRegistration = 6,
  NewUserWelcome = 7,
  LivecontactsUserInvitation = 8,
  LostPassword = 9,
  PaymentDeviceActivated = 10,
  PaymentSubscriptionActiveFailed = 11,
  ContactMessage = 12,
  PaymentAfterProcured = 13,
  TrialPeriodExpired = 14,
  SubscriptionExpired = 15,
  PendingPaymentExpired = 16,
  GetLocation = 17,
  Geo = 18,
  Battery = 19,
  Speed = 20,
  Shutdown = 21,
  ClientGeo = 22,
  Panic = 23,
  IO1 = 24,
  IO2 = 25,
  Ignition = 26,
  Startup = 27,
  GeneralEmail = 28,
  Maintenance = 29,
  ExternalPowerLostNotification = 30,
  Temperature = 31,
  // ObdSettingMessage, // Obsolete
  Excel = 33,
  ClientSideBreakdown = 34,
  VtronSmsErrorNotification = 35,
  ViewerInvitationNotification = 36,
  TheftDetected = 37,
  TowingAlertStart = 38,
  SettingsChange = 39,
  Movement = 40,
  CloudReportSubscription = 41,
  WeGoDoorOpen = 42,
  WeGoDoorClose = 43,
  WeGoWakeup = 44,
  DoorLockManipulation = 45,
  Rfid = 46,
  DoorSensor = 47,
  PTO = 48,
  DeviceReset = 49,
  InsideTimeViolationMessage = 50,
  CloudReportSubscriptionFailed = 51,
  Idling = 52,
  IgnitionOnOutsideSchedule = 53,
  EngineRunning = 54,
  CalibrationAlert = 55,
  TripDistanceAlert = 56,
  LocationReceived = 57,
  NoGpsForStartOfTripNotification = 58,
  Audit = 59,
  Accident = 60,
  DeviceAudit = 61,
  DTC = 62,
  CustomEvent = 63,
  DriverNotification = 64,
  RoadSpeed = 65,
  GpsFixLost = 66,
  ContactGroupChanged = 67,
  ThresholdChanged = 68,
  IdlingOutsideAllGeofences = 69,
  OutsideAllGeofencesEventType = 70,
  NotCommunicating = 71,
  DriverCalibrationAlert = 72,
  DriverMaintenanceAlert = 73,
  DriverLicenseExpiryAlert = 74,
  DriverInspectionAlert = 75,
}

export enum InputPorts {
  Input1 = 1,
  Input2 = 2,
  Input3 = 3,
  Input4 = 4,
  Input5 = 5,
  Input6 = 6,
  ExternalPower = 7,
  Ignition = 8,
}

export enum OutputPorts {
  Output1 = 1,
  Output2 = 2,
  Output3 = 3,
  Output4 = 4,
}

export enum RuleIOPorts {
  Input1 = 1,
  Input2 = 2,
  Input3 = 3,
  Input4 = 4,
  Input5 = 5,
  Input6 = 6,
  ExternalPower = 7,
  Ignition = 8,
  Output1 = 9,
  Output2 = 10,
  Output3 = 11,
  Output4 = 12,
}

export enum DebounceTimeUnit {
  Unknown = 0,
  Seconds = 1,
  Minutes = 2,
  Hours = 3,
}

export enum TrackerTypeSource {
  Column = 1,
  Default = 2,
}
