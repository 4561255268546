import { PipeTransform, Pipe } from "@angular/core";

@Pipe({
  name: "trimWhiteSpace",
})
export class TrimWhiteSpacePipe implements PipeTransform {
  transform(value: string) {
    return value.trim();
  }
}
