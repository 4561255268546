import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormMode } from "app/common/enums";
import { EntityType } from "app/common/enums";
import { AuthenticationService } from "app/services/authentication/authentication.service";
// Moment timezone
import Moment from "moment-timezone";

import { UserService } from "app/services/users/user.service";
import { AppUser } from "app/models/user.model";
import { FhChartService } from "app/services/charts/charts.service";
import { AssetTypeService } from "app/services/asset/assetTypes.service";
import { UserContact } from "app/models/userContact.model";
import { UserContactService } from "app/services/users/userContacts.service";

window["moment"] = Moment;

@Component({
  providers: [FhChartService],
  selector: "fh-fh-user-contact-details",
  templateUrl: "userContactDetails.template.html",
})
export class UserContactDetailsViewComponent implements OnInit {
  sub: any;
  userContact: UserContact;
  device: any;
  tagType = EntityType.UserContact.valueOf();
  formMode = FormMode.read;

  loading = false;
  saving = false;
  loadingAssetWasl = false;

  filter;

  error: any;
  success: any;

  permissions: {};

  users: AppUser[];

  loadingDeviceCount = false;
  chartDeviceCount: any;

  // Pagination
  totalItems = 0;
  currentPage = 1;
  smallnumPages = 0;
  deviceCountData = [];
  distanceChartData = [];
  loadingCount = false;

  permissionName = "UserContacts_View";

  constructor(
    private router: Router,
    private assetTypeService: AssetTypeService,
    private cd: ChangeDetectorRef,
    private authentication: AuthenticationService,
    private userContactService: UserContactService,
    private route: ActivatedRoute,
    private userService: UserService
  ) {
    this.permissions = this.authentication.permissions;
  }

  ngOnInit() {
    this.loading = true;
    this.sub = this.route.params.subscribe((params) => {
      const id = params["id"];

      this.getUserContactInfo(id);
    });
  }

  // Form

  onDelete() {
    this.loading = true;

    this.userContactService.deleteUserContact(this.userContact).subscribe(
      (result) => {
        this.error = null;
        this.success = {
          statusText: "Success",
          success: "User contact is successfully deleted.",
        };

        setTimeout(() => {
          this.router.navigate(["/Notifications/UserContacts/Overview"]);
        }, 3000);
      },
      (error) => {
        this.success = null;
        this.error = error;
        this.loading = false;
        this.cd.markForCheck();
      }
    );
  }

  onSave() {
    this.saving = true;

    this.userContactService.updateUserContact(this.userContact).subscribe(
      (result) => {
        this.error = null;
        this.success = {
          statusText: "Success",
          success: "User contact is successfully updated.",
        };

        this.saving = false;
        this.cd.markForCheck();

        this.setFormMode(FormMode.read);
      },
      (error) => {
        this.saving = false;
        this.success = null;
        this.error = error;
        this.cd.markForCheck();
      }
    );
  }

  setFormMode(mode) {
    this.formMode = mode;

    if (this.formMode === FormMode.read) {
      this.loading = true;
      this.getUserContactInfo();
    }
  }

  getUserContactInfo(id = 0) {
    this.loading = true;
    this.cd.markForCheck();

    if (id === 0) {
      id = this.userContact.id;
    }

    this.userContactService.getUserContactById(id.toString()).subscribe(
      (userContact) => {
        this.userContact = userContact;

        this.loading = false;
        this.cd.markForCheck();
      },
      (error) => {
        this.success = null;
        this.error = error;
        this.error.statusText = "Error fetching User contact";
        this.cd.markForCheck();

        setTimeout(() => {
          this.router.navigate(["/Notifications/UserContacts/Overview"]);
        }, 3000);
      }
    );
  }
}
