<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/"> <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/System/">{{'general.system' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/System/WebhookEndpoints/Overview">{{'general.webhookEndpoints' | translate }}</a>
                </li>
                <li class="breadcrumb-item active">{{'general.details' | translate }}</li>
            </ol>
        </nav>
    </div>
    <fh-system-navigation> </fh-system-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">
        <div class="row" data-equalizer="" *ngIf="error || success">
            <div class="col-lg-12">
                <fh-error [error]="error" [success]="success"></fh-error>
            </div>
        </div>

        <div class="row" data-equalizer="">
            <div class="col-lg-6">
                <form #form="ngForm">
                    <div class="ibox" style="position: relative">
                        <ngx-loading [show]="loading || saving"></ngx-loading>
                        <div class="ibox-title">
                            <h5>
                                <i class="fas fa-fw fa-network-wired"></i> {{'general.webhookEndpoint' | translate }}
                            </h5>
                        </div>
                        <fh-webhook-endpoint-details
                            [loading]="loading"
                            [webhookEndpoint]="webhookEndpoint"
                            [formMode]="formMode"
                        >
                        </fh-webhook-endpoint-details>
                        <fh-save-footer
                            [valid]="!form.invalid"
                            (onSave)="onSave()"
                            (onDelete)="onDelete()"
                            (setFormMode)="setFormMode($event)"
                            [allowEdit]="true"
                            [allowDelete]="true"
                            [formMode]="formMode"
                        >
                        </fh-save-footer>
                    </div>
                </form>
            </div>
            <div class="col-lg-6">
                <div class="ibox" style="position: relative">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-city"></i> {{'general.info' | translate }}</h5>
                    </div>
                    <div class="ibox-content">Info</div>
                </div>
            </div>
        </div>
    </div>
</fh-permission-handler>
