<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/"> <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/System/">{{'general.system' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/System/HierarchyGroups/Overview">{{'general.hierarchyGroups' | translate }}</a>
                </li>
                <li class="breadcrumb-item active">{{'general.details' | translate }}</li>
            </ol>
        </nav>
    </div>
    <fh-system-navigation> </fh-system-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">
        <div class="row" data-equalizer="" *ngIf="error || success">
            <div class="col-lg-12">
                <fh-error [error]="error" [success]="success"></fh-error>
            </div>
        </div>

        <div class="row" data-equalizer="">
            <div class="col-lg-6">
                <form #form="ngForm">
                    <div class="ibox" style="position: relative">
                        <ngx-loading [show]="loading || saving"></ngx-loading>
                        <div class="ibox-title">
                            <h5><i class="fas fa-fw fa-folder-tree"></i> {{'general.hierarchyGroup' | translate }}</h5>
                        </div>
                        <fh-hierarchy-group-details [loading]="loading" [group]="group" [formMode]="formMode">
                        </fh-hierarchy-group-details>
                        <fh-save-footer
                            [valid]="!form.invalid"
                            (onSave)="onSave()"
                            (onDelete)="onDelete()"
                            (setFormMode)="setFormMode($event)"
                            [allowEdit]="true"
                            [allowDelete]="true"
                            [formMode]="formMode"
                        >
                        </fh-save-footer>
                    </div>
                </form>

                <div class="ibox">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-folder-tree"></i> {{'general.children' | translate }}</h5>
                    </div>
                    <div class="ibox-content" *ngIf="hierarchyGroups.length == 0">
                        <span>{{ "general.noDataFound" | translate }}</span>
                    </div>
                    <div class="ibox-content" *ngIf="hierarchyGroups.length > 0" style="margin-left: -15px">
                        <fh-hierarchy-display
                            [groups]="hierarchyGroups"
                            [parentId]="group.groupId"
                            [includeItems]="false"
                        ></fh-hierarchy-display>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="ibox" style="position: relative">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-list-ul"></i> {{'general.assets' | translate }}</h5>
                    </div>
                    <div class="ibox-content" style="position: relative">
                        <ngx-loading [show]="loadingAssets"></ngx-loading>
                        <fh-dual-list
                            [source]="assetsSource"
                            [(destination)]="assetsList"
                            [format]="format"
                            [key]="assetsKey"
                            [display]="assetsDisplay"
                            [sort]="true"
                            [disabled]="formMode == 1"
                        >
                        </fh-dual-list>
                    </div>
                </div>

                <div class="ibox" style="position: relative">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-list-ul"></i> {{'general.drivers' | translate }}</h5>
                    </div>
                    <div class="ibox-content" style="position: relative">
                        <ngx-loading [show]="loadingDrivers"></ngx-loading>
                        <fh-dual-list
                            [source]="driversSource"
                            [(destination)]="driversList"
                            [format]="format"
                            [key]="driversKey"
                            [display]="driversDisplay"
                            [sort]="true"
                            [disabled]="formMode == 1"
                        >
                        </fh-dual-list>
                    </div>
                </div>

                <div class="ibox" style="position: relative">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-list-ul"></i> {{'general.geofences' | translate }}</h5>
                    </div>
                    <div class="ibox-content" style="position: relative">
                        <ngx-loading [show]="loadingGeofences"></ngx-loading>
                        <fh-dual-list
                            [source]="geofencesSource"
                            [(destination)]="geofencesList"
                            [format]="format"
                            [key]="geofencesKey"
                            [display]="geofencesDisplay"
                            [sort]="true"
                            [disabled]="formMode == 1"
                        >
                        </fh-dual-list>
                    </div>
                </div>

                <fh-tagging [objectId]="group?.groupId" [objectType]="tagType"> </fh-tagging>
            </div>
        </div>
    </div>
</fh-permission-handler>
