<div class="ibox-footer">
    <ng-container *ngIf="showButtons; else showNotification">
        <button
            class="btn btn-primary"
            *ngIf="allowAdd && formMode == 1"
            (click)="sendFormMode(2)"
            title="{{ 'general.add' | translate }}"
        >
            <i class="fa fa-fw fa-plus"></i>
            <span class="d-none d-md-inline-flex">{{ "general.add" | translate }}</span>
        </button>

        <button
            class="btn btn-primary"
            *ngIf="allowEdit && formMode == 1"
            (click)="sendFormMode(3)"
            title="{{ 'general.edit' | translate }}"
        >
            <i class="fa fa-fw fa-edit"></i>
            <span class="d-none d-md-inline-flex">{{ "general.edit" | translate }}</span>
        </button>

        <button
            class="btn btn-primary"
            *ngIf="allowSave && formMode == 3"
            [disabled]="!valid"
            (click)="save()"
            title="{{ 'general.save' | translate }}"
        >
            <i class="fa fa-fw fa-save"></i>
            <span class="d-none d-md-inline-flex">{{ "general.update" | translate }}</span>
        </button>

        <button
            class="btn btn-primary"
            [disabled]="!valid"
            *ngIf="formMode == 2"
            (click)="insert()"
            title="{{ 'general.save' | translate }}"
        >
            <i class="fa fa-fw fa-save"></i>
            <span class="d-none d-md-inline-flex">{{ "general.add" | translate }}</span>
        </button>

        <button
            class="btn btn-danger"
            *ngIf="allowDelete && formMode == 1"
            (click)="showDelete()"
            title="{{ 'general.delete' | translate }}"
        >
            <i class="fa fa-fw fa-trash-alt"></i>
            <span class="d-none d-md-inline-flex">{{ "general.delete" | translate }}</span>
        </button>

        <button
            class="btn btn-info"
            *ngIf="formMode == 3 || (formMode == 2 && allowCancelOnInsert)"
            (click)="sendFormMode(1)"
            title="{{ 'general.cancel' | translate }}"
        >
            <i class="fa fa-fw fa-times"></i>
            <span class="d-none d-md-inline-flex">{{ "general.cancel" | translate }}</span>
        </button>
    </ng-container>

    <ng-template #showNotification>
        <span *ngIf="showButtonsNotification">
            {{ showButtonsNotification }}
        </span>
    </ng-template>

    <ng-content></ng-content>
    <div style="clear: both"></div>
</div>

<fh-confirmation-modal *ngIf="allowDelete" #deleteModal (onFinish)="delete($event)">
    <h4 header class="modal-title pull-left"><i class="fa fa-trash-alt"></i> {{ "general.areyousure" | translate }}</h4>
</fh-confirmation-modal>
