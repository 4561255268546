<nav [class.fo-hidden-sidebar]="sidebarHidden === true">
    <div>
        <ul style="max-height: 100%" class="nav metismenu" id="side-menu">
            <li class="nav-header nav-overview">
                <div class="dropdown profile-element hand" [routerLink]="['/']">
                    <span style="display: none" class="fleet-overview-logo">
                        <i
                            id="mainIcon"
                            class="fa fa-fw"
                            [class.fa-map]="!fetchingStates"
                            [class.fa-spin]="fetchingStates"
                            [class.fa-sync]="fetchingStates"
                        ></i>

                        {{'general.fleetOverview' | translate}}</span
                    >
                </div>
            </li>
            <li class="fleet-overview-tabs">
                <button [class.active]="tab === 'Assets'" (click)="changeTab('Assets')" class="fleet-overview-tab">
                    <i class="fas fa-fw fa-car-bus"></i> {{'general.assets' | translate}}
                </button>
                <button [class.active]="tab === 'Tasks'" (click)="changeTab('Tasks')" class="fleet-overview-tab">
                    <i class="fas fa-fw fa-bolt"></i> {{'general.tasks' | translate}}
                </button>
                <button [class.active]="tab === 'Chat'" (click)="changeTab('Chat')" class="fleet-overview-tab">
                    <i class="fas fa-fw fa-comments"></i> {{'general.chat' | translate}}
                </button>

                <div class="btn-group">
                    <div class="btn" style="display: none"></div>
                    <button
                        style="padding: 7.5px 12.5px; box-shadow: none"
                        (click)="toggleAssetStatus()"
                        type="button"
                        class="btn dropdown-toggle-split fleet-overview-tab"
                        data-toggle="dropdown"
                        aria-expanded="false"
                    >
                        <span class="sr-only">{{ 'general.enable' | translate }}</span>
                        <i class="fas fa-fw fa-filter"></i>
                    </button>
                    <ul [class.show]="filterToggle === true" class="dropdown-menu">
                        <li class="dropdown-header">{{ 'general.deviceStatus' | translate }}</li>

                        <li
                            *ngFor="let dropdown of dropdownStates"
                            class="btn dropdown-item"
                            style="padding: 0; margin: 0"
                        >
                            <div style="margin-bottom: 0" class="fa_checkbox">
                                <input
                                    [id]="'filterSelection_' + dropdown.state"
                                    [name]="'filterSelection_' + dropdown.state"
                                    type="checkbox"
                                    checked
                                    [disabled]="state !== 'Loaded' || assetGroups.size === 0"
                                    (change)="$event.stopPropagation?.(); visibilityAssetStatus($event, dropdown.state)"
                                />
                                <label
                                    style="padding: 8.5px 12.5px; transform: translateY(2px); width: 100%"
                                    [for]="'filterSelection_' + dropdown.state"
                                >
                                    <i
                                        style="margin: 4px"
                                        class="eye-active fa fa-fw {{dropdown.image}} {{dropdown.color}}"
                                    ></i>
                                    {{ 'general.deviceState_' + dropdown.state | translate }} ({{
                                    dropdown.count.call(this) }})
                                </label>
                            </div>
                        </li>

                        <li style="margin-top: 0" class="dropdown-divider"></li>

                        <li class="btn dropdown-item" style="padding: 0">
                            <div style="margin-bottom: 0" class="fa_checkbox">
                                <input
                                    [id]="'filterGps_' + '0'"
                                    [name]="'filterGps_' + '0'"
                                    type="checkbox"
                                    [disabled]="state !== 'Loaded' || assetGroups.size === 0"
                                    (change)="$event.stopPropagation?.(); visibilityAssetStatus($event, 6)"
                                />
                                <label
                                    style="padding: 8.5px 12.5px; transform: translateY(2px); width: 100%"
                                    [for]="'filterGps_' + '0'"
                                >
                                    <i style="margin: 4px" class="eye-active fa fa-rss fa-fw green"></i> {{
                                    'general.deviceState_6' | translate }}
                                </label>
                            </div>
                        </li>
                    </ul>
                </div>
            </li>

            <div class="fleet-overview-sidebar">
                <div style="padding: 4px">
                    <!-- Search -->
                    <div
                        [style.display]="mode !== 'History' ? 'flex' : 'none'"
                        class="input-group"
                        style="padding: 3px; max-height: 55px; border-radius: 4px; background-color: #fff; color: #555"
                    >
                        <span class="input-group-prepend" style="padding: 10px 6px 10px 6px">
                            <i class="fa fa-fw fa-search"></i>
                        </span>
                        <input
                            style="width: 100%; padding: 6px"
                            type="text"
                            min="0"
                            class="form-control"
                            #ref
                            placeholder="{{ 'general.search' | translate}}"
                            [(ngModel)]="filter"
                            (ngModelChange)="onSearchChanged(ref.value)"
                        />
                    </div>
                </div>

                <!-- List -->
                <div class="cockpit-list">
                    <div *ngIf="state !== 'Loaded'">
                        <div class="fleet-overview-header">
                            <a style="display: block; align-items: center">
                                <span style="padding: 12px" *ngIf="state === 'Initialize'">
                                    {{ 'general.loadingData' | translate }}
                                </span>
                                <span style="padding: 12px" *ngIf="state === 'FetchingState'">
                                    {{ 'general.fetchingState' | translate }}
                                </span>
                                <span style="padding: 12px" *ngIf="state === 'LoadingMap'">
                                    {{ 'general.loadingMap' | translate }}
                                </span>
                            </a>
                        </div>
                    </div>
                    <div
                        *ngIf="state === 'Loaded' && ((tab === 'Assets' && emptyGroups(assetGroups)) || (tab === 'Drivers' && emptyGroups(driverGroups)) || (tab === 'Geofences' && emptyGroups(geofenceGroups)))"
                        class="fleet-overview-header"
                    >
                        <a style="display: block; align-items: center">
                            <span style="padding: 12px"> {{ 'general.noGroupsFound' | translate }} </span>
                        </a>
                    </div>

                    <!-- Assets -->
                    <div
                        [style.display]="(state === 'Loaded' || state === 'FetchingState') && tab === 'Assets' ? 'block' : 'none'"
                    >
                        <div
                            *ngIf="extendFilterMap(assetGroups) as groups"
                            [style.display]="mode === 'Overview' ? 'block' : 'none'"
                        >
                            @for (assetGroup of groups | keyvalue: valueAscOrder; track assetGroup.key; let i = $index;)
                            {
                            <ng-container *ngIf="i < (maxGroups + currentMarker)">
                                <ng-container
                                    *ngIf="(assetGroup.value.items | stringFilter: filter | hiddenFilter) as filteredGroup"
                                >
                                    <!-- [style.display]="!(filteredGroup.length === 0) ? 'block' : increaseViewCount() && 'none'"  -->
                                    <li
                                        *ngIf="!(filteredGroup.length === 0 && increaseViewCount())"
                                        class="fleet-overview-header"
                                        style="position: relative"
                                    >
                                        <a
                                            style="
                                                display: flex;
                                                padding: 10px;
                                                align-items: center;
                                                justify-content: space-between;
                                            "
                                            (click)="openGroup(assetGroup)"
                                        >
                                            <div [title]="assetGroup.value.name" class="fleet-overview-header-label">
                                                {{ assetGroup.value.name }}
                                            </div>

                                            <div style="flex: 0 0 auto">
                                                <span style="padding: 0 2.5px">({{filteredGroup.length}})</span>

                                                <span
                                                    style="float: unset; margin-left: 5px"
                                                    class="fa arrow"
                                                    [ngClass]="(assetGroup.value.filterClose === false && filter.length > 0) || assetGroup.value.open === true ? 'fa-rotate-270' : 'fa-flip-horizontal'"
                                                >
                                                </span>
                                            </div>
                                        </a>
                                        <ul
                                            *ngIf="(assetGroup.value.filterClose === false && filter.length > 0) || assetGroup.value.open === true"
                                            class="nav nav-second-level"
                                        >
                                            @for (device of filteredGroup | orderBy:['_order']:false |
                                            slice:0:maxListItems; track $any(device).id;) {
                                            <li
                                                [ngClass]="{ 'active': $any(device).name === selectedDevice }"
                                                class="fleet-overview-item fontColor hand base-color {{calculatedDeviceState.get($any(device).id)?.color}}"
                                                (click)="selectAsset($any(device))"
                                            >
                                                <span
                                                    [title]="$any(device).name?.length > 0 ? $any(device).name : $any(device).id"
                                                    class="fleet-overview-subtitle"
                                                >
                                                    {{ $any(device).name?.length > 0 ? $any(device).name :
                                                    $any(device).id }}

                                                    <ng-container
                                                        *ngIf="lastStateUpdated.get($any(device).id) as momentTime;"
                                                    >
                                                        <ng-container>
                                                            <span [title]="$any(device).displayLabel"
                                                                >{{ $any(device).displayLabel }}</span
                                                            >
                                                        </ng-container>
                                                    </ng-container>
                                                </span>
                                                <i
                                                    (click)="$event.preventDefault?.(); changeVisibility(hiddenAssets, $any(device).id);"
                                                    [ngClass]="hiddenAssets.get($any(device).id) === true ? 'fa-eye-slash' : 'fa-eye'"
                                                    class="fa fa-fw eye-active fleet-overview-eye {{calculatedDeviceState.get($any(device).id)?.color}}"
                                                ></i>
                                            </li>
                                            }
                                            <li
                                                *ngIf="filteredGroup.length > maxListItems"
                                                class="fontColor"
                                                style="
                                                    display: flex;
                                                    align-items: center;
                                                    padding: 15px 2px 15px 9px;
                                                    background: white;
                                                "
                                            >
                                                <span style="display: flex"
                                                    >We do only show {{maxListItems}} items per group. If you want to
                                                    see more please refine search.</span
                                                >
                                            </li>
                                        </ul>
                                    </li>
                                </ng-container>
                            </ng-container>
                            }

                            <li
                                *ngIf="filter.length === 0 && assetGroups.size > maxGroups"
                                class="fleet-overview-header"
                                style="position: relative"
                            >
                                <a style="pointer-events: none">
                                    <span>
                                        <i style="margin-right: 8.5px">&ndash;</i>
                                        Showing {{maxGroups}} groups only
                                    </span>
                                </a>
                            </li>
                        </div>
                    </div>

                    <!-- Tasks -->
                    <div [style.display]="state === 'Loaded' && tab === 'Tasks' ? 'block' : 'none'">
                        <div [style.display]="mode === 'Overview' ? 'block' : 'none'"></div>
                    </div>

                    <!-- Chat -->
                    <div [style.display]="state === 'Loaded' && tab === 'Chat' ? 'block' : 'none'">
                        <div [style.display]="mode === 'Overview' ? 'block' : 'none'"></div>
                    </div>
                </div>
            </div>
        </ul>
    </div>

    <!-- Navigation -->
    <div style="position: relative; padding: 0px 0px 0px 0px" class="buttonContent cockpit-sidebar-content">
        <div class="fo-sidebar-buttons col-12 pl-0 pr-0">
            <button
                type="button"
                class="app-btn fo-toggle-button"
                [class.fo-toggle-button__popout]="sidebarHidden === true"
                (click)="toggleSidebar()"
            >
                <i class="fa fa-window-maximize"></i>
            </button>
        </div>
    </div>
</nav>
