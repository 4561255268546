<ng-select
    [loading]="loadingDriverGroups"
    ngDefaultControl
    [searchable]="true"
    [clearable]="clearable"
    style="height: unset"
    [disabled]="loadingDriverGroups || (!canAddNew && driverGroups.length == 0)"
    [readonly]="readonly"
    placeholder="{{ placeholder }} ({{ driverGroups.length }})"
    #input
    [multiple]="multiple"
    [(ngModel)]="value"
    class="form-control"
    [ngClass]="tableInline === true ? 'table-inline' : ''"
    addTagText="{{ 'general.createNew' | translate }}"
    [addTag]="canAddNew ? createNew.bind(this) : false"
    [items]="driverGroups"
    bindLabel="name"
    bindValue="id"
    (ngModelChange)="driverGroupChanged()"
>
    <ng-template ng-label-tmp let-drivergroup="item" let-clear="clear">
        <span
            ><i class="fas fa-fw fa-layer-group" title="Items: {{ drivergroup.itemCount }}"></i>
            {{ drivergroup.displayName }} ({{ drivergroup.itemCount }})
            <span *ngIf="serverResponses[drivergroup.displayName]" class="badge badge-danger">
                <i class="fas fa-fw fa-exclamation-triangle"></i>
            </span>
        </span>
        <span class="ng-value-icon right" (click)="clear(drivergroup)" aria-hidden="true">&times;</span>
    </ng-template>
    <ng-template ng-option-tmp let-drivergroup="item" let-search="searchTerm">
        <div>
            <div [ngOptionHighlight]="search">{{ drivergroup.displayName }} ({{ drivergroup.itemCount }})</div>
            <div [ngSwitch]="true">
                <span
                    class="badge badge-primary"
                    tooltip="'Item added'"
                    *ngSwitchCase="serverResponses[drivergroup.displayName] === false"
                >
                    <i class="fas fa-fw fa-square-check"></i> {{ "general.created" | translate }}
                </span>
                <span
                    class="badge badge-danger"
                    tooltip="'Item failed'"
                    *ngSwitchCase="serverResponses[drivergroup.displayName]?.length > 0"
                >
                    <i class="fas fa-fw fa-exclamation-triangle"></i> {{ serverResponses[drivergroup.displayName] }}
                </span>
            </div>
        </div>
        <small class="text-muted">
            <span
                class="block-item"
                *ngFor="
                    let driverGroupItem of drivergroup.driverGroupItems
                        | slice : 0 : (drivergroup.showAll ? 99999999 : 5)
                        | orderBy : 'driverName' : false
                "
            >
                {{ driverGroupItem.driverName }}
            </span>

            <span
                class="block-item"
                *ngIf="drivergroup.driverGroupItems?.length > 5 && !drivergroup.showAll"
                (click)="showAll($event, drivergroup)"
            >
                <i class="fas fa-fw fa-layer-group"></i> {{ "general.more" | translate }}
            </span>

            <span *ngIf="drivergroup.driverGroupItems?.length == 0">{{ "general.noDataFound" | translate }}</span>
        </small>
    </ng-template>
</ng-select>
