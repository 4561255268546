export class ClientAnnouncement {
  id: any;
  accountId: any;
  resellerId: any;
  companyName: any;
  startDate: Date;
  timestamp: Date;
  messageLevel1: any;
  messageLevel2: any;
  messageLevel3: any;
  daysToLevel2: any;
  daysToLevel3: any;
  daysToBlock: any;
  useBlock: any;
}
