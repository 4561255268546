<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/"> <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Trailers/Overview">{{'general.trailers' | translate }}</a>
                </li>
                <li class="breadcrumb-item active">
                    <a href="/#/Trailers/Add">{{'menu.add' | translate }}</a>
                </li>
            </ol>
        </nav>
    </div>
    <fh-trailer-navigation>
        <li class="active">
            <a href="/#/Trailers/Add/"
                ><i class="fas fa-fw fa-trailer"></i>
                <div>{{ 'general.add' | translate }}</div>
            </a>
        </li>
    </fh-trailer-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">
        <div class="row">
            <div class="col-lg-12">
                <fh-error [success]="success" [error]="error"></fh-error>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <form #userForm="ngForm">
                    <div class="ibox" style="position: relative">
                        <div class="ibox-title">
                            <h5><i class="fas fa-fw fa-trailer"></i> {{'general.trailer' | translate }}</h5>
                        </div>
                        <fh-trailer-details
                            [trailer]="trailer"
                            [formMode]="formMode"
                            [loading]="loading"
                            (onAccountChanged)="accountChanged($event)"
                        ></fh-trailer-details>
                        <fh-save-footer
                            [valid]="!userForm.invalid"
                            (onInsert)="onInsert()"
                            [allowAdd]="permissions['Trailers_Add']"
                            [formMode]="formMode"
                        >
                        </fh-save-footer>
                    </div>
                </form>
            </div>
            <div class="col-lg-6">
                <div class="ibox" style="position: relative">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-city"></i> {{'general.info' | translate }}</h5>
                    </div>
                    <div class="ibox-content">Info</div>
                </div>
            </div>
        </div>
    </div>
</fh-permission-handler>
