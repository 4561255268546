import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { throwError } from "rxjs/internal/observable/throwError";
import { Observable, catchError, map } from "rxjs";
import { Warehouse, WarehouseSensor, WarehouseZone } from "../../models/warehouse.model";
import { AuthenticationService } from "../authentication/authentication.service";
import { LoggingService } from "../logging/logging.service";

// Moment timezone
import Moment from "moment-timezone";
import { RegisterWarehouse } from "app/models/warehouse.model";
window["moment"] = Moment;

@Injectable()
export class WarehouseService {
  url = "";
  urlZone = "";
  urlSensor = "";
  base_url = "";
  inventoryUrl = "";
  Warehouses: Warehouse[] = [];
  timezoneIana: string;

  constructor(
    private http: HttpClient,
    private loggingService: LoggingService,
    private authenticationService: AuthenticationService
  ) {
    this.url = this.authenticationService.getWebserviceURL("warehouse");
    this.urlZone = this.authenticationService.getWebserviceURL("warehouseZone");
    this.urlSensor = this.authenticationService.getWebserviceURL("warehouseSensor");
    this.timezoneIana = this.authenticationService.getTimeZoneIana();
  }

  getPagingUrl(accountId?: number) {
    return this.url + "Paging" + (accountId > 0 ? `?accountId=${accountId}` : "");
  }

  getWarehouses(): Observable<Warehouse[]> {
    console.log("getting warehouses from service");
    return this.http.get(this.url, { headers: this.authenticationService.headers }).pipe(
      map((data) => {
        return this.parseResponse(data);
      }),
      catchError(this.handleError)
    );
  }

  getWarehousesByAccount(accountId): Observable<Warehouse[]> {
    console.log("getting warehouses from service");
    return this.http.get(this.url + "Account/" + accountId, { headers: this.authenticationService.headers }).pipe(
      map((data) => {
        return this.parseResponse(data);
      }),
      catchError(this.handleError)
    );
  }

  getWarehouseById(id: string): Observable<Warehouse> {
    console.log("Fetch warehouse by id " + id);
    return this.http.get(this.url + id, { headers: this.authenticationService.headers }).pipe(
      map((data) => {
        return this.parseReponseDetails(data);
      }),
      catchError(this.handleError)
    );
  }

  getWarehouseByRowKey(rowKey: string): Observable<any> {
    console.log("Fetch warehouse by rowKey " + rowKey);
    return this.http.get(this.url + "RowKey/" + rowKey, { headers: this.authenticationService.headers }).pipe(
      map((data) => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  getWarehouseEventsById(id: string): Observable<any> {
    console.log("Fetch warehouse by id " + id);
    return this.http.get(this.url + id + "/Sensors", { headers: this.authenticationService.headers }).pipe(
      map((data) => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  getWarehouseZoneById(id: string): Observable<WarehouseZone> {
    console.log("Fetch warehouse zone by id " + id);
    return this.http.get(this.urlZone + id, { headers: this.authenticationService.headers }).pipe(
      map((data) => {
        return this.ParseReponseZoneDetails(data);
      }),
      catchError(this.handleError)
    );
  }

  getWarehouseZoneSensorsById(id: string): Observable<any> {
    console.log("Fetch warehouse zone sensors by id " + id);
    return this.http.get(this.urlZone + id + "/Sensors", { headers: this.authenticationService.headers }).pipe(
      map((data) => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  getWarehouseLatestDataByReference(referenceId: string): Observable<any> {
    console.log("Fetch warehouse latest by referenceId " + referenceId);
    return this.http.get(this.url + referenceId + "/Latest", { headers: this.authenticationService.headers }).pipe(
      map((data) => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  getWarehouseLatestAvgPerZoneByReference(referenceId: string): Observable<any> {
    console.log("Fetch warehouse latest by referenceId " + referenceId);
    return this.http
      .get(this.url + referenceId + "/LatestAvgPerZone", { headers: this.authenticationService.headers })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  getWarehouseZoneDataByReference(referenceId: string, start, end): Observable<any> {
    console.log("Fetch warehouse zone data by referenceId " + referenceId);
    return this.http
      .get(this.url + "Zone/" + referenceId + "?start=" + start.unix() + "&end=" + end.unix(), {
        headers: this.authenticationService.headers,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  getWarehouseZonesByReference(referenceId: string): Observable<any> {
    console.log("Fetch warehouse zone by referenceId " + referenceId);
    return this.http.get(this.url + referenceId + "/Zones", { headers: this.authenticationService.headers }).pipe(
      map((data) => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  getWarehouseDataByReference(referenceId: string, start, end): Observable<any> {
    console.log("Fetch warehouse data by referenceId " + referenceId);
    return this.http
      .get(this.url + referenceId + "/Data?start=" + start.unix() + "&end=" + end.unix(), {
        headers: this.authenticationService.headers,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  getWarehouseEventsByReference(referenceId: string, start, end): Observable<any> {
    console.log("Fetch warehouse events by referenceId " + referenceId);
    return this.http
      .get(this.url + referenceId + "/Events?start=" + start.unix() + "&end=" + end.unix(), {
        headers: this.authenticationService.headers,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  saveWarehouse(warehouse: Warehouse, geoJson): Observable<any> {
    console.log("save warehouse", warehouse);

    const postObject = { ...warehouse, ...{ geoJson: geoJson } };

    return this.http
      .post(this.url, postObject, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  updateWarehouse(warehouse: Warehouse, geoJson): Observable<any> {
    console.log("update warehouse", warehouse);
    const postObject = { ...warehouse, ...{ geoJson: geoJson } };

    return this.http
      .put(this.url + warehouse.id, postObject, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  deleteWarehouse(warehouseId): Observable<any> {
    console.log("delete warehouse", warehouseId);

    return this.http
      .delete(this.url + warehouseId, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  saveWarehouseZone(warehouseZone: WarehouseZone): Observable<any> {
    console.log("save warehouseZone", warehouseZone);

    return this.http
      .post(this.urlZone, warehouseZone, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  updateWarehouseZone(warehouseZone: WarehouseZone): Observable<any> {
    console.log("update warehouseZone", warehouseZone);

    return this.http
      .put(this.urlZone + warehouseZone.zoneId, warehouseZone, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  deleteWarehouseZone(rowKey, partitionKey): Observable<any> {
    console.log("delete warehouseZone", rowKey);

    return this.http
      .delete(this.urlZone + rowKey + "?partitionKey=" + partitionKey, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  saveWarehouseSensor(warehouseSensor: WarehouseSensor): Observable<any> {
    console.log("save warehouseSensor", warehouseSensor);

    return this.http
      .post(this.urlSensor, warehouseSensor, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  updateWarehouseSensor(warehouseSensor: WarehouseSensor): Observable<any> {
    console.log("update warehouseSensor", warehouseSensor);

    return this.http
      .put(this.urlSensor + warehouseSensor.zoneId, warehouseSensor, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  deleteWarehouseSensor(rowKey, partitionKey): Observable<any> {
    console.log("delete warehouseSensor", rowKey);

    return this.http
      .delete(this.urlSensor + rowKey + "?partitionKey=" + partitionKey, {
        headers: this.authenticationService.headers,
      })
      .pipe(catchError(this.handleError));
  }

  resetCache(): Observable<boolean> {
    return this.http.get(this.url + "ResetCache", { headers: this.authenticationService.headers }).pipe(
      map((data: any) => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  private handleError(error: Response) {
    return throwError(() => error);
  }

  parseResponse(json: any): Warehouse[] {
    this.loggingService.log(this.constructor.name, "Retrieved " + json.length + " Warehouses.");

    const ident = 1;
    const warehouses: Warehouse[] = [];

    json.forEach((item) => {
      const warehouse = this.parseReponseDetails(item);
      warehouses.push(warehouse);
    });

    return warehouses;
  }

  parseReponseDetails(item) {
    const warehouse = new Warehouse();
    warehouse.id = item.id;
    warehouse.name = item.name;
    warehouse.description = item.description;
    warehouse.address = item.address;
    warehouse.geoJson = item.geoJson;
    warehouse.emailRecipients = item.emailRecipients;

    warehouse.companyName = item.companyName;
    warehouse.accountId = item.accountId;

    warehouse.referenceId = item.referenceId;
    warehouse.resellerId = item.resellerId;
    warehouse.resellerDescription = item.resellerDescription;

    const wasl = new RegisterWarehouse();
    if (item.properties) {
      if (item.properties.wasl) {
        wasl.referenceKey = item.properties.wasl.referenceKey;
        wasl.city = item.properties.wasl.city;
        wasl.landCoordinates = item.properties.wasl.landCoordinates;
        wasl.landAreaInSquareMeter = item.properties.wasl.landAreaInSquareMeter;
        wasl.licenseNumber = item.properties.wasl.licenseNumber;
        wasl.licenseIssueDate = item.properties.wasl.licenseIssueDate;
        wasl.licenseExpiryDate = item.properties.wasl.licenseExpiryDate;
        wasl.managerMobile = item.properties.wasl.managerMobile;
        wasl.phone = item.properties.wasl.phone;
        wasl.email = item.properties.wasl.email;
        wasl.registerDateSfda = item.properties.wasl.registerDateSfda;
      }

      if (item.properties.custom) {
        warehouse.properties.custom = [];

        item.properties.custom.forEach((row, index) => {
          warehouse.properties.custom.push({ key: row.key, value: row.value });
        });
      }
    }

    warehouse.properties.wasl = wasl;

    return warehouse;
  }

  ParseReponseZoneDetails(item) {
    const zone = new WarehouseZone();
    zone.zoneId = item.zoneId;
    zone.name = item.name;
    zone.identifier = item.identifier;
    zone.partitionKey = item.partitionKey;
    zone.rowKey = item.rowKey;
    zone.warehouseId = item.warehouseId;
    zone.type = item.type;

    return zone;
  }
}
