<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/"> <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/System/">{{'general.system' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/System/HierarchyGroups/Overview">{{'general.hierarchyGroups' | translate }}</a>
                </li>
                <li class="breadcrumb-item active">{{'general.details' | translate }}</li>
            </ol>
        </nav>

        <!-- <span style="margin-left: auto;" class="fa-filter-container noselect">
            <a style="margin-left:2px" class="btn btn-info margin-fix d-none d-md-inline-flex" title="Help"
                href="https://help.360locate.com/knowledge-base/use-workschedule-to-mark-trips-as-private-or-business/"
                target="_blank"><i class="fa fa-fa fa-question"></i> <span
                    class="d-none d-md-inline-flex">{{'general.needHelp' |
                    translate }}</span></a>
        </span> -->
    </div>
    <fh-system-navigation>
        <li class="active">
            <a href="/#/System/HierarchyGroupDetails/Add/"
                ><i class="fas fa-fw fa-folder-tree"></i>
                <div>{{ 'general.add' | translate }}</div>
            </a>
        </li>
    </fh-system-navigation>
</div>

<div class="wrapper wrapper-content">
    <div class="row" data-equalizer="">
        <div class="col-lg-12">
            <fh-error [error]="error" [success]="success"></fh-error>
        </div>
    </div>

    <form #form="ngForm">
        <div class="row" data-equalizer="">
            <div class="col-lg-6">
                <div class="ibox">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-folder-tree"></i> {{'general.hierarchyGroup' | translate }}</h5>
                    </div>
                    <ngx-loading [show]="loading || saving"></ngx-loading>
                    <fh-hierarchy-group-details
                        [group]="group"
                        [loading]="loading"
                        [formMode]="formMode"
                    >
                    </fh-hierarchy-group-details>
                    <fh-save-footer
                        [valid]="!form.invalid"
                        [allowCancelOnInsert]="false"
                        (onInsert)="onInsert()"
                        [formMode]="formMode"
                    >
                    </fh-save-footer>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="ibox" style="position: relative">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-city"></i> {{'general.info' | translate }}</h5>
                    </div>
                    <div class="ibox-content">Info</div>
                </div>
            </div>
        </div>
    </form>
</div>
