import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { FormMode } from "app/common/enums";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { AssetType } from "app/models/assetType.model";
import { TranslateService } from "@ngx-translate/core";
import { ClientAnnouncementService } from "app/services/announcements/clientAnnouncements";
import { ClientAnnouncement } from "app/models/clientAnnouncement.model";

@Component({
  selector: "fh-service-announcement-add",
  templateUrl: "add.template.html",
})
export class ClientAnnouncementAddViewComponent {
  loading: boolean = false;
  clientAnnouncement: ClientAnnouncement = new ClientAnnouncement();

  formMode: FormMode = FormMode.add;
  formModeAssetTypes: FormMode = FormMode.read;

  error: any;
  permissions: {};
  success: { statusText: string; success: string };
  assetTypes: AssetType[];

  permissionName: string = "UserContacts_Add";

  constructor(
    private translateServe: TranslateService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private clientAnnouncementsService: ClientAnnouncementService
  ) {
    this.permissions = this.authenticationService.permissions;

    this.clientAnnouncement.messageLevel1 = "Your subscription is about to expire. Please renew.";
    this.clientAnnouncement.messageLevel2 = "Your subscription is about to expire. You must renew to keep access.";
    this.clientAnnouncement.messageLevel3 = "Your subscription has expired. Access is denied. Please renew.";

    this.clientAnnouncement.daysToLevel2 = 7;
    this.clientAnnouncement.daysToLevel3 = 7;
    this.clientAnnouncement.useBlock = true;
    this.clientAnnouncement.daysToBlock = 14;
  }

  onInsert() {
    this.loading = true;

    this.clientAnnouncementsService.saveClientAnnouncement(this.clientAnnouncement).subscribe({
      next: (result) => {
        this.success = {
          statusText: "Success",
          success: this.translateServe.instant("general.saveSuccessRedirect", { entity: "clientAnnouncement" }),
        };

        setTimeout(() => {
          this.router.navigate(["/Settings/ClientAnnouncementDetails/Index/" + result.id]);
        }, 2000);

        this.loading = false;
      },
      error: (error) => {
        this.error = error;
        this.loading = false;
      },
    });
  }
}
