<div class="ibox" style="position: relative">
    <ngx-loading [show]="loading"></ngx-loading>
    <div class="ibox-title">
        <h5><i class="fas fa-fw fa-microchip"></i> {{'general.assignedDevices' | translate }}</h5>
        <span class="float-right">
            <div class="filter-form d-none d-md-block">
                <input
                    type="text"
                    class="form-control table-inline top-filter d-none d-md-block"
                    [(ngModel)]="filter"
                />
            </div>
        </span>
    </div>
    <div class="ibox-content slick" style="position: relative; overflow-y: auto">
        <table style="width: 100%" class="table nowrap dtr-inline no-footer">
            <thead>
                <tr>
                    <th></th>
                    <th>{{'general.device' | translate }}</th>
                    <th>{{'general.startDate' | translate }}</th>
                    <th>{{'general.endDate' | translate }}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngIf="deviceAssignments.length == 0">
                    <td colspan="4">{{'general.noDevicesFound' | translate }}</td>
                </tr>
                <tr
                    *ngFor="let deviceAssignment of deviceAssignments | stringFilter: filter | paginate: { itemsPerPage: 10, currentPage: currentPage, id: 'projectAssignments'  }"
                >
                    <td style="width: 20px" *ngIf="allowSelect"><input type="checkbox" value="" /></td>
                    <td style="width: 20px">
                        <a href="/#/DeviceDetails/Projects/{{deviceAssignment.deviceId}}">
                            <span class="secondary link_bolder"><i class="fas fa-fw fa-angle-right"></i></span>
                        </a>
                    </td>
                    <td>{{deviceAssignment.assetName}}</td>
                    <td title="{{ deviceAssignment?.dateStart?.toLocaleString() }}">
                        {{deviceAssignment.dateStart | amDateFormat: 'lll'}}
                    </td>
                    <td title="{{ deviceAssignment?.dateEnd?.toLocaleString() }}">
                        {{deviceAssignment.dateEnd | amDateFormat: 'lll'}}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="ibox-footer noselect dataTables_wrapper">
        <pagination-template #pda="paginationApi" (pageChange)="currentPage = $event" id="projectAssignments">
            <div class="dataTables_paginate paging_simple_numbers" id="dataTable_paginate">
                <ul class="pagination">
                    <li class="paginate_button page-item previous" [class.disabled]="pda.isFirstPage()">
                        <a class="page-link" (click)="pda.previous()" *ngIf="!pda.isFirstPage()"
                            >{{ 'grid.previous' | translate }}</a
                        >
                        <a *ngIf="pda.isFirstPage()">{{ 'grid.previous' | translate }}</a>
                    </li>

                    <li
                        class="paginate_button page-item"
                        *ngFor="let page of pda.pages"
                        [class.current]="pda.getCurrent() === page.value"
                    >
                        <a
                            class="page-link"
                            (click)="pda.setCurrent(page.value)"
                            *ngIf="pda.getCurrent() !== page.value"
                        >
                            <span>{{ page.label }}</span>
                        </a>
                        <a
                            class="page-link"
                            (click)="pda.setCurrent(page.value)"
                            *ngIf="pda.getCurrent() === page.value"
                        >
                            <span style="font-weight: bold">{{ page.label }}</span>
                        </a>
                    </li>

                    <li class="paginate_button page-item next" [class.disabled]="pda.isLastPage()">
                        <a class="page-link" (click)="pda.next()" *ngIf="!pda.isLastPage()">
                            {{ 'grid.next' | translate }}
                        </a>
                        <a class="page-link" *ngIf="pda.isLastPage()"> {{ 'grid.next' | translate }} </a>
                    </li>
                </ul>
            </div>
        </pagination-template>
        <div style="clear: both"></div>
    </div>
</div>
