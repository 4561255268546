import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable, catchError, map } from "rxjs";
import { AuthenticationService } from "../authentication/authentication.service";
import { LoggingService } from "../logging/logging.service";
import { TranslateService } from "@ngx-translate/core";
import { GeofenceGroup, GroupItem, GeofenceGroupItem } from "app/models/group.model";
import { throwError } from "rxjs/internal/observable/throwError";
import { localizeSystemGroupNames } from "app/common/globals";

// Moment timezone
import Moment from "moment-timezone";
window["moment"] = Moment;

@Injectable()
export class GeofenceGroupsService {
  url = "";
  base_url = "";
  inventoryUrl = "";
  timezoneIana: string;

  constructor(
    private http: HttpClient,
    private loggingService: LoggingService,
    private authenticationService: AuthenticationService,
    private translateService: TranslateService
  ) {
    this.url = this.authenticationService.getWebserviceURL("geofenceGroup");
    this.timezoneIana = this.authenticationService.getTimeZoneIana();
  }

  getPagingUrl() {
    return this.url + "Paging";
  }

  getGeofenceGroups(accountId?: number, includingItems = false): Observable<GeofenceGroup[]> {
    return this.http
      .get(
        this.url +
          (accountId
            ? `?accountId=${accountId}&includingItems=${includingItems}`
            : `?includingItems=${includingItems}`),
        { headers: this.authenticationService.headers }
      )
      .pipe(
        map((data) => {
          return this.parseResponse(data);
        }),
        catchError(this.handleError)
      );
  }

  getGeofenceGroupById(id: number): Observable<GeofenceGroup> {
    console.log("Fetch geofencegroup by id " + id);
    return this.http.get(this.url + id, { headers: this.authenticationService.headers }).pipe(
      map((data) => {
        return this.parseReponseDetails(data);
      }),
      catchError(this.handleError)
    );
  }

  getGeofenceGroupItemsById(geofenceId: number, geofenceGroupId: number): Observable<GroupItem[]> {
    console.log("Fetch geofencegroupitems by id " + geofenceId + "/group/" + geofenceGroupId);
    return this.http
      .get(this.url + geofenceId + "/group/" + geofenceGroupId, { headers: this.authenticationService.headers })
      .pipe(
        map((data) => {
          return this.parseItemResponse(data);
        }),
        catchError(this.handleError)
      );
  }

  saveGeofenceGroup(geofenceGroup: GeofenceGroup): Observable<any> {
    console.log("save geofencegroup", geofenceGroup);
    return this.http
      .post(this.url, geofenceGroup, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  updateGeofenceGroup(geofenceGroup: GeofenceGroup): Observable<any> {
    console.log("update geofencegroup", geofenceGroup);

    return this.http
      .put(this.url + geofenceGroup.id, geofenceGroup, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  convertGeofenceGroup(geofenceGroup: GeofenceGroup): Observable<any> {
    console.log("convert geofencegroup", geofenceGroup);

    return this.http
      .get(this.url + geofenceGroup.id + "/convert", { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  deleteGeofenceGroup(geofenceGroup: GeofenceGroup): Observable<any> {
    console.log("update geofencegroup", geofenceGroup);

    return this.http
      .delete(this.url + geofenceGroup.id, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  private handleError(error: Response) {
    return throwError(() => error);
  }

  parseResponse(json: any): GeofenceGroup[] {
    this.loggingService.log(this.constructor.name, "Retrieved " + json.length + " GeofenceGroups.");

    const ident = 1;
    const geofenceGroups: GeofenceGroup[] = [];

    json.forEach((item) => {
      const geofence = this.parseReponseDetails(item);
      geofenceGroups.push(geofence);
    });

    return geofenceGroups;
  }

  parseReponseDetails(item) {
    const geofenceGroup = new GeofenceGroup();
    geofenceGroup.id = item.id;
    geofenceGroup.name = item.name;
    geofenceGroup.displayName = item.name;
    geofenceGroup.firstName = item.firstName;
    geofenceGroup.lastName = item.lastName;
    geofenceGroup.userId = item.userId;
    geofenceGroup.itemCount = item.itemCount;
    geofenceGroup.timestamp =
      item.timestamp !== undefined ? Moment.utc(item.timestamp)["tz"](this.timezoneIana) : undefined;
    geofenceGroup.accountId = item.accountId;
    geofenceGroup.companyName = item.companyName;
    geofenceGroup.resellerId = item.resellerId;
    geofenceGroup.resellerDescription = item.resellerDescription;
    geofenceGroup.leaveUserContactGroupId = item.leaveUserContactGroupId;
    geofenceGroup.enterUserContactGroupId = item.enterUserContactGroupId;
    geofenceGroup.violateInsideThresholdUserContactGroupId = item.violateInsideThresholdUserContactGroupId;
    geofenceGroup.insideThresholdInSeconds = item.insideThresholdInSeconds;
    geofenceGroup.violateOutsideThresholdUserContactGroupId = item.violateOutsideThresholdUserContactGroupId;
    geofenceGroup.outsideThresholdInSeconds = item.outsideThresholdInSeconds;
    geofenceGroup.violateOutsideAllGeofencesThresholdUserContactGroupId =
      item.violateOutsideAllGeofencesThresholdUserContactGroupId;
    geofenceGroup.outsideAllThresholdsInSeconds = item.outsideAllThresholdInSeconds;
    geofenceGroup.notifyOnEventTypeOccurrence = item.notifyOnEventTypeOccurrence;
    geofenceGroup.notifyOnEventTypeOccurenceUserContactGroupId = item.eventTypeOccurrenceUserContactGroupId;

    const selectedDevices: number[] = [];
    const devices = item.deviceIds && item.deviceIds.split(",");
    devices?.forEach((id) => {
      id = Number(id);
      selectedDevices.push(id);
    });

    geofenceGroup.selectedDevices = selectedDevices;
    geofenceGroup.devicesCount = selectedDevices.length;

    geofenceGroup.displayName = localizeSystemGroupNames(item.name, this.translateService);

    if (item.geofenceGroupItems) {
      geofenceGroup.geofenceGroupItems = [];

      item.geofenceGroupItems.forEach((geofence) => {
        const geofenceGroupItem = {
          id: geofence.id,
          geofenceGroupId: item.id,
          geofenceGroupName: item.name,
          geofenceName: geofence.geofenceName,
        };

        geofenceGroup.geofenceGroupItems.push(geofenceGroupItem);
      });
    }

    return geofenceGroup;
  }

  parseItemResponse(json: any): GroupItem[] {
    this.loggingService.log(this.constructor.name, "Retrieved " + json.length + " GeofenceGroups.");

    const ident = 1;
    const geofenceGroups: GroupItem[] = [];

    json.forEach((item) => {
      const geofence = this.parseItemReponseDetails(item);
      geofenceGroups.push(geofence);
    });

    return geofenceGroups;
  }

  parseItemReponseDetails(item) {
    const geofence = new GeofenceGroupItem();
    geofence.id = item.id;
    geofence.name = item.name;
    geofence.companyName = item.companyName;
    geofence.color = item.color;
    geofence.geoJson = item.geoJson;
    geofence.radius = item.radius;
    geofence.accountId = item.accountId;
    geofence.timestamp = item.timestamp !== undefined ? Moment.utc(item.timestamp)["tz"](this.timezoneIana) : undefined;
    geofence.startDate = item.startDate !== undefined ? Moment.utc(item.startDate)["tz"](this.timezoneIana) : undefined;
    geofence.endDate = item.endDate !== undefined ? Moment.utc(item.endDate)["tz"](this.timezoneIana) : undefined;
    geofence.name = localizeSystemGroupNames(geofence.name, this.translateService);

    geofence.displayName = localizeSystemGroupNames(item.name, this.translateService);

    return geofence;
  }
}
