<div
    *ngIf="isModalShown"
    [config]="{ show: true, backdrop: true, ignoreBackdropClick: true }"
    (onHidden)="onHidden()"
    bsModal
    #autoShownModal="bs-modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
>
    <form #userForm="ngForm" autocomplete="off">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title pull-left"><i class="fas fa-fw fa-user"></i> {{ groupType | translate }}</h5>
                    <button type="button" class="close float-right" aria-label="Close" (click)="hideModal()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div class="popup-content">
                    <ngx-loading [show]="loadingUser"></ngx-loading>
                    <div style="padding: 15px">
                        <ng-select
                            [loading]="loadingAccounts"
                            name="accountId"
                            ngDefaultControl
                            [searchable]="true"
                            [clearable]="true"
                            placeholder="{{ 'general.chooseAccount' | translate}}"
                            [(ngModel)]="user.id"
                            #accountVar="ngModel"
                            [disabled]="accounts.length === 1"
                            class="form-control table-inline"
                            style="width: 100%"
                            (ngModelChange)="accountChanged($event)"
                            required
                        >
                            <ng-option *ngFor="let account of accounts | orderBy : 'name':false" [value]="account.id">
                                <i class="fas fa-fw fa-building"></i> {{ account.name }}
                            </ng-option>
                        </ng-select>
                    </div>
                    <div style="padding: 15px" class="popupOverflow">
                        <fh-viewer-overview
                            #viewerOverview
                            [linkUrl]="groupLinkUrl"
                            [showIbox]="false"
                            name="{{ groupType | translate }}"
                            [formMode]="formMode"
                        >
                        </fh-viewer-overview>
                    </div>
                </div>

                <div class="modal-footer">
                    <button [disabled]="userForm.invalid" class="btn btn-primary" (click)="finish()">
                        <i class="fas fa-fw fa-check"></i> {{'general.assign' | translate }}
                    </button>

                    <button class="btn btn-danger" (click)="close()">
                        <i class="fas fa-fw fa-times"></i> {{'general.cancel' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>
