import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable, catchError, map } from "rxjs";
import { AuthenticationService } from "../authentication/authentication.service";
import { LoggingService } from "../logging/logging.service";
import { DeviceStatesItem, StateObject } from "app/models/StateObject";
import { throwError } from "rxjs/internal/observable/throwError";

// Moment timezone
import Moment from "moment-timezone";

window["moment"] = Moment;

@Injectable()
export class LocationService {
  url = "";
  timezoneIana: string;

  constructor(
    private http: HttpClient,
    private loggingService: LoggingService,
    private authenticationService: AuthenticationService
  ) {
    this.url = this.authenticationService.getWebserviceURL("");
    this.timezoneIana = this.authenticationService.getTimeZoneIana();
  }

  getStates(
    devices,
    drivers,
    timestamp = null,
    deviceState = 0,
    activeOnly = true,
    shared = false
  ): Observable<StateObject> {
    const request = {
      deviceIds: devices,
      driverIds: drivers,
      previousLookupTimestamp: timestamp,
      deviceState: deviceState,
      activeOnly: activeOnly,
    };

    let headers;
    if (shared) {
      this.url = this.authenticationService.getWebserviceURL("");
      headers = this.authenticationService.shareheaders;
    } else {
      headers = this.authenticationService.headers;
    }

    const body = JSON.stringify(request);

    return this.http.post(this.url + "StateLookup", body, { headers: headers }).pipe(
      map((data) => this.parseResponse(data)),
      catchError(this.handleError)
    );
  }

  getCities(deviceStates): Observable<any> {
    const request = deviceStates.map((x) => ({
      AssetId: x.id,
      Latitude: x.currentPosition?.latitude,
      Longitude: x.currentPosition?.longitude,
    }));

    const body = JSON.stringify(request);

    return this.http
      .post(this.url + "AddressLookup", body, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  getGeofenceLocations(geofences, timestamp = null): Observable<any> {
    const request = {
      GeofenceIds: geofences,
      previousLookupTimestamp: timestamp,
    };

    const body = JSON.stringify(request);

    return this.http
      .post(this.url + "GeofenceLookup", body, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  private handleError(error: Response) {
    return throwError(() => error);
  }

  parseResponse(json: any): StateObject {
    const locationO: StateObject = new StateObject();

    locationO.timestamp = json?.timestamp ?? new Date();
    locationO.deviceStates = [];

    if (json == null) {
      this.loggingService.log(this.constructor.name, "Retrieved no locations.");

      return locationO;
    }

    if (json?.deviceStates != null) {
      const deviceStates = Object.values(json?.deviceStates);
      this.loggingService.log(this.constructor.name, "Retrieved " + (deviceStates?.length ?? "no") + " locations.");

      deviceStates?.forEach((item) => {
        const location = this.parseDeviceStateReponseDetails(item);
        locationO.deviceStates.push(location);
      });
    }
    if (json?.driverStates != null) {
      const driverStates = Object.values(json?.driverStates);
      this.loggingService.log(this.constructor.name, "Retrieved " + (driverStates?.length ?? "no") + " locations.");

      driverStates?.forEach((item) => {
        locationO.driverStates.push(item);
      });
    }
    return locationO;
  }

  parseDeviceStateReponseDetails(item): DeviceStatesItem {
    const formatTimestamps = (data) =>
      Object.keys(data).every((v) => {
        const value = data[v];

        if (value === null || value === 0) {
          return true;
        }

        if (
          v === "updateTimestamp" ||
          v === "stateChangedTimestamp" ||
          v === "startedTimestamp" ||
          v === "calculatedTimestamp" ||
          v === "UpdateTimeStamp" ||
          v === "Timestamp"
        ) {
          data[v] = Moment.utc(value)["tz"](this.timezoneIana);
        }

        if (typeof value === "object") {
          formatTimestamps(value);
        }

        return true;
      });

    let locationObject = new DeviceStatesItem();
    locationObject = item;

    formatTimestamps(locationObject);

    return locationObject;
  }
}
