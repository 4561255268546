<div class="ibox-content slick" style="position: relative; overflow: visible">
    <ngx-loading [show]="loading"></ngx-loading>
    <table style="width: 100%" class="table table-fixed nowrap dtr-inline no-footer">
        <tbody *ngIf="!geofence">
            <tr>
                <td colspan="4">
                    {{ "general.noGeofence" | translate }}
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="geofence">
            <ng-container *ngIf="formMode != 2">
                <tr [hidden]="!permissions['Resellers_View']">
                    <td class="row_opening">
                        {{ "general.resellerDescription" | translate }}
                    </td>
                    <td class="padding-readonly-correct row_content">
                        <a class="secondary link_bolder" href="/#/ResellerDetails/Index/{{ geofence?.resellerId }}">{{
                            geofence?.resellerDescription
                        }}</a>
                    </td>
                </tr>
                <tr [hidden]="!permissions['Accounts_View']">
                    <td class="row_opening">
                        {{ "general.account" | translate }}
                    </td>
                    <td class="row_content padding-readonly-correct">
                        <a class="secondary link_bolder" href="/#/AccountDetails/Index/{{ geofence?.accountId }}">{{
                            geofence?.companyName
                        }}</a>
                    </td>
                </tr>
            </ng-container>
            <ng-container *ngIf="formMode == 2">
                <tr [hidden]="!permissions['Resellers_View'] && selectedResellerId">
                    <td class="row_opening">
                        {{ "general.resellerDescription" | translate }}
                        <span class="error" *ngIf="resellerVar.invalid">*</span>
                    </td>
                    <td class="row_content" style="position: relative; overflow: visible">
                        <fh-reseller-input
                            name="reseller"
                            #resellerVar="ngModel"
                            (onChanged)="resellerChanged($event)"
                            required
                            placeholder="{{ 'general.chooseReseller' | translate }}"
                            [tableInline]="true"
                            [(ngModel)]="selectedResellerId"
                        >
                        </fh-reseller-input>
                    </td>
                </tr>
                <tr [hidden]="!permissions['Accounts_View'] && geofence.accountId">
                    <td class="row_opening">
                        {{ "general.account" | translate }}
                        <span class="error" *ngIf="accountVar.invalid">*</span>
                    </td>
                    <td class="row_content" style="position: relative; overflow: visible">
                        <fh-account-input
                            name="account"
                            #accountVar="ngModel"
                            (onChanged)="accountChanged($event)"
                            required
                            [selectedResellerId]="selectedResellerId"
                            [tableInline]="true"
                            placeholder="{{
                                (selectedResellerId ? 'general.chooseAccount' : 'general.firstChooseReseller')
                                    | translate
                            }}"
                            [(ngModel)]="geofence.accountId"
                        >
                        </fh-account-input>
                    </td>
                </tr>
            </ng-container>
            <tr>
                <td class="row_opening">
                    {{ "general.name" | translate }}
                    <span class="error" *ngIf="name.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input
                        type="text"
                        [readonly]="formMode == 1"
                        name="name"
                        class="form-control table-inline"
                        placeholder="{{ 'placeholder.name' | translate }}"
                        [(ngModel)]="geofence.name"
                        required
                        minlength="4"
                        #name="ngModel"
                    />
                </td>
            </tr>
            <tr class="alert alert-danger" *ngIf="name.invalid && (name.dirty || name.touched)">
                <td colspan="2">
                    <div *ngIf="name.errors.minlength">* name must be at least 4 characters long.</div>
                    <div *ngIf="name.errors.required">* name is required</div>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ "general.reference" | translate }}
                </td>
                <td class="row_content">
                    <input
                        type="text"
                        [readonly]="formMode == 1"
                        name="reference"
                        class="form-control table-inline"
                        placeholder="{{ 'placeholder.reference' | translate }}"
                        [(ngModel)]="geofence.referenceId"
                        #reference="ngModel"
                    />
                </td>
            </tr>
            <tr *ngIf="formMode != 1 && geofence.layerRef?.corridor > 0">
                <td class="row_opening">
                    {{ "general.radiusInMeters" | translate }}
                </td>
                <td class="row_content" style="position: relative; overflow: visible">
                    <input
                        (change)="updateRadius($event)"
                        type="range"
                        min="5"
                        max="300"
                        [value]="geofence.radius"
                        class="slider"
                    />
                </td>
            </tr>
            <tr>
                <td style="width: 50%; vertical-align: middle">
                    {{ "general.color" | translate }}
                </td>
                <td class="row_content">
                    <span *ngIf="formMode != 1">
                        <input
                            [style.background]="geofence.color"
                            [cpPosition]="'bottom-left'"
                            class="form-control table-inline"
                            [colorPicker]="geofence.color"
                            [cpAlphaChannel]="'disabled'"
                            [cpOutputFormat]="'hex'"
                            [cpPresetColors]="['#fff', '#000', '#2889e9', '#e920e9', '#fff500', 'rgb(236,64,64)']"
                            placeholder="{{ 'placeholder.color' | translate }}"
                            (colorPickerChange)="geofence.color = $event; colorChanged($event)"
                        />
                    </span>
                    <span *ngIf="formMode == 1" [style.background]="geofence.color" class="form-control table-inline">
                    </span>
                </td>
            </tr>
            <!-- <tr>
                <td class="row_opening" style="width:50%; vertical-align: middle;">
                    {{ 'general.type' | translate}} <i
                        [tooltip]="'If you set the Type on \'No Theft Area\', a Theft Event within this geofence will have a certainty of 10%.'"
                        class="fas fa-fw fa-question"></i>
                </td>
                <td class="row-content" style="vertical-align: middle; padding-top: 0; padding-bottom: 0;">
                    <ng-select class="form-control table-inline" name="geofenceTypes" style="height: unset;"
                        ngDefaultControl [items]="geofenceTypes" [multiple]="true" [readonly]="formMode == 1"
                        bindLabel="name" [closeOnSelect]="false" bindValue="value" [(ngModel)]="selectedTypes"
                        (ngModelChange)="typesChanged()">
                        <ng-template ng-option-tmp let-item="item" let-index="index" let-item$="item$">
                            <input [name]="'item-'+index" id="item-{{index}}" type="checkbox"
                                [ngModel]="item$.selected" /> {{item.name}}
                        </ng-template>
                    </ng-select>

                </td>
            </tr> -->
            <tr *ngIf="permissions['FleetManagement_CargoAndRoutes']">
                <td class="row_opening" style="width: 50%; vertical-align: middle">
                    {{ "general.demarcatesRoutes" | translate }}
                    <i
                        [tooltip]="
                            'This option marks this geofence as the start and / or end of a collection of trips. These are shown in Route Cargo Report.'
                        "
                        class="fas fa-fw fa-question"
                    ></i>
                </td>
                <td class="row-content">
                    <ng-select
                        class="form-control table-inline"
                        name="routeProperty"
                        ngDefaultControl
                        [multiple]="false"
                        [readonly]="formMode == 1"
                        [closeOnSelect]="false"
                        [clearable]="false"
                        [searchable]="false"
                        [(ngModel)]="geofence.routeProperty"
                    >
                        <ng-option [value]="0">{{ "enums.demarcatesRoutes.0" | translate }}</ng-option>
                        <ng-option [value]="1">{{ "enums.demarcatesRoutes.1" | translate }}</ng-option>
                        <ng-option [value]="2">{{ "enums.demarcatesRoutes.2" | translate }}</ng-option>
                        <ng-option [value]="3">{{ "enums.demarcatesRoutes.3" | translate }}</ng-option>
                    </ng-select>
                </td>
            </tr>
            <tr>
                <td style="width: 50%; vertical-align: middle">
                    {{ "general.isPersonal" | translate }}
                    <i
                        [tooltip]="
                            'When geofence is personal it will become a private geofence and will be hidden to other users of the account.'
                        "
                        class="fas fa-fw fa-question"
                    ></i>
                </td>
                <td class="padding-readonly-correct row_content">
                    <div class="fa_checkbox">
                        <input
                            name="isPersonal"
                            id="isPersonal"
                            #personal="ngModel"
                            type="checkbox"
                            [disabled]="formMode == 1"
                            [(ngModel)]="geofence.isPersonal"
                        />
                        <label for="isPersonal"></label>
                    </div>
                </td>
            </tr>
            <tr *ngIf="formMode != 2 && geofence?.isArchived">
                <td class="row_opening" [class.text-danger]="geofence?.isArchived">
                    {{ "general.archived" | translate }}
                </td>
                <td [class.text-danger]="geofence?.isArchived" class="row_content padding-readonly-correct">
                    {{ geofence?.isArchived }}
                </td>
            </tr>
            <tr *ngIf="formMode == 1 && geofence.isPersonal">
                <td class="row_opening">
                    {{ "general.personalUser" | translate }}
                </td>
                <td class="padding-readonly-correct row_content">
                    <a class="secondary link_bolder" href="/#/UserDetails/Index/{{ geofence?.userId }}">
                        {{ geofence?.userName }}
                    </a>
                </td>
            </tr>
            <tr *ngIf="formMode != 1 && geofence.isPersonal">
                <td class="row_opening">
                    {{ "general.personalUser" | translate }}
                    <span class="error" *ngIf="!geofence.userId">*</span>
                </td>
                <td class="row_content" style="position: relative; overflow: visible">
                    <fh-user-chooser
                        [accountId]="geofence.accountId"
                        [userId]="geofence.userId"
                        [formMode]="formMode"
                        (onUserChanged)="geofence.userId = $event ? $event.id : undefined"
                    >
                    </fh-user-chooser>
                </td>
            </tr>
        </tbody>
    </table>
</div>
