import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { FormMode } from "app/common/enums";
// Moment timezone
import { WaslService } from "app/services/wasl/wasl.service";
import { AccountService } from "app/services/account/account.service";
import { AccountInventory } from "app/models/account.model";
import { WarehouseService } from "app/services/warehouse/warehouse.service";
import { LandCoordinate, Warehouse } from "app/models/warehouse.model";
import { Geofence } from "app/models/geofence.model";
import { LeafletMapComponent } from "../shared/usercontrols/leafletMap.component";
import * as turf from "@turf/turf";
import { roundAsString } from "app/common/globals";

declare const L;
@Component({
  selector: "fh-warehouse-wasl",
  templateUrl: "wasl.template.html",
})
export class WarehouseWaslViewComponent implements OnInit {
  @ViewChild(LeafletMapComponent, { static: false }) leafletMapComponent: LeafletMapComponent;
  sub;
  account: AccountInventory;
  loading = false;
  loadingZones = false;
  allowEdit = false;
  error: any;
  warning: any;
  success: any;

  formMode = FormMode.read;
  warehouse: Warehouse;
  zones = [];
  warehouseAzure: any;
  geofence: Geofence;
  warehouseId: any;

  constructor(
    private cd: ChangeDetectorRef,
    private warehouseService: WarehouseService,
    private waslService: WaslService,
    private accountService: AccountService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.warehouse = null;
  }

  ngOnInit() {
    this.loading = true;
    this.warehouse = new Warehouse();
    this.warehouse.id = "";

    this.sub = this.route.params.subscribe((params) => {
      const id = params["id"];

      this.warehouseId = id;
      this.warehouseService.getWarehouseById(id).subscribe((warehouses) => {
        this.warehouse = warehouses;

        this.geofence = new Geofence();
        this.geofence.name = this.warehouse.name;
        this.geofence.radius = 200;
        this.geofence.geoJson = this.warehouse.geoJson;

        setTimeout(() => {
          this.parseMapData();
        }, 1000);

        this.getZones();

        // Get warehouse from azure
        this.warehouseService.getWarehouseByRowKey(this.warehouse.referenceId).subscribe({
          next: (warehouseAzure) => {
            this.warehouseAzure = warehouseAzure;
          },
          error: (error) => {
            this.error = error;
            this.error.statusText = "Error fetching warehouse from azure";

            this.cd.markForCheck();
          },
        });

        this.accountService.getAccountById(this.warehouse.accountId).subscribe({
          next: (account) => {
            this.account = account;
            console.log(account);
          },
          error: (error) => {
            this.error = error;
            this.error.statusText = "Error fetching account";

            this.cd.markForCheck();
          },
        });

        if (this.warehouse == null) {
          this.router.navigate(["/Warehouses/Overview"]);
        }

        this.loading = false;
      });
    });
  }

  onMapReady() {
    setTimeout(() => {
      this.parseMapData();
    }, 1000);
  }

  parseMapData() {
    if (this.geofence && this.geofence.geoJson && this.leafletMapComponent && this.leafletMapComponent.geofenceLayer) {
      // const latLng = this.leafletMapComponent.geofenceLayer.getLatLngs();
      const geofenceLayer = this.leafletMapComponent.geofenceLayer;

      const latLng = geofenceLayer.getBounds().getCenter();

      if (latLng) {
        this.warehouse.latitude = roundAsString(latLng.lat, 5);
        this.warehouse.longitude = roundAsString(latLng.lng, 5);
      }

      const area = turf.area(geofenceLayer.toGeoJSON());
      this.warehouse.properties.wasl.landAreaInSquareMeter = roundAsString(area, 0);
    }
  }

  getZones() {
    this.loadingZones = true;
    this.error = undefined;
    this.warehouseService.getWarehouseZonesByReference(this.warehouse.referenceId).subscribe({
      next: (zones) => {
        this.zones = zones;

        this.loadingZones = false;
      },
      error: (error) => {
        this.loadingZones = false;
        this.error = error;
      },
    });
  }

  getWarehouseInfo() {
    if (this.loading !== true) {
      this.loading = true;
    }

    this.warehouseService.getWarehouseById(this.warehouse.id).subscribe((warehouse) => {
      this.warehouse = warehouse;
      this.loading = false;

      this.parseMapData();
    });
  }

  // Wasl
  setFormMode(mode) {
    this.formMode = mode;

    if (this.formMode === FormMode.read) {
      this.loading = true;
      this.getWarehouseInfo();
    }
  }

  inquire(company) {
    this.loading = true;

    this.waslService.getInquireAccountWarehouses(this.account.id, this.account.properties.wasl.referenceKey).subscribe({
      next: (registerResult) => {
        this.loading = false;
        if (registerResult.isSuccess) {
          this.success = {
            statusText: "Success",
            json: JSON.parse(registerResult.entity.json),
            success: registerResult.message,
          };

          const result = JSON.parse(registerResult.entity.json);
          this.error = null;
        } else if (registerResult.isWarning) {
          this.warning = {
            statusText: "Warning",
            success: registerResult.message,
          };
          this.error = null;
        } else {
          this.error = {
            statusText: "Error",
            error: registerResult.message,
          };
        }
        this.getWarehouseInfo();
      },
      error: (error) => {
        console.log(error);
        this.error = error;
        this.loading = false;
        this.cd.markForCheck();
      },
    });
  }

  register(warehouse: Warehouse) {
    this.loading = true;

    const geoJson = JSON.parse(warehouse.geoJson);

    warehouse.properties.wasl.landCoordinates = "";

    geoJson.geometry.coordinates[0].forEach((item) => {
      const coord = new LandCoordinate();
      coord.x = item[0]; // longitude
      coord.y = item[1]; // latitude

      warehouse.properties.wasl.landCoordinates += coord.x + "," + coord.y + ";";
    });

    const warehouseItem = {
      activity: "SFDA",
      name: warehouse.name,
      phone: warehouse.properties.wasl.phone,
      address: warehouse.address,
      city: warehouse.properties.wasl.city,
      landCoordinates: warehouse.properties.wasl.landCoordinates,
      longitude: warehouse.longitude,
      latitude: warehouse.latitude,
      landAreaInSquareMeter: warehouse.properties.wasl.landAreaInSquareMeter,
      licenseNumber: warehouse.properties.wasl.licenseNumber,
      licenseIssueDate: warehouse.properties.wasl.licenseIssueDate,
      licenseExpiryDate: warehouse.properties.wasl.licenseExpiryDate,
      managerMobile: warehouse.properties.wasl.managerMobile,
      email: warehouse.properties.wasl.email,
    };

    this.waslService
      .registerWarehouse(this.warehouse.id, warehouseItem, this.account.properties.wasl.referenceKey)
      .subscribe({
        next: (registerResult) => {
          this.loading = false;
          if (registerResult.isSuccess) {
            this.success = {
              statusText: "Success",
              success: registerResult.message,
            };
            this.error = null;
          } else if (registerResult.isWarning) {
            this.warning = {
              statusText: "Warning",
              success: registerResult.message,
            };
            this.error = null;
          } else {
            this.error = {
              statusText: "Error",
              error: registerResult.message,
            };
          }
          this.getWarehouseInfo();
        },
        error: (error) => {
          console.log(error);
          this.error = error;
          this.loading = false;
          this.cd.markForCheck();
        },
      });
  }

  unRegister(warehouse) {
    this.loading = true;

    this.waslService.unregisterWarehouse(this.warehouse.id).subscribe({
      next: (unregisterResult) => {
        console.log(unregisterResult);
        this.loading = false;
        if (unregisterResult.isSuccess) {
          this.success = {
            statusText: "Success",
            success: unregisterResult.resultCode,
          };
          this.error = null;
        } else {
          this.error = {
            statusText: "Error",
            error: unregisterResult.resultCode,
          };
        }
        this.getWarehouseInfo();
      },
      error: (error) => {
        console.log(error);
        this.error = error;
        this.loading = false;
        this.cd.markForCheck();
      },
    });
  }

  registerInventory(inventory) {
    this.loading = true;

    const inventoryItem = {
      activity: "SFDA",
      name: inventory.name,
      inventoryNumber: "1a",
      storingCategory: inventory.type,
    };

    console.log(inventory);

    this.waslService.registerInventory(this.warehouse.id, inventoryItem, inventory.rowKey).subscribe({
      next: (registerResult) => {
        this.loading = false;
        if (registerResult.isSuccess) {
          this.success = {
            statusText: "Success",
            success: registerResult.message,
          };
          this.error = null;
        } else if (registerResult.isWarning) {
          this.warning = {
            statusText: "Warning",
            success: registerResult.message,
          };
          this.error = null;
        } else {
          this.error = {
            statusText: "Error",
            error: registerResult.message,
          };
        }
        this.getWarehouseInfo();
        this.getZones();
      },
      error: (error) => {
        console.log(error);
        this.error = error;
        this.loading = false;
        this.cd.markForCheck();
      },
    });
  }

  unRegisterInventory(inventory) {
    this.loading = true;

    this.waslService.unregisterInventory(this.warehouse.id, inventory.rowKey).subscribe({
      next: (unregisterResult) => {
        console.log(unregisterResult);
        this.loading = false;
        if (unregisterResult.isSuccess) {
          this.success = {
            statusText: "Success",
            success: unregisterResult.resultCode,
          };
          this.error = null;
        } else {
          this.error = {
            statusText: "Error",
            error: unregisterResult.resultCode,
          };
        }
        this.getWarehouseInfo();
        this.getZones();
      },
      error: (error) => {
        console.log(error);
        this.error = error;
        this.loading = false;
        this.cd.markForCheck();
      },
    });
  }
}
