export class Trigger {
  public id: any;
  public accountId: any;
  public companyName: string;
  public resellerDescription;
  public resellerId;
  public name: string;
  public isEnabled = true;

  public triggerInputSource = 0;
  public intervalInSeconds = 60;
  public domainEventInput = 0;

  public deviation: number;
  public duration: number;
  public delayInSeconds: number;
  public notifyDriverByEmail: boolean;
  public notifyDriverBySMS: boolean;

  public assetGroups = [];
  public userContactGroups = [];

  public sendOutputMessage: boolean;
  public sendMobileNotification: boolean;
  public sendFmNotification: boolean;

  public useWebHook: boolean;
  public webhookEndpointId: any;
  public webHookType = 2;
  public webHookMethod = 2;
  public webHookContentType = 1;
  public webHookUrl: string;
  public webHookHeaders: WebHookHeader[] = [];
  public webhookMoment = 3;
  public priority = 2;
  public episodeType: any;
  public storeAsEpisode: boolean;

  public rules = [];

  public messageBodyTemplate = `Dear {{ContactName}},

We have an event on our system named {{TriggerName}}. The episode has started on {{TimestampBegin}} and ended on {{TimestampEnd}}`;

  public json = "";
  public subjectTemplate = "Event {{TriggerName}} on your vehicle: {{AssetName}} has {{NotificationMoment}}";
  public episodeCount: any;
  public notificationCount: any;
  public webhookEndpointNotificationCount: any;
  public hasDebounce = true;
  public debounceInSeconds = 600;
  public triggerType = 2;
  public outputMessageMoment = 3;

  public geofenceSource = [];
  public geofenceEventType;
  public allGeofences = false;
}

export class WebHookHeader {
  public webHookHeaderName;
  public webHookHeaderProperty;
}

export class TriggerRule {
  public ruleType;

  // Duration of rule
  public filterDuration;
  public minDuration;

  // general
  public thresholdCompare;
  public threshold;
  public ruleProperty;

  // DeviceState
  public deviceState;
  public triggerDeviceSensor;

  // Event
  public eventType;

  // IO
  public ioPort;
  public ioState;

  // Geofences
  public geofenceSource;
  public geofences;
  public geofenceGroups;
  public allGeofences;
  public geofenceEventType = 0;

  // template
  public templateId;

  // Inside/Outside
  public insideOutsideSettings;

  // Virtual sensors
  public virtualSensorOutputType;
}
