import {
  Component,
  ChangeDetectorRef,
  ViewChild,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { AuthenticationService } from "app/services/authentication/authentication.service";
import { DriverService } from "app/services/driver/driver.service";
import { ModalDirective, BsModalRef } from "ngx-bootstrap/modal";
import { Subject } from "rxjs";
import { Device } from "app/models/device.model";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";

@Component({
  selector: "fh-driver-chooser",
  templateUrl: "driverChooser.template.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [],
})
export class DriverChooserComponent implements OnInit {
  @ViewChild("autoShownModal") autoShownModal: ModalDirective;
  modalRef: BsModalRef;
  isModalShown = false;

  token: string;
  @Input() device: Device;
  @Output() onSelect = new EventEmitter();

  dropdown_IssueType = "";

  dtOptions = {};

  min: number;
  max: number;

  loading = false;
  languageLoaded: boolean;
  permissions: {};

  selected: any = { id: null, to: null, from: new Date() };

  search$ = new Subject<string>();
  searchTerm: any;

  toEnabled = false;

  step = 1;

  ismeridian = false;

  // Datepicker
  public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();

  constructor(
    private translateService: TranslateService,
    private cd: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private driverService: DriverService
  ) {
    this.token = this.authenticationService.getAuthToken();

    // Datepicker
    this.dpConfig.containerClass = "theme-default"; // or whatever color
    this.dpConfig.dateInputFormat = "lll";
    this.dpConfig.withTimepicker = true;
    this.dpConfig.keepDatepickerOpened = true;
    this.dpConfig.adaptivePosition = true;
    this.dpConfig.isAnimated = true;
  }

  close() {
    this.toEnabled = false;
    this.step = 1;
    this.hideModal();
    this.cd.markForCheck();
  }

  onHidden() {
    this.hideModal();
    this.cd.markForCheck();
  }

  onToDate(event) {
    if (event !== null) {
      this.toEnabled = true;
    }
  }

  resetToDate() {
    this.selected.to = null;
    this.toEnabled = false;
    this.cd.markForCheck();
  }

  select() {
    // this.hideModal();
    // this.onSelect.emit({ id: this.selected.id, name: this.selected.name });
    this.step++;
  }

  finish() {
    let driver;
    [this.selected, driver] = [{ id: null, to: null, from: new Date() }, this.selected];
    this.step = 1;
    this.hideModal();

    if (!this.toEnabled) {
      driver.to = null;
      driver.toTime = null;
    }

    this.onSelect.emit(driver);
  }

  clickHandler(info: any): void {
    const { from } = this.selected;
    this.selected = info;
    this.selected.from = from;
  }

  ngOnInit(): void {
    this.permissions = this.authenticationService.permissions;

    this.translateService.get("general.date").subscribe((value) => {
      this.languageLoaded = true;
      this.cd.markForCheck();
    });
  }

  // Model
  public showModal(): void {
    console.log("show modal");
    this.isModalShown = true;
    this.cd.markForCheck();
  }

  public hideModal(): void {
    this.isModalShown = false;
    this.cd.markForCheck();
  }
}
