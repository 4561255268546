import { Component, Input, OnInit } from "@angular/core";
import { ControlContainer, NgForm } from "@angular/forms";
import { FormMode } from "app/common/enums";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import { WarehouseZone } from "app/models/warehouse.model";

@Component({
  selector: "fh-warehouse-zone-details",
  templateUrl: "warehouseZoneDetails.component.html",
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class WarehouseZoneDetailsComponent implements OnInit {
  @Input() zone: WarehouseZone;
  @Input() loading = false;
  @Input() formMode: any = FormMode.read;

  // Datepicker
  public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();

  constructor() {}

  ngOnInit(): void {}
}
