import {
  Component,
  AfterViewInit,
  Input,
  ElementRef,
  ViewChild,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from "@angular/core";

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { ProjectService } from "app/services/project/project.service";

const noop = () => {};

@Component({
  selector: "fh-project-input",
  templateUrl: "./projectSelector.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: ProjectInputComponent }],
})
export class ProjectInputComponent implements OnChanges, OnInit, AfterViewInit, ControlValueAccessor {
  @Input() placeholder: string;
  @Input() clearable = true;
  @Input() selectedAccountId;
  @Input() autoSelect = true;
  @Input() readonly = false;
  @Input() multiple = false;
  @Input() tableInline = true;
  @Input() setCurrentProjects;

  @Output() onChanged = new EventEmitter();

  projects = [];

  loadingProjects = false;

  @ViewChild("input") el: ElementRef;

  private _value: string;
  private _onChange: (_: any) => void = noop;

  get value(): any {
    return this._value;
  }

  set value(v: any) {
    if (v !== this._value) {
      this._value = v;
      this._onChange(v);
      this.cd.markForCheck();
    }
  }

  constructor(private projectService: ProjectService, private cd: ChangeDetectorRef) {}

  projectChanged() {
    this.onChanged.emit(this.value);
    this.cd.markForCheck();
  }

  ngOnChanges() {
    setTimeout(() => {
      // Get accounts again
      this.loadProjects();
      this.cd.markForCheck();
    }, 0);
  }

  compareFn(item, selected): boolean {
    if (item && selected) {
      return item == selected;
    }
    return false;
  }

  loadProjects() {
    this.projects = [];
    this.cd.markForCheck();

    if (this.selectedAccountId) {
      this.loadingProjects = true;
      this.cd.markForCheck();

      if (this.selectedAccountId && this.selectedAccountId !== 0) {
        this.projectService.getProjectsByAccount(this.selectedAccountId).subscribe((result) => {
          result = result.sort((a, b) => (a.name < b.name ? -1 : 1));

          this.projects = result;

          // Autoselect
          if (!this.readonly && this.projects.length === 1 && this.autoSelect) {
            console.log("Setting default to " + this.projects[0].id);
            if (this.multiple) {
              this.value = [this.projects[0].id];
            } else {
              this.value = this.projects[0].id;
            }

            this.cd.markForCheck();
          }

          //Set current projects when subscription is edited
          if (this.setCurrentProjects !== undefined) {
            this.value = this.setCurrentProjects;
          }

          this.loadingProjects = false;
          this.cd.markForCheck();
        });
      } else {
        this.loadingProjects = false;
        this.cd.markForCheck();
      }
    }
  }

  ngOnInit() {}

  ngAfterViewInit() {
    const __this = this;
  }

  writeValue(value: any) {
    this._value = value;
    this.cd.markForCheck();
  }

  registerOnChange(fn: (value: any) => void) {
    this._onChange = fn;
    this.cd.markForCheck();
  }

  registerOnTouched(fn: any) {
    this.cd.markForCheck();
  }
}
