import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { AuthenticationService } from "app/services/authentication/authentication.service";
import { DeviceService } from "app/services/device/device.service";
import { ModalDirective } from "ngx-bootstrap/modal";
import { ConfirmationModalComponent } from "../confirmationModal.component";
import { HandleTaskProcessingComponent } from "./handleTaskProcessing.component";

@Component({
  selector: "fh-task-dropdown",
  templateUrl: "singleTaskProcessing.template.html",
  changeDetection: ChangeDetectionStrategy.Default,
})
export class SingleTaskProcessingDropdownComponent implements OnChanges {
  @ViewChild("confirmModal", { static: false }) confirmModal: ConfirmationModalComponent;
  @ViewChild(HandleTaskProcessingComponent, { static: true }) handleAction: HandleTaskProcessingComponent;

  @Input() batchModal: ModalDirective;

  @Input() entity;
  @Input() entityTypes;

  @Output() onFinishAction = new EventEmitter();

  taskObject;
  processing = false;

  selectedAction;
  batchActions = [];

  permissions: {};

  constructor(
    private cd: ChangeDetectorRef,
    private translate: TranslateService,
    private deviceService: DeviceService,
    private authenticationService: AuthenticationService
  ) {
    this.permissions = this.authenticationService.permissions;
  }

  ngOnChanges(): void {
    if (!this.entityTypes && !this.entity) {
      return;
    }

    if (this.entity?.id) {
      this.batchActions = this.handleAction.getBatchActionList(this.entityTypes, this.entity);
      this.cd.markForCheck();
    }
  }

  handleError(error): any {
    console.log(error);
  }

  // Popup
  showModal(selectedAction) {
    this.selectedAction = selectedAction;
    this.confirmModal.showModal("");
  }

  hideModal(event = null) {
    this.processing = false;
    this.cd.markForCheck();

    this.selectedAction = 0;
    this.confirmModal.hideModal();
  }

  resetAction(event = null) {
    this.selectedAction = 0;
  }

  process(event) {
    this.processing = true;
    this.cd.markForCheck();

    if (event != undefined) {
      this.handleAction.processAction(event).subscribe({
        next: (result) => {
          this.onFinishAction.emit({ completed: true, result });
          this.processing = false;
          this.cd.markForCheck();
        },
        error: (error) => {
          this.onFinishAction.emit({ completed: false, error });
          this.processing = false;
          this.cd.markForCheck();
        },
      });
    }

    this.hideModal();
  }
}
