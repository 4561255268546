import { Pipe, PipeTransform } from "@angular/core";
import { DeviceSensor } from "app/models/sensor.model";

@Pipe({
  name: "textFilter",
})
export class TextFilterPipe implements PipeTransform {
  transform(items: DeviceSensor[], searchText: string): DeviceSensor[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLowerCase();
    return items.filter((it) => {
      const parameter = it.parameter;
      const name = it.name;
      return [parameter, name].some((predicate) => predicate && predicate.toLowerCase().indexOf(searchText) > -1);
    });
  }
}
