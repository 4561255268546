<a
    *ngIf="geofence?.value?.episodes?.length === 0"
    class="fleet-overview-date"
    style="display: flex; position: relative; background: white"
>
    <ngx-loading [show]="geofence.value.loading"></ngx-loading>
    {{'general.noDataFound' | translate}}
</a>

<ng-container *ngFor="let episode of renderEpisodes(geofence?.value?.episodes); let first = index;">
    <a *ngIf="isNewDate(episode)" style="justify-content: space-between" class="fleet-overview-date">
        {{ episode.dateTime | amDateFormat:'dddd LL' }}
    </a>

    <a
        class="fleet-overview-item"
        [ngStyle]="{'border-left': '7px solid ' + colorArray[(episode.ident) % colorArray.length]}"
    >
        <span
            class="fontColor"
            style="display: flex; flex-direction: column; flex: auto; overflow: hidden"
            [class.activeTrip]="episode.tripType === 3 || episode.tripType === 4"
        >
            <span class="fo-item-heading">{{ episode.assetName }} </span>
            <span class="fo-content"
                >{{ episode.dateTime | amDateFormat:'HH:mm' }} - {{ roundSeconds(episode.durationInSeconds)}}
            </span>
        </span>
        <span
            class="fontColor fa arrow"
            [ngClass]="episode.isOpen === true ? 'fa-rotate-270' : 'fa-flip-horizontal'"
            style="margin: 0px 7.5px"
        ></span>
        <i
            [class.green]="episode.plotted === true"
            style="
                align-items: center;
                min-height: 25px;
                display: flex;
                justify-content: center;
                min-width: 20px;
                padding-right: 2.5px;
            "
            class="fa fa-fw fa-eye eye-active fontColor"
        ></i>
    </a>
</ng-container>
