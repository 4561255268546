<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/"> <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Notifications/">{{'general.notifications' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Notifications/Triggers/Overview">{{'general.triggers' | translate }}</a>
                </li>
                <li class="breadcrumb-item active">{{'menu.add' | translate }}</li>
            </ol>
        </nav>

        <span class="fa-filter-container noselect">
            <a
                style="margin-left: 2px"
                class="btn btn-info margin-fix d-none d-md-inline-flex"
                title="Help"
                href=" https://help.360locate.com/knowledge-base/triggers/"
                target="_blank"
                ><i class="fa fa-fa fa-book" style="margin-right: 6px"></i>
                <span class="d-none d-md-inline-flex">{{'general.needHelp' | translate }}</span></a
            >
        </span>
    </div>
    <fh-notifications-navigation>
        <li class="active">
            <a href="/#/Notifications/Triggers/Add/"
                ><i class="fa fa-fw fa-bell-on"></i>
                <div>{{ 'general.add' | translate }}</div>
            </a>
        </li>
    </fh-notifications-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">
        <div class="row">
            <div class="col-lg-12">
                <fh-error [success]="success" [error]="error"></fh-error>
            </div>
        </div>
        <form #userForm="ngForm">
            <div class="row">
                <div class="col-lg-6">
                    <div class="ibox" style="position: relative">
                        <ngx-loading [show]="loading"></ngx-loading>
                        <div class="ibox-title">
                            <h5><i class="fa fa-fw fa-bell-on"></i> {{'general.trigger' | translate }}</h5>
                            <span class="float-right">
                                <h5><span style="margin-top: -10px; display: inline" class="btn btn-info"> 1</span></h5>
                            </span>
                        </div>
                        <fh-trigger-details
                            [trigger]="trigger"
                            [formMode]="formMode"
                            (onAccountChanged)="accountChanged($event)"
                        ></fh-trigger-details>
                        <fh-save-footer
                            [valid]="!userForm.invalid && trigger.assetGroups.length > 0 && (trigger.rules.length > 0 || trigger.triggerInputSource == 2 || trigger.triggerInputSource == 6)"
                            (onInsert)="onInsert()"
                            [formMode]="formMode"
                        >
                        </fh-save-footer>
                    </div>

                    <div class="ibox" style="position: relative">
                        <div class="ibox-title">
                            <h5><i class="fas fa-fw fa-symbols"></i> {{'general.triggerSource' | translate }}</h5>
                            <span class="float-right">
                                <h5><span style="margin-top: -10px; display: inline" class="btn btn-info"> 2</span></h5>
                            </span>
                        </div>
                        <div>
                            <fh-trigger-source
                                [trigger]="trigger"
                                [formMode]="formMode"
                                (onSourceChanged)="sourceChanged($event)"
                                #triggerSource
                            >
                            </fh-trigger-source>
                        </div>
                    </div>

                    <div class="ibox" style="position: relative" *ngIf="trigger.triggerInputSource != 2">
                        <div class="ibox-title">
                            <h5>
                                <i class="fas fa-fw fa-ruler"></i> {{'general.rules' | translate }}
                                <span
                                    class="error"
                                    *ngIf="trigger.rules?.length == 0 && trigger.triggerInputSource !== 2 && trigger.triggerInputSource !== 6"
                                    >*</span
                                >
                            </h5>
                            <span class="float-right">
                                <h5><span style="margin-top: -10px; display: inline" class="btn btn-info"> 3</span></h5>
                            </span>
                        </div>

                        <fh-trigger-rules [trigger]="trigger" [formMode]="formMode" #triggerRulesView>
                        </fh-trigger-rules>

                        <fh-trigger-rule-details
                            [trigger]="trigger"
                            [style.display]="formMode == 1 || !adding ? 'none' : 'block'"
                            #ruleConfiguration
                            (ruleAdded)="onRuleAdded($event)"
                            [formMode]="formMode"
                        >
                        </fh-trigger-rule-details>

                        <div class="ibox-footer" *ngIf="!adding">
                            <button type="button" name="addBtn" (click)="add()" class="btn btn-primary">
                                <i class="fa fa-fw fa-plus"></i> {{'general.addRule'|translate}}
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <fh-trigger-output-details [trigger]="trigger" [formMode]="formMode"> </fh-trigger-output-details>
                </div>
            </div>
        </form>
    </div>
</fh-permission-handler>
