export class Config {
  public version: string;
  public ConsumerToken: string; // = "c4p8GFRvoWjo+x1I4QZNZP2hpsoRrIvkj44xP5J0zj6eG7fYI1RgNMyXXxNmmWdkjyRjjs4VlWJFMA8OR6huFw==";
  public AuthenticationUrl: string; // = "https://services.staging.gpscockpit.net/authentication/api/";
  public Environment: string; // = "staging";
  public Debug: boolean; // = "false";
  public Local: boolean; // = "false";
  public SSOAuthenticationUrl: string; // = 'http://sso.staging.gpscockpit.net/'
  public SSORedirectUrl: string; // = https://fleethealth.staging.gpscockpit.net/#/Auth-callback?'
  public SSOPostLogoutRedirectUrl: string; // = 'https://fleethealth.staging.gpscockpit.net/#/Endsession-callback'
}
