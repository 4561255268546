import { InventorySensorType } from "./sensorType.model";

export class DeviceSensor {
  public name: any;
  public type: any;
  public metrics: any;
  public parameter: any;
  public deviceSensorTypeId: number;
  public sensorCount: any;
  public isChanged = false;
  public isCustomDeviceSensorTypeId = false;
  public deviceSensorType: any;
}

export class AssetSensor {
  public id: any;
  public assetId: any;
  public sensorTypeId: any;
  public sensorTypeName: any;
  public sensorPosition: any;
  public calibrationPoints: any = [];
  public sensorType: InventorySensorType;
}

export class DeviceSensorType {
  public id: any;
  public name: any;
  public category: any;
}

export class SensorUser {
  public customerId: string;
  public customerName: string;
  public manufacturer: string;
  public deviceTypeId: string;
  public deviceType: string;
  public sensorCount: number;
}

export class SensorDevice {
  public sensorName: string;
  public parameter: string;
  public id: string;
  public deviceName: number;
  public unitId: number;
}
