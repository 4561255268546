<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/"> <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Warehouses/Overview">{{'general.warehouses' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/WarehouseDetails/Index/{{warehouse?.id}}">{{warehouse?.name}}</a>
                </li>
                <li class="breadcrumb-item active">{{'menu.charts' | translate }}</li>
            </ol>
        </nav>
    </div>
    <fh-warehouse-details-navigation [warehouseId]="warehouseId"></fh-warehouse-details-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">
        <div class="row" data-equalizer="">
            <div class="col-lg-12">
                <fh-error [error]="error" [success]="success"></fh-error>

                <div class="ibox">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-filter"></i> {{'general.filter' | translate }}</h5>
                    </div>
                    <div class="ibox-content slick_toppadding flex-container" style="position: relative">
                        <ngx-loading [show]="loading || loadingSensors"></ngx-loading>
                        <div class="col-lg-6">
                            <div class="form-group input-group" style="margin-bottom: 5px">
                                <span class="input-group-prepend">
                                    <i class="fa fa-fw fa-calendar"></i>
                                </span>
                                <input
                                    placeholder="Daterangepicker"
                                    style="width: 100%"
                                    placeholder="Datepicker"
                                    type="text"
                                    [bsConfig]="dpConfig"
                                    class="form-control"
                                    bsDaterangepicker
                                    (onHidden)="dateChanged('hidden')"
                                    [(ngModel)]="daterangepickerModel"
                                />
                                <span class="input-group-calendar-datatables">
                                    <i class="fa fa-calendar-alt"></i>
                                </span>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group input-group" style="margin-bottom: 5px" title="Filter on 0 values">
                                <span id="checkBox11" class="input-group-prepend hand" title="Filter on 0 values">
                                    <i
                                        id="checkBox_11"
                                        class="auto_checkbox fa fa-fw"
                                        (click)="filterZeroValues = !filterZeroValues"
                                        [ngClass]="filterZeroValues ? 'fa-check' : 'fa-times'"
                                        title="Filter on 0 values"
                                        [style.color]="filterZeroValues ? 'rgba(0, 142, 67, 0.8)' : 'rgba(151, 28, 36, 0.8)'"
                                    ></i>
                                </span>
                                <input
                                    name="checkboxName_11"
                                    id="checkboxName_11"
                                    style="width: 100%; cursor: pointer !important"
                                    placeholder="{{'general.filterZeroValues' | translate }}"
                                    disabled="disabled"
                                    class="form-control auto_search"
                                />
                            </div>
                        </div>
                        <div style="clear: both"></div>
                    </div>
                </div>

                <div *ngIf="sensors.length == 0" class="ibox">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-chart-area"></i> {{ 'menu.charts' | translate}}</h5>
                    </div>
                    <div class="ibox-content" style="position: relative">
                        <ngx-loading [show]="loading || loadingSensors"></ngx-loading>
                        {{ 'general.noSensorData' | translate}}
                    </div>
                </div>

                <div *ngFor="let sensor of sensors; let i = index" class="ibox">
                    <div class="ibox-title" style="overflow: visible; height: 46px">
                        <h5><i class="fas fa-fw fa-chart-area"></i> {{ sensor.zoneName }} - {{ sensor.sensorName }}</h5>
                        <span class="float-right">
                            <ibox-chart-export [chartObject]="charts[sensor.sensorId]"> </ibox-chart-export>
                        </span>
                    </div>
                    <div class="ibox-content slick" style="position: relative">
                        <highcharts-chart
                            [runOutsideAngular]="true"
                            style="display: block; width: 100%; height: 300px"
                            [Highcharts]="Highcharts"
                            [options]="charts[sensor.sensorId]"
                        >
                        </highcharts-chart>
                    </div>
                </div>
            </div>
        </div>
    </div>
</fh-permission-handler>
