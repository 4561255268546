<fh-wizard [(step)]="step" (finish)="onCheckOutFinish()">
    <fh-wizard-step
        title="SelectReseller"
        icon="fa-file-pdf"
        [padding]="false"
        [validate]="groupForm"
        (open)="initForm()"
    >
        <div
            *ngIf="!loading && allowedEntities?.length !== gridSelection.length"
            class="col-lg-12"
            style="margin-top: 5px"
        >
            <div [ngClass]="allowedEntities.length === 0 ? 'alert-danger' : 'alert-warning'" class="alert shadow">
                <span>
                    <p style="font-size: 15px"><strong>{{'general.warning' | translate }}</strong></p>
                </span>
                <span *ngIf="allowedEntities?.length; else notAllowed">
                    <p
                        [innerHTML]="'wizard.invalidAccountGeneralSummary' | translate:{ allowed: allowedEntities.length, all: gridSelection.length }"
                        style="margin: 0"
                    ></p>
                </span>
                <ng-template #notAllowed>
                    <p style="margin: 0">{{'wizard.invalidGeneral' | translate }}</p>
                    <small
                        ><li style="margin-left: 10px; margin-top: 7.5px">
                            {{ 'wizard.differentAccounts' | translate }}
                        </li></small
                    >
                </ng-template>
            </div>
        </div>
        <div class="wizard-content border-bottom" style="padding: 12px">{{ 'wizard.selectYourGroups' | translate}}</div>
        <div class="wizard-content" style="position: relative">
            <form [formGroup]="groupForm" style="width: 100%; border-top: 0px">
                <table style="width: 100%" class="table table-fixed nowrap dtr-inline no-footer">
                    <tbody [ngSwitch]="batchEntityType">
                        <tr>
                            <td style="width: 25%; border-bottom: 0px !important">
                                {{'general.account' | translate }}
                                <span class="error" *ngIf="groupForm.get('selectedAccount').errors">*</span>
                            </td>
                            <td style="width: 75%; border-bottom: 0px !important">
                                <fh-account-input
                                    #accountInputVar
                                    formControlName="selectedAccount"
                                    ngDefaultControl
                                    required
                                    [needReseller]="false"
                                    (onChanged)="accountChanged($event, groupInputVar)"
                                    placeholder="{{ 'general.chooseAccount' | translate }}"
                                >
                                </fh-account-input>
                            </td>
                        </tr>

                        <!-- Device -->
                        <tr *ngSwitchCase="1">
                            <td style="width: 25%; border-bottom: 0px !important">
                                {{'general.assetGroups' | translate }}
                                <span class="error" *ngIf="groupForm.get('selectedGroups').errors">*</span>
                            </td>
                            <td style="width: 75%; border-bottom: 0px !important">
                                <fh-asset-group-input
                                    #groupInputVar
                                    formControlName="selectedGroups"
                                    ngDefaultControl
                                    required
                                    [tableInline]="false"
                                    [selectedAccountId]="selectedAccountId"
                                    (onChanged)="filterDevices(groupInputVar)"
                                    [hideSystemGroups]="true"
                                    [hideEmptyGroups]="false"
                                    [activeOnly]="true"
                                    [autoSelect]="false"
                                    placeholder="{{ 'general.chooseAssetGroups' | translate }}"
                                    [canAddNew]="true"
                                >
                                </fh-asset-group-input>
                            </td>
                        </tr>

                        <!-- Driver -->
                        <tr *ngSwitchCase="4">
                            <td style="width: 25%; border-bottom: 0px !important">
                                {{'general.driverGroups' | translate }}
                                <span class="error" *ngIf="groupForm.get('selectedGroups').errors">*</span>
                            </td>
                            <td style="width: 75%; border-bottom: 0px !important">
                                <fh-driver-group-input
                                    #groupInputVar
                                    formControlName="selectedGroups"
                                    ngDefaultControl
                                    required
                                    [tableInline]="false"
                                    [selectedAccountId]="selectedAccountId"
                                    (onChanged)="filterDrivers(groupInputVar)"
                                    [hideSystemGroups]="true"
                                    [autoSelect]="false"
                                    placeholder="{{ 'general.chooseDriverGroups' | translate }}"
                                    [canAddNew]="true"
                                >
                                </fh-driver-group-input>
                            </td>
                        </tr>

                        <!-- Driver -->
                        <tr *ngSwitchCase="5">
                            <td style="width: 25%; border-bottom: 0px !important">
                                {{'general.geofenceGroups' | translate }}
                                <span class="error" *ngIf="groupForm.get('selectedGroups').errors">*</span>
                            </td>
                            <td style="width: 75%; border-bottom: 0px !important">
                                <fh-geofence-group-input
                                    #groupInputVar
                                    formControlName="selectedGroups"
                                    ngDefaultControl
                                    required
                                    [canAddNew]="true"
                                    [selectedAccountId]="selectedAccountId"
                                    (onChanged)="filterGeofences(groupInputVar)"
                                    [hideSystemGroups]="true"
                                    [autoSelect]="false"
                                    placeholder="{{ 'general.chooseGeofenceGroups' | translate }}"
                                >
                                </fh-geofence-group-input>
                            </td>
                        </tr>

                        <tr *ngSwitchDefault>
                            <td colspan="2" style="width: 25%; border-bottom: 0px !important">
                                {{ 'general.unknown' | translate }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </div>
    </fh-wizard-step>
    <fh-wizard-step title="Remarks" icon="fa-file-pdf">
        <div class="wizard-content" style="padding-bottom: 10px">{{ 'wizard.optionalComment' | translate }}</div>
        <div class="wizard-content">
            <textarea
                class="form-control noresize stickypad"
                style="height: 100px; width: 100%; resize: none"
                placeholder="{{ 'placeholder.comments' | translate}}"
                [(ngModel)]="logItem"
            ></textarea>
        </div>
    </fh-wizard-step>
    <fh-wizard-step title="Summary" icon="fa-summary-pdf">
        <div
            class="wizard-content"
            style="padding-bottom: 10px"
            [innerHTML]="'wizard.summary' | translate:{ count: allowedEntities.length }"
        ></div>
        <div class="ibox-content slick popupOverflow" style="position: relative; min-height: 72px; overflow: auto">
            <table class="table nowrap dtr-inline no-footer table-summary">
                <thead [ngSwitch]="batchEntityType">
                    <!-- Device -->
                    <tr *ngSwitchCase="1">
                        <th class="small_padding">{{ 'general.name' | translate}}</th>
                        <th class="small_padding">{{ 'general.assetGroup' | translate}}</th>
                    </tr>
                    <!-- Driver -->
                    <tr *ngSwitchCase="4">
                        <th class="small_padding">{{ 'general.name' | translate}}</th>
                        <th class="small_padding">{{ 'general.driverGroup' | translate}}</th>
                    </tr>
                    <!-- Geofence -->
                    <tr *ngSwitchCase="5">
                        <th class="small_padding">{{ 'general.name' | translate}}</th>
                        <th class="small_padding">{{ 'general.geofenceGroup' | translate}}</th>
                    </tr>
                    <!-- Default -->
                    <tr *ngSwitchDefault>
                        <th colspan="2" class="small_padding">{{ 'general.unknown' | translate }}</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let entity of allowedEntities; let i = index;">
                        <tr>
                            <td [ngSwitch]="batchEntityType" class="small_padding">
                                <!-- Device -->
                                <input
                                    *ngSwitchCase="1"
                                    [value]="entity.asset?.name ?? entity.name"
                                    readonly
                                    class="form-control table-inline"
                                />
                                <!-- Default -->
                                <input
                                    *ngSwitchDefault
                                    [value]="entity.name"
                                    readonly
                                    class="form-control table-inline"
                                />
                            </td>
                            <td class="small_padding">
                                <input [value]="addedGroups" readonly class="form-control table-inline" />
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </fh-wizard-step>
</fh-wizard>
