<fh-wizard [(step)]="step" (finish)="onCheckOutFinish()" [canContinue]="deviceForm">
    <fh-wizard-step title="general.archiveAssets" icon="fa-user" (open)="initForm()">
        <div class="wizard-content">{{ 'wizard.optionalUnArchive' | translate }}</div>
    </fh-wizard-step>
    <fh-wizard-step
        title="{{ 'general.updateFields' | translate}}"
        icon="fa-file-pdf"
        [padding]="false"
        (open)="loadDevices()"
    >
        <div class="ibox-content slick" style="position: relative; min-height: 72px; overflow: auto">
            <ngx-loading [show]="loadingDeviceDetails"></ngx-loading>
            <ng-container *ngIf="deviceForm">
                <div class="popupOverflow">
                    <table class="table nowrap dtr-inline no-footer">
                        <thead>
                            <tr>
                                <th class="small_padding">{{ 'general.imei' | translate}}</th>
                                <th class="small_padding">{{ 'general.assetName' | translate}}</th>
                                <th class="small_padding">{{ 'general.assetCode' | translate}}</th>
                                <th class="small_padding">{{ 'general.assignmentEnd' | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngIf="loadingDeviceDetails == false && formControls.length === 0">
                                <tr>
                                    <td colspan="5">{{ 'general.noValidDevicesFound' | translate}}</td>
                                </tr>
                            </ng-container>
                            <ng-container *ngFor="let formGroup of formControls; let i = index;">
                                <tr [formGroup]="formGroup">
                                    <td class="small_padding">
                                        <input readonly class="form-control table-inline" formControlName="imei" />
                                    </td>
                                    <td class="small_padding">
                                        <input readonly class="form-control table-inline" formControlName="name" />
                                    </td>
                                    <td class="small_padding">
                                        <input readonly class="form-control table-inline" formControlName="code" />
                                    </td>
                                    <td class="small_padding">
                                        <input
                                            [ngStyle]="{'border': formGroup.get('dateEnd').errors ? '1px solid red' : '' }"
                                            class="form-control table-inline"
                                            formControlName="dateEnd"
                                            autocomplete="off"
                                            [bsConfig]="dpConfig"
                                            bsDatepicker
                                        />
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </ng-container>
        </div>
    </fh-wizard-step>
    <fh-wizard-step title="Remarks" icon="fa-file-pdf">
        <div class="wizard-content" style="padding-bottom: 10px">{{ 'wizard.optionalComment' | translate }}</div>
        <div class="wizard-content">
            <textarea
                class="form-control noresize stickypad"
                style="height: 100px; width: 100%; resize: none"
                placeholder="{{ 'placeholder.comments' | translate}}"
                [(ngModel)]="logItem"
            ></textarea>
        </div>
    </fh-wizard-step>
    <fh-wizard-step title="Summary" icon="fa-summary-pdf">
        <div
            class="wizard-content"
            style="padding-bottom: 10px"
            [innerHTML]="'wizard.summary' | translate:{ count: deviceForm?.controls?.length }"
        ></div>
        <div class="ibox-content slick popupOverflow" style="position: relative; min-height: 72px; overflow: auto">
            <table class="table nowrap dtr-inline no-footer table-summary">
                <thead>
                    <tr>
                        <th class="small_padding">{{ 'general.imei' | translate}}</th>
                        <th class="small_padding">{{ 'general.assetName' | translate}}</th>
                        <th class="small_padding">{{ 'general.assetCode' | translate}}</th>
                        <th class="small_padding">{{ 'general.assignmentEnd' | translate}}</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let formGroup of deviceForm?.controls; let i = index;">
                        <tr>
                            <td class="small_padding">
                                <input
                                    [value]="formGroup.get('imei').value"
                                    readonly
                                    class="form-control table-inline"
                                />
                            </td>
                            <td class="small_padding">
                                <input
                                    [value]="formGroup.get('name').value"
                                    readonly
                                    class="form-control table-inline"
                                />
                            </td>
                            <td class="small_padding">
                                <input
                                    [value]="formGroup.get('code').value"
                                    readonly
                                    class="form-control table-inline"
                                />
                            </td>
                            <td class="small_padding">
                                <input
                                    [value]="formGroup.get('dateEnd').value | amDateFormat: 'll'"
                                    readonly
                                    class="form-control table-inline"
                                />
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </fh-wizard-step>
</fh-wizard>
