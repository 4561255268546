<div class="col-lg-12">
    <div class="horizontalMenuWrapper">
        <ul>
            <li
                *ngIf="permissions['FleetManagement_DriverScore']"
                [ngClass]="{active: activeRoute('/EcoDriving/Drivers')}"
            >
                <a [routerLink]="['/EcoDriving/Drivers/']">
                    <i class="fas fa-fw fa-id-card-alt"></i>
                    <div>{{ 'general.drivers' | translate }}</div>
                </a>
            </li>
            <li
                *ngIf="permissions['FleetManagement_DriverScore']"
                [ngClass]="{active: activeRoute('/EcoDriving/Assets')}"
            >
                <a [routerLink]="['/EcoDriving/Assets/']">
                    <i class="fas fa-fw fa-car-bus"></i>
                    <div>{{ 'general.assets' | translate }}</div>
                </a>
            </li>
            <li
                *ngIf="permissions['FleetManagement_DriverScore']"
                [ngClass]="{active: activeRoute('/EcoDriving/Settings')}"
            >
                <a [routerLink]="['/EcoDriving/Settings/']">
                    <i class="fa fa-fw fa-cog"></i>
                    <div>{{ 'general.settings' | translate }}</div>
                </a>
            </li>
        </ul>
    </div>
    <div class="horizontalMenuWrapper" style="float: right">
        <ul>
            <ng-content></ng-content>
        </ul>
    </div>
</div>
<div style="clear: both"></div>
