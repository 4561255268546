import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { WizardComponent } from "../../modules/wizard/wizard";
import { WizardStepComponent } from "../../modules/wizard/wizard-step";

@NgModule({
  imports: [CommonModule, TranslateModule],
  declarations: [WizardComponent, WizardStepComponent],
  exports: [WizardComponent, WizardStepComponent, TranslateModule],
})
export class WizardModule {}
