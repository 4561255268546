import { HttpClient } from "@angular/common/http";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Device } from "app/models/device.model";
import { DeviceSensorType } from "app/models/sensor.model";
import { AuthenticationService } from "app/services/authentication/authentication.service";
import { FhChartService } from "../../services/charts/charts.service";
import { DeviceService } from "../../services/device/device.service";

import { getDefaultDpConfig } from "app/common/gridhelper";
import { LeafletMapComponent } from "../shared/usercontrols/leafletMap.component";
import { TripService } from "app/services/trip/trip.service";
import { AccountService } from "app/services/account/account.service";

import { slideInOut } from "app/common/animations";

// Moment
import Moment from "moment-timezone";
import { timer } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { LocationService } from "app/services/locations/locations.service";
import { FormMode, virtualSensorOutputType } from "app/common/enums";
import { SensorTemplateService } from "app/services/sensorTemplate/sensorTemplate.service";

window["moment"] = Moment;

@Component({
  selector: "fh-device-sensor-settings",
  templateUrl: "sensorSettings.template.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FhChartService],
  animations: [slideInOut],
})
export class DeviceSensorSettingsViewComponent implements OnInit, OnDestroy {
  @ViewChild(LeafletMapComponent, { static: false }) leafletMapComponent: LeafletMapComponent;

  chartRpm: any;
  chartSpeed: any;
  sensors;
  sub;
  device: Device;
  deviceId;
  loading = false;
  loadingSensors = false;
  sensorTypes: DeviceSensorType[];
  timezoneIana: string;
  daterangepickerModel: Date[];
  dpConfig: {};
  error;
  success;
  chart: any;
  locations = [];
  geofences = [];
  loadingGeofences = false;
  mapHeight = 250;

  saving;

  filterZeroValues = true;

  permissionName = "FleetManagement_Sensors";

  locationSubscription: any;
  isSpinning: boolean;
  loadingLocation: boolean;
  previousLookupDisplay: any;
  previousLookupTimestamp: any;
  deviceState: any;
  activeLocation: any;
  unmappedPorts: any;
  lastCommunication: any;
  permissions: {};
  formMode = FormMode.read;

  sensorTemplates = [];
  loadingSensorTemplates = false;

  showProp = true;

  // Virtual sensors
  virtualSensors = [];
  loadingVirtualSensors = false;
  newSensorName;
  newSensorFormula;
  newSensorOutputType = 0;
  formModeVirtualSensors = FormMode.read;
  errorVirtualSensors;
  successVirtualSensors;
  virtualSensorOutputTypes = [];

  constructor(
    private cd: ChangeDetectorRef,
    private locationService: LocationService,
    private sensorTemplateService: SensorTemplateService,
    private chartService: FhChartService,
    private tripService: TripService,
    private accountService: AccountService,
    private translateService: TranslateService,
    private authenticationService: AuthenticationService,
    private http: HttpClient,
    private deviceService: DeviceService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.device = null;
    this.sensors = [];

    this.permissions = this.authenticationService.permissions;

    this.timezoneIana = authenticationService.getTimeZoneIana();

    this.daterangepickerModel = [
      Moment().tz(this.timezoneIana).subtract(2, "days").startOf("day").toDate(),
      Moment().tz(this.timezoneIana).endOf("day").toDate(),
    ];

    this.dpConfig = getDefaultDpConfig(authenticationService);
  }

  ngOnInit() {
    this.virtualSensorOutputTypes = Object.keys(virtualSensorOutputType)
      .filter((k) => typeof virtualSensorOutputType[k] === "string")
      .map((n) => ({ value: +n, name: "enums.sensorOutputType." + n }));

    this.device = new Device();
    this.loading = true;
    this.device.id = "";

    this.sub = this.route.params.subscribe((params) => {
      const id = params["id"];

      this.deviceId = id;
      this.deviceService.getDeviceById(id).subscribe((device) => {
        this.device = device;

        console.log(device);

        this.loadSensorTemplates();

        this.getVirtualSensors(id);

        this.loading = false;
        this.cd.markForCheck();

        this.getLocations(id);

        if (this.device == null) {
          this.router.navigate(["/Devices/Overview"]);
        }
      });
    });
  }

  loadSensorTemplates() {
    this.sensorTemplates = [];

    if (!this.device.accountId) {
      return;
    }

    this.loadingSensorTemplates = true;
    this.cd.markForCheck();

    this.sensorTemplateService.getSensorTemplatesByAccount(this.device.accountId).subscribe((result) => {
      this.sensorTemplates = result;
      this.loadingSensorTemplates = false;
      this.cd.markForCheck();
    });
  }

  showProperties() {
    this.showProp = !this.showProp;
  }

  onSave() {
    this.saving = true;

    this.deviceService.saveDeviceProperties(this.device).subscribe(
      (result) => {
        this.saving = false;

        this.error = null;
        this.success = {
          statusText: "Success",
          success: "Device is successfull updated",
        };

        this.deviceService.resetDeviceCache().subscribe((res) => {
          console.log("Devices are cleared");
        });

        this.setFormMode(FormMode.read);
      },
      (error) => {
        this.saving = false;
        this.success = null;
        this.error = error;
        this.cd.markForCheck();
      }
    );
  }

  setFormMode(mode) {
    this.formMode = mode;
    this.cd.markForCheck();
  }

  getLocations(deviceId) {
    if (this.locationSubscription !== undefined) {
      this.locationSubscription.unsubscribe();
    }

    this.loadingLocation = true;
    this.cd.markForCheck();

    this.locationSubscription = timer(0, 30000)
      .pipe(
        mergeMap(() => {
          // Start the spinner
          this.isSpinning = true;

          this.cd.markForCheck();

          return this.locationService.getDeviceStates([+deviceId], null, this.previousLookupTimestamp, 0, false);
        })
      )
      .subscribe(
        (result) => {
          // Stop the spinner
          setTimeout(() => {
            this.isSpinning = false;
            this.cd.markForCheck();
          }, 500);

          this.loadingLocation = false;
          this.cd.markForCheck();

          this.previousLookupTimestamp = new Date(result.timestamp);
          this.previousLookupDisplay = Moment.utc(result.timestamp)["tz"](this.timezoneIana);

          if (result?.deviceStates.length > 0) {
            this.deviceState = result.deviceStates;

            this.deviceState[0].deviceId = this.device?.id;
            this.deviceState[0].assetName = this.device?.asset?.name;

            this.deviceState[0].hasGpsFix = (this.deviceState[0].locationType & 2) > 0;
            this.deviceState[0].hasCellFix = (this.deviceState[0].locationType & 1) > 0;

            this.activeLocation = this.deviceState[0];

            this.lastCommunication = this.activeLocation.timestamp;

            this.cd.markForCheck();
          } else {
            console.log("No data updated");
          }
        },
        (error) => {
          if (this.locationSubscription !== undefined) {
            this.locationSubscription.unsubscribe();
          }

          this.loadingLocation = false;
          this.error = error;
          this.cd.markForCheck();
        }
      );
  }

  ngOnDestroy(): void {
    if (this.locationSubscription !== undefined) {
      this.locationSubscription.unsubscribe();
    }
  }

  checkSensorOnTempate(value, templateId) {
    const template = this.sensorTemplates.find((x) => x.id === templateId);

    if (value === undefined && templateId !== undefined) {
      return false;
    }

    if (template === undefined) {
      return true;
    }

    if (template.maxValue !== undefined && value > template.maxValue) {
      return false;
    }
    if (template.minValue !== undefined && value < template.minValue) {
      return false;
    }
    return true;
  }

  // Virtual sensors
  getVirtualSensors(id) {
    this.loadingVirtualSensors = true;

    this.deviceService.getVirtualSensors(id).subscribe(
      (virtualSensors) => {
        this.virtualSensors = [];

        virtualSensors.forEach((sensor) => {
          this.virtualSensors.push({
            virtualSensorId: sensor.virtualSensorId,
            name: sensor.name,
            formula: sensor.formula,
            sensorOutputType: sensor.sensorOutputType,
            timestamp: Date(),
          });
        });

        this.loadingVirtualSensors = false;
        this.cd.markForCheck();
      },
      (error) => {
        this.loadingVirtualSensors = false;
        this.errorVirtualSensors = error;
      }
    );
  }

  removeSensor(sensor) {
    const index: number = this.virtualSensors.indexOf(sensor);
    if (index !== -1) {
      this.virtualSensors.splice(index, 1);
    }
  }

  add() {
    this.virtualSensors.push({
      virtualSensorId: null,
      name: this.newSensorName,
      formula: this.newSensorFormula,
      sensorOutputType: this.newSensorOutputType,
      timestamp: Date(),
    });

    this.newSensorName = "";
    this.newSensorFormula = "";
    this.newSensorOutputType = 0;
  }

  onSaveVirtualSensors() {
    this.loadingVirtualSensors = true;

    this.deviceService.saveVirtualSensors(this.device.id, this.virtualSensors).subscribe(
      (result) => {
        this.errorVirtualSensors = null;
        this.successVirtualSensors = null;

        if (result.isSuccess) {
          this.successVirtualSensors = {
            statusText: "Success",
            success: result.message,
          };
        } else {
          this.errorVirtualSensors = {
            statusText: "Error",
            error: result.message,
          };
        }

        this.loadingVirtualSensors = false;

        this.setFormModeVirtualSensors(FormMode.read);
      },
      (error) => {
        this.loadingVirtualSensors = false;
        this.successVirtualSensors = null;
        this.errorVirtualSensors = error;
        this.errorVirtualSensors = {
          statusText: "Success",
        };
      }
    );
  }

  setFormModeVirtualSensors(mode) {
    this.formModeVirtualSensors = mode;

    if (this.formModeVirtualSensors === FormMode.read) {
      this.getVirtualSensors(this.device.id);
    }
  }
}
