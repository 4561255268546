import { Upload } from "./upload.model";

export class Fuel {
  public id: string;
  public assetName: any;
  public assetCode: any;
  public plateNumber: any;
  public assetSearchName: string;
  public unitId: string;
  public certaintyInPercentage = 0;
  public companyName: any;
  public deviceId: any;
  public tags: any;
  public assetId: any;
  public endFuelEventFuelLevelInLiters = 0;
  public eventTimeStamp: any;
  public fuelingActivityInLiters = 0;
  public latitude: any;
  public longitude: any;
  public refuelVsTheft: any;
  public resellerDescription: any;
  public resellerId: any;
  public source: any;
  public auxiliaryAttachments: any;
  public timestamp: any;
  public accountId: any;
  public isValid: boolean;
  public fuelTankCapacityInLiters = 0;
  public fuelTank1CapacityInLiters = 0;
  public fuelTank2CapacityInLiters = 0;
  public fillupThresholdPercentage: any;
  public theftThresholdPercentage: any;
  public iconId: any;
  public geofenceId: any;
  public geofenceLabel: any;
  public geoJson: any;
  public geoColor: any;
}

export class FuelUpload extends Upload {
  public assetName: any;
  public assetId: any;
  public deviceId: any;
  public eventTimeStamp: Date;
  public fuelingActivityInLiters: number;
  public odo: number;
}
