import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChange,
  NgZone,
  ChangeDetectorRef,
  OnDestroy,
} from "@angular/core";
import { ControlContainer, NgForm } from "@angular/forms";
import {
  createMapOptions,
  colorMapper,
  drawGeofences,
  setBounds,
  getMapProvidersExtended,
} from "app/common/leafletGlobals";
import { Map, marker, type FeatureGroup } from "leaflet";

import "../../../../../vendor/leaflet-statusmarker/leaflet-statusmarker.js";
import "../../../../../vendor/leaflet-geoman/leaflet-geoman.min.js";

import "../../../../../vendor/leaflet-spiderfy/leaflet-spiderfy.js";

import "../../../../../vendor/leaflet-bookmarks/L.Control.Bookmarks.js";

import "../../../../../vendor/leaflet-search/leaflet-search.src.js";

import "../../../../../vendor/leaflet-rotate/leaflet-rotate.js";
import "../../../../../vendor/leaflet-restoreview/leaflet-restoreview.js";
import "../../../../../vendor/leaflet-corridor/leaflet-corridor.js";

import "../../../../../vendor/leaflet-movingmarker/leaflet-movingmarker.js";

import "../../../../../vendor/leaflet-heat/leaflet-heat.js";

import { BOUND_CHECK, MAX_LATITUDE, MAX_LONGITUDE, getIconPath } from "app/common/globals";
import { MapService } from "../../../services/common/map.service";
import { TranslateService } from "@ngx-translate/core";
import { StorageHelper } from "app/common/storagehelper";
import Moment from "moment";
import { AssetDisplayName, StorageType } from "app/common/enums";
import { forkJoin } from "rxjs";
import { Router } from "@angular/router";
import { UserService } from "../../../services/users/user.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { AppUser } from "app/models/user.model";
import * as turf from "@turf/turf";
import { DistanceUnitService } from "app/common/distanceunit.service";

declare var PruneCluster;
declare var PruneClusterForLeaflet;
declare var L;
declare const OverlappingMarkerSpiderfier;
declare const window;

@Component({
  selector: "fh-leaflet-map",
  templateUrl: "leafletMap.template.html",
  providers: [MapService],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class LeafletMapComponent implements OnInit, OnChanges, OnDestroy, OnChanges {
  @Input() height = 300;
  @Input() allowEdit = false;
  @Input() useClustering = false;
  @Input() showFitMap = true;
  @Input() showExtendMap = true;
  @Input() showGeofenceSwitch = true;
  @Input() showSearch = false;
  @Input() allowBookmarks = true;
  @Input() fly = true;

  @Input() isSpinning = false;
  @Input() geofenceFromTrip = false;
  @Input() showSaveAsGeofence = true;
  @Output() mapReady = new EventEmitter();
  @Output() mapResized = new EventEmitter();
  @Output() onSave = new EventEmitter();
  @Input() borderRadius = 5;

  @Input() locations = [];
  @Input() geofences = [];
  @Input() geofenceEnabled = true;
  @Input() heatmapEnabled = false;
  @Input() device;

  loadingLocation = false;

  maps = [];
  options: any;
  map: any;
  markers: any = [];
  circleMarkers: any = [];
  circleMarker;
  geofenceLayer: any;
  markerLayer: any;
  radiusLayer: any;
  pruneCluster: any;
  locationLayer: any;
  eventsLayer: any;
  heatmapLayer: any;
  tripLayer: FeatureGroup;

  user: AppUser = new AppUser();

  public markerList: any = [];

  oms: any;
  fillColor: any;

  translatedKm: any = "km";
  translatedKmh: any = "km/h";

  get editableGeofence(): any {
    return this.geofences && this.geofences.length && this.geofences[0];
  }

  @Input()
  set editableGeofence(value: any) {
    if (value === undefined) {
      return;
    }
    this.prepareGeofences((this.geofences = [value]));
  }

  storageType = StorageType.LocalStorage;
  skipClustering: boolean = false;
  slideTo: boolean = false;
  hideLabels: boolean = false;
  skipIncludingGroupColors: boolean = false;
  showScale: boolean = false;

  isLoaded: boolean = false;

  constructor(
    private theMapService: MapService,
    private distance: DistanceUnitService,
    private translateService: TranslateService,
    private zone: NgZone,
    private router: Router,
    private cd: ChangeDetectorRef,
    private storageHelper: StorageHelper,
    private userService: UserService,
    private authenticationService: AuthenticationService
  ) {
    this.geofenceLayer = L.featureGroup();
    this.markerLayer = L.featureGroup();
    this.radiusLayer = L.featureGroup();
    this.locationLayer = L.featureGroup();
    this.tripLayer = L.featureGroup();
    this.eventsLayer = L.featureGroup();
    this.heatmapLayer = L.featureGroup();
    this.pruneCluster = L.featureGroup();

    this.translateService.get("general.date").subscribe((data) => {
      this.translatedKm = this.translateService.instant(this.distance.getDistanceUnit());
      this.translatedKmh = this.translateService.instant(this.distance.getDistanceUnitPerHour());
    });

    // Get settings
    this.storageHelper.loadStoreState(this.storageType, "settings_", "skipClustering").subscribe((result) => {
      this.skipClustering = JSON.parse(result) === true;
    });

    this.storageHelper.loadStoreState(this.storageType, "settings_", "slideTo").subscribe((result) => {
      this.slideTo = JSON.parse(result) === true;
    });

    this.storageHelper.loadStoreState(this.storageType, "settings_", "showScale").subscribe((result) => {
      this.showScale = JSON.parse(result) === true;
    });

    this.storageHelper.loadStoreState(this.storageType, "settings_", "hideLabels").subscribe((result) => {
      this.hideLabels = JSON.parse(result) === true;
    });

    this.storageHelper.loadStoreState(this.storageType, "settings_", "skipIncludingGroupColors").subscribe((result) => {
      this.skipIncludingGroupColors = JSON.parse(result) === true;
    });
  }

  // Clear all data
  ngOnDestroy(): void {
    this.map = null;
    this.locations = [];
    this.markers = [];

    this.geofenceLayer = L.featureGroup();
    this.markerLayer = L.featureGroup();
    this.radiusLayer = L.featureGroup();
    this.locationLayer = L.featureGroup();
    this.tripLayer = L.featureGroup();
    this.eventsLayer = L.featureGroup();
    this.heatmapLayer = L.featureGroup();
    this.pruneCluster = L.featureGroup();
  }

  async ngOnInit(): Promise<void> {
    this.loadMaps();

    window.my = window.my || {};
    window.my.namespace = window.my.namespace || {};

    window.my.namespace.saveAsGeofence = this.saveAsGeofence.bind(this);
    window.my.namespace.panTo = this.panToPublic.bind(this);
    window.my.namespace.zoomIn = this.zoomInPublic.bind(this);
    window.my.namespace.zoomOut = this.zoomOutPublic.bind(this);
    window.my.namespace.saveStartAsGeofence = this.saveStartAsGeofence.bind(this);
    window.my.namespace.saveEndAsGeofence = this.saveEndAsGeofence.bind(this);
    window.my.namespace.saveRouteAsGeofence = this.saveRouteAsGeofence.bind(this);

    const userId = this.authenticationService.getUserId();
    if (userId) {
      this.user = await this.userService.getUserById(userId).toPromise();
    }
  }

  changeGeofenceColor(color: any) {
    this.fillColor = color;
    this.geofenceLayer.setStyle({ fillColor: color, color: color });
  }

  unpackGeoJSON(layer) {
    const { features } = <any>layer.toGeoJSON();
    const [startPosition, endPosition, route, ..._] = features;
    return { startPosition, endPosition, route };
  }

  saveStartAsGeofence(e) {
    const { startPosition } = this.unpackGeoJSON(this.tripLayer);

    this.zone.run(() =>
      this.router.navigateByUrl("/Geofences/Add", {
        state: {
          newGeofence: startPosition,
        },
      })
    );
  }

  saveEndAsGeofence(e) {
    const { endPosition } = this.unpackGeoJSON(this.tripLayer);

    this.zone.run(() =>
      this.router.navigateByUrl("/Geofences/Add", {
        state: {
          newGeofence: endPosition,
        },
      })
    );
  }

  saveRouteAsGeofence(e) {
    const { route } = this.unpackGeoJSON(this.tripLayer);

    this.zone.run(() =>
      this.router.navigateByUrl("/Geofences/Add", {
        state: {
          newGeofence: route,
        },
      })
    );
  }

  saveAsGeofence(latitude: number, longitude: number): void {
    const circle: L.Circle = L.circle([latitude, longitude], 50);

    this.zone.run(() =>
      this.router.navigateByUrl("/Geofences/Add", {
        state: {
          newGeofence: circle.toGeoJSON(),
        },
      })
    );
  }

  zoomInPublic(e): void {
    this.zone.run(() => this.map.zoomIn());
  }

  zoomOutPublic(e): void {
    this.zone.run(() => this.map.zoomOut());
  }

  panToPublic(latitude, longitude): void {
    this.zone.run(() => this.map.panTo([latitude, longitude]));
  }

  ngOnChanges(changeRecord: { [key: string]: SimpleChange }) {
    if (changeRecord["locations"] && this.locations?.length > 0) {
      this.setLocations(this.locations);
    }
    if (changeRecord["geofences"] && this.geofences?.length > 0) {
      this.prepareGeofences(this.geofences);
    }
  }

  clearLocations() {
    if (this.useClustering && this.pruneCluster && this.markerList && this.markerList.length > 0) {
      this.pruneCluster.RemoveMarkers?.(this.markerList);
      this.markerList = [];
    }
  }

  setLocations(locations): void {
    if (locations === undefined || locations.length === 0) {
      return;
    }

    let iconPath = getIconPath(this.device?.asset?.icon)[1];

    if (this.skipClustering == true && locations.length > 200) {
      console.log("Set skip clustering to false");
      this.skipClustering = false;
    }

    if (this.useClustering && !this.skipClustering) {
      //
      console.log("Map: Use clustering");

      if (this.pruneCluster && this.markerList && this.markerList.length > 0) {
        this.pruneCluster.RemoveMarkers(this.markerList);
        this.markerList = [];
      }

      locations.forEach((location) => {
        if (location.latitude !== 0) {
          const positionValid =
            BOUND_CHECK(location.latitude, MAX_LATITUDE) && BOUND_CHECK(location.longitude, MAX_LONGITUDE);

          if (!positionValid) {
            console.log("Invalid location", location.latitude, location.longitude);
            return;
          }

          let assetDisplayLabel: string;

          location.assetName = location.assetName ?? location.unitId;
          location.companyName = location.companyName ?? "Not yet activated";

          switch (this.user.assetDisplayName) {
            case AssetDisplayName["Asset Code"]:
              assetDisplayLabel = `${location.assetCode || location.assetName}`;
              break;
            case AssetDisplayName["Plate Number"]:
              assetDisplayLabel = `${location.assetPlateNumber || location.assetName}`;
              break;
            case AssetDisplayName["Device Name"]:
              assetDisplayLabel = `${location.assetName}`;
              break;
            case AssetDisplayName["Client Handle: Asset Code"]:
              assetDisplayLabel = `${location.companyName}: ${location.assetCode || location.assetName}`;
              break;
            case AssetDisplayName["Client Handle: Plate Number"]:
              assetDisplayLabel = `${location.companyName}: ${location.assetPlateNumber || location.assetName}`;
              break;
            case AssetDisplayName["Client Handle: Device Name"]:
              assetDisplayLabel = `${location.companyName}: ${location.assetName}`;
              break;
            default:
              assetDisplayLabel = `${location.assetName}`;
              break;
          }

          const theMarker = new PruneCluster.Marker(location.latitude, location.longitude, {
            title: assetDisplayLabel,
            iconId: location.icon,
          });

          theMarker.category = Math.ceil(location.deviceState).toString();
          theMarker.data.imei = location.unitId;
          theMarker.data.brand = location.assetBrand;
          theMarker.data.model = location.assetModel;
          theMarker.data.deviceId = location.deviceId;
          theMarker.data.deviceTypeId = location.deviceTypeId;
          theMarker.data.lastCommunication = location.lastCommunication;
          theMarker.data.stateChangedTimestamp = location.stateChangedTimestamp;
          theMarker.data.deviceState = location.deviceState;
          theMarker.data.radiusInMeters = location.radiusInMeters;
          theMarker.data.speed = location.speed;
          theMarker.data.insideGeofences = location.insideGeofences;
          theMarker.data.assetGroupIds = location.assetGroupIds;
          theMarker.data.assetGroups = location.assetGroups;
          theMarker.data.location = location;

          theMarker.data.assetDisplayLabel = `<b>${assetDisplayLabel}</b>`;
          theMarker.data.summary = `${location.assetBrand ?? ""} ${location.assetModel ?? ""}`;

          theMarker.filtered = false;
          theMarker.filteredWithoutState = false;

          this.markerList.push(theMarker);
          this.pruneCluster.RegisterMarker(theMarker);
        }
      });

      this.pruneCluster.ProcessView();

      setTimeout(() => {
        this.centerMap();
      }, 100);
    } else {
      console.log("Map: Dont use clustering");
      setTimeout(() => {
        if (this.markers && this.markerLayer) {
          this.markers.forEach((theMarker) => {
            this.markerLayer?.removeLayer(theMarker);
          });

          // Renew layer
          this.markers = [];
          // this.markerLayer = new L.featureGroup();
        }

        if (this.circleMarkers && this.radiusLayer) {
          this.circleMarkers.forEach((therCircleMarker) => {
            this.radiusLayer?.removeLayer(therCircleMarker);
          });

          // Renew layer
          this.circleMarkers = [];
          // this.markerLayer = new L.featureGroup();
        }

        locations.forEach((location) => {
          if (location.icon > 999) {
            iconPath = getIconPath(location.icon)[1];
          }

          if (location.latitude && location.longitude) {
            const positionValid =
              BOUND_CHECK(location.latitude, MAX_LATITUDE) && BOUND_CHECK(location.longitude, MAX_LONGITUDE);

            if (!positionValid) {
              console.log("Invalid location", location.latitude, location.longitude);
              return;
            }

            let assetDisplayLabel: string;

            location.assetName = location.assetName ?? location.unitId;
            location.companyName = location.companyName ?? "Not yet activated";

            switch (this.user.assetDisplayName) {
              case AssetDisplayName["Asset Code"]:
                assetDisplayLabel = `${location.assetCode || location.assetName}`;
                break;
              case AssetDisplayName["Plate Number"]:
                assetDisplayLabel = `${location.assetPlateNumber || location.assetName}`;
                break;
              case AssetDisplayName["Device Name"]:
                assetDisplayLabel = `${location.assetName}`;
                break;
              case AssetDisplayName["Client Handle: Asset Code"]:
                assetDisplayLabel = `${location.companyName}: ${location.assetCode || location.assetName}`;
                break;
              case AssetDisplayName["Client Handle: Plate Number"]:
                assetDisplayLabel = `${location.companyName}: ${location.assetPlateNumber || location.assetName}`;
                break;
              case AssetDisplayName["Client Handle: Device Name"]:
                assetDisplayLabel = `${location.companyName}: ${location.assetName}`;
                break;
              default:
                assetDisplayLabel = `${location.assetName}`;
                break;
            }

            location.title = assetDisplayLabel;

            const [markerIcon, heading] =
              location.deviceState === 6
                ? ["fa-rss", 0]
                : location.deviceState === 1 && location.headingInDegrees > 0
                ? ["fa-arrow-circle-up", location.headingInDegrees]
                : location.deviceState === 2
                ? ["fa-stop-circle", 0]
                : location.deviceState === 3
                ? ["fa-pause-circle", 0]
                : location.deviceState === 4
                ? ["fa-signal", 0]
                : location.deviceState === 5
                ? ["fa-power-off", 0]
                : location.deviceState === 0
                ? ["fa-question-circle", 0]
                : ["fa-play-circle", 0];

            const icon = L["StatusMarker"].icon({
              iconUrl: iconPath,
              icon: markerIcon,
              markerColor: colorMapper(location.deviceState),
              rotate: heading,
              shape: "circle",
              prefix: "fas",
            });

            let device = marker([location.latitude, location.longitude], {
              icon: icon,
            });

            location.marker = device;

            // This code manages showing outdated position(s) on the map
            if (locations.length === 1 && location.gpsPosition) {
              const gpsPosition = new L.LatLng(location.gpsPosition.latitude, location.gpsPosition.longitude);
              const currentPosition = new L.LatLng(location.latitude, location.longitude);

              const distanceLargeEnough = gpsPosition.distanceTo(currentPosition) > 25;

              if (distanceLargeEnough) {
                const pointList = [gpsPosition, currentPosition];

                const polyline = L.polyline(pointList, {
                  color: "red",
                  weight: 2,
                  opacity: 0.8,
                  dashArray: "10,10",
                  smoothFactor: 0,
                });

                const outdatedLocation = marker(gpsPosition, {
                  icon: L.ExtraMarkers.icon({
                    icon: "fa-thumbtack",
                    markerColor: "blue-dark",
                    rotate: 0,
                    shape: "circle",
                    prefix: "fas",
                  }),
                });

                this.markerLayer.addLayer(polyline);
                this.markerLayer.addLayer(outdatedLocation);
                this.markers.push(polyline, outdatedLocation);
              }
            }

            // Predictive marker animation
            if (this.slideTo && location.deviceState === 1) {
              var animate = false; //locations.length == 1;

              var point = turf.point([location.longitude, location.latitude]);

              var distance = ((5 / 18) * location.speed) / 10; // meters per second;
              var bearing = heading;
              var options = {};

              try {
                var destination = turf.destination(point, distance, bearing, options);

                // calculate next location based on speed and heading

                const position = [location.latitude, location.longitude];
                const nextPosition = [destination.geometry.coordinates[1], destination.geometry.coordinates[0]];

                var myMovingMarker = L["movingMarker"]([position, nextPosition], [160000], {
                  icon: icon,
                  animate: animate,
                  autostart: true,
                }).addTo(this.markerLayer);
                myMovingMarker.start();

                device = myMovingMarker;
              } catch (error) {
                console.log(error);
              }
            }

            this.theMapService.addLabel(device, location, !this.hideLabels);

            if (location.assetName) {
              const markerPopup = `
                <div style="width:100%">
                  <div style="width: 300px; overflow: auto;" class="leaflet-mappopup">
                  <div class="header">${location.assetName}</div>
                  <div class="content">${this.translateService.instant("general.lastCommunication")}</div>
                  <div class="content" title="${location.lastCommunication?.toLocaleString()}">
                    ${Moment(location.lastCommunication).fromNow()}
                  </div>
                  <div class="content">${this.translateService.instant("general.speed")}</div>
                  <div class="content" title="${location.speed?.toLocaleString()}">
                    ${location.speed} ${this.translatedKmh}
                  </div>
                  <div class="content" style="width: 100%">
                    <a href="/#/DeviceDetails/Index/${location.deviceId}">
                      ${this.translateService.instant("general.details")}
                    </a> / 
                    <a href="/#/DeviceDetails/Trips/${location.deviceId}">
                      ${this.translateService.instant("general.trips")}
                    </a>
                  </div>
                  </div>
                </div>`;

              device.bindPopup(markerPopup, {
                closeButton: false,
              });
            }

            if (location.radiusInMeters) {
              const circleMarker = L.circle([location.latitude, location.longitude], {
                color: "#e100ff",
                opacity: 0.4,
                fillOpacity: 0.1,
                dashArray: "10, 10",
                radius: location.radiusInMeters,
              });
              this.radiusLayer.addLayer(circleMarker);
              this.circleMarkers.push(circleMarker);
            }

            this.markerLayer.addLayer(device);
            this.markers.push(device);
          }
        });

        this.centerMap();
      }, 100);
    }
  }

  createIcon(data) {
    const location = data.location;
    const iconPath = getIconPath(data.iconId)[1];

    const [markerIcon, heading] =
      location.deviceState === 6
        ? ["fa-rss", 0]
        : location.deviceState === 1 && location.headingInDegrees > 0
        ? ["fa-arrow-circle-up", location.headingInDegrees]
        : location.deviceState === 2
        ? ["fa-stop-circle", 0]
        : location.deviceState === 3
        ? ["fa-pause-circle", 0]
        : location.deviceState === 4
        ? ["fa-signal", 0]
        : location.deviceState === 5
        ? ["fa-power-off", 0]
        : location.deviceState === 0
        ? ["fa-question-circle", 0]
        : ["fa-play-circle", 0];

    return L["StatusMarker"].icon({
      iconUrl: iconPath,
      icon: markerIcon,
      markerColor: colorMapper(location.deviceState),
      rotate: heading,
      shape: "circle",
      prefix: "fas",
    });
  }

  loadMaps(): void {
    forkJoin([
      this.storageHelper.loadStoreState(StorageType.LocalStorage, "settings_", "mapSelectionOptions"),
      this.storageHelper.loadStoreState(StorageType.LocalStorage, "Map_", "GeofenceLayerEnabled"),
      this.storageHelper.loadStoreState(StorageType.LocalStorage, "settings_", "showCompass"),
    ]).subscribe(([mapSelectionOptions, geofenceLayerEnabled, showCompass]) => {
      this.maps = getMapProvidersExtended(L, mapSelectionOptions);

      this.pruneCluster = new PruneClusterForLeaflet();

      let mapType = this.theMapService.getLeafletMapType();

      if (!mapType) {
        mapType = this.maps[0].name;
        this.theMapService.setLeafletMapType(mapType);
      }

      const defaultLayers = [];

      let defaultMap = this.maps.find((x) => x.name.toString() === mapType.toString());
      if (!defaultMap) {
        console.log("MAP: Falling back to default map");
        defaultMap = this.maps[0];
      }

      defaultLayers.push(defaultMap.layer);

      defaultLayers.push(this.markerLayer);

      if (this.geofenceEnabled || geofenceLayerEnabled) {
        defaultLayers.push(this.geofenceLayer);
      }

      defaultLayers.push(this.locationLayer);
      defaultLayers.push(this.tripLayer);
      defaultLayers.push(this.eventsLayer);
      defaultLayers.push(this.radiusLayer);

      if (this.useClustering) {
        defaultLayers.push(this.pruneCluster);

        this.theMapService.setPruneCluster(this.pruneCluster);
        const that = this;

        // console.log('MAP: Skip clustering');
        // this.pruneCluster.Cluster.Size = .000001;
        // this.pruneCluster.Cluster.Margin = .000001;

        this.pruneCluster.PrepareLeafletMarker = function (theMarker, data, category) {
          // parse data to icon
          that.theMapService.addLabel(theMarker, data, !that.hideLabels, that.skipIncludingGroupColors);

          that.theMapService.createPopup(theMarker, data, category);

          theMarker.setIcon(that.createIcon(data));
        };
      } else {
        console.log("MAP: Skip clustering");
      }

      const mapOptions = createMapOptions.call(
        this,
        L,
        defaultLayers,
        this.translateService,
        this.geofenceFromTrip,
        showCompass ?? false
      );

      this.options = {
        ...mapOptions,
      };
    });
  }

  checkCircleMarker(data) {
    this.clearCircleMarkers();

    if (data?.location?.radiusInMeters) {
      const circleMarker = L.circle([data.location.latitude, data.location.longitude], {
        color: "#e100ff",
        opacity: 0.4,
        fillOpacity: 0.1,
        dashArray: "10, 10",
        radius: data.location.radiusInMeters,
      });
      this.radiusLayer.addLayer(circleMarker);
      this.circleMarkers.push(circleMarker);
    }
  }

  clearCircleMarkers() {
    if (this.circleMarkers) {
      this.circleMarkers.forEach((therCircleMarker) => {
        this.map.removeLayer(therCircleMarker);
      });

      // Renew layer
      this.circleMarkers = [];
      // this.markerLayer = new L.featureGroup();
    }
  }

  prepareGeofences(geofences) {
    if (geofences === undefined) {
      return;
    }

    if (this.geofenceLayer) {
      this.geofenceLayer.clearLayers();
    }

    drawGeofences(L, geofences, this.geofenceLayer, this.allowEdit ? this.fillColor : null);

    if (this.geofenceEnabled) {
      setTimeout(() => {
        this.centerMap(undefined, true);
      }, 100);
    }
  }

  onMapReady(map: Map) {
    console.log("Map ready");

    const that = this;
    this.map = map;

    this.map.on("baselayerchange", (event) => {
      this.theMapService.setLeafletMapType(event?.["name"]);
    });

    setBounds(L, map);

    // spiderfy
    this.oms = new OverlappingMarkerSpiderfier(this.map);

    this.oms.addListener("click", function (_marker): void {});

    this.oms.addListener("spiderfy", function (markers): void {
      for (let i = 0, len = markers.length; i < len; i++) {
        map.closePopup();
      }
    });

    this.oms.addListener("unspiderfy", function (markers): void {
      for (let i = 0, len = markers.length; i < len; i++) {}
    });

    this.map.on("click", (evt: any) => {
      this.zone.run(() => {
        this.clearCircleMarkers();
      });
    });

    const overlayMaps = {
      Geofences: this.geofenceLayer,
      Markers: this.markerLayer,
      Trips: this.tripLayer,
      Events: this.eventsLayer,
    };

    if (this.useClustering) {
      overlayMaps["PruneCluster"] = this.pruneCluster;
    }

    if (this.heatmapEnabled) {
      overlayMaps["Heatmap"] = this.heatmapLayer;
    }

    // overlayMaps
    // L.control.layers(this.maps, [], { position: 'topright' }).addTo(map);
    if (this.showScale) {
      L.control.scale().addTo(map);
    }

    new L.basemapsSwitcher(this.maps, { position: "topright" }).addTo(this.map);

    if (this.showFitMap) {
      L.easyButton({
        id: "fit map button",
        position: "topleft",
        states: [
          {
            stateName: "add-markers",
            icon: "fa-arrows-to-eye",
            title: "Fit map",
            onClick: function (control) {
              that.centerMap(undefined, true);
            },
          },
        ],
      }).addTo(this.map);
    }

    if (this.showExtendMap) {
      const extendToggle = L.easyButton({
        id: "animated-heatmap-toggle",
        position: "topright",
        states: [
          {
            stateName: "add-extend",
            icon: "fa-expand",
            title: "Extend map view",
            onClick: function (control) {
              that.setHeight(600);
              that.mapResized.next(true);
              control.state("remove-extend");
            },
          },
          {
            stateName: "remove-extend",
            title: "Compress map view",
            icon: "fa-compress",
            onClick: function (control) {
              that.setHeight(250);
              that.mapResized.next(true);
              control.state("add-extend");
            },
          },
        ],
      });
      extendToggle.addTo(this.map);
    }

    if (this.heatmapEnabled) {
      const heatmapToggle = L.easyButton({
        id: "animated-heatmap-toggle",
        position: "topleft",
        states: [
          {
            stateName: "add-markers",
            icon: "fa-fire",
            title: "Show heatmap",
            onClick: function (control) {
              that.map.addLayer(that.heatmapLayer);
              that.map.removeLayer(that.tripLayer);
              that.map.removeLayer(that.pruneCluster);
              control.state("remove-markers");
            },
          },
          {
            stateName: "remove-markers",
            title: "Remove heatmap",
            icon: "fa-undo",
            onClick: function (control) {
              that.map.removeLayer(that.heatmapLayer);
              that.map.addLayer(that.tripLayer);
              that.map.addLayer(that.pruneCluster);
              control.state("add-markers");
            },
          },
        ],
      });
      heatmapToggle.addTo(this.map);
    }

    if (this.showGeofenceSwitch) {
      this.storageHelper
        .loadStoreState(StorageType.LocalStorage, "Map_", "GeofenceLayerEnabled")
        .subscribe((geofenceLayerEnabled) => {
          const geofenceToggle = L.easyButton({
            id: "animated-geofences-toggle",
            position: "topright",
            states: [
              {
                stateName: "add-geofences",
                icon: "fa-draw-polygon",
                title: "Show geofences",
                onClick: function (control) {
                  that.map.addLayer(that.geofenceLayer);
                  control.state("remove-geofences");
                  that.storageHelper.saveStoreState(StorageType.LocalStorage, "Map_", "GeofenceLayerEnabled", true);
                },
              },
              {
                stateName: "remove-geofences",
                title: "Remove geofences",
                icon: "fa-vector-polygon",
                onClick: function (control) {
                  that.map.removeLayer(that.geofenceLayer);
                  control.state("add-geofences");
                  that.storageHelper.saveStoreState(StorageType.LocalStorage, "Map_", "GeofenceLayerEnabled", false);
                },
              },
            ],
          });

          if (geofenceLayerEnabled) {
            geofenceToggle.state("remove-geofences");
          }

          geofenceToggle.addTo(this.map);
        });
    }

    if (this.showSearch) {
      map.addControl(
        new L.Control.Search({
          url: "https://nominatim.openstreetmap.org/search?format=json&q={s}",
          jsonpParam: "json_callback",
          propertyName: "display_name",
          propertyLoc: ["lat", "lon"],
          marker: L.circleMarker([0, 0], { radius: 30 }),
          autoCollapse: true,
          hideMarkerOnCollapse: true,
          autoType: true,
          zoom: 12,
          minLength: 2,
        })
      );
    }

    // Restoring mapview on reentering view
    if (!this.map["restoreView"]()) {
      // map["restoreView"] !== "undefined" &&
      console.log("Error restoring view, setting to default");
      this.map.setView([25.0512538, 55.1980439], 15);
    } else {
      console.log("Restored view");
    }

    if (this.allowEdit) {
      // this.addDrawControls();
    }

    if (this.allowBookmarks) {
      const bookmarks = new L.Control.Bookmarks().addTo(this.map);
    }

    this.mapReady.next(this);
  }

  removeDrawControls() {
    this.map.pm.removeControls();
  }

  addDrawControls(): void {
    // define toolbar options
    const options = {
      position: "topright", // toolbar position, options are 'topleft', 'topright', 'bottomleft', 'bottomright'
      allowSelfIntersection: true,
      drawMarker: false, // adds button to draw markers
      drawCircleMarker: false, // adds button to draw circle markers
      drawPolyline: true, // adds button to draw a polyline
      drawRectangle: true, // adds button to draw a rectangle
      drawPolygon: true, // adds button to draw a polygon
      drawCircle: true, // adds button to draw a cricle
      cutPolygon: false, // adds button to cut a hole in a polygon
      editMode: true, // adds button to toggle edit mode for all layers
      removalMode: false, // adds a button to remove layers
    };

    // add leaflet.pm controls to the map
    this.map.pm.addControls(options);

    this.geofenceLayer.pm.enable(options);

    this.map.on("pm:create", (e) => {
      this.geofenceLayer.clearLayers();

      const type = e.layerType,
        layer = e.layer;

      this.map.removeLayer(layer);

      if (e.shape === "Line") {
        // Create a corridor and add to the map
        const corridor = L.corridor(layer.getLatLngs(), {
          corridor: 35, // meters
          className: "route-corridor",
          opacity: "0.35",
          color: this.fillColor,
        });

        this.geofenceLayer.addLayer(corridor);
        this.onSave.next(corridor);
      } else {
        layer.edited = true;
        layer.options.color = this.fillColor;

        this.geofenceLayer.addLayer(layer);
        this.onSave.next(layer);
      }
    });

    this.map.on("pm:edit", (e) => {
      const layer = e.layer;
      this.onSave.next(layer);
    });

    // listen to when a layer is changed in edit mode
    this.geofenceLayer.on("pm:edit", (e) => {
      const layer = e.layer;
      this.onSave.next(layer);
    });
  }

  centerMap(layer?, resetZoom = false) {
    console.log("centring map");

    this.invalidateSize();

    if (layer === undefined) {
      const maxZoomLevel = 15;
      const previousZoomLevel = resetZoom || !this.isLoaded ? 15 : this.map.getZoom();

      this.isLoaded = true;

      if (!this.allowEdit && this.useClustering && this.pruneCluster?.Cluster && !this.skipClustering) {
        console.log("Center on cluster layer");

        layer = this.pruneCluster;
        const theBounds = this.pruneCluster.Cluster.ComputeGlobalBounds();
        if (theBounds) {
          this.map.fitBounds(
            new L.LatLngBounds(
              new L.LatLng(theBounds.minLat, theBounds.maxLng),
              new L.LatLng(theBounds.maxLat, theBounds.minLng)
            ),
            { maxZoom: 15, padding: [50, 50], animate: true, duration: 0.5 }
          );
        }
        return;
      } else if (this.tripLayer.getLayers().length > 0) {
        console.log("Center on trip layer");

        layer = this.tripLayer;

        const bounds = layer.getBounds();
        if (bounds.isValid()) {
          this.map.fitBounds(bounds, { maxZoom: maxZoomLevel, padding: [15, 15] });
        }
        return;
      } else if (this.allowEdit && this.geofenceLayer.getLayers().length > 0) {
        console.log("Center on geofence layer");

        layer = this.geofenceLayer;

        const bounds = layer.getBounds();
        if (bounds.isValid()) {
          this.map.fitBounds(bounds, { maxZoom: maxZoomLevel, padding: [15, 15] });
        }
        return;
      } else if (this.markerLayer.getLayers().length > 0) {
        console.log("Center on marker layer");

        layer = this.markerLayer;
        const markerBounds = layer.getBounds();

        // Check if radius layer is set
        if (this.radiusLayer.getLayers().length > 0) {
          console.log("Set radius layer");
          const radiusMarkerBounds = this.radiusLayer.getBounds();

          if (this.fly) {
            this.map.fitBounds(radiusMarkerBounds, {
              padding: [15, 15],
              maxZoom: maxZoomLevel,
              animate: true,
              duration: 0.5,
            });
          } else {
            this.map.fitBounds(radiusMarkerBounds, { padding: [15, 15], maxZoom: maxZoomLevel });
          }

          return;
        }

        if (markerBounds.isValid()) {
          if (this.markerLayer.getLayers().length === 1) {
            const latLng = markerBounds.getCenter();
            if (this.fly) {
              this.map.setView(latLng, previousZoomLevel, { animate: true, duration: 0.5 });
            } else {
              this.map.setView(latLng, previousZoomLevel);
            }
          } else {
            if (this.fly) {
              this.map.fitBounds(markerBounds, {
                padding: [15, 15],
                maxZoom: maxZoomLevel,
                animate: true,
                duration: 0.5,
              });
            } else {
              this.map.fitBounds(markerBounds, { padding: [15, 15], maxZoom: maxZoomLevel });
            }
          }
        }
        return;
      } else if (this.heatmapLayer) {
        console.log("Center on heatmap layer");
        layer = this.heatmapLayer;

        const bounds = layer.getBounds();
        if (bounds.isValid()) {
          this.map.fitBounds(bounds, { padding: [15, 15], maxZoom: maxZoomLevel });
        }
        return;
      } else if (this.geofenceLayer) {
        console.log("Center on geofence layer");

        layer = this.geofenceLayer;

        const bounds = layer.getBounds();
        if (bounds.isValid()) {
          this.map.fitBounds(bounds, { padding: [15, 15], maxZoom: maxZoomLevel });
        }
        return;
      }
    }

    if (layer.getLatLng?.() === undefined) {
      return;
    }

    const bounds = layer.getBounds();
    if (bounds.isValid()) {
      this.map.fitBounds(bounds, { padding: [15, 15] });
    }
  }

  setHeight(value) {
    this.height = value;
    this.cd.markForCheck();

    setTimeout(() => {
      this.map.invalidateSize();
    }, 1);
  }

  invalidateSize() {
    this.map?.invalidateSize();
  }
}
