import { HttpClient } from "@angular/common/http";
import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuditLog } from "../../models/auditlog.model";
import { TranslateService } from "@ngx-translate/core";
import { BsDaterangepickerConfig } from "ngx-bootstrap/datepicker";
import { getDefaultDpConfig } from "app/common/gridhelper";
import { AuthenticationService } from "app/services/authentication/authentication.service";
import { NotificationLogService } from "app/services/notificationlog/notificationlog.service";

// Moment timezone
import Moment from "moment-timezone";
window["moment"] = Moment;

@Component({
  selector: "fh-notifications",
  templateUrl: "notifications.template.html",
})
export class NotificationsViewComponent implements OnInit {
  deviceLog: AuditLog[];
  sub;

  loading = false;
  loadingNotifications = false;
  loadingTriggers = false;

  error: any;
  success: any;
  notifications = [];
  triggers = [];
  selectedNotification;

  currentPage;
  filter;

  userId;

  // Daterange
  public dpConfig: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();
  daterangepickerModel: Date[];
  permissions: {};
  timezoneIana: string;

  constructor(
    private authenticationService: AuthenticationService,
    private cd: ChangeDetectorRef,
    private http: HttpClient,
    private notificationLogService: NotificationLogService,
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService
  ) {
    this.permissions = authenticationService.permissions;

    this.deviceLog = [];

    this.timezoneIana = authenticationService.getTimeZoneIana();

    this.daterangepickerModel = [
      Moment().subtract(1, "month").startOf("day").toDate(),
      Moment().add(0, "days").endOf("day").toDate(),
    ];

    this.dpConfig = getDefaultDpConfig(this.authenticationService);
  }

  dateChanged(event) {
    const that = this;
    this.loadingNotifications = true;
    this.error = null;

    if (event !== null) {
      this.daterangepickerModel = event;
    }

    this.notificationLogService
      .getNoticationLogByUserId(
        this.userId,
        Moment(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf("day"),
        Moment(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf("day")
      )
      .subscribe(
        (result) => {
          this.notifications = result;
          this.loadingNotifications = false;
          this.cd.markForCheck();
        },
        (error) => {
          this.error = error;
          this.loadingNotifications = false;
          this.cd.markForCheck();
        }
      );
  }

  ngOnInit() {
    this.loading = true;
    this.loadingNotifications = true;

    this.userId = this.authenticationService.getUserId();
    this.dateChanged(null);
  }
}
