export class InventorySensorType {
  public id: string;
  public brand: string;
  public model: string;
  public deviceSensorType: any;
  public conversionFormula: any = "";
  public calibrationPoints: { rawValue: number; actualValue: number }[] = [];
  public deviceSensorTypeId: any;
  public deviceSensorTypeName: any;
}
