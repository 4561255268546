import { ChangeDetectorRef, Component } from "@angular/core";
import { Router } from "@angular/router";
import { FormMode } from "app/common/enums";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { AssetType } from "app/models/assetType.model";
import { TranslateService } from "@ngx-translate/core";
import { ServiceAnnouncementService } from "app/services/announcements/serviceAnnouncements";
import { ServiceAnnouncement } from "app/models/serviceAnnouncement.model";

@Component({
  selector: "fh-service-announcement-add",
  templateUrl: "add.template.html",
})
export class ServiceAnnouncementAddViewComponent {
  token: string;
  loading = false;
  serviceAnnouncement = new ServiceAnnouncement();

  formMode: FormMode = FormMode.add;
  formModeAssetTypes: FormMode = FormMode.read;

  error: any;
  permissions: {};
  success: { statusText: string; success: string };
  assetTypes: AssetType[];

  permissionName = "UserContacts_Add";

  constructor(
    private translateServe: TranslateService,
    private cd: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private router: Router,
    private serviceAnnouncementsService: ServiceAnnouncementService
  ) {
    this.permissions = this.authenticationService.permissions;
    this.token = this.authenticationService.getAuthToken();
  }

  onInsert() {
    this.loading = true;

    this.serviceAnnouncementsService.saveServiceAnnouncement(this.serviceAnnouncement).subscribe(
      (result) => {
        this.success = {
          statusText: "Success",
          success: this.translateServe.instant("general.saveSuccessRedirect", { entity: "serviceAnnouncement" }),
        };

        setTimeout(() => {
          this.router.navigate(["/Settings/ServiceAnnouncementDetails/Index/" + result.id]);
        }, 2000);

        this.loading = false;
      },
      (error) => {
        this.error = error;
        this.loading = false;
      }
    );
  }
}
