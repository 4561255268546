<ng-select
    [loading]="loadingAssetGroups"
    ngDefaultControl
    [searchable]="true"
    [clearable]="clearable"
    style="height: unset"
    [disabled]="assetGroups.length == 0"
    [readonly]="readonly"
    [virtualScroll]="true"
    placeholder="{{ placeholder }} ({{ assetGroups.length }})"
    #input
    [multiple]="multiple"
    [(ngModel)]="value"
    [items]="assetGroups"
    bindLabel="assetName"
    bindValue="id"
    class="form-control"
    groupBy="assetGroupItems"
    [selectableGroup]="true"
    [groupValue]="groupValueFn"
    [closeOnSelect]="false"
    [searchFn]="searchFn"
    [compareWith]="compareFn"
    [ngClass]="tableInline === true ? 'table-inline' : ''"
    (ngModelChange)="assetGroupChanged()"
>
    <ng-template ng-label-tmp let-assetgroup="item" let-clear="clear">
        <span *ngIf="assetgroup.displayName">
            <i
                class="fas fa-fw fa-layer-group"
                [style.color]="assetgroup.color"
                title="Group: {{ assetgroup.displayName }} {{ assetgroup.length }}"
            ></i>
            {{ assetgroup.displayName }} ({{ assetgroup.length }})</span
        >
        <span *ngIf="assetgroup.assetName">
            <span
                style="float: left; margin-top: -8px; padding-right: 6px"
                [innerHTML]="formatIconId(assetgroup)"
            ></span>
            {{ assetgroup.assetName }}
        </span>
        <span class="ng-value-icon right" (click)="clear(assetgroup)" aria-hidden="true">×</span>
    </ng-template>
    <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index" let-search="searchTerm">
        <i
            class="far fa-fw fa-lg"
            [style.color]="item.color"
            [ngClass]="item$.selected ? 'fa-check-square' : 'fa-square'"
        ></i>
        {{ item.displayName }} ({{ item.length }})
    </ng-template>
    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index" let-search="searchTerm">
        <span style="float: left; margin-top: -8px" [innerHTML]="formatIconId(item)"></span>
        <span style="padding: 10px"> {{ item.assetName }} {{ item.plateNumber ? ": " + item.plateNumber : "" }}</span>
    </ng-template>
</ng-select>
