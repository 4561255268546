import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { getDefaultDpConfig } from "app/common/gridhelper";
import { AuthenticationService } from "app/services/authentication/authentication.service";
import { BsDaterangepickerConfig } from "ngx-bootstrap/datepicker";
import { Observable, debounceTime, distinctUntilChanged, of } from "rxjs";
import { forkJoin } from "rxjs/internal/observable/forkJoin";
import "../../../../vendor/jquery-skedTape/jquery.skedTape.js";

// Moment timezone
import Moment from "moment-timezone";
import { getIconPath, roundAsString, roundMinutes, roundSeconds, contains } from "app/common/globals";

window["moment"] = Moment;

import { DeviceTypeService } from "app/services/devicetypes/devicetypes.service";
import { AccountService } from "app/services/account/account.service";
import { AssetGroupInputComponent } from "app/modules/customInputs/assetGroupSelector.component";
import { GanttEvent } from "app/models/ganttEvent.model";
import { TriggerScheduleService } from "app/services/triggerSchedule/triggerSchedule.service";
import { GanttStatistic } from "app/models/ganttStatistic.model";

@Component({
  selector: "fh-account-driver-trips-dayview",
  templateUrl: "dayviewTrips.template.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DriverTripsDayViewViewComponent implements OnInit {
  @ViewChild("assetGroupVar") el: AssetGroupInputComponent;
  token: string;
  countrySelect = false;

  excludingColumns = [
    "timeStamp",
    "assetDateStart",
    "assetDateEnd",
    "lastCommunication",
    "input1",
    "input2",
    "input3",
    "input4",
    "input5",
    "input6",
    "output1",
    "output2",
  ];
  loading = false;
  loadingData = false;

  permissionName = "Drivers_View";
  constructorName = "DriverScoreViewComponent";

  selectedResellerId;
  selectedAccountId;
  selectedAssetGroups;

  error: any;
  success: any;
  warning: any;

  timezoneIana: string;
  languageLoaded: boolean;

  trips = [];
  selectedDrivers = [];
  hideEmptyRows = true;

  idlingPercentage = "-";
  engineOnPercentage = "-";
  workingPercentage = "-";
  crossoverPercentage = "-";

  // Pagination
  itemsPerPage = 20;
  currentPage = 1;
  stringFilter = "";
  totalItems: any[];

  orderBy = "created";
  orderByReversed = false;
  status;

  // Datepicker
  public dpConfig: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();
  to: any;
  from: any;
  daterangepickerModel: any[];

  deferSearch$: Observable<void>;

  showActualHours = false;
  engineOnHours: string;
  idlingHours: string;
  workingHours: string;
  crossoverHours: string;
  mySubscription: any;

  constructor(
    private translateService: TranslateService,
    private cd: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private triggerScheduleService: TriggerScheduleService,
    private accountService: AccountService,
    private deviceTypeService: DeviceTypeService
  ) {
    const that = this;

    this.showActualHours = localStorage.getItem("ShowActualHours" + this.constructorName) === "true";

    this.token = this.authenticationService.getAuthToken();
    this.timezoneIana = this.authenticationService.getTimeZoneIana();

    this.daterangepickerModel = [
      Moment().tz(this.timezoneIana).subtract(1, "days").startOf("day").toDate(),
      Moment().tz(this.timezoneIana).endOf("day").toDate(),
    ];

    this.dpConfig = getDefaultDpConfig(authenticationService);
  }

  flipShowActualHours() {
    this.showActualHours = !this.showActualHours;

    this.getData();
  }

  resellerChanged(event) {
    this.trips = [];
    this.selectedAssetGroups = [];

    this.selectedAccountId = null;
  }

  accountChanged(event) {
    this.trips = [];
    this.selectedAssetGroups = [];

    this.getData();
  }

  ngOnInit() {
    // Get all the date for dropdown boxes
    forkJoin([this.translateService.get("general.date"), this.deviceTypeService.getDeviceTypes()]).subscribe(
      (data) => {
        this.getData();
        this.languageLoaded = true;
        this.cd.markForCheck();
      },
      (err) => {
        this.error = err;
        this.languageLoaded = true;
        this.loading = false;
        this.cd.markForCheck();
      }
    );

    this.deferSearch$ = new Observable((observer) => {
      of(this.stringFilter)
        .pipe(debounceTime(500), distinctUntilChanged())
        .subscribe((_) => {
          this.renderData();
          observer.next();
        });
    });

    this.dateChanged(true);
  }

  getIcon(iconId) {
    return getIconPath(iconId)[2];
  }

  filterDrivers() {
    // Filter groups
    let filteredAssetGroups = this.el.assetGroups;
    if (this.selectedAssetGroups && this.selectedAssetGroups.length > 0) {
      this.trips = this.trips.filter((x) => x.assetGroups.some((ag) => this.selectedAssetGroups.includes(+ag.id)));
      filteredAssetGroups = this.el.assetGroups.filter((assetgroup) =>
        this.selectedAssetGroups.some((id) => id === assetgroup.id)
      );
    }

    this.selectedDrivers = [];

    const uniqueDrivers = {};
    for (const assetGroup of filteredAssetGroups) {
      for (const asset of assetGroup.assetGroupItems) {
        if (uniqueDrivers[asset.id]) {
          continue;
        }

        uniqueDrivers[asset.id] = asset.assetName;
        this.selectedDrivers.push({ id: asset.id, name: uniqueDrivers[asset.id] });
      }
    }

    this.renderData();
    this.cd.markForCheck();
  }

  dateChanged(event) {
    const that = this;
    console.log("Changed date");
    if (event !== null) {
      this.getData();
    }
  }

  cancel() {
    this.mySubscription.unsubscribe();

    this.loading = false;

    this.warning = {};
    this.warning.warning = "Call was cancelled.";
    this.warning.statusText = "Warning";
  }

  getData() {
    this.loadingData = true;

    this.trips = [];

    if (this.selectedAccountId == null) {
      this.loadingData = false;
      return;
    }

    this.mySubscription = this.accountService
      .getAccountTrips(
        this.selectedAccountId,
        Moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf("day"),
        Moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf("day"),
        true
      )
      .subscribe(
        (trips) => {
          trips.forEach((trip) => {
            trip.beginTS = Moment.utc(trip.beginTS).tz(this.timezoneIana);
            trip.endTS = Moment.utc(trip.endTS).tz(this.timezoneIana);
            trip.duration = Moment.duration(trip.endTS.diff(trip.beginTS)).asMinutes();

            trip.episodes?.forEach((episode) => {
              episode.episodeStart = Moment.utc(episode.episodeStart).tz(this.timezoneIana);
              episode.episodeEnd = Moment.utc(episode.episodeEnd).tz(this.timezoneIana);
            });
          });

          this.trips = trips;
          this.error = null;

          this.filterDrivers();
          this.loadingData = false;
          this.cd.markForCheck();
        },
        (err) => {
          this.error = err;
          this.languageLoaded = true;
          this.loadingData = false;
          this.cd.markForCheck();
        }
      );
  }

  actualRound(value, decimals) {
    return roundAsString(value, decimals);
  }

  async renderData() {
    const that = this;

    const driverNames = {};
    const events = [] as GanttEvent[];

    const assetStatistic: { [key: string]: GanttStatistic } = {};

    const episodeTypes = {
      131: this.translateService.instant("general.idling"),
      160: this.translateService.instant("general.crossover"),
      161: this.translateService.instant("general.work"),
    };

    const totalTimespan = (this.daterangepickerModel[1].getTime() - this.daterangepickerModel[0].getTime()) / 1000;

    for (const trip of this.trips) {
      if (!trip.driverId) {
        continue;
      }

      if (trip.driverName?.toLowerCase().indexOf(this.stringFilter?.toLowerCase()) === -1) {
        continue;
      }

      assetStatistic[trip.driverId] = assetStatistic[trip.driverId] ?? new GanttStatistic();

      driverNames[trip.driverId] = trip.driverName ?? trip.driverId;

      // Add the trip
      const tripEvent = {
        start: Moment.max(this.daterangepickerModel[0], trip.beginTS),
        end: trip.endTS ?? new Date(),
        location: trip.driverId,
        name: `${trip.assetName}\u200E\n${trip.beginTS.format("lll")}\u00A0-\u00A0${trip.endTS?.format("lll") ?? ""}`,
        // name: `${trip.beginTS.format('HH:mm')} - ${trip.endTS?.format('HH:mm') ?? ''}`,
        stylePlain:
          "margin: 21.5px 0; background-color: #007a80; color: #fff; border: none; box-shadow: 1px 1px 5px 0px rgb(0 0 0 / 30%)",
        userData: { deviceId: trip.deviceId },
      };
      events.push(tripEvent);

      assetStatistic[trip.driverId].engineOn.push([tripEvent.start, tripEvent.end]);

      // Add the episodes
      if (!trip.episodes) {
        continue;
      }

      for (const episode of trip.episodes) {
        let color = "#ccc";

        switch (episode.fkDeviceEpisodeTypeId) {
          case 131:
            // Idling
            episode.isEnabled = true;
            assetStatistic[trip.driverId].idling.push([episode.episodeStart, episode.episodeEnd]);
            color = "rgba(242, 200, 15, 0.9)";
            break;
          // case 160:
          //     // Crossover
          //     color = '#A8973B';
          //     break;
          // case 161:
          //     // Work
          //     color = '#01b8aa';
          //     break;
          default:
            episode.isEnabled = false;
            break;
        }

        if (episode.isEnabled && episode.episodeEnd > episode.episodeStart) {
          events.push({
            start: episode.episodeStart,
            end: episode.episodeEnd,
            location: trip.driverId,
            name: `${episodeTypes[episode.fkDeviceEpisodeTypeId]}\u200E\n${episode.episodeStart.format(
              "lll"
            )}\u00A0-\u00A0${episode.episodeEnd.format("lll")}`,
            stylePlain: `margin: 25px 0; background-color: ${color}; color: transparent; border: 1px solid rgb(0 0 0 / 30%);`,
            userData: { deviceId: trip.deviceId },
          });
        }
      }
    }

    // Option to hide all assets without trips
    if (!this.hideEmptyRows) {
      for (const asset of this.selectedDrivers) {
        driverNames[asset.id] = asset.name;
      }
    }

    const drivers = [];

    for (const property in driverNames) {
      if (!property) {
        continue;
      }
      drivers.push({ id: +property, name: driverNames[property] });
    }

    const assetStatistics = await Promise.all(
      Object.values(assetStatistic).map((x) => new Promise<number[]>((resolve, _) => resolve(x.getAllTotals)))
    );
    const [workingTotal, idlingTotal, equipmentIdlingTotal, engineOnTotal, crossoverTotal] = assetStatistics.reduce(
      (
        [_workingTotal, _idlingTotal, _equipmentIdlingTotal, _engineOnTotal, _crossoverTotal],
        [_workingSum, _idlingSum, _equipmentIdlingSum, _engineOnSum, _crossoverSum]
      ) => [
        _workingTotal + _workingSum,
        _idlingTotal + _idlingSum,
        _equipmentIdlingTotal + _equipmentIdlingSum,
        _engineOnTotal + _engineOnSum,
        _crossoverTotal + _crossoverSum,
      ],
      [0, 0, 0, 0, 0]
    );

    const percentFormatter = new Intl.NumberFormat(undefined, {
      style: "percent",
      minimumFractionDigits: 1,
    });

    this.engineOnHours = engineOnTotal ? `${roundMinutes(engineOnTotal / 60, true)} H` : "-";
    this.idlingHours = engineOnTotal ? `${roundMinutes(idlingTotal / 60, true)} H` : "-";
    this.workingHours = engineOnTotal ? `${roundMinutes(workingTotal / 60, true)} H` : "-";
    this.crossoverHours = engineOnTotal ? `${roundMinutes(crossoverTotal / 60, true)} H` : "-";

    this.engineOnPercentage =
      drivers.length > 0 ? percentFormatter.format(engineOnTotal / drivers.length / totalTimespan) : "-";
    this.idlingPercentage =
      drivers.length > 0 ? percentFormatter.format(idlingTotal / drivers.length / totalTimespan) : "-";
    this.workingPercentage =
      drivers.length > 0 ? percentFormatter.format(workingTotal / drivers.length / totalTimespan) : "-";
    this.crossoverPercentage =
      drivers.length > 0 ? percentFormatter.format(crossoverTotal / drivers.length / totalTimespan) : "-";
    this.totalItems = drivers;

    const slidedDrivers = drivers.slice(
      (this.currentPage - 1) * this.itemsPerPage,
      (this.currentPage - 1) * this.itemsPerPage + this.itemsPerPage
    );
    const slicedEvents = events.filter((x) =>
      contains(
        slidedDrivers.map((y) => y.id),
        x.location
      )
    );

    $("#skedGantt")["skedTape"]({
      caption: "Assets",
      start: this.daterangepickerModel[0],
      end: this.daterangepickerModel[1],
      // showEventTime: true,
      // showEventDuration: true,
      scrollWithYWheel: true,
      locations: slidedDrivers,
      events: slicedEvents,
      // maxTimeGapHi: 60 * 1000, // 1 minute
      // minGapTimeBetween: 1 * 60 * 1000,
      snapToMins: 1,
      editMode: false,
      timeIndicatorSerifs: true,
      showIntermission: true,
      showPopovers: "never",
      formatters: {
        date: function (date) {
          return $.fn["skedTape"].format.date(date, "l", ".");
        },
      },
      canAddIntoLocation: function (location, event) {
        return true;
      },
      postRenderLocation: function ($el, location, canAdd) {
        this.constructor.prototype.postRenderLocation($el, location, canAdd);

        let engineOn = "";
        if (assetStatistic[location.id]?.getEngineOnTotal) {
          engineOn = `<i class="fas fa-fw fa-square" style="color: #007a801a"></i> ${
            that.showActualHours
              ? roundMinutes(assetStatistic[location.id].getEngineOnTotal / 60, true) + " H"
              : percentFormatter.format(assetStatistic[location.id].getEngineOnTotal / totalTimespan)
          }  `;
        }

        let idling = "";
        if (assetStatistic[location.id]?.getIdlingTotal) {
          idling = `<i class="fas fa-fw fa-square" style="color: #f2c80f"></i> ${
            that.showActualHours
              ? roundMinutes(assetStatistic[location.id].getIdlingTotal / 60, true) + " H"
              : percentFormatter.format(assetStatistic[location.id].getIdlingTotal / totalTimespan)
          }  `;
        }

        let working = "";
        if (assetStatistic[location.id]?.getWorkingTotal) {
          working = `<i class="fas fa-fw fa-square" style="color: #01b8aa"></i> ${
            that.showActualHours
              ? roundMinutes(assetStatistic[location.id].getWorkingTotal / 60, true) + " H"
              : percentFormatter.format(assetStatistic[location.id].getWorkingTotal / totalTimespan)
          }  `;
        }

        let crossover = "";
        if (assetStatistic[location.id]?.getCrossoverTotal) {
          crossover = `<i class="fas fa-fw fa-square" style="color: #A8973B"></i> ${
            that.showActualHours
              ? roundMinutes(assetStatistic[location.id].getCrossoverTotal / 60, true) + " H"
              : percentFormatter.format(assetStatistic[location.id].getCrossoverTotal / totalTimespan)
          }  `;
        }

        $el.after(
          `<span style="display: block; margin: -45px 0px 0px -2px;">${engineOn}${idling}${working}${crossover}&nbsp;</div></span`
        );

        $el.addClass("secondary link_bolder hand").click(function () {
          document.location.href = "#/DriverDetails/Index/" + location.id;
        });
      },
      postRenderEvent: function ($el, event) {
        $el.attr("data-container", "body");

        $el.css("cursor", "pointer");
        $el.click(function () {
          document.location.href = "#/DeviceDetails/Index/" + event.userData.deviceId;
        });
      },
    });

    this.loading = false;
    this.cd.markForCheck();
  }

  onChangeCheckbox() {
    if (this.selectedAccountId == null) {
      return;
    }

    this.loading = true;
    this.cd.markForCheck();

    setTimeout(() => {
      this.renderData();
    }, 0);
  }

  actualRoundMinutes(value) {
    return roundMinutes(value);
  }

  actualRoundSeconds(value) {
    return roundSeconds(value);
  }
}
