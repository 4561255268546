import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Device } from "app/models/device.model";
import { AuditLog } from "../../models/auditlog.model";
import { DeviceService } from "../../services/device/device.service";
import { TranslateService } from "@ngx-translate/core";

import * as XLSX from "xlsx-js-style";
import { DeviceRoutingConfigurationService } from "app/services/deviceroutingconfiguration/deviceroutingconfiguration.service";
import { ModalDirective } from "ngx-bootstrap/modal";
import { Cluster } from "app/common/enums";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import { AuthenticationService } from "app/services/authentication/authentication.service";
@Component({
  selector: "fh-device-rawdata",
  templateUrl: "rawData.template.html",
})
export class DeviceRawDataViewComponent implements OnInit {
  @ViewChild("autoShownModal") autoShownModal: ModalDirective;

  deviceLog: AuditLog[];
  sub;
  device: Device;
  deviceId;
  loading = false;
  loadingDetails = false;
  selectedFile;
  error: any;
  success: any;
  warning: any;

  files = [];
  fileDetails = [];
  currentPage;
  fileName = "rawData_";

  filter;

  mapping = [{ imei: "", routings: [] }];
  loadingRoutings = false;

  permissionName = "FleetManagement_RawData";

  clusters = [];

  rerunChecked = false;

  public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
  rerunDate;
  minDate: Date;
  maxDate: Date;
  permissions: {};

  constructor(
    private authenticationService: AuthenticationService,
    private messageDistributorService: DeviceRoutingConfigurationService,
    private deviceService: DeviceService,
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService
  ) {
    this.device = null;
    this.deviceLog = [];

    this.permissions = this.authenticationService.permissions;

    this.dpConfig.containerClass = "theme-default"; // or whatever color
    this.dpConfig.dateInputFormat = "lll";
    this.dpConfig.isAnimated = true;
    this.dpConfig.withTimepicker = true;
    this.dpConfig.keepDatepickerOpened = true;

    this.minDate = new Date();
    this.maxDate = new Date();

    this.minDate.setDate(this.minDate.getDate() - 50);
    this.maxDate.setDate(this.maxDate.getDate() - 1);

    this.clusters = Object.keys(Cluster)
      .filter((k) => typeof Cluster[k] === "string")
      .map((n) => ({ value: +n, name: Cluster[n] }))
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  rerun(useBeta = false) {
    this.loadingDetails = true;
    this.deviceService.rerun(this.device, this.rerunDate, useBeta).subscribe(
      (result) => {
        this.error = null;
        this.warning = null;

        if (result.isSuccess) {
          this.success = {
            statusText: "Success",
            success: this.translateService.instant("general.rerunPlanned"),
          };
        } else {
          this.warning = {
            statusText: "warning",
            warning: result.message,
          };
        }

        this.loadingDetails = false;
      },
      (error) => {
        this.loadingDetails = false;
        this.success = null;
        this.warning = null;
        this.error = error;
      }
    );

    this.rerunDate = null;
  }

  ngOnInit() {
    this.loading = true;
    this.device = new Device();
    this.device.id = "";

    this.sub = this.route.params.subscribe((params) => {
      const id = params["id"];

      this.deviceId = id;
      this.deviceService.getDeviceById(id).subscribe(
        (device) => {
          this.device = device;
          this.loading = false;

          this.getDataLogFiles();
          this.getMessageDistributorMapping();

          if (this.device == null) {
            this.router.navigate(["/Devices/Overview"]);
          }
        },
        (error) => {
          this.success = null;
          this.loading = false;
          this.error = error;
        }
      );
    });
  }

  getDataLogFiles() {
    this.loading = true;
    this.deviceService.getFiles(this.device.unitId).subscribe(
      (files) => {
        this.files = files;
        this.loading = false;
      },
      (error) => {
        this.success = null;
        this.loading = false;
        this.error = error;
      }
    );
  }

  fileSelected(file) {
    this.loadingDetails = true;

    this.selectedFile = file;

    this.deviceService.getFileDetails(this.device.unitId, file).subscribe(
      (fileDetails) => {
        this.fileDetails = fileDetails;
        this.loadingDetails = false;
      },
      (error) => {
        this.loadingDetails = false;
        this.success = null;
        this.error = error;
      }
    );
  }

  export() {
    /* generate worksheet */
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.fileDetails);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, this.device.unitId);

    /* save to file */
    XLSX.writeFile(wb, "RawData_" + this.device.unitId + ".csv", { bookType: "csv" });
  }

  exportRaw(file) {
    this.deviceService.getFileDetailsRaw(this.device.unitId, this.selectedFile).subscribe(
      (fileDetails) => {
        this.fileDetails = fileDetails;
        this.loadingDetails = false;
      },
      (error) => {
        this.loadingDetails = false;
        this.success = null;
        this.error = error;
      }
    );
  }

  showMapping() {
    this.autoShownModal.show();
  }

  onHidden() {}

  hideModal() {
    this.resetMapping();
  }

  getMessageDistributorMapping() {
    this.loadingRoutings = true;

    this.messageDistributorService.getMDeviceRoutingConfiguration(this.device.unitId).subscribe(
      (result) => {
        this.mapping = result;

        if (this.mapping.length > 0) {
          this.mapping[0].routings.forEach((routing) => {
            const cluster = this.clusters.find((x) => x.value === routing.destinationCluster);
            if (cluster) {
              cluster.isChecked = true;
            }
          });
        }

        this.loadingRoutings = false;
      },
      (error) => {
        this.loadingRoutings = false;
        this.success = null;
        this.error = error;
      }
    );
  }

  resetMapping() {
    this.autoShownModal.hide();

    this.clusters = Object.keys(Cluster)
      .filter((k) => typeof Cluster[k] === "string")
      .map((n) => ({ value: +n, name: Cluster[n] }))
      .sort((a, b) => a.name.localeCompare(b.name));

    this.getMessageDistributorMapping();
  }

  updateMapping() {
    this.loadingRoutings = true;

    this.messageDistributorService.updateDeviceRouting(this.device.unitId, this.clusters).subscribe(
      (result) => {
        this.error = null;

        this.success = {
          statusText: "Success",
          success: this.translateService.instant("general.saveSuccess"),
        };

        this.resetMapping();
      },
      (error) => {
        this.autoShownModal.hide();
        this.loadingRoutings = false;
        this.success = null;
        this.error = error;
      }
    );
  }
}
