import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable, catchError, map, throwError } from "rxjs";
import { AuthenticationService } from "../authentication/authentication.service";
import { LoggingService } from "../logging/logging.service";
import { Trigger, TriggerRule, WebHookHeader } from "app/models/trigger.model";

@Injectable()
export class TriggerService {
  url = "";
  base_url = "";
  inventoryUrl = "";
  Triggers: Trigger[] = [];

  constructor(
    private http: HttpClient,
    private loggingService: LoggingService,
    private authenticationService: AuthenticationService
  ) {
    this.url = this.authenticationService.getWebserviceURL("trigger");
    this.base_url = this.authenticationService.getWebserviceURL("");
  }

  getPagingUrl() {
    return this.url + `Paging`;
  }

  getTriggers(): Observable<Trigger[]> {
    console.log("getting trigger from service");
    return this.http.get(this.url, { headers: this.authenticationService.headers }).pipe(
      map((data) => {
        return this.parseResponse(data);
      }),
      catchError(this.handleError)
    );
  }

  getTriggerById(id: number): Observable<Trigger> {
    console.log("Fetch trigger by id " + id);
    return this.http.get(this.url + id, { headers: this.authenticationService.headers }).pipe(
      map((data) => {
        return this.parseReponseDetails(data);
      }),
      catchError(this.handleError)
    );
  }

  getTriggerAssetsById(id: number): Observable<any> {
    console.log("Fetch trigger by id " + id);
    return this.http.get(this.url + id + "/Assets", { headers: this.authenticationService.headers }).pipe(
      map((data) => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  getTriggerCountById(id: number, start, end): Observable<any> {
    console.log("Fetch trigger count by id " + id);
    return this.http
      .get(this.url + id + "/Count?start=" + start.unix() + "&end=" + end.unix(), {
        headers: this.authenticationService.headers,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  testTrigger(trigger: Trigger): Observable<any> {
    console.log("Fire test trigger", trigger);

    return this.http
      .post(this.url + "Test", trigger, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  getTriggerByDeviceId(id: number): Observable<Trigger[]> {
    console.log("Fetch trigger by device id " + id);
    return this.http
      .get(this.base_url + "Device/" + id + "/Triggers", { headers: this.authenticationService.headers })
      .pipe(
        map((data) => {
          return this.parseResponse(data);
        }),
        catchError(this.handleError)
      );
  }

  getTriggerByGeofenceGroupId(id: number): Observable<Trigger[]> {
    return this.http
      .get(this.base_url + "GeofenceGroup/" + id + "/Triggers", { headers: this.authenticationService.headers })
      .pipe(
        map((data) => {
          return this.parseResponse(data);
        }),
        catchError(this.handleError)
      );
  }

  saveTrigger(trigger: Trigger): Observable<any> {
    console.log("save trigger", trigger);

    this.refine(trigger);

    return this.http
      .post(this.url, trigger, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  updateTrigger(trigger: Trigger): Observable<any> {
    console.log("update trigger", trigger);

    this.refine(trigger);

    return this.http
      .put(this.url + trigger.id, trigger, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  rerunTrigger(id: number, start, end): Observable<any> {
    return this.http
      .post(
        this.url + "Rerun",
        {
          TriggerId: id,
          Start: start.unix(),
          End: end.unix(),
        },
        { headers: this.authenticationService.headers }
      )
      .pipe(catchError(this.handleError));
  }

  refine(trigger) {
    trigger.rules.forEach((rule) => {
      if (rule.geofenceSource) {
        rule.geofenceGroups = [];
        rule.geofences = [];

        rule.geofenceSource.forEach((element) => {
          if (typeof element === "number") {
            rule.geofences.push(element);
          } else {
            if (rule.geofenceGroups.indexOf(element[0].geofenceGroupId) === -1) {
              rule.geofenceGroups.push(element[0].geofenceGroupId);
            }
          }
        });

        rule.geofenceSource = null;
      }
    });

    if (trigger.geofenceSource) {
      const geofences = [];
      const geofenceGroups = [];

      trigger.geofenceSource.forEach((element) => {
        if (typeof element === "number") {
          // Individual geofences
          geofences.push(element);
        } else if (element.id !== undefined && geofenceGroups.indexOf(element.id.split("_")[1]) === -1) {
          // GeofenceGroups already added to trigger
          geofenceGroups.push(element.id.split("_")[1]);
        } else if (geofenceGroups.indexOf(element[0].geofenceGroupid) === -1) {
          // GeofencesGroups newly added to trigger
          geofenceGroups.push(element[0].geofenceGroupId);
        }
      });

      trigger.geofenceSource = { geofences: geofences, geofenceGroups: geofenceGroups };
    }
  }

  deleteTrigger(trigger: Trigger): Observable<any> {
    console.log("delete trigger", trigger);

    return this.http
      .delete(this.url + trigger.id, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  private handleError(error: Response) {
    console.log(error);
    return throwError(error);
  }

  parseResponse(json: any): Trigger[] {
    this.loggingService.log(this.constructor.name, "Retrieved " + json.length + " Triggers.");

    const ident = 1;
    const triggers: Trigger[] = [];

    json.forEach((item) => {
      const trigger = this.parseReponseDetails(item);
      triggers.push(trigger);
    });

    return triggers;
  }

  parseReponseDetails(item) {
    const trigger = new Trigger();
    trigger.id = item.id;
    trigger.name = item.name;
    trigger.companyName = item.companyName;
    trigger.accountId = item.accountId;
    trigger.resellerId = item.resellerId;
    trigger.resellerDescription = item.resellerDescription;

    trigger.sendOutputMessage = item.sendOutputMessage;
    trigger.sendFmNotification = item.sendFmNotification;
    trigger.sendMobileNotification = item.sendMobileNotification;

    trigger.isEnabled = item.isEnabled;

    trigger.triggerInputSource = item.source;

    (trigger.intervalInSeconds = item.intervalInSeconds),
      (trigger.domainEventInput = item.domainEventInput),
      (trigger.triggerInputSource = item.triggerInputSource),
      // Webhook
      (trigger.useWebHook = item.useWebHook);
    trigger.webHookType = item.webHookType;
    trigger.webHookMethod = item.webHookMethod;
    trigger.webHookContentType = item.webHookContentType;
    trigger.webHookUrl = item.webHookUrl;
    trigger.webhookEndpointId = item.webhookEndpointId;

    trigger.webHookHeaders = [];
    if (item.webHookHeaders?.length) {
      item.webHookHeaders.forEach((webHookHeader) => {
        const newWebHookHeader = new WebHookHeader();
        newWebHookHeader.webHookHeaderName = webHookHeader.webHookHeaderName;
        newWebHookHeader.webHookHeaderProperty = webHookHeader.webHookHeaderProperty;

        trigger.webHookHeaders.push(newWebHookHeader);
      });
    }

    trigger.subjectTemplate = item.subjectTemplate;
    trigger.messageBodyTemplate = item.messageBodyTemplate;
    trigger.json = item.json;
    trigger.priority = item.priority;
    trigger.delayInSeconds = item.delayInSeconds;
    trigger.episodeCount = item.episodeCount;
    trigger.notificationCount = item.notificationCount;
    trigger.webhookEndpointNotificationCount = item.webhookEndpointNotificationCount;

    trigger.episodeType = item.episodeType;
    trigger.storeAsEpisode = item.storeAsEpisode;

    trigger.hasDebounce = item.debounceInSeconds != null && item.debounceInSeconds > 0;
    trigger.debounceInSeconds = item.debounceInSeconds;
    trigger.triggerType = item.triggerType;
    trigger.outputMessageMoment = item.outputMessageMoment;
    trigger.webhookMoment = item.webhookMoment;

    trigger.allGeofences = item.allGeofences;
    // trigger.geofenceSource = item.geofenceSource;
    trigger.geofenceEventType = item.geofenceEventType;

    if (item.geofenceSource) {
      item.geofenceSource.geofences.forEach((geofence) => {
        trigger.geofenceSource.push(geofence);
      });

      item.geofenceSource.geofenceGroups.forEach((geofenceGroup) => {
        trigger.geofenceSource.push({ id: "geofenceGroupId_" + geofenceGroup });
      });
    }

    trigger.assetGroups = [];
    if (item.assetGroups) {
      item.assetGroups.forEach((assetGroupId) => {
        trigger.assetGroups.push(assetGroupId);
      });
    }

    trigger.userContactGroups = [];
    if (item.userContactGroups) {
      item.userContactGroups.forEach((userContactGroupId) => {
        trigger.userContactGroups.push(userContactGroupId);
      });
    }

    trigger.rules = [];
    if (item.rules) {
      item.rules.forEach((rule) => {
        const newRule = new TriggerRule();
        newRule.ruleType = rule.ruleType;
        newRule.triggerDeviceSensor = rule.triggerDeviceSensor;

        // Duration of rule
        newRule.minDuration = rule.minDuration;

        // general
        newRule.threshold = rule.threshold;
        newRule.thresholdCompare = rule.thresholdCompare;
        newRule.ruleProperty = rule.ruleProperty;

        // Event
        newRule.eventType = rule.eventType;

        // Devicestate
        newRule.deviceState = rule.deviceState;

        // IO
        newRule.ioPort = rule.ioPort;
        newRule.ioState = rule.ioState;

        // Geofences
        newRule.geofences = rule.geofences;
        newRule.geofenceGroups = rule.geofenceGroups;
        newRule.allGeofences = rule.allGeofences;

        // Schedule
        newRule.templateId = rule.templateId;

        // Inside/Outside Settings
        newRule.insideOutsideSettings = rule.insideOutsideSettings;

        // Virtual sensorss
        newRule.virtualSensorOutputType = rule.virtualSensorOutputType;

        trigger.rules.push(newRule);
      });
    }
    return trigger;
  }
}
