import { HttpClient } from "@angular/common/http";
import { Component, OnInit, ViewChild, ChangeDetectorRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Device } from "app/models/device.model";
import { DeviceService } from "../../services/device/device.service";
import { TranslateService } from "@ngx-translate/core";

import { AuthenticationService } from "app/services/authentication/authentication.service";
import { TripService } from "app/services/trip/trip.service";
import { AccountService } from "app/services/account/account.service";
import { FhChartService } from "app/services/charts/charts.service";
import { EpisodeDetailsComponent } from "../shared/usercontrols/episodeDetails.component";

// Moment
import Moment from "moment-timezone";

window["moment"] = Moment;

@Component({
  providers: [FhChartService],
  selector: "fh-device-episode-details",
  templateUrl: "episodeDetails.template.html",
})
export class DeviceEpisodeDetailsViewComponent implements OnInit {
  @ViewChild(EpisodeDetailsComponent, { static: false }) episodeDetailsComponent: EpisodeDetailsComponent;

  sub;
  device: Device;
  deviceId;

  loading = false;

  error: any;
  success: any;

  timezoneIana: string;

  limit = 1000;
  token: string;

  languageLoaded = false;
  episodesLoaded = false;

  permissionName = "FleetManagement_Episodes";
  constructorName = "DeviceEpisodesViewComponent";
  loadingMapData = false;
  showWiderMap = false;
  chart: any;
  loadingEpisodeDetails = false;
  trip;
  geoJsonData: any;
  geoJson: any;

  tripFeatureGroup: any;
  activeEpisode: any;
  permissions: {};

  constructor(
    private http: HttpClient,
    private cd: ChangeDetectorRef,
    private chartService: FhChartService,
    private accountService: AccountService,
    private deviceService: DeviceService,
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    private authenticationService: AuthenticationService,
    private tripService: TripService
  ) {
    this.permissions = this.authenticationService.permissions;

    this.device = null;

    this.timezoneIana = authenticationService.getTimeZoneIana();
    this.token = authenticationService.getAuthToken();
  }

  ngOnInit() {
    this.loading = true;
    this.device = new Device();
    this.device.id = "";

    this.sub = this.route.params.subscribe(
      (params) => {
        const id = params["id"];

        this.deviceId = id;
        this.deviceService.getDeviceById(id).subscribe((device) => {
          this.device = device;

          if (this.device == null) {
            this.router.navigate(["/Devices/Overview"]);
          }

          this.fetchEpisode();

          this.loading = false;
        });
      },
      (error) => {
        this.error = error;
        this.error.statusText = "Error fetching device";

        setTimeout(() => {
          this.router.navigate(["/Devices/Overview"]);
        }, 3000);
      }
    );
  }

  fetchEpisode() {
    this.route.queryParams.subscribe((params) => {
      const episodeId = params["episodeId"];
      const episodeIdentifier = params["episodeIdentifier"];

      if (episodeId) {
        this.episodeDetailsComponent.showOnMap({ id: episodeId });
      }
      if (episodeIdentifier) {
        this.episodeDetailsComponent.showOnMap({ episodeIdentifier: episodeIdentifier });
      }
    });
  }
}
