import { HttpClient } from "@angular/common/http";
import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Device } from "app/models/device.model";
import { AuditLog } from "../../models/auditlog.model";
import { DeviceService } from "../../services/device/device.service";
import { DriverChooserComponent } from "../shared/usercontrols/driverChooser.component";
import { AuthenticationService } from "app/services/authentication/authentication.service";
import { AssetService } from "app/services/asset/asset.service";
import { fadeInOnEnterAnimation } from "angular-animations";
import { ConfirmationModalComponent } from "../shared/usercontrols/confirmationModal.component";
import { AssignmentModalComponent } from "../shared/usercontrols/assignmentModal.component";

@Component({
  selector: "fh-device-drivers",
  templateUrl: "drivers.template.html",
  animations: [fadeInOnEnterAnimation({ anchor: "enter" })],
})
export class DeviceDriversViewComponent implements OnInit {
  @ViewChild("driverChooser") driverChooser: DriverChooserComponent;
  @ViewChild("deleteModal") deleteModal: ConfirmationModalComponent;

  @ViewChild("assignmentModal", { static: false }) assignmentModal: ConfirmationModalComponent;
  @ViewChild("assignmentBody", { static: false }) assignmentBody: AssignmentModalComponent;
  selectedDriverAssignment;

  deviceLog: AuditLog[];
  sub;
  device: Device;
  deviceId;

  loading = false;

  error: any;
  success: any;
  warning: any;

  allDriverAssignments = [];

  permissionName = "Drivers_View";
  permissions: {};

  currentPage: number;
  filter: string;
  itemsPerPage = 10;

  constructor(
    private http: HttpClient,
    private assetService: AssetService,
    private authenticationService: AuthenticationService,
    private deviceService: DeviceService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.permissions = this.authenticationService.permissions;

    this.device = null;
    this.deviceLog = [];
  }

  ngOnInit() {
    this.loading = true;

    this.device = new Device();
    this.device.id = "";

    this.sub = this.route.params.subscribe({
      next: (params) => {
        const id = params["id"];

        this.deviceId = id;
        this.deviceService.getDeviceById(id).subscribe((device) => {
          this.device = device;

          if (this.device == null) {
            this.router.navigate(["/Devices/Overview"]);
          }

          if (!this.device.asset?.id) {
            this.warning = {
              statusText: "Warning",
              warning: "There is no asset activated to this device. Drivers can not be configured!",
            };
            this.loading = false;
          } else {
            this.getDriverInfo(id);
          }
        });
      },
      error: (error) => {
        this.error = error;
        this.error.statusText = "Error fetching device";

        setTimeout(() => {
          this.router.navigate(["/Devices/Overview"]);
        }, 3000);
      },
    });
  }

  getDriverInfo(id: any) {
    this.deviceService.getDriversByDeviceId(id).subscribe({
      next: (drivers) => {
        this.allDriverAssignments = drivers;
        this.loading = false;
      },
      error: (error) => {
        this.loading = false;
        this.success = null;
        this.error = error;
      },
    });
  }

  deleteAssignment(assignment) {
    this.deleteModal.hideModal();

    this.loading = true;

    const deleteAssignDriver = {
      AssetId: assignment.assetId,
      AssetDriverAssignmentId: assignment.id,
      DriverAssignmentRecordIdentifier: assignment.driverAssignmentRecordIdentifier,
    };

    this.assetService.deleteDriverAssignment(assignment.assetId, deleteAssignDriver).subscribe({
      next: (task: { error: boolean; isSuccess: boolean; message: any }) => {
        if (task?.isSuccess) {
          this.error = null;

          if (task.message?.startsWith("You can only")) {
            this.success = {
              statusText: "Warning",
              success: task.message,
            };
          } else {
            this.success = {
              statusText: "Success",
              success: task.message,
            };
          }
        } else {
          this.error = task?.message ?? task?.error;
        }
        this.getDriverInfo(this.device.id);
      },
      error: (error) => {
        this.loading = false;
        this.success = null;
        this.error = error;
      },
    });
  }

  showEndAssignment(assignment) {
    this.selectedDriverAssignment = assignment;
    this.assignmentModal.showModal(assignment);
  }

  endAssignment(assignment) {
    this.assignmentModal.hideModal();

    this.loading = true;

    const stopAssignDriver = {
      AssetId: assignment.assetId,
      AssetDriverAssignmentId: assignment.id,
      EndDate: +this.assignmentBody.archiveDate / 1000,
      DriverAssignmentRecordIdentifier: assignment.driverAssignmentRecordIdentifier,
    };

    this.assetService.stopDriverAssignment(assignment.assetId, stopAssignDriver).subscribe({
      next: (task: { error: boolean; isSuccess: boolean; message: any }) => {
        if (task?.isSuccess) {
          this.error = null;

          if (task.message?.startsWith("You can only")) {
            this.success = {
              statusText: "Warning",
              success: task.message,
            };
          } else {
            this.success = {
              statusText: "Success",
              success: task.message,
            };
          }
        } else {
          this.error = task?.message ?? task?.error;
        }
        this.getDriverInfo(this.device.id);
      },
      error: (error) => {
        this.loading = false;
        this.success = null;
        this.error = error;
      },
    });
  }

  onSelect(event): void {
    this.loading = true;

    const assignDriver = {
      DriverId: event.id,
      AssetId: this.device.asset?.id,
      StartDate: +event.from / 1000,
      EndDate: event.to ? +event.to / 1000 : null,
    };

    this.assetService.assignDriver(this.device.asset?.id, assignDriver).subscribe({
      next: (task: { error: boolean; isSuccess: boolean; message: any }) => {
        if (task?.isSuccess) {
          this.error = null;

          if (task.message?.startsWith("You can only")) {
            this.success = {
              statusText: "Warning",
              success: task.message,
            };
          } else {
            this.success = {
              statusText: "Success",
              success: task.message,
            };
          }
        } else {
          this.error = task?.message ?? task?.error;
        }
        this.getDriverInfo(this.device.id);
      },
      error: (error) => {
        this.loading = false;
        this.success = null;
        this.error = error;
      },
    });
  }

  showDelete(assignment) {
    this.deleteModal.showModal(assignment);
  }
}
