<span *ngIf="score != undefined" class="float-right" title="{{ round(score, 2) }} penalty points">
    <b *ngIf="+score < 0.1" style="color: green"><i class="fa fa-solid fa-circle"></i></b>
    <b *ngIf="+score >= 0.1 && +score < 0.5" style="color: greenyellow"><i class="fa fa-solid fa-circle"></i></b>
    <b *ngIf="+score >= 0.5 && +score < 2" style="color: orange"><i class="fa fa-solid fa-circle"></i></b>
    <b *ngIf="+score >= 2 && +score < 5" style="color: orangered"><i class="fa fa-solid fa-circle"></i></b>
    <b *ngIf="+score >= 5" style="color: red"><i class="fa fa-solid fa-circle"></i></b>
</span>

<span *ngIf="totalScore != undefined" class="float-right" title="{{ round(totalScore, 2) }} penalty points">
    <b *ngIf="+totalScore < 2" style="color: green"><i class="fa fa-solid fa-circle"></i></b>
    <b *ngIf="+totalScore >= 2 && +totalScore < 5" style="color: greenyellow"><i class="fa fa-solid fa-circle"></i></b>
    <b *ngIf="+totalScore >= 5 && +totalScore < 10" style="color: orange"><i class="fa fa-solid fa-circle"></i></b>
    <b *ngIf="+totalScore >= 10 && +totalScore < 15" style="color: orangered"><i class="fa fa-solid fa-circle"></i></b>
    <b *ngIf="+totalScore >= 15" style="color: red"><i class="fa fa-solid fa-circle"></i></b>
</span>
