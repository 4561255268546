import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable, catchError, map } from "rxjs";
import { AuthenticationService } from "../authentication/authentication.service";
import { LoggingService } from "../logging/logging.service";

// Moment timezone
import Moment from "moment-timezone";
import { throwError } from "rxjs/internal/observable/throwError";
window["moment"] = Moment;

@Injectable()
export class StateService {
  url = "";
  base_url = "";
  inventoryUrl = "";
  timezoneIana: string;

  constructor(
    private http: HttpClient,
    private loggingService: LoggingService,
    private authenticationService: AuthenticationService
  ) {
    this.url = this.authenticationService.getWebserviceURL("componentState");
    this.base_url = this.authenticationService.getWebserviceURL("");
    this.timezoneIana = this.authenticationService.getTimeZoneIana();
  }

  getState(component, deviceId): Observable<any> {
    console.log("getting componentstate from service");
    const deviceIdParam = deviceId != null ? deviceId : "";
    return this.http
      .get(this.url + "?component=" + component + "&deviceId=" + deviceIdParam, {
        headers: this.authenticationService.headers,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  private handleError(error: Response) {
    return throwError(error);
  }
}
