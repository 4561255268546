import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable, catchError, map, of } from "rxjs";
import { AuthenticationService } from "../authentication/authentication.service";
import { LoggingService } from "../logging/logging.service";
import { Reseller } from "app/models/reseller.model";
import { throwError } from "rxjs/internal/observable/throwError";

@Injectable()
export class ResellerService {
  url = "";
  base_url = "";
  inventoryUrl = "";
  resellers: Reseller[] = [];

  constructor(
    private http: HttpClient,
    private loggingService: LoggingService,
    private authenticationService: AuthenticationService
  ) {
    this.url = this.authenticationService.getWebserviceURL("reseller");
  }

  getPagingUrl() {
    return this.url + "Paging";
  }

  getResellers(setDefinate = true): Observable<Reseller[]> {
    if (setDefinate && this.resellers.length !== 0) {
      console.log("Returning resellers from cache.");
      return of(this.resellers);
    }

    console.log("getting resellers from service");
    return this.http.get(this.url, { headers: this.authenticationService.headers }).pipe(
      map((data) => {
        this.resellers = this.parseResponse(data);
        return this.resellers;
      }),
      catchError(this.handleError)
    );
  }

  getResellerById(id: string): Observable<Reseller> {
    console.log("Fetch reseller by id " + id);
    return this.http.get(this.url + id, { headers: this.authenticationService.headers }).pipe(
      map((data) => {
        return this.parseReponseDetails(data);
      }),
      catchError(this.handleError)
    );
  }

  getResellersByParentId(id: string): Observable<Reseller[]> {
    console.log("getting reseller from service");
    return this.http.get(this.url + id + "/children", { headers: this.authenticationService.headers }).pipe(
      map((data) => {
        return this.parseResponse(data);
      }),
      catchError(this.handleError)
    );
  }

  getKPISCount(id: string): Observable<any> {
    return this.http.get(this.url + id + "/dashboard", { headers: this.authenticationService.headers }).pipe(
      map((data) => {
        const parsedResponse = data;
        return parsedResponse;
      }),
      catchError(this.handleError)
    );
  }

  getDevicesAdded(id: string): Observable<any> {
    return this.http.get(this.url + id + "/added", { headers: this.authenticationService.headers }).pipe(
      map((data: any) => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  saveReseller(reseller: Reseller): Observable<any> {
    console.log("save reseller", reseller);

    return this.http
      .post(this.url, reseller, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  updateReseller(reseller: Reseller): Observable<any> {
    console.log("update reseller", reseller);

    return this.http
      .put(this.url + reseller.id, reseller, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  private handleError(error: Response) {
    return throwError(error);
  }

  parseResponse(json: any): Reseller[] {
    this.loggingService.log(this.constructor.name, "Retrieved " + json.length + " Resellers.");

    const ident = 1;
    const resellers: Reseller[] = [];

    json.forEach((item) => {
      const reseller = this.parseReponseDetails(item);
      resellers.push(reseller);
    });

    return resellers;
  }

  parseReponseDetails(item) {
    const reseller = new Reseller();
    reseller.id = item.id;
    reseller.clientCount = item.clientCount;
    reseller.deviceCount = item.deviceCount;
    reseller.deviceRecursiveCount = item.deviceRecursiveCount;
    reseller.resellerDescription = item.resellerDescription;
    reseller.resellerAuthorizationCode = item.resellerAuthorizationCode;
    reseller.resellerEmail = item.resellerEmail;
    reseller.resellerTechnicalContactEmail = item.resellerTechnicalContactEmail;
    reseller.parentResellerId = item.parentResellerId;
    reseller.isNotSelectable = item.isNotSelectable;
    reseller.parentDescription = item.parentDescription;
    reseller.defaultAssetIconId = item.defaultAssetIconId;

    return reseller;
  }
}
