<div class="ibox">
    <div class="ibox-title">
        <h5><i class="fa fa-fw fa-file-alt"></i> {{ "general.log" | translate }}</h5>
        <span class="float-right">
            <div class="filter-form d-none d-md-block">
                <input
                    type="text"
                    class="form-control table-inline top-filter d-none d-md-block"
                    (ngModelChange)="p.setCurrent(0)"
                    [(ngModel)]="filter"
                />
            </div>
        </span>
        <span
            class="float-right"
            *ngIf="permissions['Platform_IsReseller']"
            style="padding-right: 20px"
            tooltip="Show JSON for log"
        >
            <i
                class="fas fa-2x fa-bug"
                style="font-size: 1.3em"
                [ngStyle]="{ color: showJson ? '#ccc' : '#555' }"
                (click)="showJson = !showJson"
            ></i>
        </span>
    </div>
    <div class="ibox-content" style="position: relative" *ngIf="logItems.length === 0">
        <ngx-loading [show]="loading"></ngx-loading>
        {{ "general.nodatafound" | translate }}
    </div>
    <ng-container
        *ngFor="
            let log of logItems
                | orderBy : 'timestamp' : true
                | stringFilter : filter
                | paginate : { itemsPerPage: 10, currentPage: currentPage }
        "
    >
        <div class="ibox-title log-header">
            <i class="fa fa-location-arrow logtype" [ngClass]="'logtype_' + log.logtype"></i>
            {{ "enums.logType." + log.logType | translate }}

            <span class="date" *ngIf="log.userId && log.userId > 0">
                {{ "general.by" | translate }}
                <a class="secondary link_bolder" href="/#/UserDetails/Index/{{ log.userId }}">{{ log.user }}</a>
            </span>
            <span class="date" *ngIf="log.timestamp" title="{{ log.timestamp?.toLocaleString() }}">
                {{ "general.on" | translate }}
                {{ log.timestamp | amDateFormat : "lll" }}</span
            >
        </div>
        <div class="ibox-content slick">
            <div class="log-content">
                <div style="padding: 10px" *ngIf="log.message !== undefined">
                    {{ "general.message" | translate }} : {{ log.message }}
                </div>
                <div style="padding: 10px" *ngIf="log.location !== undefined">
                    {{ "general.location" | translate }} : {{ log.location }}
                </div>
                <div style="padding: 10px" *ngIf="log.description !== undefined">
                    {{ log.description }}
                </div>
                <div *ngIf="showJson && log.deltaItems != null" class="ibox-content slick" style="position: relative">
                    <pretty-json [obj]="log.deltaItems"></pretty-json>
                </div>
                <div *ngIf="!showJson && log.deltaItems != null && log.deltaItems.Differences != null">
                    <table style="width: 100%" class="table table-fixed nowrap dtr-inline no-footer">
                        <thead>
                            <tr>
                                <th style="width: 33%">
                                    {{ "general.item" | translate }}
                                </th>
                                <th style="width: 33%">
                                    {{ "general.from" | translate }}
                                </th>
                                <th style="width: 33%">
                                    {{ "general.to" | translate }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of log.deltaItems.Differences; let i = index">
                                <td style="width: 33%">
                                    {{ item.PropertyName }}
                                </td>
                                <td style="width: 33%" class="elipses_overflow" title="{{ item.OldValue | json }}">
                                    {{ item.OldValue | json }}
                                </td>
                                <td style="width: 33%" class="elipses_overflow" title="{{ item.NewValue | json }}">
                                    {{ item.NewValue | json }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div *ngIf="!showJson && log.deltaItems != null && log.deltaItems.length > 0">
                    <table style="width: 100%" class="table table-fixed nowrap dtr-inline no-footer">
                        <thead>
                            <tr>
                                <th style="width: 33%">
                                    {{ "general.item" | translate }}
                                </th>
                                <th style="width: 33%">
                                    {{ "general.from" | translate }}
                                </th>
                                <th style="width: 33%">
                                    {{ "general.to" | translate }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of log.deltaItems; let i = index">
                                <td style="width: 33%">
                                    {{ item.Item }}
                                </td>
                                <td style="width: 33%" class="elipses_overflow" title="{{ item.From | json }}">
                                    {{ item.From }}
                                </td>
                                <td style="width: 33%" class="elipses_overflow" title="{{ item.To | json }}">
                                    {{ item.To }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div *ngIf="log.stack !== undefined">
                    <span class="info">{{ log.stack }}</span>
                </div>
            </div>
        </div>
    </ng-container>
    <div class="ibox-footer noselect dataTables_wrapper">
        <pagination-template #p="paginationApi" (pageChange)="currentPage = $event">
            <div class="dataTables_paginate paging_simple_numbers" id="dataTable_paginate">
                <ul class="pagination">
                    <li class="paginate_button page-item previous" [class.disabled]="p.isFirstPage()">
                        <a class="page-link" (click)="p.previous()" *ngIf="!p.isFirstPage()">{{
                            "grid.previous" | translate
                        }}</a>
                        <a *ngIf="p.isFirstPage()">{{ "grid.previous" | translate }}</a>
                    </li>

                    <li
                        class="paginate_button page-item"
                        *ngFor="let page of p.pages"
                        [class.current]="p.getCurrent() === page.value"
                    >
                        <a class="page-link" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
                            <span>{{ page.label }}</span>
                        </a>
                        <a class="page-link" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() === page.value">
                            <span style="font-weight: bold">{{ page.label }}</span>
                        </a>
                    </li>

                    <li class="paginate_button page-item next" [class.disabled]="p.isLastPage()">
                        <a class="page-link" (click)="p.next()" *ngIf="!p.isLastPage()">
                            {{ "grid.next" | translate }}
                        </a>
                        <a class="page-link" *ngIf="p.isLastPage()"> {{ "grid.next" | translate }} </a>
                    </li>
                </ul>
            </div>
        </pagination-template>
        <div style="clear: both"></div>
    </div>
</div>
