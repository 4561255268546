import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormMode } from "app/common/enums";
import { AuthenticationService } from "app/services/authentication/authentication.service";
import { roundAsNumber, roundSeconds } from "app/common/globals";
import { ReportService } from "app/services/reports/report.service";
import { FhChartService } from "app/services/charts/charts.service";
import { TranslateService } from "@ngx-translate/core";
import { ConfirmationModalComponent } from "../shared/usercontrols/confirmationModal.component";
import { CronOptions } from "app/modules/cron-editor/CronOptions";
import { RerunReportModalComponent } from "../shared/usercontrols/rerunReportModal.component";
import { RerunReportSettings } from "app/models/reporting.model";

// Moment
import Moment from "moment-timezone";
import { SignalrService } from "app/services/signalr/signalr.service";

window["moment"] = Moment;

@Component({
  selector: "fh-subsciption-details",
  templateUrl: "subscriptionDetails.template.html",
  providers: [FhChartService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscriptionDetailsViewComponent implements OnInit, OnDestroy {
  @ViewChild("deleteModal", { static: false }) deleteModal: ConfirmationModalComponent;
  @ViewChild("archiveModal", { static: false }) archiveModal: ConfirmationModalComponent;
  @ViewChild("unarchiveModal", { static: false }) unarchiveModal: ConfirmationModalComponent;
  @ViewChild("rerunModal", { static: false }) rerunModal: ConfirmationModalComponent;
  @ViewChild("rerunBody", { static: false }) rerunBody: RerunReportModalComponent;

  loading: boolean;
  sub: any;

  reportSubscription;

  loadingExecutions = false;
  executions = [];

  showJson = false;

  formMode = FormMode.read;

  success;
  error;

  filter;

  // Pagination
  currentPage = 1;

  permissionName = "FleetManagement_Reporting";

  timezoneIana: string;
  reportData: any;

  permissions = {};

  // Rerun
  daterangepickerModel: Date[];

  // Edit

  step = 1;

  useCustomSubject = true;
  sendOutputMessage = true;
  defaultSourceColumns = [];

  public isCronDisabled = false;
  public cronOptions: CronOptions = {
    formInputClass: "cron-editor-input table-inline",
    formSelectClass: "cron-editor-select table-inline",
    formRadioClass: "cron-editor-radio",
    formCheckboxClass: "cron-editor-checkbox",

    defaultTime: "10:00:00",
    use24HourTime: true,

    hideMinutesTab: true,
    hideHourlyTab: true,
    hideDailyTab: false,
    hideWeeklyTab: false,
    hideMonthlyTab: false,
    hideYearlyTab: false,
    hideAdvancedTab: true,

    hideSeconds: true,
    removeSeconds: true,
    removeYears: true,
  };

  hubToastSubscription: any;

  constructor(
    private router: Router,
    private signalrService: SignalrService,
    private cd: ChangeDetectorRef,
    private translate: TranslateService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private reportService: ReportService
  ) {
    this.permissions = this.authenticationService.permissions;
    this.timezoneIana = this.authenticationService.getTimeZoneIana();
  }

  onCheckOutFinish() {}

  setFormMode(mode) {
    this.formMode = mode;
  }

  onSave() {}

  ngOnDestroy(): void {
    if (this.hubToastSubscription !== undefined) {
      this.hubToastSubscription.unsubscribe();
    }
  }

  checkForSubscription(id) {
    if (this.hubToastSubscription !== undefined) {
      this.hubToastSubscription.unsubscribe();
    }

    this.hubToastSubscription = this.signalrService.hubToast.subscribe((message) => {
      if (message && message[3]) {
        var subscriptionId = message[3];

        if (subscriptionId && subscriptionId == id) {
          this.getReportData(id);
        }
      }
    });
  }

  getDefaultColumns() {
    this.reportService.getReportColumnsByReportType(this.reportSubscription.reportType).subscribe((result) => {
      this.defaultSourceColumns = result;
    });
  }

  actualRound(value, decimals) {
    return roundAsNumber(value, decimals);
  }

  actualRoundSeconds(value) {
    return roundSeconds(value);
  }

  ngOnInit() {
    this.loading = true;
    this.loadingExecutions = true;

    this.sub = this.route.params.subscribe({
      next: (params) => {
        const id = params["id"];

        this.checkForSubscription(id);

        this.getReportData(id);
      },
      error: (error) => {
        this.success = null;
        this.error = error;
        this.loading = false;
        this.loadingExecutions = false;
        this.cd.markForCheck();
      },
    });
  }

  getReportData(id: any) {
    this.loading = true;
    this.loadingExecutions = true;

    this.reportService.getReportSubscriptionById(id).subscribe((res) => {
      if (res == null) {
        this.success = null;
        this.error = {
          statusText: "Error",
          error: "Report is not found.",
        };

        setTimeout(() => {
          this.router.navigate(["/Reporting/Executions"]);
        }, 3000);
      }

      this.reportSubscription = res;

      this.reportSubscription.createdDate = Moment.utc(this.reportSubscription.createdDate)["tz"](this.timezoneIana);
      this.reportSubscription.maxExecutionCompleted = this.reportSubscription.maxExecutionCompletedTimestamp
        ? Moment.utc(this.reportSubscription.maxExecutionCompletedTimestamp)["tz"](this.timezoneIana)
        : null;

      this.getDefaultColumns();

      this.loading = false;
      this.cd.markForCheck();
    });

    this.reportService.getReportSubscriptionExecutionsById(id).subscribe((res) => {
      this.executions = res;

      this.executions.forEach((execution) => {
        execution.maxExecutionCompletedTimestamp = Moment.utc(execution.maxExecutionCompletedTimestamp)["tz"](
          this.timezoneIana
        );
        execution.periodStart = Moment.utc(execution.periodStart)["tz"](this.timezoneIana);
        execution.periodEnd = Moment.utc(execution.periodEnd)["tz"](this.timezoneIana);
      });

      this.loadingExecutions = false;
      this.cd.markForCheck();
    });
  }

  onDelete(event) {
    this.loading = true;

    this.reportService.deleteReportSubscriptionById(this.reportSubscription).subscribe({
      next: (result) => {
        this.error = null;
        this.success = {
          statusText: "Success",
          success: "Report is successfully deleted.",
        };

        setTimeout(() => {
          this.router.navigate(["/Reporting/Executions"]);
        }, 3000);
      },
      error: (error) => {
        this.success = null;
        this.error = error;
        this.loading = false;
        this.cd.markForCheck();
      },
    });
  }

  showArchive() {
    this.archiveModal.showModal(this.reportSubscription);
  }

  archiveSubcription(event) {
    this.archiveModal.hideModal();
    this.reportSubscription.isArchived = true;

    this.reportService.archiveReportSubscription(this.reportSubscription.id, this.reportSubscription).subscribe({
      next: (_) => {
        this.error = null;
        this.success = null;
        this.success = {
          statusText: "Success",
          success: this.translate.instant("general.reportSubscriptionArchived"),
        };
        this.cd.markForCheck();
      },
      error: (error) => {
        this.success = null;
        this.error = error;
      },
    });
  }

  unarchiveSubscription(event) {
    this.unarchiveModal.hideModal();
    this.reportSubscription.isArchived = false;

    this.reportService.archiveReportSubscription(this.reportSubscription.id, this.reportSubscription).subscribe({
      next: (_) => {
        this.error = null;
        this.success = null;
        this.success = {
          statusText: "Success",
          success: this.translate.instant("general.reportSubscriptionUnarchived"),
        };
        this.cd.markForCheck();
      },
      error: (error) => {
        this.success = null;
        this.error = error;
      },
    });
  }

  showUnarchive() {
    this.unarchiveModal.showModal(this.reportSubscription.id);
  }

  showRerun() {
    this.rerunModal.showModal(this.reportSubscription.id);
  }

  rerunReport(event) {
    this.loading = true;
    this.rerunModal.hideModal();

    const data = new RerunReportSettings();
    data.reportSubscriptionId = event;
    data.start = Moment(this.rerunBody.daterangepickerModel[0]).tz(this.timezoneIana).unix();
    data.end = Moment(this.rerunBody.daterangepickerModel[1]).tz(this.timezoneIana).unix();
    data.includeExecutedSql = this.rerunBody.includeExecutedSql;

    this.reportService.rerunReport(data).subscribe({
      next: (result) => {
        this.error = null;
        this.success = {
          statusText: "Success",
          success: "Rerun request is successfully processed.",
        };
        this.loading = false;
        this.cd.markForCheck();
      },
      error: (error) => {
        this.success = null;
        this.error = error;
        this.loading = false;
        this.cd.markForCheck();
      },
    });
  }
}
