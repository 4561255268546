import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { UserContactGroup } from "app/models/userContactGroup.model";

import { throwError } from "rxjs/internal/observable/throwError";
import { Observable, catchError, map } from "rxjs";
import { AuthenticationService } from "../authentication/authentication.service";
import { LoggingService } from "../logging/logging.service";

@Injectable()
export class UserContactGroupService {
  token: string;
  timestamp;
  url = "";

  constructor(
    private http: HttpClient,
    private loggingService: LoggingService,
    private authenticationService: AuthenticationService
  ) {
    this.url = this.authenticationService.getWebserviceURL("userContactGroup");
  }

  getPagingUrl() {
    return this.url + "Paging";
  }

  getUserContactGroups(accountId?: number, includingItems = false): Observable<UserContactGroup[]> {
    console.log("getting userContactGroups from service");
    return this.http
      .get(this.url + (accountId ? `?accountId=${accountId}&includingItems=${includingItems}` : ""), {
        headers: this.authenticationService.headers,
      })
      .pipe(
        map((data) => this.parseResponse(data)),
        catchError(this.handleError)
      );
  }

  getUserContactGroupById(id: string): Observable<UserContactGroup> {
    return this.http.get(this.url + id, { headers: this.authenticationService.headers }).pipe(
      map((data) => this.parseReponseDetails(data)),
      catchError(this.handleError)
    );
  }

  saveUserContactGroup(userContactGroup: UserContactGroup): Observable<any> {
    console.log("save userContactGroup");
    return this.http
      .post(this.url, userContactGroup, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  updateUserContactGroup(userContactGroup: UserContactGroup): Observable<any> {
    console.log("update userContactGroup");
    return this.http
      .put(this.url + userContactGroup.id, userContactGroup, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  deleteUserContactGroup(userContactGroup: UserContactGroup): Observable<any> {
    console.log("delete userContactGroup");
    return this.http
      .delete(this.url + userContactGroup.id, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  parseResponse(json: any): UserContactGroup[] {
    this.loggingService.log(this.constructor.name, "Retrieved " + json.length + " UserContactGroups.");

    const ident = 1;
    const assets: UserContactGroup[] = [];

    json.forEach((item) => {
      const asset = this.parseReponseDetails(item);
      assets.push(asset);
    });

    this.timestamp = new Date().getTime();
    return assets;
  }

  parseReponseDetails(item) {
    //   this.loggingService.log(this.constructor().name + " - New", item);
    const userContactGroup = new UserContactGroup();
    userContactGroup.id = item.id;
    userContactGroup.name = item.name;
    userContactGroup.isSystemGroup = item.isSystemGroup;
    userContactGroup.accountId = item.accountId;
    userContactGroup.companyName = item.companyName;
    userContactGroup.items = item.items;
    userContactGroup.resellerId = item.resellerId;
    userContactGroup.resellerDescription = item.resellerDescription;
    userContactGroup.userContactCount = item.userContactCount;
    userContactGroup.userContactItems = [];

    if (item.userContactItems) {
      userContactGroup.userContactItems = [];

      item.userContactItems.forEach((userContact) => {
        const userContactItem = {
          id: userContact.id,
          driverName: userContact.driverName,
          email: userContact.email,
          firstName: userContact.firstName,
          lastName: userContact.lastName,
          userContactGroupId: userContact.userContactGroupId,
          userContactType: userContact.userContactType,
          msisdn: userContact.msisdn?.trim() || null,
          phoneEnabled: userContact.phoneEnabled,
        };

        userContactGroup.userContactItems.push(userContactItem);
      });

      userContactGroup.userContactCount = userContactGroup.userContactItems.filter((x) => x.email != undefined).length;
    }
    return userContactGroup;
  }

  private handleError(error: Response) {
    return throwError(() => error);
  }
}
