import { CommonModule } from "@angular/common";
import { InjectionToken, ModuleWithProviders, NgModule } from "@angular/core";
import { ICheckComponent } from "./components/icheck.component";
import { ICheckConfig, ICheckConfigArgs } from "./icheck.config";
import { ICheckRadioService } from "./services/icheck-radio.service";

const ICheckOptions = new InjectionToken<ICheckConfigArgs>("config");

export function factoryICheckConfig(config: ICheckConfigArgs) {
  return new ICheckConfig(config);
}

@NgModule({
  imports: [CommonModule],
  declarations: [ICheckComponent],
  exports: [ICheckComponent],
  providers: [ICheckRadioService],
})
export class ICheckModule {
  static forRoot(config: ICheckConfigArgs = {}): ModuleWithProviders<ICheckModule> {
    return {
      ngModule: ICheckModule,
      providers: [
        { provide: ICheckOptions, useValue: config },
        { provide: ICheckConfig, useFactory: factoryICheckConfig, deps: [ICheckOptions] },
      ],
    };
  }
}
