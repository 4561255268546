<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/"> <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Reporting">{{'general.reporting' | translate }}</a>
                </li>
                <li class="breadcrumb-item active">
                    <a href="/#/Reporting/Executions">{{'menu.overview' | translate }}</a>
                </li>
            </ol>
        </nav>
    </div>
    <fh-reporting-navigation>
        <li *ngIf="permissions['FleetManagementReporting_Add']">
            <a [routerLink]="['/Reporting/Create']">
                <i class="fa fa-fw fa-user-chart"></i>
                <div>{{'general.createReport' | translate }}</div>
            </a>
        </li>
    </fh-reporting-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">
        <div class="row">
            <div class="col-lg-12">
                <fh-error [success]="success" [error]="error"></fh-error>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-7">
                <div class="ibox">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-file-chart-pie"></i> {{'general.reportUsage' | translate }}</h5>
                    </div>
                    <div class="ibox-content slicker" style="min-height: 210px; position: relative">
                        <ngx-loading [show]="loadingReportUsage"></ngx-loading>

                        <highcharts-chart
                            *ngIf="chartReportUsage"
                            [runOutsideAngular]="true"
                            style="display: block; width: 100%; height: 200px"
                            [Highcharts]="Highcharts"
                            [options]="chartReportUsage"
                        >
                        </highcharts-chart>
                    </div>
                </div>

                <div class="ibox">
                    <div class="ibox-title">
                        <h5><i class="fa fa-fw fa-clock"></i> {{'general.scheduledReports' | translate }}</h5>
                        <span class="float-right">
                            <span class="pointer" (click)="calenderEditMode = !calenderEditMode">
                                {{ viewDate | calendarDate:(view + 'ViewTitle'):locale:weekStartsOn }}
                                <i class="fa fa-fw fa-calendar"></i
                            ></span>
                        </span>
                    </div>
                    <div class="ibox-content" *ngIf="calenderEditMode">
                        <div style="float: left; width: 50%">
                            <div class="btn-group">
                                <div
                                    class="btn btn-secondary"
                                    mwlCalendarPreviousView
                                    [view]="view"
                                    [(viewDate)]="viewDate"
                                    (viewDateChange)="closeOpenMonthViewDay()"
                                >
                                    <i class="fa fa-fw fa-arrow-left"></i> {{ 'general.previous' | translate }}
                                </div>
                                <div class="btn btn-secondary" mwlCalendarToday [(viewDate)]="viewDate">
                                    {{ 'general.today' | translate }}
                                </div>
                                <div
                                    class="btn btn-secondary"
                                    mwlCalendarNextView
                                    [view]="view"
                                    [(viewDate)]="viewDate"
                                    (viewDateChange)="closeOpenMonthViewDay()"
                                >
                                    <i class="fa fa-fw fa-arrow-right"></i> {{ 'general.next' | translate }}
                                </div>
                            </div>
                        </div>

                        <div style="width: 50%; float: left">
                            <div class="btn-group" style="float: right">
                                <div
                                    class="btn btn-secondary"
                                    (click)="setView(CalendarView.Month)"
                                    [class.active]="view === CalendarView.Month"
                                >
                                    <i class="fa fa-fw fa-calendar-days"></i> {{ 'general.month' | translate }}
                                </div>
                                <div
                                    class="btn btn-secondary"
                                    (click)="setView(CalendarView.Week)"
                                    [class.active]="view === CalendarView.Week"
                                >
                                    <i class="fa fa-fw fa-calendar-week"></i> {{ 'general.week' | translate }}
                                </div>
                                <div
                                    class="btn btn-secondary"
                                    (click)="setView(CalendarView.Day)"
                                    [class.active]="view === CalendarView.Day"
                                >
                                    <i class="fa fa-fw fa-calendar"></i> {{ 'general.day' | translate }}
                                </div>
                            </div>
                        </div>
                        <div style="clear: both"></div>
                    </div>
                    <div class="ibox-content slick" style="position: relative; overflow-y: auto">
                        <ngx-loading [show]="loadingScheduledReports"></ngx-loading>

                        <div [ngSwitch]="view">
                            <mwl-calendar-month-view
                                *ngSwitchCase="CalendarView.Month"
                                [viewDate]="viewDate"
                                [events]="events"
                                [refresh]="refresh"
                                [activeDayIsOpen]="activeDayIsOpen"
                                (dayClicked)="dayClicked($event.day)"
                                (eventClicked)="handleEvent('Clicked', $event.event)"
                                [locale]="locale"
                                [weekStartsOn]="weekStartsOn"
                                (eventTimesChanged)="eventTimesChanged($event)"
                            >
                            </mwl-calendar-month-view>
                            <mwl-calendar-week-view
                                *ngSwitchCase="CalendarView.Week"
                                [viewDate]="viewDate"
                                [events]="events"
                                [refresh]="refresh"
                                (eventClicked)="handleEvent('Clicked', $event.event)"
                                [locale]="locale"
                                [weekStartsOn]="weekStartsOn"
                                (eventTimesChanged)="eventTimesChanged($event)"
                            >
                            </mwl-calendar-week-view>
                            <mwl-calendar-day-view
                                *ngSwitchCase="CalendarView.Day"
                                [viewDate]="viewDate"
                                [events]="events"
                                [refresh]="refresh"
                                (eventClicked)="handleEvent('Clicked', $event.event)"
                                [locale]="locale"
                                (eventTimesChanged)="eventTimesChanged($event)"
                            >
                            </mwl-calendar-day-view>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-5">
                <div class="ibox">
                    <div class="ibox-title">
                        <h5><i class="fa fa-fw fa-file-chart-pie"></i> {{'general.latestReports' | translate }}</h5>

                        <span class="float-right">
                            <div class="filter-form d-none d-md-block">
                                <input
                                    type="text"
                                    class="form-control table-inline top-filter d-none d-md-block"
                                    (ngModelChange)="p.setCurrent(0)"
                                    [(ngModel)]="filter"
                                />
                            </div>
                        </span>
                    </div>
                    <div class="ibox-content slick" style="position: relative; overflow-y: auto">
                        <ngx-loading [show]="loadingRecentReports"></ngx-loading>

                        <table style="width: 100%" class="table nowrap dtr-inline no-footer">
                            <thead>
                                <tr>
                                    <th>{{'general.date' | translate }}</th>
                                    <th>{{'general.name' | translate }}</th>
                                    <th *ngIf="permissions['Platform_IsReseller']">
                                        {{'general.company' | translate }}
                                    </th>
                                    <th *ngIf="permissions['Platform_IsReseller']">{{'general.user' | translate }}</th>
                                    <th *ngIf="permissions['Platform_IsReseller']" style="text-align: right">
                                        {{'general.duration' | translate }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngIf="recentReports?.length == 0">
                                    <td colspan="4">{{'general.noDataFound' | translate }}</td>
                                </tr>
                                <tr
                                    *ngFor="let report of recentReports | orderBy : 'executionCompletedTimestamp':true | stringFilter: filter | paginate: { itemsPerPage: 10, currentPage: currentPage }; let i = index"
                                >
                                    <td>{{ report.executionCompletedTimestamp | amDateFormat: 'lll'}}</td>
                                    <td>
                                        <a
                                            class="secondary link_bolder hand"
                                            routerLink="/ReportSubscriptionDetails/Index/{{report.reportSubscriptionId}}"
                                        >
                                            {{ report.displayName }}</a
                                        >
                                    </td>
                                    <td *ngIf="permissions['Platform_IsReseller']">{{ report.companyName}}</td>
                                    <td *ngIf="permissions['Platform_IsReseller']">{{ report.emailLoginName}}</td>
                                    <td *ngIf="permissions['Platform_IsReseller']" style="text-align: right">
                                        {{ report.sumExecutionDurationInSeconds | amDuration:'seconds' }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="ibox-footer noselect dataTables_wrapper">
                        <pagination-template #p="paginationApi" (pageChange)="currentPage = $event">
                            <div class="dataTables_paginate paging_simple_numbers" id="dataTable_paginate">
                                <ul class="pagination">
                                    <li class="paginate_button page-item previous" [class.disabled]="p.isFirstPage()">
                                        <a class="page-link" (click)="p.previous()" *ngIf="!p.isFirstPage()"
                                            >{{ 'grid.previous' | translate }}</a
                                        >
                                        <a *ngIf="p.isFirstPage()">{{ 'grid.previous' | translate }}</a>
                                    </li>

                                    <li
                                        class="paginate_button page-item"
                                        *ngFor="let page of p.pages"
                                        [class.current]="p.getCurrent() === page.value"
                                    >
                                        <a
                                            class="page-link"
                                            (click)="p.setCurrent(page.value)"
                                            *ngIf="p.getCurrent() !== page.value"
                                        >
                                            <span>{{ page.label }}</span>
                                        </a>
                                        <a
                                            class="page-link"
                                            (click)="p.setCurrent(page.value)"
                                            *ngIf="p.getCurrent() === page.value"
                                        >
                                            <span style="font-weight: bold">{{ page.label }}</span>
                                        </a>
                                    </li>

                                    <li class="paginate_button page-item next" [class.disabled]="p.isLastPage()">
                                        <a class="page-link" (click)="p.next()" *ngIf="!p.isLastPage()">
                                            {{ 'grid.next' | translate }}
                                        </a>
                                        <a class="page-link" *ngIf="p.isLastPage()"> {{ 'grid.next' | translate }} </a>
                                    </li>
                                </ul>
                            </div>
                        </pagination-template>
                        <div style="clear: both"></div>
                    </div>
                </div>

                <div class="ibox">
                    <div class="ibox-title">
                        <h5><i class="fa fa-fw fa-dollar"></i> {{'general.expensiveReports' | translate }}</h5>
                    </div>
                    <div class="ibox-content slick" style="position: relative; overflow-y: auto">
                        <ngx-loading [show]="loadingExpensiveReports"></ngx-loading>

                        <table style="width: 100%" class="table nowrap dtr-inline no-footer">
                            <thead>
                                <tr>
                                    <th>{{'general.name' | translate }}</th>
                                    <th *ngIf="permissions['Platform_IsReseller']">
                                        {{'general.company' | translate }}
                                    </th>
                                    <th *ngIf="permissions['Platform_IsReseller']">{{'general.user' | translate }}</th>
                                    <th style="text-align: right">{{'general.count' | translate }}</th>
                                    <th style="text-align: right">{{'general.duration' | translate }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngIf="expensiveReports?.length == 0">
                                    <td colspan="4">{{'general.noDataFound' | translate }}</td>
                                </tr>
                                <tr *ngFor="let report of expensiveReports; let i = index">
                                    <td>
                                        <a
                                            class="secondary link_bolder hand"
                                            routerLink="/ReportSubscriptionDetails/Index/{{report.reportSubscriptionId}}"
                                        >
                                            {{ report.name || (('enums.reportType.' + report.selectedReportType) |
                                            translate) }}</a
                                        >
                                    </td>
                                    <td *ngIf="permissions['Platform_IsReseller']">{{ report.companyName }}</td>
                                    <td *ngIf="permissions['Platform_IsReseller']">{{ report.emailLoginName}}</td>
                                    <td style="text-align: right">{{ report.executionCount}}</td>
                                    <td style="text-align: right">
                                        {{ report.sumExecutionDurationInSeconds | amDuration:'seconds' }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</fh-permission-handler>
