<div class="ibox-content slick">
    <table style="width: 100%" class="table table-fixed nowrap dtr-inline no-footer">
        <tbody *ngIf="!sensorType">
            <tr>
                <td colspan="4">
                    {{ "general.noSensorType" | translate }}
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="sensorType">
            <tr>
                <td class="row_opening">
                    {{ "general.brand" | translate }}
                    <span class="error" *ngIf="brand.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input
                        type="text"
                        [readonly]="formMode == 1"
                        name="brand"
                        class="form-control table-inline"
                        [(ngModel)]="sensorType.brand"
                        required
                        #brand="ngModel"
                    />
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ "general.model" | translate }}
                    <span class="error" *ngIf="model.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input
                        type="text"
                        [readonly]="formMode == 1"
                        name="model"
                        class="form-control table-inline"
                        [(ngModel)]="sensorType.model"
                        required
                        #model="ngModel"
                    />
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ "general.deviceSensorType" | translate }}
                    <span class="error" *ngIf="deviceSensorType.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content" style="overflow: visible">
                    <ng-select
                        style="width: 100%"
                        [disabled]="formMode == 1"
                        name="deviceSensorType"
                        class="form-control table-inline"
                        required
                        #deviceSensorType="ngModel"
                        [(ngModel)]="sensorType.deviceSensorTypeId"
                    >
                        <ng-option value="">{{ "general.chooseSensorType" | translate }}</ng-option>
                        <ng-option *ngFor="let sensorType of sensorTypes" [value]="sensorType.id"
                            >{{ sensorType.name }}
                        </ng-option>
                    </ng-select>
                </td>
            </tr>
            <tr>
                <td colspan="2">
                    {{ "general.conversionFormula" | translate }}
                    <span class="error" *ngIf="conversionFormula.invalid && formMode != 1">*</span>
                </td>
            </tr>

            <tr>
                <td colspan="2">
                    <textarea
                        rows="4"
                        cols="50"
                        [readonly]="formMode == 1"
                        name="conversionFormula"
                        class="form-control table-inline"
                        [(ngModel)]="sensorType.conversionFormula"
                        placeholder="{{ 'placeholder.comments' | translate }}"
                        #conversionFormula="ngModel"
                    ></textarea>
                </td>
            </tr>
        </tbody>
    </table>
</div>
