<div class="ibox-content slick" style="position: relative">
    <table style="width: 100%" class="table nowrap dtr-inline no-footer table-fixed">
        <tbody *ngIf="!fuelThresholds">
            <tr>
                <td colspan="4">
                    {{ "general.noDataFound" | translate }}
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="fuelThresholds">
            <tr>
                <td class="row_opening" style="width: 50%">
                    {{ "general.fuelCapacity" | translate }}1 L
                    <span class="error" *ngIf="fuelTank1Capacity.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content" [attr.colspan]="hasVepamonEnabled ? 1 : 2">
                    <input
                        type="number"
                        [readonly]="formMode == 1"
                        name="fuelTank1Capacity"
                        class="form-control table-inline"
                        placeholder="{{ 'placeholder.liter' | translate }}"
                        [(ngModel)]="fuelThresholds.fuelTank1Capacity"
                        #fuelTank1Capacity="ngModel"
                    />
                </td>
                <td class="col-checkbox" *ngIf="hasVepamonEnabled">
                    <button
                        type="button"
                        class="btn btn-primary"
                        [disabled]="!fuelThresholds.fuelTank1Capacity"
                        [tooltip]="'general.showCalibrationWindow' | translate"
                        container="body"
                        style="position: absolute; padding: 4px 7px; transform: translate(-7px, -4px)"
                        (click)="showModal(1)"
                    >
                        <span> <i class="fa fa-chart-area"></i></span>
                    </button>
                </td>
            </tr>
            <tr>
                <td class="row_opening" style="width: 50%">
                    {{ "general.fuelCapacity" | translate }}2 L
                    <span class="error" *ngIf="fuelTank2Capacity.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content" style="width: 50%" [attr.colspan]="hasVepamonEnabled ? 1 : 2">
                    <input
                        type="number"
                        [readonly]="formMode == 1"
                        name="fuelTank2Capacity"
                        class="form-control table-inline"
                        placeholder="{{ 'placeholder.liter' | translate }}"
                        [(ngModel)]="fuelThresholds.fuelTank2Capacity"
                        #fuelTank2Capacity="ngModel"
                    />
                </td>
                <td class="col-checkbox" *ngIf="hasVepamonEnabled">
                    <button
                        type="button"
                        class="btn btn-primary"
                        [disabled]="!fuelThresholds.fuelTank2Capacity"
                        [tooltip]="'general.showCalibrationWindow' | translate"
                        container="body"
                        style="position: absolute; padding: 4px 7px; transform: translate(-7px, -4px)"
                        (click)="showModal(2)"
                    >
                        <span> <i class="fa fa-chart-area"></i></span>
                    </button>
                </td>
            </tr>
            <tr>
                <td class="row_opening" style="width: 50%">
                    {{ "general.fillupThreshold" | translate }}%
                    <span class="error" *ngIf="fillupThreshold.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content" style="width: 50%" colspan="2">
                    <input
                        type="number"
                        [readonly]="formMode == 1"
                        name="fillupThreshold"
                        class="form-control table-inline"
                        placeholder="{{ 'placeholder.percentage' | translate }}"
                        [(ngModel)]="fuelThresholds.fillupThreshold"
                        #fillupThreshold="ngModel"
                    />
                </td>
            </tr>
            <tr>
                <td class="row_opening" style="width: 50%">
                    {{ "general.theftThreshold" | translate }}%
                    <span class="error" *ngIf="theftThreshold.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content" style="width: 50%" colspan="2">
                    <input
                        type="number"
                        [readonly]="formMode == 1"
                        name="theftThreshold"
                        class="form-control table-inline"
                        placeholder="{{ 'placeholder.percentage' | translate }}"
                        [(ngModel)]="fuelThresholds.theftThreshold"
                        #theftThreshold="ngModel"
                    />
                </td>
            </tr>
        </tbody>
    </table>
</div>

<!-- Calibrations -->
<div
    (onHidden)="hideModal()"
    [config]="{ backdrop: true, ignoreBackdropClick: true }"
    bsModal
    #addModal="bs-modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
>
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title pull-left">
                    <i class="fa fa-chart-area"></i> {{ "general.sensorCalibration" | translate }}
                    <i [tooltip]="'general.configurationBaseSensors' | translate" class="fas fa-fw fa-question"></i>
                </h5>
                <button type="button" class="close float-right" aria-label="Close" (click)="hideModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <form #userForm="ngForm">
                <div class="col-lg-12" style="padding: 0px 12.5px">
                    <fh-error [error]="sensorError" [success]="sensorSuccess"> </fh-error>
                </div>
                <div class="modal-body" style="padding: 10px; padding-top: 0px">
                    <fh-calibration-details
                        #calibrationComponent
                        [sensorNumber]="tankNumber"
                        [calibrationPoints]="sensorCalibrationPoints"
                        [formMode]="formMode"
                    >
                    </fh-calibration-details>
                </div>
            </form>
        </div>
    </div>
</div>
