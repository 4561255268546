<div class="ibox-content slick" style="position: relative">
    <ngx-loading [show]="loading || loadingDmv"></ngx-loading>
    <p class="alert alert-warning" *ngIf="formMode != 1 && dmvMessage" style="margin-bottom: 0">
        {{ dmvMessage }}
    </p>

    <table style="width: 100%" class="table nowrap dtr-inline no-footer table-fixed">
        <tbody *ngIf="!(asset && asset.id) && formMode == 1">
            <tr>
                <td>
                    {{ "general.noAsset" | translate }}
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="(asset && asset.id) || formMode != 1">
            <tr [hidden]="!permissions['Resellers_View']">
                <td class="row_opening">
                    {{ "general.resellerDescription" | translate }}
                </td>
                <td class="padding-readonly-correct row_content">
                    <a class="secondary link_bolder" href="/#/ResellerDetails/Index/{{ asset?.resellerId }}">{{
                        asset?.resellerDescription
                    }}</a>
                </td>
            </tr>
            <tr *ngIf="formMode != 2" [hidden]="!permissions['Accounts_View']">
                <td class="row_opening">
                    {{ "general.companyName" | translate }}
                </td>
                <td class="padding-readonly-correct row_content">
                    <a class="secondary link_bolder" href="/#/AccountDetails/Index/{{ asset?.accountId }}">{{
                        asset?.companyName
                    }}</a>
                </td>
            </tr>
            <tr *ngIf="formMode == 2" [hidden]="!permissions['Accounts_View'] && asset.accountId">
                <td class="row_opening">
                    {{ "general.companyName" | translate }}

                    <span class="error" *ngIf="accountVar?.invalid">*</span>
                </td>
                <td class="row_content" style="position: relative; overflow: visible">
                    <fh-account-input
                        name="account"
                        #accountVar="ngModel"
                        (onChanged)="accountChanged($event)"
                        required
                        [autoSelect]="false"
                        [selectedResellerId]="asset.resellerId"
                        [clearable]="false"
                        [tableInline]="true"
                        placeholder="{{
                            (asset.resellerId ? 'general.chooseAccount' : 'general.firstChooseReseller') | translate
                        }}"
                        [(ngModel)]="asset.accountId"
                    >
                    </fh-account-input>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ "general.name" | translate }}
                    <span class="error" *ngIf="name.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <fh-copyinput [hide]="formMode != 1">
                        <input
                            body
                            type="text"
                            [readonly]="formMode == 1"
                            name="name"
                            class="form-control table-inline"
                            placeholder="{{ 'placeholder.name' | translate }}"
                            [(ngModel)]="asset.name"
                            required
                            minlength="3"
                            #name="ngModel"
                        />
                    </fh-copyinput>
                </td>
            </tr>
            <tr>
                <td class="row_opening">{{ "general.assetCode" | translate }} / {{ "general.plate" | translate }}</td>
                <td class="row_content">
                    <span style="width: 50%; float: left">
                        <fh-copyinput [hide]="formMode != 1">
                            <input
                                body
                                type="text"
                                [readonly]="formMode == 1"
                                name="code"
                                placeholder="{{ 'placeholder.assetCode' | translate }}"
                                class="form-control table-inline"
                                [(ngModel)]="asset.code"
                                #code="ngModel"
                            />
                        </fh-copyinput>
                    </span>
                    <span style="width: 50%; float: left; padding-left: 4px">
                        <fh-copyinput [hide]="formMode != 1">
                            <input
                                on
                                body
                                type="text"
                                [readonly]="formMode == 1"
                                name="plate"
                                class="form-control table-inline"
                                placeholder="{{ 'placeholder.plate' | translate }}"
                                [(ngModel)]="asset.plateNumber"
                                #plateNumber="ngModel"
                                (change)="getInformationDMV(asset.plateNumber)"
                            />
                        </fh-copyinput>
                    </span>
                    <i
                        *ngIf="formMode != 1 && asset.plateNumber && asset.plateNumber != ''"
                        tooltip="Get asset information from DMV"
                        class="fa fa-fw fa-satellite-dish"
                        style="right: 22px; position: relative; cursor: pointer"
                        (click)="getInformationDMV(asset.plateNumber)"
                    ></i>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ "general.assetDateStart" | translate }}
                </td>
                <td class="row_content" *ngIf="formMode == 2">
                    <input
                        type="text"
                        name="assignmentStart"
                        [bsConfig]="dpConfig"
                        placeholder="{{ 'placeholder.assignmentStart' | translate }}"
                        bsDatepicker
                        [(ngModel)]="asset.assignmentStart"
                        class="form-control table-inline"
                    />
                    <span *ngIf="formMode == 2" class="input-group-calendar"> <i class="fa fa-calendar-alt"></i></span>
                </td>
                <td class="row_content" *ngIf="formMode != 2">
                    <input
                        type="text"
                        readonly
                        name="endDate"
                        class="form-control table-inline"
                        [title]="asset?.assignmentStart"
                        [value]="asset.assignmentStart | amDateFormat : 'lll'"
                    />
                </td>
            </tr>
            <tr *ngIf="asset?.assignmentEnd" class="elipses_overflow">
                <td [class.text-danger]="asset?.assignmentEnd" class="elipses_overflow">
                    {{ "general.endDate" | translate }}
                </td>
                <td [class.text-danger]="asset?.assignmentEnd">
                    <input
                        type="text"
                        readonly
                        name="endDate"
                        class="form-control table-inline"
                        [title]="asset?.assignmentEnd"
                        [value]="asset.assignmentEnd | amDateFormat : 'lll'"
                    />
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ "general.assetBrand" | translate }} / {{ "general.assetModel" | translate }}
                    <span class="error" *ngIf="brand.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <span style="width: 50%; float: left">
                        <input
                            type="text"
                            [readonly]="formMode == 1"
                            name="brand"
                            placeholder="{{ 'placeholder.assetBrand' | translate }}"
                            class="form-control table-inline"
                            [(ngModel)]="asset.brand"
                            #brand="ngModel"
                    /></span>
                    <span style="width: 50%; float: left; padding-left: 4px">
                        <input
                            type="text"
                            [readonly]="formMode == 1"
                            name="model"
                            class="form-control table-inline"
                            placeholder="{{ 'placeholder.assetModel' | translate }}"
                            [(ngModel)]="asset.model"
                            #model="ngModel"
                    /></span>
                </td>
            </tr>
            <tr *ngIf="formMode == 1">
                <td class="row_opening">
                    {{ "general.assetType" | translate }}
                </td>
                <td class="row_content">
                    <input
                        type="text"
                        name="vehicleType"
                        disabled="disabled"
                        class="form-control table-inline"
                        value="{{ asset.assetTypeName }}"
                    />
                </td>
            </tr>
            <tr *ngIf="formMode != 1">
                <td class="row_opening">
                    {{ "general.assetType" | translate }}
                    <span class="error" *ngIf="assetTypeVar?.invalid">*</span>
                </td>
                <td class="row_content" style="position: relative; overflow: visible">
                    <fh-asset-type-input
                        name="assetType"
                        #assetTypeVar="ngModel"
                        required
                        [selectedAccountId]="asset.accountId"
                        placeholder="{{
                            (asset.accountId ? 'general.chooseAssetType' : 'general.firstChooseAccount') | translate
                        }}"
                        [(ngModel)]="asset.assetTypeId"
                    >
                    </fh-asset-type-input>
                </td>
            </tr>
            <tr *ngIf="formMode == 1">
                <td class="row_opening">
                    {{ "general.vehicleType" | translate }}
                </td>
                <td class="row_content">
                    <input
                        type="text"
                        name="vehicleType"
                        disabled="disabled"
                        class="form-control table-inline"
                        value="{{ 'enums.vehicleType.' + asset.vehicleType | translate }}"
                    />
                </td>
            </tr>
            <tr *ngIf="formMode != 1">
                <td class="row_opening">
                    {{ "general.vehicleType" | translate }}
                    <span class="error" *ngIf="vehicleType?.invalid">*</span>
                </td>
                <td class="row_content" style="overflow: visible">
                    <ng-select
                        [disabled]="formMode == 1"
                        placeholder="{{ 'general.chooseVehicleType' | translate }}"
                        [clearable]="false"
                        class="form-control table-inline"
                        name="vehicleType"
                        required
                        #vehicleType="ngModel"
                        [(ngModel)]="asset.vehicleType"
                        style="width: 100%"
                    >
                        <ng-option *ngFor="let vehicleType of vehicleTypes" [value]="vehicleType.id">
                            {{ "enums.vehicleType." + vehicleType.id | translate }}
                        </ng-option>
                    </ng-select>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ "general.icon" | translate }}
                </td>
                <td class="row_content" style="overflow: visible">
                    <fh-asset-icon-chooser
                        [iconId]="asset.icon"
                        [formMode]="formMode"
                        (onIconChanged)="asset.icon = $event"
                    ></fh-asset-icon-chooser>
                </td>
            </tr>
            <tr>
                <td colspan="2" style="background-color: unset; padding: 2px">
                    <textarea
                        name="note"
                        [readonly]="formMode == 1"
                        name="note"
                        class="noresize stickypad"
                        style="height: 100px; width: 100%; resize: none"
                        id="encCss"
                        placeholder="{{ 'placeholder.comments' | translate }}"
                        [(ngModel)]="asset.comment"
                    >
                    </textarea>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ "general.isalert" | translate }}
                    <i [tooltip]="'general.displayAsWarning' | translate" class="fas fa-fw fa-question"></i>
                </td>
                <td class="row_content" style="overflow: visible; border-bottom: none !important">
                    <div class="fa_checkbox">
                        <input
                            id="isCommentWarning"
                            type="checkbox"
                            [disabled]="formMode == 1"
                            name="isCommentWarning"
                            [(ngModel)]="asset.isCommentWarning"
                        />
                        <label for="isCommentWarning"></label>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>
