<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/"> <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Reporting/Executions">{{'general.reporting' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/ReportDetails/Index/{{activeReport?.id}}"
                        >{{ activeReport?.ReportName != "" ? activeReport?.ReportName : ('enums.reportType.' +
                        activeReport?.ReportType) | translate }}</a
                    >
                </li>
                <li class="breadcrumb-item active">{{'general.dashboard' | translate }}</li>
            </ol>
        </nav>
    </div>
    <fh-reporting-navigation></fh-reporting-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">
        <div class="row" data-equalizer="">
            <div class="col-lg-12">
                <fh-error [error]="error" [success]="success"></fh-error>

                <div class="ibox">
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-user-chart"></i> {{ activeReport?.ReportName != "" ?
                            (('enums.reportType.' + activeReport?.ReportType) | translate) + ' - ' +
                            activeReport?.ReportName : ('enums.reportType.' + activeReport?.ReportType) | translate }}
                        </h5>

                        <span class="float-right" tooltip="Show Debug" style="margin-top: -5px">
                            <button class="btn btn-info" (click)="showJson = !showJson">
                                <i class="fas fa-bug" [ngStyle]="{'color': showJson ? '#ccc': '#555' }"></i>
                            </button>
                        </span>

                        <span class="float-right" tooltip="Show column selector" style="margin-top: -5px">
                            <button class="btn btn-info" (click)="displayColumnSelector()">
                                <i
                                    class="fas fa-table-columns"
                                    *ngIf="permissions['FleetManagementReporting_HasReportColumnSelection']"
                                    [ngStyle]="{'color': showColumnSelector ? '#ccc': '#555' }"
                                ></i>
                            </button>
                        </span>

                        <span class="float-right" style="margin-top: -5px">
                            <fh-share-link type="1" [entityId]="activeReport?.id"></fh-share-link>
                        </span>
                    </div>

                    <div class="ibox-content slick" style="position: relative; overflow: auto">
                        <ngx-loading [show]="loading"></ngx-loading>
                        <table style="width: 100%" class="table nowrap dtr-inline no-footer">
                            <tbody>
                                <tr>
                                    <td class="row_opening_even">{{ 'general.account' | translate }}</td>
                                    <td class="row_content" style="position: relative; overflow: visible">
                                        <a
                                            class="secondary link_bolder"
                                            href="/#/AccountDetails/Index/{{activeReport?.accountId}}"
                                            >{{ activeReport?.companyName }}</a
                                        >
                                    </td>
                                    <td class="row_opening_even">{{ 'general.selection' | translate }}</td>
                                    <td
                                        class="row_content"
                                        style="position: relative; overflow: visible"
                                        title="{{activeReport?.periodStart}} - {{activeReport?.periodEnd}}"
                                    >
                                        {{ activeReport?.periodStart | amDateFormat: 'lll' }} - {{
                                        activeReport?.periodEnd | amDateFormat: 'lll' }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="row_opening_even">{{ 'general.reportType' | translate}}</td>
                                    <td class="row_content" style="position: relative; overflow: visible">
                                        {{ activeReport?.selectedReportType }}
                                    </td>
                                    <td class="row_opening_even">{{ 'general.reportName' | translate }}</td>
                                    <td class="row_content" style="position: relative; overflow: visible">
                                        {{ activeReport?.name != "" ? activeReport?.name : (activeReport?.template?.name
                                        ? activeReport?.template?.name : activeReport?.selectedReportType) }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="row_opening_even">{{ 'general.generatedBy' | translate }}</td>
                                    <td class="row_content" style="position: relative; overflow: visible">
                                        {{ activeReport?.userName }}
                                    </td>
                                    <td class="row_opening_even">{{ 'general.generatedOn' | translate }}</td>
                                    <td
                                        class="row_content"
                                        style="position: relative; overflow: visible"
                                        [title]="activeReport?.executionCompletedTimestamp"
                                    >
                                        {{ activeReport?.executionCompletedTimestamp | amDateFormat: 'lll' }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="row_opening_even">{{ 'general.visitCount' | translate }}</td>
                                    <td class="row_content" style="position: relative; overflow: visible">
                                        {{ activeReport?.visitCount }}
                                    </td>
                                    <td class="row_opening_even">{{ 'general.lastVisited' | translate }}</td>
                                    <td
                                        class="row_content"
                                        style="position: relative; overflow: visible"
                                        [title]="activeReport?.lastVisitedTimestamp"
                                    >
                                        {{ (activeReport?.lastVisitedTimestamp ? (activeReport?.lastVisitedTimestamp |
                                        amDateFormat: 'lll') : '-') }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="row_opening_even">{{ 'general.status' | translate }}</td>
                                    <td class="row_content" style="position: relative; overflow: visible">
                                        <container-element [ngSwitch]="activeReport?.reportStatus">
                                            <i
                                                [title]="('enums.reportStatus.' + activeReport?.reportStatus) | translate "
                                                *ngSwitchCase="1"
                                                class="fa fa-check fa-fw"
                                                style="color: rgb(0, 142, 67)"
                                            ></i>
                                            <i
                                                [title]="('enums.reportStatus.' + activeReport?.reportStatus) | translate"
                                                *ngSwitchCase="2"
                                                class="fa fa-check fa-fw"
                                                class="fa fa-times fa-fw"
                                                style="color: rgb(151, 28, 36)"
                                            ></i>
                                            <i
                                                [title]="('enums.reportStatus.' + activeReport?.reportStatus) | translate"
                                                *ngSwitchCase="3"
                                                class="fa fa-check fa-fw"
                                                class="fa fa-recycle fa-fw"
                                                style="color: rgb(151, 28, 36)"
                                            ></i>
                                            <i
                                                [title]="('enums.reportStatus.' + activeReport?.reportStatus) | translate"
                                                *ngSwitchCase="4"
                                                class="fa fa-check fa-fw"
                                                class="fa fa-recycle fa-fw"
                                                style="color: #ee9234"
                                            ></i>
                                            <i
                                                [title]="('enums.reportStatus.' + activeReport?.reportStatus) | translate"
                                                *ngSwitchDefault
                                                class="fa fa-check fa-fw"
                                                style="color: rgb(0, 142, 67)"
                                            ></i>
                                        </container-element>
                                    </td>
                                    <td class="row_opening_even">{{ 'general.recipient' | translate }}</td>
                                    <td class="row_content" style="position: relative; overflow: visible">
                                        {{ activeReport?.recipients != '' ? activeReport?.recipients : '-' }}
                                    </td>
                                </tr>
                                <tr *ngIf="activeReport?.resultMessage != ''">
                                    <td class="row_opening_even">{{'general.message' | translate }}</td>
                                    <td colspan="3" class="row_content" style="position: relative; overflow: visible">
                                        {{ activeReport?.resultMessage != '' ? activeReport?.resultMessage : '-' }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div *ngIf="activeReportJson && showJson" class="ibox-content slick" style="position: relative">
                        <pretty-json [obj]="activeReportJson"></pretty-json>
                    </div>
                    <div *ngIf="activeReport" class="ibox-footer" style="position: relative">
                        <button
                            class="btn btn-danger"
                            (click)="showDelete()"
                            [disabled]="!permissions['Platform_IsReseller'] && activeReport?.userId != userId"
                            title="{{ 'general.delete' | translate }}"
                        >
                            <i class="fa fa-fw fa-trash-alt"></i>
                            <span class="d-none d-md-inline-flex"> {{'general.delete' | translate }}</span>
                        </button>

                        <button
                            class="btn btn-primary"
                            [routerLink]="['/ReportSubscriptionDetails/Index/', activeReport?.reportSubscriptionId]"
                            [disabled]="!permissions['Platform_IsReseller'] && activeReport?.userId != userId"
                            title="{{ 'general.subscription' | translate }}"
                        >
                            <i class="fa fa-fw fa-file-chart-pie"></i>
                            <span class="d-none d-md-inline-flex"> {{'general.subscription' | translate }}</span>
                        </button>

                        <span class="float-right">
                            <div class="ibox-content slick" style="position: relative; border: 0">
                                <div dropdown style="display: flex">
                                    <button
                                        *ngIf="activeReport?.executedSql"
                                        type="button"
                                        (click)="textToClipboard(activeReport.executedSql)"
                                        title="{{'general.copy' | translate }}"
                                        class="btn btn-primary"
                                    >
                                        <i class="fa fa-fw fa-copy"></i>
                                    </button>
                                    <button
                                        [disabled]="error != null || !activeReport?.isSuccessful"
                                        style="margin-right: 0; border-radius: 5px 0 0 8px"
                                        type="button"
                                        (click)="downloadPdf(true, true)"
                                        title="{{'general.downloadPdf' | translate }}"
                                        class="btn btn-primary"
                                    >
                                        <i class="fa fa-fw fa-file-pdf"></i>
                                        <span class="d-none d-md-inline-flex" style="padding-left: 7px">
                                            {{'general.downloadPdf' | translate }}</span
                                        >
                                    </button>
                                    <button
                                        id="button-basic"
                                        dropdownToggle
                                        type="button"
                                        [disabled]="error != null || !activeReport?.isSuccessful"
                                        class="btn btn-primary dropdown-toggle dropdown-toggle-split"
                                        aria-controls="dropdown-basic"
                                        style="margin-right: 0; border-radius: 0 8px 8px 0"
                                    >
                                        <span class="sr-only">Toggle Dropright</span>
                                        <span class="caret"></span>
                                    </button>
                                    <ul
                                        id="dropdown-basic"
                                        *dropdownMenu
                                        class="dropdown-menu dropdown-menu-fix dropdown-menu-right"
                                        role="menu"
                                        aria-labelledby="button-basic"
                                    >
                                        <li role="menuitem">
                                            <a
                                                class="dropdown-item"
                                                title="{{'general.downloadPdfCharts' | translate }}"
                                                (click)="downloadPdf(true, false)"
                                            >
                                                <i class="fa fa-fw fa-file-pdf"></i>
                                                <span style="padding-left: 7px">
                                                    {{'general.downloadPdfCharts' | translate }}</span
                                                ></a
                                            >
                                        </li>
                                        <li role="menuitem">
                                            <a
                                                class="dropdown-item"
                                                title="{{'general.downloadPdfData' | translate }}"
                                                (click)="downloadPdf(false, true)"
                                            >
                                                <i class="fa fa-fw fa-file-pdf"></i>
                                                <span style="padding-left: 7px">
                                                    {{'general.downloadPdfData' | translate }}</span
                                                ></a
                                            >
                                        </li>
                                        <li *ngIf="activeReport?.ReportType == 2" role="menuitem">
                                            <a
                                                class="dropdown-item"
                                                title="{{'general.downloadPdfIncludingMaps' | translate }}"
                                                (click)="downloadPdf(true, true, true)"
                                            >
                                                <i class="fa fa-fw fa-file-pdf"></i>
                                                <span style="padding-left: 7px">
                                                    {{'general.downloadPdfIncludingMaps' | translate }}</span
                                                ></a
                                            >
                                        </li>
                                        <li role="menuitem">
                                            <a
                                                class="dropdown-item"
                                                title="{{'general.downloadData' | translate }}"
                                                (click)="downloadJson()"
                                            >
                                                <i class="fa fa-fw fa-file-spreadsheet"></i>
                                                <span style="padding-left: 7px">
                                                    {{'general.downloadData' | translate }}</span
                                                ></a
                                            >
                                        </li>
                                        <li role="menuitem">
                                            <a
                                                class="dropdown-item"
                                                title="{{'general.downloadXls' | translate }}"
                                                (click)="downloadXls(true)"
                                            >
                                                <i class="fa fa-fw fa-file-excel"></i>
                                                <span style="padding-left: 7px">
                                                    {{'general.downloadXls' | translate }}</span
                                                ></a
                                            >
                                        </li>
                                        <li
                                            role="menuitem"
                                            *ngIf="activeReport?.ReportType != 2 && activeReport?.ReportType != 32"
                                        >
                                            <a
                                                class="dropdown-item"
                                                title="{{'general.downloadXls' | translate }}"
                                                (click)="downloadXls(false)"
                                            >
                                                <i class="fa fa-fw fa-file-excel"></i>
                                                <span style="padding-left: 7px">
                                                    {{'general.downloadXlsExcludingFormatting' | translate }}</span
                                                ></a
                                            >
                                        </li>
                                        <!-- <li role="menuitem"><a class="dropdown-item"
                                                title="{{'general.downloadCsv' | translate }}" (click)="downloadCsv()">
                                                <i class="fa fa-fw fa-file-csv"></i>
                                                <span style="padding-left: 7px">
                                                    {{'general.downloadCsv' | translate }}</span></a>
                                        </li> -->
                                    </ul>
                                </div>
                            </div>
                        </span>
                        <div style="clear: both"></div>
                    </div>
                </div>

                <fh-error [success]="columnSuccess"></fh-error>

                <div *ngIf="showColumnSelector && activeReport" class="ibox">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-table-columns"></i> {{'general.reportTemplate' | translate }}</h5>
                    </div>
                    <div class="ibox-content slick" style="position: relative">
                        <form #columnForm="ngForm" style="width: 100%; border-top: 0px">
                            <fh-column-selector
                                [defaultSourceColumns]="defaultSourceColumns"
                                [loading]="false"
                                (saveReportTemplateEmitter)="saveReportTemplate(activeReport.ReportType, $event)"
                                (updateReportTemplateEmitter)="updateReportTemplate($event)"
                                [reportTemplates]="reportTemplates"
                                (templateChange)="format($event)"
                                [selectedReportTemplateId]="selectedReportTemplateId"
                                [(template)]="template"
                            >
                            </fh-column-selector>
                        </form>
                    </div>
                </div>

                <fh-report-details
                    [selectedReportType]="activeReport?.selectedReportType"
                    [selectedAccountName]="activeReport?.companyName"
                    [reportData]="reportData"
                    [loading]="loadingDetails"
                    [template]="template"
                    [activeReport]="activeReport"
                >
                </fh-report-details>
            </div>
        </div>
    </div>
</fh-permission-handler>

<fh-confirmation-modal #deleteModal (onFinish)="onDelete($event)"> </fh-confirmation-modal>
