import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { throwError } from "rxjs/internal/observable/throwError";
import { Observable, catchError, map } from "rxjs";
import { AuthenticationService } from "../authentication/authentication.service";

@Injectable()
export class ShareTokenService {
  token: string;
  timestamp;
  url = "";

  constructor(private http: HttpClient, private authenticationService: AuthenticationService) {
    this.url = this.authenticationService.getWebserviceURL("ShareToken");
  }

  getShareTokens(): Observable<any> {
    console.log("getting shareTokens from service");
    return this.http.get(this.url, { headers: this.authenticationService.headers }).pipe(
      map((data) => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  addShareToken(shareToken: any): Observable<any> {
    console.log("Post shareToken");
    return this.http
      .post(this.url, shareToken, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  saveShareToken(shareToken: any): Observable<any> {
    console.log("Put shareToken");

    return this.http
      .put(this.url + shareToken.id, shareToken, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  deleteShareToken(id: any): Observable<any> {
    console.log("Delete sharetoken");

    return this.http
      .delete(this.url + id, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  private handleError(error: Response) {
    return throwError(() => error);
  }
}
