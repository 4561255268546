<div class="ibox-content slick" style="position: relative">
    <ngx-loading [show]="loading"></ngx-loading>
    <table style="width: 100%" class="table table-fixed nowrap dtr-inline no-footer">
        <tbody *ngIf="!triggerSchedule">
            <tr>
                <td colspan="4">
                    {{ "general.noTriggerSchedule" | translate }}
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="triggerSchedule">
            <ng-container *ngIf="formMode != 2">
                <tr [hidden]="!permissions['Resellers_View']">
                    <td class="row_opening">
                        {{ "general.resellerDescription" | translate }}
                    </td>
                    <td class="padding-readonly-correct row_content">
                        <a
                            class="secondary link_bolder"
                            href="/#/ResellerDetails/Index/{{ triggerSchedule?.resellerId }}"
                            >{{ triggerSchedule?.resellerDescription }}</a
                        >
                    </td>
                </tr>
                <tr [hidden]="!permissions['Accounts_View']">
                    <td class="row_opening">
                        {{ "general.account" | translate }}
                    </td>
                    <td class="row_content padding-readonly-correct">
                        <a
                            class="secondary link_bolder"
                            href="/#/AccountDetails/Index/{{ triggerSchedule?.accountId }}"
                            >{{ triggerSchedule?.companyName }}</a
                        >
                    </td>
                </tr>
            </ng-container>
            <ng-container *ngIf="formMode == 2">
                <tr [hidden]="!permissions['Resellers_View'] && triggerSchedule.resellerId">
                    <td class="row_opening">
                        {{ "general.resellerDescription" | translate }}
                        <span class="error" *ngIf="resellerVar.invalid">*</span>
                    </td>
                    <td class="row_content" style="position: relative; overflow: visible">
                        <fh-reseller-input
                            name="reseller"
                            #resellerVar="ngModel"
                            (onChanged)="resellerChanged($event)"
                            required
                            placeholder="{{ 'general.chooseReseller' | translate }}"
                            [tableInline]="true"
                            [(ngModel)]="triggerSchedule.resellerId"
                        >
                        </fh-reseller-input>
                    </td>
                </tr>
                <tr [hidden]="!permissions['Accounts_View'] && triggerSchedule.accountId">
                    <td class="row_opening">
                        {{ "general.account" | translate }}
                        <span class="error" *ngIf="accountVar.invalid">*</span>
                    </td>
                    <td class="row_content" style="position: relative; overflow: visible">
                        <fh-account-input
                            name="account"
                            #accountVar="ngModel"
                            (onChanged)="accountChanged($event)"
                            required
                            [selectedResellerId]="triggerSchedule.resellerId"
                            [tableInline]="true"
                            [filterEmptyAccounts]="false"
                            placeholder="{{
                                (triggerSchedule.resellerId ? 'general.chooseAccount' : 'general.firstChooseReseller')
                                    | translate
                            }}"
                            [(ngModel)]="triggerSchedule.accountId"
                        >
                        </fh-account-input>
                    </td>
                </tr>
            </ng-container>
            <tr>
                <td class="row_opening">
                    {{ "general.name" | translate }}
                    <span class="error" *ngIf="name.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input
                        type="text"
                        [readonly]="formMode == 1"
                        name="name"
                        #name="ngModel"
                        required
                        placeholder="{{ 'placeholder.name' | translate }}"
                        class="form-control table-inline"
                        [(ngModel)]="triggerSchedule.triggerScheduleName"
                    />
                </td>
            </tr>
            <tr *ngIf="!hideTimezoneSelector">
                <td class="row_opening">
                    {{ "dates.timezone" | translate }}
                    <span class="error" *ngIf="timezoneVar.invalid">*</span>
                </td>
                <td class="row_content" style="overflow: visible">
                    <fh-timezone-picker
                        id="timeZoneSelector"
                        name="timeZoneSelector"
                        [disabled]="formMode == 1"
                        [timezone]="triggerSchedule.timezoneIana"
                        required
                        #timezoneVar="ngModel"
                        [(ngModel)]="triggerSchedule.timezoneIana"
                    >
                    </fh-timezone-picker>
                </td>
            </tr>
        </tbody>
    </table>
</div>
