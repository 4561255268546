import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { throwError } from "rxjs/internal/observable/throwError";
import { Observable, catchError, map } from "rxjs";
import { Driver, DriverScore, RegisterOperatingDriver } from "../../models/driver.model";
import { AuthenticationService } from "../authentication/authentication.service";
import { fixDate } from "../common/functions.service";
import { LoggingService } from "../logging/logging.service";
import { localizeSystemGroupNames } from "app/common/globals";
import { TranslateService } from "@ngx-translate/core";

// Moment timezone
import Moment from "moment-timezone";
import { parseEpisode } from "../common/episode.parser";
import { DistanceUnitService } from "app/common/distanceunit.service";

window["moment"] = Moment;

@Injectable()
export class DriverService {
  url = "";
  base_url = "";
  inventoryUrl = "";
  Drivers: Driver[] = [];
  timezoneIana: string;

  constructor(
    private http: HttpClient,
    private loggingService: LoggingService,
    private distance: DistanceUnitService,
    private authenticationService: AuthenticationService,
    private translateService: TranslateService
  ) {
    this.url = this.authenticationService.getWebserviceURL("driver");
    this.base_url = this.authenticationService.getWebserviceURL("");
    this.timezoneIana = this.authenticationService.getTimeZoneIana();
  }

  getPagingUrl(accountId?: number) {
    return this.url + "Paging" + (accountId > 0 ? `?accountId=${accountId}` : "");
  }

  getExternalPagingUrl() {
    return this.url + "External";
  }

  getDrivers(includeGroups = false, limitData = false): Observable<Driver[]> {
    console.log("getting drivers from service");
    return this.http
      .get(this.url + `?includeGroups=${includeGroups}&limitData=${limitData}`, {
        headers: this.authenticationService.headers,
      })
      .pipe(
        map((data) => {
          return this.parseResponse(data);
        }),
        catchError(this.handleError)
      );
  }

  getDriversByAccount(accountId, includeGroups = false, limitData = false): Observable<Driver[]> {
    console.log("getting drivers from service");
    return this.http
      .get(this.base_url + "Account/" + accountId + `/Drivers?includeGroups=${includeGroups}&limitData=${limitData}`, {
        headers: this.authenticationService.headers,
      })
      .pipe(
        map((data) => {
          return this.parseResponse(data);
        }),
        catchError(this.handleError)
      );
  }

  getDriverById(id: string): Observable<Driver> {
    console.log("Fetch driver by id " + id);
    return this.http.get(this.url + id, { headers: this.authenticationService.headers }).pipe(
      map((data) => {
        return this.parseReponseDetails(data);
      }),
      catchError(this.handleError)
    );
  }

  getDriverByTag(tag: string, accountid: string, shared = false): Observable<Driver> {
    console.log("Fetch driver by tag " + tag);
    let headers;
    if (shared) {
      headers = this.authenticationService.shareheaders;
    } else {
      headers = this.authenticationService.headers;
    }

    return this.http.get(this.url + "tag/" + tag + "?accountid=" + accountid, { headers: headers }).pipe(
      map((data) => {
        if (data != null) {
          return this.parseReponseDetails(data);
        } else {
          return null;
        }
      }),
      catchError(this.handleError)
    );
  }

  getDriverData(drivers: any[]): Observable<Driver[]> {
    return this.http
      .post<Driver[]>(
        this.url + "List",
        drivers.map((d) => +d),
        { headers: this.authenticationService.headers }
      )
      .pipe(catchError(this.handleError));
  }

  getDriverAssignmentsById(id: string): Observable<any> {
    console.log("Fetch driver assignments by id " + id);
    return this.http.get(this.url + id + "/Assignments", { headers: this.authenticationService.headers }).pipe(
      map((data: any) => {
        data.forEach((item) => {
          item.dateStart =
            item.dateStart !== undefined ? Moment.utc(item.dateStart)["tz"](this.timezoneIana) : undefined;
          item.dateEnd = item.dateEnd !== undefined ? Moment.utc(item.dateEnd)["tz"](this.timezoneIana) : undefined;
        });
        return data;
      }),
      catchError(this.handleError)
    );
  }

  getDriverAssignmentsByAccountId(id: string, start, end): Observable<any> {
    console.log("Fetch driver assignments by accountid " + id);
    return this.http
      .get(this.base_url + "Account/" + id + "/DriverAssignments?start=" + start.unix() + "&end=" + end.unix(), {
        headers: this.authenticationService.headers,
      })
      .pipe(
        map((data: any) => {
          data.forEach((item) => {
            item.dateStart =
              item.dateStart !== undefined ? Moment.utc(item.dateStart)["tz"](this.timezoneIana) : undefined;
            item.dateEnd = item.dateEnd !== undefined ? Moment.utc(item.dateEnd)["tz"](this.timezoneIana) : undefined;
          });
          return data;
        }),
        catchError(this.handleError)
      );
  }

  getEpisodes(driverId: string, start, end, groupBySeconds = -1, episodeTypeCSV) {
    console.log("getting episodes from service");
    return this.http
      .get(
        this.url +
          driverId +
          "/Episodes?start=" +
          start.unix() +
          "&end=" +
          end.unix() +
          "&groupBySeconds=" +
          groupBySeconds +
          "&episodeTypeCSV=" +
          episodeTypeCSV,
        { headers: this.authenticationService.headers }
      )
      .pipe(
        map((data: any) => {
          const returnItem = [];
          data.forEach((item) => {
            const episode = parseEpisode(item, this.translateService, Moment, this.timezoneIana, this.distance);
            returnItem.push(episode);
          });
          return returnItem;
        }),
        catchError(this.handleError)
      );
  }

  saveDriver(driver: Driver): Observable<any> {
    console.log("save driver", driver);

    driver.dateOfBirth = fixDate(driver.dateOfBirth);
    driver.driverLicenceExpirationDate = fixDate(driver.driverLicenceExpirationDate);
    driver.identificationCardExpirationDate = fixDate(driver.identificationCardExpirationDate);

    return this.http
      .post(this.url, driver, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  updateDriver(driver: Driver): Observable<any> {
    console.log("update driver", driver);

    driver.dateOfBirth = fixDate(driver.dateOfBirth);
    driver.driverLicenceExpirationDate = fixDate(driver.driverLicenceExpirationDate);
    driver.identificationCardExpirationDate = fixDate(driver.identificationCardExpirationDate);

    return this.http
      .put(this.url + driver.id, driver, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  deleteDriver(driver: Driver): Observable<any> {
    console.log("delete driver", driver);

    return this.http
      .delete(this.url + driver.id, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  addToGroup(driverId: number, driverGroups): Observable<any> {
    return this.http
      .post(
        this.url + driverId + "/AddToGroups",
        { driverId, driverGroups },
        { headers: this.authenticationService.headers }
      )
      .pipe(catchError(this.handleError));
  }

  removeFromGroups(driverId: any, object: object): Observable<any> {
    return this.http.post(this.url + driverId + "/RemoveFromGroups", object, {
      headers: this.authenticationService.headers,
    });
  }

  resetCache(): Observable<boolean> {
    return this.http.get(this.url + "ResetCache", { headers: this.authenticationService.headers }).pipe(
      map((data: any) => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  getDriversByDeviceId(id: string): Observable<Driver[]> {
    console.log("Fetch drivers by id " + id);
    return this.http.get(this.url + "Device/" + id, { headers: this.authenticationService.headers }).pipe(
      map((data) => this.parseResponse(data)),
      catchError(this.handleError)
    );
  }

  getDriversByAssetId(id: string): Observable<Driver[]> {
    console.log("Fetch drivers by id " + id);
    return this.http.get(this.url + "Asset/" + id, { headers: this.authenticationService.headers }).pipe(
      map((data) => this.parseResponse(data)),
      catchError(this.handleError)
    );
  }

  private handleError(error: Response) {
    return throwError(() => error);
  }

  parseResponse(json: any): Driver[] {
    this.loggingService.log(this.constructor.name, "Retrieved " + json.length + " Drivers.");

    const ident = 1;
    const drivers: Driver[] = [];

    json.forEach((item) => {
      const driver = this.parseReponseDetails(item);
      drivers.push(driver);
    });

    return drivers;
  }

  parseReponseDetails(item) {
    const driver = new Driver();
    driver.id = item.id;
    driver.firstName = item.firstName;
    driver.lastName = item.lastName;
    driver.name = item.firstName;

    if (item.lastName) {
      driver.name += " " + item.lastName;
    }

    driver.email = item.email;
    driver.erpCode = item.erpCode;
    driver.tag = item.code;
    driver.created = item.timestamp !== undefined ? Moment.utc(item.timestamp)["tz"](this.timezoneIana) : undefined;
    driver.dateOfBirth = item.dateOfBirth !== undefined ? Moment.utc(item.dateOfBirth).toDate() : undefined;
    driver.mobilePhone = item.mobilePhone;
    driver.officePhone = item.officePhone;
    driver.homePhone = item.homePhone;
    driver.accountPlatformId = item.accountPlatformId;
    driver.driverLicense = item.driverLicence;
    driver.driverLicenceExpirationDate =
      item.driverLicenceExpirationDate !== undefined
        ? Moment.utc(item.driverLicenceExpirationDate).toDate()
        : undefined;
    driver.identificationCardNumber = item.identificationCardNumber;
    driver.identificationCardExpirationDate =
      item.identificationCardExpirationDate !== undefined
        ? Moment.utc(item.identificationCardExpirationDate).toDate()
        : undefined;
    driver.comment = item.comments;
    driver.qualification = item.qualification;
    driver.isArchived = item.isArchived;
    driver.isActive = item.isActive;

    driver.activeAssignments = item.activeAssignments;

    driver.companyName = item.companyName;
    driver.accountId = item.accountId;

    driver.deviceId = item.deviceId;
    driver.assetName = item.assetName;
    driver.assignmentStart = item.driverAssignmentStart;
    driver.assignmentEnd = item.driverAssignmentEnd;

    driver.resellerId = item.resellerId;
    driver.resellerDescription = item.resellerDescription;

    driver.garageId = item.garageId;
    driver.garageName = item.garageName;

    const wasl = new RegisterOperatingDriver();

    if (item.properties) {
      if (item.properties.custom) {
        driver.properties.custom = [];

        item.properties.custom.forEach((row, index) => {
          driver.properties.custom.push({ key: row.key, value: row.value });
        });
      }

      if (item.properties.wasl) {
        wasl.identityNumber = item.properties.wasl.identityNumber;
        wasl.dateOfBirthGregorian = item.properties.wasl.dateOfBirthGregorian;
        wasl.mobileNumber = item.properties.wasl.mobileNumber;

        wasl.referenceKey = item.properties.wasl.referenceKey;
        wasl.registerDateWasl = item.properties.wasl.registerDateWasl;
        wasl.registerDateSfda = item.properties.wasl.registerDateSfda;
        wasl.registerDateTow = item.properties.wasl.registerDateTow;
        wasl.registerDateSpecialityTransport = item.properties.wasl.registerDateSpecialityTransport;
        wasl.registerDateBusRental = item.properties.wasl.registerDateBusRental;
        wasl.registerDateEducationalTransport = item.properties.wasl.registerDateEducationalTransport;
      }
    }
    driver.properties.wasl = wasl;

    driver.driverGroups = item.driverGroups != null ? JSON.parse(item.driverGroups) : [];

    if (driver.driverGroups.length > 0) {
      driver.driverGroups.forEach((driverGroup) => {
        driverGroup.name = localizeSystemGroupNames(driverGroup.name, this.translateService);
      });
    }

    return driver;
  }

  // DriverScore

  saveEcoDrivingSettings(accountId, deviceId, calculationBasedOn, settings): Observable<any> {
    console.log("save driverscore");

    const ecoDrivingSettings = {
      accountId: accountId,
      deviceId: null,
      calculationBasedOn: calculationBasedOn,
      settings: settings,
    };

    return this.http
      .post(this.base_url + "EcoDriving", ecoDrivingSettings, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  getEcoDrivingSettings(accountId, deviceId) {
    console.log("getting episodes from service");
    return this.http
      .get(this.base_url + "EcoDriving/" + accountId, { headers: this.authenticationService.headers })
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  getDriverScoreEvents(driverId: string, accountId, start, end, groupBySeconds = -1, episodeTypeCSV) {
    console.log("getting episodes from service");
    return this.http
      .get(
        this.url +
          driverId +
          "/ScoreEvents?accountId=" +
          accountId +
          "&start=" +
          start.unix() +
          "&end=" +
          end.unix() +
          "&groupBySeconds=" +
          groupBySeconds +
          "&episodeTypeCSV=" +
          episodeTypeCSV,
        { headers: this.authenticationService.headers }
      )
      .pipe(
        map((data: any) => {
          const returnItem = [];
          data.forEach((item) => {
            const episode = parseEpisode(item, this.translateService, Moment, this.timezoneIana, this.distance);
            returnItem.push(episode);
          });
          return returnItem;
        }),
        catchError(this.handleError)
      );
  }

  getDriverDetailsScore(driverId, accountId, start, end): Observable<any> {
    return this.http
      .get(this.url + driverId + "/Score?accountId=" + accountId + "&start=" + start.unix() + "&end=" + end.unix(), {
        headers: this.authenticationService.headers,
      })
      .pipe(
        map((data: any) => {
          this.loggingService.log(this.constructor.name, "Retrieved " + data.length + " Drivers.");

          return this.parseDriverScore(data);
        }),
        catchError(this.handleError)
      );
  }

  getDriverScore(accountId, start, end): Observable<any> {
    return this.http
      .get(this.url + "Score?accountId=" + accountId + "&start=" + start.unix() + "&end=" + end.unix(), {
        headers: this.authenticationService.headers,
      })
      .pipe(
        map((data: any) => {
          this.loggingService.log(this.constructor.name, "Retrieved " + data.length + " Drivers.");

          return this.parseDriverScore(data);
        }),
        catchError(this.handleError)
      );
  }

  parseDriverScore(data) {
    const drivers: DriverScore[] = [];
    let rank = 0;

    data.forEach((item) => {
      const driver = new DriverScore();
      driver.driverId = item.driverId;
      driver.driverName = item.driverName;
      driver.assetCount = item.assetCount;
      driver.assignmentCount = item.assignmentCount;
      driver.accountId = item.accountId;

      driver.driverGroups = item.driverGroups != null ? JSON.parse(item.driverGroups) : [];
      if (driver.driverGroups.length > 0) {
        driver.driverGroups.forEach((assetGroup) => {
          assetGroup.name = localizeSystemGroupNames(assetGroup.name, this.translateService);
        });
      }

      driver.tripCount = item.tripCount ?? 0;
      driver.workingHoursDurationInSeconds = item.tripDurationInSeconds ?? 0;
      driver.maxSpeed = this.distance.calculateDistanceUnitFromKmFixed(item.maxSpeed, 0) ?? 0;
      driver.segmentsDistance = this.distance.calculateDistanceUnitFromKmFixed(item.segmentsDistance, 0) ?? 0;
      driver.totalDurationInSeconds = item.totalDurationInSeconds ?? 0;
      driver.score = item.score ?? 0;
      driver.idlingDurationInSeconds = item.idlingDurationInSeconds ?? 0;
      driver.speedingDurationInSeconds = item.speedingDurationInSeconds ?? 0;
      driver.speedingCount = item.speedingCount ?? 0;
      driver.accelCount = item.accelCount ?? 0;
      driver.decelCount = item.decelCount ?? 0;
      driver.roadSpeedingCount = item.roadSpeedingCount ?? 0;
      driver.corneringCount = item.corneringCount ?? 0;
      driver.rpmCount = item.rpmCount ?? 0;

      driver.speedingDurationInSeconds = item.speedingDurationInSeconds ?? 0;
      driver.durationSeatBeltViolationInSeconds = item.durationSeatBeltViolationInSeconds ?? 0;

      driver.workingHoursDurationInSeconds = item.workingHoursDurationInSeconds ?? 0;
      driver.pureDrivingDurationInSeconds = item.pureDrivingDurationInSeconds ?? 0;

      driver.accellerationEventScore = item.accellerationEventScore ?? 0;
      driver.brakingEventScore = item.brakingEventScore ?? 0;
      driver.corneringEventScore = item.corneringEventScore ?? 0;
      driver.rpmEventScore = item.rpmEventScore ?? 0;

      driver.idlingScoreWeighted = item.idlingScoreWeighted;
      driver.speedScoreWeighted = item.speedScoreWeighted;
      driver.seatBeltScoreWeighted = item.seatBeltScoreWeighted;

      driver.accellerationEventScoreWeighted = item.accellerationEventScoreWeighted;
      driver.brakingEventScoreWeighted = item.brakingEventScoreWeighted;
      driver.corneringEventScoreWeighted = item.corneringEventScoreWeighted;
      driver.rpmEventScoreWeighted = item.rpmEventScoreWeighted;

      driver.isActive = item.isActive;
      driver.rank = ++rank;

      drivers.push(driver);
    });

    return drivers;
  }
}
