import { ChangeDetectorRef, Component, Input, OnChanges, OnInit } from "@angular/core";
import { ControlContainer, NgForm } from "@angular/forms";
import { FormMode } from "app/common/enums";
import { AuthenticationService } from "app/services/authentication/authentication.service";

@Component({
  selector: "fh-notificationsetting-details",
  templateUrl: "notificationSettingDetails.component.html",
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class NotificationSettingDetailsComponent implements OnInit, OnChanges {
  @Input() settings;
  @Input() loading = false;
  @Input() formMode: any = FormMode.read;

  permissions: {};
  accountId: string;

  constructor(private authenticationService: AuthenticationService, private cd: ChangeDetectorRef) {
    this.permissions = this.authenticationService.permissions;
    this.accountId = this.authenticationService.getAccountId();
  }

  ngOnInit(): void {}

  ngOnChanges(): void {}

  onChanged(): void {
    this.cd.markForCheck();
  }
}
