import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ControlContainer, NgForm } from "@angular/forms";
import { FormMode, languages } from "app/common/enums";
import { AccountInventory } from "app/models/account.model";
import { Country } from "app/models/country.model";
import { Reseller } from "app/models/reseller.model";
import { AccountService } from "app/services/account/account.service";
import { AuthenticationService } from "app/services/authentication/authentication.service";
import { CountriesService } from "app/services/countries/countries.service";
import { ResellerService } from "app/services/resellers/resellers.service";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";

@Component({
  selector: "fh-account-details",
  templateUrl: "accountDetails.component.html",
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class AccountDetailsComponent implements OnInit {
  @Input() account: AccountInventory;
  @Input() loading = false;
  @Input() formMode: any = FormMode.read;
  @Output() onResellerChanged = new EventEmitter();

  languages: { name: string; path: any }[];
  loadingAccounts = false;
  loadingResellers = false;
  loadingCountries = false;

  // Datepicker
  public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
  resellers: Reseller[] = [];
  countries: Country[] = [];
  permissions: {};

  constructor(
    private cd: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private resellerService: ResellerService,
    private accountService: AccountService,
    private countriesService: CountriesService
  ) {
    this.permissions = this.authenticationService.permissions;

    this.languages = Object.keys(languages)
      .filter((k) => typeof languages[k] === "string")
      .map((n) => ({ name: n, path: languages[n] }));
  }

  ngOnInit(): void {
    // Datepicker
    this.dpConfig.containerClass = "theme-default"; // or whatever color
    this.dpConfig.dateInputFormat = "lll";
    this.dpConfig.isAnimated = true;
    this.dpConfig.withTimepicker = true;
    this.dpConfig.keepDatepickerOpened = true;

    this.loadingResellers = true;
    this.loadingCountries = true;

    this.countriesService.getCountries().subscribe((result) => {
      this.countries = result;
      this.loadingCountries = false;
    });
  }

  resellerChanged(resellerId) {
    this.onResellerChanged.emit(this.account.resellerId);

    this.account.parentAccountId = undefined;
  }

  accountChanged(accountId) {}
}
