import { Pipe, PipeTransform, Injectable } from "@angular/core";

@Pipe({
  name: "keys",
})
@Injectable()
export class KeysPipe implements PipeTransform {
  transform(value): any {
    const keys = [];

    for (const key in value) {
      keys.push(key);
    }
    return keys;
  }
}
