<fh-wizard [(step)]="step" (finish)="onCheckOutFinish()">
    <fh-wizard-step
        title="SelectReseller"
        icon="fa-file-pdf"
        [validate]="groupForm"
        [padding]="false"
        (open)="initForm()"
    >
        <div
            *ngIf="!loadingDevices && allowedDevices?.length !== gridSelection.length"
            class="col-lg-12"
            style="margin-top: 5px"
        >
            <div [ngClass]="allowedDevices.length === 0 ? 'alert-danger' : 'alert-warning'" class="alert shadow">
                <span>
                    <p style="font-size: 15px"><strong>{{'general.warning' | translate }}</strong></p>
                </span>
                <span *ngIf="allowedDevices?.length; else notAllowed">
                    <p style="margin: 0">
                        {{ 'wizard.invalidDevicesSummary' | translate:{ allowed: allowedDevices.length, all:
                        gridSelection.length } }}
                    </p>
                </span>
                <ng-template #notAllowed>
                    <p style="margin: 0">{{'wizard.invalidDevices' | translate }}</p>
                </ng-template>
            </div>
        </div>

        <div class="wizard-content border-bottom" style="padding: 12px">{{ 'wizard.selectYourClient' | translate}}</div>
        <div class="wizard-content" style="position: relative">
            <form [formGroup]="groupForm" style="width: 100%; border-top: 0px">
                <table style="width: 100%" class="table table-fixed nowrap dtr-inline no-footer">
                    <tbody>
                        <tr>
                            <td style="width: 25%; border-bottom: 0px !important">
                                {{'general.reseller' | translate }}

                                <span class="error" *ngIf="groupForm.get('selectedReseller').errors">*</span>
                            </td>
                            <td style="width: 75%; border-bottom: 0px !important">
                                <fh-reseller-input
                                    name="reseller"
                                    (onChanged)="resellerChanged($event)"
                                    required
                                    placeholder="{{ 'general.chooseReseller' | translate}}"
                                    [tableInline]="true"
                                    formControlName="selectedReseller"
                                >
                                </fh-reseller-input>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </div>
    </fh-wizard-step>
    <fh-wizard-step title="Remarks" icon="fa-file-pdf">
        <div class="wizard-content" style="padding-bottom: 10px">{{ 'wizard.optionalComment' | translate }}</div>
        <div class="wizard-content">
            <textarea
                class="form-control noresize stickypad"
                style="height: 100px; width: 100%; resize: none"
                placeholder="{{ 'placeholder.comments' | translate}}"
                [(ngModel)]="logItem"
            ></textarea>
        </div>
    </fh-wizard-step>
    <fh-wizard-step title="Summary" icon="fa-summary-pdf">
        <div
            class="wizard-content"
            style="padding-bottom: 10px"
            [innerHTML]="'wizard.summary' | translate:{ count: allowedDevices.length }"
        ></div>
        <div class="ibox-content slick popupOverflow" style="position: relative; min-height: 72px; overflow: auto">
            <table class="table nowrap dtr-inline no-footer table-summary">
                <thead>
                    <tr>
                        <th class="small_padding">{{ 'general.imei' | translate}}</th>
                        <th class="small_padding">{{ 'general.reseller' | translate}}</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let device of allowedDevices; let i = index;">
                        <tr>
                            <td class="small_padding">
                                <input [value]="device.name" readonly class="form-control table-inline" />
                            </td>
                            <td class="small_padding">
                                <input [value]="selectedResellerName" readonly class="form-control table-inline" />
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </fh-wizard-step>
</fh-wizard>
