<fh-permission-handler [permissionName]="permissionName">
    <div class="row wrapper border-bottom white-bg page-heading">
        <fh-home-navigation> </fh-home-navigation>

        <div class="flex-container">
            <div [hidden]="!permissions['Resellers_View']" class="col-lg-3">
                <div class="form-group input-group" style="margin-bottom: 6px; display: block">
                    <fh-reseller-input
                        name="reseller"
                        #resellerVar="ngModel"
                        (onChanged)="resellerChanged($event)"
                        required
                        placeholder="{{ 'general.chooseReseller' | translate}}"
                        [(ngModel)]="selectedResellerId"
                    >
                    </fh-reseller-input>
                </div>
            </div>
            <div [hidden]="!permissions['Accounts_View']" class="col-lg-3">
                <div class="form-group input-group" style="margin-bottom: 6px; display: block">
                    <fh-account-input
                        name="account"
                        #accountVar="ngModel"
                        (onChanged)="accountChanged($event)"
                        required
                        [selectedResellerId]="selectedResellerId"
                        [showEmptyAccountsButton]="false"
                        placeholder="{{ (selectedResellerId ? 'general.chooseAccount' : 'general.firstChooseReseller') | translate}}"
                        [(ngModel)]="selectedAccountId"
                    >
                    </fh-account-input>
                </div>
            </div>
            <div [hidden]="!permissions['AssetGroups_View']" class="col-lg-3">
                <div class="form-group" style="margin-bottom: 6px">
                    <fh-asset-group-input
                        name="assetGroups"
                        #assetGroupVar="ngModel"
                        required
                        [selectedAccountId]="selectedAccountId"
                        [tableInline]="false"
                        [multiple]="false"
                        [autoSelect]="false"
                        (onChanged)="filterMarkers(true)"
                        placeholder="{{ (selectedAccountId? 'general.chooseAssetGroups' : 'general.firstChooseAccount') | translate}}"
                        [(ngModel)]="selectedAssetGroups"
                    >
                    </fh-asset-group-input>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="form-group input-group" style="margin-bottom: 6px">
                    <span class="input-group-prepend">
                        <i class="fa fa-fw fa-search"></i>
                    </span>
                    <input
                        style="width: 100%"
                        type="text"
                        min="0"
                        class="form-control"
                        placeholder="{{ 'general.searchOnDeviceOrImei' | translate}}"
                        (input)="filterMarkers(true)"
                        [(ngModel)]="search"
                    />
                </div>
            </div>
            <div class="col-3 d-none d-sm-block">
                <div class="form-group input-group" style="margin-bottom: 6px">
                    <span class="input-group-prepend">
                        <i class="fa fa-fw fa-map-marker-alt"></i>
                    </span>
                    <ng-select
                        class="form-control"
                        name="displayEvents"
                        (change)="filterMarkers(true)"
                        placeholder="{{ 'general.deviceState' | translate }}"
                        [(ngModel)]="status"
                        style="width: 100%"
                    >
                        <ng-option [value]="0">
                            <i class="fa fa-fw fa-map-marker-alt eye-active black"></i>
                            {{ 'general.deviceState_0' | translate}} ({{getDeviceCount(0)}})
                        </ng-option>
                        <ng-option [value]="1">
                            <i class="fa fa-fw fa-map-marker-alt eye-active green"></i>
                            {{ 'general.deviceState_1' | translate}} ({{getDeviceCount(1)}})
                        </ng-option>
                        <ng-option [value]="2">
                            <i class="fa fa-fw fa-map-marker-alt eye-active red"></i>
                            {{ 'general.deviceState_2' | translate}} ({{getDeviceCount(2)}})
                        </ng-option>
                        <ng-option [value]="3">
                            <i class="fa fa-fw fa-map-marker-alt eye-active orange"></i>
                            {{ 'general.deviceState_3' | translate}} ({{getDeviceCount(3)}})
                        </ng-option>
                        <ng-option [value]="4">
                            <i class="fa fa-fw fa-map-marker-alt eye-active blue"></i>
                            {{ 'general.deviceState_4' | translate}} ({{getDeviceCount(4)}})
                        </ng-option>
                        <ng-option [value]="5">
                            <i class="fa fa-fw fa-map-marker-alt eye-active black"></i>
                            {{ 'general.deviceState_5' | translate}} ({{getDeviceCount(5)}})
                        </ng-option>
                        <ng-option [value]="6">
                            <i class="fa fa-fw fa-location-crosshairs-slash eye-active violet"></i>
                            {{ 'general.deviceState_6' | translate}} ({{getDeviceCount(6)}})
                        </ng-option>
                    </ng-select>
                </div>
            </div>

            <div class="col-flex d-flex flex-fill d-none d-sm-block" *ngIf="permissions['Entities_DeviceTypes']">
                <div class="form-group input-group" style="margin-bottom: 6px">
                    <span class="input-group-prepend">
                        <i class="fa fa-fw fa-router"></i>
                    </span>
                    <ng-select
                        class="form-control"
                        name="displayEvents"
                        (change)="filterMarkers(true)"
                        placeholder="{{ 'general.chooseDeviceType' | translate }}  ({{ deviceTypeOptions.length }})"
                        [loading]="loadingDeviceTypes"
                        [(ngModel)]="deviceType"
                        style="width: 100%"
                    >
                        <ng-option *ngFor="let deviceType of deviceTypeOptions" [value]="deviceType.id">
                            {{ deviceType.value }}</ng-option
                        >
                    </ng-select>
                </div>
            </div>

            <div class="col-flex d-flex flex-fill d-none d-sm-block">
                <div class="form-group input-group" style="margin-bottom: 0px" title="Filter on 0 values">
                    <span
                        id="checkBox11"
                        class="input-group-prepend hand"
                        title="Filter on 0 values"
                        (click)="flipIncludeAssetsOtherAccounts(); getData()"
                    >
                        <i
                            id="checkBox_11"
                            class="auto_checkbox fa fa-fw"
                            [ngClass]="includeAssetsOtherAccounts ? 'fa-check' : 'fa-times'"
                            title="Filter on 0 values"
                            [style.color]="includeAssetsOtherAccounts ? 'rgba(0, 142, 67, 0.8)' : 'rgba(151, 28, 36, 0.8)'"
                        ></i>
                    </span>
                    <input
                        name="checkboxName_11"
                        id="checkboxName_11"
                        style="width: 100%"
                        placeholder="{{'general.includeAssetsOtherAccounts' | translate }}"
                        disabled="disabled"
                        class="form-control"
                    />
                </div>
            </div>
            <!-- <div class="col-lg-2">
            <div class="form-group input-group" style="margin-bottom: 0px;" title="Filter on 0 values">
                <span id="checkBox11" class="input-group-prepend hand" title="Filter on 0 values"
                    (click)="flipIncludeAssetsOtherAccounts();">
                    <i id="checkBox_11" class="auto_checkbox fa fa-fw"
                        [ngClass]="includeAssetsOtherAccounts ? 'fa-check' : 'fa-times'" title="Filter on 0 values"
                        [style.color]="includeAssetsOtherAccounts ? 'rgba(0, 142, 67, 0.8)' : 'rgba(151, 28, 36, 0.8)'"></i>
                </span>
                <input name="checkboxName_11" id="checkboxName_11" style="width:100%"
                    placeholder="{{'general.includeAssetsOtherAccounts' | translate }}" disabled="disabled"
                    class="form-control">
            </div>
        </div> -->
            <div style="clear: both"></div>
        </div>
        <div class="col-lg-12" style="padding: 1px 0 0 0">
            <ngx-loading [show]="loadingDevices"></ngx-loading>
            <fh-leaflet-map
                [geofences]="geofences"
                [locations]="locationData"
                [useClustering]="true"
                [isSpinning]="isSpinning"
                [geofenceEnabled]="false"
                [height]="mapHeight"
                [allowBookmarks]="false"
            >
            </fh-leaflet-map>
        </div>
    </div>

    <div class="wrapper wrapper-content">
        <div class="row">
            <div class="col-lg-12">
                <fh-error [error]="error"></fh-error>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-4">
                <div class="ibox">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-filter"></i> {{'general.deviceState' | translate }}</h5>
                    </div>
                    <div class="ibox-content slicker" style="min-height: 224px; position: relative">
                        <ngx-loading [show]="loadingDevices"></ngx-loading>

                        <highcharts-chart
                            *ngIf="chartActivity"
                            [runOutsideAngular]="true"
                            style="display: block; width: 100%; height: 214px"
                            [Highcharts]="Highcharts"
                            [options]="chartActivity"
                        ></highcharts-chart>
                    </div>
                </div>
            </div>

            <div class="col-lg-8">
                <div class="ibox">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-fw fa-draw-polygon"></i> {{'general.geofences' | translate }}</h5>
                    </div>
                    <div class="ibox-content slicker" style="min-height: 224px; position: relative">
                        <ngx-loading [show]="loadingDevices"></ngx-loading>

                        <highcharts-chart
                            *ngIf="chartGeofences"
                            [runOutsideAngular]="true"
                            style="display: block; width: 100%; height: 214px"
                            [Highcharts]="Highcharts"
                            [options]="chartGeofences"
                        ></highcharts-chart>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-8">
                <div class="ibox">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-fw fa-layer-group"></i> {{'general.assetGroups' | translate }}</h5>
                    </div>
                    <div class="ibox-content slicker" style="min-height: 224px; position: relative">
                        <ngx-loading [show]="loadingDevices"></ngx-loading>

                        <highcharts-chart
                            *ngIf="chartAssetGroups"
                            [runOutsideAngular]="true"
                            style="display: block; width: 100%; height: 214px"
                            [Highcharts]="Highcharts"
                            [options]="chartAssetGroups"
                        ></highcharts-chart>
                    </div>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="ibox">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-car-crash"></i> {{'general.accidents' | translate }}</h5>
                    </div>
                    <div class="ibox-content" style="min-height: 224px; position: relative">
                        <ngx-loading [show]="loadingDevices"></ngx-loading>

                        No accidents found.
                    </div>
                </div>
            </div>
        </div>
    </div>
</fh-permission-handler>
