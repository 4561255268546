<div class="ibox-content slick" style="position: relative">
    <table style="width: 100%" class="table table-fixed nowrap dtr-inline no-footer">
        <tbody *ngIf="!userContactGroup">
            <tr>
                <td colspan="4">
                    {{ "general.userContactGroup" | translate }}
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="userContactGroup">
            <ng-container *ngIf="formMode != 2">
                <tr [hidden]="!permissions['Resellers_View']">
                    <td class="row_opening">
                        {{ "general.resellerDescription" | translate }}
                    </td>
                    <td class="padding-readonly-correct row_content">
                        <a
                            class="secondary link_bolder"
                            href="/#/AccountDetails/Index/{{ userContactGroup?.resellerId }}"
                            >{{ userContactGroup?.resellerDescription }}</a
                        >
                    </td>
                </tr>
                <tr [hidden]="!permissions['Accounts_View']">
                    <td class="row_opening">
                        {{ "general.account" | translate }}
                    </td>
                    <td class="row_content padding-readonly-correct">
                        <a
                            class="secondary link_bolder"
                            href="/#/AccountDetails/Index/{{ userContactGroup?.accountId }}"
                            >{{ userContactGroup?.companyName }}</a
                        >
                    </td>
                </tr>
            </ng-container>
            <ng-container *ngIf="formMode == 2">
                <tr [hidden]="!permissions['Resellers_View'] && selectedResellerId">
                    <td class="row_opening">
                        {{ "general.resellerDescription" | translate }}
                        <span class="error" *ngIf="resellerVar.invalid">*</span>
                    </td>
                    <td class="row_content" style="position: relative; overflow: visible">
                        <fh-reseller-input
                            name="reseller"
                            #resellerVar="ngModel"
                            (onChanged)="resellerChanged($event)"
                            required
                            placeholder="{{ 'general.chooseReseller' | translate }}"
                            [tableInline]="true"
                            [(ngModel)]="selectedResellerId"
                        >
                        </fh-reseller-input>
                    </td>
                </tr>
                <tr [hidden]="!permissions['Accounts_View'] && userContactGroup.accountId">
                    <td class="row_opening">
                        {{ "general.account" | translate }}
                        <span class="error" *ngIf="accountVar.invalid">*</span>
                    </td>
                    <td class="row_content" style="position: relative; overflow: visible">
                        <fh-account-input
                            name="account"
                            #accountVar="ngModel"
                            (onChanged)="accountChanged($event)"
                            required
                            [selectedResellerId]="selectedResellerId"
                            [tableInline]="true"
                            placeholder="{{
                                (selectedResellerId ? 'general.chooseAccount' : 'general.firstChooseReseller')
                                    | translate
                            }}"
                            [(ngModel)]="userContactGroup.accountId"
                        >
                        </fh-account-input>
                    </td>
                </tr>
            </ng-container>
            <tr>
                <td class="row_opening">
                    {{ "general.name" | translate }}
                    <span class="error" *ngIf="name.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input
                        type="text"
                        [readonly]="formMode == 1"
                        name="name"
                        class="form-control table-inline"
                        [(ngModel)]="userContactGroup.name"
                        required
                        minlength="1"
                        #name="ngModel"
                    />
                </td>
            </tr>
            <tr [hidden]="!permissions['Platform_IsAdministrator']">
                <td class="row_opening">
                    {{ "general.isSystemGroup" | translate }}
                </td>
                <td class="padding-readonly-correct row_content">
                    <div class="fa_checkbox">
                        <input
                            name="isSystemGroup"
                            id="isSystemGroup"
                            type="checkbox"
                            [disabled]="formMode == 1"
                            [(ngModel)]="userContactGroup.isSystemGroup"
                        />
                        <label for="isSystemGroup"></label>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>
