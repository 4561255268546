import { Component } from "@angular/core";
import { AuthenticationService } from "app/services/authentication/authentication.service";

@Component({
  selector: "fh-settings-sharing",
  templateUrl: "sharing.template.html",
})
export class SharingComponent {
  accountId;

  constructor(protected authenticationService: AuthenticationService) {
    this.accountId = this.authenticationService.getAccountId();
  }
}
