<ng-select
    [loading]="loadingMaintenanceTypes"
    ngDefaultControl
    [searchable]="true"
    [clearable]="clearable"
    [addTag]="addTag"
    [disabled]="maintenanceTypes.length == 0"
    [readonly]="readonly"
    placeholder="{{ placeholder }} ({{ maintenanceTypes.length }})"
    #input
    [(ngModel)]="value"
    class="form-control table-inline"
    (ngModelChange)="assetTypeChanged()"
>
    <ng-option *ngFor="let assetType of maintenanceTypes | orderBy : 'name' : false" [value]="assetType.id">
        <i class="fas fa-fw fa-car-mechanic"></i> {{ assetType.typeName }}</ng-option
    >
</ng-select>
