import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { getDefaultDpConfig, getGridButtons, getGridLanguages } from "app/common/gridhelper";
import { Driver } from "app/models/driver.model";
import { DriverService } from "app/services/driver/driver.service";
import { BsDaterangepickerConfig } from "ngx-bootstrap/datepicker";
import { AuthenticationService } from "app/services/authentication/authentication.service";
import { getIconPath, roundAsNumber, roundSeconds } from "app/common/globals";
import { LeafletMapComponent } from "../shared/usercontrols/leafletMap.component";
import * as L from "leaflet";
import { GridBase360Directive } from "app/common/360Grid.base";

declare var HeatmapOverlay;

// Moment timezone
import Moment from "moment-timezone";
import { TranslateService } from "@ngx-translate/core";
import { DeviceEpisode, StorageType } from "app/common/enums";
import { StorageHelper } from "app/common/storagehelper";
import { colorMapper } from "app/common/leafletGlobals";
import { FhChartService } from "app/services/charts/charts.service";

window["moment"] = Moment;

import * as Highcharts from "highcharts";
import { ColorService } from "app/services/common/color.service";

@Component({
  selector: "fh-driver-scoredetails",
  templateUrl: "scoreDetails.template.html",
  providers: [FhChartService],
})
export class DriverScoreDetailsViewComponent extends GridBase360Directive implements OnInit {
  Highcharts: typeof Highcharts = Highcharts;

  @ViewChild(LeafletMapComponent, { static: false }) leafletMapComponent: LeafletMapComponent;

  sub;
  loading = false;
  loadingScore = false;
  loadingEpisodes = false;

  showDebug = false;
  drawLine = false;

  error: any;
  warning: any;
  success: any;

  driver: Driver;
  score;

  episodes = [];

  geofences = [];
  height = 250;
  storageType = StorageType.LocalStorage;

  // Daterange
  public dpConfig: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();
  daterangepickerModel: Date[];
  maxDate = new Date();
  timezoneIana: string;
  languageLoaded = false;
  theMarker;
  driverId: any;
  mapHeight = 250;
  showMapOnSide = false;

  useClustering = true;
  pruneCluster: L.FeatureGroup<any>;
  markerList = [];

  heatmapLayer: any;

  chartViolationsPerDay;
  chartViolationsPerType;

  constructor(
    private cd: ChangeDetectorRef,
    private chartService: FhChartService,
    private translateService: TranslateService,
    private authenticationService: AuthenticationService,
    private driverService: DriverService,
    private route: ActivatedRoute,
    private router: Router,
    protected storageHelper: StorageHelper,
    private colorService: ColorService
  ) {
    super(storageHelper);

    this.driver = null;

    this.timezoneIana = authenticationService.getTimeZoneIana();

    this.daterangepickerModel = [
      Moment().tz(this.timezoneIana).subtract(1, "month").startOf("day").toDate(),
      Moment().tz(this.timezoneIana).endOf("day").toDate(),
    ];

    this.storageHelper.loadStoreState(this.storageType, "settings_", "showMapOnSide").subscribe((result) => {
      this.showMapOnSide = JSON.parse(result) === true;

      if (this.showMapOnSide) {
        this.mapHeight = 330;
      }
      this.cd.markForCheck();
    });

    this.pruneCluster = L.featureGroup();

    this.dpConfig = getDefaultDpConfig(authenticationService);
  }

  ngOnInit() {
    this.loading = true;
    this.driver = new Driver();
    this.driver.id = "";

    this.sub = this.route.params.subscribe((params) => {
      const id = params["id"];

      this.driverId = id;
      this.driverService.getDriverById(id).subscribe((driver) => {
        this.driver = driver;

        this.fetchScore(false);

        this.translateService.get("general.date").subscribe((value) => {
          this.initGrid();
          this.languageLoaded = true;
        });

        this.loading = this.loading = false;
        if (this.driver == null) {
          this.router.navigate(["/Drivers/Overview"]);
        }
      });
    });
  }

  actualRound(value, decimals) {
    return roundAsNumber(value, decimals);
  }

  dateChanged(event) {
    this.fetchScore(true);
  }

  actualRoundSeconds(value) {
    return roundSeconds(value);
  }

  fetchScore(loadEpisodes = true) {
    this.loadingScore = true;
    this.score = null;
    this.episodes = [];

    this.driverService
      .getDriverDetailsScore(
        this.driver.id,
        this.driver.accountId,
        Moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf("day"),
        Moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf("day")
      )
      .subscribe(
        (result) => {
          this.score = result != null && result.length > 0 && result[0];

          this.displayChart();
          this.loadingScore = false;
          this.cd.markForCheck();
        },
        (err) => {
          this.error = err;
          this.loadingScore = false;
          this.cd.markForCheck();
        }
      );

    if (loadEpisodes) {
      this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.ajax.reload();
      });
    }
  }

  displayChart() {
    const theDataPerDay = [];

    const dataViolationsPerType = [
      {
        name: this.translateService.instant("general.violationsPerDay"),
        colorByPoint: true,
        minPointSize: 20,
        innerSize: "20%",
        zMin: 0,
        data: [
          {
            name: "Idling",
            y: roundAsNumber(this.score?.idlingScoreWeighted, 1),
          },
          {
            name: "Speeding",
            y: roundAsNumber(this.score?.speedScoreWeighted, 1),
          },
          {
            name: "Seatbelt",
            y: roundAsNumber(this.score?.durationSeatBeltViolationInSeconds, 1),
          },
          {
            name: "Cornering",
            y: roundAsNumber(this.score?.corneringEventScoreWeighted, 1),
          },
          {
            name: "Accel",
            y: roundAsNumber(this.score?.accellerationEventScoreWeighted, 1),
          },
          {
            name: "Braking",
            y: roundAsNumber(this.score?.brakingEventScoreWeighted, 1),
          },
          {
            name: "RPM",
            y: roundAsNumber(this.score?.rpmEventScoreWeighted, 1),
          },
        ],
      },
    ];

    this.chartViolationsPerType = this.chartService.generateVarPieChartDevice(dataViolationsPerType, {});
  }

  handleEpisodesFetched(episodes) {
    this.leafletMapComponent.markerLayer.clearLayers();

    if (episodes.length === 0) {
      return;
    }

    episodes.forEach((episode) => {
      if (
        episode.beginLatitude == 0 ||
        episode.beginLongitude == 0 ||
        !episode.beginLatitude ||
        !episode.beginLongitude
      ) {
        console.log("Skipping episode");
        return;
      }

      if (
        (episode.beginLatitude == episode.endLatitude && episode.beginLongitude == episode.endLongitude) ||
        !this.drawLine
      ) {
        // Add point

        const color = "#ff0000";

        const circle = new L.Circle(new L.LatLng(episode.beginLatitude, episode.beginLongitude), {
          radius: 15,
          fillColor: "#DC143C",
          color: color,
          fillOpacity: 0.2,
          weight: 4,
          opacity: 0.8,
        });

        circle.addTo(this.leafletMapComponent.tripLayer);
      } else {
        // Add line
        const color = "#ff0000";

        if (episode.beginLatitude && episode.beginLongitude && episode.endLatitude && episode.endLongitude) {
          const polyline = new L.Polyline(
            [
              new L.LatLng(episode.beginLatitude, episode.beginLongitude),
              new L.LatLng(episode.endLatitude, episode.endLongitude),
            ],
            {
              fillColor: "#DC143C",
              color: color,
              fillOpacity: 0.2,
              weight: 10,
              opacity: 0.8,
              smoothFactor: 1,
            }
          );

          polyline.addTo(this.leafletMapComponent.tripLayer);
        }
      }
    });

    this.leafletMapComponent.centerMap();
  }

  addLabel(theMarker, label) {
    if (!label) {
      return;
    }

    const direction = "bottom";
    const offset = L.point(0, 20);

    // theMarker.data.label
    theMarker["bindTooltip"](label, { permanent: true, direction: direction, opacity: 0.8, offset: offset });
  }

  createPopup(theMarker, data, category) {
    // parse data to icon
    theMarker.setIcon(this.createIcon(data));

    const markerPopup = `
      <div style="width:100%">
        <div style="width:300px;overflow: auto;" class="leaflet-mappopup">
          <div class="header">${data.title}</div>
          <div class="content">${this.translateService.instant("general.episode")}</div>
          <div class="content">${this.translateService.instant("enums.deviceEpisode." + data.episodeTypeId)}</div>
          <div class="content">${this.translateService.instant("general.episodeStart")}</div>
          <div class="content" title="${data.startUtc?.toLocaleString()}">${data.startUtc}</div>
          <div class="content">${this.translateService.instant("general.maxValue")}</div>
          <div class="content" title="${data.maxValue?.toLocaleString()}">${data.maxValue} mg</div>
          <div class="content">
            <a href="/#/DeviceDetails/Index/${data.deviceId}">${this.translateService.instant("general.details")}</a>
          </div>
        </div>
      </div>`;

    if (theMarker.getPopup()) {
      theMarker.setPopupContent(markerPopup);
    } else {
      theMarker.bindPopup(markerPopup, {
        closeButton: false,
      });
    }
  }

  createIcon(data) {
    const location = data.location;
    const iconPath = getIconPath(data.iconId)[1];

    const [markerIcon, heading] =
      location.deviceState === 6
        ? ["fa-rss", 0]
        : location.deviceState === 1 && location.headingInDegrees > 0
        ? ["fa-arrow-circle-up", location.headingInDegrees]
        : location.deviceState === 2
        ? ["fa-stop-circle", 0]
        : location.deviceState === 3
        ? ["fa-pause-circle", 0]
        : location.deviceState === 4
        ? ["fa-signal", 0]
        : location.deviceState === 5
        ? ["fa-power-off", 0]
        : location.deviceState === 0
        ? ["fa-question-circle", 0]
        : ["fa-play-circle", 0];

    return L["StatusMarker"].icon({
      iconUrl: iconPath,
      icon: markerIcon,
      markerColor: colorMapper(location.deviceState),
      rotate: heading,
      shape: "circle",
      prefix: "fas",
    });
  }

  onMapReady(map) {
    const that = this;

    this.heatmapLayer = new HeatmapOverlay({
      radius: 30,
      maxOpacity: 0.8,
      scaleRadius: false,
      useLocalExtrema: false,
      latField: "lat",
      lngField: "lng",
      valueField: "count",
    });

    setTimeout(() => {
      this.heatmapLayer.addTo(this.leafletMapComponent.heatmapLayer);

      // Prunecluster
      that.leafletMapComponent.pruneCluster.PrepareLeafletMarker = function (theMarker, data, category) {
        // parse data to icon
        that.addLabel(theMarker, data);

        that.createPopup(theMarker, data, category);

        // theMarker.off('click');

        // theMarker.on('click', (evt: any) => {
        //     that.zone.run(() => {
        //         that.checkCircleMarker(data);
        //     });
        // });

        theMarker.setIcon(that.createIcon(data));
      };
    }, 100);
  }

  initGrid(): void {
    const that = this;

    const commonExportOptions = {
      modifier: {
        page: "all",
        search: "none",
      },
      columns: ["id_export:name", ":visible[tabindex]"],
    };

    const deviceEpisodeTypes = [];
    Object.values(DeviceEpisode)
      .filter((key) => isNaN(Number(DeviceEpisode[key])))
      .forEach(function (item, index) {
        deviceEpisodeTypes.push({ id: item.toString(), value: DeviceEpisode[item].toString() });
      });

    this.columns = [
      {
        name: "id_export",
        data: "id",
        className: "noVis",
        title: this.translateService.instant("general.id"),
        visible: false,
      },
      {
        name: "startUtc",
        data: "startUtc",
        width: 130,
        title: this.translateService.instant("general.episodeStart"),
        type: "date",
        render: function (data, type, row) {
          if (type === "display") {
            const date = Moment.utc(data)["tz"](that.timezoneIana);
            return data
              ? '<span title=" ' + date.toLocaleString() + '">' + date.format("YYYY-MM-DD HH:mm:ss") + "</span>"
              : "";
          } else {
            return data;
          }
        },
      },
      {
        name: "endUtc",
        data: "endUtc",
        title: this.translateService.instant("general.episodeEnd"),
        width: 130,
        type: "date",
        visible: false,
        render: function (data, type, row) {
          const date = Moment.utc(data)["tz"](that.timezoneIana);
          return data
            ? '<span title=" ' + date.toLocaleString() + '">' + date.format("YYYY-MM-DD HH:mm:ss") + "</span>"
            : "";
        },
      },
      {
        name: "durationInSeconds",
        data: "durationInSeconds",
        title: this.translateService.instant("general.duration"),
        type: "num",
        render: function (data, type, row) {
          if (type === "display") {
            return roundSeconds(data);
          }
          return data ?? "0";
        },
      },
      {
        name: "timestamp",
        data: "timestamp",
        title: this.translateService.instant("general.timestamp"),
        type: "date",
        visible: false,
        width: 130,
        defaultContent: "",
        render: function (data, type, row) {
          if (type === "display") {
            const date = Moment.utc(data)["tz"](that.timezoneIana);
            return data
              ? '<span title=" ' + date.toLocaleString() + '">' + date.format("YYYY-MM-DD HH:mm:ss") + "</span>"
              : "";
          } else {
            return data;
          }
        },
      },
      {
        name: "description",
        data: "description",
        visible: false,
        title: this.translateService.instant("general.description"),
        render: function (data, type, row) {
          return data ?? "-";
        },
      },
      {
        name: "fkDeviceEpisodeTypeId",
        data: "fkDeviceEpisodeTypeId",
        type: "select",
        options: deviceEpisodeTypes.sort((a, b) => a.value.localeCompare(b.value)),
        title: this.translateService.instant("general.episodeType"),
        render: function (data, type, row) {
          return that.translateService.instant("enums.deviceEpisode." + data);
        },
      },
      {
        name: "maxValue",
        data: "maxValue",
        width: 70,
        type: "num",
        visible: true,
        defaultContent: "",
        title: this.translateService.instant("general.maxValue"),
        render: function (data, type, row) {
          if (type === "display") {
            if ([24, 25, 26].indexOf(row.fkDeviceEpisodeTypeId) > -1) {
              return data + " mG";
            }
            return data ?? "-";
          } else {
            return data;
          }
        },
      },
      {
        name: "count",
        data: "count",
        width: 70,
        type: "num",
        visible: false,
        defaultContent: "",
        title: this.translateService.instant("general.count"),
        render: function (data, type, row) {
          return data ?? "0";
        },
      },
      {
        name: "location",
        data: "latitude",
        defaultContent: "",
        visible: false,
        title: this.translateService.instant("general.location"),
        render: function (data, type, row) {
          if (row.beginLatitude != null && row.beginLongitude != null) {
            return row.beginLatitude.toFixed(4) + "/" + row.beginLongitude.toFixed(4);
          }
          return "Unknown";
        },
      },
    ];

    this.dtOptions = {
      buttons: getGridButtons(
        this.commonExportOptions,
        "driverscore_overview",
        this.translateService.instant("menu.messagesoverview"),
        this.colorService
      ),
      pagingType: "simple_numbers",
      serverSide: false,
      processing: false,
      scrollX: true,
      colReorder: { fixedColumnsLeft: 1 },
      order: [[1, "desc"]],
      stateSave: false,
      ajax: (dataTablesParameters: any, callback) => {
        $(".dataTables_info").html(this.translateService.instant("grid.loadingData"));
        this.loadingEpisodes = true;
        this.driverService
          .getDriverScoreEvents(
            this.driver.id,
            this.driver.accountId,
            Moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf("day"),
            Moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf("day"),
            10,
            "4,24,25,26,40"
          )
          .subscribe(
            (resp) => {
              this.episodes = resp;

              this.handleEpisodesFetched(this.episodes);

              this.loading = false;
              callback({
                recordsTotal: resp.length,
                recordsFiltered: resp.length,
                data: resp,
              });
            },
            (error) => {
              this.success = null;
              this.error = error;
              this.error.statusText = "Error fetching data";
              this.loading = false;
            }
          );
      },
      initComplete: function (settings, json) {
        that.loadingEpisodes = false;

        console.log("init complete");
        that.checkFilters();
        that.drawFilterRow();
      },
      colVis: {
        restore: this.translateService.instant("general.restore"),
        showAll: this.translateService.instant("general.showAll"),
        showNone: this.translateService.instant("general.hideAll"),
      },
      columns: this.columns,
      pageLength: 17,
      lengthMenu: [
        [10, 17, 25, 50, 200, -1],
        [10, 17, 25, 50, 200, this.translateService.instant("general.all")],
      ],
      language: getGridLanguages(this.translateService),
      rowCallback: (row, data) => {
        this.setMapInteraction(that, row, data);

        if (data.beginLatitude != null && data.beginLongitude != null) {
          $(row).addClass("hand");
        }
      },
    };
  }

  setMapInteraction(table, row, data) {
    const theLatLon = data;
    const that = this;

    $(row).click(function () {
      that.showOnMap(data);
    });
  }

  showOnMap(episode) {
    if (!episode.beginLatitude) {
      return;
    }

    if (episode.beginLatitude == episode.endLatitude && episode.beginLongitude == episode.endLongitude) {
      // single point
      this.leafletMapComponent.map.setView([episode.beginLatitude, episode.beginLongitude], 15, {
        animate: true,
        duration: 0.5,
      });
    } else {
      this.leafletMapComponent.map.setView([episode.beginLatitude, episode.beginLongitude], 15, {
        animate: true,
        duration: 0.5,
      });
    }

    this.filterAllMarkers(episode);
  }

  filterAllMarkers(episode) {
    console.log("filtering markers");
    // filter the events on map

    this.episodes.forEach((episode) => {
      const theMarker = episode.theMarker;
      if (theMarker) {
        theMarker.filtered = true;

        if (theMarker === episode.theMarker) {
          theMarker.filtered = false;
        }
      }
    });

    if (typeof this.leafletMapComponent.pruneCluster.ProcessView === "function") {
      this.leafletMapComponent.pruneCluster.ProcessView();
    }

    // Reset
    setTimeout(() => {
      this.episodes.forEach((episode) => {
        const theMarker = episode.theMarker;
        if (theMarker) {
          theMarker.filtered = false;
        }
      });

      if (typeof this.leafletMapComponent.pruneCluster.ProcessView === "function") {
        this.leafletMapComponent.pruneCluster.ProcessView();
      }
    }, 5000);
  }
}
