export class AssetType {
  public id;
  public name: string;
  public accountId;
  public maintenanceTypeChecked = false;
  public companyName: any;
  public resellerDescription: any;
  public resellerId: any;
  public description: any;
  public assetCount: number;
}
