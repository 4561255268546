<table style="width: 100%" class="table table-fixed nowrap dtr-inline no-footer">
    <tbody>
        <tr>
            <td class="row_opening">
                {{ 'general.settingId' | translate}}
                <span class="error" *ngIf="settingId.invalid">*</span>
            </td>
            <td class="row_content" style="position: relative; overflow: visible">
                <ng-select
                    class="form-control table-inline"
                    name="settingId"
                    #settingId="ngModel"
                    placeholder="{{ 'general.choose' | translate }}"
                    [(ngModel)]="deviceSettingChange.settingId"
                    style="width: 100%"
                    [clearable]="false"
                    (ngModelChange)="checkIfValueInputIsNeeded($event)"
                >
                    <ng-option *ngFor="let settingId of settingIds | keyvalue" [value]="settingId.value">
                        {{ ('enums.deviceSettingChange.' + settingId.value) | translate }}
                    </ng-option>
                </ng-select>
            </td>
        </tr>
        <tr>
            <td class="row_opening">
                {{ 'general.settingValue' | translate}}
                <span class="error" *ngIf="settingValue.invalid">*</span>
            </td>
            <td class="row_content">
                <textarea
                    id="settingValue"
                    name="settingValue"
                    class="noresize stickypad"
                    style="height: 120px; width: 100%; resize: none"
                    placeholder="{{ 'placeholder.settingValue' | translate}}"
                    class="form-control table-inline"
                    [(ngModel)]="deviceSettingChange.settingValue"
                    #settingValue="ngModel"
                    [disabled]="valueInputIsDisabled"
                    required
                ></textarea>
            </td>
        </tr>
        <tr *ngIf="permissions['FleetManagement_DeviceSettingsWakeUp']">
            <td class="row_opening">{{'general.wakeUpDevice' | translate }}</td>
            <td class="row_content">
                <div class="fa_checkbox">
                    <input id="checkbox" type="checkbox" [(ngModel)]="deviceSettingChange.wakeUpDevice" />
                    <label for="checkbox"> </label>
                </div>
            </td>
        </tr>
    </tbody>
</table>
