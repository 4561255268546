import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { FormMode } from "app/common/enums";
import { Device } from "app/models/device.model";
import { DeviceService } from "app/services/device/device.service";
import { AuthenticationService } from "../../services/authentication/authentication.service";
import { AssetGroupsService } from "app/services/asset/assetGroups.service";
import { AssetGroup } from "app/models/group.model";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "fh-devices-add",
  templateUrl: "add.template.html",
})
export class DeviceAddViewComponent {
  addDevice: boolean = false;
  device: Device;
  formMode = FormMode.add;
  error: any;
  loading: boolean = false;
  form: any;
  success: { statusText: string; success: any };
  loadingGroups: boolean = false;
  assetGroups: AssetGroup[];
  selectedGroups = [];
  permissions = {};
  permissionName: string = "Devices_Add";

  constructor(
    private translateServe: TranslateService,
    private deviceService: DeviceService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private assetGroupService: AssetGroupsService
  ) {
    this.device = new Device();
    this.selectedGroups = [];
    this.assetGroups = [];

    this.permissions = this.authenticationService.permissions;

    this.assetGroupService.getAssetGroups().subscribe((res) => {
      this.assetGroups = res;
      this.loadingGroups = false;

      this.assetGroups.forEach((assetGroup) => {
        this.selectedGroups.push(assetGroup.id);
      });
    });
  }

  onInsert() {
    this.loading = true;

    this.deviceService.createDevice(this.device).subscribe({
      next: (result) => {
        this.loading = false;

        this.success = {
          statusText: "Success",
          success: this.translateServe.instant("general.saveSuccessRedirect", { entity: "device" }),
        };

        setTimeout(() => {
          this.router.navigate(["/DeviceDetails/Index/" + result.id]);
        }, 2000);

        this.deviceService.resetDeviceCache().subscribe((res) => {
          console.log("Devices are cleared");
        });
      },
      error: (error) => {
        this.error = error;
        this.loading = false;
      },
    });
  }
}
