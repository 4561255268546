import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { contains } from "app/common/globals";
import { AuthenticationService } from "app/services/authentication/authentication.service";

@Component({
  selector: "fh-device-navigation",
  templateUrl: "deviceNavigation.template.html",
})
export class DeviceNavigationComponent implements OnInit {
  sub: any;
  permissions: {};
  activeId: any;
  permissionKeys: string[];

  hasDataPermissions = false;
  dataParmissions = ["Devices_Edit", "FleetManagement_Trips"];

  hasSettingPermissions = false;
  settingPermissions = [
    "AssetTypes_View",
    "Entities_DeviceTypes",
    "FleetManagement_Sensors",
    "Platform_IsAdministrator",
    "Platform_IsFleetManager",
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    this.permissions = this.authenticationService.permissions;
    this.permissionKeys = Object.keys(this.permissions);

    this.hasDataPermissions = this.permissionKeys.some((e) => contains(this.dataParmissions, e));

    this.hasSettingPermissions = this.permissionKeys.some((e) => contains(this.settingPermissions, e));
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe((params) => {
      const id = params["id"];
      this.activeId = id;
    });
  }

  isAssetGroupDetails() {
    const routes = ["AssetGroupDetails"];

    let routeActive = false;

    routes.forEach((route) => {
      const active = this.router.url.indexOf(route);
      if (active > -1) {
        routeActive = true;
      }
    });

    return routeActive;
  }

  activeRoute(routename: string): boolean {
    const active = this.router.url.indexOf(routename) !== -1;
    return active;
  }

  isSettings() {
    const routes = [
      "AssetTypes",
      "DeviceTypes",
      "DeviceTypeDetails",
      "SensorTemplate",
      "DeviceRoutingConfiguration",
      "Commands",
    ];

    let routeActive = false;

    routes.forEach((route) => {
      const active = this.router.url.indexOf(route);
      if (active > -1) {
        routeActive = true;
      }
    });

    return routeActive;
  }

  isData() {
    const routes = ["DayView", "Trips", "Assignments", "Changes", "Routes"];

    let routeActive = false;

    routes.forEach((route) => {
      const active = this.router.url.indexOf(route);
      if (active > -1) {
        routeActive = true;
      }
    });

    return routeActive;
  }

  navigateData() {
    this.router.navigate(["/Devices/DayView"]);
    return;
  }

  navigateSettings() {
    if (this.permissions["AssetTypes_View"]) {
      this.router.navigate(["/Devices/AssetTypes/Overview"]);
      return;
    }

    if (this.permissions["Entities_DeviceTypes"]) {
      this.router.navigate(["/Devices/DeviceTypes/Overview/"]);
      return;
    }

    if (this.permissions["FleetManagement_Sensors"]) {
      this.router.navigate(["/Devices/SensorTemplates/Overview"]);
      return;
    }

    if (this.permissions["Platform_IsAdministrator"]) {
      this.router.navigate(["/Devices/DeviceRoutingConfiguration"]);
      return;
    }

    if (this.permissions["Platform_IsFleetManager"]) {
      this.router.navigate(["/Devices/CustomCommands/Overview"]);
      return;
    }
  }
}
