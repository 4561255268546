import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectorRef } from "@angular/core";
import { WizardComponent } from "./wizard";

@Component({
  selector: "fh-wizard-step",
  host: {
    "[style.display]": 'isCurrent ? "block" : "none"',
  },
  template: `
    <div
      [ngClass]="{
        'modal-inner': padding === true,
        'modal-inner-smallpadding': padding === false
      }"
    >
      <ng-content> </ng-content>
    </div>
  `,
})
export class WizardStepComponent implements OnInit {
  private isCurrent;
  private step;

  @Input() stepTitle = "";
  @Input() icon = "fa-microchip";
  @Input() disabled = false;
  @Input() validate: any = false;
  @Input() padding = true;

  @Output() open = new EventEmitter();

  constructor(private cd: ChangeDetectorRef, private parent: WizardComponent) {}

  ngOnInit() {
    if (!this.disabled) {
      this.step = this.parent.addStep(this.stepTitle, this.icon, this.disabled, this.validate, this.padding);
      this.isCurrent = this.step === this.parent.step;

      if (this.isCurrent) {
        this.open.emit();
      }

      this.parent.stepChange.subscribe((step) => {
        this.isCurrent = this.step === step;
        if (this.isCurrent) {
          this.open.emit();
        }
        this.cd.markForCheck();
      });
    }
  }
}
