<div class="row border-bottom white-bg dashboard-header">
    <div class="col-sm-12" style="padding: 15px">
        <h2><i class="fas fa-fw fa-question-circle"></i> {{ "help.header" | translate }}</h2>
        {{ "help.intro" | translate }}
    </div>
</div>

<div class="wrapper wrapper-content">
    <div class="row">
        <div class="col-lg-6">
            <div class="ibox">
                <div class="ibox-title">
                    <h5><i class="fas fa-fw fa-question-circle"></i> {{ "help.header" | translate }}</h5>
                </div>
                <div class="ibox-content">
                    <div class="activity-stream">
                        <div class="activity-stream">
                            <div class="stream" *ngFor="let question of questions">
                                <div class="stream-badge">
                                    <i class="fa fa-question-circle logtype logtype_0"></i>
                                </div>
                                <div class="stream-panel">
                                    <div class="stream-info" (click)="question.hide = !question.hide">
                                        {{ question.text }}
                                    </div>
                                    <div class="stream-info" [hidden]="question.hide">
                                        <span class="info">{{ question.answer }}</span>

                                        <a class="btn btn-link" (click)="removeQuestion(question)">Remove</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="ibox">
                <div class="ibox-title">
                    <h5><i class="fas fa-fw fa-question-circle"></i> {{ "help.header" | translate }}</h5>
                </div>
                <div class="ibox-content">
                    <div class="cards">
                        <div class="card-blobk">
                            <form (submit)="addQuestion()">
                                <div class="form-group">
                                    <label for="text">Question </label>
                                    <input type="text" name="text" [(ngModel)]="text" class="form-control" />
                                </div>
                                <div class="form-group">
                                    <label for="text">Answer </label>
                                    <textarea
                                        type="answer"
                                        name="answer"
                                        [(ngModel)]="answer"
                                        class="form-control"
                                        rows="4"
                                        placeholder="{{ 'placeholder.comments' | translate }}"
                                    ></textarea>
                                </div>
                                <input type="submit" class="btn btn-primary" value="Submit" />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
