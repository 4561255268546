import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Driver } from "app/models/driver.model";
import { DriverService } from "app/services/driver/driver.service";
import { AuditLog } from "../../models/auditlog.model";
import { AuditLogService } from "../../services/auditlog/auditlog.service";

@Component({
  selector: "fh-driver-log",
  templateUrl: "driverLog.template.html",
})
export class DriverLogViewComponent implements OnInit {
  driverLog: AuditLog[];
  sub;
  driver: Driver;
  loading = false;

  error: any;
  success: any;

  permissionName = "FleetManagement_Log";
  driverId: any;

  constructor(
    private http: HttpClient,
    private auditLogService: AuditLogService,
    private driverService: DriverService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.driver = null;
    this.driverLog = [];
  }

  ngOnInit() {
    this.loading = true;
    this.driver = new Driver();
    this.driver.id = "";

    this.sub = this.route.params.subscribe((params) => {
      const id = params["id"];

      this.driverId = id;
      this.driverService.getDriverById(id).subscribe((driver) => {
        this.driver = driver;

        if (this.driver == null) {
          this.router.navigate(["/Drivers/Overview"]);
        }

        this.auditLogService.getAuditLogByDriver(id).subscribe({
          next: (deviceLog) => {
            console.log("loading done");
            this.driverLog = deviceLog;
            this.loading = false;
          },
          error: (error) => {
            this.error = error;
            this.loading = false;
          },
        });
      });
    });
  }
}
