<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/"> <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Devices/Overview">{{'general.devices' | translate }}</a>
                </li>
                <li class="breadcrumb-item active">{{'general.utilization' | translate }}</li>
            </ol>
        </nav>
    </div>
    <fh-device-navigation></fh-device-navigation>
</div>

<div class="wrapper wrapper-content">
    <div class="row" data-equalizer="">
        <div class="col-lg-12">
            <fh-error [error]="error" [success]="success" [warning]="warning"></fh-error>
        </div>

        <div class="col-lg-8">
            <!-- Custom filters -->
            <div class="ibox">
                <div class="ibox-title">
                    <h5><i class="fas fa-fw fa-filter"></i> {{'general.filter' | translate }}</h5>
                </div>
                <div class="ibox-content slick_toppadding" style="position: relative" *ngIf="languageLoaded">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <div id="dynamicFilters" class="flex-container">
                        <div class="col-lg-4">
                            <div class="form-group" style="margin-bottom: 6px; display: block">
                                <fh-reseller-input
                                    name="reseller"
                                    #resellerVar="ngModel"
                                    (onChanged)="resellerChanged($event)"
                                    required
                                    [tableInline]="false"
                                    placeholder="{{ 'general.chooseReseller' | translate}}"
                                    [(ngModel)]="selectedResellerId"
                                >
                                </fh-reseller-input>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group" style="margin-bottom: 6px; display: block">
                                <fh-account-input
                                    name="account"
                                    #accountVar="ngModel"
                                    (onChanged)="accountChanged($event)"
                                    required
                                    [activeOnly]="true"
                                    [selectedResellerId]="selectedResellerId"
                                    [tableInline]="false"
                                    [showEmptyAccountsButton]="false"
                                    placeholder="{{ (selectedResellerId ? 'general.chooseAccount' : 'general.firstChooseReseller') | translate}}"
                                    [(ngModel)]="selectedAccountId"
                                >
                                </fh-account-input>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group input-group" style="margin-bottom: 0px">
                                <input
                                    placeholder="Daterangepicker"
                                    style="width: 100%"
                                    placeholder="Datepicker"
                                    type="text"
                                    [bsConfig]="dpConfig"
                                    class="form-control"
                                    bsDaterangepicker
                                    (onHidden)="dateChanged('hidden')"
                                    [(ngModel)]="daterangepickerModel"
                                />
                                <span class="input-group-calendar-datatables">
                                    <i class="fa fa-calendar-alt"></i>
                                </span>
                                <div style="clear: both"></div>
                            </div>
                        </div>
                        <div style="clear: both"></div>
                        <div class="col-lg-4">
                            <div class="form-group" style="margin-bottom: 6px; display: block">
                                <fh-asset-group-input
                                    name="assetGroups"
                                    #assetGroupVar
                                    required
                                    [selectedAccountId]="selectedAccountId"
                                    [tableInline]="false"
                                    [multiple]="false"
                                    [autoSelect]="false"
                                    placeholder="{{ (selectedAccountId? 'general.chooseAssetGroups' : 'general.firstChooseAccount') | translate}}"
                                    [(ngModel)]="selectedAssetGroup"
                                >
                                </fh-asset-group-input>
                            </div>
                        </div>

                        <div class="col-lg-4">
                            <div class="form-group" style="margin-bottom: 6px; display: block">
                                <ng-select
                                    placeholder="{{ 'general.chooseVehicleType' | translate}}"
                                    [clearable]="true"
                                    class="form-control"
                                    name="vehicleType"
                                    required
                                    #vehicleType="ngModel"
                                    [(ngModel)]="selectedVehicleType"
                                    style="width: 100%"
                                >
                                    <ng-option *ngFor="let vehicleType of vehicleTypes" [value]="vehicleType.id">
                                        {{ ('enums.vehicleType.' + vehicleType.id) | translate }}
                                    </ng-option>
                                </ng-select>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group" style="margin-bottom: 6px; display: block">
                                <fh-asset-type-input
                                    name="assetTypes"
                                    #assetTypeVar
                                    [selectedAccountId]="selectedAccountId"
                                    [tableInline]="false"
                                    [multiple]="false"
                                    [autoSelect]="false"
                                    placeholder="{{ (selectedAccountId? 'general.chooseAssetTypes' : 'general.firstChooseAccount') | translate}}"
                                    [(ngModel)]="selectedAssetType"
                                >
                                </fh-asset-type-input>
                            </div>
                        </div>
                        <div style="clear: both"></div>
                        <div class="col-lg-4">
                            <div class="form-group" style="margin-bottom: 6px; display: block">
                                <fh-project-input
                                    name="project"
                                    #projectVar
                                    [selectedAccountId]="selectedAccountId"
                                    [tableInline]="false"
                                    [multiple]="false"
                                    [autoSelect]="false"
                                    placeholder="{{ (selectedAccountId? 'general.chooseProject' : 'general.firstChooseAccount') | translate}}"
                                    [(ngModel)]="selectedProjectId"
                                >
                                </fh-project-input>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group input-group" style="margin-bottom: 0px; display: block">
                                <ng-select
                                    class="form-control"
                                    name="deviceTypes"
                                    class="form-control"
                                    placeholder="{{ 'general.chooseDeviceType' | translate }}  ({{ deviceTypeOptions.length }})"
                                    [(ngModel)]="selectedDeviceType"
                                    style="width: 100%"
                                >
                                    <ng-option *ngFor="let deviceType of deviceTypeOptions" [value]="deviceType.id">
                                        {{ deviceType.value }}</ng-option
                                    >
                                </ng-select>
                                <div style="clear: both"></div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="fa_checkbox">
                                <input
                                    id="hideEmptyRows"
                                    type="checkbox"
                                    [checked]="false"
                                    [(ngModel)]="hideEmptyRows"
                                    (change)="onChangeCheckbox()"
                                />
                                <label style="display: flex; align-items: center" for="hideEmptyRows">
                                    <input
                                        disabled="disabled"
                                        style="pointer-events: none"
                                        class="form-control"
                                        placeholder="Filter zero values"
                                    />
                                </label>
                            </div>
                        </div>
                        <div style="clear: both"></div>
                    </div>
                </div>
                <div class="ibox-footer">
                    <button
                        *ngIf="!loading"
                        type="button"
                        name="search"
                        class="btn btn-primary"
                        (click)="dateChanged('hidden')"
                        [disabled]="selectedAccountId == null"
                    >
                        {{ 'general.fetchData' | translate}}
                    </button>

                    <button *ngIf="loading" type="button" name="cancel" class="btn btn-info" (click)="cancel()">
                        <i class="fas fa-fw fa-times"></i> {{ 'general.cancel' | translate}}
                    </button>
                </div>
            </div>
        </div>
        <div class="col-lg-4">
            <div class="ibox">
                <div class="ibox-title">
                    <h5><i class="fas fa-fw fa-square-info"></i> {{'general.legend' | translate }}</h5>

                    <span class="float-right">
                        <div class="hand btn btn-info filter-margin-fix" (click)="flipShowActualHours();">
                            <i
                                class="fa-duotone fa-fw fa-lg"
                                [ngClass]="showActualHours ? 'fa-toggle-on' : 'fa-toggle-off'"
                            ></i>
                        </div>
                    </span>
                </div>
                <div
                    class="ibox-content slick_toppadding flex-container"
                    style="position: relative; min-height: 166px"
                    *ngIf="languageLoaded"
                >
                    <ngx-loading [show]="loadingData"></ngx-loading>
                    <div class="col-lg-4 sked-legend">
                        <div>
                            <i class="fas fa-fw fa-square" style="color: #007a80"></i> {{'general.trip' | translate }}
                        </div>
                        <span>{{ showActualHours ? engineOnHours: engineOnPercentage }}</span>
                    </div>
                    <div class="col-lg-4 sked-legend">
                        <div>
                            <i class="fas fa-fw fa-square" style="color: #f2c80f"></i> {{'general.idling' | translate }}
                        </div>
                        <span>{{ showActualHours ? idlingHours :idlingPercentage }}</span>
                    </div>
                    <div class="col-lg-4 sked-legend">
                        <div>
                            <i class="fas fa-fw fa-square" style="color: rgba(255, 255, 255, 0.05)"></i>
                            {{'general.schedule' | translate }}
                        </div>
                        <span>-</span>
                    </div>
                    <!-- <div class="col-lg-6 sked-legend">
                        <div><i class="fas fa-fw fa-square" style="color: #01b8aa"></i> {{'general.work' | translate }}
                        </div>
                        <span>{{workingPercentage}}</span>
                    </div>
                    <div class="col-lg-3 sked-legend">
                        <div><i class="fas fa-fw fa-square" style="color: #A8973B"></i> {{'general.crossover' |
                            translate }}</div>
                        <span
                           >{{crossoverPercentage}}</span>
                    </div> -->
                    <div style="clear: both"></div>
                </div>
            </div>
        </div>
        <div class="col-lg-12">
            <div class="ibox">
                <div class="ibox-title">
                    <h5><i class="fas fa-fw fa-calendar-days"></i> {{'general.utilization' | translate }}</h5>
                    <span class="float-right">
                        <div class="filter-form d-none d-md-block">
                            <input
                                type="text"
                                class="form-control table-inline top-filter d-none d-md-block"
                                [(ngModel)]="stringFilter"
                                (input)="deferSearch$.subscribe()"
                            />
                        </div>
                    </span>
                </div>
                <div class="ibox-content slick" style="position: relative">
                    <ngx-loading [show]="loadingData"></ngx-loading>
                    <div class="sked-utility" id="skedGantt"></div>
                    <div
                        *ngFor="let _ of totalItems | stringFilter: stringFilter | paginate: { itemsPerPage, currentPage }"
                    ></div>
                    <span style="padding: 10px; display: block" *ngIf="!totalItems || totalItems?.length == 0"
                        >{{'general.noDataFound' | translate}}</span
                    >
                </div>
                <div class="ibox-footer noselect dataTables_wrapper">
                    <pagination-template #p="paginationApi" (pageChange)="currentPage = $event; renderData()">
                        <div class="dataTables_paginate paging_simple_numbers" id="dataTable_paginate">
                            <ul class="pagination">
                                <li class="paginate_button page-item previous" [class.disabled]="p.isFirstPage()">
                                    <a class="page-link" (click)="p.previous()" *ngIf="!p.isFirstPage()"
                                        >{{ 'grid.previous' | translate }}</a
                                    >
                                    <a *ngIf="p.isFirstPage()">{{ 'grid.previous' | translate }}</a>
                                </li>

                                <li
                                    class="paginate_button page-item"
                                    *ngFor="let page of p.pages"
                                    [class.current]="p.getCurrent() === page.value"
                                >
                                    <a
                                        class="page-link"
                                        (click)="p.setCurrent(page.value)"
                                        *ngIf="p.getCurrent() !== page.value"
                                    >
                                        <span>{{ page.label }}</span>
                                    </a>
                                    <a
                                        class="page-link"
                                        (click)="p.setCurrent(page.value)"
                                        *ngIf="p.getCurrent() === page.value"
                                    >
                                        <span style="font-weight: bold">{{ page.label }}</span>
                                    </a>
                                </li>

                                <li class="paginate_button page-item next" [class.disabled]="p.isLastPage()">
                                    <a class="page-link" (click)="p.next()" *ngIf="!p.isLastPage()">
                                        {{ 'grid.next' | translate }}
                                    </a>
                                    <a class="page-link" *ngIf="p.isLastPage()"> {{ 'grid.next' | translate }} </a>
                                </li>
                            </ul>
                        </div>
                    </pagination-template>
                    <div style="clear: both"></div>
                </div>
            </div>
        </div>
    </div>
</div>
