import { Component, OnDestroy, OnInit } from "@angular/core";

@Component({
  selector: "fh-placeholder",
  templateUrl: "placeHolder.component.html",
})
export class PlaceholderViewComponent implements OnDestroy, OnInit {
  constructor() {}

  ngOnDestroy(): void {}

  ngOnInit(): void {}
}
