<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/"> <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item" *ngIf="driver?.accountId">
                    <a href="/#/AccountDetails/Index/{{driver?.accountId}}">{{driver?.companyName}}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Drivers/Overview">{{'general.driver' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/DriverDetails/Index/{{driver?.id}}">{{driver?.firstName}} {{driver?.lastName}}</a>
                </li>
                <li class="breadcrumb-item active">{{'general.dashboard' | translate }}</li>
            </ol>
        </nav>
    </div>
    <fh-driver-details-navigation [driverId]="driverId"></fh-driver-details-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">
        <div class="row" data-equalizer="" *ngIf="error || warning || success">
            <div class="col-lg-12">
                <fh-error [error]="error" [warning]="warning" [success]="success"></fh-error>
            </div>
        </div>

        <div class="row" data-equalizer="">
            <div class="col-lg-6">
                <form #userForm="ngForm">
                    <div class="ibox" style="position: relative">
                        <div class="ibox-title">
                            <h5><i class="fa fa-id-card-alt"></i> {{'general.driver' | translate }}</h5>
                        </div>
                        <fh-driver-details [driver]="driver" [formMode]="formMode" [loading]="loading || saving">
                        </fh-driver-details>
                        <fh-save-footer
                            [valid]="!userForm.invalid"
                            (onSave)="onSave()"
                            [allowEdit]="permissions['Drivers_Edit']"
                            (onDelete)="onDelete()"
                            [allowDelete]="permissions['Drivers_Delete']"
                            (setFormMode)="setFormMode($event)"
                            [formMode]="formMode"
                        >
                            <span class="float-right">
                                <fh-task-dropdown
                                    (onFinishAction)="finishTask($event)"
                                    #taskDropdown
                                    [entityTypes]="tagType"
                                    [entity]="driver"
                                ></fh-task-dropdown>
                            </span>
                        </fh-save-footer>
                    </div>
                </form>

                <fh-custom-fields
                    *ngIf="driver.properties?.custom"
                    [formMode]="formMode"
                    [(customFields)]="driver.properties.custom"
                >
                </fh-custom-fields>
            </div>
            <div class="col-lg-6">
                <fh-driver-devices [deviceAssignments]="driverAssignments" [loading]="loadingAssignments">
                </fh-driver-devices>

                <fh-group-overview
                    [loading]="loadingGroups"
                    [groups]="driverGroups"
                    [deletePermission]="'Drivers_Edit'"
                    name="{{'general.driverGroups' | translate }}"
                    [childId]="driver?.id"
                    [linkUrl]="driverGroupLink"
                    (onFinish)="getDriverInfo()"
                >
                    <span
                        groupAddSlot
                        (click)="confirmationModal.showModal(driver.id)"
                        *ngIf="permissions['DriverGroups_Edit']"
                        [title]="'general.addToGroup' | translate"
                        container="body"
                    >
                        <button class="btn btn-primary">
                            <i class="fa fa-fw fa-add"></i> {{ 'general.assign' | translate}}
                        </button>
                    </span>
                </fh-group-overview>

                <fh-tagging [objectId]="driver?.id" [objectType]="tagType"> </fh-tagging>
            </div>
        </div>
    </div>
</fh-permission-handler>

<fh-confirmation-modal
    #confirmationModal
    (onFinish)="addToGroup($event, confirmationModal, groupInputVar)"
    [invalid]="!(groupInputVar.value?.length > 0)"
>
    <h4 header class="modal-title pull-left">
        <i class="fa fa-fw fa-bolt"></i> {{'general.action' | translate }}: {{ 'general.addToGroup' | translate }}
    </h4>
    <form body #groupForm="ngForm" style="width: 100%; border-top: 0px">
        <div class="modal-body">
            <fh-driver-group-input
                #groupInputVar
                ngDefaultControl
                required
                [tableInline]="false"
                [selectedAccountId]="driver.accountId"
                [canAddNew]="true"
                [hideSystemGroups]="true"
                [autoSelect]="false"
                placeholder="{{ 'general.chooseDriverGroups' | translate }}"
            >
            </fh-driver-group-input>
        </div>
    </form>
</fh-confirmation-modal>
