<div class="col-lg-6">
    <div class="ibox">
        <div class="ibox-title">
            <h5><i class="fas fa-fw fa-filter"></i> {{ "general.filter" | translate }}</h5>
        </div>
        <div class="ibox-content slick_toppadding flex-container" style="position: relative">
            <div class="col-lg-12">
                <div class="form-group input-group" style="margin-bottom: 5px">
                    <span class="input-group-prepend">
                        <i class="fa fa-fw fa-calendar"></i>
                    </span>
                    <input
                        placeholder="Daterangepicker"
                        style="width: 100%"
                        placeholder="Datepicker"
                        type="text"
                        [bsConfig]="dpConfig"
                        class="form-control"
                        bsDaterangepicker
                        [maxDate]="maxDate"
                        (onHidden)="dateChanged('hidden')"
                        [(ngModel)]="daterangepickerModel"
                    />
                    <span class="input-group-calendar-datatables">
                        <i class="fa fa-calendar-alt"></i>
                    </span>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="form-group input-group" style="margin-bottom: 5px">
                    <span class="input-group-prepend">
                        <i class="fa fa-fw fa-bolt"></i>
                    </span>
                    <ng-select
                        class="form-control"
                        [(ngModel)]="selectType"
                        placeholder="{{ 'general.notificationType' | translate }}"
                    >
                        <ng-option *ngFor="let notificationType of notificationTypes" [value]="notificationType">
                            {{ "enums.notificationType." + notificationType | translate }}
                        </ng-option>
                    </ng-select>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="form-group input-group" style="margin-bottom: 5px">
                    <span class="input-group-prepend">
                        <i class="fa fa-fw fa-router"></i>
                    </span>
                    <ng-select
                        class="form-control"
                        [(ngModel)]="selectMethod"
                        placeholder="{{ 'general.notificationMethod' | translate }}"
                    >
                        <ng-option *ngFor="let notificationMethod of notificationMethods" [value]="notificationMethod">
                            {{ "enums.notificationMethod." + notificationMethod | translate }}
                        </ng-option>
                    </ng-select>
                </div>
            </div>
            <div style="clear: both"></div>
        </div>
    </div>

    <div class="ibox">
        <div class="ibox-title">
            <h5><i class="fas fa-fw fa-envelope-open-text"></i> {{ "general.notifications" | translate }}</h5>
            <span class="float-right">
                <div class="filter-form d-none d-md-block">
                    <input
                        type="text"
                        class="form-control table-inline top-filter d-none d-md-block"
                        (ngModelChange)="p.setCurrent(0)"
                        (ngModelChange)="p.setCurrent(0)"
                        [(ngModel)]="filter"
                    />
                </div>
            </span>
        </div>

        <div class="ibox-content slick" style="position: relative; overflow-y: hidden">
            <ngx-loading [show]="loading"> </ngx-loading>
            <table style="width: 100%" class="table nowrap dtr-inline no-footer">
                <thead>
                    <tr>
                        <th style="width: 140px">
                            {{ "general.date" | translate }}
                        </th>
                        <th style="min-width: 100px">
                            {{ "general.recipient" | translate }}
                        </th>
                        <th style="min-width: 100px">
                            {{ "general.subject" | translate }}
                        </th>
                        <th style="min-width: 100px">
                            {{ "general.assetName" | translate }}
                        </th>
                        <th style="min-width: 100px">
                            {{ "general.notificationType" | translate }}
                        </th>
                        <th style="min-width: 100px">
                            {{ "general.notificationMethod" | translate }}
                        </th>
                        <th style="min-width: 100px">
                            {{ "general.responseCode" | translate }}
                        </th>
                    </tr>
                </thead>
                <tbody *ngIf="notifications.length > 0">
                    <ng-container
                        *ngFor="
                            let notification of notifications
                                | stringFilter : filter
                                | filter : 'notificationMethod' : selectMethod
                                | filter : 'notificationType' : selectType
                                | paginate : { itemsPerPage: 10, currentPage: currentPage }
                        "
                    >
                        <tr (click)="selectedNotification = notification" class="hand">
                            <td title="{{ notification.timeStampSending?.toLocaleString() }}">
                                {{ notification.timeStampSending | amDateFormat : "LLL" }}
                            </td>
                            <td class="elipses_overflow">
                                {{ notification.recipient }}
                            </td>
                            <td class="elipses_overflow">
                                {{ notification.subject ?? "-" }}
                            </td>
                            <td class="elipses_overflow">
                                {{ notification.assetName }}
                            </td>
                            <td class="elipses_overflow">
                                {{ "enums.notificationType." + notification.notificationType | translate }}
                            </td>
                            <td class="elipses_overflow">
                                {{ "enums.notificationMethod." + notification.notificationMethod | translate }}
                            </td>
                            <td class="elipses_overflow">
                                {{ notification.responseCode }}
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
                <tbody *ngIf="notifications.length == 0">
                    <tr>
                        <td colspan="6">
                            {{ "general.noDataFound" | translate }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="ibox-footer noselect dataTables_wrapper">
            <pagination-template #p="paginationApi" (pageChange)="currentPage = $event">
                <div class="dataTables_paginate paging_simple_numbers" id="dataTable_paginate">
                    <ul class="pagination">
                        <li class="paginate_button page-item previous" [class.disabled]="p.isFirstPage()">
                            <a class="page-link" (click)="p.previous()" *ngIf="!p.isFirstPage()">{{
                                "grid.previous" | translate
                            }}</a>
                            <a *ngIf="p.isFirstPage()">{{ "grid.previous" | translate }}</a>
                        </li>

                        <li
                            class="paginate_button page-item"
                            *ngFor="let page of p.pages"
                            [class.current]="p.getCurrent() === page.value"
                        >
                            <a
                                class="page-link"
                                (click)="p.setCurrent(page.value)"
                                *ngIf="p.getCurrent() !== page.value"
                            >
                                <span>{{ page.label }}</span>
                            </a>
                            <a
                                class="page-link"
                                (click)="p.setCurrent(page.value)"
                                *ngIf="p.getCurrent() === page.value"
                            >
                                <span style="font-weight: bold">{{ page.label }}</span>
                            </a>
                        </li>

                        <li class="paginate_button page-item next" [class.disabled]="p.isLastPage()">
                            <a class="page-link" (click)="p.next()" *ngIf="!p.isLastPage()">
                                {{ "grid.next" | translate }}
                            </a>
                            <a class="page-link" *ngIf="p.isLastPage()"> {{ "grid.next" | translate }} </a>
                        </li>
                    </ul>
                </div>
            </pagination-template>
            <div style="clear: both"></div>
        </div>
    </div>
</div>
<div class="col-lg-6">
    <div class="ibox">
        <div class="ibox-title">
            <h5><i class="fas fa-fw fa-envelope-open-text"></i> {{ "general.notifications" | translate }}</h5>
        </div>
        <div class="ibox-content slick" style="position: relative">
            <div *ngIf="!selectedNotification" style="padding: 12px !important">
                {{ "general.noNotificationSelected" | translate }}
            </div>
            <table
                style="width: 100%"
                class="table nowrap dtr-inline no-footer table-fixed"
                *ngIf="selectedNotification"
            >
                <tbody>
                    <tr>
                        <td width="120px">{{ "general.asset" | translate }}</td>
                        <td class="row_content">
                            <a
                                class="secondary link_bolder"
                                href="/#/DeviceDetails/Index/{{ selectedNotification?.deviceId }}"
                                >{{ selectedNotification.assetName }}</a
                            >
                        </td>
                    </tr>
                    <tr>
                        <td width="120px">{{ "general.date" | translate }}</td>
                        <td class="row_content" title="{{ selectedNotification.timeStampSending?.toLocaleString() }}">
                            {{ selectedNotification.timeStampSending | amDateFormat : "LLL" }}
                        </td>
                    </tr>
                    <tr>
                        <td width="120px">{{ "general.recipient" | translate }}</td>
                        <td class="row_content">{{ selectedNotification.recipient }}</td>
                    </tr>
                    <tr>
                        <td width="120px">{{ "general.user" | translate }}</td>
                        <td class="row_content">
                            <a
                                class="secondary link_bolder"
                                href="/#/UserDetails/Index/{{ selectedNotification?.fkUserId }}"
                                >{{ selectedNotification.emailLoginName }}</a
                            >
                        </td>
                    </tr>
                    <tr>
                        <td width="120px">{{ "general.notificationType" | translate }}</td>
                        <td class="row_content">
                            {{ "enums.notificationType." + selectedNotification.notificationType | translate }}
                        </td>
                    </tr>
                    <tr>
                        <td width="120px">{{ "general.notificationMethod" | translate }}</td>
                        <td class="row_content">
                            {{ "enums.notificationMethod." + selectedNotification.notificationMethod | translate }}
                        </td>
                    </tr>
                    <tr *ngIf="selectedNotification?.triggerId">
                        <td width="120px">{{ "general.trigger" | translate }}</td>
                        <td class="row_content">
                            <a
                                class="secondary link_bolder"
                                href="/#/Notifications/TriggerDetails/Index/{{ selectedNotification?.triggerId }}"
                                >{{ selectedNotification.triggerName }}</a
                            >
                        </td>
                    </tr>
                    <tr *ngIf="selectedNotification?.responseCode">
                        <td width="120px">{{ "general.responseCode" | translate }}</td>
                        <td class="row_content">{{ selectedNotification?.responseCode }}</td>
                    </tr>
                    <tr>
                        <td width="120px">{{ "general.subject" | translate }}</td>
                        <td class="row_content">{{ selectedNotification.subject }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="ibox-content" *ngIf="selectedNotification" style="position: relative">
            <pretty-json *ngIf="checkJson(selectedNotification?.message)" [obj]="selectedNotification?.message">
            </pretty-json>

            <span
                style="overflow: auto; display: block; white-space: pre-line"
                *ngIf="!checkJson(selectedNotification?.message)"
                [innerHTML]="selectedNotification.message"
            >
            </span>
        </div>
    </div>

    <div class="ibox" *ngIf="selectedNotification?.responseMessage">
        <div class="ibox-title">
            <h5><i class="fas fa-fw fa-envelope-open-text"></i> {{ "general.response" | translate }}</h5>
        </div>
        <div class="ibox-content" style="position: relative">
            <pretty-json
                *ngIf="checkJson(selectedNotification?.responseMessage)"
                [obj]="selectedNotification?.responseMessage"
            >
            </pretty-json>

            <span
                style="overflow: auto; display: block"
                *ngIf="!checkJson(selectedNotification?.responseMessage)"
                [innerHTML]="selectedNotification.responseMessage"
            >
            </span>
        </div>
    </div>
</div>
