// Status Markers 
! function(t, e) {
    "use strict";
    L.StatusMarker = {}, L.StatusMarker.version = "1.0.1", L.StatusMarker.Icon = L.Icon.extend({
        options: {
            iconSize: [35, 45],
            iconAnchor: [17, 42],
            popupAnchor: [1, -32],
            shadowAnchor: [10, 12],
            shadowSize: [36, 16],
            className: "extra-marker",
            iconUrl: "",
            rotate: 0,
            prefix: "",
            extraClasses: "",
            shape: "circle",
            icon: "",
            innerHTML: "",
            markerColor: "red",
            iconColor: "#fff",
            number: "",
            translate: {
                x: '0px',
                y: '0px',
                z: '0px'
            }
        },
        initialize: function(t) {
            t = L.Util.setOptions(this, t)
        },
        createIcon: function() {
            var t = e.createElement("div"),
                o = this.options;

            if (!o.shape) {
                return t;
            }

            return o.icon && (t.innerHTML = this._createInner()), o.innerHTML && (t.innerHTML = o.innerHTML), o.bgPos && (t.style.backgroundPosition = -o.bgPos.x + "px " + -o.bgPos.y + "px"), this._setIconStyles(t, o.shape + "-" + o.markerColor), t
        },
        _createInner: function() {
            var t = "",
                e = "",
                o = this.options;
            return o.iconColor && (t = "style='color: " + o.iconColor + ";transform: rotate(" + o.rotate + "deg);-webkit-transform: rotate(" + o.rotate + "deg);-moz-transform: rotate(" + o.rotate + "deg);-ms-transform: rotate(" + o.rotate + "deg);-o-transform: rotate(" + o.rotate + "deg);'"), o.number && (e = "number='" + o.number + "' "), "<i " + e + t + "class='" + o.extraClasses + " " + o.prefix + " " + o.icon + "'></i>"
        },
        _setIconStyles: function(t, e) {
            var o, r, n = this.options,
                i = L.point(n["shadow" === e ? "shadowSize" : "iconSize"]);
            "shadow" === e ? (o = L.point(n.shadowAnchor || n.iconAnchor), r = "shadow") : (o = L.point(n.iconAnchor), r = "icon"), !o && i && (o = i.divideBy(2, !0)), t.className = "leaflet-marker-" + r + " extra-marker-" + e + " " + n.className, o && (t.style.marginLeft = -o.x + "px", t.style.marginTop = -o.y + "px"), i && (t.style.width = i.x + "px", t.style.height = i.y + "px")
        },
        createShadow: function() {
            var t = e.createElement("div"),
                o = this.options;

            this.img = document.createElement("img");
            this.img.style.transform = `translate3d(${o.translate.x}, ${o.translate.y}, ${o.translate.z})`;

            return this.img.src = o.iconUrl, t.appendChild(this.img), this._setIconStyles(t, "shadow"), t
        }
    }), L.StatusMarker.icon = function(t) {
        return new L.StatusMarker.Icon(t)
    }
}(window, document);