import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from "@angular/core";
import { BsModalRef, ModalDirective } from "ngx-bootstrap/modal";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "fh-confirmation-modal",
  templateUrl: "confirmationModal.template.html",
})
export class ConfirmationModalComponent implements OnChanges {
  @Output() onFinish = new EventEmitter();
  @Output() onHide = new EventEmitter();
  @Input() message = "general.warningDelete";
  @Input() title = "";
  @Input() invalid = false;
  @Input() showButtons = true;

  @ViewChild("autoShownModal") autoShownModal: ModalDirective;
  modalRef: BsModalRef;
  isModalShown = false;
  activeId;

  constructor(private translateService: TranslateService) {}

  ngOnChanges(): void {}

  // Model
  public showModal(id): void {
    if (this.title.length === 0) {
      this.title = this.translateService.instant("general.areyousure");
    }
    this.activeId = id;
    this.isModalShown = true;
  }

  public hideModal(): void {
    this.decline();
  }

  finish() {
    this.onFinish.emit(this.activeId);
  }

  decline(): void {
    this.onHide.emit(true);
    this.autoShownModal.hide();
  }

  onHidden(): void {
    this.isModalShown = false;
  }
}
