<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/"> <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Reporting">{{'general.reporting' | translate }}</a>
                </li>
                <li class="breadcrumb-item active">{{'general.edit' | translate }}</li>
            </ol>
        </nav>
    </div>
</div>

<div class="wrapper wrapper-content">
    <div class="row">
        <div class="col-lg-12">
            <fh-error [success]="success" [error]="error"></fh-error>
        </div>
    </div>
    <fh-report-wizard
        [reportSubscription]="reportSubscription"
        [formMode]="formMode"
        (finish)="onCheckOutFinish()"
        [prefillWizard]="prefillWizard"
        [sendOutputMessage]="sendOutputMessage"
        (subscription)="reportSubscription = $event"
        (reportWizard)="wizard = $event"
    ></fh-report-wizard>
</div>
