import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { AuthenticationService } from "app/services/authentication/authentication.service";

import { ErrorLogService } from "app/services/logging/error-log.service";
import { ErrorLog } from "app/models/errorlog.model";

// Moment timezone
import Moment from "moment-timezone";

window["moment"] = Moment;

@Component({
  selector: "fh-error-log-details",
  templateUrl: "errorLogDetails.template.html",
})
export class ErrorLogDetailsViewComponent implements OnInit {
  permissions: {};
  loading = false;
  error;
  errorLog: ErrorLog;
  sub: any;

  permissionName = "Platform_IsAdministrator";

  constructor(
    private router: Router,
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute,
    private authentication: AuthenticationService,
    private errorLogService: ErrorLogService
  ) {
    this.permissions = this.authentication.permissions;
  }

  ngOnInit() {
    this.loading = true;
    this.sub = this.route.params.subscribe((params) => {
      const id = params["id"];

      this.getErrorLogDetails(id);
    });
  }

  getErrorLogDetails(id = 0) {
    this.loading = true;
    this.cd.markForCheck();

    if (id === 0) {
      id = this.errorLog.id;
    }

    this.errorLogService.getErrorLogById(id.toString()).subscribe(
      (errorLog) => {
        this.errorLog = errorLog;

        this.loading = false;
        this.cd.markForCheck();
      },
      (error) => {
        this.error = error;
        this.error.statusText = "Error fetching Error Details";
        this.cd.markForCheck();

        setTimeout(() => {
          this.router.navigate(["/System/ErrorLog/Overview"]);
        }, 3000);
      }
    );
  }
}
