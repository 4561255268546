import { ChangeDetectorRef, Component, Input, OnChanges, OnInit } from "@angular/core";
import { FormMode, TriggerRules } from "app/common/enums";
import { Trigger } from "app/models/trigger.model";
import { FhChartService } from "app/services/charts/charts.service";
import { GeofenceGroupsService } from "app/services/geofence/geofenceGroups.service";
import { TriggerScheduleService } from "app/services/triggerSchedule/triggerSchedule.service";

@Component({
  providers: [FhChartService],
  selector: "fh-trigger-rules",
  templateUrl: "triggerRules.component.html",
})
export class TriggerRulesViewComponent implements OnInit, OnChanges {
  @Input() trigger: Trigger;
  @Input() formMode: any = FormMode.read;

  triggerScheduleNames: any[] = [];
  geofenceGroups;
  geofenceItems = [];
  allTriggerRules: typeof TriggerRules;

  constructor(
    private cd: ChangeDetectorRef,
    private triggerScheduleService: TriggerScheduleService,
    private geofenceGroupService: GeofenceGroupsService
  ) {
    this.allTriggerRules = TriggerRules;
  }

  ngOnInit(): void {
    this.setValues();
  }

  ngOnChanges(): void {
    console.log("Changes");

    this.setValues();
  }

  getScheduleName(rule, index: number) {
    this.triggerScheduleService.getTriggerScheduleById(rule.templateId).subscribe((schedule) => {
      this.triggerScheduleNames[index] = schedule.triggerScheduleName;
      console.log(schedule.triggerScheduleName);
      this.cd.markForCheck();
    });
  }

  removeRule(rule) {
    const index: number = this.trigger.rules.indexOf(rule);
    if (index !== -1) {
      this.trigger.rules.splice(index, 1);
    }
  }

  public refresh() {
    console.log("Refresh");

    this.setValues();
  }

  setValues() {
    console.log("Setting values");
    this.trigger.rules.forEach((rule) => {
      const index: number = this.trigger.rules.indexOf(rule);

      // Set schedule name
      if (rule.ruleType === TriggerRules.Schedule) {
        this.getScheduleName(rule, index);
      }

      // Set geofence id's
      if (rule.ruleType === TriggerRules.Geofences && (rule.geofenceSource || rule.geofences || rule.geofenceGroups)) {
        if (this.geofenceGroups == null) {
          this.geofenceGroupService.getGeofenceGroups(this.trigger.accountId, true).subscribe((result) => {
            this.geofenceGroups = result;

            this.geofenceGroups.forEach((geofenceGroup) => {
              if (geofenceGroup.geofenceGroupItems && geofenceGroup.geofenceGroupItems.length > 0) {
                this.geofenceItems = this.geofenceItems.concat(geofenceGroup.geofenceGroupItems);
              }
            });

            this.nameGeofences(rule);
            this.cd.markForCheck();
          });
        } else {
          this.nameGeofences(rule);
        }
      }
    });
  }

  nameGeofences(rule) {
    rule.geofenceDescription = "";

    if (rule.geofenceSource) {
      rule.geofenceGroups = [];
      rule.geofences = [];

      rule.geofenceSource.forEach((element) => {
        if (typeof element === "number") {
          rule.geofences.push(element);
        } else {
          if (rule.geofenceGroups.indexOf(element[0].geofenceGroupId) === -1) {
            rule.geofenceGroups.push(element[0].geofenceGroupId);
          }
        }
      });

      rule.geofenceSource = null;
    }

    // Name the geofenceGroups
    rule.geofenceGroups?.forEach((geofenceGroupId) => {
      if (rule.geofenceDescription !== "") {
        rule.geofenceDescription += ", ";
      }
      rule.geofenceDescription += this.geofenceGroups.find((x) => x.id === geofenceGroupId)?.name;
    });

    // Name the geofences
    rule.geofences?.forEach((geofenceId) => {
      if (rule.geofenceDescription !== "") {
        rule.geofenceDescription += ", ";
      }

      rule.geofenceDescription += this.geofenceItems.find((x) => x.id === geofenceId)?.geofenceName;
    });
  }
}
