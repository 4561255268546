import {
  Component,
  AfterViewInit,
  Input,
  ElementRef,
  ViewChild,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from "@angular/core";

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { NgSelectComponent } from "@ng-select/ng-select";
import { GeofenceGroup } from "app/models/group.model";
import { GeofenceGroupsService } from "app/services/geofence/geofenceGroups.service";

const noop = () => {};

@Component({
  selector: "fh-geofence-group-input",
  templateUrl: "./geofenceGroupSelector.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: GeofenceGroupInputComponent }],
})
export class GeofenceGroupInputComponent implements OnChanges, OnInit, AfterViewInit, ControlValueAccessor {
  @ViewChild("input", { static: false }) selector: NgSelectComponent;

  @Input() placeholder: string;
  @Input() clearable = true;
  @Input() selectedAccountId;
  @Input() autoSelect = true;
  @Input() readonly = false;
  @Input() multiple = true;
  @Input() canAddNew = false;
  @Input() hideSystemGroups = false;

  @Output() onChanged = new EventEmitter();

  geofenceGroups = [];

  serverResponses = {};
  createdItems = [];

  loadingGeofenceGroups = false;

  @ViewChild("input") el: ElementRef;

  private _value: string;
  private _onChange: (_: any) => void = noop;

  get value(): any {
    return this._value;
  }

  set value(v: any) {
    if (v !== this._value) {
      this._value = v;
      this._onChange(v);
      this.cd.markForCheck();
    }
  }

  constructor(private geofenceGroupService: GeofenceGroupsService, private cd: ChangeDetectorRef) {}

  createNew(displayName: string) {
    this.loadingGeofenceGroups = true;
    this.serverResponses[displayName] = false;

    this.cd.markForCheck();

    const itemId = this.createdItems.length;
    this.createdItems[itemId] = -1;

    const geofenceGroup = new GeofenceGroup();
    geofenceGroup.accountId = this.selectedAccountId;
    geofenceGroup.name = displayName;

    this.geofenceGroupService.saveGeofenceGroup(geofenceGroup).subscribe({
      next: (result) => {
        if (result?.isSuccess) {
          this.serverResponses[displayName] = false;
          this.createdItems[itemId] = result.id;
        }

        this.selector["_updateNgModel"]();
        this.loadingGeofenceGroups = false;
        this.cd.markForCheck();
      },
      error: (error) => {
        this.serverResponses[displayName] = error.error;

        this.selector["_updateNgModel"]();
        this.loadingGeofenceGroups = false;
        this.cd.markForCheck();
      },
    });

    const that = this;
    return {
      get id(): any {
        return that.createdItems[itemId];
      },
      itemCount: 0,
      displayName,
    };
  }

  geofenceGroupChanged() {
    this.onChanged.emit(this.value);
    this.cd.markForCheck();
  }

  ngOnChanges() {
    setTimeout(() => {
      // Get accounts again
      this.loadGeofenceGroups();
      this.cd.markForCheck();
    }, 0);
  }

  showAll(event, geofenceGroup) {
    geofenceGroup.showAll = !geofenceGroup.showAll;
    event.stopPropagation();
  }

  loadGeofenceGroups(callback = () => {}) {
    this.geofenceGroups = [];
    this.cd.markForCheck();

    if (this.selectedAccountId) {
      this.loadingGeofenceGroups = true;
      this.cd.markForCheck();

      if (this.selectedAccountId && this.selectedAccountId !== 0) {
        this.geofenceGroupService.getGeofenceGroups(this.selectedAccountId, true).subscribe((result) => {
          result = result.sort((a, b) => (a.name < b.name ? -1 : 1));
          this.geofenceGroups = result;

          if (this.hideSystemGroups) {
            this.geofenceGroups = this.geofenceGroups.filter((x) => x.name !== "MyManagedGeofences");
          }

          // Autoselect
          if (!this.readonly && this.geofenceGroups.length === 1 && this.autoSelect) {
            console.log("Setting default to " + this.geofenceGroups[0].id);
            this.value = this.multiple ? [this.geofenceGroups[0].id] : this.geofenceGroups[0].id;
          }

          this.geofenceGroups = this.geofenceGroups.sort((a, b) => {
            return a.displayName.localeCompare(b.displayName, "en", { sensitivity: "base" });
          });

          this.loadingGeofenceGroups = false;
          this.cd.markForCheck();

          callback();
        });
      } else {
        this.loadingGeofenceGroups = false;
        this.cd.markForCheck();
      }
    }
  }

  ngOnInit() {}

  ngAfterViewInit() {
    const __this = this;
  }

  writeValue(value: any) {
    this._value = value;
    this.cd.markForCheck();
  }

  registerOnChange(fn: (value: any) => void) {
    this._onChange = fn;
    this.cd.markForCheck();
  }

  registerOnTouched(fn: any) {
    this.cd.markForCheck();
  }
}
