<fh-wizard [(step)]="step" (finish)="onCheckOutFinish()">
    <fh-wizard-step icon="fa-car-bus" [validate]="userForm" [padding]="false">
        <form #userForm="ngForm">
            <ng-container *ngIf="selectedDevice?.id; else loadingDevice">
                <fh-devicesettingchanges [deviceSettingChange]="deviceSettingChange"></fh-devicesettingchanges>
            </ng-container>
            <ng-template #loadingDevice>
                <ngx-loading [show]="true"></ngx-loading>
            </ng-template>
        </form>
    </fh-wizard-step>
    <fh-wizard-step title="Remarks" icon="fa-file-pdf">
        <div class="wizard-content" style="padding-bottom: 10px">{{ 'wizard.optionalComment' | translate }}</div>
        <div class="wizard-content">
            <textarea
                class="form-control noresize stickypad"
                style="height: 100px; width: 100%; resize: none"
                placeholder="{{ 'placeholder.comments' | translate}}"
                [(ngModel)]="logItem"
            ></textarea>
        </div>
    </fh-wizard-step>
    <fh-wizard-step title="Summary" icon="fa-summary-pdf">
        <div
            class="wizard-content"
            style="padding-bottom: 10px"
            [innerHTML]="'wizard.summary' | translate:{ count: gridSelection.length }"
        ></div>
        <div class="ibox-content slick popupOverflow" style="position: relative; min-height: 72px; overflow: auto">
            <table class="table nowrap dtr-inline no-footer table-summary">
                <thead>
                    <tr>
                        <th class="small_padding">{{ 'general.imei' | translate}}</th>
                        <th class="small_padding">{{ 'general.settingId' | translate}}</th>
                        <th *ngIf="deviceSettingChange?.settingValue" class="small_padding">
                            {{ 'general.settingValue' | translate}}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let id of gridSelection; let i = index;">
                        <tr>
                            <td class="small_padding">
                                <input [value]="deviceImeiDict[id]" readonly class="form-control table-inline" />
                            </td>
                            <td class="small_padding">
                                <input
                                    [value]="('enums.deviceSettingChange.' + deviceSettingChange?.settingId) | translate"
                                    readonly
                                    class="form-control table-inline"
                                />
                            </td>
                            <td *ngIf="deviceSettingChange?.settingValue" class="small_padding">
                                <input
                                    [value]="deviceSettingChange.settingValue"
                                    readonly
                                    class="form-control table-inline"
                                />
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </fh-wizard-step>
</fh-wizard>
