import { ChangeDetectorRef, Component, OnDestroy } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { getGridButtons, getGridLanguages, getDefaultDpConfig, createdCellCheckbox } from "app/common/gridhelper";
import { AuthenticationService } from "../../services/authentication/authentication.service";
import { GridBase360Directive } from "app/common/360Grid.base";
import { SignalrService } from "app/services/signalr/signalr.service";
import { ColorService } from "app/services/common/color.service";
import { roundAsNumber, roundAsString, roundSeconds } from "app/common/globals";

import { ReportService } from "app/services/reports/report.service";
import { BsDaterangepickerConfig } from "ngx-bootstrap/datepicker";

import { StorageHelper } from "app/common/storagehelper";
import { forkJoin } from "rxjs/internal/observable/forkJoin";

// Moment
import Moment from "moment-timezone";

window["moment"] = Moment;

@Component({
  selector: "fh-reporting-executions",
  templateUrl: "executions.template.html",
})
export class ReportingViewComponent extends GridBase360Directive implements OnDestroy {
  token: string;

  loading = false;
  languageLoaded: boolean;
  permissions: {};

  availableReports = {};

  excludingColumns = ["timestamp"];

  success;
  error;

  randomKey;

  constructorName = "ReportingViewComponent";
  permissionName = "FleetManagement_Reporting";
  timezoneIana: string;

  // Daterange
  public dpConfig: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();
  daterangepickerModel: Date[];

  constructor(
    public signalrService: SignalrService,
    private translateService: TranslateService,
    private cd: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private reportService: ReportService,
    protected storageHelper: StorageHelper,
    private colorService: ColorService
  ) {
    super(storageHelper);

    this.loading = true;

    this.permissions = this.authenticationService.permissions;
    this.token = this.authenticationService.getAuthToken();
    this.timezoneIana = this.authenticationService.getTimeZoneIana();

    this.randomKey = Math.floor(Math.random() * (999999 - 100000)) + 100000;

    this.daterangepickerModel = [
      Moment().tz(this.timezoneIana).subtract(1, "months").startOf("day").toDate(),
      Moment().add(0, "days").endOf("day").toDate(),
    ];

    this.dpConfig = getDefaultDpConfig(authenticationService);

    // Get all the date for dropdown boxes
    forkJoin([this.reportService.getReportColumnsByReportType(), this.translateService.get("general.date")]).subscribe({
      next: ([columns, _]) => {
        this.languageLoaded = true;
        this.loading = false;

        for (const item in columns) {
          if (item === null) {
            continue;
          }

          this.availableReports[columns[item].reportType] = true;
        }

        delete this.availableReports["0"];

        this.cd.markForCheck();

        this.initGrid();
      },
      error: (err) => {
        this.error = err;
        this.languageLoaded = true;
        this.loading = false;
        this.cd.markForCheck();
      },
    });
  }

  dateChanged(event) {
    if (event !== null) {
      this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.ajax
          .url(
            this.reportService.getPagingUrl(
              Moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf("day"),
              Moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf("day"),
              this.randomKey
            )
          )
          .load();
      });
    }
  }

  actualRound(value, decimals) {
    return roundAsNumber(value, decimals);
  }

  initGrid(): void {
    $.fn["dataTable"].ext.search.pop();

    this.loading = true;

    // Id = (Guid)row.GeneratedReportId,
    // AccountId = (int)row.AccountId,
    // SelectedReportTypeId = (int)row.SelectedReportTypeId,
    // FkUserId = (int)row.FkUserId,
    // Timestamp = (DateTime)row.Timestamp,
    // Filters = (string)row.Filters,
    // ReportData = (string)row.ReportData,

    const reportTypeOptions = [];
    for (const i of Object.keys(this.availableReports)) {
      reportTypeOptions.push({ id: i, value: this.translateService.instant("enums.reportType." + i) });
    }

    this.columns = [
      {
        name: "id",
        data: "id",
        className: "noVis",
        orderable: false,
        title: '<div class="hideDropdown"></div>',
        width: "20",
        render: (data, type, row) => {
          return `
            <a class='btn btn-primary btn-grid' title='${this.translateService.instant(
              "general.details"
            )}' href='/#/ReportDetails/Index/${data}'>
              <span class="d-none d-md-inline-flex" style="padding-left: 7px">${this.translateService.instant(
                "general.details"
              )}</span>
              <i class='fas fa-fw fa-angle-right'></i>
            </a>`;
        },
      },
      {
        name: "executionStartedTimestamp",
        data: "executionStartedTimestamp",
        type: "date",
        visible: true,
        title: this.translateService.instant("general.executionStartedTimestamp"),
        render: (data, type, row) => {
          const date = Moment.utc(data)["tz"](this.timezoneIana);
          return data ? '<span title=" ' + date.toLocaleString() + '">' + date.format("lll") + "</span>" : "-";
        },
      },
      {
        name: "executionCompletedTimestamp",
        data: "executionCompletedTimestamp",
        type: "date",
        visible: false,
        defaultContent: "-",
        title: this.translateService.instant("general.executionCompletedTimestamp"),
        render: (data, type, row) => {
          const date = Moment.utc(data)["tz"](this.timezoneIana);
          return data ? '<span title=" ' + date.toLocaleString() + '">' + date.format("lll") + "</span>" : "-";
        },
      },
      {
        name: "selectedReportType",
        data: "selectedReportType",
        title: this.translateService.instant("general.selectedReportType"),
        type: "select",
        options: reportTypeOptions.sort((a, b) => a.value.localeCompare(b.value)),
        render: (data, type, row) => {
          return this.translateService.instant("enums.reportType." + data);
        },
      },
      {
        name: "name",
        data: "name",
        defaultContent: "",
        title: this.translateService.instant("general.name"),
        render: (data, type, row) => {
          var templateName = row.template?.name;
          return data ? data : templateName ? templateName : "-";
        },
      },
      {
        name: "isCompleted",
        data: "isCompleted",
        title: this.translateService.instant("general.status"),
        defaultContent: "",
        visible: true,
        width: "40",
        render: (data, type, row) => {
          var isSuccessful = row["isSuccessful"];
          var isCompleted = row["isCompleted"];
          var executionCompletedTimestamp = row["executionCompletedTimestamp"]
            ? Moment.utc(row["executionCompletedTimestamp"])["tz"](this.timezoneIana)
            : null;
          var executionStartedTimestamp = row["executionStartedTimestamp"]
            ? Moment.utc(row["executionStartedTimestamp"])["tz"](this.timezoneIana)
            : null;

          var reportStatus = 0;
          const compareDate = Moment.utc()["tz"](this.timezoneIana).add(-3, "hours");

          if (isCompleted && isSuccessful) {
            reportStatus = 1;
          } else if (isCompleted && !isSuccessful) {
            reportStatus = 2;
          } else if (!isCompleted && !executionCompletedTimestamp && executionStartedTimestamp < compareDate) {
            reportStatus = 3;
          } else if (!isCompleted && !executionCompletedTimestamp && executionStartedTimestamp >= compareDate) {
            reportStatus = 4;
          }

          var statusText = this.translateService.instant("enums.reportStatus." + reportStatus);
          switch (reportStatus) {
            case 1:
              return `<i title="${statusText}" class="fa fa-check fa-fw " style="color: rgb(0, 142, 67);"></i>`;
            case 2:
              return `<i title="${statusText}" class="fa fa-times fa-fw " style="color: rgb(151, 28, 36);"></i>`;
            case 3:
              return `<i title="${statusText}" class="fa fa-recycle fa-fw " style="color: rgb(151, 28, 36);"></i>`;
            case 4:
              return `<i title="${statusText}" class="fa fa-recycle fa-fw " style="color: #EE9234;"></i>`;
            default:
              return `<i title="${statusText}" class="fa fa-times fa-fw " style="color: rgba(100, 100, 100, 0.3);"></i>`;
          }
        },
      },
      {
        name: "isSuccessful",
        data: "isSuccessful",
        title: this.translateService.instant("general.successful"),
        type: "checkBox",
        visible: false,
        defaultContent: "",
        createdCell: createdCellCheckbox,
        width: "40",
      },
      {
        name: "isCompleted",
        data: "isCompleted",
        title: this.translateService.instant("general.completed"),
        type: "checkBox",
        defaultContent: "",
        visible: false,
        createdCell: createdCellCheckbox,
        width: "40",
      },
      {
        name: "executionDurationInMilliseconds",
        data: "executionDurationInMilliseconds",
        type: "num",
        visible: false,
        title: this.translateService.instant("general.executionDurationInSeconds"),
        render: (data, type, row) => {
          return data ? roundSeconds(data / 1000) : "-";
        },
      },
      {
        name: "executionResultBytes",
        data: "executionResultBytes",
        type: "num",
        visible: false,
        title: this.translateService.instant("general.executionResultBytes"),
        render: (data, type, row) => {
          return data ? roundAsString(data / 1000, 0) + " KB" : "-";
        },
      },
      {
        name: "executionResultRecordCount",
        data: "executionResultRecordCount",
        type: "num",
        title: this.translateService.instant("general.executionResultRecordCount"),
        render: (data, type, row) => {
          return data ? data.toLocaleString() : "-";
        },
      },
      {
        name: "resultMessage",
        data: "resultMessage",
        visible: false,
        title: this.translateService.instant("general.message"),
        render: (data, type, row) => {
          return data;
        },
      },
      {
        name: "userName",
        data: "userName",
        defaultContent: "-",
        title: this.translateService.instant("general.userName"),
      },
      {
        name: "companyName",
        data: "companyName",
        defaultContent: "-",
        title: this.translateService.instant("general.companyName"),
      },
      {
        name: "accountId",
        data: "accountId",
        defaultContent: "-",
        title: this.translateService.instant("general.accountId"),
        visible: false,
      },
      {
        name: "resellerDescription",
        data: "resellerDescription",
        defaultContent: "-",
        title: this.translateService.instant("general.resellerDescription"),
        visible: false,
      },
      {
        name: "notifyOnCompletion",
        data: "notifyOnCompletion",
        title: this.translateService.instant("general.notifyOnCompletion"),
        type: "checkBox",
        defaultContent: "",
        visible: true,
        createdCell: createdCellCheckbox,
        width: "40",
      },
    ];

    this.dtOptions = {
      buttons: getGridButtons(
        this.commonExportOptions,
        "reporting_overview",
        this.translateService.instant("general.job"),
        this.colorService
      ),
      pagingType: "simple_numbers",
      serverSide: true,
      processing: true,
      searchDelay: 2000,
      deferRender: true,
      scrollX: true,
      colReorder: { fixedColumnsLeft: 1 },
      deferLoading: 0,
      stateSave: true,
      stateSaveCallback: (settings, data) => {
        this.saveState(this.constructorName, data);
      },
      stateLoadCallback: (_, callback) => {
        (async () => {
          try {
            const columnSettings = await this.loadState(this.constructorName);
            this.searchTerm = columnSettings && columnSettings.search && columnSettings.search.search;
            return columnSettings;
          } catch (e) {
            this.error = {};
            this.error.error = e;
            this.error.statusText = "Error fetching column settings";

            return null;
          }
        })().then((result) => {
          callback(result);
        });
      },
      order: [[1, "desc"]],
      ajax: {
        beforeSend: () => {
          this.drawFilterRow();

          $(".dataTables_info").html(this.translateService.instant("grid.loadingData"));
        },
        url: this.reportService.getPagingUrl(
          Moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf("day"),
          Moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf("day"),
          this.randomKey
        ),
        data: (d) => {
          return d;
        },
        type: "POST",
        headers: {
          Authorization: "Bearer " + this.token,
        },
      },
      initComplete: (settings, json) => {
        this.checkFilters();
        this.setEvents();
        this.drawFilterRow();
        this.loading = false;
        this.cd.markForCheck();
      },
      colVis: {
        restore: this.translateService.instant("general.restore"),
        showAll: this.translateService.instant("general.showAll"),
        showNone: this.translateService.instant("general.hideAll"),
        exclude: ["id", "id_export"],
      },
      columns: this.columns,
      pageLength: 17,
      lengthMenu: [
        [10, 17, 25, 50, -1],
        [10, 17, 25, 50, this.translateService.instant("general.all")],
      ],
      language: getGridLanguages(this.translateService),
    };
  }
}
