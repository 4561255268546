import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { FormMode, NotificationType } from "app/common/enums";
import { AuthenticationService } from "app/services/authentication/authentication.service";
import { NotificationLogService } from "app/services/notificationlog/notificationlog.service";

@Component({
  selector: "fh-notifications-settings",
  templateUrl: "notificationsSettings.component.html",
})
export class NotificationsSettingsViewComponent implements OnDestroy, OnInit {
  formMode = FormMode.read;
  loading: boolean;
  permissions = {};
  userId: string;
  settings = [];

  error;
  warning;
  success;

  constructor(
    private cd: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private notificationLogService: NotificationLogService
  ) {
    this.permissions = this.authenticationService.permissions;
    this.userId = this.authenticationService.getUserId();
  }

  ngOnDestroy(): void {}

  ngOnInit(): void {
    this.getNotificationSettings();
  }

  onSave(): void {
    this.loading = true;

    this.notificationLogService.saveNotificationThresholdsByUserId(this.userId, this.settings).subscribe(
      (result) => {
        this.error = null;
        this.success = {
          statusText: "Success",
          success: "Settings are successfull updated",
        };

        this.setFormMode(FormMode.read);
      },
      (error) => {
        this.error = error;

        this.loading = false;
        this.cd.markForCheck();
      }
    );
  }

  getNotificationSettings(): void {
    this.loading = true;

    var types = [
      NotificationType.Maintenance,
      NotificationType.CalibrationAlert,
      NotificationType.TripDistanceAlert,
      NotificationType.DriverCalibrationAlert,
      NotificationType.DriverMaintenanceAlert,
      NotificationType.DriverLicenseExpiryAlert,
      NotificationType.DriverInspectionAlert,
    ];

    this.notificationLogService.getNotificationThresholdsByUserId(this.userId).subscribe(
      (result) => {
        this.settings = [];

        types.forEach((enumVar) => {
          var typeId = enumVar.valueOf();
          var prop = result.find((x) => x.notificationType == typeId);
          var settingItem = {
            notificationThresholdId: null,
            name: NotificationType[enumVar],
            notificationType: null,
            enabled: false,
            userContactGroupId: null,
          };

          if (prop) {
            settingItem.notificationThresholdId = prop.notificationThresholdId;
            settingItem.notificationType = typeId;
            settingItem.enabled = prop.enabled;
            settingItem.userContactGroupId = prop.userContactGroupId;
          } else {
            settingItem.notificationType = typeId;
            settingItem.enabled = false;
            settingItem.userContactGroupId = null;
          }

          this.settings.push(settingItem);
        });

        this.loading = false;
        this.cd.markForCheck();
      },
      (error) => {
        this.error = error;

        this.loading = false;
        this.cd.markForCheck();
      }
    );
  }

  setFormMode(mode) {
    this.formMode = mode;

    if (this.formMode === FormMode.read) {
      this.loading = true;
      this.getNotificationSettings();
    }
  }
}
