<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/"> <i class="fas fa-fw fa-home"></i> {{ "general.home" | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Notifications/">{{ "general.notifications" | translate }}</a>
                </li>
                <li class="breadcrumb-item active">{{ "general.settings" | translate }}</li>
            </ol>
        </nav>
    </div>
    <fh-notifications-navigation></fh-notifications-navigation>
</div>

<div class="wrapper wrapper-content">
    <div class="row">
        <div class="col-lg-12">
            <fh-error [error]="error" [warning]="warning" [success]="success"></fh-error>

            <div class="ibox" style="position: relative">
                <form #settingsForm="ngForm">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-cog"></i> {{ "general.settings" | translate }}</h5>
                    </div>
                    <fh-notificationsetting-details
                        [settings]="settings"
                        [loading]="loading"
                        [formMode]="formMode"
                    ></fh-notificationsetting-details>

                    <fh-save-footer
                        [valid]="!settingsForm.invalid"
                        (onSave)="onSave()"
                        (onInsert)="onSave()"
                        (setFormMode)="setFormMode($event)"
                        [formMode]="formMode"
                    >
                    </fh-save-footer>
                </form>
            </div>
        </div>
    </div>
</div>
