import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { HierarchieService } from "app/services/hierarchy/hierarchie.service";

@Component({
  selector: "fh-hierarchy-dashboard",
  templateUrl: "dashboard.template.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HierarchyViewComponent implements OnInit, AfterViewInit {
  sub;
  error: null;
  warning: null;
  hierarchyGroups = [];
  loading: boolean;
  selectedAccountId: any;
  selectedResellerId: any;

  constructor(private cd: ChangeDetectorRef, private hierarchyService: HierarchieService) {}

  ngOnInit() {
    this.fetchHierarchy();
  }

  resellerChanged(resellerId) {
    this.selectedAccountId = null;

    this.selectedResellerId = resellerId;
  }

  accountChanged(accountId) {
    this.selectedAccountId = accountId;

    this.error = null;
    this.warning = null;

    if (accountId) {
      this.fetchHierarchy();
    }
  }

  fetchHierarchy() {
    this.loading = true;
    this.cd.markForCheck();

    if (!this.selectedAccountId) {
      this.loading = false;
      this.cd.markForCheck();

      return;
    }

    this.hierarchyService.getHierarchyGroupsByAccountId(this.selectedAccountId).subscribe((result) => {
      this.hierarchyGroups = result;

      this.loading = false;
      this.cd.markForCheck();
    });
  }

  ngAfterViewInit() {
    this.cd.markForCheck();
  }
}
