<fh-wizard [(step)]="step" (finish)="onCheckOutFinish()">
    <fh-wizard-step title="general.deleteHistory" icon="fa-user" (open)="initForm()">
        <div class="wizard-content">
            <alert type="warning">
                <i class="fas fa-fw fa-info"></i> {{ 'general.deleteHistoryWarning' | translate }}
            </alert>
        </div>
    </fh-wizard-step>
    <fh-wizard-step
        title="{{ 'general.finish' | translate}}"
        icon="fa-save"
        (open)="initFinish()"
        icon="fa-car-bus"
        [validate]="assetForm"
        [padding]="false"
    >
        <div class="wizard-content">
            <form [formGroup]="assetForm" style="width: 100%; border-top: 0px">
                <table style="width: 100%" class="table table-fixed nowrap dtr-inline no-footer">
                    <tbody>
                        <tr style="display: none">
                            <td style="width: 40%">{{'general.createReports' | translate }}</td>
                            <td style="width: auto">
                                <div class="fa_checkbox">
                                    <input id="createReports" type="checkbox" formControlName="createReports" />
                                    <label for="createReports"></label>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 40%">{{ 'general.startDate' | translate }}</td>
                            <td style="width: auto">
                                <input
                                    [ngStyle]="{'border': assetForm.get('dateFrom').errors ? '1px solid red' : '' }"
                                    class="form-control table-inline"
                                    id="dateFrom"
                                    formControlName="dateFrom"
                                    autocomplete="off"
                                    [bsConfig]="dpConfig"
                                    bsDatepicker
                                />
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 40%">{{ 'general.endDate' | translate }}</td>
                            <td style="width: auto">
                                <input
                                    [ngStyle]="{'border': assetForm.get('dateTo').errors ? '1px solid red' : '' }"
                                    class="form-control table-inline"
                                    id="dateTo"
                                    formControlName="dateTo"
                                    autocomplete="off"
                                    [bsConfig]="dpConfig"
                                    bsDatepicker
                                />
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 40%">
                                {{'general.areyousure' | translate }}
                                <span class="error" *ngIf="assetForm.get('checkbox').errors">*</span>
                            </td>
                            <td style="width: auto; border-bottom: 0px !important">
                                <div class="fa_checkbox">
                                    <input id="checkbox" type="checkbox" formControlName="checkbox" required />
                                    <label for="checkbox"></label>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </div>
    </fh-wizard-step>
    <fh-wizard-step title="Remarks" icon="fa-file-pdf">
        <div class="wizard-content" style="padding-bottom: 10px">{{ 'wizard.optionalComment' | translate }}</div>
        <div class="wizard-content">
            <textarea
                class="form-control noresize stickypad"
                style="height: 100px; width: 100%; resize: none"
                placeholder="{{ 'placeholder.comments' | translate}}"
                [(ngModel)]="logItem"
            ></textarea>
        </div>
    </fh-wizard-step>
    <fh-wizard-step title="Summary" icon="fa-summary-pdf">
        <div
            class="wizard-content"
            style="padding-bottom: 10px"
            [innerHTML]="'wizard.summary' | translate:{ count: gridSelection.length }"
        ></div>
    </fh-wizard-step>
</fh-wizard>
