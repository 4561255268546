import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuditLog } from "../../../models/auditlog.model";
import { AuditLogService } from "../../../services/auditlog/auditlog.service";
import { MaintenanceType } from "app/models/maintenance.model";
import { MaintenanceTypesService } from "app/services/maintenance/maintenanceTypes.service";

@Component({
  selector: "fh-maintenance-type-log",
  templateUrl: "maintenanceTypeLog.template.html",
})
export class MaintenanceTypeLogViewComponent implements OnInit {
  maintenanceTypeLog: AuditLog[];
  sub;
  maintenanceType: MaintenanceType;
  loading = false;

  error: any;
  success: any;

  permissionName = "FleetManagement_Log";

  constructor(
    private http: HttpClient,
    private auditLogService: AuditLogService,
    private maintenanceTypeService: MaintenanceTypesService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.maintenanceType = null;
    this.maintenanceTypeLog = [];
  }

  ngOnInit() {
    this.loading = true;
    this.maintenanceType = new MaintenanceType();
    this.maintenanceType.id = "";

    this.sub = this.route.params.subscribe((params) => {
      const id = params["id"];
      this.maintenanceTypeService.getMaintenanceTypeById(id).subscribe((maintenanceType) => {
        this.maintenanceType = maintenanceType;

        if (this.maintenanceType == null) {
          this.router.navigate(["/MaintenanceTypes/Overview"]);
        }

        this.auditLogService.getAuditLogByMaintenanceType(id).subscribe({
          next: (maintenanceTypeLog) => {
            console.log("loading done");
            this.maintenanceTypeLog = maintenanceTypeLog;
            this.loading = false;
          },
          error: (error) => {
            this.error = error;
            this.loading = false;
          },
        });
      });
    });
  }
}
