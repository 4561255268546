import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { environment } from "app/../environments/environment";
import { StorageHelper } from "app/common/storagehelper";
import { AuthenticationService } from "app/services/authentication/authentication.service";
import { EventService } from "app/services/events/events.service";
import { detectBody } from "../../../app.helpers";
import { ColorService } from "../../../services/common/color.service";
import { Router } from "@angular/router";

@Component({
  selector: "fh-basic-layout",
  templateUrl: "basicLayout.template.html",
  host: { "(window:resize)": "onResize()" },
})
export class BasicLayoutComponent implements OnInit {
  cssVariables: any;

  isFleetOverview = false;

  ngOnInit(): void {
    detectBody();

    const whitelabel = this.authenticationService.getWhitelabel();

    if (this.authenticationService.config.Debug) {
      console.log("using whitelabel: ", whitelabel);
    }

    if (whitelabel == null) {
      return;
    }

    this.colors.getThemeColors(whitelabel).subscribe((result) => {
      this.colors.addTheme(result);
    });

    if (this.router.url === "/FleetOverview" || this.router.url === "/Map" || this.router.url === "/Fuel/Map") {
      this.isFleetOverview = true;
    }
  }

  constructor(
    private router: Router,
    private colors: ColorService,
    private eventService: EventService,
    private authenticationService: AuthenticationService,
    private titleService: Title,
    private storageHelper: StorageHelper
  ) {
    this.titleService.setTitle(environment.ApplicationName);
  }

  public onResize() {
    detectBody();
  }
}
