import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormMode } from "app/common/enums";
import { MaintenanceType } from "app/models/maintenance.model";
import { EntityType } from "app/common/enums";
import { AuthenticationService } from "app/services/authentication/authentication.service";
// Moment timezone
import Moment from "moment-timezone";
import { MaintenanceTypesService } from "app/services/maintenance/maintenanceTypes.service";
import { UserService } from "app/services/users/user.service";
import { AppUser } from "app/models/user.model";
import { FhChartService } from "app/services/charts/charts.service";
import { AssetTypeService } from "app/services/asset/assetTypes.service";

window["moment"] = Moment;

@Component({
  providers: [FhChartService],
  selector: "fh-fh-maintenance-type-details",
  templateUrl: "typeDetails.template.html",
})
export class MaintenanceTypeDetailsViewComponent implements OnInit {
  sub: any;
  maintenanceType = new MaintenanceType();
  tagType = EntityType.MaintenanceType.valueOf();
  formMode = FormMode.read;

  loading = false;
  saving = false;
  loadingMaintenanceWasl = false;

  filter;

  error: any;
  assetTypes = [];

  permissions: {};
  success: any;
  users: AppUser[];

  permissionName = "MaintenanceTypes_View";

  constructor(
    private router: Router,
    private assetTypeService: AssetTypeService,
    private cd: ChangeDetectorRef,
    private authentication: AuthenticationService,
    private maintenanceTypesService: MaintenanceTypesService,
    private route: ActivatedRoute,
    private userService: UserService
  ) {
    this.permissions = this.authentication.permissions;
  }

  hasSelectedTypes(): boolean {
    return this.assetTypes.some((x) => x.maintenanceTypeChecked === true);
  }

  ngOnInit() {
    this.loading = true;
    this.sub = this.route.params.subscribe((params) => {
      const id = params["id"];

      this.getMaintenanceInfo(id);
    });
  }

  // Form

  onDelete() {
    this.loading = true;

    this.maintenanceTypesService.deleteMaintenanceType(this.maintenanceType).subscribe(
      (result) => {
        this.error = null;
        this.success = {
          statusText: "Success",
          success: "Maintenance type is successfully deleted.",
        };

        setTimeout(() => {
          this.router.navigate(["/Settings/MaintenanceTypes/Overview"]);
        }, 3000);
      },
      (error) => {
        this.success = null;
        this.error = error;
        this.loading = false;
        this.cd.markForCheck();
      }
    );
  }

  onSave() {
    this.saving = true;

    this.maintenanceTypesService.updateMaintenanceType(this.maintenanceType, this.assetTypes).subscribe(
      (result) => {
        this.error = null;
        this.success = {
          statusText: "Success",
          success: "Maintenance type is successfully updated.",
        };

        this.saving = false;
        this.cd.markForCheck();

        this.setFormMode(FormMode.read);
      },
      (error) => {
        this.saving = false;
        this.success = null;
        this.error = error;
        this.cd.markForCheck();
      }
    );
  }

  setFormMode(mode) {
    this.formMode = mode;

    if (this.formMode === FormMode.read) {
      this.loading = true;
      this.getMaintenanceInfo();
    }
  }

  getMaintenanceInfo(id = 0) {
    this.loading = true;

    if (id === 0) {
      id = this.maintenanceType.id;
    }

    this.maintenanceTypesService.getMaintenanceTypeById(id.toString()).subscribe(
      (maintenanceType) => {
        this.maintenanceType = maintenanceType;
        this.loading = false;
        this.getAssetTypes();

        this.cd.markForCheck();
      },
      (error) => {
        this.success = null;
        this.error = error;
        this.error.statusText = "Error fetching maintenance";
        this.cd.markForCheck();

        setTimeout(() => {
          this.router.navigate(["/Settings/MaintenanceTypes/Overview"]);
        }, 3000);
      }
    );

    this.loading = false;
    this.loadingMaintenanceWasl = false;
  }

  getAssetTypes() {
    this.loading = true;
    const assetTypeIds = this.maintenanceType.assetTypeIds && this.maintenanceType.assetTypeIds?.split(",");

    if (this.maintenanceType.accountId && this.maintenanceType.accountId > 0) {
      this.assetTypeService.getAssetTypes(this.maintenanceType.accountId).subscribe((result) => {
        assetTypeIds?.forEach((id) => {
          id = Number(id);
          if (result.findIndex((item) => item.id === id) !== -1) {
            result.find((item) => item.id === id).maintenanceTypeChecked = true;
          }
        });

        this.assetTypes = result;
        this.loading = false;
        this.cd.markForCheck();
      });
    } else {
      this.loading = false;
    }
  }
}
