<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/"> <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li
                    class="breadcrumb-item"
                    *ngIf="permissions['Accounts_View'] && device?.asset && device?.asset.accountId"
                >
                    <a href="/#/AccountDetails/Index/{{device?.asset.accountId}}">{{device?.asset?.companyName}}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Devices/Overview?companyName={{device?.companyName}}"
                        >{{'general.devices' | translate }}</a
                    >
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/DeviceDetails/Index/{{device?.id}}"
                        >{{device?.asset?.name ? device?.asset?.name : device?.name}}</a
                    >
                </li>
                <li class="breadcrumb-item active">{{'general.immobilization' | translate }}</li>
            </ol>
        </nav>
    </div>
    <fh-device-details-navigation [deviceId]="deviceId" [device]="device"></fh-device-details-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">
        <div class="row" data-equalizer="">
            <div class="col-lg-12">
                <fh-error [error]="error" [success]="success" [autoRemove]="false"></fh-error>
            </div>
        </div>

        <div class="row" data-equalizer="">
            <div class="col-lg-6">
                <fh-error [error]="errorTags" [success]="successTags" [warning]="warningTags" [autoRemove]="false">
                </fh-error>

                <div class="ibox" *ngIf="hasDriverTagsEnabled || loading">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-barcode"></i> {{'general.driverTagAssignments' | translate }}</h5>
                    </div>
                    <form #deviceForm="ngForm">
                        <div class="ibox-content slick" style="position: relative">
                            <ngx-loading [show]="loadingDriverTagAssignments || loading || loadingDriverSettings">
                            </ngx-loading>
                            <table style="width: 100%" class="table nowrap dtr-inline no-footer">
                                <thead>
                                    <tr>
                                        <th>{{'general.tag' | translate }}</th>
                                        <th style="text-align: right">{{'general.start' | translate }}</th>
                                        <th style="text-align: right; width: 60px">
                                            {{'general.applied' | translate }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngIf="driverTagAssignments?.length == 0">
                                        <td colspan="4">{{'general.noTagsFound' | translate }}</td>
                                    </tr>
                                    <tr *ngFor="let driverTagAssignment of driverTagAssignments; let i = index">
                                        <td style="width: auto">
                                            <input
                                                type="text"
                                                [readonly]="formMode == 1"
                                                [name]="'name_' + i"
                                                placeholder="{{ 'placeholder.driverTag' | translate}}"
                                                class="form-control table-inline"
                                                required
                                                minlength="8"
                                                [(ngModel)]="driverTagAssignment.driverTag"
                                            />
                                        </td>
                                        <td style="width: auto; text-align: right">
                                            {{ driverTagAssignment.dateStart | amDateFormat: 'LL' }}
                                        </td>
                                        <td style="text-align: right; width: 60px">
                                            <button
                                                class="btn btn-danger btn-inline"
                                                *ngIf="formMode == 3"
                                                (click)="removeTag(driverTagAssignment)"
                                            >
                                                <i class="fa fa-fw fa-trash-alt"></i>
                                            </button>

                                            <i
                                                class="fa fa-fw fa-check"
                                                title="activated"
                                                style="color: rgb(0, 142, 67)"
                                                *ngIf="formMode == 1 && driverTagAssignment.active == 1"
                                            ></i>
                                            <i
                                                class="fa fa-fw fa-triangle-exclamation"
                                                title="pending"
                                                *ngIf="formMode == 1 && driverTagAssignment.active == 0"
                                            ></i>
                                        </td>
                                    </tr>
                                    <tr *ngIf="formMode == 3">
                                        <td style="width: auto">
                                            <input
                                                type="text"
                                                name="newDriverTag"
                                                placeholder="{{ 'placeholder.driverTag' | translate}}"
                                                class="form-control table-inline"
                                                [(ngModel)]="newDriverTag"
                                            />
                                        </td>
                                        <td></td>
                                        <td>
                                            <button
                                                class="btn btn-primary btn-inline"
                                                [disabled]="!newDriverTag || newDriverTag.length < 8"
                                                (click)="add()"
                                            >
                                                <i class="fa fa-fw fa-plus"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </form>
                    <fh-save-footer
                        [valid]="!deviceForm.invalid && (!newDriverTag || newDriverTag.length == 0)"
                        (onSave)="onSave()"
                        [allowEdit]="permissions['Devices_Edit']"
                        [allowDelete]="false"
                        (setFormMode)="setFormMode($event)"
                        [formMode]="formMode"
                    >
                        <span class="float-right" *ngIf="formMode == 1">
                            <button class="btn btn-info" (click)="pasteModal.showModal(1)">
                                <i class="fa fa-fw fa-paste"></i> {{'general.paste' | translate }}
                            </button>
                            <button class="btn btn-info" (click)="massApply()">
                                <i class="fa fa-fw fa-layer-plus"></i> {{'general.massApply' | translate }}
                            </button>
                        </span>
                    </fh-save-footer>
                </div>
            </div>
            <div class="col-lg-6">
                <fh-error [error]="errorStarter" [warning]="warningStarter" [autoRemove]="false"></fh-error>

                <div class="ibox" *ngIf="immobilizationType">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-scanner"></i> {{'general.immobilization' | translate }}</h5>
                    </div>
                    <div class="ibox-content slick" style="position: relative">
                        <ngx-loading [show]="loading"></ngx-loading>
                        <table style="width: 100%" class="table table-fixed nowrap dtr-inline no-footer">
                            <thead></thead>
                            <tbody>
                                <tr>
                                    <td class="row_opening">{{'general.type' | translate }}</td>
                                    <td class="row_content">{{ immobilizationType }}</td>
                                </tr>
                                <tr>
                                    <td class="row_opening">{{'general.setting' | translate }}</td>
                                    <td class="row_content">{{ theSettingId }}</td>
                                </tr>
                                <tr>
                                    <td class="row_opening">{{'general.port' | translate }}</td>
                                    <td class="row_content">{{ thePort }}</td>
                                </tr>
                                <tr
                                    *ngIf="immobilizationStatus < 3 && permissions['FleetManagement_DeviceSettingsWakeUp']"
                                >
                                    <td class="row_opening">{{'general.wakeUpDevice' | translate }}</td>
                                    <td class="row_content">
                                        <div class="fa_checkbox">
                                            <input id="checkbox" type="checkbox" [(ngModel)]="wakeUpDevice" />
                                            <label for="checkbox"> </label>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="ibox-footer noselect">
                        <button class="btn btn-primary" *ngIf="immobilizationStatus == 1" (click)="immobilise()">
                            <i class="fas fa-fw fa-check"></i> {{'general.immobilise' | translate }}
                        </button>

                        <button class="btn btn-danger" *ngIf="immobilizationStatus > 2" (click)="abort()">
                            <i class="fas fa-fw fa-times"></i> {{'general.abort' | translate }}
                        </button>

                        <button class="btn btn-primary" *ngIf="immobilizationStatus == 2" (click)="unimmobilise()">
                            <i class="fas fa-fw fa-check"></i> {{'general.unimmobilise' | translate }}
                        </button>
                    </div>
                </div>

                <div class="ibox" *ngIf="immobilizationType">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-lock-open"></i> {{'general.status' | translate }}</h5>
                    </div>
                    <div class="ibox-content slick" style="position: relative">
                        <ngx-loading [show]="loading"></ngx-loading>
                        <table style="width: 100%" class="table table-fixed nowrap dtr-inline no-footer">
                            <tbody>
                                <tr>
                                    <td class="row_opening">{{'general.status' | translate }}</td>
                                    <td class="row_content" style="word-wrap: normal; white-space: break-spaces">
                                        {{('enums.immobilization.' + immobilizationStatus) | translate }}
                                        <span *ngIf="activeDsc"
                                            >{{ 'general.retryCountValue' | translate: { value: activeDsc.retryCount }
                                            }}</span
                                        >
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="ibox" *ngIf="immobilizationType">
                    <div class="ibox-title">
                        <h5><i class="fa fa-signal"></i> {{ 'general.commands' | translate }}</h5>
                    </div>
                    <div class="ibox-footer" style="position: relative">
                        <button
                            class="btn btn-primary"
                            [disabled]="immobilizationStatus < 3"
                            (click)="refresh();"
                            title="{{ 'general.refresh' | translate }}"
                        >
                            <i class="fa fa-fw fa-recycle"></i> {{'general.refresh' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</fh-permission-handler>

<fh-confirmation-modal #pasteModal (onFinish)="paste($event)" title="{{ 'general.tags' | translate}}">
    <div #assignmentBody body class="wizard-content">
        <textarea
            class="form-control noresize stickypad"
            style="height: 100px; width: 100%; resize: none"
            placeholder="{{ 'placeholder.driverPaste' | translate}}"
            [(ngModel)]="pasteText"
        ></textarea>
    </div>
</fh-confirmation-modal>
