! function () {
    "use strict";
    var l = {}.hasOwnProperty,
        u = [].slice;
    (function () {
        var t, o;
        if (null != this.L) return this.OverlappingMarkerSpiderfier = ((t = e.prototype).VERSION = "0.2.6", o = 2 * Math.PI, t.keepSpiderfied = !1, t.nearbyDistance = 20, t.circleSpiralSwitchover = 9, t.circleFootSeparation = 25, t.circleStartAngle = o / 12, t.spiralFootSeparation = 28, t.spiralLengthStart = 11, t.spiralLengthFactor = 5, t.legWeight = 1.5, t.legColors = {
            usual: "#222",
            highlighted: "#f00"
        }, t.initMarkerArrays = function () {
            return this.markers = [], this.markerListeners = []
        }, t.addMarker = function (t) {
            var e, r;
            return null != t._oms || (t._oms = !0, r = this, e = function () {
                return r.spiderListener(t)
            }, t.addEventListener("click", e), this.markerListeners.push(e), this.markers.push(t)), this
        }, t.getMarkers = function () {
            return this.markers.slice(0)
        }, t.removeMarker = function (t) {
            var e, r;
            return null != t._omsData && this.unspiderfy(), (e = this.arrIndexOf(this.markers, t)) < 0 || (r = this.markerListeners.splice(e, 1)[0], t.removeEventListener("click", r), delete t._oms, this.markers.splice(e, 1)), this
        }, t.clearMarkers = function () {
            var t, e, r, i, n, s;
            for (this.unspiderfy(), t = e = 0, r = (s = this.markers).length; e < r; t = ++e) i = s[t], n = this.markerListeners[t], i.removeEventListener("click", n), delete i._oms;
            return this.initMarkerArrays(), this
        }, t.addListener = function (t, e) {
            var r;
            return (null != (r = this.listeners)[t] ? r[t] : r[t] = []).push(e), this
        }, t.removeListener = function (t, e) {
            var r;
            return (r = this.arrIndexOf(this.listeners[t], e)) < 0 || this.listeners[t].splice(r, 1), this
        }, t.clearListeners = function (t) {
            return this.listeners[t] = [], this
        }, t.trigger = function () {
            var t, e, r, i, n, s, a, h;
            for (e = arguments[0], t = 2 <= arguments.length ? u.call(arguments, 1) : [], h = [], i = 0, n = (a = null != (s = this.listeners[e]) ? s : []).length; i < n; i++) r = a[i], h.push(r.apply(null, t));
            return h
        }, t.generatePtsCircle = function (t, e) {
            var r, i, n, s, a, h, l;
            for (a = this.circleFootSeparation * (2 + t) / o, i = o / t, l = [], n = s = 0, h = t; 0 <= h ? s < h : h < s; n = 0 <= h ? ++s : --s) r = this.circleStartAngle + n * i, l.push(new L.Point(e.x + a * Math.cos(r), e.y + a * Math.sin(r)));
            return l
        }, t.generatePtsSpiral = function (t, e) {
            var r, i, n, s, a, h, l;
            for (s = this.spiralLengthStart, l = [], i = n = r = 0, h = t; 0 <= h ? n < h : h < n; i = 0 <= h ? ++n : --n) r += this.spiralFootSeparation / s + 5e-4 * i, a = new L.Point(e.x + s * Math.cos(r), e.y + s * Math.sin(r)), s += o * this.spiralLengthFactor / r, l.push(a);
            return l
        }, t.spiderListener = function (t) {
            var e, r, i, n, s, a, h, l, o, u;
            if ((a = null != t._omsData) && this.keepSpiderfied || this.unspiderfy(), a) return this.trigger("click", t);
            for (h = [], l = [], o = this.nearbyDistance * this.nearbyDistance, s = this.map.latLngToLayerPoint(t.getLatLng()), e = 0, r = (u = this.markers).length; e < r; e++) i = u[e], this.map.hasLayer(i) && (n = this.map.latLngToLayerPoint(i.getLatLng()), this.ptDistanceSq(n, s) < o ? h.push({
                marker: i,
                markerPt: n
            }) : l.push(i));
            return 1 === h.length ? this.trigger("click", t) : this.spiderfy(h, l)
        }, t.makeHighlightListeners = function (t) {
            return {
                highlight: (r = this, function () {
                    return t._omsData.leg.setStyle({
                        color: r.legColors.highlighted
                    })
                }),
                unhighlight: (e = this, function () {
                    return t._omsData.leg.setStyle({
                        color: e.legColors.usual
                    })
                })
            };
            var e, r
        }, t.spiderfy = function (i, t) {
            var e, n, s, a, h, l, o, u, g, r, c;
            return this.spiderfying = !0, r = i.length, e = this.ptAverage(function () {
                var t, e, r;
                for (r = [], t = 0, e = i.length; t < e; t++) o = i[t], r.push(o.markerPt);
                return r
            }()), a = r >= this.circleSpiralSwitchover ? this.generatePtsSpiral(r, e).reverse() : this.generatePtsCircle(r, e), c = function () {
                var t, e, r;
                for (r = [], t = 0, e = a.length; t < e; t++) s = a[t], n = this.map.layerPointToLatLng(s), g = this.minExtract(i, function (e) {
                    return function (t) {
                        return e.ptDistanceSq(t.markerPt, s)
                    }
                }(this)), l = g.marker, h = new L.Polyline([l.getLatLng(), n], {
                    color: this.legColors.usual,
                    weight: this.legWeight,
                    clickable: !1
                }), this.map.addLayer(h), l._omsData = {
                    usualPosition: l.getLatLng(),
                    leg: h
                }, this.legColors.highlighted !== this.legColors.usual && (u = this.makeHighlightListeners(l), l._omsData.highlightListeners = u, l.addEventListener("mouseover", u.highlight), l.addEventListener("mouseout", u.unhighlight)), l.setLatLng(n), l.setZIndexOffset(l.options.zIndexOffset + 1e6), r.push(l);
                return r
            }.call(this), delete this.spiderfying, this.spiderfied = !0, this.trigger("spiderfy", c, t)
        }, t.unspiderfy = function (t) {
            var e, r, i, n, s, a, h;
            if (null == t && (t = null), null == this.spiderfied) return this;
            for (this.unspiderfying = !0, h = [], s = [], e = 0, r = (a = this.markers).length; e < r; e++) null != (i = a[e])._omsData ? (this.map.removeLayer(i._omsData.leg), i !== t && i.setLatLng(i._omsData.usualPosition), i.setZIndexOffset(i.options.zIndexOffset - 1e6), null != (n = i._omsData.highlightListeners) && (i.removeEventListener("mouseover", n.highlight), i.removeEventListener("mouseout", n.unhighlight)), delete i._omsData, h.push(i)) : s.push(i);
            return delete this.unspiderfying, delete this.spiderfied, this.trigger("unspiderfy", h, s), this
        }, t.ptDistanceSq = function (t, e) {
            var r, i;
            return (r = t.x - e.x) * r + (i = t.y - e.y) * i
        }, t.ptAverage = function (t) {
            var e, r, i, n, s, a;
            for (s = a = 0, e = 0, r = t.length; e < r; e++) s += (n = t[e]).x, a += n.y;
            return i = t.length, new L.Point(s / i, a / i)
        }, t.minExtract = function (t, e) {
            var r, i, n, s, a, h;
            for (n = s = 0, a = t.length; s < a; n = ++s) h = e(t[n]), (null == r || h < i) && (i = h, r = n);
            return t.splice(r, 1)[0]
        }, t.arrIndexOf = function (t, e) {
            var r, i, n;
            if (null != t.indexOf) return t.indexOf(e);
            for (r = i = 0, n = t.length; i < n; r = ++i)
                if (t[r] === e) return r;
            return -1
        }, e);

        function e(t, e) {
            var r, i, n, s, a, h;
            for (n in this.map = t, null == e && (e = {}), e) l.call(e, n) && (h = e[n], this[n] = h);
            for (this.initMarkerArrays(), this.listeners = {}, i = 0, s = (a = ["click", "zoomend"]).length; i < s; i++) r = a[i], this.map.addEventListener(r, function (t) {
                return function () {
                    return t.unspiderfy()
                }
            }(this))
        }
    }).call(window)
}();