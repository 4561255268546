import { Component, Input } from "@angular/core";
import * as Highcharts from "highcharts";

@Component({
  selector: "ibox-chart-export",
  templateUrl: "iboxexport.template.html",
})
export class IboxChartExportComponent {
  Highcharts: typeof Highcharts = Highcharts;

  @Input() chartObject: any;
  initChart = false;
  chart;

  constructor() {}

  logChartInstance(chart) {
    console.log("Enable chart");
    this.chart = chart;
  }

  chartAction(action) {
    this.initChart = true;

    setTimeout(() => {
      if (action === "exportToPdf") {
        this.chart.exportChartLocal({
          type: "application/pdf",
          filename: "chart-export",
        });
      }
      if (action === "exportToPng") {
        this.chart.exportChartLocal({
          type: "image/png",
          filename: "chart-export",
        });
      }
      if (action === "exportToJpg") {
        this.chart.exportChartLocal({
          type: "image/jpeg",
          filename: "chart-export",
        });
      }
      if (action === "exportToSvg") {
        this.chart.exportChartLocal({
          type: "image/svg+xml",
          filename: "chart-export",
        });
      }
      if (action === "exportAsync") {
        const test = this.chart.exportChartLocal({
          async: true,
          type: "image/png",
          filename: "chart-export",
        });
        console.log(test);
      }
      if (action === "fullscreen") {
        this.chart.fullscreen.toggle();
      }
    }, 10);
  }
}
