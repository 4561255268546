import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";
import { ControlContainer, NgForm, NgModel } from "@angular/forms";
import { FormMode } from "app/common/enums";
import { WebHookHeader } from "app/models/trigger.model";
import { WebhookEndpoint } from "app/models/webhookEndpoint.model";
import { AuthenticationService } from "app/services/authentication/authentication.service";

@Component({
  selector: "fh-webhook-endpoint-details",
  templateUrl: "webhookEndpointDetails.component.html",
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class WebhookEndpointDetailsComponent implements OnInit, OnChanges {
  @Input() webhookEndpoint: WebhookEndpoint;
  @Input() loading = false;
  @Input() formMode: any = FormMode.read;
  @Output() error = new EventEmitter();
  @Output() success = new EventEmitter<string>();
  @Output() onAccountChanged = new EventEmitter();

  webHookHeaderName;
  webHookHeaderProperty;
  webHookHeaders = [];

  loadingAccounts = false;
  selectedResellerId;
  permissions: {};

  constructor(private cd: ChangeDetectorRef, private authenticationService: AuthenticationService) {
    this.permissions = this.authenticationService.permissions;
  }

  ngOnInit(): void {}

  ngOnChanges(): void {}

  resellerChanged(resellerId) {
    this.webhookEndpoint.accountId = null;

    this.selectedResellerId = resellerId;
  }

  accountChanged(accountId) {
    this.onAccountChanged.emit(this.webhookEndpoint.accountId);
  }

  addRow(webHookHeaderName: NgModel, webHookHeaderProperty: NgModel) {
    console.log(webHookHeaderName.value + "+" + webHookHeaderProperty.value);
    this.webhookEndpoint.headers.push({
      webHookHeaderName: webHookHeaderName.value,
      webHookHeaderProperty: webHookHeaderProperty.value,
    } as WebHookHeader);

    this.webHookHeaderName = "";
    this.webHookHeaderProperty = "";
  }

  removeRow(data) {
    const index: number = this.webhookEndpoint.headers.indexOf(data);

    if (index !== -1) {
      this.webhookEndpoint.headers.splice(index, 1);
    }
  }
}
