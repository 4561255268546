import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Device } from "app/models/device.model";
import { AuditLog } from "../../models/auditlog.model";
import { AuditLogService } from "../../services/auditlog/auditlog.service";
import { DeviceService } from "../../services/device/device.service";

@Component({
  selector: "fh-device-log",
  templateUrl: "deviceLog.template.html",
})
export class DeviceLogViewComponent implements OnInit {
  deviceLog: AuditLog[];
  sub;
  device: Device;
  deviceId;
  loading = false;

  permissionName = "FleetManagement_Log";
  constructorName = "DeviceLogViewComponent";

  error: any;
  success: any;

  constructor(
    private http: HttpClient,
    private auditLogService: AuditLogService,
    private deviceService: DeviceService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.device = null;
    this.deviceLog = [];
  }

  ngOnInit() {
    this.loading = true;
    this.device = new Device();
    this.device.id = "";

    this.sub = this.route.params.subscribe({
      next: (params) => {
        const id = params["id"];

        this.deviceId = id;
        this.deviceService.getDeviceById(id).subscribe((device) => {
          this.device = device;

          if (this.device == null) {
            this.router.navigate(["/Devices/Overview"]);
          }

          this.auditLogService.getAuditLogByDevice(id).subscribe({
            next: (deviceLog) => {
              console.log("loading done");
              this.deviceLog = deviceLog;
              this.loading = false;
            },
            error: (error) => {
              this.error = error;
              this.loading = false;
            },
          });
        });
      },
      error: (error) => {
        this.error = error;
        this.error.statusText = "Error fetching device";

        setTimeout(() => {
          this.router.navigate(["/Devices/Overview"]);
        }, 3000);
      },
    });
  }
}
