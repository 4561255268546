import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable, catchError, map } from "rxjs";
import { AuthenticationService } from "../authentication/authentication.service";
import { LoggingService } from "../logging/logging.service";
import { BatchJob } from "app/models/batch.model";
import { throwError } from "rxjs/internal/observable/throwError";
import { generateEntityDetailLink } from "app/common/globals";
import { EntityType } from "app/common/enums";

@Injectable()
export class BatchService {
  url = "";

  constructor(
    private http: HttpClient,
    private loggingService: LoggingService,
    private authenticationService: AuthenticationService
  ) {
    this.url = this.authenticationService.getWebserviceURL("batch");
  }

  getPagingUrl(key, start, end) {
    return this.url + "Paging?rnd=" + key + "&startRange=" + start.unix() + "&endRange=" + end.unix();
  }

  getBatch(): Observable<any> {
    console.log("getting batch from service");
    return this.http.get(this.url, { headers: this.authenticationService.headers }).pipe(
      map((data) => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  getBatchById(id: string): Observable<any> {
    console.log("Fetch batch by id " + id);
    return this.http.get(this.url + id, { headers: this.authenticationService.headers }).pipe(
      map((data) => {
        const parsedReponse = this.parseResponseDetails(data);
        return parsedReponse;
      }),
      catchError(this.handleError)
    );
  }

  saveBatch(job: BatchJob): Observable<any> {
    console.log("post batch");

    return this.http
      .post(this.url, job, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  private handleError(error: Response) {
    return throwError(() => error);
  }

  parseResponseDetails(item) {
    const batch = new BatchJob();
    batch.id = item.id;
    batch.items = [];

    item.batchItems?.forEach((element) => {
      if (item.entityTypes === 1 && item.batchActionType === 5) {
        element.link = generateEntityDetailLink(EntityType.Asset, element.entityId);
      } else {
        element.link = generateEntityDetailLink(item.entityTypes, element.entityId);
      }

      batch.items.push(element);
    });
    batch.entityTypes = item.entityTypes;
    batch.batchActionType = item.batchActionType;
    batch.jsonObject = item.jsonObject;
    batch.status = item.status;
    batch.error = item.error;
    batch.jobRequested = item.jobRequested;
    batch.jobStarted = item.jobStarted;
    batch.jobCompleted = item.jobCompleted;
    batch.errorDescription = item.errorDescription;
    batch.log = item.log;

    return batch;
  }
}
