<div class="ibox" style="position: relative">
    <ngx-loading [show]="loadingEpisodeDetails"></ngx-loading>

    <fh-leaflet-map
        [allowBookmarks]="false"
        [showFitMap]="true"
        [geofences]="geofences"
        [geofenceEnabled]="false"
        [borderRadius]="8"
        [fly]="false"
    >
        <div header class="ibox-title">
            <span class="pull-left">
                <h5><i class="fa fa-fw fa-map-marked-alt"></i> {{'general.map' | translate }}</h5>
            </span>

            <span class="float-right">
                <i
                    (click)="flipShowWiderMap()"
                    style="font-size: 1.3em; padding-right: 25px"
                    class="fa fa-fw fa-2x"
                    [ngClass]="{'fa-times-circle': showWiderMap, 'fa-expand-arrows-alt': !showWiderMap}"
                ></i>
            </span>
        </div>
    </fh-leaflet-map>
</div>

<div class="ibox float-e-margins" *ngIf="geoJsonData">
    <div class="ibox-title">
        <h5><i class="fas fa-fw fa-chart-area"></i> {{'general.chart' | translate }}</h5>
    </div>
    <div class="ibox-content" style="position: relative; min-height: 72px">
        <ngx-loading [show]="loadingEpisodeDetails"></ngx-loading>
        <highcharts-chart
            [runOutsideAngular]="true"
            style="display: block; width: 100%; height: 300px"
            [Highcharts]="Highcharts"
            [options]="chartOptions"
            (chartInstance)="logChartInstance($event)"
        >
        </highcharts-chart>
    </div>
</div>

<div class="ibox" *ngIf="permissions['Platform_IsReseller'] && episode">
    <div class="ibox-title">
        <h5><i class="fas fa-fw fa-bolt"></i> {{'general.actions' | translate }}</h5>
    </div>
    <div class="ibox-content slick" style="position: relative">
        <button class="btn btn-danger" style="width: 100%" (click)="delete()">
            <i class="fa fa-fw fa-times"></i> {{'general.delete' | translate }}
        </button>
    </div>
</div>
