import {
  Component,
  EventEmitter,
  Output,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnInit,
  Input,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { TypeaheadMatch } from "ngx-bootstrap/typeahead";
import { Observable, debounceTime, distinctUntilChanged } from "rxjs";
import { SearchService } from "app/services/search/search.service";
import { DeviceService } from "app/services/device/device.service";
import { FormMode } from "app/common/enums";
import { Device } from "app/models/device.model";

@Component({
  selector: "fh-batch-device-settings",
  templateUrl: "updateDeviceSettings.template.html",
  providers: [SearchService],
  styleUrls: ["updateDeviceSettings.template.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BatchDeviceSettingsComponent implements OnInit {
  step: number;
  @Input() gridSelection = [];
  @Output() onFinish = new EventEmitter();
  disabled = true;
  logItem;

  device;

  typeaheadLoading = false;
  autoComplete$: Observable<string[]>;
  autoCompleteSearchTerm: string;

  selectedDevice = new Device();

  asyncSelected = "";

  form = new FormGroup({});

  formMode = FormMode.edit;

  loadingDevice = false;

  constructor(
    private cd: ChangeDetectorRef,
    private searchService: SearchService,
    private deviceService: DeviceService
  ) {
    this.step = 1;
  }

  ngOnInit(): void {
    this.loadingDevice = true;

    this.deviceService.getDeviceById(this.gridSelection[0]).subscribe((device) => {
      this.selectedDevice = device;
      this.selectedDevice.deviceTypeId = "0";
      this.loadingDevice = false;
      this.cd.markForCheck();
    });

    this.autoComplete$ = Observable.create((observer: any) => {
      this.actualSearch(this.asyncSelected).subscribe((result: any) => {
        observer.next(result);
      });
    });
  }

  actualSearch(search) {
    console.log(search);
    return this.searchService.searchDevices(search).pipe(debounceTime(300), distinctUntilChanged());
    // return Observable.create().distinctUntilChanged();
  }

  initFinish() {
    // Fetch all data and make sure it can be saved
    console.log(1);
  }

  onCheckOutFinish() {
    this.selectedDevice.settings.sensorCalibrationPoints.forEach((sensorCalibrationPoint) => {
      sensorCalibrationPoint.deviceId = +this.selectedDevice.id;
    });

    this.form.value["sensorCalibrationPoints"] = this.selectedDevice.settings.sensorCalibrationPoints;

    this.onFinish.emit({ status: "success", object: this.form.value, log: this.logItem });
    this.step = 1;
  }

  changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  typeaheadOnSelect(e: TypeaheadMatch): void {
    console.log("Selected value: ", e);
    this.loadingDevice = true;

    this.deviceService.getDeviceById(e.item.id).subscribe((device) => {
      this.selectedDevice = device;
      this.selectedDevice.deviceTypeId = "0";
      this.loadingDevice = false;
      this.cd.markForCheck();
    });
  }

  initForm() {
    this.step = 1;
    console.log("init tab");
  }
}
