<ng-select
    [loading]="loadingAssetTypes"
    ngDefaultControl
    [searchable]="true"
    [clearable]="clearable"
    [multiple]="multiple"
    [disabled]="assetTypes.length == 0"
    [readonly]="readonly"
    placeholder="{{ placeholder }} ({{ assetTypes.length }})"
    #input
    [ngClass]="tableInline === true ? 'table-inline' : ''"
    [(ngModel)]="value"
    class="form-control"
    (ngModelChange)="assetTypeChanged()"
>
    <ng-option *ngFor="let assetType of assetTypes | orderBy : 'name' : false" [value]="assetType.id">
        <i class="fas fa-fw fa-truck-monster"></i> {{ assetType.name }} ({{ assetType.assetCount }})</ng-option
    >
</ng-select>
