import { Location } from "@angular/common";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "app/../environments/environment";
import { jwtDecode } from "jwt-decode";
import { CookieService } from "ngx-cookie-service";
import { UserManager, UserManagerSettings } from "oidc-client-ts";

import { catchError, map, Observable, of, Subject, throwError } from "rxjs";
import { AuthenticateRequest } from "../../models/authenticaterequest.model";
import { AuthenticateResult } from "../../models/authenticateresult.model";
import { Config } from "../../models/config.model";
import { EventService } from "../events/events.service";
import { sha256 } from "js-sha256";
import { roundAsString } from "app/common/globals";
import { DistanceUnitService } from "app/common/distanceunit.service";
import { ColorService } from "../common/color.service";

@Injectable()
export class AuthenticationService implements OnInit {
  public permissions = {};
  public permissionsFetched = false;
  public config: Config;

  public useRefreshToken = true;

  public isLoggedInWithSSO = false;
  private manager: UserManager = new UserManager(AuthenticationService.getSSOSettings());

  // Logged in event
  private loggedIn = new Subject<boolean>();
  onLoggedIn = this.loggedIn.asObservable();

  // Constants for our preferences.
  public static readonly TOKEN: string = "token";
  public static readonly SHARETOKEN: string = "sharetoken";

  public static readonly REFRESHTOKEN: string = "refreshtoken";

  public static readonly TOKEN_EXPIRED: string = "token_expired";
  public static readonly SHARETOKEN_EXPIRED: string = "sharetoken_expired";

  public static readonly TOKEN_IMPERSONATE: string = "token_impersonate";
  public static readonly REFRESHTOKEN_IMPERSONATE: string = "refreshtoken_impersonate";
  public static readonly TOKEN_IMPERSONATE_EXPIRED: string = "token_impersonate_expired";

  public static readonly CLUSTER_URL: string = "cluster_url";

  public static readonly USERID: string = "userid";
  public static readonly USERID_IMPERSONATE: string = "userid_impersonate";
  public static readonly PERMISSIONS: string = "permissions";
  public static readonly PERMISSIONS_IMPERSONATE: string = "permissions_impersonate";

  public static readonly ID: string = "id";
  public static readonly ID_IMPERSONATE: string = "id_impersonate";

  public static readonly ACCOUNTID: string = "accountid";
  public static readonly ACCOUNTIDENTIFIER: string = "accountidentifier";
  public static readonly ACCOUNTID_IMPERSONATE: string = "accountid_impersonate";
  public static readonly ACCOUNTIDENTIFIER_IMPERSONATE: string = "accountidentifier_impersonate";

  public static readonly RESELLERID: string = "resellerid";
  public static readonly RESELLERID_IMPERSONATE: string = "resellerid_impersonate";

  public static readonly DRIVERID: string = "driverid";
  public static readonly DRIVERID_IMPERSONATE: string = "driverid_impersonate";

  public static readonly TIMEZONE: string = "timezone";
  public static readonly TIMEZONEIANA: string = "timezoneiana";
  public static readonly TIMEZONEIANA_IMPERSONATE: string = "timezoneiana_impersonate";
  public static readonly CULTURE: string = "culture";
  public static readonly LIMITDISTORYINDAYS: string = "limithistoryindays";

  public static readonly FEATUREFLAGS: string = "featureflags";
  public static readonly FEATUREFLAGS_IMPERSONATE: string = "featureflags_impersonate";

  public static readonly WHITELABEL: string = "whitelabel";
  public static readonly WHITELABEL_IMPERSONATE: string = "whitelabel_impersonate";

  public static readonly USERTYPE: string = "usertype";
  public static readonly USERTYPE_IMPERSONATE: string = "usertype_impersonate";

  public static readonly USERROLE: string = "userrole";
  public static readonly USERROLE_IMPERSONATE: string = "userrole_impersonate";

  public static readonly MAPPROVIDER: string = "mapprovider";
  public static readonly DISTANCEUNIT: string = "distanceunit";
  public static readonly DISTANCEUNIT_IMPERSONATE: string = "distanceunit_impersonate";

  public static readonly VOLUMEUNIT: string = "volumeunit";
  public static readonly ISIMPERSONATINGUSER: string = "isimpersonatinguser";
  public static readonly SSOPROVIDER: string = "ssoprovider";
  public static readonly SSOPROVIDERTOKEN: string = "ssoprovidertoken";

  public static getStaticToken(): string {
    return localStorage.getItem(AuthenticationService.TOKEN_IMPERSONATE)
      ? localStorage.getItem(AuthenticationService.TOKEN_IMPERSONATE)
      : localStorage.getItem(AuthenticationService.TOKEN);
  }

  public static getStaticRefreshToken(): string {
    return localStorage.getItem(AuthenticationService.REFRESHTOKEN_IMPERSONATE)
      ? localStorage.getItem(AuthenticationService.REFRESHTOKEN_IMPERSONATE)
      : localStorage.getItem(AuthenticationService.REFRESHTOKEN);
  }

  public static getRefreshTokenImpersonation(): boolean {
    return localStorage.getItem(AuthenticationService.REFRESHTOKEN_IMPERSONATE) ? true : false;
  }

  public static getStaticClusterUrl(): string {
    return localStorage.getItem(AuthenticationService.CLUSTER_URL);
  }

  public static getSSOSettings(): UserManagerSettings {
    return {
      authority: environment.SSOAuthenticationUrl,
      client_id: "Fleethealth",
      redirect_uri: environment.SSORedirectUrl,
      response_type: "id_token token",
      scope: "openid profile Roles",
      post_logout_redirect_uri: environment.SSOPostLogoutRedirectUrl,
      filterProtocolClaims: true,
      loadUserInfo: true,
    };
  }

  constructor(
    private http: HttpClient,
    private distance: DistanceUnitService,
    private colorService: ColorService,
    private router: Router,
    private location: Location,
    private cookieService: CookieService,
    private eventService: EventService
  ) {}

  setPermissions(setting, permissionList = []) {
    this.permissions = {};

    permissionList.forEach((permission) => {
      this.permissions[permission] = true;
    });

    if (this.permissions["HasExternal"] && !window["server_variables"]["HasExternal"]) {
      this.permissions["HasExternal"] = false;
    }

    const permissionJson = JSON.stringify(this.permissions);

    if (permissionJson.length > 0) {
      localStorage.setItem(setting, btoa(permissionJson));
    }
  }

  getPermissions() {
    // Try to decode
    const permissions = localStorage.getItem(AuthenticationService.PERMISSIONS_IMPERSONATE)
      ? localStorage.getItem(AuthenticationService.PERMISSIONS_IMPERSONATE)
      : localStorage.getItem(AuthenticationService.PERMISSIONS);
    try {
      const permissionJson = JSON.parse(atob(permissions));
      if (this.permissions) {
        this.permissions = permissionJson;
      }
    } catch (err) {
      console.error(err);
    }
  }

  ngOnInit() {}

  // The cookie we retrieve our ssoToken from.
  public getSsoCookie(enviroment: string): string {
    let returnVar = "authGpscockpit";
    if (enviroment.indexOf("clust01") !== -1) {
      returnVar = "authGpscockpit";
    }
    if (enviroment.indexOf("clust02") !== -1) {
      returnVar = "authGpscockpitCLUST02";
    }
    if (enviroment.indexOf("staging") !== -1) {
      returnVar = "authGpscockpit";
    }
    if (enviroment.indexOf("local") !== -1) {
      returnVar = "authGpscockpit";
    }
    return returnVar;
  }

  // The cookie we retrieve our ssoToken from.
  public getSessionCookie(enviroment: string): string {
    const returnVar = "sessionToken";
    return returnVar;
  }

  // TODO: will be deprecated, just to make code compile for now
  public getAuthToken(): string {
    const request = localStorage.getItem(AuthenticationService.TOKEN_IMPERSONATE)
      ? localStorage.getItem(AuthenticationService.TOKEN_IMPERSONATE)
      : localStorage.getItem(AuthenticationService.TOKEN);
    return request === "null" ? "" : request;
  }

  public getShareAuthToken(): string {
    const request = localStorage.getItem(AuthenticationService.SHARETOKEN);
    return request === "null" ? "" : request;
  }

  public get headers(): HttpHeaders {
    const token = this.getAuthToken();
    return new HttpHeaders({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    });
  }

  public get unAuthenticatedHeaders(): HttpHeaders {
    return new HttpHeaders({ "Content-Type": "application/json", Accept: "application/json" });
  }

  public get shareheaders(): HttpHeaders {
    const sharetoken = this.getShareAuthToken();
    return new HttpHeaders({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + sharetoken,
    });
  }

  public getAuthTokenExpired(): string {
    const request = localStorage.getItem(AuthenticationService.TOKEN_IMPERSONATE_EXPIRED)
      ? localStorage.getItem(AuthenticationService.TOKEN_IMPERSONATE_EXPIRED)
      : localStorage.getItem(AuthenticationService.TOKEN_EXPIRED);
    return request === "null" ? "" : request;
  }

  public getIsImpersonated(): boolean {
    return localStorage.getItem(AuthenticationService.TOKEN_IMPERSONATE) &&
      localStorage.getItem(AuthenticationService.TOKEN_IMPERSONATE) !== ""
      ? true
      : false;
  }

  public setImpersonationToken(result, redirect = true) {
    localStorage.setItem(AuthenticationService.ID_IMPERSONATE, result.user.userName);
    localStorage.setItem(AuthenticationService.USERID_IMPERSONATE, result.user.id);
    localStorage.setItem(AuthenticationService.ACCOUNTID_IMPERSONATE, result.user.accountId);
    localStorage.setItem(AuthenticationService.ACCOUNTIDENTIFIER_IMPERSONATE, result.user.accountIdentifier);
    localStorage.setItem(AuthenticationService.RESELLERID_IMPERSONATE, result.user.resellerId);
    localStorage.setItem(AuthenticationService.DRIVERID_IMPERSONATE, result.user.driverId);
    this.setPermissions(AuthenticationService.PERMISSIONS_IMPERSONATE, result.user.permissions);
    localStorage.setItem(AuthenticationService.TIMEZONEIANA_IMPERSONATE, result.user.timezoneIana);
    localStorage.setItem(AuthenticationService.WHITELABEL_IMPERSONATE, result.user.whitelabel);
    localStorage.setItem(AuthenticationService.USERTYPE_IMPERSONATE, result.user.userType);
    localStorage.setItem(AuthenticationService.USERROLE_IMPERSONATE, result.user.userRoleId);
    localStorage.setItem(AuthenticationService.DISTANCEUNIT_IMPERSONATE, result.user.distanceUnit);
    this.distance.setUnit(result.user.distanceUnit);
    localStorage.setItem(AuthenticationService.TOKEN_IMPERSONATE, result.token);
    localStorage.setItem(AuthenticationService.REFRESHTOKEN_IMPERSONATE, result.refreshToken);
    localStorage.setItem(AuthenticationService.FEATUREFLAGS_IMPERSONATE, result.user.featureFlags);

    if (redirect) {
      this.router.navigate(["/"]).then(() => {
        location.reload();
      });
    }
  }

  public stopImpersonation(reloadPage = true) {
    localStorage.setItem(AuthenticationService.TIMEZONEIANA_IMPERSONATE, "");
    localStorage.setItem(AuthenticationService.USERID_IMPERSONATE, "");
    localStorage.setItem(AuthenticationService.ACCOUNTID_IMPERSONATE, "");
    localStorage.setItem(AuthenticationService.ACCOUNTIDENTIFIER_IMPERSONATE, "");
    localStorage.setItem(AuthenticationService.RESELLERID_IMPERSONATE, "");
    localStorage.setItem(AuthenticationService.DRIVERID_IMPERSONATE, "");
    localStorage.setItem(AuthenticationService.ID_IMPERSONATE, "");
    localStorage.setItem(AuthenticationService.PERMISSIONS_IMPERSONATE, "");
    localStorage.setItem(AuthenticationService.WHITELABEL_IMPERSONATE, "");
    localStorage.setItem(AuthenticationService.USERTYPE_IMPERSONATE, "");
    localStorage.setItem(AuthenticationService.USERROLE_IMPERSONATE, "");
    localStorage.setItem(AuthenticationService.DISTANCEUNIT_IMPERSONATE, "");
    localStorage.setItem(AuthenticationService.TOKEN_IMPERSONATE, "");
    localStorage.setItem(AuthenticationService.REFRESHTOKEN_IMPERSONATE, "");
    localStorage.setItem(AuthenticationService.FEATUREFLAGS_IMPERSONATE, "");

    this.distance.setUnit(this.getDistanceUnit());

    if (reloadPage) {
      location.reload();
    }
  }

  public getUserId(): string {
    return localStorage.getItem(AuthenticationService.USERID_IMPERSONATE)
      ? localStorage.getItem(AuthenticationService.USERID_IMPERSONATE)
      : localStorage.getItem(AuthenticationService.USERID);
  }

  public getDistanceUnit(): string {
    return localStorage.getItem(AuthenticationService.DISTANCEUNIT_IMPERSONATE)
      ? localStorage.getItem(AuthenticationService.DISTANCEUNIT_IMPERSONATE)
      : localStorage.getItem(AuthenticationService.DISTANCEUNIT);
  }

  public getVolumeUnit(): string {
    return localStorage.getItem(AuthenticationService.VOLUMEUNIT);
  }

  public getWhitelabel(): string {
    return localStorage.getItem(AuthenticationService.WHITELABEL_IMPERSONATE)
      ? localStorage.getItem(AuthenticationService.WHITELABEL_IMPERSONATE)
      : localStorage.getItem(AuthenticationService.WHITELABEL);
  }

  public getUserType(): string {
    return localStorage.getItem(AuthenticationService.USERTYPE_IMPERSONATE)
      ? localStorage.getItem(AuthenticationService.USERTYPE_IMPERSONATE)
      : localStorage.getItem(AuthenticationService.USERTYPE);
  }

  public getUserRole(): string {
    return localStorage.getItem(AuthenticationService.USERROLE_IMPERSONATE)
      ? localStorage.getItem(AuthenticationService.USERROLE_IMPERSONATE)
      : localStorage.getItem(AuthenticationService.USERROLE);
  }

  public getFeatureFlags(): string {
    return localStorage.getItem(AuthenticationService.FEATUREFLAGS_IMPERSONATE)
      ? localStorage.getItem(AuthenticationService.FEATUREFLAGS_IMPERSONATE)
      : localStorage.getItem(AuthenticationService.FEATUREFLAGS);
  }

  public getCultureLang(): string {
    const serverCulture = this.getServerCulture();

    // Try to guess the culture from the language
    switch (serverCulture) {
      case "nl-NL":
        return "nl";
      case "fr-FR":
        return "fr";
      case "ar-EG":
        return "ar";
      case "ar-AE":
        return "ar";
      case "en-US":
        return "en";
      case "en-GB":
        return "en";
      case "de-DE":
        return "de";
      default:
        console.warn(`Could not translate language ${serverCulture} to culture. Falling back to server culture`);
        return serverCulture;
    }
  }

  public getServerCulture(): string {
    return localStorage.getItem(AuthenticationService.CULTURE);
  }

  public getTimeZone(): string {
    return localStorage.getItem(AuthenticationService.TIMEZONE);
  }

  public getTimeZoneIana(): string {
    return localStorage.getItem(AuthenticationService.TIMEZONEIANA_IMPERSONATE)
      ? localStorage.getItem(AuthenticationService.TIMEZONEIANA_IMPERSONATE)
      : localStorage.getItem(AuthenticationService.TIMEZONEIANA);
  }

  public getId(): string {
    return localStorage.getItem(AuthenticationService.ID_IMPERSONATE) &&
      localStorage.getItem(AuthenticationService.ID_IMPERSONATE) !== ""
      ? localStorage.getItem(AuthenticationService.ID_IMPERSONATE)
      : localStorage.getItem(AuthenticationService.ID);
  }

  public getAccountId(): string {
    return localStorage.getItem(AuthenticationService.ACCOUNTID_IMPERSONATE) &&
      localStorage.getItem(AuthenticationService.ACCOUNTID_IMPERSONATE) !== ""
      ? localStorage.getItem(AuthenticationService.ACCOUNTID_IMPERSONATE)
      : localStorage.getItem(AuthenticationService.ACCOUNTID);
  }

  public getAccountIdentifier(): string {
    return localStorage.getItem(AuthenticationService.ACCOUNTIDENTIFIER_IMPERSONATE) &&
      localStorage.getItem(AuthenticationService.ACCOUNTIDENTIFIER_IMPERSONATE) !== ""
      ? localStorage.getItem(AuthenticationService.ACCOUNTIDENTIFIER_IMPERSONATE)
      : localStorage.getItem(AuthenticationService.ACCOUNTIDENTIFIER);
  }

  public getResellerId(): string {
    return localStorage.getItem(AuthenticationService.RESELLERID_IMPERSONATE) &&
      localStorage.getItem(AuthenticationService.RESELLERID_IMPERSONATE) !== ""
      ? localStorage.getItem(AuthenticationService.RESELLERID_IMPERSONATE)
      : localStorage.getItem(AuthenticationService.RESELLERID);
  }

  public getDriverId(): string {
    return localStorage.getItem(AuthenticationService.DRIVERID_IMPERSONATE) &&
      localStorage.getItem(AuthenticationService.DRIVERID_IMPERSONATE) !== ""
      ? localStorage.getItem(AuthenticationService.DRIVERID_IMPERSONATE)
      : localStorage.getItem(AuthenticationService.DRIVERID);
  }

  public getWebserviceURL(resource: string): string {
    // Checks if the url is set, if not then get new one. Else return the url.
    const baseUrl = localStorage.getItem(AuthenticationService.CLUSTER_URL);
    return baseUrl === "null" ? null : baseUrl + (resource === "" ? "" : resource + "/");
  }

  public getLimitHistoryInDays(): string {
    return localStorage.getItem(AuthenticationService.LIMITDISTORYINDAYS);
  }

  public IsAuthenticated(_path: string, forceCheck = false): Observable<boolean> {
    const config = this.getConfig();

    this.clearLocalStorage().then();

    if (_path == null) {
      _path = this.location.path();
    }

    // Check if redirect / share token
    if (_path?.toLowerCase().indexOf("sharing") > -1 && _path?.toLowerCase().indexOf("carsharing") == -1) {
      console.log("Using share token so returning true");

      return of(true);
    }

    if (this.isImpersonatingUser() || !this.isTokenValid() || forceCheck === true) {
      if (this.config.Debug) {
        console.log("Authentication: Testing Token for validity... ");
      }
      // get ssotoken cookie
      const ssoToken = AuthenticationService.getStaticToken();
      const refreshToken = AuthenticationService.getStaticRefreshToken();
      const isImpersonated = AuthenticationService.getRefreshTokenImpersonation();

      if (refreshToken != null && refreshToken !== "null" && refreshToken !== "") {
        if (this.config.Debug) {
          console.log("Authentication: Trying to login with refreshToken... ");
        }
        return this.performLoginWithRefreshToken(refreshToken, config, isImpersonated).pipe(
          map((result) => {
            if (result != null) {
              console.log("Authentication: Refresh Session is verified!");
              this.loggedIn.next(true);
              return true;
            }

            if (this.config.Debug) {
              console.log("Authentication: Could not login with refreshToken!");
            }
            this.router.navigate(["/Login"], { queryParams: { redirect: encodeURI(_path) } });
            return false;
          }),
          catchError((error) => {
            if (this.config.Debug) {
              console.log("Authentication: Could not login with refreshToken!", error);
            }
            this.router.navigate(["/Login"], { queryParams: { redirect: encodeURI(_path) } });
            return of(false);
          })
        );
      } else if (ssoToken != null && ssoToken !== "null" && ssoToken !== "") {
        if (this.config.Debug) {
          console.log("Authentication: Trying to login with ssoToken...");
        }
        return this.performLoginWithSsoToken(ssoToken, config).pipe(
          map((result) => {
            // Test if we have userId
            if (!this.getUserId()) {
              this.router.navigate(["/Login"], { queryParams: { redirect: encodeURI(_path) } });
            }

            if (result === true) {
              console.log("Authentication: Session is verified!");
              this.loggedIn.next(true);
              return true;
            }

            if (this.config.Debug) {
              console.log("Authentication: Could not login with ssoToken!");
            }
            this.router.navigate(["/Login"], { queryParams: { redirect: encodeURI(_path) } });
            return false;
          }),
          catchError((error) => {
            if (this.config.Debug) {
              console.log("Authentication: Could not login with ssoToken!");
            }
            this.router.navigate(["/Login"], { queryParams: { redirect: encodeURI(_path) } });
            return of(false);
          })
        );
      } else {
        this.router.navigate(["/Login"], { queryParams: { redirect: encodeURI(_path) } });
        return of(false);
      }
    } else {
      if (this.config.Debug) {
        console.log("Authentication: Token not expired yet.");
      }
      this.getPermissions();
    }

    if (this.config.Debug) {
      console.log("Authentication: Done with IsAuthenticated.");
    }
  }

  performLoginWithSsoToken(token: string, config: Config): Observable<any> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    });

    return this.http.get(config.AuthenticationUrl + "Authentication/FromToken", { headers: headers }).pipe(
      map((res) => {
        return res;
      })
    );
  }

  performLoginWithRefreshToken(token: string, config: Config, isImpersonated: boolean): Observable<any> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    });

    return this.http
      .post(config.AuthenticationUrl + "Authentication/FromRefreshToken", { RefreshToken: token }, { headers: headers })
      .pipe(
        map((res) => {
          const result = res as AuthenticateResult;
          result.environment = config.Environment;
          result.clusterUrl = config.AuthenticationUrl;

          if (!isImpersonated) {
            console.log("Saving normal token");
            const resultToken = this.saveToken(result);
            return resultToken;
          } else {
            console.log("Saving impersonation token");
            const resultToken = this.setImpersonationToken(result, false);
            return true;
          }
        })
      );
  }

  performReset(userName: string) {
    if (this.config && this.config.Debug) {
      console.log("AuthenticateService: Performing reset for user: " + userName);
    }

    const config = this.getConfig();
    const body = '"' + encodeURIComponent(userName) + '"';

    const headers = new HttpHeaders({ "Content-Type": "application/json", Accept: "application/json" });

    return this.http.post(config.AuthenticationUrl + "User/resetByUsername", body, { headers: headers });
  }

  performLogin(
    userName: string,
    password: string,
    loginAsRegularUser = false,
    firebaseToken: string
  ): Observable<AuthenticateResult> {
    if (this.config && this.config.Debug) {
      console.log("AuthenticateService: Performing login for user: " + userName);
    }

    const config = this.getConfig();

    const passwordHashed = sha256(password);

    const request = new AuthenticateRequest(
      userName,
      passwordHashed,
      loginAsRegularUser,
      null,
      firebaseToken,
      this.useRefreshToken
    );

    const body = JSON.stringify(request);
    const headers = new HttpHeaders({ "Content-Type": "application/json", Accept: "application/json" });

    return this.http.post(config.AuthenticationUrl + "Authentication", body, { headers: headers }).pipe(
      map((res) => {
        const result = res as AuthenticateResult;
        result.environment = config.Environment;
        result.clusterUrl = config.AuthenticationUrl;
        return this.saveToken(result);
      })
    );
  }

  performLoginWithSSO(userName: string, provider: string, token: string): Observable<AuthenticateResult> {
    if (this.config && this.config.Debug) {
      console.log("AuthenticateService: Performing SSO login for user: " + userName);
    }

    localStorage.setItem(AuthenticationService.ID, userName);
    localStorage.setItem(AuthenticationService.SSOPROVIDER, provider);
    localStorage.setItem(AuthenticationService.SSOPROVIDERTOKEN, token);

    const config = this.getConfig();

    const headers = new HttpHeaders({ "Content-Type": "application/json", Accept: "application/json" });
    const body = JSON.stringify(userName);

    return this.http
      .post(config.AuthenticationUrl + "Authentication/SSOAuthentication", body, { headers: headers })
      .pipe(
        map((res) => {
          const result = res as AuthenticateResult;
          result.environment = config.Environment;
          result.clusterUrl = config.AuthenticationUrl;
          return this.saveToken(result);
        })
      );
  }

  private getConfig(): Config {
    const config = (this.config = this.environmentToConfig());
    if (this.config && this.config.Debug) {
      console.log("getConfig: Retrieved configuration, enviroment: " + config.Environment);
    }
    return config;
  }

  // XXX: replace Config with environment completely
  private environmentToConfig(): Config {
    const cfg = new Config();

    cfg.version = environment.version;
    cfg.AuthenticationUrl = window["server_variables"].AuthenticationUrl;
    cfg.ConsumerToken = environment.ConsumerToken;
    cfg.Debug = environment.Debug;
    cfg.Environment = environment.Environment;
    cfg.SSOAuthenticationUrl = environment.SSOAuthenticationUrl;
    cfg.SSOPostLogoutRedirectUrl = environment.SSOPostLogoutRedirectUrl;
    cfg.SSORedirectUrl = environment.SSORedirectUrl;

    return cfg;
  }

  saveToken(authenticateResult: AuthenticateResult): AuthenticateResult {
    if (authenticateResult) {
      this.config = this.getConfig();
      if (authenticateResult.token && authenticateResult.clusterUrl) {
        // Clearing cache
        this.loggedIn.next(false);

        this.stopImpersonation(false);

        if (this.config.Debug) {
          console.log(
            `Authentication: Saving login token: ${authenticateResult.token} expires: ${authenticateResult.tokenExpires} url: ${authenticateResult.clusterUrl}`
          );
        }

        const url = window.location.origin;
        let overwriteWhitelabel = "";
        overwriteWhitelabel = this.colorService.getOverwriteTheme(url);
        if (overwriteWhitelabel !== "") {
          console.log(`Overwriting whitelabel with path ${url} setting theme ${overwriteWhitelabel}`);

          authenticateResult.user.whitelabel = overwriteWhitelabel;
        }

        // this.eventService.setWhitelabel('360');
        localStorage.setItem(AuthenticationService.WHITELABEL, authenticateResult.user.whitelabel);
        localStorage.setItem(AuthenticationService.USERTYPE, authenticateResult.user.userType);
        localStorage.setItem(AuthenticationService.USERROLE, authenticateResult.user.userRoleId);

        localStorage.setItem(AuthenticationService.TOKEN, authenticateResult.token);
        localStorage.setItem(AuthenticationService.REFRESHTOKEN, authenticateResult.refreshToken);
        localStorage.setItem(AuthenticationService.TOKEN_EXPIRED, authenticateResult.tokenExpires);
        localStorage.setItem(AuthenticationService.CLUSTER_URL, authenticateResult.clusterUrl);
        localStorage.setItem(AuthenticationService.USERID, authenticateResult.user.id);

        localStorage.setItem(AuthenticationService.ID, authenticateResult.user.userName);
        localStorage.setItem(AuthenticationService.ACCOUNTID, authenticateResult.user.accountId);
        localStorage.setItem(AuthenticationService.ACCOUNTIDENTIFIER, authenticateResult.user.accountIdentifier);
        localStorage.setItem(AuthenticationService.RESELLERID, authenticateResult.user.resellerId);
        localStorage.setItem(AuthenticationService.DRIVERID, authenticateResult.user.driverId);

        localStorage.setItem(AuthenticationService.DISTANCEUNIT, authenticateResult.user.distanceUnit);
        this.distance.setUnit(authenticateResult.user.distanceUnit);

        localStorage.setItem(AuthenticationService.CULTURE, authenticateResult.user.culture);

        localStorage.setItem(AuthenticationService.TIMEZONE, authenticateResult.user.timeZone);
        localStorage.setItem(AuthenticationService.TIMEZONEIANA, authenticateResult.user.timezoneIana);

        localStorage.setItem(AuthenticationService.LIMITDISTORYINDAYS, authenticateResult.user.limitHistoryInDays);

        localStorage.setItem(AuthenticationService.FEATUREFLAGS, authenticateResult.user.featureFlags);

        this.setPermissions(AuthenticationService.PERMISSIONS, authenticateResult.user.permissions);

        this.cookieService.set(this.getSessionCookie(this.config.Environment), authenticateResult.token);

        return authenticateResult;
      }
    }

    return null;
  }

  clearToken(redirect = true) {
    if (this.config.Debug) {
      console.log("Authentication: Logging out and removing tokens");
    }

    localStorage.removeItem(AuthenticationService.ACCOUNTID);
    localStorage.removeItem(AuthenticationService.RESELLERID);
    localStorage.removeItem(AuthenticationService.TOKEN);
    localStorage.removeItem(AuthenticationService.REFRESHTOKEN);
    localStorage.removeItem(AuthenticationService.TOKEN_EXPIRED);
    localStorage.removeItem(AuthenticationService.CLUSTER_URL);
    localStorage.removeItem(AuthenticationService.USERID);
    localStorage.removeItem(AuthenticationService.TIMEZONE);
    localStorage.removeItem(AuthenticationService.CULTURE);
    localStorage.removeItem(AuthenticationService.DISTANCEUNIT);
    localStorage.removeItem(AuthenticationService.DISTANCEUNIT);
    localStorage.removeItem(AuthenticationService.VOLUMEUNIT);
    localStorage.removeItem(AuthenticationService.USERTYPE);
    localStorage.removeItem(AuthenticationService.USERROLE);
    localStorage.removeItem(AuthenticationService.WHITELABEL);
    localStorage.removeItem(AuthenticationService.MAPPROVIDER);
    localStorage.removeItem(AuthenticationService.ISIMPERSONATINGUSER);
    localStorage.removeItem(AuthenticationService.SSOPROVIDER);
    localStorage.removeItem(AuthenticationService.SSOPROVIDERTOKEN);
    localStorage.removeItem(AuthenticationService.FEATUREFLAGS);

    const enviroment = "clust01";
    const cookie = this.getSessionCookie(enviroment);
    let domain = "";

    if (enviroment.indexOf("staging") !== -1) {
      domain = ".gpscockpit.net";
    } else {
      domain = ".gpscockpit.com";
    }
    if (this.config.Debug) {
      console.log(`Authentication: Logging out and removing ${cookie} for domain ${domain}`);
    }
    this.cookieService.delete(cookie, "/", domain);

    if (this.config.Debug) {
      console.log("Authentication: Redirecting");
    }
    if (redirect) {
      this.router.navigate(["/Login"]);
    }
  }

  private isImpersonatingUser(): boolean {
    const value = localStorage.getItem(AuthenticationService.ISIMPERSONATINGUSER);
    if (value) {
      return JSON.parse(value);
    }
    return false;
  }

  public isTokenValid(): boolean {
    if (this.config && this.config.Debug) {
      console.log("Authentication: Testing token validity...");
    }

    const token = localStorage.getItem(AuthenticationService.TOKEN);
    const tokenExpired = localStorage.getItem(AuthenticationService.TOKEN_EXPIRED);

    if (token != null && tokenExpired != null) {
      var now = new Date();
      var utc_timestamp = Date.UTC(
        now.getUTCFullYear(),
        now.getUTCMonth(),
        now.getUTCDate(),
        now.getUTCHours(),
        now.getUTCMinutes(),
        now.getUTCSeconds(),
        now.getUTCMilliseconds()
      );
      var expireDate = Date.parse(tokenExpired);

      if (utc_timestamp < expireDate) {
        if (this.config && this.config.Debug) {
          console.log(`Token still valid for (${roundAsString((expireDate - utc_timestamp) / 1000, 0)} seconds)!`);
        }
        return true;
      }
    }

    if (this.config && this.config.Debug) {
      console.log("Authentication: Token is invalid!");
    }
    return false;
  }

  public isShareTokenValid(): boolean {
    if (this.config && this.config.Debug) {
      console.log("Authentication: Testing token validity...");
    }

    const token = localStorage.getItem(AuthenticationService.SHARETOKEN);
    const tokenExpired = localStorage.getItem(AuthenticationService.SHARETOKEN_EXPIRED);

    if (token != null && tokenExpired != null) {
      var now = new Date();
      var utc_timestamp = Date.UTC(
        now.getUTCFullYear(),
        now.getUTCMonth(),
        now.getUTCDate(),
        now.getUTCHours(),
        now.getUTCMinutes(),
        now.getUTCSeconds(),
        now.getUTCMilliseconds()
      );
      var expireDate = Date.parse(tokenExpired);

      if (utc_timestamp < expireDate) {
        if (this.config && this.config.Debug) {
          console.log(`Token still valid for (${roundAsString((expireDate - utc_timestamp) / 1000, 0)} seconds)!`);
        }
        return true;
      }
    }

    if (this.config && this.config.Debug) {
      console.log("Authentication: Token is invalid!");
    }
    return false;
  }

  clearLocalStorage(): Promise<void> {
    return this.manager.clearStaleState().then((result) => {
      if (this.config.Debug) {
        console.log("state cleared");
      }
    });
  }

  revokeAccessGoogle(): Observable<Object> {
    const token = localStorage.getItem(AuthenticationService.SSOPROVIDERTOKEN);
    const headers = new HttpHeaders({
      "Cache-Control": "no-store",
      "Content-Type": "application/x-www-form-urlencoded",
    });

    return this.http.post("https://accounts.google.com/o/oauth2/revoke?token=" + token, null, { headers: headers });
  }

  signInWithSSO(idtoken: string): Observable<AuthenticateResult> {
    const userData = jwtDecode(idtoken);
    const nonce = userData["nonce"];
    const userName = userData["Email"];
    if (this.checkNonce(nonce)) {
      return this.performLoginWithSSO(userName, "sso360", idtoken);
    }
    return throwError("Something went wrong while trying to login");
  }

  checkNonce(nonce: string): boolean {
    const local = localStorage.getItem("360SSO");
    if (nonce === local) {
      localStorage.removeItem("360SSO");
      return true;
    }
    return false;
  }

  // Sharetokens
  public IsAuthenticatedShareToken(path: string): Observable<any> {
    this.config = this.getConfig();

    if (this.config.Debug) {
      console.log("Authentication: Done with IsAuthenticatedShareToken.");
    }

    if (this.isShareTokenValid()) {
      return of(true);
    } else {
      this.router.navigate(["/Login"]);
      return of(false);
    }
  }

  public LoginShareToken(shareToken): Observable<any> {
    const config = this.getConfig();

    return this.performLoginWithShareToken(shareToken, config).pipe(
      map((result) => {
        result.environment = config.Environment;
        result.clusterUrl = config.AuthenticationUrl;
        result.whitelabel = "WDemo";

        this.saveShareToken(result);

        return result;
      }),
      catchError((error) => {
        console.log(error);
        if (this.config.Debug) {
          console.log("Authentication: Could not login with shareToken!");
        }
        this.router.navigate(["/Login"], { queryParams: { redirect: encodeURI("") } });
        return of(false);
      })
    );
  }

  performLoginWithShareToken(token: string, config: Config): Observable<any> {
    return this.http.post(
      config.AuthenticationUrl + "Authentication/AuthenticateFromShareToken",
      { shareToken: token },
      { headers: this.unAuthenticatedHeaders }
    );
  }

  saveShareToken(authenticateResult): AuthenticateResult {
    if (authenticateResult) {
      this.config = this.getConfig();
      if (authenticateResult.token && authenticateResult.clusterUrl) {
        // Clearing cache
        // this.loggedIn.next(false);

        // this.stopImpersonation(false);

        localStorage.setItem(AuthenticationService.SHARETOKEN, authenticateResult.token);
        localStorage.setItem(
          AuthenticationService.SHARETOKEN_EXPIRED,
          authenticateResult.tokenExpiryTimestamp?.toString()
        );

        localStorage.setItem(AuthenticationService.CLUSTER_URL, authenticateResult.clusterUrl);

        localStorage.setItem(AuthenticationService.WHITELABEL, authenticateResult.whiteLabel);
        localStorage.setItem(AuthenticationService.DISTANCEUNIT, authenticateResult.distanceUnit);

        localStorage.setItem(AuthenticationService.WHITELABEL, authenticateResult.whiteLabel);

        localStorage.setItem(AuthenticationService.DISTANCEUNIT, authenticateResult.distanceUnit);

        localStorage.setItem(AuthenticationService.TIMEZONE, authenticateResult.timeZone);
        localStorage.setItem(AuthenticationService.TIMEZONEIANA, authenticateResult.timeZoneIana);

        return authenticateResult;
      }

      return null;
    }
  }
}
