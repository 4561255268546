<form #groupForm="ngForm" style="width: 100%; border-top: 0px">
    <table class="table nowrap dtr-inline no-footer">
        <tbody>
            <tr>
                <td class="row_opening">
                    {{ "general.schedule" | translate }}
                </td>
                <td class="row_content" style="overflow: visible">
                    <ngx-loading [show]="loading || saving"></ngx-loading>
                    <fh-schedule-input
                        name="triggerSchedules"
                        #triggerSchedueVar="ngModel"
                        [selectedAccountId]="selectedAccountId"
                        (ngModelChange)="selectedScheduleChanged($event)"
                        placeholder="{{
                            (selectedAccountId ? 'general.chooseTriggerSchedule' : 'general.firstChooseAccount')
                                | translate
                        }}"
                        [(ngModel)]="scheduleAssignment.triggerScheduleId"
                        [clearable]="true"
                    >
                    </fh-schedule-input>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ "general.startDate" | translate }}
                </td>
                <td class="row_content">
                    <input
                        type="text"
                        name="assignmentStart"
                        [bsConfig]="dpConfig"
                        placeholder="{{ 'placeholder.assignmentStart' | translate }}"
                        bsDatepicker
                        [(ngModel)]="scheduleAssignment.startDate"
                        class="form-control table-inline"
                    />
                    <span *ngIf="formMode == 3" class="input-group-calendar"> <i class="fa fa-calendar-alt"></i></span>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ "general.endDate" | translate }}
                </td>
                <td class="row_content">
                    <input
                        type="text"
                        name="assignmentEnd"
                        [bsConfig]="dpConfig"
                        placeholder="{{ 'placeholder.assignmentEnd' | translate }}"
                        bsDatepicker
                        [(ngModel)]="scheduleAssignment.endDate"
                        class="form-control table-inline"
                    />
                    <span *ngIf="formMode == 3" class="input-group-calendar"> <i class="fa fa-calendar-alt"></i></span>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ "general.applyScheduleToTrip" | translate }}
                </td>
                <td class="row_content">
                    <div class="fa_checkbox">
                        <input
                            name="applyScheduleToTrip"
                            id="applyScheduleToTrip"
                            #personal="ngModel"
                            type="checkbox"
                            [(ngModel)]="scheduleAssignment.applyScheduleToTrip"
                        />
                        <label for="applyScheduleToTrip"></label>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</form>
