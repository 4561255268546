import { NgModule } from "@angular/core";

import { BatchArchiveAssetsComponent } from "./archiveAssets.component";
import { BatchUnArchiveAssetsComponent } from "./unArchiveAssets.component";
import { TagInputModule } from "ngx-chips";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MomentModule } from "ngx-moment";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { WizardModule } from "app/modules/wizard/wizard.module";
import { TranslateModule } from "@ngx-translate/core";
import { ModalModule } from "ngx-bootstrap/modal";
import { BatchUpdateFieldsComponent } from "./updateFields.component";
import { BatchActivateDeviceComponent } from "./activateDevice.component";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { AssetIconChooserDetailsComponent } from "../../assetIconChooser.component";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { BatchDeleteComponent } from "./delete.component";
import { BatchGetLocationComponent } from "./getLocation.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgOptionHighlightModule } from "@ng-select/ng-option-highlight";
import { BatchChangeInventoryComponent } from "./changeInventory.component";
import { ColorPickerModule } from "ngx-color-picker";
import { BatchAddLogComponent } from "./batchAddLog.component";
import { BatchMoveToClientComponent } from "./moveToClient.component";
import { BatchReplaceDeviceComponent } from "./replaceDevice.component";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { AlertModule } from "ngx-bootstrap/alert";
import { BatchRemoveFromAccountComponent } from "./removeFromAccount.component";
import { BatchRegisterWaslComponent } from "./registerWasl.component";
import { BatchUnRegisterWaslComponent } from "./unRegisterWasl.component";
import { ngxLoadingAnimationTypes } from "app/modules/loading/ngx-loading-config";
import { NgxLoadingModule } from "app/modules/loading/ngx-loading.module";
import { CopyInputBoxModule } from "app/modules/copyInputBox/copyInputBox.module";
import { CustomInputModule } from "app/modules/customInputs/customInput.module";
import { BatchDeleteHistoryComponent } from "./deleteHistory.component";
import { BatchDeviceResetComponent } from "./deviceReset.component";
import { BatchDeviceVirtualSensorsComponent } from "./deviceVirtualSensors.component";
import { BatchCalibrateOdoComponent } from "./odo.component";
import { BatchInstantReportComponent } from "./instantReport.component";
import { BatchRerunTripsComponent } from "./rerunTrips.component";
import { BatchBasicTriggerComponent } from "./createBasicTrigger.component";
import { BatchSimpleComponent } from "./batchSimple.component";

@NgModule({
  declarations: [
    BatchArchiveAssetsComponent,
    BatchUnArchiveAssetsComponent,
    BatchAddLogComponent,
    BatchDeleteComponent,
    BatchSimpleComponent,
    BatchChangeInventoryComponent,
    BatchMoveToClientComponent,
    BatchReplaceDeviceComponent,
    BatchRemoveFromAccountComponent,
    BatchActivateDeviceComponent,
    BatchUpdateFieldsComponent,
    BatchGetLocationComponent,
    BatchDeleteHistoryComponent,
    BatchRegisterWaslComponent,
    BatchUnRegisterWaslComponent,
    BatchDeviceResetComponent,
    BatchDeviceVirtualSensorsComponent,
    BatchCalibrateOdoComponent,
    BatchInstantReportComponent,
    BatchRerunTripsComponent,
    BatchBasicTriggerComponent,
    AssetIconChooserDetailsComponent,
  ],
  imports: [
    CustomInputModule,
    ColorPickerModule,
    NgSelectModule,
    NgOptionHighlightModule,
    TagInputModule,
    BrowserAnimationsModule,
    FormsModule,
    MomentModule,
    ReactiveFormsModule,
    BrowserModule,
    RouterModule,
    AlertModule.forRoot(),
    ModalModule.forRoot(),
    TypeaheadModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TooltipModule.forRoot(),
    WizardModule,
    CopyInputBoxModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.rectangleBounce,
      backdropBackgroundColour: "rgba(255,255,255,0.7)",
      backdropBorderRadius: "4px",
      primaryColour: "#DC8139",
      secondaryColour: "#DC8139",
      tertiaryColour: "#DC8139",
    }),
    TranslateModule,
  ],
  exports: [
    BatchArchiveAssetsComponent,
    BatchUnArchiveAssetsComponent,
    BatchAddLogComponent,
    BatchChangeInventoryComponent,
    BatchSimpleComponent,
    BatchMoveToClientComponent,
    BatchReplaceDeviceComponent,
    BatchRemoveFromAccountComponent,
    BatchDeleteComponent,
    BatchActivateDeviceComponent,
    BatchUpdateFieldsComponent,
    BatchGetLocationComponent,
    BatchDeleteHistoryComponent,
    AssetIconChooserDetailsComponent,
    BatchRegisterWaslComponent,
    BatchDeviceResetComponent,
    BatchDeviceVirtualSensorsComponent,
    BatchCalibrateOdoComponent,
    BatchInstantReportComponent,
    BatchRerunTripsComponent,
    BatchBasicTriggerComponent,
    BatchUnRegisterWaslComponent,
  ],
})
export class BatchProcessingUsercontrolsModule {}
