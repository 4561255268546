<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/"> <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li
                    class="breadcrumb-item"
                    *ngIf="permissions['Accounts_View'] && device?.asset && device?.asset.accountId"
                >
                    <a href="/#/AccountDetails/Index/{{device?.asset.accountId}}">{{device?.asset?.companyName}}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Devices/Overview?companyName={{device?.companyName}}"
                        >{{'general.devices' | translate }}</a
                    >
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/DeviceDetails/Index/{{device?.id}}"
                        >{{device?.asset?.name ? device?.asset?.name : device?.name}}</a
                    >
                </li>
                <li class="breadcrumb-item active">{{'menu.fuel' | translate }}</li>
            </ol>
        </nav>
    </div>
    <fh-device-details-navigation [deviceId]="deviceId" [device]="device"></fh-device-details-navigation>
    <div *ngIf="!showMapOnSide" class="col-lg-12" style="padding: 1px 0 0 0">
        <ngx-loading [show]="loadingGeofences"></ngx-loading>
        <fh-leaflet-map
            (mapReady)="onMapReady($event)"
            [geofences]="geofences"
            [height]="mapHeight"
            [geofenceEnabled]="false"
            [allowBookmarks]="false"
        >
        </fh-leaflet-map>
    </div>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">
        <div class="row" data-equalizer="">
            <div class="col-lg-12">
                <fh-error [error]="error" [warning]="warning" [success]="success" [autoRemove]="false"></fh-error>
            </div>
        </div>
        <div class="row" data-equalizer="" *ngIf="hasFuelEnabled || loading">
            <div class="col-lg-8">
                <div class="ibox">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-filter"></i> {{'general.filter' | translate }}</h5>
                    </div>
                    <div class="ibox-content slick_toppadding flex-container" style="position: relative">
                        <div class="col-lg-6">
                            <div class="form-group input-group" style="margin-bottom: 5px">
                                <span class="input-group-prepend">
                                    <i class="fa fa-fw fa-calendar"></i>
                                </span>
                                <input
                                    placeholder="Daterangepicker"
                                    style="width: 100%"
                                    placeholder="Datepicker"
                                    type="text"
                                    [bsConfig]="dpConfig"
                                    class="form-control"
                                    bsDaterangepicker
                                    [maxDate]="maxDate"
                                    (onHidden)="dateChanged('hidden')"
                                    [(ngModel)]="daterangepickerModel"
                                />
                                <span class="input-group-calendar-datatables">
                                    <i class="fa fa-calendar-alt"></i>
                                </span>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group input-group" style="margin-bottom: 5px">
                                <span class="input-group-prepend">
                                    <i class="fa fa-fw fa-percent"></i>
                                </span>
                                <input
                                    type="text"
                                    name="certainty"
                                    id="certainty"
                                    style="width: 100%"
                                    class="form-control"
                                    [(ngModel)]="certainty"
                                    (change)="dateChanged($event)"
                                />
                            </div>
                        </div>
                        <div style="clear: both"></div>
                    </div>
                </div>

                <div class="ibox">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-gas-pump"></i> {{'general.events' | translate }}</h5>
                        <span class="float-right">
                            <div class="filter-form d-none d-md-block">
                                <input
                                    type="text"
                                    class="form-control table-inline top-filter d-none d-md-block"
                                    (ngModelChange)="p.setCurrent(0)"
                                    [(ngModel)]="filter"
                                />
                            </div>
                        </span>
                    </div>
                    <div class="ibox-content slick" style="position: relative; overflow-y: auto">
                        <ngx-loading [show]="loadingFuel"></ngx-loading>
                        <table style="width: 100%" class="table nowrap dtr-inline no-footer">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th style="width: 140px">{{ 'general.date' | translate}}</th>
                                    <th>{{ 'general.source' | translate}}</th>
                                    <th>{{ 'general.event' | translate}}</th>
                                    <th>{{ 'general.theftInLiters' | translate}}</th>
                                    <th>{{ 'general.fillupInLiters' | translate}}</th>
                                    <th>{{ 'general.endFuelEventFuelLevelInLiters' | translate}}</th>
                                    <th>{{ 'general.certaintyInPercentage' | translate}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngIf="filteredFuelEvents.length == 0 && !loadingFuel">
                                    <tr>
                                        <td colspan="8">{{ 'general.noDataFound' | translate}}</td>
                                    </tr>
                                </ng-container>
                                <ng-container
                                    *ngFor="let event of filteredFuelEvents | orderBy : 'eventTimeStamp':true | stringFilter: filter | paginate: { itemsPerPage: 10, currentPage: currentPage }"
                                >
                                    <tr (click)="fetchLocations(event)" class="hand">
                                        <td style="width: 20px">
                                            <a
                                                class="btn btn-primary btn-grid"
                                                (click)="$event.stopPropagation();"
                                                href="/#/FuelDetails/Index/{{event.id}}"
                                            >
                                                <span class="d-none d-md-inline-flex" style="padding-left: 7px"
                                                    >{{'general.details' | translate }}</span
                                                >
                                                <i class="fas fa-fw fa-angle-right"></i>
                                            </a>
                                        </td>
                                        <td>{{ event.eventTimeStamp | amDateFormat: 'YYYY-MM-DD HH:mm' }}</td>
                                        <td>{{ event.source }}</td>
                                        <td>{{ ('enums.fuelEvent.' + event.refuelVsTheft) | translate }}</td>
                                        <td>
                                            {{ event.refuelVsTheft.toString() == '1' ?
                                            (event.fuelingActivityInLiters).toLocaleString() + ' L' : '-' }}
                                        </td>
                                        <td>
                                            {{ event.refuelVsTheft.toString() != '1' ?
                                            (event.fuelingActivityInLiters).toLocaleString() + ' L' : '-' }}
                                        </td>
                                        <td>{{ event.endFuelEventFuelLevelInLiters }} L</td>
                                        <td>{{ event.certaintyInPercentage }}%</td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </div>
                    <div class="ibox-footer noselect dataTables_wrapper">
                        <pagination-template #p="paginationApi" (pageChange)="currentPage = $event">
                            <div class="dataTables_paginate paging_simple_numbers" id="dataTable_paginate">
                                <ul class="pagination">
                                    <li class="paginate_button page-item previous" [class.disabled]="p.isFirstPage()">
                                        <a class="page-link" (click)="p.previous()" *ngIf="!p.isFirstPage()"
                                            >{{ 'grid.previous' | translate }}</a
                                        >
                                        <a *ngIf="p.isFirstPage()">{{ 'grid.previous' | translate }}</a>
                                    </li>

                                    <li
                                        class="paginate_button page-item"
                                        *ngFor="let page of p.pages"
                                        [class.current]="p.getCurrent() === page.value"
                                    >
                                        <a
                                            class="page-link"
                                            (click)="p.setCurrent(page.value)"
                                            *ngIf="p.getCurrent() !== page.value"
                                        >
                                            <span>{{ page.label }}</span>
                                        </a>
                                        <a
                                            class="page-link"
                                            (click)="p.setCurrent(page.value)"
                                            *ngIf="p.getCurrent() === page.value"
                                        >
                                            <span style="font-weight: bold">{{ page.label }}</span>
                                        </a>
                                    </li>

                                    <li class="paginate_button page-item next" [class.disabled]="p.isLastPage()">
                                        <a class="page-link" (click)="p.next()" *ngIf="!p.isLastPage()">
                                            {{ 'grid.next' | translate }}
                                        </a>
                                        <a class="page-link" *ngIf="p.isLastPage()"> {{ 'grid.next' | translate }} </a>
                                    </li>
                                </ul>
                            </div>
                        </pagination-template>
                        <div style="clear: both"></div>
                    </div>
                </div>

                <div class="ibox">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-gas-pump"></i> {{'menu.fuel' | translate }}</h5>
                    </div>
                    <div class="ibox-content" style="position: relative">
                        <ngx-loading [show]="loadingFuel"></ngx-loading>
                        <highcharts-chart
                            [runOutsideAngular]="true"
                            [hidden]="!selectedEvent"
                            style="display: block; width: 100%; height: 350px"
                            [Highcharts]="Highcharts"
                            [options]="chartFuel"
                        ></highcharts-chart>
                        <span *ngIf="!selectedEvent">No selected event found</span>
                    </div>
                    <div class="ibox-footer">
                        <button class="btn btn-primary" (click)="showModal()">
                            <i class="fa fa-fw fa-plus"></i> {{'general.add' | translate }}
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="ibox" style="position: relative" *ngIf="showMapOnSide">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <fh-leaflet-map
                        (mapReady)="onMapReady($event)"
                        [height]="mapHeight"
                        [geofences]="geofences"
                        [geofenceEnabled]="false"
                    >
                    </fh-leaflet-map>
                </div>

                <form #fuelForm="ngForm">
                    <div class="ibox" style="position: relative">
                        <div class="ibox-title">
                            <h5><i class="fas fa-fw fa-gas-pump"></i> {{'general.thresholds' | translate }}</h5>
                        </div>
                        <ngx-loading [show]="loading"></ngx-loading>

                        <fh-fuel-edit-thresholds
                            [fuelThresholds]="fuelThresholds"
                            [formMode]="formModeFuel"
                            [hasVepamonEnabled]="hasVepamonEnabled"
                            [sensorCalibrationPoints]="sensorCalibrationPoints"
                        >
                        </fh-fuel-edit-thresholds>

                        <fh-save-footer
                            [valid]="!fuelForm.invalid"
                            (onSave)="onSaveFuelThresholds()"
                            [allowEdit]="true"
                            (setFormMode)="setFormModeFuel($event)"
                            [formMode]="formModeFuel"
                        >
                        </fh-save-footer>
                    </div>
                </form>
            </div>
        </div>
    </div>
</fh-permission-handler>

<!-- Adding a fuel event -->
<div
    (onHidden)="hideModal()"
    [config]="{ backdrop: true, ignoreBackdropClick: true }"
    bsModal
    #addModal="bs-modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
>
    <div class="modal-dialog">
        <form #fuelForm="ngForm">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title pull-left">
                        <i class="fa fa-fw fa-gas-pump"></i> {{'general.fuelEvent' | translate }}
                    </h5>
                    <button type="button" class="close float-right" aria-label="Close" (click)="hideModal()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="popup-content" style="position: relative">
                    <fh-fuel-details [fuel]="fuel" [formMode]="formModeAdd" [smallMode]="true" [loading]="loading">
                    </fh-fuel-details>
                </div>
                <div class="modal-footer">
                    <button
                        type="button"
                        class="btn btn-primary"
                        (click)="onInsertFuel()"
                        [disabled]="fuelForm.invalid || !this.device.asset?.id"
                    >
                        <i class="fa fa-fw fa-save"></i> {{'general.finish' | translate }}
                    </button>
                    <button type="button" class="btn btn-info" (click)="hideModal()">
                        <i class="fa fa-fw fa-times"></i> {{'general.cancel' | translate }}
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
