import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

// Moment timezone
import Moment from "moment-timezone";
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { FormMode, SortingOptionFleetOverview, StorageType } from "../../common/enums";
import { AppUser, UpdatePasswordRequest } from "../../models/user.model";
import { AuthenticationService } from "../../services/authentication/authentication.service";
import { UserService } from "../../services/users/user.service";
import { AccountService } from "app/services/account/account.service";
import { AccountInventory } from "app/models/account.model";
import { sha256 } from "js-sha256";
import { StorageHelper } from "app/common/storagehelper";

window["moment"] = Moment;

@Component({
  selector: "fh-profile",
  templateUrl: "profile.template.html",
})
export class ProfileComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  loading = false;
  saving = false;
  loadingPassword = false;
  user: AppUser;
  users: AppUser[];
  currentLang;

  userSettings = [];
  notificationSettings = [];
  featureFlagSettings = [];

  storageType = StorageType.LocalStorage;

  oldPassword: string;
  confirmPassword: string;
  newPassword: string;

  periods: string[] = [];
  daysInTheWeek: string[] = [];
  days: number[] = [];
  quarters: string[] = [];

  chosenPeriod: string;
  chosenDayOfTheWeek: string;
  chosenDayOfTheMonth: number;
  chosenQuarter: string;
  account: AccountInventory;

  formMode: FormMode = FormMode.read;

  autoRemove = false;

  showMapOnSide;

  error: any;
  success: any;
  copyText: any;
  userId: string;

  mapSelectionOptions;

  isImpersonated: boolean;
  permissions: {};

  foSettings = [];
  foLabelSettings = [];
  sidebarSettings = [];

  sortingOrderFleetOverview;
  sortingOrderFleetOverviewOptions;

  constructor(
    private cd: ChangeDetectorRef,
    private localeService: BsLocaleService,
    private translate: TranslateService,
    private authenticationService: AuthenticationService,
    private accountService: AccountService,
    private userService: UserService,
    private storageHelper: StorageHelper
  ) {
    this.user = new AppUser();
    this.user.timezoneIana = "Europe/Amsterdam";

    this.userId = this.authenticationService.getUserId();

    this.loading = true;

    this.userSettings = [
      { name: "hideWarehouses", value: null },
      { name: "hideTrailers", value: null },
      { name: "hideProjects", value: null },
      { name: "hideTags", value: null },
      { name: "hideTriggers", value: null },
      { name: "hideFuel", value: null },
      { name: "hideMaintenance", value: null },
      { name: "showMapOnSide", value: null },
      { name: "hideUtilization", value: null },
      { name: "showCompass", value: null },
      { name: "showScale", value: null },
    ];

    this.featureFlagSettings = [
      { name: "enableBetaTrips", value: null },
      { name: "enableBetaMaintenance", value: null },
    ];

    this.notificationSettings = [{ name: "notifyAsToast", value: null }];

    this.foSettings = [
      { name: "brightColors", value: null },
      { name: "includeArchivedGroups", value: null },
      { name: "skipClustering", value: null },
      { name: "slideTo", value: null },
      { name: "hideLabels", value: null },
      { name: "skipIncludingGroupColors", value: null },
      { name: "forceOnlyOwnAcccount", value: null}
    ];

    this.foLabelSettings = [
      { name: "assetName", value: null },
      { name: "driverName", value: null },
      { name: "driverPhone", value: null },
      { name: "speed", value: null },
      { name: "temperature", value: null },
      { name: "weight", value: null },
      { name: "humidity", value: null },
    ];

    this.sidebarSettings = [
      { name: "showSidebarLocationUpdated", value: null },
      { name: "showSidebarStateUpdated", value: null },
      { name: "showSidebarDriverName", value: null },
      { name: "showSidebarCity", value: null },
      { name: "showSidebarGeofence", value: null },
    ];

    this.sortingOrderFleetOverviewOptions = Object.keys(SortingOptionFleetOverview)
      .filter((k) => typeof SortingOptionFleetOverview[k] === "string")
      .map((n) => ({ value: +n, name: SortingOptionFleetOverview[n] }));

    this.getUserInfo();

    this.isImpersonated = this.authenticationService.getIsImpersonated();

    this.permissions = this.authenticationService.permissions;
  }

  async loadSetting(setting, isBool = true, storageType) {
    const result = await this.storageHelper.loadStoreState(storageType, "settings_", setting).toPromise();

    if (isBool) {
      return result === "true";
    }

    return result;
  }

  saveSetting(setting, value, storageType) {
    if (value != null) {
      this.storageHelper.saveStoreState(storageType, "settings_", setting, value.toString());
    }
  }

  ngOnInit(): void {
    while (this.days.length < 31) {
      this.days.push(this.days.length + 1);
    }

    this.translate.get("general.date").subscribe((value) => {
      this.periods.push(this.translate.instant("general.none"));
      this.periods.push(this.translate.instant("report.weekly"));
      this.periods.push(this.translate.instant("report.monthly"));
      this.periods.push(this.translate.instant("report.quarterly"));

      this.chosenPeriod = this.periods[0];

      this.daysInTheWeek.push(this.translate.instant("dates.monday"));
      this.daysInTheWeek.push(this.translate.instant("dates.tuesday"));
      this.daysInTheWeek.push(this.translate.instant("dates.wednesday"));
      this.daysInTheWeek.push(this.translate.instant("dates.thursday"));
      this.daysInTheWeek.push(this.translate.instant("dates.friday"));
      this.daysInTheWeek.push(this.translate.instant("dates.saturday"));
      this.daysInTheWeek.push(this.translate.instant("dates.sunday"));

      this.quarters.push(this.translate.instant("report.quarter1"));
      this.quarters.push(this.translate.instant("report.quarter2"));
      this.quarters.push(this.translate.instant("report.quarter3"));
    });
  }

  readCronTabPattern(pattern: string): void {
    const splitPattern = pattern.split(" ");

    if (splitPattern.length === 3) {
      const dayOfWeekPattern = parseInt(splitPattern[0], 10);
      const dayOfMonthPattern = parseInt(splitPattern[1], 10);
      const monthPattern = splitPattern[2];

      if (!isNaN(dayOfWeekPattern) && monthPattern === "*") {
        if (dayOfWeekPattern > 0 && dayOfWeekPattern <= 7) {
          this.chosenPeriod = this.periods[1];
          this.chosenDayOfTheWeek = this.daysInTheWeek[dayOfWeekPattern - 1].toString();
        }
      } else if (!isNaN(dayOfMonthPattern) && monthPattern === "*") {
        this.chosenPeriod = this.periods[2];
        this.chosenDayOfTheMonth = dayOfMonthPattern;
      } else if (!isNaN(dayOfMonthPattern) && monthPattern !== "-" && monthPattern !== "*") {
        const quarters = monthPattern.split(",");
        const notAllNumbers = quarters.find((x) => isNaN(parseInt(x, 10)));
        if (!notAllNumbers) {
          this.chosenPeriod = this.periods[3];
          this.chosenDayOfTheMonth = dayOfMonthPattern;
          this.chosenQuarter = this.quarters[parseInt(monthPattern.split(",")[0], 10) - 1];
        }
      }
    } else {
      this.chosenPeriod = this.periods[0];
    }
  }

  createCronTab(period: string): void {
    let cronTabPattern = "";
    if (period === this.periods[1]) {
      if (!this.chosenDayOfTheWeek) {
        this.chosenDayOfTheWeek = this.daysInTheWeek[0];
      }
      cronTabPattern += this.daysInTheWeek.indexOf(this.chosenDayOfTheWeek) + 1 + " - *";
    } else if (period === this.periods[2]) {
      if (!this.chosenDayOfTheMonth) {
        this.chosenDayOfTheMonth = this.days[0];
      }
      cronTabPattern += "- " + this.chosenDayOfTheMonth + " *";
    } else if (period === this.periods[3]) {
      if (!this.chosenDayOfTheMonth || !this.chosenQuarter) {
        this.chosenDayOfTheMonth = this.chosenDayOfTheMonth ? this.chosenDayOfTheMonth : this.days[0];
        this.chosenQuarter = this.chosenQuarter ? this.chosenQuarter : this.quarters[0];
      }
      cronTabPattern +=
        "- " +
        this.chosenDayOfTheMonth +
        " " +
        (this.quarters.indexOf(this.chosenQuarter) === 0
          ? "1,4,7,10"
          : this.quarters.indexOf(this.chosenQuarter) === 1
          ? "2,5,8,11"
          : "3,6,9,12");
    }
    this.user.cronTabPattern = cronTabPattern ? cronTabPattern : null;
  }

  // Updating

  getUserInfo() {
    this.loading = true;
    this.userService.getUserById(this.userId).subscribe((user) => {
      this.loading = false;
      this.cd.markForCheck();
      this.user = user;
      if (user.cronTabPattern) {
        this.readCronTabPattern(user.cronTabPattern);
      }

      // Set feature flags
      const value = this.user.featureFlags;
      if (value) {
        const filterSettings = value.split(",");

        filterSettings.forEach((featureFlagSetting) => {
          var item = this.featureFlagSettings.find((x) => x.name == featureFlagSetting);
          item.value = true;
        });
      }

      const userSettingPromises = [];

      this.userSettings.forEach((userSetting) => {
        userSettingPromises.push(
          (async () => {
            const value = await this.loadSetting(userSetting.name, true, this.storageType);
            userSetting.value = value;
          })()
        );
      });

      this.notificationSettings.forEach((notificationSetting) => {
        userSettingPromises.push(
          (async () => {
            const value = await this.loadSetting(notificationSetting.name, true, this.storageType);
            notificationSetting.value = value;
          })()
        );
      });

      this.foSettings.forEach((foSetting) => {
        userSettingPromises.push(
          (async () => {
            const value = await this.loadSetting(foSetting.name, true, this.storageType);
            foSetting.value = value;
          })()
        );
      });

      this.foLabelSettings.forEach((foLabelSetting) => {
        userSettingPromises.push(
          (async () => {
            const value = await this.loadSetting(foLabelSetting.name, true, this.storageType);
            foLabelSetting.value = value;
          })()
        );
      });

      this.sidebarSettings.forEach((sidebarSetting) => {
        userSettingPromises.push(
          (async () => {
            const value = await this.loadSetting(sidebarSetting.name, true, this.storageType);
            sidebarSetting.value = value;
          })()
        );
      });

      Promise.all(userSettingPromises);

      this.loadSetting("sortingOrderFleetOverview", false, this.storageType).then((value) => {
        this.sortingOrderFleetOverview = +value;
      });

      this.loadSetting("mapSelectionOptions", false, this.storageType).then((value) => {
        this.mapSelectionOptions = +value;
      });
    });
  }

  onSave() {
    this.autoRemove = true;
    this.saving = true;
    this.cd.markForCheck();

    this.success = undefined;
    this.error = undefined;
    this.copyText = undefined;

    // Save feature flags as an array
    this.user.featureFlags = this.featureFlagSettings
      .filter((x) => x.value == true)
      .map((x) => x.name)
      .join();

    this.userService.updateUserProfile(this.user).subscribe({
      next: (result) => {
        this.saving = false;

        this.success = {
          statusText: "Success",
          success: this.translate.instant("general.profileUpdated"),
        };

        this.userSettings.forEach((userSetting) => {
          this.saveSetting(userSetting.name, userSetting.value, this.storageType);
        });

        this.notificationSettings.forEach((notificationSetting) => {
          this.saveSetting(notificationSetting.name, notificationSetting.value, this.storageType);
        });

        this.foSettings.forEach((foSetting) => {
          this.saveSetting(foSetting.name, foSetting.value, this.storageType);
        });

        this.foLabelSettings.forEach((foLabelSetting) => {
          this.saveSetting(foLabelSetting.name, foLabelSetting.value, this.storageType);
        });

        this.sidebarSettings.forEach((foSetting) => {
          this.saveSetting(foSetting.name, foSetting.value, this.storageType);
        });

        this.saveSetting("mapSelectionOptions", this.mapSelectionOptions, this.storageType);

        this.saveSetting("sortingOrderFleetOverview", this.sortingOrderFleetOverview, this.storageType);

        this.cd.markForCheck();

        this.setFormMode(FormMode.read);
      },
      error: (error) => {
        this.saving = false;
        this.error = error;
        this.cd.markForCheck();
      },
    });
  }

  savePassword() {
    this.autoRemove = true;
    this.loadingPassword = true;
    if (this.newPassword === this.confirmPassword) {
      const request = new UpdatePasswordRequest();
      request.id = this.user.id;
      request.userName = this.user.name;
      request.oldPassword = sha256(this.oldPassword);
      request.newPassword = sha256(this.newPassword);

      this.success = undefined;
      this.error = undefined;
      this.copyText = undefined;

      this.userService.updateUserPassword(this.user.id, request).subscribe({
        next: (result) => {
          this.success = {
            statusText: "Success",
            success: this.translate.instant("general.passwordUpdated"),
          };

          this.loadingPassword = false;
          this.cd.markForCheck();
        },
        error: (error) => {
          this.loadingPassword = false;
          this.cd.markForCheck();
          this.error = error;
          this.getUserInfo();
        },
      });
    } else {
      this.error = "Passwords do not match";
      this.loadingPassword = false;
      this.cd.markForCheck();
    }
  }

  temporaryPassword() {
    this.autoRemove = false;
    this.saving = true;
    this.success = undefined;
    this.error = undefined;
    this.copyText = undefined;

    this.userService.createTemporaryPassword(this.userId).subscribe({
      next: (result) => {
        if (result.isSuccess) {
          this.success = `Your temporary password is; ` + result.message;
          this.copyText = result.message;
        } else {
          this.success = result.message;
        }
        this.saving = false;
        this.cd.markForCheck();
      },
      error: (error) => {
        this.saving = false;
        this.cd.markForCheck();
        this.error = error;
        this.getUserInfo();
      },
    });
  }

  setFormMode(mode) {
    this.formMode = mode;
    if (this.formMode === FormMode.read) {
      this.getUserInfo();
    }

    if (this.formMode === FormMode.edit) {
      if (this.user.assetDisplayName === 0 || this.user.assetDisplayName === null) {
        this.user.assetDisplayName = 1;
      }
    }
  }

  onChange($event, lang) {
    this.user.language = lang;
    if (lang !== this.translate.currentLang) {
      this.translate.use(lang);
      Moment.locale(lang);
      this.localeService.use(lang);
    }
  }
}

// Recursively reduce sub-arrays to the specified depth
function flatten<T>(arr: T[], depth = 1): T[] {
  // If depth is 0, return the array as-is
  if (depth < 1) {
    return arr.slice();
  }

  // Otherwise, concatenate into the parent array
  return arr.reduce((_acc, _val) => {
    return _acc.concat(Array.isArray(_val) ? flatten(_val, depth - 1) : _val);
  }, []);
}
