<div style="position: relative; width: 100%">
    <ng-select
        [loading]="loading"
        name="asset"
        ngDefaultControl
        [searchable]="true"
        [clearable]="true"
        placeholder="{{ 'general.assetSelector' | translate }} ({{ assets.length }})"
        [(ngModel)]="selectedAsset"
        #assetSelector="ngModel"
        [disabled]="assets.length === 0"
        class="form-control table-inline"
        style="width: 100%"
        required
        (ngModelChange)="itemSelected($event)"
    >
        <ng-option *ngFor="let asset of assets | orderBy : 'name' : false; let i = index" [value]="asset.id">
            <i class="fas fa-fw fa-car"></i> {{ asset.name || asset.id }} / {{ asset.unitId }}
        </ng-option>
    </ng-select>
</div>
