export class Schedule {
  day: number;
  commuting: boolean;
  startTime: string;
  endTime: string;

  constructor(day: number, commuting: boolean, startTime: string, endTime: string) {
    this.day = day;
    this.commuting = commuting;
    this.startTime = startTime;
    this.endTime = endTime;
  }
}
