import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import * as XLSX from "xlsx-js-style";
type AOA = any[][];
interface UploadColumn {
  name: string;
  isRequired: boolean;
  mapping?: number;
}

@Component({
  selector: "ng-uploader",
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "./uploader.component.html",
  styleUrls: ["./uploader.component.css"],
})
export class UploaderComponent implements OnInit {
  @Input() columns: UploadColumn[] = [];
  @Input() useButton = false;
  @Output() onFinished = new EventEmitter();
  @Input() processing = false;

  uploadForm;

  data: AOA = [[]];
  headers = [];
  wopts: XLSX.WritingOptions = { bookType: "xlsx", type: "array" };
  fileName = "export.xlsx";
  uploading = false;

  constructor(private cd: ChangeDetectorRef, private translateService: TranslateService) {}

  ngOnInit(): void {}

  onFileChange(event: any) {
    this.uploading = true;
    /* wire up file reader */
    // const target: DataTransfer = <DataTransfer>(evt.target);
    if (event.target.files.length !== 1) {
      this.uploading = false;
      this.cd.markForCheck();
      throw new Error("Cannot use multiple files");
    }

    // Define reader
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: "binary", cellText: true, cellDates: true });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.data = <AOA>XLSX.utils.sheet_to_json(ws, { header: 1, raw: true, dateNF: "yyyy-mm-dd" });
      this.headers = [];
      this.data[0].forEach((value, index) => {
        this.headers.push({ id: index + 1, name: value });
      });
      this.tryMapping();

      this.uploading = false;
      this.cd.markForCheck();
    };

    // Read
    reader.readAsBinaryString(event.target.files[0]);
  }

  tryMapping() {
    // Try to automap columns
    this.columns.forEach((column) => {
      const index = this.headers.map((x) => x.name.toLowerCase()).indexOf(column.name.toLowerCase());
      if (index !== -1) {
        column.mapping = index + 1;
      }
    });

    console.log("Check form");
    // this.validateAllFormControl(this.uploadForm);
    this.cd.markForCheck();
  }

  post(): void {
    const returnVar = [];

    this.data.forEach((row, index) => {
      if (index > 0) {
        const rowItem = {};
        this.columns.forEach((column) => {
          if (column.mapping) {
            rowItem[column.name] = row[column.mapping - 1];
          }
        });
        returnVar.push(rowItem);
      }
    });

    // Merge mapping with data
    this.onFinished.emit(returnVar);

    // Reset data
    this.cancel();
  }

  cancel(): void {
    // Reset data
    this.data = [[]];
    this.headers = [];

    this.columns.forEach((column) => {
      column.mapping = undefined;
    });

    this.cd.markForCheck();
  }

  public reset(): void {
    this.cancel();
  }

  // Mapping
  saveMapping() {}

  loadMapping() {}
}
