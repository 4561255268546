<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/"> <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li
                    class="breadcrumb-item"
                    *ngIf="permissions['Accounts_View'] && device?.asset && device?.asset.accountId"
                >
                    <a href="/#/AccountDetails/Index/{{device?.asset.accountId}}">{{device?.asset?.companyName}}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Devices/Overview?companyName={{device?.companyName}}"
                        >{{'general.devices' | translate }}</a
                    >
                </li>
                <li class="breadcrumb-item active">{{'menu.routes' | translate }}</li>
            </ol>
        </nav>
    </div>
    <fh-device-navigation></fh-device-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">
        <div class="row" data-equalizer="">
            <div class="col-lg-12">
                <fh-error [error]="error" [warning]="warning" [success]="success"></fh-error>

                <div class="ibox">
                    <div class="ibox-title">
                        <h5><i class="fa fa-route"></i> {{'general.routes' | translate }}</h5>
                        <span class="float-right">
                            <ng-select
                                (ngModelChange)="createRouting($event)"
                                name="method"
                                ngDefaultControl
                                [searchable]="false"
                                [clearable]="false"
                                style="height: unset; width: 100px"
                                [(ngModel)]="method"
                                [multiple]="false"
                                class="form-control table-inline"
                            >
                                <ng-option value="mapbox/walking">walking</ng-option>
                                <ng-option value="mapbox/cycling">cycling</ng-option>
                                <ng-option value="mapbox/driving">driving</ng-option>
                            </ng-select>
                        </span>
                    </div>

                    <div class="ibox-content slick" style="position: relative">
                        <ngx-loading [show]="loadingGeofences"></ngx-loading>
                        <fh-leaflet-map
                            (mapReady)="onMapReady($event)"
                            [geofences]="geofences"
                            [height]="mapHeight"
                            [showExtendMap]="false"
                            [showGeofenceSwitch]="false"
                            [geofenceEnabled]="false"
                            [allowBookmarks]="false"
                            (mapResized)="onMapResized()"
                        >
                        </fh-leaflet-map>
                    </div>
                </div>
            </div>
        </div>
    </div>
</fh-permission-handler>
