import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { TranslateService } from "@ngx-translate/core";
import { throwError } from "rxjs/internal/observable/throwError";
import { Observable, catchError, map } from "rxjs";
import { AuthenticationService } from "../authentication/authentication.service";
import { TranslatorService } from "../common/translator.service";
import { LoggingService } from "../logging/logging.service";
import { AppDashboard } from "app/models/dashboard.model";

@Injectable()
export class DashboardService {
  timestamp;
  url = "";

  constructor(
    private translate: TranslateService,
    private translatorService: TranslatorService,
    private http: HttpClient,
    private loggingService: LoggingService,
    private authenticationService: AuthenticationService
  ) {
    this.url = authenticationService.getWebserviceURL("dashboard");
  }

  getIssuesUrl(key, start, end) {
    return this.url + "Issues/Paging?rnd=" + key + "&startRange=" + start.unix() + "&endRange=" + end.unix();
  }

  getIssuesLatencyUrl(key, start, end) {
    return this.url + "Latency/Paging?rnd=" + key + "&startRange=" + start.unix() + "&endRange=" + end.unix();
  }

  getKPISCount(): Observable<any> {
    console.log("getting dashboard from service");
    return this.http.get(this.url, { headers: this.authenticationService.headers }).pipe(
      map((data) => {
        const parsedResponse = data;
        return parsedResponse;
      }),
      catchError(this.handleError)
    );
  }

  getAccouncements(): Observable<any> {
    console.log("getting dashboard announcements from service");
    return this.http.get(this.url + "announcements", { headers: this.authenticationService.headers }).pipe(
      map((data) => {
        const parsedResponse = data;
        return parsedResponse;
      }),
      catchError(this.handleError)
    );
  }

  getDeviceCount(): Observable<any> {
    return this.http.get(this.url + "deviceCount", { headers: this.authenticationService.headers }).pipe(
      map((data) => {
        const parsedResponse = data;
        return parsedResponse;
      }),
      catchError(this.handleError)
    );
  }

  getLocationCount(): Observable<any> {
    return this.http.get(this.url + "locationCount", { headers: this.authenticationService.headers }).pipe(
      map((data) => {
        const parsedResponse = data;
        return parsedResponse;
      }),
      catchError(this.handleError)
    );
  }

  getTriggerCount(): Observable<any> {
    return this.http.get(this.url + "triggerCount", { headers: this.authenticationService.headers }).pipe(
      map((data) => {
        const parsedResponse = data;
        return parsedResponse;
      }),
      catchError(this.handleError)
    );
  }

  getReportCount(): Observable<any> {
    return this.http.get(this.url + "reportCount", { headers: this.authenticationService.headers }).pipe(
      map((data) => {
        const parsedResponse = data;
        return parsedResponse;
      }),
      catchError(this.handleError)
    );
  }

  getApiCount(): Observable<any> {
    return this.http.get(this.url + "apiCount", { headers: this.authenticationService.headers }).pipe(
      map((data) => {
        const parsedResponse = data;
        return parsedResponse;
      }),
      catchError(this.handleError)
    );
  }

  getTopUsage(): Observable<any> {
    return this.http.get(this.url + "topUsage", { headers: this.authenticationService.headers }).pipe(
      map((data) => {
        const parsedResponse = data;
        return parsedResponse;
      }),
      catchError(this.handleError)
    );
  }

  getTopEvents(): Observable<any> {
    return this.http.get(this.url + "topEvents", { headers: this.authenticationService.headers }).pipe(
      map((data) => {
        const parsedResponse = data;
        return parsedResponse;
      }),
      catchError(this.handleError)
    );
  }

  getDeviceTypes(): Observable<any> {
    return this.http.get(this.url + "deviceTypes", { headers: this.authenticationService.headers }).pipe(
      map((data) => {
        const parsedResponse = data;
        return parsedResponse;
      }),
      catchError(this.handleError)
    );
  }

  getDevicesAdded(): Observable<any> {
    return this.http.get(this.url + "added", { headers: this.authenticationService.headers }).pipe(
      map((data: any) => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  private handleError(error: Response) {
    return throwError(error);
  }

  parseKPIResponse(json: any): AppDashboard {
    this.loggingService.log(this.constructor.name, "Retrieved " + json.length + " Customers with devicecount.");

    const ident = 1;

    const dashboard = new AppDashboard();

    dashboard.deviceCount = json.deviceCount;
    dashboard.customerCount = json.customerCount;
    dashboard.geofenceCount = json.geofenceCount;
    dashboard.driverCount = json.driverCount;
    dashboard.userCount = json.userCount;
    dashboard.warehouseCount = json.warehouseCount;
    dashboard.tagCount = json.tagCount;

    return dashboard;
  }
}
