<ng-select
    [loading]="loadingUsers"
    ngDefaultControl
    [searchable]="true"
    [clearable]="clearable"
    [disabled]="users.length == 0"
    [readonly]="readonly"
    placeholder="{{ placeholder }} ({{ users.length }})"
    #input
    [(ngModel)]="value"
    class="form-control table-inline"
    (ngModelChange)="usersChanged()"
>
    <ng-option *ngFor="let user of users | orderBy : 'name' : false" [value]="user.id">
        <i class="fas fa-fw fa-user"></i> {{ user.fullName }} ({{ user.userName }})
    </ng-option>
</ng-select>
<div style="clear: both"></div>
