import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuditLog } from "../../models/auditlog.model";
import { AuditLogService } from "../../services/auditlog/auditlog.service";
import { GeofenceGroup } from "app/models/group.model";
import { GeofenceGroupsService } from "app/services/geofence/geofenceGroups.service";

@Component({
  selector: "fh-geofence-group-log",
  templateUrl: "geofenceGroupLog.template.html",
})
export class GeofenceGroupLogViewComponent implements OnInit {
  geofenceGroupLog: AuditLog[];
  sub;
  geofenceGroup: GeofenceGroup;
  loading = false;

  permissionName = "FleetManagement_Log";
  constructorName = "GeofenceLogViewComponent";

  error: any;
  success: any;
  geofenceId: any;

  constructor(
    private http: HttpClient,
    private auditLogService: AuditLogService,
    private geofenceGroupService: GeofenceGroupsService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.geofenceGroup = null;
    this.geofenceGroupLog = [];
  }

  ngOnInit() {
    this.loading = true;
    this.geofenceGroup = new GeofenceGroup();

    this.sub = this.route.params.subscribe((params) => {
      const id = params["id"];

      this.geofenceId = id;
      this.geofenceGroupService.getGeofenceGroupById(id).subscribe((geofenceGroup) => {
        this.geofenceGroup = geofenceGroup;

        if (this.geofenceGroup == null) {
          this.router.navigate(["/GeofenceGroups/Overview"]);
        }

        this.auditLogService.getAuditLogByGeofenceGroup(id).subscribe(
          (deviceLog) => {
            console.log("loading done");
            this.geofenceGroupLog = deviceLog;
            this.loading = false;
          },
          (error) => {
            this.error = error;
            this.loading = false;
          }
        );
      });
    });
  }
}
