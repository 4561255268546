<div class="col-lg-12">
    <div class="horizontalMenuWrapper">
        <ul>
            <li [ngClass]="{'active': activeRoute('Devices/Overview')}">
                <a href="/#/Devices/Overview/"
                    ><i class="fas fa-fw fa-microchip"></i>
                    <div>{{ 'general.overview' |translate }}</div>
                </a>
            </li>
            <li *ngIf="permissions['FleetManagement_DeviceState']" [ngClass]="{'active': activeRoute('Devices/State')}">
                <a href="/#/Devices/State/"
                    ><i class="fas fa-fw fa-heartbeat"></i>
                    <div>{{ 'general.state' | translate }}</div>
                </a>
            </li>
            <li *ngIf="permissions['FleetManagement_DeviceCards']" [ngClass]="{'active': activeRoute('Devices/Cards')}">
                <a href="/#/Devices/Cards/"
                    ><i class="fas fa-fw fa-credit-card-front"></i>
                    <div>{{ 'general.cards' | translate }}</div>
                </a>
            </li>
            <li *ngIf="permissions['FleetManagement_External']" [ngClass]="{'active': activeRoute('Devices/External')}">
                <a href="/#/Devices/External/"
                    ><i class="fas fa-fw fa-link"></i>
                    <div>{{ 'general.external' | translate }}</div>
                </a>
            </li>
            <li *ngIf="hasSettingPermissions" [ngClass]="{'active': isSettings()}">
                <a (click)="navigateSettings()"
                    ><i class="fas fa-fw fa-cog"></i>
                    <div>{{ 'general.settings' | translate }}</div>
                </a>
            </li>
            <li *ngIf="hasDataPermissions" [ngClass]="{'active': isData()}">
                <a (click)="navigateData()"
                    ><i class="fas fa-fw fa-database"></i>
                    <div>{{ 'menu.data' | translate }}</div>
                </a>
            </li>
            <li [ngClass]="{'active': activeRoute('AssetGroups') || activeRoute('AssetGroupDetails')}">
                <a href="/#/AssetGroups/Overview/"
                    ><i class="fas fa-fw fa-layer-group"></i>
                    <div>{{ 'general.groups' | translate }}</div>
                </a>
            </li>
        </ul>
    </div>
    <div class="horizontalMenuWrapper" style="float: right">
        <ul>
            <ng-content></ng-content>
        </ul>
    </div>
</div>

<div class="col-lg-12" style="padding: 5px" *ngIf="isSettings()">
    <div class="horizontalMenuSubWrapper">
        <ul>
            <li *ngIf="permissions['AssetTypes_View']" [ngClass]="{active: activeRoute('Devices/AssetType')}">
                <a [routerLink]="['/Devices/AssetTypes/Overview']">
                    <i class="fas fa-fw fa-truck-monster"></i>
                    {{ 'menu.assetTypes' | translate }}
                </a>
            </li>
            <li
                *ngIf="permissions['Entities_DeviceTypes']"
                [ngClass]="{active: activeRoute('DeviceTypes/Overview') || activeRoute('Devices/DeviceTypeDetails')}"
            >
                <a [routerLink]="['/Devices/DeviceTypes/Overview/']">
                    <i class="fas fa-fw fa-router"></i>
                    {{ 'general.devicetypes' | translate }}
                </a>
            </li>
            <li
                *ngIf="permissions['FleetManagement_Sensors']"
                [ngClass]="{active: activeRoute('Devices/SensorTemplate')}"
            >
                <a [routerLink]="['/Devices/SensorTemplates/Overview']">
                    <i class="fas fa-fw fa-sensor"></i>
                    {{ 'general.sensorTemplates' | translate }}
                </a>
            </li>
            <li
                *ngIf="permissions['Platform_IsAdministrator']"
                [ngClass]="{active: activeRoute('Devices/DeviceRoutingConfiguration')}"
            >
                <a [routerLink]="['/Devices/DeviceRoutingConfiguration']">
                    <i class="fas fa-fw fa-shuffle"></i>
                    {{ 'general.deviceRoutingConfiguration' | translate }}
                </a>
            </li>
            <li
                *ngIf="permissions['Platform_IsFleetManager']"
                [ngClass]="{active: activeRoute('Devices/CustomCommands')}"
            >
                <a [routerLink]="['/Devices/CustomCommands/Overview']">
                    <i class="fas fa-fw fa-tower-broadcast"></i>
                    {{ 'general.customCommands' | translate }}
                </a>
            </li>
        </ul>
    </div>
</div>

<div class="col-lg-12" style="padding: 5px" *ngIf="isData()">
    <div class="horizontalMenuSubWrapper">
        <ul>
            <li [ngClass]="{active: activeRoute('/Devices/DayView')}">
                <a [routerLink]="['/Devices/DayView/']">
                    <i class="fas fa-fw fa-solid fa-calendar-range"></i>
                    {{ 'general.utilization' | translate }}
                </a>
            </li>
            <li [ngClass]="{'active': activeRoute('Devices/Assignments')}">
                <a href="/#/Devices/Assignments/"
                    ><i class="fas fa-fw fa-route"></i>
                    {{ 'general.assignments' | translate }}
                </a>
            </li>
            <li [ngClass]="{'active': activeRoute('Devices/Trips')}">
                <a href="/#/Devices/Trips/"
                    ><i class="fas fa-fw fa-route"></i>
                    {{ 'general.trips' | translate }}
                </a>
            </li>
            <li *ngIf="permissions['Devices_Edit']" [ngClass]="{active: activeRoute('Devices/Changes')}">
                <a [routerLink]="['/Devices/Changes']">
                    <i class="fas fa-fw fa-cubes"></i>
                    {{'general.settingsChanges' | translate }}
                </a>
            </li>
            <li *ngIf="permissions['FleetManagement_Trips']" [ngClass]="{active: activeRoute('Devices/Routes')}">
                <a [routerLink]="['/Devices/Routes']">
                    <i class="fas fa-fw fa-route"></i>
                    {{ 'menu.routes' | translate }}
                </a>
            </li>
        </ul>
    </div>
</div>

<div class="col-lg-12" style="padding: 5px" *ngIf="isAssetGroupDetails()">
    <div class="horizontalMenuSubWrapper">
        <ul>
            <li [ngClass]="{active: activeRoute('AssetGroupDetails/Index')}">
                <a [routerLink]="['/AssetGroupDetails/Index/', activeId]">
                    <i class="fas fa-fw fa-layer-group"></i>
                    {{ 'menu.details' | translate }}
                </a>
            </li>
            <li [ngClass]="{active: activeRoute('AssetGroupDetails/Log')}">
                <a [routerLink]="['/AssetGroupDetails/Log/', activeId]">
                    <i class="fas fa-fw fa-file-alt"></i>
                    {{ 'menu.log' | translate }}
                </a>
            </li>
        </ul>
    </div>
</div>
<div style="clear: both"></div>
