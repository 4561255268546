import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable, catchError, map, of } from "rxjs";
import { AuthenticationService } from "../authentication/authentication.service";
import { LoggingService } from "../logging/logging.service";
import { AccountInventory, RegisterOperatingCompany } from "app/models/account.model";
import { throwError } from "rxjs/internal/observable/throwError";
import { AppUser } from "app/models/user.model";
import { TranslateService } from "@ngx-translate/core";
import { localizeSystemGroupNames } from "app/common/globals";
import { DeviceUtilization } from "app/models/device.model";
import { DriverScore } from "app/models/driver.model";
import { NotificationLog } from "app/models/notificationlog.model";

// Moment timezone
import Moment from "moment-timezone";
import { DistanceUnitService } from "app/common/distanceunit.service";

window["moment"] = Moment;

@Injectable()
export class AccountService {
  url: string = "";
  base_url: string = "";
  inventoryUrl: string = "";
  accounts: AccountInventory[] = [];
  timezoneIana: string;

  constructor(
    private http: HttpClient,
    private distance: DistanceUnitService,
    private loggingService: LoggingService,
    private authenticationService: AuthenticationService,
    private translateService: TranslateService
  ) {
    this.url = this.authenticationService.getWebserviceURL("account");
    this.base_url = this.authenticationService.getWebserviceURL("");
    this.timezoneIana = this.authenticationService.getTimeZoneIana();
  }

  getPagingUrl() {
    return this.url + "Paging";
  }

  getAccounts(setDefinate = true, includeProperties = false): Observable<AccountInventory[]> {
    if (setDefinate && this.accounts.length !== 0) {
      console.log("Returning accounts from cache: ", this.accounts.length);
      return of(this.accounts);
    }

    return this.http
      .get(this.url + "?includeProperties=" + includeProperties, { headers: this.authenticationService.headers })
      .pipe(
        map((data) => {
          const result = this.parseResponse(data, includeProperties);
          this.accounts = result.sort((a, b) => (a.linkName < b.linkName ? -1 : 1));
          return this.accounts;
        }),
        catchError(this.handleError)
      );
  }

  getAccountById(id: string): Observable<AccountInventory> {
    return this.http.get(this.url + id, { headers: this.authenticationService.headers }).pipe(
      map((data) => {
        return this.parseReponseDetails(data, true);
      }),
      catchError(this.handleError)
    );
  }

  getSubAccounts(parentAccountId = 0): Observable<AccountInventory[]> {
    return this.http
      .get(this.url + parentAccountId + "/SubAccounts", { headers: this.authenticationService.headers })
      .pipe(
        map((data) => {
          const result = this.parseResponse(data);
          return result.sort((a, b) => (a.linkName < b.linkName ? -1 : 1));
        }),
        catchError(this.handleError)
      );
  }

  getAccountByResellerId(resellerId): Observable<AccountInventory[]> {
    return this.http
      .get(this.base_url + "Reseller/" + resellerId + "/Accounts", { headers: this.authenticationService.headers })
      .pipe(
        map((data) => {
          const result = this.parseResponse(data);
          return result.sort((a, b) => (a.linkName < b.linkName ? -1 : 1));
        }),
        catchError(this.handleError)
      );
  }

  getKPISCount(id: string): Observable<any> {
    return this.http
      .get(this.url + id + "/Dashboard", { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  getNotifications(id: string, start, end): Observable<any> {
    this.url = this.authenticationService.getWebserviceURL("account");
    return this.http
      .get(this.url + id + "/Notifications?start=" + start.unix() + "&end=" + end.unix(), {
        headers: this.authenticationService.headers,
      })
      .pipe(
        map((data: any) => {
          const notifications = this.parseNotificationResponse(data);
          return notifications;
        }),
        catchError(this.handleError)
      );
  }

  getAccountSharedAssetGroups(id: string): Observable<any> {
    return this.http.get(this.url + id + "/Sharing", { headers: this.authenticationService.headers }).pipe(
      map((data: any[]) => {
        const result = [];
        data.forEach((item) => {
          const account = this.parseGroupResponseDetails(item);
          result.push(account);
        });
        return result;
      }),
      catchError(this.handleError)
    );
  }

  getAccountUtilization(
    accountId,
    start,
    end,
    includeAll = false,
    vehicleType = null,
    assetTypeId = null,
    deviceType = null,
    projectId = null,
    assetgroups = null,
    includeWork = true
  ): Observable<any> {
    if (accountId == null) {
      return of([]);
    }

    return this.http
      .get(
        this.url +
          accountId +
          `/Utilization?start=${start.unix()}&end=${end.unix()}&includeAll=${includeAll}&vehicleType=${vehicleType}&assetTypeId=${assetTypeId}&deviceType=${deviceType}&projectId=${projectId}&assetgroups=${assetgroups}&includeWork=${includeWork}`,
        { headers: this.authenticationService.headers }
      )
      .pipe(
        map((data: any) => {
          this.loggingService.log(this.constructor.name, "Retrieved " + data.length + " Devices.");

          const devices: DeviceUtilization[] = [];

          data.forEach((item) => {
            const device = new DeviceUtilization();
            device.deviceId = item.deviceId;
            device.assetId = item.assetId;
            device.brand = item.brand;
            device.model = item.model;
            device.plateNumber = item.plateNumber;
            device.accountId = item.accountId;
            device.assetName = item.assetName;
            device.unitId = item.unitId;
            device.iconId = item.iconId;
            device.deviceTypeId = item.deviceTypeId;

            try {
              device.assetGroups = item.assetGroups != null ? JSON.parse(item.assetGroups.replace("\t", "")) : [];
            } catch (error) {
              console.log(error);
            }

            if (device.assetGroups && device.assetGroups.length > 0) {
              device.assetGroups.forEach((assetGroup) => {
                assetGroup.name = localizeSystemGroupNames(assetGroup.name, this.translateService);
              });
            }

            device.speedingDurationInSeconds = item.speedingDurationInSeconds;
            device.tripCount = item.tripCount;
            device.tripCountPrivate = item.tripCountPrivate;
            device.tripCountBusiness = item.tripCountBusiness;
            device.tripDurationInSeconds = item.tripDurationInSeconds;
            device.maxSpeed = this.distance.calculateDistanceUnitFromKmFixed(item.maxSpeed, 0) ?? 0;
            device.segmentsDistance = item.segmentsDistance;
            device.segmentsDistanceBusiness = item.segmentsDistanceBusiness;
            device.segmentsDistancePrivate = item.segmentsDistancePrivate;
            device.totalDurationInSeconds = item.totalDurationInSeconds;
            device.geofenceEventCount = item.geofenceEventCount;
            device.idlingDurationInSeconds = item.idlingDurationInSeconds;
            device.speedingCount = item.speedingCount;
            device.roadSpeedingCount = item.roadSpeedingCount;
            device.accelCount = item.accelCount;
            device.decelCount = item.decelCount;
            device.corneringCount = item.corneringCount;

            device.brakingEventScore = item.brakingEventScore;
            device.accellerationEventScore = item.accellerationEventScore;
            device.corneringEventScore = item.corneringEventScore;

            device.workingHoursInSeconds = item.workingHoursInSeconds;
            device.pureDrivingDurationInSeconds = item.pureDrivingDurationInSeconds;

            device.utilization = (device.workingHoursInSeconds / device.totalDurationInSeconds) * 100;
            device.idlingPercentage = (device.idlingDurationInSeconds / device.workingHoursInSeconds) * 100;

            // Equipment
            device.equipmentIdlingDurationInSeconds = item.equipmentIdlingDurationInSeconds;
            device.equipmentIdlingPercentage =
              (item.equipmentIdlingDurationInSeconds / item.totalDurationInSeconds) * 100;
            device.equipmentCrossOverDurationInSeconds = item.equipmentCrossOverDurationInSeconds;
            device.equipmentWorkDurationInSeconds = item.equipmentWorkDurationInSeconds;
            device.equipmentUtilization = (item.equipmentWorkDurationInSeconds / item.totalDurationInSeconds) * 100;

            device.totalEmissionCO2 = item.totalEmissionCO2;
            device.totalEmissionParticlesLight = item.totalEmissionParticlesLight;
            device.totalEmissionParticlesHeavy = item.totalEmissionParticlesHeavy;
            device.totalConsumptionMixed = item.totalConsumptionMixed;

            device.fuelUsedWhileDriving = item.fuelUsedWhileDriving;
            device.fuelLostWhileIdling = item.fuelLostWhileIdling;
            device.fuelLostPercentage = item.fuelLostPercentage;
            device.fuelUsedTotal = item.fuelUsedTotal;
            device.fuelEfficiency = item.fuelEfficiency;

            device.avgKmh = item.avgKmh;

            device.vehicleType = item.vehicleType;
            device.assetTypeId = item.assetTypeId;
            device.assetTypeName = item.assetTypeName;
            device.projectId = item.projectId;
            device.projectName = item.projectName;

            device.assignedScheduleId = item.assignedScheduleId;
            device.assignedScheduleStart = item.assignedScheduleStart;
            device.assignedScheduleEnd = item.assignedScheduleEnd;

            device.active = true;
            if (device.tripCount < 1 || device.utilization < 0 || device.segmentsDistance < 0) {
              device.active = false;
            }

            devices.push(device);
          });

          return devices;
        }),
        catchError(this.handleError)
      );
  }

  getAccountCosts(
    accountId,
    start,
    end,
    yearlyDeprecation,
    fuelCostPetrol,
    fuelCostDiesel,
    fuelCostElectricity
  ): Observable<any> {
    if (accountId == null) {
      return of([]);
    }

    return this.http
      .get(
        this.url +
          accountId +
          `/Costs?start=${start.unix()}&end=${end.unix()}&yearlyDeprecation=${yearlyDeprecation}&fuelCostPetrol=${fuelCostPetrol}&fuelCostDiesel=${fuelCostDiesel}&fuelCostElectricity=${fuelCostElectricity}`,
        { headers: this.authenticationService.headers }
      )
      .pipe(
        map((data: any) => {
          this.loggingService.log(this.constructor.name, "Retrieved " + data.length + " Devices.");

          return data;
        }),
        catchError(this.handleError)
      );
  }

  getAccountTrips(
    accountId,
    start,
    end,
    includeEpisodes = false,
    includeGoefenceStates = false,
    includeMessages = false,
    includeJsonLocationData = false,
    vehicleType = null,
    assetTypeId = null,
    deviceType = null,
    projectId = null,
    assetgroups = null
  ): Observable<any> {
    return this.http
      .get(
        this.url +
          accountId +
          `/Trips?start=${start.unix()}&end=${end.unix()}&includeEpisodes=${includeEpisodes}&includeGeofenceStates=${includeGoefenceStates}&includeMessages=${includeMessages}&includeJsonLocationData=${includeJsonLocationData}&vehicleType=${vehicleType}&assetTypeId=${assetTypeId}&deviceType=${deviceType}&projectId=${projectId}&assetgroups=${assetgroups}`,
        { headers: this.authenticationService.headers }
      )
      .pipe(
        map((data: any) => {
          this.loggingService.log(this.constructor.name, "Retrieved " + data.length + " trips.");

          data.forEach((item) => {
            try {
              item.assetGroups = item.assetGroups != null ? JSON.parse(item.assetGroups.replace("\t", "")) : [];
            } catch (error) {
              console.log(error);
            }

            if (item.assetGroups && item.assetGroups.length > 0) {
              item.assetGroups.forEach((assetGroup) => {
                assetGroup.name = localizeSystemGroupNames(assetGroup.name, this.translateService);
              });
            }
          });

          return data;
        }),
        catchError(this.handleError)
      );
  }

  getAccountTrends(
    accountId,
    groupBy,
    split,
    start,
    end,
    thresholds,
    vehicleType,
    assetTypeId,
    deviceType,
    projectId,
    assetgroups,
    includeWork
  ): Observable<any> {
    if (accountId == null) {
      return of([]);
    }

    return this.http
      .post(
        this.url +
          accountId +
          `/Trends?groupBy=${groupBy}&split=${split}&start=${start.unix()}&end=${end.unix()}&vehicleType=${vehicleType}&assetTypeId=${assetTypeId}&deviceType=${deviceType}&projectId=${projectId}&assetgroups=${assetgroups}&includeWork=${includeWork}`,
        thresholds,
        { headers: this.authenticationService.headers }
      )
      .pipe(
        map((data: any) => {
          this.loggingService.log(this.constructor.name, "Retrieved " + data.length + " Trend items.");

          return data;
        }),
        catchError(this.handleError)
      );
  }

  getAccountEngineHours(
    accountId,
    start,
    end,
    vehicleType,
    assetTypeId,
    deviceType,
    projectId,
    assetgroups
  ): Observable<any> {
    if (accountId == null) {
      return of([]);
    }

    return this.http
      .get(
        this.url +
          accountId +
          `/EngineHours?start=${start.unix()}&end=${end.unix()}&vehicleType=${vehicleType}&assetTypeId=${assetTypeId}&deviceType=${deviceType}&projectId=${projectId}&assetgroups=${assetgroups}`,
        { headers: this.authenticationService.headers }
      )
      .pipe(
        map((data: any) => {
          this.loggingService.log(this.constructor.name, "Retrieved " + data.length + " Trend items.");

          return data;
        }),
        catchError(this.handleError)
      );
  }

  getAccountDriverScore(accountId, start, end): Observable<any> {
    if (accountId == null) {
      return of([]);
    }

    return this.http
      .get(this.url + accountId + "/DriverScore?start=" + start.unix() + "&end=" + end.unix(), {
        headers: this.authenticationService.headers,
      })
      .pipe(
        map((data: any) => {
          this.loggingService.log(this.constructor.name, "Retrieved " + data.length + " Devices.");

          let drivers: DriverScore[] = [];
          drivers = this.parseDriverScore(data);

          return drivers;
        }),
        catchError(this.handleError)
      );
  }

  getAccountAssetScore(accountId, start, end): Observable<any> {
    if (accountId == null) {
      return of([]);
    }

    return this.http
      .get(this.url + accountId + "/AssetScore?start=" + start.unix() + "&end=" + end.unix(), {
        headers: this.authenticationService.headers,
      })
      .pipe(
        map((data: any) => {
          this.loggingService.log(this.constructor.name, "Retrieved " + data.length + " Devices.");

          let assets: DriverScore[] = [];
          assets = this.parseDriverScore(data);

          return assets;
        }),
        catchError(this.handleError)
      );
  }

  parseDriverScore(data) {
    let rank = 0;
    const result = [];

    data.forEach((item) => {
      const driverScore = new DriverScore();
      driverScore.driverId = item.driverId;
      driverScore.driverName = item.driverName;
      driverScore.deviceId = item.deviceId;
      driverScore.assetName = item.assetName;
      driverScore.vehicleType = item.vehicleType;
      driverScore.assetCount = item.assetCount;
      driverScore.assignmentCount = item.assignmentCount;
      driverScore.accountId = item.accountId;

      driverScore.driverGroups = item.driverGroups != null ? JSON.parse(item.driverGroups) : [];
      if (driverScore.driverGroups.length > 0) {
        driverScore.driverGroups.forEach((assetGroup) => {
          assetGroup.name = localizeSystemGroupNames(assetGroup.name, this.translateService);
        });
      }

      driverScore.assetGroups = item.assetGroups != null ? JSON.parse(item.assetGroups.replace("\t", "")) : [];
      if (driverScore.assetGroups && driverScore.assetGroups.length > 0) {
        driverScore.assetGroups.forEach((assetGroup) => {
          assetGroup.name = localizeSystemGroupNames(assetGroup.name, this.translateService);
        });
      }

      driverScore.calculatorValue = item.calculatorValue;

      driverScore.tripCount = item.tripCount ?? 0;
      driverScore.workingHoursDurationInSeconds = item.workingHoursDurationInSeconds ?? 0;
      driverScore.pureDrivingDurationInSeconds = item.pureDrivingDurationInSeconds ?? 0;
      driverScore.maxSpeed = this.distance.calculateDistanceUnitFromKmFixed(item.maxSpeed, 0);
      driverScore.segmentsDistance = this.distance.calculateDistanceUnitFromKmFixed(item.segmentsDistance, 0) ?? 0;
      driverScore.totalDurationInSeconds = item.totalDurationInSeconds ?? 0;
      driverScore.score = item.score ?? 0;
      driverScore.idlingDurationInSeconds = item.idlingDurationInSeconds ?? 0;
      driverScore.speedingDurationInSeconds = item.speedingDurationInSeconds ?? 0;
      driverScore.speedingCount = item.speedingCount ?? 0;

      driverScore.accelCount = item.accelCount ?? 0;
      driverScore.decelCount = item.decelCount ?? 0;
      driverScore.roadSpeedingCount = item.roadSpeedingCount ?? 0;
      driverScore.corneringCount = item.corneringCount ?? 0;
      driverScore.rpmCount = item.rpmCount ?? 0;

      driverScore.speedingDurationInSeconds = item.speedingDurationInSeconds ?? 0;
      driverScore.durationSeatBeltViolationInSeconds = item.durationSeatBeltViolationInSeconds ?? 0;

      driverScore.accellerationEventScore = item.accellerationEventScore ?? 0;
      driverScore.brakingEventScore = item.brakingEventScore ?? 0;
      driverScore.corneringEventScore = item.corneringEventScore ?? 0;
      driverScore.rpmEventScore = item.rpmEventScore ?? 0;

      driverScore.idlingScore = item.idlingScore;
      driverScore.speedScore = item.speedScore;
      driverScore.seatBeltScore = item.seatBeltScore;

      driverScore.idlingScoreWeighted = item.idlingScoreWeighted;
      driverScore.speedScoreWeighted = item.speedScoreWeighted;
      driverScore.seatBeltScoreWeighted = item.seatBeltScoreWeighted;

      driverScore.accellerationEventScoreWeighted = item.accellerationEventScoreWeighted;
      driverScore.brakingEventScoreWeighted = item.brakingEventScoreWeighted;
      driverScore.corneringEventScoreWeighted = item.corneringEventScoreWeighted;
      driverScore.rpmEventScoreWeighted = item.rpmEventScoreWeighted;

      driverScore.isActive = item.isActive;
      driverScore.rank = ++rank;

      result.push(driverScore);
    });

    return result;
  }

  getDevicesAdded(id: string): Observable<any> {
    return this.http.get(this.url + id + "/added", { headers: this.authenticationService.headers }).pipe(
      map((data: any) => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  getLocationCount(
    id: string,
    start,
    end,
    vehicleType,
    assetTypeId,
    deviceType,
    projectId,
    assetgroups
  ): Observable<any> {
    return this.http
      .get(
        this.url +
          id +
          `/locationCount?start=${start.unix()}&end=${end.unix()}&vehicleType=${vehicleType}&assetTypeId=${assetTypeId}&deviceType=${deviceType}&projectId=${projectId}&assetgroups=${assetgroups}`,
        { headers: this.authenticationService.headers }
      )
      .pipe(catchError(this.handleError));
  }

  getGeofencesByAccount(id: string, shared = false): Observable<any> {
    let headers;
    if (shared) {
      this.url = this.authenticationService.getWebserviceURL("account");
      headers = this.authenticationService.shareheaders;
    } else {
      headers = this.authenticationService.headers;
    }

    return this.http.get(this.url + id + "/geofences", { headers: headers }).pipe(catchError(this.handleError));
  }

  saveAccount(account: AccountInventory, user: AppUser): Observable<any> {
    // Reset cache
    this.accounts = [];

    return this.http
      .post(this.url, { ...user, ...account }, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  updateAccount(account: AccountInventory): Observable<any> {
    // Reset cache
    this.accounts = [];

    return this.http
      .put(this.url + account.id, account, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  updateSharingMatrix(id: string, sharingMatrix): Observable<any> {
    return this.http
      .post(this.url + id + "/Sharing", JSON.stringify({ matrixObjects: sharingMatrix }), {
        headers: this.authenticationService.headers,
      })
      .pipe(catchError(this.handleError));
  }

  deleteAccount(account: AccountInventory): Observable<any> {
    // Reset cache
    this.accounts = [];

    return this.http
      .delete(this.url + account.id, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  private handleError(error: Response) {
    return throwError(() => error);
  }

  parseResponse(json: any, addExtended = false): AccountInventory[] {
    this.loggingService.log(this.constructor.name, "Retrieved " + json.length + " Accounts.");

    const accounts: AccountInventory[] = [];

    json.forEach((item) => {
      const account = this.parseReponseDetails(item, addExtended);
      accounts.push(account);
    });

    return accounts;
  }

  parseGroupResponseDetails(item) {
    item.groupName = localizeSystemGroupNames(item.groupName, this.translateService);
    return item;
  }

  parseReponseDetails(item, addExtended = false) {
    const account = new AccountInventory();
    account.id = item.id;
    account.name = item.name;
    account.erpCode = item.erpCode;

    account.firstName = item.firstName;
    account.lastName = item.lastName;

    account.linkName = item.name && item.name.length > 0 ? item.name : account.firstName + " " + account.lastName;

    account.contactEmail = item.contactEmail;
    account.street = item.street;
    account.street2 = item.street2;
    account.city = item.city;
    account.state = item.state;
    account.zip = item.zip;
    account.language = item.language;

    account.latitude = item.latitude;
    account.longitude = item.longitude;

    account.countryId = item.countryId;

    account.resellerDescription = item.resellerDescription;
    account.resellerId = item.resellerId;

    account.deviceCount = item.deviceCount;
    account.driverCount = item.driverCount;
    account.geofenceCount = item.geofenceCount;
    account.isActive = item.isActive;

    account.adminUserName = item.adminUserName;
    account.adminUserId = item.adminUserId;
    account.parentAccountId = item.parentAccountId;
    account.parentAccountName = item.parentAccountName;
    account.resellerDefaultAssetIcon = item.defaultAssetIconId;
    account.defaultScheduleId = item.defaultScheduleId;

    if (addExtended) {
      const wasl = new RegisterOperatingCompany();
      if (item.properties) {
        if (item.properties.custom) {
          account.properties.custom = [];

          item.properties.custom.forEach((row, index) => {
            account.properties.custom.push({ key: row.key, value: row.value });
          });
        }

        if (item.properties.wasl) {
          wasl.identityNumber = item.properties.wasl.identityNumber;
          wasl.commercialRecordNumber = item.properties.wasl.commercialRecordNumber;
          wasl.commercialRecordIssueDateHijri = item.properties.wasl.commercialRecordIssueDateHijri;

          wasl.extensionNumber = item.properties.wasl.extensionNumber;
          wasl.emailAddress = item.properties.wasl.emailAddress;
          wasl.managerName = item.properties.wasl.managerName;
          wasl.managerPhoneNumber = item.properties.wasl.managerPhoneNumber;
          wasl.managerMobileNumber = item.properties.wasl.managerMobileNumber;
          wasl.phoneNumber = item.properties.wasl.phoneNumber;

          wasl.dateOfBirthGregorian = item.properties.wasl.dateOfBirthGregorian;
          wasl.sfdaCompanyActivity = item.properties.wasl.sfdaCompanyActivity;

          wasl.referenceKey = item.properties.wasl.referenceKey;

          wasl.registerDateWasl = item.properties.wasl.registerDateWasl;
          wasl.registerDateSfda = item.properties.wasl.registerDateSfda;
          wasl.registerDateTow = item.properties.wasl.registerDateTow;
          wasl.registerDateSpecialityTransport = item.properties.wasl.registerDateSpecialityTransport;
          wasl.registerDateBusRental = item.properties.wasl.registerDateBusRental;
          wasl.registerDateEducationalTransport = item.properties.wasl.registerDateEducationalTransport;
        }
      }
      account.properties.wasl = wasl;
    }

    return account;
  }

  // Maptokens
  getMapTokens(id: any): Observable<any> {
    return this.http.get(this.url + id + "/MapTokens", { headers: this.authenticationService.headers }).pipe(
      map((data) => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  createMapToken(accountId: any, mapType: number, mapToken: any): Observable<any> {
    return this.http
      .post(
        this.url + accountId + "/MapToken",
        { accountId: accountId, mapType: mapType, token: mapToken },
        { headers: this.authenticationService.headers }
      )
      .pipe(catchError(this.handleError));
  }

  deleteMapToken(accountId: any, id: any): Observable<any> {
    return this.http
      .delete(this.url + accountId + "/MapToken/" + id, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  assignDefaultSchedule(accountId: any, scheduleId: any) {
    return this.http
      .post(
        this.url + accountId + "/Schedule",
        { accountId: accountId, defaultScheduleId: scheduleId },
        { headers: this.authenticationService.headers }
      )
      .pipe(catchError(this.handleError));
  }

  unassignDefaultSchedule(accountId: any) {
    return this.http
      .delete(this.url + accountId + "/Schedule", { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  parseNotificationResponse(json: any): NotificationLog[] {
    this.loggingService.log(this.constructor.name, "Retrieved " + json.length + " Logitems.");
    const auditlogs: NotificationLog[] = [];

    json.forEach((item) => {
      const log = this.parseNotificationResponseDetails(item);
      auditlogs.push(log);
    });

    return auditlogs;
  }

  parseNotificationResponseDetails(item) {
    const log = new NotificationLog();
    log.id = item.id;
    log.deviceId = item.deviceId;
    log.dataTriggerId = item.dataTriggerId;
    log.recipient = item.recipient;
    log.notificationType = item.notificationType;
    log.responseCode = item.responseCode;
    log.notificationMethod = item.notificationMethod;
    log.timeStampSending = Moment.utc(item.timeStampSending)["tz"](this.timezoneIana);
    log.emailLoginName = item.emailLoginName;
    log.timeStampAcknowledged = item.timeStampAcknowledged;
    log.transmissionStatus = item.transmissionStatus;
    log.fkUserId = item.fkUserId;
    log.subject = item.subject;
    log.triggerId = item.triggerId;
    log.triggerName = item.triggerName;
    log.smsExternalMessageReference = item.smsExternalMessageReference;
    log.assetName = item.assetName;
    log.dataTriggerName = item.dataTriggerName;

    try {
      log.message = item.message && item.message.charAt(0) === "{" ? JSON.parse(item.message) : item.message;
      log.responseMessage =
        item.responseMessage && item.responseMessage.charAt(0) === "{"
          ? JSON.parse(item.responseMessage)
          : item.responseMessage;
    } catch (error) {
      console.log(error);
    }

    return log;
  }
}
