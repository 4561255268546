<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/"> <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item active">{{'general.myAccount' | translate}}</li>
            </ol>
        </nav>
    </div>
</div>

<div class="wrapper wrapper-content">
    <div class="row" data-equalizer="">
        <div class="col-lg-12">
            <fh-error [error]="error" [success]="success"></fh-error>
        </div>

        <div class="col-lg-6">
            <div class="ibox">
                <div class="ibox-title">
                    <h5><i class="fas fa-fw fa-building"></i> {{'general.myAccount' | translate }}</h5>
                </div>
                <form #userForm="ngForm">
                    <fh-account-details [account]="account" [formMode]="formMode" [loading]="loading || saving">
                    </fh-account-details>

                    <fh-save-footer
                        [valid]="!userForm.invalid"
                        [allowEdit]="permissions['Accounts_Edit']"
                        [allowDelete]="false"
                        (onSave)="onSave()"
                        (setFormMode)="setFormMode($event)"
                        [formMode]="formMode"
                        [showButtons]="true"
                    >
                    </fh-save-footer>
                </form>
            </div>
        </div>
        <div class="col-lg-6">
            <fh-schedule-assignments
                [accountId]="account?.id"
                [selectedAccountId]="accountId"
                [deletePermission]="'Accounts_Edit'"
            >
            </fh-schedule-assignments>
        </div>
    </div>
</div>
