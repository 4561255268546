import { Component, EventEmitter, Output, ChangeDetectionStrategy, ChangeDetectorRef, Input } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { ThirdPartyProviders } from "app/common/enums";

@Component({
  selector: "fh-batch-unregister-wasl",
  templateUrl: "unRegisterWasl.template.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BatchUnRegisterWaslComponent {
  step: number;
  @Input() gridSelection = [];
  @Output() onFinish = new EventEmitter();
  disabled = true;
  logItem;

  thirdPartyProviders = [];

  selectedThirdPartyProvider: undefined;

  groupForm = new FormGroup({
    selectedThirdPartyProvider: new FormControl(null, Validators.required),
  });

  constructor(private cd: ChangeDetectorRef, private translateService: TranslateService) {
    this.step = 1;
  }

  initFinish() {
    // Fetch all data and make sure it can be saved
    console.log(1);
  }

  onCheckOutFinish() {
    this.onFinish.emit({ status: "success", object: this.groupForm.value, log: this.logItem });
    this.step = 1;
  }

  initForm() {
    this.step = 1;
    console.log("init tab");
    this.populateSelectOptions();
  }

  populateSelectOptions(): void {
    this.thirdPartyProviders = Object.keys(ThirdPartyProviders)
      .filter((k) => typeof ThirdPartyProviders[k] === "string")
      .map((n) => ({ id: n, value: this.translateService.instant("enums.thirdPartyProviders." + n) }));
  }
}
