import {
  Component,
  EventEmitter,
  Output,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { AssetGroupsService } from "app/services/asset/assetGroups.service";
import { EntityType, VehicleType, FormMode } from "app/common/enums";
import { DriverGroupsService } from "app/services/driver/driverGroups.service";
import { GeofenceGroupsService } from "app/services/geofence/geofenceGroups.service";

@Component({
  selector: "fh-batch-update-fields",
  templateUrl: "updateFields.template.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BatchUpdateFieldsComponent implements OnInit, OnChanges {
  step: number;
  @Input() gridSelection = [];
  @Input() entityTypes = EntityType.Unknown;
  @Output() onFinish = new EventEmitter();

  formMode = FormMode.add;
  disabled = true;
  logItem;
  groupForm = new FormGroup({});
  // Device
  selectedVehicleType = undefined;
  selectedIcon = undefined;

  color;
  brand;
  model;
  fuelTank1Capacity;
  fuelTank2Capacity;
  fillupThreshold;
  theftThreshold;

  // Driver
  comments;

  // Geofence
  geofenceColor = "#0033FF";

  rowsEnabled = [];

  icons = [{ id: 1, name: "default" }];
  vehicleTypes: { id: string; name: any }[];
  iconId;

  constructor(
    private cd: ChangeDetectorRef,
    private assetGroupService: AssetGroupsService,
    private driverGroupService: DriverGroupsService,
    private geofenceGroupService: GeofenceGroupsService
  ) {
    this.step = 1;
  }

  ngOnChanges(changes: SimpleChanges): void {}

  ngOnInit() {
    this.vehicleTypes = Object.keys(VehicleType)
      .filter((k) => typeof VehicleType[k] === "string")
      .map((n) => ({ id: n, name: VehicleType[n] }));

    this.step = 1;
  }

  initFinish() {
    // Fetch all data and make sure it can be saved
    console.log(1);
  }

  setTouched() {
    setTimeout(() => {
      this.groupForm.markAllAsTouched();
      this.cd.markForCheck();
    }, 10);
  }

  onCheckOutFinish() {
    // Fill return object
    const returnObject: any = {};

    switch (this.entityTypes) {
      case EntityType.Device:
        // Check if device fields
        if (this.rowsEnabled[0]) {
          returnObject.vehicleType = this.selectedVehicleType;
        }
        if (this.rowsEnabled[1]) {
          returnObject.color = this.color;
        }
        if (this.rowsEnabled[2]) {
          returnObject.brand = this.brand;
        }
        if (this.rowsEnabled[3]) {
          returnObject.model = this.model;
        }
        if (this.rowsEnabled[4]) {
          returnObject.icon = this.iconId;
        }
        if (this.rowsEnabled[5]) {
          returnObject.fuelTank1Capacity = this.fuelTank1Capacity;
        }
        if (this.rowsEnabled[6]) {
          returnObject.fuelTank2Capacity = this.fuelTank2Capacity;
        }
        if (this.rowsEnabled[7]) {
          returnObject.fillupThreshold = this.fillupThreshold;
        }
        if (this.rowsEnabled[8]) {
          returnObject.theftThreshold = this.theftThreshold;
        }
        break;
      case EntityType.Driver:
        if (this.rowsEnabled[20]) {
          returnObject.comments = this.comments;
        }
        break;
      case EntityType.Geofence:
        if (this.rowsEnabled[40]) {
          returnObject.geofenceColor = this.geofenceColor;
        }
        break;
      default:
        break;
    }

    this.onFinish.emit({ status: "success", object: returnObject, log: this.logItem });
    this.step = 1;
  }

  initForm() {
    console.log("init tab");

    this.step = 1;
    console.log("Filling groups for " + this.entityTypes);
  }
}
