<fh-wizard [(step)]="step" (finish)="onCheckOutFinish()" [canContinue]="deviceForm">
    <fh-wizard-step title="{{ 'enums.batchAction.23' | translate }}" icon="fa-user" (open)="initForm()">
        <div class="wizard-content">
            <table style="width: 100%" class="table nowrap dtr-inline no-footer">
                <tbody>
                    <tr>
                        <td>{{ 'general.date' | translate }}</td>
                        <td>
                            <input
                                class="form-control table-inline"
                                [(ngModel)]="odoDate"
                                required
                                autocomplete="off"
                                [bsConfig]="dpConfig"
                                bsDatepicker
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>{{ 'general.odo' | translate }}</td>
                        <td><input class="form-control table-inline" [(ngModel)]="odoValue" required /></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </fh-wizard-step>
    <fh-wizard-step title="Remarks" icon="fa-file-pdf">
        <div class="wizard-content" style="padding-bottom: 10px">{{ 'wizard.optionalComment' | translate }}</div>
        <div class="wizard-content">
            <textarea
                class="form-control noresize stickypad"
                style="height: 100px; width: 100%; resize: none"
                placeholder="{{ 'placeholder.comments' | translate}}"
                [(ngModel)]="logItem"
            ></textarea>
        </div>
    </fh-wizard-step>
</fh-wizard>
