import { Component, Input, ChangeDetectorRef, OnChanges, Output, EventEmitter, SimpleChanges } from "@angular/core";
import { FormMode } from "app/common/enums";
import { ControlContainer, NgForm } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { ReportTemplate } from "app/models/reporting.model";
import { ReportService } from "../../../services/reports/report.service";

import { create, all } from "../../../../../vendor/mathjs/math.js";
import { AuthenticationService } from "app/services/authentication/authentication.service";

const config = {};
const math = create(all, config);

@Component({
  selector: "fh-column-selector",
  templateUrl: "columnSelector.component.html",
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class ColumnSelectorComponent implements OnChanges {
  @Input() defaultSourceColumns = [];
  @Input() loading = false;
  @Input() template: ReportTemplate;
  @Input() reportTemplates = [];
  @Input() selectedReportTemplateId;
  @Input() formMode;

  @Output() templateChange = new EventEmitter();
  @Output() reportTemplateChange = new EventEmitter();
  @Output() saveReportTemplateEmitter = new EventEmitter();
  @Output() updateReportTemplateEmitter = new EventEmitter();

  sourceColumns = [];
  sourceColumnsSorted = [];

  formulaColumns = [];

  selectedColumn;
  isModalShown = false;
  isSavingModal = false;
  availableColumns = false;
  source_new;

  private draggedIndex: number;
  public draggedOverIndex: number;

  public exampleDate = new Date();
  enableFormula = true;
  permissions: {};

  constructor(
    private cd: ChangeDetectorRef,
    private translate: TranslateService,
    private reportService: ReportService,
    private authenticationService: AuthenticationService
  ) {
    this.permissions = this.authenticationService.permissions;
  }

  emitChanges() {
    setTimeout(() => {
      this.templateChange.emit(this.template);
      this.cd.markForCheck();
    }, 1);
  }

  groupOnChanges(columnIndex) {
    if (columnIndex === -1) {
      this.template.groupByType = -1;

      this.emitChanges();
      return;
    }

    const groupOnColumn = this.template.columnConfiguration[columnIndex];

    if (groupOnColumn.dataType === "DateTime") {
      this.template.groupByType = 2;
      this.cd.markForCheck();
    } else {
      this.template.groupByType = 1;
      this.cd.markForCheck();
    }

    this.emitChanges();
  }

  sortOnChanges() {
    const index = this.template.orderByIndex;
    this.emitChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["defaultSourceColumns"] && this.defaultSourceColumns?.length > 0) {
      if (this.enableFormula) {
        this.defaultSourceColumns.push({
          entity: "Formula",
          source: "Formula",
          name: "Formula",
          width: 0,
          alignment: 1,
          enabled: false,
          isCustom: true,
          grouping: 0,
          dataType: "Formula",
          uom: "",
          formula: "",
        });

        this.formulaColumns = this.defaultSourceColumns.filter(
          (x) => x.dataType === "Float" || x.dataType.startsWith("Int") || x.dataType.startsWith("Date")
        );
      }

      this.sourceColumns = this.defaultSourceColumns;
      this.sourceColumnsSorted = this.sourceColumns.sort((a, b) => (a.name < b.name ? -1 : 1));

      if (this.template.columnConfiguration?.length === 0) {
        this.template.columnConfiguration = this.defaultSourceColumns.filter((x) => x.enabled === true);
      } else {
        return;
      }

      this.template.columnConfiguration.forEach((column, index) => {
        if (+column.uom === 0) {
          column.uom = "";
        }
      });
    }

    if (this.formMode === FormMode.edit) {
      if (this.template?.name != "default") {
        this.selectedReportTemplateId = this.template?.id;
      } else {
        this.selectedReportTemplateId = "default";
      }

      if (this.reportTemplates !== undefined || this.reportTemplates.length !== 0) {
        const duplicateTemplate = this.reportTemplates.find((x) => x.id === this.template.id);

        if (duplicateTemplate !== undefined && duplicateTemplate !== null) {
          this.reportTemplates = this.reportTemplates.filter((template) => template != duplicateTemplate);
        }

        if (this.template.name != "default") {
          this.reportTemplates.push(this.template);
        }
      }
    }

    this.emitChanges();
  }

  evaluate(row) {
    let result = "";
    let formula = row.formula;

    // Replace brackets with random int
    formula = formula.replace(/\[(.*?)\]/g, function (match, p1, p2, p3, offset, string) {
      return Math.floor(Math.random() * 100);
    });

    try {
      result = math.evaluate(formula);
    } catch (error) {
      result = error;
    }

    row.evaluationResult = result;
  }

  // using https://mathjs.org/

  allowDrop($event, index): void {
    this.draggedOverIndex = index;
    $event.preventDefault();
  }

  onDragStart($event, index): void {
    console.log("Drag start for index: " + index);
    this.draggedIndex = index;
  }

  onDrop($event, newIndex): void {
    $event.preventDefault();
    const column = this.template.columnConfiguration[this.draggedIndex];
    const theOldColumnIndex: number = this.template.columnConfiguration.indexOf(column);

    if (this.template.groupByIndex !== undefined || this.template.orderByIndex !== undefined) {
      // Grouping

      if (theOldColumnIndex === this.template.groupByIndex) {
        this.template.groupByIndex = newIndex;
      } else if (theOldColumnIndex < this.template.groupByIndex && newIndex >= this.template.groupByIndex) {
        this.template.groupByIndex = this.template.groupByIndex - 1;
      } else if (theOldColumnIndex >= this.template.groupByIndex && newIndex <= this.template.groupByIndex) {
        this.template.groupByIndex = this.template.groupByIndex + 1;
      }

      // Ordering
      if (theOldColumnIndex === this.template.orderByIndex) {
        this.template.orderByIndex = newIndex;
      } else if (theOldColumnIndex < this.template.orderByIndex && newIndex >= this.template.orderByIndex) {
        this.template.orderByIndex = this.template.orderByIndex - 1;
      } else if (theOldColumnIndex >= this.template.orderByIndex && newIndex <= this.template.orderByIndex) {
        this.template.orderByIndex = this.template.orderByIndex + 1;
      }
    }

    this.template.columnConfiguration.splice(this.draggedIndex, 1);
    this.template.columnConfiguration.splice(newIndex, 0, column);
    this.draggedIndex = -1;
    this.draggedOverIndex = -1;

    this.emitChanges();
  }

  add() {
    const source = this.defaultSourceColumns.find((x) => x.source === this.source_new);

    const newColumn = {
      enabled: true,
      source: source.source,
      entity: source.entity,
      name: source.name,
      width: source.width,
      alignment: source.alignment,
      grouping: source.grouping,
      formula: source.formula,
      dataType: source.dataType,
      uom: source.uom,
      formatting: source.formatting,
    };

    this.cd.markForCheck();

    this.template.columnConfiguration.push(newColumn);

    this.emitChanges();
  }

  edit() {}

  // Popup
  public onHidden(): void {
    console.log("hidden modal");

    this.emitChanges();
  }

  public showModal(index): void {
    this.selectedColumn = this.template.columnConfiguration[index];

    this.isModalShown = true;
    this.cd.markForCheck();
  }

  removeColumn(index) {
    if (index !== -1) {
      if (this.template.groupByIndex === index) {
        console.log("reset groupByIndex");
        this.template.groupByIndex = -1;
      }

      if (this.template.orderByIndex === index) {
        console.log("reset orderByIndex");
        this.template.orderByIndex = -1;
      }

      this.emitChanges();

      if (index < this.template.groupByIndex) {
        this.template.groupByIndex = this.template.groupByIndex - 1;
      }

      if (index < this.template.orderByIndex) {
        this.template.orderByIndex = this.template.orderByIndex - 1;
      }

      this.template.columnConfiguration.splice(index, 1);

      this.emitChanges();
    }
  }

  public hideModal(): void {
    this.selectedColumn = null;
    this.isModalShown = false;
    this.cd.markForCheck();
  }

  saveColumn(column) {
    this.selectedColumn = null;
    this.hideModal();

    this.emitChanges();
  }

  saveAsReportTemplate(form) {
    this.saveReportTemplateEmitter.emit({ form: form.value, template: this.template });
    this.isSavingModal = false;

    this.emitChanges();
  }

  saveReportTemplate() {
    this.updateReportTemplateEmitter.emit({ template: this.template });
  }

  showColumns() {}

  checkForEdit(reportTemplateId) {
    if (reportTemplateId === "default") {
      return false;
    }

    const reportTemplate = this.reportTemplates.find((x) => x.id === reportTemplateId);

    if (
      reportTemplate.userId != null &&
      reportTemplate != null &&
      reportTemplate.userId?.toString() === this.authenticationService.getUserId()
    ) {
      return true;
    }
    return false;
  }

  templateChanged(reportTemplateId) {
    this.reportTemplateChange.emit(reportTemplateId);

    if (reportTemplateId === "default") {
      console.log("Setting default");

      if (this.defaultSourceColumns?.length === 0) {
        return;
      }

      const defaultTemplate = new ReportTemplate();
      defaultTemplate.columnConfiguration = this.defaultSourceColumns?.filter((x) => x.enabled === true);

      // Reset grouping
      defaultTemplate.groupByIndex = -1;
      defaultTemplate.groupByType = -1;
      defaultTemplate.orderByAscending = false;
      defaultTemplate.orderByIndex = -1;
      defaultTemplate.orientation = 0;

      this.template = defaultTemplate;

      this.emitChanges();

      return;
    }

    console.log("Setting template " + reportTemplateId);

    const reportTemplate = this.reportTemplates.find((x) => x.id === reportTemplateId);

    // Copy as to not change the defenitions
    const template = { ...reportTemplate };

    // Filter columns
    const columnConfiguration = reportTemplate.columnConfiguration;
    template.columnConfiguration = columnConfiguration.filter((x) => x.enabled === true);

    this.template = template;

    this.emitChanges();
  }
}
