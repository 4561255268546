import { ChangeDetectorRef, Component } from "@angular/core";
import { Router } from "@angular/router";
import { FormMode } from "app/common/enums";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { AssetTypeService } from "app/services/asset/assetTypes.service";
import { AssetType } from "app/models/assetType.model";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "fh-asset-type-add",
  templateUrl: "addType.template.html",
})
export class AssetTypeAddViewComponent {
  token: string;
  loading = false;
  assetType = new AssetType();

  formMode: FormMode = FormMode.add;
  formModeAssetTypes: FormMode = FormMode.read;

  error: any;
  permissions: {};
  success: { statusText: string; success: string };
  assetTypes: AssetType[];

  permissionName = "AssetTypes_Add";

  constructor(
    private translateServe: TranslateService,
    private cd: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private router: Router,
    private assetTypeService: AssetTypeService
  ) {
    this.permissions = this.authenticationService.permissions;
    this.token = this.authenticationService.getAuthToken();
  }

  onInsert() {
    this.loading = true;

    this.assetTypeService.saveAssetType(this.assetType).subscribe(
      (result) => {
        this.success = {
          statusText: "Success",
          success: this.translateServe.instant("general.saveSuccessRedirect", { entity: "assetType" }),
        };

        setTimeout(() => {
          this.router.navigate(["/Devices/AssetTypeDetails/Index/" + result.id]);
        }, 2000);

        this.loading = false;
      },
      (error) => {
        this.error = error;
        this.loading = false;
      }
    );
  }

  accountChanged(accountId) {
    console.log("Changed accountId");
    this.assetType.accountId = accountId;

    this.loading = true;

    if (this.assetType.accountId && this.assetType.accountId > 0) {
      this.assetTypeService.getAssetTypes(this.assetType.accountId).subscribe((result) => {
        this.assetTypes = result;
        this.loading = false;
      });
    } else {
      this.loading = false;
    }

    this.cd.markForCheck();
  }
}
