import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { throwError } from "rxjs/internal/observable/throwError";
import { Observable, catchError, map } from "rxjs";
import { AuditLog } from "../../models/auditlog.model";
import { Device } from "../../models/device.model";
import { AuthenticationService } from "../authentication/authentication.service";
import { LoggingService } from "../logging/logging.service";

// Moment timezone
import Moment from "moment-timezone";
import { generateEntityDetailLink } from "app/common/globals";
window["moment"] = Moment;

@Injectable()
export class AuditLogService {
  timestamp: number;
  url: string = "";
  urlBase: string = "";
  timezoneIana: string;

  constructor(
    private http: HttpClient,
    private loggingService: LoggingService,
    private authenticationService: AuthenticationService
  ) {
    this.url = this.authenticationService.getWebserviceURL("auditlog");
    this.urlBase = this.authenticationService.getWebserviceURL("");
    this.timezoneIana = this.authenticationService.getTimeZoneIana();
  }

  getPagingUrl(start, end) {
    return this.url + "Paging?startRange=" + start.unix() + "&endRange=" + end.unix();
  }

  getAllAuditLogsForAccount(id: string, start = null, end = null): Observable<AuditLog[]> {
    console.log("getting auditlog from service");
    return this.http
      .get(this.url + "?accountId=" + id + "&start=" + start.unix() + "&end=" + end.unix(), {
        headers: this.authenticationService.headers,
      })
      .pipe(
        map((data) => this.parseResponse(data)),
        catchError(this.handleError)
      );
  }

  getAuditLogById(id: string): Observable<AuditLog> {
    console.log("getting auditLog from service");
    return this.http.get(this.url + id, { headers: this.authenticationService.headers }).pipe(
      map((data) => this.parseResponseDetails(data, true)),
      catchError(this.handleError)
    );
  }

  getAuditLogByDevice(id: string): Observable<AuditLog[]> {
    console.log("getting auditlog from service");
    return this.http
      .get(this.urlBase + "device/" + id + "/auditlog", { headers: this.authenticationService.headers })
      .pipe(
        map((data) => this.parseResponse(data)),
        catchError(this.handleError)
      );
  }

  getAuditLogByDriver(id: string): Observable<AuditLog[]> {
    console.log("getting auditlog from service");
    return this.http
      .get(this.urlBase + "driver/" + id + "/auditlog", { headers: this.authenticationService.headers })
      .pipe(
        map((data) => this.parseResponse(data)),
        catchError(this.handleError)
      );
  }

  getAuditLogByDriverGroup(id: string): Observable<AuditLog[]> {
    console.log("getting auditlog from service");
    return this.http
      .get(this.urlBase + "driverGroup/" + id + "/auditlog", { headers: this.authenticationService.headers })
      .pipe(
        map((data) => this.parseResponse(data)),
        catchError(this.handleError)
      );
  }

  getAuditLogByTrailer(id: string): Observable<AuditLog[]> {
    console.log("getting auditlog from service");
    return this.http
      .get(this.urlBase + "trailer/" + id + "/auditlog", { headers: this.authenticationService.headers })
      .pipe(
        map((data) => this.parseResponse(data)),
        catchError(this.handleError)
      );
  }

  getAuditLogByReportSubscription(id: string): Observable<AuditLog[]> {
    console.log("getting auditlog from service");
    return this.http
      .get(this.urlBase + "report/subscription/" + id + "/auditlog", { headers: this.authenticationService.headers })
      .pipe(
        map((data) => this.parseResponse(data)),
        catchError(this.handleError)
      );
  }

  getAuditLogByTrigger(id: string): Observable<AuditLog[]> {
    console.log("getting auditlog from service");
    return this.http
      .get(this.urlBase + "trigger/" + id + "/auditlog", { headers: this.authenticationService.headers })
      .pipe(
        map((data) => this.parseResponse(data)),
        catchError(this.handleError)
      );
  }

  getAuditLogByProject(id: string): Observable<AuditLog[]> {
    console.log("getting auditlog from service");
    return this.http
      .get(this.urlBase + "project/" + id + "/auditlog", { headers: this.authenticationService.headers })
      .pipe(
        map((data) => this.parseResponse(data)),
        catchError(this.handleError)
      );
  }

  getAuditLogByGeofence(id: string): Observable<AuditLog[]> {
    console.log("getting auditlog from service");
    return this.http
      .get(this.urlBase + "geofence/" + id + "/auditlog", { headers: this.authenticationService.headers })
      .pipe(
        map((data) => this.parseResponse(data)),
        catchError(this.handleError)
      );
  }

  getAuditLogByGeofenceGroup(id: string): Observable<AuditLog[]> {
    console.log("getting auditlog from service");
    return this.http
      .get(this.urlBase + "geofenceGroup/" + id + "/auditlog", { headers: this.authenticationService.headers })
      .pipe(
        map((data) => this.parseResponse(data)),
        catchError(this.handleError)
      );
  }

  getAuditLogByMaintenance(id: string): Observable<AuditLog[]> {
    console.log("getting auditlog from service");
    return this.http
      .get(this.urlBase + "maintenance/" + id + "/auditlog", { headers: this.authenticationService.headers })
      .pipe(
        map((data) => this.parseResponse(data)),
        catchError(this.handleError)
      );
  }

  getAuditLogByMaintenanceType(id: string): Observable<AuditLog[]> {
    console.log("getting auditlog from service");
    return this.http
      .get(this.urlBase + "maintenanceType/" + id + "/auditlog", { headers: this.authenticationService.headers })
      .pipe(
        map((data) => this.parseResponse(data)),
        catchError(this.handleError)
      );
  }

  getAuditLogByAccount(id: string): Observable<AuditLog[]> {
    console.log("getting auditlog from service");
    return this.http
      .get(this.urlBase + "account/" + id + "/auditlog", { headers: this.authenticationService.headers })
      .pipe(
        map((data) => this.parseResponse(data)),
        catchError(this.handleError)
      );
  }

  getAuditLogByReseller(id: string): Observable<AuditLog[]> {
    console.log("getting auditlog from service");
    return this.http
      .get(this.urlBase + "reseller/" + id + "/auditlog", { headers: this.authenticationService.headers })
      .pipe(
        map((data) => this.parseResponse(data)),
        catchError(this.handleError)
      );
  }

  getAuditLogByWarehouse(id: string): Observable<AuditLog[]> {
    console.log("getting auditlog from service");
    return this.http
      .get(this.urlBase + "warehouse/" + id + "/auditlog", { headers: this.authenticationService.headers })
      .pipe(
        map((data) => this.parseResponse(data)),
        catchError(this.handleError)
      );
  }

  getAuditLogByAsset(id: string): Observable<AuditLog[]> {
    console.log("getting auditlog from service");
    return this.http
      .get(this.urlBase + "asset/" + id + "/auditlog", { headers: this.authenticationService.headers })
      .pipe(
        map((data) => this.parseResponse(data)),
        catchError(this.handleError)
      );
  }

  getAuditLogByAssetGroup(id: string): Observable<AuditLog[]> {
    console.log("getting auditlog from service");
    return this.http
      .get(this.urlBase + "assetGroup/" + id + "/auditlog", { headers: this.authenticationService.headers })
      .pipe(
        map((data) => this.parseResponse(data)),
        catchError(this.handleError)
      );
  }

  getAuditLogByAssetType(id: string): Observable<AuditLog[]> {
    console.log("getting auditlog from service");
    return this.http
      .get(this.urlBase + "assetType/" + id + "/auditlog", { headers: this.authenticationService.headers })
      .pipe(
        map((data) => this.parseResponse(data)),
        catchError(this.handleError)
      );
  }

  getAuditLogByUser(id: string): Observable<AuditLog[]> {
    console.log("getting auditlog from service");
    return this.http
      .get(this.urlBase + "user/" + id + "/auditlog", { headers: this.authenticationService.headers })
      .pipe(
        map((data) => this.parseResponse(data)),
        catchError(this.handleError)
      );
  }

  getAuditLogByCustomer(id: string): Observable<AuditLog[]> {
    console.log("getting auditlog from service");
    return this.http
      .get(this.urlBase + "customer/" + id + "/auditlog", { headers: this.authenticationService.headers })
      .pipe(
        map((data) => this.parseResponse(data)),
        catchError(this.handleError)
      );
  }

  private handleError(error: Response) {
    return throwError(() => error);
  }

  parseResponse(json: any): AuditLog[] {
    this.loggingService.log(this.constructor.name, "Retrieved " + json.length + " Logitems.");

    const auditlogs: AuditLog[] = [];

    json.forEach((item) => {
      const log = this.parseResponseDetails(item);
      auditlogs.push(log);
    });

    this.timestamp = new Date().getTime();
    return auditlogs;
  }

  parseResponseDetails(item, isDetailsView = false) {
    const log = new AuditLog();
    log.id = item.id;
    log.logType = item.logType;
    log.timestamp = Moment.utc(item.createdDate)["tz"](this.timezoneIana);
    log.createdDate = Moment.utc(item.createdDate)["tz"](this.timezoneIana);
    log.description = item.description;
    log.delta = item.delta;
    if (item.delta) {
      log.deltaItems = JSON.parse(item.delta);
    }
    log.user = item.userName;
    log.userId = item.userId;

    if (item.device) {
      const device = new Device();
      device.name = item.device.name;
      device.id = item.device.id;
      log.device = device;
    }

    log.accountId = item.accountId;
    log.companyName = item.companyName;

    log.entityId = item.entityId;
    log.entityType = item.entityType;

    log.link = generateEntityDetailLink(item.entityType, item.entityId);

    return log;
  }
}
