(function(window, document, undefined) {
    "use strict";
    L.NumberMarker = {};
    L.NumberMarker.version = "1.0.1";
    L.NumberMarker.Icon = L.Icon.extend({
        options: {
            iconSize: [ 24, 24 ],
            iconAnchor: [ 12, 12 ],
            popupAnchor: [ 0, 0 ],
            shadowAnchor: [ 0, 0 ],
            shadowSize: [ 0, 0 ],
            className: "m360",
            prefix: "",
            extraClasses: "",
            shape: "circle",
            icon: "",
            innerHTML: "",
            color: "#fff",
			backgroundColor: "#000",
            number: ""
        },
        initialize: function(options) {
            options = L.Util.setOptions(this, options);
        },
		createIcon: function () {
			var div = document.createElement('div');
            div.style.backgroundColor = this.options['backgroundColor'];
            div.style.opacity = 0.8;

			var numdiv = document.createElement('div');
			numdiv.setAttribute ( "class", "markerNumber" );
			numdiv.innerHTML = this.options['number'] || '';
			numdiv.style.color = this.options['color'];
			
			div.appendChild ( numdiv );
			this._setIconStyles(div, 'icon');
			return div;
		},
    });
    L.NumberMarker.icon = function(options) {
        alert('test');
        return new L.NumberMarker.Icon(options);
    };
})(window, document);