import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { generateEntityDetailLink } from "app/common/globals";
import { TagSearch } from "app/models/tag.model";
import { TagService } from "app/services/tag/tag.service";

@Component({
  selector: "fh-taglist",
  templateUrl: "taglist.component.html",
})
export class TagListViewComponent implements OnDestroy, OnInit {
  sub: any;
  searchFor: any;
  tags: TagSearch[] = [];
  loading = false;

  constructor(private tagService: TagService, private router: Router, private route: ActivatedRoute) {}

  ngOnDestroy(): void {}

  ngOnInit(): void {
    this.sub = this.route.params.subscribe((params) => {
      const id = params["id"];
      this.searchFor = id;
      this.loading = true;
      this.tagService.getObjectsByName(id).subscribe((result) => {
        this.tags = result;
        this.loading = false;
      });
    });
  }

  goTo(tag: TagSearch) {
    let path = "";

    path = generateEntityDetailLink(tag.objectType, tag.objectId).replace("/#/", "/");

    this.router.navigate([path]);
  }
}
