import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable, catchError, map } from "rxjs";
import { AuthenticationService } from "../authentication/authentication.service";
import { LoggingService } from "../logging/logging.service";
import { throwError } from "rxjs/internal/observable/throwError";
import { ReportSubscription } from "app/models/reporting.model";
import { TranslateService } from "@ngx-translate/core";

// Moment timezone
import Moment from "moment-timezone";

window["moment"] = Moment;

@Injectable()
export class ReportService {
  url = "";
  importUrl = "";
  timezoneIana: string;

  constructor(
    private http: HttpClient,
    private loggingService: LoggingService,
    private authenticationService: AuthenticationService,
    private translate: TranslateService
  ) {
    this.url = this.authenticationService.getWebserviceURL("report");
    this.timezoneIana = this.authenticationService.getTimeZoneIana();
  }

  getPagingUrl(start, end, key): string {
    return this.url + "Paging?startRange=" + start.unix() + "&endRange=" + end.unix() + "&rnd=" + key;
  }

  getSubscriptionPagingUrl(start, end, key): string {
    return this.url + "Subscription/Paging?rnd=" + key;
  }

  createReportSubscription(
    accountId: number,
    selectedReportCategory: number,
    selectedReportType: number,
    reportDetails
  ): Observable<any> {
    return this.http
      .post(
        this.url +
          `?accountId=${accountId}&selectedReportTypeId=${selectedReportCategory}&selectedReportId=${selectedReportType}`,
        reportDetails,
        { headers: this.authenticationService.headers }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  updateReportSubscription(id, reportDetails): Observable<any> {
    console.log("Updating subscription");
    return this.http
      .put(this.url + "Subscription/" + id, reportDetails, { headers: this.authenticationService.headers })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  archiveReportSubscription(id, reportDetails): Observable<any> {
    console.log("Updating archive of subscription");
    return this.http
      .put(this.url + "Subscription/" + id + "/Archive", reportDetails, { headers: this.authenticationService.headers })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  saveReportSubscription(reportSubscription: ReportSubscription): Observable<any> {
    return this.http.post(this.url, reportSubscription, { headers: this.authenticationService.headers }).pipe(
      map((data) => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  getReportById(id: string, shared = false): Observable<any> {
    let headers;
    if (shared) {
      headers = this.authenticationService.shareheaders;
    } else {
      headers = this.authenticationService.headers;
    }

    console.log("Fetch report by id " + id);
    return this.http.get(this.url + id + `?shared=${shared}`, { headers: headers }).pipe(
      map((data: any) => {
        var isSuccessful = data["isSuccessful"];
        var isCompleted = data["isCompleted"];
        var executionCompletedTimestamp = data["executionCompletedTimestamp"]
          ? Moment.utc(data["executionCompletedTimestamp"])["tz"](this.timezoneIana)
          : null;
        var executionStartedTimestamp = data["executionStartedTimestamp"]
          ? Moment.utc(data["executionStartedTimestamp"])["tz"](this.timezoneIana)
          : null;

        var reportStatus = 0;
        const compareDate = Moment.utc()["tz"](this.timezoneIana).add(-3, "hours");

        if (isCompleted && isSuccessful) {
          reportStatus = 1;
        } else if (isCompleted && !isSuccessful) {
          reportStatus = 2;
        } else if (!isCompleted && !executionCompletedTimestamp && executionStartedTimestamp < compareDate) {
          reportStatus = 3;
        } else if (!isCompleted && !executionCompletedTimestamp && executionStartedTimestamp >= compareDate) {
          reportStatus = 4;
        }

        data.reportStatus = reportStatus;
        return data;
      }),
      catchError(this.handleError)
    );
  }

  getReportDetailsById(id: string, accountIdentifier: string, shared = false): Observable<any> {
    let headers;
    if (shared) {
      headers = this.authenticationService.shareheaders;
    } else {
      headers = this.authenticationService.headers;
    }

    console.log("Fetch report details by id " + id);
    return this.http
      .get(this.url + id + "/details/" + accountIdentifier + `?shared=${shared}`, { headers: headers })
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  getExpensiveReports(): Observable<any> {
    return this.http.get(this.url + "Expensive", { headers: this.authenticationService.headers }).pipe(
      map((data) => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  getRecentReports(): Observable<any> {
    return this.http.get(this.url + "Recent", { headers: this.authenticationService.headers }).pipe(
      map((data) => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  getScheduledReports(): Observable<any> {
    return this.http.get(this.url + "Scheduled", { headers: this.authenticationService.headers }).pipe(
      map((data) => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  getReportColumnsByReportType(id: number = null): Observable<any> {
    console.log("Fetch report columns by id " + id);

    return this.http
      .get(this.url + "AvailableColumns" + (id ? `/${id}` : ""), { headers: this.authenticationService.headers })
      .pipe(
        map((data: any) => {
          var columns = Object.values(data);
          columns.forEach((item: any) => {
            item.template?.columnConfiguration?.forEach((column) => {
              if (column.name?.indexOf("lt.reporting") > -1) {
                column.name = this.translate.instant(column.name);
              }
            });
          });

          return columns;
        }),
        catchError(this.handleError)
      );
  }

  saveReportTemplate(reportTemplate): Observable<any> {
    console.log("Saving report template with properties", reportTemplate);

    return this.http
      .post(this.url + "ReportTemplate", reportTemplate, { headers: this.authenticationService.headers })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  updateReportTemplate(id, template): Observable<any> {
    console.log("Updating report template with properties", template);

    return this.http
      .put(this.url + "ReportTemplate/" + id, template, { headers: this.authenticationService.headers })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  getReportTemplates(): Observable<any> {
    return this.http.get(this.url + "ReportTemplate", { headers: this.authenticationService.headers }).pipe(
      map((data: any) => {
        var columns = Object.values(data);
        columns.forEach((item: any) => {
          item?.columnConfiguration?.forEach((column) => {
            if (column.name?.indexOf("lt.reporting") > -1) {
              column.name = this.translate.instant(column.name);
            }
          });
        });

        return columns;
      }),
      catchError(this.handleError)
    );
  }

  deleteReportTemplate(id): Observable<any> {
    return this.http.delete(this.url + "ReportTemplate/" + id, { headers: this.authenticationService.headers }).pipe(
      map((data) => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  getReportSubscriptionById(id: string): Observable<any> {
    console.log("Fetch report subscription by id " + id);
    return this.http.get(this.url + "Subscription/" + id, { headers: this.authenticationService.headers }).pipe(
      map((data) => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  getReportSubscriptionExecutionsById(id: string): Observable<any> {
    const that = this;
    console.log("Fetch report execution by id " + id);
    return this.http
      .get(this.url + "Subscription/" + id + "/Executions", { headers: this.authenticationService.headers })
      .pipe(
        map((data: any) => {
          data.forEach((report) => {
            var isSuccessful = report["isSuccessful"];
            var isCompleted = report["isCompleted"];
            var executionCompletedTimestamp = report["executionCompletedTimestamp"]
              ? Moment.utc(report["executionCompletedTimestamp"])["tz"](this.timezoneIana)
              : null;
            var executionStartedTimestamp = report["executionStartedTimestamp"]
              ? Moment.utc(report["executionStartedTimestamp"])["tz"](this.timezoneIana)
              : null;

            var reportStatus = 0;
            const compareDate = Moment.utc()["tz"](this.timezoneIana).add(-3, "hours");

            if (isCompleted && isSuccessful) {
              reportStatus = 1;
            } else if (isCompleted && !isSuccessful) {
              reportStatus = 2;
            } else if (!isCompleted && !executionCompletedTimestamp && executionStartedTimestamp < compareDate) {
              reportStatus = 3;
            } else if (!isCompleted && !executionCompletedTimestamp && executionStartedTimestamp >= compareDate) {
              reportStatus = 4;
            }

            report.reportStatus = reportStatus;
          });
          return data;
        }),
        catchError(this.handleError)
      );
  }

  deleteReportById(report): Observable<any> {
    console.log("delete report", report);

    return this.http
      .delete(this.url + report.id + `?reportSubscriptionId=${report.reportSubscriptionId}`, {
        headers: this.authenticationService.headers,
      })
      .pipe(catchError(this.handleError));
  }

  deleteReportSubscriptionById(subscription): Observable<any> {
    console.log("delete subscription", subscription);

    return this.http
      .delete(this.url + "Subscription/" + subscription.id, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  rerunReport(subscription): Observable<any> {
    return this.http
      .post(this.url + "Subscription/" + subscription.reportSubscriptionId, subscription, {
        headers: this.authenticationService.headers,
      })
      .pipe(catchError(this.handleError));
  }

  private handleError(error: Response) {
    return throwError(() => error);
  }
}
