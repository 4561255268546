import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable, catchError, map } from "rxjs";
import { AuthenticationService } from "../authentication/authentication.service";
import { LoggingService } from "../logging/logging.service";
import { TranslateService } from "@ngx-translate/core";
import { DriverGroup, GroupItem, DriverGroupItem } from "app/models/group.model";
import { throwError } from "rxjs/internal/observable/throwError";
import { localizeSystemGroupNames } from "app/common/globals";

// Moment timezone
import Moment from "moment-timezone";
window["moment"] = Moment;

@Injectable()
export class DriverGroupsService {
  url = "";
  base_url = "";
  inventoryUrl = "";
  timezoneIana: string;

  constructor(
    private http: HttpClient,
    private loggingService: LoggingService,
    private authenticationService: AuthenticationService,
    private translateService: TranslateService
  ) {
    this.url = this.authenticationService.getWebserviceURL("driverGroup");
    this.timezoneIana = this.authenticationService.getTimeZoneIana();
  }

  getPagingUrl() {
    return this.url + "Paging";
  }

  getDriverGroups(accountId?: number, includingItems = false): Observable<DriverGroup[]> {
    return this.http
      .get(this.url + (accountId ? `?accountId=${accountId}&includingItems=${includingItems}` : ""), {
        headers: this.authenticationService.headers,
      })
      .pipe(
        map((data) => {
          return this.parseResponse(data);
        }),
        catchError(this.handleError)
      );
  }

  getDriverGroupById(id: string): Observable<DriverGroup> {
    console.log("Fetch drivergroup by id " + id);
    return this.http.get(this.url + id, { headers: this.authenticationService.headers }).pipe(
      map((data) => {
        return this.parseReponseDetails(data);
      }),
      catchError(this.handleError)
    );
  }

  getDriverGroupItemsById(driverId: number, driverGroupId: number): Observable<GroupItem[]> {
    console.log("Fetch drivergroupitems by id " + driverId + "/group/" + driverGroupId);
    return this.http
      .get(this.url + driverId + "/group/" + driverGroupId, { headers: this.authenticationService.headers })
      .pipe(
        map((data) => {
          return this.parseItemResponse(data);
        }),
        catchError(this.handleError)
      );
  }

  getUserItemsById(id: string): Observable<any> {
    return this.http.get(this.url + id + "/users", { headers: this.authenticationService.headers }).pipe(
      map((data: any[]) => {
        data.forEach((item) => {
          item["name"] = item["emailLoginName"] + (item["firstName"] ? ` (${item["firstName"]})` : "");
        });
        return data;
      }),
      catchError(this.handleError)
    );
  }

  saveDriverGroup(driverGroup: DriverGroup): Observable<any> {
    console.log("save drivergroup", driverGroup);
    return this.http
      .post(this.url, driverGroup, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  updateDriverGroup(driverGroup: DriverGroup): Observable<any> {
    console.log("update drivergroup", driverGroup);

    return this.http
      .put(this.url + driverGroup.id, driverGroup, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  deleteDriverGroup(driverGroup: DriverGroup): Observable<any> {
    console.log("update drivergroup", driverGroup);

    return this.http
      .delete(this.url + driverGroup.id, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  private handleError(error: Response) {
    return throwError(() => error);
  }

  parseResponse(json: any): DriverGroup[] {
    this.loggingService.log(this.constructor.name, "Retrieved " + json.length + " DriverGroups.");

    const driverGroups: DriverGroup[] = [];

    json.forEach((item) => {
      const group = this.parseReponseDetails(item);
      driverGroups.push(group);
    });

    return driverGroups;
  }

  parseReponseDetails(item, localize = true) {
    const driverGroup = new DriverGroup();
    driverGroup.id = item.id;
    driverGroup.name = item.name;
    driverGroup.displayName = item.name;
    driverGroup.firstName = item.firstName;
    driverGroup.lastName = item.lastName;
    driverGroup.userId = item.userId;
    driverGroup.timestamp =
      item.timestamp !== undefined ? Moment.utc(item.timestamp)["tz"](this.timezoneIana) : undefined;
    driverGroup.itemCount = item.itemCount;
    driverGroup.accountId = item.accountId;
    driverGroup.companyName = item.companyName;
    driverGroup.resellerId = item.resellerId;
    driverGroup.resellerDescription = item.resellerDescription;

    // Private Fleet
    driverGroup.parentGroupId = item.parentGroupId;
    driverGroup.parentGroupName = item.parentGroupName;
    driverGroup.groupType = item.groupType;

    driverGroup.displayName = localizeSystemGroupNames(driverGroup.name, this.translateService);

    if (item.driverGroupItems) {
      driverGroup.driverGroupItems = [];

      item.driverGroupItems.forEach((driver) => {
        const driverGroupItem = {
          id: driver.id,
          driverGroupId: item.id,
          driverGroupName: item.name,
          driverName: driver.driverName,
        };

        driverGroup.driverGroupItems.push(driverGroupItem);
      });
    }

    return driverGroup;
  }

  parseItemResponse(json: any): GroupItem[] {
    this.loggingService.log(this.constructor.name, "Retrieved " + json.length + " DriverGroupItems.");

    const driverGroupItems: GroupItem[] = [];

    json.forEach((item) => {
      const driver = this.parseItemReponseDetails(item);
      driverGroupItems.push(driver);
    });

    return driverGroupItems;
  }

  parseItemReponseDetails(item) {
    const driverGroupItem = new DriverGroupItem();
    driverGroupItem.id = item.id;
    driverGroupItem.name = item.name;
    driverGroupItem.companyName = item.companyName;
    driverGroupItem.accountId = item.accountId;
    driverGroupItem.timestamp =
      item.timestamp !== undefined ? Moment.utc(item.timestamp)["tz"](this.timezoneIana) : undefined;
    driverGroupItem.startDate =
      item.startDate !== undefined ? Moment.utc(item.startDate)["tz"](this.timezoneIana) : undefined;
    driverGroupItem.endDate =
      item.endDate !== undefined ? Moment.utc(item.endDate)["tz"](this.timezoneIana) : undefined;
    driverGroupItem.displayName = localizeSystemGroupNames(driverGroupItem.name, this.translateService);

    return driverGroupItem;
  }
}
