import { trigger, state, style, transition, group, animate } from "@angular/animations";

export const slideInOut = trigger("slideInOut", [
  state("in", style({ height: "*", opacity: 0.5 })),
  transition(":leave", [
    style({ height: "*", opacity: 1 }),
    group([
      animate(300, style({ height: 0 })),
      animate("200ms ease-in-out", style({ opacity: "0.5" })),
      animate("200ms ease-in", style({ transform: "translateY(-100%)" })),
    ]),
  ]),
  transition(":enter", [
    style({ height: "0", opacity: 0.5 }),
    group([
      animate(300, style({ height: "*" })),
      style({ transform: "translateY(-100%)" }),
      animate("400ms ease-in-out", style({ opacity: "1" })),
      animate("200ms ease-in", style({ transform: "translateY(0%)" })),
    ]),
  ]),
]);
