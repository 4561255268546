<div class="alert shadow" [ngClass]="messageStatus" *ngIf="isMessage" (click)="isMessage = undefined">
    {{ updateMessage }}
</div>

<div class="ibox" *ngIf="!hideTags">
    <div class="ibox-title">
        <h5><i class="fas fa-fw fa-tags"></i> {{ 'general.tags' | translate}}</h5>
    </div>
    <div class="ibox-content" style="position: relative">
        <ngx-loading [show]="loading"></ngx-loading>
        <tag-input
            [(ngModel)]="items"
            class="taginput"
            theme="bootstrap"
            placeholder="+"
            (onAdd)="onItemAdded($event)"
            [identifyBy]="'id'"
            [displayBy]="'display'"
            [editable]="true"
            (onFocus)="onInputFocused($event)"
            (onSelect)="onSelected($event)"
            (onRemove)="onItemRemoved($event)"
            (onTagEdited)="onTagEdited($event)"
        >
            <tag-input-dropdown [autocompleteObservable]="requestAutocompleteItems" [appendToBody]="false">
            </tag-input-dropdown>
        </tag-input>
        <div style="clear: both"></div>
    </div>
</div>
