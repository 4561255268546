<div class="ibox-content slick" style="position: relative">
    <ngx-loading [show]="loading"></ngx-loading>

    <table style="width: 100%" class="table table-fixed nowrap dtr-inline no-footer">
        <tbody>
            <tr *ngFor="let setting of settings">
                <td class="row_opening">
                    {{ "enums.notificationType." + setting.notificationType | translate }}
                    <span *ngIf="setting.name.indexOf('Driver') === -1">
                        <span class="error" *ngIf="localVar.invalid">*</span>
                    </span>
                </td>
                <td class="row_content" style="width: 30px">
                    <div class="fa_checkbox">
                        <input
                            [checked]="true"
                            [disabled]="formMode == 1"
                            [id]="'checked_' + setting.notificationType"
                            type="checkbox"
                            [name]="'checked_' + setting.notificationType"
                            [(ngModel)]="setting.enabled"
                        />
                        <label [for]="'checked_' + setting.notificationType"> </label>
                    </div>
                </td>
                <td class="row_content" style="position: relative; overflow: visible">
                    {{ settings.enabled }}
                    <fh-user-contact-group-input
                        [hidden]="setting.name.indexOf('Driver') > -1"
                        #localVar="ngModel"
                        [id]="'userGroups_' + setting.notificationType"
                        [name]="'userGroups_' + setting.notificationType"
                        [multiple]="false"
                        [required]="setting.enabled && setting.name.indexOf('Driver') === -1"
                        [selectedAccountId]="accountId"
                        [readonly]="formMode == 1 || !setting.enabled"
                        placeholder="{{
                            (accountId ? 'general.chooseUserContactGroup' : 'general.firstChooseAccount') | translate
                        }}"
                        [(ngModel)]="setting.userContactGroupId"
                    >
                    </fh-user-contact-group-input>
                </td>
            </tr>
        </tbody>
    </table>
</div>
