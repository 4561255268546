import { Component } from "@angular/core";
import { AuthenticationService } from "app/services/authentication/authentication.service";
import { StateService } from "app/services/state/state.service";

@Component({
  selector: "fh-settings-state",
  templateUrl: "stateViewer.template.html",
  styleUrls: ["stateViewer.template.css"],
})
export class SettingStateViewerComponent {
  accountId;
  component;
  deviceId;
  loading = false;

  jsonObjectSource = {};
  jsonObject = {};
  objects = [];
  error: any;

  filter;

  constructor(protected authenticationService: AuthenticationService, private stateService: StateService) {
    this.accountId = this.authenticationService.getAccountId();
  }

  getState() {
    this.loading = true;

    if (this.component !== 2) {
      this.deviceId = null;
    }

    this.stateService.getState(this.component, this.deviceId).subscribe({
      next: (result) => {
        this.jsonObjectSource = result;
        this.jsonObject = result;
        this.loading = false;
      },
      error: (error) => {
        this.error = error;
        this.loading = false;
      },
    });
  }

  search() {
    const key = this.filter;

    if (key) {
      this.objects = [];
      this.jsonObject = this.findObject(this.jsonObjectSource, key);
      this.jsonObject = this.objects;
    } else {
      this.jsonObject = this.jsonObjectSource;
    }
  }

  findObject(obj, searchTerm) {
    const values = Object.values(obj);

    for (const value of values) {
      if (value == null) {
        return false;
      }
      if (typeof value === "string") {
        // if value i a string, check if it matches!
        if (value.toString().includes(searchTerm)) {
          this.objects.push(values);
        }
      } else if (typeof value === "number") {
        // if value i a string, check if it matches!
        if (value.toString().includes(searchTerm.toString())) {
          this.objects.push(values);
        }
      } else if (typeof value === "object") {
        // use recursion to check if nested object contains the search term
        const isMatch = this.findObject(value, searchTerm);
      }
    }

    // no match, so return false
    return false;
  }
}
