import { Component, OnDestroy, OnInit } from "@angular/core";

@Component({
  selector: "fh-search",
  templateUrl: "search.component.html",
})
export class SearchViewComponent implements OnDestroy, OnInit {
  searchString = "";

  constructor() {}

  ngOnDestroy(): void {}

  ngOnInit(): void {}
}
