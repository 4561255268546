import { HttpClient } from "@angular/common/http";
import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Device } from "app/models/device.model";
import { AuditLog } from "../../models/auditlog.model";
import { DeviceService } from "../../services/device/device.service";
import { TranslateService } from "@ngx-translate/core";
import { ProjectChooserComponent } from "../shared/usercontrols/projectChooser.component";
import { AuthenticationService } from "app/services/authentication/authentication.service";
import { AssetService } from "app/services/asset/asset.service";
import { fadeInOnEnterAnimation } from "angular-animations";
import { ConfirmationModalComponent } from "../shared/usercontrols/confirmationModal.component";
import { AssignmentModalComponent } from "../shared/usercontrols/assignmentModal.component";

@Component({
  selector: "fh-device-projects",
  templateUrl: "projects.template.html",
  animations: [fadeInOnEnterAnimation({ anchor: "enter" })],
})
export class DeviceProjectsViewComponent implements OnInit {
  @ViewChild("projectChooser") projectChooser: ProjectChooserComponent;
  @ViewChild("deleteModal") deleteModal: ConfirmationModalComponent;

  @ViewChild("assignmentModal", { static: false }) assignmentModal: ConfirmationModalComponent;
  @ViewChild("assignmentBody", { static: false }) assignmentBody: AssignmentModalComponent;
  selectedProjectAssignment;

  deviceLog: AuditLog[];
  sub;
  device: Device;
  deviceId;

  loading = false;
  hasProjectTagsEnabled = false;
  loadingProjectTagAssignments = false;

  error: any;
  success: any;
  warning: any;

  projects = [];
  projectTagAssignments = [];

  permissionName = "Projects_View";
  permissions: {};

  currentPage: number;
  filter: string;
  itemsPerPage = 10;

  constructor(
    private http: HttpClient,
    private assetService: AssetService,
    private authenticationService: AuthenticationService,
    private deviceService: DeviceService,
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService
  ) {
    this.permissions = this.authenticationService.permissions;
    this.device = null;
    this.deviceLog = [];
  }

  ngOnInit() {
    this.loading = true;
    this.device = new Device();
    this.device.id = "";

    this.sub = this.route.params.subscribe({
      next: (params) => {
        const id = params["id"];

        this.deviceId = id;
        this.deviceService.getDeviceById(id).subscribe((device) => {
          this.device = device;

          if (this.device == null) {
            this.router.navigate(["/Devices/Overview"]);
          }

          if (this.device.asset?.id == null) {
            this.warning = {
              statusText: "Warning",
              warning: "There is no asset activated to this device. Projects can not be configured!",
            };
            this.loading = false;
          } else {
            this.getProjectInfo(id);
          }
        });
      },
      error: (error) => {
        this.error = error;
        this.error.statusText = "Error fetching device";

        setTimeout(() => {
          this.router.navigate(["/Devices/Overview"]);
        }, 3000);
      },
    });
  }

  getProjectInfo(id = this.device.id, func?: Function) {
    this.deviceService.getProjectsById(id).subscribe({
      next: (projects) => {
        this.projects = projects;
        this.loading = false;
      },
      error: (error) => {
        this.loading = false;
        this.success = null;
        this.error = error;
      },
    });
  }

  deleteAssignment(assignment) {
    this.deleteModal.hideModal();

    this.loading = true;

    const deleteAssignProject = {
      AssetId: assignment.assetId,
      AssetProjectAssignmentId: assignment.id,
    };

    this.assetService.deleteProjectAssignment(assignment.assetId, deleteAssignProject).subscribe({
      next: (task: { error: boolean; isSuccess: boolean; message: any }) => {
        if (task?.isSuccess) {
          this.error = null;

          if (task.message?.startsWith("You can only")) {
            this.success = {
              statusText: "Warning",
              success: task.message,
            };
          } else {
            this.success = {
              statusText: "Success",
              success: task.message,
            };
          }
        } else {
          this.error = task?.message ?? task?.error;
        }
        this.getProjectInfo(undefined);
      },
      error: (error) => {
        this.loading = false;
        this.success = null;
        this.error = error;
      },
    });
  }

  showEndAssignment(project) {
    this.selectedProjectAssignment = project;
    this.assignmentModal.showModal(project);
  }

  endAssignment(assignment) {
    this.assignmentModal.hideModal();
    this.loading = true;

    const stopAssignProject = {
      AssetId: assignment.assetId,
      AssetProjectAssignmentId: this.selectedProjectAssignment.id,
      EndDate: +this.assignmentBody.archiveDate / 1000,
    };

    this.assetService.stopProjectAssignment(assignment.assetId, stopAssignProject).subscribe({
      next: (task: { error: boolean; isSuccess: boolean; message: any }) => {
        if (task?.isSuccess) {
          this.error = null;

          if (task.message?.startsWith("You can only")) {
            this.success = {
              statusText: "Warning",
              success: task.message,
            };
          } else {
            this.success = {
              statusText: "Success",
              success: task.message,
            };
          }
        } else {
          this.error = task?.message ?? task?.error;
        }
        this.getProjectInfo(undefined, () => (this.loading = false));
      },
      error: (error) => {
        this.loading = false;
        this.success = null;
        this.error = error;
      },
    });
  }

  onSelect(event): void {
    this.loading = true;

    const assignProject = {
      ProjectId: event.id,
      AssetId: this.device.asset?.id,
      StartDate: +event.from / 1000,
      EndDate: event.to ? +event.to / 1000 : null,
    };

    this.assetService.assignProject(this.device.asset?.id, assignProject).subscribe({
      next: (task: { error: boolean; isSuccess: boolean; message: any }) => {
        if (task?.isSuccess) {
          this.error = null;

          if (task.message?.startsWith("You can only")) {
            this.success = {
              statusText: "Warning",
              success: task.message,
            };
          } else {
            this.success = {
              statusText: "Success",
              success: task.message,
            };
          }
        } else {
          this.error = task?.message ?? task?.error;
        }
        this.getProjectInfo(undefined);
      },
      error: (error) => {
        this.loading = false;
        this.success = null;
        this.error = error;
      },
    });
  }

  showDelete(assignment) {
    this.deleteModal.showModal(assignment);
  }
}
