<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/"> <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item" *ngIf="device?.asset && device?.asset.accountId">
                    <a href="/#/AccountDetails/Index/{{device?.asset.accountId}}">{{device?.asset?.companyName}}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Devices/Overview?companyName={{device?.companyName}}"
                        >{{'general.devices' | translate }}</a
                    >
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/DeviceDetails/Index/{{device?.id}}"
                        >{{device?.asset?.name ? device?.asset?.name : device?.name}}</a
                    >
                </li>
                <li class="breadcrumb-item active">{{'menu.utilization' | translate }}</li>
            </ol>
        </nav>
    </div>
    <fh-device-details-navigation [deviceId]="deviceId" [device]="device"></fh-device-details-navigation>
</div>

<div class="wrapper wrapper-content">
    <div class="row" data-equalizer="">
        <div class="col-lg-12">
            <fh-error [error]="error" [warning]="warning" [success]="success" [autoRemove]="false"></fh-error>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <div class="ibox">
                <div class="ibox-title">
                    <h5><i class="fas fa-fw fa-filter"></i> {{'general.filter' | translate }}</h5>
                </div>
                <div class="ibox-content slick_toppadding" style="position: relative">
                    <div class="col-lg-12">
                        <div class="form-group input-group" style="margin-bottom: 5px">
                            <span class="input-group-prepend">
                                <i class="fa fa-fw fa-calendar"></i>
                            </span>
                            <input
                                placeholder="Daterangepicker"
                                style="width: 100%"
                                placeholder="Datepicker"
                                type="text"
                                [bsConfig]="dpConfig"
                                class="form-control"
                                bsDaterangepicker
                                [maxDate]="maxDate"
                                (onHidden)="dateChanged('hidden')"
                                [(ngModel)]="daterangepickerModel"
                            />
                            <span class="input-group-calendar-datatables">
                                <i class="fa fa-calendar-alt"></i>
                            </span>
                        </div>
                    </div>
                    <div style="clear: both"></div>
                </div>
            </div>

            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5><i class="fas fa-fw fa-engine"></i> {{'general.utilization' | translate }}</h5>
                </div>
                <div *ngIf="!utilization" class="ibox-content" style="position: relative">
                    <ngx-loading [show]="loading || loadingUtilization"></ngx-loading>
                </div>
                <div *ngIf="utilization" class="ibox-content slick" style="position: relative">
                    <ngx-loading [show]="loading || loadingUtilization"></ngx-loading>
                    <table style="width: 100%" class="table table-fixed nowrap dtr-inline no-footer">
                        <tbody>
                            <tr>
                                <td class="row_opening_small">{{ 'general.trips' | translate}}</td>
                                <td class="row_content">
                                    {{ utilization?.tripCount ? actualRound(utilization?.tripCount,0).toLocaleString() :
                                    0 }}
                                </td>
                                <td class="row_opening_small">{{ 'general.distance' | translate}}</td>
                                <td class="row_content">
                                    {{ utilization?.segmentsDistance ?
                                    actualRound(utilization?.segmentsDistance,0).toLocaleString() : 0 }} KM
                                </td>
                            </tr>
                            <tr>
                                <td class="row_opening_small">{{ 'general.distanceBusiness' | translate}}</td>
                                <td class="row_content">
                                    {{ utilization?.segmentsDistanceBusiness ?
                                    actualRound(utilization?.segmentsDistanceBusiness,0).toLocaleString() : 0 }} KM
                                </td>
                                <td class="row_opening_small">{{ 'general.distancePrivate' | translate}}</td>
                                <td class="row_content">
                                    {{ utilization?.segmentsDistancePrivate ?
                                    actualRound(utilization?.segmentsDistancePrivate,0).toLocaleString() : 0 }} KM
                                </td>
                            </tr>
                            <tr>
                                <td class="row_opening_small">{{ 'general.workingHours' | translate}}</td>
                                <td class="row_content">
                                    {{ utilization?.workingHoursInSeconds ?
                                    actualRoundSeconds((utilization?.workingHoursInSeconds)).toLocaleString() : 0 }}
                                </td>
                                <td class="row_opening_small">{{ 'general.pureDriving' | translate}}</td>
                                <td class="row_content">
                                    {{ utilization?.pureDrivingDurationInSeconds ?
                                    actualRoundSeconds((utilization?.pureDrivingDurationInSeconds)).toLocaleString() : 0
                                    }}
                                </td>
                            </tr>
                            <tr>
                                <td class="row_opening_small">{{ 'general.idling' | translate}}</td>
                                <td class="row_content">
                                    {{ utilization?.idlingDurationInSeconds ?
                                    actualRoundSeconds((utilization?.idlingDurationInSeconds)).toLocaleString() : 0 }}
                                </td>
                                <td class="row_opening_small">{{ 'general.efficiency' | translate}}</td>
                                <td class="row_content">
                                    {{ utilization?.efficiency ? actualRound(utilization?.efficiency,2).toLocaleString()
                                    + ' %' : 0 }}
                                </td>
                            </tr>
                            <tr>
                                <td class="row_opening_small">{{ 'general.avgKmh' | translate}}</td>
                                <td class="row_content">
                                    {{ utilization?.avgKmh ? actualRound(utilization?.avgKmh,0).toLocaleString() + ' ' +
                                    translatedKm : 0 }}
                                </td>
                                <td class="row_opening_small">{{ 'general.maxSpeed' | translate}}</td>
                                <td class="row_content">
                                    {{ utilization?.maxSpeed ? actualRound(utilization?.maxSpeed,0).toLocaleString() + '
                                    ' + translatedKmh : 0 }}
                                </td>
                            </tr>
                            <tr>
                                <td class="row_content" colspan="4">
                                    <span *ngFor="let group of utilization?.driverGroups">
                                        <a
                                            class="btn btn-info btn-grid"
                                            href="/#/DriverGroupDetails/Index/{{group?.id}}"
                                            ><i class="fas fa-fw fa-layer-group"></i> {{group?.name }}</a
                                        >
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="col-lg-6">
            <div class="ibox">
                <div class="ibox-title">
                    <h5><i class="fas fa-fw fa-ruler"></i> {{'general.distanceperday' | translate }}</h5>
                </div>
                <div class="ibox-content" style="position: relative">
                    <ngx-loading [show]="loadingCount"></ngx-loading>

                    <highcharts-chart
                        *ngIf="chartDistance"
                        [runOutsideAngular]="true"
                        style="display: block; width: 100%; height: 214px"
                        [Highcharts]="Highcharts"
                        [options]="chartDistance"
                    >
                    </highcharts-chart>
                </div>
            </div>

            <div class="ibox">
                <div class="ibox-title">
                    <h5><i class="fas fa-fw fa-route"></i> {{'general.trips' | translate }}</h5>
                </div>
                <div class="ibox-content" style="position: relative">
                    <ngx-loading [show]="loadingCount"></ngx-loading>

                    <highcharts-chart
                        *ngIf="chartTrips"
                        [runOutsideAngular]="true"
                        style="display: block; width: 100%; height: 214px"
                        [Highcharts]="Highcharts"
                        [options]="chartTrips"
                    >
                    </highcharts-chart>
                </div>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="ibox">
                <div class="ibox-title">
                    <h5><i class="fa fa-fw fa-comment-alt-dots"></i> {{'general.locationCount' | translate }}</h5>
                </div>
                <div class="ibox-content" style="position: relative">
                    <ngx-loading [show]="loadingCount"></ngx-loading>

                    <highcharts-chart
                        *ngIf="chartLocationCount"
                        [runOutsideAngular]="true"
                        style="display: block; width: 100%; height: 214px"
                        [Highcharts]="Highcharts"
                        [options]="chartLocationCount"
                    >
                    </highcharts-chart>
                </div>
            </div>
            <div class="ibox">
                <div class="ibox-title">
                    <h5><i class="fas fa-fw fa-car"></i> {{'general.communicatingAssets' | translate }}</h5>
                </div>
                <div class="ibox-content" style="position: relative">
                    <ngx-loading [show]="loadingCount"></ngx-loading>

                    <highcharts-chart
                        *ngIf="chartActiveDevices"
                        [runOutsideAngular]="true"
                        style="display: block; width: 100%; height: 214px"
                        [Highcharts]="Highcharts"
                        [options]="chartActiveDevices"
                    >
                    </highcharts-chart>
                </div>
            </div>
        </div>
    </div>
</div>
