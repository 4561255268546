// Global vars
import { AnalogFunctions, BatchAction, EntityType, IconColors } from "./enums";

export { MovingMarkerHelper } from "./movingMarker";

export interface ResultModel<T> {
  completed: boolean;
  data: T;
}

declare global {
  interface Navigator {
    msSaveBlob?: (blob: any, defaultName?: string) => boolean;
  }
}

// World General
export const MAX_LATITUDE = 90;
export const MAX_LONGITUDE = 180;

export const BOUND_CHECK = (input_value, min_value = 0) => min_value - Math.abs(input_value) > -1;

export const kmToMilesMultiplier = 0.621371;

export const litersToUSLiquidGallonsMultiplier = 0.264172052;

export const litersToImperialGallonsMultiplier = 0.219969;

export const outputOptionsCount = 10;

export const auxilaryOptionCount = 49;

export const canOptionCount = 8;

export function deepCopy(data: any, objMap?: WeakMap<any, any>) {
  if (!objMap) {
    // Map for handle recursive objects
    objMap = new WeakMap();
  }

  // recursion wrapper
  const deeper = (value) => {
    if (value && typeof value === "object") {
      return deepCopy(value, objMap);
    }
    return value;
  };

  // Array value
  if (Array.isArray(data)) {
    return data.map(deeper);
  }

  // Object value
  if (data && typeof data === "object") {
    // Same object seen earlier
    if (objMap.has(data)) {
      return objMap.get(data);
    }
    // Date object
    if (data instanceof Date) {
      const result = new Date(data.valueOf());
      objMap.set(data, result);
      return result;
    }
    // Use original prototype
    const node = Object.create(Object.getPrototypeOf(data));
    // Save object to map before recursion
    objMap.set(data, node);
    for (const [key, value] of Object.entries(data)) {
      node[key] = deeper(value);
    }
    return node;
  }
  // Scalar value
  return data;
}

export function roundAsString(value, decimals) {
  const no: any = value + "e" + decimals;
  return Number(Math.round(no) + "e-" + decimals).toLocaleString(undefined, {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });
}

export function roundAsNumber(value, decimals) {
  const no: any = value + "e" + decimals;
  return Number(Math.round(no) + "e-" + decimals);
}

export function roundMinutes(value, sanitize = false) {
  const sec_num = parseInt(value, 10); // don't forget the second param
  const hours = Math.floor(sec_num / 60);
  const minutes = Math.floor(sec_num - hours * 60);
  return hours.toLocaleString() + (sanitize && hours > 100 ? 0 : ":" + (minutes < 10 ? "0" : "") + minutes);
}

export function roundSeconds(value, fillHours = true, includeSeconds = true) {
  const sec_num = Math.round(value);
  const hours = Math.floor(sec_num / 3600);
  const minutes = Math.floor((sec_num - hours * 3600) / 60);
  const seconds = sec_num - hours * 3600 - minutes * 60;

  return (
    (hours < 10 ? (fillHours ? "0" : "") : "") +
    (fillHours || hours > 0 ? hours + ":" : "") +
    (minutes < 10 ? "0" : "") +
    minutes +
    (includeSeconds ? ":" + (seconds < 10 ? "0" : "") + seconds : "")
  );
}

export function localizeSystemGroupNames(systemGroupName, translateService) {
  switch (systemGroupName) {
    case "MyManagedDevices":
      return translateService.instant("general.MyManagedDevices");
    case "MyManagedDrivers":
      return translateService.instant("general.MyManagedDrivers");
    case "MyManagedGeofences":
      return translateService.instant("general.MyManagedGeofences");
    case "ManagedArchivedAssets":
      return translateService.instant("general.ManagedArchivedAssets");
    case "ArchivedAssets":
      return translateService.instant("general.ArchivedAssets");
    default:
      break;
  }

  return systemGroupName;
}

export function generateEntityDetailLink(entityType, id) {
  let path = "";

  switch (entityType) {
    case EntityType.Device:
      path = "/#/DeviceDetails/Index/";
      break;
    case EntityType.DeviceExternal:
      path = "/#/DeviceDetails/Index/";
      break;
    case EntityType.Asset:
      path = "/#/AssetDetails/Index/";
      break;
    case EntityType.AssetGroup:
      path = "/#/AssetGroupDetails/Index/";
      break;
    case EntityType.Driver:
      path = "/#/DriverDetails/Index/";
      break;
    case EntityType.DriverGroup:
      path = "/#/DriverGroupDetails/Index/";
      break;
    case EntityType.User:
      path = "/#/UserDetails/Index/";
      break;
    case EntityType.DeviceSettingChanges:
      path = "/#/DeviceDetails/Changes/";
      break;
    case EntityType.Account:
      path = "/#/AccountDetails/Index/";
      break;
    case EntityType.Geofence:
      path = "/#/GeofenceDetails/Index/";
      break;
    case EntityType.GeofenceGroup:
      path = "/#/GeofenceGroupDetails/Index/";
      break;
    case EntityType.Issue:
      path = "/#/IssueDetails/Index/";
      break;
    case EntityType.Reseller:
      path = "/#/ResellerDetails/Index/";
      break;
    case EntityType.Maintenance:
      path = "/#/MaintenanceDetails/Index/";
      break;
    case EntityType.Fuel:
      path = "/#/FuelDetails/Index/";
      break;
    case EntityType.MaintenanceType:
      path = "/#/MaintenanceTypeDetails/Index/";
      break;
    case EntityType.Trigger:
      path = "/#/Notifications/TriggerDetails/Index/";
      break;
    case EntityType.UserContact:
      path = "/#/Notifications/UserContactDetails/Index/";
      break;
    case EntityType.UserContactGroup:
      path = "/#/Notifications/UserContactGroupDetails/Index/";
      break;
    case EntityType.Trailer:
      path = "/#/TrailerDetails/Index/";
      break;
    case EntityType.UserRole:
      path = "/#/Users/UserRoleDetails/Index/";
      break;
    case EntityType.UserRole:
      path = "/#/Users/UserRoleDetails/Index/";
      break;
    case EntityType.Warehouse:
      path = "/#/WarehouseDetails/Index/";
      break;
    default:
      break;
  }
  return path + id;
}

export const colorArray = ["#FF0000", "#FFC000", "#FFFC00", "#00FF00", "#00FFFF", "#0000FF", "#000000"];

export const colorArray2 = [
  "#000000",
  "#000000",
  "#FFFF00",
  "#1CE6FF",
  "#FF34FF",
  "#FF4A46",
  "#008941",
  "#006FA6",
  "#FFDBE5",
  "#7A4900",
  "#0000A6",
  "#63FFAC",
  "#B79762",
  "#004D43",
  "#8FB0FF",
  "#997D87",
  "#A30059",
  "#5A0007",
  "#809693",
  "#1B4400",
  "#4FC601",
  "#3B5DFF",
  "#4A3B53",
  "#FF2F80",
  "#61615A",
  "#BA0900",
  "#6B7900",
  "#00C2A0",
  "#FFAA92",
  "#FF90C9",
  "#B903AA",
  "#D16100",
  "#DDEFFF",
  "#000035",
  "#7B4F4B",
  "#A1C299",
  "#300018",
  "#0AA6D8",
  "#013349",
  "#00846F",
  "#372101",
  "#FFB500",
  "#C2FFED",
  "#A079BF",
  "#CC0744",
  "#C0B9B2",
  "#C2FF99",
  "#001E09",
  "#00489C",
  "#6F0062",
  "#0CBD66",
  "#EEC3FF",
  "#456D75",
  "#B77B68",
  "#7A87A1",
  "#788D66",
  "#885578",
  "#FAD09F",
  "#FF8A9A",
  "#D157A0",
  "#BEC459",
  "#456648",
  "#0086ED",
  "#886F4C",
  "#34362D",
  "#B4A8BD",
  "#00A6AA",
  "#452C2C",
  "#636375",
  "#A3C8C9",
  "#FF913F",
  "#938A81",
  "#575329",
  "#00FECF",
  "#B05B6F",
  "#8CD0FF",
  "#3B9700",
  "#04F757",
  "#C8A1A1",
  "#1E6E00",
  "#7900D7",
  "#A77500",
  "#6367A9",
  "#A05837",
  "#6B002C",
  "#772600",
  "#D790FF",
  "#9B9700",
  "#549E79",
  "#FFF69F",
  "#201625",
  "#72418F",
  "#BC23FF",
  "#99ADC0",
  "#3A2465",
  "#922329",
  "#5B4534",
  "#FDE8DC",
  "#404E55",
  "#0089A3",
  "#CB7E98",
  "#A4E804",
  "#324E72",
  "#6A3A4C",
  "#83AB58",
  "#001C1E",
  "#D1F7CE",
  "#004B28",
  "#C8D0F6",
  "#A3A489",
  "#806C66",
  "#222800",
  "#BF5650",
  "#E83000",
  "#66796D",
  "#DA007C",
  "#FF1A59",
  "#8ADBB4",
  "#1E0200",
  "#5B4E51",
  "#C895C5",
  "#320033",
  "#FF6832",
  "#66E1D3",
  "#CFCDAC",
  "#D0AC94",
  "#7ED379",
  "#012C58",
  "#7A7BFF",
  "#D68E01",
  "#353339",
  "#78AFA1",
  "#FEB2C6",
  "#75797C",
  "#837393",
  "#943A4D",
  "#B5F4FF",
  "#D2DCD5",
  "#9556BD",
  "#6A714A",
  "#001325",
  "#02525F",
  "#0AA3F7",
  "#E98176",
  "#DBD5DD",
  "#5EBCD1",
  "#3D4F44",
  "#7E6405",
  "#02684E",
  "#962B75",
  "#8D8546",
  "#9695C5",
  "#E773CE",
  "#D86A78",
  "#3E89BE",
  "#CA834E",
  "#518A87",
  "#5B113C",
  "#55813B",
  "#E704C4",
  "#00005F",
  "#A97399",
  "#4B8160",
  "#59738A",
  "#FF5DA7",
  "#F7C9BF",
  "#643127",
  "#513A01",
  "#6B94AA",
  "#51A058",
  "#A45B02",
  "#1D1702",
  "#E20027",
  "#E7AB63",
  "#4C6001",
  "#9C6966",
  "#64547B",
  "#97979E",
  "#006A66",
  "#391406",
  "#F4D749",
  "#0045D2",
  "#006C31",
  "#DDB6D0",
  "#7C6571",
  "#9FB2A4",
  "#00D891",
  "#15A08A",
  "#BC65E9",
  "#FFFFFE",
  "#C6DC99",
  "#203B3C",
  "#671190",
  "#6B3A64",
  "#F5E1FF",
  "#FFA0F2",
  "#CCAA35",
  "#374527",
  "#8BB400",
  "#797868",
  "#C6005A",
  "#3B000A",
  "#C86240",
  "#29607C",
  "#402334",
  "#7D5A44",
  "#CCB87C",
  "#B88183",
  "#AA5199",
  "#B5D6C3",
  "#A38469",
  "#9F94F0",
  "#A74571",
  "#B894A6",
  "#71BB8C",
  "#00B433",
  "#789EC9",
  "#6D80BA",
  "#953F00",
  "#5EFF03",
  "#E4FFFC",
  "#1BE177",
  "#BCB1E5",
  "#76912F",
  "#003109",
  "#0060CD",
  "#D20096",
  "#895563",
  "#29201D",
  "#5B3213",
  "#A76F42",
  "#89412E",
  "#1A3A2A",
  "#494B5A",
  "#A88C85",
  "#F4ABAA",
  "#A3F3AB",
  "#00C6C8",
  "#EA8B66",
  "#958A9F",
  "#BDC9D2",
  "#9FA064",
  "#BE4700",
  "#658188",
  "#83A485",
  "#453C23",
  "#47675D",
  "#3A3F00",
  "#061203",
  "#DFFB71",
  "#868E7E",
  "#98D058",
  "#6C8F7D",
  "#D7BFC2",
  "#3C3E6E",
  "#D83D66",
  "#2F5D9B",
  "#6C5E46",
  "#D25B88",
  "#5B656C",
  "#00B57F",
  "#545C46",
  "#866097",
  "#365D25",
  "#252F99",
  "#00CCFF",
  "#674E60",
  "#FC009C",
  "#92896B",
  "#1E2324",
  "#DEC9B2",
  "#9D4948",
  "#85ABB4",
  "#342142",
  "#D09685",
  "#A4ACAC",
  "#00FFFF",
  "#AE9C86",
  "#742A33",
  "#0E72C5",
  "#AFD8EC",
  "#C064B9",
  "#91028C",
  "#FEEDBF",
  "#FFB789",
  "#9CB8E4",
  "#AFFFD1",
  "#2A364C",
  "#4F4A43",
  "#647095",
  "#34BBFF",
  "#807781",
  "#920003",
  "#B3A5A7",
  "#018615",
  "#F1FFC8",
  "#976F5C",
  "#FF3BC1",
  "#FF5F6B",
  "#077D84",
  "#F56D93",
  "#5771DA",
  "#4E1E2A",
  "#830055",
  "#02D346",
  "#BE452D",
  "#00905E",
  "#BE0028",
  "#6E96E3",
  "#007699",
  "#FEC96D",
  "#9C6A7D",
  "#3FA1B8",
  "#893DE3",
  "#79B4D6",
  "#7FD4D9",
  "#6751BB",
  "#B28D2D",
  "#E27A05",
  "#DD9CB8",
  "#AABC7A",
  "#980034",
  "#561A02",
  "#8F7F00",
  "#635000",
  "#CD7DAE",
  "#8A5E2D",
  "#FFB3E1",
  "#6B6466",
  "#C6D300",
  "#0100E2",
  "#88EC69",
  "#8FCCBE",
  "#21001C",
  "#511F4D",
  "#E3F6E3",
  "#FF8EB1",
  "#6B4F29",
  "#A37F46",
  "#6A5950",
  "#1F2A1A",
  "#04784D",
  "#101835",
  "#E6E0D0",
  "#FF74FE",
  "#00A45F",
  "#8F5DF8",
  "#4B0059",
  "#412F23",
  "#D8939E",
  "#DB9D72",
  "#604143",
  "#B5BACE",
  "#989EB7",
  "#D2C4DB",
  "#A587AF",
  "#77D796",
  "#7F8C94",
  "#FF9B03",
  "#555196",
  "#31DDAE",
  "#74B671",
  "#802647",
  "#2A373F",
  "#014A68",
  "#696628",
  "#4C7B6D",
  "#002C27",
  "#7A4522",
  "#3B5859",
  "#E5D381",
  "#FFF3FF",
  "#679FA0",
  "#261300",
  "#2C5742",
  "#9131AF",
  "#AF5D88",
  "#C7706A",
  "#61AB1F",
  "#8CF2D4",
  "#C5D9B8",
  "#9FFFFB",
  "#BF45CC",
  "#493941",
  "#863B60",
  "#B90076",
  "#003177",
  "#C582D2",
  "#C1B394",
  "#602B70",
  "#887868",
  "#BABFB0",
  "#030012",
  "#D1ACFE",
  "#7FDEFE",
  "#4B5C71",
  "#A3A097",
  "#E66D53",
  "#637B5D",
  "#92BEA5",
  "#00F8B3",
  "#BEDDFF",
  "#3DB5A7",
  "#DD3248",
  "#B6E4DE",
  "#427745",
  "#598C5A",
  "#B94C59",
  "#8181D5",
  "#94888B",
  "#FED6BD",
  "#536D31",
  "#6EFF92",
  "#E4E8FF",
  "#20E200",
  "#FFD0F2",
  "#4C83A1",
  "#BD7322",
  "#915C4E",
  "#8C4787",
  "#025117",
  "#A2AA45",
  "#2D1B21",
  "#A9DDB0",
  "#FF4F78",
  "#528500",
  "#009A2E",
  "#17FCE4",
  "#71555A",
  "#525D82",
  "#00195A",
  "#967874",
  "#555558",
  "#0B212C",
  "#1E202B",
  "#EFBFC4",
  "#6F9755",
  "#6F7586",
  "#501D1D",
  "#372D00",
  "#741D16",
  "#5EB393",
  "#B5B400",
  "#DD4A38",
  "#363DFF",
  "#AD6552",
  "#6635AF",
  "#836BBA",
  "#98AA7F",
  "#464836",
  "#322C3E",
  "#7CB9BA",
  "#5B6965",
  "#707D3D",
  "#7A001D",
  "#6E4636",
  "#443A38",
  "#AE81FF",
  "#489079",
  "#897334",
  "#009087",
  "#DA713C",
  "#361618",
  "#FF6F01",
  "#006679",
  "#370E77",
  "#4B3A83",
  "#C9E2E6",
  "#C44170",
  "#FF4526",
  "#73BE54",
  "#C4DF72",
  "#ADFF60",
  "#00447D",
  "#DCCEC9",
  "#BD9479",
  "#656E5B",
  "#EC5200",
  "#FF6EC2",
  "#7A617E",
  "#DDAEA2",
  "#77837F",
  "#A53327",
  "#608EFF",
  "#B599D7",
  "#A50149",
  "#4E0025",
  "#C9B1A9",
  "#03919A",
  "#1B2A25",
  "#E500F1",
  "#982E0B",
  "#B67180",
  "#E05859",
  "#006039",
  "#578F9B",
  "#305230",
  "#CE934C",
  "#B3C2BE",
  "#C0BAC0",
  "#B506D3",
  "#170C10",
  "#4C534F",
  "#224451",
  "#3E4141",
  "#78726D",
  "#B6602B",
  "#200441",
  "#DDB588",
  "#497200",
  "#C5AAB6",
  "#033C61",
  "#71B2F5",
  "#A9E088",
  "#4979B0",
  "#A2C3DF",
  "#784149",
  "#2D2B17",
  "#3E0E2F",
  "#57344C",
  "#0091BE",
  "#E451D1",
  "#4B4B6A",
  "#5C011A",
  "#7C8060",
  "#FF9491",
  "#4C325D",
  "#005C8B",
  "#E5FDA4",
  "#68D1B6",
  "#032641",
  "#140023",
  "#8683A9",
  "#CFFF00",
  "#A72C3E",
  "#34475A",
  "#B1BB9A",
  "#B4A04F",
  "#8D918E",
  "#A168A6",
  "#813D3A",
  "#425218",
  "#DA8386",
  "#776133",
  "#563930",
  "#8498AE",
  "#90C1D3",
  "#B5666B",
  "#9B585E",
  "#856465",
  "#AD7C90",
  "#E2BC00",
  "#E3AAE0",
  "#B2C2FE",
  "#FD0039",
  "#009B75",
  "#FFF46D",
  "#E87EAC",
  "#DFE3E6",
  "#848590",
  "#AA9297",
  "#83A193",
  "#577977",
  "#3E7158",
  "#C64289",
  "#EA0072",
  "#C4A8CB",
  "#55C899",
  "#E78FCF",
  "#004547",
  "#F6E2E3",
  "#966716",
  "#378FDB",
  "#435E6A",
  "#DA0004",
  "#1B000F",
  "#5B9C8F",
  "#6E2B52",
  "#011115",
  "#E3E8C4",
  "#AE3B85",
  "#EA1CA9",
  "#FF9E6B",
  "#457D8B",
  "#92678B",
  "#00CDBB",
  "#9CCC04",
  "#002E38",
  "#96C57F",
  "#CFF6B4",
  "#492818",
  "#766E52",
  "#20370E",
  "#E3D19F",
  "#2E3C30",
  "#B2EACE",
  "#F3BDA4",
  "#A24E3D",
  "#976FD9",
  "#8C9FA8",
  "#7C2B73",
  "#4E5F37",
  "#5D5462",
  "#90956F",
  "#6AA776",
  "#DBCBF6",
  "#DA71FF",
  "#987C95",
  "#52323C",
  "#BB3C42",
  "#584D39",
  "#4FC15F",
  "#A2B9C1",
  "#79DB21",
  "#1D5958",
  "#BD744E",
  "#160B00",
  "#20221A",
  "#6B8295",
  "#00E0E4",
  "#102401",
  "#1B782A",
  "#DAA9B5",
  "#B0415D",
  "#859253",
  "#97A094",
  "#06E3C4",
  "#47688C",
  "#7C6755",
  "#075C00",
  "#7560D5",
  "#7D9F00",
  "#C36D96",
  "#4D913E",
  "#5F4276",
  "#FCE4C8",
  "#303052",
  "#4F381B",
  "#E5A532",
  "#706690",
  "#AA9A92",
  "#237363",
  "#73013E",
  "#FF9079",
  "#A79A74",
  "#029BDB",
  "#FF0169",
  "#C7D2E7",
  "#CA8869",
  "#80FFCD",
  "#BB1F69",
  "#90B0AB",
  "#7D74A9",
  "#FCC7DB",
  "#99375B",
  "#00AB4D",
  "#ABAED1",
  "#BE9D91",
  "#E6E5A7",
  "#332C22",
  "#DD587B",
  "#F5FFF7",
  "#5D3033",
  "#6D3800",
  "#FF0020",
  "#B57BB3",
  "#D7FFE6",
  "#C535A9",
  "#260009",
  "#6A8781",
  "#A8ABB4",
  "#D45262",
  "#794B61",
  "#4621B2",
  "#8DA4DB",
  "#C7C890",
  "#6FE9AD",
  "#A243A7",
  "#B2B081",
  "#181B00",
  "#286154",
  "#4CA43B",
  "#6A9573",
  "#A8441D",
  "#5C727B",
  "#738671",
  "#D0CFCB",
  "#897B77",
  "#1F3F22",
  "#4145A7",
  "#DA9894",
  "#A1757A",
  "#63243C",
  "#ADAAFF",
  "#00CDE2",
  "#DDBC62",
  "#698EB1",
  "#208462",
  "#00B7E0",
  "#614A44",
  "#9BBB57",
  "#7A5C54",
  "#857A50",
  "#766B7E",
  "#014833",
  "#FF8347",
  "#7A8EBA",
  "#274740",
  "#946444",
  "#EBD8E6",
  "#646241",
  "#373917",
  "#6AD450",
  "#81817B",
  "#D499E3",
  "#979440",
  "#011A12",
  "#526554",
  "#B5885C",
  "#A499A5",
  "#03AD89",
  "#B3008B",
  "#E3C4B5",
  "#96531F",
  "#867175",
  "#74569E",
  "#617D9F",
  "#E70452",
  "#067EAF",
  "#A697B6",
  "#B787A8",
  "#9CFF93",
  "#311D19",
  "#3A9459",
  "#6E746E",
  "#B0C5AE",
  "#84EDF7",
  "#ED3488",
  "#754C78",
  "#384644",
  "#C7847B",
  "#00B6C5",
  "#7FA670",
  "#C1AF9E",
  "#2A7FFF",
  "#72A58C",
  "#FFC07F",
  "#9DEBDD",
  "#D97C8E",
  "#7E7C93",
  "#62E674",
  "#B5639E",
  "#FFA861",
  "#C2A580",
  "#8D9C83",
  "#B70546",
  "#372B2E",
  "#0098FF",
  "#985975",
  "#20204C",
  "#FF6C60",
  "#445083",
  "#8502AA",
  "#72361F",
  "#9676A3",
  "#484449",
  "#CED6C2",
  "#3B164A",
  "#CCA763",
  "#2C7F77",
  "#02227B",
  "#A37E6F",
  "#CDE6DC",
  "#CDFFFB",
  "#BE811A",
  "#F77183",
  "#EDE6E2",
  "#CDC6B4",
  "#FFE09E",
  "#3A7271",
  "#FF7B59",
  "#4E4E01",
  "#4AC684",
  "#8BC891",
  "#BC8A96",
  "#CF6353",
  "#DCDE5C",
  "#5EAADD",
  "#F6A0AD",
  "#E269AA",
  "#A3DAE4",
  "#436E83",
  "#002E17",
  "#ECFBFF",
  "#A1C2B6",
  "#50003F",
  "#71695B",
  "#67C4BB",
  "#536EFF",
  "#5D5A48",
  "#890039",
  "#969381",
  "#371521",
  "#5E4665",
  "#AA62C3",
  "#8D6F81",
  "#2C6135",
  "#410601",
  "#564620",
  "#E69034",
  "#6DA6BD",
  "#E58E56",
  "#E3A68B",
  "#48B176",
  "#D27D67",
  "#B5B268",
  "#7F8427",
  "#FF84E6",
  "#435740",
  "#EAE408",
  "#F4F5FF",
  "#325800",
  "#4B6BA5",
  "#ADCEFF",
  "#9B8ACC",
  "#885138",
  "#5875C1",
  "#7E7311",
  "#FEA5CA",
  "#9F8B5B",
  "#A55B54",
  "#89006A",
  "#AF756F",
  "#2A2000",
  "#7499A1",
  "#FFB550",
  "#00011E",
  "#D1511C",
  "#688151",
  "#BC908A",
  "#78C8EB",
  "#8502FF",
  "#483D30",
  "#C42221",
  "#5EA7FF",
  "#785715",
  "#0CEA91",
  "#FFFAED",
  "#B3AF9D",
  "#3E3D52",
  "#5A9BC2",
  "#9C2F90",
  "#8D5700",
  "#ADD79C",
  "#00768B",
  "#337D00",
  "#C59700",
  "#3156DC",
  "#944575",
  "#ECFFDC",
  "#D24CB2",
  "#97703C",
  "#4C257F",
  "#9E0366",
  "#88FFEC",
  "#B56481",
  "#396D2B",
  "#56735F",
  "#988376",
  "#9BB195",
  "#A9795C",
  "#E4C5D3",
  "#9F4F67",
  "#1E2B39",
  "#664327",
  "#AFCE78",
  "#322EDF",
  "#86B487",
  "#C23000",
  "#ABE86B",
  "#96656D",
  "#250E35",
  "#A60019",
  "#0080CF",
  "#CAEFFF",
  "#323F61",
  "#A449DC",
  "#6A9D3B",
  "#FF5AE4",
  "#636A01",
  "#D16CDA",
  "#736060",
  "#FFBAAD",
  "#D369B4",
  "#FFDED6",
  "#6C6D74",
  "#927D5E",
  "#845D70",
  "#5B62C1",
  "#2F4A36",
  "#E45F35",
  "#FF3B53",
  "#AC84DD",
  "#762988",
  "#70EC98",
  "#408543",
  "#2C3533",
  "#2E182D",
  "#323925",
  "#19181B",
  "#2F2E2C",
  "#023C32",
  "#9B9EE2",
  "#58AFAD",
  "#5C424D",
  "#7AC5A6",
  "#685D75",
  "#B9BCBD",
  "#834357",
  "#1A7B42",
  "#2E57AA",
  "#E55199",
  "#316E47",
  "#CD00C5",
  "#6A004D",
  "#7FBBEC",
  "#F35691",
  "#D7C54A",
  "#62ACB7",
  "#CBA1BC",
  "#A28A9A",
  "#6C3F3B",
  "#FFE47D",
  "#DCBAE3",
  "#5F816D",
  "#3A404A",
  "#7DBF32",
  "#E6ECDC",
  "#852C19",
  "#285366",
  "#B8CB9C",
  "#0E0D00",
  "#4B5D56",
  "#6B543F",
  "#E27172",
  "#0568EC",
  "#2EB500",
  "#D21656",
  "#EFAFFF",
  "#682021",
  "#2D2011",
  "#DA4CFF",
  "#70968E",
  "#FF7B7D",
  "#4A1930",
  "#E8C282",
  "#E7DBBC",
  "#A68486",
  "#1F263C",
  "#36574E",
  "#52CE79",
  "#ADAAA9",
  "#8A9F45",
  "#6542D2",
  "#00FB8C",
  "#5D697B",
  "#CCD27F",
  "#94A5A1",
  "#790229",
  "#E383E6",
  "#7EA4C1",
  "#4E4452",
  "#4B2C00",
  "#620B70",
  "#314C1E",
  "#874AA6",
  "#E30091",
  "#66460A",
  "#EB9A8B",
  "#EAC3A3",
  "#98EAB3",
  "#AB9180",
  "#B8552F",
  "#1A2B2F",
  "#94DDC5",
  "#9D8C76",
  "#9C8333",
  "#94A9C9",
  "#392935",
  "#8C675E",
  "#CCE93A",
  "#917100",
  "#01400B",
  "#449896",
  "#1CA370",
  "#E08DA7",
  "#8B4A4E",
  "#667776",
  "#4692AD",
  "#67BDA8",
  "#69255C",
  "#D3BFFF",
  "#4A5132",
  "#7E9285",
  "#77733C",
  "#E7A0CC",
  "#51A288",
  "#2C656A",
  "#4D5C5E",
  "#C9403A",
  "#DDD7F3",
  "#005844",
  "#B4A200",
  "#488F69",
  "#858182",
  "#D4E9B9",
  "#3D7397",
  "#CAE8CE",
  "#D60034",
  "#AA6746",
  "#9E5585",
  "#BA6200",
];

export const batchActionList = [
  {
    batchActionType: BatchAction.CreateBasicTrigger,
    permission: "Triggers_Add",
    disabled: false,
    icon: "fa-bell-on",
    entityTypes: EntityType.Device,
    actionPath: "{0}/CreateBasicTrigger",
  },
  {
    batchActionType: BatchAction.CreateInstantReport,
    permission: "Actions_CreateInstantReport",
    disabled: false,
    icon: "fa-file-pdf",
    entityTypes: EntityType.Device,
    actionPath: "{0}/GenerateInstantReport",
  },
  {
    batchActionType: BatchAction.RerunTrips,
    permission: "Actions_RerunTrips",
    disabled: false,
    icon: "fa-refresh",
    entityTypes: EntityType.Device,
    actionPath: "{0}/RerunBatch",
  },
  {
    batchActionType: BatchAction.CalibrateOdo,
    permission: "Actions_CalibrateOdo",
    disabled: false,
    icon: "fa-tachometer-alt",
    entityTypes: EntityType.Device,
    hideOnBatchPage: true,
    actionPath: "{0}/CalibrateOdo",
  },

  {
    batchActionType: BatchAction.RefreshDeviceStateFromDatabase,
    permission: "Actions_RefreshDeviceStateFromDatabase",
    disabled: false,
    icon: "fa-refresh",
    entityTypes: EntityType.Device,
    actionPath: "{0}/RefreshDeviceStateFromDatabase",
  },

  {
    batchActionType: BatchAction.Archive,
    permission: "Actions_Archive",
    disabled: false,
    icon: "fa-file-archive",
    entityTypes: EntityType.Device,
    hideOnDetailPage: true,
  },
  {
    batchActionType: BatchAction.Unarchive,
    permission: "Actions_Archive",
    disabled: false,
    icon: "fa-recycle",
    entityTypes: EntityType.Device,
    hideOnDetailPage: true,
  },
  {
    batchActionType: BatchAction.AddLogitem,
    permission: "FleetManagement_Log",
    disabled: false,
    icon: "fa-comment-alt",
    entityTypes: EntityType.Device,
    actionPath: "AuditLog/Create",
  },
  {
    batchActionType: BatchAction.RequestLatestLocation,
    permission: "Actions_RequestLocation",
    disabled: false,
    icon: "fa-map-marker",
    entityTypes: EntityType.Device,
    actionPath: "LiveLocation",
  },
  {
    batchActionType: BatchAction.DeleteHistory,
    permission: "Devices_Delete",
    disabled: false,
    icon: "fa-eraser",
    entityTypes: EntityType.Device,
    actionPath: "{0}/DeleteHistory",
  },
  {
    batchActionType: BatchAction.ActivateDevice,
    permission: "Actions_Activate",
    disabled: false,
    icon: "fa-barcode",
    entityTypes: EntityType.Device,
    actionPath: "{0}/Activate",
    hideOnDetailPage: false,
  },
  {
    batchActionType: BatchAction.UpdateFields,
    permission: "Devices_Edit",
    disabled: false,
    icon: "fa-list",
    entityTypes: EntityType.Device,
    hideOnDetailPage: true,
  },
  {
    batchActionType: BatchAction.DeviceSettings,
    permission: "Devices_Edit",
    disabled: false,
    icon: "fa-random",
    entityTypes: EntityType.Device,
    hideOnDetailPage: true,
  },
  {
    batchActionType: BatchAction.VirtualSensors,
    permission: "FleetManagement_Sensors",
    disabled: false,
    icon: "fa-sensor",
    entityTypes: EntityType.Device,
    hideOnDetailPage: true,
  },
  // { batchActionType: BatchAction.DeviceSensors, permission: 'Devices_Edit', disabled: false, icon: 'fa-sensor', entityTypes: EntityType.Device, hideOnDetailPage: true },
  {
    batchActionType: BatchAction.DeviceSettingChanges,
    permission: "Devices_Edit",
    disabled: false,
    icon: "fa-cubes",
    entityTypes: EntityType.Device,
    hideOnDetailPage: true,
  },
  {
    batchActionType: BatchAction.ChangeReseller,
    permission: "Actions_ChangeReseller",
    disabled: false,
    icon: "fa-home",
    entityTypes: EntityType.Device,
    actionPath: "{0}/ChangeReseller",
    hideOnDetailPage: false,
  },
  {
    batchActionType: BatchAction.MoveToAccount,
    permission: "Actions_MoveToAccount",
    disabled: false,
    icon: "fa-random",
    entityTypes: EntityType.Device,
    actionPath: "{0}/MoveToAccount",
    hideOnDetailPage: false,
  },
  {
    batchActionType: BatchAction.ReplaceDevice,
    permission: "Actions_ReplaceDevice",
    disabled: false,
    icon: "fa-exchange-alt",
    entityTypes: EntityType.Device,
    actionPath: "{0}/ReplaceDevice",
    hideOnDetailPage: false,
    hideOnBatchPage: false,
  },
  {
    batchActionType: BatchAction.RemoveFromAccount,
    permission: "Actions_RemoveFromAccount",
    disabled: false,
    icon: "fa-times",
    entityTypes: EntityType.Device,
    actionPath: "{0}/DeactivateDevice",
    hideOnDetailPage: false,
    hideOnBatchPage: false,
  },
  {
    batchActionType: BatchAction.WaslRegister,
    permission: "FleetManagement_External",
    disabled: false,
    icon: "fa-link",
    entityTypes: EntityType.DeviceExternal,
    hideOnDetailPage: true,
    hideOnBatchPage: false,
  },
  {
    batchActionType: BatchAction.WaslUnregister,
    permission: "FleetManagement_External",
    disabled: false,
    icon: "fa-link",
    entityTypes: EntityType.DeviceExternal,
    hideOnDetailPage: true,
    hideOnBatchPage: false,
  },
  {
    batchActionType: BatchAction.ResetDevice,
    permission: "Devices_Edit",
    disabled: false,
    icon: "fa-tornado",
    entityTypes: EntityType.Device,
    hideOnDetailPage: false,
    hideOnBatchPage: false,
    actionPath: "{0}/Reset",
  },
  {
    batchActionType: BatchAction.AddToGroup,
    permission: "Devices_Edit",
    disabled: false,
    icon: "fa-layer-group",
    entityTypes: EntityType.Device,
    hideOnDetailPage: true,
  },

  {
    batchActionType: BatchAction.AddLogitem,
    permission: "FleetManagement_Log",
    disabled: false,
    icon: "fa-comment-alt",
    entityTypes: EntityType.Driver,
    actionPath: "AuditLog/Create",
  },
  {
    batchActionType: BatchAction.UpdateFields,
    permission: "Drivers_Edit",
    disabled: false,
    icon: "fa-list",
    entityTypes: EntityType.Driver,
    hideOnDetailPage: true,
  },
  {
    batchActionType: BatchAction.Delete,
    permission: "Drivers_Delete",
    disabled: false,
    icon: "fa-times",
    entityTypes: EntityType.Driver,
    hideOnDetailPage: true,
  },
  {
    batchActionType: BatchAction.WaslRegister,
    permission: "Actions_Archive",
    disabled: false,
    icon: "fa-link",
    entityTypes: EntityType.Driver,
    hideOnDetailPage: true,
    hideOnBatchPage: false,
  },
  {
    batchActionType: BatchAction.WaslUnregister,
    permission: "Actions_Archive",
    disabled: false,
    icon: "fa-link",
    entityTypes: EntityType.Driver,
    hideOnDetailPage: true,
    hideOnBatchPage: false,
  },
  {
    batchActionType: BatchAction.AddToGroup,
    permission: "Drivers_Edit",
    disabled: false,
    icon: "fa-layer-group",
    entityTypes: EntityType.Driver,
    hideOnDetailPage: true,
  },

  {
    batchActionType: BatchAction.AddLogitem,
    permission: "FleetManagement_Log",
    disabled: false,
    icon: "fa-comment-alt",
    entityTypes: EntityType.Geofence,
    actionPath: "AuditLog/Create",
  },
  {
    batchActionType: BatchAction.ResetState,
    permission: "Actions_ResetState",
    disabled: false,
    icon: "fa-recycle",
    entityTypes: EntityType.Geofence,
    hideOnDetailPage: false,
    hideOnBatchPage: false,
    actionPath: "{0}/Reset",
  },
  {
    batchActionType: BatchAction.UpdateFields,
    permission: "Geofences_Edit",
    disabled: false,
    icon: "fa-list",
    entityTypes: EntityType.Geofence,
    hideOnDetailPage: true,
  },
  {
    batchActionType: BatchAction.Delete,
    permission: "Geofences_Delete",
    disabled: false,
    icon: "fa-times",
    entityTypes: EntityType.Geofence,
    hideOnDetailPage: true,
  },
  {
    batchActionType: BatchAction.AddToGroup,
    permission: "Geofences_Edit",
    disabled: false,
    icon: "fa-layer-group",
    entityTypes: EntityType.Geofence,
    hideOnDetailPage: true,
  },

  {
    batchActionType: BatchAction.AddLogitem,
    permission: "FleetManagement_Log",
    disabled: false,
    icon: "fa-comment-alt",
    entityTypes: EntityType.Fuel,
    actionPath: "AuditLog/Create",
  },
  {
    batchActionType: BatchAction.Delete,
    permission: "FuelEvents_Delete",
    disabled: false,
    icon: "fa-times",
    entityTypes: EntityType.Fuel,
    hideOnDetailPage: true,
  },
  {
    batchActionType: BatchAction.Delete,
    permission: "Maintenances_Delete",
    disabled: false,
    icon: "fa-times",
    entityTypes: EntityType.Maintenance,
    hideOnDetailPage: true,
  },

  {
    batchActionType: BatchAction.AddLogitem,
    permission: "FleetManagement_Log",
    disabled: false,
    icon: "fa-comment-alt",
    entityTypes: EntityType.Trailer,
    actionPath: "AuditLog/Create",
  },

  {
    batchActionType: BatchAction.AddLogitem,
    permission: "FleetManagement_Log",
    disabled: false,
    icon: "fa-comment-alt",
    entityTypes: EntityType.Project,
    actionPath: "AuditLog/Create",
  },

  {
    batchActionType: BatchAction.AddLogitem,
    permission: "FleetManagement_Log",
    disabled: false,
    icon: "fa-comment-alt",
    entityTypes: EntityType.Trigger,
    actionPath: "AuditLog/Create",
  },

  {
    batchActionType: BatchAction.SendCustomCommand,
    permission: "Actions_SendCustomCommand",
    disabled: false,
    icon: "fa-tower-broadcast",
    entityTypes: EntityType.Device,
    hideOnDetailPage: false,
    actionPath: "{0}/SendCustomCommand",
  },
];

export const iconSource = [
  {
    Category: "Cars",
    Value: "Cars",
  },
  {
    Category: "Cars_Cabriolet",
    Value: "Cabriolet",
  },
  {
    Category: "Cars_CamperVan",
    Value: "CamperVan",
  },
  {
    Category: "Cars_Car",
    Value: "Car",
  },
  {
    Category: "Cars_ExecutiveCar",
    Value: "ExecutiveCar",
  },
  {
    Category: "Cars_Hatchback",
    Value: "Hatchback",
  },
  {
    Category: "Cars_HybridCar",
    Value: "HybridCar",
  },
  {
    Category: "Cars_Jeep",
    Value: "Jeep",
  },
  {
    Category: "Cars_Limousine",
    Value: "Limousine",
  },
  {
    Category: "Cars_LuxuryCar",
    Value: "LuxuryCar",
  },
  {
    Category: "Cars_LuxuryCarDriver",
    Value: "LuxuryCarDriver",
  },
  {
    Category: "Cars_Minibus",
    Value: "Minibus",
  },
  {
    Category: "Cars_Minicar",
    Value: "Minicar",
  },
  {
    Category: "Cars_MinicarTrailer",
    Value: "MinicarTrailer",
  },
  {
    Category: "Cars_Minitruck",
    Value: "Minitruck",
  },
  {
    Category: "Cars_MUV",
    Value: "MUV",
  },
  {
    Category: "Cars_PanelVan",
    Value: "PanelVan",
  },
  {
    Category: "Cars_Pickup",
    Value: "Pickup",
  },
  {
    Category: "Cars_ServiceVan",
    Value: "ServiceVan",
  },
  {
    Category: "Cars_SportsCar",
    Value: "SportsCar",
  },
  {
    Category: "Cars_StationWagon",
    Value: "StationWagon",
  },
  {
    Category: "Cars_SUV",
    Value: "SUV",
  },
  {
    Category: "Cars_Van",
    Value: "Van",
  },
  {
    Category: "Containers",
    Value: "Containers",
  },
  {
    Category: "Containers_20ftContainer",
    Value: "20ftContainer",
  },
  {
    Category: "Containers_40ftContainer",
    Value: "40ftContainer",
  },
  {
    Category: "Containers_Container",
    Value: "Container",
  },
  {
    Category: "Containers_DomesticOilTank",
    Value: "DomesticOilTank",
  },
  {
    Category: "Containers_Skip",
    Value: "Skip",
  },
  {
    Category: "Containers_SwapContainer",
    Value: "SwapContainer",
  },
  {
    Category: "Containers_SwapCurtainSideContainer",
    Value: "SwapCurtainSideContainer",
  },
  {
    Category: "Containers_SwapTankContainer",
    Value: "SwapTankContainer",
  },
  {
    Category: "EmergencyVehicles",
    Value: "EmergencyVehicles",
  },
  {
    Category: "EmergencyVehicles_AirAmbulance",
    Value: "AirAmbulance",
  },
  {
    Category: "EmergencyVehicles_Ambulance",
    Value: "Ambulance",
  },
  {
    Category: "EmergencyVehicles_FireTruck",
    Value: "FireTruck",
  },
  {
    Category: "EmergencyVehicles_PoliceCar",
    Value: "PoliceCar",
  },
  {
    Category: "EmergencyVehicles_RecoveryTruck",
    Value: "RecoveryTruck",
  },
  {
    Category: "EmergencyVehicles_TowTruck",
    Value: "TowTruck",
  },
  {
    Category: "GroundSupportEquipment",
    Value: "GroundSupportEquipment",
  },
  {
    Category: "GroundSupportEquipment_BeltLoader",
    Value: "BeltLoader",
  },
  {
    Category: "GroundSupportEquipment_ContainerLoader",
    Value: "ContainerLoader",
  },
  {
    Category: "GroundSupportEquipment_GroundPowerUnitTrailer",
    Value: "GroundPowerUnitTrailer",
  },
  {
    Category: "GroundSupportEquipment_PushbackTug",
    Value: "PushbackTug",
  },
  {
    Category: "GroundSupportEquipment_TempestDeicer",
    Value: "TempestDeicer",
  },
  {
    Category: "HeavyEquipment",
    Value: "HeavyEquipment",
  },
  {
    Category: "HeavyEquipment_BackhoeLoader",
    Value: "BackhoeLoader",
  },
  {
    Category: "HeavyEquipment_Bulldozer",
    Value: "Bulldozer",
  },
  {
    Category: "HeavyEquipment_CompactExcavator",
    Value: "CompactExcavator",
  },
  {
    Category: "HeavyEquipment_Excavator",
    Value: "Excavator",
  },
  {
    Category: "HeavyEquipment_FarmTractor",
    Value: "FarmTractor",
  },
  {
    Category: "HeavyEquipment_ForkliftTruck",
    Value: "ForkliftTruck",
  },
  {
    Category: "HeavyEquipment_ForkliftTruckLoaded",
    Value: "ForkliftTruckLoaded",
  },
  {
    Category: "HeavyEquipment_LandfillCompactor",
    Value: "LandfillCompactor",
  },
  {
    Category: "HeavyEquipment_Loader",
    Value: "Loader",
  },
  {
    Category: "HeavyEquipment_MaterialHandler",
    Value: "MaterialHandler",
  },
  {
    Category: "HeavyEquipment_RoadRoller",
    Value: "RoadRoller",
  },
  {
    Category: "HeavyEquipment_RoadSweeper",
    Value: "RoadSweeper",
  },
  {
    Category: "HeavyEquipment_RollerCompactor",
    Value: "RollerCompactor",
  },
  {
    Category: "HeavyEquipment_SkidSteerLoader",
    Value: "SkidSteerLoader",
  },
  {
    Category: "HeavyEquipment_SkipLoader",
    Value: "SkipLoader",
  },
  {
    Category: "HeavyEquipment_SoilStabilizer",
    Value: "SoilStabilizer",
  },
  {
    Category: "HeavyEquipment_SteamRoller",
    Value: "SteamRoller",
  },
  {
    Category: "HeavyEquipment_StreetSweeper",
    Value: "StreetSweeper",
  },
  {
    Category: "HeavyEquipment_TelescopicHandler",
    Value: "TelescopicHandler",
  },
  {
    Category: "HeavyEquipment_TowerCrane",
    Value: "TowerCrane",
  },
  {
    Category: "HeavyEquipment_TruckMountedCrane",
    Value: "TruckMountedCrane",
  },
  {
    Category: "HeavyEquipment_TruckMountedCraneWorking",
    Value: "TruckMountedCraneWorking",
  },
  {
    Category: "HeavyEquipment_WheeledExcavator",
    Value: "WheeledExcavator",
  },
  {
    Category: "HeavyEquipment_WreckingBall",
    Value: "WreckingBall",
  },
  {
    Category: "PublicTransport",
    Value: "PublicTransport",
  },
  {
    Category: "PublicTransport_Coach",
    Value: "Coach",
  },
  {
    Category: "PublicTransport_CommuterBus",
    Value: "CommuterBus",
  },
  {
    Category: "PublicTransport_DoubleDeckerBus",
    Value: "DoubleDeckerBus",
  },
  {
    Category: "PublicTransport_DoubleDeckerBusOpenTop",
    Value: "DoubleDeckerBusOpenTop",
  },
  {
    Category: "PublicTransport_SchoolBus",
    Value: "SchoolBus",
  },
  {
    Category: "PublicTransport_SubwayTrain",
    Value: "SubwayTrain",
  },
  {
    Category: "PublicTransport_Taxi",
    Value: "Taxi",
  },
  {
    Category: "PublicTransport_Tram",
    Value: "Tram",
  },
  {
    Category: "PublicTransport_TransitBus",
    Value: "TransitBus",
  },
  {
    Category: "PublicTransport_Trolleybus",
    Value: "Trolleybus",
  },
  {
    Category: "Trailers",
    Value: "Trailers",
  },
  {
    Category: "Trailers_BoxTrailer",
    Value: "BoxTrailer",
  },
  {
    Category: "Trailers_Caravan",
    Value: "Caravan",
  },
  {
    Category: "Trailers_CarTrailer",
    Value: "CarTrailer",
  },
  {
    Category: "Trailers_ContainerizedGeneratorTrailer",
    Value: "ContainerizedGeneratorTrailer",
  },
  {
    Category: "Trailers_CurtainSideTrailer",
    Value: "CurtainSideTrailer",
  },
  {
    Category: "Trailers_FlatbedTrailer",
    Value: "FlatbedTrailer",
  },
  {
    Category: "Trailers_FlatbedTrailerBulkhead",
    Value: "FlatbedTrailerBulkhead",
  },
  {
    Category: "Trailers_FlatbedTrailerLoaderCraneHead",
    Value: "FlatbedTrailerLoaderCraneHead",
  },
  {
    Category: "Trailers_FlatbedTrailerLoaderCraneRear",
    Value: "FlatbedTrailerLoaderCraneRear",
  },
  {
    Category: "Trailers_FullTrailer",
    Value: "FullTrailer",
  },
  {
    Category: "Trailers_JetterTrailer",
    Value: "JetterTrailer",
  },
  {
    Category: "Trailers_LowboyTrailer",
    Value: "LowboyTrailer",
  },
  {
    Category: "Trailers_PlantTrailer",
    Value: "PlantTrailer",
  },
  {
    Category: "Trailers_ReeferTrailer",
    Value: "ReeferTrailer",
  },
  {
    Category: "Trailers_SuperSilentGeneratorTrailer",
    Value: "SuperSilentGeneratorTrailer",
  },
  {
    Category: "Trailers_TankerTrailer",
    Value: "TankerTrailer",
  },
  {
    Category: "Trailers_TankerTrailer2",
    Value: "TankerTrailer2",
  },
  {
    Category: "Trailers_TravelTrailer",
    Value: "TravelTrailer",
  },
  {
    Category: "Trailers_UltraSilentGeneratorTrailer",
    Value: "UltraSilentGeneratorTrailer",
  },
  {
    Category: "Trailers_UtilityTrailer",
    Value: "UtilityTrailer",
  },
  {
    Category: "Trailers_WasteTankerTrailer",
    Value: "WasteTankerTrailer",
  },
  {
    Category: "Trailers_WaterBowserTrailer",
    Value: "WaterBowserTrailer",
  },
  {
    Category: "Trailers_WoodChipper",
    Value: "WoodChipper",
  },
  {
    Category: "Trucks",
    Value: "Trucks",
  },
  {
    Category: "Trucks_BinTruck",
    Value: "BinTruck",
  },
  {
    Category: "Trucks_CCTVTruck",
    Value: "CCTVTruck",
  },
  {
    Category: "Trucks_ConcretePump",
    Value: "ConcretePump",
  },
  {
    Category: "Trucks_FlatbedTruckLoaderCraneHead",
    Value: "FlatbedTruckLoaderCraneHead",
  },
  {
    Category: "Trucks_FlatbedTruckLoaderCraneRear",
    Value: "FlatbedTruckLoaderCraneRear",
  },
  {
    Category: "Trucks_FuelTankTruck",
    Value: "FuelTankTruck",
  },
  {
    Category: "Trucks_Gritter",
    Value: "Gritter",
  },
  {
    Category: "Trucks_GullyEmptier",
    Value: "GullyEmptier",
  },
  {
    Category: "Trucks_HaulTruck",
    Value: "HaulTruck",
  },
  {
    Category: "Trucks_Lorry",
    Value: "Lorry",
  },
  {
    Category: "Trucks_LorryCab",
    Value: "LorryCab",
  },
  {
    Category: "Trucks_LorryTrailer",
    Value: "LorryTrailer",
  },
  {
    Category: "Trucks_LPGTankTruck",
    Value: "LPGTankTruck",
  },
  {
    Category: "Trucks_MixerTruck",
    Value: "MixerTruck",
  },
  {
    Category: "Trucks_MixerTruck2",
    Value: "MixerTruck2",
  },
  {
    Category: "Trucks_PetroleumTankTruck",
    Value: "PetroleumTankTruck",
  },
  {
    Category: "Trucks_ServiceTruck",
    Value: "ServiceTruck",
  },
  {
    Category: "Trucks_SewerCleaningTruck",
    Value: "SewerCleaningTruck",
  },
  {
    Category: "Trucks_SiloTruck",
    Value: "SiloTruck",
  },
  {
    Category: "Trucks_SiloTruck2",
    Value: "SiloTruck2",
  },
  {
    Category: "Trucks_SkipTruck",
    Value: "SkipTruck",
  },
  {
    Category: "Trucks_SnowPlowTruck",
    Value: "SnowPlowTruck",
  },
  {
    Category: "Trucks_TankerTruck",
    Value: "TankerTruck",
  },
  {
    Category: "Trucks_TipperTruck",
    Value: "TipperTruck",
  },
  {
    Category: "Trucks_TractorFlatbedTrailer",
    Value: "TractorFlatbedTrailer",
  },
  {
    Category: "Trucks_TractorTrailer",
    Value: "TractorTrailer",
  },
  {
    Category: "Trucks_TractorTrailer2",
    Value: "TractorTrailer2",
  },
  {
    Category: "Trucks_TractorUnit",
    Value: "TractorUnit",
  },
  {
    Category: "Trucks_Truck",
    Value: "Truck",
  },
  {
    Category: "Trucks_TruckV",
    Value: "TruckV",
  },
  {
    Category: "Trucks_WaterTankTruck",
    Value: "WaterTankTruck",
  },
  {
    Category: "zBikes",
    Value: "Bikes",
  },
  {
    Category: "zBikes_Bicycle",
    Value: "Bicycle",
  },
  {
    Category: "zBikes_CruiserMotorcycle",
    Value: "CruiserMotorcycle",
  },
  {
    Category: "zBikes_Motorcycle",
    Value: "Motorcycle",
  },
  {
    Category: "zBikes_Pedicab",
    Value: "Pedicab",
  },
  {
    Category: "zBikes_QuadBike",
    Value: "QuadBike",
  },
  {
    Category: "zBikes_RecumbentTrike",
    Value: "RecumbentTrike",
  },
  {
    Category: "zBikes_RecumbentTrikeRider",
    Value: "RecumbentTrikeRider",
  },
  {
    Category: "zBikes_Scooter",
    Value: "Scooter",
  },
  {
    Category: "zBikes_TouringMotorcycle",
    Value: "TouringMotorcycle",
  },
  {
    Category: "Watercrafts",
    Value: "Watercrafts",
  },
  {
    Category: "Watercrafts_Barge",
    Value: "Barge",
  },
  {
    Category: "Watercrafts_Boat",
    Value: "Boat",
  },
  {
    Category: "Watercrafts_BulkCarrier",
    Value: "BulkCarrier",
  },
  {
    Category: "Watercrafts_CargoShip",
    Value: "CargoShip",
  },
  {
    Category: "Watercrafts_Catamaran",
    Value: "Catamaran",
  },
  {
    Category: "Watercrafts_ContainerShip",
    Value: "ContainerShip",
  },
  {
    Category: "Watercrafts_CruiseShip",
    Value: "CruiseShip",
  },
  {
    Category: "Watercrafts_InflatableBoat",
    Value: "InflatableBoat",
  },
  {
    Category: "Watercrafts_Motorboat",
    Value: "Motorboat",
  },
  {
    Category: "Watercrafts_RescueLifeboat",
    Value: "RescueLifeboat",
  },
  {
    Category: "Watercrafts_Sailboat",
    Value: "Sailboat",
  },
  {
    Category: "Watercrafts_SailingShip",
    Value: "SailingShip",
  },
  {
    Category: "Watercrafts_Schooner",
    Value: "Schooner",
  },
  {
    Category: "Watercrafts_Tanker",
    Value: "Tanker",
  },
  {
    Category: "Watercrafts_WaterBike",
    Value: "WaterBike",
  },
  {
    Category: "Watercrafts_WaterMotorcycle",
    Value: "WaterMotorcycle",
  },
  {
    Category: "RailTransport",
    Value: "RailTransport",
  },
  {
    Category: "RailTransport_DieselLocomotive",
    Value: "DieselLocomotive",
  },
  {
    Category: "RailTransport_DieselLocomotiveBoxcar",
    Value: "DieselLocomotiveBoxcar",
  },
  {
    Category: "RailTransport_SteamLocomotive",
    Value: "SteamLocomotive",
  },
  {
    Category: "RailTransport_SteamLocomotiveTender",
    Value: "SteamLocomotiveTender",
  },
  {
    Category: "RailTransport_Train",
    Value: "Train",
  },
  {
    Category: "Aircrafts",
    Value: "Aircrafts",
  },
  {
    Category: "Aircrafts_Airliner",
    Value: "Airliner",
  },
  {
    Category: "Aircrafts_Airplane",
    Value: "Airplane",
  },
  {
    Category: "Aircrafts_Helicopter",
    Value: "Helicopter",
  },
  {
    Category: "Pets",
    Value: "Pets",
  },
  {
    Category: "Pets_Cat",
    Value: "Cat",
  },
  {
    Category: "Pets_Dog",
    Value: "Dog",
  },
];

export const iconCategories = iconSource.filter((x) => x.Category.toString().indexOf("_") < 0);
export const iconIcon = iconSource.filter((x) => x.Category.toString().indexOf("_") > -1);

export const assetTypeOptions = [
  {
    id: 100,
    name: "Bodywork",
    options: [
      {
        id: 101,
        name: "Body type",
      },
      {
        id: 102,
        name: "The length of the car",
      },
      {
        id: 103,
        name: "The width of the car",
      },
      {
        id: 104,
        name: "The height of the car",
      },
      {
        id: 105,
        name: "Wheelbase",
      },
      {
        id: 106,
        name: "Ground clearance",
      },
      {
        id: 107,
        name: "Front track",
      },
      {
        id: 108,
        name: "Rear track",
      },
      {
        id: 109,
        name: "Cab type",
      },
      {
        id: 110,
        name: "Length of the tractor",
      },
      {
        id: 111,
        name: "Tractor width",
      },
      {
        id: 112,
        name: "Height of the tractor",
      },
      {
        id: 113,
        name: "Quantity of doors",
      },
      {
        id: 114,
        name: "Door width",
      },
      {
        id: 115,
        name: "The width of the passage between the seats",
      },
      {
        id: 116,
        name: "The ceiling height in the cabin",
      },
      {
        id: 117,
        name: "The height of a step above the level of the road",
      },
    ],
  },
  {
    id: 200,
    name: "Engine",
    options: [
      {
        id: 201,
        name: "Potencia",
      },
      {
        id: 202,
        name: "Engine power",
      },
      {
        id: 203,
        name: "With turnover",
      },
      {
        id: 204,
        name: "Torque",
      },
      {
        id: 205,
        name: "Engine type",
      },
      {
        id: 206,
        name: "Arrangement of cylinders",
      },
      {
        id: 207,
        name: "Number of cylinders",
      },
      {
        id: 208,
        name: "Compression ratio",
      },
      {
        id: 209,
        name: "Fuel",
      },
      {
        id: 210,
        name: "Engine model",
      },
      {
        id: 211,
        name: "Environmental standard",
      },
      {
        id: 212,
        name: "The number of valves per cylinder",
      },
      {
        id: 213,
        name: "Pressurization",
      },
      {
        id: 214,
        name: "Engine power at rpm",
      },
      {
        id: 215,
        name: "Torque at rpm",
      },
      {
        id: 216,
        name: "The volume of the engine at rpm",
      },
      {
        id: 217,
        name: "If the rpm with rpm",
      },
      {
        id: 218,
        name: "Engine layout",
      },
    ],
  },
  {
    id: 300,
    name: "Gearbox and handling",
    options: [
      {
        id: 301,
        name: "Number of gear (mechanical gearbox)",
      },
      {
        id: 302,
        name: "Drive wheels",
      },
      {
        id: 303,
        name: "Number of gears (automatic transmission)",
      },
      {
        id: 304,
        name: "Transmission type",
      },
      {
        id: 305,
        name: "Amount of gear",
      },
      {
        id: 306,
        name: "Model transmission",
      },
      {
        id: 307,
        name: "Wheel arrangement",
      },
    ],
  },
  {
    id: 400,
    name: "PERFORMANCE",
    options: [
      {
        id: 401,
        name: "Maximum speed",
      },
      {
        id: 402,
        name: "Fuel tank capacity",
      },
      {
        id: 403,
        name: "Fuel consumption mixed cycle",
      },
      {
        id: 404,
        name: "Acceleration to 100 km/h",
      },
      {
        id: 405,
        name: "Acceleration time to 60 km/h",
      },
      {
        id: 406,
        name: "Diamètre de braquage",
      },
      {
        id: 407,
        name: "Angle me to ove. lift, not less",
      },
      {
        id: 408,
        name: "Fuel consumption",
      },
      {
        id: 409,
        name: "The maximum transport speed",
      },
      {
        id: 410,
        name: "The height of the fifth wheel",
      },
      {
        id: 411,
        name: "Recommended tractor",
      },
      {
        id: 412,
        name: "Fuel consumption at 60 km/h, l/100 km",
      },
      {
        id: 413,
        name: "Outer turning radius",
      },
    ],
  },
  {
    id: 500,
    name: "Payload",
    options: [
      {
        id: 501,
        name: "Number of places",
      },
      {
        id: 502,
        name: "Payload",
      },
      {
        id: 503,
        name: "Length of cargo area (platform)",
      },
      {
        id: 504,
        name: "Width the cargo compartment (platform)",
      },
      {
        id: 505,
        name: "The height of the cargo compartment (sides of the platform)",
      },
      {
        id: 506,
        name: "The volume of the cargo compartment",
      },
      {
        id: 507,
        name: "Useful volume",
      },
      {
        id: 508,
        name: "Length of platform",
      },
      {
        id: 509,
        name: "Platform width",
      },
      {
        id: 510,
        name: "Height without awning",
      },
      {
        id: 511,
        name: "Height with awning",
      },
      {
        id: 512,
        name: "Number of Euro pallets (800x1200)",
      },
      {
        id: 513,
        name: "Loading height",
      },
      {
        id: 514,
        name: "The area of the platform",
      },
      {
        id: 515,
        name: "Platform description",
      },
      {
        id: 516,
        name: "Length of loading platform",
      },
      {
        id: 517,
        name: "Width of loading platform",
      },
      {
        id: 518,
        name: "Height of loading platform",
      },
      {
        id: 519,
        name: "The volume of the tank/bodywork",
      },
      {
        id: 520,
        name: "Pump",
      },
      {
        id: 521,
        name: "The tank material",
      },
      {
        id: 522,
        name: "Compartments",
      },
      {
        id: 523,
        name: "Cross-section",
      },
      {
        id: 524,
        name: "The height of self-suction",
      },
    ],
  },
  {
    id: 600,
    name: "Dimentions and weight",
    options: [
      {
        id: 601,
        name: "Curb vehicle weight",
      },
      {
        id: 602,
        name: "Gross vehicle weight",
      },
      {
        id: 603,
        name: "Rims, the dimension",
      },
      {
        id: 604,
        name: "Tyre dimension",
      },
      {
        id: 605,
        name: "Total weight with trailer",
      },
      {
        id: 606,
        name: "Total train weight",
      },
      {
        id: 607,
        name: "Full trailer weight",
      },
      {
        id: 608,
        name: "Load on fifth wheel",
      },
      {
        id: 609,
        name: "The load on the front axle",
      },
      {
        id: 610,
        name: "The load on the rear axle (trolley)",
      },
      {
        id: 611,
        name: "Curb weight of the trailer",
      },
      {
        id: 612,
        name: "Total mass",
      },
      {
        id: 613,
        name: "Length of the trailer",
      },
      {
        id: 614,
        name: "Width of the trailer",
      },
      {
        id: 615,
        name: "Height with awning",
      },
      {
        id: 616,
        name: "Number of axes",
      },
      {
        id: 617,
        name: "The number of wheels + spare",
      },
      {
        id: 618,
        name: "Tire size",
      },
      {
        id: 619,
        name: "Support device",
      },
      {
        id: 620,
        name: "Height",
      },
      {
        id: 621,
        name: "The load on the fifth wheel of the tractor",
      },
      {
        id: 622,
        name: "Base",
      },
      {
        id: 623,
        name: "Rear track",
      },
      {
        id: 624,
        name: "The mass on the cart (axis)",
      },
      {
        id: 625,
        name: "Ground clearance",
      },
      {
        id: 626,
        name: "Length",
      },
      {
        id: 627,
        name: "Back track width",
      },
      {
        id: 628,
        name: "Curb weight",
      },
      {
        id: 629,
        name: "The full weight of the bus",
      },
      {
        id: 630,
        name: "Front overhang",
      },
      {
        id: 631,
        name: "Rear overhang",
      },
      {
        id: 632,
        name: "Front track",
      },
      {
        id: 633,
        name: "The load on the rear axle",
      },
      {
        id: 634,
        name: "The load on the Central axle",
      },
    ],
  },
  {
    id: 700,
    name: "Steering",
    options: [
      {
        id: 701,
        name: "Diamètre de braquage",
      },
      {
        id: 702,
        name: "Type steering",
      },
      {
        id: 703,
        name: "Servo",
      },
    ],
  },
  {
    id: 800,
    name: "Suspension and brakes",
    options: [
      {
        id: 801,
        name: "Type rear suspension",
      },
      {
        id: 802,
        name: "Type front suspension",
      },
      {
        id: 803,
        name: "Type of suspension",
      },
    ],
  },
  {
    id: 900,
    name: "Brake System",
    options: [
      {
        id: 901,
        name: "Front brake",
      },
      {
        id: 902,
        name: "Rear brake",
      },
      {
        id: 903,
        name: "Service brake",
      },
      {
        id: 904,
        name: "Parking brake",
      },
      {
        id: 905,
        name: "ABS",
      },
      {
        id: 906,
        name: "Type brake",
      },
      {
        id: 907,
        name: "The type of braking system",
      },
    ],
  },
  {
    id: 1000,
    name: "Main characteristic",
    options: [
      {
        id: 1001,
        name: "Type of trailer",
      },
      {
        id: 1002,
        name: "Class bus",
      },
      {
        id: 1003,
        name: "Appointment",
      },
      {
        id: 1004,
        name: "The total number of seats (including boarding)",
      },
      {
        id: 1005,
        name: "The Luggage compartment",
      },
    ],
  },
];

export function getGeofenceActionResources(item, translate) {
  if (item.hasEntered) {
    const hasEntered = translate.instant("general.enteredGeofence");
    return {
      icon: "fa-draw-polygon",
      iconFontColor: "#3BA850",
      markerColor: "green",
      description: hasEntered + " <i>" + item.geoFenceLabel + "</i>",
    };
  }

  if (item.hasLeft) {
    const hasLeft = translate.instant("general.leftGeofence");
    return {
      icon: "fa-draw-polygon",
      iconFontColor: "#a90000",
      markerColor: "red",
      description: hasLeft + " <i>" + item.geoFenceLabel + "</i>",
    };
  }

  if (item.hasPassedThrough) {
    const hasPassedThrough = translate.instant("general.passedTroughGeofence");
    return {
      icon: "fa-draw-polygon",
      iconFontColor: "#a90000",
      markerColor: "orange",
      description: hasPassedThrough + " " + item.geoFenceLabel,
    };
  }

  return {
    icon: "fas fa-location-arrow",
    iconFontColor: "#666",
    markerColor: "blue",
    description: translate.instant("general.passedTroughGeofence") + " " + item.geoFenceLabel,
  };
}

export function getIconPath(iconId) {
  const result: string[] = [];
  result[0] = "/assets/images/icons/vista/Trucks/16x16/TankerTruck_Black.png";
  result[1] = "/assets/images/icons/vista/Trucks/32x32/TankerTruck_Black.png";
  result[2] = "/assets/images/icons/vista/Trucks/64x64/TankerTruck_Black.png";

  if (iconId && iconId > 999) {
    // The new format is as follows:
    // number abbc stands for: Category index 'a', vehicle name index 'bb' and color index 'c'
    let category: number = iconId / 1000;
    let vehicle: number = (iconId % 1000) / 10;
    let color: number = iconId % 10;

    category = Math.floor(category);
    vehicle = Math.floor(vehicle);
    color = Math.floor(color);

    const cat = Object.values(iconCategories)[category - 1];
    if (cat === undefined) {
      return "";
    }

    const aSelectedCategoryKey = cat.Category;
    const aSelectedCategoryValue = cat.Value;

    const iconList = Object.values(iconIcon.filter((x) => x.Category.indexOf(aSelectedCategoryKey) > -1));

    const theResourceItem = iconList[vehicle];

    const icon: string[] = [];

    if (theResourceItem != null) {
      const colors = Object.values(IconColors);

      const aSelectedIconKey = theResourceItem.Category;
      const aSelectedIconValue = theResourceItem.Value;

      let colorName = Object.keys(IconColors).find((key) => IconColors[key] === color);
      if (colorName === undefined) {
        colorName = "Blue";
      }

      icon[0] =
        "/assets/images/icons/vista/" +
        aSelectedCategoryKey +
        "/16x16/" +
        aSelectedIconValue +
        "_" +
        colorName +
        ".png";
      icon[1] =
        "/assets/images/icons/vista/" +
        aSelectedCategoryKey +
        "/32x32/" +
        aSelectedIconValue +
        "_" +
        colorName +
        ".png";
      icon[2] =
        "/assets/images/icons/vista/" +
        aSelectedCategoryKey +
        "/64x64/" +
        aSelectedIconValue +
        "_" +
        colorName +
        ".png";
    }

    return icon;
  } else {
    return result;
  }
}

export function generatePassword(passwordLength) {
  const numberChars = "11234567891223456789";
  const upperChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const lowerChars = "abcdefghijklmnopqrstuvwxyz";
  const specialChars = "$@!%?#.&$@!%?#.&";
  const allChars = numberChars + upperChars + lowerChars + specialChars;
  let randPasswordArray = Array(passwordLength);
  randPasswordArray[0] = numberChars;
  randPasswordArray[1] = upperChars;
  randPasswordArray[2] = lowerChars;
  randPasswordArray[3] = specialChars;
  randPasswordArray = randPasswordArray.fill(allChars, 4);
  return shuffleArray(
    randPasswordArray.map(function (x) {
      return x[Math.floor(Math.random() * x.length)];
    })
  ).join("");
}

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
}

export function checkAnalogPortForValidSensor(analogPort: string) {
  switch (Number(analogPort)) {
    case AnalogFunctions.TechnotonAnalogFuelSensor:
    case AnalogFunctions.CapacitorFuelSensor:
    case AnalogFunctions.GNOM_DDE:
    case AnalogFunctions.GNOM_TP:
    case AnalogFunctions.Eurosens_Difference_01:
    case AnalogFunctions.Eurosens_Difference_02:
    case AnalogFunctions.Veinasa_HRTM030:
    case AnalogFunctions.Veinasa_HTW_IC00X_H:
    case AnalogFunctions.Veinasa_HTW_IC00X_T:
    case AnalogFunctions.Voss_Strain_Weight:
    case AnalogFunctions.WeightSensorFlags:
      return true;
    default:
      return false;
  }
}

export function contains(array, obj) {
  if (array == null) {
    return false;
  }

  for (let i = 0, len = array.length; i < len; i++) {
    if (array[i] == obj) {
      return true;
    }
  }

  return false;
}

export function mergeRanges(ranges: any[]): any[] {
  if (!(ranges && ranges.length)) {
    return [];
  }

  // Stack of final ranges
  const stack = [];

  // Sort according to start value
  ranges.sort(function (a, b): any {
    return a[0] - b[0];
  });

  // Add first range to stack
  stack.push(ranges[0]);

  ranges.slice(1).forEach(function (range, _): any {
    const top = stack[stack.length - 1];

    if (top[1] < range[0]) {
      // No overlap, push range onto stack
      stack.push(range);
    } else if (top[1] < range[1]) {
      // Update previous range
      top[1] = range[1];
    }
  });

  return stack;
}
