<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/"> <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Settings/">{{'general.settings' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Settings/AssetTypes/Overview">{{'general.assetTypes' | translate }}</a>
                </li>
                <li class="breadcrumb-item active">{{'general.assetTypeDetails' | translate }}</li>
            </ol>
        </nav>
    </div>
    <fh-device-navigation> </fh-device-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">
        <div class="row" data-equalizer="" *ngIf="error || success">
            <div class="col-lg-12">
                <fh-error [error]="error" [success]="success"></fh-error>
            </div>
        </div>

        <div class="row" data-equalizer="">
            <div class="col-lg-6">
                <form #userForm="ngForm">
                    <div class="ibox" style="position: relative">
                        <ngx-loading [show]="loading || saving"></ngx-loading>
                        <div class="ibox-title">
                            <h5>
                                <i class="fa fa-truck-monster"></i> {{'general.assetType' | translate }}
                                ({{assetType?.assetCount}})
                            </h5>
                        </div>
                        <fh-asset-type-details [assetType]="assetType" [formMode]="formMode"> </fh-asset-type-details>
                        <fh-save-footer
                            [valid]="!userForm.invalid"
                            (onSave)="onSave()"
                            (onDelete)="onDelete()"
                            [allowEdit]="permissions['AssetTypes_Edit']"
                            [allowDelete]="permissions['AssetTypes_Delete']"
                            (setFormMode)="setFormMode($event)"
                            [formMode]="formMode"
                        >
                        </fh-save-footer>
                    </div>
                </form>
            </div>
            <div class="col-lg-6">
                <fh-tagging [objectId]="assetType?.id" [objectType]="tagType"> </fh-tagging>
            </div>
        </div>
    </div>
</fh-permission-handler>
