<div class="col-lg-12">
    <div class="horizontalMenuWrapper">
        <ul>
            <li [ngClass]="{'active': activeRoute('/Dashboard/Dashboard')}">
                <a [routerLink]="['/Dashboard/Dashboard/']">
                    <i class="fas fa-fw fa-gauge"></i>
                    <div>{{ 'menu.dashboard' | translate }}</div>
                </a>
            </li>
            <li
                *ngIf="permissions['FleetManagement_DeviceUtilization']"
                [ngClass]="{active: activeRoute('/Dashboard/Utilization')}"
            >
                <a [routerLink]="['/Dashboard/Utilization/']">
                    <i class="fas fa-fw fa-engine"></i>
                    <div>{{ 'general.utilization' | translate }}</div>
                </a>
            </li>
            <li
                *ngIf="permissions['FleetManagement_DeviceUtilization']"
                [ngClass]="{active: activeRoute('/Dashboard/Trends')}"
            >
                <a [routerLink]="['/Dashboard/Trends/']">
                    <i class="fas fa-fw fa-chart-line-up"></i>
                    <div>{{ 'general.trends' | translate }}</div>
                </a>
            </li>
            <li *ngIf="permissions['FleetManagement_Costs']" [ngClass]="{'active': activeRoute('/Dashboard/Costs')}">
                <a [routerLink]="['/Dashboard/Costs/']">
                    <i class="fas fa-fw fa-sack-dollar"></i>
                    <div>{{ 'general.costs' | translate }}</div>
                </a>
            </li>
            <li *ngIf="permissions['FleetManagement_Issues']" [ngClass]="{'active': activeRoute('/Dashboard/Issues')}">
                <a [routerLink]="['/Dashboard/Issues/']">
                    <i class="fas fa-fw fa-car-crash"></i>
                    <div>{{ 'general.issues' | translate }}</div>
                </a>
            </li>
            <li
                *ngIf="permissions['FleetManagementIssues_Latency']"
                [ngClass]="{'active': activeRoute('/Dashboard/Latency')}"
            >
                <a [routerLink]="['/Dashboard/Latency/']">
                    <i class="fas fa-fw fa-clock"></i>
                    <div>{{ 'general.latency' | translate }}</div>
                </a>
            </li>
        </ul>
    </div>
    <div class="horizontalMenuWrapper" style="float: right">
        <ul>
            <ng-content></ng-content>
        </ul>
    </div>
</div>
<div style="clear: both"></div>
