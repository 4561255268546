import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "app/../environments/environment";
import { AuthenticationService } from "../../services/authentication/authentication.service";
import { UserService } from "../../services/users/user.service";

@Component({
  templateUrl: "./forgotPassword.template.html",
  styleUrls: ["./login.template.css"],
})
export class ForgotComponent {
  model: any = {};
  loading = false;
  messageStatus = "alert-danger";
  appVersion: string;
  UserName = "";
  Password = "";
  error: string;
  data: string;
  returnUrl;
  subscription;
  param = null;
  errorLoggedIn = false;
  resetDiabled: boolean;

  constructor(
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private authenticationService: AuthenticationService,
    private userService: UserService
  ) {
    this.subscription = route.queryParams.subscribe((queryParam: any) => (this.param = queryParam["prev"]));

    this.appVersion = environment.version;
  }

  onMouseMove(event) {
    const e = event;
    // Mouse Move 3d Effect

    const perX = e.clientX / $(window).width() - 0.5;
    const perY = e.clientY / $(window).height() - 0.5;
    // TweenMax.to('.effect-3d-element', 0.4, { rotationY: 5 * perX, rotationX: 5 * perY, transformPerspective: 1000, transformOrigin: 'center' })
  }

  clicked(event) {
    this.loading = true;

    if (this.UserName.length === 0) {
      this.error = this.translateService.instant("settings.fieldRequired");
      this.loading = false;
    } else {
      this.authenticationService.performReset(this.UserName).subscribe({
        next: (result) => {
          this.loading = false;

          if (result === true) {
            this.messageStatus = "alert-success";
            this.resetDiabled = true;
            this.error = this.translateService.instant("login.passwordResetSucces");
          } else {
            this.messageStatus = "alert-danger";
            this.error = this.translateService.instant("login.passwordResetFailed");
          }
        },
        error: (error) => {
          this.loading = false;
          this.error = error;
        },
      });
    }
  }
}
