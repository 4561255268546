import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { EntityType } from "app/common/enums";
import { TagSearch, TagUsage } from "app/models/tag.model";
import { of, Observable, map, catchError, throwError } from "rxjs";
import { AuthenticationService } from "../authentication/authentication.service";
import { LoggingService } from "../logging/logging.service";

@Injectable()
export class TagService {
  timestamp: number;
  url: string = "";

  constructor(
    private http: HttpClient,
    private loggingService: LoggingService,
    private authenticationService: AuthenticationService
  ) {
    this.url = this.authenticationService.getWebserviceURL("tag");
  }

  getTagsForObject(guid: string, type: EntityType): Observable<TagSearch[]> {
    if (guid == null) {
      return of([]);
    }

    return this.http
      .get(this.url + "GetTagsForObject?objectId=" + guid + "&objectType=" + type, {
        headers: this.authenticationService.headers,
      })
      .pipe(
        map((data) => this.parseTagSearchResponse(data)),
        catchError(this.handleError)
      );
  }

  getTagsWithUsage(): Observable<TagUsage[]> {
    return this.http.get(this.url, { headers: this.authenticationService.headers }).pipe(
      map((data) => this.parseTagUsageResponse(data)),
      catchError(this.handleError)
    );
  }

  getObjectsByName(tag): Observable<TagSearch[]> {
    return this.http.get(this.url + "GetMatches?Tags=" + tag, { headers: this.authenticationService.headers }).pipe(
      map((data) => this.parseTagSearchResponse(data)),
      catchError(this.handleError)
    );
  }

  saveTag(tag: string, objectId: string, objectType: EntityType): Observable<boolean> {
    const body = {
      name: tag,
      objectId: objectId,
      objectType: objectType,
    };

    console.log("posting tag " + tag + " for object " + objectId + " of type " + objectType);
    return this.http
      .post(this.url + "PostTag", body, { headers: this.authenticationService.headers })
      .pipe(
        map((data) => data === true),
        catchError(this.handleError)
      );
  }

  removeTag(tag: string, objectId: string, objectType: EntityType): Observable<boolean> {
    const body = {
      name: tag,
      objectId: objectId,
      objectType: objectType,
    };

    console.log("removing tag " + tag + " for object " + objectId + " of type " + objectType);
    return this.http
      .post(this.url + "RemoveTag", body, { headers: this.authenticationService.headers })
      .pipe(
        map((data) => data === true),
        catchError(this.handleError)
      );
  }

  private handleError(error: Response) {
    return throwError(() => error);
  }

  parseTagSearchResponse(json: any): TagSearch[] {
    this.loggingService.log(this.constructor.name, "Retrieved " + json.length + " tags.");

    const tags: TagSearch[] = [];

    json.forEach((item) => {
      const tag = this.parseTagSearchResponseDetails(item);
      tags.push(tag);
    });

    this.timestamp = new Date().getTime();
    return tags;
  }

  parseTagSearchResponseDetails(item): TagSearch {
    const tag = new TagSearch();
    tag.id = item.id;
    tag.name = item.name;
    tag.objectId = item.objectId;
    tag.objectName = item.objectName;
    tag.objectType = item.objectType;

    return tag;
  }

  parseTagUsageResponse(json: any): TagUsage[] {
    this.loggingService.log(this.constructor.name, "Retrieved " + json.length + " tags.");

    const tags: TagUsage[] = [];

    json.forEach((item) => {
      const tag = this.parseTagUsageResponseDetails(item);
      tags.push(tag);
    });

    this.timestamp = new Date().getTime();
    return tags;
  }

  parseTagUsageResponseDetails(item): TagUsage {
    const tag = new TagUsage();
    tag.id = item.id;
    tag.name = item.name;
    tag.useCount = item.useCount;
    tag.nameWithCount = item.name + " (" + item.useCount + ")";

    return tag;
  }
}
