import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AppUser } from "app/models/user.model";
import { UserService } from "app/services/users/user.service";

@Component({
  selector: "fh-unsubscribe",
  templateUrl: "unsubscribe.template.html",
})
export class UnsubscribeComponent implements OnInit {
  unsubscribeSuccess: boolean;
  user: AppUser;
  loading: boolean;

  constructor(private route: ActivatedRoute, private userService: UserService) {}

  ngOnInit(): void {
    this.loading = true;
    this.route.params.subscribe((params) => {
      this.userService.unsubscribeFromEmailReport(params["id"]).subscribe((result) => {
        if (result) {
          this.unsubscribeSuccess = true;
          this.user = result;
        } else {
          this.unsubscribeSuccess = false;
        }
        this.loading = false;
      });
    });
  }
}
