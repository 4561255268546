import { HttpClient } from "@angular/common/http";
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Trigger } from "app/models/trigger.model";
import { AuthenticationService } from "app/services/authentication/authentication.service";
import { NotificationLogService } from "app/services/notificationlog/notificationlog.service";
import { TriggerService } from "app/services/triggers/triggers.service";
import { BsDaterangepickerConfig } from "ngx-bootstrap/datepicker";

// Moment timezone
import Moment from "moment-timezone";
import { getDefaultDpConfig } from "app/common/gridhelper";
window["moment"] = Moment;

@Component({
  selector: "fh-trigger-notifications",
  templateUrl: "notifications.template.html",
})
export class TriggerNotificationsViewComponent implements OnInit {
  sub;
  trigger = new Trigger();
  triggerId;
  loading = false;
  loadingNotifications = false;

  error: any;
  success: any;
  notifications = [];

  selectedNotification;

  currentPage;
  filter;

  // Daterange
  public dpConfig: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();
  daterangepickerModel: Date[];

  permissions: {};
  timezoneIana: string;

  constructor(
    private authenticationService: AuthenticationService,
    private cd: ChangeDetectorRef,
    private http: HttpClient,
    private notificationLogService: NotificationLogService,
    private triggerService: TriggerService,
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService
  ) {
    this.permissions = this.authenticationService.permissions;

    this.trigger = null;

    this.timezoneIana = this.authenticationService.getTimeZoneIana();

    this.daterangepickerModel = [
      Moment().subtract(1, "month").startOf("day").toDate(),
      Moment().add(0, "days").endOf("day").toDate(),
    ];

    this.dpConfig = getDefaultDpConfig(this.authenticationService);
  }

  dateChanged(event) {
    this.loadingNotifications = true;
    this.error = null;

    if (event !== null) {
      this.daterangepickerModel = event;
    }

    this.notificationLogService
      .getNotificationLogByTriggerId(
        this.trigger.id,
        Moment(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf("day"),
        Moment(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf("day")
      )
      .subscribe({
        next: (result) => {
          this.notifications = result;
          this.loadingNotifications = false;
          this.cd.markForCheck();
        },
        error: (error) => {
          this.error = error;
          this.loadingNotifications = false;
          this.cd.markForCheck();
        },
      });
  }

  ngOnInit(): void {
    this.loading = true;
    this.loadingNotifications = true;
    this.trigger = new Trigger();
    this.trigger.id = "";

    this.sub = this.route.params.subscribe({
      next: (params) => {
        const id = params["id"];

        this.triggerId = id;
        this.triggerService.getTriggerById(id).subscribe((trigger) => {
          this.trigger = trigger;

          if (this.trigger == null) {
            this.router.navigate(["/Notifications/Triggers/Overview"]);
          }

          this.loading = false;

          this.dateChanged(null);
        });
      },
      error: (error) => {
        this.error = error;
        this.error.statusText = "Error fetching Trigger";

        setTimeout(() => {
          this.router.navigate(["/Notifications/Triggers/Overview"]);
        }, 3000);
      },
    });
  }
}
