<div class="col-lg-12">
    <div class="horizontalMenuWrapper">
        <ul>
            <li [ngClass]="{'active': activeRoute('Geofences/Overview')}">
                <a href="/#/Geofences/Overview/"
                    ><i class="fa fa-fw fa-draw-polygon"></i>
                    <div>{{ 'general.overview' |translate }}</div>
                </a>
            </li>
            <li [ngClass]="{'active': activeRoute('GeofenceGroups') || activeRoute('GeofenceGroupDetails')}">
                <a href="/#/GeofenceGroups/Overview/"
                    ><i class="fas fa-fw fa-layer-group"></i>
                    <div>{{ 'general.groups' | translate }}</div>
                </a>
            </li>
        </ul>
    </div>
    <div class="horizontalMenuWrapper" style="float: right">
        <ul>
            <ng-content></ng-content>
        </ul>
    </div>
</div>
<div class="col-lg-12" style="padding: 5px" *ngIf="isGeofenceGroupDetails()">
    <div class="horizontalMenuSubWrapper">
        <ul>
            <li [ngClass]="{active: activeRoute('GeofenceGroupDetails/Index')}">
                <a [routerLink]="['/GeofenceGroupDetails/Index/', activeId]">
                    <i class="fas fa-fw fa-layer-group"></i>
                    {{ 'menu.details' | translate }}
                </a>
            </li>
            <li [ngClass]="{active: activeRoute('GeofenceGroupDetails/Log')}">
                <a [routerLink]="['/GeofenceGroupDetails/Log/', activeId]">
                    <i class="fas fa-fw fa-file-alt"></i>
                    {{ 'menu.log' | translate }}
                </a>
            </li>
        </ul>
    </div>
</div>
<div style="clear: both"></div>
