import { IssueTypeEnums, PermissionsBitmask } from "app/common/enums";
import Moment from "moment-timezone";

export function arrayFromMask(nMask, permissions) {
  // nMask must be between -2147483648 and 2147483647
  if (nMask > 0x7fffffff || nMask < -0x80000000) {
    throw new TypeError("arrayFromMask - out of range");
  }

  permissions = Object.keys(PermissionsBitmask)
    .filter((k) => typeof PermissionsBitmask[k] === "number")
    .map((n) => ({ name: n, value: PermissionsBitmask[n], isEnabled: false }));

  permissions.forEach((permission) => {
    permission.isEnabled = (nMask & permission.value) == permission.value;
  });

  return permissions;
}

export function fixDate(d): Date {
  const date = Moment(d).startOf("day");
  return date.utcOffset(0, true).toDate();
}

export function createMask(thePermissionArray) {
  let mask = 0;

  thePermissionArray.forEach((permission) => {
    if (permission.isEnabled == true) {
      mask = mask | permission.value;
    }
  });

  return mask;
}

export function formatViolationThreshold(translateService, issueType, threshold, isUpperThreshold = false) {
  let violationThreshold = threshold;

  if (threshold == null) {
    return threshold;
  }

  if (
    issueType === IssueTypeEnums.OutlierDistance ||
    issueType === IssueTypeEnums.TooMuchDistancePerMessage ||
    issueType === IssueTypeEnums.TooMuchDistance
  ) {
    // format km
    violationThreshold = Math.round(threshold / 1000) + " " + translateService.instant("abbreviation.kiloMeters");
  } else if (
    issueType === IssueTypeEnums.HighDataConsumptionMinimumMotion ||
    issueType === IssueTypeEnums.TooFewLocations ||
    issueType === IssueTypeEnums.TooManyLocations ||
    issueType === IssueTypeEnums.FarTooManyMessages
  ) {
    // format # messages
    violationThreshold = threshold + " " + translateService.instant("general.messages");
  } else if (issueType === IssueTypeEnums.BackupBatteryTooLow || issueType === IssueTypeEnums.BatteryPowerTooLow) {
    // format volt
    violationThreshold = threshold + " " + translateService.instant("general.volt");
  } else if (
    issueType === IssueTypeEnums.LooseConnectionOrIgnitionWire ||
    issueType === IssueTypeEnums.TooManyPowerlossEvents
  ) {
    // format # flips
    violationThreshold = threshold + " " + translateService.instant("general.flips");
  } else if (issueType === IssueTypeEnums.BadGpsReception && isUpperThreshold) {
    violationThreshold = threshold + " " + translateService.instant("chart.hdop");
  } else if (
    issueType === IssueTypeEnums.BadGpsReception ||
    issueType === IssueTypeEnums.GpsDrift ||
    issueType === IssueTypeEnums.MissingDriver ||
    issueType === IssueTypeEnums.DelayedMessages
  ) {
    // format % of messages
    violationThreshold = threshold + " " + translateService.instant("general.percentageOfMessages");
  }

  return violationThreshold;
}

export function humanizeTimespan(translateService, value) {
  let result = "";
  let originalValue = value;
  const hourDuration = 60 * 60;
  const minuteDuration = 60;
  let hourValue = 0;
  let minuteValue = 0;
  let secondValue = 0;

  while (originalValue >= hourDuration) {
    hourValue += 1;
    originalValue = originalValue - hourDuration;
  }
  while (originalValue >= minuteDuration) {
    minuteValue += 1;
    originalValue = originalValue - minuteDuration;
  }
  secondValue += Math.round(originalValue);

  if (hourValue > 0) {
    result += hourValue + translateService.instant("abbreviation.hours");
  }
  if (minuteValue > 0) {
    result += minuteValue + translateService.instant("abbreviation.minutes");
  }
  if (secondValue > 0) {
    result += secondValue + translateService.instant("abbreviation.seconds");
  }

  return result;
}

export function getUTCStartOfDayDateTimeFromTimezone(date: Date, timezone: string) {
  return Moment.utc(Moment(date).clone().tz(timezone).startOf("day").utc().format("YYYY-MM-DD HH:mm:SS")).toDate();
}

export function formatFromTimezone(date: Date, timezone: string) {
  return Moment.utc(date).tz(timezone).format("LLL");
}

export function formatDetailsFromTimezone(date: Date, timezone: string) {
  return Moment.utc(date).tz(timezone).format("YYYY-MM-DD HH:mm:ss");
}

export function formatDetailsWithMinutesFromTimezone(date: Date, timezone: string) {
  return Moment.utc(date).tz(timezone).format("YYYY-MM-DD HH:mm");
}

export function fromTimezone(date: Date, timezone: string) {
  return Moment.utc(date).tz(timezone);
}

export function formatFromTimezoneWithFormat(date: Date, timezone: string, format: string) {
  return Moment.utc(date).tz(timezone).format(format);
}

export function groupByDate(object, index, dateFormat, prefix, groupByType: number) {
  var sortedObject = object?.reduce(function (val, obj) {
    const groupOn: any = Moment(obj[index]).isValid() ? Moment(obj[index]).format(dateFormat) : null;
    (val[groupOn] = val[groupOn] || []).push(obj);
    return val;
  }, {});

  var sortedKeys = [];

  if (groupByType == 2) {
    // If by day init is as moment to compare.. otherwise go by alphabeth
    sortedKeys = Object.keys(sortedObject).sort((a, b) => {
      var date1 = Moment(a);
      var date2 = Moment(b);
      return date1.toDate().getTime() - date2.toDate().getTime();
    });
  } else {
    sortedKeys = Object.keys(sortedObject).sort((a, b) => {
      var date1 = Moment(sortedObject[a][0][index]);
      var date2 = Moment(sortedObject[b][0][index]);
      return date1.toDate().getTime() - date2.toDate().getTime();
    });
  }

  var returnObject = [];

  sortedKeys.forEach((key) => {
    var dateString = key != null ? prefix + key : "No data found";
    returnObject[dateString] = sortedObject[key];
  });

  return returnObject;
}

export function groupByString(object, index, prefix) {
  var sortedObject = object?.reduce(function (val, obj) {
    const groupOn = prefix + obj[index];
    (val[groupOn] = val[groupOn] || []).push(obj);
    return val;
  }, {});

  var sortedKeys = Object.keys(sortedObject).sort((a, b) => {
    return a.localeCompare(b);
  });

  var returnObject = [];

  sortedKeys.forEach((key) => {
    returnObject[key] = sortedObject[key];
  });

  return returnObject;
}

export function formatMoney(amount, decimalCount = 2, decimal = ",", thousands = ".") {
  try {
    if (isNaN(amount)) {
      return 0;
    }

    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    const i: any = parseInt((amount = Math.abs(Number(amount)).toFixed(decimalCount))).toString();
    const j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  } catch (e) {
    console.log(e);
  }
}

export function formatDecimal(amount, decimalCount = 0, decimal = ",", thousands = ".") {
  try {
    if (isNaN(amount)) {
      return 0;
    }

    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    const i: any = parseInt((amount = Math.abs(Number(amount)).toFixed(decimalCount))).toString();
    const j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  } catch (e) {
    console.log(e);
  }
}
