import { HttpClient } from "@angular/common/http";
import { Component, OnInit, ViewChild, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Device } from "app/models/device.model";
import { DeviceService } from "../../services/device/device.service";
import { TranslateService } from "@ngx-translate/core";
import { BsDaterangepickerConfig } from "ngx-bootstrap/datepicker";
import { getDefaultDpConfig, getGridButtons, getGridLanguages } from "app/common/gridhelper";

import { AuthenticationService } from "app/services/authentication/authentication.service";
import { TripService } from "app/services/trip/trip.service";
import { DeviceEpisode } from "app/common/enums";
import { GridBase360Directive } from "app/common/360Grid.base";
import { AccountService } from "app/services/account/account.service";
import { FhChartService } from "app/services/charts/charts.service";
import { EpisodeDetailsComponent } from "../shared/usercontrols/episodeDetails.component";

// Moment
import Moment from "moment-timezone";
import { StorageHelper } from "app/common/storagehelper";
import { roundSeconds } from "app/common/globals";
import { ColorService } from "app/services/common/color.service";

window["moment"] = Moment;

@Component({
  providers: [FhChartService],
  selector: "fh-device-episodes",
  templateUrl: "episodes.template.html",
})
export class DeviceEpisodesViewComponent extends GridBase360Directive implements OnInit, OnDestroy {
  @ViewChild(EpisodeDetailsComponent, { static: false }) episodeDetailsComponent: EpisodeDetailsComponent;

  sub;
  device: Device;
  deviceId;

  loading = false;

  error: any;
  success: any;

  timezoneIana: string;

  limit = 1000;
  token: string;
  excludingColumns = ["timestamp", "episodeStart", "episodeEnd"];

  languageLoaded = false;
  episodesLoaded = false;

  // Datepicker
  public dpConfig: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();
  to: any;
  from: any;
  daterangepickerModel: any[];
  permissions: {};

  maxDate = new Date();

  randomKey: number;

  theMarker;

  permissionName = "FleetManagement_Episodes";
  constructorName = "DeviceEpisodesViewComponent";
  loadingMapData = false;
  showWiderMap = false;
  chart: any;
  loadingEpisodeDetails = false;
  trip;
  geoJsonData: any;
  geoJson: any;

  tripFeatureGroup: any;
  activeEpisode: any;

  constructor(
    private http: HttpClient,
    private cd: ChangeDetectorRef,
    private chartService: FhChartService,
    private accountService: AccountService,
    private deviceService: DeviceService,
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    private authenticationService: AuthenticationService,
    private tripService: TripService,
    storageHelper: StorageHelper,
    private colorService: ColorService
  ) {
    super(storageHelper);

    this.permissions = this.authenticationService.permissions;

    this.device = null;

    this.timezoneIana = authenticationService.getTimeZoneIana();
    this.token = authenticationService.getAuthToken();

    this.randomKey = Math.floor(Math.random() * (999999 - 100000)) + 100000;

    this.daterangepickerModel = [
      Moment().tz(this.timezoneIana).subtract(1, "weeks").startOf("day").toDate(),
      Moment().tz(this.timezoneIana).endOf("day").toDate(),
    ];

    this.dpConfig = getDefaultDpConfig(authenticationService);

    this.showWiderMap = localStorage.getItem("ShowWiderMap_Episodes") === "true";
  }

  ngOnInit() {
    this.loading = true;
    this.device = new Device();
    this.device.id = "";

    this.sub = this.route.params.subscribe(
      (params) => {
        const id = params["id"];

        this.deviceId = id;
        this.deviceService.getDeviceById(id).subscribe((device) => {
          this.device = device;

          if (this.device == null) {
            this.router.navigate(["/Devices/Overview"]);
          }

          this.loading = false;

          this.translateService.get("general.date").subscribe((value) => {
            this.initGrid();
            this.languageLoaded = true;
          });
        });
      },
      (error) => {
        this.error = error;
        this.error.statusText = "Error fetching device";

        setTimeout(() => {
          this.router.navigate(["/Devices/Overview"]);
        }, 3000);
      }
    );
  }

  dateChanged(event) {
    console.log("Changed date");
    const that = this;
    if (event !== null) {
      this.episodesLoaded = true;

      console.log(this.daterangepickerModel);

      this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.ajax
          .url(
            that.tripService.getEpisodeUrl(
              this.device.id,
              null,
              Moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf("day"),
              Moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf("day"),
              this.randomKey
            )
          )
          .load(() => (this.episodesLoaded = false));
      });
    }
  }

  initGrid(): void {
    const that = this;

    const commonExportOptions = {
      modifier: {
        page: "all",
        search: "none",
      },
      columns: ["id_export:name", ":visible[tabindex]"],
    };

    const deviceEpisodeTypes = [];
    Object.values(DeviceEpisode)
      .filter((key) => isNaN(Number(DeviceEpisode[key])))
      .forEach(function (item, index) {
        deviceEpisodeTypes.push({
          id: item.toString(),
          value: that.translateService.instant("enums.deviceEpisode." + item),
        });
      });

    this.columns = [
      {
        name: "id_export",
        data: "id",
        className: "noVis",
        title: this.translateService.instant("general.id"),
        visible: false,
      },
      {
        name: "episodeStart",
        data: "episodeStart",
        width: 130,
        title: this.translateService.instant("general.episodeStart"),
        type: "date",
        render: function (data, type, row) {
          const date = Moment.utc(data)["tz"](that.timezoneIana);
          return data
            ? '<span title=" ' + date.toLocaleString() + '">' + date.format("YYYY-MM-DD HH:mm:ss") + "</span>"
            : "-";
        },
      },
      {
        name: "episodeEnd",
        data: "episodeEnd",
        defaultContent: "-",
        title: this.translateService.instant("general.episodeEnd"),
        width: 130,
        type: "date",
        visible: false,
        render: function (data, type, row) {
          const date = Moment.utc(data)["tz"](that.timezoneIana);
          return data
            ? '<span title=" ' + date.toLocaleString() + '">' + date.format("YYYY-MM-DD HH:mm:ss") + "</span>"
            : "-";
        },
      },
      {
        name: "durationInSeconds",
        data: "durationInSeconds",
        title: this.translateService.instant("general.duration"),
        type: "num",
        render: function (data, type, row) {
          if (type === "display") {
            return roundSeconds(data);
          }
          return data ?? "0";
        },
      },
      {
        name: "timestamp",
        data: "timestamp",
        title: this.translateService.instant("general.timestamp"),
        type: "date",
        visible: false,
        width: 130,
        render: function (data, type, row) {
          const date = Moment.utc(data)["tz"](that.timezoneIana);
          return data
            ? '<span title=" ' + date.toLocaleString() + '">' + date.format("YYYY-MM-DD HH:mm:ss") + "</span>"
            : "";
        },
      },
      {
        name: "triggerName",
        data: "triggerName",
        visible: false,
        title: this.translateService.instant("general.triggerName"),
        render: function (data, type, row) {
          return data
            ? `<a class="secondary link_bolder" href='/#/Notifications/TriggerDetails/Index/${row["triggerId"]}'>${data}</a>`
            : "-";
        },
      },
      {
        name: "description",
        data: "description",
        title: this.translateService.instant("general.description"),
        render: function (data, type, row) {
          return data ?? "-";
        },
      },
      {
        name: "fkDeviceEpisodeTypeId",
        data: "fkDeviceEpisodeTypeId",
        type: "select",
        options: deviceEpisodeTypes.sort((a, b) => a.value.localeCompare(b.value)),
        title: this.translateService.instant("general.episodeType"),
        render: function (data, type, row) {
          return that.translateService.instant("enums.deviceEpisode." + data);
        },
      },
      {
        name: "maxValue",
        data: "maxValue",
        width: 70,
        type: "num",
        visible: false,
        title: this.translateService.instant("general.maxValue"),
        render: function (data, type, row) {
          return data ?? "0";
        },
      },
      {
        name: "count",
        data: "count",
        width: 70,
        type: "num",
        title: this.translateService.instant("general.count"),
        render: function (data, type, row) {
          return data ?? "0";
        },
      },
      {
        name: "location",
        data: "latitude",
        defaultContent: "",
        title: this.translateService.instant("general.location"),
        render: function (data, type, row) {
          if (row.beginLatitude != null && row.beginLongitude != null) {
            return row.beginLatitude.toFixed(4) + "/" + row.beginLongitude.toFixed(4);
          }
          return "Unknown";
        },
      },
    ];

    this.dtOptions = {
      buttons: getGridButtons(
        this.commonExportOptions,
        "episodes_overview",
        this.translateService.instant("menu.episodesoverview"),
        this.colorService
      ),
      pagingType: "simple_numbers",
      serverSide: true,
      processing: true,
      // scrollY: 500,
      // scroller: {
      //     loadingIndicator: true
      // },
      searchDelay: 2000,
      deferRender: true,
      scrollX: true,
      colReorder: { fixedColumnsLeft: 2 },
      deferLoading: 0,
      stateSave: true,
      stateSaveCallback: function (settings, data) {
        that.saveState(that.constructorName, data);
      },
      stateLoadCallback: function (_, callback) {
        (async () => {
          try {
            const columnSettings = await that.loadState(that.constructorName);
            that.searchTerm = columnSettings && columnSettings.search && columnSettings.search.search;
            return columnSettings;
          } catch (e) {
            that.error = {};
            that.error.error = e;
            that.error.statusText = "Error fetching column settings";

            return null;
          }
        })().then((result) => {
          callback(result);
        });
      },
      order: [[0, "desc"]],
      ajax: {
        beforeSend: () => {
          that.drawFilterRow();

          $(".dataTables_info").html(this.translateService.instant("grid.loadingData"));
        },
        url: that.tripService.getEpisodeUrl(
          this.device.id,
          null,
          Moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf("day"),
          Moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf("day"),
          this.randomKey
        ),
        data: (d) => {
          return d;
        },
        dataSrc: function (json) {
          return json.data;
        },
        type: "POST",
        headers: {
          Authorization: "Bearer " + that.token,
        },
      },
      initComplete: function (settings, json) {
        that.loading = false;
        that.episodesLoaded = false;

        console.log("init complete");
        that.checkFilters();
        that.drawFilterRow();
        that.loading = false;
      },
      colVis: {
        restore: this.translateService.instant("general.restore"),
        showAll: this.translateService.instant("general.showAll"),
        showNone: this.translateService.instant("general.hideAll"),
      },
      columns: this.columns,
      pageLength: 17,
      lengthMenu: [
        [10, 17, 25, 50, -1],
        [10, 17, 25, 50, this.translateService.instant("general.all")],
      ],
      language: getGridLanguages(this.translateService),
      rowCallback: (row, data) => {
        this.setMapInteraction(that, row, data);

        if (data.beginLatitude != null && data.beginLongitude != null) {
          $(row).addClass("hand");
        }
      },
    };
  }

  setMapInteraction(table, row, data) {
    const theLatLon = data;
    const that = this;

    this.activeEpisode = data;

    $(row).click(function () {
      that.episodeDetailsComponent.showOnMap(data);
    });
  }

  onWideChanged() {
    this.showWiderMap = this.episodeDetailsComponent.showWiderMap;
  }

  episodeDeleted() {
    this.dateChanged(false);

    this.error = null;
    this.success = {
      statusText: "Success",
      success: "Episode is deleted.",
    };
    this.cd.markForCheck();
  }
}
