<div class="ibox-content slick" style="position: relative">
    <table style="width: 100%" class="table table-fixed nowrap dtr-inline no-footer">
        <tbody *ngIf="!maintenanceType">
            <tr>
                <td colspan="2">
                    {{ "general.noMaintenance" | translate }}
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="maintenanceType">
            <ng-container *ngIf="formMode != 2">
                <tr [hidden]="!permissions['Resellers_View']">
                    <td class="row_opening">
                        {{ "general.resellerDescription" | translate }}
                    </td>
                    <td class="padding-readonly-correct row_content">
                        <a
                            class="secondary link_bolder"
                            href="/#/ResellerDetails/Index/{{ maintenanceType?.resellerId }}"
                            >{{ maintenanceType?.resellerDescription }}</a
                        >
                    </td>
                </tr>
                <tr [hidden]="!permissions['Accounts_View']">
                    <td class="row_opening">
                        {{ "general.account" | translate }}
                    </td>
                    <td class="row_content padding-readonly-correct">
                        <a
                            class="secondary link_bolder"
                            href="/#/AccountDetails/Index/{{ maintenanceType?.accountId }}"
                            >{{ maintenanceType?.companyName }}</a
                        >
                    </td>
                </tr>
            </ng-container>
            <ng-container *ngIf="formMode == 2">
                <tr [hidden]="!permissions['Resellers_View'] && selectedResellerId">
                    <td class="row_opening">
                        {{ "general.resellerDescription" | translate }}
                        <span class="error" *ngIf="resellerVar.invalid">*</span>
                    </td>
                    <td class="row_content" style="position: relative; overflow: visible">
                        <fh-reseller-input
                            name="reseller"
                            #resellerVar="ngModel"
                            (onChanged)="resellerChanged($event)"
                            required
                            placeholder="{{ 'general.chooseReseller' | translate }}"
                            [tableInline]="true"
                            [(ngModel)]="selectedResellerId"
                        >
                        </fh-reseller-input>
                    </td>
                </tr>
                <tr [hidden]="!permissions['Accounts_View'] && maintenanceType.accountId">
                    <td class="row_opening">
                        {{ "general.account" | translate }}
                        <span class="error" *ngIf="accountVar.invalid">*</span>
                    </td>
                    <td class="row_content" style="position: relative; overflow: visible">
                        <fh-account-input
                            name="account"
                            #accountVar="ngModel"
                            (onChanged)="accountChanged($event)"
                            required
                            [selectedResellerId]="selectedResellerId"
                            [tableInline]="true"
                            placeholder="{{
                                (selectedResellerId ? 'general.chooseAccount' : 'general.firstChooseReseller')
                                    | translate
                            }}"
                            [(ngModel)]="maintenanceType.accountId"
                        >
                        </fh-account-input>
                    </td>
                </tr>
            </ng-container>
            <tr>
                <td class="row_opening">
                    {{ "general.nameType" | translate }}
                    <span class="error" *ngIf="typeName.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input
                        name="typeName"
                        type="text"
                        [readonly]="formMode == 1"
                        class="form-control table-inline"
                        placeholder="{{ 'placeholder.nameType' | translate }}"
                        [(ngModel)]="maintenanceType.typeName"
                        required
                        minlength="4"
                        #typeName="ngModel"
                    />
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ "general.daysThreshold" | translate }}
                    <span class="error" *ngIf="daysThreshold.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input
                        name="daysThreshold"
                        type="number"
                        [readonly]="formMode == 1"
                        class="form-control table-inline"
                        placeholder="{{ 'placeholder.daysThreshold' | translate }}"
                        [(ngModel)]="maintenanceType.daysThreshold"
                        required
                        #daysThreshold="ngModel"
                    />
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ "general.kilometersThreshold" | translate }}
                    <span class="error" *ngIf="kilometersThreshold.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input
                        name="kilometersThreshold"
                        type="number"
                        [readonly]="formMode == 1"
                        placeholder="{{ 'placeholder.kilometersThreshold' | translate }}"
                        class="form-control table-inline"
                        [(ngModel)]="maintenanceType.kilometersThreshold"
                        required
                        #kilometersThreshold="ngModel"
                    />
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ "general.hoursThreshold" | translate }}
                    <span class="error" *ngIf="hoursThreshold.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input
                        name="hoursThreshold"
                        type="number"
                        [readonly]="formMode == 1"
                        placeholder="{{ 'placeholder.hoursThreshold' | translate }}"
                        class="form-control table-inline"
                        [(ngModel)]="maintenanceType.hoursThreshold"
                        required
                        #hoursThreshold="ngModel"
                    />
                </td>
            </tr>
        </tbody>
    </table>
</div>
