<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/"> <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li
                    class="breadcrumb-item"
                    *ngIf="permissions['Accounts_View'] && device?.asset && device?.asset.accountId"
                >
                    <a href="/#/AccountDetails/Index/{{device?.asset.accountId}}">{{device?.asset?.companyName}}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Devices/Overview?companyName={{device?.companyName}}"
                        >{{'general.devices' | translate }}</a
                    >
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/DeviceDetails/Index/{{device?.id}}"
                        >{{device?.asset?.name ? device?.asset?.name : device?.name}}</a
                    >
                </li>
                <li class="breadcrumb-item active">{{'general.maintenance' | translate }}</li>
            </ol>
        </nav>
    </div>
    <fh-device-details-navigation [deviceId]="deviceId" [device]="device"></fh-device-details-navigation>
</div>

<div class="wrapper wrapper-content">
    <div class="row" data-equalizer="">
        <div class="col-lg-8">
            <fh-error [error]="error" [success]="success"></fh-error>

            <div class="ibox">
                <div class="ibox-title">
                    <h5><i class="fas fa-fw fa-car-mechanic"></i> {{'general.maintenanceDue' | translate }}</h5>
                </div>
                <div class="ibox-content slick" style="position: relative; overflow-y: auto">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <table style="width: 100%" class="table nowrap dtr-inline no-footer">
                        <thead>
                            <tr>
                                <th style="width: 140px">{{ 'general.dueDate' | translate}}</th>
                                <th>{{ 'general.pastDue' | translate}}</th>
                                <th>{{ 'general.maintenanceType' | translate}}</th>
                                <th>{{ 'general.currentEngineHour' | translate}}</th>
                                <th>{{ 'general.nextMaintenanceEngineHours' | translate}}</th>
                                <th>{{ 'general.currentOdoValue' | translate}}</th>
                                <th>{{ 'general.nextMaintenanceOdo' | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngIf="maintenanceDue.length == 0 && !loading">
                                <tr>
                                    <td colspan="8">{{ 'general.noDataFound' | translate}}</td>
                                </tr>
                            </ng-container>
                            <ng-container *ngFor="let event of maintenanceDue">
                                <tr>
                                    <td>{{ event.expectedMaintenanceDate | amDateFormat: 'YYYY-MM-DD HH:mm' }}</td>
                                    <td>
                                        <span [ngClass]="event.pastDue  === 'Imminent' ? 'font-orange' : 'font-red' "
                                            >{{ event.pastDue }}</span
                                        >
                                    </td>
                                    <td>
                                        <a
                                            class="link_bolder"
                                            href="/#/Maintenances/MaintenanceTypeDetails/Index/{{event.maintenanceTypeId}}"
                                            >{{ event.typeName }}</a
                                        >
                                    </td>
                                    <td>{{ event.currentEngineHour?.toLocaleString() }}</td>
                                    <td>{{ event.nextMaintenanceEngineHours?.toLocaleString() }}</td>
                                    <td>{{ event.currentOdoValue?.toLocaleString() }}</td>
                                    <td>{{ event.nextMaintenanceOdo?.toLocaleString() }}</td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="ibox">
                <div class="ibox-title">
                    <h5><i class="fas fa-fw fa-car-mechanic"></i> {{'general.occurrences' | translate }}</h5>
                    <span class="float-right">
                        <div class="filter-form d-none d-md-block">
                            <input
                                type="text"
                                class="form-control table-inline top-filter d-none d-md-block"
                                (ngModelChange)="p.setCurrent(0)"
                                [(ngModel)]="filter"
                            />
                        </div>
                    </span>
                </div>
                <div class="ibox-content slick" style="position: relative; overflow-y: auto">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <table style="width: 100%" class="table nowrap dtr-inline no-footer">
                        <thead>
                            <tr>
                                <th></th>
                                <th style="width: 140px">{{ 'general.date' | translate}}</th>
                                <th>{{ 'general.maintenanceType' | translate}}</th>
                                <th>{{ 'general.odo' | translate }}</th>
                                <th>{{ 'general.description' | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngIf="maintenances.length == 0 && !loading">
                                <tr>
                                    <td colspan="8">{{ 'general.noDataFound' | translate}}</td>
                                </tr>
                            </ng-container>
                            <ng-container
                                *ngFor="let event of maintenances | orderBy : 'occurrenceDate':true | stringFilter: filter | paginate: { itemsPerPage: 10, currentPage: currentPage }"
                            >
                                <tr class="hand">
                                    <td style="width: 20px">
                                        <a
                                            class="btn btn-primary btn-grid"
                                            (click)="$event.stopPropagation();"
                                            href="/#/MaintenanceDetails/Index/{{event.id}}"
                                        >
                                            <span class="d-none d-md-inline-flex" style="padding-left: 7px"
                                                >{{'general.details' | translate }}</span
                                            >
                                            <i class="fas fa-fw fa-angle-right"></i>
                                        </a>
                                    </td>
                                    <td>{{ event.occurrenceDate | amDateFormat: 'YYYY-MM-DD HH:mm' }}</td>
                                    <td>{{ event.typeName }}</td>
                                    <td>
                                        {{ (event.realAssetOdo ?? 0 > 0) ? event.realAssetOdo?.toLocaleString() : '-' }}
                                    </td>
                                    <td>{{ event.description }}</td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
                <div class="ibox-footer noselect dataTables_wrapper">
                    <pagination-template #p="paginationApi" (pageChange)="currentPage = $event">
                        <div class="dataTables_paginate paging_simple_numbers" id="dataTable_paginate">
                            <ul class="pagination">
                                <li class="paginate_button page-item previous" [class.disabled]="p.isFirstPage()">
                                    <a class="page-link" (click)="p.previous()" *ngIf="!p.isFirstPage()"
                                        >{{ 'grid.previous' | translate }}</a
                                    >
                                    <a *ngIf="p.isFirstPage()">{{ 'grid.previous' | translate }}</a>
                                </li>

                                <li
                                    class="paginate_button page-item"
                                    *ngFor="let page of p.pages"
                                    [class.current]="p.getCurrent() === page.value"
                                >
                                    <a
                                        class="page-link"
                                        (click)="p.setCurrent(page.value)"
                                        *ngIf="p.getCurrent() !== page.value"
                                    >
                                        <span>{{ page.label }}</span>
                                    </a>
                                    <a
                                        class="page-link"
                                        (click)="p.setCurrent(page.value)"
                                        *ngIf="p.getCurrent() === page.value"
                                    >
                                        <span style="font-weight: bold">{{ page.label }}</span>
                                    </a>
                                </li>

                                <li class="paginate_button page-item next" [class.disabled]="p.isLastPage()">
                                    <a class="page-link" (click)="p.next()" *ngIf="!p.isLastPage()">
                                        {{ 'grid.next' | translate }}
                                    </a>
                                    <a class="page-link" *ngIf="p.isLastPage()"> {{ 'grid.next' | translate }} </a>
                                </li>
                            </ul>
                        </div>
                    </pagination-template>
                    <div style="clear: both"></div>
                </div>
            </div>
        </div>
        <div class="col-lg-4">
            <form #userForm="ngForm">
                <div class="ibox" style="position: relative">
                    <div class="ibox-title">
                        <h5><i class="fa fa-fw fa-car-mechanic"></i> {{'general.maintenance' | translate }}</h5>
                    </div>
                    <div class="ibox-content slick" style="position: relative">
                        <table style="width: 100%" class="table table-fixed nowrap dtr-inline no-footer">
                            <tbody>
                                <tr>
                                    <td class="row_opening">
                                        {{ 'general.typeName' | translate}}
                                        <span class="error" *ngIf="maintenanceType.invalid">*</span>
                                        <span>
                                            <i
                                                class="fa fa-fw fa-list pointer"
                                                title="Edit the maintenance types here"
                                                [routerLink]="['/Maintenances/MaintenanceTypes/Overview']"
                                            ></i>
                                        </span>
                                    </td>
                                    <td class="row_content" style="position: relative; overflow: visible">
                                        <fh-maintenance-type-input
                                            name="maintenanceType"
                                            #maintenanceType="ngModel"
                                            required
                                            [selectedAccountId]="maintenanceOccurrence.accountId"
                                            [assetTypeId]="device?.asset?.assetTypeId"
                                            placeholder="{{ (maintenanceOccurrence.accountId? 'general.maintenanceTypeSelector' : 'general.noMaintenanceTypesFound') | translate}}"
                                            [(ngModel)]="maintenanceOccurrence.maintenanceTypeId"
                                        >
                                        </fh-maintenance-type-input>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="row_opening">
                                        {{ 'general.occurrenceDate' | translate}}
                                        <span class="error" *ngIf="occurrenceDate.invalid">*</span>
                                    </td>
                                    <td class="row_content">
                                        <input
                                            type="text"
                                            name="occurrenceDate"
                                            #occurrenceDate="ngModel"
                                            class="form-control table-inline"
                                            [(ngModel)]="maintenanceOccurrence.occurrenceDate"
                                            autocomplete="off"
                                            placeholder="{{ 'placeholder.occurrenceDate' | translate}}"
                                            [bsConfig]="dpConfig"
                                            required
                                            bsDatepicker
                                            #occurrenceDateDp="bsDatepicker"
                                        />
                                        <span class="input-group-calendar"
                                            ><i class="fa fa-calendar-alt" (click)="occurrenceDateDp.toggle()"></i
                                        ></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="row_opening">
                                        {{ 'general.supplierName' | translate}}
                                        <span class="error" *ngIf="supplierName.invalid">*</span>
                                    </td>
                                    <td class="row_content">
                                        <input
                                            type="text"
                                            name="supplierName"
                                            class="form-control table-inline"
                                            [(ngModel)]="maintenanceOccurrence.supplierName"
                                            placeholder="{{ 'placeholder.supplierName' | translate}}"
                                            #supplierName="ngModel"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td class="row_opening">
                                        {{ 'general.localPurchaseOrderNumber' | translate}}
                                        <span class="error" *ngIf="localPurchaseOrderNumber.invalid">*</span>
                                    </td>
                                    <td class="row_content">
                                        <input
                                            type="text"
                                            name="localPurchaseOrderNumber"
                                            class="form-control table-inline"
                                            [(ngModel)]="maintenanceOccurrence.localPurchaseOrderNumber"
                                            placeholder="{{ 'placeholder.localPurchaseOrderNumber' | translate}}"
                                            #localPurchaseOrderNumber="ngModel"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td class="row_opening">
                                        {{ 'general.materialReceiptInspectionVoucherNumber' | translate}}
                                        <span class="error" *ngIf="materialReceiptInspectionVoucherNumber.invalid"
                                            >*</span
                                        >
                                    </td>
                                    <td class="row_content">
                                        <input
                                            type="text"
                                            name="materialReceiptInspectionVoucherNumber"
                                            class="form-control table-inline"
                                            placeholder="{{ 'placeholder.materialReceiptInspectionVoucherNumber' | translate}}"
                                            [(ngModel)]="maintenanceOccurrence.materialReceiptInspectionVoucherNumber"
                                            #materialReceiptInspectionVoucherNumber="ngModel"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td class="row_opening">
                                        {{ 'general.controlNumber' | translate}}
                                        <span class="error" *ngIf="controlNumber.invalid">*</span>
                                    </td>
                                    <td class="row_content">
                                        <input
                                            type="text"
                                            name="controlNumber"
                                            placeholder="{{ 'placeholder.controlNumber' | translate}}"
                                            class="form-control table-inline"
                                            [(ngModel)]="maintenanceOccurrence.controlNumber"
                                            #controlNumber="ngModel"
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <fh-maintenance-details
                        *ngIf="showProp"
                        [maintenanceOccurrence]="maintenanceOccurrence"
                        [formMode]="formModeAdd"
                        [useCompact]="true"
                        [loading]="loading"
                    >
                    </fh-maintenance-details>
                    <fh-save-footer
                        [valid]="!userForm.invalid && this.device?.asset?.id"
                        (onInsert)="onInsertMaintenance()"
                        [allowAdd]="permissions['MaintenanceEvents_Add']"
                        [formMode]="formModeAdd"
                    >
                        <span class="float-right">
                            <button
                                class="btn btn-info"
                                *ngIf="showProp"
                                (click)="showProperties()"
                                title="{{'general.hideProperties' | translate }}"
                            >
                                <i class="fa fa-fw fa-minus"></i>
                                <span class="d-none d-sm-inline-flex" style="padding-left: 8px">
                                    {{'general.hideProperties' | translate }}</span
                                >
                            </button>
                            <button
                                class="btn btn-info"
                                *ngIf="!showProp"
                                (click)="showProperties()"
                                title="{{'general.showProperties' | translate }}"
                            >
                                <i class="fa fa-fw fa-plus"></i>
                                <span class="d-none d-sm-inline-flex" style="padding-left: 2px">
                                    {{'general.showProperties' | translate }}</span
                                >
                            </button>
                        </span>
                    </fh-save-footer>
                </div>
            </form>
        </div>
    </div>
</div>
