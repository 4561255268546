<table style="width: 100%" class="table nowrap dtr-inline no-footer" *ngIf="schedule.length > 0">
    <thead>
        <tr>
            <th>{{ 'general.active' | translate }}</th>
            <th class="d-none d-sm-inline-flex">{{ 'general.day' | translate }}</th>
            <th>{{ 'general.time' | translate }}</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let set of schedule; let i = index">
            <td style="width: 30px">
                <div class="fa_checkbox" style="float: left">
                    <input
                        [id]="'checkbox_' + set.day"
                        [name]="'checkbox_' + set.day"
                        type="checkbox"
                        [disabled]="formMode == 1"
                        [(ngModel)]="set.commuting"
                        (ngModelChange)="checkSchedule()"
                    />
                    <label [for]="'checkbox_' + set.day">
                        <span class="hidden-sm hidden-md d-lg-none d-xl-none"
                            >{{ 'general.dates.small.' + set.day | translate }}</span
                        >
                    </label>
                </div>
            </td>
            <td style="width: 30px" class="d-none d-sm-inline-flex">{{ 'general.dates.' + set.day | translate }}</td>
            <td style="width: auto; padding-top: 3px; padding-bottom: 3px">
                <div
                    *ngIf="timePickerOptions[set.day]"
                    class="form-group input-group hidden-sm hidden-md d-lg-none d-xl-none"
                    style="width: 50%; float: left"
                >
                    <select
                        [id]="'start_hours_' + set.day"
                        [name]="'start_hours_' + set.day"
                        dir="rtl"
                        [(ngModel)]="timePickerOptions[set.day].start.hours"
                        (ngModelChange)="setTime(set.day)"
                        class="form-control align-right"
                    >
                        <option *ngFor="let hour of hours" [value]="hour">{{ hour }}</option>
                    </select>
                    <span class="input-group-prepend just-center align-center" style="padding: 0">
                        <div class="input-group-text">:</div>
                    </span>
                    <select
                        [id]="'start_time_' + set.day"
                        [name]="'start_time_' + set.day"
                        [(ngModel)]="timePickerOptions[set.day].start.minutes"
                        (ngModelChange)="setTime(set.day)"
                        class="form-control"
                    >
                        <option value="00">00</option>
                        <option value="30" *ngIf="timePickerOptions[set.day].end.hours !== '24'">30</option>
                    </select>
                </div>
                <div
                    *ngIf="timePickerOptions[set.day]"
                    class="form-group input-group hidden-sm hidden-md d-lg-none d-xl-none"
                    style="width: 50%; float: left"
                >
                    <select
                        [id]="'end_hours_' + set.day"
                        [name]="'end_hours_' + set.day"
                        dir="rtl"
                        [(ngModel)]="timePickerOptions[set.day].end.hours"
                        (ngModelChange)="setTime(set.day)"
                        class="form-control align-right"
                    >
                        <option *ngFor="let hour of hours" [value]="hour">{{ hour }}</option>
                    </select>
                    <span class="input-group-prepend just-center align-center" style="padding: 0">
                        <div class="input-group-text">:</div>
                    </span>
                    <select
                        [id]="'end_time_' + set.day"
                        [name]="'end_time_' + set.day"
                        [(ngModel)]="timePickerOptions[set.day].end.minutes"
                        (ngModelChange)="setTime(set.day)"
                        class="form-control"
                    >
                        <option value="00" *ngIf="timePickerOptions[set.day].end.hours !== '00'">00</option>
                        <option value="30" *ngIf="timePickerOptions[set.day].end.hours !== '24'">30</option>
                    </select>
                </div>
                <ngx-slider
                    *ngIf="timePickerOptions[set.day]"
                    [(value)]="timePickerOptions[set.day].time.start"
                    class="d-none d-sm-inline-flex"
                    [(highValue)]="timePickerOptions[set.day].time.end"
                    [options]="options"
                    (valueChange)="sliderValueChange($event, 'start', set)"
                    (highValueChange)="sliderValueChange($event, 'end', set)"
                >
                </ngx-slider>
            </td>
        </tr>
    </tbody>
</table>
