export class SensorTemplate {
  id: string;
  accountId: any;
  resellerId: any;

  companyName: any;
  resellerDescription: any;

  sensorType: any;
  name: any;
  minValue: any;
  maxValue: any;
}
