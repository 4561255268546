import { Component, Input, OnInit } from "@angular/core";
import { ControlContainer, NgForm } from "@angular/forms";
import { FormMode } from "app/common/enums";
import { Driver } from "app/models/driver.model";

@Component({
  selector: "fh-driver-wasl-details",
  templateUrl: "waslDriverDetails.component.html",
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class WaslDriverDetailsComponent implements OnInit {
  @Input() driver: Driver;
  @Input() formMode: any = FormMode.read;

  constructor() {}

  ngOnInit(): void {}
}
