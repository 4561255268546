<fh-wizard [(step)]="step" (finish)="onCheckOutFinish()">
    <fh-wizard-step title="Remarks" icon="fa-file-pdf" [validate]="groupForm">
        <div class="wizard-content">
            <form [formGroup]="groupForm">
                <table style="width: 100%" class="table nowrap dtr-inline no-footer">
                    <tbody>
                        <tr>
                            <td class="row_opening">
                                {{ 'general.customCommand' | translate }}
                                <span class="error" *ngIf="groupForm.get('customCommandId')?.errors">*</span>
                            </td>
                            <td class="row_content" style="overflow: visible">
                                <ng-select
                                    ngDefaultControl
                                    [searchable]="false"
                                    [clearable]="false"
                                    [multiple]="false"
                                    [readonly]="false"
                                    placeholder="{{ 'general.chooseCustomCommand' | translate}}"
                                    #input
                                    formControlName="customCommandId"
                                    required
                                    class="form-control table-inline"
                                >
                                    <ng-option
                                        *ngFor="let customCommand of customCommands | orderBy : 'name':false"
                                        [value]="customCommand.id"
                                    >
                                        <i class="fas fa-fw fa-tower-broadcast"></i> {{ customCommand.name }}
                                    </ng-option>
                                </ng-select>
                            </td>
                        </tr>
                        <tr>
                            <td class="row_opening">{{ 'general.wakeUpDevice' | translate }}</td>
                            <td>
                                <div class="fa_checkbox">
                                    <input id="checkbox" type="checkbox" formControlName="wakeUpDevice" />
                                    <label for="checkbox"> {{'general.wakeUpDevice' | translate }} </label>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </div>
    </fh-wizard-step>
</fh-wizard>
