import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { AssetSensor } from "app/models/sensor.model";
import { Observable, catchError, map, throwError } from "rxjs";
import { AuthenticationService } from "../authentication/authentication.service";
import { LoggingService } from "../logging/logging.service";

@Injectable()
export class SensorService {
  timestamp: number;
  url: string = "";
  urlBase: string = "";

  constructor(
    private http: HttpClient,
    private loggingService: LoggingService,
    private authenticationService: AuthenticationService
  ) {
    this.url = this.authenticationService.getWebserviceURL("Sensor");
    this.urlBase = this.authenticationService.getWebserviceURL("");
  }

  getSensorsOnAsset(assetId, sensorTypes): Observable<AssetSensor[]> {
    console.log("getting sensors from service");
    return this.http
      .get(this.urlBase + "Asset/" + assetId + "/Sensors", { headers: this.authenticationService.headers })
      .pipe(
        map((data) => this.parseResponse(data, assetId, sensorTypes)),
        catchError(this.handleError)
      );
  }

  addSensor(sensor: AssetSensor): Observable<any> {
    console.log("Post sensor");
    return this.http
      .post(this.url, sensor, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  saveSensor(sensor: AssetSensor): Observable<any> {
    console.log("Put sensor");

    return this.http
      .put(this.url + sensor.id, sensor, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  deleteSensor(sensor: AssetSensor): Observable<any> {
    console.log("Saving sensor settings");

    return this.http
      .delete(this.url + sensor.id, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  private handleError(error: Response) {
    return throwError(() => error);
  }

  parseResponse(json: any, assetId, sensorTypes): AssetSensor[] {
    this.loggingService.log(this.constructor.name, "Retrieved " + json.length + " sensortypes.");

    const sensors: AssetSensor[] = [];

    json.forEach((item) => {
      const sensor = this.parseReponseDetails(item, assetId, sensorTypes);
      sensors.push(sensor);
    });

    this.timestamp = new Date().getTime();
    return sensors;
  }

  parseReponseDetails(item, assetId, sensorTypes): AssetSensor {
    const sensor = new AssetSensor();

    sensor.id = item.id;
    sensor.assetId = item.assetId;
    sensor.calibrationPoints = item.calibrationPoints;
    sensor.sensorPosition = item.sensorPosition;
    sensor.sensorTypeId = item.sensorTypeId;
    sensor.sensorType = sensorTypes.find((x) => x.id === sensor.sensorTypeId);

    return sensor;
  }
}
