import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { getDefaultDpConfig, getGridLanguages, getGridButtons, createdCellCheckbox } from "app/common/gridhelper";
import { AuthenticationService } from "app/services/authentication/authentication.service";
import { BsDaterangepickerConfig } from "ngx-bootstrap/datepicker";
import { GridBase360Directive } from "app/common/360Grid.base";
import { forkJoin } from "rxjs";

import { getIconPath, roundAsNumber, roundAsString, roundMinutes, roundSeconds } from "app/common/globals";
import { AccountService } from "app/services/account/account.service";
import { FhChartService } from "app/services/charts/charts.service";

import * as Highcharts from "highcharts";
import { DeviceTypeService } from "app/services/devicetypes/devicetypes.service";
import { StorageHelper } from "app/common/storagehelper";
import { TriggerScheduleService } from "app/services/triggerSchedule/triggerSchedule.service";

// Moment timezone
import Moment from "moment-timezone";
import { DistanceUnitService } from "app/common/distanceunit.service";
import { ColorService } from "app/services/common/color.service";

window["moment"] = Moment;

@Component({
  providers: [FhChartService],
  selector: "fh-account-fuel",
  templateUrl: "fuel.template.html",
})
export class AccountFuelViewComponent extends GridBase360Directive implements OnInit, OnDestroy {
  Highcharts: typeof Highcharts = Highcharts;

  loading: boolean;
  asset: any;
  sub: any;
  messages: any;

  theMarker;

  excludingColumns = ["timestamp"];

  // Datepicker
  public dpConfig: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();

  to: any;
  from: any;
  daterangepickerModel: any[];
  permissions: {};
  languageLoaded: boolean;

  maxDate = new Date();
  sensors = [];
  constructorName = "AccountUtilizationViewComponent";

  filterZeroValues = true;

  timezoneIana: string;
  token: string;

  error: any;
  warning: any;
  success: any;
  limit = 1000;

  loadingLocations = false;
  previousLookupTimestamp;
  locationSubscription: any;
  loadingLocation: boolean;

  updatesActive = true;
  isLoaded = false;
  loadingCount = false;
  randomKey: number;
  distanceChartData = [];
  devices = [];
  chartDistance;
  chartLocationCount;
  chartActiveDevices;
  chartDistanceCompare;
  chartUtilization;
  kpis;

  selectedResellerId;
  selectedAccountId;
  selectedAssetGroup;
  selectedDeviceType;
  utilizationCalculationFactor = 1;
  deviceTypeOptions = [];
  deviceTypes = [];

  resellerChanged(resellerId) {
    this.selectedAccountId = null;

    this.selectedResellerId = resellerId;
  }

  accountChanged(accountId) {
    this.selectedAccountId = accountId;
    this.selectedAssetGroup = null;

    this.error = null;
    this.warning = null;

    this.dateChanged(true);
  }

  constructor(
    private accountService: AccountService,
    private distance: DistanceUnitService,
    private triggerScheduleService: TriggerScheduleService,
    private deviceTypeService: DeviceTypeService,
    private chartService: FhChartService,
    private translateService: TranslateService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    protected storageHelper: StorageHelper,
    private colorService: ColorService
  ) {
    super(storageHelper);

    this.sensors = [];

    this.timezoneIana = this.authenticationService.getTimeZoneIana();

    this.daterangepickerModel = [
      Moment().tz(this.timezoneIana).subtract(8, "days").startOf("day").toDate(),
      Moment().tz(this.timezoneIana).subtract(1, "days").startOf("day").toDate(),
    ];

    this.dpConfig = getDefaultDpConfig(authenticationService);
  }

  ngOnInit() {
    // Get all the date for dropdown boxes
    forkJoin([this.translateService.get("general.date"), this.deviceTypeService.getDeviceTypes()]).subscribe({
      next: (data) => {
        this.languageLoaded = true;
        this.loading = false;

        this.deviceTypes = data[1].filter((x) => x.deviceCount > 0);
        this.deviceTypes = this.deviceTypes.sort((a, b) => (a.modelName > b.modelName ? 1 : -1));

        this.initGrid();
      },
      error: (err) => {
        this.error = err;
        this.languageLoaded = true;
        this.loading = false;
      },
    });
  }

  actualRound(value, decimals) {
    return roundAsNumber(value, decimals);
  }

  actualRoundMinutes(value) {
    return roundMinutes(value);
  }

  actualRoundSeconds(value) {
    return roundSeconds(value);
  }

  async processData(data) {
    // Add utilization percentage
    data.forEach((device) => {
      device.utilization = (device.workingHoursInSeconds / device.totalDurationInSeconds) * 100;
      device.idlingPercentage = (device.idlingDurationInSeconds / device.workingHoursInSeconds) * 100;
    });

    if (this.deviceTypeOptions.length === 0) {
      data.forEach((device) => {
        // Check if it ha it already
        if (this.deviceTypeOptions.findIndex((x) => x.id === device.deviceTypeId) === -1) {
          const type = this.deviceTypes.find((x) => x.id === device.deviceTypeId);
          if (type) {
            this.deviceTypeOptions.push({ id: device.deviceTypeId, value: type.modelName + " - " + type.description });
          }
        }
      });

      this.deviceTypeOptions.sort((a, b) => a.value.localeCompare(b.value));
    }

    if (this.filterZeroValues) {
      data = data.filter((x) => x.active === true);
    }

    if (this.selectedAssetGroup) {
      data = data.filter((x) => x.assetGroups?.some((ag) => this.selectedAssetGroup === +ag.id));
    }

    if (this.selectedDeviceType) {
      data = data.filter((x) => +x.deviceTypeId === +this.selectedDeviceType);
    }

    if (this.utilizationCalculationFactor) {
      let utilizationCalculator = 168 / 168;

      if (this.utilizationCalculationFactor === 2) {
        utilizationCalculator = 168 / 40;
      }

      let workingHours = 40;
      if (this.utilizationCalculationFactor === 3) {
        await this.accountService.getAccountById(this.selectedAccountId).subscribe((account) => {
          if (account.defaultScheduleId !== undefined) {
            this.triggerScheduleService
              .getTriggerScheduleById(account.defaultScheduleId)
              .toPromise()
              .then((result) => {
                if (result && result.schedule.length > 0) {
                  workingHours = 0;
                  result.schedule.forEach((element) => {
                    if (element.commuting) {
                      const timeStart = new Date("01/01/2007 " + element.startTime).getTime();
                      const timeEnd = new Date("01/01/2007 " + element.endTime).getTime();

                      const hourDiff = (timeEnd - timeStart) / 1000 / 3600;
                      workingHours += hourDiff;
                    }
                  });

                  utilizationCalculator = 168 / workingHours;
                }
              });
          }
        });
      }

      data.forEach((device) => {
        if (this.utilizationCalculationFactor === 2) {
          device.utilization = device.utilization * utilizationCalculator;
        }

        if (this.utilizationCalculationFactor === 3) {
          device.utilization = device.utilization * utilizationCalculator;
        }

        if (device.utilization > 100) {
          device.utilizationOverUse = device.utilization - 100;
        } else {
          device.utilizationOverUse = null;
        }
      });
    }

    // Calculate totals
    this.kpis = {
      assetCount: data.length,
      assetCountWithTrips: data.filter((x) => x.tripCount > 0).length,
      tripCount: data.reduce((partial_sum, d) => partial_sum + d.tripCount, 0),
      totalDistance: data.reduce((partial_sum, d) => partial_sum + d.segmentsDistance, 0),
      segmentsDistancePrivate: data.reduce((partial_sum, d) => partial_sum + d.segmentsDistancePrivate, 0),
      totalDuration: data.reduce((partial_sum, d) => partial_sum + d.tripDurationInSeconds, 0),
      totalIdling: data.reduce((partial_sum, d) => partial_sum + d.idlingDurationInSeconds, 0),
      utilization: data.reduce((partial_sum, d) => partial_sum + d.utilization, 0),
      fuelUsedWhileDriving: data.reduce((partial_sum, d) => partial_sum + d.fuelUsedWhileDriving, 0),
      fuelLostWhileIdling: data.reduce((partial_sum, d) => partial_sum + d.fuelLostWhileIdling, 0),
      fuelUsedTotal: data.reduce((partial_sum, d) => partial_sum + d.fuelUsedTotal, 0),
    };

    const theDataUtilization = [];
    const theContentUtilization = [];
    const categoriesUtilization = [];

    const theDataDistance = [];
    const theContentDistance = [];
    const categoriesDistance = [];

    data.sort((a, b) => (a.utilization < b.utilization ? 1 : -1));
    data.forEach((device) => {
      categoriesUtilization.push(device.assetName);
      theContentUtilization.push({
        y: roundAsNumber(device.utilization, 1),
        deviceId: device.deviceId,
        hours: Math.round(device.tripDurationInSeconds / 3600),
      });
    });

    data.sort((a, b) => (a.segmentsDistance < b.segmentsDistance ? 1 : -1));
    data.forEach((device) => {
      categoriesDistance.push(device.assetName);
      theContentDistance.push({ y: roundAsNumber(device.segmentsDistance, 0), deviceId: device.deviceId });
    });

    theDataUtilization.push({
      data: theContentUtilization,
      type: "column",
      name: "Efficiency",
      turboThreshold: 5000,
    });

    theDataDistance.push({
      data: theContentDistance,
      type: "column",
      name: "Distance",
      turboThreshold: 5000,
    });

    this.chartUtilization = this.chartService.generateUtilizationScoreChart(
      theDataUtilization,
      categoriesUtilization,
      null,
      " %",
      true
    );
    this.chartDistanceCompare = this.chartService.generateUtilizationScoreChart(
      theDataDistance,
      categoriesDistance,
      null,
      " km"
    );

    return data;
  }

  dateChanged(event) {
    console.log("Changed date");
    if (event !== null) {
      this.loadingLocations = true;

      this.error = null;
      this.warning = null;

      this.getLocationCount();

      this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.ajax.reload();
      });
    } else {
      this.getLocationCount();
    }
  }

  getLocationCount() {
    this.loadingCount = true;

    if (this.selectedAccountId == null) {
      return;
    }

    this.accountService
      .getLocationCount(
        this.selectedAccountId,
        Moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf("day"),
        Moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf("day"),
        null,
        null,
        null,
        null,
        null
      )
      .subscribe({
        next: (result) => {
          this.loadingCount = false;

          if (result.length === 0) {
            this.warning = {};
            this.warning.warning = "No data found for selection.";
            this.warning.statusText = "Warning";
          } else {
            this.warning = null;
          }

          result = result.sort((a, b) => (a.summaryDate < b.summaryDate ? -1 : 1));
          this.distanceChartData = result;
          this.generateChartLocationCountChart(this.distanceChartData);
        },
        error: (error) => {
          this.loadingCount = false;
          this.error = error;
        },
      });
  }

  // Generate chart locationcount
  generateChartLocationCountChart(data) {
    const theLocationDataIgnitionOn = [];
    const theLocationDataIgnitionOff = [];
    const theLocationDataEvents = [];
    const theDistanceData = [];
    const theCategories = [];
    const theNonCommunicatingCountData = [];
    const theComminicatingButNotMovingAssetCountData = [];
    const theMovingAssetCountData = [];

    data.forEach((value) => {
      theLocationDataIgnitionOn.push({
        x: Moment.utc(value.summaryDate).toDate().getTime(),
        y: value.ignitionCount,
        clientData: value.customerId,
      });
      theLocationDataIgnitionOff.push({
        x: Moment.utc(value.summaryDate).toDate().getTime(),
        y: value.locationCount - value.ignitionCount,
        clientData: value.customerId,
      });
      theLocationDataEvents.push({
        x: Moment.utc(value.summaryDate).toDate().getTime(),
        y: value.eventCount,
        clientData: value.customerId,
      });
      theDistanceData.push({
        x: Moment.utc(value.summaryDate).toDate().getTime(),
        y: Math.round((value.distanceInMeters + Number.EPSILON) / 1000),
        clientData: value.customerId,
      });
      theNonCommunicatingCountData.push({
        x: Moment.utc(value.summaryDate).toDate().getTime(),
        y: roundAsNumber(value.assetCount - value.deviceCommunicatingCount, 0),
        clientData: value.customerId,
      });
      theComminicatingButNotMovingAssetCountData.push({
        x: Moment.utc(value.summaryDate).toDate().getTime(),
        y: roundAsNumber(value.deviceCommunicatingCount - value.deviceMovingCount, 0),
        clientData: value.customerId,
      });
      theMovingAssetCountData.push({
        x: Moment.utc(value.summaryDate).toDate().getTime(),
        y: roundAsNumber(value.deviceMovingCount, 0),
        clientData: value.customerId,
      });
    });

    const theChartDataLocationCount = [
      {
        name: "Ignition on",
        data: theLocationDataIgnitionOn,
        type: "area",
        dashStyle: "dash",
        fillOpacity: 0.5,
        opacity: 1,
        marker: {
          enabled: false,
          lineWidth: 1,
          symbol: "square",
        },
      },
      {
        name: "Ignition off",
        data: theLocationDataIgnitionOff,
        type: "area",
        dashStyle: "dash",
        fillOpacity: 0.5,
        opacity: 1,
        marker: {
          enabled: false,
          lineWidth: 1,
          symbol: "square",
        },
      },
      {
        name: "Events",
        data: theLocationDataEvents,
        type: "area",
        dashStyle: "dash",
        fillOpacity: 0.5,
        opacity: 1,
        marker: {
          enabled: false,
          lineWidth: 1,
          symbol: "square",
        },
      },
    ];

    const theChartDataDistance = [
      {
        name: "Km",
        data: theDistanceData,
        yAxis: 0,
        dashStyle: "dash",
        fillOpacity: 0.5,
        opacity: 1,
        type: "areaspline",
      },
    ];

    const theChartDataDeviceCount = [
      {
        name: "Movement",
        data: theMovingAssetCountData,
        yAxis: 0,
        type: "area",
        dashStyle: "dash",
        fillOpacity: 0.5,
        opacity: 1,
        marker: {
          enabled: false,
          lineWidth: 1,
          symbol: "square",
        },
      },
      {
        name: "No movement",
        data: theComminicatingButNotMovingAssetCountData,
        yAxis: 0,
        type: "area",
        dashStyle: "dash",
        fillOpacity: 0.5,
        opacity: 1,
        marker: {
          enabled: false,
          lineWidth: 1,
          symbol: "square",
        },
      },
      {
        name: "Non communicating",
        data: theNonCommunicatingCountData,
        yAxis: 0,
        type: "area",
        dashStyle: "dash",
        fillOpacity: 0.5,
        opacity: 1,
        marker: {
          enabled: false,
          lineWidth: 1,
          symbol: "square",
        },
      },
    ];

    this.chartLocationCount = this.chartService.generateColumnChartDates(
      theChartDataLocationCount,
      {},
      theCategories,
      undefined,
      "normal",
      true
    );
    this.chartDistance = this.chartService.generateColumnChartDates(
      theChartDataDistance,
      {},
      theCategories,
      undefined,
      "normal",
      false,
      " km"
    );
    this.chartActiveDevices = this.chartService.generateColumnChartDates(
      theChartDataDeviceCount,
      {},
      theCategories,
      undefined,
      "normal",
      true
    );
  }

  initGrid(): void {
    console.log("Init grid");

    const commonExportOptions = {
      modifier: {
        page: "all",
        search: "none",
      },
      columns: ["id_export:name", ":visible[tabindex]"],
    };

    this.columns = [
      {
        name: "deviceId",
        data: "deviceId",
        visible: false,
        defaultContent: "-",
        title: this.translateService.instant("general.deviceId"),
      },
      {
        name: "assetId",
        data: "assetId",
        visible: false,
        defaultContent: "-",
        title: this.translateService.instant("general.assetId"),
      },
      {
        name: "accountId",
        data: "accountId",
        visible: false,
        defaultContent: "-",
        title: this.translateService.instant("general.accountId"),
      },
      {
        name: "assetName",
        data: "assetName",
        defaultContent: "-",
        title: this.translateService.instant("general.assetName"),
        render: (data, type, row) => {
          const generalDetails = this.translateService.instant("general.details");

          if (type === "display") {
            const imgSrc =
              row.iconId > 0
                ? getIconPath(row.iconId)[1]
                : "/assets/images/icons/vista/Trucks/32x32/TankerTruck_Black.png";
            const icon =
              row.iconId &&
              `<img style="height: 32px; width: 32px; margin-top: -20px; margin-bottom: -10px;" src="${imgSrc}">&nbsp;&nbsp;`;

            return `
                <a class='secondary link_bolderd' title='${generalDetails}' href='/#/DeviceDetails/Index/${row.deviceId}'>${icon}${data}</a>`;
          } else {
            return data;
          }
        },
      },
      {
        name: "unitId",
        data: "unitId",
        defaultContent: "-",
        title: this.translateService.instant("general.unitId"),
      },
      {
        name: "active",
        data: "active",
        type: "checkBox",
        defaultContent: "",
        title: this.translateService.instant("general.active"),
        visible: true,
        createdCell: createdCellCheckbox,
        width: "40",
      },
      {
        name: "vehicleType",
        data: "vehicleType",
        title: this.translateService.instant("general.vehicleType"),
        render: (data, type, row) => {
          return this.translateService.instant("enums.vehicleType." + data);
        },
      },
      {
        name: "segmentsDistance",
        data: "segmentsDistance",
        type: "num",
        defaultContent: "0",
        title: this.translateService.instant("general.segmentsDistance"),
        render: (data, type, row) => {
          if (type && type === "display") {
            return data
              ? roundAsNumber(data, 0).toLocaleString() +
                  " " +
                  this.translateService.instant(this.distance.getDistanceUnit())
              : "-";
          } else {
            return data;
          }
        },
      },
      {
        name: "totalDurationInSeconds",
        data: "totalDurationInSeconds",
        visible: false,
        title: this.translateService.instant("general.totalDuration"),
        render: (data, type, row) => {
          if (type && type === "display") {
            return data ? roundSeconds(data) : "-";
          } else {
            return data;
          }
        },
      },
      {
        name: "workingHoursInSeconds",
        data: "workingHoursInSeconds",
        title: this.translateService.instant("general.workingHours"),
        render: (data, type, row) => {
          if (type && type === "display") {
            return data ? roundSeconds(data) : "-";
          } else {
            return data;
          }
        },
      },
      {
        name: "pureDriving",
        data: "pureDrivingDurationInSeconds",
        title: this.translateService.instant("general.pureDriving"),
        render: (data, type, row) => {
          if (type && type === "display") {
            return data ? roundSeconds(data) : "-";
          } else {
            return data;
          }
        },
      },
      {
        name: "idlingDurationInSeconds",
        data: "idlingDurationInSeconds",
        title: this.translateService.instant("general.idlingDuration"),
        render: (data, type, row) => {
          if (type && type === "display") {
            return data ? roundSeconds(data) : "-";
          } else {
            return data;
          }
        },
      },
      {
        name: "idlingPercentage",
        data: "idlingDurationInSeconds",
        title: this.translateService.instant("general.idlingPercentage"),
        render: (data, type, row) => {
          if (type && type === "display") {
            return data
              ? roundAsString((row.idlingDurationInSeconds / row.workingHoursInSeconds) * 100, 2) + " %"
              : "-";
          } else {
            return data;
          }
        },
      },
      {
        name: "fuelUsedWhileDriving",
        data: "fuelUsedWhileDriving",
        defaultContent: "-",
        title: this.translateService.instant("general.fuelUsedWhileDriving"),
        render: (data, type, row) => {
          if (type && type === "display") {
            return data ? roundAsNumber(data, 1).toLocaleString() + " L" : "-";
          } else {
            return data;
          }
        },
      },
      {
        name: "fuelLostWhileIdling",
        data: "fuelLostWhileIdling",
        defaultContent: "-",
        title: this.translateService.instant("general.fuelLostWhileIdling"),
        render: (data, type, row) => {
          if (type && type === "display") {
            return data ? roundAsNumber(data, 1).toLocaleString() + " L" : "-";
          } else {
            return data;
          }
        },
      },
      {
        name: "fuelLostPercentage",
        data: "fuelLostPercentage",
        defaultContent: "-",
        title: this.translateService.instant("general.fuelLostPercentage"),
        render: (data, type, row) => {
          if (type && type === "display") {
            return data ? roundAsNumber(data, 1).toLocaleString() + " %" : "-";
          } else {
            return data;
          }
        },
      },
      {
        name: "fuelUsedTotal",
        data: "fuelUsedTotal",
        defaultContent: "-",
        title: this.translateService.instant("general.fuelUsedTotal"),
        render: (data, type, row) => {
          if (type && type === "display") {
            return data ? roundAsNumber(data, 1).toLocaleString() + " L" : "-";
          } else {
            return data;
          }
        },
      },
      {
        name: "totalEmissionParticlesLight",
        data: "totalEmissionParticlesLight",
        defaultContent: "-",
        title: this.translateService.instant("general.totalEmissionParticlesLight"),
        render: (data, type, row) => {
          if (type && type === "display") {
            return data ? roundAsNumber(data, 1).toLocaleString() + "" : "-";
          } else {
            return data;
          }
        },
      },
      {
        name: "totalEmissionParticlesHeavy",
        data: "totalEmissionParticlesHeavy",
        defaultContent: "-",
        title: this.translateService.instant("general.totalEmissionParticlesHeavy"),
        render: (data, type, row) => {
          if (type && type === "display") {
            return data ? roundAsNumber(data, 1).toLocaleString() + "" : "-";
          } else {
            return data;
          }
        },
      },
      {
        name: "totalConsumptionMixed",
        data: "totalConsumptionMixed",
        defaultContent: "-",
        title: this.translateService.instant("general.totalConsumptionMixed"),
        render: (data, type, row) => {
          if (type && type === "display") {
            return data ? roundAsNumber(data, 1).toLocaleString() + " L" : "-";
          } else {
            return data;
          }
        },
      },
      {
        name: "assetGroups",
        data: "assetGroups",
        title: this.translateService.instant("general.assetGroups"),
        render: (data, type, row) => {
          if (type && type === "display") {
            return data
              ? data
                  .map((k) => {
                    return k.name;
                  })
                  .join(", ")
              : "-";
          } else {
            return null;
          }
        },
      },
    ];

    this.dtOptions = {
      buttons: getGridButtons(
        this.commonExportOptions,
        "utilization_overview",
        this.translateService.instant("menu.messagesoverview"),
        this.colorService
      ),
      pagingType: "simple_numbers",
      serverSide: false,
      processing: false,
      scrollX: true,
      colReorder: { fixedColumnsLeft: 1 },
      order: [[0, "desc"]],
      stateSave: false,
      ajax: (dataTablesParameters: any, callback) => {
        $(".dataTables_info").html(this.translateService.instant("grid.loadingData"));

        this.loading = true;
        this.accountService
          .getAccountUtilization(
            this.selectedAccountId,
            Moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf("day"),
            Moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf("day")
          )
          .subscribe({
            next: async (resp) => {
              this.devices = await this.processData(resp);
              this.loading = false;
              callback({
                recordsTotal: resp.length,
                recordsFiltered: resp.length,
                data: this.devices,
              });
            },
            error: (error) => {
              this.success = null;
              this.error = error;
              this.error.statusText = "Error fetching data";
              this.loading = false;
            },
          });
      },
      initComplete: (settings, json) => {
        this.loading = false;
        this.loadingLocations = false;

        console.log("init complete");
        this.checkFilters();
        this.drawFilterRow();
        this.loading = false;
      },
      colVis: {
        restore: this.translateService.instant("general.restore"),
        showAll: this.translateService.instant("general.showAll"),
        showNone: this.translateService.instant("general.hideAll"),
      },
      columns: this.columns,
      pageLength: 25,
      lengthMenu: [
        [10, 17, 25, 50, 200, -1],
        [10, 17, 25, 50, 200, this.translateService.instant("general.all")],
      ],
      language: getGridLanguages(this.translateService),
      rowCallback: (row, data) => {},
    };
  }
}
