import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { AuthenticationService } from "../authentication/authentication.service";
import { LoggingService } from "../logging/logging.service";
import { throwError } from "rxjs/internal/observable/throwError";
import { Observable, catchError, map } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { Schedule } from "app/models/commutingSchedule.model";
import { SensorTemplate } from "app/models/sensorTemplate.model";

@Injectable()
export class SensorTemplateService {
  base_url = "";
  url = "";

  constructor(
    private http: HttpClient,
    private loggingService: LoggingService,
    private authenticationService: AuthenticationService,
    private translateService: TranslateService
  ) {
    this.base_url = this.authenticationService.getWebserviceURL("");
    this.url = this.authenticationService.getWebserviceURL("sensorTemplate");
  }

  getPagingUrl() {
    return this.url + `Paging`;
  }

  getSensorTemplatesByAccount(accountId: string): Observable<any> {
    return this.http
      .get(this.base_url + "Account/" + accountId + "/SensorTemplates", {
        headers: this.authenticationService.headers,
      })
      .pipe(
        map((data: any) => {
          return this.parseResponse(data);
        }),
        catchError(this.handleError)
      );
  }

  getSensorTemplateById(sensorTemplateId: any): Observable<any> {
    return this.http
      .get(this.url + sensorTemplateId, {
        headers: this.authenticationService.headers,
      })
      .pipe(
        map((data: any) => {
          return this.parseReponseDetails(data);
        }),
        catchError(this.handleError)
      );
  }

  deleteSensorTemplate(sensorTemplate: SensorTemplate) {
    return this.http
      .delete(this.url + sensorTemplate.id, {
        headers: this.authenticationService.headers,
      })
      .pipe(catchError(this.handleError));
  }

  saveSensorTemplate(sensorTemplate: SensorTemplate): Observable<any> {
    return this.http
      .post(this.url, sensorTemplate, {
        headers: this.authenticationService.headers,
      })
      .pipe(catchError(this.handleError));
  }

  updateSensorTemplate(sensorTemplate: any) {
    return this.http
      .put(this.url + sensorTemplate.id, sensorTemplate, {
        headers: this.authenticationService.headers,
      })
      .pipe(catchError(this.handleError));
  }

  private handleError(error: Response) {
    console.log(error);
    return throwError(error);
  }

  parseResponse(json: any) {
    this.loggingService.log(this.constructor.name, "Retrieved " + json.length + " SensorTemplates.");

    const sensorTemplates: SensorTemplate[] = [];

    json.forEach((item) => {
      const sensorTemplate = this.parseReponseDetails(item);
      sensorTemplates.push(sensorTemplate);
    });

    return sensorTemplates;
  }

  parseReponseDetails(item) {
    const sensorTemplate = new SensorTemplate();
    sensorTemplate.id = item.id;
    sensorTemplate.name = item.name;
    sensorTemplate.accountId = item.accountId;
    sensorTemplate.sensorType = item.sensorType;
    sensorTemplate.minValue = item.minValue;
    sensorTemplate.maxValue = item.maxValue;
    sensorTemplate.resellerId = item.resellerId;

    sensorTemplate.companyName = item.companyName;
    sensorTemplate.resellerDescription = item.resellerDescription;

    return sensorTemplate;
  }

  parseScheduleDetails(data) {
    const schedule = new Schedule(data.day, data.commuting, data.startTime, data.endTime);
    return schedule;
  }
}
