<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/"> <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Devices/Overview">{{'general.devices' | translate }}</a>
                </li>
                <li class="breadcrumb-item active">{{'general.devicesState' | translate }}</li>
            </ol>
        </nav>

        <fh-filter-toggler
            style="margin-left: auto"
            [constructorName]="constructorName"
            (filterChanged)="showFilter = $event"
            (presetsChanged)="showPresets = $event"
        >
            <a
                style="margin-left: 2px"
                class="btn btn-info margin-fix d-none d-md-inline-flex"
                title="Help"
                href="https://help.360locate.com/knowledge-base/fleet-management/state/"
                target="_blank"
                ><i class="fa fa-fa fa-book" style="margin-right: 6px"></i>
                <span class="d-none d-md-inline-flex">{{'general.needHelp' | translate }}</span></a
            >
        </fh-filter-toggler>
    </div>
    <fh-device-navigation></fh-device-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">
        <div class="row">
            <div class="col-lg-12">
                <fh-error [error]="error" [success]="success"></fh-error>

                <!-- Custom filters -->
                <div class="ibox" [hidden]="!showFilter">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-filter"></i> {{'general.filter' | translate }}</h5>
                        <span class="float-right hand btn btn-info filter-margin-fix" (click)="clearAllFilters();">
                            <i class="fa fa-fw fa-times"></i>
                            <span class="d-none d-md-inline-flex"> {{'general.clearFilters' | translate }}</span>
                        </span>
                    </div>
                    <div class="ibox-content slick_toppadding" style="position: relative">
                        <ngx-loading [show]="loading"></ngx-loading>
                        <div id="dynamicFilters" class="flex-container"></div>
                        <div style="clear: both"></div>
                    </div>
                    <div class="ibox-content slick_toppadding flex-container" style="position: relative">
                        <ngx-loading [show]="loading"></ngx-loading>
                        <div class="col-lg-6">
                            <div class="form-group input-group" style="margin-bottom: 6px" title="includeGeofenceNames">
                                <span
                                    id="checkBoxincludeGeofenceNames"
                                    class="input-group-prepend hand"
                                    title="includeGeofenceNames"
                                >
                                    <i
                                        class="auto_checkbox fa fa-fw"
                                        (click)="includeGeofenceNames = !includeGeofenceNames; dateChanged('hidden')"
                                        [ngClass]="includeGeofenceNames ? 'fa-check' : 'fa-times'"
                                        title="includeGeofenceNames"
                                        [style.color]="includeGeofenceNames ? 'rgba(0, 142, 67, 0.8)' : 'rgba(151, 28, 36, 0.8)'"
                                    ></i>
                                </span>
                                <input
                                    name="checkboxName_includeGeofenceNames"
                                    id="checkboxName_includeGeofenceNames"
                                    style="width: 100%; cursor: pointer !important"
                                    placeholder="{{'general.includeGeofenceNames' | translate }}"
                                    disabled="disabled"
                                    class="form-control auto_search"
                                />

                                <div style="clear: both"></div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group input-group" style="margin-bottom: 6px" title="includeAddresses">
                                <span id="includeAddresses" class="input-group-prepend hand" title="includeAddresses">
                                    <i
                                        class="auto_checkbox fa fa-fw"
                                        (click)="includeAddresses = !includeAddresses; dateChanged('hidden')"
                                        [ngClass]="includeAddresses ? 'fa-check' : 'fa-times'"
                                        title="includeAddresses"
                                        [style.color]="includeAddresses ? 'rgba(0, 142, 67, 0.8)' : 'rgba(151, 28, 36, 0.8)'"
                                    ></i>
                                </span>
                                <input
                                    name="checkboxName_includeAddresses"
                                    id="checkboxName_includeAddresses"
                                    style="width: 100%; cursor: pointer !important"
                                    placeholder="{{'general.includeAddresses' | translate }}"
                                    disabled="disabled"
                                    class="form-control auto_search"
                                />

                                <div style="clear: both"></div>
                            </div>
                        </div>
                        <div style="clear: both"></div>
                    </div>
                </div>

                <!-- End default dynamic filters -->

                <!-- Create the grid -->
                <div class="ibox">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-heartbeat"></i> {{'general.devicesState' | translate }}</h5>
                        <iboxseach
                            [searchTerm]="searchTerm"
                            (seachChanged)="seachChanged($event)"
                            [constructorName]="constructorName"
                            (filterChanged)="showFilter = $event"
                            (presetLoaded)="onPresetLoaded($event)"
                            [showColumns]="true"
                            (columnsChanged)="drawFilterRow()"
                        >
                        </iboxseach>
                    </div>
                    <div class="ibox-content slick" style="position: relative" *ngIf="languageLoaded">
                        <table
                            id="dataTable"
                            style="width: 100%; min-height: 50px"
                            fh-datatable
                            [dtTrigger]="dtTrigger"
                            [dtOptions]="dtOptions"
                            class="table nowrap margin-bottom-0 dataTable dtr-inline"
                        ></table>
                    </div>
                </div>
                <!-- End create the grid -->
            </div>
        </div>
    </div>
</fh-permission-handler>
