<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/"> <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Accounts/Overview">{{'general.accounts' | translate }}</a>
                </li>
                <li class="breadcrumb-item" *ngIf="account?.id">
                    <a href="/#/AccountDetails/Index/{{account?.id}}">{{account?.name}}</a>
                </li>
                <li class="breadcrumb-item active">{{'general.settings' | translate }}</li>
            </ol>
        </nav>
    </div>
    <fh-account-details-navigation [accountId]="accountId"></fh-account-details-navigation>
</div>

<div class="wrapper wrapper-content">
    <div class="row" data-equalizer="" *ngIf="error || success">
        <div class="col-lg-12">
            <fh-error [error]="error" [success]="success"></fh-error>
        </div>
    </div>
    <div class="row" data-equalizer="">
        <div class="col-lg-6">
            <div class="ibox">
                <div class="ibox-title">
                    <h5><i class="fa fa-fw fa-key"></i>{{ 'general.authtokens' | translate }}</h5>
                </div>
                <div class="ibox-content slick" style="position: relative">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <table
                        class="table table-fixed nowrap dtr-inline no-footer"
                        style="width: 100%; margin-bottom: 0px"
                    >
                        <thead>
                            <tr>
                                <th style="width: auto" scope="col">{{ 'general.token' | translate}}</th>
                                <th style="width: 140px" scope="col">{{ 'general.expiryDate' | translate}}</th>
                                <th style="width: 60px" scope="col">&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ngx-loading [show]="loading"></ngx-loading>
                            <ng-container *ngFor="let token of tokens">
                                <tr>
                                    <td style="width: auto" class="elipses_overflow">
                                        <fh-copyinput [textMode]="true"> {{ token.token }} </fh-copyinput>
                                    </td>
                                    <td style="width: 140px; text-align: left">
                                        {{ token.expiryDate | amDateFormat: 'YYYY-MM-DD HH:mm'}}
                                    </td>
                                    <td style="width: 60px; text-align: right">
                                        <a
                                            class="btn btn-danger btn-grid"
                                            (click)="deleteToken(token.id)"
                                            title="{{'general.delete' | translate }}"
                                        >
                                            <i class="fas fa-fw fa-trash-alt"></i>
                                        </a>
                                    </td>
                                </tr>
                            </ng-container>
                            <tr *ngIf="tokens.length === 0">
                                <td colspan="3">{{'general.nodatafound' | translate }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="ibox-footer">
                    <button class="btn btn-primary" (click)="addToken()">
                        <i class="fa fa-fw fa-plus"></i> {{'general.add' | translate }}
                    </button>

                    <div style="clear: both"></div>
                </div>
            </div>
        </div>

        <div class="col-lg-6">
            <div class="ibox">
                <div class="ibox-title">
                    <h5><i class="fa fa-fw fa-map"></i>{{ 'general.mapTokens' | translate }}</h5>
                </div>
                <div class="ibox-content slick" style="position: relative">
                    <ngx-loading [show]="loading || loadingTokens"></ngx-loading>
                    <table
                        class="table table-fixed nowrap dtr-inline no-footer"
                        style="width: 100%; margin-bottom: 0px"
                    >
                        <thead>
                            <tr>
                                <th style="width: auto" scope="col">{{ 'general.token' | translate}}</th>
                                <th style="width: 155px" scope="col">{{ 'general.type' | translate}}</th>
                                <th style="width: 60px" scope="col">&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ngx-loading [show]="loading || loadingMapTokens"></ngx-loading>
                            <ng-container *ngFor="let token of mapTokens">
                                <tr>
                                    <td style="width: auto" class="elipses_overflow">
                                        <fh-copyinput [textMode]="true"> {{ token.token }} </fh-copyinput>
                                    </td>
                                    <td style="width: 155px; text-align: left">
                                        {{ ('enums.mapType.' + token.mapType) | translate}}
                                    </td>
                                    <td style="width: 60px; text-align: right">
                                        <a
                                            class="btn btn-danger btn-grid"
                                            (click)="deleteMapToken(token.id)"
                                            title="{{'general.delete' | translate }}"
                                        >
                                            <i class="fas fa-fw fa-trash-alt"></i>
                                        </a>
                                    </td>
                                </tr>
                            </ng-container>
                            <tr *ngIf="mapTokens.length === 0">
                                <td colspan="3">{{'general.nodatafound' | translate }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="ibox-footer">
                    <form #tokenForm="ngForm">
                        <table class="table nowrap dtr-inline no-footer" style="width: 100%; margin-bottom: 0px">
                            <tbody>
                                <tr>
                                    <td style="width: 100px">
                                        <ng-select
                                            name="mapType"
                                            [searchable]="false"
                                            [clearable]="false"
                                            [(ngModel)]="mapType"
                                            #mapTypeVar="ngModel"
                                            class="form-control table-inline"
                                            style="width: 100%"
                                            required
                                        >
                                            <ng-option [value]="7">{{ ('enums.mapType.7') | translate}}</ng-option>
                                            <ng-option [value]="19">{{ ('enums.mapType.19') | translate}}</ng-option>
                                        </ng-select>
                                    </td>
                                    <td style="width: auto">
                                        <input
                                            type="text"
                                            name="token"
                                            class="form-control table-inline"
                                            [(ngModel)]="mapToken"
                                            required
                                            minlength="10"
                                            #tokenVar="ngModel"
                                        />
                                    </td>
                                    <td style="width: 0px">
                                        <button
                                            class="btn btn-primary btn-inline"
                                            (click)="addMapToken()"
                                            [disabled]="tokenForm.invalid"
                                        >
                                            <i class="fa fa-fw fa-plus"></i> {{'general.add' | translate }}
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
