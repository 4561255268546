import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";
import { ControlContainer, NgForm } from "@angular/forms";
import { TriggerName, FormMode, DebounceTimeUnit } from "app/common/enums";
import { Trigger } from "app/models/trigger.model";
import { AuthenticationService } from "app/services/authentication/authentication.service";

@Component({
  selector: "fh-trigger-details",
  templateUrl: "triggerDetails.component.html",
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class TriggerDetailsComponent implements OnInit, OnChanges {
  @Input() trigger: Trigger;
  @Input() loading = false;
  @Input() formMode: any = FormMode.read;
  @Output() error = new EventEmitter();
  @Output() success = new EventEmitter<string>();
  @Output() onAccountChanged = new EventEmitter();

  triggerNames = [];

  loadingAccounts = false;
  selectedResellerId;
  permissions: {};

  debounceTime: number;
  debounceTimeUnitSelected = 1;
  debounceTimeUnits = Object.keys(DebounceTimeUnit)
    .filter((k) => typeof DebounceTimeUnit[k] === "string")
    .map((n) => ({ value: +n, name: "enums.debounceTimeUnit." + n }));

  constructor(private cd: ChangeDetectorRef, private authenticationService: AuthenticationService) {
    this.permissions = this.authenticationService.permissions;
    this.triggerNames = Object.keys(TriggerName)
      .filter((k) => typeof TriggerName[k] === "string")
      .map((n) => ({ value: +n, name: TriggerName[n] }))
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  ngOnInit(): void {
    this.formatTriggerDebounceInSecondsToDisplayedDebounceTime();
  }

  ngOnChanges(): void {
    this.formatTriggerDebounceInSecondsToDisplayedDebounceTime();
  }

  resellerChanged(resellerId) {
    this.trigger.accountId = null;
    this.trigger.assetGroups = [];

    this.selectedResellerId = resellerId;
  }

  checkedChanged(event) {
    if (!this.trigger.hasDebounce) {
      this.trigger.debounceInSeconds = 0;
    }
  }

  changeTriggerType(event) {
    if (this.trigger.triggerType === 1) {
      this.trigger.storeAsEpisode = false;
      this.trigger.hasDebounce = false;
      this.trigger.debounceInSeconds = 0;

      this.trigger.outputMessageMoment = 1;
      this.trigger.webhookMoment = 1;
    }
  }

  accountChanged(accountId) {
    this.onAccountChanged.emit(this.trigger.accountId);
  }

  // Updates DebounceTimeInSeconds on Trigger based on selected time unit
  debounceChanged(event) {
    if (this.debounceTimeUnitSelected === DebounceTimeUnit.Hours) {
      this.trigger.debounceInSeconds = this.debounceTime * 3600;
    } else if (this.debounceTimeUnitSelected === DebounceTimeUnit.Minutes) {
      this.trigger.debounceInSeconds = this.debounceTime * 60;
    } else if (this.debounceTimeUnitSelected === DebounceTimeUnit.Seconds) {
      this.trigger.debounceInSeconds = this.debounceTime;
    }
  }

  // Formats stored DebounceTimeInSeconds into displayed time based on unit selected
  debounceTimeUnitChanged() {
    if (this.debounceTimeUnitSelected === DebounceTimeUnit.Hours) {
      this.debounceTime = Math.round(this.trigger.debounceInSeconds / 3600);
    } else if (this.debounceTimeUnitSelected === DebounceTimeUnit.Minutes) {
      this.debounceTime = Math.round(this.trigger.debounceInSeconds / 60);
    } else if (this.debounceTimeUnitSelected === DebounceTimeUnit.Seconds) {
      this.debounceTime = Math.round(this.trigger.debounceInSeconds);
    }
  }

  // Formats stored DebounceTimeInSeconds into displayed time unit based on modular arithmetic (60 seconds = 1 minute, 3600 seconds = 1 hour)
  formatTriggerDebounceInSecondsToDisplayedDebounceTime(): void {
    // console.log(this.trigger.debounceInSeconds);
    if (this.trigger.hasDebounce) {
      if (this.trigger.debounceInSeconds % 3600 === 0) {
        this.debounceTimeUnitSelected = DebounceTimeUnit.Hours;
        this.debounceTime = this.trigger.debounceInSeconds / 3600;
      } else if (this.trigger.debounceInSeconds % 60 === 0) {
        this.debounceTimeUnitSelected = DebounceTimeUnit.Minutes;
        this.debounceTime = this.trigger.debounceInSeconds / 60;
      } else {
        this.debounceTimeUnitSelected = DebounceTimeUnit.Seconds;
        this.debounceTime = this.trigger.debounceInSeconds;
      }
    }
  }
}
