<div style="position: relative" *ngIf="!textMode">
    <div class="input-group-copy-crop" #inputWrapper>
        <ng-content select="[body]"></ng-content>
    </div>

    <ng-container *ngIf="!hide">
        <ng-container *ngIf="!copyCompleted; else copyResult">
            <i
                tooltip="{{'general.clickToCopy' | translate }}"
                container="body"
                class="fa fa-fw fa-copy input-group-copy"
                (click)="textToClipboard(null)"
            >
            </i>
        </ng-container>
        <ng-template #copyResult>
            <i
                tooltip="{{'general.copied' | translate }}"
                container="body"
                class="fa fa-fw fa-copy input-group-copy"
            ></i>
        </ng-template>
    </ng-container>
</div>

<div style="position: relative" *ngIf="textMode">
    <div class="input-group-copy-crop elipses_overflow" #inputWrapper style="margin-right: 10px">
        <ng-content></ng-content>
    </div>

    <div *ngIf="!hide">
        <ng-container *ngIf="!copyCompleted; else copyResult">
            <i
                tooltip="{{'general.clickToCopy' | translate }}"
                container="body"
                class="fa fa-fw fa-copy input-group-copy"
                style="top: 6px; padding-right: 0px"
                (click)="textToClipboard(inputWrapper.textContent)"
            ></i>
        </ng-container>
        <ng-template #copyResult>
            <i
                tooltip="{{'general.copied' | translate }}"
                container="body"
                class="fa fa-fw fa-copy input-group-copy"
                style="top: 6px; padding-right: 0px"
            ></i>
        </ng-template>
    </div>
</div>
