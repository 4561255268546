import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { AuthenticationService } from "app/services/authentication/authentication.service";
import { batchActionList } from "app/common/globals";
import { BatchAction, EntityType } from "app/common/enums";
import { of, map, Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "fh-handle-tasks",
  templateUrl: "handleTaskProcessing.template.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HandleTaskProcessingComponent {
  @Input({ required: true }) batchProcessing: boolean = false;
  @Input({ required: true }) selectedAction;
  @Input({ required: true }) entityTypes;
  @Input() gridSelection;
  @Input() entity;

  @Output() onFinish = new EventEmitter();

  permissions;
  constructor(
    private authenticationService: AuthenticationService,
    private http: HttpClient,
    private translate: TranslateService,
    private cd: ChangeDetectorRef
  ) {
    this.permissions = this.authenticationService.permissions;
  }

  getBatchActionList(entityTypes, entity) {
    let actionList;

    if (this.batchProcessing) {
      actionList = batchActionList.filter(
        (x) => x.entityTypes === entityTypes && !x.hideOnBatchPage && this.permissions[x.permission] == true
      );
    } else {
      actionList = batchActionList.filter(
        (x) => x.entityTypes === entityTypes && x.hideOnDetailPage !== true && this.permissions[x.permission] == true
      );
    }

    //In case entity input is not set yet
    if (!this.batchProcessing && entity != undefined && this.entity?.id !== "") {
      this.entity = entity;
      this.gridSelection = [entity.id];
    }

    this.translate.get("general.date").subscribe((value) => {
      actionList.forEach((batchAction) => {
        this.checkBatchAction(batchAction);
      });
    });

    return actionList;
  }

  checkBatchAction(batchAction: any) {
    batchAction.name = this.translate.instant("enums.batchAction." + batchAction.batchActionType);

    if (!this.batchProcessing) {
      // Filter items on object and actiontype
      if (batchAction.batchActionType === BatchAction.ActivateDevice) {
        batchAction.disabled = !(this.entity?.accountId === undefined || this.entity?.accountId === 0);
      }

      if (batchAction.batchActionType === BatchAction.MoveToAccount) {
        batchAction.disabled = this.entity?.asset === undefined || this.entity?.accountId === 0;
      }

      if (batchAction.batchActionType === BatchAction.ReplaceDevice) {
        batchAction.disabled = this.entity?.asset === undefined;
      }

      if (batchAction.batchActionType === BatchAction.RemoveFromAccount) {
        batchAction.disabled = this.entity?.accountId === undefined || this.entity?.accountId === 0;
      }

      if (batchAction.batchActionType === BatchAction.CreateInstantReport) {
        batchAction.disabled = this.entity?.asset === undefined || this.entity?.accountId === 0;
      }

      if (batchAction.batchActionType === BatchAction.RerunTrips) {
        batchAction.disabled = this.entity?.asset === undefined || this.entity?.accountId === 0;
      }

      if (batchAction.batchActionType === BatchAction.CreateBasicTrigger) {
        batchAction.disabled = this.entity?.asset === undefined || this.entity?.accountId === 0;
      }
    }

    // Check permissions
    if (!this.permissions[batchAction.permission]) {
      batchAction.disabled = true;
    }
  }

  //Not to be used for batch processing
  process(event) {
    if (this.batchProcessing) {
      this.onFinish.emit(event);
      return;
    }

    if (event.status == "hide") {
      this.onFinish.emit();
      return;
    }

    let object;

    if (this.selectedAction?.batchActionType === BatchAction.AddLogitem) {
      object = { id: this.entity?.id, accountId: this.entity?.accountId, comment: event.object };
    }

    if (this.selectedAction?.batchActionType === BatchAction.RefreshDeviceStateFromDatabase) {
      object = { deviceIds: [this.entity?.id], accountId: this.entity?.accountId };
    }

    if (this.selectedAction?.batchActionType === BatchAction.RequestLatestLocation) {
      object = { deviceId: this.entity?.id };
    }

    if (this.selectedAction?.batchActionType === BatchAction.ResetDevice) {
      object = { deviceId: this.entity?.id };
    }

    if (this.selectedAction?.batchActionType === BatchAction.ActivateDevice) {
      object = { ...event.object.items[0], log: event?.log };
    }

    if (this.selectedAction?.batchActionType === BatchAction.ChangeReseller) {
      object = { selectedReseller: event?.object?.selectedReseller, log: event?.log };
    }

    if (this.selectedAction?.batchActionType === BatchAction.MoveToAccount) {
      object = { deviceId: this.entity?.id, accountId: event?.object?.selectedAccount, log: event?.log };
    }

    if (this.selectedAction?.batchActionType === BatchAction.ReplaceDevice) {
      object = { ...event.object[0], log: event?.log };
    }

    if (this.selectedAction?.batchActionType === BatchAction.RemoveFromAccount) {
      object = { deviceId: event?.object[0]?.deviceId, log: event?.log };
    }

    if (this.selectedAction?.batchActionType === BatchAction.DeleteHistory && event?.object) {
      object = { ...event.object, accountId: this.entity?.accountId, deviceId: this.entity?.id, log: event.log };
    }

    if (this.selectedAction?.batchActionType === BatchAction.CreateInstantReport) {
      object = { ...event.object, deviceId: this.entity?.id, log: event?.log };
    }

    if (this.selectedAction?.batchActionType === BatchAction.CalibrateOdo) {
      object = { ...event.object, deviceId: this.entity?.id, log: event?.log };
    }

    if (this.selectedAction?.batchActionType === BatchAction.RerunTrips) {
      object = { ...event.object, deviceId: this.entity?.id, startDateBatch: event.object.startDate, log: event?.log };
    }

    if (this.selectedAction?.batchActionType === BatchAction.CreateBasicTrigger) {
      object = {
        ...event.object,
        deviceId: this.entity?.id,
        accountId: this.entity?.accountId,
        value: event.object.value,
        useCase: event.object.useCase,
        log: event?.log,
      };
    }

    if (this.selectedAction?.batchActionType === BatchAction.SendCustomCommand) {
      object = { ...event.object, deviceId: this.entity?.id };
    }

    this.onFinish.emit(object);
  }

  processAction(object): Observable<any> {
    if (!this.selectedAction.actionPath) {
      return of("Not implemented");
    }

    return this.http.post(
      this.authenticationService.getWebserviceURL(EntityType[this.selectedAction.entityTypes]) +
        this.selectedAction.actionPath.replace("{0}", this.entity?.id),
      object,
      { headers: this.authenticationService.headers }
    );
  }
}
