<fh-error [error]="error" [success]="success"></fh-error>
<div class="ibox" style="position: relative">
    <ngx-loading [show]="loading"></ngx-loading>
    <div class="ibox-title">
        <h5><i class="fas fa-fw fa-calendar"></i> {{ "general.assignedSchedules" | translate }}</h5>
        <span class="float-right">
            <div class="filter-form d-none d-md-block">
                <input
                    type="text"
                    class="form-control table-inline top-filter d-none d-md-block"
                    [(ngModel)]="filter"
                />
            </div>
        </span>
    </div>
    <div class="ibox-content slick" style="position: relative; overflow-y: auto">
        <table style="width: 100%" class="table nowrap dtr-inline no-footer">
            <thead>
                <tr>
                    <th>{{ "general.schedule" | translate }}</th>
                    <th style="max-width: 200px">{{ "general.startDate" | translate }}</th>
                    <th style="max-width: 200px">{{ "general.endDate" | translate }}</th>
                    <th>{{ "general.applyScheduleToTrip" | translate }}</th>
                    <th *ngIf="assetId || assetGroupId || projectId">{{ "general.inheritedFrom" | translate }}</th>
                    <th
                        *ngIf="scheduleAssignments.length > 0 && deletePermission && permissions[deletePermission]"
                        style="width: 60px"
                    >
                        &nbsp;
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngIf="scheduleAssignments.length === 0">
                    <td colspan="4">{{ "general.noSchedulesFound" | translate }}</td>
                </tr>
                <ng-container
                    *ngFor="
                        let scheduleAssignment of scheduleAssignments
                            | orderBy : ['endDate', 'startDate'] : true
                            | stringFilter : filter
                    "
                >
                    <tr>
                        <td class="elipses_overflow">
                            <a
                                href="/#/Notifications/TriggerScheduleDetails/Index/{{
                                    scheduleAssignment.triggerScheduleId
                                }}"
                                ><span class="secondary link_bolder"><i class="fas fa-fw fa-angle-right"></i></span>
                                {{ scheduleAssignment.triggerScheduleName }}</a
                            >
                        </td>
                        <td title="{{ scheduleAssignment?.startDate?.toLocaleString() }}" style="max-width: 200px">
                            {{ scheduleAssignment.startDate | amDateFormat : "ll" }}
                        </td>
                        <td title="{{ scheduleAssignment?.endDate?.toLocaleString() }}" style="max-width: 200px">
                            {{ scheduleAssignment.endDate | amDateFormat : "ll" }}
                        </td>
                        <td>
                            <i
                                class="fa fa-check fa-fw"
                                *ngIf="scheduleAssignment.applyScheduleToTrip"
                                style="color: rgba(0, 142, 67, 1)"
                            ></i>
                            <i
                                class="fa fa-times fa-fw"
                                *ngIf="!scheduleAssignment.applyScheduleToTrip"
                                style="color: rgba(151, 28, 36, 1)"
                            ></i>
                        </td>
                        <td *ngIf="assetId || assetGroupId || projectId">
                            <a
                                href="/#/AccountDetails/Index/{{ scheduleAssignment.accountId }}"
                                *ngIf="scheduleAssignment.accountId"
                                ><span class="secondary link_bolder"><i class="fas fa-fw fa-angle-right"></i></span
                                >{{ "general.account" | translate }}</a
                            >
                            <a
                                href="/#/AssetGroupDetails/Index/{{ scheduleAssignment.assetGroupId }}"
                                *ngIf="!assetGroupId && scheduleAssignment.assetGroupId"
                                ><span class="secondary link_bolder"><i class="fas fa-fw fa-angle-right"></i></span
                                >{{ "general.assetGroup" | translate }}</a
                            >
                            <a
                                href="/#/ProjectDetails/Index/{{ scheduleAssignment.projectId }}"
                                *ngIf="!projectId && scheduleAssignment.projectId"
                                ><span class="secondary link_bolder"><i class="fas fa-fw fa-angle-right"></i></span
                                >{{ "general.project" | translate }}</a
                            >
                            <span
                                *ngIf="
                                    (assetId && scheduleAssignment.assetId) ||
                                    (assetGroupId && scheduleAssignment.assetGroupId) ||
                                    (projectId && scheduleAssignment.projectId)
                                "
                            ></span>
                        </td>
                        <ng-container
                            *ngIf="scheduleAssignments.length > 0 && deletePermission && permissions[deletePermission]"
                        >
                            <ng-container
                                *ngIf="
                                    (assetId && scheduleAssignment.assetId) ||
                                        (assetGroupId && scheduleAssignment.assetGroupId) ||
                                        (projectId && scheduleAssignment.projectId) ||
                                        (accountId && scheduleAssignment.accountId);
                                    else empty
                                "
                            >
                                <td style="width: 60px; text-align: right">
                                    <span [title]="'general.removeFromGroup' | translate" container="body">
                                        <button
                                            class="btn btn-danger btn-grid"
                                            (click)="deleteItem(scheduleAssignment)"
                                        >
                                            <i class="fas fa-fw fa-trash-alt"></i>
                                        </button>
                                    </span>
                                </td>
                            </ng-container>
                            <ng-template #empty>
                                <td style="width: 60px; text-align: right">&nbsp;</td>
                            </ng-template>
                        </ng-container>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
    <div class="ibox-footer">
        <span
            groupAddSlot
            (click)="scheduleAssignmentModal.showModal(assetId ?? assetGroupId ?? projectId ?? accountId)"
            [title]="'general.assignSchedule' | translate"
            container="body"
        >
            <button class="btn btn-primary"><i class="fa fa-fw fa-add"></i> {{ "general.assign" | translate }}</button>
        </span>
        <div style="clear: both"></div>
    </div>
</div>

<fh-confirmation-modal
    #scheduleAssignmentModal
    (onFinish)="assignSchedule($event, scheduleAssignmentModal, scheduleAssignmentsInputVar)"
>
    <h4 header class="modal-title pull-left">
        <i class="fa fa-fw fa-bolt"></i> {{ "general.action" | translate }}: {{ "general.assignSchedule" | translate }}
    </h4>
    <form body #groupForm="ngForm" style="width: 100%; border-top: 0px">
        <div class="modal-body">
            <fh-schedule-assignment-input
                #scheduleAssignmentsInputVar
                ngDefaultControl
                required
                [tableInline]="false"
                [scheduleAssignment]="scheduleAssignment"
                [selectedAccountId]="selectedAccountId"
                [autoSelect]="false"
                placeholder="{{ 'general.chooseDriverGroups' | translate }}"
            >
            </fh-schedule-assignment-input>
        </div>
    </form>
</fh-confirmation-modal>
