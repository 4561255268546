import { Component, Input, OnInit, ChangeDetectorRef, OnChanges, Output, EventEmitter } from "@angular/core";
import { ControlContainer, NgForm } from "@angular/forms";
import { FormMode } from "app/common/enums";
import { UserService } from "app/services/users/user.service";

@Component({
  selector: "fh-user-chooser",
  templateUrl: "userChooser.component.html",
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class UserChooserDetailsComponent implements OnChanges, OnInit {
  @Input() accountId: number;
  @Input() userId: number;
  @Input() formMode: any = FormMode.read;
  @Output() onUserChanged = new EventEmitter();

  users = [];
  selectedUser;
  loading = false;

  constructor(private cd: ChangeDetectorRef, private userService: UserService) {
    console.log("User selector init");
  }

  ngOnInit(): void {
    console.log("User selector init");
  }

  ngOnChanges(): void {
    console.log("User selector init");
    this.loading = true;
    if (this.accountId > 0) {
      this.selectedUser = undefined;

      this.userService.getSlimUsersByAccountId(this.accountId).subscribe((result) => {
        this.users = result;

        if (this.users.length === 1) {
          this.selectedUser = this.users[0].id;
          this.itemSelected();
        }

        this.loading = false;
        this.cd.markForCheck();
      });
    } else {
      this.loading = false;
      this.cd.markForCheck();
    }

    if (this.userId > 0) {
      this.selectedUser = this.userId;
    }
  }

  itemSelected() {
    const users = this.selectedUser && this.users.filter((x) => x.id.toString() === this.selectedUser.toString());
    this.onUserChanged.emit(users && users.length > 0 ? users[0] : undefined);
  }
}
