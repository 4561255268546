import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable, catchError, map } from "rxjs";
import { AuthenticationService } from "../authentication/authentication.service";
import { LoggingService } from "../logging/logging.service";
import { MaintenanceType } from "app/models/maintenance.model";
import { AssetType } from "app/models/assetType.model";
import { throwError } from "rxjs/internal/observable/throwError";

// Moment timezone
import Moment from "moment-timezone";
window["moment"] = Moment;

@Injectable()
export class MaintenanceTypesService {
  url = "";
  base_url = "";
  inventoryUrl = "";
  MaintenanceTypes: MaintenanceType[] = [];
  timezoneIana: string;

  constructor(
    private http: HttpClient,
    private loggingService: LoggingService,
    private authenticationService: AuthenticationService
  ) {
    this.url = this.authenticationService.getWebserviceURL("maintenanceType");
    this.timezoneIana = this.authenticationService.getTimeZoneIana();
  }

  getPagingUrl() {
    return this.url + "Paging";
  }

  getMaintenanceTypes(): Observable<MaintenanceType[]> {
    console.log("getting maintenance type from service");
    return this.http.get(this.url, { headers: this.authenticationService.headers }).pipe(
      map((data) => {
        return this.parseResponse(data);
      }),
      catchError(this.handleError)
    );
  }

  getMaintenanceTypesByAccount(id: string): Observable<MaintenanceType[]> {
    console.log("getting maintenance type from service");
    return this.http.get(this.url + "account/" + id, { headers: this.authenticationService.headers }).pipe(
      map((data) => {
        return this.parseResponse(data);
      }),
      catchError(this.handleError)
    );
  }

  getMaintenanceTypeById(id: string): Observable<MaintenanceType> {
    console.log("Fetch maintenance type by id " + id);
    return this.http.get(this.url + id, { headers: this.authenticationService.headers }).pipe(
      map((data) => {
        return this.parseReponseDetails(data);
      }),
      catchError(this.handleError)
    );
  }

  saveMaintenanceType(maintenanceType: MaintenanceType, assetTypes = []): Observable<any> {
    console.log("save maintenance type", maintenanceType);
    maintenanceType.assetTypeIds = this.parseAssetTypes(assetTypes);

    return this.http
      .post(this.url, maintenanceType, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  updateMaintenanceType(maintenanceType: MaintenanceType, assetTypes = []): Observable<any> {
    console.log("update maintenance type", maintenanceType);
    maintenanceType.assetTypeIds = this.parseAssetTypes(assetTypes);

    return this.http
      .put(this.url + maintenanceType.id, maintenanceType, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  deleteMaintenanceType(maintenance: MaintenanceType): Observable<any> {
    console.log("delete maintenance type", maintenance);

    return this.http
      .delete(this.url + maintenance.id, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  private handleError(error: Response) {
    return throwError(() => error);
  }

  parseAssetTypes(assetTypes: AssetType[]) {
    const result: number[] = [];

    assetTypes.forEach((assetType) => {
      if (assetType.maintenanceTypeChecked) {
        result.push(assetType.id);
      }
    });

    return result;
  }

  parseResponse(json: any): MaintenanceType[] {
    this.loggingService.log(this.constructor.name, "Retrieved " + json?.length + " MaintenanceTypes.");

    const ident = 1;
    const maintenances: MaintenanceType[] = [];

    json.forEach((item) => {
      const maintenance = this.parseReponseDetails(item);
      maintenances.push(maintenance);
    });

    return maintenances;
  }

  parseReponseDetails(item) {
    const maintenance = new MaintenanceType();
    maintenance.id = item.id;
    maintenance.accountId = item.accountId;
    maintenance.typeName = item.typeName;
    maintenance.hoursThreshold = item.hoursThreshold;
    maintenance.kilometersThreshold = item.kilometersThreshold;
    maintenance.daysThreshold = item.daysThreshold;
    maintenance.timestamp =
      item.timestamp !== undefined ? Moment.utc(item.timestamp)["tz"](this.timezoneIana) : undefined;
    maintenance.companyName = item.companyName;
    maintenance.resellerId = item.resellerId;
    maintenance.resellerDescription = item.resellerDescription;
    maintenance.assetTypeIds = item.assetTypeIds;
    maintenance.assetTypeList = item.assetTypeIds?.split(",");

    return maintenance;
  }
}
