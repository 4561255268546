import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import {
  CalendarEvent,
  CalendarEventTimesChangedEvent,
  CalendarView,
  DateFormatterParams,
  DAYS_OF_WEEK,
} from "angular-calendar";
import { FhChartService } from "app/services/charts/charts.service";
import { isSameDay, isSameMonth } from "date-fns";
import { Subject } from "rxjs";
import { formatDate } from "@angular/common";
import { roundAsNumber } from "app/common/globals";
import Highcharts from "highcharts";
import Moment from "moment";

@Component({
  providers: [FhChartService],
  selector: "fh-maintenance-v2-dashboard",
  templateUrl: "dashboard.template.html",
})
export class MaintenanceV2DashboardViewComponent implements OnInit, OnDestroy {
  Highcharts: typeof Highcharts = Highcharts;

  success;
  error;
  warning;

  loading = false;
  loadingScheduledMaintenance = false;
  chartDistanceCompare: any;
  chartCostPerType: any;
  chartCostPerMonth: Highcharts.Options;

  constructor(
    private translateService: TranslateService,
    private chartService: FhChartService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnDestroy(): void {}

  ngOnInit() {
    // Chart cost per asset

    const data = [
      { assetName: "Volvo Jelle", total: 600, deviceId: 1, wheels: 98, oil: 120, unplanned: 434 },
      { assetName: "Mercedes electric", total: 120, deviceId: 3, wheels: 38, oil: 10, unplanned: 20 },
      { assetName: "Dikke bmw", total: 2230, deviceId: 2, wheels: 120, oil: 22, unplanned: 3214 },
    ];

    const categoriesDistance = [];
    const theDataDistance = [];
    const theContentDistance = [];

    data.sort((a, b) => (a.total < b.total ? 1 : -1));
    data.forEach((device) => {
      categoriesDistance.push(device.assetName);
    });

    data.sort((a, b) => (a.total < b.total ? 1 : -1));
    data.forEach((device) => {
      categoriesDistance.push(device.assetName);
      theContentDistance.push({ y: roundAsNumber(device.total, 0), deviceId: device.deviceId });
    });

    theDataDistance.push({
      data: theContentDistance,
      type: "column",
      name: "Cost",
      turboThreshold: 5000,
    });

    this.chartDistanceCompare = this.chartService.generateUtilizationScoreChart(
      theDataDistance,
      categoriesDistance,
      null,
      ""
    );

    // Chart cost per type

    const dataViolationsPerType = [
      {
        name: this.translateService.instant("general.costPerType"),
        colorByPoint: true,
        minPointSize: 20,
        innerSize: "20%",
        zMin: 0,
        data: [
          {
            name: "Wheels",
            y: roundAsNumber(
              data.reduce((partial_sum, d) => partial_sum + d.wheels, 0),
              0
            ),
          },
          {
            name: "Oil",
            y: roundAsNumber(
              data.reduce((partial_sum, d) => partial_sum + d.oil, 0),
              0
            ),
          },
          {
            name: "Unplanned",
            y: roundAsNumber(
              data.reduce((partial_sum, d) => partial_sum + d.unplanned, 0),
              0
            ),
          },
        ],
      },
    ];

    this.chartCostPerType = this.chartService.generateVarPieChartDevice(dataViolationsPerType, {}, null, true);

    const thePlannedData = [];
    const theUnplannedData = [];

    for (let index = 0; index < 12; index++) {
      var planned = Math.random() * 10000;
      var unplanned = Math.random() * 10000;

      thePlannedData.push({
        x: Moment.utc()
          .add(-1 * index, "month")
          .startOf("day")
          .toDate()
          .getTime(),
        y: planned,
      });
      theUnplannedData.push({
        x: Moment.utc()
          .add(-1 * index, "month")
          .startOf("day")
          .toDate()
          .getTime(),
        y: unplanned,
      });
    }

    const theCategories = [];

    const theChartData = [
      {
        name: this.translateService.instant("general.planned"),
        data: thePlannedData,
        type: "area",
        dashStyle: "dash",
        fillOpacity: 0.5,
        opacity: 1,
        marker: {
          enabled: false,
          lineWidth: 1,
          symbol: "square",
        },
      },
      {
        name: this.translateService.instant("general.unplanned"),
        data: theUnplannedData,
        type: "area",
        dashStyle: "dash",
        fillOpacity: 0.5,
        opacity: 1,
        marker: {
          enabled: false,
          lineWidth: 1,
          symbol: "square",
        },
      },
    ];

    console.log(theChartData);

    this.chartCostPerMonth = this.chartService.generateColumnChartDates(
      theChartData,
      {},
      theCategories,
      undefined,
      "normal",
      true
    );
  }

  // Calendar
  viewDate: Date = new Date();
  events: CalendarEvent[] = [];
  daysInWeek = 7;
  refresh = new Subject<void>();
  locale: string = "en";
  weekStartsOn: number = DAYS_OF_WEEK.MONDAY;
  weekendDays: number[] = [DAYS_OF_WEEK.FRIDAY, DAYS_OF_WEEK.SATURDAY];
  activeDayIsOpen = false;
  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;
  calenderEditMode = false;
  eventClicked({ event }: { event: CalendarEvent }): void {
    console.log("Event clicked", event);
  }

  generateCalendar(events) {
    events.forEach((event) => {
      this.events.push({
        title: event.companyName + ": " + this.translateService.instant("enums.reportType." + event.selectedReportType),
        start: new Date(event.nextOccurrence),
      });
    });
    this.cd.markForCheck();
    this.refresh.next();
  }

  public dayViewHour({ date, locale }: DateFormatterParams): string {
    return formatDate(date, "HH:mm", locale);
  }

  public weekViewHour({ date, locale }: DateFormatterParams): string {
    return this.dayViewHour({ date, locale });
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  handleEvent(action: string, event: CalendarEvent): void {}

  eventTimesChanged({ event, newStart, newEnd }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent("Dropped or resized", event);
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if ((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) || events.length === 0) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }
}
