<nav class="navbar-default navbar-static-side noselect" role="navigation">
    <div
        class="sidebar-collapse"
        [ngStyle]="{'height': isFleetOverview ? '100%' : 'inherit', 'overflow': isFleetOverview ? 'hidden' : 'inherit'}"
    >
        <ul class="nav metismenu" id="side-menu">
            <li class="nav-header">
                <div class="dropdown profile-element hand" [routerLink]="['/']">
                    <div class="site_logo"></div>
                </div>
                <div class="logo-element hand" [routerLink]="['/']">
                    <div class="logo-small"></div>
                </div>
            </li>
            <li *ngIf="permissions['FleetManagement_Home']" [ngClass]="{active: activeRoute('Home') }">
                <a [routerLink]="['/Home/Default']" aria-expanded="true" aria-disabled="true">
                    <i class="fas fa-fw fa-home" title="{{ 'menu.home' | translate }}"></i>
                    <span class="nav-label">{{ 'menu.home' | translate }}</span>
                </a>
            </li>
            <li *ngIf="permissions['FleetManagement_Overview']" [ngClass]="{active: activeRoute('FleetOverview') }">
                <a [routerLink]="['/FleetOverview']" aria-expanded="true" aria-disabled="true">
                    <i class="fas fa-fw fa-chart-radar" title="{{ 'general.overview' | translate }}"></i>
                    <span class="nav-label">{{ 'general.overview' | translate }}</span>
                </a>
            </li>
            <li *ngIf="permissions['FleetManagement_Cockpit']" [ngClass]="{active: activeRoute('Cockpit') }">
                <a [routerLink]="['/Cockpit']" aria-expanded="true" aria-disabled="true">
                    <i class="fas fa-fw fa-bolt" title="{{ 'menu.cockpit' | translate }}"></i>
                    <span class="nav-label">{{ 'menu.cockpit' | translate }}</span>
                </a>
            </li>
            <li *ngIf="permissions['FleetManagement_Dashboard']" [ngClass]="{active: activeRoute('Dashboard') }">
                <a [routerLink]="['/Dashboard/Dashboard']" aria-expanded="true" aria-disabled="true">
                    <i class="fas fa-fw fa-gauge" title="{{ 'menu.dashboard' | translate }}"></i>
                    <span class="nav-label">{{ 'menu.dashboard' | translate }}</span>
                </a>
            </li>
            <li *ngIf="permissions['FleetManagement_Equipment']" [ngClass]="{active: activeRoute('Equipment') }">
                <a [routerLink]="['/Equipment/Dashboard']" aria-expanded="true" aria-disabled="true">
                    <i class="fas fa-fw fa-truck-plow" title="{{ 'general.equipment' | translate }}"></i>
                    <span class="nav-label">{{ 'general.equipment' | translate }}</span>
                </a>
            </li>
            <li *ngIf="permissions['Modules_HasPrivateFleet']" [ngClass]="{active: activeRoute('CarSharing') }">
                <a [routerLink]="['/CarSharing/Dashboard']" aria-expanded="true" aria-disabled="true">
                    <i class="fas fa-fw fa-garage-car" title="{{ 'general.carsharing' | translate }}"></i>
                    <span class="nav-label">{{ 'general.carsharing' | translate }}</span>
                </a>
            </li>
            <li *ngIf="permissions['FleetManagement_DriverScore']" [ngClass]="{active: activeRoute('EcoDriving') }">
                <a [routerLink]="['/EcoDriving/Drivers']" aria-expanded="true" aria-disabled="true">
                    <i class="fas fa-fw fa-hand-holding-seedling" title="{{ 'general.ecoDriving' | translate }}"></i>
                    <span class="nav-label">{{ 'general.ecoDriving' | translate }}</span>
                </a>
            </li>
            <li *ngIf="permissions['FleetManagement_Map']" [ngClass]="{active: activeRoute('Map')}">
                <a [routerLink]="['/Map/']" aria-expanded="true" aria-disabled="true">
                    <i class="fas fa-fw fa-map" title="{{ 'general.map' | translate }}"></i>
                    <span class="nav-label">{{ 'general.map' | translate }}</span>
                </a>
            </li>
            <li *ngIf="permissions['Devices_View']" [ngClass]="{active: activeRoute('Device') || activeRoute('Asset')}">
                <a [routerLink]="['/Devices/Overview/']" aria-expanded="true" aria-disabled="true">
                    <i class="fas fa-fw fa-microchip" title="{{ 'general.devices' | translate }}"></i>
                    <span class="nav-label">{{ 'general.devices' | translate }}</span>
                </a>
            </li>

            <li *ngIf="permissions['Accounts_View']" [ngClass]="{active: activeRoute('Account') }">
                <a [routerLink]="['/Accounts/Overview/']" aria-expanded="true" aria-disabled="true">
                    <i class="fas fa-fw fa-building" title="{{ 'general.accounts' | translate }}"></i>
                    <span class="nav-label">{{ 'general.accounts' | translate }}</span>
                </a>
            </li>

            <li *ngIf="permissions['Users_View']" [ngClass]="{active: activeRoute('User')}">
                <a [routerLink]="['/Users/Overview/']" aria-expanded="true" aria-disabled="true">
                    <i class="fas fa-fw fa-users" title="{{ 'general.users' | translate }}"></i>
                    <span class="nav-label">{{ 'general.users' | translate }}</span>
                </a>
            </li>

            <li *ngIf="permissions['Geofences_View']" [ngClass]="{active: activeRoute('Geofence')}">
                <a [routerLink]="['/Geofences/Overview/']" aria-expanded="true" aria-disabled="true">
                    <i class="fa fa-fw fa-draw-polygon" title="{{ 'general.geofences' | translate }}"></i>
                    <span class="nav-label">{{ 'general.geofences' | translate }}</span>
                </a>
            </li>

            <li
                *ngIf="permissions['Drivers_View']"
                [ngClass]="{active: activeRoute('Drivers/') || activeRoute('DriverDetails') || activeRoute('DriverGroups') || activeRoute('DriverGroupDetails')   }"
            >
                <a [routerLink]="['/Drivers/Overview/']" aria-expanded="true" aria-disabled="true">
                    <i class="fa fa-fw fa-id-card-alt" title="{{ 'general.drivers' | translate }}"></i>
                    <span class="nav-label">{{ 'general.drivers' | translate }}</span>
                </a>
            </li>

            <li *ngIf="!hideTrailers && permissions['Trailers_View']" [ngClass]="{active: activeRoute('Trailer')}">
                <a [routerLink]="['/Trailers/Overview/']" aria-expanded="true" aria-disabled="true">
                    <i class="fas fa-fw fa-trailer" title="{{ 'general.trailers' | translate }}"></i>
                    <span class="nav-label">{{ 'general.trailers' | translate }}</span>
                </a>
            </li>

            <li *ngIf="!hideProjects && permissions['Projects_View']" [ngClass]="{active: activeRoute('Project')}">
                <a [routerLink]="['/Projects/Overview/']" aria-expanded="true" aria-disabled="true">
                    <i class="fas fa-fw fa-gavel" title="{{ 'general.projects' | translate }}"></i>
                    <span class="nav-label">{{ 'general.projects' | translate }}</span>
                </a>
            </li>

            <li
                *ngIf="!hideWarehouses && permissions['Warehouses_View']"
                [ngClass]="{active: activeRoute('Warehouse')}"
            >
                <a [routerLink]="['/Warehouses/Overview/']" aria-expanded="true" aria-disabled="true">
                    <i class="fas fa-fw fa-warehouse" title="{{ 'general.warehouses' | translate }}"></i>
                    <span class="nav-label">{{ 'general.warehouses' | translate }}</span>
                </a>
            </li>

            <li *ngIf="permissions['Resellers']" [ngClass]="{active: activeRoute('Reseller')}">
                <a [routerLink]="['/Resellers/Overview/']" aria-expanded="true" aria-disabled="true">
                    <i class="fas fa-fw fa-city" title="{{ 'general.resellers' | translate }}"></i>
                    <span class="nav-label">{{ 'general.resellers' | translate }}</span>
                </a>
            </li>

            <li *ngIf="!hideFuel && permissions['FuelEvents_View']" [ngClass]="{active: activeRoute('Fuel')}">
                <a [routerLink]="['/Fuel/Dashboard/']" aria-expanded="true" aria-disabled="true">
                    <i class="fas fa-fw fa-gas-pump" title="{{ 'general.fuel' | translate }}"></i>
                    <span class="nav-label">{{ 'general.fuel' | translate }}</span>
                </a>
            </li>

            <li
                *ngIf="!hideMaintenance && permissions['Maintenances_View'] && !hasFeature('enableBetaMaintenance')"
                [ngClass]="{active: activeRoute('Maintenances/') || activeRoute('MaintenancesDetails/')}"
            >
                <a [routerLink]="['/Maintenances/Overview/']" aria-expanded="true" aria-disabled="true">
                    <i class="fas fa-fw fa-car-mechanic" title="{{ 'general.maintenance' | translate }}"></i>
                    <span class="nav-label">{{ 'general.maintenance' | translate }}</span>
                </a>
            </li>

            <li
                *ngIf="!hideMaintenance && permissions['Maintenances_View'] && hasFeature('enableBetaMaintenance')"
                [ngClass]="{active: activeRoute('MaintenancesV2/') || activeRoute('MaintenancesV2Details/')}"
            >
                <a [routerLink]="['/MaintenancesV2/Dashboard/']" aria-expanded="true" aria-disabled="true">
                    <i class="fas fa-fw fa-car-mechanic" title="{{ 'general.maintenance' | translate }}"></i>
                    <span class="nav-label">{{ 'general.maintenance' | translate }} V2</span>
                </a>
            </li>

            <li *ngIf="permissions['FleetManagement_Reporting']" [ngClass]="{active: (activeRoute('Report') )}">
                <a [routerLink]="['/Reporting/Dashboard/']" aria-expanded="true" aria-disabled="true">
                    <i class="fas fa-fw fa-file-chart-pie" title="{{ 'general.reporting' | translate }}"></i>
                    <span class="nav-label">{{ 'general.reporting' | translate }}</span>
                </a>
            </li>

            <li
                *ngIf="permissions['FleetManagement_Notifications']"
                [ngClass]="{active: (activeRoute('Notifications') )}"
            >
                <a [routerLink]="['/Notifications/Overview/']" aria-expanded="true" aria-disabled="true">
                    <i class="fas fa-fw fa-bell" title="{{ 'general.notifications' | translate }}"></i>
                    <span class="nav-label">{{ 'general.notifications' | translate }}</span>
                </a>
            </li>

            <li
                *ngIf="permissions['FleetManagement_Tags'] || permissions['HasNotifications'] || permissions['Triggers_View'] || permissions['FleetManagement_Jobs'] || permissions['Platform_IsReseller']"
                [ngClass]="{active: activeRoute('System') || activeRoute('Tags') || activeRoute('Trigger') || activeRoute('Batch') || activeRoute('AuditLog') || activeRoute('NotificationLog') || activeRoute('Export')|| activeRoute('Import') || activeRoute('DeviceTypes')}"
            >
                <a [routerLink]="['/System/Health']" aria-expanded="true" aria-disabled="true">
                    <i class="fas fa-fw fa-toolbox" title="{{ 'general.system' | translate }}"></i>
                    <span class="nav-label">{{ 'general.system' | translate }}</span>
                </a>
            </li>

            <li
                [ngClass]="{active: activeRoute('Settings')}"
                *ngIf="permissions['Platform_IsReseller'] || permissions['Platform_IsAdministrator']"
            >
                <a [routerLink]="['/Settings/ClientAnnouncements']" aria-expanded="true" aria-disabled="true">
                    <i class="fas fa-fw fa-cog" title="{{ 'general.settings' | translate }}"></i>
                    <span class="nav-label">{{ 'general.settings' | translate }}</span>
                </a>
            </li>
        </ul>
    </div>
</nav>
