import { ChangeDetectorRef, Component, OnInit, ChangeDetectionStrategy } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

import { DeviceTypeService } from "app/services/devicetypes/devicetypes.service";

import { forkJoin } from "rxjs";
import { AuthenticationService } from "../../services/authentication/authentication.service";

import * as XLSX from "xlsx-js-style";
// Moment timezone
import Moment from "moment-timezone";

import { getIconPath } from "app/common/globals";
import { DriverService } from "app/services/driver/driver.service";
import { Driver } from "app/models/driver.model";
import { BsDaterangepickerConfig } from "ngx-bootstrap/datepicker";
import { getDefaultDpConfig } from "app/common/gridhelper";

window["moment"] = Moment;

@Component({
  selector: "fh-drivers-assignments",
  templateUrl: "assignments.template.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DriverAssignmentsViewComponent implements OnInit {
  token: string;
  countrySelect = false;

  excludingColumns = [
    "timeStamp",
    "assetDateStart",
    "assetDateEnd",
    "lastCommunication",
    "input1",
    "input2",
    "input3",
    "input4",
    "input5",
    "input6",
    "output1",
    "output2",
  ];
  loading = false;

  permissionName = "Drivers_View";
  constructorName = "DriverScoreViewComponent";

  selectedResellerId;
  selectedAccountId;
  selectedDriverGroups;

  error: any;
  success: any;
  timezoneIana: string;
  languageLoaded: boolean;

  assignments: Driver[] = [];
  drivers: Driver[] = [];

  currentPage;
  filter;
  itemsPerPage = 10;
  orderBy = "created";
  orderByReversed = false;
  status;

  // Datepicker
  public dpConfig: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();
  to: any;
  from: any;
  daterangepickerModel: any[];

  constructor(
    private translateService: TranslateService,
    private cd: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private driverService: DriverService,
    private deviceTypeService: DeviceTypeService
  ) {
    const that = this;

    this.token = this.authenticationService.getAuthToken();
    this.timezoneIana = this.authenticationService.getTimeZoneIana();

    this.daterangepickerModel = [
      Moment().tz(this.timezoneIana).subtract(1, "weeks").startOf("day").toDate(),
      Moment().tz(this.timezoneIana).endOf("day").toDate(),
    ];

    this.dpConfig = getDefaultDpConfig(authenticationService);

    // Get all the date for dropdown boxes
    forkJoin([this.translateService.get("general.date"), this.deviceTypeService.getDeviceTypes()]).subscribe(
      (data) => {
        this.languageLoaded = true;
        this.cd.markForCheck();
      },
      (err) => {
        this.error = err;
        this.languageLoaded = true;
        this.loading = false;
        this.cd.markForCheck();
      }
    );
  }

  resellerChanged(event) {
    this.assignments = [];
    this.drivers = [];
    this.selectedDriverGroups = [];

    this.selectedAccountId = null;
  }

  accountChanged(event) {
    this.assignments = [];
    this.drivers = [];
    this.selectedDriverGroups = [];

    this.getData();
  }

  ngOnInit() {}

  getIcon(iconId) {
    return getIconPath(iconId)[2];
  }

  filterDrivers() {
    this.drivers = this.assignments;
    if (this.status) {
      // this.drivers = this.drivers.filter(x => x.deviceState === this.status);
    }

    // Filter groups
    if (this.selectedDriverGroups && this.selectedDriverGroups.length > 0) {
      this.drivers = this.drivers.filter((x) =>
        x.driverGroups.some((ag) => this.selectedDriverGroups.includes(+ag.id))
      );
    }

    this.cd.markForCheck();
  }

  dateChanged(event) {
    const that = this;
    console.log("Changed date");
    if (event !== null) {
      this.getData();
    }
  }

  export() {
    /* generate worksheet */
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.assignments);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, "Account" + this.selectedAccountId);

    /* save to file */
    XLSX.writeFile(wb, "DriverAssignments_" + this.selectedAccountId + ".csv", { bookType: "csv" });
  }

  getData() {
    this.loading = true;

    this.assignments = [];
    this.drivers = [];

    if (this.selectedAccountId == null) {
      this.loading = false;
      return;
    }

    this.driverService
      .getDriverAssignmentsByAccountId(
        this.selectedAccountId,
        Moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf("day"),
        Moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf("day")
      )
      .subscribe(
        (result) => {
          this.assignments = result;
          this.error = null;

          this.filterDrivers();
          this.loading = false;
          this.cd.markForCheck();
        },
        (err) => {
          this.error = err;
          this.languageLoaded = true;
          this.loading = false;
          this.cd.markForCheck();
        }
      );
  }
}
