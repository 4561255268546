import {
  Component,
  EventEmitter,
  Output,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { EntityType } from "app/common/enums";
import { AccountInputComponent } from "app/modules/customInputs/accountSelector.component";
import { DeviceService } from "app/services/device/device.service";
import { DriverService } from "app/services/driver/driver.service";
import { GeofenceService } from "app/services/geofence/geofence.service";

@Component({
  selector: "fh-batch-group-add",
  templateUrl: "groupAdd.template.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BatchGroupAddComponent implements OnChanges, OnInit {
  @ViewChild("groupInputVar", { static: false }) groupInputVar: ElementRef;
  @ViewChild("accountInputVar", { static: false }) accountInputVar: AccountInputComponent;

  step: number;

  @Input() gridSelection = [];
  @Input() batchEntityType: EntityType;

  @Output() onFinish = new EventEmitter();

  logItem: string;

  loading = true;

  groupForm = new FormGroup({
    selectedAccount: new FormControl(null, Validators.required),
    selectedGroups: new FormControl(null, Validators.required),
  });

  allowedEntities = [];
  allowedEntitiesData = [];

  selectedAccountId: number;

  addedGroups = "";

  selectedAssetGroups = [];

  constructor(
    private cd: ChangeDetectorRef,
    private deviceService: DeviceService,
    private driverService: DriverService,
    private geofenceService: GeofenceService
  ) {
    this.step = 1;
  }

  ngOnInit(): void {
    console.log("init");
    this.step = 1;
    this.cd.markForCheck();
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log("changes");
    this.step = 1;
    this.cd.markForCheck();
  }

  initFinish() {
    // Fetch all data and make sure it can be saved
    console.log(1);
    console.log(this.groupForm.value);
  }

  onCheckOutFinish() {
    switch (this.batchEntityType) {
      case EntityType.Device:
        this.onFinish.emit({
          status: "success",
          object: {
            ...this.groupForm.value,
            selectedGroups: this.groupForm.value.selectedGroups.filter((x) => x > -1),
            selectedItems: this.allowedEntities.map((x) => x.asset?.id),
          },
          log: this.logItem,
        });
        break;
      case EntityType.Driver:
        this.onFinish.emit({
          status: "success",
          object: {
            ...this.groupForm.value,
            selectedGroups: this.groupForm.value.selectedGroups.filter((x) => x > -1),
            selectedItems: this.allowedEntities.map((x) => x.id),
          },
          log: this.logItem,
        });
        break;
      case EntityType.Geofence:
        this.onFinish.emit({
          status: "success",
          object: {
            ...this.groupForm.value,
            selectedGroups: this.groupForm.value.selectedGroups.filter((x) => x > -1),
            selectedItems: this.allowedEntities.map((x) => x.id),
          },
          log: this.logItem,
        });
        break;
      default:
        break;
    }

    this.step = 1;
    this.cd.markForCheck();
  }

  initForm() {
    console.log("init tab for " + EntityType[this.batchEntityType]);
    this.step = 1;

    switch (this.batchEntityType) {
      case EntityType.Device:
        this.deviceService.getDeviceData(this.gridSelection).subscribe((devices) => {
          this.allowedEntitiesData = devices.filter((i) => i.asset != null && i.asset.id != null && i.asset.id > 0);
          this.initializeControls();
        });
        break;
      case EntityType.Driver:
        this.driverService.getDriverData(this.gridSelection).subscribe((drivers) => {
          this.allowedEntitiesData = drivers;
          this.initializeControls();
        });
        break;
      case EntityType.Geofence:
        this.geofenceService.getGeofenceData(this.gridSelection).subscribe((geofences) => {
          this.allowedEntitiesData = geofences;
          this.initializeControls();
        });
        break;
      default:
        this.allowedEntities = [];
        this.loading = false;
        this.cd.markForCheck();
        break;
    }
  }

  initializeControls() {
    this.selectedAccountId = this.allowedEntitiesData.slice(-1).pop()?.accountId;
    this.accountInputVar.value = this.selectedAccountId;

    this.filterItems();
    this.loading = false;
    this.cd.markForCheck();
  }

  filterDevices(groupInput) {
    groupInput.loadAssetGroups(() => {
      this.addedGroups = groupInput.assetGroups
        .filter((x) => groupInput.value.indexOf(x.id) > -1)
        .reduce(function (a, b) {
          return a + ["", ", "][+!!a.length] + b.displayName;
        }, "");
    });
  }

  filterDrivers(groupInput) {
    groupInput.loadDriverGroups(() => {
      this.addedGroups = groupInput.driverGroups
        .filter((x) => groupInput.value.indexOf(x.id) > -1)
        .reduce(function (a, b) {
          return a + ["", ", "][+!!a.length] + b.displayName;
        }, "");
    });
  }

  filterGeofences(groupInput) {
    groupInput.loadGeofenceGroups(() => {
      this.addedGroups = groupInput.geofenceGroups
        .filter((x) => groupInput.value.indexOf(x.id) > -1)
        .reduce(function (a, b) {
          return a + ["", ", "][+!!a.length] + b.displayName;
        }, "");
    });
  }

  accountChanged(accountId, groupInput: any | undefined) {
    if (groupInput === undefined) {
      this.cd.markForCheck();
      return;
    }

    this.selectedAccountId = accountId;
    groupInput.value = [];

    this.filterItems();

    this.cd.markForCheck();
  }

  filterItems() {
    this.allowedEntities = this.allowedEntitiesData.filter((x) =>
      this.selectedAccountId > -1 ? this.selectedAccountId === +x.accountId : false
    );
  }
}
