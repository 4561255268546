import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { FormMode } from "app/common/enums";
import {
  availablePermissions,
  createPermissionsMap,
  formatSelectedUserPermissions,
  updatePermissionMapWithCurrentUserRoleValues,
} from "app/common/permissionhelper";
import { AssetType } from "app/models/assetType.model";
import { UserRole } from "app/models/userRole.model";
import { AuthenticationService } from "app/services/authentication/authentication.service";
import { UserRoleService } from "app/services/users/userRole.service";

@Component({
  selector: "fh-user-role-add",
  templateUrl: "add.template.html",
})
export class UserRoleAddViewComponent implements OnInit {
  loading: boolean = false;
  userRole: UserRole = new UserRole();

  formMode: FormMode = FormMode.add;
  formModeAssetTypes: FormMode = FormMode.read;

  error: any;
  availablePermissions = [];
  permissionsMap = [];
  permissions: {};
  success: { statusText: string; success: string };
  assetTypes: AssetType[];

  constructor(
    private translateServe: TranslateService,
    private cd: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private router: Router,
    private userRoleService: UserRoleService,
    private route: ActivatedRoute
  ) {
    this.permissions = this.authenticationService.permissions;
    this.availablePermissions = availablePermissions;
  }

  ngOnInit(): void {
    this.permissionsMap = createPermissionsMap(this.availablePermissions);

    this.loading = true;
    this.route.queryParams.subscribe((params) => {
      console.log(params);
      const userRoleId = params["userRoleId"];

      if (userRoleId != null) {
        this.userRoleService.getUserRoleById(userRoleId.toString()).subscribe({
          next: (userRole) => {
            this.userRole = userRole;
            updatePermissionMapWithCurrentUserRoleValues(this.permissionsMap, this.userRole);
            this.userRole.name = "Clone of " + this.userRole.name;
            this.loading = false;
            this.cd.markForCheck();
          },
          error: (error) => {
            this.success = null;
            this.error = error;
            this.error.statusText = "Error fetching User role";
            this.cd.markForCheck();
          },
        });
      } else {
        this.loading = false;
        this.cd.markForCheck();
      }
    });
  }

  onInsert() {
    this.loading = true;
    this.userRole.permissions = formatSelectedUserPermissions(this.permissionsMap);

    this.userRoleService.saveUserRole(this.userRole).subscribe({
      next: (result) => {
        this.success = {
          statusText: "Success",
          success: this.translateServe.instant("general.saveSuccessRedirect", { entity: "userRole" }),
        };
        setTimeout(() => {
          this.router.navigate(["/Users/UserRoleDetails/Index/" + result.id]);
        }, 2000);
        this.loading = false;
      },
      error: (error) => {
        this.error = error;
        this.loading = false;
      },
    });
  }

  accountChanged(accountId: number) {
    console.log("Changed accountId");
    this.userRole.accountId = accountId;
    this.cd.markForCheck();
  }

  resellerChanged(resellerId: number) {
    console.log("Changed reselllerId");
    this.userRole.resellerId = resellerId;
    this.cd.markForCheck();
  }

  checkPermissionsValues(event: any) {
    this.cd.markForCheck();
  }
}
