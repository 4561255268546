export class RegisterOperatingCompany {
  public identityNumber = "";
  public commercialRecordNumber = "";
  public commercialRecordIssueDateHijri = "";
  public phoneNumber = "";
  public extensionNumber = "";
  public emailAddress = "";
  public managerName = "";
  public managerPhoneNumber = "";
  public managerMobileNumber = "";
  public dateOfBirthGregorian = "";
  public sfdaCompanyActivity = "";
  public referenceKey = "";
  public registerDateWasl: any;
  public registerDateSfda: any;
  public registerDateTow: any;
  public registerDateSpecialityTransport: any;
  public registerDateBusRental: any;
  public registerDateEducationalTransport: any;
}

export class Properties {
  public wasl = new RegisterOperatingCompany();
  public custom: any = [];
}

export class AccountInventory {
  public id: any;
  public language: string;
  public createdDate: Date;
  public modifiedDate: Date;
  public name: string;
  public erpCode: string;
  public referenceId: string;
  public deviceCount: any;
  public platformName: any;
  public platformId: any;
  public firstName: any;
  public lastName: any;
  public contactEmail: any;
  public street: any;
  public street2: any;
  public city: any;
  public state: any;
  public zip: any;
  public countryId: any;

  public latitude: any;
  public longitude: any;

  public properties = new Properties();

  public resellerDescription: any;
  public resellerId: any;
  public driverCount: any;
  public geofenceCount: any;
  public isActive: any;
  public parentAccountId: any;
  public adminUserName: any;
  public adminUserId: any;
  public parentAccountName: any;
  public linkName: any;
  public resellerDefaultAssetIcon: any;
  public defaultScheduleId: any;
}

export class RegisterInventory {
  public identityNumber = "";
  public commercialRecordNumber = "";
  public commercialRecordIssueDateHijri = "";
  public phoneNumber = "";
  public extensionNumber = "";
  public emailAddress = "";
  public managerName = "";
  public managerPhoneNumber = "";
  public managerMobileNumber = "";
  public dateOfBirthGregorian = "";
  public sfdaCompanyActivity = "";
  public referenceKey = "";
  public registerDateWasl: any;
  public registerDateSfda: any;
  public registerDateTow: any;
  public registerDateSpecialityTransport: any;
  public registerDateBusRental: any;
  public registerDateEducationalTransport: any;
}
