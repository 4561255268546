<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/"> <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Devices/Overview">{{'general.devices' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Devices/DeviceTypes/Overview">{{'general.devicetypes' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Devices/DeviceTypeDetails/Index/{{deviceType?.id}}">{{deviceType?.modelName}}</a>
                </li>
                <li class="breadcrumb-item active">{{'menu.dashboard' | translate }}</li>
            </ol>
        </nav>
    </div>
    <fh-device-navigation></fh-device-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">
        <div class="row" data-equalizer="">
            <div class="col-lg-6">
                <div class="ibox">
                    <div class="ibox-title">
                        <h5><i class="fa fa-router"></i> {{ deviceType?.modelName }}</h5>
                    </div>
                    <div class="ibox-content slick" style="min-height: 50px; position: relative">
                        <ngx-loading [show]="loading"></ngx-loading>
                        <table style="width: 100%" class="table nowrap dtr-inline no-footer table-fixed">
                            <tbody>
                                <tr>
                                    <td class="row_opening">{{'general.modelName' | translate }}</td>
                                    <td class="row_content">{{ deviceType?.modelName }}</td>
                                </tr>
                                <tr>
                                    <td class="row_opening">{{ 'general.modelNumber' | translate}}</td>
                                    <td class="row_content">{{ deviceType?.modelNumber }}</td>
                                </tr>
                                <tr>
                                    <td class="row_opening">{{ 'general.description' | translate}}</td>
                                    <td class="row_content">{{ deviceType?.description }}</td>
                                </tr>
                                <tr>
                                    <td class="row_opening">{{ 'general.latestVersion' | translate}}</td>
                                    <td class="row_content">{{ deviceType?.latestVersion }}</td>
                                </tr>
                                <tr>
                                    <td class="row_opening">{{ 'general.port' | translate}}</td>
                                    <td class="row_content">{{ deviceType?.port }}</td>
                                </tr>
                                <tr>
                                    <td class="row_opening">{{ 'general.supplierName' | translate}}</td>
                                    <td class="row_content">{{ deviceType?.supplier.name }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <fh-devices-connected [loadingDeviceCount]="loadingDeviceCount" [data]="deviceCountData">
                </fh-devices-connected>
            </div>
        </div>
    </div>
</fh-permission-handler>
