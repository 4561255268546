import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { EntityType, FormMode } from "app/common/enums";
import { AccountInventory } from "app/models/account.model";
import { HierarchyGroup } from "app/models/hierarchyGroup.model";
import { AccountService } from "app/services/account/account.service";
import { AssetService } from "app/services/asset/asset.service";
import { AuthenticationService } from "app/services/authentication/authentication.service";
import { DriverService } from "app/services/driver/driver.service";
import { GeofenceService } from "app/services/geofence/geofence.service";
import { HierarchieService } from "app/services/hierarchy/hierarchie.service";

@Component({
  providers: [],
  selector: "fh-fh-hierarchy-groups-details",
  templateUrl: "hierarchyGroupDetails.template.html",
})
export class HierarchyGroupDetailsViewComponent implements OnInit {
  sub;

  loading = false;
  saving = false;

  tagType = EntityType.HierarchyGroup.valueOf();

  error: any;
  success: any;

  formMode: FormMode = FormMode.read;

  group: HierarchyGroup;
  account: AccountInventory;

  permissionName = "Platform_IsFleetManager";
  permissions: {};

  // Display
  hierarchyGroups = [];

  // Selector assets
  assetsSource = [];
  assetsList = [];
  assetsDisplay = "assetSearchName";
  assetsKey = "id";

  // Selector drivers
  driversSource = [];
  driversList = [];
  driversDisplay = "name";
  driversKey = "id";

  // Selector geofences
  geofencesSource = [];
  geofencesList = [];
  geofencesDisplay = "name";
  geofencesKey = "id";

  format = {
    add: "Add",
    remove: "Remove",
    all: "All",
    none: "None",
    draggable: true,
  };

  loadingAssets = false;
  loadingDrivers = false;
  loadingGeofences = false;

  constructor(
    private hierarchyService: HierarchieService,
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    private assetService: AssetService,
    private driverService: DriverService,
    private geofenceService: GeofenceService
  ) {
    this.account = null;

    this.group = new HierarchyGroup();
  }

  ngOnInit(): void {
    this.loading = true;
    this.sub = this.route.params.subscribe((params) => {
      const id = params["id"];

      this.getHierarchieGroup(id);
    });
  }

  getHierarchieGroup(hierarchyGroupId) {
    if (this.loading !== true) {
      this.loading = true;
    }

    this.hierarchyService.getHierarchyGroupById(hierarchyGroupId).subscribe((hierarchyGroup) => {
      this.group = hierarchyGroup;
      this.loading = false;

      this.loadSelector();
      this.fetchHierarchy();
    });
  }

  setFormMode(mode) {
    this.formMode = mode;

    if (this.formMode === FormMode.read) {
      this.loading = true;

      this.getHierarchieGroup(this.group.groupId);
    }
  }

  onDelete() {
    this.loading = true;

    this.hierarchyService.deleteHierarchyGroupBy(this.group).subscribe({
      next: (result) => {
        this.error = null;
        this.success = {
          statusText: "Success",
          success: "Hierarchy group is successfully deleted.",
        };

        setTimeout(() => {
          this.router.navigate(["/System/HierarchyGroups/Overview"]);
        }, 3000);
      },
      error: (error) => {
        this.success = null;
        this.error = error;
        this.loading = false;
        this.cd.markForCheck();
      },
    });
  }

  onSave() {
    this.saving = true;
    this.loading = true;

    this.group.assets = this.assetsList.map((asset) => +asset.id);
    this.group.drivers = this.driversList.map((driver) => +driver.id);
    this.group.geofences = this.geofencesList.map((geofence) => +geofence.id);

    this.hierarchyService.updateHierarchyGroup(this.group).subscribe({
      next: (result) => {
        this.error = null;
        this.success = {
          statusText: "Success",
          success: "Hierarchy group is successfully updated.",
        };

        this.saving = false;
        this.loading = false;
        this.cd.markForCheck();

        this.setFormMode(FormMode.read);
      },
      error: (error) => {
        this.loading = false;
        this.saving = false;
        this.success = null;
        this.error = error;
        this.cd.markForCheck();
      },
    });
  }

  // Display
  fetchHierarchy() {
    this.loading = true;
    this.cd.markForCheck();

    if (!this.group.accountId) {
      this.loading = false;
      this.cd.markForCheck();

      return;
    }

    this.hierarchyService.getHierarchyGroupsByAccountId(this.group.accountId).subscribe((result) => {
      this.hierarchyGroups = result;

      this.loading = false;
      this.cd.markForCheck();
    });
  }

  // Assets
  loadSelector() {
    // Load items from service
    if (+this.group.accountId > -1) {
      this.loadingAssets = true;
      this.loadingDrivers = true;
      this.loadingGeofences = true;

      this.assetService.getAssetsByAccount(this.group.accountId).subscribe((result) => {
        this.assetsSource = result.filter((x) => x.isArchived !== true);
        this.assetsList = Object.keys(this.group.assets).map((n) => ({
          id: +n,
          assetDisplayName: n.toString(),
        }));

        this.loadingAssets = false;

        this.cd.markForCheck();
      });

      this.driverService.getDriversByAccount(this.group.accountId).subscribe((result) => {
        this.driversSource = result.filter((x) => x.isArchived !== true);
        this.driversList = Object.keys(this.group.drivers).map((n) => ({
          id: +n,
          name: n.toString(),
        }));

        this.loadingDrivers = false;
        this.cd.markForCheck();
      });

      this.geofenceService.getGeofencesByAccount(this.group.accountId).subscribe((result) => {
        this.geofencesSource = result.filter((x) => x.isArchived !== true);
        this.geofencesList = Object.keys(this.group.geofences).map((n) => ({
          id: +n,
          name: n.toString(),
        }));

        this.loadingGeofences = false;
        this.cd.markForCheck();
      });
    }
  }
}
