<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/"> <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/GeofenceGroups/Overview">{{'general.geofenceGroups' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/GeofenceGroupDetails/Index/{{geofenceGroup?.id}}">{{geofenceGroup?.displayName}}</a>
                </li>
                <li class="breadcrumb-item active">{{'general.geofenceDetails' | translate }}</li>
            </ol>
        </nav>
    </div>
    <fh-geofence-navigation></fh-geofence-navigation>
    <div *ngIf="!showMapOnSide" class="col-lg-12" style="padding: 1px 0 0 0">
        <ngx-loading [show]="loading"></ngx-loading>
        <fh-leaflet-map
            [geofences]="geofences"
            [height]="mapHeight"
            [showGeofenceSwitch]="false"
            [showSearch]="true"
            [allowBookmarks]="false"
        >
        </fh-leaflet-map>
    </div>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">
        <div class="row" data-equalizer="" *ngIf="error || success">
            <div class="col-lg-12">
                <fh-error [error]="error" [success]="success"></fh-error>
            </div>
        </div>

        <div class="row" data-equalizer="">
            <div class="col-lg-6">
                <form #userForm="ngForm">
                    <div class="ibox" style="position: relative">
                        <div class="ibox-title">
                            <h5><i class="fas fa-fw fa-layer-group"></i> {{'general.geofenceGroup' | translate }}</h5>
                        </div>
                        <fh-group-details [group]="geofenceGroup" [formMode]="formMode" [loading]="loading || saving">
                        </fh-group-details>
                        <fh-save-footer
                            [valid]="!userForm.invalid"
                            (onSave)="onSave()"
                            [allowEdit]="permissions['GeofenceGroups_Edit']"
                            (onDelete)="onDelete()"
                            [allowDelete]="permissions['GeofenceGroups_Delete']"
                            (setFormMode)="setFormMode($event)"
                            [formMode]="formMode"
                        >
                            <span class="float-right">
                                <button
                                    class="btn btn-info"
                                    *ngIf="permissions['Platform_IsReseller']"
                                    (click)="showTriggerModal();"
                                    title="{{ 'general.convertToTrigger' | translate }}"
                                >
                                    <i class="fa fa-fw fa-wand-magic-sparkles"></i>
                                    {{'general.convertToTrigger' | translate }}
                                </button>
                            </span>
                        </fh-save-footer>
                    </div>

                    <div class="ibox" style="position: relative">
                        <div class="ibox-title">
                            <h5>
                                <i class="fas fa-fw fa-layer-group"></i> {{'general.geofenceGroupOptions' | translate }}
                            </h5>
                        </div>
                        <div class="ibox-content slick" style="position: relative">
                            <ngx-loading [show]="loading || saving"></ngx-loading>
                            <fh-geofence-group-details
                                [geofenceGroup]="geofenceGroup"
                                [loadingUserContactGroups]="loadingUserContactGroups"
                                [geofenceGroupOptions]="geofenceGroupOptions"
                                [userContactGroups]="userContactGroups"
                                [formMode]="formMode"
                            ></fh-geofence-group-details>
                        </div>
                    </div>
                </form>
            </div>

            <div class="col-lg-6">
                <div class="ibox" *ngIf="showMapOnSide">
                    <ngx-loading [show]="loading"></ngx-loading>

                    <fh-leaflet-map
                        [geofences]="geofences"
                        [height]="mapHeight"
                        [showGeofenceSwitch]="false"
                        [showSearch]="true"
                        [borderRadius]="8"
                        [allowBookmarks]="false"
                    >
                    </fh-leaflet-map>
                </div>

                <div class="ibox" style="position: relative" *ngIf="formMode == 3">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-list-ul"></i> {{'general.geofences' | translate }}</h5>
                    </div>
                    <div class="ibox-content" style="position: relative">
                        <ngx-loading [show]="loading || saving ||loadingGeofences"></ngx-loading>
                        <fh-dual-list
                            [source]="source"
                            [(destination)]="confirmed"
                            [format]="format"
                            [display]="display"
                            [sort]="true"
                        >
                        </fh-dual-list>
                    </div>
                </div>

                <fh-group-overview
                    *ngIf="formMode == 1"
                    [loading]="loadingGroups"
                    [groups]="geofenceGroupsItems"
                    name="{{'general.geofences' | translate }}"
                    [showItem]="true"
                    [linkUrl]="geofenceLink"
                >
                </fh-group-overview>

                <div class="ibox" style="position: relative">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-list-ul"></i> {{'general.devices' | translate }}</h5>
                    </div>
                    <div class="ibox-content" style="position: relative">
                        <ng-select
                            class="form-control"
                            style="height: unset"
                            [items]="devices"
                            [multiple]="true"
                            bindLabel="name"
                            [closeOnSelect]="false"
                            [readonly]="formMode == 1"
                            bindValue="id"
                            [(ngModel)]="geofenceGroup.selectedDevices"
                        >
                            <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                <div>
                                    <span><img height="32" width="32" [src]="item.iconPath" /></span>
                                    <span style="margin-left: 6px">{{item.assetSearchName}}</span>
                                    <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                                </div>
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                <div>
                                    <span style="padding: 10px"
                                        ><input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"
                                    /></span>
                                    <span><img height="32" width="32" [src]="item.iconPath" /></span>
                                    <span style="margin-left: 6px">{{item.assetSearchName}}</span>
                                </div>
                            </ng-template>
                        </ng-select>
                    </div>
                </div>

                <div class="ibox" *ngIf="!hideTriggers && permissions['Triggers_View']">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-bell-on"></i> {{'general.triggers' | translate }}</h5>
                    </div>
                    <div class="ibox-content slick" style="position: relative">
                        <ngx-loading [show]="loadingTriggers"></ngx-loading>

                        <table style="width: 100%" class="table table-fixed nowrap dtr-inline no-footer">
                            <thead>
                                <tr>
                                    <th>{{'general.name' | translate }}</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="triggers.length == 0">
                                <tr>
                                    <td>{{'general.noTriggersFound' | translate }}</td>
                                </tr>
                            </tbody>
                            <tbody>
                                <tr *ngFor="let trigger of triggers">
                                    <td class="elipses_overflow" title="{{trigger?.name}}">
                                        <a href="/#/Notifications/TriggerDetails/Index/{{trigger?.id}}">
                                            <span class="secondary link_bolder"
                                                ><i class="fas fa-fw fa-angle-right"></i
                                            ></span>
                                            {{trigger?.name}}</a
                                        >
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <fh-tagging [objectId]="geofenceGroup?.id" [objectType]="tagType"> </fh-tagging>
            </div>
        </div>
    </div>
</fh-permission-handler>

<fh-confirmation-modal
    #triggerModal
    (onFinish)="convertToTrigger()"
    title="{{'general.convertToTrigger' | translate }}"
    message="general.convertToTriggerMessage"
></fh-confirmation-modal>
