import { Component, Input, OnInit, OnChanges, ChangeDetectorRef, ChangeDetectionStrategy } from "@angular/core";
import { Group } from "app/models/group.model";
import { AuthenticationService } from "app/services/authentication/authentication.service";

@Component({
  selector: "fh-usertable-overview",
  templateUrl: "userTableOverview.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserTableOverviewComponent implements OnInit, OnChanges {
  @Input() groups: Group[] = [];
  @Input() loading = false;
  @Input() name: string;
  @Input() itemsPerPage = 10;
  @Input() allowSelect = false;
  @Input() showCount = false;
  @Input() showItem = false;
  @Input() sharingIcon = false;
  @Input() linkUrl = "GeofenceGroupDetails";

  // Pagination
  totalItems = 0;
  currentPage = 1;
  smallnumPages = 0;

  filter;
  permissions: {};

  constructor(private cd: ChangeDetectorRef, private authenticationService: AuthenticationService) {
    this.permissions = this.authenticationService.permissions;
  }

  ngOnChanges(): void {
    this.totalItems = this.groups.length;
    this.cd.markForCheck();
  }

  ngOnInit(): void {}

  // Pagination
  setPage(pageNo: number): void {
    this.currentPage = pageNo;
  }

  pageChanged(event: any): void {
    console.log("Page changed to: " + event.page);
    console.log("Number items per page: " + event.itemsPerPage);
  }
}
