<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/"> <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item" *ngIf="project?.accountId">
                    <a href="/#/AccountDetails/Index/{{project?.accountId}}">{{project?.companyName}}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Projects/Overview">{{'general.project' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/ProjectDetails/Index/{{project?.id}}">{{project?.name}}</a>
                </li>
                <li class="breadcrumb-item active">{{'menu.log' | translate }}</li>
            </ol>
        </nav>

        <span style="margin-left: auto" class="fa-filter-container noselect">
            <a
                style="margin-left: 2px"
                class="btn btn-info margin-fix d-none d-md-inline-flex"
                title="Help"
                href="https://help.360locate.com/knowledge-base/fleet-management/auditing/"
                target="_blank"
                ><i class="fa fa-fa fa-book" style="margin-right: 6px"></i>
                <span class="d-none d-md-inline-flex">{{'general.needHelp' | translate }}</span></a
            >
        </span>
    </div>
    <fh-project-details-navigation [projectId]="projectId"></fh-project-details-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">
        <div class="row" data-equalizer="">
            <div class="col-lg-12">
                <fh-error [error]="error" [success]="success"></fh-error>

                <fh-logger [logItems]="projectLog" [loading]="loading"></fh-logger>
            </div>
        </div>
    </div>
</fh-permission-handler>
