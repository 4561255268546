import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { FhChartService } from "app/services/charts/charts.service";
import { ColorService } from "app/services/common/color.service";

import "../../../../vendor/jspdf/IBMPlexSansArabic-Bold-normal.js";
import "../../../../vendor/jspdf/IBMPlexSansArabic-Regular-normal.js";
import "../../../../vendor/jspdf/IBMPlexSansArabic-Light-normal.js";
import "../../../../vendor/jspdf/IBMPlexSansArabic-ExtraLight-normal.js";

import "../../../../vendor/jspdf/fa-solid-900-normal.js";
import "../../../../vendor/leaflet-export/leaflet-export.js";

declare var leafletImage;

import * as XLSX from "xlsx-js-style";
import * as L from "leaflet";
import jsPDF from "jspdf";
import "svg2pdf.js";
import autoTable from "jspdf-autotable";

import {
  colorArray,
  colorArray2,
  deepCopy,
  getIconPath,
  localizeSystemGroupNames,
  roundAsNumber,
  roundAsString,
  roundSeconds,
} from "app/common/globals";
import {
  groupByDate,
  formatFromTimezoneWithFormat,
  fromTimezone,
  groupByString,
} from "app/services/common/functions.service";
import { AuthenticationService } from "app/services/authentication/authentication.service";
import { TranslateService } from "@ngx-translate/core";
import { LeafletMapComponent } from "../shared/usercontrols/leafletMap.component";

import * as Highcharts from "highcharts";

import { parseEpisode } from "app/services/common/episode.parser";
import { DistanceUnitService } from "app/common/distanceunit.service";
import { DistanceUnits, StorageType } from "app/common/enums";

import { ReportTemplate } from "app/models/reporting.model";
import { StorageHelper } from "app/common/storagehelper";

// Moment timezone
import Moment from "moment-timezone";

window["moment"] = Moment;

// Math
import { create, all } from "../../../../vendor/mathjs/math.js";

const config = {};
const math = create(all, config);

@Component({
  selector: "fh-report-details",
  templateUrl: "reportDisplay.template.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportDisplayDetailsComponent implements OnChanges {
  Highcharts: typeof Highcharts = Highcharts;

  @ViewChild(LeafletMapComponent, { static: false }) leafletMapComponent: LeafletMapComponent;

  @Input() selectedReportType;
  @Input() loading;

  @Input() template: ReportTemplate;

  @Input() activeReport;
  @Input() reportData;
  @Input() selectedAccountName;
  @Output() onResetWizard = new EventEmitter();

  timezoneIana;

  showAbuseIcons = false;

  charts = [];
  filter = [];
  filterCategories;

  currentPageArray = [];
  currentPageSections;
  resettedPage = 0;
  p = [];
  p2;
  renderedChart;
  tripFeatureGroup;
  colorArray = colorArray2;

  reportDataFlat: any;
  reportDataGrouped: any;

  formulaErrors = 0;
  mathError: {};
  displayedTripId: any;

  delay = (ms) => new Promise((res) => setTimeout(res, ms));
  mapAsSVG: boolean = true;
  reportDisplayName: any;

  heatmapLayer: any;
  translatedKm: any;
  translatedKmh: any;

  constructor(
    private storageHelper: StorageHelper,
    private cd: ChangeDetectorRef,
    private distance: DistanceUnitService,
    private colorService: ColorService,
    private chartService: FhChartService,
    private translateService: TranslateService,
    private authenticationService: AuthenticationService
  ) {
    this.timezoneIana = this.authenticationService.getTimeZoneIana();

    this.storageHelper.loadStoreState(StorageType.LocalStorage, "settings_", "brightColors").subscribe((result) => {
      if (JSON.parse(result) === true) {
        this.colorArray = colorArray;
      }
    });

    this.translateService.get("general.date").subscribe((data) => {
      this.translatedKm = this.translateService.instant(this.distance.getDistanceUnit());
      this.translatedKmh = this.translateService.instant(this.distance.getDistanceUnitPerHour());
      this.cd.markForCheck();
    });
  }

  resetWizard() {
    this.onResetWizard.emit(true);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["reportData"] || (changes["template"] && changes["template"].firstChange)) {
      // Data checks
      this.reportData?.data?.forEach((data, index) => {
        data?.data?.forEach((subData, index2) => {
          if (subData.TripMethod) {
          }
        });
      });

      this.reportData?.charts?.forEach((chart, index) => {
        // Translate
        chart = this.checkTranslation(chart);

        chart?.chartData?.forEach((chartItem) => {
          chartItem = this.checkTranslation(chartItem);
          chartItem?.Data?.forEach((chartItem2) => {
            chartItem2 = this.checkTranslation(chartItem2);
          });
        });

        this.charts[index] = this.generateChart(chart);
      });

      if (this.reportData?.data?.length > 0) {
        this.formatData(this.template);
      }

      //  this.activeReport.ReportName ? this.activeReport.ReportName : this.selectedReportType
      this.reportDisplayName = this.activeReport?.ReportName
        ? this.activeReport?.ReportName
        : this.activeReport?.template?.name
        ? this.activeReport?.template?.name
        : this.selectedReportType;
    }
  }

  abs(value) {
    return Math.abs(value);
  }

  actualRound(value, decimals) {
    return roundAsNumber(value, decimals);
  }

  setSortIndex(index) {
    if (this.template.orderByIndex == index) {
      this.template.orderByAscending = !this.template.orderByAscending;
    } else {
      this.template.orderByIndex = index;
      this.template.orderByAscending = true;
    }

    this.formatData(this.template);
  }

  evaluateFormula(columnConfiguration, row) {
    let result = "";
    let formula = columnConfiguration.formula;

    const scope: { [id: string]: any } = {};

    formula = formula.replace(/\[(.*?)\]/g, (match, p1, p2, p3, offset, string) => {
      let data: any;

      const entity = p1.split(".")[0];
      const source = p1.split(".")[1];

      if (entity && entity !== "Base") {
        data = row[entity]?.[source];
      } else {
        data = row[source];
      }

      var isNumeric = !isNaN(data);

      if (
        data != null &&
        !isNumeric &&
        Moment(data, true).isValid() &&
        data?.toString()?.indexOf("T") > -1 &&
        data?.toString()?.indexOf("Z") > -1
      ) {
        data = Moment(data).toDate().getTime() / 1000;
      }

      scope[p1.replace(".", "_")] = data;

      return p1.replace(".", "_");
    });

    try {
      result = math.evaluate(formula, scope);
    } catch (error) {
      return { isSuccess: false, result: null, error: error };
    }

    if (isNaN(+result)) {
      return { isSuccess: false, result: result, error: null };
    } else {
      return { isSuccess: true, result: result, error: null };
    }
  }

  public checkTranslation(object) {
    if (object.name && object.name?.indexOf("lt.reporting") > -1) {
      var translation = this.translateService.instant(object.name);
      if (translation.indexOf("lt.reporting") == -1) {
        object.name = translation;
      }
    }

    if (object.Name && object.Name?.indexOf("lt.reporting") > -1) {
      var translation = this.translateService.instant(object.Name);
      if (translation.indexOf("lt.reporting") == -1) {
        object.Name = translation;
      }
    }

    return object;
  }

  public formatData(template) {
    const columns = template.columnConfiguration;

    columns.forEach((data) => {
      data = this.checkTranslation(data);
    });

    this.reportDataFlat = [];
    this.reportDataFlat.data = [];

    this.reportDataGrouped = [];
    this.reportDataGrouped.data = [];

    this.mathError = {};
    this.formulaErrors = 0;

    let idx = 0;
    this.reportData?.kpiList.forEach((kpi) => {
      if (kpi.uom == "km" || kpi.uom == "kmh" || kpi.uom == "km/h") {
        // Check if we need to convert to miles
        if (this.distance.distanceUnit !== DistanceUnits.Kilometers) {
          if (kpi.value != null) {
            kpi.value = this.distance.calculateDistanceUnitFromKm(kpi.value);
          }

          if (kpi.uom == "km") {
            kpi.uom = "mi";
          }

          if (kpi.uom == "kmh" || kpi.uom == "km/h") {
            kpi.uom = "mph";
          }
        }
      }

      kpi = this.checkTranslation(kpi);

      if (this.reportData?.previousKpiList[idx]) {
        kpi.delta = roundAsNumber(kpi.value - this.reportData?.previousKpiList[idx]?.value, 0);
        kpi.deltaPercentage = roundAsNumber(
          ((kpi.value - this.reportData?.previousKpiList[idx]?.value) / this.reportData?.previousKpiList[idx]?.value) *
            100,
          0
        );
        kpi.previousPeriodStart = Moment.utc(this.reportData?.previousPeriodStart)
          ["tz"](this.timezoneIana)
          .format("ll");
        kpi.previousPeriodEnd = Moment.utc(this.reportData?.previousPeriodEnd)["tz"](this.timezoneIana).format("ll");
      }
      idx++;
    });

    this.reportData?.data.forEach((data) => {
      data = this.checkTranslation(data);
    });

    this.reportDataFlat.displayLocations = this.reportData.displayLocations;
    this.reportDataFlat.displayLocationsAsTrip = this.reportData.displayLocationsAsTrip;
    this.reportDataFlat.displayLocationsAsHeatmap = this.reportData.displayLocationsAsHeatmap;

    if (columns == null || columns.length === 0) {
      console.log("Skipping formatting data");
      return;
    }

    // If TWD then we must format trip data
    if (this.activeReport?.ReportType == 2 || this.activeReport?.ReportType == 32) {
      this.reportData?.data.forEach((reportItem) => {
        reportItem?.Data.forEach((trip) => {
          this.prepareTripData(trip);
        });
      });
    }

    const flattenedSource = deepCopy(this.reportData);

    // Flatten data object
    flattenedSource.data.forEach((dataRow) => {
      // Copy original row
      const flattenedObject = { ...dataRow };

      // Reset data list
      flattenedObject.Data = null;

      if (dataRow.Data && dataRow.Data.length > 0) {
        flattenedObject.Data = [];
        dataRow.Data?.forEach((row) => {
          const newRow = {};

          columns.forEach((columnConfiguration, index) => {
            if (columnConfiguration.enabled) {
              let data;

              if (columnConfiguration.entity && columnConfiguration.entity !== "Base") {
                data = row[columnConfiguration.entity]?.[columnConfiguration.source];
              } else {
                data = row[columnConfiguration.source];
              }

              if (columnConfiguration.dataType === "Object") {
                var filter = columnConfiguration.formula;
                var filteredObject = row[columnConfiguration.entity]?.[columnConfiguration.source];

                if (filteredObject != null) {
                  try {
                    var object = JSON.parse(filteredObject);

                    if (object && object.Custom && object.Custom.length > 0) {
                      var theObject = object.Custom;
                      let keys = Object.keys(theObject);

                      if (filter) {
                        var entryIndexe = theObject.findIndex((x) => x.Key === filter);
                        if (entryIndexe > -1) {
                          data = theObject[entryIndexe]["Value"];
                        } else {
                          data = "-";
                        }
                      } else {
                        data = "";
                        keys.forEach((key) => {
                          var object = theObject[key];
                          data = object.Key + ":" + object.Value + "," + data;
                        });
                      }
                    } else {
                      data = "-";
                    }
                  } catch (error) {
                    data = "-";
                  }
                } else {
                  data = "-";
                }
              }

              if (columnConfiguration.dataType === "Formula") {
                const formulaResult = this.evaluateFormula(columnConfiguration, row);
                data = formulaResult.result;

                if (formulaResult.error) {
                  this.formulaErrors++;

                  this.mathError = {
                    error: "Some errors in the formulas",
                    statusText: "Error",
                  };

                  data = formulaResult.error;
                }
              }

              newRow[index] = data ?? "-";
            }
          });

          // Custom data checks
          if (row["Trip"]?.["TripMethod"] === 5) {
            newRow["isHighlighted"] = true;
          }

          flattenedObject.Data.push(newRow);
        });
      }

      this.reportDataFlat.data.push(flattenedObject);
    });

    this.reportDataFlat.data?.forEach((dataItem) => {
      // Create total summary
      const totalSummary = this.createSummary(columns, dataItem.Data);

      // Grouping
      if (template.groupByIndex !== undefined && template.groupByIndex > -1) {
        const groupedResults = [];
        let tempGrouped = [];

        switch (template.groupByType) {
          case 1:
            var columnName = columns[template.groupByIndex];
            tempGrouped = groupByString(dataItem.Data, template.groupByIndex, columnName?.name + " : ");
            break;
          case 2:
            tempGrouped = groupByDate(
              dataItem.Data,
              template.groupByIndex,
              "LL",
              this.translateService.instant("general.date") + ": ",
              template.groupByType
            );
            break;
          case 3:
            tempGrouped = groupByDate(
              dataItem.Data,
              template.groupByIndex,
              "YYYY w",
              this.translateService.instant("general.week") + ": ",
              template.groupByType
            );
            break;
          case 4:
            tempGrouped = groupByDate(
              dataItem.Data,
              template.groupByIndex,
              "YYYYY W",
              this.translateService.instant("general.week") + ": ",
              template.groupByType
            );
            break;
          case 5:
            tempGrouped = groupByDate(
              dataItem.Data,
              template.groupByIndex,
              "YYYY MMM",
              this.translateService.instant("general.month") + ": ",
              template.groupByType
            );
            break;
          case 6:
            tempGrouped = groupByDate(
              dataItem.Data,
              template.groupByIndex,
              "YYYY Q",
              this.translateService.instant("general.quarter") + ": ",
              template.groupByType
            );
            break;
          case 7:
            tempGrouped = groupByDate(
              dataItem.Data,
              template.groupByIndex,
              "YYYY",
              this.translateService.instant("general.year") + ": ",
              template.groupByType
            );
            break;
          default:
            tempGrouped = groupByString(dataItem.Data, 999, "");
            break;
        }
        // Reset the data
        dataItem.Data = [];
        let intermediateData = [];

        Object.values(tempGrouped).forEach((groupedDataItem: any, index) => {
          // Add header
          intermediateData = [];

          intermediateData.push({ isHeader: true, headerName: Object.keys(tempGrouped)[index] });

          this.orderData(groupedDataItem, template.orderByIndex, template, columns);

          groupedDataItem.forEach((theDataItem) => {
            intermediateData.push(theDataItem);
          });

          // Add footer
          var sum = this.createSummary(columns, groupedDataItem);

          if (sum) {
            intermediateData.push(sum);
          }

          // Insert into data list
          intermediateData.forEach((element) => {
            dataItem.Data.push(element);
          });
        });

        // Add the total summary
        dataItem.Data.push(totalSummary);
      } else {
        this.orderData(dataItem.Data, template.orderByIndex, template, columns);

        // Draw summary
        if (totalSummary) {
          dataItem.Data.push(totalSummary);
        }
      }
    });

    this.cd.markForCheck();
  }

  orderData(dataItem, orderByIndex, t, columns) {
    // Ordering
    if (orderByIndex !== undefined && orderByIndex > -1) {
      if (t.orderByAscending !== false) {
        // Ascending
        if (columns[orderByIndex].dataType === "DateTime") {
          dataItem?.sort((a, b) => new Date(a[orderByIndex])?.getTime() - new Date(b[orderByIndex])?.getTime());
        } else if (columns[orderByIndex].dataType === "String") {
          dataItem?.sort((a, b) => {
            let valueA = a[orderByIndex];
            let valueB = b[orderByIndex];

            if (typeof valueA === "object") {
              if (valueA != undefined && valueA.hasOwnProperty("Value")) {
                valueA = valueA?.Value;
              } else {
                valueA = "";
              }
            }
            if (typeof valueB === "object") {
              if (valueB != undefined && valueB.hasOwnProperty("Value")) {
                valueB = valueB?.Value;
              } else {
                valueB = "";
              }
            }

            return valueA?.localeCompare(valueB);
          });
        } else {
          dataItem?.sort((a, b) => a[orderByIndex] - b[orderByIndex]);
        }
      } else {
        // Descending
        if (columns[orderByIndex].dataType === "DateTime") {
          dataItem?.sort((a, b) => new Date(b[orderByIndex])?.getTime() - new Date(a[orderByIndex])?.getTime());
        } else if (columns[orderByIndex].dataType === "String") {
          dataItem?.sort((a, b) => {
            let valueA = a[orderByIndex];
            let valueB = b[orderByIndex];

            if (typeof valueA === "object") {
              if (valueA != undefined && valueA.hasOwnProperty("Value")) {
                valueA = valueA?.Value;
              } else {
                valueA = "";
              }
            }
            if (typeof valueB === "object") {
              if (valueB != undefined && valueB.hasOwnProperty("Value")) {
                valueB = valueB?.Value;
              } else {
                valueB = "";
              }
            }

            return valueB?.localeCompare(valueA);
          });
        } else {
          dataItem?.sort((a, b) => b[orderByIndex] - a[orderByIndex]);
        }
      }
    }

    // Add rownumbers
    const rowNumberColumns = columns.filter((x) => x.source?.indexOf("RowNumber") > -1);
    rowNumberColumns.forEach((column) => {
      const rowNumberIndex = columns.indexOf(column);
      let counter = 1;

      if (rowNumberIndex > -1) {
        dataItem
          .slice()
          .reverse()
          .forEach((data) => {
            if (!data) {
              return;
            }

            if (data.isHeader || data.isFooter || data.isSummary) {
              // Reset counter
              counter = 1;
            } else {
              data[rowNumberIndex] = counter;
              counter++;
            }
          });
      }
    });
  }

  createSummary(columns, sourceData) {
    if (columns.some((x) => x.grouping !== 0)) {
      // Add summary column
      const summary = {};
      summary["isSummary"] = true;

      columns.forEach((column, index) => {
        if (column.grouping) {
          switch (column.grouping) {
            case 1:
              const sum = sourceData?.reduce((partial_sum, d) => partial_sum + (isNaN(d[index]) ? 0 : d[index]), 0);
              summary[index] = sum;
              break;
            case 2:
              const sumForAvg = sourceData?.reduce(
                (partial_sum, d) => partial_sum + (isNaN(d[index]) ? 0 : d[index]),
                0
              );
              const count = sourceData?.reduce((partial_sum, d) => partial_sum + (isNaN(d[index]) ? 0 : 1), 0);

              summary[index] = sumForAvg / count;
              break;
            case 3:
              const min = sourceData?.reduce((partial_sum, d) => (partial_sum > d[index] ? partial_sum : d[index]), 0);

              summary[index] = min;
              break;
            case 4:
              const max = sourceData?.reduce((partial_sum, d) => (partial_sum > d[index] ? partial_sum : d[index]), 0);

              summary[index] = max;
              break;
            default:
              summary[index] = null;
              break;
          }
        } else {
          summary[index] = null;
        }
      });

      return summary;
    }
  }

  resetSubPage(page) {
    // Reset subpage
    if (page !== this.resettedPage) {
      this.currentPageArray[page] = 0;

      this.renderedChart = null;

      this.resettedPage = page;
    }
  }

  checkPage(page, active) {
    let name = "";
    let selectedData;

    if (this.reportDataFlat?.data) {
      selectedData = this.reportDataFlat?.data[page.value - 1];
    }

    if (selectedData?.IconId !== undefined) {
      name += this.formatIconId(selectedData.IconId);
    }

    name += `<span>${selectedData?.Name}</span>`; // (${selectedData.Data?.length})
    return name;
  }

  formatIconId(iconId) {
    return (
      '<img style="position: relative; margin-top: -10px; margin-bottom: -3px; padding-right:10px" src="' +
      getIconPath(iconId)[1] +
      '">'
    );
  }

  createHeader(item, index = 0) {
    if (item.IconId !== undefined) {
      return this.formatIconId(item.IconId);
    }

    return '<i class="fas fa-fw ' + (item.IconId ?? "fa-file-chart-pie") + '"></i>';
  }

  checkSpecialFormatting(value, formatting, uom) {
    let digits = 0;

    if (value === "-") {
      return value;
    }

    if (isNaN(value)) {
      value = 0;
    }

    switch (formatting) {
      case "NUMERIC_num0":
        digits = 0;
        break;
      case "NUMERIC_num1":
        digits = 1;
        break;
      case "NUMERIC_num2":
        digits = 2;
        break;
      case "NUMERIC_num3":
        digits = 3;
        break;
      case "NUMERIC_m":
        // Conversion to minutes
        return Moment.utc(new Date(value * 1_000)).diff(0, "minute");
      case "NUMERIC_H":
        // Conversion to hours
        return Moment.utc(new Date(value * 1_000)).diff(0, "hour");
      case "NUMERIC_mm:ss":
        // Conversion to minutes seconds
        const minutes = Moment.utc(new Date(value * 1_000)).diff(0, "minute");
        return (
          `${minutes}`.padStart(2, "0") +
          ":" +
          `${Moment.utc(new Date(value * 1_000)).diff(0, "seconds") - minutes * 60}`.padStart(2, "0")
        );
      case "NUMERIC_HH:mm":
        var result = roundSeconds(value, true, false);
        return result;
      case "NUMERIC_HH:mm:ss":
        var result = roundSeconds(value);
        return result;
      default:
        // Conversion from seconds
        return Moment.utc(new Date(value * 1_000)).format(formatting.substring("NUMERIC_".length));
    }

    const no: any = value + "e" + digits;
    const formattedValue = Number(Math.round(no) + "e-" + digits).toLocaleString(undefined, {
      minimumFractionDigits: digits,
      maximumFractionDigits: digits,
    });

    return `${formattedValue}${formattedValue !== "-" ? uom : ""}`;
  }

  checkFormat(key, item, displayFormat, column) {
    if (!column) {
      console.log("columns not found", key, item);
      return;
    }

    let value = item[key];
    const nullMarker = item.isSummary ? "" : "-";

    let uom = column.uom;
    if (!uom || +uom === 0 || displayFormat === "XLSX") {
      uom = "";
    } else {
      uom = this.translateService.instant("enums.uom." + column.uom);
    }

    if (column.uom == "km" || column.uom == "kmh") {
      // Check if we need to convert to miles
      if (this.distance.distanceUnit !== DistanceUnits.Kilometers) {
        if (value != null) {
          value = this.distance.calculateDistanceUnitFromKm(value);
        }

        if (column.uom == "km") {
          uom = this.translateService.instant("enums.uom.mi");
        }

        if (column.uom == "kmh") {
          uom = this.translateService.instant("enums.uom.mph");
        }
      }
    }

    // Get from column configuration
    if (column != null) {
      key = column.source;
    }

    if (value == null || value === undefined || (typeof value.trim === "function" && value.trim() === ",")) {
      return nullMarker;
    } else {
      // Check for link
      if (typeof value === "object") {
        if (displayFormat === "HTML") {
          if (value?.Link !== undefined) {
            let addressLink = "";
            if (value?.Value == null || value?.Value?.length === 0) {
              if (item?.isHighlighted) {
                return this.translateService.instant("lt.reporting.CalibrationTrip");
              } else {
                if (value?.Link?.length === 0) {
                  return this.translateService.instant("lt.reporting.CalibrationTrip");
                } else {
                  addressLink = this.createClickableLink(
                    this.translateService.instant("lt.reporting.AddressUnknown"),
                    value.Link
                  );
                }
              }
              return addressLink;
            }
            addressLink = this.createClickableLink(value?.Value, value?.Link);
            return addressLink;
          }
        } else if (displayFormat === "PDF") {
          if (value?.Value == undefined || value?.Value?.length === 0) {
            if (item?.isHighlighted) {
              return this.translateService.instant("lt.reporting.CalibrationTrip");
            } else {
              return this.translateService.instant("lt.reporting.AddressUnknown");
            }
          }
          return value.Value;
        } else if (displayFormat === "XLSX") {
          if (value?.Value == undefined || value?.Value?.length === 0) {
            if (item?.isHighlighted) {
              return this.translateService.instant("lt.reporting.CalibrationTrip");
            } else {
              return this.translateService.instant("lt.reporting.AddressUnknown");
            }
          }
          var returnLink = { t: "s", v: value?.Value, l: { Target: value?.Link } };
          return returnLink;
        }
      }
    }

    if (key.endsWith("IconId")) {
      if (displayFormat !== "HTML") {
        return value;
      }

      return this.formatIconId(value);
    }

    let dataType = column?.dataType;
    if (dataType === "Formula" && column.formatting?.startsWith("DATE_")) {
      dataType = "DateTime";
    }

    switch (dataType) {
      case "DateTime":
        if (value == null || value === undefined || value === "-") {
          return nullMarker;
        }

        if (!column.formatting || column.formatting === "") {
          column.formatting = "DATE_LLL";
        }

        if (displayFormat === "XLSX") {
          const dateValue = new Date(value);

          if (isNaN(dateValue.getTime())) {
            console.log("no date", value);
            return value;
          }

          switch (column.formatting) {
            case "DATE_lll":
              return { t: "d", v: dateValue, z: "m/d/yy" };
            case "DATE_LLL":
              return { t: "d", v: dateValue, z: "m/d/yy" };
            case "DATE_LLLL":
              return { t: "d", v: dateValue, z: "mm/dd/yyyy" };
            case "DATE_L":
              return { t: "d", v: dateValue, z: "m/d/yy" };
            case "DATE_l":
              return { t: "d", v: dateValue, z: "m/d/yy" };
            case "DATE_LL":
              return { t: "d", v: dateValue, z: "m/d/yy" };
            case "DATE_ll":
              return { t: "d", v: dateValue, z: "m/d/yy" };
            case "DATE_LT":
              return { t: "d", v: dateValue, z: "HH:mm" };
            case "DATE_LTS":
              return { t: "d", v: dateValue, z: "HH:mm:ss" };
            case "DATE_HH:mm":
              return { t: "d", v: dateValue, z: "HH:mm" };
            case "DATE_HH:mm:ss":
              return { t: "d", v: dateValue, z: "HH:mm:ss" };
            case "DATE_YYYY-MM-DD HH:mm":
              return { t: "d", v: dateValue, z: "YYYY-MM-DD HH:mm" };
            case "DATE_DD":
              return { t: "d", v: dateValue, z: "DD" };
            case "DATE_MM":
              return { t: "d", v: dateValue, z: "MM" };
            case "DATE_YYYY":
              return { t: "d", v: dateValue, z: "YYYY" };
          }
        }

        if (displayFormat !== "HTML") {
          return formatFromTimezoneWithFormat(value, this.timezoneIana, column.formatting.substring("DATE_".length));
        }

        return (
          '<span title="' +
          fromTimezone(value, this.timezoneIana).toLocaleString() +
          '">' +
          formatFromTimezoneWithFormat(value, this.timezoneIana, column.formatting.substring("DATE_".length)) +
          "</span>"
        );
      case "Float":
        if (value == null || value === undefined || value === "-") {
          return nullMarker;
        }

        if (!column.formatting || column.formatting === "") {
          if (displayFormat === "XLSX") {
            return { t: "n", v: value };
          }

          return `${value}${value !== "-" ? uom : ""}`;
        }

        if (displayFormat === "XLSX") {
          switch (column.formatting) {
            case "NUMERIC_m":
              return { t: "n", v: value / 86400, z: "[m]" };
            case "NUMERIC_H":
              return { t: "n", v: value / 86400, z: "[h]" };
            case "NUMERIC_mm:ss":
              return { t: "n", v: value / 86400, z: "[m]:ss" };
            case "NUMERIC_HH:mm":
              return { t: "n", v: value / 86400, z: "[h]:mm" };
            case "NUMERIC_HH:mm:ss":
              return { t: "n", v: value / 86400, z: "[h]:mm:ss" };
            case "NUMERIC_num0":
              return { t: "n", z: "0", v: value };
            case "NUMERIC_num1":
              return { t: "n", z: "0.0", v: value };
            case "NUMERIC_num2":
              return { t: "n", z: "0.00", v: value };
            case "NUMERIC_num3":
              return { t: "n", z: "0.000", v: value };
            default:
              return { t: "n", z: "#,#0", v: value };
          }
        }

        return this.checkSpecialFormatting(value, column.formatting, uom);
      case "Formula":
      case "Int32":
      case "Int64":
        if (value == null || value === undefined || value === "-") {
          return nullMarker;
        }

        if (displayFormat === "XLSX") {
          switch (column.formatting) {
            case "NUMERIC_m":
              return { t: "n", v: value / 86400, z: "[m]" };
            case "NUMERIC_H":
              return { t: "n", v: value / 86400, z: "[h]" };
            case "NUMERIC_mm:ss":
              return { t: "n", v: value / 86400, z: "[m]:ss" };
            case "NUMERIC_HH:mm":
              return { t: "n", v: value / 86400, z: "[h]:mm" };
            case "NUMERIC_HH:mm:ss":
              return { t: "n", v: value / 86400, z: "[h]:mm:ss" };
            case "NUMERIC_num0":
              return { t: "n", z: "0", v: value };
            case "NUMERIC_num1":
              return { t: "n", z: "0.0", v: value };
            case "NUMERIC_num2":
              return { t: "n", z: "0.00", v: value };
            case "NUMERIC_num3":
              return { t: "n", z: "0.000", v: value };
            default:
              return { t: "n", z: "#,#0", v: value };
          }
        }

        if (key.endsWith("Id")) {
          return `${value}${uom}`;
        } else if (!column.formatting || column.formatting === "") {
          return `${value.toLocaleString()}${uom}`;
        }

        return this.checkSpecialFormatting(value, column.formatting, uom);
      case "Boolean":
        let booleanValue = value;

        if (column.inverted) {
          booleanValue = !booleanValue;
        }

        var booleanValueFormatted = "";
        booleanValueFormatted = booleanValue.toString();

        if (!column.formatting || column.formatting === "") {
          booleanValueFormatted = booleanValue == true ? "True" : "False";
        }

        switch (column.formatting) {
          case "BOOL_truefalse":
            booleanValueFormatted = booleanValue == true ? "True" : "False";
            break;
          case "BOOL_onoff":
            booleanValueFormatted = booleanValue == true ? "On" : "Off";
            break;
          case "BOOL_highlow":
            booleanValueFormatted = booleanValue == true ? "High" : "Low";
            break;
          case "BOOL_openclosed":
            booleanValueFormatted = booleanValue == true ? "Open" : "Closed";
            break;
          case "BOOL_enableddisabled":
            booleanValueFormatted = booleanValue == true ? "Enabled" : "Disabled";
            break;
          default:
            booleanValueFormatted = booleanValue == true ? "True" : "False";
        }

        if (displayFormat === "XLSX") {
          return { t: "b", v: value };
        }

        if (displayFormat === "HTML") {
          if (booleanValue) {
            return (booleanValueFormatted = '<span style="color: green">' + booleanValueFormatted + "</span>");
          } else {
            return (booleanValueFormatted = '<span style="color: darkred">' + booleanValueFormatted + "</span>");
          }
        }

        return booleanValueFormatted;
      default:
        if (key.indexOf("Groups") > -1) {
          let returnObject = "";

          try {
            const groups = JSON.parse(value);

            groups.forEach((group) => {
              if (returnObject !== "") {
                returnObject += ", ";
              }
              returnObject += localizeSystemGroupNames(group.name, this.translateService);
            });

            if (displayFormat === "PDF") {
              if (returnObject.length > 30) {
                return returnObject.substring(0, 27) + "...";
              }
              return returnObject;
            }

            return returnObject;
          } catch (error) {
            return value;
          }
        }

        if (key.indexOf("Score") > -1) {
          let color = "";
          switch (value) {
            case value < 0.1:
              color = "green";
              break;
            case value < 0.5:
              color = "greenyellow";
              break;
            case value < 0.2:
              color = "orange";
              break;
            case value < 0.5:
              color = "orangered";
              break;
            default:
              color = "red";
              break;
          }

          if (displayFormat !== "HTML") {
            return roundAsString(value, 1);
          } else {
            return `
              <span title="score: ${value}" style="font-weight: 700; color: ${color}">
                  ${roundAsString(value, 1)}
              </span>`;
          }
        }

        break;
    }

    if (displayFormat === "XLSX") {
      return { t: "s", v: value };
    }

    return value;
  }

  // Function used to generate map for display as html for normal report
  generateMapHtmlDisplay(item) {
    const latColumn = this.template.columnConfiguration.find((x) => x.source?.indexOf("Latitude") > -1);
    const lonColumn = this.template.columnConfiguration.find((x) => x.source?.indexOf("Longitude") > -1);
    const timestampColumn = this.template.columnConfiguration.find((x) => x.source?.indexOf("Timestamp") > -1);

    const latIndex = this.template.columnConfiguration.indexOf(latColumn);
    const lonIndex = this.template.columnConfiguration.indexOf(lonColumn);
    const timestampIndex = this.template.columnConfiguration.indexOf(timestampColumn);

    if (latIndex < 0 || lonIndex < 0) {
      return false;
    }

    var messages =
      item?.Data &&
      item?.Data.map((data) => {
        if (data != null && data[latIndex] != null && data[lonIndex] != null)
          return {
            latitude: data[latIndex],
            longitude: data[lonIndex],
            ts: data[timestampIndex],
          };
      });

    if (messages && messages.length > 0) {
      setTimeout(() => {
        this.drawLocations(
          this.reportDataFlat?.DisplayLocationsAsTrip,
          this.leafletMapComponent?.map,
          messages,
          !this.reportDataFlat?.displayLocationsAsHeatmap
            ? this.leafletMapComponent?.tripLayer
            : this.leafletMapComponent?.heatmapLayer
        );
      }, 100);

      // Draw the map
      return true;
    } else {
      // Dont draw the map
      return false;
    }
  }

  onMapReady(map) {
    setTimeout(() => {
      this.leafletMapComponent.invalidateSize();
    }, 10);
  }

  drawLocations(
    displayAsTrip = this.reportDataFlat?.displayLocationsAsTrip,
    map = this.leafletMapComponent?.map,
    locationData,
    tripLayer,
    displayAsHeatmap = this.reportDataFlat?.displayLocationsAsHeatmap
  ) {
    console.log("Drawing locations");

    const pointList = [];
    const color = "#000";
    if (this.tripFeatureGroup) {
      map.removeLayer(this.tripFeatureGroup);
    }

    this.tripFeatureGroup = L.featureGroup();

    // Sort on timestamp
    locationData.sort((a, b) => (a.ts < b.ts ? -1 : 1));

    locationData.forEach((location) => {
      if (
        location != null &&
        location.latitude != null &&
        location.latitude !== "0" &&
        location.latitude !== 0 &&
        location.latitude !== "-"
      ) {
        pointList.push(new L.LatLng(location.latitude, location.longitude));
      }
    });

    if (pointList.length == 0) {
      console.log("Return null");
      return null;
    }

    this.tripFeatureGroup.addTo(tripLayer);

    var heatmapData = [];

    if (displayAsHeatmap || pointList.length > 1000) {
      if (this.heatmapLayer) {
        this.leafletMapComponent.heatmapLayer.removeLayer(this.heatmapLayer);
      }

      pointList.forEach((point, index) => {
        heatmapData.push([point.lat, point.lng, 1]);
      });

      this.heatmapLayer = L["heatLayer"](heatmapData, { radius: 25 }).addTo(this.leafletMapComponent.heatmapLayer);
    } else if (displayAsTrip) {
      const tripPolyLine = new L.Polyline(pointList, {
        color,
        weight: 2,
        opacity: 1,
        smoothFactor: 1,
        dashArray: "10, 5",
      });

      const tripPolyLine2 = new L.Polyline(pointList, {
        color: "#fff",
        weight: 6,
        opacity: 0.8,
      });

      const tripPolyLine3 = new L.Polyline(pointList, {
        color: "#000",
        weight: 9,
        opacity: 0.3,
      });

      // Markers
      const iconUrl = "assets/images/icons/marker-icon.png";

      const startIcon = L.icon({
        iconUrl: iconUrl,
        shadowUrl: null,
        iconSize: [25, 41], // size of the icon
        iconAnchor: [0, 0], // point of the icon which will correspond to marker's location
        shadowAnchor: [0, 0], // the same for the shadow
        popupAnchor: [0, 0], // point from which the popup should open relative to the iconAnchor
      });

      const startMarker = L.marker(new L.LatLng(pointList[0].lat, pointList[0].lng), { icon: startIcon });

      const finishIcon = L.icon({
        iconUrl: "assets/images/icons/end.png",
        shadowUrl: null,
        className: "markerEnd",

        iconSize: [28, 28], // size of the icon
        iconAnchor: [0, 0], // point of the icon which will correspond to marker's location
        shadowAnchor: [0, 0], // the same for the shadow
        popupAnchor: [0, 0], // point from which the popup should open relative to the iconAnchor
      });

      const endMarker = L.marker(
        new L.LatLng(pointList[pointList.length - 1].lat, pointList[pointList.length - 1].lng),
        { icon: finishIcon }
      );

      this.tripFeatureGroup = L.featureGroup([tripPolyLine3, tripPolyLine2, tripPolyLine, startMarker, endMarker]);
      this.tripFeatureGroup.addTo(tripLayer);

      const bounds = this.tripFeatureGroup.getBounds();

      if (bounds.isValid()) {
        map.fitBounds(bounds, { padding: [5, 5] }, { animate: false, duration: 0 });
      }
    } else {
      pointList.forEach((point, index) => {
        const color2 = this.colorArray[index % this.colorArray.length];

        const pointIcon = new L["NumberMarker"].Icon({
          backgroundColor: color2,
          className: "m360",
          color: "#fff",
          number: index + 1,
        });
        const pointMarker = L.marker(point, { icon: pointIcon });
        pointMarker.addTo(tripLayer);
        pointMarker.addTo(this.tripFeatureGroup);
      });
    }

    if (displayAsHeatmap) {
      let minLat = heatmapData[0][0];
      let minLng = heatmapData[0][1];
      let maxLat = heatmapData[0][0];
      let maxLng = heatmapData[0][1];

      heatmapData.forEach((element) => {
        minLat = Math.min(element[0], minLat);
        minLng = Math.min(element[1], minLng);
        maxLat = Math.max(element[0], maxLat);
        maxLng = Math.max(element[1], maxLng);
      });

      var corner1 = L.latLng(minLat, minLng),
        corner2 = L.latLng(maxLat, maxLng),
        bounds2 = L.latLngBounds(corner1, corner2);

      if (bounds2.isValid()) {
        map.fitBounds(bounds2, { padding: [5, 5] }, { animate: false, duration: 0 });
      }
      return;
    }

    const bounds = this.tripFeatureGroup.getBounds();

    if (bounds.isValid()) {
      map.fitBounds(bounds, { padding: [5, 5] }, { animate: false, duration: 0 });
    }
  }

  public addEpisodesToMap(trip, layer) {
    trip.Episodes.forEach((episode, index) => {
      this.addEpisodeToMap(episode, layer, index);
    });
  }

  public addEpisodeToMap(episode, layer, index) {
    const color2 = this.colorArray[index % this.colorArray.length];

    const theEpisodeIcon = new L["NumberMarker"].Icon({
      backgroundColor: color2,
      className: "m360",
      color: "#fff",
      number: index + 1,
    });

    const episodeMarker = L.marker([episode.BeginLatitude, episode.BeginLongitude], { icon: theEpisodeIcon }).addTo(
      layer
    );
  }

  parseChartData(theData, chart, chartType) {
    let yAxis = 0;
    let dashStyle = "Solid";
    let step = undefined;

    if (chart.Name) {
      if (chart.Name.indexOf("Speed") > -1) {
        yAxis = 6;
      }

      if (chart.Name.indexOf("Temp") > -1) {
        yAxis = 1;
        dashStyle = "LongDash";
      }

      if (chart.Name.indexOf("Humidity") > -1) {
        yAxis = 2;
      }

      if (chart.Name.indexOf("Weight") > -1) {
        yAxis = 3;
        dashStyle = "ShortDot";
      }

      if (chart.Name.indexOf("Ignition") > -1) {
        yAxis = 4;
        step = "left";
      }

      if (chart.Name.indexOf("Power") > -1) {
        yAxis = 5;
        step = "left";
      }

      if (chart.Name.indexOf("Analog") > -1) {
        yAxis = 7;
        dashStyle = "ShortDotDash";
      }

      if (chart.Name.indexOf("Odometer") > -1) {
        yAxis = 0;
      }

      if (chart.Name.indexOf("Door") > -1) {
        yAxis = 4;
        step = "left";
      }

      if (chart.Name.indexOf("Fuel") > -1) {
        yAxis = 2;
        dashStyle = "LongDashDot";
      }
    }

    theData.push({
      data: chart.Data,
      dashStyle: dashStyle,
      type: chart.Type ?? chartType,
      color: chart.Color ?? null,
      yAxis: yAxis,
      step: step,
      visible: chart.Visible ?? true,
      name: chart.Name,
    });
  }

  // Generate chart locationcount
  generateChart(data) {
    const theData = [];
    let categories = [];

    if (data.chartData) {
      categories = data.categories;
      data.chartData.forEach((chart) => {
        this.parseChartData(theData, chart, data.chartType);
      });
    }

    if (data.ChartData) {
      categories = data.Categories;
      data.ChartData?.forEach((chart) => {
        this.parseChartData(theData, chart, data.chartType);
      });
    }

    let theChart;

    if (data.isDateTimeBased || data.IsDateTimeBased) {
      // For instance sensor based
      theChart = this.chartService.generateReportChartDateTime(theData, data.chartType, true);
    } else {
      theChart = this.chartService.generateReportChart(theData, categories, data.chartType, false);
    }

    data.renderedChart = theChart;
    return theChart;
  }

  // Export to XLSX
  downloadXls(exportAsCsv = true, formatXlsExport = false) {
    // If TWD then do a custom xls download
    if (this.activeReport?.ReportType == 2 || this.activeReport?.ReportType == 32) {
      return this.downloadTwdXls(exportAsCsv);
    }

    const columns = this.template.columnConfiguration;

    const theme = this.colorService.getThemeByUserType(this.activeReport.userType);
    const headerColor = theme.primary?.replace("#", "");
    const header2Color = theme.secondary?.replace("#", "");

    const workbook = XLSX.utils.book_new();

    const requestData = this.generateHeaderData();

    requestData.unshift({ Name: this.translateService.instant("general.reportName"), Value: this.reportDisplayName });

    const kpiDeepCopy = JSON.parse(JSON.stringify(this.reportData?.kpiList ?? []));

    kpiDeepCopy.forEach((item, key) => {
      if (item?.hasOwnProperty("icon")) {
        delete item["icon"];
      }
    });

    const workSheetKpi: XLSX.WorkSheet = XLSX.utils.json_to_sheet(kpiDeepCopy, {
      skipHeader: false,
      dateNF: "yyyy-mm-dd HH:mm;@",
      cellDates: true,
    });

    Object.values(workSheetKpi).forEach((element) => {
      if (element.v && typeof element.v === "string") {
        element.v = element.v?.charAt(0).toUpperCase() + element.v?.slice(1);
      }
    });

    this.xlsFormatSheet(workSheetKpi, headerColor, [], [], formatXlsExport, columns);
    XLSX.utils.book_append_sheet(workbook, workSheetKpi, this.translateService.instant("lt.reporting.Summary"));

    this.reportDataFlat.data.forEach((sheet, index) => {
      const contentData = [];
      const headerIndex = [];
      const summaryIndex = [];

      sheet.Data.forEach((dataRow, rowIndex) => {
        const item = {};

        let pushExtraRow = false;

        if (dataRow != null && ((!dataRow["isSummary"] && !dataRow["isHeader"]) || formatXlsExport)) {
          Object.keys(dataRow).forEach((key, columnIndex) => {
            if (key == "isHeader") {
              headerIndex.push(rowIndex);
            }
            if (key == "isSummary" || key == "isHighlighted") {
              summaryIndex.push(rowIndex);
            }
            if (key !== "isSummary" && key !== "isHeader" && key !== "isHighlighted") {
              if (columns[columnIndex] && columns[columnIndex].name) {
                item[columns[columnIndex].name] = this.checkFormat(key, dataRow, "XLSX", columns[columnIndex]);
              }
            }
          });

          contentData.push(item);
        }
      });

      const workSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(contentData, {
        dateNF: "yyyy-mm-dd HH:mm;@",
        cellDates: true,
      });
      this.xlsFormatSheet(workSheet, headerColor, headerIndex, summaryIndex, formatXlsExport, columns);

      XLSX.utils.book_append_sheet(
        workbook,
        workSheet,
        sheet.Name?.replace(/[|&;$%@"<>()+,\/]/g, "").substring(0, 30) ?? "Unknown"
      );
    });

    const requestworkSheetKpi: XLSX.WorkSheet = XLSX.utils.json_to_sheet(requestData, {
      skipHeader: false,
      dateNF: "yyyy-mm-dd HH:mm;@",
      cellDates: true,
    });
    this.xlsFormatSheet(requestworkSheetKpi, headerColor, [], [], formatXlsExport, columns);

    XLSX.utils.book_append_sheet(workbook, requestworkSheetKpi, this.translateService.instant("lt.reporting.Request"));

    const filename = ("fm_report_" + this.selectedAccountName + "_" + this.selectedReportType)
      .replace(/\s/g, "")
      .replace(" ", "")
      .replace(".", "")
      .toLowerCase();

    if (exportAsCsv) {
      XLSX.writeFile(workbook, filename + ".csv", { bookType: "csv", compression: true });
      return true;
    } else {
      XLSX.writeFile(workbook, filename + ".xlsx", { compression: true });
      return true;
    }
  }

  alphaToNum(alpha) {
    var i = 0,
      num = 0,
      len = alpha.length;

    for (; i < len; i++) {
      num = num * 26 + alpha.charCodeAt(i) - 0x40;
    }

    return num - 1;
  }

  numToAlpha(num) {
    var alpha = "";

    for (; num >= 0; num = parseInt((num / 26).toString(), 10) - 1) {
      alpha = String.fromCharCode((num % 26) + 0x41) + alpha;
    }

    return alpha;
  }

  xlsFormatSheet(sheet, color, headerIndex = [], summaryIndex = [], formatXlsExport = false, columns) {
    var indexes = sheet["!ref"];
    var key = indexes.split(":");
    var cols = [];
    var merges = [];

    if (!formatXlsExport) {
      return;
    }

    Object.keys(sheet).forEach((key: any, index) => {
      cols.push({ wch: 17 });

      let alignment = "left";

      var keyIndex = key.replace(/[A-Z]/g, "");
      var keyColumn = key.replace(/[0-9]/g, "");

      var col = this.alphaToNum(keyColumn);
      var endColumn = this.numToAlpha(columns.length - 1);

      var columnConfiguration = columns[col];

      if (columnConfiguration && columnConfiguration.alignment) {
        switch (columnConfiguration.alignment) {
          case 1:
            alignment = "left";
            break;
          case 2:
            alignment = "middle";
            break;
          case 3:
            alignment = "right";
            break;

          default:
            break;
        }
      }

      if (keyIndex == "1") {
        sheet[key].s = {
          fill: {
            fgColor: { rgb: color },
          },
          font: {
            sz: 13,
            bold: true,
            color: { rgb: "FFFFFF" },
          },
          alignment: {
            horizontal: alignment,
          },
        };
      }
      // Check if in headerindex
      else if (headerIndex.indexOf(+keyIndex - 2) > -1) {
        // Merge headerrow
        merges.push(XLSX.utils.decode_range(key + ":" + (endColumn + keyIndex)));

        sheet[key].s = {
          fill: {
            fgColor: { rgb: "F9F9F9" },
          },
          font: {
            sz: 11,
            bold: true,
            color: { rgb: "222222" },
          },
          alignment: {
            horizontal: alignment,
          },
        };
      }
      // Check if in summaryindex
      else if (summaryIndex.indexOf(+keyIndex - 2) > -1) {
        sheet[key].s = {
          fill: {
            fgColor: { rgb: "F2F2F2" },
          },
          font: {
            sz: 11,
            bold: true,
            color: { rgb: "222222" },
          },
          alignment: {
            horizontal: alignment,
          },
        };
      } else {
        if (sheet[key] != null && sheet[key].t != null) {
          sheet[key].s = {
            alignment: {
              horizontal: alignment,
            },
          };
        }
      }
    });

    sheet["!cols"] = cols;
    sheet["!merges"] = merges;
  }

  xlsFormatHeader(sheet, color) {
    var returnSheet = [];
    var cols = [];

    var actionIndex = sheet[0].indexOf("Action");
    var activityIndex = sheet[0].indexOf(this.translateService.instant("reportHeader.Activity"));

    sheet.forEach((contentRow: any, index) => {
      let sheetJsonRow = [];

      if (index > 0) {
        var action = contentRow[actionIndex];
      }

      if (contentRow?.length > 0) {
        contentRow?.forEach((tripColumn, columnIndex) => {
          if (index == 0) {
            sheetJsonRow[columnIndex] = {
              v: tripColumn,
              t: "s",
              s: { fill: { fgColor: { rgb: color } }, font: { sz: 13, bold: true, color: { rgb: "FFFFFF" } } },
            };
          } else {
            if (action) {
              // && (activityIndex == columnIndex)

              var actionColor = "777777";
              var backgroundColor = "eeeeee";

              if (action == "Idling") {
                actionColor = "dba100";
                //backgroundColor = 'eeeeee';
              }

              if (action == "TripBegins") {
                actionColor = "058f00";
                //backgroundColor = '90EE90';
              }

              if (action == "StopBegins") {
                actionColor = "CC0000";
                //backgroundColor = 'FFA07A';
              }

              sheetJsonRow[columnIndex] = {
                v: tripColumn,
                t: "s",
                s: { fill: { fgColor: { rgb: backgroundColor } }, font: { color: { rgb: actionColor } } },
              };
            } else {
              sheetJsonRow[columnIndex] = tripColumn;
            }
          }
        });
      } else {
        returnSheet[index] = contentRow;
      }
      returnSheet.push(sheetJsonRow);
    });

    var timeStampIndex2 = returnSheet[0].map((x) => x.v).indexOf("Timestamp");
    if (timeStampIndex2 > -1) {
      for (var i = 0; i < returnSheet.length; i++) {
        returnSheet[i].splice(timeStampIndex2, 1);
      }
    }

    var actionIndex2 = returnSheet[0].map((x) => x.v).indexOf("Action");
    if (actionIndex2 > -1) {
      for (var i = 0; i < returnSheet.length; i++) {
        returnSheet[i].splice(actionIndex2, 1);
      }
    }

    return returnSheet;
  }

  // Export to XLSX
  downloadTwdXls(exportAsCsv = true) {
    const columns = this.template.columnConfiguration;

    const workbook = XLSX.utils.book_new();

    const requestData = this.generateHeaderData();

    requestData.unshift({ Name: this.translateService.instant("general.reportName"), Value: this.reportDisplayName });

    const theme = this.colorService.getThemeByUserType(this.activeReport.userType);
    const headerColor = theme.primary?.replace("#", "");
    const header2Color = theme.secondary?.replace("#", "");

    this.reportData?.data.forEach((asset, index) => {
      var worksheetList = [];

      asset.Data.sort((a, b) => a["BeginTS"]?.localeCompare(b["BeginTS"])).map((dat) => {
        let tripDuration = Moment.duration(dat.DurationInSeconds, "seconds").humanize();

        var data = [];
        data.push({ Name: "Start trip", Value: Moment.utc(dat.BeginTS)["tz"](this.timezoneIana).format("lll") });
        data.push({ Name: "End trip", Value: Moment.utc(dat.EndTS)["tz"](this.timezoneIana).format("lll") });
        data.push({ Name: "Duration", Value: tripDuration ?? "-" });
        data.push({
          Name: "Distance",
          Value:
            this.distance.calculateDistanceUnitFromKmFixed(dat.SegmentDistanceInKilometers, 2) +
            " " +
            this.translatedKm,
        });
        data.push({ Name: "Driver", Value: dat.DriverName ?? "-" });
        data.push({ Name: "Asset", Value: dat.AssetName ?? "-" });

        // Push trip
        const workSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, {
          skipHeader: false,
          dateNF: "yyyy-mm-dd HH-mm;@",
          cellDates: true,
        });

        var contents = [];

        // Push start
        var start = {};
        start["Timestamp"] = dat.BeginTS;
        start["Date"] = Moment.utc(dat.BeginTS)["tz"](this.timezoneIana).format("lll");
        start[this.translateService.instant("reportHeader.Activity")] = "Trip start";
        start[this.translateService.instant("reportHeader.Location")] =
          (dat.BeginAddress?.Value ?? "Unknown") + ", " + (dat.BeginCity?.Value ?? "Unknown") || "Unknown";
        start[this.translateService.instant("reportHeader.Duration")] = "-";
        start[this.translateService.instant("reportHeader.Distance")] = "-";
        start["Action"] = "TripBegins";

        contents.push(start);

        // Push end
        var end = {};
        end["Timestamp"] = dat.EndTS;
        end["Date"] = Moment.utc(dat.EndTS)["tz"](this.timezoneIana).format("lll");
        end[this.translateService.instant("reportHeader.Activity")] = "Trip ends";
        end[this.translateService.instant("reportHeader.Location")] =
          (dat.EndAddress?.Value ?? "Unknown") + ", " + (dat.EndCity?.Value ?? "Unknown") || "-";
        end[this.translateService.instant("reportHeader.Duration")] = tripDuration ?? "-";
        end[this.translateService.instant("reportHeader.Distance")] = dat.SegmentDistanceInKilometers
          ? this.distance.calculateDistanceUnitFromKmFixed(dat.SegmentDistanceInKilometers, 2) + " " + this.translatedKm
          : "-";
        end["Action"] = "StopBegins";

        contents.push(end);

        // Push episodes
        dat.Episodes.forEach((theEpisode) => {
          // Push episode
          var episode = {};

          let name = "";

          if (theEpisode.FkDeviceEpisodeTypeId == 0) {
            name = this.translateService.instant("general.trigger") + ": " + theEpisode.Description;
          } else {
            name =
              this.translateService.instant("general.violation") +
              ": " +
              this.translateService.instant("enums.deviceEpisode." + theEpisode.FkDeviceEpisodeTypeId);
          }

          const begin = Moment.utc(theEpisode.EpisodeStart)["tz"](this.timezoneIana);
          const end = theEpisode.EpisodeEnd
            ? Moment.utc(theEpisode.EpisodeEnd)["tz"](this.timezoneIana)
            : Moment.utc()["tz"](this.timezoneIana);
          const diff = end.diff(begin, "seconds");
          let duration = Moment.duration(diff, "seconds").humanize();

          episode["Timestamp"] = theEpisode.EpisodeStart;
          episode["Date"] = Moment.utc(theEpisode.EpisodeStart)["tz"](this.timezoneIana).format("lll");
          episode[this.translateService.instant("reportHeader.Activity")] = name ?? "-";
          episode[this.translateService.instant("reportHeader.Location")] = "-";
          episode[this.translateService.instant("reportHeader.Duration")] = duration ?? "-";

          if (theEpisode.FkDeviceEpisodeTypeId == 131) {
            episode["Action"] = "Idling";
          }

          contents.push(episode);
        });

        // Push GeofenceStates
        dat.GeofenceStates.forEach((geofenceState) => {
          // Push episode
          var geofence = {};

          const name = geofenceState.HasEntered
            ? this.translateService.instant("general.enteredGeofence")
            : this.translateService.instant("general.leftGeofence");

          geofence["Timestamp"] = geofenceState.StateChangeDateTime;
          geofence["Date"] = Moment.utc(geofenceState.StateChangeDateTime)["tz"](this.timezoneIana).format("lll");
          geofence[this.translateService.instant("reportHeader.Activity")] = name ?? "-";
          geofence[this.translateService.instant("reportHeader.Location")] = geofenceState.GeoFenceLabel ?? "-";
          geofence[this.translateService.instant("reportHeader.Duration")] = "-";
          contents.push(geofence);
        });

        contents.sort((a, b) => a["Timestamp"]?.localeCompare(b["Timestamp"]));

        const workSheet2: XLSX.WorkSheet = XLSX.utils.json_to_sheet(contents, {
          skipHeader: false,
          dateNF: "yyyy-mm-dd HH-mm;@",
          cellDates: true,
        });

        let tripSheet = XLSX.utils.sheet_to_json(workSheet, { header: 1 });
        let episodeSheet = XLSX.utils.sheet_to_json(workSheet2, { header: 1 });

        let tripSheetJson = this.xlsFormatHeader(tripSheet, headerColor);
        let episodeSheetJson = this.xlsFormatHeader(episodeSheet, header2Color);

        worksheetList = worksheetList.concat([""]).concat(tripSheetJson).concat([""]).concat(episodeSheetJson);
      });

      let worksheet = XLSX.utils.json_to_sheet(worksheetList, { skipHeader: true });

      worksheet["!cols"] = [
        { wch: 20 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
      ];

      const sheetName = asset.Name;
      XLSX.utils.book_append_sheet(workbook, worksheet, sheetName.replace(/[|&;$%@"<>()+,\/]/g, "").substring(0, 30));
    });

    const requestWorkSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(requestData, { skipHeader: true });
    XLSX.utils.book_append_sheet(workbook, requestWorkSheet, this.translateService.instant("lt.reporting.Request"));

    const filename = ("fm_report_" + this.selectedAccountName + "_" + this.selectedReportType)
      .replace(/\s/g, "")
      .replace(" ", "")
      .replace(".", "")
      .toLowerCase();

    if (exportAsCsv) {
      XLSX.writeFile(workbook, filename + ".csv", { bookType: "csv", compression: true });
    } else {
      XLSX.writeFile(workbook, filename + ".xlsx", { compression: true });
    }
  }

  generateHeaderData() {
    const start = Moment.utc(this.activeReport?.periodStart)["tz"](this.timezoneIana);
    const end = Moment.utc(this.activeReport?.periodEnd)["tz"](this.timezoneIana);
    const timestamp = Moment.utc(this.activeReport?.executionCompletedTimestamp)["tz"](this.timezoneIana);

    return [
      { Name: this.translateService.instant("general.account"), Value: this.activeReport.companyName },
      {
        Name: this.translateService.instant("general.selection"),
        Value: start.format("lll") + " - " + end.format("lll"),
      },
      { Name: this.translateService.instant("general.generatedBy"), Value: this.activeReport.userName },
      { Name: this.translateService.instant("general.generatedOn"), Value: timestamp.format("lll") },
      { Name: this.translateService.instant("dates.timezone"), Value: `${start.tz()} (${start.format("z Z")})` },
    ];
  }

  getIndexOfArray(arr, find: string[]) {
    const indexes = [];
    for (let i = 0; i < arr.length; i++) {
      if (find.indexOf(arr[i]) > -1) {
        indexes.push(i);
      }
    }

    return indexes;
  }

  generateChartBase64(options, key, theme, parentContainer = document.body, isTWD = false) {
    return new Promise((resolve, reject) => {
      const container = document.createElement("div");
      if (isTWD) {
        container.setAttribute("style", "position: absolute; height: 500px; width: 1600px; ");
      } else {
        container.setAttribute("style", "position: absolute; height: 800px; width: 1600px; ");
      }
      parentContainer.appendChild(container);

      const chartObject = options?.chart;
      chartObject["renderTo"] = container;

      if (options.plotOptions) {
        options.plotOptions.pie = {
          animation: false,
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: true,
          },
          showInLegend: true,
        };

        if (options.plotOptions.column) {
          options.plotOptions.column.animation = false;
        }
      }

      const legend = {
        enabled: true, // chartObject?.type === 'pie'
        itemStyle: {
          color: "#333333",
          cursor: "pointer",
          fontSize: "16px",
          fontWeight: "normal",
          textOverflow: "ellipsis",
        },
      };

      const fontSize = 16;

      const xAxis = options?.xAxis;
      if (xAxis?.labels && xAxis.labels.style) {
        xAxis.labels.style.fontSize = fontSize;
      } else {
        xAxis.labels = {
          style: {
            fontSize: fontSize + "px",
            color: "#333333",
          },
        };
      }

      let yAxis = options?.yAxis;

      const labels = {
        style: {
          fontSize: fontSize + "px",
          color: "#333333",
        },
      };

      if (yAxis.length > 0) {
        for (let i = 0; i < yAxis.length; i++) {
          if (yAxis[i]?.labels && yAxis[i].labels.style) {
            yAxis[i].labels.style.fontSize = fontSize;
          } else {
            yAxis[i] = { ...yAxis[i], labels };
          }
        }
      } else {
        yAxis = { ...yAxis, labels };
      }

      // options.colors = null;

      const _ = Highcharts.chart({ ...options, legend, xAxis, yAxis, chart: chartObject }, (chart) => {
        const activeSerie = chart.series.find((serie) => serie.visible);
        resolve([container.querySelector("svg"), activeSerie?.name]);
      });
    });
  }

  // Generate map for export
  generateMapBase64(trip, parentContainer = document.body) {
    return new Promise((resolve, reject) => {
      const container = document.createElement("div");
      container.setAttribute("style", "height: 300px; width: 900px; position: absolute;");
      container.setAttribute("class", "export_container");
      parentContainer.appendChild(container);

      var map = L.map(container).setView([-33.865, 151.2094], 10);

      // Set up the OSM layer
      L.tileLayer("https://api.maptiler.com/maps/streets/{z}/{x}/{y}.png?key=YLfOtVnqZuWr7kkDLbI4", {
        maxZoom: 18,
      }).addTo(map);

      const tripLayer = L.featureGroup();
      tripLayer.addTo(map);

      var messages = trip.Messages.map((data) => {
        return { latitude: data.Latitude, longitude: data.Longitude };
      });

      this.drawLocations(true, map, messages, tripLayer);

      resolve([map, container]);
    });
  }

  resolver(container = document.body) {
    return new Promise((resolve, reject) => {
      resolve(container);
    });
  }

  async renderCharts(chartContainer, theme) {
    const chartPromises = [];

    for (let i = 0; i < this.charts.length; i++) {
      const chartItem = this.charts[i];
      chartPromises.push(this.generateChartBase64(chartItem, "CHART_RENDER_" + i, theme, chartContainer));
    }

    return await Promise.all(chartPromises);
  }

  async downloadPdf(includeCharts = true, includeData = true, includeMaps = false) {
    // If TWD then do a custom pdf download
    if (this.activeReport?.ReportType == 2 || this.activeReport?.ReportType == 32) {
      return this.downloadTwdPdf(includeCharts, includeData, includeMaps);
    }

    let orientation: "p" | "portrait" | "l" | "landscape" = "l";
    if (this.template?.orientation?.toString() == "1") {
      orientation = "p";
    }

    const doc = new jsPDF(orientation, "px", "A4", true);
    const docWidth = doc.internal.pageSize.getWidth();
    const docHeight = doc.internal.pageSize.getHeight();

    const totalPagesExp = "{total_pages_count_string}";

    const theme = this.colorService.getThemeByUserType(this.activeReport.userType);
    const headerColor = theme.primary;
    const linkColor = theme.secondary;

    const fontSize = 8;

    // Add charts container
    const chartContainer = document.createElement("div");
    chartContainer.setAttribute("id", "reportChart");
    chartContainer.setAttribute("class", "export_container");
    chartContainer.setAttribute("style", "position: absolute; display:none; width: 800px; height: 400px");
    document.body.appendChild(chartContainer);

    // Add report reseller logo
    const reportImage = await new Promise<JQuery>((resolve, _) => {
      const image = $(
        '<img style="max-width: 300px; max-height: 135px;" src="data:image/png;base64,' + theme.reportBinary + '" />'
      ).appendTo("body");

      setTimeout(() => {
        resolve(image);
      }, 500);
    });

    const base64Img = { width: reportImage.innerWidth(), height: reportImage.innerHeight(), url: theme.reportBinary };

    const base64ImgSmall = { width: 22, height: 22, url: theme.reportBinarySmall };

    if (base64Img?.url) {
      doc.addImage(
        base64Img.url,
        "PNG",
        docWidth - 20 - base64Img.width * 0.5,
        25,
        base64Img.width * 0.5,
        base64Img.height * 0.5
      );
    }

    reportImage.remove();

    doc.setFont("IBMPlexSansArabic-Regular", "normal");

    doc.setTextColor(40);
    doc.setFontSize(28);

    doc.textWithLink(this.reportDisplayName, 15, 40, { url: window.location.href });

    autoTable(doc, {
      body: this.generateHeaderData(),
      startY: 63,
      theme: "plain",
      tableWidth: "wrap",
      rowPageBreak: "avoid",
      bodyStyles: {
        font: "IBMPlexSansArabic-Regular",
        textColor: 40,
        fontSize: 10,
        fontStyle: "normal",
        cellPadding: 2,
      },
      margin: {
        left: 15,
      },
      columnStyles: {
        Name: {
          cellWidth: 70,
        },
        Value: {
          cellWidth: 180,
        },
      },
    });

    // Set copyrights
    doc.setFontSize(8);
    doc.setTextColor(20);
    doc.setFont("IBMPlexSansArabic-Regular", "normal");

    const pageSize = doc.internal.pageSize;
    const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
    const copyrights = "© " + new Date().getFullYear() + " - " + theme.name;
    doc.text(copyrights, docWidth - 15, pageHeight - 15, { align: "right" });

    // Add Home kpi's
    const MAX_ROW_KPI = 4;

    const rowPadding = 5;
    const kpiWidth = (docWidth - 6) / 4;
    const kpiHeight = 50;

    const spacerHeight = rowPadding + kpiHeight;
    const centerWidth = (rowPadding + kpiWidth) / 2;

    const bottomPadding = math.ceil(this.reportData.kpiList.length / MAX_ROW_KPI) * kpiHeight;
    const textPadding = 17;
    const radius = 6;

    const iconWidth = 9;

    var position = docHeight;

    for (let i = 0; i < this.reportData.kpiList.length; i++) {
      doc.setDrawColor(240);
      doc.setFillColor(252, 252, 252);

      doc.roundedRect(
        (i % MAX_ROW_KPI) * kpiWidth + rowPadding,
        position - (bottomPadding + 35) + Math.floor(i / MAX_ROW_KPI) * spacerHeight,
        kpiWidth - rowPadding,
        kpiHeight,
        radius,
        radius,
        "FD"
      );

      // KPI value
      doc.setFont("IBMPlexSansArabic-Regular", "normal");

      doc.setTextColor(40);
      doc.setFontSize(13);
      doc.text(
        (this.reportData.kpiList[i].value.toLocaleString() + " " + this.reportData.kpiList[i].uom).replace(/\s+$/g, ""),
        iconWidth + ((i % MAX_ROW_KPI) * kpiWidth + centerWidth),
        position - (bottomPadding + textPadding) + Math.floor(i / MAX_ROW_KPI) * spacerHeight,
        null,
        "center"
      );

      const textWidth =
        doc.getTextWidth(
          (this.reportData.kpiList[i].value.toLocaleString() + " " + this.reportData.kpiList[i].uom).replace(
            /\s+$/g,
            ""
          )
        ) *
          0.45 +
        22;

      // Icon
      doc.setFont("fa-solid-900", "normal");

      doc.setTextColor(80);
      doc.setFontSize(15);
      doc.text(
        this.reportData.kpiList[i].icon,
        iconWidth + ((i % MAX_ROW_KPI) * kpiWidth + centerWidth - textWidth),
        position - bottomPadding + Math.floor(i / MAX_ROW_KPI) * spacerHeight - 16
      );

      // KPI text
      doc.setFont("IBMPlexSansArabic-ExtraLight", "normal");

      doc.setTextColor(100);
      doc.setFontSize(12);
      // doc.text(this.reportData.kpiList[i].name.split(' ').join('\n'), ((i % MAX_ROW_KPI) * kpiWidth) + centerWidth, (position - bottomPadding) + (Math.floor(i / MAX_ROW_KPI) * spacerHeight), null, 'center');
      doc.text(
        this.reportData.kpiList[i].name,
        (i % MAX_ROW_KPI) * kpiWidth + centerWidth,
        position - bottomPadding + Math.floor(i / MAX_ROW_KPI) * spacerHeight,
        null,
        "center"
      );
    }

    // Generate and add base charts
    if (includeCharts && this.charts.length > 0) {
      const charts = await this.renderCharts(chartContainer, theme);

      const chartWidth = docWidth - 40;
      const chartHeight = chartWidth / 2;

      doc.setTextColor(30);

      for (let i = 0; i < charts.length; i++) {
        doc.addPage();

        // Add title
        if (this.reportData?.charts[i]?.icon) {
          doc.setFont("fa-solid-900", "normal");

          doc.setFontSize(20);
          doc.text(this.reportData?.charts[i].icon, 15, 28);
        }

        if (this.reportData?.charts[i]?.name) {
          doc.setFont("IBMPlexSansArabic-Regular", "normal");

          doc.setFontSize(16);
          doc.text(this.reportData?.charts[i].name, 40, 26);
        }

        await doc.svg(charts[i][0], { x: 20, y: 55, width: chartWidth, height: chartHeight });

        let str = this.translateService.instant("general.pager.page") + " " + doc.internal["getNumberOfPages"]();
        if (typeof doc.putTotalPages === "function") {
          str = str + " " + this.translateService.instant("general.pager.of") + " " + totalPagesExp;
        }

        doc.setFontSize(8);
        doc.setTextColor(30);
        doc.setFont("IBMPlexSansArabic-Regular", "normal");

        const pageSize = doc.internal.pageSize;
        const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
        doc.text(str, 15, pageHeight - 15);

        doc.text(copyrights, docWidth - 15, pageHeight - 15, { align: "right" });
      }
    }

    doc.setFontSize(10);
    doc.setTextColor(30);

    let chartCollection = [];

    // Prefetch all page charts upfront
    if (includeCharts) {
      const chartsPromises = [];

      for (const rowData of this.reportDataFlat.data) {
        if (rowData.Charts?.length > 0) {
          const data = this.generateChart(rowData.Charts[0]);
          chartsPromises.push(this.generateChartBase64(data, "CHART_RENDER_" + rowData.Name, theme, chartContainer));
        }
      }

      chartCollection = await Promise.all(chartsPromises);
    }

    // Foreach over data
    for (let i = 0; i < this.reportDataFlat.data.length; i++) {
      const rowData = this.reportDataFlat.data[i];

      // Print charts - Please note this is very slow
      if (includeCharts && rowData.Charts && chartCollection[i][1]) {
        // New page
        doc.addPage();

        doc.setFont("fa-solid-900", "normal");
        doc.setFontSize(24);

        doc.text("\uf2db", 15, 32);

        doc.setFont("IBMPlexSansArabic-Regular", "normal");
        doc.setFontSize(16);

        doc.textWithLink(rowData.Name + " [" + chartCollection[i][1] + "]", 40, 30, {
          url:
            location.protocol +
            "//" +
            location.host +
            (rowData?.DriverId
              ? "#/DriverDetails/Index/" + rowData?.DriverId
              : "#/DeviceDetails/Index/" + rowData?.DeviceId),
        });

        const chartWidth = docWidth - 20;
        const chartHeight = chartWidth / 2;

        try {
          await doc.svg(chartCollection[i][0], { x: 10, y: 55, width: chartWidth, height: chartHeight });
        } catch (error) {
          console.log(error);
        }
      }

      // Print data
      if (includeData) {
        // New page
        doc.addPage();

        const start = Moment.utc(this.activeReport?.periodStart)["tz"](this.timezoneIana);
        const end = Moment.utc(this.activeReport?.periodEnd)["tz"](this.timezoneIana);
        const timestamp = Moment.utc(this.activeReport?.executionCompletedTimestamp)["tz"](this.timezoneIana);

        // Add report title
        doc.setFontSize(8);
        doc.setTextColor(40);
        doc.setFont("IBMPlexSansArabic-Regular", "normal");

        const docWidth = doc.internal.pageSize.getWidth();

        const text = this.translateService.instant("general.reportName") + ": " + String(this.reportDisplayName);
        const text2 = this.translateService.instant("general.account") + ": " + String(this.activeReport.companyName);
        const text3 =
          this.translateService.instant("general.selection") + ": " + start.format("lll") + " - " + end.format("lll");

        doc.text(text, docWidth - 40, 14, { align: "right" });
        doc.text(text2, docWidth - 40, 23, { align: "right" });
        doc.text(text3, docWidth - 40, 32, { align: "right" });

        if (base64ImgSmall?.url) {
          doc.addImage(base64ImgSmall.url, "PNG", docWidth - 34, 10, base64ImgSmall.width, base64ImgSmall.height);
        }

        // Add header
        doc.setFont("fa-solid-900", "normal");
        doc.setFontSize(24);
        doc.setTextColor(40, 40, 40);

        let iconUnicode = "\uf2db";

        if (rowData.Icon !== undefined) {
          iconUnicode = rowData.Icon;
        }

        doc.text(iconUnicode, 15, 28);

        doc.setFont("IBMPlexSansArabic-Regular", "normal");
        doc.setFontSize(16);
        doc.setTextColor(40, 40, 40);

        let externalLink: string;

        if (rowData.DeviceId > -1) {
          externalLink = "#/DeviceDetails/Index/" + rowData.DeviceId;
        } else if (rowData.AssetId > -1) {
          externalLink = "#/AssetDetails/Index/" + rowData.AssetId;
        } else if (rowData.DriverId > -1) {
          externalLink = "#/DriverDetails/Index/" + rowData.DriverId;
        } else if (rowData.GeofenceId > -1) {
          externalLink = "#/GeofenceDetails/Index/" + rowData.GeofenceId;
        } else if (rowData.ProjectId > -1) {
          externalLink = "#/ProjectDetails/Index/" + rowData.ProjectId;
        }

        if (externalLink) {
          doc.textWithLink(String(rowData.Name), 40, 26, {
            url: location.protocol + "//" + location.host + externalLink,
          });
        } else {
          doc.text(String(rowData.Name), 40, 26);
        }

        // Filter unwanted columns in export
        const columns = this.template.columnConfiguration;

        // Remove useless columns
        const reportHead = [];
        const reportStyles = {};

        for (let j = 0; j < columns.length; j++) {
          reportStyles[j] = {
            cellWidth: columns[j].width > 0 ? columns[j].width / 2 : "auto",
            halign: columns[j].alignment === 3 ? "right" : columns[j].alignment === 2 ? "center" : "left",
          };

          if (!(this.template.groupByIndex == j && this.template.hideGroupByColumn)) {
            reportHead.push(columns[j].name);
          }
        }

        const linkArray = [];
        const reportData = [];

        rowData.Data?.filter((x) => x !== undefined).forEach((item) => {
          const items = [];
          const linkItems = [];

          Object.keys(item).forEach((key, index) => {
            if (key === "headerName") {
              items.push(item[key]);
            } else if (
              key !== "isSummary" &&
              key !== "isHeader" &&
              key !== "isHighlighted" &&
              !(this.template.groupByIndex == index && this.template.hideGroupByColumn)
            ) {
              if (typeof item[key] === "object") {
                if (item[key]?.Link || item[key]?.Link == "") {
                  linkItems[key] = item[key]?.Link;
                }

                if (item[key]?.Value !== null) {
                  if (item[key]?.Value?.length === 0) {
                    item[key].Value = this.translateService.instant("lt.reporting.AddressUnknown");
                  }
                }
                if (item[key]?.Value || item[key]?.Value) {
                  item[key] = item[key].Value;
                }

                let checkedItem = this.checkFormat(key, item, "PDF", columns[index]);

                items.push(checkedItem);
              } else {
                let checkedItem = this.checkFormat(key, item, "PDF", columns[index]);

                items.push(checkedItem);
              }
            }
          });

          reportData.push(items);
          linkArray.push(linkItems);
        });

        // Draw table
        autoTable(doc, {
          head: [reportHead],
          body: reportData,
          startY: 39,
          headStyles: {
            font: "IBMPlexSansArabic-Bold",
            fillColor: headerColor,
            textColor: 255,
            fontSize: fontSize,
            fontStyle: "bold",
          },
          pageBreak: "auto",
          rowPageBreak: "avoid",
          didParseCell: (hookData) => {
            if (hookData.section === "head") {
              hookData.cell.styles.halign = reportStyles[hookData.column.dataKey].halign ?? "left";
            } else if (hookData.section === "body") {
              if (hookData.row.raw["length"] === 1) {
                hookData.row.cells[0].colSpan = Object.keys(hookData.row.cells).length;
              }
            }
          },
          willDrawCell: (data) => {
            if (data.section === "body") {
              // Check the source data for special rendering
              if (rowData?.Data[data.row.index]?.isHeader) {
                // Draw header

                doc.setDrawColor(255, 255, 255);
                doc.setFont("IBMPlexSansArabic-Regular", "normal");
                doc.setLineWidth(2);
                doc.line(data.cell.x, data.cell.y, data.cell.x + data.cell.width, data.cell.y);

                doc.setFillColor(225, 225, 225);
                doc.setFont("IBMPlexSansArabic-Regular", "normal");
                doc.setTextColor(20, 20, 20);
              } else if (rowData?.Data[data.row.index]?.isSummary) {
                // Draw footer
                doc.setDrawColor(255, 255, 255);
                doc.setLineWidth(1);
                doc.line(data.cell.x, data.cell.y, data.cell.x + data.cell.width, data.cell.y);

                doc.setFillColor(237, 237, 237);
                doc.setFont("IBMPlexSansArabic-Regular", "normal");
                doc.setTextColor(20, 20, 20);
              } else if (rowData?.Data[data.row.index]?.isHighlighted) {
                // Draw calibration
                doc.setFillColor(255, 248, 225);
              } else {
                // Normal row
                doc.setTextColor(0, 0, 0);

                var link = linkArray[data.row.index][data.column.index];

                if (link) {
                  // doc.setTextColor(linkColor)
                  doc.link(data.cell.x, data.cell.y, data.cell.contentWidth, data.cell.contentHeight, { url: link });
                }
              }
            }
          },
          didDrawPage: (data) => {
            // Footer

            let str = this.translateService.instant("general.pager.page") + " " + doc.internal["getNumberOfPages"]();
            if (typeof doc.putTotalPages === "function") {
              str = str + " " + this.translateService.instant("general.pager.of") + " " + totalPagesExp;
            }

            doc.setFont("IBMPlexSansArabic-Regular", "normal");
            doc.setFontSize(8);
            doc.setTextColor(30);

            const pageSize = doc.internal.pageSize;
            const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
            doc.text(str, data.settings.margin.left + 3, pageHeight - 15);

            doc.text(copyrights, docWidth - 15, pageHeight - 15, { align: "right" });
          },
          margin: { top: 15, left: 12, right: 12, bottom: 25 },
          styles: {
            fontSize: fontSize,
            textColor: 30,
            cellPadding: 3,
            font: "IBMPlexSansArabic-ExtraLight",
            overflow: "ellipsize", // linebreak
          },
          columnStyles: reportStyles,
          tableWidth: "auto",
        });
      }
    }

    // Total page number plugin only available in jspdf v1.0+
    if (typeof doc.putTotalPages === "function") {
      doc.putTotalPages(totalPagesExp);
    }

    const filename = ("fm_report_" + this.selectedAccountName + "_" + this.selectedReportType)
      .replace(/\s/g, "")
      .replace(" ", "")
      .replace(".", "")
      .toLowerCase();

    // Cleanup
    $("#reportChart").remove();
    $(".export_container").remove();

    doc.save(filename + ".pdf", { returnPromise: true }).then(() => {
      console.log("returning true");
      return true;
    });
  }

  createClickableLink = (text, href) => {
    var link = `<a href="${href}" target="_blank" class="secondary link_bolder">${text}</a>`;
    return link;
  };

  createMapsLink = (lat, lon) => {
    var link = `http://maps.google.com/?q=Location@${lat},${lon}`;
    return link;
  };

  testUnicode(string) {
    return /[^\u0000-\u00ff]/.test(string);
  }

  /////////////////////////////////////////
  // TRIPS WITH DETAILS
  /////////////////////////////////////////
  generateTwdMap(trip, store = false, delay = true) {
    // reset locationdata
    var messages = trip?.Messages?.map((data) => {
      return {
        latitude: data.Latitude,
        longitude: data.Longitude,
      };
    });

    if (messages.length > 0) {
      if (delay) {
        setTimeout(() => {
          this.drawLocations(true, this.leafletMapComponent?.map, messages, this.leafletMapComponent?.tripLayer);

          if (this.showAbuseIcons) {
            console.log("display episodes");
            this.addEpisodesToMap(trip, this.leafletMapComponent?.tripLayer);
          }
        }, 100);
      } else {
        this.drawLocations(true, this.leafletMapComponent?.map, messages, this.leafletMapComponent?.tripLayer);

        if (this.showAbuseIcons) {
          console.log("display episodes");
          this.addEpisodesToMap(trip, this.leafletMapComponent?.tripLayer);
        }
      }

      // Draw the map
      return true;
    } else {
      // Dont draw the map
      return false;
    }
  }

  resetSubPageTripsWithDetails(trip, page) {
    // Reset subpage
    if (trip.Id !== this.displayedTripId) {
      this.generateTwdMap(trip, true);
      this.generateTwdChart(trip, true);
    }

    this.cd.markForCheck();

    this.displayedTripId = trip.Id;

    return trip;
  }

  displayLocation(episode) {}

  prepareTripData(trip) {
    trip.Details = [];

    trip.Start = {
      timestamp: Moment.utc(trip.BeginTS)["tz"](this.timezoneIana),
      classNameIcon: "fa-play",
      name: this.translateService.instant("general.startTrip"),
      location: { Value: trip.BeginAddressFull },
    };

    trip.End = {
      timestamp: Moment.utc(trip.EndTS)["tz"](this.timezoneIana),
      classNameIcon: "fa-flag-checkered",
      name: this.translateService.instant("general.endTrip"),
      location: { Value: trip.EndAddressFull },
    };

    trip.DurationInSeconds = trip.End?.timestamp.diff(trip.Start.timestamp, "seconds");
    trip.Duration = Moment.duration(trip.DurationInSeconds, "seconds");
    trip.DurationHumanized = trip.Duration.humanize();

    trip.Episodes.forEach((episode) => {
      let classNameIcon = "fa-alert";
      let classNameRow = "";
      // Idling
      if (episode.FkDeviceEpisodeTypeId == 131) {
        classNameIcon = "fa-snooze";
        classNameRow = "idling";
      }
      const begin = Moment.utc(episode.EpisodeStart)["tz"](this.timezoneIana);
      const end = episode.EpisodeEnd
        ? Moment.utc(episode.EpisodeEnd)["tz"](this.timezoneIana)
        : Moment.utc()["tz"](this.timezoneIana);
      const diff = end.diff(begin, "seconds");
      let status = Moment.duration(diff, "seconds").humanize();
      const beginLocation =
        episode.BeginLocation != null && episode.BeginLocation.length > 0
          ? episode.BeginLocation
          : roundAsNumber(episode.BeginLatitude, 4) + " - " + roundAsNumber(episode.BeginLongitude, 4);

      let name = "";
      if (episode.FkDeviceEpisodeTypeId == 0) {
        name = this.translateService.instant("general.trigger") + ": " + episode.Description;
      } else {
        name =
          this.translateService.instant("general.violation") +
          ": " +
          this.translateService.instant("enums.deviceEpisode." + episode.FkDeviceEpisodeTypeId);
      }

      const episodeFormatted = {
        beginLatitude: episode.BeginLatitude,
        beginLocation: beginLocation,
        beginLongitude: episode.BeginLongitude,
        description: episode.Description,
        episodeEnd: episode.EpisodeEnd,
        episodeStart: episode.EpisodeStart,
        fkDeviceEpisodeTypeId: episode.FkDeviceEpisodeTypeId,
        fkDeviceId: episode.FkDeviceId,
        triggerId: episode.FkTriggerId,
        id: episode.Id,
        tripId: episode.TripId,
      };

      const episode2 = parseEpisode(episodeFormatted, this.translateService, Moment, this.timezoneIana, this.distance);

      if (episode2) {
        classNameIcon = episode2.icon;
      }

      var address = { Value: beginLocation, Link: this.createMapsLink(episode.BeginLatitude, episode.BeginLongitude) };

      trip.Details.push({
        classNameRow: classNameRow,
        location: address,
        classNameIcon: classNameIcon,
        timestamp: Moment.utc(episode.EpisodeStart)["tz"](this.timezoneIana),
        name: name,
        status: status,
      });
    });

    trip.GeofenceStates.forEach((geofenceState) => {
      var address = { Value: geofenceState.GeoFenceLabel };

      trip.Details.push({
        timestamp: Moment.utc(geofenceState.StateChangeDateTime)["tz"](this.timezoneIana),
        classNameIcon: "fa-draw-polygon",
        name: geofenceState.HasEntered
          ? this.translateService.instant("general.enteredGeofence")
          : this.translateService.instant("general.leftGeofence"),
        location: address,
      });
    });
  }

  // Generate chart locationcount
  generateTwdChart(trip, store = false, delay = true) {
    const iconPath = getIconPath(trip.IconId)[1];
    const theIcon = L.icon({
      iconUrl: iconPath,
      // className: 'markerPlayTrip',
      iconAnchor: [16, 16],
    });

    let theChart;

    if (store) {
      this.renderedChart = null;
    }

    let theData = this.drawChart(trip.Messages);

    if (delay) {
      setTimeout(() => {
        theChart = this.chartService.generateMapChart(theData, [], [], this.leafletMapComponent?.map, theIcon);

        if (store) {
          this.renderedChart = theChart;
        }

        this.cd.markForCheck();

        return true;
      }, 10);
    } else {
      theChart = this.chartService.generateMapChart(theData, [], [], this.leafletMapComponent?.map, theIcon);

      if (store) {
        this.renderedChart = theChart;
      }

      return theChart;
    }

    return true;
  }

  drawChart(locations) {
    // The speed gauge
    // data, km, fuel percentage, deviation, symbol

    const theChartDataDistance = [];
    const theChartDataIgnition = [];
    const theChartDataSpeed = [];
    const theChartDataExternalPower = [];
    const theChartDataGpsFix = [];
    const theChartFuelLevel = [];
    const theChartDataTemperature = [];
    const theChartDataTemperature2 = [];
    const theChartDataWeight = [];
    const theChartDataAvgWeight = [];
    const theChartDataWeight2 = [];
    const theChartDataAvgWeight2 = [];
    const theChartDataHumidity = [];
    const theChartDataExternalPowerVoltage = [];

    const theChartDataAcceleration = [];
    const theChartDataBraking = [];
    const theChartDataCornering = [];

    const theChartDataRpm = [];

    const theChartDataAnalog1 = [];
    const theChartDataAnalog2 = [];

    const theChartDataIO = [];

    const theChartFuelConsumed = [];

    const that = this;

    const cachedDistanceOffset = 0;
    let lastlocation = null;

    if (locations.length === 0) {
      return;
    }

    $.each(
      locations.sort((a, b) => (a.Timestamp < b.Timestamp ? -1 : 1)),
      function (index, value) {
        const distance = value.OdometerValueInMetres - cachedDistanceOffset;

        // Set lastlocation + 1 milisecond to stop distancechart from making a big leap
        if (lastlocation !== null && distance === 0 && lastlocation.y !== 0) {
          lastlocation.x = lastlocation.x + 1;
          lastlocation.y = 0;
          theChartDataDistance.push(lastlocation);
        }

        const dateTime = Moment.utc(value.Timestamp)["tz"](that.timezoneIana).unix() * 1000;
        const coordinate = [value.Latitude, value.Longitude];

        if (value.Latitude !== 0) {
          const location = { x: dateTime, y: Math.max(0, distance / 1000), suffix: "km", latlon: coordinate };
          lastlocation = location;
          theChartDataDistance.push(location);
        }

        theChartDataIgnition.push({ x: dateTime, y: value.IgnitionSignal ? 1 : 0, latlon: coordinate });
        theChartDataExternalPower.push({ x: dateTime, y: value.ExternalPowerSignal ? 1 : 0, latlon: coordinate });
        theChartDataGpsFix.push({ x: dateTime, y: value.HasGpsFix ? 1 : 0, latlon: coordinate });

        if (value.SpeedInKph !== undefined || this.filterZeroValues) {
          theChartDataSpeed.push({ x: dateTime, y: value.SpeedInKph, suffix: "km/h", latlon: coordinate });
        }

        if (value.ExternalPowerVoltage !== undefined || this.filterZeroValues) {
          theChartDataExternalPowerVoltage.push({
            x: dateTime,
            y: value.ExternalPowerVoltage,
            suffix: " V",
            latlon: coordinate,
          });
        }

        if (value.FuelLevel !== undefined && (value.FuelLevel !== 0 || this.filterZeroValues)) {
          theChartFuelLevel.push({ x: dateTime, y: value.FuelLevel, suffix: "%", latlon: coordinate });
        }

        if (value.Weight1 !== undefined && (value.Weight1 !== 0 || this.filterZeroValues)) {
          theChartDataWeight2.push({ x: dateTime, y: value.Weight1, suffix: " kg", latlon: coordinate });
        }

        if (value.Weight2 !== undefined && (value.Weight2 !== 0 || this.filterZeroValues)) {
          theChartDataWeight2.push({ x: dateTime, y: value.Weight2, suffix: " kg", latlon: coordinate });
        }

        if (value.Humidity1 !== undefined && (value.Humidity1 !== 0 || this.filterZeroValues)) {
          theChartDataHumidity.push({ x: dateTime, y: value.Humidity1, suffix: "%", latlon: coordinate });
        }

        if (value.Temperature1 !== undefined && (value.Temperature1 !== 0 || this.filterZeroValues)) {
          theChartDataTemperature.push({ x: dateTime, y: value.Temperature1, suffix: "°C", latlon: coordinate });
        }

        if (value.Temperature2 !== undefined && (value.Temperature2 !== 0 || this.filterZeroValues)) {
          theChartDataTemperature2.push({ x: dateTime, y: value.Temperature2, suffix: "°C", latlon: coordinate });
        }

        if (value.Rpm !== undefined && (value.Rpm !== 0 || this.filterZeroValues)) {
          theChartDataRpm.push({ x: dateTime, y: value.Rpm, suffix: " rpm", latlon: coordinate });
        }

        theChartDataIO.push({ x: dateTime, y: { i1: value.input1, i2: value.input2 }, latlon: coordinate });
      }
    );

    let theData = [];

    theData = [
      {
        name: "Distance",
        type: "area",
        dashStyle: "dash",
        fillOpacity: 0.1,
        opacity: 0.3,
        color: "#ccc",
        zIndex: 5,
        yAxis: 1,
        data: theChartDataDistance,
      },
      {
        name: "Speed",
        type: "spline",
        color: "#5AB867",
        visible: true,
        yAxis: 0,
        marker: {
          enabled: false,
          lineWidth: 2,
          symbol: "square",
        },
        zIndex: 3,
        data: theChartDataSpeed,
      },
    ];

    if (theChartDataIgnition.some((x) => x.y)) {
      theData.push({
        name: "Ignition",
        type: "line",
        color: "#00E0C6",
        visible: false,
        step: "left",
        marker: {
          enabled: false,
          lineWidth: 2,
          symbol: "square",
        },
        yAxis: 3,
        zIndex: 3,
        data: theChartDataIgnition,
      });
    }

    if (theChartDataExternalPower.some((x) => x.y)) {
      theData.push({
        name: "ExternalPower",
        type: "line",
        color: "#00E0C6",
        visible: false,
        step: "left",
        marker: {
          enabled: false,
          lineWidth: 2,
          symbol: "square",
        },
        yAxis: 4,
        zIndex: 3,
        data: theChartDataExternalPower,
      });
    }

    if (theChartDataExternalPowerVoltage.some((x) => x.y)) {
      theData.push({
        name: "Voltage",
        type: "spline",
        dashStyle: "ShortDashDot",
        color: "#00E0C6",
        visible: false,
        step: "left",
        marker: {
          enabled: false,
          lineWidth: 2,
          symbol: "square",
        },
        yAxis: 6,
        zIndex: 3,
        data: theChartDataExternalPowerVoltage,
      });
    }

    if (theChartDataRpm.some((x) => x.y)) {
      theData.push({
        name: "RPM",
        type: "spline",
        dashStyle: "ShortDashDot",
        color: "#ff0000",
        visible: false,
        step: "left",
        marker: {
          enabled: false,
          lineWidth: 2,
          symbol: "square",
        },
        yAxis: 13,
        zIndex: 3,
        data: theChartDataRpm,
      });
    }

    if (theChartDataAnalog1.some((x) => x.y)) {
      theData.push({
        name: "Analog1",
        type: "spline",
        dashStyle: "ShortDashDot",
        color: "#00E0C6",
        visible: false,
        step: "left",
        marker: {
          enabled: false,
          lineWidth: 2,
          symbol: "square",
        },
        yAxis: 6,
        zIndex: 3,
        data: theChartDataAnalog1,
      });
    }

    if (theChartDataAnalog2.some((x) => x.y)) {
      theData.push({
        name: "Analog2",
        type: "spline",
        dashStyle: "ShortDashDot",
        color: "#00E0C6",
        visible: false,
        step: "left",
        marker: {
          enabled: false,
          lineWidth: 2,
          symbol: "square",
        },
        yAxis: 6,
        zIndex: 3,
        data: theChartDataAnalog2,
      });
    }

    if (theChartDataGpsFix.some((x) => x.y)) {
      theData.push({
        name: "HasGpsFix",
        type: "line",
        color: "#1A4467",
        visible: false,
        step: "left",
        marker: {
          enabled: false,
          lineWidth: 2,
          symbol: "square",
        },
        yAxis: 5,
        zIndex: 3,
        data: theChartDataGpsFix,
      });
    }

    if (theChartFuelLevel.some((x) => x.y)) {
      theData.push({
        name: "FuelLevel",
        type: "spline",
        color: "#7589FF",
        dashStyle: "ShortDot",
        fillOpacity: 0.2,
        visible: true,
        step: "left",
        marker: {
          enabled: false,
          lineWidth: 2,
          symbol: "square",
        },
        yAxis: 2,
        zIndex: 3,
        data: theChartFuelLevel,
      });
    }

    if (theChartFuelConsumed.some((x) => x.y)) {
      theData.push({
        name: "Fuel Consumed",
        type: "spline",
        color: "#A982FF",
        dashStyle: "LongDash",
        fillOpacity: 0.2,
        visible: true,
        step: "left",
        marker: {
          enabled: false,
          lineWidth: 2,
          symbol: "square",
        },
        yAxis: 12,
        zIndex: 3,
        data: theChartFuelConsumed,
      });
    }

    if (theChartDataWeight.some((x) => x.y)) {
      theData.push({
        name: "Weight",
        type: "spline",
        color: "#FF0015",
        dashStyle: "Dash",
        fillOpacity: 0.2,
        visible: true,
        step: "left",
        marker: {
          enabled: false,
          lineWidth: 2,
          symbol: "square",
        },
        yAxis: 9,
        zIndex: 3,
        data: theChartDataWeight,
      });
    }

    if (theChartDataAvgWeight.some((x) => x.y)) {
      theData.push({
        name: "AvgWeight",
        type: "spline",
        color: "#FF6666",
        dashStyle: "Dash",
        fillOpacity: 0.2,
        visible: true,
        step: "left",
        marker: {
          enabled: false,
          lineWidth: 2,
          symbol: "square",
        },
        yAxis: 9,
        zIndex: 3,
        data: theChartDataAvgWeight,
      });
    }

    if (theChartDataAvgWeight2.some((x) => x.y)) {
      theData.push({
        name: "AvgWeight2",
        type: "spline",
        color: "#FF6666",
        dashStyle: "Dash",
        fillOpacity: 0.2,
        visible: true,
        step: "left",
        marker: {
          enabled: false,
          lineWidth: 2,
          symbol: "square",
        },
        yAxis: 9,
        zIndex: 3,
        data: theChartDataAvgWeight2,
      });
    }

    if (theChartDataWeight2.some((x) => x.y)) {
      theData.push({
        name: "Weight",
        type: "spline",
        color: "#FFFF15",
        dashStyle: "Dash",
        fillOpacity: 0.2,
        visible: true,
        step: "left",
        marker: {
          enabled: false,
          lineWidth: 2,
          symbol: "square",
        },
        yAxis: 9,
        zIndex: 3,
        data: theChartDataWeight2,
      });
    }

    if (theChartDataHumidity.some((x) => x.y)) {
      theData.push({
        name: "Humidity",
        type: "spline",
        color: "#FF0090",
        fillOpacity: 0.2,
        visible: true,
        step: "left",
        marker: {
          enabled: false,
          lineWidth: 2,
          symbol: "square",
        },
        yAxis: 8,
        zIndex: 3,
        data: theChartDataHumidity,
      });
    }

    if (theChartDataTemperature.some((x) => x.y)) {
      theData.push({
        name: "Temperature",
        type: "spline",
        color: "#FF0015",
        fillOpacity: 0.2,
        visible: true,
        step: "left",
        marker: {
          enabled: false,
          lineWidth: 2,
          symbol: "square",
        },
        yAxis: 7,
        zIndex: 3,
        data: theChartDataTemperature,
      });
    }

    if (theChartDataTemperature2.some((x) => x.y)) {
      theData.push({
        name: "Temperature2",
        type: "spline",
        color: "#8700FF",
        fillOpacity: 0.2,
        visible: true,
        step: "left",
        marker: {
          enabled: false,
          lineWidth: 2,
          symbol: "square",
        },
        yAxis: 7,
        zIndex: 3,
        data: theChartDataTemperature2,
      });
    }

    if (theChartDataAcceleration.some((x) => x.y)) {
      theData.push({
        name: "Accelleration",
        type: "spline",
        color: "#ffa600",
        fillOpacity: 0.2,
        visible: false,
        step: "left",
        marker: {
          enabled: false,
          lineWidth: 2,
          symbol: "square",
        },
        yAxis: 10,
        zIndex: 4,
        data: theChartDataAcceleration,
      });
    }

    if (theChartDataBraking.some((x) => x.y)) {
      theData.push({
        name: "Braking",
        type: "spline",
        color: "#ff6361",
        fillOpacity: 0.2,
        visible: false,
        step: "left",
        marker: {
          enabled: false,
          lineWidth: 2,
          symbol: "square",
        },
        yAxis: 10,
        zIndex: 4,
        data: theChartDataBraking,
      });
    }

    if (theChartDataCornering.some((x) => x.y)) {
      theData.push({
        name: "Cornering",
        type: "spline",
        color: "#bc5090",
        fillOpacity: 0.2,
        visible: false,
        step: "left",
        marker: {
          enabled: false,
          lineWidth: 2,
          symbol: "square",
        },
        yAxis: 10,
        zIndex: 4,
        data: theChartDataCornering,
      });
    }

    const plotLines = [];
    const plotBands = [];

    return theData;
  }

  async downloadTwdPdf(includeCharts = true, includeData = true, includeMaps = false) {
    const doc = new jsPDF("p", "px", "A4", true);
    const docWidth = doc.internal.pageSize.getWidth();
    const docHeight = doc.internal.pageSize.getHeight();

    const totalPagesExp = "{total_pages_count_string}";

    const theme = this.colorService.getThemeByUserType(this.activeReport.userType);
    const headerColor = theme.primary;
    const linkColor = theme.secondary;

    const fontSize = 8;

    // Set copyrights
    doc.setFontSize(8);
    doc.setTextColor(20);
    doc.setFont("IBMPlexSansArabic-Regular", "normal");

    const pageSize = doc.internal.pageSize;
    const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
    const copyrights = "© " + new Date().getFullYear() + " - " + theme.name;
    doc.text(copyrights, docWidth - 15, pageHeight - 15, { align: "right" });

    // Add charts container
    const chartContainer = document.createElement("div");
    chartContainer.setAttribute("id", "reportChart");
    chartContainer.setAttribute("class", "export_container");
    chartContainer.setAttribute("style", "height: 400px; width: 1200px; position: absolute; visibility: hidden;");
    document.body.appendChild(chartContainer);

    const mapContainer = document.createElement("div");
    mapContainer.setAttribute("id", "reportMap");
    mapContainer.setAttribute("class", "export_container");
    mapContainer.setAttribute(
      "style",
      "height: 400px; width: 1200px; position: absolute; visibility: normal; right: -1300px"
    );
    document.body.appendChild(mapContainer);

    const reportImage = await new Promise<JQuery>((resolve, _) => {
      const image = $(
        '<img style="max-width: 300px; max-height: 135px;" src="data:image/png;base64,' + theme.reportBinary + '" />'
      ).appendTo("body");

      setTimeout(() => {
        resolve(image);
      }, 500);
    });

    const base64Img = { width: reportImage.innerWidth(), height: reportImage.innerHeight(), url: theme.reportBinary };

    const base64ImgSmall = { width: 22, height: 22, url: theme.reportBinarySmall };

    if (base64Img?.url) {
      doc.addImage(
        base64Img.url,
        "PNG",
        docWidth - 20 - base64Img.width * 0.5,
        25,
        base64Img.width * 0.5,
        base64Img.height * 0.5
      );
    }

    reportImage.remove();

    doc.setFont("IBMPlexSansArabic-Regular", "normal");

    doc.setTextColor(40);
    doc.setFontSize(28);

    doc.textWithLink(this.activeReport.ReportName ? this.activeReport.ReportName : this.selectedReportType, 15, 40, {
      url: window.location.href,
    });

    autoTable(doc, {
      body: this.generateHeaderData(),
      startY: 50,
      theme: "plain",
      tableWidth: "wrap",
      rowPageBreak: "avoid",
      bodyStyles: {
        font: "IBMPlexSansArabic-Regular",
        textColor: 40,
        fontSize: 10,
        fontStyle: "normal",
        cellPadding: 2,
      },
      margin: {
        left: 15,
      },
    });

    // Generate trip charts
    let chartCollection = [];
    let mapCollection = [];

    // Prefetch all charts upfront
    if (includeCharts) {
      const chartsPromises = [];
      const mapPromises = [];

      // Loop over assets
      this.reportData?.data.forEach((asset) => {
        // Loop over trips
        asset.Data.forEach((trip) => {
          const options = this.generateTwdChart(trip, false, false);

          chartsPromises.push(
            this.generateChartBase64(options, "CHART_RENDER_" + trip.TripId, theme, chartContainer, true)
          );

          if (includeMaps) {
            mapPromises.push(this.generateMapBase64(trip, mapContainer));
          }
        });
      });

      console.log("start await charts");
      chartCollection = await Promise.all(chartsPromises);
      console.log("done await charts");

      if (includeMaps) {
        console.log("start await maps display");
        mapCollection = await Promise.all(mapPromises);
        console.log("done await maps display");

        console.log("start await map display");
        const resolut = await Promise.resolve(this.resolver(mapContainer));
        console.log("done await map display");

        // Make sure maps are loaded
        console.log("Delay 2000ms to load trip on map");
        await this.delay(2000);
        console.log("Done delay....");
      }
    }

    let g = 0;

    // Loop over assets
    for (let i = 0; i < this.reportData?.data.length; i++) {
      const asset = this.reportData.data[i];

      // Loop over trips
      for (let t = 0; t < asset.Data.length; t++) {
        const trip = asset.Data[t];
        doc.addPage();

        g++;

        // Trip icon
        doc.setFont("fa-solid-900", "normal");
        doc.setFontSize(20);
        let iconUnicode = "\uf4d7";
        doc.text(iconUnicode, 15, 28);

        // Trip start
        doc.setFont("IBMPlexSansArabic-Regular", "normal");
        doc.setFontSize(16);

        doc.text(
          String(asset.Name + " - " + trip.Start.timestamp.format("lll") + " - " + trip.DurationHumanized),
          39,
          26
        );

        if (base64ImgSmall?.url) {
          doc.addImage(base64ImgSmall.url, "PNG", docWidth - 34, 10, base64ImgSmall.width, base64ImgSmall.height);
        }

        // Trip header
        autoTable(doc, {
          body: [
            { Name: "Started at", Value: trip.Start.timestamp.format("lll"), Data: trip.BeginAddressFull?.Value },
            { Name: "Ended at", Value: trip.End?.timestamp.format("lll"), Data: trip.EndAddressFull?.Value },
            {
              Name: "Duration / Distance",
              Value: roundSeconds(trip.Duration.asSeconds()),
              Data:
                this.actualRound(trip.SegmentDistanceInKilometers, 1)?.toLocaleString() +
                " " +
                this.translateService.instant(this.distance.getDistanceUnit()),
            },
            { Name: "Driver", Value: trip.DriverName, Data: "" },
            // { Name: 'Temperatures', Value: temp1MinString, Data: temp2MinString },
          ],
          startY: 40,
          theme: "plain",
          tableWidth: "wrap",
          rowPageBreak: "avoid",
          bodyStyles: {
            font: "IBMPlexSansArabic-Regular",
            textColor: 40,
            fontSize: 9,
            fontStyle: "normal",
            cellPadding: 2,
          },
          columnStyles: {
            Name: {
              cellWidth: 80,
            },
            Value: {
              cellWidth: 80,
              textColor: 20,
            },
            Data: {
              cellWidth: 400,
            },
          },
          margin: { top: 5, left: 15, right: 5, bottom: 15 },
        });

        // Trip chart
        const chartWidth = 428;
        const chartHeight = 200;

        try {
          const chartSource = chartCollection[g - 1][0];
          await doc.svg(chartSource, { x: 10, y: 80, width: chartWidth, height: chartHeight });
        } catch (error) {
          console.log(error);
        }

        if (includeMaps) {
          try {
            console.log("Adding map " + (g - 1));

            // Make sure tiles are loaded
            const map = mapCollection[g - 1];

            // Try to use SVG of the trip
            if (this.mapAsSVG == true) {
              // Try to use SVG of the trip
              console.log("Creating leaflet image");
              var test = await leafletImage(map[0], async (err, canvas) => {
                console.log("Adding image");
                doc.addImage(canvas.toDataURL().toString(), "PNG", 10, 250, 1200 / 2.8, 400 / 2.8);
                console.log("Adding image is done");
              });
            } else {
              // Try to export trip to image using html2canvas
              var printOptions = {
                container: map[1],
                exclude: [".leaflet-control-zoom"],
                format: "image/png",
                fileName: "map_" + g + ".svg",
                // afterRender: afterRender,
                // afterExport: afterExport
              };

              await map[0]["downloadExport"](printOptions).then(async (result) => {
                doc.addImage(result.data.toString(), "PNG", 10, 255, 1200 / 2.8, 400 / 2.8);
              });
            }
          } catch (error) {
            console.log(error);
          }
        }

        // Add episodes
        const reportHead = ["Time", "Event", "Location", "Status"];
        const sortedEpisodes = trip.Details.sort((a, b) => (a.timestamp < b.timestamp ? -1 : 1));
        const episodes = sortedEpisodes.map((episode) => {
          return [episode.timestamp?.format("YYYY-MM-DD HH:mm"), episode.name, episode.location, episode.status];
        });

        const start = [
          [
            trip.Start.timestamp?.format("YYYY-MM-DD HH:mm"),
            this.translateService.instant("general.startTrip"),
            trip.BeginAddressFull?.Value,
            "",
          ],
        ];
        const end = [
          [
            trip.End?.timestamp?.format("YYYY-MM-DD HH:mm"),
            this.translateService.instant("general.endTrip"),
            trip.EndAddressFull?.Value,
            "",
          ],
        ];

        const reportDataSource = start.concat(episodes).concat(end);
        const reportData = [];
        const linkArray = [];

        reportDataSource.forEach((reportDataItem) => {
          const linkItems = [];
          const contentItems = [];

          reportDataItem.forEach((reportDataItemColumn, index) => {
            if (typeof reportDataItemColumn === "object") {
              if (reportDataItemColumn?.Value != undefined) {
                contentItems.push(reportDataItemColumn?.Value);
                linkItems[index] = reportDataItemColumn?.Link;
              } else {
                contentItems.push(reportDataItemColumn);
              }
            } else {
              contentItems.push(reportDataItemColumn);
            }
          });

          linkArray.push(linkItems);
          reportData.push(contentItems);
        });

        // Trip episodes
        autoTable(doc, {
          head: [reportHead],
          body: reportData,
          tableWidth: "auto",
          startY: includeMaps ? 405 : 255,
          headStyles: {
            font: "IBMPlexSansArabic-Bold",
            fillColor: headerColor,
            textColor: 255,
            fontSize: fontSize,
            fontStyle: "bold",
          },
          pageBreak: "auto",
          rowPageBreak: "avoid",
          bodyStyles: { lineColor: [248, 248, 248] },
          columnStyles: {
            0: { cellWidth: 70 },
          },
          willDrawCell: (data) => {
            if (data.section === "body") {
              if (sortedEpisodes[data.row.index - 1] && sortedEpisodes[data.row.index - 1].classNameRow == "idling") {
                // Draw idling
                doc.setFillColor(255, 248, 225);
              }

              var link = linkArray[data.row.index][data.column.index];

              if (link) {
                // doc.setTextColor(linkColor)
                doc.link(data.cell.x, data.cell.y, data.cell.contentWidth, data.cell.contentHeight, { url: link });
              }
            }
          },
          didParseCell: (hookData) => {},
          didDrawPage: (data) => {
            // Footer
            let str = this.translateService.instant("general.pager.page") + " " + doc.internal["getNumberOfPages"]();
            if (typeof doc.putTotalPages === "function") {
              str = str + " " + this.translateService.instant("general.pager.of") + " " + totalPagesExp;
            }

            doc.setFontSize(8);
            doc.setTextColor(30);
            doc.setFont("IBMPlexSansArabic-Regular", "normal");

            // jsPDF 1.4+ uses getWidth, <1.4 uses .width
            const pageSize = doc.internal.pageSize;
            const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
            doc.text(str, data.settings.margin.left + 3, pageHeight - 15);

            doc.text(copyrights, docWidth - 15, pageHeight - 15, { align: "right" });
          },
          margin: { top: 15, left: 12, right: 12, bottom: 25 },
          styles: {
            fontSize: fontSize,
            textColor: 20,
            cellPadding: 3,
            font: "IBMPlexSansArabic-ExtraLight",
            overflow: "ellipsize",
          },
        });
      }
    }

    // Total page number plugin only available in jspdf v1.0+
    if (typeof doc.putTotalPages === "function") {
      doc.putTotalPages(totalPagesExp);
    }

    const filename = ("fm_report_" + this.selectedAccountName + "_" + this.selectedReportType)
      .replace(/\s/g, "")
      .replace(" ", "")
      .replace(".", "")
      .toLowerCase();

    $("#reportChart").remove();
    $(".export_container").remove();

    doc.save(filename + ".pdf", { returnPromise: true }).then(() => {
      console.log("returning true");
      return true;
    });
  }
}
