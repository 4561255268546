import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { AuthenticationService } from "app/services/authentication/authentication.service";
// Moment timezone
import Moment from "moment-timezone";
import { NotificationLogService } from "app/services/notificationlog/notificationlog.service";

window["moment"] = Moment;

@Component({
  selector: "fh-notification-log-details",
  templateUrl: "notificationLogDetails.template.html",
})
export class NotificationLogDetailsViewComponent implements OnInit {
  permissions: {};
  loading = false;
  error;
  notificationLog;
  sub: any;

  permissionName = "FleetManagement_Notifications";

  constructor(
    private router: Router,
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute,
    private authentication: AuthenticationService,
    private notificationLogService: NotificationLogService
  ) {
    this.permissions = this.authentication.permissions;
  }

  ngOnInit() {
    this.loading = true;
    this.sub = this.route.params.subscribe((params) => {
      const id = params["id"];

      this.getNotificationLogDetails(id);
    });
  }

  getNotificationLogDetails(id = 0) {
    this.loading = true;
    this.cd.markForCheck();

    if (id === 0) {
      id = this.notificationLog.id;
    }

    this.notificationLogService.getNoticationLogById(id.toString()).subscribe({
      next: (notificationLog) => {
        this.notificationLog = notificationLog;
        this.loading = false;
        this.cd.markForCheck();
      },
      error: (error) => {
        this.error = error;
        this.error.statusText = "Error fetching Notification Details";
        this.cd.markForCheck();

        setTimeout(() => {
          this.router.navigate(["/NotificationLog/Overview"]);
        }, 3000);
      },
    });
  }

  checkJson(value) {
    if (typeof value === "object") {
      return true;
    } else {
      return false;
    }
  }
}
