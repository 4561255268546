import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormMode } from "app/common/enums";
import { ConfirmationModalComponent } from "./confirmationModal.component";

@Component({
  selector: "fh-save-footer",
  templateUrl: "saveFooter.component.html",
})
export class SaveFooterComponent implements OnInit {
  @ViewChild("deleteModal") deleteModal: ConfirmationModalComponent;

  @Input() formMode: any = FormMode.read;
  @Output() onSave = new EventEmitter();
  @Output() onInsert = new EventEmitter();
  @Output() onDelete = new EventEmitter();
  @Output() setFormMode = new EventEmitter();
  @Input() valid = false;
  @Input() allowEdit = true;
  @Input() allowSave = true;
  @Input() allowAdd = false;
  @Input() allowDelete = false;
  @Input() allowCancelOnInsert = false;
  @Input() showButtons = true;
  @Input() showButtonsNotification = "";

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit(): void {}

  insert() {
    this.onInsert.emit(true);
    // this.setFormMode.emit(1);
  }

  save() {
    this.onSave.emit(true);
    // this.setFormMode.emit(1);
  }

  sendFormMode(mode) {
    this.setFormMode.emit(mode);
  }

  delete(event) {
    this.onDelete.emit();
    this.deleteModal.hideModal();
  }

  showDelete() {
    this.deleteModal.showModal(null);
  }
}
