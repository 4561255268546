import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { catchError, Observable, throwError } from "rxjs";
import { AuthenticationService } from "../authentication/authentication.service";

@Injectable()
export class InfoService {
  url: string = "";
  base_url: string = "";

  constructor(private http: HttpClient, private authenticationService: AuthenticationService) {
    this.url = this.authenticationService.getWebserviceURL("info");
    this.base_url = this.authenticationService.getWebserviceURL("");
  }

  getInfo(): Observable<any> {
    return this.http.get(this.url, { headers: this.authenticationService.headers }).pipe(catchError(this.handleError));
  }

  getHealth(): Observable<any> {
    return this.http
      .get(this.base_url + "health", { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  private handleError(error: Response) {
    return throwError(() => error);
  }
}
