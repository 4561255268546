<div
    dropdown
    [dropup]="true"
    class="taskDropdown"
    *ngIf="permissions['FleetManagement_Actions']"
    [isDisabled]="!entity || batchActions.length == 0"
    style="width: 100%; position: relative; overflow: visible"
>
    <ngx-loading [show]="processing"></ngx-loading>
    <button
        id="button-basic"
        dropdownToggle
        type="button"
        class="btn btn-primary dropdown-toggle"
        aria-controls="dropdown-basic"
        style="width: 100%; margin: 0px"
    >
        <i class="fa fa-fw fa-bolt"></i>
        <span class="d-none d-md-inline-flex"> {{'general.chooseAction' | translate }}</span>
        <span class="caret"></span>
    </button>
    <ul
        id="dropdown-basic"
        *dropdownMenu
        class="dropdown-menu dropdown-menu-fix dropdown-menu-right"
        role="menu"
        aria-labelledby="button-basic"
    >
        <li
            role="menuitem"
            *ngFor="let action of batchActions | orderBy : 'name':false"
            [ngClass]="action.disabled ? 'disabled' : ''"
        >
            <a
                [ngClass]="action.disabled ? 'disabled' : ''"
                class="dropdown-item"
                href="javascript:void(0)"
                (click)="!action.disabled ? showModal(action) : null"
            >
                <i
                    class="fas fa-fw"
                    [ngClass]="action.icon ? action.icon : 'fa-angle-right'"
                    style="padding-right: 5px"
                ></i>
                {{("enums.batchAction." + action.batchActionType) | translate}}</a
            >
        </li>
    </ul>
</div>

<fh-confirmation-modal
    [message]="'general.areyousure'"
    [showButtons]="(selectedAction?.batchActionType == 27)"
    [invalid]="groupForm.invalid"
    #confirmModal
    (onFinish)="process($event)"
    (onHide)="resetAction($event)"
>
    <h4 header class="modal-title pull-left">
        <i class="fa fa-fw fa-bolt"></i> {{'general.action' | translate }}: {{('enums.batchAction.' +
        selectedAction?.batchActionType) | translate }}
    </h4>
    <form body #groupForm="ngForm" style="width: 100%; border-top: 0px">
        <fh-handle-tasks
            #handleAction
            [selectedAction]="selectedAction"
            [batchProcessing]="false"
            [entityTypes]="entityTypes"
            (onFinish)="process($event)"
        ></fh-handle-tasks>
    </form>
</fh-confirmation-modal>
