import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ControlContainer, NgForm } from "@angular/forms";

// Moment
import Moment from "moment-timezone";

import { FormMode } from "app/common/enums";
import { CustomCommandDetailsComponent } from "app/views/shared/usercontrols/customCommandDetails.component";

window["moment"] = Moment;

@Component({
  selector: "fh-fh-custom-command-details",
  templateUrl: "commandDetails.template.html",
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomCommandDetailsViewComponent implements OnInit {
  @ViewChild(CustomCommandDetailsComponent, { static: true })
  commandParent: CustomCommandDetailsComponent;
  loading: boolean;
  sub: any;
  formMode = FormMode.read;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.loading = true;
    this.sub = this.route.params.subscribe((params) => {
      const id = params["id"];

      this.commandParent.getCustomCommand(id);
    });
  }
}
