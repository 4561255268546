<ng-select
    [loading]="loadingTrailers"
    ngDefaultControl
    [searchable]="true"
    [clearable]="clearable"
    style="height: unset"
    [disabled]="trailers.length == 0"
    [readonly]="readonly"
    placeholder="{{ placeholder }} ({{ trailers.length }})"
    #input
    [multiple]="multiple"
    [(ngModel)]="value"
    class="form-control"
    [ngClass]="multiple !== true ? 'table-inline' : ''"
    (ngModelChange)="trailerChanged()"
>
    <ng-option *ngFor="let trailer of trailers | orderBy : 'name' : false" [value]="trailer.id">
        <i class="fas fa-fw fa-trailer"></i> {{ trailer.name }}</ng-option
    >
</ng-select>
<div style="clear: both"></div>
