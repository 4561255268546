import { ChangeDetectorRef, Component, OnDestroy } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { getGridButtons, getGridLanguages, getDefaultDpConfig, createdCellCheckbox } from "app/common/gridhelper";
import { AuthenticationService } from "../../services/authentication/authentication.service";
import { GridBase360Directive } from "app/common/360Grid.base";
import { ActivatedRoute } from "@angular/router";

import { ReportService } from "app/services/reports/report.service";
import { BsDaterangepickerConfig } from "ngx-bootstrap/datepicker";

import { StorageHelper } from "app/common/storagehelper";
import { forkJoin } from "rxjs/internal/observable/forkJoin";

// Moment
import Moment from "moment-timezone";
import { roundAsString, roundAsNumber } from "app/common/globals";
import { ColorService } from "app/services/common/color.service";
window["moment"] = Moment;

@Component({
  selector: "fh-reporting-subscriptions",
  templateUrl: "subscriptions.template.html",
})
export class ReportingSubscriptionsViewComponent extends GridBase360Directive implements OnDestroy {
  token: string;
  showRecurringSubs = true;

  loading = false;
  languageLoaded: boolean;
  permissions: {};

  availableReports = {};

  excludingColumns = ["timestamp"];

  success;
  error;

  randomKey;

  constructorName = "ReportingViewComponent";
  permissionName = "FleetManagement_Reporting";
  timezoneIana: string;

  // Daterange
  public dpConfig: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();
  daterangepickerModel: Date[];

  constructor(
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private cd: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private reportService: ReportService,
    protected storageHelper: StorageHelper,
    private colorService: ColorService
  ) {
    super(storageHelper);

    this.loading = true;

    this.randomKey = Math.floor(Math.random() * (999999 - 100000)) + 100000;

    this.permissions = this.authenticationService.permissions;
    this.token = this.authenticationService.getAuthToken();
    this.timezoneIana = this.authenticationService.getTimeZoneIana();

    this.daterangepickerModel = [
      Moment().tz(this.timezoneIana).subtract(1, "months").startOf("day").toDate(),
      Moment().tz(this.timezoneIana).endOf("day").toDate(),
    ];

    this.dpConfig = getDefaultDpConfig(authenticationService);

    // Get all the date for dropdown boxes
    forkJoin([this.reportService.getReportColumnsByReportType(), this.translateService.get("general.date")]).subscribe({
      next: ([columns, _]) => {
        this.languageLoaded = true;
        this.loading = false;

        for (const item in columns) {
          if (item === null) {
            continue;
          }

          this.availableReports[columns[item].reportType] = true;
        }

        delete this.availableReports["0"];

        this.cd.markForCheck();

        this.initGrid();
      },
      error: (err) => {
        this.error = err;
        this.languageLoaded = true;
        this.loading = false;
        this.cd.markForCheck();
      },
    });
  }

  dateChanged(event) {
    if (event !== null) {
      this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.ajax
          .url(
            this.reportService.getSubscriptionPagingUrl(
              Moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf("day"),
              Moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf("day"),
              this.randomKey
            )
          )
          .load();
      });
    }
  }

  actualRound(value, decimals) {
    return roundAsNumber(value, decimals);
  }

  // Check custom filters from grid save
  checkFilters() {
    this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
      this.showRecurringSubs = dtInstance.column("reportSubscriptionType:name").search() !== "@ignore";
    });

    this.fireFilter(1);
  }

  // Fire custom filters to update grid and call server again
  fireFilter(event): void {
    if (event != null) {
      console.log("Fire update");
      this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
        if (this.showRecurringSubs === true) {
          dtInstance.column("reportSubscriptionType:name").search("2");
        } else {
          dtInstance.column("reportSubscriptionType:name").search("@ignore");
        }

        // Fire the request
        dtInstance.draw();
      });
    }
  }

  initGrid(): void {
    $.fn["dataTable"].ext.search.pop();

    this.loading = true;

    // Id = (Guid)row.GeneratedReportId,
    // AccountId = (int)row.AccountId,
    // SelectedReportTypeId = (int)row.SelectedReportTypeId,
    // FkUserId = (int)row.FkUserId,
    // Timestamp = (DateTime)row.Timestamp,
    // Filters = (string)row.Filters,
    // ReportData = (string)row.ReportData,

    const reportSubscriptionTypeOptions = [];
    for (let i = 1; i < 3; i++) {
      reportSubscriptionTypeOptions.push({
        id: i,
        value: this.translateService.instant("enums.reportSubscriptionType." + i),
      });
    }

    const reportTypeOptions = [];
    for (const i of Object.keys(this.availableReports)) {
      reportTypeOptions.push({ id: i, value: this.translateService.instant("enums.reportType." + i) });
    }

    this.columns = [
      {
        name: "id",
        data: "id",
        className: "noVis",
        orderable: false,
        title: '<div class="hideDropdown"></div>',
        width: "20",
        render: (data, type, row) => {
          return `
            <a class='btn btn-primary btn-grid' title='${this.translateService.instant(
              "general.details"
            )}' href='/#/ReportSubscriptionDetails/Index/${data}'>
              <span class="d-none d-md-inline-flex" style="padding-left: 7px">${this.translateService.instant(
                "general.details"
              )}</span>
              <i class='fas fa-fw fa-angle-right'></i>
            </a>`;
        },
      },
      {
        name: "createdDate",
        data: "createdDate",
        type: "date",
        title: this.translateService.instant("general.createdDate"),
        render: (data, type, row) => {
          const date = Moment.utc(data)["tz"](this.timezoneIana);
          return data ? '<span title=" ' + date.toLocaleString() + '">' + date.format("lll") + "</span>" : "";
        },
      },
      {
        name: "selectedReportType",
        data: "selectedReportType",
        title: this.translateService.instant("general.selectedReportType"),
        type: "select",
        options: reportTypeOptions.sort((a, b) => a.value.localeCompare(b.value)),
        render: (data, type, row) => {
          return this.translateService.instant("enums.reportType." + data);
        },
      },
      {
        name: "name",
        data: "name",
        defaultContent: "",
        title: this.translateService.instant("general.name"),
        render: (data, type, row) => {
          var templateName = row.template?.name;
          return data ? data : templateName ? templateName : "-";
        },
      },
      {
        name: "executionCount",
        data: "executionCount",
        defaultContent: "",
        type: "num",
        title: this.translateService.instant("general.executionCount"),
        render: (data, type, row) => {
          return data ? roundAsString(data, 0) : "-";
        },
      },
      {
        name: "maxExecutionCompletedTimestamp",
        data: "maxExecutionCompletedTimestamp",
        defaultContent: "",
        type: "date",
        title: this.translateService.instant("general.maxExecutionCompletedTimestamp"),
        render: (data, type, row) => {
          if (data == null) {
            return "N/A";
          } else {
            const date = Moment.utc(data)["tz"](this.timezoneIana);
            return data ? '<span title=" ' + date.toLocaleString() + '">' + date.format("lll") + "</span>" : "";
          }
        },
      },
      {
        name: "reportSubscriptionType",
        data: "reportSubscriptionType",
        title: this.translateService.instant("general.reportSubscriptionType"),
        type: "select",
        options: reportSubscriptionTypeOptions.sort((a, b) => a.value.localeCompare(b.value)),
        render: (data, type, row) => {
          return this.translateService.instant("enums.reportSubscriptionType." + data);
        },
      },
      {
        name: "reportingInterval",
        data: "reportingInterval",
        defaultContent: "",
        visible: false,
        title: this.translateService.instant("general.reportingInterval"),
        render: (data, type, row) => {
          return data ? data : "-";
        },
      },
      {
        name: "nextOccurrence",
        data: "nextOccurrence",
        defaultContent: "",
        type: "date",
        title: this.translateService.instant("general.nextOccurrence"),
        render: (data, type, row) => {
          if (data == null) {
            return "N/A";
          } else {
            if (row.isArchived) {
              return "-";
            }
            const date = Moment.utc(data)["tz"](this.timezoneIana);
            return data ? '<span title=" ' + date.toLocaleString() + '">' + date.format("lll") + "</span>" : "";
          }
        },
      },
      {
        name: "userName",
        data: "userName",
        defaultContent: "-",
        title: this.translateService.instant("general.userName"),
      },
      {
        name: "companyName",
        data: "companyName",
        defaultContent: "-",
        title: this.translateService.instant("general.companyName"),
      },
      {
        name: "accountId",
        data: "accountId",
        defaultContent: "-",
        title: this.translateService.instant("general.accountId"),
        visible: false,
      },
      {
        name: "resellerDescription",
        data: "resellerDescription",
        defaultContent: "-",
        title: this.translateService.instant("general.resellerDescription"),
        visible: false,
      },
      {
        name: "notifyOnCompletion",
        data: "notifyOnCompletion",
        title: this.translateService.instant("general.notifyOnCompletion"),
        type: "checkBox",
        defaultContent: "",
        visible: true,
        createdCell: createdCellCheckbox,
        width: "40",
      },
      {
        name: "isArchived",
        data: "isArchived",
        title: this.translateService.instant("general.archived"),
        type: "checkBox",
        defaultContent: "",
        visible: true,
        createdCell: createdCellCheckbox,
        width: "40",
      },
    ];

    this.dtOptions = {
      buttons: getGridButtons(
        this.commonExportOptions,
        "reporting_overview",
        this.translateService.instant("general.job"),
        this.colorService
      ),
      pagingType: "simple_numbers",
      serverSide: true,
      processing: true,
      searchDelay: 2000,
      deferRender: true,
      scrollX: true,
      colReorder: { fixedColumnsLeft: 1 },
      deferLoading: 0,
      stateSave: true,
      stateSaveCallback: (settings, data) => {
        this.saveState(this.constructorName, data);
      },
      stateLoadCallback: (_, callback) => {
        (async () => {
          try {
            const columnSettings = await this.loadState(this.constructorName);
            this.searchTerm = columnSettings && columnSettings.search && columnSettings.search.search;
            return columnSettings;
          } catch (e) {
            this.error = {};
            this.error.error = e;
            this.error.statusText = "Error fetching column settings";

            return null;
          }
        })().then((result) => {
          callback(result);
        });
      },
      order: [[1, "desc"]],
      ajax: {
        beforeSend: () => {
          this.drawFilterRow();

          $(".dataTables_info").html(this.translateService.instant("grid.loadingData"));
        },
        url: this.reportService.getSubscriptionPagingUrl(
          Moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf("day"),
          Moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf("day"),
          this.randomKey
        ),
        data: (d) => {
          return d;
        },
        type: "POST",
        headers: {
          Authorization: "Bearer " + this.token,
        },
      },
      initComplete: (settings, json) => {
        this.checkFilters();
        this.setEvents();
        this.drawFilterRow();
        this.loading = false;
        this.cd.markForCheck();
      },
      colVis: {
        restore: this.translateService.instant("general.restore"),
        showAll: this.translateService.instant("general.showAll"),
        showNone: this.translateService.instant("general.hideAll"),
        exclude: ["id", "id_export"],
      },
      columns: this.columns,
      pageLength: 17,
      lengthMenu: [
        [10, 17, 25, 50, -1],
        [10, 17, 25, 50, this.translateService.instant("general.all")],
      ],
      language: getGridLanguages(this.translateService),
    };
  }
}
