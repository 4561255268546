export class AssetTrailer {
  public startDate: any;
  public endDate: any;
  public type: any;
  public id: any;
}

export class Trailer {
  public id: any;

  public name: string;
  public erpCode: any;

  public assetTrailer: AssetTrailer;
  public IsArchived: any;

  public companyName: any;
  public accountId: any;
  public deviceId: any;
  public assetName: any;
  public trailerAssignmentStart: any;
  public trailerAssignmentEnd: any;
  public identificationTag: any;

  public resellerId: any;
  public resellerDescription: any;
}
