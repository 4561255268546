<fh-wizard [(step)]="step" (finish)="onCheckOutFinish()">
    <fh-wizard-step
        title="{{ 'general.finish' | translate}}"
        icon="fa-save"
        (open)="initForm()"
        icon="fa-car-bus"
        [validate]="deviceForm"
        [padding]="false"
    >
        <div class="wizard-content">
            <table style="width: 100%" class="table nowrap dtr-inline no-footer">
                <thead>
                    <tr>
                        <th>{{'general.cloneDeviceSettings' | translate }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <div class="input-group" style="display: flex; width: 100%">
                                <input
                                    [typeahead]="autoComplete$"
                                    [(ngModel)]="asyncSelected"
                                    [ngModelOptions]="{standalone: true}"
                                    [typeaheadMinLength]="2"
                                    (typeaheadLoading)="changeTypeaheadLoading($event)"
                                    (typeaheadOnSelect)="typeaheadOnSelect($event)"
                                    [typeaheadWaitMs]="1000"
                                    typeaheadGroupField="groupname"
                                    [typeaheadOptionsLimit]="50"
                                    [typeaheadScrollable]="true"
                                    [typeaheadOptionsInScrollableView]="10"
                                    typeaheadOptionField="name"
                                    placeholder="{{'search.searchdevices' | translate }}"
                                    class="form-control typeahead"
                                />
                                <div class="input-group-btn" style="width: 35px">
                                    <button
                                        class="btn btn-default"
                                        style="border: none; background: none; color: #fff"
                                        type="submit"
                                    >
                                        <span *ngIf="typeaheadLoading">
                                            <i class="fa fa-fw fa-spinner fa-spin"></i>
                                        </span>
                                        <span *ngIf="!typeaheadLoading">
                                            <i class="fa fa-search"></i>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

            <form #sensorForm="ngForm">
                <form #deviceForm="ngForm">
                    <div class="ibox-content slick" style="position: relative">
                        <ngx-loading [show]="virtualSensorsLoading"> </ngx-loading>
                        <table style="width: 100%" class="table nowrap dtr-inline no-footer">
                            <thead>
                                <tr>
                                    <th style="width: 200px">{{'general.sensor' | translate }}</th>
                                    <th>{{'general.configuration' | translate }}</th>
                                    <th style="width: 150px">{{'general.output' | translate }}</th>
                                    <th style="width: 50px">&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngIf="formMode === 1 && virtualSensors?.length === 0">
                                    <td colspan="4">{{'general.noSensorsFound' | translate }}</td>
                                </tr>
                                <input
                                    style="display: none"
                                    *ngIf="!virtualSensorsOverWrite && virtualSensors?.length === 0"
                                    type="checkbox"
                                    name="emptyForm"
                                    [(ngModel)]="validForm"
                                    required
                                />
                                <tr *ngFor="let sensor of virtualSensors; let i = index">
                                    <td style="width: 200px">
                                        <input
                                            type="text"
                                            [readonly]="formMode == 1"
                                            [name]="'name_' + i"
                                            placeholder="{{ 'placeholder.driverTag' | translate}}"
                                            class="form-control"
                                            required
                                            [(ngModel)]="sensor.name"
                                        />
                                    </td>
                                    <td style="width: auto">
                                        <textarea
                                            style="width: 100%; height: 75px; resize: none"
                                            [readonly]="formMode == 1"
                                            [name]="'configuration_' + i"
                                            placeholder="{{ 'placeholder.formula' | translate}}"
                                            class="form-control"
                                            required
                                            [(ngModel)]="sensor.formula"
                                        >
                                        </textarea>
                                    </td>
                                    <td style="width: 150px">
                                        <ng-select
                                            [name]="'sensorOuput_' + i"
                                            ngDefaultControl
                                            [searchable]="true"
                                            [clearable]="false"
                                            [readonly]="formMode == 1"
                                            [(ngModel)]="sensor.sensorOutputType"
                                            class="form-control"
                                            style="width: 100%"
                                            required
                                        >
                                            <ng-option
                                                *ngFor="let virtualSensorOutputType of virtualSensorOutputTypes | orderBy : 'name':false"
                                                [value]="virtualSensorOutputType.value"
                                            >
                                                {{ ('enums.virtualSensorOutputType.' + virtualSensorOutputType.value) |
                                                translate}}
                                            </ng-option>
                                        </ng-select>
                                    </td>
                                    <td style="text-align: right; width: 60px">
                                        <button
                                            class="btn btn-danger btn-inline"
                                            *ngIf="formMode !== 1"
                                            (click)="removeSensor(sensor)"
                                        >
                                            <i class="fa fa-fw fa-trash-alt"></i>
                                        </button>
                                    </td>
                                </tr>

                                <tr *ngIf="formMode !== 1">
                                    <td style="width: auto">
                                        <input
                                            type="text"
                                            name="newDriverTag"
                                            placeholder="{{ 'placeholder.newSensor' | translate}}"
                                            class="form-control"
                                            [(ngModel)]="newSensorName"
                                        />
                                    </td>
                                    <td style="width: auto">
                                        <textarea
                                            style="width: 100%; height: 75px; resize: none"
                                            name="newSensorFormula"
                                            placeholder="{{ 'placeholder.formula' | translate}}"
                                            class="form-control"
                                            [(ngModel)]="newSensorFormula"
                                        ></textarea>
                                    </td>
                                    <td style="width: auto">
                                        <ng-select
                                            name="newSensorOutputType"
                                            ngDefaultControl
                                            [searchable]="true"
                                            [clearable]="false"
                                            [(ngModel)]="newSensorOutputType"
                                            class="form-control"
                                            style="width: 100%"
                                        >
                                            <ng-option
                                                *ngFor="let virtualSensorOutputType of virtualSensorOutputTypes | orderBy : 'name':false"
                                                [value]="virtualSensorOutputType.value"
                                            >
                                                {{ ('enums.virtualSensorOutputType.' + virtualSensorOutputType.value) |
                                                translate}}
                                            </ng-option>
                                        </ng-select>
                                    </td>
                                    <td>
                                        <button
                                            class="btn btn-primary btn-inline"
                                            [disabled]="!newSensorName || newSensorName.length < 2 || !newSensorFormula || newSensorFormula.length < 2 || newSensorOutputType < 0"
                                            (click)="add()"
                                        >
                                            <i class="fa fa-fw fa-plus"></i>
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="4">
                                        <div class="fa_checkbox">
                                            <input
                                                id="checkbox"
                                                name="virtualSensorsOverWrite"
                                                type="checkbox"
                                                (change)="checkForm(deviceForm)"
                                                [(ngModel)]="virtualSensorsOverWrite"
                                            />
                                            <label for="checkbox">
                                                {{'general.overWriteCurrentVirtualSensors' | translate }}
                                            </label>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </form>
            </form>
        </div>
    </fh-wizard-step>
    <fh-wizard-step title="Remarks" icon="fa-file-pdf">
        <div class="wizard-content" style="padding-bottom: 10px">{{ 'wizard.optionalComment' | translate }}</div>
        <div class="wizard-content">
            <textarea
                class="form-control noresize stickypad"
                style="height: 100px; width: 100%; resize: none"
                placeholder="{{ 'placeholder.comments' | translate}}"
                [(ngModel)]="logItem"
            ></textarea>
        </div>
    </fh-wizard-step>
    <fh-wizard-step title="Summary" icon="fa-summary-pdf">
        <div class="wizard-content" style="padding-bottom: 10px">
            {{ 'wizard.summary' | translate:{ count: gridSelection.length } }}
        </div>
    </fh-wizard-step>
</fh-wizard>
