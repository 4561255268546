import { Component, OnInit, ChangeDetectorRef, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormMode } from "app/common/enums";
import { Warehouse, WarehouseSensor, WarehouseZone } from "app/models/warehouse.model";
import { AuthenticationService } from "app/services/authentication/authentication.service";
// Moment timezone

import { WarehouseService } from "../../services/warehouse/warehouse.service";
import { Subscription } from "rxjs";
import { ConfirmationModalComponent } from "../shared/usercontrols/confirmationModal.component";
import { ModalDirective } from "ngx-bootstrap/modal";

@Component({
  selector: "fh-fh-warehouse-zone-details",
  templateUrl: "warehouseZoneDetails.template.html",
})
export class WarehouseZoneDetailsViewComponent implements OnInit {
  @ViewChild("confirmModal", { static: false }) confirmModal: ConfirmationModalComponent;
  @ViewChild("deleteModal") deleteModal: ConfirmationModalComponent;

  @ViewChild("addModal") addModal: ModalDirective;
  formModeSensor = FormMode.add;
  sensor: WarehouseSensor = new WarehouseSensor();

  sub: any;
  sub2: any;
  zone: WarehouseZone = new WarehouseZone();
  warehouse: Warehouse = new Warehouse();

  formMode = FormMode.read;

  loading = false;
  saving = false;
  loadingSensors = false;
  error: any;
  success: any;

  loadingLocation = false;
  allowDelete = true;
  isSpinning = false;

  geofence;

  warehouseAssignments = [];
  sensorData = [];
  sortedResult = [];
  previousLookupDisplay;

  permissionName = "Warehouses_View";
  locationSubscription: Subscription;
  timezoneIana: string;
  permissions: {};
  sensors = [];
  warehouseId: any;

  constructor(
    private router: Router,
    private cd: ChangeDetectorRef,
    private authentication: AuthenticationService,
    private warehouseService: WarehouseService,
    private route: ActivatedRoute
  ) {
    this.permissions = this.authentication.permissions;
    this.timezoneIana = this.authentication.getTimeZoneIana();
  }

  ngOnInit() {
    this.loading = true;

    this.sub = this.route.params.subscribe((params) => {
      const id = params["id"];

      this.warehouseId = id;
      this.warehouseService.getWarehouseById(id).subscribe((warehouse) => {
        this.warehouse = warehouse;
      });
    });

    this.sub2 = this.route.queryParams.subscribe((params) => {
      const id = params["zoneId"];
      this.getWarehouseZoneInfo(id);
    });
  }

  // Form
  onDelete() {
    this.loading = true;

    this.warehouseService.deleteWarehouseZone(this.zone.rowKey, this.zone.partitionKey).subscribe({
      next: (result) => {
        this.error = null;
        this.success = {
          statusText: "Success",
          success: "WarehousZone is successfully deleted.",
        };

        setTimeout(() => {
          this.router.navigate(["/Warehouses/Overview"]);
        }, 3000);
      },
      error: (error) => {
        this.success = null;
        this.error = error;
        this.loading = false;
        this.cd.markForCheck();
      },
    });
  }

  onSave() {
    this.saving = true;

    this.warehouseService.updateWarehouseZone(this.zone).subscribe({
      next: (result) => {
        this.error = null;
        this.success = {
          statusText: "Success",
          success: "WarehouseZone is successfully updated.",
        };

        this.saving = false;
        this.cd.markForCheck();

        this.setFormMode(FormMode.read);
      },
      error: (error) => {
        this.saving = false;
        this.success = null;
        this.error = error;
        this.cd.markForCheck();
      },
    });
  }

  setFormMode(mode) {
    this.formMode = mode;

    if (this.formMode === FormMode.read) {
      this.loading = true;
      this.getWarehouseZoneInfo(this.zone.rowKey);
    }
  }

  getWarehouseZoneInfo(id) {
    this.loading = true;
    this.loadingSensors = true;

    this.warehouseService.getWarehouseZoneById(id).subscribe({
      next: (zone) => {
        this.zone = zone;
        this.loading = false;

        this.warehouseService.getWarehouseZoneSensorsById(id).subscribe((sensors) => {
          this.sensors = sensors;
          this.loadingSensors = false;
        });
      },
      error: (error) => {
        this.error = error;
        this.error.statusText = "Error fetching warehouse zone";

        this.cd.markForCheck();

        setTimeout(() => {
          this.router.navigate(["/Warehouses/Overview"]);
        }, 3000);
      },
    });
  }

  // Editing

  editSensor(sensor) {
    this.sensor = sensor;

    this.addModal.show();
  }
  // Deleting

  deleteSensor(sensor) {
    this.deleteModal.hideModal();
    this.saving = true;

    this.warehouseService.deleteWarehouseSensor(sensor.rowKey, sensor.partitionKey).subscribe({
      next: (result) => {
        this.error = null;
        this.success = {
          statusText: "Success",
          success: "Sensor is successfully deleted.",
        };

        this.saving = false;
        this.setFormMode(FormMode.read);
      },
      error: (error) => {
        this.success = null;
        this.error = error;
        this.saving = false;
        this.cd.markForCheck();
      },
    });
  }

  showDelete(id) {
    this.deleteModal.showModal(id);
  }

  // Adding
  showModal() {
    this.addModal.show();
  }

  hideModal() {
    this.addModal.hide();

    this.sensor = new WarehouseSensor();
  }

  confirm() {
    this.addModal.hide();

    this.saving = true;

    if (this.sensor.rowKey && this.sensor.partitionKey) {
      // Update
      this.warehouseService.updateWarehouseSensor(this.sensor).subscribe({
        next: (result) => {
          this.error = null;
          this.success = {
            statusText: "Success",
            success: "WarehouseZone Sensor is successfully updated.",
          };

          this.saving = false;
          this.cd.markForCheck();

          this.setFormMode(FormMode.read);
        },
        error: (error) => {
          this.saving = false;
          this.success = null;
          this.error = error;
          this.cd.markForCheck();
        },
      });
    } else {
      // Add
      this.sensor.zoneId = this.zone.rowKey;
      this.warehouseService.saveWarehouseSensor(this.sensor).subscribe({
        next: (result) => {
          this.error = null;
          this.success = {
            statusText: "Success",
            success: "WarehouseZone Sensor is successfully added.",
          };

          this.saving = false;
          this.cd.markForCheck();

          this.setFormMode(FormMode.read);
        },
        error: (error) => {
          this.saving = false;
          this.success = null;
          this.error = error;
          this.cd.markForCheck();
        },
      });
    }
    this.sensor = new WarehouseSensor();
  }
}
