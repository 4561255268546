<div class="ibox-content slick" style="position: relative; overflow: visible">
    <ngx-loading [show]="loading"></ngx-loading>
    <table style="width: 100%" class="table nowrap dtr-inline no-footer table-fixed">
        <tbody [@enterAnimation]>
            <tr [hidden]="!permissions['Platform_IsReseller']">
                <td class="row_opening">{{ 'general.inventoryLocation' | translate}}</td>
                <td class="padding-readonly-correct row_content">
                    <a class="secondary link_bolder" href="/#/ResellerDetails/Index/{{device?.resellerId}}"
                        >{{device?.resellerDescription }}</a
                    >
                </td>
            </tr>
            <tr [hidden]="!permissions['Platform_IsReseller']">
                <td class="row_opening">{{ 'general.activatedTo' | translate}}</td>
                <td class="padding-readonly-correct row_content">
                    <a
                        *ngIf="device?.accountId"
                        class="secondary link_bolder"
                        href="/#/AccountDetails/Index/{{device?.accountId}}"
                        >{{ device?.companyName }}</a
                    >
                    <span *ngIf="!device?.accountId">-</span>
                </td>
            </tr>
            <tr [hidden]="!permissions['Platform_IsReseller']">
                <td class="row_opening">
                    {{ 'general.deviceInventoryStatus' | translate}} / {{ 'general.activationDate' | translate}}
                </td>
                <td class="row_content" style="overflow: visible">
                    <span style="width: 50%; float: left">
                        <ng-select
                            name="deviceInventoryStatus"
                            [disabled]="formMode == 1"
                            ngDefaultControl
                            [searchable]="false"
                            [clearable]="false"
                            placeholder="{{ 'general.deviceStatus' | translate}}"
                            [(ngModel)]="device.deviceInventoryStatus"
                            #deviceInventoryStatusVar="ngModel"
                            class="form-control table-inline"
                            style="width: 100%"
                            required
                        >
                            <ng-option [value]="0">
                                <i class="fa fa-fw fa-question"></i>
                                {{(('enums.deviceInventoryStatus.0') | translate)}}
                            </ng-option>
                            <ng-option [value]="1">
                                <i class="fa fa-fw fa-pennant"></i>
                                {{(('enums.deviceInventoryStatus.1') | translate)}}
                            </ng-option>
                            <ng-option [value]="2">
                                <i class="fa fa-fw fa-check"></i>
                                {{(('enums.deviceInventoryStatus.2') | translate)}}
                            </ng-option>
                            <ng-option [value]="3">
                                <i class="fa fa-fw fa-check"></i>
                                {{(('enums.deviceInventoryStatus.3') | translate)}}
                            </ng-option>
                            <ng-option [value]="4">
                                <i class="fa fa-fw fa-cactus"></i>
                                {{(('enums.deviceInventoryStatus.4') | translate)}}
                            </ng-option>
                            <ng-option [value]="5">
                                <i class="fa fa-fw fa-cactus"></i>
                                {{(('enums.deviceInventoryStatus.5') | translate)}}
                            </ng-option>
                            <ng-option [value]="6">
                                <i class="fa fa-fw fa-cactus"></i>
                                {{(('enums.deviceInventoryStatus.6') | translate)}}
                            </ng-option>
                            <ng-option [value]="7">
                                <i class="fa fa-fw fa-cactus"></i>
                                {{(('enums.deviceInventoryStatus.7') | translate)}}
                            </ng-option>
                            <ng-option [value]="8">
                                <i class="fa fa-fw fa-cactus"></i>
                                {{(('enums.deviceInventoryStatus.8') | translate)}}
                            </ng-option>
                            <ng-option [value]="9">
                                <i class="fa fa-fw fa-cactus"></i>
                                {{(('enums.deviceInventoryStatus.9') | translate)}}
                            </ng-option>
                            <ng-option [value]="10">
                                <i class="fa fa-fw fa-cactus"></i>
                                {{(('enums.deviceInventoryStatus.10') | translate)}}
                            </ng-option>
                        </ng-select>
                    </span>
                    <span style="width: 50%; float: left; padding-left: 4px">
                        <input
                            type="text"
                            readonly
                            name="activationDate"
                            class="form-control table-inline"
                            [value]="device.activationDate | amDateFormat: 'll'"
                    /></span>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.deviceStatus' | translate}} / {{ 'general.createdDate' | translate}}
                </td>
                <td class="row_content" style="overflow: visible">
                    <span style="width: 50%; float: left">
                        <ng-select
                            name="deviceStatus"
                            [disabled]="formMode == 1"
                            ngDefaultControl
                            [searchable]="false"
                            [clearable]="false"
                            placeholder="{{ 'general.deviceStatus' | translate}}"
                            [(ngModel)]="device.deviceStatus"
                            #deviceStatusVar="ngModel"
                            class="form-control table-inline"
                            style="width: 100%"
                            required
                        >
                            <ng-option [value]="0">
                                <i class="fa fa-fw fa-check"></i>
                                {{(('enums.deviceStatus.0') | translate)}}
                            </ng-option>
                            <ng-option [value]="4">
                                <i class="fa fa-fw fa-snooze"></i>
                                {{(('enums.deviceStatus.4') | translate)}}
                            </ng-option>
                            <ng-option [value]="8">
                                <i class="fa fa-fw fa-cactus"></i>
                                {{(('enums.deviceStatus.8') | translate)}}
                            </ng-option>
                            <ng-option [value]="32">
                                <i class="fa fa-fw fa-undo-alt"></i>
                                {{(('enums.deviceStatus.32') | translate)}}
                            </ng-option>
                            <ng-option [value]="64">
                                <i class="fa fa-fw fa-archive"></i>
                                {{(('enums.deviceStatus.64') | translate)}}
                            </ng-option>
                        </ng-select>
                    </span>
                    <span style="width: 50%; float: left; padding-left: 4px">
                        <input
                            type="text"
                            readonly
                            name="createdDate"
                            class="form-control table-inline"
                            [value]="device.createdDate | amDateFormat: 'll'"
                    /></span>
                </td>
            </tr>
            <tr *ngIf="device?.isEnded">
                <td [class.text-danger]="device?.isEnded" class="row_opening">{{ 'general.endDate' | translate}}</td>
                <td [class.text-danger]="device?.isEnded" class="row_content">
                    <input
                        type="text"
                        readonly
                        name="endDate"
                        class="form-control table-inline"
                        [value]="device.endDate | amDateFormat: 'll'"
                    />
                </td>
            </tr>

            <!-- <tr>
                <td class="row_opening">
                    {{ 'general.referenceId' | translate}}
                </td>
                <td class="row_content">
                    <a href="{{device?.referenceLink}}" target="_blank"
                        class="secondary link_bolder">{{ device?.referenceId }}</a>
                </td>
            </tr> -->
            <tr>
                <td class="row_opening">
                    {{ 'general.unitId' | translate}}
                    <span class="error" *ngIf="unitId.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <fh-copyinput [hide]="formMode != 1">
                        <input
                            body
                            type="text"
                            [readonly]="true"
                            name="unitId"
                            class="form-control table-inline"
                            placeholder="{{ 'placeholder.unitId' | translate}}"
                            [(ngModel)]="device.unitId"
                            required
                            minlength="3"
                            #unitId="ngModel"
                        />
                    </fh-copyinput>
                </td>
            </tr>
            <tr [hidden]="!permissions['Platform_IsReseller']">
                <td class="row_opening">
                    {{ 'general.simActivationStatus' | translate}} / {{ 'general.simIdentifier' | translate}}
                </td>
                <td class="row_content" style="overflow: visible">
                    <span style="width: 50%; float: left">
                        <ng-select
                            name="simActivationStatus"
                            [disabled]="formMode == 1"
                            ngDefaultControl
                            [searchable]="false"
                            [clearable]="false"
                            placeholder="{{ 'general.simActivationStatus' | translate}}"
                            [(ngModel)]="device.simActivationStatus"
                            #simActivatioNVar="ngModel"
                            class="form-control table-inline"
                            style="width: 100%"
                            required
                        >
                            <ng-option [value]="0">
                                <i class="fa fa-fw fa-question"></i>
                                {{(('enums.simActivationStatus.0') | translate)}}
                            </ng-option>
                            <ng-option [value]="1">
                                <i class="fa fa-fw fa-bell-slash"></i>
                                {{(('enums.simActivationStatus.1') | translate)}}
                            </ng-option>
                            <ng-option [value]="2">
                                <i class="fa fa-fw fa-check"></i>
                                {{(('enums.simActivationStatus.2') | translate)}}
                            </ng-option>
                            <ng-option [value]="3">
                                <i class="fa fa-fw fa-snooze"></i>
                                {{(('enums.simActivationStatus.3') | translate)}}
                            </ng-option>
                            <ng-option [value]="4">
                                <i class="fa fa-fw fa-times"></i>
                                {{(('enums.simActivationStatus.4') | translate)}}
                            </ng-option>
                        </ng-select>
                    </span>
                    <span style="width: 50%; float: left; padding-left: 4px">
                        <fh-copyinput [hide]="formMode != 1">
                            <input
                                body
                                type="text"
                                [readonly]="formMode == 1"
                                name="simidentifier"
                                class="form-control table-inline"
                                placeholder="{{ 'placeholder.simIdentifier' | translate}}"
                                [(ngModel)]="device.simIdentifier "
                                #simIdentifier="ngModel"
                            />
                        </fh-copyinput>
                        <span class="input-group-calendar" *ngIf="formMode != 1" style="color: rgb(10, 138, 21)">
                            <i
                                class="fa"
                                [ngClass]="isValidIccid(device.simIdentifier) === true ? 'fa-check-circle' : '' "
                            ></i
                        ></span>
                    </span>
                </td>
            </tr>
            <tr [hidden]="!permissions['Platform_IsReseller']">
                <td class="row_opening">{{ 'general.msisdn' | translate}} / {{ 'general.imsi' | translate}}</td>
                <td class="row_content">
                    <span style="width: 50%; float: left">
                        <fh-copyinput [hide]="formMode != 1">
                            <input
                                body
                                type="text"
                                [readonly]="formMode == 1"
                                name="msisdn"
                                placeholder="{{ 'placeholder.msisdn' | translate}}"
                                class="form-control table-inline"
                                [(ngModel)]="device.msisdn"
                                #msisdn="ngModel"
                            />
                        </fh-copyinput>
                    </span>
                    <span style="width: 50%; float: left; padding-left: 4px">
                        <fh-copyinput [hide]="formMode != 1">
                            <input
                                body
                                type="text"
                                [readonly]="formMode == 1"
                                name="imsi"
                                class="form-control table-inline"
                                placeholder="{{ 'placeholder.imsi' | translate}}"
                                [(ngModel)]="device.imsi"
                                #imsi="ngModel"
                            />
                        </fh-copyinput>
                    </span>
                </td>
            </tr>
            <tr [hidden]="!permissions['Platform_IsReseller']" *ngIf="permissions['Entities_DeviceTypes']">
                <td class="row_opening">{{ 'general.devicetype' | translate}}</td>
                <td class="padding-readonly-correct row_content">
                    <a class="secondary link_bolder" href="/#/Devices/DeviceTypeDetails/Index/{{device.deviceTypeId}}"
                        >{{device.deviceTypeNameFull}}</a
                    >
                </td>
            </tr>
            <tr [hidden]="!permissions['Platform_IsReseller']">
                <td class="row_opening">{{ 'general.firmware' | translate}}</td>
                <td class="row_content">
                    <input
                        type="text"
                        [readonly]="true"
                        name="firmware"
                        class="form-control table-inline"
                        placeholder="{{ 'placeholder.firmware' | translate}}"
                        [(ngModel)]="device.firmware"
                    />
                </td>
            </tr>
        </tbody>
    </table>
</div>
