import {
  Component,
  AfterViewInit,
  Input,
  ElementRef,
  ViewChild,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from "@angular/core";

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { NgSelectComponent } from "@ng-select/ng-select";
import { DriverGroup } from "app/models/group.model";
import { AuthenticationService } from "app/services/authentication/authentication.service";
import { DriverGroupsService } from "app/services/driver/driverGroups.service";

const noop = () => {};

@Component({
  selector: "fh-driver-group-input",
  templateUrl: "./driverGroupSelector.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: DriverGroupInputComponent }],
})
export class DriverGroupInputComponent implements OnChanges, OnInit, AfterViewInit, ControlValueAccessor {
  @ViewChild("input", { static: false }) selector: NgSelectComponent;

  @Input() placeholder: string;
  @Input() clearable = true;
  @Input() selectedAccountId;
  @Input() autoSelect = true;
  @Input() readonly = false;
  @Input() multiple = true;
  @Input() tableInline;
  @Input() canAddNew = false;
  @Input() hideSystemGroups = false;

  @Output() onChanged = new EventEmitter();

  driverGroups = [];

  serverResponses = {};
  createdItems = [];

  loadingDriverGroups = false;

  @ViewChild("input") el: ElementRef;

  private _value: any[];
  private _onChange: (_: any) => void = noop;

  get value(): any {
    return this._value;
  }

  set value(v: any) {
    if (v !== this._value) {
      this._value = v;
      this._onChange(v);
      this.cd.markForCheck();
    }
  }

  constructor(
    private driverGroupService: DriverGroupsService,
    private authenticationService: AuthenticationService,
    private cd: ChangeDetectorRef
  ) {}

  createNew(displayName: string) {
    this.loadingDriverGroups = true;
    this.serverResponses[displayName] = false;

    this.cd.markForCheck();

    const itemId = this.createdItems.length;
    this.createdItems[itemId] = -1;

    const driverGroup = new DriverGroup();
    driverGroup.accountId = this.selectedAccountId;
    driverGroup.ownerId = this.authenticationService.getUserId();
    driverGroup.name = displayName;

    this.driverGroupService.saveDriverGroup(driverGroup).subscribe({
      next: (result) => {
        if (result?.isSuccess) {
          this.serverResponses[displayName] = false;
          this.createdItems[itemId] = result.id;
        }

        this.selector["_updateNgModel"]();
        this.loadingDriverGroups = false;
        this.cd.markForCheck();
      },
      error: (error) => {
        this.serverResponses[displayName] = error.error;

        this.selector["_updateNgModel"]();
        this.loadingDriverGroups = false;
        this.cd.markForCheck();
      },
    });

    const that = this;
    return {
      get id(): any {
        return that.createdItems[itemId];
      },
      itemCount: 0,
      displayName,
    };
  }

  driverGroupChanged() {
    this.onChanged.emit(this.value);
    this.cd.markForCheck();
  }

  ngOnChanges() {
    setTimeout(() => {
      // Get accounts again
      this.loadDriverGroups();
      this.cd.markForCheck();
    }, 0);
  }

  showAll(event, driverGroup) {
    driverGroup.showAll = !driverGroup.showAll;
    event.stopPropagation();
  }

  loadDriverGroups(callback = () => {}) {
    this.driverGroups = [];
    this.cd.markForCheck();

    if (this.selectedAccountId) {
      this.loadingDriverGroups = true;
      this.cd.markForCheck();

      if (this.selectedAccountId && this.selectedAccountId !== 0) {
        this.driverGroupService.getDriverGroups(this.selectedAccountId, true).subscribe((result) => {
          result = result.sort((a, b) => (a.name < b.name ? -1 : 1));

          this.driverGroups = result;

          if (this.hideSystemGroups) {
            this.driverGroups = this.driverGroups.filter((x) => x.name !== "MyManagedDrivers" && x.groupType !== 11);
          }

          // Autoselect
          if (!this.readonly && this.driverGroups.length === 1 && this.autoSelect) {
            console.log("Setting default to " + this.driverGroups[0].id);
            this.value = this.multiple ? [this.driverGroups[0].id] : this.driverGroups[0].id;
          }

          this.driverGroups = this.driverGroups.sort((a, b) => {
            return a.displayName.localeCompare(b.displayName, "en", { sensitivity: "base" });
          });

          this.loadingDriverGroups = false;
          this.cd.markForCheck();

          callback();
        });
      } else {
        this.loadingDriverGroups = false;
        this.cd.markForCheck();
      }
    }
  }

  ngOnInit() {}

  ngAfterViewInit() {
    const __this = this;
  }

  writeValue(value: any) {
    this._value = value;
    this.cd.markForCheck();
  }

  registerOnChange(fn: (value: any) => void) {
    this._onChange = fn;
    this.cd.markForCheck();
  }

  registerOnTouched(fn: any) {
    this.cd.markForCheck();
  }
}
