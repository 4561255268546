<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/"> <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Warehouses/Overview">{{'general.warehouses' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/WarehouseDetails/Index/{{warehouse?.id}}">{{warehouse?.name}}</a>
                </li>
                <li class="breadcrumb-item active">{{'menu.sensors' | translate }}</li>
            </ol>
        </nav>
    </div>
    <fh-warehouse-details-navigation [warehouseId]="warehouseId"></fh-warehouse-details-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">
        <div class="row" data-equalizer="">
            <div class="col-lg-12">
                <fh-error [error]="error" [success]="success"></fh-error>

                <div class="ibox" style="position: relative">
                    <div class="ibox-title">
                        <h5><i class="fa fa-border-none"></i> {{'general.zones' | translate }}</h5>
                    </div>
                    <div class="ibox-content slick" style="position: relative; overflow-y: auto">
                        <ngx-loading [show]="loading || loadingZones || saving"></ngx-loading>

                        <table style="width: 100%" class="table nowrap dtr-inline no-footer">
                            <tbody *ngIf="this.zones.length == 0">
                                <tr>
                                    <td colspan="4">{{ 'general.noSensorData' | translate}}</td>
                                </tr>
                            </tbody>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>{{ 'general.name' | translate}}</th>
                                    <th>{{ 'general.type' | translate}}</th>
                                    <th>{{ 'general.waslKey' | translate}}</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="this.zones.length > 0">
                                <tr *ngFor="let zone of zones | orderBy: 'name'; let i = index">
                                    <td style="width: 0px">
                                        <a
                                            class="btn btn-primary btn-grid"
                                            title="Details"
                                            href="/#/WarehouseZoneDetails/Index/{{warehouse.id}}?zoneId={{zone.rowKey}}"
                                            ><span class="d-none d-md-inline-flex" style="padding-left: 7px"
                                                >{{'general.details' | translate }} </span
                                            ><i class="fas fa-fw fa-angle-right"></i
                                        ></a>
                                    </td>
                                    <td>{{ zone.name}}</td>
                                    <td>{{ zone.type}}</td>
                                    <td>{{ zone.identifier}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="ibox-footer" style="position: relative">
                        <button class="btn btn-primary" (click)="showModal()">
                            <i class="fa fa-fw fa-plus"></i> {{'general.add' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</fh-permission-handler>

<!-- Adding a zone -->
<div
    (onHidden)="hideModal()"
    [config]="{ backdrop: true, ignoreBackdropClick: true }"
    bsModal
    #addModal="bs-modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
>
    <div class="modal-dialog">
        <form #zoneForm="ngForm">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title pull-left">
                        <i class="fa fa-border-none"></i> {{'general.zone' | translate }}
                    </h5>
                    <button type="button" class="close float-right" aria-label="Close" (click)="hideModal()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="popup-content" style="position: relative">
                    <fh-warehouse-zone-details [zone]="zone" [formMode]="formMode" [loading]="loading">
                    </fh-warehouse-zone-details>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" (click)="confirm()" [disabled]="zoneForm.invalid">
                        <i class="fa fa-fw fa-save"></i> {{'general.finish' | translate }}
                    </button>
                    <button type="button" class="btn btn-info" (click)="hideModal()">
                        <i class="fa fa-fw fa-times"></i> {{'general.cancel' | translate }}
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
