import { ChangeDetectorRef, Component, OnDestroy, OnInit, ChangeDetectionStrategy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import {
  getGridButtons,
  getGridLanguages,
  setSelection,
  setTableStars,
  setSelectionRows,
  createdCellCheckbox,
} from "app/common/gridhelper";
import { DeviceType } from "app/models/devicetype.model";

import { DeviceTypeService } from "app/services/devicetypes/devicetypes.service";

import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { forkJoin } from "rxjs";
import { AuthenticationService } from "../../services/authentication/authentication.service";
import { TranslatorService } from "../../services/common/translator.service";
import { BatchStatus, EntityType } from "app/common/enums";
import { GridBase360Directive } from "app/common/360Grid.base";

// Moment timezone
import Moment from "moment-timezone";
import { DriverService } from "app/services/driver/driver.service";
import { StorageHelper } from "app/common/storagehelper";
import { ColorService } from "app/services/common/color.service";

window["moment"] = Moment;

@Component({
  selector: "fh-drivers-external",
  templateUrl: "external.template.html",
  changeDetection: ChangeDetectionStrategy.Default,
})
export class DriversExternalViewComponent extends GridBase360Directive implements OnInit, OnDestroy {
  token: string;
  countrySelect = false;

  excludingColumns = ["timeStamp", "assetDateStart"];
  loading = false;

  deviceTypes: DeviceType[];

  dropdown_CustomerName = "";
  languageLoaded: boolean;
  timezoneIana: string;
  dropdown_CustomerNameSettings: any;
  selectedCustomers = [];

  dropdownSettingsDeviceTypes: any;

  permissionName = "Drivers_View";
  constructorName = "DriversExternalViewComponent";

  error: any;
  success: any;

  enableSelecting = true;

  gridSelection = [];
  showSelection = false;

  entityTypes = EntityType.DeviceExternal;
  isAllSelected = false;

  constructor(
    private localeService: BsLocaleService,
    private route: ActivatedRoute,
    private translatorService: TranslatorService,
    private translateService: TranslateService,
    private cd: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private driverService: DriverService,
    private deviceTypeService: DeviceTypeService,
    protected storageHelper: StorageHelper,
    private colorService: ColorService
  ) {
    super(storageHelper);

    const that = this;
    this.loading = true;

    this.token = this.authenticationService.getAuthToken();
    this.timezoneIana = this.authenticationService.getTimeZoneIana();

    // Get all the date for dropdown boxes
    forkJoin([this.translateService.get("general.date"), this.deviceTypeService.getDeviceTypes()]).subscribe(
      (data) => {
        this.languageLoaded = true;
        this.loading = false;
        this.cd.markForCheck();

        this.deviceTypes = data[1].filter((x) => x.deviceCount > 0);
        this.deviceTypes = this.deviceTypes.sort((a, b) => (a.modelName > b.modelName ? 1 : -1));

        this.initGrid();
      },
      (err) => {
        this.error = err;
        this.languageLoaded = true;
        this.loading = false;
        this.cd.markForCheck();
      }
    );
  }

  ngOnInit(): void {}

  // Check custom filters from grid save
  checkFilters() {
    this.fireFilter(1);
  }

  // Check route to apply filters
  checkRoute() {}

  // Fire custom filters to update grid and call server again
  fireFilter(event): void {
    if (event != null) {
      console.log("Fire update");
      this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
        // Fire the request
        dtInstance.draw();
      });
    }
  }

  initGrid(): void {
    const that = this;

    $.fn["dataTable"].ext.search.pop();

    this.loading = true;

    this.columns = [
      {
        name: "isSelected",
        data: "id",
        className: "noVis",
        title: '<div class="hideDropdown" id="selectorHeader"></div>',
        orderable: false,
        width: "20",
        render: function (data, type, row) {
          if (!that.processing.tableIdsSelection) {
            that.processing.tableIdsSelection = [];
          }
          if (that.processing.tableIdsSelection.indexOf(row.id.toString()) === -1) {
            that.processing.tableIdsSelection.push(row.id.toString());
          }

          if (!that.processing.tableIds) {
            that.processing.tableIds = [];
          }
          if (that.processing.tableIds.indexOf(row.id.toString()) === -1) {
            that.processing.tableIds.push(row.id.toString());
          }

          const isSelected = that.processing.gridSelection.indexOf(data.toString()) !== -1;
          return `<i id="selection_${row.id}" class="far fa-fw fa-lg ${
            isSelected ? "fa-check-square" : "fa-square"
          }" style="cursor: pointer;"></i>`;
        },
      },
      {
        name: "id",
        data: "id",
        className: "noVis",
        orderable: false,
        title: '<div class="hideDropdown"></div>',
        width: "20",
        render: function (data, type, row) {
          return `<a class='btn btn-primary btn-grid' title='${that.translateService.instant(
            "general.details"
          )}' href='/#/DriverDetails/External/${data}'><span class="d-none d-md-inline-flex" style="padding-left: 7px">${that.translateService.instant(
            "general.details"
          )} </span><i class='fas fa-fw fa-angle-right'></i></a>`;
        },
      },
      {
        name: "id_export",
        data: "id",
        className: "noVis",
        title: this.translateService.instant("general.id"),
        visible: false,
      },
      {
        name: "name",
        data: "name",
        defaultContent: "-",
        title: this.translateService.instant("general.name"),
      },
      {
        name: "erpCode",
        data: "erpCode",
        defaultContent: "-",
        visible: true,
        title: this.translateService.instant("general.erpCode"),
      },
      {
        name: "companyName",
        data: "companyName",
        defaultContent: "-",
        title: this.translateService.instant("general.companyName"),
      },
      {
        name: "referenceKey",
        data: "properties.wasl.referenceKey",
        defaultContent: "-",
        title: this.translateService.instant("general.waslKey"),
        visible: false,
      },
      {
        name: "registerDateWasl",
        data: "hasRegisterDateWasl",
        defaultContent: "-",
        title: this.translateService.instant("general.wasl"),
        visible: true,
        searchable: true,
        orderable: true,
        type: "checkBox",
        createdCell: createdCellCheckbox,
        render: function (data, type, row) {
          return data ? true : false;
        },
      },
      {
        name: "driverSequenceNumber",
        data: "properties.wasl.sequenceNumber",
        defaultContent: "-",
        title: this.translateService.instant("general.driverSequenceNumber"),
        visible: true,
      },
      {
        name: "accountIdentityNumber",
        data: "accountProperties.wasl.identityNumber",
        defaultContent: "-",
        title: this.translateService.instant("general.accountIdentityNumber"),
        visible: true,
      },
      {
        name: "referenceKey",
        data: "properties.wasl.referenceKey",
        defaultContent: "-",
        title: this.translateService.instant("general.reference"),
        visible: true,
      },
      {
        name: "dateOfBirthGregorian",
        data: "properties.wasl.dateOfBirthGregorian",
        defaultContent: "-",
        title: this.translateService.instant("general.dateOfBirthGregorian"),
        visible: true,
      },
      {
        name: "identityNumber",
        data: "properties.wasl.identityNumber",
        defaultContent: "-",
        title: this.translateService.instant("general.identityNumber"),
        visible: true,
      },
      {
        name: "mobileNumber",
        data: "properties.wasl.mobileNumber",
        defaultContent: "-",
        title: this.translateService.instant("general.mobileNumber"),
        visible: true,
      },
      {
        name: "timeStamp",
        data: "timestamp",
        title: this.translateService.instant("general.timeStamp"),
        render: function (data, type, row) {
          const date = Moment.utc(data)["tz"](that.timezoneIana);
          return data ? `<span title=" ${date.toLocaleString()}">${date.format("lll")}</span>` : "";
        },
        visible: false,
      },
      {
        name: "resellerDescription",
        data: "resellerDescription",
        defaultContent: "-",
        title: this.translateService.instant("general.resellerDescription"),
        visible: false,
      },
    ];

    this.dtOptions = {
      buttons: getGridButtons(
        this.commonExportOptions,
        "drivers_external_overview",
        this.translateService.instant("menu.deviceoverview"),
        this.colorService
      ),
      pagingType: "simple_numbers",
      serverSide: true,
      processing: true,
      searchDelay: 2000,
      deferRender: true,
      scrollX: true,
      colReorder: { fixedColumnsLeft: 1 },
      deferLoading: 0,
      stateSave: true,
      stateSaveCallback: function (settings, data) {
        that.saveState(that.constructorName, data);
      },
      stateLoadCallback: function (_, callback) {
        (async () => {
          try {
            const columnSettings = await that.loadState(that.constructorName);
            that.searchTerm = columnSettings && columnSettings.search && columnSettings.search.search;
            return columnSettings;
          } catch (e) {
            that.error = {};
            that.error.error = e;
            that.error.statusText = "Error fetching column settings";

            return null;
          }
        })().then((result) => {
          callback(result);
        });
      },
      order: [[3, "asc"]],
      ajax: {
        beforeSend: () => {
          that.drawFilterRow();

          $(".dataTables_info").html(this.translateService.instant("grid.loadingData"));
        },
        url: that.driverService.getExternalPagingUrl(),
        data: (d) => {
          return d;
        },
        dataSrc: function (json) {
          // if selection and returning keys
          if (that.processing.tableIdsSelection && that.processing.tableIdsSelection.length === 0) {
            that.processing.tableIdsSelection = json.keys;
          }
          return json.data;
        },
        error: (jqXHR, ajaxOptions, thrownError) => {
          that.loading = false;
          that.error = thrownError;
          that.cd.markForCheck();
        },
        type: "POST",
        headers: {
          Authorization: "Bearer " + that.token,
        },
      },
      initComplete: function (settings, json) {
        that.checkRoute();
        that.checkFilters();
        that.setEvents();
        that.drawFilterRow();
        $("#grid_select_or").appendTo($("th > #selectorHeader"));
        that.loading = false;
        that.cd.markForCheck();
      },
      colVis: {
        restore: this.translateService.instant("general.restore"),
        showAll: this.translateService.instant("general.showAll"),
        showNone: this.translateService.instant("general.hideAll"),
        exclude: ["id", "id_export", "isSelected"],
      },
      columns: this.columns,
      pageLength: 17,
      lengthMenu: [
        [10, 17, 25, 50, -1],
        [10, 17, 25, 50, this.translateService.instant("general.all")],
      ],
      language: getGridLanguages(this.translateService),
      drawCallback: (data) => {
        setTableStars(that.processing.tableIds);
        setSelection(that.processing.tableIds, that.processing.gridSelection);
      },
      rowCallback: (row, data) => {
        setSelectionRows(that, row, data, that.processing.gridSelection);
      },
    };
  }
  // batch processing
  onClose(event) {
    console.log("Closing batch popup");

    this.success = null;
    this.error = null;

    if (event && event.status === BatchStatus.Success) {
      console.log("success");

      this.success = {};
      this.success.statusText = "Successfully posted batch";
      this.success.success = "Your batch was accepted and scheduled to be proccesed";
      this.success.unique = Math.floor(Math.random() * (999999 - 100000)) + 100000;
      this.cd.markForCheck();
    }
    if (event && event.status === BatchStatus.Error) {
      this.error = {};
      this.error.statusText = "Error";
      this.error.error = "Error in processing the batch";
      this.error.unique = Math.floor(Math.random() * (999999 - 100000)) + 100000;
      this.cd.markForCheck();
    }
  }
}
