import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { DeviceSensor, DeviceSensorType, SensorDevice, SensorUser } from "app/models/sensor.model";
import { throwError } from "rxjs/internal/observable/throwError";
import { Observable, catchError, map } from "rxjs";
import { AuthenticationService } from "../authentication/authentication.service";
import { TranslatorService } from "../common/translator.service";
import { LoggingService } from "../logging/logging.service";

@Injectable()
export class DeviceSensorsService {
  token: string;
  timestamp;
  url = "";

  constructor(
    private http: HttpClient,
    private loggingService: LoggingService,
    private translatorService: TranslatorService,
    private authenticationService: AuthenticationService
  ) {
    this.url = authenticationService.getWebserviceURL("deviceSensor");
    this.token = authenticationService.getAuthToken();
  }

  getSensors(): Observable<DeviceSensor[]> {
    this.token = this.authenticationService.getAuthToken();
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + this.token,
    });

    console.log("getting sensors from service");
    return this.http.get(this.url, { headers: headers }).pipe(
      map((data) => {
        const parsedResponse = this.parseResponse(data);
        return parsedResponse;
      }),
      catchError(this.handleError)
    );
  }

  getSensorUsers(
    name: string,
    parameter: string,
    deviceSensorTypeId: number,
    isCustomDeviceSensorTypeId: boolean
  ): Observable<SensorUser[]> {
    this.token = this.authenticationService.getAuthToken();
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + this.token,
    });

    console.log("getting sensor users from service");
    return this.http
      .get(
        this.url +
          "SensorUsers?name=" +
          name +
          "&parameter=" +
          (parameter ? parameter : "") +
          "&deviceSensorTypeId=" +
          deviceSensorTypeId +
          "&isCustomDeviceSensorTypeId=" +
          isCustomDeviceSensorTypeId,
        { headers: headers }
      )
      .pipe(
        map((data: any) => {
          const sensorUsers: SensorUser[] = [];
          data.forEach((item) => {
            const sensorUser = new SensorUser();
            sensorUser.customerId = item.customerId;
            sensorUser.customerName = item.customerName;
            sensorUser.manufacturer = item.manufacturer;
            sensorUser.deviceType = item.deviceType;
            sensorUser.deviceTypeId = item.deviceTypeId;
            sensorUser.sensorCount = item.sensorCount;
            sensorUsers.push(sensorUser);
          });
          return sensorUsers;
        }),
        catchError(this.handleError)
      );
  }

  getSensorDevices(
    name: string,
    parameter: string,
    deviceSensorTypeId: number,
    isCustomDeviceSensorTypeId: boolean,
    deviceTypeId: string,
    manufacturer: string,
    customerId: string
  ): Observable<SensorDevice[]> {
    this.token = this.authenticationService.getAuthToken();
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + this.token,
    });

    console.log("getting sensor devices from service");
    return this.http
      .get(
        this.url +
          "SensorDevices?name=" +
          name +
          "&parameter=" +
          (parameter ? parameter : "") +
          "&deviceSensorTypeId=" +
          deviceSensorTypeId +
          "&isCustomDeviceSensorTypeId=" +
          isCustomDeviceSensorTypeId +
          "&deviceTypeId=" +
          deviceTypeId +
          "&manufacturer=" +
          manufacturer +
          "&customerId=" +
          customerId,
        { headers: headers }
      )
      .pipe(
        map((data: any) => {
          const sensorDevices: SensorDevice[] = [];
          data.forEach((item) => {
            const sensorDevice = new SensorDevice();
            sensorDevice.sensorName = item.sensorName;
            sensorDevice.parameter = item.param;
            sensorDevice.id = item.id;
            sensorDevice.deviceName = item.deviceName;
            sensorDevice.unitId = item.unitId;
            sensorDevices.push(sensorDevice);
          });
          return sensorDevices;
        }),
        catchError(this.handleError)
      );
  }

  getDeviceSensorTypes(): Observable<DeviceSensorType[]> {
    this.token = this.authenticationService.getAuthToken();
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + this.token,
    });

    console.log("getting schedule from service");
    return this.http.get(this.url + "DeviceSensorTypes", { headers: headers }).pipe(
      map((data: any) => {
        const sensorTypes: DeviceSensorType[] = [];
        data.forEach((item) => {
          const sensorType = new DeviceSensorType();
          sensorType.id = item.id;
          sensorType.name = item.name;
          sensorType.category = item.category;
          sensorTypes.push(sensorType);
        });
        return sensorTypes;
      }),
      catchError(this.handleError)
    );
  }

  saveSensorSettings(sensors: DeviceSensor[]): Observable<any> {
    this.token = this.authenticationService.getAuthToken();
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + this.token,
    });

    console.log("Saving sensor settings");

    return this.http
      .put(this.url + "SaveDeviceSensors", sensors, { headers: headers })
      .pipe(catchError(this.handleError));
  }

  private handleError(error: Response) {
    return throwError(error);
  }

  parseResponse(json: any): DeviceSensor[] {
    this.loggingService.log(this.constructor.name, "Retrieved " + json.length + " sensortypes.");

    const ident = 1;
    const sensors: DeviceSensor[] = [];

    // this.loggingService.log(json);
    json.forEach((item) => {
      //   this.loggingService.log(this.constructor().name + " - New", item);
      const sensor = this.parseReponseDetails(item);
      sensors.push(sensor);
    });

    this.timestamp = new Date().getTime();
    return sensors;
  }

  parseReponseDetails(item): DeviceSensor {
    const sensor = new DeviceSensor();
    sensor.name = item.name;
    sensor.type = item.type;
    sensor.metrics = item.metrics;
    sensor.parameter = item.parameter;
    sensor.deviceSensorTypeId = item.deviceSensorTypeId;
    sensor.deviceSensorType = this.translatorService.translate(item.deviceSensorTypeId, "enums.sensorTypes");
    sensor.isCustomDeviceSensorTypeId = item.isCustomDeviceSensorTypeId;
    sensor.sensorCount = item.sensorCount;

    return sensor;
  }
}
