<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/"> <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item" *ngIf="device?.asset && device?.asset.accountId">
                    <a href="/#/AccountDetails/Index/{{device?.asset.accountId}}">{{device?.asset?.companyName}}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Devices/Overview?companyName={{device?.companyName}}"
                        >{{'general.devices' | translate }}</a
                    >
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/DeviceDetails/Index/{{device?.id}}"
                        >{{device?.asset?.name ? device?.asset?.name : device?.name}}</a
                    >
                </li>
                <li class="breadcrumb-item active">{{'general.drivers' | translate }}</li>
            </ol>
        </nav>
    </div>
    <fh-device-details-navigation [deviceId]="deviceId" [device]="device">
        <li *ngIf="device?.id && device?.asset?.id && permissions['Drivers_Assign']">
            <a (click)="driverChooser.showModal()">
                <i class="fas fa-fw fa-id-card-alt"></i>
                <div>{{'general.assignDriver' | translate }}</div>
            </a>
        </li>
    </fh-device-details-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">
        <div class="row" data-equalizer="">
            <div class="col-lg-12">
                <fh-error [error]="error" [warning]="warning" [success]="success"></fh-error>

                <div class="ibox">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-id-card-alt"></i> {{'general.assignments' | translate }}</h5>
                        <span class="float-right">
                            <div class="filter-form d-none d-sm-block">
                                <input
                                    type="text"
                                    class="form-control table-inline top-filter"
                                    (ngModelChange)="p.setCurrent(0)"
                                    [(ngModel)]="filter"
                                />
                            </div>
                        </span>
                    </div>
                    <div class="ibox-content slick" style="position: relative; overflow-y: hidden">
                        <ngx-loading [show]="loading"></ngx-loading>

                        <table style="width: 100%" class="table nowrap dtr-inline no-footer">
                            <thead>
                                <tr>
                                    <th>{{'general.driver' | translate }}</th>
                                    <th>{{'general.asset' | translate }}</th>
                                    <th>{{'general.source' | translate }}</th>
                                    <th>{{'general.start' | translate }}</th>
                                    <th>{{'general.end' | translate }}</th>
                                    <ng-container *ngIf="permissions['Drivers_Assign']">
                                        <th style="width: 60px" scope="col">&nbsp;</th>
                                    </ng-container>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngIf="allDriverAssignments?.length === 0 && !loading">
                                    <td colspan="5">{{'general.nodatafound' | translate }}</td>
                                </tr>
                                <tr
                                    *ngFor="let driverAssignment of allDriverAssignments | orderBy : ['dateEnd','dateStart']:true | stringFilter: filter | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, id: 'first'}; "
                                >
                                    <td>
                                        <a
                                            class="secondary link_bolder"
                                            href="/#/DriverDetails/Index/{{driverAssignment?.driverId}}"
                                        >
                                            {{ driverAssignment.driverName }}</a
                                        >
                                    </td>
                                    <td>{{ driverAssignment.assetName }}</td>
                                    <td>{{ driverAssignment.sourceFormatted }}</td>
                                    <td>{{ driverAssignment.dateStart | amDateFormat: 'LLL' }}</td>
                                    <td>
                                        {{ driverAssignment.dateEnd ? (driverAssignment.dateEnd | amDateFormat: 'LLL') :
                                        '-' }}
                                    </td>
                                    <ng-container *ngIf="permissions['Drivers_Assign']">
                                        <td [ngSwitch]="true" style="width: 60px; text-align: right">
                                            <ng-container *ngSwitchCase="(driverAssignment.dateEnd ?? null) === null">
                                                <a
                                                    class="btn btn-primary btn-grid"
                                                    (click)="showEndAssignment(driverAssignment)"
                                                    title="{{'general.endAssignment' | translate }}"
                                                >
                                                    <i class="fas fa-fw fa-hand-paper"></i>
                                                </a>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="(driverAssignment.dateEnd ?? null) !== null">
                                                <a
                                                    class="btn btn-danger btn-grid"
                                                    (click)="showDelete(driverAssignment)"
                                                    title="{{'general.delete' | translate }}"
                                                >
                                                    <i class="fas fa-fw fa-trash-alt"></i>
                                                </a>
                                            </ng-container>
                                            <ng-container *ngSwitchDefault> &nbsp; </ng-container>
                                        </td>
                                    </ng-container>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="ibox-footer noselect dataTables_wrapper">
                        <pagination-template #p="paginationApi" (pageChange)="currentPage = $event" id="first">
                            <div class="dataTables_paginate paging_simple_numbers" id="dataTable_paginate">
                                <ul class="pagination">
                                    <li class="paginate_button page-item previous" [class.disabled]="p.isFirstPage()">
                                        <a class="page-link" (click)="p.previous()" *ngIf="!p.isFirstPage()"
                                            >{{ 'grid.previous' | translate }}</a
                                        >
                                        <a *ngIf="p.isFirstPage()">{{ 'grid.previous' | translate }}</a>
                                    </li>

                                    <li
                                        class="paginate_button page-item"
                                        *ngFor="let page of p.pages"
                                        [class.current]="p.getCurrent() === page.value"
                                    >
                                        <a
                                            class="page-link"
                                            (click)="p.setCurrent(page.value)"
                                            *ngIf="p.getCurrent() !== page.value"
                                        >
                                            <span>{{ page.label }}</span>
                                        </a>
                                        <a
                                            class="page-link"
                                            (click)="p.setCurrent(page.value)"
                                            *ngIf="p.getCurrent() === page.value"
                                        >
                                            <span style="font-weight: bold">{{ page.label }}</span>
                                        </a>
                                    </li>

                                    <li class="paginate_button page-item next" [class.disabled]="p.isLastPage()">
                                        <a class="page-link" (click)="p.next()" *ngIf="!p.isLastPage()">
                                            {{ 'grid.next' | translate }}
                                        </a>
                                        <a class="page-link" *ngIf="p.isLastPage()"> {{ 'grid.next' | translate }} </a>
                                    </li>
                                </ul>
                            </div>
                        </pagination-template>
                        <div style="clear: both"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</fh-permission-handler>

<fh-driver-chooser
    *ngIf="device?.id && device?.asset?.id"
    [device]="device"
    #driverChooser
    (onSelect)="onSelect($event)"
>
</fh-driver-chooser>

<fh-confirmation-modal #deleteModal (onFinish)="deleteAssignment($event)"> </fh-confirmation-modal>

<fh-confirmation-modal #assignmentModal (onFinish)="endAssignment($event)">
    <fh-archiving-modal #assignmentBody [driver]="selectedDriverAssignment" body></fh-archiving-modal>
</fh-confirmation-modal>
