import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from "app/services/authentication/authentication.service";

@Component({
  selector: "fh-reporting-navigation",
  templateUrl: "reportingNavigation.template.html",
})
export class ReportingNavigationComponent implements OnInit {
  sub: any;
  permissions: {};
  activeId;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    this.permissions = this.authenticationService.permissions;
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe((params) => {
      const id = params["id"];
      this.activeId = id;
    });
  }

  activeRoute(routename: string): boolean {
    const active = this.router.url.indexOf(routename) !== -1;
    return active;
  }

  isSubscriptionDetails() {
    const routes = ["Log", "ReportSubscriptionDetails"];

    let routeActive = false;

    routes.forEach((route) => {
      const active = this.router.url.indexOf(route);
      if (active > -1) {
        routeActive = true;
      }
    });

    return routeActive;
  }
}
