<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/"> <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Devices/Overview">{{'general.devices' | translate }}</a>
                </li>
                <li class="breadcrumb-item active">
                    <a href="/#/Devices/Overview">{{'menu.trips' | translate }}</a>
                </li>
            </ol>
        </nav>

        <fh-filter-toggler
            style="margin-left: auto"
            [constructorName]="constructorName"
            (filterChanged)="showFilter = $event"
            (presetsChanged)="showPresets = $event"
        ></fh-filter-toggler>
    </div>
    <fh-device-navigation></fh-device-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">
        <div class="row">
            <div class="col-lg-12">
                <fh-error [error]="error" [success]="success" [warning]="warning"></fh-error>
                <!-- Custom filters -->
                <div class="ibox" [hidden]="!showFilter">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-filter"></i> {{'general.filter' | translate }}</h5>
                        <span class="float-right hand btn btn-info filter-margin-fix" (click)="clearAllFilters();">
                            <i class="fa fa-fw fa-times"></i>
                            <span class="d-none d-md-inline-flex"> {{'general.clearFilters' | translate }}</span>
                        </span>
                    </div>
                    <div class="ibox-content slick_toppadding flex-container" style="position: relative">
                        <div class="col-lg-4">
                            <div class="form-group" style="margin-bottom: 6px">
                                <fh-reseller-input
                                    name="reseller"
                                    #resellerVar="ngModel"
                                    (onChanged)="resellerChanged($event)"
                                    required
                                    [tableInline]="false"
                                    placeholder="{{ 'general.chooseReseller' | translate}}"
                                    [(ngModel)]="selectedResellerId"
                                >
                                </fh-reseller-input>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group" style="margin-bottom: 6px">
                                <fh-account-input
                                    name="account"
                                    #accountVar="ngModel"
                                    (onChanged)="accountChanged($event)"
                                    required
                                    [activeOnly]="true"
                                    [selectedResellerId]="selectedResellerId"
                                    [tableInline]="false"
                                    [showEmptyAccountsButton]="false"
                                    placeholder="{{ (selectedResellerId ? 'general.chooseAccount' : 'general.firstChooseReseller') | translate}}"
                                    [(ngModel)]="selectedAccountId"
                                >
                                </fh-account-input>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group input-group" style="margin-bottom: 5px">
                                <span class="input-group-prepend">
                                    <i class="fa fa-fw fa-calendar"></i>
                                </span>
                                <input
                                    placeholder="Daterangepicker"
                                    style="width: 100%"
                                    placeholder="Datepicker"
                                    type="text"
                                    [bsConfig]="dpConfig"
                                    class="form-control"
                                    bsDaterangepicker
                                    (onHidden)="dateChanged('hidden')"
                                    [(ngModel)]="daterangepickerModel"
                                />
                                <span class="input-group-calendar-datatables">
                                    <i class="fa fa-calendar-alt"></i>
                                </span>
                                <div style="clear: both"></div>
                            </div>
                        </div>
                    </div>
                    <div class="ibox-content slick_toppadding" style="position: relative">
                        <ngx-loading [show]="loading"></ngx-loading>
                        <div id="dynamicFilters" class="flex-container"></div>
                        <div style="clear: both"></div>
                    </div>
                    <div class="ibox-footer">
                        <button
                            *ngIf="!loading"
                            type="button"
                            name="search"
                            class="btn btn-primary"
                            (click)="dateChanged('hidden')"
                            [disabled]="selectedAccountId == null"
                        >
                            {{ 'general.fetchData' | translate}}
                        </button>

                        <button *ngIf="loading" type="button" name="cancel" class="btn btn-info" (click)="cancel()">
                            <i class="fas fa-fw fa-times"></i> {{ 'general.cancel' | translate}}
                        </button>
                    </div>
                </div>
                <!-- End default dynamic filters -->

                <div class="ibox">
                    <div class="ibox-title">
                        <h5><i class="fa fa-fw fa-route"></i> {{'general.trips' | translate }}</h5>
                        <iboxseach
                            [searchTerm]="searchTerm"
                            (seachChanged)="seachChanged($event)"
                            [constructorName]="
                            constructorName"
                            (filterChanged)="showFilter = $event"
                            (presetLoaded)="onPresetLoaded($event)"
                            [showColumns]=" true"
                            (columnsChanged)="drawFilterRow()"
                        >
                        </iboxseach>
                    </div>
                    <div class="ibox-content slick" style="position: relative" *ngIf="languageLoaded">
                        <table
                            style="width: 100%; min-height: 50px"
                            fh-datatable
                            [dtOptions]="dtOptions"
                            [dtTrigger]="dtTrigger"
                            class="table nowrap margin-bottom-0 dataTable dtr-inline"
                        ></table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</fh-permission-handler>
