import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { FormMode } from "app/common/enums";
import { AccountInventory } from "app/models/account.model";
import { AuthenticationService } from "../../services/authentication/authentication.service";
import { AccountService } from "app/services/account/account.service";
import { AppUser } from "app/models/user.model";
import { sha256 } from "js-sha256";
import { TranslateService } from "@ngx-translate/core";
import { generatePassword } from "app/common/globals";

@Component({
  selector: "fh-account-add",
  templateUrl: "add.template.html",
})
export class AccountsAddViewComponent {
  loading: boolean = false;
  account = new AccountInventory();
  user: AppUser = new AppUser();

  formMode: FormMode = FormMode.add;
  error: any;
  permissions: {};
  success: { statusText: string; success: string };

  permissionName = "Accounts_Add";

  constructor(
    private translateServe: TranslateService,
    private accountService: AccountService,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {
    this.permissions = this.authenticationService.permissions;

    // Set new lang to current
    this.account.language = this.authenticationService.getServerCulture();
    this.user.language = this.authenticationService.getServerCulture();
    this.user.userType = +this.authenticationService.getUserType();

    this.user.password = generatePassword(8);
  }

  onInsert() {
    this.loading = true;

    const userVar = { ...this.user };
    userVar.password = sha256(this.user.password);

    this.accountService.saveAccount(this.account, userVar).subscribe({
      next: (result) => {
        this.success = {
          statusText: "Success",
          success: this.translateServe.instant("general.saveSuccessRedirect", { entity: "account" }),
        };

        setTimeout(() => {
          this.router.navigate(["/AccountDetails/Index/" + result.id]);
        }, 2000);

        this.loading = false;
      },
      error: (error) => {
        this.error = error;
        this.loading = false;
      },
    });
  }
}
