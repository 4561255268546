<div class="ibox-content slick">
    <table style="width: 100%" class="table table-fixed nowrap dtr-inline no-footer">
        <thead>
            <tr>
                <ng-container *ngIf="trigger.rules?.length == 0; else showHeader">
                    <td class="row_opening">{{ "general.addTriggerRule" | translate }}</td>
                </ng-container>
                <ng-template #showHeader>
                    <td class="row_opening">{{ "general.triggerRule" | translate }}</td>
                    <td class="row_content">{{ "general.value" | translate }}</td>
                    <!-- <td class="row_opening">{{'general.duration' | translate }}</td> -->
                    <td *ngIf="formMode != 1" style="width: 40px"></td>
                </ng-template>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let rule of trigger.rules; let indexOfRule = index">
                <td class="row_opening">{{ "enums.triggerRuleType." + rule.ruleType | translate }}</td>
                <td class="row_content" style="white-space: pre-line">
                    <ng-container *ngIf="rule.ruleType == 2">
                        {{ "enums.deviceStateProperties." + rule.deviceState | translate }} |
                        {{
                            rule.ioState !== undefined
                                ? ("general.condition" | translate | lowercase) + "=" + rule.ioState
                                : ""
                        }}
                    </ng-container>
                    <ng-container *ngIf="rule.ruleType == 3 && rule.insideOutsideSettings == 1">
                        <ng-container *ngIf="rule.allGeofences; else selectedGeofences">
                            {{ "general.inside" | translate }} ({{ "general.all" | translate }})
                        </ng-container>
                        <ng-template #selectedGeofences>
                            {{ "general.inside" | translate }} ({{ rule.geofenceDescription }})
                        </ng-template>
                    </ng-container>
                    <ng-container *ngIf="rule.ruleType == 3 && rule.insideOutsideSettings == 2">
                        <ng-container *ngIf="rule.allGeofences; else selectedGeofences">
                            {{ "general.outside" | translate }} ({{ "general.all" | translate }})
                        </ng-container>
                        <ng-template #selectedGeofences>
                            {{ "general.outside" | translate }} ({{ rule.geofenceDescription }})
                        </ng-template>
                    </ng-container>
                    <ng-container *ngIf="rule.ruleType == 4">
                        {{ "enums.ruleIOPort." + rule.ioPort | translate }} |
                        {{
                            rule.ioState !== undefined
                                ? ("general.condition" | translate | lowercase) + "=" + rule.ioState
                                : ""
                        }}
                    </ng-container>
                    <ng-container *ngIf="rule.ruleType == 5">
                        {{ "enums.locationEventType." + rule.eventType | translate }} |
                        {{
                            rule.ioState !== undefined
                                ? ("general.condition" | translate | lowercase) + "=" + rule.ioState
                                : ""
                        }}
                    </ng-container>
                    <ng-container *ngIf="rule.ruleType == 6">
                        {{ "enums.locationEventType." + rule.eventType | translate }}
                    </ng-container>
                    <ng-container *ngIf="rule.ruleType == 7 && rule.insideOutsideSettings == 1">
                        {{ "general.inside" | translate }} ({{ triggerScheduleNames[indexOfRule] }})
                    </ng-container>
                    <ng-container *ngIf="rule.ruleType == 7 && rule.insideOutsideSettings == 2">
                        {{ "general.outside" | translate }} ({{ triggerScheduleNames[indexOfRule] }})
                    </ng-container>
                    <ng-container *ngIf="rule.ruleType == 8">
                        {{
                            rule.thresholdCompare == 1
                                ? ("general.valueHigherAs" | translate : { x: rule.threshold })
                                : ""
                        }}
                        {{
                            rule.thresholdCompare == 2
                                ? ("general.valueLowerAs" | translate : { x: rule.threshold })
                                : ""
                        }}
                    </ng-container>
                    <ng-container *ngIf="rule.ruleType == 16">
                        {{ "general.deviation" | translate }}
                        {{
                            rule.thresholdCompare == 1
                                ? ("general.valueHigherAs" | translate : { x: rule.threshold })
                                : ""
                        }}
                        {{
                            rule.thresholdCompare == 2
                                ? ("general.valueLowerAs" | translate : { x: rule.threshold })
                                : ""
                        }}
                        %
                    </ng-container>
                    <ng-container
                        *ngIf="
                            rule.ruleType == 9 ||
                            rule.ruleType == 10 ||
                            rule.ruleType == 11 ||
                            rule.ruleType == 12 ||
                            rule.ruleType == 14
                        "
                    >
                        {{
                            rule.thresholdCompare == 1
                                ? ("general.valueHigherAs" | translate : { x: rule.threshold })
                                : ""
                        }}
                        {{
                            rule.thresholdCompare == 2
                                ? ("general.valueLowerAs" | translate : { x: rule.threshold })
                                : ""
                        }}
                    </ng-container>
                    <ng-container *ngIf="rule.ruleType == 17">
                        {{ "enums.triggerDeviceSensor." + rule.triggerDeviceSensor | translate }} -

                        {{
                            rule.thresholdCompare == 1
                                ? ("general.valueHigherAs" | translate : { x: rule.threshold })
                                : ""
                        }}
                        {{
                            rule.thresholdCompare == 2
                                ? ("general.valueLowerAs" | translate : { x: rule.threshold })
                                : ""
                        }}
                    </ng-container>
                    <ng-container *ngIf="rule.ruleType == 13">
                        {{ "enums.ruleProperty." + rule.ruleProperty | translate }} : {{ rule.threshold }}%
                    </ng-container>
                    <ng-container *ngIf="rule.ruleType == 15">
                        {{ "enums.virtualSensorOutputType." + rule.virtualSensorOutputType | translate }} |
                        {{
                            rule.ioState !== undefined
                                ? ("general.condition" | translate | lowercase) + "=" + rule.ioState
                                : ""
                        }}
                    </ng-container>
                    <ng-container *ngIf="rule.ruleType == 18">
                        {{ "enums.triggerDeviceSensor." + rule.triggerDeviceSensor | translate }}
                    </ng-container>
                </td>
                <!-- <td class="row_opening">{{ rule.minDuration ? rule.minDuration : '-' }}</td> -->
                <td style="width: 40px" *ngIf="formMode != 1">
                    <i class="fa fa-fw fa-times hand" style="color: rgb(151, 28, 36)" (click)="removeRule(rule)"></i>
                </td>
            </tr>
        </tbody>
    </table>
</div>
