<div class="ibox-content slick" style="position: relative">
    <table style="width: 100%" class="table table-fixed nowrap dtr-inline no-footer">
        <tbody *ngIf="!clientAnnouncement">
            <tr>
                <td colspan="4">
                    {{ "general.noServiceAnnounceMent" | translate }}
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="clientAnnouncement">
            <tr>
                <td class="row_opening">
                    {{ "general.reseller" | translate }}
                    <span class="error" *ngIf="resellerVar.invalid">*</span>
                </td>
                <td class="row_content" style="position: relative; overflow: visible">
                    <fh-reseller-input
                        name="reseller"
                        #resellerVar="ngModel"
                        required
                        placeholder="{{ 'general.chooseReseller' | translate }}"
                        [readonly]="formMode == 1"
                        [(ngModel)]="clientAnnouncement.resellerId"
                    >
                    </fh-reseller-input>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ "general.account" | translate }}
                    <span class="error" *ngIf="accountVar.invalid">*</span>
                </td>
                <td class="row_content" style="position: relative; overflow: visible">
                    <fh-account-input
                        name="account"
                        #accountVar="ngModel"
                        required
                        [selectedResellerId]="clientAnnouncement.resellerId"
                        [readonly]="formMode == 1"
                        placeholder="{{
                            (clientAnnouncement.resellerId ? 'general.chooseAccount' : 'general.firstChooseReseller')
                                | translate
                        }}"
                        [(ngModel)]="clientAnnouncement.accountId"
                    >
                    </fh-account-input>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ "general.startDate" | translate }}
                    <span class="error" *ngIf="startDate.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input
                        type="text"
                        [disabled]="formMode == 1"
                        name="startDate"
                        #startDate="ngModel"
                        class="form-control table-inline"
                        [(ngModel)]="clientAnnouncement.startDate"
                        required
                        placeholder=" {{ 'placeholder.startDate' | translate }}"
                        autocomplete="off"
                        [bsConfig]="dpConfig"
                        bsDatepicker
                    />
                    <span *ngIf="formMode != 1" class="input-group-calendar">
                        <i class="fa fa-calendar-alt"></i>
                    </span>
                </td>
            </tr>

            <tr>
                <td class="row_opening">
                    {{ "general.messageLevel1" | translate }}
                    <span class="error" *ngIf="messageLevel1.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <textarea
                        name="messageLevel1"
                        [readonly]="formMode == 1"
                        name="messageLevel1"
                        class="noresize stickypad"
                        style="height: 100px; width: 100%; resize: none"
                        id="encCss"
                        #messageLevel1="ngModel"
                        required
                        placeholder="{{ 'placeholder.message' | translate }}"
                        [(ngModel)]="clientAnnouncement.messageLevel1"
                    ></textarea>
                </td>
            </tr>
            <tr>
                <td class="row_opening" style="width: 50%">
                    {{ "general.daysToLevel2" | translate }}
                    <span class="error" *ngIf="daysToLevel2.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content" style="width: 50%">
                    <input
                        type="number"
                        [readonly]="formMode == 1"
                        name="daysToLevel2"
                        class="form-control table-inline"
                        placeholder="{{ 'placeholder.daysToLevel2' | translate }}"
                        [(ngModel)]="clientAnnouncement.daysToLevel2"
                        #daysToLevel2="ngModel"
                    />
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ "general.messageLevel2" | translate }}
                    <span class="error" *ngIf="messageLevel2.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <textarea
                        name="messageLevel2"
                        [readonly]="formMode == 1"
                        name="messageLevel2"
                        class="noresize stickypad"
                        style="height: 100px; width: 100%; resize: none"
                        id="encCss"
                        #messageLevel2="ngModel"
                        required
                        placeholder="{{ 'placeholder.message' | translate }}"
                        [(ngModel)]="clientAnnouncement.messageLevel2"
                    ></textarea>
                </td>
            </tr>
            <tr>
                <td class="row_opening" style="width: 50%">
                    {{ "general.daysToLevel3" | translate }}
                    <span class="error" *ngIf="daysToLevel3.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content" style="width: 50%">
                    <input
                        type="number"
                        [readonly]="formMode == 1"
                        name="daysToLevel3"
                        class="form-control table-inline"
                        placeholder="{{ 'placeholder.daysToLevel3' | translate }}"
                        [(ngModel)]="clientAnnouncement.daysToLevel3"
                        #daysToLevel3="ngModel"
                    />
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ "general.messageLevel3" | translate }}
                    <span class="error" *ngIf="messageLevel2.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <textarea
                        name="messageLevel2"
                        [readonly]="formMode == 1"
                        name="messageLevel3"
                        class="noresize stickypad"
                        style="height: 100px; width: 100%; resize: none"
                        id="encCss"
                        #messageLevel3="ngModel"
                        required
                        placeholder="{{ 'placeholder.message' | translate }}"
                        [(ngModel)]="clientAnnouncement.messageLevel3"
                    ></textarea>
                </td>
            </tr>
            <tr>
                <td class="row_opening" style="width: 50%">
                    {{ "general.daysToBlock" | translate }}
                    <span class="error" *ngIf="daysToBlock.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content" style="width: 50%">
                    <input
                        type="number"
                        [readonly]="formMode == 1"
                        name="daysToBlock"
                        class="form-control table-inline"
                        placeholder="{{ 'placeholder.daysToBlock' | translate }}"
                        [(ngModel)]="clientAnnouncement.daysToBlock"
                        #daysToBlock="ngModel"
                    />
                </td>
            </tr>
            <tr>
                <td class="row_opening" style="width: 50%">
                    {{ "general.useBlock" | translate }}
                </td>
                <td class="row_content" style="width: 50%">
                    <div class="fa_checkbox">
                        <input
                            id="alertmode"
                            type="checkbox"
                            name="useBlock"
                            [(ngModel)]="clientAnnouncement.useBlock"
                        />
                        <label for="alertmode">{{ "general.useBlock" | translate }}</label>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>
