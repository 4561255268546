<fh-permission-handler [permissionName]="permissionName">
    <div class="row wrapper border-bottom white-bg page-heading">
        <fh-home-navigation> </fh-home-navigation>

        <div class="flex-container">
            <div [hidden]="!permissions['Resellers_View']" class="col-lg-3">
                <div class="form-group input-group" style="margin-bottom: 6px; display: block">
                    <fh-reseller-input
                        name="reseller"
                        #resellerVar="ngModel"
                        (onChanged)="resellerChanged($event)"
                        required
                        placeholder="{{ 'general.chooseReseller' | translate}}"
                        [(ngModel)]="selectedResellerId"
                    >
                    </fh-reseller-input>
                </div>
            </div>
            <div [hidden]="!permissions['Accounts_View']" class="col-lg-3">
                <div class="form-group input-group" style="margin-bottom: 6px; display: block">
                    <fh-account-input
                        name="account"
                        #accountVar="ngModel"
                        (onChanged)="accountChanged($event)"
                        required
                        [selectedResellerId]="selectedResellerId"
                        [showEmptyAccountsButton]="false"
                        placeholder="{{ (selectedResellerId ? 'general.chooseAccount' : 'general.firstChooseReseller') | translate}}"
                        [(ngModel)]="selectedAccountId"
                    >
                    </fh-account-input>
                </div>
            </div>
            <div [hidden]="!permissions['AssetGroups_View']" cclass="col-lg-3">
                <div class="form-group" style="margin-bottom: 6px; display: block">
                    <fh-asset-group-input
                        name="assetGroups"
                        #assetGroupVar="ngModel"
                        required
                        [selectedAccountId]="selectedAccountId"
                        [tableInline]="false"
                        [multiple]="false"
                        [autoSelect]="false"
                        (onChanged)="dateChanged()"
                        placeholder="{{ (selectedAccountId? 'general.chooseAssetGroups' : 'general.firstChooseAccount') | translate}}"
                        [(ngModel)]="selectedAssetGroups"
                    >
                    </fh-asset-group-input>
                </div>
            </div>
            <div class="col-flex d-flex flex-fill d-none d-sm-block">
                <div class="form-group input-group" style="margin-bottom: 6px">
                    <span class="input-group-prepend">
                        <i class="fa fa-fw fa-calendar"></i>
                    </span>
                    <input
                        placeholder="Daterangepicker"
                        style="width: 100%; margin-top: 0px; width: 100%; margin-bottom: -5px"
                        placeholder="Datepicker"
                        type="text"
                        [bsConfig]="dpConfig"
                        class="form-control"
                        bsDaterangepicker
                        (onHidden)="dateChanged()"
                        [(ngModel)]="daterangepickerModel"
                    />
                    <span class="input-group-calendar-datatables">
                        <i class="fa fa-calendar-alt"></i>
                    </span>
                    <div style="clear: both"></div>
                </div>
            </div>
            <div class="col-flex d-flex flex-fill d-none d-sm-block">
                <div class="form-group" style="margin-bottom: 6px">
                    <ng-select
                        placeholder="{{ 'general.chooseVehicleType' | translate}}"
                        [clearable]="true"
                        class="form-control"
                        name="vehicleType"
                        required
                        #vehicleType="ngModel"
                        (change)="dateChanged()"
                        [(ngModel)]="selectedVehicleType"
                        style="width: 100%"
                    >
                        <ng-option *ngFor="let vehicleType of vehicleTypes" [value]="vehicleType.id">
                            {{ ('enums.vehicleType.' + vehicleType.id) | translate }}
                        </ng-option>
                    </ng-select>
                    <div style="clear: both"></div>
                </div>
            </div>
            <div class="col-flex d-flex flex-fill d-none d-sm-block">
                <div class="form-group" style="margin-bottom: 6px">
                    <fh-asset-type-input
                        name="assetTypes"
                        #assetTypeVar
                        [selectedAccountId]="selectedAccountId"
                        [tableInline]="false"
                        (onChanged)="dateChanged()"
                        [multiple]="false"
                        [autoSelect]="false"
                        placeholder="{{ (selectedAccountId? 'general.chooseAssetTypes' : 'general.firstChooseAccount') | translate}}"
                        [(ngModel)]="selectedAssetType"
                    >
                    </fh-asset-type-input>
                </div>
            </div>
            <div class="col-flex d-flex flex-fill d-none d-sm-block">
                <div class="form-group input-group" style="margin-bottom: 6px" title="Filter on 0 values">
                    <span
                        id="checkBox11"
                        class="input-group-prepend hand"
                        title="Filter on 0 values"
                        (click)="flipIncludeAssetsOtherAccounts(); dateChanged()"
                    >
                        <i
                            id="checkBox_11"
                            class="auto_checkbox fa fa-fw"
                            [ngClass]="includeAssetsOtherAccounts ? 'fa-check' : 'fa-times'"
                            title="Filter on 0 values"
                            [style.color]="includeAssetsOtherAccounts ? 'rgba(0, 142, 67, 0.8)' : 'rgba(151, 28, 36, 0.8)'"
                        ></i>
                    </span>
                    <input
                        name="checkboxName_11"
                        id="checkboxName_11"
                        style="width: 100%"
                        placeholder="{{'general.includeAssetsOtherAccounts' | translate }}"
                        disabled="disabled"
                        class="form-control"
                    />
                </div>
            </div>
            <div style="clear: both"></div>
        </div>
    </div>

    <div class="wrapper wrapper-content">
        <div class="row">
            <div class="col-lg-12">
                <fh-error [error]="error"></fh-error>
            </div>
        </div>

        <!-- Utilization tab -->
        <div class="row" style="position: relative">
            <ngx-loading [show]="loadingUtilization"></ngx-loading>

            <div class="col-6 col-md-4 col-lg-2">
                <div class="ibox hand">
                    <div
                        class="kpi-item"
                        style="position: relative"
                        [routerLink]="['/Devices/Overview/']"
                        title="{{'general.activeAssetsHover' | translate }}"
                    >
                        <h3 class="margin-bottom font-bold">
                            <i class="fas fa-fw fa-car"></i>
                            {{ accountUtilization?.assetCountWithTrips ?? 0 }}<span
                                *ngIf="accountUtilization?.assetCountWithTrips != accountUtilization?.assetCount"
                            >
                                / {{ accountUtilization?.assetCount ?? 0 }}</span
                            >
                        </h3>
                        <div>{{'general.activeAssets' | translate }}</div>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-4 col-lg-2">
                <div class="ibox">
                    <div
                        class="kpi-item"
                        style="position: relative"
                        title="{{'general.distancePrivate' | translate }} {{ accountUtilization?.totalDistancePrivate ?
                    accountUtilization?.totalDistancePrivate.toLocaleString() : 0 }} km"
                    >
                        <h3 class="margin-bottom font-bold">
                            <i class="fas fa-fw fa-route"></i>
                            {{ accountUtilization?.totalDistance ? accountUtilization?.totalDistance.toLocaleString() :
                            0 }} km
                        </h3>
                        <div>{{'general.distance' | translate }}</div>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-4 col-lg-2">
                <div class="ibox">
                    <div class="kpi-item" style="position: relative">
                        <h3 class="margin-bottom font-bold">
                            <i class="fas fa-fw fa-route"></i>
                            {{ accountUtilization?.tripCount ? accountUtilization?.tripCount?.toLocaleString() : 0 }}
                        </h3>
                        <div>{{'general.trips' | translate }}</div>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-4 col-lg-2">
                <div class="ibox hand">
                    <div
                        class="kpi-item"
                        style="position: relative"
                        [routerLink]="'/Devices/Assignments'"
                        [queryParams]="{start: daterangepickerModel[0], end: daterangepickerModel[1], orderBy: 'maxSpeed', accountId: includeAssetsOtherAccounts ? null : selectedAccountId}"
                    >
                        <h3 class="margin-bottom font-bold">
                            <i class="fas fa-fw fa-tachometer-fastest"></i>
                            {{ accountUtilization?.maxSpeed ?
                            actualRound((accountUtilization?.maxSpeed),0)?.toLocaleString() : 0 }} km/h
                        </h3>
                        <div>{{'general.maxSpeed' | translate }}</div>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-4 col-lg-2">
                <div class="ibox">
                    <div class="kpi-item" style="position: relative">
                        <h3 class="margin-bottom font-bold">
                            <i class="fas fa-fw fa-gas-pump"></i>
                            {{ accountUtilization?.totalConsumptionMixed ?
                            actualRound((accountUtilization?.totalConsumptionMixed),0)?.toLocaleString() : 0 }} L
                        </h3>
                        <div>{{'general.totalConsumptionMixed' | translate }}</div>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-4 col-lg-2">
                <div class="ibox">
                    <div class="kpi-item" style="position: relative">
                        <h3 class="margin-bottom font-bold">
                            <i class="fas fa-fw fa-cloud"></i>
                            {{ accountUtilization?.totalEmissionCO2 ?
                            actualRound((accountUtilization?.totalEmissionCO2),0)?.toLocaleString() : 0 }} kg
                        </h3>
                        <div>{{'general.totalEmissionCO2' | translate }}</div>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-4 col-lg-2" *ngIf="permissions['FleetManagement_DeviceUtilization']">
                <div class="ibox">
                    <div class="kpi-item" style="position: relative">
                        <h3 class="margin-bottom font-bold">
                            <i class="fas fa-fw fa-route"></i>
                            {{ accountUtilization?.totalDistance && accountUtilization?.tripCount ?
                            (actualRound(accountUtilization?.totalDistance /
                            accountUtilization?.tripCount,1)).toLocaleString() : 0 }} km
                        </h3>
                        <div>{{'general.avgTripDistance' | translate }}</div>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-4 col-lg-2" *ngIf="permissions['FleetManagement_DeviceUtilization']">
                <div class="ibox">
                    <div class="kpi-item" style="position: relative">
                        <h3 class="margin-bottom font-bold">
                            <i class="fas fa-fw fa-clock"></i>
                            {{ accountUtilization?.totalWorkingHoursInSeconds ?
                            actualRound(accountUtilization?.totalWorkingHoursInSeconds / 3600, 0).toLocaleString() : 0
                            }} h
                        </h3>
                        <div>{{'general.workingHours' | translate }}</div>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-4 col-lg-2" *ngIf="permissions['FleetManagement_DeviceUtilization']">
                <div class="ibox">
                    <div class="kpi-item" style="position: relative">
                        <h3 class="margin-bottom font-bold">
                            <i class="fas fa-fw fa-clock"></i>
                            {{ accountUtilization?.totalPureDrivingDurationInSeconds ?
                            actualRound(accountUtilization?.totalPureDrivingDurationInSeconds / 3600,
                            0).toLocaleString() : 0 }} h
                        </h3>
                        <div>{{'general.pureDriving' | translate }}</div>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-4 col-lg-2" *ngIf="permissions['FleetManagement_DeviceUtilization']">
                <div class="ibox">
                    <div class="kpi-item" style="position: relative">
                        <h3 class="margin-bottom font-bold">
                            <i class="fas fa-fw fa-snooze"></i>
                            {{ accountUtilization?.totalIdlingDurationInSeconds ?
                            actualRound(accountUtilization?.totalIdlingDurationInSeconds / 3600, 0).toLocaleString() : 0
                            }} h
                        </h3>
                        <div>{{'general.idling' | translate }}</div>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-4 col-lg-2" *ngIf="permissions['FleetManagement_DeviceUtilization']">
                <div class="ibox">
                    <div class="kpi-item" style="position: relative">
                        <h3 class="margin-bottom font-bold">
                            <i class="fas fa-fw fa-snooze"></i>
                            {{ accountUtilization?.totalIdlingDurationInSeconds ? actualRound(((
                            (accountUtilization?.totalIdlingDurationInSeconds /
                            accountUtilization?.totalWorkingHoursInSeconds) * 100)),1)?.toLocaleString() : 0 }} %
                        </h3>
                        <div>{{'general.idlingPercentage' | translate }}</div>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-4 col-lg-2" *ngIf="permissions['FleetManagement_DeviceUtilization']">
                <div class="ibox">
                    <div class="kpi-item" style="position: relative">
                        <h3 class="margin-bottom font-bold">
                            <i class="fas fa-fw fa-percent"></i>
                            {{ accountUtilization?.totalWorkingHoursInSeconds ? actualRound(((
                            (accountUtilization?.totalWorkingHoursInSeconds /
                            accountUtilization?.totalDurationInSeconds) * 100)),1)?.toLocaleString() : 0 }} %
                        </h3>
                        <div>{{'general.utilization' | translate }}</div>
                    </div>
                </div>
            </div>

            <div style="clear: both"></div>
        </div>

        <div class="row">
            <div class="col-lg-6">
                <div class="ibox">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-route"></i> {{'general.chartDistance' | translate }}</h5>
                    </div>
                    <div class="ibox-content slicker" style="min-height: 224px; position: relative">
                        <ngx-loading [show]="loadingCount"></ngx-loading>

                        <highcharts-chart
                            *ngIf="chartDistance"
                            [runOutsideAngular]="true"
                            style="display: block; width: 100%; height: 214px"
                            [Highcharts]="Highcharts"
                            [options]="chartDistance"
                        >
                        </highcharts-chart>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="ibox">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-engine"></i> {{'general.chartEngineHours' | translate }}</h5>
                    </div>
                    <div class="ibox-content slicker" style="min-height: 224px; position: relative">
                        <ngx-loading [show]="loadingTrends"></ngx-loading>

                        <highcharts-chart
                            *ngIf="chartEngineHours"
                            [runOutsideAngular]="true"
                            style="display: block; width: 100%; height: 214px"
                            [Highcharts]="Highcharts"
                            [options]="chartEngineHours"
                        >
                        </highcharts-chart>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4" *ngIf="accountUtilization?.assetCount > 5">
                <div class="ibox">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-car"></i> {{'general.communicatingAssets' | translate }}</h5>
                    </div>
                    <div class="ibox-content slicker" style="min-height: 224px; position: relative">
                        <ngx-loading [show]="loadingCount"></ngx-loading>

                        <highcharts-chart
                            *ngIf="chartActiveDevices"
                            [runOutsideAngular]="true"
                            style="display: block; width: 100%; height: 214px"
                            [Highcharts]="Highcharts"
                            [options]="chartActiveDevices"
                        ></highcharts-chart>
                    </div>
                </div>
            </div>
            <div [ngClass]="accountUtilization?.assetCount > 5 ? 'col-lg-4' : 'col-lg-6'">
                <div class="ibox">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-car"></i> {{'general.activityBreakdown' | translate }}</h5>
                    </div>
                    <div class="ibox-content slicker" style="min-height: 224px; position: relative">
                        <ngx-loading [show]="loadingCount"></ngx-loading>

                        <highcharts-chart
                            *ngIf="chartActivity"
                            [runOutsideAngular]="true"
                            style="display: block; width: 100%; height: 214px"
                            [Highcharts]="Highcharts"
                            [options]="chartActivity"
                        ></highcharts-chart>
                    </div>
                </div>
            </div>
            <div [ngClass]="accountUtilization?.assetCount > 5 ? 'col-lg-4' : 'col-lg-6'">
                <div class="ibox">
                    <div class="ibox-title">
                        <h5><i class="fa fa-fw fa-comment-alt-dots"></i> {{'general.locationCount' | translate }}</h5>
                    </div>
                    <div class="ibox-content slicker" style="min-height: 224px; position: relative">
                        <ngx-loading [show]="loadingCount"></ngx-loading>

                        <highcharts-chart
                            *ngIf="chartLocationCount"
                            [runOutsideAngular]="true"
                            style="display: block; width: 100%; height: 214px"
                            [Highcharts]="Highcharts"
                            [options]="chartLocationCount"
                        ></highcharts-chart>
                    </div>
                </div>
            </div>
        </div>
    </div>
</fh-permission-handler>
