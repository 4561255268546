import {
  Component,
  Input,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  AfterViewInit,
} from "@angular/core";
import { BatchAction, BatchStatus, EntityType } from "app/common/enums";
import { ModalDirective } from "ngx-bootstrap/modal";
import { BatchService } from "app/services/batch/batch.service";
import { BatchJob } from "app/models/batch.model";
import { TranslateService } from "@ngx-translate/core";
import { AuthenticationService } from "app/services/authentication/authentication.service";
import { HandleTaskProcessingComponent } from "./handleTaskProcessing.component";

@Component({
  selector: "fh-batch-processing",
  templateUrl: "batchProcessing.component.html",
  providers: [HandleTaskProcessingComponent],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class BatchProcessingComponent implements OnInit, AfterViewInit {
  @ViewChild("batchModal", { static: false }) batchModal: ModalDirective;
  @ViewChild("handleActions", { static: true }) handleActions: HandleTaskProcessingComponent;

  @Input() gridSelection = [];
  @Input() enableSelecting = false;

  @Input() entityTypes = EntityType.Unknown;

  @Output() onClose = new EventEmitter();

  saving = false;
  showSelection = false;

  selectedAction;

  batchActions = [];

  // Selecting
  tableIds = [];
  tableIdsSelection = [];

  eventsSet = false;

  permissions: {};

  constructor(
    private cd: ChangeDetectorRef,
    private translate: TranslateService,
    private batchService: BatchService,
    private authenticationService: AuthenticationService
  ) {
    this.permissions = this.authenticationService.permissions;
  }

  ngAfterViewInit(): void {
    this.batchActions = this.handleActions.getBatchActionList(this.entityTypes, null);
    this.cd.markForCheck();
  }

  ngOnInit(): void {}

  setGridSelection() {
    //Setting grid selection in user control
    this.handleActions.gridSelection = this.gridSelection;
  }

  showModal(selectedAction) {
    this.selectedAction = selectedAction;
    this.batchModal.show();
  }

  hideModal() {
    this.selectedAction = 0;
    this.batchModal.hide();
  }

  handleSelection() {
    if (this.gridSelection.length === 0) {
      // select all
      this.selectAll();
      return;
    }
    if (this.gridSelection.length > 0 && this.gridSelection.length === this.tableIdsSelection.length) {
      // select none
      this.clearAll();
      return;
    }
    if (this.gridSelection.length > 0 && this.gridSelection.length !== this.tableIdsSelection.length) {
      // select all
      this.selectAll();
      return;
    }
  }

  process(event) {
    if (event.status === "hide") {
      this.selectedAction = null;
      this.batchModal.hide();
      return;
    }

    if (event.status === "error") {
      this.selectedAction = null;
      this.batchModal.hide();
      this.onClose.emit(event);
      return;
    }

    this.saving = true;

    if (
      this.selectedAction.batchActionType === BatchAction.DeviceSettings &&
      typeof event.object["auxiliaryAttachements"] !== "number"
    ) {
      event.object["auxiliaryAttachements"] = event.object["auxiliaryAttachements"].reduce(
        (acc, current) => acc + +current,
        0
      );
    }

    if (
      this.selectedAction.batchActionType === BatchAction.DeviceSettings &&
      typeof event.object["canBusParameters"] !== "number"
    ) {
      event.object["canBusParameters"] = event.object["canBusParameters"].reduce((acc, current) => acc + +current, 0);
    }

    const batchStatus: BatchJob = {
      id: null,
      status: BatchStatus.Unknown,
      items: [],
      entityTypes: EntityType.Unknown,
      batchActionType: BatchAction.Unknown,
      jsonObject: null,
      log: null,
      error: null,
      jobRequested: null,
      jobStarted: null,
      jobCompleted: null,
      errorDescription: null,
    };

    batchStatus.status = BatchStatus.Pending;
    batchStatus.jsonObject = JSON.stringify(event.object);
    batchStatus.log = event.log;
    batchStatus.items =
      event.object.selectedItems?.length > 0
        ? event.object.selectedItems.map((x) => +x)
        : this.gridSelection.map((x) => +x);
    batchStatus.entityTypes = this.entityTypes;
    batchStatus.batchActionType = this.selectedAction.batchActionType;

    this.batchModal.hide();
    this.cd.markForCheck();

    this.batchService.saveBatch(batchStatus).subscribe({
      next: (result) => {
        this.saving = false;
        batchStatus.status = BatchStatus.Success;
        this.cd.markForCheck();
        this.onClose.emit(batchStatus);
      },
      error: (error) => {
        this.saving = false;
        batchStatus.status = BatchStatus.Error;
        batchStatus.error = error;
        this.onClose.emit(batchStatus);
        this.cd.markForCheck();
      },
    });
  }

  // Reset the selection vars
  resetSelection() {
    console.log("Resetting selection");
    // do not reset this to [] as it loses it reference
    this.gridSelection.splice(0, this.gridSelection.length);

    this.tableIdsSelection = [];
    this.tableIds = [];
  }

  selectAll() {
    // do not reset this to [] as it loses it reference
    this.gridSelection.splice(0, this.gridSelection.length);

    this.tableIdsSelection.forEach((id) => {
      const checkbox = $("#selection_" + id);
      const parentRow = $(checkbox).closest("tr");

      if (checkbox) {
        checkbox.removeClass("fa-square").addClass("fa-check-square");
      }
      if (parentRow) {
        parentRow.addClass("selected");
      }

      this.gridSelection.push(id);
    });
  }

  clearAll() {
    // do not reset this to [] as it loses it reference
    this.gridSelection.splice(0, this.gridSelection.length);

    this.tableIdsSelection.forEach((id) => {
      const checkbox = $("#selection_" + id);
      const parentRow = $(checkbox).closest("tr");

      checkbox.removeClass("fa-check-square").addClass("fa-square");
      parentRow.removeClass("selected");
    });
  }
}
