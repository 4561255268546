import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthenticationService } from "app/services/authentication/authentication.service";
import { from, Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { StorageType } from "./enums";

@Injectable({
  providedIn: "root",
})
export class StorageHelper {
  prefetchedDatabase = false;

  constructor(private http: HttpClient, private authenticationService: AuthenticationService) {}

  // State
  saveStoreState(storageType, prexix, settingName, data) {
    if (storageType === StorageType.LocalStorage) {
      localStorage.setItem(prexix + settingName, JSON.stringify(data));
    } else if (storageType === StorageType.SessionStorage) {
      sessionStorage.setItem(prexix + settingName, JSON.stringify(data));
    } else if (storageType === StorageType.Cookie) {
      // this.setCookie(prexix + settingName, JSON.stringify(data), 30);
    } else if (storageType === StorageType.Database || storageType === StorageType.DatabasePrefetch) {
      console.log("Save in Database", prexix + settingName);

      const state = JSON.stringify(data);

      localStorage.setItem(prexix + settingName, state);

      const request = {
        name: prexix + settingName,
        value: state,
        accountId: null,
        userId: this.authenticationService.getUserId(),
      };

      this.http
        .post<string>(this.authenticationService.getWebserviceURL("user") + "Settings", request, {
          headers: this.authenticationService.headers,
        })
        .subscribe();
    }
  }

  loadStoreState(storageType, prexix, constructor): Observable<any> {
    if (!this.authenticationService.getUserId()) {
      return of(null);
    }

    try {
      if (storageType === StorageType.LocalStorage) {
        return of(JSON.parse(localStorage.getItem(prexix + constructor)));
      } else if (storageType === StorageType.SessionStorage) {
        return of(JSON.parse(sessionStorage.getItem(prexix + constructor)));
      } else if (storageType === StorageType.Cookie) {
        // return JSON.parse(this.getCookie(prexix + constructor));
      } else if (storageType === StorageType.Database) {
        console.log("Return from database", prexix + constructor);
        // return of(JSON.parse(localStorage.getItem(prexix + constructor)));
        return this.http
          .get<string>(this.authenticationService.getWebserviceURL("user") + "Settings/" + (prexix + constructor), {
            headers: this.authenticationService.headers,
          })
          .pipe(
            map((item) => {
              const state = JSON.parse(item || "null");
              this.saveStoreState(StorageType.LocalStorage, "Presets_", constructor, state);
              return state;
            })
          );
      } else if (storageType === StorageType.DatabasePrefetch) {
        if (this.prefetchedDatabase) {
          return from(
            new Promise((resolve) => {
              setTimeout(
                () =>
                  this.loadStoreState(StorageType.LocalStorage, prexix, constructor).subscribe((result) => {
                    resolve(result);
                  }),
                1
              );
            })
          );
        }

        this.prefetchedDatabase = true;

        return this.http
          .get<string>(this.authenticationService.getWebserviceURL("user") + "Settings", {
            headers: this.authenticationService.headers,
          })
          .pipe(
            map((item) => {
              const jsonArray = JSON.parse(item || "[]");

              const distinctList = {};
              for (const json of jsonArray) {
                let element;
                try {
                  element = JSON.parse(json["Value"]);

                  if (element?.length) {
                    for (const value of element) {
                      value["fromDatabase"] = true;

                      if (json["AccountId"]) {
                        value["accountId"] = json["AccountId"];
                      }
                      if (json["UserId"]) {
                        value["userId"] = json["UserId"];
                      }
                    }
                  }

                  if (Array.isArray(distinctList[json["Name"]])) {
                    distinctList[json["Name"]] = (distinctList[json["Name"]] as any[]).concat(element);
                  } else {
                    distinctList[json["Name"]] = element;
                  }

                  localStorage.setItem(json["Name"], JSON.stringify(distinctList[json["Name"]]));
                } catch (error) {
                  console.log("issue with " + json["Value"]);
                }
              }

              return JSON.parse(localStorage.getItem(prexix + constructor));
            })
          );
      }
    } catch {
      return of({});
    }
  }
}
