<div class="ibox" style="position: relative">
    <ngx-loading [show]="loading"></ngx-loading>
    <div class="ibox-title">
        <h5><i class="fa fa-fw fa-id-card-alt"></i> {{ 'general.assignedDriver' | translate}}</h5>
    </div>
    <div class="ibox-content slick" style="position: relative" *ngIf="device.asset">
        <table style="width: 100%" class="table table-fixed nowrap dtr-inline no-footer">
            <tbody *ngIf="device.asset.driver == null">
                <tr>
                    <td colspan="2">{{'general.noDriverFound' | translate }}</td>
                </tr>
            </tbody>
            <tbody *ngIf="device.asset.driver != null">
                <tr>
                    <td class="row_opening">{{ 'general.name' | translate}}</td>
                    <td class="row_content">
                        <a class="secondary link_bolder" href="/#/DriverDetails/Index/{{device.asset.driver.id}}">
                            {{device.asset.driver.name}}</a
                        >
                    </td>
                </tr>
                <tr>
                    <td class="row_opening">{{ 'general.assignmentStart' | translate}}</td>
                    <td class="row_content">{{device.asset.driverAssignmentStart | amDateFormat: 'll'}}</td>
                </tr>
                <tr>
                    <td class="row_opening">{{ 'general.assignmentEnd' | translate}}</td>
                    <td class="row_content">{{device.asset.driverAssignmentEnd | amDateFormat: 'll'}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
