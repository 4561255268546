import {
  Component,
  AfterViewInit,
  Input,
  ElementRef,
  ViewChild,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from "@angular/core";

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { DriverGroupsService } from "app/services/driver/driverGroups.service";

const noop = () => {};

@Component({
  selector: "fh-driver-group-item-input",
  templateUrl: "./driverGroupItemSelector.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: DriverGroupItemInputComponent }],
})
export class DriverGroupItemInputComponent implements OnChanges, OnInit, AfterViewInit, ControlValueAccessor {
  @Input() placeholder: string;
  @Input() clearable = true;
  @Input() selectedAccountId;
  @Input() readonly = false;
  @Input() multiple = true;
  @Input() tableInline;
  @Input() includeEmptyGroups = true;
  @Input() setCurrentDrivers;

  @Output() onChanged = new EventEmitter();

  driverGroups = [];

  loadingDriverGroups = false;

  @ViewChild("input") el: ElementRef;

  private _value: string;
  private _onChange: (_: any) => void = noop;

  get value(): any {
    return this._value;
  }

  set value(v: any) {
    if (v !== this._value) {
      this._value = v;
      this._onChange(v);
      this.cd.markForCheck();
    }
  }

  constructor(private driverGroupService: DriverGroupsService, private cd: ChangeDetectorRef) {}

  groupValueFn = (object: { [key: string]: any }, children: any[]) => ({
    displayName: object.value.displayName,
    id: [{ driverGroupId: object.value.id }],
    length: children.length,
  });

  // Override search function to be able to search on groups
  searchFn(searchTerm: string, item: any) {
    const columns = ["driverGroupName", "driverName"];
    let isSearchMatch = false;

    columns.forEach((column) => {
      if (item[column]?.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) {
        isSearchMatch = true;
      }
    });

    return isSearchMatch;
  }

  driverGroupChanged() {
    this.onChanged.emit(this.value);
    this.cd.markForCheck();
  }

  ngOnChanges() {
    setTimeout(() => {
      // Get accounts again
      this.loadGeofenceGroups();
      this.cd.markForCheck();
    }, 0);
  }

  compareFunction = (item, selected) => {
    if (selected.gender && item.gender) {
      return item.gender === selected.gender;
    }
    if (item.id && selected.id) {
      return item.id === selected.id;
    }
    return false;
  };

  compareFn(item, selected): boolean {
    if (item.displayName !== undefined) {
      if (item.id[0].driverGroupId === selected) {
        return true;
      }
      return false;
    }
    if (item?.id === selected) {
      return true;
    }
    return false;
  }

  showAll(event, driverGroup) {
    driverGroup.showAll = !driverGroup.showAll;
    event.stopPropagation();
  }

  loadGeofenceGroups() {
    this.driverGroups = [];
    this.cd.markForCheck();

    if (this.selectedAccountId) {
      this.loadingDriverGroups = true;
      this.cd.markForCheck();

      if (this.selectedAccountId && this.selectedAccountId !== 0) {
        this.driverGroupService.getDriverGroups(this.selectedAccountId, true).subscribe((result) => {
          result = result.sort((a, b) => (a.name < b.name ? -1 : 1));

          this.driverGroups = result;

          // Removing empty groups
          if (!this.includeEmptyGroups) {
            this.driverGroups = this.driverGroups.filter((x) => x.driverGroupItems?.length > 0);
          }

          this.driverGroups.sort((a, b) => (a.displayName < b.displayName ? -1 : 1));
          this.driverGroups.forEach((group) => {
            group.driverGroupItems.sort((a, b) => (a.driverName < b.driverName ? -1 : 1));
          });

          this.loadingDriverGroups = false;
          this.cd.markForCheck();
        });
      } else {
        this.loadingDriverGroups = false;
        this.cd.markForCheck();
      }
    }
  }

  ngOnInit() {}

  ngAfterViewInit() {
    const __this = this;
  }

  writeValue(value: any) {
    this._value = value;
    this.cd.markForCheck();
  }

  registerOnChange(fn: (value: any) => void) {
    this._onChange = fn;
    this.cd.markForCheck();
  }

  registerOnTouched(fn: any) {
    this.cd.markForCheck();
  }
}
