import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { throwError } from "rxjs/internal/observable/throwError";
import { Observable, catchError, map } from "rxjs";
import { Trailer } from "../../models/trailer.model";
import { AuthenticationService } from "../authentication/authentication.service";
import { LoggingService } from "../logging/logging.service";

// Moment timezone
import Moment from "moment-timezone";
window["moment"] = Moment;

@Injectable()
export class TrailerService {
  url = "";
  base_url = "";
  inventoryUrl = "";
  Trailers: Trailer[] = [];
  timezoneIana: string;

  constructor(
    private http: HttpClient,
    private loggingService: LoggingService,
    private authenticationService: AuthenticationService
  ) {
    this.url = this.authenticationService.getWebserviceURL("trailer");
    this.base_url = this.authenticationService.getWebserviceURL("");
    this.timezoneIana = this.authenticationService.getTimeZoneIana();
  }

  getPagingUrl(accountId?: number) {
    return this.url + "Paging" + (accountId > 0 ? `?accountId=${accountId}` : "");
  }

  getTrailers(): Observable<Trailer[]> {
    console.log("getting trailers from service");
    return this.http.get(this.url, { headers: this.authenticationService.headers }).pipe(
      map((data) => {
        return this.parseResponse(data);
      }),
      catchError(this.handleError)
    );
  }

  getTrailersByAccount(accountId): Observable<Trailer[]> {
    console.log("getting trailers from service");
    return this.http
      .get(this.base_url + "Account/" + accountId + "/Trailers", { headers: this.authenticationService.headers })
      .pipe(
        map((data) => {
          return this.parseResponse(data);
        }),
        catchError(this.handleError)
      );
  }

  getTrailerById(id: string): Observable<Trailer> {
    console.log("Fetch trailer by id " + id);
    return this.http.get(this.url + id, { headers: this.authenticationService.headers }).pipe(
      map((data) => {
        return this.parseReponseDetails(data);
      }),
      catchError(this.handleError)
    );
  }

  getTrailerAssignmentsById(id: string): Observable<any> {
    console.log("Fetch trailer assignments by id " + id);
    return this.http.get(this.url + id + "/Assignments", { headers: this.authenticationService.headers }).pipe(
      map((data: any) => {
        data.forEach((item) => {
          item.dateStart =
            item.dateStart !== undefined ? Moment.utc(item.dateStart)["tz"](this.timezoneIana) : undefined;
          item.dateEnd = item.dateEnd !== undefined ? Moment.utc(item.dateEnd)["tz"](this.timezoneIana) : undefined;
        });
        return data;
      }),
      catchError(this.handleError)
    );
  }

  saveTrailer(trailer: Trailer): Observable<any> {
    console.log("save trailer", trailer);

    return this.http
      .post(this.url, trailer, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  updateTrailer(trailer: Trailer): Observable<any> {
    console.log("update trailer", trailer);

    return this.http
      .put(this.url + trailer.id, trailer, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  deleteTrailer(trailer: Trailer): Observable<any> {
    console.log("delete trailer", trailer);

    return this.http
      .delete(this.url + trailer.id, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  resetCache(): Observable<boolean> {
    return this.http.get(this.url + "ResetCache", { headers: this.authenticationService.headers }).pipe(
      map((data: any) => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  getTrailersByDeviceId(id: string): Observable<Trailer[]> {
    console.log("Fetch trailers by id " + id);
    return this.http.get(this.url + "Device/" + id, { headers: this.authenticationService.headers }).pipe(
      map((data) => this.parseResponse(data)),
      catchError(this.handleError)
    );
  }

  getTrailersByAssetId(id: string): Observable<Trailer[]> {
    console.log("Fetch trailers by id " + id);
    return this.http.get(this.url + "Asset/" + id, { headers: this.authenticationService.headers }).pipe(
      map((data) => this.parseResponse(data)),
      catchError(this.handleError)
    );
  }

  private handleError(error: Response) {
    return throwError(() => error);
  }

  parseResponse(json: any): Trailer[] {
    this.loggingService.log(this.constructor.name, "Retrieved " + json.length + " Trailers.");

    const ident = 1;
    const trailers: Trailer[] = [];

    json.forEach((item) => {
      const trailer = this.parseReponseDetails(item);
      trailers.push(trailer);
    });

    return trailers;
  }

  parseReponseDetails(item) {
    const trailer = new Trailer();
    trailer.id = item.id;
    trailer.name = item.name;
    trailer.erpCode = item.erpCode;
    trailer.identificationTag = item.identificationTag;

    trailer.companyName = item.companyName;
    trailer.accountId = item.accountId;

    trailer.deviceId = item.deviceId;
    trailer.assetName = item.assetName;
    trailer.trailerAssignmentStart = item.trailerAssignmentStart;
    trailer.trailerAssignmentEnd = item.trailerAssignmentEnd;
    trailer.resellerId = item.resellerId;
    trailer.resellerDescription = item.resellerDescription;

    return trailer;
  }
}
