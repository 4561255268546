<ng-select
    [loading]="loading"
    class="form-control table-inline"
    placeholder="{{ 'general.selector' | translate }}"
    name="user"
    required
    [(ngModel)]="selectedUser"
    #userSelector="ngModel"
    style="width: 100%; float: left"
    (change)="itemSelected()"
>
    <ng-option *ngFor="let user of users | orderBy : 'name' : false; let i = index" [value]="user.id">
        {{ user.name }}
    </ng-option>
</ng-select>
