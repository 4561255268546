import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { throwError } from "rxjs/internal/observable/throwError";
import { Observable, catchError, map } from "rxjs";
import { Config } from "../../models/config.model";
import { ErrorLog } from "../../models/errorlog.model";
import { AuthenticationService } from "../authentication/authentication.service";

// Moment timezone
import Moment from "moment-timezone";
import { environment } from "environments/environment";
@Injectable()
export class ErrorLogService {
  url: string;
  token: string;
  public config: Config;
  timezoneIana: string;

  // I initialize the service.
  constructor(private http: HttpClient, private authenticationService: AuthenticationService) {
    this.http = http;
    this.url = this.authenticationService.getWebserviceURL("errorlog");
    this.timezoneIana = this.authenticationService.getTimeZoneIana();

    this.config = this.environmentToConfig();
  }

  private environmentToConfig(): Config {
    const cfg = new Config();

    cfg.version = environment.version;
    cfg.AuthenticationUrl = window["server_variables"].AuthenticationUrl;
    cfg.ConsumerToken = environment.ConsumerToken;
    cfg.Debug = environment.Debug;
    cfg.Environment = environment.Environment;
    cfg.SSOAuthenticationUrl = environment.SSOAuthenticationUrl;
    cfg.SSOPostLogoutRedirectUrl = environment.SSOPostLogoutRedirectUrl;
    cfg.SSORedirectUrl = environment.SSORedirectUrl;

    return cfg;
  }

  getPagingUrl(start, end) {
    this.url = this.authenticationService.getWebserviceURL("errorlog");
    return this.url + "Paging?startRange=" + start.unix() + "&endRange=" + end.unix();
  }

  // I log the given error to various aggregation and tracking services.
  public logError(error: any): void {
    // Internal tracking.
    this.sendToConsole(error);
    this.sendToServer(error);
  }

  public logErrorString(caller: any, error: any): void {
    this.logError(new CustomError(caller + " , " + error));
  }

  // I send the error the browser console (safely, if it exists).
  private sendToConsole(error: any): void {
    if (console && console.group && console.error) {
      console.group("Error Log Service");
      console.error(error);
      console.error(error.message);
      console.error(error.stack);
      console.groupEnd();
    }
  }

  getErrorLogs(): Observable<ErrorLog[]> {
    this.url = AuthenticationService.getStaticClusterUrl();

    console.log("getting errorlog from service");
    return this.http.get(this.url + "errorlog", { headers: this.authenticationService.headers }).pipe(
      map((data) => this.parseResponse(data)),
      catchError(this.handleError)
    );
  }

  getErrorLogById(id: string): Observable<ErrorLog> {
    this.url = AuthenticationService.getStaticClusterUrl();

    console.log("getting errorlog from service");
    return this.http.get(this.url + "errorlog/" + id, { headers: this.authenticationService.headers }).pipe(
      map((data) => this.parseReponseDetails(data)),
      catchError(this.handleError)
    );
  }

  parseResponse(json: any): ErrorLog[] {
    const ident = 1;
    const errorlogs: ErrorLog[] = [];

    json.forEach((item) => {
      const errorlog = this.parseReponseDetails(item);
      errorlogs.push(errorlog);
    });

    return errorlogs;
  }

  parseReponseDetails(item) {
    const errorlog = new ErrorLog();
    errorlog.id = item.id;
    errorlog.description = item.description;
    errorlog.stack = item.stack;
    errorlog.location = item.location;
    errorlog.username = item.userName;
    errorlog.message = item.message;
    errorlog.createdDate = Moment.utc(item.createdDate)["tz"](this.timezoneIana);

    return errorlog;
  }

  private handleError(error: Response) {
    console.log(error);
    return throwError(() => error);
  }

  // I send the error to the server-side error tracking end-point.
  private sendToServer(error: any): void {
    console.log("Error send to server");

    this.url = AuthenticationService.getStaticClusterUrl();

    const theError = {
      Location: window.location.href,
      Name: error.name,
      Message: error.message,
      Description: "FM APP",
      Stack: error.stack,
    };

    if (this.config && !this.config.Debug) {
      this.http.post(this.url + "ErrorLog", theError, { headers: this.authenticationService.headers }).subscribe({
        next: (result) => {
          console.log("Error posted");
        },
        error: (hasError) => {
          console.log(hasError);
        },
      });
    } else {
      console.log("Skipping error post");
      console.log(theError);
    }
  }
}

function CustomError(message) {
  this.name = "Custom Error";
  this.message = message || "Default Message";
  this.stack = new Error().stack;
}
