import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "fh-geofence-navigation",
  templateUrl: "geofenceNavigation.template.html",
})
export class GeofenceNavigationComponent implements OnInit {
  sub: any;
  activeId: any;

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    this.sub = this.route.params.subscribe((params) => {
      const id = params["id"];
      this.activeId = id;
    });
  }

  isGeofenceGroupDetails() {
    const routes = ["GeofenceGroupDetails"];

    let routeActive = false;

    routes.forEach((route) => {
      const active = this.router.url.indexOf(route);
      if (active > -1) {
        routeActive = true;
      }
    });

    return routeActive;
  }

  activeRoute(routename: string): boolean {
    const active = this.router.url.indexOf(routename) !== -1;
    return active;
  }
}
