<ng-select
    [loading]="loadingDrivers"
    ngDefaultControl
    [searchable]="true"
    [clearable]="clearable"
    [disabled]="drivers.length == 0"
    [readonly]="readonly"
    placeholder="{{ placeholder }} ({{ drivers.length }})"
    #input
    [(ngModel)]="value"
    class="form-control table-inline"
    (ngModelChange)="driversChanged()"
>
    <ng-option *ngFor="let driver of drivers | orderBy : 'name' : false" [value]="driver.id">
        <i class="fas fa-fw fa-id-card-alt"></i> {{ driver.name }}
    </ng-option>
</ng-select>
<div style="clear: both"></div>
