import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormMode } from "app/common/enums";
import {
  availablePermissions,
  createPermissionsMap,
  formatSelectedUserPermissions,
  updatePermissionMapWithCurrentUserRoleValues,
} from "app/common/permissionhelper";
import { UserRole } from "app/models/userRole.model";
import { AuthenticationService } from "app/services/authentication/authentication.service";
import { UserRoleService } from "app/services/users/userRole.service";

@Component({
  selector: "fh-fh-user-role-details",
  templateUrl: "userRoleDetails.template.html",
})
export class UserRoleDetailsViewComponent implements OnInit {
  sub: any;
  userRole: UserRole;
  formMode = FormMode.read;
  formModeAssetTypes: FormMode = FormMode.read;

  loading = false;
  saving = false;

  filter;

  availablePermissions = [];
  permissionsMap = [];

  error: any;
  success: any;
  warning: any;

  permissionName = "UserRoles_View";
  permissions: {};
  myUserRole: any;

  constructor(
    private router: Router,
    private cd: ChangeDetectorRef,
    private authentication: AuthenticationService,
    private userRoleService: UserRoleService,
    private route: ActivatedRoute
  ) {
    this.permissions = this.authentication.permissions;
    this.myUserRole = this.authentication.getUserRole();

    this.availablePermissions = availablePermissions;
  }

  ngOnInit(): void {
    this.permissionsMap = createPermissionsMap(this.availablePermissions);

    this.loading = true;
    this.sub = this.route.params.subscribe((params) => {
      const id = params["id"];

      this.getUserRoleInfo(id);
    });
  }

  setFormMode(mode) {
    this.formMode = mode;

    if (this.formMode === FormMode.read) {
      this.loading = true;
      this.getUserRoleInfo();
    }
  }

  clone() {
    this.loading = true;

    setTimeout(() => {
      this.router.navigate(["/Users/UserRoleDetails/Add"], { queryParams: { userRoleId: this.userRole.id } });
    }, 500);
  }

  getUserRoleInfo(id = 0) {
    this.loading = true;
    this.cd.markForCheck();

    if (id === 0) {
      id = this.userRole.id;
    }

    if (this.myUserRole === id.toString()) {
      this.warning = {
        warning: "You cannot edit your own userrole!",
        statusText: "Warning",
      };
    }

    this.userRoleService.getUserRoleById(id.toString()).subscribe(
      (userRole) => {
        this.userRole = userRole;

        this.permissionsMap = createPermissionsMap(this.availablePermissions);
        updatePermissionMapWithCurrentUserRoleValues(this.permissionsMap, this.userRole);

        this.loading = false;
        this.cd.markForCheck();
      },
      (error) => {
        this.success = null;
        this.error = error;
        this.error.statusText = "Error fetching User role";
        this.cd.markForCheck();
        setTimeout(() => {
          this.router.navigate(["/Users/UserRoles/Overview"]);
        }, 3000);
      }
    );
  }

  onDelete() {
    this.loading = true;

    this.userRoleService.deleteUserRole(this.userRole).subscribe(
      (result) => {
        this.error = null;
        this.success = {
          statusText: "success",
          successText: "User role is sucessfully deleted",
        };

        this.loading = false;
        this.cd.markForCheck();

        this.setFormMode(FormMode.read);
      },
      (error) => {
        this.loading = false;
        this.success = null;
        this.error = error;
        this.cd.markForCheck();
      }
    );
  }

  onSave() {
    this.saving = true;
    this.userRole.permissions = formatSelectedUserPermissions(this.permissionsMap);

    this.userRoleService.updateUserRole(this.userRole).subscribe(
      (result) => {
        this.error = null;
        this.success = {
          statusText: "Success",
          successText: "User role is successfully updated",
        };

        this.saving = false;
        this.cd.markForCheck();

        this.setFormMode(FormMode.read);
      },
      (error) => {
        this.saving = false;
        this.success = null;
        this.error = error;
        this.cd.markForCheck();
      }
    );
  }

  accountChanged(accountId: number) {
    console.log("Changed accountId");
    this.userRole.accountId = accountId;
    this.cd.markForCheck();
  }

  resellerChanged(resellerId: number) {
    console.log("Changed reselllerId");
    this.userRole.resellerId = resellerId;
    this.cd.markForCheck();
  }
}
