import { Component, OnInit, ChangeDetectorRef, ViewChild, ChangeDetectionStrategy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Device } from "app/models/device.model";
import { AuditLog } from "../../models/auditlog.model";
import { DeviceService } from "../../services/device/device.service";
import { TranslateService } from "@ngx-translate/core";
import { FormMode } from "app/common/enums";
import { AuthenticationService } from "app/services/authentication/authentication.service";
import { ConfirmationModalComponent } from "../shared/usercontrols/confirmationModal.component";
import { AssetService } from "app/services/asset/asset.service";
import { ArchivingModalComponent } from "../shared/usercontrols/archivingModal.component";
import { Asset } from "app/models/asset.model";
import { AssignmentModalComponent } from "../shared/usercontrols/assignmentModal.component";

@Component({
  selector: "fh-device-assets",
  templateUrl: "assets.template.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceAssetsViewComponent implements OnInit {
  @ViewChild("archiveModal", { static: false }) archiveModal: ConfirmationModalComponent;
  @ViewChild("archiveBody", { static: false }) archiveBody: ArchivingModalComponent;

  @ViewChild("deleteModal") deleteModal: ConfirmationModalComponent;

  @ViewChild("assignmentModal", { static: false }) assignmentModal: ConfirmationModalComponent;
  @ViewChild("assignmentBody", { static: false }) assignmentBody: AssignmentModalComponent;

  deviceLog: AuditLog[];
  sub;
  device: Device;
  deviceId;
  loading = false;
  loadingAssets = false;
  showProp = false;

  selectedAsset;

  error: any;
  success: any;
  warning: any;

  assets = [];
  inactiveAssets = [];

  filter = "";

  formModeAsset: any = FormMode.read;

  currentPage = 1;
  permissions: {};

  permissionName = "Assets_View";

  constructor(
    private cd: ChangeDetectorRef,
    private assetService: AssetService,
    private authenticationService: AuthenticationService,
    private deviceService: DeviceService,
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService
  ) {
    this.device = null;
    this.deviceLog = [];

    this.permissions = this.authenticationService.permissions;
  }

  ngOnInit() {
    this.loading = true;
    this.device = new Device();
    this.device.id = "";

    this.sub = this.route.params.subscribe(
      (params) => {
        const id = params["id"];

        this.deviceId = id;
        this.deviceService.getDeviceById(id).subscribe((device) => {
          this.device = device;
          this.selectedAsset = device.asset;

          this.loadingAssets = true;
          this.deviceService.getAssets(this.device.id).subscribe((assets) => {
            this.assets = assets;

            this.inactiveAssets = assets.filter(
              (asset) => asset.assignmentEnd != null && new Date(asset.assignmentEnd) < new Date()
            );

            this.loadingAssets = false;
            this.cd.markForCheck();
          });

          if (this.device == null) {
            this.router.navigate(["/Devices/Overview"]);
          }

          this.loading = false;
          this.cd.markForCheck();
        });
      },
      (error) => {
        this.error = error;
        this.error.statusText = "Error fetching device";

        this.cd.markForCheck();

        setTimeout(() => {
          this.router.navigate(["/Devices/Overview"]);
        }, 3000);
      }
    );
  }

  setFormModeAsset(mode) {
    this.formModeAsset = mode;

    if (this.formModeAsset === FormMode.read) {
      this.loadingAssets = true;
      this.getDeviceInfo();
    }

    // When adding a new assset
    if (this.formModeAsset === FormMode.add) {
      this.device.asset = new Asset();
      this.device.asset.deviceId = this.device.id;
      this.device.asset.companyName = this.device.companyName;
      this.device.asset.accountId = this.device.accountId;
      this.device.asset.resellerDescription = this.device.resellerDescription;
      this.device.asset.resellerId = this.device.resellerId;
      this.device.asset.assignmentStart = new Date();

      this.selectedAsset = this.device.asset;
    }
  }

  getDeviceInfo() {
    const deviceFromService = this.deviceService.getDeviceById(this.device.id).subscribe((device) => {
      this.device = device;

      this.loadingAssets = true;
      this.cd.markForCheck();

      this.deviceService.getAssets(this.device.id).subscribe((assets) => {
        this.loadingAssets = false;
        this.assets = assets;

        this.selectedAsset = this.assets.find((x) => (x.id = this.device.asset?.id));
        this.inactiveAssets = assets.filter(
          (asset) => asset.assignmentEnd != null && new Date(asset.assignmentEnd) < new Date()
        );

        this.cd.markForCheck();
      });
    });
  }

  onInsertAsset() {
    this.loading = true;

    this.selectedAsset.deviceId = this.device.id;
    this.assetService.saveAsset(this.selectedAsset).subscribe(
      (result) => {
        this.loading = false;

        this.error = null;
        this.success = {
          statusText: "Success",
          success: "Asset is successfully added.",
        };

        this.setFormModeAsset(FormMode.read);
        this.getDeviceInfo();
      },
      (error) => {
        this.success = null;
        this.error = error;
        this.loading = false;
        this.cd.markForCheck();
      }
    );
  }

  onSaveAsset() {
    this.loading = true;

    if (this.selectedAsset?.ownership?.label) {
      this.selectedAsset.ownership = this.selectedAsset.ownership.label;
    }

    if (this.selectedAsset?.fuelType?.label) {
      this.selectedAsset.fuelType = this.selectedAsset.fuelType.label;
    }

    this.assetService.updateAsset(this.selectedAsset).subscribe(
      (result) => {
        this.loading = false;

        this.error = null;
        this.success = {
          statusText: "Success",
          success: "Asset is successfull updated",
        };

        this.setFormModeAsset(FormMode.read);
        this.getDeviceInfo();
      },
      (error) => {
        this.success = null;
        this.error = error;
        this.loading = false;
        this.cd.markForCheck();
      }
    );
  }

  onChangeAssetAssignment(event) {
    this.assignmentModal.hideModal();

    this.loading = true;
    this.assetService
      .updateAssetAssignment(
        this.device.id,
        this.selectedAsset.id,
        this.assignmentBody.startDate,
        this.assignmentBody.archiveDate
      )
      .subscribe(
        (result) => {
          this.loading = false;

          this.error = null;
          this.success = {
            statusText: "Success",
            success: "Asset assignment is successfully updated",
          };

          this.setFormModeAsset(FormMode.read);
          this.getDeviceInfo();
        },
        (error) => {
          this.loading = false;
          this.success = null;
          this.error = error;
          this.cd.markForCheck();
        }
      );
  }

  showAssignmentAsset(id) {
    this.assignmentModal.showModal(id);
  }

  showArchive(id) {
    this.archiveModal.showModal(id);
  }

  onArchiveAsset(event) {
    this.archiveModal.hideModal();

    this.loading = true;
    this.assetService.archiveAsset(this.device.id, this.selectedAsset.id, this.archiveBody.archiveDate).subscribe(
      (result) => {
        this.loading = false;

        this.error = null;
        this.success = {
          statusText: "Success",
          success: "Asset is successfull archived",
        };

        this.setFormModeAsset(FormMode.read);
        this.getDeviceInfo();
      },
      (error) => {
        this.loading = false;
        this.success = null;
        this.error = error;
        this.cd.markForCheck();
      }
    );
  }

  showDelete(assetId) {
    this.deleteModal.showModal(assetId);
  }

  deleteAssetAssignment(id) {
    this.deleteModal.hideModal();
    this.loading = true;
    this.assetService.deleteAsset(id, this.device.id).subscribe(
      (task) => {
        if (task?.isSuccess) {
          this.error = null;

          if (task.message?.startsWith("Please")) {
            this.success = {
              statusText: "Warning",
              success: task.message,
            };
          } else {
            this.success = {
              statusText: "Success",
              success: task.message,
            };
          }
        } else {
          this.error = task?.message ?? task?.error;
        }
        this.loading = false;
        this.getDeviceInfo();
      },
      (error) => {
        this.loading = false;
        this.success = null;
        this.error = error;
        this.cd.markForCheck();
      }
    );
  }

  reactivateAsset(id) {
    this.loading = true;
    this.assetService.reactivateAsset(id, this.device.id).subscribe(
      (task) => {
        if (task?.isSuccess) {
          this.error = null;

          if (task.message?.startsWith("Please")) {
            this.success = {
              statusText: "Warning",
              success: task.message,
            };
          } else {
            this.success = {
              statusText: "Success",
              success: task.message,
            };
          }
        } else {
          this.error = task?.message ?? task?.error;
        }
        this.loading = false;
        this.getDeviceInfo();
      },
      (error) => {
        this.loading = false;
        this.success = null;
        this.error = error;
        this.cd.markForCheck();
      }
    );
  }

  showProperties() {
    if (this.showProp) {
      this.showProp = false;
    } else {
      this.showProp = true;
    }
  }
}
