import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { DeviceSensorType } from "app/models/sensor.model";
import { AuthenticationService } from "app/services/authentication/authentication.service";
import { FhChartService } from "../../services/charts/charts.service";

import { slideInOut } from "app/common/animations";

// Moment
import Moment from "moment-timezone";
import { WarehouseService } from "app/services/warehouse/warehouse.service";
import { Warehouse, WarehouseZone } from "app/models/warehouse.model";
import { ModalDirective } from "ngx-bootstrap/modal";
import { FormMode } from "app/common/enums";
window["moment"] = Moment;

@Component({
  selector: "fh-warehouse-zones",
  templateUrl: "zones.template.html",
  providers: [FhChartService],
  animations: [slideInOut],
})
export class WarehouseZonesViewComponent implements OnInit {
  @ViewChild("addModal") addModal: ModalDirective;
  formMode = FormMode.add;
  zone: WarehouseZone = new WarehouseZone();

  timeoutHandler;
  chartRpm: any;
  chartSpeed: any;
  sensors;
  sub;

  loading = false;
  loadingZones = false;
  saving = false;

  sensorTypes: DeviceSensorType[];
  timezoneIana: string;

  error;
  success;

  locations = [];
  geofences = [];
  zones = [];

  permissionName = "Warehouses_View";

  warehouse: Warehouse;
  warehouseId: any;

  constructor(
    private chartService: FhChartService,
    private warehouseService: WarehouseService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.warehouse = null;
    this.zones = [];

    this.timezoneIana = authenticationService.getTimeZoneIana();
  }

  ngOnInit() {
    this.warehouse = new Warehouse();
    this.loading = true;
    this.warehouse.id = "";

    this.sub = this.route.params.subscribe((params) => {
      const id = params["id"];

      this.warehouseId = id;
      this.warehouseService.getWarehouseById(id).subscribe(
        (warehouse) => {
          this.warehouse = warehouse;

          this.getZones();

          this.loading = false;
        },
        (error) => {
          this.error = error;
          this.error.statusText = "Error fetching warehouse";

          setTimeout(() => {
            this.router.navigate(["/Warehouses/Overview"]);
          }, 3000);
        }
      );
    });
  }

  getZones() {
    this.loadingZones = true;
    this.error = undefined;
    this.warehouseService.getWarehouseZonesByReference(this.warehouse.referenceId).subscribe(
      (zones) => {
        this.zones = zones;

        this.loadingZones = false;
      },
      (error) => {
        this.loadingZones = false;
        this.error = error;
      }
    );
  }

  // Adding
  showModal() {
    this.addModal.show();
  }

  hideModal() {
    this.addModal.hide();

    this.zone = new WarehouseZone();
  }

  confirm() {
    this.addModal.hide();

    this.zone.warehouseId = this.warehouse.referenceId;
    this.warehouseService.saveWarehouseZone(this.zone).subscribe(
      (result) => {
        this.error = null;
        this.success = {
          statusText: "Success",
          success: "WarehouseZone Zone is successfully added.",
        };

        this.saving = false;
        this.getZones();
      },
      (error) => {
        this.saving = false;
        this.success = null;
        this.error = error;
      }
    );

    this.zone = new WarehouseZone();
  }
}
