import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { ControlContainer, NgForm } from "@angular/forms";
import { FormMode } from "app/common/enums";

@Component({
  selector: "fh-dashcams-admin",
  templateUrl: "dashcamsAdmin.component.html",
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class DashcamsAdminComponent implements OnInit {
  @Input() dashcams: any;
  @Input() formMode: any = FormMode.read;
  @Output() dashcamsChange = new EventEmitter<any>();

  customName: string;
  customUrl: string;

  constructor() {}

  ngOnInit(): void {}

  addRow() {
    this.dashcams.push({ name: this.customName, url: this.customUrl });

    this.customName = "";
    this.customUrl = "";

    this.dashcamsChange.emit(this.dashcams);
  }

  removeRow(data) {
    const index: number = this.dashcams.indexOf(data);

    if (index !== -1) {
      this.dashcams.splice(index, 1);
    }

    this.dashcamsChange.emit(this.dashcams);
  }
}
