<fh-wizard [(step)]="step" (finish)="onCheckOutFinish()">
    <fh-wizard-step title="general.getLocations" icon="fa-user" (open)="initForm()" [validate]="groupForm">
        <div class="wizard-content" style="padding-bottom: 10px">
            {{'wizard.waslUnregisterNotification' | translate }}
        </div>
        <div class="wizard-content" style="padding-bottom: 10px">
            <form [formGroup]="groupForm" style="width: 100%; border-top: 0px">
                <table style="width: 100%" class="table table-fixed nowrap dtr-inline no-footer">
                    <tbody>
                        <tr>
                            <td style="width: 25%; border-bottom: 0px !important">
                                {{'general.thirdPartyProvider' | translate }}

                                <span class="error" *ngIf="groupForm.get('selectedThirdPartyProvider').errors">*</span>
                            </td>
                            <td style="width: 100%; border-bottom: 0px !important">
                                <ng-select
                                    name="selectedThirdPartyProvider"
                                    ngDefaultControl
                                    placeholder="{{ 'general.chooseThirdPartyProvider' | translate}}"
                                    style="width: 100%"
                                    [searchable]="false"
                                    [clearable]="false"
                                    class="form-control table-inline"
                                    formControlName="selectedThirdPartyProvider"
                                >
                                    <ng-option
                                        *ngFor="let thirdPartyProvider of thirdPartyProviders"
                                        [value]="thirdPartyProvider.id"
                                    >
                                        {{ thirdPartyProvider.value }}
                                    </ng-option>
                                </ng-select>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </div>
    </fh-wizard-step>
    <fh-wizard-step title="Remarks" icon="fa-file-pdf">
        <div class="wizard-content" style="padding-bottom: 10px">{{ 'wizard.optionalComment' | translate }}</div>
        <div class="wizard-content">
            <textarea
                class="form-control noresize stickypad"
                style="height: 100px; width: 100%; resize: none"
                placeholder="{{ 'placeholder.comments' | translate}}"
                [(ngModel)]="logItem"
            ></textarea>
        </div>
    </fh-wizard-step>
    <fh-wizard-step title="Summary" icon="fa-summary-pdf">
        <div class="wizard-content" style="padding-bottom: 10px">
            {{ 'wizard.summary' | translate:{ count: gridSelection.length } }}
        </div>
    </fh-wizard-step>
</fh-wizard>
