import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable, catchError, map } from "rxjs";
import { AuthenticationService } from "../authentication/authentication.service";
import { LoggingService } from "../logging/logging.service";
import { throwError } from "rxjs/internal/observable/throwError";

@Injectable()
export class ExportService {
  exportUrl = "";
  importUrl = "";

  constructor(
    private http: HttpClient,
    private loggingService: LoggingService,
    private authenticationService: AuthenticationService
  ) {
    this.exportUrl = this.authenticationService.getWebserviceURL("export");
    this.importUrl = this.authenticationService.getWebserviceURL("import");
  }

  getExport(accountId: any, resellerId: number, entityId: number): Observable<any> {
    return this.http
      .get(this.exportUrl + `?accountId=${accountId ?? ""}&resellerId=${resellerId}&entityId=${entityId}`, {
        headers: this.authenticationService.headers,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  postImport(accountId: any, resellerId: number, entityId: number, data): Observable<any> {
    return this.http
      .post(this.importUrl + `?accountId=${accountId ?? ""}&resellerId=${resellerId}&entityId=${entityId}`, data, {
        headers: this.authenticationService.headers,
      })
      .pipe(
        map((result) => {
          return result;
        }),
        catchError(this.handleError)
      );
  }

  private handleError(error: Response) {
    return throwError(error);
  }
}
