import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { DeviceSensorType } from "app/models/sensor.model";
import { AuthenticationService } from "app/services/authentication/authentication.service";
import { slideInOut } from "app/common/animations";

// Moment
import Moment from "moment-timezone";
import { WarehouseService } from "app/services/warehouse/warehouse.service";
import { Warehouse } from "app/models/warehouse.model";
import { roundAsNumber } from "app/common/globals";
import { timer } from "rxjs";
import { mergeMap } from "rxjs/operators";
window["moment"] = Moment;

@Component({
  selector: "fh-warehouse-sensors",
  templateUrl: "sensors.template.html",
  animations: [slideInOut],
})
export class WarehouseSensorsViewComponent implements OnInit, OnDestroy {
  timeoutHandler;
  chartRpm: any;
  chartSpeed: any;
  sensors;
  sub;

  loading = false;
  loadingZones = false;
  saving = false;
  loadingSensorData = false;
  isSpinning = false;

  sensorTypes: DeviceSensorType[];
  timezoneIana: string;

  error;
  success;

  locations = [];
  geofences = [];
  zones = [];
  sensorData = [];

  permissionName = "Warehouses_View";

  warehouse: Warehouse;
  locationSubscription: any;

  previousLookupDisplay: Moment.Moment;
  warehouseId: any;
  permissions: {};

  constructor(
    private warehouseService: WarehouseService,
    private authenticationService: AuthenticationService,
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.warehouse = null;
    this.zones = [];

    this.timezoneIana = this.authenticationService.getTimeZoneIana();
    this.permissions = this.authenticationService.permissions;
  }
  log(val) {
    console.log(val);
  }
  ngOnInit() {
    this.warehouse = new Warehouse();
    this.loading = true;
    this.loadingSensorData = true;

    this.warehouse.id = "";

    this.sub = this.route.params.subscribe((params) => {
      const id = params["id"];

      this.warehouseId = id;
      this.warehouseService.getWarehouseById(id).subscribe({
        next: (warehouse) => {
          this.warehouse = warehouse;

          if (this.locationSubscription !== undefined) {
            this.locationSubscription.unsubscribe();
          }

          // Get sensors from azure
          this.getSensorData();

          this.loading = false;
        },
        error: (error) => {
          this.error = error;
          this.error.statusText = "Error fetching warehouse";

          setTimeout(() => {
            this.router.navigate(["/Warehouses/Overview"]);
          }, 3000);
        },
      });
    });
  }

  ngOnDestroy(): void {
    if (this.locationSubscription !== undefined) {
      this.locationSubscription.unsubscribe();
    }
  }

  refresh() {
    this.getSensorData();
  }
  getSensorData() {
    this.loadingSensorData = true;

    this.locationSubscription = timer(0, 30000)
      .pipe(
        mergeMap(() => {
          // Start the spinner
          this.isSpinning = true;
          this.cd.markForCheck();

          return this.warehouseService.getWarehouseLatestDataByReference(this.warehouse.referenceId);
        })
      )
      .subscribe({
        next: (result) => {
          this.loadingSensorData = false;
          this.previousLookupDisplay = Moment.utc(result.timestamp)["tz"](this.timezoneIana);

          // Stop the spinner
          setTimeout(() => {
            this.isSpinning = false;
            this.cd.markForCheck();
          }, 500);

          result.forEach((item) => {
            item.markerColor = item.isViolated ? "red" : "green";
          });

          this.sensorData = result;
        },
        error: (error) => {
          if (this.locationSubscription !== undefined) {
            this.locationSubscription.unsubscribe();
          }
          this.loadingSensorData = false;
          this.error = error;
          this.cd.markForCheck();
        },
      });
  }

  actualRound(value, decimals) {
    return roundAsNumber(value, decimals);
  }
}
