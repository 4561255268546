<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/"> <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/System">{{'general.system' | translate}}</a>
                </li>
                <li class="breadcrumb-item active">
                    <a href="/#/Notifications/Log/Overview">{{'general.notificationLog' | translate }}</a>
                </li>
                <li class="breadcrumb-item active">{{'general.dashboard' | translate }}</li>
            </ol>
        </nav>
    </div>
    <fh-notifications-navigation></fh-notifications-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">
        <div class="row" data-equalizer="" *ngIf="error">
            <div class="col-lg-12">
                <fh-error [error]="error"></fh-error>
            </div>
        </div>
        <div class="row" data-equalizer="">
            <div class="col-lg-12">
                <div class="ibox">
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-envelope-open-text"></i> {{'general.notifications' | translate }}
                        </h5>
                    </div>
                    <div class="ibox-content slick" style="position: relative">
                        <div *ngIf="!notificationLog" style="padding: 12px !important">
                            {{'general.noNotificationSelected' | translate }}
                        </div>
                        <table
                            style="width: 100%"
                            class="table nowrap dtr-inline no-footer table-fixed"
                            *ngIf="notificationLog"
                        >
                            <tbody>
                                <tr>
                                    <td width="120px">{{'general.asset' | translate }}</td>
                                    <td>
                                        <a
                                            class="secondary link_bolder"
                                            href="/#/DeviceDetails/Index/{{notificationLog?.deviceId}}"
                                            >{{ notificationLog.assetName }}</a
                                        >
                                    </td>
                                </tr>
                                <tr>
                                    <td width="120px">{{'general.date' | translate }}</td>
                                    <td title="{{ notificationLog.timeStampSending?.toLocaleString() }}">
                                        {{ notificationLog.timeStampSending | amDateFormat: 'LLL' }}
                                    </td>
                                </tr>
                                <tr>
                                    <td width="120px">{{'general.recipient' | translate }}</td>
                                    <td>{{ notificationLog.recipient }}</td>
                                </tr>
                                <tr>
                                    <td width="120px">{{'general.notificationType' | translate }}</td>
                                    <td>
                                        {{ ('enums.notificationType.' + notificationLog.notificationType) | translate}}
                                    </td>
                                </tr>
                                <tr>
                                    <td width="120px">{{'general.notificationMethod' | translate }}</td>
                                    <td>
                                        {{ ('enums.notificationMethod.' + notificationLog.notificationMethod) |
                                        translate}}
                                    </td>
                                </tr>
                                <tr *ngIf="notificationLog.triggerId">
                                    <td width="120px">{{'general.trigger' | translate }}</td>
                                    <td>
                                        <a
                                            class="secondary link_bolder"
                                            href="/#/Notifications/TriggerDetails/Index/{{notificationLog.triggerId}}"
                                            >{{ notificationLog.triggerName }}</a
                                        >
                                    </td>
                                </tr>
                                <tr>
                                    <td width="120px">{{'general.responseCode' | translate }}</td>
                                    <td>{{ notificationLog?.responseCode }}</td>
                                </tr>
                                <tr>
                                    <td width="120px">{{'general.subject' | translate }}</td>
                                    <td>{{ notificationLog.subject }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="ibox-content" *ngIf="notificationLog" style="position: relative">
                        <pretty-json *ngIf="checkJson(notificationLog?.message)" [obj]="notificationLog?.message">
                        </pretty-json>

                        <span *ngIf="!checkJson(notificationLog?.message)" [innerHTML]="notificationLog.message">
                            {{notificationLog?.message}}
                        </span>
                    </div>
                </div>

                <div class="ibox" *ngIf="notificationLog?.responseMessage">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-envelope-open-text"></i> {{'general.response' | translate }}</h5>
                    </div>
                    <div class="ibox-content" style="position: relative">
                        <pretty-json
                            *ngIf="checkJson(notificationLog?.responseMessage)"
                            [obj]="notificationLog?.responseMessage"
                        >
                        </pretty-json>

                        <span
                            *ngIf="!checkJson(notificationLog?.responseMessage)"
                            style="white-space: pre-line"
                            [innerHTML]="notificationLog.responseMessage"
                        >
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</fh-permission-handler>
