import { Injectable } from "@angular/core";
import { Question } from "../../models/question";

@Injectable()
export class FaqService {
  questions: Question[];
  constructor() {
    this.questions = [];
  }

  getQuestions() {
    if (localStorage.getItem("questions") !== null) {
      this.questions = JSON.parse(localStorage.getItem("questions"));
      console.log(this.questions);
    } else {
      console.log("questions = 0");
      this.questions = [
        {
          text: "What is this application ?",
          answer:
            "With this application you can analise the devices in your assets. Many times you only see problems with your assets very late. We made this application to help you",
          hide: true,
        },
        {
          text: "Who are you?",
          answer:
            "We are 360Locate, A company based in Rotterdam, Netherlands... specialised in asset tracking and analising.",
          hide: true,
        },
      ];
    }

    return this.questions;
  }

  addQuestion(question: Question) {
    this.questions.unshift(question);
    let questionsLS = [];
    if (localStorage.getItem("questions") != null) {
      questionsLS = JSON.parse(localStorage.getItem("questions"));
    }
    questionsLS.unshift(question);
    localStorage.setItem("questions", JSON.stringify(questionsLS));
  }

  removeQuestion(question: Question) {
    for (let i = 0; i < this.questions.length; i++) {
      if (this.questions[i] === question) {
        this.questions.splice(i, 1);
        localStorage.setItem("questions", JSON.stringify(this.questions));
      }
    }
  }
}
