import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from "app/services/authentication/authentication.service";
import { FhChartService } from "../../services/charts/charts.service";

import { getDefaultDpConfig } from "app/common/gridhelper";
import { roundAsNumber } from "app/common/globals";

import { slideInOut } from "app/common/animations";

// Moment
import Moment from "moment-timezone";
import { WarehouseService } from "app/services/warehouse/warehouse.service";
import { Warehouse } from "app/models/warehouse.model";
window["moment"] = Moment;

import * as Highcharts from "highcharts";

@Component({
  selector: "fh-warehouse-charts",
  templateUrl: "charts.template.html",
  providers: [FhChartService],
  animations: [slideInOut],
})
export class WarehouseChartsViewComponent implements OnInit, OnDestroy {
  Highcharts: typeof Highcharts = Highcharts;

  timeoutHandler;

  sensors;
  sub;
  loading = false;
  loadingSensors = false;

  timezoneIana: string;
  daterangepickerModel: Date[];
  dpConfig: {};

  error;
  success;

  charts = [];

  filterZeroValues = true;

  permissionName = "Warehouses_View";

  warehouse: Warehouse;
  warehouseId: any;

  constructor(
    private chartService: FhChartService,
    private warehouseService: WarehouseService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.warehouse = null;
    this.sensors = [];

    this.timezoneIana = this.authenticationService.getTimeZoneIana();

    this.daterangepickerModel = [
      Moment().tz(this.timezoneIana).subtract(2, "days").startOf("day").toDate(),
      Moment().tz(this.timezoneIana).endOf("day").toDate(),
    ];

    this.dpConfig = getDefaultDpConfig(authenticationService);
  }

  ngOnInit() {
    this.warehouse = new Warehouse();
    this.loading = true;
    this.warehouse.id = "";

    this.sub = this.route.params.subscribe((params) => {
      const id = params["id"];

      this.warehouseId = id;
      this.warehouseService.getWarehouseById(id).subscribe({
        next: (warehouse) => {
          this.warehouse = warehouse;

          this.dateChanged(null);

          this.loading = false;
        },
        error: (error) => {
          this.error = error;
          this.error.statusText = "Error fetching warehouse";

          setTimeout(() => {
            this.router.navigate(["/Warehouses/Overview"]);
          }, 3000);
        },
      });
    });
  }

  dateChanged(event) {
    this.generateData(
      this.warehouse.id,
      Moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf("day"),
      Moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf("day")
    );
  }

  generateData(id, start, end) {
    this.loadingSensors = true;
    this.error = undefined;
    this.warehouseService.getWarehouseZoneDataByReference(this.warehouse.referenceId, start, end).subscribe({
      next: (sensors) => {
        this.sensors = sensors;
        this.drawChart(sensors);

        this.loadingSensors = false;
      },
      error: (error) => {
        this.loadingSensors = false;
        this.error = error;
      },
    });
  }

  drawChart(sensors) {
    sensors.forEach((sensor) => {
      const theData = [];

      const dataTemperature = [];
      const dataHumidity = [];

      const sortedData = sensor.sensorData.sort((a, b) => (a.timestamp < b.timestamp ? -1 : 1));

      sortedData.forEach((sensorData) => {
        dataTemperature.push([
          Moment.utc(sensorData.timestamp)["tz"](this.timezoneIana).unix() * 1000,
          roundAsNumber(sensorData.temperature, 1),
        ]);
        dataHumidity.push([
          Moment.utc(sensorData.timestamp)["tz"](this.timezoneIana).unix() * 1000,
          roundAsNumber(sensorData.humidity, 0),
        ]);
      });

      theData.push({
        name: sensor.zoneName + " °C",
        type: "spline",
        visible: true,
        yAxis: 0,
        data: dataTemperature,
      });

      theData.push({
        name: sensor.zoneName + "%",
        type: "spline",
        visible: true,
        yAxis: 1,
        data: dataHumidity,
      });

      this.charts[sensor.sensorId] = this.chartService.generateSensorChart(theData);
    });
  }

  ngOnDestroy(): void {
    clearTimeout(this.timeoutHandler);
  }
}
