<div class="vertical-center" (document:mousemove)="onMouseMove($event)">
    <div class="page-content-inner login-background">
        <div class="container loginscreen" style="display: table-cell; vertical-align: middle; height: 100% !important">
            <div class="login-box well single-page-block-inner blur-placeholder effect-3d-element">
                <div class="text-center" style="min-height: 145px">
                    <div class="alert alert-danger" *ngIf="error">
                        <strong>{{error}}</strong>
                    </div>
                    <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '14px' }"></ngx-loading>
                    <form novalidate #f="ngForm" action="">
                        <div class="site_login_logo"></div>
                        <h1 style="font-size: 40px; padding-bottom: 20px; display: none" class="text-center">
                            {{'general.title' | translate }}
                        </h1>
                        <div class="form-group col-sm-12" [ngClass]="{ 'has-error': f.submitted && !username.valid }">
                            <input
                                type="text"
                                class="form-control"
                                name="firstName"
                                placeholder="First Name"
                                [(ngModel)]="model.firstName"
                                #firstName="ngModel"
                                required
                            />
                            <div *ngIf="f.submitted && !firstName.valid" class="error">First Name is required</div>
                        </div>
                        <div class="form-group col-sm-12" [ngClass]="{ 'has-error': f.submitted && !username.valid }">
                            <input
                                type="text"
                                class="form-control"
                                name="lastName"
                                placeholder="Last Name"
                                [(ngModel)]="model.lastName"
                                #lastName="ngModel"
                                required
                            />
                            <div *ngIf="f.submitted && !lastName.valid" class="error">Last Name is required</div>
                        </div>
                        <div class="form-group col-sm-12" [ngClass]="{ 'has-error': f.submitted && !username.valid }">
                            <input
                                type="text"
                                class="form-control"
                                name="username"
                                placeholder="{{'login.emailoruser' | translate }}"
                                [(ngModel)]="model.username"
                                #username="ngModel"
                                required
                            />
                            <div *ngIf="f.submitted && !username.valid" class="error">Username is required</div>
                        </div>
                        <div class="form-group col-sm-12" [ngClass]="{ 'has-error': f.submitted && !password.valid }">
                            <input
                                type="password"
                                class="form-control"
                                name="password"
                                placeholder="{{'login.password' | translate }}"
                                [(ngModel)]="model.password"
                                #password="ngModel"
                                required
                            />
                            <div *ngIf="f.submitted && !password.valid" class="error">Password is required</div>
                        </div>
                        <div class="form-group col-sm-6">
                            <button [disabled]="loading" class="btn btn-primary btn-block">
                                {{'login.register' | translate }}
                            </button>
                        </div>
                        <div class="form-group col-sm-6">
                            <button type="button" [routerLink]="['/Login']" class="btn btn-light btn-block">
                                {{'login.cancel' | translate }}
                            </button>
                        </div>
                    </form>
                    <footer class="col-sm-12">
                        <div class="copy-text col-sm-12">
                            {{'login.footer' | translate }}
                            <a href="https://360locate.com" target="_blank">360locate</a> - {{appVersion}}
                        </div>
                    </footer>
                </div>
            </div>
        </div>
    </div>
</div>
