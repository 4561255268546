<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/"> <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Reporting">{{'general.reporting' | translate }}</a>
                </li>
                <li class="breadcrumb-item active">{{'general.create' | translate }}</li>
            </ol>
        </nav>

        <span style="margin-left: auto" class="fa-filter-container noselect">
            <a
                style="margin-left: 2px"
                class="btn btn-info margin-fix d-none d-md-inline-flex"
                title="Help"
                href="https://help.360locate.com/knowledge-base/create-report"
                target="_blank"
                ><i class="fa fa-fa fa-book" style="margin-right: 6px"></i>
                <span class="d-none d-md-inline-flex">{{'general.needHelp' | translate }}</span></a
            >
        </span>
    </div>
    <fh-reporting-navigation>
        <li class="active">
            <a [routerLink]="['/Reporting/Create']">
                <i class="fa fa-fw fa-user-chart"></i>
                <div>{{'general.createReport' | translate }}</div>
            </a>
        </li>
    </fh-reporting-navigation>
</div>

<div class="wrapper wrapper-content" style="position: relative">
    <div class="row">
        <div class="col-lg-12">
            <fh-error [success]="success" [error]="error"></fh-error>
        </div>
    </div>

    <ngx-loading [show]="processing"></ngx-loading>

    <fh-report-wizard
        [reportSubscription]="reportSubscription"
        [formMode]="formMode"
        (finish)="onCheckOutFinish()"
        [resellerChanged]="resellerChanged"
        [accountChanged]="accountChanged"
        (subscription)="reportSubscription = $event"
        (reportWizard)="wizard = $event"
    >
    </fh-report-wizard>
</div>
