import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import videojs from "video.js";

@Component({
  selector: "app-vjs-player",
  templateUrl: "./vjs-player.template.html",
  styleUrls: ["./vjs-player.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class VjsPlayerComponent implements OnInit, OnDestroy {
  @ViewChild("target", { static: true }) target: ElementRef;

  @Input() options;
  player;
  playlist = [];

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    // instantiate Video.js
    const that = this;

    this.playlist = [];

    this.options.sources.forEach((source) => {
      that.playlist.push({
        url: source.src,
        isLive: false,
        title: source.name,
        length: "",
        playing: false,
      });
    });

    this.player = videojs(this.target.nativeElement, this.options, function onPlayerReady() {});

    var last_video_index = 0;

    function build_list_item(s) {
      var isLive = s.isLive.toString();
      var isPlaying = s.playing.toString();
      var e = $(
        `<li class='${isPlaying == "true" ? "isPlaying " : ""} ${isLive == "true" ? "isLive " : ""}'>${s.title}</li>`
      );
      e.click((i) => {
        that.player.src(s.url);
        that.playlist[last_video_index].playing = false;
        last_video_index = that.playlist.indexOf(s);
        that.playlist[last_video_index].playing = true;
        populate_playlist();
      });
      return e;
    }
    function populate_list(data, elem) {
      elem.html("");
      for (var item of data) {
        elem.append(build_list_item(item));
      }
    }
    function populate_playlist() {
      populate_list(that.playlist, $("#plylst_upcming"));
    }
    populate_playlist();
  }

  ngOnDestroy() {
    // destroy player
    if (this.player) {
      this.player.dispose();
    }
  }
}
