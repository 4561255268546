<div class="ibox" *ngIf="loading">
    <div class="ibox-title">
        <h5><i class="fas fa-fw fa-fa-file-chart-pie"></i> {{'general.report' | translate }}</h5>
    </div>
    <div class="ibox-content" style="position: relative">
        <ngx-loading [show]="loading"></ngx-loading>
        {{'general.loadingDetails' | translate }}
    </div>
</div>

<div class="ibox" *ngIf="!loading && reportData?.kpiList?.length == 0 && reportData?.data?.length == 0">
    <div class="ibox-title">
        <h5><i class="fas fa-fw fa-fa-file-chart-pie"></i> {{'general.report' | translate }}</h5>
    </div>
    <div class="ibox-content" style="position: relative">{{'general.noDataFound' | translate }}</div>
</div>

<div class="ibox" *ngIf="reportData?.kpiList?.length > 0">
    <div class="ibox-title">
        <h5><i class="fas fa-fw fa-list-check"></i> {{'general.summary' | translate }}</h5>
    </div>
    <div class="ibox-content slick" style="position: relative">
        <div class="flex-container">
            <div class="col-6 col-lg-3" style="padding: 0" *ngFor="let kpi of reportData?.kpiList; let idx = index;">
                <div class="kpi-item">
                    <h3 class="margin-bottom font-bold">
                        <i *ngIf="testUnicode(kpi.icon)" class="fas fa-fw">{{ kpi.icon }}</i> {{
                        kpi.value?.toLocaleString() ?? '-' }} {{ kpi.uom }}

                        <span class="float-right" *ngIf="kpi.delta">
                            <span
                                class="label compare-label hand"
                                title="{{ kpi.delta + ' ' + ('general.comparedTo' | translate)  + ' ' + reportData?.previousKpiList[idx]?.value?.toLocaleString() + ' ' + kpi.previousPeriodStart + ' - ' + kpi.previousPeriodEnd  }}"
                                [ngClass]="{'green' : kpi.delta > 0, 'red': kpi.delta < 0}"
                            >
                                {{ abs(kpi.delta).toLocaleString() }}
                            </span>
                        </span>
                    </h3>
                    <div>{{ kpi.name }}</div>
                </div>
            </div>
        </div>
        <div style="clear: both"></div>
    </div>
</div>

<div id="charts">
    <div class="ibox" *ngFor="let data of reportData?.charts; let idx=index;">
        <div class="ibox-title">
            <h5><i *ngIf="testUnicode(data.icon)" class="fas fa-fw">{{ data.icon }}</i> {{ data.name }}</h5>
            <span class="float-right">
                <ibox-chart-export [chartObject]="charts[idx]"> </ibox-chart-export>
            </span>
        </div>
        <div class="ibox-content slicker" style="position: relative">
            <div>
                <highcharts-chart
                    [runOutsideAngular]="true"
                    style="display: block; width: 100%; height: 200px"
                    [Highcharts]="Highcharts"
                    [options]="charts[idx]"
                ></highcharts-chart>
            </div>
            <div style="clear: both"></div>
        </div>
    </div>
</div>

<!-- page all items -->
<div>
    <div *ngIf="formulaErrors > 0">
        <fh-error [error]="mathError"></fh-error>
    </div>

    <div class="ibox" *ngIf="reportDataFlat?.data?.length > 1">
        <div class="ibox-title">
            <h5>
                <i class="fas fa-fw fa-file-lines"></i> {{'general.reportSections' | translate }}
                ({{reportDataFlat?.data?.length}})
            </h5>

            <span class="float-right">
                <div class="filter-form d-none d-md-block">
                    <input
                        type="text"
                        class="form-control table-inline top-filter d-none d-md-block"
                        [(ngModel)]="filterCategories"
                    />
                </div>
            </span>
        </div>
        <div class="ibox-content">
            <span class="noselect">
                <pagination-template
                    #p="paginationApi"
                    (pageChange)="currentPageSections = $event; resetSubPage($event)"
                    [maxSize]="50"
                    id="pager_all"
                >
                    <div
                        class="dataTables_paginate paging_simple_numbers"
                        style="display: block; position: relative; margin-top: -5px; margin-right: -5px"
                        id="dataTable_paginate"
                    >
                        <ul class="pagination">
                            <li
                                class="paginate_button paginate_button_itemselector page-item previous"
                                [class.disabled]="p.isFirstPage()"
                            >
                                <a class="page-link" (click)="p.previous()" *ngIf="!p.isFirstPage()">
                                    <i class="fa-solid fa-arrow-left-long"></i>
                                </a>
                                <a class="page-link" *ngIf="p.isFirstPage()">
                                    <i class="fa-solid fa-arrow-left-long"></i>
                                </a>
                            </li>

                            <li
                                class="paginate_button paginate_button_itemselector page-item"
                                *ngFor="let page of p.pages | reportCategoryFilter: filterCategories : reportDataFlat?.data"
                                [class.current]="p.getCurrent() === page.value"
                            >
                                <a
                                    class="page-link"
                                    (click)="p.setCurrent(page.value)"
                                    *ngIf="p.getCurrent() !== page.value"
                                >
                                    <span [innerHtml]="checkPage(page, false) | byPassSafeHtml"></span>
                                </a>
                                <a
                                    class="page-link"
                                    (click)=" p.setCurrent(page.value)"
                                    *ngIf="p.getCurrent() === page.value"
                                >
                                    <span [innerHtml]="checkPage(page, true) | byPassSafeHtml"></span>
                                </a>
                            </li>

                            <li
                                class="paginate_button paginate_button_itemselector page-item next"
                                [class.disabled]="p.isLastPage()"
                            >
                                <a class="page-link" (click)="p.next()" *ngIf="!p.isLastPage()">
                                    <i class="fa-solid fa-arrow-right-long"></i>
                                </a>
                                <a class="page-link" *ngIf="p.isLastPage()">
                                    <i class="fa-solid fa-arrow-right-long"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </pagination-template>
            </span>
        </div>
    </div>

    <!-- Trip with details  -->
    <div *ngIf="activeReport?.ReportType == 2 || activeReport?.ReportType == 32">
        <div
            *ngFor=" let item of reportData?.data | paginate: { itemsPerPage: 1, currentPage: currentPageSections, id: 'pager_all' }; let idx=index;"
        >
            <span *ngIf="resetSubPage(idx)"></span>

            <div class="ibox">
                <div class="ibox-title">
                    <h5><i class="fas fa-fw fa-route"></i> {{'general.trips' | translate }} - {{ item.Name }}</h5>
                </div>
                <div class="ibox-content">
                    <span
                        class="noselect"
                        style="display: block; position: relative; margin-top: -5x; margin-right: 0px"
                    >
                        <pagination-template
                            #p2="paginationApi"
                            (pageChange)="currentPageArray[idx] = $event"
                            id="pager_{{0}}"
                        >
                            <div class="dataTables_paginate paging_simple_numbers" id="dataTable_paginate">
                                <ul class="pagination">
                                    <li
                                        class="paginate_button paginate_button_itemselector page-item previous"
                                        [class.disabled]="p2.isFirstPage()"
                                    >
                                        <a class="page-link" (click)="p2.previous()" *ngIf="!p2.isFirstPage()">
                                            <i class="fa-solid fa-arrow-left-long"></i>
                                        </a>
                                        <a class="page-link" *ngIf="p2.isFirstPage()">
                                            <i class="fa-solid fa-arrow-left-long"></i>
                                        </a>
                                    </li>

                                    <li
                                        class="paginate_button paginate_button_itemselector page-item"
                                        *ngFor="let page of p2.pages"
                                        [class.current]="p2.getCurrent() === page.value"
                                    >
                                        <a
                                            class="page-link"
                                            (click)="p2.setCurrent(page.value)"
                                            *ngIf="p2.getCurrent() !== page.value"
                                        >
                                            <span>{{ page.label }}</span>
                                        </a>
                                        <a
                                            class="page-link"
                                            (click)=" p2.setCurrent(page.value)"
                                            *ngIf="p2.getCurrent() === page.value"
                                        >
                                            <span style="font-weight: bold">{{ page.label }}</span>
                                        </a>
                                    </li>

                                    <li
                                        class="paginate_button paginate_button_itemselector page-item next"
                                        [class.disabled]="p2.isLastPage()"
                                    >
                                        <a class="page-link" (click)="p2.next()" *ngIf="!p2.isLastPage()">
                                            <i class="fa-solid fa-arrow-right-long"></i>
                                        </a>
                                        <a class="page-link" *ngIf="p2.isLastPage()">
                                            <i class="fa-solid fa-arrow-right-long"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </pagination-template>
                    </span>
                    <div style="clear: both"></div>
                </div>
            </div>

            <ng-container
                *ngFor="let trip of item.Data | stringFilter: filter[idx] | paginate: { itemsPerPage: 1, currentPage: currentPageArray[idx], id: 'pager_' + idx  }; let idx2=index;"
            >
                <span *ngIf="resetSubPageTripsWithDetails(trip, idx2)"></span>

                <div class="flex-container">
                    <div class="col-lg-4">
                        <div class="ibox hand">
                            <div class="kpi-item">
                                <h3 class="margin-bottom font-bold">
                                    <i class="fa fas fa-fw fa-map-marker-alt"></i>
                                    {{ trip.Start?.timestamp | amDateFormat: 'lll'}}
                                </h3>
                                <div>
                                    <a
                                        href="{{ trip.BeginAddressFull.Link }}"
                                        target="_blank"
                                        class="secondary link_bolder"
                                        >{{ trip.BeginAddressFull?.Value }}</a
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="ibox hand">
                            <div class="kpi-item">
                                <h3 class="margin-bottom font-bold">
                                    <i class="fa fas fa-fw fa-clock"></i>
                                    {{ trip.DurationInSeconds | amDuration:'seconds'}} / {{
                                    trip.SegmentDistanceInKilometers?.toLocaleString()}} km
                                </h3>
                                <div>{{ trip.DriverName ?? '-' }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="ibox hand">
                            <div class="kpi-item">
                                <h3 class="margin-bottom font-bold">
                                    <i class="fa fas fa-fw fa-map-marker-alt"></i>
                                    {{ trip.End?.timestamp | amDateFormat: 'lll'}}
                                </h3>
                                <div>
                                    <a
                                        href="{{ trip.EndAddressFull?.Link }}"
                                        target="_blank"
                                        class="secondary link_bolder"
                                        >{{ trip.EndAddressFull?.Value }}</a
                                    >
                                </div>
                            </div>
                        </div>
                    </div>

                    <ng-container *ngIf="trip.Temperature1Min && trip.Temperature1Max && trip.Temperature1Avg">
                        <div class="col-lg-4">
                            <div class="ibox hand">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold">
                                        {{ actualRound(trip.Temperature1Min,1) }} °C
                                    </h3>
                                    <div>{{ 'general.minimumTemperature' | translate }} 1</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="ibox hand">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold">
                                        {{ actualRound(trip.Temperature1Avg,1) }} °C
                                    </h3>
                                    <div>{{ 'general.avgTemperature' | translate }} 1</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="ibox hand">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold">
                                        {{ actualRound(trip.Temperature1Max,1) }} °C
                                    </h3>
                                    <div>{{ 'general.maximumTemperature' | translate }} 1</div>
                                </div>
                            </div>
                        </div>
                        <div style="clear: both"></div>
                    </ng-container>

                    <ng-container *ngIf="trip.Temperature2Min && trip.Temperature2Max && trip.Temperature2Avg">
                        <div class="col-lg-4">
                            <div class="ibox hand">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold">
                                        {{ actualRound(trip.Temperature2Min,1) }} °C
                                    </h3>
                                    <div>{{ 'general.minimumTemperature' | translate }} 2</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="ibox hand">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold">
                                        {{ actualRound(trip.Temperature2Avg,1) }} °C
                                    </h3>
                                    <div>{{ 'general.avgTemperature' | translate }} 2</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="ibox hand">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold">
                                        {{ actualRound(trip.Temperature2Max,1) }} °C
                                    </h3>
                                    <div>{{ 'general.maximumTemperature' | translate }} 2</div>
                                </div>
                            </div>
                        </div>
                        <div style="clear: both"></div>
                    </ng-container>
                </div>

                <div class="ibox">
                    <div class="ibox-content slicker flex-container">
                        <div class="col-lg-6">
                            <fh-leaflet-map
                                [height]="300"
                                [geofenceEnabled]="false"
                                [allowBookmarks]="false"
                                (mapReady)="onMapReady($event)"
                                [heatmapEnabled]="reportDataFlat.displayLocationsAsHeatmap"
                            >
                            </fh-leaflet-map>
                        </div>

                        <div class="col-lg-6" *ngIf="renderedChart">
                            <highcharts-chart
                                [runOutsideAngular]="true"
                                style="display: block; width: 100%; height: 300px"
                                [Highcharts]="Highcharts"
                                [options]="renderedChart"
                            >
                            </highcharts-chart>
                        </div>

                        <div style="clear: both"></div>
                    </div>
                </div>

                <div class="ibox">
                    <div class="ibox-title">
                        <h5>
                            <div style="float: left">{{ 'general.events' | translate }}</div>
                        </h5>
                    </div>
                    <div class="ibox-content slick">
                        <div class="col-lg-12" style="display: block; overflow-x: hidden; overflow-y: auto">
                            <div class="table-responsive table-borderless">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th style="width: 80px">Time</th>
                                            <th></th>
                                            <th>{{'general.activity' | translate }}</th>
                                            <th>{{'general.location' | translate }}</th>
                                            <th>{{'general.status' | translate }}</th>
                                        </tr>
                                    </thead>
                                    <tbody class="table-body">
                                        <tr>
                                            <td style="width: 80px" title="{{ trip.End?.timestamp }}">
                                                {{ trip.End?.timestamp | amDateFormat: 'HH:mm'}}
                                            </td>
                                            <td><i class="far fa-fw fa-lg fa-flag-checkered"></i></td>
                                            <td>{{'general.endTrip' | translate}}</td>
                                            <td>
                                                <a
                                                    href="{{ trip.EndAddressFull?.Link }}"
                                                    target="_blank"
                                                    class="secondary link_bolder"
                                                    >{{ trip.EndAddressFull?.Value }}</a
                                                >
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr
                                            [ngClass]="detail.classNameRow"
                                            class="hand"
                                            (click)="displayLocation(detail)"
                                            *ngFor="let detail of trip.Details | orderBy : 'timestamp':true"
                                        >
                                            <td style="width: 80px" title="{{ detail.timestamp }}">
                                                {{ detail.timestamp | amDateFormat: 'HH:mm'}}
                                            </td>
                                            <td>
                                                <i class="far fa-fw fa-lg" [ngClass]="detail.classNameIcon"></i>
                                            </td>
                                            <td>{{ detail.name }}</td>
                                            <td>
                                                <a
                                                    *ngIf="detail.location?.Link"
                                                    href="{{ detail.location?.Link }}"
                                                    target="_blank"
                                                    class="secondary link_bolder"
                                                    >{{ detail.location?.Value }}</a
                                                >

                                                <span *ngIf="!detail.location?.Link">{{ detail.location?.Value }}</span>
                                            </td>
                                            <td>{{ detail.status }}</td>
                                        </tr>
                                        <tr *ngIf="trip.Details?.length == 0">
                                            <td><i class="far fa-fw fa-lg fa-up"></i></td>
                                            <td>{{'general.nodatafound' | translate}}</td>
                                            <td colspan="3"></td>
                                        </tr>
                                        <tr>
                                            <td style="width: 80px" title="{{ trip.Start?.timestamp }}">
                                                {{ trip.Start?.timestamp |amDateFormat: 'HH:mm'}}
                                            </td>
                                            <td><i class="far fa-fw fa-lg fa-play"></i></td>
                                            <td>{{'general.startTrip' | translate}}</td>
                                            <td>
                                                <a
                                                    href="{{ trip.BeginAddressFull?.Link }}"
                                                    target="_blank"
                                                    class="secondary link_bolder"
                                                    >{{ trip.BeginAddressFull?.Value }}</a
                                                >
                                            </td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div style="clear: both"></div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>

    <!-- Normal reports -->
    <div *ngIf="activeReport?.ReportType != 2 && activeReport?.ReportType != 32">
        <div
            *ngFor=" let item of reportDataFlat?.data | paginate: { itemsPerPage: 1, currentPage: currentPageSections,
        id: 'pager_all' }; let idx=index;"
        >
            <span *ngIf="resetSubPage(idx)"></span>

            <ng-container *ngIf="item.Charts && item.Charts.length > 0">
                <ng-container *ngFor="let chart of item.Charts">
                    <div class="ibox" *ngIf="generateChart(chart)">
                        <div class="ibox-title">
                            <h5>
                                <div style="float: left" [innerHTML]="createHeader(item) | byPassSafeHtml"></div>
                                <div style="float: left">{{ item.Name }} - {{ chart.Name }}</div>
                            </h5>
                            <span class="float-right">
                                <ibox-chart-export [chartObject]="chart.renderedChart"> </ibox-chart-export>
                            </span>
                        </div>
                        <div class="ibox-content slicker">
                            <div>
                                <highcharts-chart
                                    [runOutsideAngular]="true"
                                    style="display: block; width: 100%; height: 300px"
                                    [Highcharts]="Highcharts"
                                    [options]="chart.renderedChart"
                                >
                                </highcharts-chart>
                            </div>
                            <div style="clear: both"></div>
                        </div>
                    </div>
                </ng-container>
            </ng-container>

            <div
                class="ibox"
                style="position: relative"
                *ngIf="reportDataFlat?.displayLocations && item && generateMapHtmlDisplay(item)"
            >
                <fh-leaflet-map
                    [height]="300"
                    [geofenceEnabled]="false"
                    (mapReady)="onMapReady($event)"
                    [heatmapEnabled]="reportDataFlat.displayLocationsAsHeatmap"
                >
                    <div header class="ibox-title">
                        <h5>
                            <div style="float: left" [innerHTML]="createHeader(item) | byPassSafeHtml"></div>
                            <div style="float: left">{{ item.Name }}</div>
                        </h5>
                    </div>
                </fh-leaflet-map>
            </div>

            <div class="ibox">
                <div class="ibox-title">
                    <h5 [ngSwitch]="true">
                        <div style="float: left" [innerHTML]="createHeader(item) | byPassSafeHtml"></div>
                        <a *ngSwitchCase="item.DeviceId > -1" href="/#/DeviceDetails/Index/{{item.DeviceId}}">
                            <div style="float: left">{{ item.Name }} <i class="fas fa-fw fa-external-link"></i></div>
                        </a>
                        <a
                            *ngSwitchCase="item.AssetId > -1 && !(item.DeviceId > -1)"
                            href="/#/AssetDetails/Index/{{item.AssetId}}"
                        >
                            <div style="float: left">{{ item.Name }} <i class="fas fa-fw fa-external-link"></i></div>
                        </a>
                        <a *ngSwitchCase="item.DriverId > -1" href="/#/DriverDetails/Index/{{item.DriverId}}">
                            <div style="float: left">{{ item.Name }} <i class="fas fa-fw fa-external-link"></i></div>
                        </a>
                        <a *ngSwitchCase="item.GeofenceId > -1" href="/#/GeofenceDetails/Index/{{item.GeofenceId}}">
                            <div style="float: left">{{ item.Name }} <i class="fas fa-fw fa-external-link"></i></div>
                        </a>
                        <a *ngSwitchCase="item.ProjectId > -1" href="/#/ProjectDetails/Index/{{item.ProjectId}}">
                            <div style="float: left">{{ item.Name }} <i class="fas fa-fw fa-external-link"></i></div>
                        </a>
                        <div *ngSwitchDefault style="float: left">{{ item.Name }}</div>
                    </h5>

                    <span class="float-right">
                        <div class="filter-form d-none d-md-block">
                            <input
                                type="text"
                                class="form-control table-inline top-filter d-none d-md-block"
                                [(ngModel)]="filter[idx]"
                            />
                        </div>
                    </span>
                </div>
                <div
                    *ngIf="item.Data?.length > 0"
                    class="ibox-content slick"
                    style="position: relative; overflow-y: auto; text-overflow: ellipsis"
                >
                    <!-- Dormatting of column configuration object -->
                    <table id="reportTable" class="table nowrap dtr-inline no-footer table-reports" style="width: 100%">
                        <thead>
                            <tr>
                                <ng-container *ngFor="let column of template.columnConfiguration; let idx=index;">
                                    <th
                                        class="hand"
                                        (click)="setSortIndex(idx)"
                                        *ngIf="!(template.groupByIndex == idx && template.hideGroupByColumn)"
                                        style="text-transform: math-auto; overflow: hidden"
                                        [style.text-align]="column.alignment == 3 ? 'right' : (column.alignment == 2 ? 'center' : 'left')"
                                        [style.max-width.px]="column.width > 0 ? column.width : 'auto'"
                                    >
                                        <span
                                            style="display: block; overflow: hidden"
                                            [style.width.px]="column.width > 0 ? column.width : 'auto'"
                                            >{{ column.name }}</span
                                        >
                                    </th>
                                </ng-container>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                *ngFor="let c of item.Data | stringFilter: filter[idx] | paginate: { itemsPerPage: 20, currentPage: currentPageArray[idx], id: 'pager_' + idx  };"
                                [ngClass]="{'error': c?.hasError === true, 
                            'new-entity': c?.isNewEntity === true, 
                            'highlightRow': c?.isHighlighted === true,
                            'headerRow': c?.isHeader === true,
                            'summary': c?.isSummary === true }"
                            >
                                <ng-container *ngIf="c?.isHeader">
                                    <td [attr.colspan]="template.columnConfiguration.length">{{c?.headerName}}</td>
                                </ng-container>
                                <ng-container *ngFor="let key of c | keys; let idx=index;">
                                    <td
                                        *ngIf="key != 'isSummary' && key != 'isHeader' && key != 'headerName' && key != 'isHighlighted' && !(template.groupByIndex == idx && template.hideGroupByColumn)"
                                        style="overflow: hidden"
                                        [style.text-align]="template.columnConfiguration[key]?.alignment == 3 ? 'right' : (template.columnConfiguration[key]?.alignment == 2 ? 'center' : 'left')"
                                        [style.max-width.px]="template.columnConfiguration[key]?.width > 0 ? template.columnConfiguration[key]?.width : 'auto'"
                                    >
                                        <span
                                            [innerHtml]="checkFormat(key, c, 'HTML', template.columnConfiguration[key]) | byPassSafeHtml"
                                        >
                                        </span>
                                    </td>
                                </ng-container>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="ibox-footer noselect dataTables_wrapper">
                    <span>
                        <div class="dataTables_info" id="dataTable_info" role="status" aria-live="polite">
                            {{item.Data?.length ? item.Data?.length.toLocaleString() : 0 }} entries
                        </div>
                    </span>

                    <pagination-template
                        #p2="paginationApi"
                        (pageChange)="currentPageArray[idx] = $event"
                        id="pager_{{0}}"
                    >
                        <div class="dataTables_paginate paging_simple_numbers" id="dataTable_paginate">
                            <ul class="pagination">
                                <li class="paginate_button page-item previous" [class.disabled]="p2.isFirstPage()">
                                    <a class="page-link" (click)="p2.previous()" *ngIf="!p2.isFirstPage()"
                                        >{{ 'grid.previous' | translate }}</a
                                    >
                                    <a *ngIf="p2.isFirstPage()">{{ 'grid.previous' | translate }}</a>
                                </li>

                                <li
                                    class="paginate_button page-item"
                                    *ngFor="let page of p2.pages"
                                    [class.current]="p2.getCurrent() === page.value"
                                >
                                    <a
                                        class="page-link"
                                        (click)="p2.setCurrent(page.value)"
                                        *ngIf="p2.getCurrent() !== page.value"
                                    >
                                        <span>{{ page.label }}</span>
                                    </a>
                                    <a
                                        class="page-link"
                                        (click)="p2.setCurrent(page.value)"
                                        *ngIf="p2.getCurrent() === page.value"
                                    >
                                        <span style="font-weight: bold">{{ page.label }}</span>
                                    </a>
                                </li>

                                <li class="paginate_button page-item next" [class.disabled]="p2.isLastPage()">
                                    <a class="page-link" (click)="p2.next()" *ngIf="!p2.isLastPage()">
                                        {{ 'grid.next' | translate }}
                                    </a>
                                    <a class="page-link" *ngIf="p2.isLastPage()"> {{ 'grid.next' | translate }} </a>
                                </li>
                            </ul>
                        </div>
                    </pagination-template>
                    <div style="clear: both"></div>
                </div>
            </div>
        </div>
    </div>
</div>
