<div class="col-lg-12">
    <div class="horizontalMenuWrapper">
        <ul>
            <li [ngClass]="{'active': activeRoute('Accounts/Overview')}">
                <a href="/#/Accounts/Overview/"
                    ><i class="fas fa-fw fa-building"></i>
                    <div>{{ 'general.overview' |translate }}</div>
                </a>
            </li>
        </ul>
    </div>
    <div class="horizontalMenuWrapper" style="float: right">
        <ul>
            <ng-content></ng-content>
        </ul>
    </div>
</div>
<div style="clear: both"></div>
