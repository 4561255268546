<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/"> <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item" *ngIf="driver?.accountId">
                    <a href="/#/AccountDetails/Index/{{driver?.accountId}}">{{driver?.companyName}}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Drivers/Overview">{{'general.driver' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/DriverDetails/Index/{{driver?.id}}">{{driver?.firstName}} {{driver?.lastName}}</a>
                </li>
                <li class="breadcrumb-item active">{{'menu.trips' | translate }}</li>
            </ol>
        </nav>
    </div>
    <fh-driver-details-navigation [driverId]="driverId"></fh-driver-details-navigation>
</div>

<div class="wrapper wrapper-content">
    <div class="row" data-equalizer="">
        <div class="col-lg-12">
            <fh-error [error]="error" [warning]="warning" [success]="success" [autoRemove]="false"></fh-error>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5><i class="fa fa-link"></i> {{'general.trips' | translate }}</h5>
                </div>
                <div class="ibox-content" style="position: relative">
                    <ngx-loading [show]="loading"></ngx-loading>
                    Not implemented yet
                </div>
            </div>
        </div>
    </div>
</div>
