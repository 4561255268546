<fh-wizard [(step)]="step" (finish)="onCheckOutFinish()">
    <fh-wizard-step title="UpdateFieldsMessage" icon="fa-user" (open)="initForm()">
        <div class="wizard-content">This action will update data over multiple items</div>
    </fh-wizard-step>
    <fh-wizard-step title="UpdateFields" [validate]="groupForm" icon="fa-file-pdf" [padding]="false">
        <div class="wizard-content">
            <form #groupForm="ngForm" style="width: 100%; border-top: 0px">
                <table style="width: 100%" class="table table-fixed nowrap dtr-inline no-footer">
                    <!-- Devices -->
                    <tbody *ngIf="entityTypes == 1">
                        <tr>
                            <td style="width: 40%">
                                <div class="fa_checkbox">
                                    <input
                                        id="checkbox_0"
                                        #checkbox_0="ngModel"
                                        type="checkbox"
                                        (change)="setTouched()"
                                        [(ngModel)]="rowsEnabled[0]"
                                        name="checkbox_0"
                                    />
                                    <label for="checkbox_0">
                                        {{'general.vehicleType' | translate }}

                                        <span class="error" *ngIf="vehicleTypeVar.invalid">*</span>
                                    </label>
                                </div>
                            </td>
                            <td style="width: auto">
                                <ng-select
                                    [(ngModel)]="selectedVehicleType"
                                    name="selectedVehicleType"
                                    placeholder="{{ 'general.selector' | translate}}"
                                    class="form-control table-inline"
                                    #vehicleTypeVar="ngModel"
                                    (change)="rowsEnabled[0] = true;"
                                    [required]="checkbox_0.value"
                                    style="width: 100%"
                                >
                                    <ng-option *ngFor="let vehicleType of vehicleTypes" [value]="vehicleType.id">
                                        {{ ('enums.vehicleType.' + vehicleType.id) | translate }}
                                    </ng-option>
                                </ng-select>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 40%">
                                <div class="fa_checkbox">
                                    <input
                                        id="checkbox_1"
                                        #checkbox_1="ngModel"
                                        type="checkbox"
                                        (change)="setTouched()"
                                        [(ngModel)]="rowsEnabled[1]"
                                        name="checkbox_1"
                                    />
                                    <label for="checkbox_1">
                                        {{'general.color' | translate }}

                                        <span class="error" *ngIf="colorVar.invalid">*</span>
                                    </label>
                                </div>
                            </td>
                            <td style="width: auto">
                                <input
                                    type="text"
                                    [(ngModel)]="color"
                                    name="color5"
                                    placeholder="{{ 'placeholder.colorSuggestion' | translate}}"
                                    class="form-control table-inline"
                                    #colorVar="ngModel"
                                    [required]="checkbox_1.value"
                                    style="width: 100%"
                                    (change)="rowsEnabled[1] = true;"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 40%">
                                <div class="fa_checkbox">
                                    <input
                                        id="checkbox_2"
                                        #checkbox_2="ngModel"
                                        type="checkbox"
                                        (change)="setTouched()"
                                        [(ngModel)]="rowsEnabled[2]"
                                        name="checkbox_2"
                                    />
                                    <label for="checkbox_2">
                                        {{'general.brand' | translate }}

                                        <span class="error" *ngIf="brandVar.invalid">*</span>
                                    </label>
                                </div>
                            </td>
                            <td style="width: auto">
                                <input
                                    type="text"
                                    [(ngModel)]="brand"
                                    name="brand"
                                    class="form-control table-inline"
                                    #brandVar="ngModel"
                                    [required]="checkbox_2.value"
                                    style="width: 100%"
                                    (change)="rowsEnabled[2] = true;"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 40%">
                                <div class="fa_checkbox">
                                    <input
                                        id="checkbox_3"
                                        #checkbox_3="ngModel"
                                        type="checkbox"
                                        (change)="setTouched()"
                                        [(ngModel)]="rowsEnabled[3]"
                                        name="checkbox_3"
                                    />
                                    <label for="checkbox_3">
                                        {{'general.model' | translate }}

                                        <span class="error" *ngIf="modelVar.invalid">*</span>
                                    </label>
                                </div>
                            </td>
                            <td style="width: auto">
                                <input
                                    type="text"
                                    [(ngModel)]="model"
                                    name="model"
                                    class="form-control table-inline"
                                    #modelVar="ngModel"
                                    [required]="checkbox_3.value"
                                    style="width: 100%"
                                    (change)="rowsEnabled[3] = true;"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 40%">
                                <div class="fa_checkbox">
                                    <input
                                        id="checkbox_4"
                                        #checkbox_4="ngModel"
                                        type="checkbox"
                                        (change)="setTouched();"
                                        [(ngModel)]="rowsEnabled[4]"
                                        name="checkbox_4"
                                    />
                                    <label for="checkbox_4"> {{'general.icon' | translate }} </label>
                                </div>
                            </td>
                            <td style="width: auto; overflow: visible">
                                <fh-asset-icon-chooser
                                    [iconId]="iconId"
                                    [formMode]="formMode"
                                    (onIconChanged)="iconId = $event; rowsEnabled[4] = true;"
                                >
                                </fh-asset-icon-chooser>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 40%">
                                <div class="fa_checkbox">
                                    <input
                                        id="checkbox_5"
                                        #checkbox_5="ngModel"
                                        type="checkbox"
                                        (change)="setTouched()"
                                        [(ngModel)]="rowsEnabled[5]"
                                        name="checkbox_5"
                                    />
                                    <label for="checkbox_5">
                                        {{'general.fuelCapacity' | translate }} 1 L

                                        <span class="error" *ngIf="fuelTank1CapacityVar.invalid">*</span>
                                    </label>
                                </div>
                            </td>
                            <td style="width: auto">
                                <input
                                    type="number"
                                    [(ngModel)]="fuelTank1Capacity"
                                    name="fuelTank1Capacity"
                                    class="form-control table-inline"
                                    min="0"
                                    placeholder="{{ 'placeholder.liter' | translate}}"
                                    #fuelTank1CapacityVar="ngModel"
                                    [required]="checkbox_5.value"
                                    style="width: 100%"
                                    (change)="rowsEnabled[5] = true;"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 40%">
                                <div class="fa_checkbox">
                                    <input
                                        id="checkbox_6"
                                        #checkbox_6="ngModel"
                                        type="checkbox"
                                        (change)="setTouched()"
                                        [(ngModel)]="rowsEnabled[6]"
                                        name="checkbox_6"
                                    />
                                    <label for="checkbox_6">
                                        {{'general.fuelCapacity' | translate }} 2 L

                                        <span class="error" *ngIf="fuelTank2CapacityVar.invalid">*</span>
                                    </label>
                                </div>
                            </td>
                            <td style="width: auto">
                                <input
                                    type="number"
                                    [(ngModel)]="fuelTank2Capacity"
                                    name="fuelTank2Capacity"
                                    class="form-control table-inline"
                                    min="0"
                                    placeholder="{{ 'placeholder.liter' | translate}}"
                                    #fuelTank2CapacityVar="ngModel"
                                    [required]="checkbox_6.value"
                                    style="width: 100%"
                                    (change)="rowsEnabled[6] = true;"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 40%">
                                <div class="fa_checkbox">
                                    <input
                                        id="checkbox_7"
                                        #checkbox_7="ngModel"
                                        type="checkbox"
                                        (change)="setTouched()"
                                        [(ngModel)]="rowsEnabled[7]"
                                        name="checkbox_7"
                                    />
                                    <label for="checkbox_7">
                                        {{'general.fillupThreshold' | translate }}%

                                        <span class="error" *ngIf="fillupThresholdVar.invalid">*</span>
                                    </label>
                                </div>
                            </td>
                            <td style="width: auto">
                                <input
                                    type="number"
                                    [(ngModel)]="fillupThreshold"
                                    name="fillupThreshold"
                                    class="form-control table-inline"
                                    min="0"
                                    placeholder="{{ 'placeholder.percentage' | translate}}"
                                    #fillupThresholdVar="ngModel"
                                    [required]="checkbox_7.value"
                                    style="width: 100%"
                                    (change)="rowsEnabled[7] = true;"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 40%">
                                <div class="fa_checkbox">
                                    <input
                                        id="checkbox_8"
                                        #checkbox_8="ngModel"
                                        type="checkbox"
                                        (change)="setTouched()"
                                        [(ngModel)]="rowsEnabled[8]"
                                        name="checkbox_8"
                                    />
                                    <label for="checkbox_8">
                                        {{'general.theftThreshold' | translate }}%

                                        <span class="error" *ngIf="theftThresholdVar.invalid">*</span>
                                    </label>
                                </div>
                            </td>
                            <td style="width: auto">
                                <input
                                    type="number"
                                    [(ngModel)]="theftThreshold"
                                    name="theftThreshold"
                                    class="form-control table-inline"
                                    min="0"
                                    placeholder="{{ 'placeholder.percentage' | translate}}"
                                    #theftThresholdVar="ngModel"
                                    [required]="checkbox_8.value"
                                    style="width: 100%"
                                    (change)="rowsEnabled[8] = true;"
                                />
                            </td>
                        </tr>
                    </tbody>

                    <!-- Drivers -->
                    <tbody *ngIf="entityTypes == 4">
                        <tr>
                            <td style="width: 40%">
                                <div class="fa_checkbox">
                                    <input
                                        id="checkbox_20"
                                        #checkbox_20="ngModel"
                                        type="checkbox"
                                        [(ngModel)]="rowsEnabled[20]"
                                        name="checkbox_20"
                                    />
                                    <label for="checkbox_20">
                                        {{'general.comments' | translate }}

                                        <span class="error" *ngIf="commentsVar.invalid">*</span>
                                    </label>
                                </div>
                            </td>
                            <td style="width: auto">
                                <input
                                    type="text"
                                    [(ngModel)]="comments"
                                    name="comments"
                                    class="table-inline stickypad"
                                    #commentsVar="ngModel"
                                    (change)="rowsEnabled[20] = true;"
                                    [required]="checkbox_20.value"
                                    style="width: 100%; vertical-align: middle"
                                />
                            </td>
                        </tr>
                    </tbody>

                    <!-- Geofences -->
                    <tbody *ngIf="entityTypes == 5">
                        <tr>
                            <td style="width: 40%">
                                <div class="fa_checkbox">
                                    <input
                                        id="checkbox_40"
                                        #checkbox_40="ngModel"
                                        type="checkbox"
                                        (change)="setTouched()"
                                        [(ngModel)]="rowsEnabled[40]"
                                        name="checkbox_40"
                                    />
                                    <label for="checkbox_40"> {{'general.color' | translate }} </label>
                                </div>
                            </td>
                            <td style="width: auto">
                                <input
                                    id="colorPicker"
                                    name="colorPicker"
                                    [style.background]="geofenceColor"
                                    [cpPosition]="'bottom-left'"
                                    [(ngModel)]="geofenceColor"
                                    class="form-control table-inline"
                                    [colorPicker]="geofenceColor"
                                    [cpAlphaChannel]="'disabled'"
                                    [cpOutputFormat]="'hex'"
                                    [cpPresetColors]="['#fff', '#000', '#2889e9', '#e920e9', '#fff500', 'rgb(236,64,64)']"
                                    [required]="checkbox_40.value"
                                    (colorPickerChange)="geofenceColor=$event; rowsEnabled[40] = true;"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </div>
    </fh-wizard-step>
    <fh-wizard-step title="Remarks" icon="fa-file-pdf">
        <div class="wizard-content" style="padding-bottom: 10px">{{ 'wizard.optionalComment' | translate }}</div>
        <div class="wizard-content">
            <textarea
                class="form-control noresize stickypad"
                style="height: 100px; width: 100%; resize: none"
                placeholder="{{ 'placeholder.comments' | translate}}"
                [(ngModel)]="logItem"
            ></textarea>
        </div>
    </fh-wizard-step>
    <fh-wizard-step title="Summary" icon="fa-summary-pdf">
        <div
            class="wizard-content"
            style="padding-bottom: 10px"
            [innerHTML]="'wizard.summary' | translate:{ count: gridSelection.length }"
        ></div>
        <div class="ibox-content slick popupOverflow" style="position: relative; min-height: 72px; overflow: auto">
            <table style="width: 100%" class="table table-summary nowrap dtr-inline no-footer">
                <!-- Devices -->
                <tbody *ngIf="entityTypes == 1">
                    <tr *ngIf="rowsEnabled[0]">
                        <td style="width: 40%">
                            <label> {{'general.vehicleType' | translate }} </label>
                        </td>
                        <td style="width: auto">{{ ('enums.vehicleType.' + selectedVehicleType) | translate }}</td>
                    </tr>
                    <tr *ngIf="rowsEnabled[1]">
                        <td style="width: 40%">
                            <label> {{'general.color' | translate }} </label>
                        </td>
                        <td style="width: auto">{{ color }}</td>
                    </tr>
                    <tr *ngIf="rowsEnabled[2]">
                        <td style="width: 40%">
                            <label> {{'general.brand' | translate }} </label>
                        </td>
                        <td style="width: auto">{{ brand }}</td>
                    </tr>
                    <tr *ngIf="rowsEnabled[3]">
                        <td style="width: 40%">
                            <label> {{'general.model' | translate }} </label>
                        </td>
                        <td style="width: auto">{{ model }}</td>
                    </tr>
                    <tr *ngIf="rowsEnabled[4]">
                        <td style="width: 40%">
                            <label> {{'general.icon' | translate }} </label>
                        </td>
                        <td style="width: auto; overflow: visible">
                            <fh-asset-icon-chooser [iconId]="iconId" [formMode]="1"> </fh-asset-icon-chooser>
                        </td>
                    </tr>
                    <tr *ngIf="rowsEnabled[5]">
                        <td style="width: 40%">
                            <label> {{'general.fuelCapacity' | translate }} 1 L </label>
                        </td>
                        <td style="width: auto">{{ fuelTank1Capacity }}</td>
                    </tr>
                    <tr *ngIf="rowsEnabled[6]">
                        <td style="width: 40%">
                            <label> {{'general.fuelCapacity' | translate }} 2 L </label>
                        </td>
                        <td style="width: auto">{{ fuelTank2Capacity }}</td>
                    </tr>
                    <tr *ngIf="rowsEnabled[7]">
                        <td style="width: 40%">
                            <label> {{'general.fillupThreshold' | translate }}% </label>
                        </td>
                        <td style="width: auto">{{ fillupThreshold }}</td>
                    </tr>
                    <tr *ngIf="rowsEnabled[8]">
                        <td style="width: 40%">
                            <label> {{'general.theftThreshold' | translate }}% </label>
                        </td>
                        <td style="width: auto">{{ theftThreshold }}</td>
                    </tr>
                </tbody>

                <!-- Drivers -->
                <tbody *ngIf="entityTypes == 4">
                    <tr *ngIf="rowsEnabled[20]">
                        <td style="width: 40%">
                            <label> {{'general.comments' | translate }} </label>
                        </td>
                        <td style="width: auto">{{ comments }}</td>
                    </tr>
                </tbody>

                <!-- Geofences -->
                <tbody *ngIf="entityTypes == 5">
                    <tr *ngIf="rowsEnabled[40]">
                        <td style="width: 40%">
                            <label> {{'general.color' | translate }} </label>
                        </td>
                        <td style="position: relative; padding: 0; width: auto">
                            <span
                                style="position: absolute; width: 100%; height: 100%; border: 10px solid white"
                                [style.background]="geofenceColor"
                            ></span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </fh-wizard-step>
</fh-wizard>
