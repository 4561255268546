import {
  Component,
  AfterViewInit,
  Input,
  ElementRef,
  ViewChild,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from "@angular/core";

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { getIconPath } from "app/common/globals";
import { AssetGroupsService } from "app/services/asset/assetGroups.service";

const noop = () => {};

@Component({
  selector: "fh-asset-group-item-input",
  templateUrl: "./assetGroupitemSelector.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: AssetGroupItemInputComponent }],
})
export class AssetGroupItemInputComponent implements OnChanges, OnInit, AfterViewInit, ControlValueAccessor {
  @Input() placeholder: string;
  @Input() clearable = true;
  @Input() selectedAccountId;
  @Input() readonly = false;
  @Input() multiple = true;
  @Input() tableInline;
  @Input() includeEmptyGroups = true;
  @Input() setCurrentAssets;

  @Output() onChanged = new EventEmitter();

  assetGroups = [];
  selectGroupBy = "assetGroupItems";
  selectBindLabel = "assetName";

  loadingAssetGroups = false;

  @ViewChild("input") el: ElementRef;

  private _value: string;
  private _onChange: (_: any) => void = noop;

  get value(): any {
    return this._value;
  }

  set value(v: any) {
    if (v !== this._value) {
      this._value = v;
      this._onChange(v);
      this.cd.markForCheck();
    }
  }

  constructor(private assetGroupService: AssetGroupsService, private cd: ChangeDetectorRef) {}

  groupValueFn = (object: { [key: string]: any }, children: any[]) => ({
    displayName: object.value.displayName,
    color: object.value.color,
    id: [{ assetGroupId: object.value.id }],
    length: children.length,
  });

  // Override search function to be able to search on groups
  searchFn(searchTerm: string, item: any) {
    const columns = ["assetGroupName", "assetName", "plateNumber", "assetCode"];
    let isSearchMatch = false;

    columns.forEach((column) => {
      if (item[column]?.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) {
        isSearchMatch = true;
      }
    });

    return isSearchMatch;
  }

  assetGroupChanged() {
    this.onChanged.emit(this.value);
    this.cd.markForCheck();
  }

  ngOnChanges() {
    setTimeout(() => {
      // Get accounts again
      this.loadAssetGroups();
      this.cd.markForCheck();
    }, 0);
  }

  compareFunction = (item, selected) => {
    if (selected.gender && item.gender) {
      return item.gender === selected.gender;
    }
    if (item.id && selected.id) {
      return item.id === selected.id;
    }
    return false;
  };

  compareFn(item, selected): boolean {
    if (item.displayName !== undefined) {
      if (item.id[0].assetGroupId === selected) {
        return true;
      }
      return false;
    }
    if (item?.id === selected) {
      return true;
    }
    return false;
  }

  showAll(event, assetGroup) {
    assetGroup.showAll = !assetGroup.showAll;
    event.stopPropagation();
  }

  loadAssetGroups() {
    this.assetGroups = [];
    this.cd.markForCheck();

    if (this.selectedAccountId) {
      this.loadingAssetGroups = true;
      this.cd.markForCheck();

      if (this.selectedAccountId && this.selectedAccountId !== 0) {
        this.assetGroupService.getAssetGroups(this.selectedAccountId, true).subscribe((result) => {
          result = result.sort((a, b) => (a.name < b.name ? -1 : 1));

          this.assetGroups = result;

          this.assetGroups.sort((a, b) => a.groupType - b.groupType || a.displayName.localeCompare(b.displayName));

          // Removing empty groups
          if (!this.includeEmptyGroups) {
            this.assetGroups = this.assetGroups.filter((x) => x.assetGroupItems?.length > 0);
          }

          this.assetGroups.forEach((group, index) => {
            group.assetGroupItems.sort((a, b) => a.assetName?.localeCompare(b.assetName));
          });

          this.loadingAssetGroups = false;
          this.cd.markForCheck();
        });
      } else {
        this.loadingAssetGroups = false;
        this.cd.markForCheck();
      }
    }
  }

  ngOnInit() {}

  ngAfterViewInit() {
    const __this = this;
  }

  writeValue(value: any) {
    this._value = value;
    this.cd.markForCheck();
  }

  registerOnChange(fn: (value: any) => void) {
    this._onChange = fn;
    this.cd.markForCheck();
  }

  registerOnTouched(fn: any) {
    this.cd.markForCheck();
  }

  formatIconId(item) {
    return (
      '<img style="position: relative; margin-top: -10px; margin-bottom: -3px; padding-right:10px" src="' +
      getIconPath(item.iconId)[1] +
      '">'
    );
  }
}
