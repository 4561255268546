import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable, catchError, map } from "rxjs";
import { AuthenticationService } from "../authentication/authentication.service";
import { LoggingService } from "../logging/logging.service";

// Moment timezone
import Moment from "moment-timezone";
import { throwError } from "rxjs/internal/observable/throwError";
window["moment"] = Moment;

@Injectable()
export class DeviceRoutingConfigurationService {
  url = "";
  base_url = "";
  inventoryUrl = "";
  timezoneIana: string;

  constructor(
    private http: HttpClient,
    private loggingService: LoggingService,
    private authenticationService: AuthenticationService
  ) {
    this.url = this.authenticationService.getWebserviceURL("deviceroutingconfiguration");
    this.base_url = this.authenticationService.getWebserviceURL("");
    this.timezoneIana = this.authenticationService.getTimeZoneIana();
  }

  getMDeviceRoutingConfiguration(imei): Observable<any> {
    console.log("getting DeviceRoutingConfiguration from service");
    return this.http.get(this.url + "?imei=" + imei, { headers: this.authenticationService.headers }).pipe(
      map((data) => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  updateDeviceRouting(unitId, routing): Observable<any> {
    console.log("update deviceRouting", routing);

    return this.http
      .put(this.url + unitId, routing, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  private handleError(error: Response) {
    return throwError(error);
  }
}
