import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from "app/services/authentication/authentication.service";

@Component({
  selector: "fh-driver-details-navigation",
  templateUrl: "driverDetailsNavigation.template.html",
})
export class DriverDetailsNavigationComponent implements OnInit {
  @Input() driverId;
  sub: any;
  activeId: any;
  permissions = {};

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    this.permissions = this.authenticationService.permissions;
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe((params) => {
      const id = params["id"];
      this.activeId = id;
    });
  }

  activeRoute(routename: string): boolean {
    const active = this.router.url.indexOf(routename) !== -1;
    return active;
  }
}
