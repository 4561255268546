import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Trailer } from "app/models/trailer.model";
import { ReportService } from "app/services/reports/report.service";
import { AuditLog } from "../../models/auditlog.model";
import { AuditLogService } from "../../services/auditlog/auditlog.service";

@Component({
  selector: "fh-subscription-log",
  templateUrl: "subscriptionLog.template.html",
})
export class SubscriptionLogViewComponent implements OnInit {
  subscriptionLog: AuditLog[];
  sub;
  subscription;
  loading = false;

  error: any;
  success: any;

  permissionName = "FleetManagement_Log";
  subscriptionId: any;

  constructor(
    private http: HttpClient,
    private auditLogService: AuditLogService,
    private reportService: ReportService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.subscription = null;
    this.subscriptionLog = [];
  }

  ngOnInit() {
    this.loading = true;
    this.subscription = new Trailer();
    this.subscription.id = "";

    this.sub = this.route.params.subscribe((params) => {
      const id = params["id"];

      this.subscriptionId = id;
      this.reportService.getReportSubscriptionById(id).subscribe((res) => {
        this.subscription = res;

        if (this.subscription == null) {
          this.router.navigate(["/Trailers/Overview"]);
        }

        this.auditLogService.getAuditLogByReportSubscription(id).subscribe({
          next: (subscriptionLog) => {
            console.log("loading done");
            this.subscriptionLog = subscriptionLog;
            this.loading = false;
          },
          error: (error) => {
            this.error = error;
            this.loading = false;
          },
        });
      });
    });
  }
}
