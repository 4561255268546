<div class="col-lg-12">
    <div class="horizontalMenuWrapper">
        <ul>
            <li [ngClass]="{active: activeRoute('DriverDetails/Index')}">
                <a [routerLink]="['/DriverDetails/Index/', driverId]">
                    <i class="fa fa-fw fa-id-card-alt"></i>
                    <div>{{ 'menu.dashboard' | translate }}</div>
                </a>
            </li>
            <li *ngIf="permissions['FleetManagement_Trips']" [ngClass]="{active: activeRoute('DriverDetails/Trips')}">
                <a [routerLink]="['/DriverDetails/Trips/', driverId]">
                    <i class="fas fa-fw fa-route"></i>
                    <div>{{ 'menu.trips' | translate }}</div>
                </a>
            </li>
            <li
                *ngIf="permissions['FleetManagement_DriverScore']"
                [ngClass]="{active: activeRoute('DriverDetails/Score')}"
            >
                <a [routerLink]="['/DriverDetails/Score/', driverId]">
                    <i class="fas fa-fw fa-sort-numeric-down"></i>
                    <div>{{ 'menu.score' | translate }}</div>
                </a>
            </li>
            <li
                *ngIf="permissions['FleetManagement_External']"
                [ngClass]="{active: activeRoute('DriverDetails/External')}"
            >
                <a [routerLink]="['/DriverDetails/External/', driverId]">
                    <i class="fas fa-fw fa-link"></i>
                    <div>{{ 'menu.externalApi' | translate }}</div>
                </a>
            </li>
            <li *ngIf="permissions['FleetManagement_Log']" [ngClass]="{active: activeRoute('DriverDetails/Log')}">
                <a [routerLink]="['/DriverDetails/Log/', driverId]">
                    <i class="fas fa-fw fa-file-alt"></i>
                    <div>{{ 'menu.log' | translate }}</div>
                </a>
            </li>
        </ul>
    </div>
    <div class="horizontalMenuWrapper" style="float: right">
        <ul>
            <ng-content></ng-content>
        </ul>
    </div>
</div>
<div style="clear: both"></div>
