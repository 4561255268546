<div class="ibox-content slick" style="position: relative">
    <form #userForm="ngForm">
        <table style="width: 100%" class="table table-fixed nowrap dtr-inline no-footer">
            <thead>
                <tr>
                    <th>{{ "general.inputValue" | translate }}</th>
                    <th>{{ "general.actualValue" | translate }}</th>
                    <th style="width: 40px; padding-top: 18px"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngIf="formMode != 1">
                    <td style="width: auto">
                        <input
                            name="reportedValue"
                            [readonly]="formMode == 1"
                            [(ngModel)]="reportedValue"
                            numberOnly
                            type="text"
                            class="form-control table-inline"
                            required
                            #reportedValueProp="ngModel"
                        />
                    </td>
                    <td style="width: auto">
                        <input
                            name="calibratedValue"
                            [readonly]="formMode == 1"
                            [(ngModel)]="calibratedValue"
                            numberOnly
                            type="text"
                            class="form-control table-inline"
                            required
                            #calibratedValueProp="ngModel"
                        />
                    </td>
                    <td style="width: 40px; padding-top: 18px">
                        <button
                            class="btn btn-primary float-right btn-grid"
                            [disabled]="userForm.invalid"
                            *ngIf="formMode != 1"
                            (click)="addRow(calibratedValueProp, reportedValueProp)"
                        >
                            <i class="fa fa-plus"></i>
                        </button>
                    </td>
                </tr>
                <tr
                    [hidden]="calibration.sensorNumber != sensorNumber"
                    *ngFor="let calibration of calibrationPoints | orderBy : 'reportedValue' : false"
                >
                    <td style="width: auto">
                        <input
                            type="text"
                            [readonly]="1"
                            class="form-control table-inline"
                            [ngModelOptions]="{ standalone: true }"
                            numberOnly
                            [(ngModel)]="calibration.reportedValue"
                        />
                    </td>
                    <td style="width: auto">
                        <input
                            type="text"
                            [readonly]="1"
                            class="form-control table-inline"
                            [ngModelOptions]="{ standalone: true }"
                            numberOnly
                            [(ngModel)]="calibration.calibratedValue"
                        />
                    </td>
                    <td style="width: 40px; padding-top: 18px">
                        <button
                            class="btn btn-primary float-right btn-grid"
                            (click)="removeRow(calibration)"
                            *ngIf="formMode != 1"
                        >
                            <i class="fa fa-trash"></i>
                        </button>
                    </td>
                </tr>
                <tr *ngIf="!hasCalibrations(sensorNumber)">
                    <td>
                        {{ "general.noDataFound" | translate }}
                    </td>
                </tr>
            </tbody>
        </table>
    </form>
</div>
