<div class="col-lg-12">
    <div class="horizontalMenuWrapper">
        <ul>
            <li [ngClass]="{active: activeRoute('WarehouseDetails/Index')}">
                <a [routerLink]="['/WarehouseDetails/Index/', warehouseId]">
                    <i class="fas fa-fw fa-th-list"></i>
                    <div>{{ 'menu.dashboard' | translate }}</div>
                </a>
            </li>
            <li
                *ngIf="permissions['WarehouseZones_View']"
                [ngClass]="{active: activeRoute('WarehouseDetails/Zones') ||  activeRoute('WarehouseZoneDetails')}"
            >
                <a [routerLink]="['/WarehouseDetails/Zones/', warehouseId]">
                    <i class="fas fa-fw fa-border-none"></i>
                    <div>{{ 'menu.zones' | translate }}</div>
                </a>
            </li>
            <li *ngIf="permissions['Warehouses_View']" [ngClass]="{active: activeRoute('WarehouseDetails/Sensors')}">
                <a [routerLink]="['/WarehouseDetails/Sensors/', warehouseId]">
                    <i class="fas fa-fw fa-sensor"></i>
                    <div>{{ 'menu.data' | translate }}</div>
                </a>
            </li>
            <li *ngIf="permissions['Warehouses_View']" [ngClass]="{active: activeRoute('WarehouseDetails/Charts')}">
                <a [routerLink]="['/WarehouseDetails/Charts/', warehouseId]">
                    <i class="fas fa-fw fa-chart-area"></i>
                    <div>{{ 'menu.charts' | translate }}</div>
                </a>
            </li>
            <li *ngIf="permissions['Warehouses_View']" [ngClass]="{active: activeRoute('WarehouseDetails/Messages')}">
                <a [routerLink]="['/WarehouseDetails/Messages/', warehouseId]">
                    <i class="fas fa-fw fa-comment-alt-dots"></i>
                    <div>{{ 'general.messages' | translate }}</div>
                </a>
            </li>
            <li
                *ngIf="permissions['WarehouseEvents_View']"
                [ngClass]="{active: activeRoute('WarehouseDetails/Events')}"
            >
                <a [routerLink]="['/WarehouseDetails/Events/', warehouseId]">
                    <i class="fas fa-fw fa-bell-on"></i>
                    <div>{{ 'menu.events' | translate }}</div>
                </a>
            </li>
            <li
                *ngIf="permissions['FleetManagement_External']"
                [ngClass]="{active: activeRoute('WarehouseDetails/External')}"
            >
                <a [routerLink]="['/WarehouseDetails/External/', warehouseId]">
                    <i class="fas fa-fw fa-link"></i>
                    <div>{{ 'menu.externalApi' | translate }}</div>
                </a>
            </li>
            <li *ngIf="permissions['FleetManagement_Log']" [ngClass]="{active: activeRoute('WarehouseDetails/Log')}">
                <a [routerLink]="['/WarehouseDetails/Log/', warehouseId]">
                    <i class="fas fa-fw fa-file-alt"></i>
                    <div>{{ 'menu.log' | translate }}</div>
                </a>
            </li>
        </ul>
    </div>
    <div class="horizontalMenuWrapper" style="float: right">
        <ul>
            <ng-content></ng-content>
        </ul>
    </div>
</div>
<div style="clear: both"></div>
