import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { getGridButtons, getGridLanguages, getDefaultDpConfig } from "app/common/gridhelper";

import { AuthenticationService } from "../../../services/authentication/authentication.service";

import { BsDaterangepickerConfig } from "ngx-bootstrap/datepicker";
import { ErrorLogService } from "app/services/logging/error-log.service";
import { forkJoin } from "rxjs";
import { GridBase360Directive } from "app/common/360Grid.base";

// Moment
import Moment from "moment-timezone";
import { StorageHelper } from "app/common/storagehelper";
import { ColorService } from "app/services/common/color.service";

window["moment"] = Moment;

@Component({
  selector: "fh-error-log",
  templateUrl: "errorlog.component.html",
})
export class ErrorLogComponent extends GridBase360Directive implements OnInit, OnDestroy {
  token: string;

  showActiveOnly = true;

  excludingColumns = ["createdDate"];

  loading = false;
  languageLoaded: boolean;
  permissions: {};

  permissionName = "Platform_IsAdministrator";
  constructorName = "ErrorLogComponent";
  timezoneIana: string;

  // Daterange
  public dpConfig: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();
  daterangepickerModel: Date[];

  error;

  constructor(
    private translateService: TranslateService,
    private cd: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private errorLogService: ErrorLogService,
    protected storageHelper: StorageHelper,
    private colorService: ColorService
  ) {
    super(storageHelper);

    this.token = this.authenticationService.getAuthToken();
    this.timezoneIana = this.authenticationService.getTimeZoneIana();

    this.daterangepickerModel = [
      Moment().tz(this.timezoneIana).subtract(1, "months").startOf("day").toDate(),
      Moment().tz(this.timezoneIana).endOf("day").toDate(),
    ];

    this.dpConfig = getDefaultDpConfig(authenticationService);

    // Get all the date for dropdown boxes
    forkJoin({ dateTranslation: this.translateService.get("general.date") }).subscribe({
      next: ({ dateTranslation }) => {
        this.languageLoaded = true;
        this.loading = false;

        this.initGrid();
      },
      error: (err) => console.error(err),
    });
  }

  ngOnInit() {
    this.permissions = this.authenticationService.permissions;
  }

  dateChanged(event) {
    if (event !== null) {
      this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.ajax
          .url(
            this.errorLogService.getPagingUrl(
              Moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf("day"),
              Moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf("day")
            )
          )
          .load();
      });
    }
  }

  initGrid(): void {
    $.fn["dataTable"].ext.search.pop();

    this.loading = true;

    this.columns = [
      {
        name: "id",
        data: "id",
        orderable: false,
        title: '<div class="hideDropdown"></div>',
        width: "20",
        render: (data, type, row) => {
          return `
            <a class='btn btn-primary btn-grid' title='${this.translateService.instant(
              "general.details"
            )}' href='/#/System/ErrorLogDetails/Index/${data}'>
                <span class="d-none d-md-inline-flex" style="padding-left: 7px">${this.translateService.instant(
                  "general.details"
                )}</span>
                <i class='fas fa-fw fa-angle-right'></i>
            </a>`;
        },
      },
      {
        name: "id_export",
        data: "id",
        title: this.translateService.instant("general.id"),
        visible: false,
      },
      {
        name: "createdDate",
        data: "createdDate",
        title: this.translateService.instant("general.createdDate"),
        render: (data, type, row) => {
          const date = Moment.utc(data)["tz"](this.timezoneIana);
          return data ? '<span title=" ' + date.toLocaleString() + '">' + date.format("lll") + "</span>" : "";
        },
      },
      {
        name: "userName",
        data: "userName",
        defaultContent: "-",
        title: this.translateService.instant("general.userName"),
      },
      {
        name: "location",
        data: "location",
        defaultContent: "-",
        title: this.translateService.instant("general.location"),
        render: (data, type, row) => {
          return data
            ? '<span class="elipses_overflow" style="width: 250px" title="' + data + '">' + data + "</span>"
            : "-";
        },
      },
      {
        name: "message",
        data: "message",
        defaultContent: "-",
        title: this.translateService.instant("general.message"),
        render: (data, type, row) => {
          return data
            ? '<span class="elipses_overflow" style="width: 250px" title="' + data + '">' + data + "</span>"
            : "-";
        },
      },
      {
        name: "description",
        data: "description",
        defaultContent: "-",
        title: this.translateService.instant("general.description"),
      },
    ];

    this.dtOptions = {
      buttons: getGridButtons(
        this.commonExportOptions,
        "error_overview",
        this.translateService.instant("menu.deviceoverview"),
        this.colorService
      ),
      pagingType: "simple_numbers",
      serverSide: true,
      processing: true,
      searchDelay: 500,
      deferRender: true,
      scrollX: true,
      colReorder: { fixedColumnsLeft: 1 },
      deferLoading: 0,
      stateSave: true,
      stateSaveCallback: (settings, data) => {
        this.saveState(this.constructorName, data);
      },
      stateLoadCallback: (_, callback) => {
        (async () => {
          try {
            const columnSettings = await this.loadState(this.constructorName);
            this.searchTerm = columnSettings && columnSettings.search && columnSettings.search.search;
            return columnSettings;
          } catch (e) {
            this.error = {};
            this.error.error = e;
            this.error.statusText = "Error fetching column settings";

            return null;
          }
        })().then((result) => {
          callback(result);
        });
      },
      order: [[2, "desc"]],
      ajax: {
        beforeSend: () => {
          this.drawFilterRow();

          $(".dataTables_info").html(this.translateService.instant("grid.loadingData"));
        },
        url: this.errorLogService.getPagingUrl(
          Moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf("day"),
          Moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf("day")
        ),
        type: "POST",
        headers: {
          Authorization: "Bearer " + this.token,
        },
      },
      initComplete: (settings, json) => {
        console.log("init complete");
        this.checkFilters();
        this.drawFilterRow();
        this.loading = false;
      },
      colVis: {
        restore: this.translateService.instant("general.restore"),
        showAll: this.translateService.instant("general.showAll"),
        showNone: this.translateService.instant("general.hideAll"),
        exclude: ["id", "id_export"],
      },
      columns: this.columns,
      pageLength: 17,
      lengthMenu: [
        [10, 17, 25, 50, -1],
        [10, 17, 25, 50, this.translateService.instant("general.all")],
      ],
      language: getGridLanguages(this.translateService),
    };
  }
}
