import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef } from "@angular/core";
import { ControlContainer, NgForm } from "@angular/forms";
import { FormMode } from "app/common/enums";
import { AssetGroup, DriverGroup } from "app/models/group.model";
import { UserService } from "app/services/users/user.service";
import { AuthenticationService } from "app/services/authentication/authentication.service";

@Component({
  selector: "fh-hierarchy-group-details",
  templateUrl: "hierarchyGroupDetails.component.html",
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class HierarchyGroupDetailsComponent implements OnInit {
  @Input() group;

  @Input() loading = false;
  @Input() formMode: any = FormMode.read;
  @Output() onAccountChanged = new EventEmitter();
  selectedResellerId;
  users = [];

  loadingAccounts = false;
  loadingUsers = false;
  permissions: {};

  constructor(
    private cd: ChangeDetectorRef,
    private userService: UserService,
    private authenticationService: AuthenticationService
  ) {
    this.permissions = this.authenticationService.permissions;
  }

  ngOnInit(): void {}

  ngOnChanges() {}

  changeGroupType(event) {}

  resellerChanged(resellerId) {
    this.group.accountId = null;
    this.selectedResellerId = resellerId;
    this.onAccountChanged.emit(this.group.accountId);
  }

  accountChanged(accountId) {
    this.onAccountChanged.emit(this.group.accountId);

    this.loadingUsers = true;
    this.group.ownerId = null;
    this.userService.getSlimUsersByAccountId(this.group.accountId).subscribe((result) => {
      this.users = result;

      if (this.users.length === 1) {
        console.log("Setting user to: " + this.users[0].id);
        this.group.ownerId = this.users[0].id;
      }

      this.loadingUsers = false;
    });
  }
}
