import {
  Component,
  AfterViewInit,
  Input,
  ElementRef,
  ViewChild,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from "@angular/core";

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Router } from "@angular/router";
import { TriggerScheduleService } from "app/services/triggerSchedule/triggerSchedule.service";

const noop = () => {};

@Component({
  selector: "fh-schedule-input",
  templateUrl: "./scheduleSelector.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: ScheduleInputComponent }],
})
export class ScheduleInputComponent implements OnChanges, OnInit, AfterViewInit, ControlValueAccessor {
  @Input() placeholder: string;
  @Input() clearable = false;
  @Input() selectedAccountId;
  @Input() autoSelect = true;
  @Input() readonly = false;
  @Input() multiple = false;

  @Output() onChanged = new EventEmitter();

  triggerSchedules = [];

  loadingTriggerSchedules = false;

  @ViewChild("input") el: ElementRef;

  private _value: string;
  private _onChange: (_: any) => void = noop;

  get value(): any {
    return this._value;
  }

  set value(v: any) {
    if (v !== this._value) {
      this._value = v;
      this._onChange(v);
      this.cd.markForCheck();
    }
  }

  constructor(
    private router: Router,
    private triggerScheduleService: TriggerScheduleService,
    private cd: ChangeDetectorRef
  ) {}

  triggerScheduleChanged() {
    this.onChanged.emit(this.value);
    this.cd.markForCheck();
  }

  ngOnChanges() {
    setTimeout(() => {
      // Get accounts again
      this.loadTriggerSchedules();
      this.cd.markForCheck();
    }, 0);
  }

  showAll(event, geofenceGroup) {
    geofenceGroup.showAll = !geofenceGroup.showAll;
    event.stopPropagation();
  }

  loadTriggerSchedules() {
    this.triggerSchedules = [];
    this.cd.markForCheck();

    if (this.selectedAccountId) {
      this.loadingTriggerSchedules = true;
      this.cd.markForCheck();

      if (this.selectedAccountId && this.selectedAccountId !== 0) {
        this.triggerScheduleService
          .getTriggerSchedulesByAccount(this.selectedAccountId)
          .subscribe((triggerSchedules) => {
            triggerSchedules = triggerSchedules.sort((a, b) =>
              a.triggerScheduleName < b.triggerScheduleName ? -1 : 1
            );

            this.triggerSchedules = triggerSchedules;
            this.loadingTriggerSchedules = false;
            this.cd.markForCheck();
          });
      } else {
        this.loadingTriggerSchedules = false;
        this.cd.markForCheck();
      }
    }
  }

  ngOnInit() {}

  ngAfterViewInit() {
    const __this = this;
  }

  writeValue(value: any) {
    this._value = value;
    this.cd.markForCheck();
  }

  registerOnChange(fn: (value: any) => void) {
    this._onChange = fn;
    this.cd.markForCheck();
  }

  registerOnTouched(fn: any) {
    this.cd.markForCheck();
  }
}
