import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { EntityType, FormMode } from "app/common/enums";
import { AccountInventory } from "app/models/account.model";
import { WebhookEndpoint } from "app/models/webhookEndpoint.model";
import { AccountService } from "app/services/account/account.service";
import { AuthenticationService } from "app/services/authentication/authentication.service";
import { FhChartService } from "app/services/charts/charts.service";
import { WebhookEndpointService } from "app/services/webhookendpoints/webhookEndpoint.service";

@Component({
  providers: [FhChartService],
  selector: "fh-fh-webhook-endpoin-details",
  templateUrl: "webhookEndpointDetails.template.html",
})
export class WebhookEndpointDetailsViewComponent implements OnInit {
  sub;

  loading = false;
  saving = false;

  tagType = EntityType.WebhookEndpoint.valueOf();

  error: any;
  success: any;

  formMode: FormMode = FormMode.read;

  webhookEndpoint: WebhookEndpoint;
  account: AccountInventory;

  permissionName = "Triggers_View";
  permissions: {};

  constructor(
    private webhookEndpointService: WebhookEndpointService,
    private cd: ChangeDetectorRef,
    private accountService: AccountService,
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    private authenticationService: AuthenticationService
  ) {
    this.account = null;

    this.webhookEndpoint = new WebhookEndpoint();
    this.webhookEndpoint.headers = [];
  }

  ngOnInit(): void {
    this.loading = true;
    this.sub = this.route.params.subscribe((params) => {
      const id = params["id"];

      this.getWebhookEndpoint(id);
    });
  }

  getWebhookEndpoint(webhookEndpointId = 0) {
    if (this.loading !== true) {
      this.loading = true;
    }

    if (webhookEndpointId === 0) {
      this.webhookEndpointService.getWebhookEndpointById(this.webhookEndpoint.id).subscribe((webhookEndpoint) => {
        this.webhookEndpoint = webhookEndpoint;
        if (!this.webhookEndpoint.headers) {
          this.webhookEndpoint.headers = [];
        }

        this.loading = false;
      });
    } else {
      this.webhookEndpointService.getWebhookEndpointById(webhookEndpointId).subscribe((webhookEndpoint) => {
        this.webhookEndpoint = webhookEndpoint;
        this.loading = false;
      });
    }
  }

  setFormMode(mode) {
    this.formMode = mode;

    if (this.formMode === FormMode.read) {
      this.loading = true;
    }

    this.getWebhookEndpoint();
  }

  onDelete() {
    this.loading = true;

    this.webhookEndpointService.deleteWebhookEndpoint(this.webhookEndpoint).subscribe({
      next: (result) => {
        this.error = null;
        this.success = {
          statusText: "Success",
          success: "Webhook endpoint is successfully deleted.",
        };

        setTimeout(() => {
          this.router.navigate(["/System/WebhookEndpoints/Overview"]);
        }, 3000);
      },
      error: (error) => {
        this.success = null;
        this.error = error;
        this.loading = false;
        this.cd.markForCheck();
      },
    });
  }

  onSave() {
    this.saving = true;
    this.loading = true;

    this.webhookEndpointService.updateWebhookEndpoint(this.webhookEndpoint).subscribe({
      next: (result) => {
        this.error = null;
        this.success = {
          statusText: "Success",
          success: "Webhook endpoint is successfully updated.",
        };

        this.saving = false;
        this.loading = false;
        this.cd.markForCheck();

        this.setFormMode(FormMode.read);
      },
      error: (error) => {
        this.loading = false;
        this.saving = false;
        this.success = null;
        this.error = error;
        this.cd.markForCheck();
      },
    });
  }
}
