<ul *ngIf="getChildren(parentId).length > 0" class="list-group hierarchy-group">
    <li
        *ngFor="let group of getChildren(parentId)"
        class="list-group-item hierarchy-group-item"
        (click)="toggleOpen($event, group.groupId)"
    >
        <span class="hierarchy-group-header">
            <i class="fa fa-fw" [ngClass]="isOpened(group.groupId) ? 'fa-folder-open' : 'fa-folder'"></i>
            {{ group.groupName }}

            <span style="display: block; position: absolute; right: 0; top: 0" *ngIf="includeItems">
                <span class="label label-default" style="float: left; margin-right: 2px"
                    ><i class="fa fa-fw fa-car"></i> {{(group.assets | keyvalue).length}}</span
                >
                <span class="label label-default" style="float: left; margin-right: 2px"
                    ><i class="fa fa-fw fa-id-card-alt"></i>{{(group.drivers | keyvalue).length}}</span
                >
                <span class="label label-default" style="float: left; margin-right: 2px"
                    ><i class="fa fa-fw fa-draw-polygon"></i> {{(group.geofences | keyvalue).length}}</span
                >

                <span style="float: left; margin-left: 10px; margin-top: 2px; margin-right: 5px"
                    ><i
                        class="fa fa-fw"
                        [ngClass]="isOpened(group.groupId) ? 'fa-light fa-chevron-down' : 'fa-light fa-chevron-right'"
                    ></i
                ></span>
            </span>
        </span>

        <div class="hierarchy-group-content" *ngIf="includeItems && isOpened(group.groupId)">
            <ng-container *ngFor="let asset of group.assets | keyvalue">
                <span class="hierarchy-group-content-item">
                    <a class="btn btn-info btn-grid"><i class="fa fa-fw fa-car"></i> {{ asset.value }} </a>
                </span>
            </ng-container>
            <ng-container *ngFor="let driver of group.drivers | keyvalue">
                <span class="hierarchy-group-content-item">
                    <a class="btn btn-info btn-grid"><i class="fa fa-fw fa-id-card-alt"></i> {{ driver.value }}</a>
                </span>
            </ng-container>
            <ng-container *ngFor="let geofence of group.geofences | keyvalue">
                <span class="hierarchy-group-content-item">
                    <a class="btn btn-info btn-grid"><i class="fa fa-fw fa-draw-polygon"></i> {{ geofence.value }}</a>
                </span>
            </ng-container>
        </div>

        <fh-hierarchy-display
            [groups]="groups"
            [includeItems]="includeItems"
            [parentId]="group.groupId"
        ></fh-hierarchy-display>
    </li>
</ul>
