<div class="col-lg-12">
    <div class="horizontalMenuWrapper">
        <ul>
            <li [ngClass]="{active: activeRoute('AccountDetails/Index')}">
                <a [routerLink]="['/AccountDetails/Index/', accountId]">
                    <i class="fas fa-fw fa-building"></i>
                    <div>{{ 'menu.dashboard' | translate }}</div>
                </a>
            </li>
            <li
                *ngIf="permissions['Platform_IsReseller'] || permissions['Platform_IsFleetManager']"
                [ngClass]="{active: activeRoute('AccountDetails/Shared')}"
            >
                <a [routerLink]="['/AccountDetails/Shared/', accountId]">
                    <i class="fas fa-fw fa-podcast"></i>
                    <div>{{ 'general.shared' | translate }}</div>
                </a>
            </li>
            <li *ngIf="permissions['Platform_IsReseller']" [ngClass]="{active: activeRoute('AccountDetails/Matrix')}">
                <a [routerLink]="['/AccountDetails/Matrix/', accountId]">
                    <i class="fas fa-fw fa-podcast"></i>
                    <div>{{ 'general.matrix' | translate }}</div>
                </a>
            </li>
            <li
                *ngIf="permissions['Platform_IsReseller'] || permissions['Platform_IsFleetManager']"
                [ngClass]="{active: activeRoute('AccountDetails/Settings')}"
            >
                <a [routerLink]="['/AccountDetails/Settings/', accountId]">
                    <i class="fas fa-fw fa-cog"></i>
                    <div>{{ 'menu.settings' | translate }}</div>
                </a>
            </li>
            <li
                *ngIf="permissions['FleetManagement_External']"
                [ngClass]="{active: activeRoute('AccountDetails/External')}"
            >
                <a [routerLink]="['/AccountDetails/External/', accountId]">
                    <i class="fas fa-fw fa-link"></i>
                    <div>{{ 'menu.externalApi' | translate }}</div>
                </a>
            </li>
            <li [ngClass]="{active: activeRoute('AccountDetails/Notifications')}">
                <a [routerLink]="['/AccountDetails/Notifications/', accountId]">
                    <i class="fas fa-fw fa-envelope-open-text"></i>
                    <div>{{ 'general.notifications' | translate }}</div>
                </a>
            </li>
            <li *ngIf="permissions['FleetManagement_Log']" [ngClass]="{'active': activeRoute('Log')}">
                <a href="/#/AccountDetails/Log/{{accountId}}"
                    ><i class="fas fa-fw fa-file-alt"></i>
                    <div>{{ 'menu.log' | translate }}</div>
                </a>
            </li>
        </ul>
    </div>
    <div class="horizontalMenuWrapper" style="float: right">
        <ul>
            <ng-content></ng-content>
        </ul>
    </div>
</div>
<div style="clear: both"></div>
