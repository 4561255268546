export class ServiceAnnouncement {
  id: any;
  cultures: any;
  resellerId: any;
  resellerDescription: any;
  message: any;
  details: any;
  severity: any;
  validFrom: Date;
  validTill: Date;
}
