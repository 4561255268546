<button class="btn btn-info" (click)="share()"><i class="fas fa-fw fa-share-nodes"></i></button>

<div
    (onHidden)="hideModal()"
    [config]="{ backdrop: true, ignoreBackdropClick: true }"
    bsModal
    #addModal="bs-modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
>
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title pull-left">
                    <i class="fa fa-fw fa-share-nodes"></i> {{'general.sharing' | translate }}
                </h5>
                <button type="button" class="close float-right" aria-label="Close" (click)="hideModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="popup-content" style="position: relative">
                <fh-wizard #wizard [(step)]="step" (finish)="process($event)">
                    <fh-wizard-step icon="fa-save" icon="fa-car-bus" [validate]="assetForm" [padding]="false">
                        <div class="wizard-content">
                            <form [formGroup]="assetForm" style="width: 100%; border-top: 0px">
                                <table style="width: 100%" class="table table-fixed nowrap dtr-inline no-footer">
                                    <tbody>
                                        <tr>
                                            <td style="width: 40%">{{ 'general.entity' | translate }}</td>
                                            <td>{{ ('enums.shareTokenTypes.' + type) | translate }}</td>
                                        </tr>
                                        <!-- <tr>
                                            <td style="width: 40%">
                                                {{ 'general.id' | translate }}
                                            </td>
                                            <td>{{ entityId }}</td>
                                        </tr> -->
                                        <tr>
                                            <td style="width: 40%">{{ 'general.endDate' | translate }}</td>
                                            <td style="width: auto">
                                                <input
                                                    [ngStyle]="{'border': assetForm.get('dateTo').errors ? '1px solid red' : '' }"
                                                    class="form-control table-inline"
                                                    id="dateTo"
                                                    formControlName="dateTo"
                                                    autocomplete="off"
                                                    [bsConfig]="dpConfig"
                                                    bsDatepicker
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </form>
                        </div>
                    </fh-wizard-step>
                    <fh-wizard-step icon="fa-file-pdf" (open)="requestShareLink()">
                        <div class="wizard-content">
                            <ngx-loading [show]="loadingShareLink"></ngx-loading>
                            <form style="width: 100%; border-top: 0px">
                                <table style="width: 100%" class="table table-fixed nowrap dtr-inline no-footer">
                                    <tbody>
                                        <tr>
                                            <td style="width: 40%">{{ 'general.link' | translate }}</td>
                                            <td>
                                                <fh-copyinput [textMode]="true">
                                                    {{ baseUrl }}/Sharing/{{ token?.message}}
                                                </fh-copyinput>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </form>
                        </div>
                    </fh-wizard-step>
                </fh-wizard>
            </div>
        </div>
    </div>
</div>
