import { Component } from "@angular/core";
import { SignalrService } from "app/services/signalr/signalr.service";

@Component({
  selector: "[fh-signalr-notifications]",
  styleUrls: ["signalrnotifications.component.css"],
  templateUrl: "./signalrnotifications.component.html",
})
export class SignalRNotificationsComponent {
  notifications = [];
  notificationsUnread = [];
  filter;

  constructor(public signalrService: SignalrService) {
    signalrService.notifications.subscribe((value) => {
      this.notifications = value;
      this.notificationsUnread = this.notifications?.filter((x) => x.isRead == false);
    });
  }

  keepOpen(event) {
    event.stopPropagation();
  }

  clear() {
    this.notifications = [];

    event.stopPropagation();
  }
}
