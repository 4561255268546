import { Component, Input, OnInit } from "@angular/core";
import { ControlContainer, NgForm } from "@angular/forms";
import { FormMode } from "app/common/enums";
import { Asset } from "app/models/asset.model";

@Component({
  selector: "fh-wasl-asset-details",
  templateUrl: "waslAssetDetails.component.html",
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class WaslAssetDetailsComponent implements OnInit {
  @Input() asset: Asset;
  @Input() formMode: any = FormMode.read;

  plateTypes = [
    "enums.plateType.0",
    "enums.plateType.1",
    "enums.plateType.2",
    "enums.plateType.3",
    "enums.plateType.4",
    "enums.plateType.5",
    "enums.plateType.6",
    "enums.plateType.7",
    "enums.plateType.8",
    "enums.plateType.9",
    "enums.plateType.10",
    "enums.plateType.11",
  ];

  getPlateType = (value) => (Number(value) ? this.plateTypes[value] : "enums.plateType.0");

  constructor() {}

  ngOnInit(): void {}
}
