<div class="ibox-content slick" style="position: relative">
    <table style="width: 100%" class="table table-fixed nowrap dtr-inline no-footer">
        <tbody *ngIf="!address">
            <tr>
                <td colspan="4">
                    {{ "general.noAddress" | translate }}
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="address" [@enterAnimation]>
            <tr>
                <td class="row_opening">{{ "general.street" | translate }} / {{ "general.no" | translate }}</td>
                <td style="width: 35%">
                    <input
                        type="text"
                        [readonly]="formMode == 1"
                        name="ac_street"
                        class="form-control table-inline"
                        [(ngModel)]="address.street"
                    />
                </td>
                <td style="width: 15%">
                    <input
                        type="text"
                        [readonly]="formMode == 1"
                        name="ac_number"
                        class="form-control table-inline"
                        [(ngModel)]="address.no"
                    />
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ "general.zip" | translate }}
                </td>
                <td class="row_content">
                    <input
                        type="text"
                        [readonly]="formMode == 1"
                        name="ac_zipcode"
                        class="form-control table-inline"
                        [(ngModel)]="address.zip"
                    />
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ "general.country" | translate }}
                </td>
                <td class="row_content">
                    <input
                        type="text"
                        [readonly]="formMode == 1"
                        name="ac_country"
                        class="form-control table-inline"
                        [(ngModel)]="address.country"
                    />
                </td>
            </tr>
            <tr>
                <td class="row_opening">{{ "general.phone" | translate }} / {{ "general.ext" | translate }}</td>
                <td style="width: 35%">
                    <input
                        type="text"
                        [readonly]="formMode == 1"
                        name="ac_street"
                        class="form-control table-inline"
                        [(ngModel)]="address.phone"
                    />
                </td>
                <td style="width: 15%">
                    <input
                        type="text"
                        [readonly]="formMode == 1"
                        name="ac_number"
                        class="form-control table-inline"
                        [(ngModel)]="address.ext"
                    />
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ "general.mobile" | translate }}
                </td>
                <td class="row_content">
                    <input
                        type="text"
                        [readonly]="formMode == 1"
                        name="ac_mobile"
                        class="form-control table-inline"
                        [(ngModel)]="address.mobile"
                    />
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ "general.email" | translate }}
                </td>
                <td class="row_content">
                    <input
                        type="text"
                        [readonly]="formMode == 1"
                        name="ac_email"
                        class="form-control table-inline"
                        [(ngModel)]="address.email"
                    />
                </td>
            </tr>
        </tbody>
    </table>
</div>
