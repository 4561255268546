<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/"> <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Geofences/Overview">{{'general.geofences' | translate }}</a>
                </li>
                <li class="breadcrumb-item active">
                    <a href="/#/Geofences/Overview">{{'menu.overview' | translate }}</a>
                </li>
            </ol>
        </nav>

        <fh-filter-toggler
            style="margin-left: auto"
            [constructorName]="constructorName"
            (filterChanged)="showFilter = $event"
            (presetsChanged)="showPresets = $event"
        ></fh-filter-toggler>
    </div>
    <fh-geofence-navigation>
        <li *ngIf="permissions['Geofences_Add']">
            <a href="/#/Geofences/Add/"
                ><i class="fa fa-fw fa-draw-polygon"></i>
                <div>{{ 'general.add' | translate }}</div>
            </a>
        </li>
    </fh-geofence-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">
        <div class="row">
            <div class="col-lg-12">
                <fh-error [error]="error" [success]="success"></fh-error>
                <!-- Custom filters -->
                <div class="ibox" [hidden]="!showFilter">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-filter"></i> {{'general.filter' | translate }}</h5>
                        <span class="float-right hand btn btn-info filter-margin-fix" (click)="clearAllFilters();">
                            <i class="fa fa-fw fa-times"></i>
                            <span class="d-none d-md-inline-flex"> {{'general.clearFilters' | translate }}</span>
                        </span>
                    </div>
                    <div class="ibox-content slick_toppadding" style="position: relative">
                        <ngx-loading [show]="loading"></ngx-loading>
                        <div id="dynamicFilters" class="flex-container"></div>
                        <div style="clear: both"></div>
                    </div>
                </div>
                <!-- End default dynamic filters -->

                <div class="ibox">
                    <div class="ibox-title">
                        <h5><i class="fa fa-fw fa-draw-polygon"></i> {{'general.geofences' | translate }}</h5>
                        <iboxseach
                            [searchTerm]="searchTerm"
                            (seachChanged)="seachChanged($event)"
                            [constructorName]="
                            constructorName"
                            (filterChanged)="showFilter = $event"
                            (presetLoaded)="onPresetLoaded($event)"
                            [showColumns]=" true"
                            (columnsChanged)="drawFilterRow()"
                        >
                            <fh-batch-processing
                                *ngIf="enableSelecting"
                                [entityTypes]="entityTypes"
                                (onClose)="onClose($event)"
                            >
                            </fh-batch-processing>
                        </iboxseach>
                    </div>
                    <div class="ibox-content slick" style="position: relative" *ngIf="languageLoaded">
                        <table
                            style="width: 100%; min-height: 50px"
                            fh-datatable
                            [dtOptions]="dtOptions"
                            [dtTrigger]="dtTrigger"
                            class="table nowrap margin-bottom-0 dataTable dtr-inline"
                        ></table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</fh-permission-handler>
