import { ChangeDetectorRef, Component, OnDestroy } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { AuthenticationService } from "app/services/authentication/authentication.service";
import { DeviceTypeService } from "app/services/devicetypes/devicetypes.service";

import { GridBase360Directive } from "app/common/360Grid.base";
import { getDefaultDpConfig, getGridButtons, getGridLanguages } from "app/common/gridhelper";
import { BsDaterangepickerConfig } from "ngx-bootstrap/datepicker";

import { CarSharingService } from "app/services/carsharing/carsharing.service";
import { StorageHelper } from "app/common/storagehelper";

// Moment
import Moment from "moment-timezone";
import { ColorService } from "app/services/common/color.service";

window["moment"] = Moment;

@Component({
  selector: "fh-carsharing-damages",
  templateUrl: "damages.component.html",
})
export class CarSharingDamagesViewComponent extends GridBase360Directive implements OnDestroy {
  loading = false;

  timezoneIana: string;
  token: string;

  languageLoaded = false;
  episodesLoaded = false;

  permissionName = "Modules_HasPrivateFleet";
  constructorName = "CarSharingDamagesViewComponent";

  error;
  success;

  // Daterange
  public dpConfig: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();
  daterangepickerModel: Date[];

  constructor(
    private carsharingService: CarSharingService,
    private translateService: TranslateService,
    private authenticationService: AuthenticationService,
    private deviceTypeService: DeviceTypeService,
    private cd: ChangeDetectorRef,
    protected storageHelper: StorageHelper,
    private colorService: ColorService
  ) {
    super(storageHelper);

    this.timezoneIana = authenticationService.getTimeZoneIana();
    this.token = authenticationService.getAuthToken();

    this.daterangepickerModel = [
      Moment().tz(this.timezoneIana).subtract(7, "days").startOf("day").toDate(),
      Moment().tz(this.timezoneIana).endOf("day").toDate(),
    ];

    this.dpConfig = getDefaultDpConfig(authenticationService);

    // Get all the date for dropdown boxes
    this.translateService.get("general.date").subscribe(
      (_) => {
        this.languageLoaded = true;
        this.loading = false;
        this.cd.markForCheck();

        this.initGrid();
      },
      (err) => {
        this.error = err;
        this.languageLoaded = true;
        this.loading = false;
        this.cd.markForCheck();
      }
    );
  }

  initGrid(): void {
    const that = this;

    this.columns = [
      {
        name: "id",
        data: "id",
        className: "noVis",
        orderable: false,
        visible: false,
        title: '<div class="hideDropdown"></div>',
        width: "20",
        render: function (data, type, row) {
          const detailsText = that.translateService.instant("general.details");
          return `
            <a class='btn btn-primary btn-grid' title='${detailsText}' href='/#/DeviceDetails/Index/${data}'>
              <span class="d-none d-md-inline-flex" style="padding-left: 7px">${detailsText}</span>
              <i class='fas fa-fw fa-angle-right'></i>
            </a>`;
        },
      },
      {
        name: "id_export",
        data: "id",
        className: "noVis",
        title: this.translateService.instant("general.id"),
        visible: false,
      },
      {
        name: "url",
        data: "url",
        defaultContent: "-",
        title: this.translateService.instant("general.image"),
        render: function (data, type, row) {
          const download = data.toString().replace("dl=0", "dl=1");
          if (type === "exportxls") {
            return download;
          }
          if (data.indexOf(".pdf") > -1) {
            return `
              <a href="${download}" target="_blank">
                <i class="fa fa-file-pdf fa-xl" />
              </a>`;
          } else {
            return `
              <a href="${download}" target="_blank">
                <img src="${download}" width="40px" />
              </a>`;
          }
        },
      },
      {
        name: "timestamp",
        data: "timestamp",
        title: this.translateService.instant("general.timeStamp"),
        render: function (data, type, row) {
          const date = Moment.utc(data)["tz"](that.timezoneIana);
          return data ? '<span title=" ' + date.toLocaleString() + '">' + date.format("lll") + "</span>" : "";
        },
      },
      {
        name: "assetCode",
        data: "assetCode",
        defaultContent: "-",
        title: this.translateService.instant("general.assetCode"),
        render: function (data, type, row) {
          return data ? data : "-";
        },
      },
      {
        name: "assetName",
        data: "assetName",
        defaultContent: "-",
        title: this.translateService.instant("general.assetName"),
        render: function (data, type, row) {
          return data ? data : "-";
        },
      },
      {
        name: "plateNumber",
        data: "plateNumber",
        defaultContent: "-",
        visible: false,
        title: this.translateService.instant("general.plateNumber"),
        render: function (data, type, row) {
          return data ? data : "-";
        },
      },
      {
        name: "description",
        data: "description",
        defaultContent: "-",
        title: this.translateService.instant("general.description"),
        render: function (data, type, row) {
          return data ? data : "-";
        },
      },
      {
        name: "firstName",
        data: "firstName",
        defaultContent: "-",
        title: this.translateService.instant("general.firstName"),
        visible: false,
        render: function (data, type, row) {
          return data ? data : "-";
        },
      },
      {
        name: "lastName",
        data: "lastName",
        defaultContent: "-",
        title: this.translateService.instant("general.lastName"),
        render: function (data, type, row) {
          return data ? data : "-";
        },
      },
      {
        name: "location",
        data: "location",
        defaultContent: "-",
        title: this.translateService.instant("general.location"),
        render: function (data, type, row) {
          switch (data) {
            case "3":
              return "Front";
            case "11":
              return "Back";
            case "18":
              return "Front Right";
            case "19":
              return "Rear Right";
            case "20":
              return "Front Left";
            case "21":
              return "Rear Left";
          }
        },
      },
    ];

    this.dtOptions = {
      buttons: getGridButtons(
        this.commonExportOptions,
        "carsharing_overview",
        this.translateService.instant("general.carsharing"),
        this.colorService
      ),
      pagingType: "simple_numbers",
      serverSide: false,
      processing: false,
      scrollX: true,
      colReorder: { fixedColumnsLeft: 2 },
      stateSave: true,
      stateSaveCallback: function (settings, data) {
        that.saveState(that.constructorName, data);
      },
      stateLoadCallback: function (_, callback) {
        (async () => {
          try {
            const columnSettings = await that.loadState(that.constructorName);
            that.searchTerm = columnSettings && columnSettings.search && columnSettings.search.search;
            return columnSettings;
          } catch (e) {
            that.error = {};
            that.error.error = e;
            that.error.statusText = "Error fetching column settings";

            return null;
          }
        })().then((result) => {
          callback(result);
        });
      },
      order: [[1, "asc"]],
      ajax: (dataTablesParameters: any, callback) => {
        $(".dataTables_info").html(this.translateService.instant("grid.loadingData"));
        this.loading = true;
        this.carsharingService.getDamages().subscribe(
          (resp) => {
            callback({
              recordsTotal: resp.length,
              recordsFiltered: resp.length,
              data: resp,
            });
          },
          (error) => {
            this.success = null;
            this.error = error;
            this.loading = false;
          }
        );
      },
      initComplete: function (settings, json) {
        that.loading = false;
        that.episodesLoaded = false;

        console.log("init complete");
        that.checkFilters();
        that.drawFilterRow();
        that.loading = false;
      },
      colVis: {
        restore: this.translateService.instant("general.restore"),
        showAll: this.translateService.instant("general.showAll"),
        showNone: this.translateService.instant("general.hideAll"),
        exclude: ["id", "id_export"],
      },
      columns: this.columns,
      pageLength: 25,
      lengthMenu: [
        [10, 17, 25, 50, -1],
        [10, 17, 25, 50, this.translateService.instant("general.all")],
      ],
      language: getGridLanguages(this.translateService),
    };
  }
}
