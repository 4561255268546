import { Component, OnDestroy, OnInit } from "@angular/core";
import { Question } from "../../models/question";
import { FaqService } from "../../services/help/faq.service";

@Component({
  selector: "fh-help",
  templateUrl: "help.component.html",
  providers: [FaqService],
})
export class HelpViewComponent implements OnDestroy, OnInit {
  text;
  answer;

  questions: Question[];

  ngOnDestroy(): void {}

  constructor(private faqService: FaqService) {}

  ngOnInit() {
    console.log("get questions");
    this.questions = this.faqService.getQuestions();
  }

  addQuestion() {
    const question: Question = {
      text: this.text,
      answer: this.answer,
      hide: true,
    };

    // console.log(question);
    this.faqService.addQuestion(question);
  }

  removeQuestion(question) {
    console.log("1 " + question);
    this.faqService.removeQuestion(question);
  }
}
