import { Component, Input, OnInit, ChangeDetectorRef, OnChanges, Output, EventEmitter } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { FormMode } from 'app/common/enums';
import { AssetService } from 'app/services/asset/asset.service';


@Component({
    selector: 'fh-asset-chooser',
    templateUrl: 'assetChooser.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class AssetChooserDetailsComponent implements OnChanges, OnInit {

    @Input() accountId: number;
    @Input() assetId: number;
    @Input() formMode: any = FormMode.read;
    @Input() activeOnly = false;
    @Output() onAssetChanged = new EventEmitter();

    assets = [];
    selectedAsset;
    loading = false;

    constructor(private cd: ChangeDetectorRef, private assetService: AssetService) {
        console.log('Asset selector init');
    }

    ngOnInit(): void {
        console.log('Asset selector init');

        this.loadAssets();
    }

    ngOnChanges(): void {
        this.loadAssets();
    }

    loadAssets() {
        console.log('Asset selector init');

        if (!this.assetId) {
            this.selectedAsset = null;
            this.assets = [];
            this.onAssetChanged.emit(undefined);
        }

        this.loading = true;
        if (this.accountId > 0) {
            this.assetService.getAssetsByAccount(this.accountId).subscribe(result => {
                this.assets = result;

                if (this.activeOnly){
                    this.assets = this.assets.filter(x => x.isArchived == false);
                }

                // if (this.assetId != null) {
                console.log('Setting asset to ' + this.assetId);
                this.selectedAsset = this.assetId;
                // }

                this.loading = false;
                this.cd.markForCheck();
            });
        } else {
            this.loading = false;
            this.cd.markForCheck();
        }
    }

    itemSelected(event) {
        const key = this.selectedAsset ? this.selectedAsset.toString() : undefined;

        const assets = this.assets.filter(x => x.id.toString() === key);
        this.onAssetChanged.emit(assets.length > 0 ? assets[0] : undefined);
    }
}

