import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { GridBase360Directive } from "app/common/360Grid.base";
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { map } from "rxjs";
import { languages } from "../../common/enums";

// Moment timezone
import Moment from "moment-timezone";
import { StorageHelper } from "app/common/storagehelper";

window["moment"] = Moment;

@Component({
  selector: "fh-language-settings",
  templateUrl: "language.template.html",
})
export class LanguageSettingsComponent extends GridBase360Directive implements OnInit {
  loading: boolean;
  dtOptions: DataTables.Settings = {};

  languages;
  langData;

  langDataArray = [];
  currentLang: any;

  constructor(
    private localeService: BsLocaleService,
    private http: HttpClient,
    private translate: TranslateService,
    protected storageHelper: StorageHelper
  ) {
    super(storageHelper);
  }

  onChange(event) {
    this.loading = true;

    let lang: any = event.value;

    this.http
      .get(event.value)
      .pipe(map(this.extractData))
      .subscribe((langData) => {
        this.langData = langData;
        this.loading = false;
      });

    if (lang) {
      lang = lang.replace("./assets/i18n/", "");
      lang = lang.replace(".json", "");
      this.translate.use(lang);
      Moment.locale(lang);
      this.localeService.use(lang);
    }
  }

  setLangtagMode() {
    console.log("Setting langtag mode");
  }

  ngOnInit(): void {
    // https://stackoverflow.com/questions/47606201/how-could-you-implement-a-database-approach-to-angular-internationalization

    this.currentLang = this.translate.currentLang;
    this.onChange({ value: "./assets/i18n/" + this.currentLang + ".json" });

    this.languages = Object.keys(languages)
      .filter((k) => typeof languages[k] === "string")
      .map((n) => ({ name: n, path: languages[n] }));

    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 17,
    };
  }

  private extractData(res: Response) {
    this.langDataArray = [];

    for (const key in res) {
      const value = res[key];
      const object = { tag: key, text: value };
      this.langDataArray.push(object);
    }
    return this.langDataArray || {};
  }
}
