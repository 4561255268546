import {
  Component,
  EventEmitter,
  Output,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { FormGroup, FormControl, Validators, FormArray, ValidatorFn, ValidationErrors } from "@angular/forms";
import { EntityType, VehicleType } from "app/common/enums";
import { AssetType } from "app/models/assetType.model";
import { DeviceService } from "app/services/device/device.service";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import { AssetService } from "app/services/asset/asset.service";

@Component({
  selector: "fh-batch-activate-device",
  templateUrl: "activateDevice.template.html",
  styleUrls: ["activateDevice.template.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BatchActivateDeviceComponent implements OnChanges, OnInit {
  step: number;
  @Input() entityTypes = EntityType.Device;
  @Input() gridSelection = [];
  @Output() onFinish = new EventEmitter();
  disabled = true;
  logItem;

  selectedAccount = undefined;

  selectedAssetType = undefined;
  selectedIcon = undefined;
  name;
  brand;
  model;
  loadingAccounts = false;
  loadingDeviceDetails = false;
  loadingResellers = false;
  selectedResellerId;
  deviceForm;

  loadingDmv = false;
  loadingDmvResultReady = false;
  dmvErrorMessages = [];

  groupForm = new FormGroup({
    selectedReseller: new FormControl(null, Validators.required),
    selectedAccount: new FormControl(null, Validators.required),
  });

  vehicleTypes: { id: string; name: any }[] = [];
  vehicleTypesDict: { [id: string]: string } = {};

  assetTypes: AssetType[];

  public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();

  constructor(private assetService: AssetService, private cd: ChangeDetectorRef, private deviceService: DeviceService) {
    this.dpConfig.containerClass = "theme-default"; // or whatever color
    this.dpConfig.dateInputFormat = "lll";
    this.dpConfig.isAnimated = true;
    this.dpConfig.withTimepicker = true;
    this.dpConfig.keepDatepickerOpened = true;
    this.step = 1;
  }

  ngOnInit(): void {
    for (const [key, name] of Object.entries(VehicleType)) {
      if (typeof VehicleType[key] === "string") {
        this.vehicleTypes.push({ id: key, name: VehicleType[key] });
        this.vehicleTypesDict[key] = <string>name;
      }
    }

    console.log("init");
    this.step = 1;
    this.cd.markForCheck();
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log("changes");
    this.step = 1;
    this.cd.markForCheck();
  }

  public comparisonValidator(): ValidatorFn {
    return (group: FormGroup): ValidationErrors => {
      const activationDate = group.controls["activationDate"];
      const calibrationOdoDate = group.controls["calibrationOdoDate"];

      if (calibrationOdoDate.value < activationDate.value) {
        calibrationOdoDate.setErrors({ notEquivalent: true });
      } else {
        calibrationOdoDate.setErrors(null);
      }
      return;
    };
  }

  loadDevices() {
    if (this.deviceForm) {
      return;
    }

    const formGroups = [];
    this.loadingDeviceDetails = true;

    this.deviceService.getDeviceData(this.gridSelection).subscribe((devices) => {
      this.loadingDeviceDetails = false;
      this.cd.markForCheck();

      // Filter devices with assets as they are not allowed to be assigned
      devices = devices.filter((x) => x.accountId == null || x.accountId === 0);

      const date = new Date();
      date.setSeconds(0);
      date.setMilliseconds(0);

      let counter = 1;
      for (const device of devices) {
        const form = new FormGroup({
          imei: new FormControl(device.unitId, Validators.required),
          deviceId: new FormControl(device.id, Validators.required),
          name: new FormControl("Asset " + counter, [
            Validators.required,
            Validators.pattern(/\S.*\S/),
            Validators.minLength(1),
          ]),
          code: new FormControl(""),
          activationDate: new FormControl(date, Validators.required),
          vin: new FormControl(""),
          vehicleType: new FormControl("1"),
          plateNumber: new FormControl(""),
          brand: new FormControl(""),
          model: new FormControl(""),
          year: new FormControl(""),
          color: new FormControl(""),
          comment: new FormControl(""),
          calibrationOdo: new FormControl(""),
          calibrationOdoDate: new FormControl(date),
        });

        form.setValidators(this.comparisonValidator());

        formGroups.push(form);
        counter++;
      }

      this.deviceForm = new FormArray(formGroups);
    });
  }

  initFinish() {
    // Fetch all data and make sure it can be saved
    console.log(1);
    console.log(this.deviceForm.value);
  }

  onCheckOutFinish() {
    for (const device of this.deviceForm.value) {
      device.accountId = this.groupForm.value.selectedAccount;
    }

    const returnObject = {
      items: this.deviceForm.value,
      ...this.groupForm.value,
    };

    this.onFinish.emit({ status: "success", object: returnObject, log: this.logItem });
    this.step = 1;
  }

  initForm() {
    console.log("init tab");
    this.step = 1;
  }

  accountChanged(accountId) {
    this.cd.markForCheck();
  }

  resellerChanged(resellerId) {
    this.groupForm.patchValue({
      selectedAccount: undefined,
    });

    if (resellerId) {
      this.selectedResellerId = resellerId;
    }

    setTimeout(() => {
      this.cd.markForCheck();
    }, 100);
  }

  getInformationDMV(isVin: boolean) {
    this.dmvErrorMessages.length = 0;

    this.deviceForm.controls.forEach((control) => {
      const device = control.value;
      this.loadingDmv = true;
      this.cd.markForCheck();

      if (isVin === false && device.plateNumber !== "" && device.brand === "") {
        this.assetService.getInformationDMV(device.plateNumber, this.selectedResellerId).subscribe(
          (dmvAsset) => {
            if (dmvAsset.brand != null) {
              device.brand = dmvAsset.brand;
              device.model = dmvAsset.model;
              device.color = dmvAsset.color;
              device.year = dmvAsset.year;
              device.vehicleType = dmvAsset.vehicleType;

              control.patchValue({ brand: dmvAsset.brand });
              control.patchValue({ model: dmvAsset.model });
              control.patchValue({ color: dmvAsset.color });
              control.patchValue({ year: dmvAsset.year });
              control.patchValue({ vehicleType: dmvAsset.vehicleType });
            } else {
              this.dmvErrorMessages.push(device.plateNumber + ": " + dmvAsset.message);
            }

            this.loadingDmv = false;
            this.loadingDmvResultReady = true;
            this.cd.markForCheck();
          },
          (error) => {
            this.dmvErrorMessages.push(error.message);
            this.loadingDmv = false;
            this.loadingDmvResultReady = true;
            this.cd.markForCheck();
          }
        );
      } else if (isVin === true && device.vin !== "" && device.brand === "") {
        this.assetService.getInformationDMV(device.vin, this.selectedResellerId).subscribe(
          (dmvAsset) => {
            if (dmvAsset.brand != null) {
              device.brand = dmvAsset.brand;
              device.model = dmvAsset.model;
              device.color = dmvAsset.color;
              device.year = dmvAsset.year;
              device.vehicleType = dmvAsset.vehicleType;

              control.patchValue({ brand: dmvAsset.brand });
              control.patchValue({ model: dmvAsset.model });
              control.patchValue({ color: dmvAsset.color });
              control.patchValue({ year: dmvAsset.year });
              control.patchValue({ vehicleType: dmvAsset.vehicleType });
            } else {
              this.dmvErrorMessages.push(device.vin + ": " + dmvAsset.message);
            }

            this.loadingDmv = false;
            this.loadingDmvResultReady = true;
            this.cd.markForCheck();
          },
          (error) => {
            this.dmvErrorMessages.push(error.message);
            this.loadingDmv = false;
            this.loadingDmvResultReady = true;
            this.cd.markForCheck();
          }
        );
      } else {
        this.loadingDmv = false;
      }
    });

    this.cd.markForCheck();
  }
}
