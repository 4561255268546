<ng-select
    [loading]="loadingGarages"
    ngDefaultControl
    [searchable]="true"
    [clearable]="clearable"
    [disabled]="garages.length == 0"
    [readonly]="readonly"
    placeholder="{{placeholder}} ({{garages.length}})"
    #input
    [(ngModel)]="value"
    class="form-control table-inline"
    (ngModelChange)="garageChanged()"
>
    <ng-option *ngFor="let garage of garages | orderBy : 'name':false" [value]="garage.id">
        <i class="fas fa-fw fa-garage"></i> {{ garage.name }}</ng-option
    >
</ng-select>
<div style="clear: both"></div>
