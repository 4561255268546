<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/"> <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Fuel/Overview">{{'general.fuel' | translate }}</a>
                </li>
                <li class="breadcrumb-item active">
                    <a href="/#/Fuel/Map">{{'menu.map' | translate }}</a>
                </li>
            </ol>
        </nav>

        <div class="d-none d-sm-inline-flex" style="margin-left: auto; margin-right: 10px">
            {{ statusText }} {{ previousLookupTimestamp | amDateFormat: 'LLL' }}
            <i *ngIf="loading" style="margin-left: 3px" class="fa fa-spinner fa-spin fa-fw"></i>
        </div>
    </div>
    <div style="clear: both"></div>
    <div style="padding: 10px 2px 0px 2px" class="flex-container">
        <div class="col-lg-3 col-sm-12">
            <div class="form-group input-group" style="margin-bottom: 2px">
                <span class="input-group-prepend">
                    <i class="fa fa-fw fa-calendar"></i>
                </span>
                <input
                    placeholder="Daterangepicker"
                    style="width: 100%"
                    placeholder="Datepicker"
                    type="text"
                    [bsConfig]="dpConfig"
                    class="form-control"
                    bsDaterangepicker
                    (onHidden)="dateChanged('hidden')"
                    [(ngModel)]="daterangepickerModel"
                />
                <span class="input-group-calendar-datatables">
                    <i class="fa fa-calendar-alt"></i>
                </span>
            </div>
        </div>
        <div class="col-sm-3 d-none d-sm-block">
            <div class="form-group input-group" style="margin-bottom: 2px">
                <span class="input-group-prepend">
                    <i class="fa fa-fw fa-search"></i>
                </span>
                <input
                    style="width: 100%"
                    type="text"
                    min="0"
                    class="form-control"
                    placeholder="{{ 'general.searchOnDeviceOrImei' | translate}}"
                    (input)="filterMarkers()"
                    [(ngModel)]="search"
                />
            </div>
        </div>
        <div class="col-sm-2 d-none d-sm-block">
            <div class="form-group input-group" style="margin-bottom: 2px">
                <span class="input-group-prepend">
                    <i class="fa fa-fw fa-gas-pump"></i>
                </span>
                <ng-select
                    class="form-control"
                    name="displayEvents"
                    (change)="filterMarkers()"
                    [(ngModel)]="displayEventTypes"
                    style="width: 100%"
                >
                    <ng-option [value]="0">{{ 'general.all' | translate}} </ng-option>
                    <ng-option [value]="1">
                        <i class="fa fa-gas-pump eye-active green"></i>
                        {{ 'general.onlyFillups' | translate}}
                    </ng-option>
                    <ng-option [value]="2">
                        <i class="fa fa-user-secret eye-active red"></i>
                        {{ 'general.onlyThefts' | translate}}
                    </ng-option>
                </ng-select>
            </div>
        </div>
        <div class="col-sm-2 d-none d-sm-block">
            <div class="form-group input-group" style="margin-bottom: 2px">
                <span class="input-group-prepend">
                    <i class="fa fa-fw fa-gas-pump"></i>
                </span>
                <input
                    style="width: 100%"
                    type="number"
                    min="0"
                    class="form-control"
                    placeholder="{{ 'general.minimumActivityInLiters' | translate}}"
                    (change)="filterMarkers()"
                    [(ngModel)]="fuelingActivityInLiters"
                />
            </div>
        </div>
        <div class="col-sm-2 d-none d-sm-block">
            <div class="form-group input-group" style="margin-bottom: 2px">
                <span class="input-group-prepend">
                    <i class="fa fa-fw fa-percentage"></i>
                </span>
                <input
                    style="width: 100%"
                    type="number"
                    min="0"
                    class="form-control"
                    placeholder="{{ 'general.minimalCertainty' | translate}}"
                    (change)="filterMarkers()"
                    [(ngModel)]="minimalCertainty"
                />
            </div>
        </div>
        <div style="clear: both"></div>
    </div>
    <div class="col-lg-12" style="position: absolute; top: 200px; z-index: 2000; width: 50%">
        <fh-error [error]="error"></fh-error>
    </div>
</div>

<div id="mapFullContainerWithFilter" class="shadow-inner">
    <ngx-loading [show]="loading"></ngx-loading>
    <div
        id="map"
        style="height: 100%; width: 100%"
        leaflet
        [leafletOptions]="options"
        (leafletMapReady)="onMapReady($event)"
    ></div>

    <div [@slideInOut]="menuState" class="slideMenu sidebar-bg">
        <div class="closeButton" (click)="toggleMenu()">
            <i class="fa fa-fw fa-times"></i>
        </div>
        <div (click)="toggleMenu()" class="slideMenuControl"></div>
        <div class="slideMenuDetails white-bg fontColor" style="position: relative">
            <ngx-loading [show]="loadingSidebar"></ngx-loading>
            <table style="width: 100%" class="table table-fixed nowrap dtr-inline no-footer">
                <tbody>
                    <tr>
                        <td>{{ 'general.fuelDetails' | translate}}</td>
                        <td>
                            <a class="btn btn-primary" href="/#/FuelDetails/Index/{{selectedEvent?.id}}"
                                >To Fuel Details</a
                            >
                        </td>
                    </tr>
                    <tr>
                        <td>{{ 'general.name' | translate}}</td>
                        <td>
                            <a class="secondary link_bolder" href="/#/DeviceDetails/Index/{{selectedEvent?.deviceId}}"
                                >{{ selectedEvent?.assetName }}</a
                            >
                        </td>
                    </tr>
                    <tr>
                        <td>{{ 'general.createdDate' | translate}}</td>
                        <td>{{ selectedEvent?.eventTimeStamp | amDateFormat: 'll' }}</td>
                    </tr>
                    <tr>
                        <td>{{ 'general.fuelingActivityInLiters' | translate}}</td>
                        <td>{{ selectedEvent?.fuelingActivityInLiters+' L' }}</td>
                    </tr>
                    <tr>
                        <td>{{ 'general.certaintyInPercentage' | translate}}</td>
                        <td>{{ selectedEvent?.certaintyInPercentage+'%' }}</td>
                    </tr>
                    <tr>
                        <td>{{ 'general.refuelVsTheft' | translate}}</td>
                        <td>{{ selectedEvent?.refuelVsTheft }}</td>
                    </tr>
                    <tr>
                        <td>{{ 'general.unitId' | translate}}</td>
                        <td>{{ selectedEvent?.unitId }}</td>
                    </tr>
                    <tr *ngIf="permissions['Accounts_View']">
                        <td style="width: 40%">{{ 'general.customer' | translate}}</td>
                        <td style="width: 60%">
                            <a class="secondary link_bolder" href="/#/AccountDetails/Index/{{selectedEvent?.accountId}}"
                                >{{ selectedEvent?.companyName }}</a
                            >
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
