<div class="ibox-content slick" style="position: relative">
    <table style="width: 100%" class="table table-fixed nowrap dtr-inline no-footer">
        <tbody *ngIf="!(geofenceGroup && geofenceGroup.id) && formMode == 1">
            <tr>
                <td colspan="3">
                    {{ "general.noGeofenceGroup" | translate }}
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="(geofenceGroup && geofenceGroup.id) || formMode != 1">
            <tr>
                <td class="row_opening" title="{{ 'general.leavingGeofence' | translate }}">
                    {{ "general.leavingGeofence" | translate }}
                </td>
                <td class="row_content" colspan="2" style="position: relatives; overflow: visible">
                    <fh-user-contact-group-input
                        name="leaveUserContactGroupId"
                        #userContactGroupVar="ngModel"
                        [selectedAccountId]="geofenceGroup.accountId"
                        [multiple]="false"
                        [userContactGroupData]="userContactGroups"
                        [readonly]="formMode == 1"
                        placeholder="{{
                            (geofenceGroup.accountId ? 'general.chooseUserContactGroup' : 'general.firstChooseAccount')
                                | translate
                        }}"
                        [(ngModel)]="geofenceGroup.leaveUserContactGroupId"
                    >
                    </fh-user-contact-group-input>
                </td>
            </tr>
            <tr>
                <td class="row_opening" title="{{ 'general.enteringGeofence' | translate }}">
                    {{ "general.enteringGeofence" | translate }}
                </td>
                <td class="row_content" colspan="2" style="position: relative; overflow: visible">
                    <fh-user-contact-group-input
                        name="enterUserContactGroupId"
                        #userContactGroupVar="ngModel"
                        [selectedAccountId]="geofenceGroup.accountId"
                        [multiple]="false"
                        [userContactGroupData]="userContactGroups"
                        [readonly]="formMode == 1"
                        placeholder="{{
                            (geofenceGroup.accountId ? 'general.chooseUserContactGroup' : 'general.firstChooseAccount')
                                | translate
                        }}"
                        [(ngModel)]="geofenceGroup.enterUserContactGroupId"
                    >
                    </fh-user-contact-group-input>
                </td>
            </tr>
            <tr>
                <td class="row_opening" title="{{ 'general.tooLongOutsideGeofence' | translate }}">
                    {{ "general.tooLongOutsideGeofence" | translate }}
                </td>
                <td class="row_content" style="position: relative; overflow: visible">
                    <fh-user-contact-group-input
                        name="violateOutsideThresholdUserContactGroupId"
                        #userContactGroupVar="ngModel"
                        [selectedAccountId]="geofenceGroup.accountId"
                        [readonly]="formMode == 1"
                        [multiple]="false"
                        [userContactGroupData]="userContactGroups"
                        placeholder="{{
                            (geofenceGroup.accountId ? 'general.chooseUserContactGroup' : 'general.firstChooseAccount')
                                | translate
                        }}"
                        [(ngModel)]="geofenceGroup.violateOutsideThresholdUserContactGroupId"
                    >
                    </fh-user-contact-group-input>
                </td>
                <td class="row_content">
                    <input
                        name="inputFieldOutsideThresholdInMinutes"
                        [(ngModel)]="geofenceGroupOptions.inputFieldOutsideThresholdInMinutes"
                        placeholder="{{ 'placeholder.thresholdInMinutes' | translate }}"
                        class="form-control table-inline"
                        type="number"
                        [readonly]="formMode == 1"
                    />
                </td>
            </tr>
            <tr>
                <td class="row_opening" title="{{ 'general.tooLongInsideGeofence' | translate }}">
                    {{ "general.tooLongInsideGeofence" | translate }}
                </td>
                <td class="row_content" style="position: relative; overflow: visible">
                    <fh-user-contact-group-input
                        name="violateInsideThresholdUserContactGroupId"
                        #userContactGroupVar="ngModel"
                        [selectedAccountId]="geofenceGroup.accountId"
                        [readonly]="formMode == 1"
                        [multiple]="false"
                        [userContactGroupData]="userContactGroups"
                        placeholder="{{
                            (geofenceGroup.accountId ? 'general.chooseUserContactGroup' : 'general.firstChooseAccount')
                                | translate
                        }}"
                        [(ngModel)]="geofenceGroup.violateInsideThresholdUserContactGroupId"
                    >
                    </fh-user-contact-group-input>
                </td>
                <td class="row_content">
                    <input
                        name="inputFieldInsideThresholdInMinutes"
                        [(ngModel)]="geofenceGroupOptions.inputFieldInsideThresholdInMinutes"
                        placeholder="{{ 'placeholder.thresholdInMinutes' | translate }}"
                        class="form-control table-inline"
                        type="number"
                        [readonly]="formMode == 1"
                    />
                </td>
            </tr>
            <tr>
                <td class="row_opening" title="{{ 'general.tooLongOutsideAllGeofences' | translate }}">
                    {{ "general.tooLongOutsideAllGeofences" | translate }}
                </td>
                <td class="row_content" style="position: relative; overflow: visible">
                    <fh-user-contact-group-input
                        name="violateOutsideAllGeofencesThresholdUserContactGroupId"
                        #userContactGroupVar="ngModel"
                        [selectedAccountId]="geofenceGroup.accountId"
                        [readonly]="formMode == 1"
                        [multiple]="false"
                        [userContactGroupData]="userContactGroups"
                        placeholder="{{
                            (geofenceGroup.accountId ? 'general.chooseUserContactGroup' : 'general.firstChooseAccount')
                                | translate
                        }}"
                        [(ngModel)]="geofenceGroup.violateOutsideAllGeofencesThresholdUserContactGroupId"
                    >
                    </fh-user-contact-group-input>
                </td>
                <td class="row_content">
                    <input
                        name="inputFieldOutSideAllThresholdsInMinutes"
                        [(ngModel)]="geofenceGroupOptions.inputFieldOutSideAllThresholdsInMinutes"
                        placeholder="{{ 'placeholder.thresholdInMinutes' | translate }}"
                        class="form-control table-inline"
                        type="number"
                        title="Threshold in minutes:"
                        [readonly]="formMode == 1"
                    />
                </td>
            </tr>
            <tr>
                <td class="row_opening" title="{{ 'general.eventOccursOutsideAllGeofences' | translate }}">
                    {{ "general.eventOccursOutsideAllGeofences" | translate }}
                </td>
                <td class="row_content" style="position: relative; overflow: visible">
                    <fh-user-contact-group-input
                        name="notifyOnEventTypeOccurenceUserContactGroupId"
                        #userContactGroupVar="ngModel"
                        [selectedAccountId]="geofenceGroup.accountId"
                        [readonly]="formMode == 1"
                        [multiple]="false"
                        [userContactGroupData]="userContactGroups"
                        placeholder="{{
                            (geofenceGroup.accountId ? 'general.chooseUserContactGroup' : 'general.firstChooseAccount')
                                | translate
                        }}"
                        [(ngModel)]="geofenceGroup.notifyOnEventTypeOccurenceUserContactGroupId"
                    >
                    </fh-user-contact-group-input>
                </td>
                <td class="row_content">
                    <div class="fa_checkbox" style="padding: 5px">
                        <input
                            name="checkboxIgnitionOff"
                            id="checkboxIgnitionOff"
                            type="checkbox"
                            [disabled]="formMode == 1"
                            [(ngModel)]="geofenceGroupOptions.checkboxIgnitionOff"
                        />
                        <label for="checkboxIgnitionOff">{{ "general.ignition" | translate }}</label>
                    </div>
                    <div class="fa_checkbox" style="padding: 5px">
                        <input
                            name="checkboxDoorSensor"
                            id="checkboxDoorSensor"
                            type="checkbox"
                            [disabled]="formMode == 1"
                            [(ngModel)]="geofenceGroupOptions.checkboxDoorSensor"
                        />
                        <label for="checkboxDoorSensor">{{ "general.doorSensor" | translate }}</label>
                    </div>
                    <div class="fa_checkbox" style="padding: 5px">
                        <input
                            name="checkboxSecondaryDoorSensor"
                            id="checkboxSecondaryDoorSensor"
                            type="checkbox"
                            [disabled]="formMode == 1"
                            [(ngModel)]="geofenceGroupOptions.checkboxSecondaryDoorSensor"
                        />
                        <label for="checkboxSecondaryDoorSensor">{{ "general.secondaryDoorSensor" | translate }}</label>
                    </div>
                    <div class="fa_checkbox" style="padding: 5px">
                        <input
                            name="checkboxExternalPowerOff"
                            id="checkboxExternalPowerOff"
                            type="checkbox"
                            [disabled]="formMode == 1"
                            [(ngModel)]="geofenceGroupOptions.checkboxExternalPowerOff"
                        />
                        <label for="checkboxExternalPowerOff">{{ "general.externalPowerOff" | translate }}</label>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>
