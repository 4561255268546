import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { CopyInputBoxModule } from "app/modules/copyInputBox/copyInputBox.module";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { NotificationsModule } from "../../modules/notification";
import { FooterComponent } from "./footer/footer.component";
import { BasicLayoutComponent } from "./layouts/basicLayout.component";
import { BlankLayoutComponent } from "./layouts/blankLayout.component";
import { NavigationComponent } from "./navigation/navigation.component";
import { SignalRComponent } from "./signalr/signalr.component";
import { TopNavbarComponent } from "./topnavbar/topnavbar.component";
import { UsercontrolsModule } from "./usercontrols.module";
import { SignalRNotificationsComponent } from "./signalrnotifications/signalrnontifications.component";
import { MomentModule } from "ngx-moment";
import { NgxPaginationModule } from "ngx-pagination";
import { ngxLoadingAnimationTypes } from "app/modules/loading/ngx-loading-config";
import { NgxLoadingModule } from "app/modules/loading/public_api";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { CustomInputModule } from "app/modules/customInputs/customInput.module";
import { ShareLayoutComponent } from "./layouts/shareLayout.component";

@NgModule({
  declarations: [
    FooterComponent,
    BasicLayoutComponent,
    BlankLayoutComponent,
    ShareLayoutComponent,
    NavigationComponent,
    SignalRComponent,
    SignalRNotificationsComponent,
    TopNavbarComponent,
  ],
  imports: [
    CustomInputModule,
    BrowserModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    UsercontrolsModule,
    CopyInputBoxModule,
    MomentModule,
    TypeaheadModule.forRoot(),
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TranslateModule,
    NotificationsModule,
    NgxPaginationModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.rectangleBounce,
      backdropBackgroundColour: "rgba(255,255,255,0.7)",
      backdropBorderRadius: "4px",
      primaryColour: "#DC8139",
      secondaryColour: "#DC8139",
      tertiaryColour: "#DC8139",
    }),
  ],
  exports: [
    FooterComponent,
    BasicLayoutComponent,
    BlankLayoutComponent,
    ShareLayoutComponent,
    NavigationComponent,
    SignalRComponent,
    SignalRNotificationsComponent,
    TopNavbarComponent,
    TranslateModule,
  ],
})
export class SharedLayoutsModule {}
