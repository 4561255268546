<ng-select
    [loading]="loadingUserContactGroups"
    ngDefaultControl
    [searchable]="true"
    [clearable]="clearable"
    style="height: unset"
    [disabled]="userContactGroups.length == 0"
    [readonly]="readonly"
    [items]="userContactGroups"
    bindLabel="name"
    bindValue="id"
    [virtualScroll]="false"
    placeholder="{{ placeholder }} ({{ userContactGroups.length }})"
    #input
    [multiple]="multiple"
    [(ngModel)]="value"
    class="form-control"
    [ngClass]="multiple !== true ? 'table-inline' : ''"
    (ngModelChange)="assetGroupChanged()"
>
    <ng-template ng-label-tmp let-userContactGroup="item" let-clear="clear">
        <span
            ><i class="fas fa-fw fa-address-card" title="Items: {{ userContactGroup.userContactCount }}"></i>
            {{ userContactGroup.name }} ({{ userContactGroup.userContactCount }})</span
        >
        <span class="ng-value-icon right" (click)="clear(userContactGroup)" aria-hidden="true">×</span>
    </ng-template>
    <ng-template ng-option-tmp let-userContactGroup="item" let-search="searchTerm">
        <div [ngOptionHighlight]="search">{{ userContactGroup.name }} ({{ userContactGroup.userContactCount }})</div>
        <small class="text-muted">
            <ng-container
                *ngFor="
                    let userContactItem of userContactGroup.userContactItems
                        | slice : 0 : (userContactGroup.showAll ? 99999999 : 5)
                        | orderBy : 'lastName' : false
                "
            >
                <span class="block-item">
                    <span
                        title="{{ userContactItem.firstName }} {{ userContactItem.lastName }} "
                        *ngIf="userContactItem.userContactType == 0"
                        ><i class="fas fa-fw fa-envelope"></i> {{ userContactItem.email }}</span
                    >
                    <span *ngIf="userContactItem.userContactType != 0"
                        ><i class="fas fa-fw fa-alert"></i> {{ userContactItem.email }}</span
                    >
                </span>
                <span *ngIf="userContactItem.phoneEnabled && userContactItem.msisdn" class="block-item">
                    <span title="{{ userContactItem.msisdn }}">
                        <i class="fas fa-fw fa-phone"></i>
                        {{ userContactItem.msisdn }}</span
                    >
                </span>
            </ng-container>

            <span
                class="block-item"
                *ngIf="userContactGroup.userContactItems.length > 5 && !userContactGroup.showAll"
                (click)="showAll($event, userContactGroup)"
            >
                <i class="fas fa-fw fa-layer-group"></i> {{ "general.more" | translate }}
            </span>

            <span *ngIf="userContactGroup.userContactItems.length == 0">{{ "general.noDataFound" | translate }}</span>
        </small>
    </ng-template>
    <ng-template ng-footer-tmp>
        <button
            class="btn btn-primary hand"
            style="width: 100%"
            [routerLink]="'/Notifications/UserContactGroupDetails/Add'"
        >
            <i class="fas fa-fw fa-plus"></i>
            {{ "general.createNew" | translate }}
        </button>
    </ng-template>
</ng-select>
<div style="clear: both"></div>
