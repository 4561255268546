<div class="ibox-content slick" style="position: relative">
    <ngx-loading [show]="loading"></ngx-loading>
    <table style="width: 100%" class="table table-fixed nowrap dtr-inline no-footer">
        <tbody *ngIf="!warehouse">
            <tr>
                <td colspan="4">
                    {{ "general.noWarehouse" | translate }}
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="warehouse">
            <ng-container *ngIf="formMode != 2">
                <tr [hidden]="!permissions['Resellers_View']">
                    <td class="row_opening">
                        {{ "general.resellerDescription" | translate }}
                    </td>
                    <td class="padding-readonly-correct row_content">
                        <a class="secondary link_bolder" href="/#/ResellerDetails/Index/{{ warehouse?.resellerId }}">{{
                            warehouse?.resellerDescription
                        }}</a>
                    </td>
                </tr>
                <tr [hidden]="!permissions['Accounts_View']">
                    <td class="row_opening">
                        {{ "general.account" | translate }}
                    </td>
                    <td class="row_content padding-readonly-correct">
                        <a class="secondary link_bolder" href="/#/AccountDetails/Index/{{ warehouse?.accountId }}">{{
                            warehouse?.companyName
                        }}</a>
                    </td>
                </tr>
            </ng-container>
            <ng-container *ngIf="formMode == 2">
                <tr [hidden]="!permissions['Resellers_View'] && selectedResellerId">
                    <td class="row_opening">
                        {{ "general.resellerDescription" | translate }}
                        <span class="error" *ngIf="resellerVar.invalid">*</span>
                    </td>
                    <td class="row_content" style="position: relative; overflow: visible">
                        <fh-reseller-input
                            name="reseller"
                            #resellerVar="ngModel"
                            (onChanged)="resellerChanged($event)"
                            required
                            placeholder="{{ 'general.chooseReseller' | translate }}"
                            [tableInline]="true"
                            [(ngModel)]="selectedResellerId"
                        >
                        </fh-reseller-input>
                    </td>
                </tr>
                <tr [hidden]="!permissions['Accounts_View'] && warehouse.accountId">
                    <td class="row_opening">
                        {{ "general.account" | translate }}
                        <span class="error" *ngIf="accountVar.invalid">*</span>
                    </td>
                    <td class="row_content" style="position: relative; overflow: visible">
                        <fh-account-input
                            name="account"
                            #accountVar="ngModel"
                            (onChanged)="accountChanged($event)"
                            required
                            [selectedResellerId]="selectedResellerId"
                            [tableInline]="true"
                            placeholder="{{
                                (selectedResellerId ? 'general.chooseAccount' : 'general.firstChooseReseller')
                                    | translate
                            }}"
                            [(ngModel)]="warehouse.accountId"
                        >
                        </fh-account-input>
                    </td>
                </tr>
            </ng-container>
            <tr>
                <td class="row_opening">
                    {{ "general.name" | translate }}
                    <span class="error" *ngIf="name.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input
                        type="text"
                        [readonly]="formMode == 1"
                        name="name"
                        #name="ngModel"
                        required
                        placeholder="{{ 'placeholder.name' | translate }}"
                        class="form-control table-inline"
                        [(ngModel)]="warehouse.name"
                    />
                </td>
            </tr>
            <!-- <tr>
                <td class="row_opening">
                    {{ 'general.referenceId' | translate}}
                    <span class="error" *ngIf="referenceId.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="text" [readonly]="formMode == 1" name="referenceId" #referenceId="ngModel"
                        placeholder="{{ 'placeholder.referenceId' | translate}}" class="form-control table-inline"
                        [(ngModel)]="warehouse.referenceId">
                </td>
            </tr> -->
            <!-- <tr>
                <td class="row_opening">
                    {{ 'general.latitude' | translate}}
                    <span class="error" *ngIf="latitude.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="text" [readonly]="formMode == 1" name="latitude" class="form-control table-inline"
                        placeholder="{{ 'placeholder.latitude' | translate}}" [(ngModel)]="warehouse.latitude"
                        #latitude="ngModel">
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.longitude' | translate}}
                    <span class="error" *ngIf="longitude.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="text" [readonly]="formMode == 1" name="longitude" class="form-control table-inline"
                        placeholder="{{ 'placeholder.longitude' | translate}}" [(ngModel)]="warehouse.longitude"
                        #longitude="ngModel">
                </td>
            </tr> -->
            <tr>
                <td class="row_opening">
                    {{ "general.address" | translate }}
                    <span class="error" *ngIf="address.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input
                        type="text"
                        [readonly]="formMode == 1"
                        name="address"
                        class="form-control table-inline"
                        required
                        placeholder="{{ 'placeholder.address' | translate }}"
                        [(ngModel)]="warehouse.address"
                        #address="ngModel"
                    />
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ "general.recipients" | translate }}
                    <span class="error" *ngIf="emailRecipients.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input
                        type="text"
                        [readonly]="formMode == 1"
                        name="emailRecipients"
                        class="form-control table-inline"
                        placeholder="{{ 'placeholder.recipients' | translate }}"
                        [(ngModel)]="warehouse.emailRecipients"
                        #emailRecipients="ngModel"
                    />
                </td>
            </tr>
            <tr *ngIf="formMode == 1">
                <td class="row_opening">
                    {{ "general.waslKey" | translate }}
                    <span class="error" *ngIf="identifier.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input
                        type="text"
                        [readonly]="true"
                        name="identifier"
                        class="form-control table-inline"
                        placeholder="{{ 'placeholder.identifier' | translate }}"
                        [(ngModel)]="warehouseAzure.identifier"
                        #identifier="ngModel"
                    />
                </td>
            </tr>
            <tr>
                <td colspan="2" style="background-color: unset; border-bottom: none !important">
                    <textarea
                        rows="4"
                        [readonly]="formMode == 1"
                        name="description"
                        #description="ngModel"
                        class="form-control noresize"
                        placeholder="{{ 'placeholder.description' | translate }}"
                        style="height: 100px; width: 100%; resize: none"
                        [(ngModel)]="warehouse.description"
                    >
                    </textarea>
                </td>
            </tr>
        </tbody>
    </table>
</div>
