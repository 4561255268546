import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable, catchError, map } from "rxjs";
import { AuthenticationService } from "../authentication/authentication.service";
import { LoggingService } from "../logging/logging.service";
import { AssetGroup, AssetGroupItem } from "app/models/group.model";
import { TranslateService } from "@ngx-translate/core";
import { throwError } from "rxjs/internal/observable/throwError";
import { localizeSystemGroupNames } from "app/common/globals";

// Moment timezone
import Moment from "moment-timezone";
window["moment"] = Moment;

@Injectable()
export class AssetGroupsService {
  url = "";
  base_url = "";
  inventoryUrl = "";
  timezoneIana: string;

  constructor(
    private http: HttpClient,
    private loggingService: LoggingService,
    private authenticationService: AuthenticationService,
    private translateService: TranslateService
  ) {
    this.base_url = this.authenticationService.getWebserviceURL("");
    this.url = this.authenticationService.getWebserviceURL("assetGroup");
    this.timezoneIana = this.authenticationService.getTimeZoneIana();
  }

  getPagingUrl() {
    return this.url + "Paging";
  }

  getAssetGroups(accountId?: number, includingItems = false): Observable<AssetGroup[]> {
    return this.http
      .get(
        this.url +
          (accountId
            ? `?accountId=${accountId}&includingItems=${includingItems}`
            : `?includingItems=${includingItems}`),
        { headers: this.authenticationService.headers }
      )
      .pipe(
        map((data) => {
          return this.parseResponse(data);
        }),
        catchError(this.handleError)
      );
  }

  getAssetGroupById(id: string): Observable<AssetGroup> {
    return this.http.get(this.url + id, { headers: this.authenticationService.headers }).pipe(
      map((data) => {
        return this.parseReponseDetails(data);
      }),
      catchError(this.handleError)
    );
  }

  getAssetGroupItemsById(assetId: number, assetGroupId: number): Observable<AssetGroupItem[]> {
    return this.http
      .get(this.url + assetId + "/group/" + assetGroupId, { headers: this.authenticationService.headers })
      .pipe(
        map((data) => {
          return this.parseItemResponse(data);
        }),
        catchError(this.handleError)
      );
  }

  getAssetGroupItemsByAccountId(accountId): Observable<AssetGroup[]> {
    return this.http
      .get(this.base_url + "Account/" + accountId + "/groups", { headers: this.authenticationService.headers })
      .pipe(
        map((data) => {
          return this.parseResponse(data);
        }),
        catchError(this.handleError)
      );
  }

  getUserItemsById(id: string): Observable<any> {
    return this.http.get(this.url + id + "/users", { headers: this.authenticationService.headers }).pipe(
      map((data: any[]) => {
        data.forEach((item) => {
          item["name"] = item["emailLoginName"] + (item["firstName"] ? ` (${item["firstName"]})` : "");
        });
        return data;
      }),
      catchError(this.handleError)
    );
  }

  saveAssetGroup(assetGroup: AssetGroup): Observable<any> {
    console.log("save assetgroup", assetGroup);

    return this.http
      .post(this.url, assetGroup, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  updateAssetGroup(assetGroup: AssetGroup): Observable<any> {
    console.log("update assetgroup", assetGroup);

    return this.http
      .put(this.url + assetGroup.id, assetGroup, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  deleteAssetGroup(assetGroup: AssetGroup): Observable<any> {
    console.log("update assetgroup", assetGroup);

    return this.http
      .delete(this.url + assetGroup.id, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  private handleError(error: Response) {
    return throwError(error);
  }

  parseResponse(json: any): AssetGroup[] {
    this.loggingService.log(this.constructor.name, "Retrieved " + json.length + " AssetGroups.");

    const ident = 1;
    const assetGroups: AssetGroup[] = [];

    json.forEach((item) => {
      const asset = this.parseReponseDetails(item);
      assetGroups.push(asset);
    });

    return assetGroups;
  }

  parseReponseDetails(item) {
    const assetGroup = new AssetGroup();
    assetGroup.id = item.id;
    assetGroup.name = item.name;
    assetGroup.color = item.color;
    assetGroup.displayName = item.name;
    assetGroup.groupType = item.groupType;
    assetGroup.emailLoginName = item.emailLoginName;
    assetGroup.firstName = item.firstName;
    assetGroup.lastName = item.lastName;
    assetGroup.userId = item.userId;
    assetGroup.timestamp =
      item.timestamp !== undefined ? Moment.utc(item.timestamp)["tz"](this.timezoneIana) : undefined;
    assetGroup.itemCount = item.itemCount;
    assetGroup.accountId = item.accountId;
    assetGroup.companyName = item.companyName;
    assetGroup.resellerId = item.resellerId;
    assetGroup.resellerDescription = item.resellerDescription;
    assetGroup.displayName = localizeSystemGroupNames(assetGroup.displayName, this.translateService);

    if (
      assetGroup.displayName === "MyManagedDevices" ||
      assetGroup.displayName === "ManagedArchivedAssets" ||
      assetGroup.displayName === "ArchivedAssets"
    ) {
      if (item.adminUserId !== item.userId) {
        assetGroup.displayName += ` (${assetGroup.emailLoginName})`;
      }
    }

    if (item.assetGroupItems) {
      assetGroup.assetGroupItems = [];

      item.assetGroupItems.forEach((asset) => {
        const assetGroupItem = {
          id: asset.id,
          deviceId: asset.deviceId,
          assetCode: asset.assetCode,
          assetGroupId: item.assetGroupId,
          assetGroupName: item.name,
          assetName: asset.assetName,
          plateNumber: asset.plateNumber,
          iconId: asset.iconId,
          driverId: asset.driverId,
          assetCompanyName: asset.assetCompanyName,
        };

        assetGroup.assetGroupItems.push(assetGroupItem);
      });
    }

    return assetGroup;
  }

  parseItemResponse(json: any): AssetGroupItem[] {
    this.loggingService.log(this.constructor.name, "Retrieved " + json.length + " GeofenceGroups.");

    const ident = 1;
    const assetGroups: AssetGroupItem[] = [];

    json.forEach((item) => {
      const asset = this.parseItemReponseDetails(item);
      assetGroups.push(asset);
    });

    return assetGroups;
  }

  parseItemReponseDetails(item) {
    const assetGroupItem = new AssetGroupItem();
    assetGroupItem.id = item.id;
    assetGroupItem.deviceId = item.deviceId;
    assetGroupItem.name = item.name;
    assetGroupItem.color = item.color;
    assetGroupItem.unitId = item.unitId;
    assetGroupItem.companyName = item.companyName;
    assetGroupItem.accountId = item.accountId;
    assetGroupItem.timestamp =
      item.timestamp !== undefined ? Moment.utc(item.timestamp)["tz"](this.timezoneIana) : undefined;
    assetGroupItem.startDate =
      item.startDate !== undefined ? Moment.utc(item.startDate)["tz"](this.timezoneIana) : undefined;
    assetGroupItem.endDate = item.endDate !== undefined ? Moment.utc(item.endDate)["tz"](this.timezoneIana) : undefined;
    assetGroupItem.displayName = localizeSystemGroupNames(assetGroupItem.name, this.translateService);
    assetGroupItem.assetSearchName = item.name !== undefined ? item.name + " - " + item.unitId : item.unitId;

    return assetGroupItem;
  }
}
