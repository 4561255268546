import { Component, Input, OnInit, ChangeDetectorRef, ChangeDetectionStrategy } from "@angular/core";

@Component({
  selector: "fh-trailer-devices",
  templateUrl: "trailerDevices.template.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrailerDevicesOverviewComponent implements OnInit {
  @Input() deviceAssignments = [];
  @Input() loading = false;
  allowSelect = false;

  // Pagination
  currentPage;

  filter;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit(): void {}
}
