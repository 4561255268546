import { HttpClient } from "@angular/common/http";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Device } from "app/models/device.model";
import { DeviceSensorType } from "app/models/sensor.model";
import { AuthenticationService } from "app/services/authentication/authentication.service";
import { FhChartService } from "../../services/charts/charts.service";
import { DeviceService } from "../../services/device/device.service";

import { getSensorDpConfig } from "app/common/gridhelper";
import { LeafletMapComponent } from "../shared/usercontrols/leafletMap.component";
import { TripService } from "app/services/trip/trip.service";
import { AccountService } from "app/services/account/account.service";
import { colorArray, colorArray2, getIconPath } from "app/common/globals";

import * as L from "leaflet";
import { slideInOut } from "app/common/animations";
declare var HeatmapOverlay;

// Moment
import Moment from "moment-timezone";
import { FormMode, StorageType } from "app/common/enums";
import { StorageHelper } from "app/common/storagehelper";
window["moment"] = Moment;

import * as Highcharts from "highcharts";
import { DistanceUnitService } from "app/common/distanceunit.service";

@Component({
  selector: "fh-device-sensors-history",
  templateUrl: "sensorsHistory.template.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FhChartService],
  animations: [slideInOut],
})
export class DeviceSensorsHistoryViewComponent implements OnInit {
  Highcharts: typeof Highcharts = Highcharts;

  @ViewChild(LeafletMapComponent, { static: false }) leafletMapComponent: LeafletMapComponent;

  chartRpm: any;
  chartSpeed: any;
  sensors;
  sub;
  device: Device;
  deviceId;
  loading = false;
  loadingSensors = false;
  sensorTypes: DeviceSensorType[];
  timezoneIana: string;
  daterangepickerModel: Date[];
  dpConfig: {};
  error;
  success;
  chart: any;
  locations = [];
  geofences = [];
  loadingGeofences = false;
  mapHeight = 250;

  saving;

  filterZeroValues = true;

  // trips
  trips = {};
  tripFeatureGroup: L.FeatureGroup<any>;
  colorArray = colorArray2;

  simplify = true;

  tripCount = 0;
  totalDriven = 0;
  totalDrivenDuration = 0;

  permissionName = "FleetManagement_Sensors";

  locationSubscription: any;
  isSpinning: boolean;
  loadingLocation: boolean;
  previousLookupDisplay: any;
  previousLookupTimestamp: any;
  locationData: any;
  activeLocation: any;
  unmappedPorts: any;
  lastCommunication: any;
  permissions: {};
  formMode = FormMode.read;

  sensorTemplates = [];
  loadingSensorTemplates = false;

  showMapOnSide = false;
  storageType = StorageType.LocalStorage;

  heatmapLayer: any;

  showProp = false;
  groupBy = <T, K extends keyof any>(list: T[], getKey: (item: T) => K) =>
    list.reduce((previous, currentItem) => {
      const group = getKey(currentItem);
      if (!previous[group]) {
        previous[group] = [];
      }
      previous[group].push(currentItem);
      return previous;
    }, {} as Record<K, T[]>);

  translatedKm: any = "km";
  translatedKmh: any = "km/h";

  constructor(
    private cd: ChangeDetectorRef,
    private distance: DistanceUnitService,
    private chartService: FhChartService,
    private tripService: TripService,
    private accountService: AccountService,
    private translateService: TranslateService,
    private authenticationService: AuthenticationService,
    private http: HttpClient,
    private deviceService: DeviceService,
    private route: ActivatedRoute,
    private router: Router,
    private storageHelper: StorageHelper
  ) {
    this.device = null;
    this.sensors = [];

    this.permissions = this.authenticationService.permissions;

    this.timezoneIana = authenticationService.getTimeZoneIana();

    this.storageHelper.loadStoreState(StorageType.LocalStorage, "settings_", "brightColors").subscribe((result) => {
      if (JSON.parse(result) === true) {
        this.colorArray = colorArray;
      }
    });

    this.daterangepickerModel = [
      Moment().tz(this.timezoneIana).subtract(2, "days").startOf("day").toDate(),
      Moment().tz(this.timezoneIana).endOf("day").toDate(),
    ];

    this.translateService.get("general.date").subscribe((data) => {
      this.translatedKm = this.translateService.instant(this.distance.getDistanceUnit());
      this.translatedKmh = this.translateService.instant(this.distance.getDistanceUnitPerHour());
    });

    this.storageHelper.loadStoreState(this.storageType, "settings_", "showMapOnSide").subscribe((result) => {
      this.showMapOnSide = JSON.parse(result) === true;

      if (this.showMapOnSide) {
        this.mapHeight = 330;
      }
      this.cd.markForCheck();
    });

    this.dpConfig = getSensorDpConfig(authenticationService);
  }

  ngOnInit() {
    this.device = new Device();
    this.loading = true;
    this.device.id = "";

    this.sub = this.route.params.subscribe((params) => {
      const id = params["id"];

      this.deviceId = id;
      this.deviceService.getDeviceById(id).subscribe((device) => {
        this.device = device;

        if (this.device && this.device.id) {
          this.generateTrips(
            this.device.id,
            Moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf("day"),
            Moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf("day")
          );
        }

        this.getGeofences();

        this.loading = false;
        this.cd.markForCheck();

        if (this.device == null) {
          this.router.navigate(["/Devices/Overview"]);
        }
      });
    });
  }

  onMapReady(map) {
    setTimeout(() => {
      this.leafletMapComponent.invalidateSize();
    }, 10);

    this.heatmapLayer = new HeatmapOverlay({
      radius: 30,
      maxOpacity: 0.8,
      scaleRadius: false,
      useLocalExtrema: false,
      latField: "lat",
      lngField: "lng",
      valueField: "count",
    });

    setTimeout(() => {
      this.heatmapLayer.addTo(this.leafletMapComponent.heatmapLayer);
    }, 100);
  }

  showProperties() {
    this.showProp = !this.showProp;
  }

  getGeofences() {
    if (this.geofences.length === 0 && this.device.accountId != null && this.device.accountId > 0) {
      this.loadingGeofences = true;
      this.accountService.getGeofencesByAccount(this.device.accountId).subscribe((geofences) => {
        this.geofences = geofences;
        this.loadingGeofences = false;
        this.cd.markForCheck();
      });
    }
  }

  dateChanged(event) {
    this.generateTrips(
      this.device.id,
      Moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf("day"),
      Moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf("day")
    );
  }

  generateTrips(id, start, end) {
    this.loadingSensors = true;
    this.error = undefined;
    this.tripService.getMessagesForDevice(id, start, end, "", 2).subscribe(
      (locations) => {
        this.locations = locations
          .filter((x) => x.latitude !== "0" && x.latitude !== 0)
          .sort((a, b) => (a.timestamp < b.timestamp ? -1 : 1));

        this.drawChart(this.locations);

        this.trips = {};
        this.trips = this.groupBy(this.locations, (i) => i.sourceId);

        this.drawTrips(this.trips);

        this.loadingSensors = false;
        this.cd.markForCheck();
      },
      (error) => {
        this.loadingSensors = false;
        this.cd.markForCheck();
        this.error = error;
      }
    );
  }

  drawChart(locations) {
    this.chart = null;

    const iconPath = getIconPath(this.device.asset?.icon)[1];

    const theIcon = L.icon({
      iconUrl: iconPath,
      // className: 'markerPlayTrip',
      iconAnchor: [16, 16],
    });

    // The speed gauge
    // data, km, fuel percentage, deviation, symbol
    // The speed gauge
    // data, km, fuel percentage, deviation, symbol
    const theChartDataDistance = [];
    const theChartDataIgnition = [];
    const theChartDataSpeed = [];
    const theChartDataExternalPower = [];
    const theChartDataGpsFix = [];
    const theChartFuelLevel = [];
    const theChartDataTemperature = [];
    const theChartDataTemperature2 = [];
    const theChartDataTemperature3 = [];
    const theChartDataTemperature4 = [];
    const theChartDataWeight = [];
    const theChartDataAvgWeight = [];
    const theChartDataWeight2 = [];
    const theChartDataAvgWeight2 = [];
    const theChartDataHumidity = [];
    const theChartDataExternalPowerVoltage = [];

    const theChartDataAcceleration = [];
    const theChartDataBraking = [];
    const theChartDataCornering = [];

    const theChartDataRpm = [];

    const theChartDataAnalog1 = [];
    const theChartDataAnalog2 = [];
    const theChartDataAnalog3 = [];
    const theChartDataAnalog4 = [];

    const theChartDataIO = [];

    const theChartFuelConsumed = [];

    const that = this;

    let cachedDistanceOffset = 0;
    let lastlocation = null;

    if (locations.length === 0) {
      return;
    }

    let averageAxleWeightInKg1 = locations[0].axleWeightInKg1;
    let averageAxleWeightInKg2 = locations[0].axleWeightInKg2;

    let averageCounterAxleWeightInKg1 = 1;
    let averageCounterAxleWeightInKg2 = 1;

    const factor = 5;

    const startFuelConsumed = 0;

    let theData = [];

    // get first odo
    cachedDistanceOffset = locations
      .filter((x) => x.odoValue > 0)
      .sort((a, b) => (a.timestamp < b.timestamp ? -1 : 1))[0]?.odoValue;

    $.each(locations, function (index, value) {
      const dateTime = Moment.utc(value.timestamp)["tz"](that.timezoneIana).unix() * 1000;
      const coordinate = [value.latitude, value.longitude];

      if (value.odoValue && value.odoValue > 0) {
        const distance = value.odoValue - cachedDistanceOffset;
        if (distance > 10000000 || distance < 0) {
          cachedDistanceOffset = distance;
          console.log("skipping location");
          return;
        }

        // Set lastlocation + 1 milisecond to stop distancechart from making a big leap
        if (lastlocation !== null && distance === 0 && lastlocation.y !== 0) {
          lastlocation.x = lastlocation.x + 1;
          lastlocation.y = 0;
          theChartDataDistance.push(lastlocation);
        }

        if (value.latitude !== 0) {
          const theDistance = Math.max(0, distance / 1000);
          const location = { x: dateTime, y: theDistance, suffix: that.translatedKm, latlon: coordinate };
          lastlocation = location;
          theChartDataDistance.push(location);
        }
      }

      theChartDataIgnition.push({ x: dateTime, y: value.ignition ? 1 : 0, latlon: coordinate });
      theChartDataExternalPower.push({ x: dateTime, y: value.externalPower ? 1 : 0, latlon: coordinate });
      theChartDataGpsFix.push({ x: dateTime, y: value.hasGpsFix ? 1 : 0, latlon: coordinate });

      if (value.speedInKph !== undefined || this.filterZeroValues) {
        theChartDataSpeed.push({
          x: dateTime,
          y: that.distance.calculateDistanceUnitFromKmFixed(value.speedInKph, 0),
          suffix: that.translatedKmh,
          latlon: coordinate,
        });
      }

      if (value.externalBatteryLevelInVoltage !== undefined || this.filterZeroValues) {
        theChartDataExternalPowerVoltage.push({
          x: dateTime,
          y: value.externalBatteryLevelInVoltage,
          suffix: " V",
          latlon: coordinate,
        });
      }

      if (value.fuelLevel !== undefined && (value.fuelLevel !== 0 || this.filterZeroValues)) {
        theChartFuelLevel.push({ x: dateTime, y: value.fuelLevel, suffix: "%", latlon: coordinate });
      }

      if (value.axleWeightInKg1 !== undefined && (value.axleWeightInKg1 !== 0 || this.filterZeroValues)) {
        averageCounterAxleWeightInKg1++;
        averageAxleWeightInKg1 =
          averageAxleWeightInKg1 +
          (value.axleWeightInKg1 - averageAxleWeightInKg1) / Math.min(averageCounterAxleWeightInKg1, factor);

        theChartDataWeight.push({ x: dateTime, y: value.axleWeightInKg1, suffix: "kg", latlon: coordinate });
        theChartDataAvgWeight.push({ x: dateTime, y: averageAxleWeightInKg1, latlon: coordinate });
      }

      if (value.axleWeightInKg2 !== undefined && (value.axleWeightInKg2 !== 0 || this.filterZeroValues)) {
        averageCounterAxleWeightInKg2++;
        averageAxleWeightInKg2 =
          averageAxleWeightInKg2 +
          (value.axleWeightInKg2 - averageAxleWeightInKg2) / Math.min(averageCounterAxleWeightInKg2, factor);

        theChartDataWeight2.push({ x: dateTime, y: value.axleWeightInKg2, suffix: " kg", latlon: coordinate });
        theChartDataAvgWeight2.push({ x: dateTime, y: averageAxleWeightInKg2, latlon: coordinate });
      }

      if (value.humidityInPercent1 !== undefined && (value.humidityInPercent1 !== 0 || this.filterZeroValues)) {
        theChartDataHumidity.push({ x: dateTime, y: value.humidityInPercent1, suffix: "%", latlon: coordinate });
      }

      if (value.temperature !== undefined && (value.temperature !== 0 || this.filterZeroValues)) {
        theChartDataTemperature.push({ x: dateTime, y: value.temperature, suffix: "°C", latlon: coordinate });
      }

      if (value.temperature2 !== undefined && (value.temperature2 !== 0 || this.filterZeroValues)) {
        theChartDataTemperature2.push({ x: dateTime, y: value.temperature2, suffix: "°C", latlon: coordinate });
      }

      if (value.temperature3 !== undefined && (value.temperature3 !== 0 || this.filterZeroValues)) {
        theChartDataTemperature3.push({ x: dateTime, y: value.temperature3, suffix: "°C", latlon: coordinate });
      }

      if (value.temperature4 !== undefined && (value.temperature4 !== 0 || this.filterZeroValues)) {
        theChartDataTemperature4.push({ x: dateTime, y: value.temperature4, suffix: "°C", latlon: coordinate });
      }

      if (value.rpm !== undefined && (value.rpm !== 0 || this.filterZeroValues)) {
        theChartDataRpm.push({ x: dateTime, y: value.rpm, suffix: " rpm", latlon: coordinate });
      }

      if (value.analogInput1 !== undefined && (value.analogInput1 !== 0 || this.filterZeroValues)) {
        theChartDataAnalog1.push({ x: dateTime, y: value.analogInput1, suffix: " V", latlon: coordinate });
      }

      if (value.analogInput2 !== undefined && (value.analogInput2 !== 0 || this.filterZeroValues)) {
        theChartDataAnalog2.push({ x: dateTime, y: value.analogInput2, suffix: " V", latlon: coordinate });
      }

      if (value.analogInput3 !== undefined && (value.analogInput3 !== 0 || this.filterZeroValues)) {
        theChartDataAnalog3.push({ x: dateTime, y: value.analogInput3, suffix: " V", latlon: coordinate });
      }

      if (value.analogInput4 !== undefined && (value.analogInput4 !== 0 || this.filterZeroValues)) {
        theChartDataAnalog4.push({ x: dateTime, y: value.analogInput4, suffix: " V", latlon: coordinate });
      }

      if (
        value.actualAccellerationForce !== undefined &&
        (value.actualAccellerationForce !== 0 || this.filterZeroValues)
      ) {
        theChartDataAcceleration.push({ x: dateTime, y: value.actualAccellerationForce, latlon: coordinate });
      }

      if (value.actualBrakingForce !== undefined && (value.actualBrakingForce !== 0 || this.filterZeroValues)) {
        theChartDataBraking.push({ x: dateTime, y: value.actualBrakingForce, latlon: coordinate });
      }

      if (value.actualCorneringForce !== undefined && (value.actualCorneringForce !== 0 || this.filterZeroValues)) {
        theChartDataCornering.push({ x: dateTime, y: value.actualCorneringForce, latlon: coordinate });
      }

      if (value.fuelConsumed !== undefined && (value.fuelConsumed !== 0 || this.filterZeroValues)) {
        theChartFuelConsumed.push({ x: dateTime, y: value.fuelConsumed - startFuelConsumed, latlon: coordinate });
      }

      theChartDataIO.push({ x: dateTime, y: { i1: value.input1, i2: value.input2 }, latlon: coordinate });
    });

    theData = [
      {
        name: "Distance",
        type: "area",
        //  threshold: null,
        // step: 'right',
        dashStyle: "dash",
        fillOpacity: 0.5,
        opacity: 0.6,
        color: "#ccc",
        step: "right",
        zIndex: 5,
        yAxis: 1,
        data: theChartDataDistance,
      },
      {
        name: "Speed",
        type: "spline",
        color: "#5AB867",
        visible: true,
        step: "left",
        yAxis: 0,
        marker: {
          enabled: false,
          lineWidth: 2,
          symbol: "square",
        },
        zIndex: 3,
        data: theChartDataSpeed,
      },
    ];

    if (theChartDataIgnition.some((x) => x.y)) {
      theData.push({
        name: "Ignition",
        type: "line",
        color: "#00E0C6",
        visible: false,
        step: "left",
        marker: {
          enabled: false,
          lineWidth: 2,
          symbol: "square",
        },
        yAxis: 3,
        zIndex: 3,
        data: theChartDataIgnition,
      });
    }

    if (theChartDataExternalPower.some((x) => x.y)) {
      theData.push({
        name: "ExternalPower",
        type: "spline",
        color: "#00E0C6",
        visible: false,
        step: "left",
        marker: {
          enabled: false,
          lineWidth: 2,
          symbol: "square",
        },
        yAxis: 4,
        zIndex: 3,
        data: theChartDataExternalPower,
      });
    }

    if (theChartDataExternalPowerVoltage.some((x) => x.y)) {
      theData.push({
        name: "Voltage",
        type: "spline",
        dashStyle: "ShortDashDot",
        color: "#00E0C6",
        visible: false,
        step: "left",
        marker: {
          enabled: false,
          lineWidth: 2,
          symbol: "square",
        },
        yAxis: 6,
        zIndex: 3,
        data: theChartDataExternalPowerVoltage,
      });
    }

    if (theChartDataRpm.some((x) => x.y)) {
      theData.push({
        name: "RPM",
        type: "spline",
        dashStyle: "ShortDashDot",
        color: "#ff0000",
        visible: false,
        step: "left",
        marker: {
          enabled: false,
          lineWidth: 2,
          symbol: "square",
        },
        yAxis: 13,
        zIndex: 3,
        data: theChartDataRpm,
      });
    }

    if (theChartDataAnalog1.some((x) => x.y)) {
      theData.push({
        name: "Analog1",
        type: "spline",
        dashStyle: "ShortDashDot",
        color: "#00E0C6",
        visible: false,
        step: "left",
        marker: {
          enabled: false,
          lineWidth: 2,
          symbol: "square",
        },
        yAxis: 6,
        zIndex: 3,
        data: theChartDataAnalog1,
      });
    }

    if (theChartDataAnalog2.some((x) => x.y)) {
      theData.push({
        name: "Analog2",
        type: "spline",
        dashStyle: "ShortDashDot",
        color: "#00E0C6",
        visible: false,
        step: "left",
        marker: {
          enabled: false,
          lineWidth: 2,
          symbol: "square",
        },
        yAxis: 6,
        zIndex: 3,
        data: theChartDataAnalog2,
      });
    }

    if (theChartDataGpsFix.some((x) => x.y)) {
      theData.push({
        name: "HasGpsFix",
        type: "line",
        color: "#1A4467",
        visible: false,
        step: "left",
        marker: {
          enabled: false,
          lineWidth: 2,
          symbol: "square",
        },
        yAxis: 5,
        zIndex: 3,
        data: theChartDataGpsFix,
      });
    }

    if (theChartFuelLevel.some((x) => x.y)) {
      theData.push({
        name: "FuelLevel",
        type: "spline",
        color: "#7589FF",
        dashStyle: "ShortDot",
        fillOpacity: 0.2,
        visible: true,
        step: "left",
        marker: {
          enabled: false,
          lineWidth: 2,
          symbol: "square",
        },
        yAxis: 2,
        zIndex: 3,
        data: theChartFuelLevel,
      });
    }

    if (theChartFuelConsumed.some((x) => x.y)) {
      theData.push({
        name: "Fuel Consumed",
        type: "spline",
        color: "#A982FF",
        dashStyle: "LongDash",
        fillOpacity: 0.2,
        visible: true,
        step: "left",
        marker: {
          enabled: false,
          lineWidth: 2,
          symbol: "square",
        },
        yAxis: 12,
        zIndex: 3,
        data: theChartFuelConsumed,
      });
    }

    if (theChartDataWeight.some((x) => x.y)) {
      theData.push({
        name: "Weight",
        type: "spline",
        color: "#FF0015",
        dashStyle: "Dash",
        fillOpacity: 0.2,
        visible: true,
        step: "left",
        marker: {
          enabled: false,
          lineWidth: 2,
          symbol: "square",
        },
        yAxis: 9,
        zIndex: 3,
        data: theChartDataWeight,
      });
    }

    if (theChartDataAvgWeight.some((x) => x.y)) {
      theData.push({
        name: "AvgWeight",
        type: "spline",
        color: "#FF6666",
        dashStyle: "Dash",
        fillOpacity: 0.2,
        visible: true,
        step: "left",
        marker: {
          enabled: false,
          lineWidth: 2,
          symbol: "square",
        },
        yAxis: 9,
        zIndex: 3,
        data: theChartDataAvgWeight,
      });
    }

    if (theChartDataAvgWeight2.some((x) => x.y)) {
      theData.push({
        name: "AvgWeight2",
        type: "spline",
        color: "#FF6666",
        dashStyle: "Dash",
        fillOpacity: 0.2,
        visible: true,
        step: "left",
        marker: {
          enabled: false,
          lineWidth: 2,
          symbol: "square",
        },
        yAxis: 9,
        zIndex: 3,
        data: theChartDataAvgWeight2,
      });
    }

    if (theChartDataWeight2.some((x) => x.y)) {
      theData.push({
        name: "Weight",
        type: "spline",
        color: "#FFFF15",
        dashStyle: "Dash",
        fillOpacity: 0.2,
        visible: true,
        step: "left",
        marker: {
          enabled: false,
          lineWidth: 2,
          symbol: "square",
        },
        yAxis: 9,
        zIndex: 3,
        data: theChartDataWeight2,
      });
    }

    if (theChartDataHumidity.some((x) => x.y)) {
      theData.push({
        name: "Humidity",
        type: "spline",
        color: "#FF0090",
        fillOpacity: 0.2,
        visible: true,
        step: "left",
        marker: {
          enabled: false,
          lineWidth: 2,
          symbol: "square",
        },
        yAxis: 8,
        zIndex: 3,
        data: theChartDataHumidity,
      });
    }

    if (theChartDataTemperature.some((x) => x.y)) {
      theData.push({
        name: "Temperature",
        type: "spline",
        color: "#FF0015",
        fillOpacity: 0.2,
        visible: true,
        step: "left",
        marker: {
          enabled: false,
          lineWidth: 2,
          symbol: "square",
        },
        yAxis: 7,
        zIndex: 3,
        data: theChartDataTemperature,
      });
    }

    if (theChartDataTemperature2.some((x) => x.y)) {
      theData.push({
        name: "Temperature2",
        type: "spline",
        color: "#8700FF",
        fillOpacity: 0.2,
        visible: true,
        step: "left",
        marker: {
          enabled: false,
          lineWidth: 2,
          symbol: "square",
        },
        yAxis: 7,
        zIndex: 3,
        data: theChartDataTemperature2,
      });
    }

    if (theChartDataTemperature3.some((x) => x.y)) {
      theData.push({
        name: "Temperature3",
        type: "spline",
        color: "#8700FF",
        fillOpacity: 0.2,
        visible: true,
        step: "left",
        marker: {
          enabled: false,
          lineWidth: 2,
          symbol: "square",
        },
        yAxis: 7,
        zIndex: 3,
        data: theChartDataTemperature3,
      });
    }

    if (theChartDataTemperature4.some((x) => x.y)) {
      theData.push({
        name: "Temperature4",
        type: "spline",
        color: "#8700FF",
        fillOpacity: 0.2,
        visible: true,
        step: "left",
        marker: {
          enabled: false,
          lineWidth: 2,
          symbol: "square",
        },
        yAxis: 7,
        zIndex: 3,
        data: theChartDataTemperature4,
      });
    }

    if (theChartDataAcceleration.some((x) => x.y)) {
      theData.push({
        name: "Accelleration",
        type: "spline",
        color: "#ffa600",
        fillOpacity: 0.2,
        visible: false,
        step: "left",
        marker: {
          enabled: false,
          lineWidth: 2,
          symbol: "square",
        },
        yAxis: 10,
        zIndex: 4,
        data: theChartDataAcceleration,
      });
    }

    if (theChartDataBraking.some((x) => x.y)) {
      theData.push({
        name: "Braking",
        type: "spline",
        color: "#ff6361",
        fillOpacity: 0.2,
        visible: false,
        step: "left",
        marker: {
          enabled: false,
          lineWidth: 2,
          symbol: "square",
        },
        yAxis: 10,
        zIndex: 4,
        data: theChartDataBraking,
      });
    }

    if (theChartDataCornering.some((x) => x.y)) {
      theData.push({
        name: "Cornering",
        type: "spline",
        color: "#bc5090",
        fillOpacity: 0.2,
        visible: false,
        step: "left",
        marker: {
          enabled: false,
          lineWidth: 2,
          symbol: "square",
        },
        yAxis: 10,
        zIndex: 4,
        data: theChartDataCornering,
      });
    }

    const plotLines = [];
    const plotBands = [];

    if (this.simplify) {
      theData = this.chartService.simplifyPathDouglasPecker(theData, 1);
    }

    this.chart = this.chartService.generateMapChart(
      theData,
      plotLines,
      plotBands,
      this.leafletMapComponent.map,
      theIcon
    );
  }

  drawTrips(trips) {
    if (this.tripFeatureGroup) {
      this.leafletMapComponent.tripLayer.removeLayer(this.tripFeatureGroup);
    }

    if (this.heatmapLayer) {
      this.leafletMapComponent.heatmapLayer.removeLayer(this.heatmapLayer);
    }

    this.tripFeatureGroup = L.featureGroup();

    let ident = 0;

    const tripKeys = Object.keys(this.trips);
    this.tripCount = tripKeys.length;

    var heatmapData = [];

    tripKeys.forEach((tripKey) => {
      ident++;
      const trip = trips[tripKey];

      const pointList = [];

      const color = this.colorArray[ident % this.colorArray.length];

      if (trip.length > 0) {
        const startIcon = new L["NumberMarker"].Icon({
          backgroundColor: color,
          className: "m360",
          color: "#fff",
          number: ident,
        });

        const startMarker = L.marker(new L.LatLng(trip[0].latitude, trip[0].longitude), { icon: startIcon });
        this.leafletMapComponent.oms.addMarker(startMarker);
        startMarker.addTo(this.tripFeatureGroup);

        trip.forEach((location) => {
          if (location.latitude !== "0" && location.latitude !== 0) {
            pointList.push(new L.LatLng(location.latitude, location.longitude));

            heatmapData.push([location.latitude, location.longitude, 1]);
          }
        });

        const tripPolyLine = new L.Polyline(pointList, {
          color,
          weight: 2,
          opacity: 0.4,
          smoothFactor: 1,
          dashArray: "10, 5",
        }).addTo(this.tripFeatureGroup);

        this.tripFeatureGroup.addTo(this.leafletMapComponent.tripLayer);
      }
    });

    this.heatmapLayer = L["heatLayer"](heatmapData, { radius: 25 }).addTo(this.leafletMapComponent.heatmapLayer);

    const bounds = this.leafletMapComponent.tripLayer.getBounds();

    if (bounds.isValid()) {
      this.leafletMapComponent.map.fitBounds(bounds, { padding: [15, 15] }, { animate: true, duration: 0.5 });
    }
  }
}
