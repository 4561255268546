<div
    *ngIf="isModalShown"
    [config]="{ show: true, backdrop: true, ignoreBackdropClick: true }"
    (onHidden)="onHidden()"
    bsModal
    #autoShownModal="bs-modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
>
    <form #userForm="ngForm" autocomplete="off">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title pull-left">
                        <i class="fas fa-fw fa-user"></i> {{'general.shared' | translate }}
                    </h5>
                    <button type="button" class="close float-right" aria-label="Close" (click)="hideModal()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div class="modal-body">
                    <ngx-loading [show]="loadingUser"></ngx-loading>

                    <ng-container *ngIf="!user; else userDetails">
                        <div class="wizard-content" style="padding-bottom: 10px">
                            {{ 'general.emailLoginNameInfo' | translate}}
                        </div>

                        <table style="width: 100%" class="table table-fixed nowrap dtr-inline no-footer">
                            <tbody>
                                <tr>
                                    <td class="row_opening">
                                        {{ 'general.emailLoginName' | translate}}
                                        <span class="error" *ngIf="(name.invalid || name.invalid)">*</span>
                                    </td>
                                    <td class="row_content">
                                        <input
                                            type="email"
                                            name="name"
                                            placeholder="{{ 'placeholder.userName' | translate}}"
                                            autocomplete="off"
                                            class="form-control table-inline"
                                            [(ngModel)]="userName"
                                            pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$"
                                            required
                                            #name="ngModel"
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </ng-container>

                    <ng-template #userDetails>
                        <div *ngIf="!user.id; else completeAccountViewer">
                            <fh-user-details [smallMode]="true" [user]="user" [formMode]="formMode"> </fh-user-details>
                        </div>

                        <ng-template #completeAccountViewer>
                            {{'general.userAccountViewerConfirmation' | translate }}
                        </ng-template>
                    </ng-template>
                </div>

                <div class="modal-footer">
                    <ng-container *ngIf="!user; else finishScreen">
                        <button [disabled]="userForm.invalid" class="btn btn-primary" (click)="findUsers()">
                            <i class="fas fa-fw fa-check"></i> {{'grid.next' | translate }}
                        </button>
                    </ng-container>
                    <ng-template #finishScreen>
                        <button [disabled]="userForm.invalid" class="btn btn-primary" (click)="finish()">
                            <i class="fas fa-fw fa-check"></i> {{'general.add' | translate }}
                        </button>
                    </ng-template>

                    <button class="btn btn-danger" (click)="close()">
                        <i class="fas fa-fw fa-times"></i> {{'general.cancel' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>
