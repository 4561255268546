import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgOptionHighlightModule } from "@ng-select/ng-option-highlight";
import { TranslateModule } from "@ngx-translate/core";
import { ngxLoadingAnimationTypes } from "../loading/ngx-loading-config";
import { NgxLoadingModule } from "../loading/public_api";

import { AccountInputComponent } from "./accountSelector.component";
import { PasswordInputComponent } from "./password.component";
import { ResellerInputComponent } from "./resellerSelector.component";
import { AssetTypeInputComponent } from "./assetTypeSelector.component";
import { AssetGroupInputComponent } from "./assetGroupSelector.component";
import { UserInputComponent } from "./userSelector.component";
import { UserContactGroupInputComponent } from "./userContactGroupSelector.component";
import { MaintenanceTypeInputComponent } from "./maintenanceTypeSelector.component";
import { DriverGroupInputComponent } from "./driverGroupSelector.component";
import { DriverInputComponent } from "./driverSelector.component";
import { GeofenceGroupInputComponent } from "./geofenceGroupSelector.component";
import { TrailerInputComponent } from "./trailerSelector.component";

import { OrderByPipe } from "app/common/orderBy.pipe";
import { ProjectInputComponent } from "./projectSelector.component";
import { SensorTemplateInputComponent } from "./sensorTemplateSelector.component";
import { GeofenceGroupItemInputComponent } from "./geofenceGroupItemSelector.component";
import { AssetGroupItemInputComponent } from "./assetGroupItemSelector.component";
import { DriverGroupItemInputComponent } from "./driverGroupItemSelector.component";
import { ScheduleInputComponent } from "./scheduleSelector.component";
import { RouterModule } from "@angular/router";
import { WebhookEndpointInputComponent } from "./webhookEndpointSelector.component";
import { UserContactGroupItemInputComponent } from "./userContactGroupItemSelector.component";
import { ScoreIndicatorComponent } from "./scoreIndicator.component";
import { GarageSelectorComponent } from "./garageSelector.component";
import { DriverGroupParentSelectorComponent } from "./driverGroupParentSelector.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    NgOptionHighlightModule,
    TranslateModule,
    RouterModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.rectangleBounce,
      backdropBackgroundColour: "rgba(255,255,255,0.7)",
      backdropBorderRadius: "4px",
      primaryColour: "#DC8139",
      secondaryColour: "#DC8139",
      tertiaryColour: "#DC8139",
    }),
  ],
  declarations: [
    OrderByPipe,
    AccountInputComponent,
    AssetTypeInputComponent,
    AssetGroupInputComponent,
    AssetGroupItemInputComponent,
    DriverGroupInputComponent,
    GeofenceGroupInputComponent,
    GeofenceGroupItemInputComponent,
    DriverGroupInputComponent,
    DriverGroupItemInputComponent,
    MaintenanceTypeInputComponent,
    SensorTemplateInputComponent,
    ScheduleInputComponent,
    TrailerInputComponent,
    ProjectInputComponent,
    PasswordInputComponent,
    ResellerInputComponent,
    DriverInputComponent,
    UserInputComponent,
    UserContactGroupInputComponent,
    UserContactGroupItemInputComponent,
    WebhookEndpointInputComponent,
    ScoreIndicatorComponent,
    GarageSelectorComponent,
    DriverGroupParentSelectorComponent,
  ],
  exports: [
    OrderByPipe,
    AccountInputComponent,
    AssetTypeInputComponent,
    AssetGroupInputComponent,
    AssetGroupItemInputComponent,
    DriverGroupInputComponent,
    GeofenceGroupInputComponent,
    GeofenceGroupItemInputComponent,
    DriverGroupInputComponent,
    DriverGroupItemInputComponent,
    MaintenanceTypeInputComponent,
    SensorTemplateInputComponent,
    ScheduleInputComponent,
    TrailerInputComponent,
    ProjectInputComponent,
    PasswordInputComponent,
    ResellerInputComponent,
    DriverInputComponent,
    UserInputComponent,
    UserContactGroupInputComponent,
    UserContactGroupItemInputComponent,
    TranslateModule,
    WebhookEndpointInputComponent,
    ScoreIndicatorComponent,
    GarageSelectorComponent,
    DriverGroupParentSelectorComponent,
  ],
})
export class CustomInputModule {}
