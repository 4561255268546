import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable, catchError, map } from "rxjs";
import { AuthenticationService } from "../authentication/authentication.service";
import { CustomCommand } from "app/models/customCommand.model";
import { throwError } from "rxjs/internal/observable/throwError";

@Injectable()
export class CustomCommandService {
  url = "";

  constructor(private http: HttpClient, private authenticationService: AuthenticationService) {
    this.url = this.authenticationService.getWebserviceURL("CustomCommand");
  }

  getCommands(): Observable<any> {
    return this.http.get(this.url, { headers: this.authenticationService.headers }).pipe(
      map((data) => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  getCommandById(id): Observable<any> {
    return this.http.get(this.url + id, { headers: this.authenticationService.headers }).pipe(
      map((data) => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  createCustomCommand(request: CustomCommand): Observable<any> {
    return this.http.post(this.url, request, { headers: this.authenticationService.headers }).pipe(
      map((data) => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  updateCustomCommand(id: number, request: CustomCommand) {
    return this.http.put(this.url + id, request, { headers: this.authenticationService.headers }).pipe(
      map((data) => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  deleteCustomCommand(id: number) {
    return this.http
      .delete(this.url + id, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  private handleError(error: Response) {
    return throwError(error);
  }
}
