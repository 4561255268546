import { Component, Input, OnInit } from "@angular/core";
import { ControlContainer, NgForm } from "@angular/forms";
import { FormMode } from "app/common/enums";
import { DeviceSensorType } from "app/models/sensor.model";
import { InventorySensorType } from "app/models/sensorType.model";
import { DeviceSensorsService } from "app/services/sensors/deviceSensors.service";

@Component({
  selector: "fh-sensor-type-details",
  templateUrl: "sensorTypeDetails.component.html",
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class SensorTypeDetailsComponent implements OnInit {
  @Input() sensorType: InventorySensorType;
  @Input() formMode: any = FormMode.read;
  sensorTypes: DeviceSensorType[] = [];

  constructor(private sensorTypeService: DeviceSensorsService) {
    sensorTypeService.getDeviceSensorTypes().subscribe((result) => {
      this.sensorTypes = result;
    });
  }

  ngOnInit(): void {}
}
