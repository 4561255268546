<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/"> <i class="fas fa-fw fa-home"></i> {{ "general.home" | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/System/">{{ "general.system" | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/System/Tags">{{ "general.tags" | translate }}</a>
                </li>
            </ol>
        </nav>
    </div>
    <fh-system-navigation></fh-system-navigation>
</div>

<div class="wrapper wrapper-content">
    <div class="row">
        <div class="col-lg-12">
            <div class="ibox">
                <div class="ibox-title">
                    <h5><i class="fas fa-fw fa-tags"></i> {{ "search.tags" | translate }}</h5>
                </div>
                <div class="ibox-content" style="position: relative">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <tag-input
                        *ngIf="tags.length > 0"
                        [(ngModel)]="tags"
                        class="taginput"
                        theme="bootstrap"
                        readonly="true"
                        [hideForm]="true"
                        [identifyBy]="'name'"
                        [displayBy]="'nameWithCount'"
                        [editable]="false"
                        (onSelect)="onSelected($event)"
                    >
                    </tag-input>

                    <span *ngIf="tags.length == 0"> {{ "search.noTagsFound" | translate }}</span>

                    <div style="clear: both"></div>
                </div>
            </div>
        </div>
    </div>
</div>
