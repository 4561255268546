<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/"> <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Drivers/Overview">{{'general.drivers' | translate }}</a>
                </li>
                <li class="breadcrumb-item active">{{'general.assignments' | translate }}</li>
            </ol>
        </nav>
    </div>
    <fh-driver-navigation></fh-driver-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">
        <div class="row">
            <div class="col-lg-12">
                <fh-error [error]="error" [success]="success"></fh-error>

                <div class="ibox">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-filter"></i> {{'general.filter' | translate }}</h5>

                        <span class="float-right">
                            <i class="fas fa-fw fa-file hand" (click)="export()"></i>
                        </span>
                    </div>
                    <div class="ibox-content slick_toppadding" style="position: relative" *ngIf="languageLoaded">
                        <ngx-loading [show]="loading"></ngx-loading>
                        <div id="dynamicFilters" class="flex-container">
                            <div class="col-lg-4">
                                <div class="form-group" style="margin-bottom: 6px">
                                    <fh-reseller-input
                                        name="reseller"
                                        #resellerVar="ngModel"
                                        (onChanged)="resellerChanged($event)"
                                        required
                                        [tableInline]="false"
                                        placeholder="{{ 'general.chooseReseller' | translate}}"
                                        [(ngModel)]="selectedResellerId"
                                    >
                                    </fh-reseller-input>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group" style="margin-bottom: 6px; display: block">
                                    <fh-account-input
                                        name="account"
                                        #accountVar="ngModel"
                                        (onChanged)="accountChanged($event)"
                                        required
                                        [activeOnly]="true"
                                        [selectedResellerId]="selectedResellerId"
                                        [tableInline]="false"
                                        placeholder="{{ (selectedResellerId ? 'general.chooseAccount' : 'general.firstChooseReseller') | translate}}"
                                        [(ngModel)]="selectedAccountId"
                                    >
                                    </fh-account-input>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group input-group" style="margin-bottom: 6px">
                                    <span class="input-group-prepend">
                                        <i class="fa fa-fw fa-calendar"></i>
                                    </span>
                                    <input
                                        placeholder="Daterangepicker"
                                        style="width: 100%"
                                        placeholder="Datepicker"
                                        type="text"
                                        [bsConfig]="dpConfig"
                                        class="form-control"
                                        bsDaterangepicker
                                        (onHidden)="dateChanged('hidden')"
                                        [(ngModel)]="daterangepickerModel"
                                    />
                                    <span class="input-group-calendar-datatables">
                                        <i class="fa fa-calendar-alt"></i>
                                    </span>
                                </div>
                            </div>
                            <div style="clear: both"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="drivers.length == 0">
            <div class="col-lg-12">
                <div class="ibox">
                    <div class="ibox-content" style="position: relative">
                        <ngx-loading [show]="loading"></ngx-loading>
                        <span *ngIf="!selectedAccountId">{{'general.pleaseSelectAccount' | translate }}</span>
                        <span *ngIf="selectedAccountId">{{'general.noDataFound' | translate }}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="drivers.length !== 0">
            <div class="col-lg-12">
                <div class="ibox">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-barcode"></i> {{'general.assignments' | translate }}</h5>
                    </div>
                    <div class="ibox-content slick" style="position: relative; overflow-y: hidden">
                        <table style="width: 100%" class="table nowrap dtr-inline no-footer">
                            <thead>
                                <tr>
                                    <th>{{'general.asset' | translate }}</th>
                                    <th>{{'general.driver' | translate }}</th>
                                    <th>{{'general.start' | translate }}</th>
                                    <th>{{'general.end' | translate }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="let assignment of assignments | orderBy : orderBy : orderByReversed | stringFilter: filter | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, id: 'first'}; "
                                >
                                    <td class="row_opening_small">
                                        <a
                                            class="secondary link_bolder"
                                            href="/#/DeviceDetails/Index/{{assignment?.deviceId}}"
                                        >
                                            {{ assignment.assetName }}</a
                                        >
                                    </td>
                                    <td>
                                        <a
                                            class="secondary link_bolder"
                                            href="/#/DriverDetails/Index/{{assignment?.driverId}}"
                                        >
                                            {{ assignment.driverName }}</a
                                        >
                                    </td>
                                    <td>{{assignment.dateStart | amDateFormat: 'LLL' }}</td>
                                    <td>{{assignment.dateEnd | amDateFormat: 'LLL' }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="ibox-footer noselect dataTables_wrapper">
                        <pagination-template #p="paginationApi" (pageChange)="currentPage = $event" id="first">
                            <div class="dataTables_paginate paging_simple_numbers" id="dataTable_paginate">
                                <ul class="pagination">
                                    <li class="paginate_button page-item previous" [class.disabled]="p.isFirstPage()">
                                        <a class="page-link" (click)="p.previous()" *ngIf="!p.isFirstPage()"
                                            >{{ 'grid.previous' | translate }}</a
                                        >
                                        <a *ngIf="p.isFirstPage()">{{ 'grid.previous' | translate }}</a>
                                    </li>

                                    <li
                                        class="paginate_button page-item"
                                        *ngFor="let page of p.pages"
                                        [class.current]="p.getCurrent() === page.value"
                                    >
                                        <a
                                            class="page-link"
                                            (click)="p.setCurrent(page.value)"
                                            *ngIf="p.getCurrent() !== page.value"
                                        >
                                            <span>{{ page.label }}</span>
                                        </a>
                                        <a
                                            class="page-link"
                                            (click)="p.setCurrent(page.value)"
                                            *ngIf="p.getCurrent() === page.value"
                                        >
                                            <span style="font-weight: bold">{{ page.label }}</span>
                                        </a>
                                    </li>

                                    <li class="paginate_button page-item next" [class.disabled]="p.isLastPage()">
                                        <a class="page-link" (click)="p.next()" *ngIf="!p.isLastPage()">
                                            {{ 'grid.next' | translate }}
                                        </a>
                                        <a class="page-link" *ngIf="p.isLastPage()"> {{ 'grid.next' | translate }} </a>
                                    </li>
                                </ul>
                            </div>
                        </pagination-template>
                        <div style="clear: both"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</fh-permission-handler>
