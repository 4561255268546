import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";

@Component({
  selector: "fh-filter-toggler",
  templateUrl: "filterToggler.component.html",
  viewProviders: [],
})
export class FilterTogglerComponent implements OnInit, OnChanges {
  showFilter = true;
  showPresets = true;

  @Input() constructorName: any;
  @Output() filterChanged = new EventEmitter();
  @Output() presetsChanged = new EventEmitter();

  constructor(private cd: ChangeDetectorRef) {}

  ngOnChanges(): void {}

  ngOnInit(): void {}
}
