import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { TimezoneSelectorComponent } from "./timezoneselector.component";

@NgModule({
  imports: [CommonModule, FormsModule, NgSelectModule],
  declarations: [TimezoneSelectorComponent],
  exports: [TimezoneSelectorComponent],
})
export class TimezoneSelectorModule {}
