import {
  Component,
  EventEmitter,
  Output,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnInit,
  Input,
  OnChanges,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Device } from "app/models/device.model";
import { AuthenticationService } from "app/services/authentication/authentication.service";
import { CustomCommandService } from "app/services/customcommand/customcommand.service";
import { DeviceService } from "app/services/device/device.service";

@Component({
  selector: "fh-batch-custom-commands",
  templateUrl: "customCommands.template.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BatchDeviceCustomCommandsComponent implements OnInit, OnChanges {
  @Input() gridSelection = [];
  @Output() onFinish = new EventEmitter();

  step: number;
  logItem;

  permissions = {};

  groupForm = new FormGroup({
    customCommandId: new FormControl(null, Validators.required),
    wakeUpDevice: new FormControl(false, Validators.required),
  });

  customCommands: any = [];

  constructor(
    private cd: ChangeDetectorRef,
    private customCommandService: CustomCommandService,
    private deviceService: DeviceService,
    private authenticationService: AuthenticationService
  ) {
    this.step = 1;
    this.permissions = this.authenticationService.permissions;
  }

  ngOnInit(): void {
    let deviceTypes = [];

    if (this.gridSelection.length > 0) {
      for (var i = 0; i < this.gridSelection.length; i++) {
        this.deviceService.getDeviceById(this.gridSelection[i]).subscribe((deviceType) => {
          deviceTypes.push(deviceType);

          if (i == this.gridSelection.length) {
            this.filterOptions(deviceTypes);
          }
        });
      }
    }
  }

  filterOptions(deviceTypes: Device[]) {
    //Checks whether a custom command is reseller only or not and if it has an implementation for the given deviceType(s)
    deviceTypes.forEach((deviceType) => {
      this.customCommandService.getCommands().subscribe((customCommands) => {
        customCommands.forEach((command) => {
          if (command.isResellerOnly && !this.permissions["Platform_IsReseller"]) {
            return;
          }

          for (var i = 0; i < command.implementations.length; i++) {
            if (command.implementations[i].deviceTypeId === deviceType.deviceTypeId) {
              if (!this.customCommands.find((duplicate) => duplicate.id == command.id)) {
                this.customCommands.push(command);
                break;
              }
            }
          }
        });
        this.cd.markForCheck();
      });
    });
  }

  ngOnChanges(): void {
    this.cd.markForCheck();
  }

  initFinish() {
    console.log(1);
  }

  onCheckOutFinish() {
    this.onFinish.emit({
      status: "success",
      object: {
        customCommandId: this.groupForm.value.customCommandId,
        wakeUpDevice: this.groupForm.value.wakeUpDevice,
      },
      log: this.logItem,
    });

    this.step = 1;
  }
}
