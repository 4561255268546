import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { AssetSensor } from "app/models/sensor.model";
import { throwError } from "rxjs/internal/observable/throwError";
import { Observable, catchError, map } from "rxjs";
import { AuthenticationService } from "../authentication/authentication.service";
import { TranslatorService } from "../common/translator.service";
import { LoggingService } from "../logging/logging.service";

@Injectable()
export class SensorService {
  token: string;
  timestamp;
  url = "";
  urlBase = "";

  constructor(
    private http: HttpClient,
    private loggingService: LoggingService,
    private translatorService: TranslatorService,
    private authenticationService: AuthenticationService
  ) {
    this.url = authenticationService.getWebserviceURL("Sensor");
  }

  getSensorsOnAsset(assetId, sensorTypes): Observable<AssetSensor[]> {
    console.log("getting sensors from service");
    return this.http
      .get(this.urlBase + "Asset/" + assetId + "/Sensors", { headers: this.authenticationService.headers })
      .pipe(
        map((data) => {
          const parsedResponse = this.parseResponse(data, assetId, sensorTypes);
          return parsedResponse;
        }),
        catchError(this.handleError)
      );

    // const sensorsList = [];
    // const sensor1 = new AssetSensor();
    // sensor1.assetId = assetId;
    // sensor1.calibrationPoints = [];
    // sensor1.sensorPosition = 1;
    // sensor1.sensorTypeId = '8375e11d-22d9-4779-5892-08d7724ee468';
    // sensor1.sensorType = sensorTypes.find(x => x.id === sensor1.sensorTypeId);

    // sensorsList.push(sensor1);

    // const sensor2 = new AssetSensor();
    // sensor2.assetId = assetId;
    // sensor2.calibrationPoints = [];
    // sensor2.sensorPosition = 2;
    // sensor2.sensorTypeId = '5904f66c-ef10-4f93-6749-08d7725cac86';
    // sensor2.sensorType = sensorTypes.find(x => x.id === sensor2.sensorTypeId);

    // sensorsList.push(sensor2);

    // const sensor3 = new AssetSensor();
    // sensor3.assetId = assetId;
    // sensor3.calibrationPoints = [];
    // sensor3.sensorPosition = 0;
    // sensor3.sensorTypeId = '3f96713e-e560-4288-82c0-08d7726f2854';
    // sensor3.sensorType = sensorTypes.find(x => x.id === sensor3.sensorTypeId);

    // sensorsList.push(sensor3);

    // return of(sensorsList);
  }

  addSensor(sensor: AssetSensor): Observable<any> {
    console.log("Post sensor");
    return this.http
      .post(this.url, sensor, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  saveSensor(sensor: AssetSensor): Observable<any> {
    console.log("Put sensor");

    return this.http
      .put(this.url + sensor.id, sensor, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  deleteSensor(sensor: AssetSensor): Observable<any> {
    this.token = this.authenticationService.getAuthToken();
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + this.token,
    });

    console.log("Saving sensor settings");

    return this.http
      .delete(this.url + sensor.id, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  private handleError(error: Response) {
    return throwError(error);
  }

  parseResponse(json: any, assetId, sensorTypes): AssetSensor[] {
    this.loggingService.log(this.constructor.name, "Retrieved " + json.length + " sensortypes.");

    const ident = 1;
    const sensors: AssetSensor[] = [];

    // this.loggingService.log(json);
    json.forEach((item) => {
      //   this.loggingService.log(this.constructor().name + " - New", item);
      const sensor = this.parseReponseDetails(item, assetId, sensorTypes);
      sensors.push(sensor);
    });

    this.timestamp = new Date().getTime();
    return sensors;
  }

  parseReponseDetails(item, assetId, sensorTypes): AssetSensor {
    const sensor = new AssetSensor();

    sensor.id = item.id;
    sensor.assetId = item.assetId;
    sensor.calibrationPoints = item.calibrationPoints;
    sensor.sensorPosition = item.sensorPosition;
    sensor.sensorTypeId = item.sensorTypeId;
    sensor.sensorType = sensorTypes.find((x) => x.id === sensor.sensorTypeId);

    return sensor;
  }
}
