<li class="nav-header" style="display: block; position: relative; height: unset">
    <div class="dropdown profile-element hand" [routerLink]="['/']">
        <div class="site_logo"></div>
    </div>
</li>

<!-- Wrapper-->
<div id="wrapper" style="position: relative; top: unset">
    <!-- Main page wrapper -->
    <div id="page-wrapper" class="gray-bg page-wrapper-or">
        <div class="wrapper wrapper-content">
            <div style="max-width: 1050px; padding: 0px; margin: auto">
                <!-- View/routes wrapper-->
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>
