<div class="ibox-content slick" style="position: relative">
    <ngx-loading [show]="loading"></ngx-loading>
    <table style="width: 100%" class="table table-fixed nowrap dtr-inline no-footer">
        <tbody *ngIf="!fuel">
            <tr>
                <td colspan="2">
                    {{ "general.noFuel" | translate }}
                </td>
            </tr>
        </tbody>
        <!-- Edit form -->
        <tbody *ngIf="fuel && formMode == 2">
            <ng-container *ngIf="formMode != 2">
                <tr [hidden]="!permissions['Resellers_View']">
                    <td class="row_opening">
                        {{ "general.resellerDescription" | translate }}
                    </td>
                    <td class="padding-readonly-correct row_content">
                        <a class="secondary link_bolder" href="/#/ResellerDetails/Index/{{ fuel?.resellerId }}">{{
                            fuel?.resellerDescription
                        }}</a>
                    </td>
                </tr>
                <tr [hidden]="!permissions['Accounts_View']">
                    <td class="row_opening">
                        {{ "general.account" | translate }}
                    </td>
                    <td class="row_content padding-readonly-correct">
                        <a class="secondary link_bolder" href="/#/AccountDetails/Index/{{ fuel?.accountId }}">{{
                            fuel?.companyName
                        }}</a>
                    </td>
                </tr>
            </ng-container>
            <ng-container *ngIf="formMode == 2 && !smallMode">
                <tr [hidden]="!permissions['Resellers_View'] && selectedResellerId">
                    <td class="row_opening">
                        {{ "general.resellerDescription" | translate }}
                        <span class="error" *ngIf="resellerVar.invalid">*</span>
                    </td>
                    <td class="row_content" style="position: relative; overflow: visible">
                        <fh-reseller-input
                            name="reseller"
                            #resellerVar="ngModel"
                            (onChanged)="resellerChanged($event)"
                            required
                            placeholder="{{ 'general.chooseReseller' | translate }}"
                            [tableInline]="true"
                            [(ngModel)]="selectedResellerId"
                        >
                        </fh-reseller-input>
                    </td>
                </tr>
                <tr [hidden]="!permissions['Accounts_View'] && fuel.accountId">
                    <td class="row_opening">
                        {{ "general.account" | translate }}
                        <span class="error" *ngIf="accountVar.invalid">*</span>
                    </td>
                    <td class="row_content" style="position: relative; overflow: visible">
                        <fh-account-input
                            name="account"
                            #accountVar="ngModel"
                            (onChanged)="accountChanged($event)"
                            required
                            [selectedResellerId]="selectedResellerId"
                            [tableInline]="true"
                            placeholder="{{
                                (selectedResellerId ? 'general.chooseAccount' : 'general.firstChooseReseller')
                                    | translate
                            }}"
                            [(ngModel)]="fuel.accountId"
                        >
                        </fh-account-input>
                    </td>
                </tr>
                <tr>
                    <td class="row_opening">
                        {{ "general.device" | translate }}
                        <span class="error" *ngIf="formMode == 2 && !(fuel.assetId > 0)">*</span>
                    </td>
                    <td class="row_content" style="overflow: visible">
                        <fh-asset-chooser
                            [accountId]="fuel.accountId"
                            [formMode]="formMode"
                            [activeOnly]="true"
                            (onAssetChanged)="
                                fuel.assetId = $event ? $event.id : undefined;
                                fuel.deviceId = $event ? $event.deviceId : undefined
                            "
                        >
                        </fh-asset-chooser>
                    </td>
                </tr>
            </ng-container>
            <tr>
                <td class="row_opening">
                    {{ "general.amount" | translate }}
                    <span class="error" *ngIf="quantity.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input
                        type="number"
                        [readonly]="formMode == 1"
                        name="quantity"
                        required
                        min="1"
                        placeholder="{{ 'placeholder.amount' | translate }}"
                        class="form-control table-inline"
                        [(ngModel)]="fuel.fuelingActivityInLiters"
                        #quantity="ngModel"
                    />
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ "general.eventTimeStamp" | translate }}
                    <span class="error" *ngIf="eventTimeStamp.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input
                        type="text"
                        [disabled]="formMode == 1"
                        name="eventTimeStamp"
                        identifier
                        #eventTimeStamp="ngModel"
                        class="form-control table-inline"
                        [(ngModel)]="fuel.eventTimeStamp"
                        autocomplete="off"
                        [bsConfig]="dpConfig"
                        placeholder="{{ 'placeholder.eventTimeStamp' | translate }}"
                        required
                        bsDatepicker
                        #eventTimeStampDp="bsDatepicker"
                    />
                    <span *ngIf="formMode != 1" class="input-group-calendar"><i class="fa fa-calendar-alt"></i></span>
                </td>
            </tr>
        </tbody>
        <!-- Read form -->
        <tbody *ngIf="fuel && formMode != 2">
            <tr>
                <td class="row_opening">
                    {{ "general.companyName" | translate }}
                </td>
                <td class="padding-readonly-correct row_content">
                    <a class="secondary link_bolder" href="/#/AccountDetails/Index/{{ fuel?.accountId }}">{{
                        fuel?.companyName
                    }}</a>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ "general.device" | translate }}
                </td>
                <td class="row_content padding-readonly-correct">
                    <a class="secondary link_bolder" href="/#/DeviceDetails/Fuel/{{ fuel?.deviceId }}">
                        {{ fuel?.assetName }}</a
                    >
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ "general.fuelCapacity" | translate }}
                </td>
                <td class="row_content padding-readonly-correct">
                    <span *ngIf="fuel.fuelTank2CapacityInLiters">
                        {{ fuel.fuelTankCapacityInLiters?.toLocaleString() }} L ({{
                            fuel.fuelTank1CapacityInLiters?.toLocaleString()
                        }}
                        L + {{ fuel.fuelTank2CapacityInLiters?.toLocaleString() }} L)
                    </span>
                    <span *ngIf="!fuel.fuelTank2CapacityInLiters">
                        {{ fuel.fuelTankCapacityInLiters?.toLocaleString() }} L
                    </span>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ "general.fillupThresholds" | translate }}
                </td>
                <td class="row_content padding-readonly-correct">
                    <span *ngIf="fuel.fuelTank1CapacityInLiters">
                        {{ fuel.fillupThresholdPercentage?.toLocaleString() }}% ({{
                            (fuel.fillupThresholdPercentage / 100) * fuel.fuelTankCapacityInLiters
                        }}
                        L)
                    </span>
                    <span *ngIf="!fuel.fuelTank1CapacityInLiters">
                        {{ fuel.fillupThresholdPercentage?.toLocaleString() }}%
                    </span>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ "general.theftThresholds" | translate }}
                </td>
                <td class="row_content padding-readonly-correct">
                    <span *ngIf="fuel.fuelTank1CapacityInLiters">
                        {{ fuel.theftThresholdPercentage?.toLocaleString() }}% ({{
                            (fuel.theftThresholdPercentage / 100) * fuel.fuelTankCapacityInLiters
                        }}
                        L)
                    </span>
                    <span *ngIf="!fuel.fuelTank1CapacityInLiters">
                        {{ fuel.theftThresholdPercentage?.toLocaleString() }}%
                    </span>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ "general.source" | translate }}
                </td>
                <td class="row_content padding-readonly-correct">
                    {{ fuel.source }}
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ "general.sensorTypes" | translate }}
                </td>
                <td class="row_content padding-readonly-correct">
                    {{ fuel.auxiliaryAttachments }}
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ "general.eventTimeStamp" | translate }}
                </td>
                <td class="row_content padding-readonly-correct" title="{{ fuel.eventTimeStamp?.toLocaleString() }}">
                    {{ fuel.eventTimeStamp | amDateFormat : "lll" }}
                </td>
            </tr>
        </tbody>
    </table>
</div>
