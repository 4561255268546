import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import "jquery-slimscroll";

import { colorArray2, getIconPath, roundAsString } from "app/common/globals";

import { Subscription } from "rxjs/internal/Subscription";
import { AuthenticationService } from "../../services/authentication/authentication.service";
import { DeviceService } from "app/services/device/device.service";
import { AssetGroupsService } from "app/services/asset/assetGroups.service";
import { KeyValue } from "@angular/common";
import { TripService } from "app/services/trip/trip.service";
import { TranslateService } from "@ngx-translate/core";
import { DriverService } from "app/services/driver/driver.service";
import { DriverGroupsService } from "app/services/driver/driverGroups.service";
import {
  type FleetOverviewModeCase,
  type FleetOverviewStateCase,
  FleetOverviewStoreService,
} from "app/services/fleetoverview/fleetoverview-store.service";
import { colorMapper } from "app/common/leafletGlobals";
import { getDefaultDpConfig } from "app/common/gridhelper";
import { AssetDisplayName, EntityType, SortingOptionFleetOverview, StorageType } from "app/common/enums";
import { AppUser } from "app/models/user.model";
import { UserService } from "app/services/users/user.service";

import { marker } from "leaflet";

import * as Moment from "moment";

import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { StorageHelper } from "app/common/storagehelper";
import { MapService } from "app/services/common/map.service";

declare var L;
declare var $;

@Component({
  selector: "fh-overview-navigation",
  templateUrl: "overviewNavigation.template.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FleetOverviewNavigationComponent implements OnInit, AfterViewInit, OnDestroy {
  hasFuel: any;
  // Permissions
  hasReporting: any;
  hasCalculationSettings: any;
  hasLocations: any;
  hasTrips: any;
  hasAdvice: any;
  hasSchedule: any;
  hasSettings: any;
  hasAssets: any;
  hasDevices: any;
  hasCustomers: any;

  showId: any;
  id: number;
  private sub: Subscription;
  children = false;
  permissions: {};
  isImpersonated: boolean;

  colorArray = colorArray2;

  selectedDevice;
  filter = "";
  assetGroups = new Map();
  driverGroups = new Map();

  assetGroupSelection = new Map();
  driverGroupSelection = new Map();
  geofenceGroupSelection = new Map();
  deviceSelection = new Map();
  driverSelection = new Map();
  geofenceSelection = new Map();

  filterToggle = false;

  calculatedDeviceState = new Map<number, any>();
  lastCommunication = new Map<number, string>();
  lastStateUpdated = new Map<number, string>();
  address = new Map<number, any>();

  calculatedStateSum = new Map<number, number>();

  cachedTrips = new Map<number, L.FeatureGroup<any>>();

  hiddenAssets = new Map<number, boolean>();
  hiddenGeofences = new Map<number, boolean>();
  liveHidden = new Map<number, boolean>();

  delay = 500;
  filterDebounce: Subscription;

  maxListItems = 60;

  maxGroups = 100;

  selectedHistoryDevice;
  selectedLiveGroup;
  mode: FleetOverviewModeCase = "Overview";
  tab = "Assets";
  history = new Map();

  live = new Map();

  currentDate: number;

  timezoneIana: string;

  daterangepickerModel: Date[] = [];
  dpConfig;

  fetchingStates = true;

  sidebarHidden = true;

  currentMarker = 0;

  user: AppUser;

  sortingOrder = SortingOptionFleetOverview["Device Name"];

  accountId;

  debouncer = new Subject();

  driversMap;

  dropdownStates = [
    {
      state: 1,
      image: "fa-car",
      color: "green",
      count() {
        if (this.getDeviceCount(1) != this.calculatedStateSum.get(1)) {
          return `${this.getDeviceCount(1)}/${this.calculatedStateSum.get(1)}`;
        }

        return this.getDeviceCount(1);
      },
    },
    {
      state: 2,
      image: "fa-car",
      color: "red",
      count() {
        if (this.getDeviceCount(2) != this.calculatedStateSum.get(2)) {
          return `${this.getDeviceCount(2)}/${this.calculatedStateSum.get(2)}`;
        }

        return this.getDeviceCount(2);
      },
    },
    {
      state: 3,
      image: "fa-car",
      color: "orange",
      count() {
        if (this.getDeviceCount(3) != this.calculatedStateSum.get(3)) {
          return `${this.getDeviceCount(3)}/${this.calculatedStateSum.get(3)}`;
        }

        return this.getDeviceCount(3);
      },
    },
    {
      state: 4,
      image: "fa-car",
      color: "blue",
      count() {
        if (this.getDeviceCount(4) != this.calculatedStateSum.get(4)) {
          return `${this.getDeviceCount(4)}/${this.calculatedStateSum.get(4)}`;
        }

        return this.getDeviceCount(4);
      },
    },
    {
      state: 5,
      image: "fa-car",
      color: "black",
      count() {
        if (this.getDeviceCount(5) != this.calculatedStateSum.get(5)) {
          return `${this.getDeviceCount(5)}/${this.calculatedStateSum.get(5)}`;
        }

        return this.getDeviceCount(5);
      },
    },
    {
      state: 0,
      image: "fa-car",
      color: "grey",
      count() {
        return this.calculatedStateSum.get(0);
      },
    },
  ];

  // Temp map properties
  theMarker: any;
  myMovingMarker: any;
  stopMarker: any;
  theGeofence: any;

  storageType = StorageType.LocalStorage;
  settings = [];

  sidebarSettings = [
    { name: "showSidebarLocationUpdated", value: null },
    { name: "showSidebarStateUpdated", value: null },
    { name: "showSidebarDriverName", value: null },
    { name: "showSidebarCity", value: null },
    { name: "showSidebarGeofence", value: null },
  ];

  get state(): FleetOverviewStateCase {
    return this.fleetOverviewStoreService.fleetOverviewState;
  }

  constructor(
    private cd: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    private authentication: AuthenticationService,
    private deviceService: DeviceService,
    private mapService: MapService,
    private assetGroupService: AssetGroupsService,
    private tripService: TripService,
    private driverService: DriverService,
    private driverGroupService: DriverGroupsService,
    private fleetOverviewStoreService: FleetOverviewStoreService,
    private translateService: TranslateService,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private storageHelper: StorageHelper
  ) {
    this.id = 0;
    this.showId = "";

    this.timezoneIana = authentication.getTimeZoneIana();

    this.daterangepickerModel = [
      Moment().tz(this.timezoneIana).subtract(1, "week").startOf("day").toDate(),
      Moment().tz(this.timezoneIana).endOf("day").toDate(),
    ];

    this.dpConfig = getDefaultDpConfig(authentication);
  }

  ngAfterViewInit() {
    if ($("body").hasClass("fixed-sidebar")) {
      $(".sidebar-collapse")["slimscroll"]({
        height: "100%",
      });
    }
  }

  onSearchChanged(value) {
    this.debouncer.next(value);
  }

  ngOnInit() {
    const that = this;

    this.debouncer.pipe(debounceTime(this.delay)).subscribe((val) => {
      console.log("Filtering markers for " + this.filter);
      this.filterMarkers();
    });

    this.userService.getUserById(this.authenticationService.getUserId()).subscribe((user) => {
      this.user = user;
    });

    this.storageHelper
      .loadStoreState(StorageType.LocalStorage, "settings_", "sortingOrderFleetOverview")
      .subscribe((value) => {
        this.sortingOrder = SortingOptionFleetOverview["Device Name"];

        if (value !== null) {
          this.sortingOrder = +value;
        }
      });

    this.sidebarSettings.forEach((setting) => {
      this.storageHelper.loadStoreState(this.storageType, "settings_", setting.name).subscribe((result) => {
        this.settings[setting.name] = JSON.parse(result) === true;
      });
    });

    // jQuery('body').addClass('mini-navbar');
    this.isImpersonated = this.authentication.getIsImpersonated();

    this.accountId = this.authenticationService.getAccountId();

    this.permissions = this.authentication.permissions;

    this.translateService.get("general.date").subscribe(
      (_) => {
        this.cd.detach();

        this.fleetOverviewStoreService.deviceState$.subscribe((states) => {
          const newStates = new Map<number, any>();
          const deviceStateValues = [0, 0, 0, 0, 0, 0];

          for (const [id, state] of states) {
            newStates.set(id, {
              id: state,
              get color() {
                return colorMapper(this.id);
              },
            });

            deviceStateValues[state]++;
          }

          this.calculatedStateSum.set(0, deviceStateValues[0]);
          this.calculatedStateSum.set(1, deviceStateValues[1]);
          this.calculatedStateSum.set(2, deviceStateValues[2]);
          this.calculatedStateSum.set(3, deviceStateValues[3]);
          this.calculatedStateSum.set(4, deviceStateValues[4]);
          this.calculatedStateSum.set(5, deviceStateValues[5]);
          this.calculatedStateSum.set(6, deviceStateValues[6]);

          this.calculatedDeviceState = newStates;
          this.cd.detectChanges();
        });

        this.fleetOverviewStoreService.lastDeviceStates$.subscribe((states) => {
          console.log("FO: Handle device subscription.");

          for (const state of states) {
            if (state.currentAddress) {
              this.address.set(+state.id, {
                address: state.currentAddress.address,
                city: state.currentAddress.city,
                country: state.currentAddress.country,
              });
            }
          }

          this.driversMap = that.fleetOverviewStoreService.driversMap;

          this.cd.detectChanges();
        });

        this.fleetOverviewStoreService.lastCommunication$.subscribe((states) => {
          this.lastCommunication = states;
          this.cd.detectChanges();
        });

        this.fleetOverviewStoreService.lastStateUpdated$.subscribe((states) => {
          this.lastStateUpdated = states;
          this.cd.detectChanges();
        });

        this.fleetOverviewStoreService.fetchingStates$.subscribe((fetchingStates) => {
          this.fetchingStates = fetchingStates;
          this.cd.detectChanges();
        });

        this.driverGroupService.getDriverGroups(this.accountId, false).subscribe((driverGroups) => {
          for (const driverGroup of driverGroups) {
            const name = driverGroup.displayName + " - " + driverGroup.companyName;
            this.driverGroups.set(driverGroup.id, {
              _order: name,
              filterClose: false,
              name,
              items: [],
              open: false,
            });
          }

          this.driverService.getDriversByAccount(this.accountId, true, true).subscribe((drivers) => {
            for (const driver of drivers) {
              this.fleetOverviewStoreService.driversMap.set(driver.id, {
                name: driver.name,
                phone: driver.mobilePhone,
                tag: driver.tag,
              });

              for (const { id } of driver.driverGroups) {
                const driverGroup = this.driverGroups.get(+id);
                if (driverGroup !== undefined) {
                  driverGroup.items.push({
                    _search: driverGroup.name,
                    id: driver.id,
                    name: driver.name,
                    get assetName() {
                      return that.fleetOverviewStoreService.driverAssetsMap.get(driver.id);
                    },
                  });
                }
              }
            }
          });
        });

        this.fleetOverviewStoreService.fleetOverviewState$.subscribe((__) => {
          this.cd.detectChanges();
        });

        this.fleetOverviewStoreService.clearTrips$.subscribe(() => {
          for (const [__, item] of this.history) {
            try {
              for (const trip of item.trips) {
                delete trip.plotted;
                delete trip.locations;
              }
            } finally {
              continue;
            }
          }

          this.cachedTrips.clear();
          this.cd.detectChanges();
        });

        this.assetGroupService.getAssetGroups(this.accountId, true).subscribe((assetGroups) => {
          for (const assetGroup of assetGroups) {
            if (assetGroup.assetGroupItems.length === 0) {
              continue;
            }

            let assetGroupName = assetGroup.displayName;

            if (assetGroup.accountId !== +this.accountId) {
              assetGroupName = assetGroup.displayName + " - " + assetGroup.companyName;
            }

            const that = this;

            const groupItems = [];
            for (const groupItem of assetGroup.assetGroupItems) {
              let assetDisplayLabel: string;

              if (this.user?.assetDisplayName === undefined) {
                console.error("User was empty, setting default");
                this.user = new AppUser();
              }

              switch (this.user.assetDisplayName) {
                case AssetDisplayName["Asset Code"]:
                  assetDisplayLabel = `${groupItem.assetCode || groupItem.assetName}`;
                  break;
                case AssetDisplayName["Plate Number"]:
                  assetDisplayLabel = `${groupItem.plateNumber || groupItem.assetName}`;
                  break;
                case AssetDisplayName["Device Name"]:
                  assetDisplayLabel = `${groupItem.assetName ?? (groupItem.assetCode || groupItem.assetName)}`;
                  break;
                case AssetDisplayName["Client Handle: Asset Code"]:
                  assetDisplayLabel = `${groupItem.assetCompanyName}: ${groupItem.assetCode || groupItem.assetName}`;
                  break;
                case AssetDisplayName["Client Handle: Plate Number"]:
                  assetDisplayLabel = `${groupItem.assetCompanyName}: ${groupItem.plateNumber || groupItem.assetName}`;
                  break;
                case AssetDisplayName["Client Handle: Device Name"]:
                  assetDisplayLabel = `${groupItem.assetCompanyName}: ${groupItem.assetName}`;
                  break;
                default:
                  assetDisplayLabel = `${groupItem.assetName ?? (groupItem.assetCode || groupItem.assetName)}`;
                  break;
              }

              var isArchived = assetGroupName.indexOf("Archived") > -1;

              if (!isArchived) {
                this.fleetOverviewStoreService.driverBindings.set(groupItem.deviceId, () =>
                  this.fleetOverviewStoreService.driversMap.get(groupItem.driverId)
                );
                this.fleetOverviewStoreService.assetDriverBindings.set(groupItem.deviceId, groupItem.driverId);
              }

              groupItems.push({
                get _search() {
                  var driverId = that.fleetOverviewStoreService.assetDriverBindings.get(this.id);
                  var driver = that.fleetOverviewStoreService.driversMap.get(driverId);
                  return assetGroup.displayName + (driver ? driver["name"] : "");
                },
                get status() {
                  return that.calculatedDeviceState.get(this.id)?.id;
                },
                get time() {
                  return Moment.utc(+that.lastCommunication.get(this.id)).tz(that.timezoneIana);
                },
                get address() {
                  var theAddress = that.address.get(this.id);
                  var returnAddress = [];

                  if (theAddress && theAddress.address) {
                    returnAddress.push(theAddress.address);
                  }

                  if (theAddress && theAddress.city) {
                    returnAddress.push(theAddress.city);
                  }

                  if (theAddress && theAddress.country) {
                    returnAddress.push(theAddress.country);
                  }
                  return returnAddress.join(", ");
                },
                get _order() {
                  switch (that.sortingOrder) {
                    case SortingOptionFleetOverview["Device Name"]:
                      return groupItem.assetName;
                    case SortingOptionFleetOverview["Asset Code"]:
                      return groupItem.assetCode;
                    case SortingOptionFleetOverview["Plate Number"]:
                      if (groupItem.plateNumber === "") {
                        return null;
                      }

                      return groupItem.plateNumber;
                    case SortingOptionFleetOverview["Location Updated"]:
                      const difference =
                        +new Date() - +Moment.utc(+that.lastCommunication.get(this.id)).tz(that.timezoneIana);

                      if (Number.isNaN(difference)) {
                        return +new Date();
                      }

                      return difference;
                    case SortingOptionFleetOverview["State Updated"]:
                      const stateDifference =
                        +new Date() - +Moment.utc(+that.lastStateUpdated.get(this.id)).tz(that.timezoneIana);

                      if (Number.isNaN(stateDifference)) {
                        return +new Date();
                      }

                      return stateDifference;
                    case SortingOptionFleetOverview["Driver Name"]:
                      var driverId = that.fleetOverviewStoreService.assetDriverBindings.get(this.id);
                      var driver = that.fleetOverviewStoreService.driversMap.get(driverId);
                      return driver ? driver["name"] : "zzz";
                    case SortingOptionFleetOverview["Display Name"]:
                    default:
                      return assetDisplayLabel;
                  }
                },
                id: groupItem.deviceId,
                name: assetDisplayLabel,
                get displayLabel() {
                  let infoDisplayLabel = [];

                  if (that.settings["showSidebarLocationUpdated"]) {
                    var time = Moment.utc(+that.lastCommunication.get(this.id)).tz(that.timezoneIana).fromNow();
                    if (time) {
                      infoDisplayLabel.push(time);
                    }
                  }

                  if (that.settings["showSidebarStateUpdated"]) {
                    var time2 = Moment.utc(+that.lastStateUpdated.get(this.id)).tz(that.timezoneIana).fromNow();
                    if (time2) {
                      infoDisplayLabel.push(time2);
                    }
                  }

                  if (that.settings["showSidebarDriverName"]) {
                    var driverId = that.fleetOverviewStoreService.assetDriverBindings.get(this.id);
                    var driver = that.fleetOverviewStoreService.driversMap.get(driverId);
                    if (driver) {
                      infoDisplayLabel.push(driver["name"]);
                    }
                  }

                  if (that.settings["showSidebarGeofence"]) {
                    var geofences =
                      that.fleetOverviewStoreService.insideGeofencesByDeviceMap.get(this.id.toString()) ?? [];
                    if (geofences) {
                      geofences.forEach((geofenceId) => {
                        const geofenceName = that.fleetOverviewStoreService.geofencesMap.get(+geofenceId);
                        if (geofenceName) {
                          infoDisplayLabel.push(geofenceName);
                        }
                      });
                    }
                  }

                  if (that.settings["showSidebarCity"]) {
                    var theAddress = that.address.get(this.id);
                    var returnAddress = [];

                    if (theAddress && theAddress.address) {
                      returnAddress.push(theAddress.address);
                    }

                    if (theAddress && theAddress.city) {
                      returnAddress.push(theAddress.city);
                    }

                    if (theAddress && theAddress.country) {
                      returnAddress.push(theAddress.country);
                    }
                    var address = returnAddress.join(", ");
                    if (address) {
                      infoDisplayLabel.push(address);
                    }
                  }

                  // Set default sidebar info display
                  if (infoDisplayLabel.length == 0) {
                    var time2 = Moment.utc(+that.lastStateUpdated.get(this.id)).tz(that.timezoneIana).fromNow();
                    if (time2) {
                      infoDisplayLabel.push(time2);
                    }
                  }

                  return infoDisplayLabel.join(", ");
                },
                driverId: groupItem.driverId,
                iconId: groupItem.iconId,
                // get geofenceList() {  },
                get driverName() {
                  return that.fleetOverviewStoreService.driversMap.get(groupItem.driverId);
                },
                get hidden() {
                  return that.filterSelection.get(that.calculatedDeviceState.get(this.id)?.id ?? 0) === true;
                },
              });

              if (groupItem.driverId) {
                let previousValue = that.fleetOverviewStoreService.driverAssetsMap.get(groupItem.driverId);

                // If not exists in list
                if (!(previousValue?.indexOf(groupItem.assetName) > -1)) {
                  previousValue = (previousValue ? previousValue + ", " : "") + groupItem.assetName;
                  this.fleetOverviewStoreService.driverAssetsMap.set(groupItem.driverId, previousValue);
                }
              }
            }

            this.assetGroups.set(assetGroup.id, {
              _order: assetGroupName?.trim(),
              filterClose: false,
              name: assetGroupName,
              items: groupItems,
              open: false,
            });
          }

          this.cd.detectChanges();
        });
      },
      (error) => {
        console.log(error);
        this.cd.detach();
      }
    );

    if (this.route.children.length > 0) {
      this.children = true;

      this.sub = this.route.children[0].params.subscribe((params) => {
        this.id = params["id"];
        this.showId = this.id ? this.id.toString().substring(0, 4) : "";
        this.cd.detectChanges();
      });
    }
  }

  changeMode(updatedMode: FleetOverviewModeCase) {
    // Update Assets always on mode change
    this.fleetOverviewStoreService.fleetOverviewMode = updatedMode;
    this.fleetOverviewStoreService.hiddenAssets.next(this.hiddenAssets);

    this.mode = updatedMode;

    switch (updatedMode) {
      case "History":
        this.getHistory();
        break;
      case "Live":
        this.getLive();
        break;
      default:
        break;
    }

    this.cd.detectChanges();
  }

  changeTab(updatedTab: string) {
    this.tab = updatedTab;

    switch (this.tab) {
      case "Assets":
        // this.getHistory();
        break;
      default:
        break;
    }

    this.cd.detectChanges();
  }

  getLive() {
    this.selectedLiveGroup = undefined;
    this.liveHidden = new Map();

    for (const [_, value] of this.assetGroups) {
      for (const item of value.items) {
        this.liveHidden.set(item.id, true);
      }
    }

    this.live = new Map();

    if (this.tab === "Assets") {
      for (const [id, predicate] of this.assetGroupSelection) {
        if (predicate === true) {
          const assetGroup = this.assetGroups.get(id);

          if (assetGroup === undefined) {
            continue;
          }

          const filteredItems = assetGroup.items.filter((value) => {
            for (const [deviceId, item] of this.deviceSelection) {
              if (item.checked === true) {
                if (value.id === deviceId) {
                  this.liveHidden.delete(value.id);
                  return true;
                }
              }
            }

            return false;
          });

          this.live.set(id, {
            _order: assetGroup._order,
            filterClose: false,
            name: assetGroup.name,
            items: filteredItems,
          });
        }
      }
    } else if (this.tab === "Drivers") {
      for (const [id, assetGroup] of this.assetGroups) {
        const filteredItems = assetGroup.items.filter((value) => {
          for (const [driverId, item] of this.driverSelection) {
            if (item.checked === true) {
              if (value.driverId === driverId) {
                this.liveHidden.delete(value.id);
                return true;
              }
            }
          }

          return false;
        });

        if (filteredItems.length === 0) {
          continue;
        }

        this.live.set(id, {
          _order: assetGroup._order,
          filterClose: false,
          name: assetGroup.name,
          items: filteredItems,
        });
      }
    } else if (this.tab === "Geofences") {
      this.filter = "";

      const geofencesWithAssets = new Map<number, Map<number, any>>();

      for (const [id, assetGroup] of this.assetGroups) {
        const filteredItems = assetGroup.items.filter((value) => {
          let isActive = false;
          for (const [geofenceId, item] of this.geofenceSelection) {
            if (item.checked === true) {
              if (this.insideGeofences.get(geofenceId.toString())?.includes(value.id.toString())) {
                const list = geofencesWithAssets.get(geofenceId) ?? new Map();
                list.set(value.id, value);

                geofencesWithAssets.set(geofenceId, list);
                this.liveHidden.delete(value.id);
                isActive = true;
              }
            }
          }

          return isActive;
        });

        if (filteredItems.length === 0) {
          continue;
        }

        // this.live.set(id, { _order: assetGroup._order, filterClose: false, name: assetGroup.name, items: filteredItems });
      }

      for (const [geofenceId, assets] of geofencesWithAssets) {
        const geofenceName = this.fleetOverviewStoreService.geofencesMap.get(geofenceId);

        if (geofenceName === undefined) {
          continue;
        }

        this.live.set(geofenceId, {
          _order: geofenceName,
          filterClose: false,
          name: geofenceName,
          items: Array.from(assets.values()),
        });
      }
    }

    if (this.live.size === 1) {
      const [liveKey] = this.live.keys();
      this.selectedLiveGroup = liveKey;
    }

    this.fleetOverviewStoreService.hiddenAssets.next(this.liveHidden);
  }

  getHistory() {
    this.selectedHistoryDevice = undefined;
    this.history = new Map();

    this.liveHidden = new Map();

    for (const [_, value] of this.assetGroups) {
      for (const item of value.items) {
        this.liveHidden.set(item.id, true);
      }
    }

    this.fleetOverviewStoreService.hiddenAssets.next(this.liveHidden);

    if (this.tab === "Assets") {
      for (const [id, item] of this.deviceSelection) {
        if (item.checked === true) {
          this.history.set(`${EntityType[EntityType.Asset]}_${id}`, {
            id,
            name: item.name,
            iconId: item.iconId,
            loading: true,
            trips: [],
          });
        }
      }
    } else if (this.tab === "Drivers") {
      for (const [id, item] of this.driverSelection) {
        if (item.checked === true) {
          this.history.set(`${EntityType[EntityType.Driver]}_${id}`, {
            id,
            name: item.name,
            loading: true,
            trips: [],
          });
        }
      }
    } else if (this.tab === "Geofences") {
      for (const [id, item] of this.geofenceSelection) {
        if (item.checked === true) {
          this.history.set(`${EntityType[EntityType.Geofence]}_${id}`, {
            id,
            name: item.name,
            loading: true,
            episodes: [],
          });
        }
      }
    }

    if (this.history.size === 1) {
      const [key, value] = this.history.entries().next().value;
      this.activateHistoryToggle(key, value.id);
    }
  }

  activateHistoryToggle(updatedSelectedDevice, id) {
    if (this.selectedHistoryDevice === updatedSelectedDevice) {
      this.selectedHistoryDevice = undefined;
      this.cd.detectChanges();
      return;
    }

    this.selectedHistoryDevice = updatedSelectedDevice;

    const tripsForAsset = this.history.get(updatedSelectedDevice);
    if (tripsForAsset === undefined || tripsForAsset.trips?.length > 0 || tripsForAsset.episodes?.length > 0) {
      this.cd.detectChanges();
      return;
    }

    // If Geofence state
    if (updatedSelectedDevice.startsWith(EntityType[EntityType.Geofence])) {
      console.log("getting geofence states");

      this.tripService
        .getGeofenceEpisodes(
          tripsForAsset.id,
          Moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf("day"),
          Moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf("day")
        )
        .subscribe(
          (geofenceEpisodes) => {
            this.currentDate = null;

            (<any>geofenceEpisodes).sort((a, b) => new Date(b.dateTime).getTime() - new Date(a.dateTime).getTime());

            this.history.set(updatedSelectedDevice, {
              id: tripsForAsset.id,
              name: tripsForAsset.name,
              iconId: tripsForAsset.iconId,
              loading: false,
              episodes: geofenceEpisodes,
            });

            this.cd.detectChanges();
          },
          (error) => {
            // this.loadingTrips = false;
            // this.error = error;
            this.cd.detectChanges();
          }
        );

      this.cd.detectChanges();
      return;
    }

    let assetId = id;
    let driverId: string;

    let tripsMethod = this.tripService.getTripsWithGapEpisodes.bind(this.tripService);
    if (updatedSelectedDevice.startsWith(EntityType[EntityType.Driver])) {
      tripsMethod = this.tripService.getTripsWithGapEpisodes.bind(this.tripService);

      driverId = id;
      assetId = null;
    }

    this.cd.detectChanges();

    tripsMethod(
      assetId,
      driverId,
      Moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf("day"),
      Moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf("day")
    ).subscribe(
      (trips) => {
        this.currentDate = null;

        (<any>trips).sort((a, b) => new Date(b.beginDateTime).getTime() - new Date(a.beginDateTime).getTime());

        this.history.set(updatedSelectedDevice, {
          id: tripsForAsset.id,
          name: tripsForAsset.name,
          iconId: tripsForAsset.iconId,
          loading: false,
          trips,
        });

        this.cd.detectChanges();
      },
      (error) => {
        // this.loadingTrips = false;
        // this.error = error;
        this.cd.detectChanges();
      }
    );
  }

  valueAscOrder = (
    a: KeyValue<number, { [id: string]: string }>,
    b: KeyValue<number, { [id: string]: string }>
  ): number => {
    return a.value._order.localeCompare(b.value._order);
  };

  selectAsset(device) {
    this.selectedDevice = device.name;

    this.cd.detectChanges();

    window.dispatchEvent(new CustomEvent("centerMap", { detail: device.id, bubbles: true }));
  }

  triggerCheckbox(event, item) {
    this.selectedDevice = item.name;
    this.deviceSelection.set(item.id, {
      checked: event,
      name: item.name,
      iconId: item.iconId,
    });

    if (event === true) {
      window.dispatchEvent(new CustomEvent("centerMap", { detail: item.id, bubbles: true }));
    }
  }

  openGroup(group) {
    if (!(group.value.items?.length > 0)) {
      return;
    }

    if (this.filter.length > 0) {
      group.value.filterClose = group.value.filterClose !== true;
      group.value.open = false;

      this.cd.detectChanges();
      return;
    }

    group.value.open = group.value.open !== true;
    this.cd.detectChanges();
  }

  openStop(trip, ident, entity) {
    if (trip.tripMethod === 5) {
      return;
    }

    trip.isOpen = !trip.isOpen;

    if (trip.episodes !== undefined) {
      trip.loading = false;

      this.cd.detectChanges();
      return;
    }

    trip.loading = true;
    this.cd.detectChanges();

    let episodesMethod = this.tripService.getEpisodes(entity.value.id, trip.beginDateTime, trip.endDateTime);
    if (entity.key.startsWith(EntityType[EntityType.Driver])) {
      episodesMethod = this.driverService.getEpisodes(
        entity.value.id,
        trip.beginDateTime,
        trip.endDateTime,
        null,
        null
      );
    }

    episodesMethod.subscribe(
      (result) => {
        trip.episodes = result;
        trip.loading = false;
        this.cd.detectChanges();
      },
      (_) => {
        trip.loading = false;
        this.cd.detectChanges();
      }
    );
  }

  openTrip(trip, ident) {
    if (trip.tripMethod === 5) {
      return;
    }

    trip.isOpen = !trip.isOpen;

    if (trip.locations !== undefined) {
      trip.loading = false;

      this.cd.detectChanges();
      return;
    }

    trip.loading = true;
    this.cd.detectChanges();

    this.tripService.getTripDetails(trip.id).subscribe(
      (result) => {
        const locations = result.messages.sort((a, b) => b.timestamp - a.timestamp);
        trip.locations = locations;

        const isOngoing = trip.tripType === 3 || trip.tripType === 4;

        const featureGroup = this.cachedTrips.get(trip.id);

        if (featureGroup !== undefined) {
          this.fleetOverviewStoreService.removeTrip.next([featureGroup]);
        }

        trip.concatenatedEvents = result.concatenatedEvents;

        const device = this.assetGroupSelection.get(trip.deviceId);
        const icon = null; // device.asset?.icon

        const tripFeatureGroup = this.tripService.drawTrip(result, ident, this.colorArray, isOngoing, icon);

        this.cachedTrips.set(trip.id, tripFeatureGroup);

        trip.plotted = true;
        trip.loading = false;

        this.cd.detectChanges();

        this.fleetOverviewStoreService.selectedTrip.next([tripFeatureGroup]);
      },
      (_) => {
        trip.loading = false;
        this.cd.detectChanges();
      }
    );
  }

  activeRoute(routename: string): boolean {
    return this.router.url.indexOf(routename) === 1;
  }

  changeTripVisibility(trip, ident, plotted?) {
    if (!("plotted" in trip)) {
      this.openTrip(trip, ident);
      return;
    }

    if (trip.plotted === plotted) {
      return;
    }

    if (plotted === undefined) {
      trip.plotted = !trip.plotted;
    } else {
      trip.plotted = plotted;
    }

    const featureGroup = this.cachedTrips.get(trip.id);

    if (featureGroup === undefined) {
      return;
    }

    if (trip.plotted === false) {
      this.fleetOverviewStoreService.removeTrip.next([featureGroup]);
    } else {
      this.fleetOverviewStoreService.selectedTrip.next([featureGroup]);
    }

    this.cd.detectChanges();
  }

  renderTrips(trips: any[]) {
    this.currentDate = null;
    return trips;
  }

  isNewDate(trip) {
    const checkDate = Moment(trip.beginDateTime).date();
    if (checkDate === this.currentDate) {
      return false;
    } else {
      this.currentDate = checkDate;
      return true;
    }
  }

  shouldToggleGroup(event, group, itemId, groupSelection, itemSelection) {
    for (const [key, value] of group) {
      if (value.items === undefined || value.items.find((x) => x.id === itemId) === undefined) {
        continue;
      }

      if (event === true) {
        groupSelection.set(key, true);
        continue;
      }

      let anyChecked = false;

      for (const item of value.items) {
        if (itemSelection.get(item.id)?.checked === true) {
          anyChecked = true;
        }
      }

      if (anyChecked === false) {
        groupSelection.set(key, false);
      }
    }
  }

  selectAssetGroup(group, items, event) {
    console.log("Selecting group: " + group);

    this.assetGroupSelection.set(group.key, event);

    for (const item of items) {
      this.deviceSelection.set(item.id, {
        checked: event,
        name: item.name,
        iconId: item.iconId,
      });
      this.shouldToggleGroup(event, this.assetGroups, item.id, this.assetGroupSelection, this.deviceSelection);
    }

    this.cd.detectChanges();
  }

  checkForUpdates() {
    this.cd.detectChanges();
  }

  selectDriverGroup(group, items, event) {
    console.log("Selecting group: " + group);

    this.driverGroupSelection.set(group.key, event);

    for (const item of items) {
      this.driverSelection.set(item.id, { checked: event, name: item.name });
      this.shouldToggleGroup(event, this.driverGroups, item.id, this.driverGroupSelection, this.driverSelection);
    }

    this.cd.detectChanges();
  }

  selectGeofenceGroup(group, items, event) {
    console.log("Selecting group: " + group);

    this.geofenceGroupSelection.set(group.key, event);

    for (const item of items) {
      this.geofenceSelection.set(item.id, { checked: event, name: item.name });
      this.shouldToggleGroup(event, this.geofenceGroups, item.id, this.geofenceGroupSelection, this.geofenceSelection);
    }

    this.cd.detectChanges();
  }

  watchGroupSelected(collection: Map<number, any>, items: any[]) {
    for (const item of items) {
      if (collection.get(item.id) === undefined || collection.get(item.id).checked === false) {
        return false;
      }
    }

    return true;
  }

  watchGroupVisibility(collection: Map<number, boolean>, items: any[]) {
    for (const item of items) {
      if (collection.get(item.id) === true) {
        return false;
      }
    }

    return true;
  }

  changeGroupVisibility(collection: Map<number, boolean>, state: boolean, items: any[]) {
    for (const item of items) {
      collection.set(item.id, !state);
    }

    this.fleetOverviewStoreService.hiddenAssets.next(collection);
    this.cd.detectChanges();
  }

  changeGeofenceGroupVisibility(collection: Map<number, boolean>, state: boolean, items: any[]) {
    for (const item of items) {
      collection.set(item.id, !state);
    }

    this.fleetOverviewStoreService.hiddenGeofences.next(collection);
    this.cd.detectChanges();
  }

  changeVisibility(collection: Map<number, boolean>, id: number) {
    collection.set(id, !collection.get(id));

    this.fleetOverviewStoreService.hiddenAssets.next(collection);
    this.cd.detectChanges();
  }

  changeGeofenceVisibility(collection: Map<number, boolean>, id: number) {
    collection.set(id, !collection.get(id));

    this.fleetOverviewStoreService.hiddenGeofences.next(collection);
    this.cd.detectChanges();
  }

  hasAnySelected(selected: Map<any, any>[]) {
    for (const map of selected) {
      for (const [_, predicate] of map) {
        if (predicate !== true && predicate?.checked !== true) {
          continue;
        }

        return true;
      }
    }

    return false;
  }

  dateChanged(event) {
    this.selectedHistoryDevice = undefined;
    this.cd.detectChanges();

    console.log("date", event);
    this.getHistory();
  }

  updateCheckbox($event, driverSelection, driver) {
    driverSelection.set(driver.id, { checked: $event, name: driver.name });
    this.cd.detectChanges();
  }

  selectGeofence(event, id) {
    if (event === true) {
      this.fleetOverviewStoreService.selectGeofence.next(id);
    }
  }

  filterMarkers() {
    if (this.tab !== "Assets") {
      this.cd.detectChanges();
      return;
    }

    this.fleetOverviewStoreService.searchFilter.next(this.filter);
    this.cd.detectChanges();
  }

  openLiveTab(liveGroup) {
    if (this.filter.length > 0) {
      liveGroup.value.filterClose = liveGroup.value.filterClose !== true;
      // liveGroup.value.open = false;
      this.selectedLiveGroup = undefined;
      this.cd.detectChanges();
      return;
    }

    this.selectedLiveGroup = this.selectedLiveGroup !== liveGroup.key && liveGroup.key;
    this.cd.detectChanges();
  }

  actualRound(value, decimals) {
    return roundAsString(value, decimals);
  }

  getFleetOverviewMode() {
    return this.fleetOverviewStoreService.fleetOverviewMode$;
  }

  extendFilterMap<T>(map: T): T {
    this.currentMarker = 0;
    return map;
  }

  increaseViewCount() {
    this.currentMarker += 1;
    return true;
  }

  toggleAssetStatus() {
    this.filterToggle = this.filterToggle !== true;
    this.cd.detectChanges();
  }

  getDeviceCount(index: number) {
    return window["deviceCounts"][index % 6];
  }

  get filterSelection() {
    return this.fleetOverviewStoreService.stateFilter.getValue();
  }

  get geofenceGroups() {
    return this.fleetOverviewStoreService.geofencesGroupsMap;
  }

  get insideGeofences() {
    return this.fleetOverviewStoreService.insideGeofencesMap;
  }

  emptyGroups(groups: Map<any, any>) {
    return !Array.from(groups.values()).find((x) => x.items?.length > 0);
  }

  visibilityAssetStatus(event, key) {
    this.filterSelection.set(key, event.target.checked !== true);
    this.fleetOverviewStoreService.stateFilter.next(this.filterSelection);

    let reset = "NULL";
    [reset, this.filter] = [this.filter, reset];
    this.cd.detectChanges();

    [reset, this.filter] = [this.filter, reset];
    this.cd.detectChanges();
  }

  toggleSidebar() {
    jQuery("body").removeClass("mini-navbar");

    this.sidebarHidden = this.sidebarHidden === false;
    this.cd.detectChanges();
  }

  log(e) {
    console.log(e);
  }

  // Play trips
  playTrip(trip) {
    this.fleetOverviewStoreService.playTrip.next([trip.assetId, trip.locations]);
  }

  // display episodes

  clearLocation() {
    const mapReferece = this.mapService.leafletMapComponent;

    if (this.theMarker) {
      mapReferece.removeLayer(this.theMarker);
    }

    if (this.myMovingMarker) {
      mapReferece.removeLayer(this.myMovingMarker);
    }

    if (this.stopMarker) {
      mapReferece.removeLayer(this.stopMarker);
    }
  }

  displayLocation(asset, location, geofence = null, episode = null) {
    const mapReference = this.mapService.leafletMapComponent;

    const iconPath = getIconPath(asset?.value?.iconId)[1];

    const movingMarkerIcon = L.icon({
      iconUrl: iconPath,
      // className: 'markerPlayTrip',
      iconAnchor: [16, 16],
    });

    // beginLongitude
    this.clearLocation();

    if (location != null && geofence == null) {
      let theEpisodeIcon = null;

      if (episode) {
        theEpisodeIcon = L["StatusMarker"].icon({
          // iconUrl: iconPath,
          icon: episode.icon,
          markerColor: episode.markerColor,
          rotate: 0,
          shape: "circle",
          prefix: "fas",
        });
      }

      this.theMarker = marker(location, {
        icon: episode ? theEpisodeIcon : movingMarkerIcon,
      }).addTo(mapReference);
      mapReference.setView(location, 15);
    }

    if (geofence != null) {
      var theGeofenceId = geofence;
      // Draw geofence
      this.selectGeofence(true, theGeofenceId);
    }
  }

  // Destroy component
  ngOnDestroy() {
    this.fleetOverviewStoreService.fleetOverviewState = "Initialize";

    if (this.children) {
      this.sub.unsubscribe();
    }

    if (this.filterDebounce) {
      this.filterDebounce.unsubscribe();
    }

    this.changeMode("Overview");
  }
}
