import {
  Component,
  AfterViewInit,
  Input,
  ElementRef,
  ViewChild,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from "@angular/core";

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { AssetTypeService } from "app/services/asset/assetTypes.service";

const noop = () => {};

@Component({
  selector: "fh-asset-type-input",
  templateUrl: "./assetTypeSelector.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: AssetTypeInputComponent }],
})
export class AssetTypeInputComponent implements OnChanges, OnInit, AfterViewInit, ControlValueAccessor {
  @Input() placeholder: string;
  @Input() clearable = true;
  @Input() selectedAccountId;
  @Input() autoSelect = true;
  @Input() readonly = false;
  @Input() multiple = false;
  @Input() tableInline = true;

  @Output() onChanged = new EventEmitter();

  assetTypes = [];

  loadingAssetTypes = false;

  @ViewChild("input") el: ElementRef;

  private _value: string;
  private _onChange: (_: any) => void = noop;

  get value(): any {
    return this._value;
  }

  set value(v: any) {
    if (v !== this._value) {
      this._value = v;
      this._onChange(v);
      this.cd.markForCheck();
    }
  }

  constructor(private assetTypeService: AssetTypeService, private cd: ChangeDetectorRef) {}

  assetTypeChanged() {
    this.onChanged.emit(this.value);
    this.loadingAssetTypes = false;
    this.cd.markForCheck();
  }

  ngOnChanges() {
    setTimeout(() => {
      // Get accounts again
      this.loadAssetTypes();
    }, 0);
  }

  loadAssetTypes() {
    this.assetTypes = [];
    this.cd.markForCheck();

    if (this.selectedAccountId) {
      this.loadingAssetTypes = true;
      this.cd.markForCheck();

      if (this.selectedAccountId && this.selectedAccountId !== 0) {
        this.assetTypeService.getAssetTypes(this.selectedAccountId).subscribe((result) => {
          result = result.sort((a, b) => (a.name < b.name ? -1 : 1));

          this.assetTypes = result;

          // Autoselect
          if (!this.readonly && this.assetTypes.length === 1 && this.autoSelect) {
            console.log("Setting default to " + this.assetTypes[0].id);
            this.value = this.assetTypes[0].id;
            this.cd.markForCheck();
          }

          this.loadingAssetTypes = false;
          this.cd.markForCheck();
        });
      } else {
        this.loadingAssetTypes = false;
        this.cd.markForCheck();
      }
    }
  }

  ngOnInit() {}

  ngAfterViewInit() {
    const __this = this;
  }

  writeValue(value: any) {
    this._value = value;
    this.cd.markForCheck();
  }

  registerOnChange(fn: (value: any) => void) {
    this._onChange = fn;
    this.cd.markForCheck();
  }

  registerOnTouched(fn: any) {
    this.cd.markForCheck();
  }
}
