export class ReportSubscription {
  public accountId: any;
  public name: string;

  public selectedReportCategory: number;
  public selectedReportType: number;
  public subscriptionType = 1;

  public reportingInterval: string;
  public start: number;
  public end: number;

  public selectedAssetGroups: any[] = [];
  public selectedAssets: any[] = [];
  public selectedDriverGroups: any[] = [];
  public selectedDrivers: any[] = [];
  public selectedGeofenceGroups: any[] = [];
  public selectedGeofences: any[] = [];
  public selectedProjects: any[] = [];

  public template: ReportTemplate = new ReportTemplate();

  public notificationConfiguration: NotificationConfiguration = new NotificationConfiguration();

  public parameterConfiguration = {
    filterSmallTrips: null,
    filterGrouping: 0,
    filterDateGrouping: 0,
  };
}

export class ReportTemplate {
  public id;
  public name;
  public columnConfiguration = [];
  public groupByIndex = -1;
  public groupByType = 1;
  public orderByIndex = -1;
  public orderByAscending = false;
  public hideGroupByColumn = false;
  public orientation = 0;
}

export class NotificationConfiguration {
  public createShareLink = false;
  public userContactGroupRecipients: any[] = [];
  public userContactRecipients: any[] = [];
  public emailRecipients: any[] = [];
  public subject = "A new Report is ready";
  public body = `Dear {{ContactName}},

We have shared new report(s): {{ReportName}}`;
}

export class RerunReportSettings {
  public reportSubscriptionId: string;
  public start: number;
  public end: number;
  public includeExecutedSql = false;
}

// export class ReportExecution {
//     id: string; // Guid
//     reportSubscriptionId: string; // Guid
//     periodStart: Date;
//     periodEnd: Date;
//     name: string;
//     selectedReportType: number;
//     executionStartedTimestamp: Date;
//     executionCompletedTimestamp: Date;
//     reportSubscriptionType: number;
//     assetSelection: AssetSelection;
//     driverSelection: DriverSelection;
//     geofenceSelection: GeofenceSelection;
//     projectSelection: ProjectSelection;
//     reportingInterval: string;
//     reportingPeriod: string;
//     notificationConfiguration?: NotificationConfiguration;
//     parameterConfiguration?: ReportParameterConfiguration;
//     template?: ReportTemplate;
//     subscriptionCreatedDate: Date;
//     subscriptionModifiedDate: Date;
//     accountId?: number;
//     accountIdentifier?: string;
//     userName: string;
//     companyName: string;
//     resellerId?: number; // Optional
//     resellerDescription: string;
//     isSuccessful: boolean;
//     isCompleted: boolean;
//     resultMessage?: string; // Optional
//     executionDurationInMilliseconds: number;
//     executionResultBytes: number;
//     executionResultRecordCount: number;
//     userType?: number; // Optional
//     userId?: number; // Optional
//     recipients: string;
//     executedSql?: string; // Optional
//     notifyOnCompletion: boolean;
//     lastVisitedTimestamp?: Date; // Optional
//     visitCount: number;
// }
