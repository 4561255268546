<div style="float: left; width: calc(100% - 40px)">
    <input
        type="password"
        class="form-control table-inline"
        placeholder="{{ placeholder }}"
        #input
        [(ngModel)]="value"
    />
</div>
<div
    title="Toggle password visible"
    class="table-inline input-group-append"
    style="float: left; width: 40px; margin-top: -4px; margin-bottom: -6px"
    #toggler
>
    <i class="fa fa-fw fa-eye"></i>
</div>
