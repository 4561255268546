import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ControlContainer, NgForm } from "@angular/forms";
import { FormMode } from "app/common/enums";
import { AssetType } from "app/models/assetType.model";
import { AuthenticationService } from "app/services/authentication/authentication.service";

@Component({
  selector: "fh-asset-type-details",
  templateUrl: "assetTypeDetails.component.html",
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class AssetTypeDetailsComponent implements OnInit {
  @Input() assetType: AssetType;
  @Output() onAccountChanged = new EventEmitter();
  @Input() formMode: any = FormMode.read;

  selectedResellerId;
  permissions: {};

  constructor(private cd: ChangeDetectorRef, private authenticationService: AuthenticationService) {
    this.permissions = this.authenticationService.permissions;
  }

  ngOnInit(): void {}

  resellerChanged(resellerId) {
    this.assetType.accountId = null;
    this.selectedResellerId = resellerId;
  }

  accountChanged(accountId) {
    this.onAccountChanged.emit(this.assetType.accountId);
  }
}
