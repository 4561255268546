export class Job {
  public accountId;
  public accountName;
  public nextRun;
  public lastRuntime;
  public isRunning;
  public lastStatus;
}

export class JobHistoryLog {
  public startJob;
  public endJob;
  public fromDate;
  public toDate;
  public accountId;
  public accountName;
  public jobId;
  public totalDeviceCount: number;
  public devicesProcessedCount: number;
  public devicesSkippedCount: number;
  public devicesErrorCount: number;
  public messages: number;
  public issues: number;
  public status: string;
  public duration: number;
}
