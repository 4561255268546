import { Injectable } from "@angular/core";
import { StorageType } from "app/common/enums";
import { StorageHelper } from "app/common/storagehelper";
import { DeviceTypeService } from "app/services/devicetypes/devicetypes.service";
import { AccountService } from "../account/account.service";

import { AuthenticationService } from "../authentication/authentication.service";
import { CountriesService } from "../countries/countries.service";
import { DeviceService } from "../device/device.service";
import { ResellerService } from "../resellers/resellers.service";
import { UserRoleService } from "../users/userRole.service";

@Injectable()
export class CacheService {
  constructor(
    private resellerService: ResellerService,
    private countriesService: CountriesService,
    private userRoleService: UserRoleService,
    private accountService: AccountService,
    private authenticationService: AuthenticationService,
    private deviceService: DeviceService,
    private deviceTypeService: DeviceTypeService,
    private storageHelper: StorageHelper
  ) {
    this.authenticationService.onLoggedIn.subscribe((_) => {
      this.clearCache();
      this.storageHelper.loadStoreState(StorageType.DatabasePrefetch, "", "").subscribe();
    });
  }

  clearCache() {
    console.log("Reset cache.");
    this.deviceTypeService.deviceTypes = [];
    this.deviceService.devices = [];
    this.accountService.accounts = [];
    this.countriesService.countries = [];
    this.userRoleService.userRoles = [];
    this.resellerService.resellers = [];
  }
}
