import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpHandler, HttpRequest, HTTP_INTERCEPTORS, HttpErrorResponse } from "@angular/common/http";

import { Observable, throwError } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";

import { AuthenticationService } from "./authentication.service";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  private isRefreshing = false;

  constructor(private authService: AuthenticationService, private translate: TranslateService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    req = req.clone({
      withCredentials: true,
    });

    return next.handle(req).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && !req.url.includes("FromRefreshToken") && error.status === 401) {
          return this.handle401Error(req, next);
        }

        if (error.status === 401 || error.status === 400 || error.status === 500 || error.status === 409) {
          error.statusText = this.translate.instant("general.somethingWentWrong");
        }

        return throwError(() => error);
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    console.log("Handling intercepting refresh");
    if (!this.isRefreshing) {
      this.isRefreshing = true;

      var isValid = this.authService.isTokenValid();
      var isShareTokenValid = false; //this.authService.isShareTokenValid();

      if (isValid == false && isShareTokenValid == false) {
        console.log("Token not valid anymore.. getting refresh");

        return this.authService.IsAuthenticated(null).pipe(
          switchMap(() => {
            this.isRefreshing = false;

            return next.handle(request);
          }),
          catchError((error) => {
            this.isRefreshing = false;

            return throwError(() => error);
          })
        );
      }
    }

    return next.handle(request);
  }
}

export const httpInterceptorProviders = [{ provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true }];
