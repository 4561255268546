import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { WebhookEndpointService } from "app/services/webhookendpoints/webhookEndpoint.service";

const noop = () => {};

@Component({
  selector: "fh-webhook-endpoint-input",
  templateUrl: "./webhookEndpointSelector.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: WebhookEndpointInputComponent }],
})
export class WebhookEndpointInputComponent implements OnChanges, OnInit, AfterViewInit, ControlValueAccessor {
  @Input() placeholder: string;
  @Input() clearable = false;
  @Input() selectedAccountId;
  @Input() autoSelect = false;
  @Input() readonly = false;
  @Input() multiple = false;

  @Output() onChanged = new EventEmitter();

  webhookEndpoints = [];

  loadingWebhookEndpoints = false;

  @ViewChild("input") el: ElementRef;

  private _value: string;
  private _onChange: (_: any) => void = noop;

  get value(): any {
    return this._value;
  }

  set value(v: any) {
    if (v !== this._value) {
      this._value = v;
      this._onChange(v);
      this.cd.markForCheck();
    }
  }

  constructor(private webhookEndpointService: WebhookEndpointService, private cd: ChangeDetectorRef) {}

  webhookEndpointChanged() {
    this.onChanged.emit(this.value);
    this.cd.markForCheck();
  }

  ngOnChanges() {
    setTimeout(() => {
      // Get accounts again
      this.loadWebhookEndpoints();
      this.cd.markForCheck();
    }, 0);
  }

  loadWebhookEndpoints() {
    this.webhookEndpoints = [];
    this.cd.markForCheck();

    if (this.selectedAccountId) {
      this.loadingWebhookEndpoints = true;
      this.cd.markForCheck();

      if (this.selectedAccountId && this.selectedAccountId !== 0) {
        this.webhookEndpointService
          .getWebhookEndpointsByAccount(this.selectedAccountId)
          .subscribe((webhookEndpoints) => {
            this.webhookEndpoints = webhookEndpoints;
            this.loadingWebhookEndpoints = false;
            this.cd.markForCheck();
          });
      } else {
        this.loadingWebhookEndpoints = false;
        this.cd.markForCheck();
      }
    }
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    const __this = this;
  }

  writeValue(value: any) {
    this._value = value;
    this.cd.markForCheck();
  }

  registerOnChange(fn: (value: any) => void) {
    this._onChange = fn;
    this.cd.markForCheck();
  }

  registerOnTouched(fn: any) {
    this.cd.markForCheck();
  }
}
