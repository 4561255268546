export class AssetProject {
  public startDate: any;
  public endDate: any;
  public type: any;
  public id: any;
}

export class Project {
  public id: any;

  public name: string;
  public description: any;

  public assetProject: AssetProject;

  public companyName: any;
  public accountId: any;
  public deviceId: any;
  public assetName: any;
  public projectAssignmentStart: any;
  public projectAssignmentEnd: any;

  public resellerId: any;
  public resellerDescription: any;
  public assets: any;

  public itemCount: any;
}
