<fh-wizard [(step)]="step" (finish)="onCheckOutFinish()">
    <fh-wizard-step title="general.getLocations" icon="fa-user" (open)="initForm()">
        <div class="wizard-content">{{'general.resetDeviceWarning' | translate:{ value: gridSelection.length } }}</div>
    </fh-wizard-step>
    <fh-wizard-step
        title="{{ 'general.finish' | translate}}"
        icon="fa-save"
        (open)="initFinish()"
        icon="fa-car-bus"
        [validate]="assetForm"
        [padding]="false"
    >
        <div class="wizard-content">
            <form [formGroup]="assetForm" style="width: 100%; border-top: 0px">
                <table style="width: 100%" class="table table-fixed nowrap dtr-inline no-footer">
                    <tbody>
                        <tr>
                            <td style="width: 100%; border-bottom: 0px !important">
                                <div class="fa_checkbox">
                                    <input id="checkbox" type="checkbox" formControlName="checkbox" required />
                                    <label for="checkbox"> {{'general.areyousure' | translate }} </label>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </div>
    </fh-wizard-step>
    <fh-wizard-step title="Remarks" icon="fa-file-pdf">
        <div class="wizard-content" style="padding-bottom: 10px">{{ 'wizard.optionalComment' | translate }}</div>
        <div class="wizard-content">
            <textarea
                class="form-control noresize stickypad"
                style="height: 100px; width: 100%; resize: none"
                placeholder="{{ 'placeholder.comments' | translate}}"
                [(ngModel)]="logItem"
            ></textarea>
        </div>
    </fh-wizard-step>
    <fh-wizard-step title="Summary" icon="fa-summary-pdf">
        <div class="wizard-content" style="padding-bottom: 10px">
            {{ 'wizard.summary' | translate:{ count: gridSelection.length } }}
        </div>
    </fh-wizard-step>
</fh-wizard>
