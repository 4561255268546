<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/"> <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Devices/Overview">{{'general.devices' | translate }}</a>
                </li>
                <li class="breadcrumb-item active">{{'general.cards' | translate }}</li>
            </ol>
        </nav>
    </div>
    <fh-device-navigation></fh-device-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">
        <div class="row">
            <div class="col-lg-12">
                <fh-error [error]="error" [success]="success"></fh-error>

                <div class="ibox">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-credit-card-front"></i> {{'general.cards' | translate }}</h5>
                        <span class="float-right" [tooltip]="'Export cards as list'">
                            <i
                                (click)="this.devices.length > 0 ? export() : null"
                                class="fas fa-fw"
                                [ngClass]="this.devices.length > 0 ? 'fa-file-download' : 'fa-ban'"
                            >
                            </i>
                        </span>
                    </div>
                    <div class="ibox-content slick_toppadding" style="position: relative" *ngIf="languageLoaded">
                        <ngx-loading [show]="loading"></ngx-loading>
                        <div id="dynamicFilters" class="flex-container">
                            <div class="col-lg-4">
                                <div class="form-group" style="margin-bottom: 6px">
                                    <fh-reseller-input
                                        name="reseller"
                                        #resellerVar="ngModel"
                                        (onChanged)="resellerChanged($event)"
                                        required
                                        [tableInline]="false"
                                        placeholder="{{ 'general.chooseReseller' | translate}}"
                                        [(ngModel)]="selectedResellerId"
                                    >
                                    </fh-reseller-input>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group" style="margin-bottom: 6px">
                                    <fh-account-input
                                        name="account"
                                        #accountVar="ngModel"
                                        (onChanged)="accountChanged($event)"
                                        required
                                        [activeOnly]="true"
                                        [selectedResellerId]="selectedResellerId"
                                        [tableInline]="false"
                                        placeholder="{{ (selectedResellerId ? 'general.chooseAccount' : 'general.firstChooseReseller') | translate}}"
                                        [(ngModel)]="selectedAccountId"
                                    >
                                    </fh-account-input>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group" style="margin-bottom: 6px">
                                    <fh-asset-group-input
                                        name="assetGroups"
                                        #assetGroupVar="ngModel"
                                        required
                                        [selectedAccountId]="selectedAccountId"
                                        [tableInline]="false"
                                        (onChanged)="filterDevices()"
                                        placeholder="{{ (selectedAccountId? 'general.chooseAssetGroups' : 'general.firstChooseAccount') | translate}}"
                                        [(ngModel)]="selectedAssetGroups"
                                    >
                                    </fh-asset-group-input>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group input-group" style="margin-bottom: 6px">
                                    <span class="input-group-prepend">
                                        <i class="fa fa-fw fa-filter"></i>
                                    </span>
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Filter on name/imei ect"
                                        (ngModelChange)="p.setCurrent(0)"
                                        [(ngModel)]="filter"
                                    />
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group input-group" style="margin-bottom: 6px">
                                    <span
                                        class="input-group-prepend hand"
                                        title="Sort direction"
                                        (click)="orderByReversed = !orderByReversed"
                                    >
                                        <i
                                            class="fa fa-fw"
                                            [ngClass]="orderByReversed == true ? 'fa-sort-amount-down' : 'fa-sort-amount-up'"
                                        ></i>
                                    </span>
                                    <ng-select
                                        [(ngModel)]="orderBy"
                                        class="form-control"
                                        name="itemsPerPage"
                                        required
                                        [clearable]="false"
                                        style="width: 50%; float: left"
                                        style="width: 100%"
                                    >
                                        <ng-option value="lastCommunication"
                                            >{{'general.lastCommunication' | translate }}
                                        </ng-option>
                                        <ng-option value="assetSearchName">{{'general.name' | translate }}</ng-option>
                                    </ng-select>
                                </div>
                            </div>
                            <div class="col-lg-2">
                                <div class="form-group" style="margin-bottom: 6px">
                                    <ng-select
                                        class="form-control"
                                        name="displayEvents"
                                        (change)="filterDevices()"
                                        placeholder="{{ 'general.deviceState' | translate }}"
                                        [(ngModel)]="status"
                                        style="width: 100%"
                                    >
                                        <ng-option [value]="0">
                                            <i class="fa fa-fw fa-map-marker-alt eye-active black"></i>
                                            {{ 'general.deviceState_0' | translate}}
                                        </ng-option>
                                        <ng-option [value]="1">
                                            <i class="fa fa-fw fa-map-marker-alt eye-active green"></i>
                                            {{ 'general.deviceState_1' | translate}}
                                        </ng-option>
                                        <ng-option [value]="2">
                                            <i class="fa fa-fw fa-map-marker-alt eye-active red"></i>
                                            {{ 'general.deviceState_2' | translate}}
                                        </ng-option>
                                        <ng-option [value]="3">
                                            <i class="fa fa-fw fa-map-marker-alt eye-active orange"></i>
                                            {{ 'general.deviceState_3' | translate}}
                                        </ng-option>
                                        <ng-option [value]="4">
                                            <i class="fa fa-fw fa-map-marker-alt eye-active blue"></i>
                                            {{ 'general.deviceState_4' | translate}}
                                        </ng-option>
                                        <ng-option [value]="5">
                                            <i class="fa fa-fw fa-map-marker-alt eye-active black"></i>
                                            {{ 'general.deviceState_5' | translate}}
                                        </ng-option>
                                        <ng-option [value]="6">
                                            <i class="fa fa-fw fa-map-marker-alt eye-active violet"></i>
                                            {{ 'general.deviceState_6' | translate}}
                                        </ng-option>
                                    </ng-select>
                                </div>
                            </div>
                            <div class="col-lg-2">
                                <div class="form-group input-group" style="margin-bottom: 6px">
                                    <span class="input-group-prepend" title="Items per page">
                                        <i class="fa fa-fw fa-stream"></i>
                                    </span>
                                    <ng-select
                                        [(ngModel)]="itemsPerPage"
                                        class="form-control"
                                        name="itemsPerPage"
                                        required
                                        [clearable]="false"
                                        style="width: 100%"
                                    >
                                        <ng-option [value]="10">10</ng-option>
                                        <ng-option [value]="17">17</ng-option>
                                        <ng-option [value]="25">25</ng-option>
                                        <ng-option [value]="50">50</ng-option>
                                        <ng-option [value]="99999">{{'general.all' | translate }}</ng-option>
                                    </ng-select>
                                </div>
                            </div>
                            <div style="clear: both"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="devices.length == 0">
            <div class="col-lg-12">
                <div class="ibox">
                    <div class="ibox-content" style="position: relative">
                        <ngx-loading [show]="loading"></ngx-loading>
                        <span *ngIf="!selectedAccountId">{{'general.pleaseSelectAccount' | translate }}</span>
                        <span *ngIf="selectedAccountId">{{'general.noDataFound' | translate }}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div
                class="col-lg-6"
                *ngFor="let device of devices | orderBy : orderBy : orderByReversed | stringFilter: filter | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, id: 'first'}; "
            >
                <div class="ibox">
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-car"></i>
                            <a class="secondary link_bolder" href="/#/DeviceDetails/Index/{{device?.id}}"
                                >{{ device.asset.name }} | {{ device.unitId }}</a
                            >
                        </h5>
                    </div>
                    <div class="ibox-content slick" style="position: relative">
                        <table style="width: 100%" class="table table-fixed nowrap dtr-inline no-footer">
                            <tbody>
                                <tr>
                                    <td class="row_opening_small">{{ 'general.companyName' | translate}}</td>
                                    <td class="row_content">
                                        <a
                                            class="secondary link_bolder"
                                            href="/#/AccountDetails/Index/{{device?.accountId}}"
                                            >{{device?.companyName }}</a
                                        >
                                    </td>
                                    <td class="row_opening_small"></td>
                                    <td class="row_content">
                                        <img style="position: absolute" [src]="getIcon(device.asset?.icon)" />
                                    </td>
                                </tr>
                                <tr>
                                    <td class="row_opening_small">{{'general.date' | translate }}</td>
                                    <td class="row_content" title="device.lastCommunication">
                                        {{ device.lastCommunication | amTimeAgo }}
                                    </td>
                                    <td class="row_opening_small"></td>
                                    <td class="row_content"></td>
                                </tr>
                                <tr>
                                    <td class="row_opening_small">{{'general.status' | translate }}</td>
                                    <td class="row_content">
                                        {{ ('general.deviceState_' +
                                        device.deviceState?.calculatedDeviceState?.deviceState ) | translate}}
                                    </td>
                                    <td class="row_opening_small">{{'general.active' | translate }}</td>
                                    <td class="row_content">{{ device.isActive }}</td>
                                </tr>
                                <!-- <tr>
                                    <td class="row_opening">
                                        {{'general.position' | translate }}
                                    </td>
                                    <td class="row_content">
                                        {{ device.lastPosition }}
                                    </td>
                                </tr> -->
                                <tr>
                                    <td class="row_opening_small">{{ 'general.groups' | translate}}</td>
                                    <td class="row_content" colspan="3">
                                        <span *ngFor="let group of device.asset?.assetGroups">
                                            <a
                                                class="btn btn-info btn-grid"
                                                href="/#/AssetGroupDetails/Index/{{group?.id}}"
                                                ><i class="fas fa-fw fa-layer-group"></i> {{group?.name }}</a
                                            >
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="ibox-content slick" style="position: relative">
                        <div class="flex-container">
                            <div class="col-lg-2 col-sm-2" style="padding: 0">
                                <div class="kpi-item-small" [title]="'general.ignitionState' | translate ">
                                    <h3 class="margin-bottom font-bold">
                                        <i
                                            style="opacity: 0.4"
                                            class="fas fa-fw fa-key"
                                            [ngStyle]="{'color': (device?.deviceState?.ignition == null) ? '#ccc' : (device?.deviceState?.ignition?.state ? 'rgba(0,142,67,0.9)' : 'rgba(151,28,36,0.9)') }"
                                        ></i>
                                    </h3>
                                </div>
                            </div>
                            <div class="col-lg-2 col-sm-2" style="padding: 0">
                                <div class="kpi-item-small" [title]="'general.externalPowerState' | translate ">
                                    <h3 class="margin-bottom font-bold">
                                        <i
                                            style="opacity: 0.4"
                                            class="fas fa-fw fa-car-battery"
                                            [ngStyle]="{'color': (device?.deviceState?.externalPower == null) ? '#ccc' : (device?.deviceState?.externalPower?.state ? 'rgba(0,142,67,0.9)' : 'rgba(151,28,36,0.9)') }"
                                        ></i>
                                    </h3>
                                </div>
                            </div>
                            <div class="col-lg-2 col-sm-2" style="padding: 0">
                                <div
                                    class="kpi-item-small"
                                    [title]="'general.inputStateFormatted' | translate:{ value: '1' } "
                                >
                                    <h3 class="margin-bottom font-bold">
                                        <i
                                            style="opacity: 0.4"
                                            class="fas fa-fw fa-arrow-alt-circle-down"
                                            [ngStyle]="{'color': ( device?.deviceState?.input1 == null) ? '#ccc' : (device?.deviceState?.input1?.state ? 'rgba(0,142,67,0.9)' : 'rgba(151,28,36,0.9)') }"
                                        ></i>
                                    </h3>
                                </div>
                            </div>
                            <div class="col-lg-2 col-sm-2" style="padding: 0">
                                <div
                                    class="kpi-item-small"
                                    [title]="'general.inputStateFormatted' | translate:{ value: '2' } "
                                >
                                    <h3 class="margin-bottom font-bold">
                                        <i
                                            style="opacity: 0.4"
                                            class="fas fa-fw fa-arrow-alt-circle-down"
                                            [ngStyle]="{'color': (device?.deviceState?.input2 == null) ? '#ccc' : (device?.deviceState?.input2?.state ? 'rgba(0,142,67,0.9)' : 'rgba(151,28,36,0.9)') }"
                                        ></i>
                                    </h3>
                                </div>
                            </div>
                            <div class="col-lg-2 col-sm-2" style="padding: 0">
                                <div
                                    class="kpi-item-small"
                                    [title]="'general.outputStateFormatted' | translate:{ value: '1' } "
                                >
                                    <h3 class="margin-bottom font-bold">
                                        <i
                                            style="opacity: 0.4"
                                            class="fas fa-fw fa-arrow-alt-circle-up"
                                            [ngStyle]="{'color': (device?.deviceState?.output1 == null) ? '#ccc' : (device?.deviceState?.output1?.state ? 'rgba(0,142,67,0.9)' : 'rgba(151,28,36,0.9)') }"
                                        ></i>
                                    </h3>
                                </div>
                            </div>
                            <div class="col-lg-2 col-sm-2" style="padding: 0">
                                <div
                                    class="kpi-item-small"
                                    [title]="'general.outputStateFormatted' | translate:{ value: '2' } "
                                >
                                    <h3 class="margin-bottom font-bold">
                                        <i
                                            style="opacity: 0.4"
                                            class="fas fa-fw fa-arrow-alt-circle-up"
                                            [ngStyle]="{'color': (device?.deviceState?.output2 == null) ? '#ccc' : (device?.deviceState?.output2?.state  ?'rgba(0,142,67,0.9)' : 'rgba(151,28,36,0.9)') }"
                                        ></i>
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div style="clear: both"></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-12">
                <div class="ibox">
                    <div class="ibox-footer noselect dataTables_wrapper">
                        <pagination-template #p="paginationApi" (pageChange)="currentPage = $event" id="first">
                            <div class="dataTables_paginate paging_simple_numbers" id="dataTable_paginate">
                                <ul class="pagination">
                                    <li class="paginate_button page-item previous" [class.disabled]="p.isFirstPage()">
                                        <a class="page-link" (click)="p.previous()" *ngIf="!p.isFirstPage()"
                                            >{{ 'grid.previous' | translate }}</a
                                        >
                                        <a *ngIf="p.isFirstPage()">{{ 'grid.previous' | translate }}</a>
                                    </li>

                                    <li
                                        class="paginate_button page-item"
                                        *ngFor="let page of p.pages"
                                        [class.current]="p.getCurrent() === page.value"
                                    >
                                        <a
                                            class="page-link"
                                            (click)="p.setCurrent(page.value)"
                                            *ngIf="p.getCurrent() !== page.value"
                                        >
                                            <span>{{ page.label }}</span>
                                        </a>
                                        <a
                                            class="page-link"
                                            (click)="p.setCurrent(page.value)"
                                            *ngIf="p.getCurrent() === page.value"
                                        >
                                            <span style="font-weight: bold">{{ page.label }}</span>
                                        </a>
                                    </li>

                                    <li class="paginate_button page-item next" [class.disabled]="p.isLastPage()">
                                        <a class="page-link" (click)="p.next()" *ngIf="!p.isLastPage()">
                                            {{ 'grid.next' | translate }}
                                        </a>
                                        <a class="page-link" *ngIf="p.isLastPage()"> {{ 'grid.next' | translate }} </a>
                                    </li>
                                </ul>
                            </div>
                        </pagination-template>
                        <div style="clear: both"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</fh-permission-handler>
