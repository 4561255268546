import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "filterRelevant",
  pure: true,
})
export class FilterRelevantPipe implements PipeTransform {
  transform(items: any[], filter: boolean): any {
    if (!items) {
      return items;
    }
    if (filter === true) {
      return items.filter((item) => item.isCustomDeviceSensorTypeId === false);
    } else {
      return items;
    }
  }
}
