import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { DeviceCalibration, EngineHourCalibration } from "app/models/device.model";

import { throwError } from "rxjs/internal/observable/throwError";
import { Observable, catchError, map } from "rxjs";
import { Asset, DmvAsset, RegisterOperatingAsset } from "../../models/asset.model";
import { AuthenticationService } from "../authentication/authentication.service";
import { LoggingService } from "../logging/logging.service";

// Moment timezone
import Moment from "moment-timezone";
window["moment"] = Moment;

@Injectable()
export class AssetService {
  Assets: Asset[] = [];
  url = "";
  base_url = "";
  timezoneIana: string;

  constructor(
    private http: HttpClient,
    private loggingService: LoggingService,
    private authenticationService: AuthenticationService
  ) {
    this.url = this.authenticationService.getWebserviceURL("asset");
    this.base_url = this.authenticationService.getWebserviceURL("");
    this.timezoneIana = this.authenticationService.getTimeZoneIana();
  }

  getPagingUrl() {
    return this.url + "Paging";
  }

  getAssets(): Observable<Asset[]> {
    return this.http.get(this.url, { headers: this.authenticationService.headers }).pipe(
      map((data) => {
        return this.parseResponse(data);
      }),
      catchError(this.handleError)
    );
  }

  getAssetsByAccount(accountId): Observable<any> {
    return this.http
      .get(this.base_url + "Account/" + accountId + "/Assets", { headers: this.authenticationService.headers })
      .pipe(
        map((data) => {
          return this.parseResponse(data);
        }),
        catchError(this.handleError)
      );
  }

  getAssetsByCustomerId(id: string): Observable<Asset[]> {
    return this.http.get(this.url + "Customer/" + id, { headers: this.authenticationService.headers }).pipe(
      map((data) => {
        return this.parseResponse(data);
      }),
      catchError(this.handleError)
    );
  }

  getAssetsByAssetTypeId(id: string): Observable<Asset[]> {
    return this.http.get(this.url + "AssetType/" + id, { headers: this.authenticationService.headers }).pipe(
      map((data) => {
        return this.parseResponse(data);
      }),
      catchError(this.handleError)
    );
  }

  getAssetsByParentId(id: string, assetClass: number): Observable<Asset[]> {
    return this.http
      .get(this.url + "Parent/" + id + "?assetClass=" + assetClass, { headers: this.authenticationService.headers })
      .pipe(
        map((data) => {
          return this.parseResponse(data);
        }),
        catchError(this.handleError)
      );
  }

  getAssetsByChildId(id: string, assetClass: number): Observable<Asset[]> {
    return this.http
      .get(this.url + "Child/" + id + "?assetClass=" + assetClass, { headers: this.authenticationService.headers })
      .pipe(
        map((data) => {
          return this.parseResponse(data);
        }),
        catchError(this.handleError)
      );
  }

  getAssetsByDriverId(id: string): Observable<Asset[]> {
    return this.http.get(this.url + "Driver/" + id, { headers: this.authenticationService.headers }).pipe(
      map((data) => {
        return this.parseResponse(data);
      }),
      catchError(this.handleError)
    );
  }

  getInformationDMV(plateOrVin: string, resellerId: number): Observable<DmvAsset> {
    return this.http
      .get(this.url + "DmvPlateOrVin/" + plateOrVin + "/ResellerId/" + resellerId, {
        headers: this.authenticationService.headers,
      })
      .pipe(
        map((data) => {
          return this.parseDmvResponse(data);
        }),
        catchError(this.handleError)
      );
  }

  getCalibrationsByAssetId(assetId: string): Observable<DeviceCalibration[]> {
    return this.http.get(this.url + assetId + "/Calibrations", { headers: this.authenticationService.headers }).pipe(
      map((data: any) => {
        const calibrations = [];
        data.forEach((item) => {
          const calibration = new DeviceCalibration();
          calibration.assetId = item.assetId;
          calibration.dateCalibration = Moment.utc(item.dateCalibration)["tz"](this.timezoneIana);
          calibration.deviceId = item.deviceId;
          calibration.deviceOdoDistance = item.deviceOdoDistance;
          calibration.deviceOdoDistanceOffset = item.deviceOdoDistanceOffset;
          calibration.incrementalOdoDistanceOffset = item.incrementalOdoDistanceOffset;
          calibration.id = item.id;
          calibration.calibrationState = item.calibrationState;
          calibration.realOdoDistance = item.realOdoDistance;

          calibrations.push(calibration);
        });
        return calibrations;
      }),
      catchError(this.handleError)
    );
  }

  getEngineHourCalibrationsByAssetId(assetId: string): Observable<EngineHourCalibration[]> {
    return this.http
      .get(this.url + assetId + "/EngineHourCalibrations", { headers: this.authenticationService.headers })
      .pipe(
        map((data: any) => {
          const calibrations = [];
          data.forEach((item) => {
            const calibration = new EngineHourCalibration();
            calibration.id = item.id;
            calibration.assetId = item.assetId;
            calibration.deviceId = item.deviceId;
            calibration.dateCalibration = Moment.utc(item.dateCalibration)["tz"](this.timezoneIana);
            calibration.realEngineHourInSeconds = item.realEngineHourInSeconds;
            calibration.tripEngineHourInSeconds = item.tripEngineHourInSeconds;
            calibration.offsetInSeconds = item.offsetInSeconds;

            calibrations.push(calibration);
          });
          return calibrations;
        }),
        catchError(this.handleError)
      );
  }

  deleteDriverAssignment(assetId: any, object: object): Observable<any> {
    return this.http.post(this.url + assetId + "/DeleteDriverAssignment", object, {
      headers: this.authenticationService.headers,
    });
  }

  stopDriverAssignment(assetId: any, object: object): Observable<any> {
    return this.http.post(this.url + assetId + "/StopDriverAssignment", object, {
      headers: this.authenticationService.headers,
    });
  }

  assignDriver(assetId: any, assignDriver: object): Observable<any> {
    return this.http.post(this.url + assetId + "/AssignDriver", assignDriver, {
      headers: this.authenticationService.headers,
    });
  }

  deleteTrailerAssignment(assetId: any, trailer: object): Observable<any> {
    return this.http.post(this.url + assetId + "/DeleteTrailerAssignment", trailer, {
      headers: this.authenticationService.headers,
    });
  }

  stopTrailerAssignment(assetId: any, trailer: object): Observable<any> {
    return this.http.post(this.url + assetId + "/StopTrailerAssignment", trailer, {
      headers: this.authenticationService.headers,
    });
  }

  assignTrailer(assetId: any, assignTrailer: object): Observable<any> {
    return this.http.post(this.url + assetId + "/AssignTrailer", assignTrailer, {
      headers: this.authenticationService.headers,
    });
  }

  deleteProjectAssignment(assetId: any, project: object): Observable<any> {
    return this.http.post(this.url + assetId + "/DeleteProjectAssignment", project, {
      headers: this.authenticationService.headers,
    });
  }

  stopProjectAssignment(assetId: any, project: object): Observable<any> {
    return this.http.post(this.url + assetId + "/StopProjectAssignment", project, {
      headers: this.authenticationService.headers,
    });
  }

  assignProject(id: any, assignProject: object): Observable<any> {
    return this.http.post(this.url + id + "/AssignProject", assignProject, {
      headers: this.authenticationService.headers,
    });
  }

  postCalibration(assetId: any, calibration): Observable<any> {
    console.log("posting calibration");

    return this.http
      .post(this.url + assetId + "/calibration", calibration, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  deleteCalibration(assetId: any, calibrationId): Observable<any> {
    console.log("deleting calibration");

    return this.http
      .delete(this.url + assetId + "/calibration/" + calibrationId, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  postEngineHourCalibration(assetId: any, calibration): Observable<any> {
    console.log("posting calibration");

    return this.http
      .post(this.url + assetId + "/enginehourcalibration", calibration, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  deleteEngineHourCalibration(assetId: any, calibrationId): Observable<any> {
    console.log("deleting calibration");

    return this.http
      .delete(this.url + assetId + "/enginehourcalibration/" + calibrationId, {
        headers: this.authenticationService.headers,
      })
      .pipe(catchError(this.handleError));
  }

  getAssetById(id: string): Observable<Asset> {
    return this.http.get(this.url + id, { headers: this.authenticationService.headers }).pipe(
      map((data) => {
        return this.parseReponseDetails(data);
      }),
      catchError(this.handleError)
    );
  }

  saveAsset(asset: Asset): Observable<any> {
    console.log("save asset");

    return this.http
      .post(this.url, asset, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  resetAssetCache(): Observable<boolean> {
    return this.http.get(this.url + "ResetCache", { headers: this.authenticationService.headers }).pipe(
      map((data: any) => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  updateAsset(asset: Asset): Observable<any> {
    console.log("save asset");
    return this.http
      .put(this.url + asset.id, asset, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  archiveAsset(deviceId, assetId: number, dateEnd: Date): Observable<any> {
    console.log("archive asset");
    return this.http
      .post(
        this.url + assetId + "/Archive",
        { dateEnd: dateEnd, deviceId: deviceId, assetId: assetId },
        { headers: this.authenticationService.headers }
      )
      .pipe(catchError(this.handleError));
  }

  updateAssetAssignment(deviceId, assetId: number, startDate: Date, dateEnd: Date): Observable<any> {
    console.log("assgignment asset");
    return this.http
      .post(
        this.url + assetId + "/Assignment",
        { dateEnd: dateEnd, startDate: startDate, deviceId: deviceId, assetId: assetId },
        { headers: this.authenticationService.headers }
      )
      .pipe(catchError(this.handleError));
  }

  reactivateAsset(assetId: number, deviceId: number | string): Observable<any> {
    console.log("reactivate asset");
    return this.http
      .post(
        this.url + assetId + "/ReactivateAsset",
        { assetId, deviceId },
        { headers: this.authenticationService.headers }
      )
      .pipe(catchError(this.handleError));
  }

  deleteAsset(assetId: number, deviceId: number | string): Observable<any> {
    console.log("Delete Asset");
    return this.http
      .post(this.url + assetId + "/Delete", { assetId, deviceId }, { headers: this.authenticationService.headers })
      .pipe(catchError(this.handleError));
  }

  getAssetByDeviceId(id: string): Observable<Asset> {
    return this.http.get(this.url + "Device/" + id, { headers: this.authenticationService.headers }).pipe(
      map((data) => {
        return this.parseReponseDetails(data);
      }),
      catchError(this.handleError)
    );
  }

  getWaslAssetStatus(): Observable<any> {
    console.log("Fetch assets for wasl status");
    return this.http.get(this.base_url + "Assets/Wasl", { headers: this.authenticationService.headers }).pipe(
      map((data) => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  addToGroup(assetId: number, assetGroups): Observable<any> {
    return this.http
      .post(
        this.url + assetId + "/AddToGroups",
        { assetId, assetGroups },
        { headers: this.authenticationService.headers }
      )
      .pipe(catchError(this.handleError));
  }

  removeFromGroups(assetId: any, object: object): Observable<any> {
    return this.http.post(this.url + assetId + "/RemoveFromGroups", object, {
      headers: this.authenticationService.headers,
    });
  }

  private handleError(error: Response) {
    return throwError(error);
  }

  getAssetMessagesById(id: string, start, end): Observable<any> {
    return this.http
      .get(this.url + id + "/Messages?start=" + start.unix() + "&end=" + end.unix(), {
        headers: this.authenticationService.headers,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  parseDmvResponse(json): DmvAsset {
    const dmvAsset = new DmvAsset();

    dmvAsset.message = json.message;

    if (json.entity !== undefined) {
      dmvAsset.brand = json.entity.brand;
      dmvAsset.model = json.entity.model;
      dmvAsset.color = json.entity.color;
      dmvAsset.year = json.entity.year;
      dmvAsset.vehicleType = json.entity.vehicleType;
      dmvAsset.safetyCertificateExpirationDate = new Date(json.entity.safetyCertificateExpirationDate);
      dmvAsset.dateAcquired = new Date(json.entity.dateAcquired);
      dmvAsset.clientAssetCategory = json.entity.clientAssetCategory;
      dmvAsset.purchasePrice = json.entity.purchasePrice;
      dmvAsset.fuelType = json.entity.fuelType;

      dmvAsset.emissionCO2 = json.entity.emissionCO2;
      dmvAsset.emissionParticlesLight = json.entity.emissionParticlesLight;
      dmvAsset.emissionParticlesHeavy = json.entity.emissionParticlesHeavy;
      dmvAsset.urbanConsumption = json.entity.urbanConsumption;
      dmvAsset.extraUrbanConsumption = json.entity.extraUrbanConsumption;
      dmvAsset.mixedConsumption = json.entity.mixedConsumption;
    }

    return dmvAsset;
  }

  parseResponse(json: any): Asset[] {
    this.loggingService.log(this.constructor.name, "Retrieved " + json.length + " Assets.");

    const ident = 1;
    const assets: Asset[] = [];

    json.forEach((item) => {
      const asset = this.parseReponseDetails(item);
      assets.push(asset);
    });

    return assets;
  }

  parseReponseDetails(item) {
    if (item === null) {
      return null;
    }

    const asset = new Asset();
    asset.id = item.id;
    asset.deviceId = item.deviceId;
    (asset.unitId = item.unitId), (asset.name = item.name);
    asset.assetSearchName = item.name !== undefined ? item.name + " - " + item.unitId : item.unitId;
    asset.year = item.year;
    asset.vehicleType = item.vehicleType;
    asset.vin = item.vin;
    asset.plateNumber = item.plateNumber;
    asset.color = item.color;
    asset.code = item.code;
    asset.brand = item.brand;
    asset.model = item.model;
    asset.assignmentEnd = item.dateEnd;
    asset.assignmentStart = item.dateStart;
    asset.isArchived = item.isArchived;

    asset.owner = item.owner;
    asset.clientAssetCategory = item.clientAssetCategory;
    asset.clientAssetSubCategory = item.clientAssetSubCategory;
    asset.ownership = item.ownership;
    asset.fuelType = item.fuelType;
    asset.capacity = item.capacity;
    asset.bodyCode = item.bodyCode;
    asset.engineSerialNumber = item.engineSerialNumber;
    asset.engineType = item.engineType;
    asset.costCenter = item.costCenter;
    asset.madeIn = item.madeIn;
    asset.plateMunicipality = item.plateMunicipality;
    asset.dateAcquired = item.dateAcquired !== undefined ? new Date(item.dateAcquired) : undefined;
    asset.dealerName = item.dealerName;
    asset.purchasePrice = item.purchasePrice;
    asset.currentValue = item.currentValue;
    asset.totalDepreciation = item.totalDepreciation;
    asset.dateDepreciation = item.dateDepreciation !== undefined ? new Date(item.dateDepreciation) : undefined;
    asset.dateSold = item.dateSold !== undefined ? new Date(item.dateSold) : undefined;
    asset.soldTo = item.soldTo;
    asset.soldPrice = item.soldPrice;
    asset.insurancePolicy = item.insurancePolicy;
    asset.dateExpireInsurance = item.dateExpireInsurance !== undefined ? new Date(item.dateExpireInsurance) : undefined;
    asset.registrationNumber = item.registrationNumber;
    asset.dateExpireRegistration =
      item.dateExpireRegistration !== undefined ? new Date(item.dateExpireRegistration) : undefined;
    asset.permitOne = item.permitOne;
    asset.permitOneExpirationDate =
      item.permitOneExpirationDate !== undefined ? new Date(item.permitOneExpirationDate) : undefined;
    asset.permitTwo = item.permitTwo;
    asset.permitTwoExpirationDate =
      item.permitTwoExpirationDate !== undefined ? new Date(item.permitTwoExpirationDate) : undefined;
    asset.safetyCertificate = item.safetyCertificate;
    asset.safetyCertificateExpirationDate =
      item.safetyCertificateExpirationDate !== undefined ? new Date(item.safetyCertificateExpirationDate) : undefined;
    asset.safetyTestedBy = item.safetyTestedBy;
    asset.erpCode = item.erpCode;
    asset.branding = item.branding;
    asset.equipment = item.equipment;
    asset.garageId = item.garageId;
    asset.garageName = item.garageName;

    asset.emissionCO2 = item.emissionCO2;
    asset.emissionParticlesLight = item.emissionParticlesLight;
    asset.emissionParticlesHeavy = item.emissionParticlesHeavy;
    asset.urbanConsumption = item.urbanConsumption;
    asset.extraUrbanConsumption = item.extraUrbanConsumption;
    asset.mixedConsumption = item.mixedConsumption;

    asset.assetTypeId = item.assetTypeId;

    asset.assignedScheduleId = item.assignedScheduleId;

    if (item.assetDriverDto != null) {
      const assetDriver = item.assetDriverDto;
    }

    if (item.assetLinkStartDate != null) {
      asset.assignmentStart = item.assetLinkStartDate;
    }

    if (item.assetLinkEndDate != null) {
      asset.assignmentEnd = item.assetLinkEndDate;
    }

    const wasl = new RegisterOperatingAsset();
    if (item.properties) {
      if (item.properties.wasl) {
        wasl.referenceKey = item.properties.wasl.referenceKey;
        wasl.registerDateWasl = item.properties.wasl.registerDateWasl;
        wasl.registerDateSfda = item.properties.wasl.registerDateSfda;
        wasl.registerDateTow = item.properties.wasl.registerDateTow;

        wasl.plateType = item.properties.wasl.plateType;
        wasl.sequenceNumber = item.properties.wasl.sequenceNumber;

        wasl.vehiclePlateNumber = item.properties.wasl.vehiclePlateNumber;
        wasl.vehiclePlateLeftLetter = item.properties.wasl.vehiclePlateLeftLetter;
        wasl.vehiclePlateMiddleLetter = item.properties.wasl.vehiclePlateMiddleLetter;
        wasl.vehiclePlateRightLetter = item.properties.wasl.vehiclePlateRightLetter;
      }
    }
    asset.properties.wasl = wasl;

    return asset;
  }
}
