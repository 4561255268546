import { Component, Input } from "@angular/core";
import { HierarchyGroup } from "app/models/hierarchyGroup.model";

@Component({
  selector: "fh-hierarchy-display",
  templateUrl: "./hierarchyDisplay.template.html",
})
export class HierarchyDisplayComponent {
  @Input() parentId;
  @Input() groups = [];
  @Input() includeItems = true;

  getChildren(parentId: string): HierarchyGroup[] {
    return this.groups.filter((group) => group.parentId === parentId);
  }

  // Track visibility for each group
  openGroups: Set<string> = new Set();

  // Toggle visibility of child groups
  toggleOpen(event, groupId: string): void {
    if (this.openGroups.has(groupId)) {
      this.openGroups.delete(groupId);
    } else {
      this.openGroups.add(groupId);
    }

    event.stopPropagation();
  }

  // Check if the group is visible
  isOpened(groupId: string): boolean {
    return this.openGroups.has(groupId);
  }
}
