import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { ControlContainer, NgForm } from "@angular/forms";
import { FormMode } from "app/common/enums";
import { DeviceCalibrationPoints } from "app/models/device.model";

@Component({
  selector: "fh-calibration-details",
  templateUrl: "calibrationDetails.component.html",
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class CalibrationComponent implements OnInit {
  @ViewChild("userForm") form: NgForm;

  @Input() calibrationPoints = [];
  @Input() formMode: any = FormMode.read;

  @Input() sensorNumber: number;

  calibratedValue;
  reportedValue;

  formChanges;

  constructor() {}

  hasCalibrations(sensorNumber) {
    return this.calibrationPoints?.filter((x) => x.sensorNumber == sensorNumber).length > 0;
  }

  addRow(calibratedValue, reportedValue) {
    const calibrationPoint = new DeviceCalibrationPoints();
    calibrationPoint.sensorNumber = this.sensorNumber;
    calibrationPoint.reportedValue = +reportedValue.value;
    calibrationPoint.calibratedValue = +calibratedValue.value;
    this.calibrationPoints.push(calibrationPoint);
    this.formChanges = true;

    this.calibratedValue = "";
    this.reportedValue = "";
  }

  removeRow(data) {
    const index: number = this.calibrationPoints.indexOf(data);

    if (index !== -1) {
      this.formChanges = true;
      this.calibrationPoints.splice(index, 1);
    }
  }

  ngOnInit(): void {}

  public getFormState() {
    if (!this.form) {
      return false;
    }

    return this.formChanges && this.form.invalid;
  }
}
