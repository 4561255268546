<form [formGroup]="formGroup">
    <div class="col-md-6">
        <div class="ibox">
            <div class="ibox-title">
                <h5>
                    <i class="fas fa-fw fa-random"></i> {{'general.inputs' | translate }}
                </h5>
            </div>
            <div class="ibox-content slick" style="position: relative">
                <ngx-loading [show]="loading"></ngx-loading>

                <table style="width: 100%" class="table nowrap dtr-inline no-footer table-fixed">
                    <thead>
                        <tr>
                            <th class="col-checkbox" *ngIf="postWrapper?.childNodes.length === 0"></th>
                            <th class="row_opening">
                                {{'general.name' | translate }}
                            </th>
                            <th class="row_content">
                                {{'general.value' | translate }}
                            </th>
                            <th class="col-checkbox" style="padding-left: 3px">
                                {{'general.inverted' | translate }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let inputPort of settings.inputPorts; let id = index;">
                            <td class="col-checkbox" *ngIf="postWrapper?.childNodes.length === 0">
                                <input type="checkbox" [value]="updatedParams['input' + inputPort.id].value"
                                    [disabled]="formMode == 1" (change)="onCheckChange($event)">
                                <label></label>
                            </td>
                            <td class="row_opening">
                                {{ 'general.ioFormatted' | translate:{ value: inputPort.id } }}
                                <i *ngIf="!!duplicateInputs[formGroup.get('input' + inputPort.id)?.value]"
                                    [tooltip]="'general.duplicateInput' | translate" style="line-height: 22px;"
                                    class="fas fa-fw fa-exclamation-triangle float-right"></i>
                            </td>
                            <td class="row_content" style="overflow:visible">
                                <ng-container *ngIf="formMode == 1; else editIO">
                                    <input type="text" [readonly]=true class="form-control table-inline"
                                        [value]="'enums.locationEventType.' + inputPort.byte | translate ">
                                </ng-container>
                                <ng-template #editIO>
                                    <ng-select [clearable]="false" [formControlName]="'input' + inputPort.id"
                                        class="form-control table-inline" style="width: 100%"
                                        [ngStyle]="{'border': !!duplicateInputs[formGroup.get('input' + inputPort.id)?.value] ? '1px solid red' : '0' }">
                                        <ng-option *ngFor="let io of inputOptions | orderBy : ['rank', 'value']:false"
                                            [value]="io.id"> {{ io.value }}
                                        </ng-option>
                                    </ng-select>
                                </ng-template>
                            </td>
                            <td class="col-checkbox">
                                <ng-container *ngIf="formMode == 1; else editInvert">
                                    <input [readonly]=true [id]="'id_' + id" type="checkbox"
                                        [checked]="inputPort.inverted" disabled />
                                    <label [for]="'id_' + id"></label>
                                </ng-container>
                                <ng-template #editInvert>
                                    <input [id]="'id_' + id" type="checkbox"
                                        [formControlName]="'invertInput' + inputPort.id" />
                                    <label [for]="'id_' + id"></label>
                                </ng-template>
                            </td>
                        </tr>
                        <tr *ngFor="let analogPort of settings.analogPorts; let id = index;">
                            <td class="col-checkbox" *ngIf="postWrapper?.childNodes.length === 0">
                                <input type="checkbox" [value]="updatedParams['a' + analogPort.id].value"
                                    [disabled]="formMode == 1" (change)="onCheckChange($event)">
                                <label></label>
                            </td>
                            <td class="row_opening">
                                {{ 'general.analogFunctionFormatted' | translate:{ value: analogPort.id } }}
                            </td>
                            <td class="row_content" style="overflow:visible">
                                <ng-container *ngIf="formMode == 1; else editAnalog">
                                    <input [readonly]=true type="text" class="form-control table-inline"
                                        [value]="'enums.analogFunctions.' + analogPort.byte | translate ">
                                </ng-container>
                                <ng-template #editAnalog>
                                    <ng-select [clearable]="false" [formControlName]="'a' + analogPort.id"
                                        class="form-control table-inline" style="width:100%">
                                        <ng-option
                                            *ngFor="let option of analogOptions | orderBy : ['rank', 'value']:false"
                                            [value]="option.id"> {{ option.value }}
                                        </ng-option>
                                    </ng-select>
                                </ng-template>
                            </td>
                            <td class="col-checkbox">
                                <button type="button" class="btn btn-primary"
                                    [tooltip]="'general.showCalibrationWindow' | translate" container="body"
                                    style="position: absolute; padding: 4px 7px; transform: translate(-7px, -4px);"
                                    *ngIf="checkAnalogPort(formGroup.get('a' + analogPort.id).value)"
                                    (click)="showModal(id + 1)">
                                    <span>
                                        <i class="fa fa-chart-area"></i></span>
                                </button>
                            </td>
                        </tr>                        
                        <tr>
                            <td class="col-checkbox" *ngIf="postWrapper?.childNodes.length === 0">
                                <input type="checkbox" [value]="updatedParams['externalPower'].value"
                                    [disabled]="formMode == 1" (change)="onCheckChange($event)">
                                <label></label>
                            </td>
                            <td class="row_opening">
                                {{'general.externalPower' | translate }}
                                <i *ngIf="!!duplicateInputs[formGroup.get('externalPower')?.value]"
                                    [tooltip]="'general.duplicateInput' | translate" style="line-height: 22px;"
                                    class="fas fa-fw fa-exclamation-triangle float-right"></i>
                            </td>
                            <td class="row_content" style="overflow:visible">
                                <ng-container *ngIf="formMode == 1; else editExternalPower">
                                    <input [readonly]=true type="text" class="form-control table-inline"
                                        [value]="'enums.locationEventType.' + settings.externalPower | translate ">
                                </ng-container>
                                <ng-template #editExternalPower>
                                    <ng-select [clearable]="false" formControlName="externalPower"
                                        class="form-control table-inline" style="width: 100%"
                                        [ngStyle]="{'border': !!duplicateInputs[formGroup.get('externalPower')?.value] ? '1px solid red' : '0' }">
                                        <ng-option *ngFor="let io of inputOptions | orderBy : ['rank', 'value']:false"
                                            [value]="io.id"> {{ io.value }}
                                        </ng-option>
                                    </ng-select>
                                </ng-template>
                            </td>
                            <td class="col-checkbox"></td>
                        </tr>
                        <tr>
                            <td class="col-checkbox" *ngIf="postWrapper?.childNodes.length === 0">
                                <input type="checkbox" [value]="updatedParams['ignition'].value"
                                    [disabled]="formMode == 1" (change)="onCheckChange($event)">
                                <label></label>
                            </td>
                            <td class="row_opening">
                                {{'general.ignition' | translate }}
                                <i *ngIf="!!duplicateInputs[formGroup.get('ignition')?.value]"
                                    [tooltip]="'general.duplicateInput' | translate" style="line-height: 22px;"
                                    class="fas fa-fw fa-exclamation-triangle float-right"></i>
                            </td>
                            <td class="row_content" style="overflow:visible">
                                <ng-container *ngIf="formMode == 1; else editIgnition">
                                    <input [readonly]=true type="text" class="form-control table-inline"
                                        [value]="'enums.locationEventType.' + settings.ignition | translate ">
                                </ng-container>
                                <ng-template #editIgnition>
                                    <ng-select [clearable]="false" formControlName="ignition"
                                        class="form-control table-inline" style="width: 100%"
                                        [ngStyle]="{'border': !!duplicateInputs[formGroup.get('ignition')?.value] ? '1px solid red' : '0' }">
                                        <ng-option *ngFor="let io of inputOptions | orderBy : ['rank', 'value']:false"
                                            [value]="io.id"> {{ io.value }}
                                        </ng-option>
                                    </ng-select>
                                </ng-template>
                            </td>
                            <td class="col-checkbox"></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div #postWrapper>
                <ng-content select="[postAction]"></ng-content>
            </div>
        </div>
        <div class="ibox">
            <div class="ibox-title">
                <h5>
                    <i class="fas fa-fw fa-random"></i>
                    {{'devicedetails.outputs' | translate }}
                </h5>
            </div>
            <div class="ibox-content slick" style="position: relative">
                <ngx-loading [show]="loading"></ngx-loading>
                <table style="width: 100%" class="table nowrap dtr-inline no-footer table-fixed">
                    <thead>
                        <tr>
                            <th class="col-checkbox" *ngIf="postWrapper?.childNodes.length === 0"></th>
                            <th class="row_opening">
                                {{'general.name' | translate }}
                            </th>
                            <th class="row_content">
                                {{'general.value' | translate }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let outputPort of settings.outputPorts;">
                            <td class="col-checkbox" *ngIf="postWrapper?.childNodes.length === 0">
                                <input type="checkbox" [value]="updatedParams['output' + outputPort.id].value"
                                    [disabled]="formMode == 1" (change)="onCheckChange($event)">
                                <label></label>
                            </td>
                            <td class="row_opening">
                                {{ 'general.outputFormatted' | translate:{ value: outputPort.id } }}
                            </td>
                            <td class="row_content" style="overflow:visible">
                                <ng-container *ngIf="formMode == 1; else editOutput">
                                    <input [readonly]=true type="text" class="form-control table-inline"
                                        [value]="'enums.deviceOutput.' + outputPort.byte | translate ">
                                </ng-container>
                                <ng-template #editOutput>
                                    <ng-select [clearable]="false" [formControlName]="'output' + outputPort.id"
                                        class="form-control table-inline" style="width:100%">
                                        <ng-option *ngFor="let deviceOutput of deviceOutputOptions"
                                            [value]="deviceOutput">
                                            {{ 'enums.deviceOutput.' + deviceOutput | translate }}
                                        </ng-option>
                                    </ng-select>
                                </ng-template>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="ibox">
            <div class="ibox-title">
                <h5>
                    <i class="fas fa-fw fa-microchip"></i> {{'deviceDetails.box' | translate }}
                </h5>
            </div>
            <div class="ibox-content slick" style="position: relative">
                <ngx-loading [show]="loading"></ngx-loading>
                <table style="width: 100%" class="table nowrap dtr-inline no-footer table-fixed">
                    <thead>
                        <tr>
                            <th class="col-checkbox" *ngIf="postWrapper?.childNodes.length === 0"></th>
                            <th class="row_opening">
                                {{'general.name' | translate }}
                            </th>
                            <th class="row_content">
                                {{'general.value' | translate }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="col-checkbox" *ngIf="postWrapper?.childNodes.length === 0">
                                <input type="checkbox" [value]="updatedParams['deviceTypeId'].value"
                                    [disabled]="formMode == 1" (change)="onCheckChange($event)">
                                <label></label>
                            </td>
                            <td class="row_opening">
                                {{'general.trackerType' | translate }}
                            </td>
                            <td class="row_content" style="position: relative; overflow: visible;">
                                <ngx-loading [show]="loadingDeviceTypes"></ngx-loading>
                                <ng-container *ngIf="formMode == 1; else editDeviceType">
                                    <input type="text" [readonly]=true class="form-control table-inline"
                                        [value]="device.deviceTypeNameFull">
                                </ng-container>
                                <ng-template #editDeviceType>
                                    <ng-select [clearable]="false" formControlName="deviceTypeId"
                                        placeholder="{{ 'general.chooseDeviceType' | translate}}"
                                        class="form-control table-inline">
                                        <ng-option *ngIf="postWrapper?.childNodes.length === 0 && formMode != 1"
                                            value="0">{{ 'general.keepTrackerType' | translate}}</ng-option>
                                        <ng-option *ngFor="let deviceType of deviceTypes" [value]="deviceType.id">
                                            {{deviceType.modelName}} | {{deviceType.description}}
                                        </ng-option>
                                    </ng-select>
                                </ng-template>
                            </td>
                        </tr>
                        <tr>
                            <td class="col-checkbox" *ngIf="postWrapper?.childNodes.length === 0">
                                <input type="checkbox" [value]="updatedParams['isStickyDriver'].value"
                                    [disabled]="formMode == 1" (change)="onCheckChange($event)">
                                <label></label>
                            </td>
                            <td class="row_opening">
                                {{'general.isStickyDriver' | translate }}
                            </td>
                            <td class="row_content padding-readonly-correct">
                                <div class="fa_checkbox">
                                    <ng-container *ngIf="formMode == 1; else editStickyDriver">
                                        <input id="stickyDriver" [readonly]=true type="checkbox"
                                            [checked]="settings.isStickyDriver" disabled />
                                        <label for="stickyDriver"></label>
                                    </ng-container>
                                    <ng-template #editStickyDriver>
                                        <input id="stickyDriver" type="checkbox" formControlName="isStickyDriver" />
                                        <label for="stickyDriver"></label>
                                    </ng-template>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="col-checkbox" *ngIf="postWrapper?.childNodes.length === 0">
                                <input type="checkbox" [value]="updatedParams['useCanSpeed'].value"
                                    [disabled]="formMode == 1" (change)="onCheckChange($event)">
                                <label></label>
                            </td>
                            <td class="row_opening">
                                {{'general.useCanSpeed' | translate }}
                            </td>
                            <td class="row_content padding-readonly-correct">
                                <div class="fa_checkbox">
                                    <ng-container *ngIf="formMode == 1; else editUseCanSpeed">
                                        <input id="useCanSpeed" [readonly]=true type="checkbox"
                                            [checked]="settings.useCanSpeed" disabled />
                                        <label for="useCanSpeed"></label>
                                    </ng-container>
                                    <ng-template #editUseCanSpeed>
                                        <input id="useCanSpeed" type="checkbox" formControlName="useCanSpeed" />
                                        <label for="useCanSpeed"></label>
                                    </ng-template>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="ibox">
            <div class="ibox-title">
                <h5>
                    <i class="fas fa-fw fa-plug"></i> {{'devicedetails.auxiliary' | translate }}
                </h5>
            </div>
            <div class="ibox-content slick" style="position: relative">
                <ngx-loading [show]="loading"></ngx-loading>
                <table style="width: 100%" class="table nowrap dtr-inline no-footer table-fixed">
                    <thead>
                        <tr>
                            <th class="col-checkbox" *ngIf="postWrapper?.childNodes.length === 0"></th>
                            <th class="row_opening">
                                {{'general.name' | translate }}
                            </th>
                            <th class="row_content">
                                {{'general.value' | translate }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="col-checkbox" *ngIf="postWrapper?.childNodes.length === 0">
                                <input type="checkbox" [value]="updatedParams['auxiliaryAttachements'].value"
                                    [disabled]="formMode == 1" (change)="onCheckChange($event)">
                                <label></label>
                            </td>
                            <td class="row_opening">
                                {{'general.auxilaryAttachments' | translate }}
                            </td>
                            <td class="row_content" style="overflow:visible">
                                <ng-container *ngIf="settings.deviceAuxiliary">
                                    <ng-container *ngIf="formMode == 1; else editAuxilary">
                                        <ng-container *ngIf="settings.deviceAuxiliary.length; else defaultAuxiliary">
                                            <div *ngFor="let auxilary of settings.deviceAuxiliary;">
                                                {{auxilary.itemName}}
                                            </div>
                                        </ng-container>
                                        <ng-template #defaultAuxiliary>
                                            {{'enums.deviceAuxiliary.' + 0 | translate }}
                                        </ng-template>
                                    </ng-container>
                                    <ng-template #editAuxilary>
                                        <div class="form-group input-group" style="margin-bottom: 5px; width: 100%">
                                            <ng-select class="form-control" style="height: unset" [items]="auxOptions"
                                                [multiple]="true" bindLabel="itemName" [closeOnSelect]="false"
                                                bindValue="id" formControlName="auxiliaryAttachements">
                                            </ng-select>
                                        </div>
                                    </ng-template>
                                </ng-container>
                            </td>
                        </tr>
                        <tr>
                            <td class="col-checkbox" *ngIf="postWrapper?.childNodes.length === 0">
                                <input type="checkbox" [value]="updatedParams['canBusParameters'].value"
                                    [disabled]="formMode == 1" (change)="onCheckChange($event)">
                                <label></label>
                            </td>
                            <td class="row_opening">
                                {{'general.canBusParameters' | translate }}
                            </td>
                            <td class="row_content" style="overflow:visible">
                                <ng-container *ngIf="settings.canBusParameters">
                                    <ng-container *ngIf="formMode == 1; else editCan">
                                        <ng-container
                                            *ngIf="settings.canBusParameters.length; else defaultCanBusParameters">
                                            <div *ngFor="let canBus of settings.canBusParameters;">
                                                {{canBus.itemName}}
                                            </div>
                                        </ng-container>
                                        <ng-template #defaultCanBusParameters>
                                            {{'enums.canBusParameters.' + 0 | translate }}
                                        </ng-template>
                                    </ng-container>
                                    <ng-template #editCan>
                                        <div class="form-group input-group" style="margin-bottom: 5px; width: 100%">
                                            <ng-select class="form-control" style="height: unset" [items]="canOptions"
                                                [multiple]="true" bindLabel="itemName" [closeOnSelect]="false"
                                                bindValue="id" formControlName="canBusParameters">
                                            </ng-select>
                                        </div>
                                    </ng-template>
                                </ng-container>
                            </td>
                        </tr>
                        <tr *ngIf="permissions['Platform_IsReseller']">
                            <td class="col-checkbox" *ngIf="postWrapper?.childNodes.length === 0">
                            </td>
                            <td class="row_opening">
                                {{'general.serviceRequests' | translate }}
                            </td>
                            <td class="row_content" style="overflow:visible">
                                {{device.serviceRequests}}
                        </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>        
        <div class="ibox">
            <div class="ibox-title">
                <h5>
                    <i class="fas fa-fw fa-snooze"></i> {{'idlingOptions.header' | translate }}
                </h5>
            </div>
            <div class="ibox-content slick" style="position: relative; overflow: visible">
                <ngx-loading [show]="loading"></ngx-loading>
                <table style="width: 100%" class="table nowrap dtr-inline no-footer table-fixed">
                    <thead>
                        <tr>
                            <th class="col-checkbox" *ngIf="postWrapper?.childNodes.length === 0"></th>
                            <th class="row_opening">
                                {{'idlingOptions.setting' | translate }}
                            </th>
                            <th class="col-md-2">
                                {{'idlingOptions.active' | translate }}
                            </th>
                            <th class="col-md-3">
                                {{'idlingOptions.value' | translate }}
                            </th>
                            <th class="col-md-3">
                                {{'idlingOptions.duration' | translate }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="col-checkbox" *ngIf="postWrapper?.childNodes.length === 0">
                                <input type="checkbox" [value]="updatedParams['permittedIdlingInMinutes'].value"
                                    [disabled]="formMode == 1" (change)="onCheckChange($event)">
                                <label></label>
                            </td>
                            <td class="row_opening" title="{{'idlingOptions.allowIdlingInMinutes' | translate }}">
                                {{'idlingOptions.allowIdlingInMinutes' | translate }}
                                <span class="error"
                                    *ngIf="formGroup.get('permittedIdlingInMinutes').errors && formMode != 1">*</span>
                            </td>
                            <td class="row_content" colspan="3">
                                <input type="text" [readonly]="formMode == 1" formControlName="permittedIdlingInMinutes"
                                    class="form-control table-inline">
                            </td>
                        </tr>
                        <tr>
                            <td class="col-checkbox" *ngIf="postWrapper?.childNodes.length === 0">
                                <input type="checkbox" [value]="updatedParams['idlingCalculationMethod'].value"
                                    [disabled]="formMode == 1" (change)="onCheckChange($event)">
                                <label></label>
                            </td>
                            <td class="row_opening">
                                {{'idlingOptions.selectorIdlingState' | translate }}
                            </td>
                            <td class="row_content" style="overflow:visible" colspan="3">
                                <ng-container *ngIf="formMode == 1; else editIdlingOption">
                                    <input type="text" [readonly]=true class="form-control table-inline"
                                        [value]="'enums.idlingOptions.' + settings.idlingCalculationMethod | translate ">
                                </ng-container>
                                <ng-template #editIdlingOption>
                                    <ng-select [clearable]="false" formControlName="idlingCalculationMethod"
                                        class="form-control table-inline" style="width:100%">
                                        <ng-option *ngFor="let option of idlingOptions" [value]="option">
                                            {{ 'enums.idlingOptions.' + option | translate }}
                                        </ng-option>
                                    </ng-select>
                                </ng-template>
                            </td>
                        </tr>
                        <tr>
                            <td class="col-checkbox" *ngIf="postWrapper?.childNodes.length === 0">
                                <input type="checkbox" [value]="updatedParams['idlingRpmIsActive'].value"
                                    [disabled]="formMode == 1" (change)="onCheckChange($event)">
                                <label></label>
                            </td>
                            <td class="row_opening elipses_overflow"
                                title="{{'idlingOptions.idlingRpmDescription' | translate }}">
                                {{'idlingOptions.idlingRpmDescription' | translate }}
                            </td>
                            <td class="col-md-2">
                                <div class="fa_checkbox">
                                    <ng-container *ngIf="formMode == 1; else editIdlingRpm">
                                        <input id="idlingRpm" [readonly]=true type="checkbox"
                                            [checked]="settings.idlingRpmIsActive" disabled />
                                        <label for="idlingRpm"></label>
                                    </ng-container>
                                    <ng-template #editIdlingRpm>
                                        <input id="idlingRpm" type="checkbox" formControlName="idlingRpmIsActive" />
                                        <label for="idlingRpm"></label>
                                    </ng-template>
                                </div>
                            </td>
                            <td class="col-md-3">
                                <input type="text" [readonly]="formMode == 1" formControlName="idlingRpmValue"
                                    class="form-control table-inline" required>
                            </td>
                            <td class="col-md-3">
                                <input type="text" [readonly]="formMode == 1" formControlName="idlingRpmDuration"
                                    class="form-control table-inline" required>
                            </td>
                        </tr>
                        <tr>
                            <td class="col-checkbox" *ngIf="postWrapper?.childNodes.length === 0">
                                <input type="checkbox" [value]="updatedParams['idlingVoltageIsActive'].value"
                                    [disabled]="formMode == 1" (change)="onCheckChange($event)">
                                <label></label>
                            </td>
                            <td class="row_opening elipses_overflow"
                                title="{{'idlingOptions.idlingVoltageDescription' | translate }}">
                                {{'idlingOptions.idlingVoltageDescription' | translate }}
                            </td>
                            <td class="col-md-2">
                                <div class="fa_checkbox">
                                    <ng-container *ngIf="formMode == 1; else editIdlingVoltage">
                                        <input id="idlingVoltage" [readonly]=true type="checkbox"
                                            [checked]="settings.idlingVoltageIsActive" disabled />
                                        <label for="idlingVoltage"></label>
                                    </ng-container>
                                    <ng-template #editIdlingVoltage>
                                        <input id="idlingVoltage" type="checkbox"
                                            formControlName="idlingVoltageIsActive" />
                                        <label for="idlingVoltage"></label>
                                    </ng-template>
                                </div>
                            </td>
                            <td class="col-md-3">
                                <input type="text" [readonly]="formMode == 1" formControlName="idlingVoltageValue"
                                    class="form-control table-inline" required>
                            </td>
                            <td class="col-md-3">
                                <input type="text" [readonly]="formMode == 1" formControlName="idlingVoltageDuration"
                                    class="form-control table-inline" required>
                            </td>
                        </tr>
                        <tr>
                            <td class="col-checkbox" *ngIf="postWrapper?.childNodes.length === 0">
                                <input type="checkbox" [value]="updatedParams['idlingInputIsActive'].value"
                                    [disabled]="formMode == 1" (change)="onCheckChange($event)">
                                <label></label>
                            </td>
                            <td class="row_opening elipses_overflow"
                                title="{{'idlingOptions.idlingInputDescription' | translate }}">
                                {{'idlingOptions.idlingInputDescription' | translate }}
                            </td>
                            <td class="col-md-2">
                                <div class="fa_checkbox">
                                    <ng-container *ngIf="formMode == 1; else editIdlingInput">
                                        <input id="idlingInput" [readonly]=true type="checkbox"
                                            [checked]="settings.idlingInputIsActive" disabled />
                                        <label for="idlingInput"></label>
                                    </ng-container>
                                    <ng-template #editIdlingInput>
                                        <input id="idlingInput" type="checkbox" formControlName="idlingInputIsActive" />
                                        <label for="idlingInput"></label>
                                    </ng-template>
                                </div>
                            </td>
                            <td class="col-md-3">
                                <ng-container *ngIf="formMode == 1; else editIdlingInputValue">
                                    <input type="text" [readonly]=true class="form-control table-inline"
                                        [value]="'enums.locationEventType.' + settings.idlingInputValue | translate ">
                                </ng-container>
                                <ng-template #editIdlingInputValue>
                                    <ng-select [clearable]="false" formControlName="idlingInputValue"
                                        class="form-control table-inline" style="width:100%">
                                        <ng-option *ngFor="let io of inputOptions | orderBy : 'value':false"
                                            [value]="+io.id"> {{ io.value }}
                                        </ng-option>
                                    </ng-select>
                                </ng-template>
                            </td>
                            <td class="col-md-3">
                                <input type="text" [readonly]="formMode == 1" formControlName="idlingInputDuration"
                                    class="form-control table-inline" required>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="ibox">
            <div class="ibox-title">
                <h5>
                    <i class="fas fa-fw fa-timer"></i> {{'intervalOptions.header' | translate }}
                </h5>
            </div>
            <div class="ibox-content slick" style="position: relative" style="overflow-x: auto;">
                <ngx-loading [show]="loading"></ngx-loading>
                <table style="width: 100%" class="table nowrap dtr-inline no-footer table-fixed">
                    <thead>
                        <tr>
                            <th class="col-checkbox" *ngIf="postWrapper?.childNodes.length === 0"></th>
                            <th class="row_opening">
                                {{'general.name' | translate }}
                            </th>
                            <th class="row_content">
                                {{'general.value' | translate }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="col-checkbox" *ngIf="postWrapper?.childNodes.length === 0">
                                <input type="checkbox" [value]="updatedParams['homeIntervalInSeconds'].value"
                                    [disabled]="formMode == 1" (change)="onCheckChange($event)">
                                <label></label>
                            </td>
                            <td class="row_opening" title="{{'intervalOptions.homeIntervalInSeconds' | translate }}">
                                {{'intervalOptions.homeIntervalInSeconds' | translate }}
                                <span class="error"
                                    *ngIf="formGroup.get('homeIntervalInSeconds').errors && formMode != 1">*</span>
                            </td>
                            <td class="row_content" style="overflow:visible" colspan="3">
                                <input type="text" [readonly]="formMode == 1" formControlName="homeIntervalInSeconds"
                                    class="form-control table-inline">
                            </td>
                        </tr>
                        <tr>
                            <td class="col-checkbox" *ngIf="postWrapper?.childNodes.length === 0">
                                <input type="checkbox" [value]="updatedParams['roamingIntervalInSeconds'].value"
                                    [disabled]="formMode == 1" (change)="onCheckChange($event)">
                                <label></label>
                            </td>
                            <td class="row_opening" title="{{'intervalOptions.roamingIntervalInSeconds' | translate }}">
                                {{'intervalOptions.roamingIntervalInSeconds' | translate }}
                                <span class="error"
                                    *ngIf="formGroup.get('roamingIntervalInSeconds').errors && formMode != 1">*</span>
                            </td>
                            <td class="row_content" colspan="3">
                                <input type="text" [readonly]="formMode == 1" formControlName="roamingIntervalInSeconds"
                                    class="form-control table-inline">
                            </td>
                        </tr>
                        <tr>
                            <td class="col-checkbox" *ngIf="postWrapper?.childNodes.length === 0">
                                <input type="checkbox"
                                    [value]="updatedParams['notCommunicatingThresholdInMinutes'].value"
                                    [disabled]="formMode == 1" (change)="onCheckChange($event)">
                                <label></label>
                            </td>
                            <td class="row_opening"
                                title="{{'intervalOptions.notCommunicatingThresholdInMinutes' | translate }}">
                                {{'intervalOptions.notCommunicatingThresholdInMinutes' | translate }}
                                <span class="error"
                                    *ngIf="formGroup.get('notCommunicatingThresholdInMinutes').errors && formMode != 1">*</span>
                            </td>
                            <td class="row_content" colspan="3">
                                <input type="text" [readonly]="formMode == 1"
                                    formControlName="notCommunicatingThresholdInMinutes"
                                    class="form-control table-inline">
                            </td>
                        </tr>
                        <tr>
                            <td class="col-checkbox" *ngIf="postWrapper?.childNodes.length === 0">
                                <input type="checkbox"
                                    [value]="updatedParams['reportExternalPowerLostImmediately'].value"
                                    [disabled]="formMode == 1" (change)="onCheckChange($event)">
                                <label></label>
                            </td>
                            <td class="row_opening"
                                title="{{'intervalOptions.reportExternalPowerLostImmediately' | translate }}">
                                {{'intervalOptions.reportExternalPowerLostImmediately' | translate }}
                            </td>
                            <td class="row_content" colspan="3">
                                <div class="fa_checkbox">
                                    <ng-container *ngIf="formMode == 1; else editReportExternalPowerLostImmediately">
                                        <input id="stickyDriver" [readonly]=true type="checkbox"
                                            [checked]="settings.reportExternalPowerLostImmediately" disabled />
                                        <label for="stickyDriver"></label>
                                    </ng-container>
                                    <ng-template #editReportExternalPowerLostImmediately>
                                        <input id="reportExternalPowerLostImmediately" type="checkbox"
                                            formControlName="reportExternalPowerLostImmediately" />
                                        <label for="reportExternalPowerLostImmediately"></label>
                                    </ng-template>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</form>

<!-- Calibrations -->
<div (onHidden)="hideModal()" [config]="{ backdrop: true, ignoreBackdropClick: true }" bsModal #addModal="bs-modal"
    class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <ngx-loading [show]="loadingModal"></ngx-loading>

            <div class="modal-header">
                <h5 class="modal-title pull-left">
                    <i class="fa fa-chart-area"></i> {{'general.sensorCalibration' | translate}}
                    <i [tooltip]="'general.configurationBaseSensors' | translate" class="fas fa-fw fa-question"></i>
                </h5>
                <button type="button" class="close float-right" aria-label="Close" (click)="hideModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <form #userForm="ngForm">
                <div class="col-lg-12" style="padding: 0px 12.5px;">
                    <fh-error [error]="sensorError" [success]="sensorSuccess">
                    </fh-error>
                </div>
                <div class="modal-body" style="padding: 10px; padding-top: 0px;">
                    <fh-calibration-details #calibrationComponent [sensorNumber]="sensorNumber"
                        [calibrationPoints]="device.settings.sensorCalibrationPoints" [formMode]="formMode">
                    </fh-calibration-details>
                </div>
            </form>
        </div>
    </div>
</div>