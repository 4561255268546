import { Component, OnInit, Renderer2 } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
// Moment timezone
import Moment from "moment-timezone";
import { defineLocale } from "ngx-bootstrap/chronos";
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import {
  arLocale,
  deLocale,
  enGbLocale,
  esLocale,
  frLocale,
  nlLocale,
  ruLocale,
  zhCnLocale,
} from "ngx-bootstrap/locale";
// Models
import { AuthenticationService } from "./services/authentication/authentication.service";
import { filter, map, tap } from "rxjs/operators";
import { NavigationEnd, Router, ActivatedRoute, ResolveEnd, ResolveStart } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { NgSelectConfig } from "@ng-select/ng-select";
import { setTheme } from "ngx-bootstrap/utils";
import { timer } from "rxjs";
import { ColorService } from "./services/common/color.service";
import { DistanceUnitService } from "./common/distanceunit.service";

window["moment"] = Moment;

defineLocale("nl", nlLocale);
defineLocale("de", deLocale);
// defineLocale('en', ); // when undefined it takes default
defineLocale("gb", enGbLocale);
defineLocale("ug", enGbLocale);
defineLocale("ar", arLocale);
defineLocale("ru", ruLocale);
defineLocale("es", esLocale);
defineLocale("fr", frLocale);
defineLocale("cn", zhCnLocale);

@Component({
  selector: "fh-app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  title = "fleet-management";
  isLoading = true;
  previousUrl: string;

  countSub$ = timer(0, 1000).pipe(
    tap((_) => (window["reloadTime"] = window["reloadTime"] || 1)),
    map((_) => --window["reloadTime"])
  );

  constructor(
    private config: NgSelectConfig,
    private distance: DistanceUnitService,
    private colorService: ColorService,
    private localeService: BsLocaleService,
    private renderer: Renderer2,
    translate: TranslateService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title
  ) {
    translate.setDefaultLang("en");
    setTheme("bs4"); // or 'bs5'

    this.checkRoute();

    this.config.notFoundText = "No data found";
    // this.config.appendTo = 'body';
    // this.config.bindValue = 'value';

    this.authenticationService.IsAuthenticated(null, true).subscribe((_) => {
      console.log("Authentication: Token validated");
    });

    const distanceUnit = this.authenticationService.getDistanceUnit();
    this.distance.setUnit(distanceUnit);

    if (!translate.currentLang) {
      let currentSavedLang = this.authenticationService.getCultureLang();
      console.log(currentSavedLang);

      if (currentSavedLang === "" || currentSavedLang == null) {
        currentSavedLang = "en";
      }

      if (this.authenticationService.config && this.authenticationService.config.Debug) {
        console.log("No currentlang... setting : " + currentSavedLang);
      }

      translate.use(currentSavedLang);
      Moment.locale(currentSavedLang);
      this.localeService.use(currentSavedLang);

      translate.onLangChange.subscribe((event) => {
        Moment.locale(event.lang);
        this.localeService.use(event.lang);
        if (event.lang === "ur" || event.lang === "ar") {
          this.renderer.addClass(document.body, "rtls");
        } else {
          this.renderer.removeClass(document.body, "rtls");
        }
      });
    }

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let child = this.activatedRoute.firstChild;
          while (child) {
            if (child.firstChild) {
              child = child.firstChild;
            } else if (child.snapshot.data && child.snapshot.data["title"]) {
              const id = child.snapshot && child.snapshot.params && child.snapshot.params["id"];
              return "[" + child.snapshot.data["title"] + (id ? ": " + id : "") + "]";
            } else {
              return null;
            }
          }
          return null;
        })
      )
      .subscribe((data: any) => {
        if (data) {
          this.titleService.setTitle(data + " - [Fleet Management]");
        }
      });
  }

  checkRoute() {
    // Check if path is recogniced and force theme based on url.
    // When this is loaded it will overwrite the logged in theme and force the url theme
    const url = window.location.origin;

    let overwriteWhitelabel = "";
    overwriteWhitelabel = this.colorService.getOverwriteTheme(url);

    if (overwriteWhitelabel !== "") {
      console.log("Overwriting whitelabel with path " + url + " setting theme " + overwriteWhitelabel);

      localStorage.setItem(AuthenticationService.WHITELABEL_IMPERSONATE, overwriteWhitelabel);
      localStorage.setItem(AuthenticationService.WHITELABEL, overwriteWhitelabel);

      this.colorService.getThemeColors(overwriteWhitelabel).subscribe((result) => {
        this.colorService.addTheme(result);
      });
    }
  }

  ngOnInit() {
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
      }
      if (e instanceof ResolveStart) {
      }
      if (e instanceof ResolveEnd) {
        this.isLoading = false;
      }
    });
  }
}
